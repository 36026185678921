import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';

import { PriceInquiryRequestPriceCommerceArticleGet } from 'app/generated-client/generated-client';

import { PriceInquiriesInputManuallyComponent } from '../price-inquiries-input-manually/price-inquiries-input-manually.component';

@Component({
  selector: 'pa-price-inquiries-articles-modal',
  templateUrl: './price-inquiries-articles-modal.component.html',
  styleUrls: ['./price-inquiries-articles-modal.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, PriceInquiriesInputManuallyComponent]
})
export class PriceInquiriesArticlesModalComponent {
  widths: number[] = [16, 97, 430, 166, 74, 97, 48];
  constructor(@Inject(MAT_DIALOG_DATA) public data: { list: PriceInquiryRequestPriceCommerceArticleGet[] }) {}
}
