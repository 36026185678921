import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';

import { AutoUpdateCheckerService } from '../../services/auto-update-checker.service';

import { version } from './../../../version';

@Component({
  selector: 'pa-update-check',
  templateUrl: './update-check.component.html',
  styleUrls: ['./update-check.component.scss'],
  standalone: true,
  imports: [MatDialogContent, MatButton]
})
export class UpdateCheckComponent implements OnInit {
  currentVersion: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public version: string,
    private autoUpdateCheckerService: AutoUpdateCheckerService,
    @Optional() private dialogRef: MatDialogRef<UpdateCheckComponent>
  ) {}

  ngOnInit(): void {
    this.currentVersion = version.version;
  }

  goToDownloadPage(): void {
    this.autoUpdateCheckerService.goToDownloadPage(this.version);
    this.dismiss();
  }

  dismiss(): void {
    this.dialogRef.close();
  }

  skipVersion(): void {
    this.autoUpdateCheckerService.skipCurrentVersion();
    this.dismiss();
  }
}
