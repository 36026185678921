import { Injectable } from '@angular/core';
import { WindowSettingType } from '../models';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageViewService {
  settings: { [key: string]: WindowSettingType } = {};
  defaultSetting: WindowSettingType = {
    alwaysOnTop: false
  };

  constructor() {}

  getSettingView(mode: string): WindowSettingType {
    if (mode in this.settings && this.settings[mode]) {
      return this.settings[mode];
    }
    const rawSetting: string = localStorage.getItem(mode);
    if (rawSetting) {
      try {
        this.settings[mode] = JSON.parse(rawSetting);
      } catch (e) {
        this.settings[mode] = { ...this.defaultSetting };
      }
    } else {
      this.settings[mode] = { ...this.defaultSetting };
    }
    return this.settings[mode];
  }

  setSettingView(mode: string): void {
    localStorage.setItem(mode, JSON.stringify(this.settings[mode]));
  }
}
