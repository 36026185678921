import { NgFor } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';

import { ProjectQuantityTakeOffType } from 'app/generated-client/generated-client';

import { ProjectQtoTypePipe } from '../../pipes/ui-data-display/project-qto-type.pipe';

@Component({
  selector: 'pa-select-project-qto-type-modal',
  templateUrl: './select-project-qto-type-modal.component.html',
  styleUrls: ['./select-project-qto-type-modal.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, MatFormField, MatDialogContent, MatLabel, MatSelect, FormsModule, NgFor, MatOption, ProjectQtoTypePipe]
})
export class SelectProjectQtoTypeModalComponent implements OnInit {
  listQtoType: ProjectQuantityTakeOffType[] = Object.keys(ProjectQuantityTakeOffType).map((ps) => <ProjectQuantityTakeOffType>ps);
  currentQtoType: ProjectQuantityTakeOffType = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ProjectQuantityTakeOffType,
    @Optional() private matDialogRef: MatDialogRef<SelectProjectQtoTypeModalComponent>
  ) {}

  ngOnInit(): void {
    this.currentQtoType = this.data;
  }

  changed(event): void {
    this.matDialogRef.close(event);
  }
}
