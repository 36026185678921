import { Component, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { ElementSelectionGet } from 'app/generated-client/generated-client';

import { SelectionElementListTableComponent } from '../selection-element-list-table/selection-element-list-table.component';

@Component({
  selector: 'pa-selection-element-list-modal',
  templateUrl: './selection-element-list-modal.component.html',
  styleUrls: ['./selection-element-list-modal.component.scss'],
  standalone: true,
  imports: [SelectionElementListTableComponent]
})
export class SelectionElementListModalComponent {
  constructor(@Optional() private dialogRef: MatDialogRef<SelectionElementListModalComponent>) {}

  chooseItem(line: ElementSelectionGet): void {
    this.dialogRef.close(line);
  }
}
