<form mat-dialog-content [formGroup]="newItemForm">
  <div fxLayout="column">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" required />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Beschreibung</mat-label>
      <input matInput formControlName="description" />
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutGap="12px">
    <button mat-raised-button color="primary" type="submit" [disabled]="newItemForm.invalid" [mat-dialog-close]="newItemForm.value">
      Speichern
    </button>
    <button mat-button mat-raised-button color="warn" type="button" mat-dialog-close>Abbrechen</button>
  </div>
</form>
