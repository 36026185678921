<h2>Excel für Haltungen Exportieren</h2>
<form class="export-form" [formGroup]="canalExportForm" (ngSubmit)="createCanalExport()">
  <mat-form-field appearance="fill">
    <mat-label>Rechnungsnummer</mat-label>
    <input matInput type="number" step="1" name="invoiceNumber" formControlName="invoiceNumber" />
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Rechnungsname</mat-label>
    <input matInput type="text" name="invoiceName" formControlName="invoiceName" />
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Auftragsname</mat-label>
    <input matInput type="text" name="orderName" formControlName="orderName" />
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Leere Haltungen</mat-label>
    <input matInput type="number" name="emptyCanalSegments" formControlName="emptyCanalSegments" />
  </mat-form-field>

  <input hidden type="file" [multiple]="false" (change)="setFile([fileInput.files.item(0)])" #fileInput />
  <button mat-button mat-raised-button class="file-selection-button" type="button" color="primary" (click)="fileInput.click()">
    X51 Datei Auswählen
  </button>
  <p *ngIf="selectedFileName">Datei: {{ selectedFileName }}</p>

  <div class="button-actions" fxLayout="row" fxLayoutGap="8px">
    <button
      mat-button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!(canalExportForm.value.emptyCanalSegments || selectedFileName)"
    >
      Erstellen
    </button>
    <button mat-button mat-raised-button color="warn" type="button" (click)="dismiss()">Abbrechen</button>
  </div>
</form>
