<pa-table-wrapper [offsetFromMaxHeightTable]="247">
  <table
    mat-table
    [dataSource]="pagesAllAvaProjectService"
    matSort
    (matSortChange)="pagesAllAvaProjectService.onSort($event)"
    [ngClass]="{
      'small-row': QuantityTakeOffType.Invoice === typeQTO
    }"
    (keyup)="onKey($event)"
    (keydown.tab)="$event.preventDefault()"
  >
    <!-- This column needed for moving cursor via keyboard  -->
    <ng-container matColumnDef="index">
      <th *matHeaderCellDef style="width: 0% !important; padding: 0 !important"></th>
      <td *matCellDef="let row" style="width: 0% !important; padding: 0 !important"></td>
    </ng-container>
    <ng-container matColumnDef="quantityTakeOff.name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Abrechnung</th>
      <td
        mat-cell
        *matCellDef="let row; let i = index"
        [ngClass]="{
          editable: row.quantityTakeOff != null && row.quantityTakeOff.markedAsBilledAtUtc == null
        }"
      >
        <div *ngIf="QuantityTakeOffType.Invoice === typeQTO && row.quantityTakeOff != null; else textField">
          <input
            id="{{ i + 1 }}-quantityTakeOff.name"
            type="text"
            matInput
            [disabled]="!row.quantityTakeOff || row.isChecked || row.quantityTakeOff?.markedAsBilledAtUtc != null"
            [(ngModel)]="row?.quantityTakeOff.name"
            (change)="updateInvoicePage(row.quantityTakeOff.name, row, 'quantityTakeOffName')"
          />
        </div>
        <ng-template #textField>
          <input
            id="{{ i + 1 }}-quantityTakeOff.name"
            type="text"
            [disabled]="!row.quantityTakeOff || row.isChecked || row.quantityTakeOff?.markedAsBilledAtUtc != null"
            matInput
            [value]=""
            readonly
          />
        </ng-template>
      </td>
    </ng-container>
    <ng-container matColumnDef="quantityTakeOff.number">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>AR-Nr.</th>
      <td
        mat-cell
        *matCellDef="let row; let i = index"
        [ngClass]="{
          editable: row.quantityTakeOff?.markedAsBilledAtUtc == null
        }"
      >
        <div *ngIf="QuantityTakeOffType.Invoice === typeQTO; else textField">
          <input
            type="text"
            id="{{ i + 1 }}-quantityTakeOff.number"
            matInput
            [disabled]="row.isChecked || row.quantityTakeOff?.markedAsBilledAtUtc != null"
            [(ngModel)]="row.quantityTakeOffNumber"
            (change)="updateInvoicePage(row.quantityTakeOffNumber, row, 'quantityTakeOffNumber')"
          />
        </div>
        <ng-template #textField>
          {{ row.quantityTakeOff?.number }}
        </ng-template>
      </td>
    </ng-container>
    <ng-container matColumnDef="isChecked">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Geprüft</th>
      <td mat-cell *matCellDef="let row">
        <span *ngIf="row.isChecked">✔</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Titel</th>
      <td
        mat-cell
        *matCellDef="let row; let i = index"
        [ngClass]="{
          editable: row.quantityTakeOff?.markedAsBilledAtUtc == null
        }"
      >
        <div *ngIf="QuantityTakeOffType.Invoice === typeQTO; else textField"></div>
        <input
          type="text"
          id="{{ i + 1 }}-name"
          [disabled]="row.isChecked || row.quantityTakeOff?.markedAsBilledAtUtc != null"
          matInput
          [(ngModel)]="row.name"
          (change)="updateInvoicePage(row.name, row, 'name')"
        />
        <ng-template #textField>
          {{ row.name }}
        </ng-template>
      </td>
    </ng-container>
    <ng-container matColumnDef="subContractor">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Sub</th>
      <td mat-cell *matCellDef="let row">
        {{ row.subContractorName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="buildingElementCode">
      <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Bauteilcode">BT Code</th>
      <td
        mat-cell
        *matCellDef="let row; let i = index"
        [ngClass]="{
          editable: row.quantityTakeOff?.markedAsBilledAtUtc == null
        }"
      >
        <div *ngIf="QuantityTakeOffType.Invoice === typeQTO; else textField">
          <input
            type="text"
            id="{{ i + 1 }}-buildingElementCode"
            matInput
            [disabled]="row.isChecked || row.quantityTakeOff?.markedAsBilledAtUtc != null"
            [(ngModel)]="row.buildingElementCode"
            (change)="updateInvoicePage(row.buildingElementCode, row, 'buildingElementCode')"
          />
        </div>
        <ng-template #textField>
          {{ row.buildingElementCode }}
        </ng-template>
      </td>
    </ng-container>
    <ng-container matColumnDef="pageNumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="right">Blatt-Nr.</th>
      <td
        mat-cell
        *matCellDef="let row; let i = index"
        [ngClass]="{
          editable: row.quantityTakeOff?.markedAsBilledAtUtc == null
        }"
      >
        <div *ngIf="QuantityTakeOffType.Invoice === typeQTO; else textField">
          <input
            id="{{ i + 1 }}-pageNumber"
            type="text"
            class="right"
            matInput
            [disabled]="row.isChecked || row.quantityTakeOff?.markedAsBilledAtUtc != null"
            [(ngModel)]="row.pageNumber"
            (change)="updateInvoicePage(row.pageNumber, row, 'pageNumber')"
          />
        </div>
        <ng-template #textField>
          {{ row.pageNumber }}
        </ng-template>
      </td>
    </ng-container>
    <ng-container matColumnDef="servicePeriodCode">
      <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Leistungszeitraum">LZ</th>
      <td
        mat-cell
        *matCellDef="let row; let i = index"
        [ngClass]="{
          editable: row.quantityTakeOff?.markedAsBilledAtUtc == null
        }"
      >
        <input
          id="{{ i + 1 }}-servicePeriodCode"
          type="text"
          matInput
          [(ngModel)]="row.servicePeriodCode"
          [disabled]="row.isChecked || row.quantityTakeOff?.markedAsBilledAtUtc != null"
          (change)="updateInvoicePage(row.servicePeriodCode, row, 'servicePeriodCode')"
        />
      </td>
    </ng-container>
    <ng-container matColumnDef="invoicePeriodCode">
      <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Abrechnungszeitraum">AZ</th>
      <td
        mat-cell
        *matCellDef="let row; let i = index"
        [ngClass]="{
          editable: row.quantityTakeOff?.markedAsBilledAtUtc == null
        }"
      >
        <input
          id="{{ i + 1 }}-invoicePeriodCode"
          type="text"
          matInput
          [(ngModel)]="row.invoicePeriodCode"
          [disabled]="row.isChecked || row.quantityTakeOff?.markedAsBilledAtUtc != null"
          (change)="updateInvoicePage(row.invoicePeriodCode, row, 'invoicePeriodCode')"
        />
      </td>
    </ng-container>
    <ng-container matColumnDef="invoiceRecipientCode">
      <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Rechnungsempfänger">RE</th>
      <td
        mat-cell
        *matCellDef="let row; let i = index"
        [ngClass]="{
          editable: row.quantityTakeOff?.markedAsBilledAtUtc == null
        }"
      >
        <input
          id="{{ i + 1 }}-invoiceRecipientCode"
          type="text"
          matInput
          [(ngModel)]="row.invoiceRecipientCode"
          [disabled]="row.isChecked || row.quantityTakeOff?.markedAsBilledAtUtc != null"
          (change)="updateInvoicePage(row.invoiceRecipientCode, row, 'invoiceRecipientCode')"
        />
      </td>
    </ng-container>
    <ng-container matColumnDef="totalSum">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="right">Summe</th>
      <td mat-cell *matCellDef="let row" class="right">{{ row.totalSum | projectCurrency }}</td>
    </ng-container>
    <ng-container matColumnDef="billed">
      <th mat-header-cell *matHeaderCellDef>Abgerechnet</th>
      <td mat-cell *matCellDef="let row">
        <div class="bill">
          {{ row.quantityTakeOff?.markedAsBilledAtUtc | date: 'dd.MM.yyyy' }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="center exclude-outline" (click)="$event.stopPropagation()">
        <div
          [ngClass]="{
            'small-btn': QuantityTakeOffType.Invoice === typeQTO
          }"
        >
          <button mat-icon-button color="primary" (click)="showContextMenu($event, row)">
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay" (dblclick)="selectedPage(row)"></tr>
  </table>
</pa-table-wrapper>
<pa-upper-paginator
  [pageSizeOptions]="[5, 10, 25, 100]"
  [upperPaginated]="pagesAllAvaProjectService.paginationResult | async"
  [upperService]="pagesAllAvaProjectService"
  (page)="pagesAllAvaProjectService.onPage($event)"
>
</pa-upper-paginator>
<pa-invoice-table-menu
  [isPages]="true"
  [contextMenuPosition]="contextMenuPosition"
  (invoiceMenuActions)="handlerMenuAction($event)"
  [disabledObject]="disabledObject"
></pa-invoice-table-menu>
