import { ExecutionDescriptionDto, NoteTextDto, PositionDto, ServiceSpecificationGroupDto } from 'app/generated-client/generated-client';
import { IElementDto, ServiceSpecificationDto } from './../../../generated-client/generated-client';

import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FlatElementsService {
  private flatElementsDtoSource = new ReplaySubject<(ServiceSpecificationGroupDto | NoteTextDto | ExecutionDescriptionDto | PositionDto)[]>(
    1
  );
  flatElementsDto = this.flatElementsDtoSource.asObservable();

  constructor() {}

  setElementsDto(elementsDto: ServiceSpecificationDto): void {
    const flatElements = FlatElementsService.getFlatElements(elementsDto);
    this.flatElementsDtoSource.next(flatElements);
  }

  public static getFlatElements(container: { elements?: IElementDto[] }): IElementDto[] {
    const flatElements: IElementDto[] = [];

    if (container.elements) {
      container.elements.forEach(e => {
        if (e.elementTypeDiscriminator === 'ServiceSpecificationGroupDto') {
          const group = e as ServiceSpecificationGroupDto;
          flatElements.push(group);
          flatElements.push(...this.getFlatElements(group));
        } else {
          flatElements.push(e);
        }
      });
    }

    return flatElements;
  }
}
