<div mat-dialog-content>
  <mat-form-field appearance="fill" class="catalog-name">
    <mat-label>Name</mat-label>
    <input matInput [(ngModel)]="catalogName" required />
  </mat-form-field>
  <pa-upload-file (uploadFile)="onSelect($event)" [uploadFileSettings]="uploadFileSettings"></pa-upload-file>
  <div class="file" *ngIf="selectedFile">
    Datei: <span>{{ selectedFile.name }}</span>
  </div>
  <div fxLayout="column" fxLayoutGap="12px">
    <div fxLayout="column" fxLayoutGap="12px">
      <div fxLayout="row" fxLayoutGap="12px">
        <button mat-raised-button color="primary" (click)="chooseContact()">Kontakt auswählen</button>
        <button mat-raised-button color="warn" (click)="selectedContac = null">Kontakt löschen</button>
      </div>
      <span *ngIf="selectedContac">Kontakt: {{ selectedContac.name }}</span>
    </div>
    <div fxLayout="row" fxLayoutGap="12px">
      <button mat-raised-button color="primary" (click)="createCatalog()" [disabled]="!selectedFile || !catalogName">Erstellen</button>
      <button mat-raised-button color="warn" type="button" mat-dialog-close>Abbrechen</button>
    </div>
  </div>
</div>
