<ng-container *ngIf="isChangeItemNumber; else createOrselect">
  <div class="dropdown" cdkTrapFocus cdkTrapFocusAutoCapture>
    <input
      #changeItemNumberInput
      class="val"
      type="text"
      (blur)="changeItemNumber()"
      [(ngModel)]="itemNumberString"
      (keydown)="inputItemNumber($event)"
      tabIndex="0"
      (keydown.enter)="changeItemNumberInput.blur()"
      (keydown.esc)="itemNumberString = originalItemNumberString; changeItemNumberInput.blur()"
    />
  </div>
</ng-container>
<ng-template #createOrselect>
  <input
    class="val"
    type="text"
    (blur)="createNewOrSelectExistingElement()"
    (keydown)="inputItemNumber($event)"
    [(ngModel)]="itemNumberString"
    (contextmenu)="openMenu($event, menuTrigger)"
  />
  <span [matMenuTriggerFor]="rootMenu" #menuTrigger="matMenuTrigger"></span>
</ng-template>

<mat-menu #rootMenu="matMenu" [overlapTrigger]="false" class="menu-content">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="isChangeItemNumber = true">
      <mat-icon>edit</mat-icon>
      <span>OZ Ändern</span>
    </button>
  </ng-template>
</mat-menu>
