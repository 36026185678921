import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'pa-note-icon',
  templateUrl: './note-icon.component.html',
  styleUrls: ['./note-icon.component.scss'],
  standalone: true,
  imports: [NgIf, MatIcon]
})
export class NoteIconComponent {
  @Input() mode: string;
}
