import { NgFor, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTabNav, MatTabLink } from '@angular/material/tabs';
import { ActivatedRoute, Router, RouterLinkActive, RouterLink, RouterOutlet } from '@angular/router';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { PriceComponentType, ProjectDto, ReportsClient, ServiceSpecificationDto } from 'app/generated-client/generated-client';
import { PrintViewMessengerService } from 'app/shared/services/electron/print-view-messenger.service';
import { SelectedPriceComponentTypeService } from 'app/shared/services/messengers/selected-price-component-type.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

import { PriceComponentTypePipe } from '../../../../../../shared/pipes/ui-data-display/price-component-type.pipe';
import { ProjectCurrencyPipe } from '../../../../../../shared/pipes/ui-data-display/project-currency.pipe';
import { ProjectProductsService } from '../../../../../../shared/services/lightquery/project-products.service';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-project-products',
  templateUrl: './project-products.component.html',
  styleUrls: ['./project-products.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutDirective,
    MatButton,
    MatCheckbox,
    FormsModule,
    MatTabNav,
    MatTabLink,
    RouterLinkActive,
    RouterLink,
    NgFor,
    RouterOutlet,
    NgIf,
    MatProgressSpinner,
    PriceComponentTypePipe,
    ProjectCurrencyPipe
  ]
})
export class ProjectProductsComponent implements OnInit, OnDestroy {
  projectId: string;
  avaProjectId: string;
  priceComponentType: PriceComponentType | null = null;
  showingPdf: boolean;
  isLoading: boolean;
  isUpdating: boolean;
  currentServiceSpecification: ServiceSpecificationDto;
  listPriceComponentType: PriceComponentType[] = Object.keys(PriceComponentType).map((key) => PriceComponentType[key]);
  includePricesInPdfExport = false;
  private _includePositionsWithoutTotal = false;
  get includePositionsWithoutTotal(): boolean {
    return this._includePositionsWithoutTotal;
  }
  set includePositionsWithoutTotal(value: boolean) {
    this._includePositionsWithoutTotal = value;
    this.projectProductsService.setQueryParameter('includePositionsWithoutTotal', this.includePositionsWithoutTotal.toString());
  }
  includeHeaderOnlyOnFirstPage = false;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private selectedPriceComponentTypeService: SelectedPriceComponentTypeService,
    private printViewMessengerService: PrintViewMessengerService,
    private reportsClient: ReportsClient,
    private projectProductsService: ProjectProductsService
  ) {}

  ngOnInit(): void {
    this.projectProductsService.setQueryParameter('includePositionsWithoutTotal', this.includePositionsWithoutTotal.toString());

    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        filter((r) => !!r),
        takeUntil(this.$destroy)
      )
      .subscribe((e: { avaProjectId: string; project: ProjectDto; parentProjectId: string }) => {
        this.projectId = e?.parentProjectId;
        this.avaProjectId = e?.avaProjectId;
        this.currentServiceSpecification = e?.project.serviceSpecifications[0];
      });
    this.selectedPriceComponentTypeService.selectedPriceComponentType
      .pipe(takeUntil(this.$destroy))
      .subscribe((c) => (this.priceComponentType = c));
    this.selectedPriceComponentTypeService.updating.pipe(takeUntil(this.$destroy)).subscribe((c: boolean) => {
      this.isUpdating = c;
    });
    this.printViewMessengerService.printViewClosed.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.showingPdf = false;
      this.isLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
    this.projectProductsService.setQueryParameter('includePositionsWithoutTotal', false.toString());
  }

  goBack(): void {
    const position = this.route.snapshot.paramMap.get('position');
    if (position) {
      this.router.navigate(['..', { position }], { relativeTo: this.route });
    } else {
      this.router.navigate(['..'], { relativeTo: this.route });
    }
  }

  print(): void {
    this.isLoading = true;
    this.reportsClient
      .getProjectProductsReport(this.projectId, this.avaProjectId, true, this.includeHeaderOnlyOnFirstPage, {
        projectId: this.projectId,
        avaProjectId: this.avaProjectId,
        priceComponentType: this.priceComponentType,
        excludePrices: !this.includePricesInPdfExport,
        includePositionsWithoutTotalPrice: this.includePositionsWithoutTotal
      })
      .subscribe({
        next: (report) => {
          this.printViewMessengerService.showPdfPreview(
            report.htmlReport,
            'priceComponentType',
            report.isLandscape,
            report.pdfReportBase64,
            this.includeHeaderOnlyOnFirstPage
          );
          this.printViewMessengerService.waitForPrintViewReady().subscribe(() => {
            this.showingPdf = true;
            this.isLoading = false;
          });
        },
        error: () => {
          this.isLoading = false;
        }
      });
  }
}
