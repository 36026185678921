<p>Das Projekt kann mit allen Daten in ein neues Projekt übertragen werden.</p>
<button mat-raised-button color="primary" (click)="prepareProjectCopy()">Projekt Kopieren</button>

<hr />

<button mat-raised-button (click)="exportProject()" [disabled]="exportRunning">Sicherungskopie Speichern</button>

<hr />

<button mat-raised-button (click)="recalculateProject()" [disabled]="exportRunning">Kalkulationen neu Berechnen</button>
<p>
  Damit werden alle Kalkulationen im Projekt neu berechnet. Das kann sinnvoll sein, falls durch ein Update neue Kalkulationsfunktionen
  hinzugefügt wurden.
</p>

<mat-spinner *ngIf="exportRunning"></mat-spinner>
