import { Pipe, PipeTransform } from '@angular/core';

import { PositionDto, PositionTypeDto, PriceTypeDto } from 'app/generated-client/generated-client';

@Pipe({
  name: 'shortPositionAndPriceType',
  standalone: true
})
export class ShortPositionAndPriceTypePipe implements PipeTransform {
  transform(value: PositionDto): string {
    let str = '';
    if (value.positionType === PositionTypeDto.Regular) {
      str = 'N';
    } else if (value.positionType === PositionTypeDto.Optional) {
      str = 'B';
    } else if (value.positionType === PositionTypeDto.Alternative) {
      str = 'A';
    }
    if (value.priceType === PriceTypeDto.WithTotal) {
      str = str + 'N';
    } else if (value.priceType === PriceTypeDto.WithoutTotal) {
      str += 'O';
    }
    return str;
  }
}
