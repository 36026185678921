import { NgIf, NgClass, AsyncPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  MatTableDataSource,
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatFooterCellDef,
  MatFooterCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow,
  MatFooterRowDef,
  MatFooterRow
} from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';

import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

import { CalculateTransferWageService } from '@serv-spec/services/calculate-transfer-wage.service';

import { SaveAdditionGroupService } from '@master/services/save-addition-group.service';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';
import {
  AvaProjectMedianWageClient,
  MasterAdditionGroupsClient,
  MasterAdditionMedianWageClient,
  MedianHourlyWageCalculation,
  MedianHourlyWageResource,
  ProjectDto,
  ProjectGet
} from 'app/generated-client/generated-client';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

import { ProjectCurrencyPipe } from '../../../../../../shared/pipes/ui-data-display/project-currency.pipe';
import { MatInputDecimalPlacesDirective } from '../../directives/mat-input-decimal-places.directive';
import { WageCalculationDetails, WageCalculationService } from '../../services/wage-calculation.service';

export class MedianHourlyWage implements MedianHourlyWageResource {
  amount?: number;
  description?: string | null;
  medianWage?: number;
  isEdit?: boolean;
  constructor(medianHourlyWage: MedianHourlyWageResource, isEdit?: boolean) {
    this.amount = medianHourlyWage.amount;
    this.description = medianHourlyWage.description;
    this.medianWage = medianHourlyWage.medianWage;
    this.isEdit = isEdit;
  }
}

@Component({
  selector: 'pa-calculation-middle-wages',
  templateUrl: './calculation-middle-wages.component.html',
  styleUrls: ['./calculation-middle-wages.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatProgressSpinner,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatFormField,
    FormsModule,
    MatInput,
    MatIconButton,
    MatIcon,
    NgClass,
    MatFooterCellDef,
    MatFooterCell,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatFooterRowDef,
    MatFooterRow,
    MatInputDecimalPlacesDirective,
    MatTooltip,
    MatButton,
    AsyncPipe,
    DecimalPipe,
    PercentPipe,
    ProjectCurrencyPipe,
    FlexLayoutDirective
  ]
})
export class CalculationMiddleWagesComponent implements OnInit, OnDestroy {
  @Input() transferWage: number;
  @Input() masterAdditionId: string;

  private _laborSiteOperationCostsAddition: number;
  @Input() set laborSiteOperationCostsAddition(value: number) {
    this._laborSiteOperationCostsAddition = value;
    this.calculateWageAdditions();
  }
  get laborSiteOperationCostsAddition(): number {
    return this._laborSiteOperationCostsAddition;
  }
  private _laborCompanyOperationCostsAddition: number;
  @Input() set laborCompanyOperationCostsAddition(value: number) {
    this._laborCompanyOperationCostsAddition = value;
    this.calculateWageAdditions();
  }
  get laborCompanyOperationCostsAddition(): number {
    return this._laborCompanyOperationCostsAddition;
  }
  private _riskAndProfitAddition: number;
  @Input() set riskAndProfitAddition(value: number) {
    this._riskAndProfitAddition = value;
    this.calculateWageAdditions();
  }
  get riskAndProfitAddition(): number {
    return this._riskAndProfitAddition;
  }

  laborSiteOperationCostsAdditionPart: number | null;
  laborCompanyOperationCostsAdditionPart: number | null;
  riskAndProfitAdditionPart: number | null;

  private $destroy = new Subject<boolean>();

  displayedColumns: string[] = ['position', 'amount', 'description', 'medianWage', 'sum', 'edit', 'delete'];
  dataSource = new MatTableDataSource<MedianHourlyWage>();
  isPossibleAddRow = true;
  copyAmount: number | null;
  copyMedianWage: number | null;
  copyDescription: string | null;
  projectCurrency: string;
  isLoading = false;
  hasNoMiddleWageConfigured = false;

  saveAdditionGroupService = inject(SaveAdditionGroupService);
  transferWage$ = inject(CalculateTransferWageService).transferWage$;

  wageCalculation: MedianHourlyWageCalculation;
  wageCalculationDetails: WageCalculationDetails;
  get supplementalWageCostsPercentage(): number {
    return Math.round(10000 * this.wageCalculation?.supplementalWageCostsPercentage ?? 0) / 100;
  }
  set supplementalWageCostsPercentage(value: number) {
    if (this.wageCalculation) {
      this.wageCalculation.supplementalWageCostsPercentage = value / 100;
    }
  }
  get incidentalWageCostsPercentage(): number {
    return Math.round(10000 * this.wageCalculation?.incidentalWageCostsPercentage ?? 0) / 100;
  }
  set incidentalWageCostsPercentage(value: number) {
    if (this.wageCalculation) {
      this.wageCalculation.incidentalWageCostsPercentage = value / 100;
    }
  }
  get toolsAndMinorDevicesPercentage(): number {
    return Math.round(10000 * this.wageCalculation?.toolsAndMinorDevicesPercentage ?? 0) / 100;
  }
  set toolsAndMinorDevicesPercentage(value: number) {
    if (this.wageCalculation) {
      this.wageCalculation.toolsAndMinorDevicesPercentage = value / 100;
    }
  }

  /** The scenarioMode indicates if this component is used withing the scenario editor, which means
   * that it is live updated to calculate possible price differences with other additions or median
   * wages. In that case, there's no saving done but the result is just communicated via the
   * calculationChange event.
   */
  @Input() scenarioMode = false;
  @Output() calculationChange = new EventEmitter<MedianHourlyWageCalculation>();
  @Output() wageCalculationLoaded = new EventEmitter<MedianHourlyWageCalculation>();

  masterAdditionGroupsClient = inject(MasterAdditionGroupsClient);

  constructor(
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private avaProjectMedianWageClient: AvaProjectMedianWageClient,
    private avaNotificationsService: AvaNotificationsService,
    private masterAdditionMedianWageClient: MasterAdditionMedianWageClient
  ) {}

  ngOnInit(): void {
    this.loadOriginalValues();

    this.selectedSpecificationMessengerService.projectCurrency
      .pipe(takeUntil(this.$destroy))
      .subscribe((currency: string) => (this.projectCurrency = currency));

    this.saveAdditionGroupService.saveAdditionGroup$.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.saveWages();
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  loadOriginalValues(): void {
    this.isLoading = true;
    let request: Observable<MedianHourlyWageCalculation>;
    if (this.masterAdditionId) {
      request = this.masterAdditionMedianWageClient.getMedianWageCalculationForMasterAddition(this.masterAdditionId);
    } else {
      request = combineLatest([
        this.selectedProjectMessengerService.selectedProject.pipe(filter((p) => !!p)),
        this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(filter((s) => !!s))
      ]).pipe(
        takeUntil(this.$destroy),
        switchMap(([project, s]: [ProjectGet, { avaProjectId: string; project: ProjectDto }]) =>
          this.avaProjectMedianWageClient.getMedianWageCalculationForAvaProject(project.id, s.avaProjectId)
        )
      );
    }

    request.subscribe(
      (wageCalculation) => {
        this.isLoading = false;
        if (wageCalculation) {
          this.hasNoMiddleWageConfigured = false;
          this.wageCalculation = wageCalculation;
          this.recalculate(false);
          this.wageCalculationLoaded.next(this.wageCalculation);
        }
      },
      () => {
        this.hasNoMiddleWageConfigured = true;
        this.wageCalculation = {
          resources: [],
          calculationWage: null,
          incidentalWageCostsPercentage: null,
          medianHourlyWage: null,
          resourcesSupervisionPart: null,
          supplementalWageCostsPercentage: null,
          toolsAndMinorDevicesPercentage: null
        };
        this.recalculate(false);
        this.isLoading = false;
      }
    );
  }

  shouldTryToCalculateWageOverride = false;
  setWageCalculationOverride(): void {
    if (!this.shouldTryToCalculateWageOverride) {
      return;
    }

    this.shouldTryToCalculateWageOverride = false;
    const previousCalculationWage = +this.wageCalculation.calculationWage;
    const calcResult = WageCalculationService.recalculate(this.wageCalculation);
    if (calcResult.isSuccess && calcResult.value.calculationWage !== previousCalculationWage) {
      this.wageCalculation.resources = [
        {
          amount: 1,
          description: 'Manuelle Eingabe',
          medianWage: previousCalculationWage
        }
      ];
      this.wageCalculation.resourcesSupervisionPart = 0;
      this.wageCalculation.incidentalWageCostsPercentage = 0;
      this.wageCalculation.supplementalWageCostsPercentage = 0;
      this.wageCalculation.toolsAndMinorDevicesPercentage = 0;
      this.wageCalculation.medianHourlyWageOverride = null;
      this.recalculate();
    }
  }

  recalculate(updateResources?: boolean): void {
    if (updateResources) {
      this.wageCalculation.resources = this.dataSource.data.map((r) => {
        return {
          amount: r.amount,
          description: r.description,
          medianWage: r.medianWage
        };
      });
    }

    if (this.wageCalculation.medianHourlyWageOverride != null && typeof this.wageCalculation.medianHourlyWageOverride === 'string') {
      if ((this.wageCalculation.medianHourlyWageOverride as string).length === 0) {
        this.wageCalculation.medianHourlyWageOverride = null;
      } else {
        this.wageCalculation.medianHourlyWageOverride = +this.wageCalculation.medianHourlyWageOverride;
      }
    }

    const calcResult = WageCalculationService.recalculate(this.wageCalculation);
    if (calcResult.isSuccess) {
      this.wageCalculation = calcResult.value;
      this.wageCalculationDetails = calcResult.additionalData;
      this.dataSource.data = this.wageCalculation.resources.map((v) => new MedianHourlyWage(v));
      this.calculateWageAdditions();

      // We're checking that there's a valid number, and also only only raise the
      // notification once loading has finished, since we don't want to communicate the
      // initial state.
      if (!isNaN(this.wageCalculation.calculationWage) && !this.isLoading && !this.hasNoMiddleWageConfigured) {
        this.calculationChange.next(this.wageCalculation);
      }
    } else {
      this.avaNotificationsService.error('Fehler bei der Berechnung des Mittellohns');
    }
  }

  deleteRow(index: number): void {
    this.isPossibleAddRow = true;
    this.dataSource.data.splice(index, 1);
    this.recalculate(true);
  }

  editRow(el: MedianHourlyWage): void {
    this.copyAmount = el.amount;
    this.copyDescription = el.description;
    this.copyMedianWage = el.medianWage;
    this.isPossibleAddRow = false;
    this.dataSource.data.map((element, i) => {
      if (!element.amount || (!element.description && this.dataSource[i].description) || !element.medianWage) {
        this.deleteRow(i);
      }
      element.isEdit = false;
      return element;
    });
    el.isEdit = true;
  }

  saveEditing(el: MedianHourlyWage, i: number): void {
    el.isEdit = false;
    this.isPossibleAddRow = true;
    this.dataSource.data[i].amount = this.copyAmount;
    this.dataSource.data[i].description = this.copyDescription;
    this.dataSource.data[i].medianWage = this.copyMedianWage;
    this.recalculate(true);
  }

  cancelEditing(el: MedianHourlyWage, i: number): void {
    if ((!el.description && this.dataSource.data[i].description) || !el.amount || !el.medianWage) {
      this.deleteRow(i);
    }
    el.isEdit = false;
    this.isPossibleAddRow = true;
    this.resetCopyValue();
  }

  addRow(): void {
    const newRow: MedianHourlyWage = {};
    this.editRow(newRow);
    const data = JSON.parse(JSON.stringify(this.dataSource.data));
    data.push(newRow);
    this.dataSource.data = data;
    this.resetCopyValue();
  }

  changeAmount(amount: number): void {
    this.copyAmount = amount;
  }
  changeMedianWage(medianWage: number): void {
    this.copyMedianWage = medianWage;
  }

  changeDescription(description: string): void {
    this.copyDescription = description;
  }

  resetCopyValue(): void {
    this.copyAmount = null;
    this.copyDescription = null;
    this.copyMedianWage = null;
  }

  saveWages(): void {
    if (this.masterAdditionId) {
      this.masterAdditionMedianWageClient
        .setMedianWageCalculationForMasterAdditionGroup(this.masterAdditionId, this.wageCalculation)
        .subscribe({
          next: (calculatedWages) => {
            this.avaNotificationsService.success('Mittellohn gespeichert');
            this.wageCalculation = calculatedWages;
          },
          error: () => this.avaNotificationsService.error('Fehler beim Speichern des Mittellohns')
        });
    }
  }

  private calculateWageAdditions(): void {
    if (this.wageCalculation?.calculationWage) {
      if (this.laborSiteOperationCostsAddition) {
        this.laborSiteOperationCostsAdditionPart = this.wageCalculation.calculationWage * this.laborSiteOperationCostsAddition;

        if (this.laborCompanyOperationCostsAddition) {
          this.laborCompanyOperationCostsAdditionPart =
            this.wageCalculation.calculationWage *
              (1 + this.laborSiteOperationCostsAddition) *
              (1 + this.laborCompanyOperationCostsAddition) -
            this.wageCalculation.calculationWage -
            this.laborSiteOperationCostsAdditionPart;

          if (this.riskAndProfitAddition) {
            this.riskAndProfitAdditionPart =
              (this.wageCalculation.calculationWage +
                this.laborSiteOperationCostsAdditionPart +
                this.laborCompanyOperationCostsAdditionPart) *
              this.riskAndProfitAddition;
          }
        }
      }
    } else {
      this.laborSiteOperationCostsAdditionPart = 0;
      this.laborCompanyOperationCostsAdditionPart = 0;
      this.riskAndProfitAdditionPart = 0;
    }
  }
}
