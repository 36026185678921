import { PageQuantityTakeOffRowModel, PositionQuantityTakeOffRowModel } from 'app/generated-client/generated-client';

import { Injectable } from '@angular/core';

interface AllPositionQuantityTakeOffRowModel extends PositionQuantityTakeOffRowModel {
  avaPositionId?: string;
  itemNumber?: string;
  isTopic?: boolean;
}

type ItemQTOType = AllPositionQuantityTakeOffRowModel | PositionQuantityTakeOffRowModel | PageQuantityTakeOffRowModel;

@Injectable({
  providedIn: 'root'
})
export class ManageInvoiceTableService {
  constructor() {}

  setNullValueToFieldsDataSource(item: ItemQTOType, dataSource: ItemQTOType[]): ItemQTOType[] {
    item.projectFileFolderId = null;
    item.projectFileId = null;
    item.fileId = null;
    item.quantityTakeOffAttachmentId = null;
    item.quantityTakeOffAttachmentImageFileId = null;

    return dataSource;
  }

  addRow(
    nextRow: ItemQTOType,
    addAfter: boolean,
    isAddNewRows: boolean,
    dataSource: ItemQTOType[],
    newRows?: ItemQTOType[]
  ): ItemQTOType[] {
    const defaultRow = {
      fileId: null,
      formula: null,
      isBold: false,
      itemNumber: null,
      projectFileId: null,
      rowIndex: 0,
      shortText: null,
      sourceSrc: null,
      unitTag: null
    } as ItemQTOType;

    const newRow = newRows
      ? newRows.map(itemRow => ({ ...defaultRow, ...JSON.parse(JSON.stringify(itemRow)) } as ItemQTOType))
      : [defaultRow];

    let newDataSource: ItemQTOType[];
    let startAddPos: number;
    let addedNumber: number;
    if (addAfter) {
      if (isAddNewRows) {
        const listBefore = dataSource.filter(r => r.rowIndex <= nextRow.rowIndex).map(r => ({ ...r }));
        newDataSource = [...listBefore, ...newRow, ...dataSource.filter(r => r.rowIndex > nextRow.rowIndex).map(r => ({ ...r }))];
        startAddPos = listBefore.length;
        addedNumber = newRow.length;
      } else {
        const listBefore = dataSource.filter(r => r.rowIndex <= nextRow.rowIndex).map(r => ({ ...r }));
        const firstCount = listBefore.length + newRow.length;
        startAddPos = listBefore.length + newRow.length;
        addedNumber = 0;
        if (firstCount >= dataSource.length) {
          newDataSource = [...listBefore, ...newRow];
        } else {
          newDataSource = [...listBefore, ...newRow, ...dataSource.filter(r => r.rowIndex > firstCount).map(r => ({ ...r }))];
        }
      }
    } else {
      if (isAddNewRows) {
        const listBefore = dataSource.filter(r => r.rowIndex < nextRow.rowIndex).map(r => ({ ...r }));
        startAddPos = listBefore.length;
        addedNumber = newRow.length;
        newDataSource = [...listBefore, ...newRow, ...dataSource.filter(r => r.rowIndex >= nextRow.rowIndex).map(r => ({ ...r }))];
      } else {
        const listBefore = dataSource.filter(r => r.rowIndex < nextRow.rowIndex).map(r => ({ ...r }));
        startAddPos = listBefore.length + newRow.length;
        addedNumber = 0;
        const firstCount = listBefore.length + newRow.length;
        if (firstCount >= dataSource.length) {
          newDataSource = [...listBefore, ...newRow];
        } else {
          newDataSource = [...listBefore, ...newRow, ...dataSource.filter(r => r.rowIndex > firstCount).map(r => ({ ...r }))];
        }
      }
    }

    newDataSource.forEach((row: ItemQTOType, index: number) => {
      row.rowIndex = index + 1;
      this.changeRelatingNumber(row, startAddPos, addedNumber);
    });
    return newDataSource;
  }

  changeRelatingNumber(row: ItemQTOType, startPos: number, changedNumber: number, deletingListRowIndex?: number[]): void {
    if (row.formula?.includes('#')) {
      let formula = row.formula;
      const regExpr = /#[\d]*/g;
      let res = regExpr.exec(formula);
      while (res) {
        let relatedNumber = parseInt(res[0].slice(1));
        if (deletingListRowIndex && deletingListRowIndex.includes(relatedNumber)) {
          const relatedNumberString = `#!${relatedNumber}`;
          const firstPart = formula.slice(0, res.index) + relatedNumberString;
          formula = firstPart + formula.slice(regExpr.lastIndex);
          regExpr.lastIndex = firstPart.length;
        } else if (relatedNumber > startPos) {
          relatedNumber += changedNumber;
          const relatedNumberString = `#${relatedNumber}`;
          const firstPart = formula.slice(0, res.index) + relatedNumberString;
          formula = firstPart + formula.slice(regExpr.lastIndex);
          regExpr.lastIndex = firstPart.length;
        }
        res = regExpr.exec(formula);
      }
      row.formula = formula;
    }
  }
}
