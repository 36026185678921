import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { Router } from '@angular/router';

import { Observable, Subject, switchMap, take, takeUntil, zip } from 'rxjs';

import { AvaNotificationsService } from '@shared/services/ava-notifications.service';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';
import { FlatElementsService } from 'app/areas/tree/services/flat-elements.service';
import {
  PageQuantityTakeOffGet,
  PageQuantityTakeOffsClient,
  PositionDto,
  QuantityTakeOffCheckGet
} from 'app/generated-client/generated-client';
import { PositionTextPipe } from 'app/shared/pipes/ui-data-display/position-text.pipe';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

import { SelectedQuantityTakeOffMessengerService } from '../../services/messengers/selected-quantity-take-off-messenger.service';
import { filterElementsByIds } from '../../utilities/filter-elements-by-ids';

@Component({
  selector: 'pa-show-errors',
  standalone: true,
  imports: [CommonModule, FlexLayoutDirective, MatIconModule, MatListModule, PositionTextPipe],
  templateUrl: './show-errors.component.html',
  styleUrls: ['./show-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowErrorsComponent implements OnInit, OnDestroy {
  private $destroy: Subject<boolean> = new Subject<boolean>();
  errorsData: QuantityTakeOffCheckGet;
  positions: PositionDto[] = [];
  pageQtos: PageQuantityTakeOffGet[] = [];
  projectId: string;
  avaProjectId: string;
  elementId: string;

  constructor(
    @Optional() public dialogRef: MatDialogRef<ShowErrorsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private flatElementsService: FlatElementsService,
    private pageQuantityTakeOffsClient: PageQuantityTakeOffsClient,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private cdr: ChangeDetectorRef,
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private router: Router,
    private selectedQuantityTakeOffMessengerService: SelectedQuantityTakeOffMessengerService,
    private avaNotificationsService: AvaNotificationsService
  ) {
    this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(takeUntil(this.$destroy)).subscribe((s) => {
      this.projectId = s?.parentProjectId;
      this.avaProjectId = s?.avaProjectId;
    });
  }

  ngOnInit(): void {
    this.errorsData = this.data;
    if (this.errorsData && this.errorsData.positionErrors?.length) {
      this.flatElementsService.flatElementsDto.pipe(take(1)).subscribe((elements) => {
        const ids = this.errorsData.positionErrors.map((e) => e.avaPositionId);
        this.positions = filterElementsByIds(elements, ids);
      });
    }
    if (this.errorsData && this.errorsData.pageErrors?.length) {
      const observables = [];
      for (const er of this.errorsData.pageErrors) {
        const observable = this.makeRequest(er.pageId);
        observables.push(observable);
      }
      zip(...observables)
        .pipe(take(1))
        .subscribe((pages: PageQuantityTakeOffGet[]) => {
          this.pageQtos = [...pages];
          this.cdr.markForCheck();
        });
    }
    this.selectedSpecificationElementMessengerService.selectedElement
      .pipe(take(1))
      .subscribe((value: { id: string }) => (this.elementId = value?.id));
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private makeRequest(pageId: string): Observable<PageQuantityTakeOffGet> {
    return this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(
      take(1),
      switchMap((s) => {
        return this.pageQuantityTakeOffsClient.getPageQuantityTakeOffById(s.parentProjectId, s.avaProjectId, pageId);
      })
    );
  }

  handlerClickPage(page: PageQuantityTakeOffGet, errorRowIndex: number): void {
    const arrUrl = this.router.url.split('/').slice(0, 6);
    const quantityTakeOffId = this.errorsData.quantityTakeOffId;
    const nextPageId = page.id;
    const newArrUrl = [...arrUrl, quantityTakeOffId, 'pages', nextPageId];
    const isSameUrl = this.router.url === newArrUrl.join('/');

    this.router.navigate(newArrUrl);
    this.avaNotificationsService.info(`Fehler in Zeile #${errorRowIndex}`);
    this.dialogRef.close(true);
    setTimeout(() => {
      this.selectedQuantityTakeOffMessengerService.setChangedQuantityTakeOffPage(nextPageId);
      this.selectedQuantityTakeOffMessengerService.manualPageChangeRequested.next(true);
      this.selectedQuantityTakeOffMessengerService.setErrorRowIndex({ isSameUrl, errorRowIndex });
    }, 1);
  }

  handlerClick(pos?: PositionDto): void {
    if (this.errorsData.positionErrors.length) {
      const errorRowIndex = this.errorsData.positionErrors.filter((item) => item.avaPositionId === pos.id)[0].errorRowIndex;
      const arrUrl = this.router.url.split('/').slice(0, 6);
      const quantityTakeOffId = this.errorsData.quantityTakeOffId;
      const newArrUrl = [...arrUrl, quantityTakeOffId, 'positions'];
      const isSameUrl = this.router.url === newArrUrl.join('/') && this.elementId === pos.id;

      this.router.navigate(newArrUrl);
      this.avaNotificationsService.info(`Fehler in Zeile #${errorRowIndex}`);
      setTimeout(() => {
        this.selectedSpecificationElementMessengerService.trySelectElementById(pos.id);
        this.selectedQuantityTakeOffMessengerService.setErrorRowIndex({ isSameUrl, errorRowIndex });
      }, 1);
    }
    this.dialogRef.close(true);
  }
}
