import { Inject, Injectable, NgZone } from '@angular/core';
import { Subject, filter, fromEvent } from 'rxjs';

import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ClipboardTableDataService {
  private pastedData: string[][] = [];
  private tablePastedSource = new Subject<string[][]>();
  tablePasted$ = this.tablePastedSource.asObservable();
  private listeners = 0;
  private isPolling = false;
  private lastClipboardText: string | null = null;

  constructor(
    private ngZone: NgZone,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.setupClipboardPolling();

    // We're creating a global event that listens for
    // CTRL + V key presses. Then, we're calling a method that handles
    // the insertion from the clipboard.
    ngZone.runOutsideAngular(() => {
      fromEvent<KeyboardEvent>(document, 'keydown')
        .pipe(filter((event) => event.ctrlKey && event.key === 'v'))
        .subscribe((event) => {
          if (this.listeners > 0) {
            this.ngZone.runOutsideAngular(() => {
              this.handlePaste(event);
            });
          }
        });
    });
  }

  public announceListener(): void {
    this.listeners++;
  }

  public removeListener(): void {
    this.listeners--;
    if (this.listeners < 1) {
      this.clear();
    }
  }

  private clear(): void {
    this.pastedData = [];
  }

  public setupClipboardPolling(): void {
    if (this.isPolling) {
      return;
    }

    this.isPolling = true;

    this.ngZone.runOutsideAngular(async () => {
      setInterval(() => {
        this.ngZone.runOutsideAngular(async () => {
          if (this.document.hasFocus()) {
            try {
              const text = await navigator.clipboard.readText();
              this.formData(text);
            } catch (error) {
              console.error(error);
            }
          }
        });
      }, 100);
    });
  }

  public handlePaste(event?: KeyboardEvent) {
    if (this.checkData()) {
      event?.preventDefault();
      this.handlePastedTextAndReturnIfTable();
    } else {
      this.clear();
    }
  }

  private handlePastedTextAndReturnIfTable(): void {
    // That means it could be a table, so we're rasing an event
    this.ngZone.run(() => {
      this.tablePastedSource.next(this.pastedData);
    });
  }

  private formData(text: string): void {
    if (text === this.lastClipboardText || (text == null && this.lastClipboardText == null)) {
      return;
    }

    this.lastClipboardText = text;

    const clipboardText = text?.trimEnd();
    this.pastedData = [];

    if (text?.length > 0) {
      const lines = clipboardText.split(/\r\n?|\n/);
      lines.forEach((line) => {
        this.pastedData.push(line.split(/\t/));
      });
    }
  }

  public checkData(): number | null {
    if (!(this.pastedData?.length > 0)) {
      // If it's empty, is likely not a table.
      return null;
    }

    // Then we're checking if all the lines are the same length
    // (i.e. if it's a table).
    const lineLength = this.pastedData[0].length;
    const isTable = lineLength > 0 && this.pastedData.every((line) => line.length > 0);
    if (!isTable) {
      return null;
    }

    if (this.pastedData.length === 1 && this.pastedData[0].length === 1) {
      // It's just a single string then
      return null;
    }

    return this.pastedData.length;
  }
}
