<h1>Projekte</h1>

<mat-button-toggle-group value="Table" #group="matButtonToggleGroup">
  <mat-button-toggle value="Table">Tabelle</mat-button-toggle>
  <mat-button-toggle value="Cards">Kacheln</mat-button-toggle>
</mat-button-toggle-group>
<div class="buttons-global">
  <button mat-raised-button color="primary" routerLink="add-project">Neues Projekt</button>
</div>

<div fxLayoutGap="12px" class="project-filter">
  <mat-form-field fxFlex="1 0" class="project-text">
    <mat-label>Suche</mat-label>
    <input matInput #filter />
  </mat-form-field>
  <mat-form-field appearance="fill" class="project-select">
    <mat-label>Status</mat-label>
    <mat-select name="status" [(value)]="filterByStatus">
      <mat-option [value]="''">Alle</mat-option>
      <mat-option *ngFor="let item of listStatus" [value]="item">{{ item | projectStatus }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="project-select">
    <mat-label>Firma</mat-label>
    <mat-select name="company" [(value)]="filterByCompanyId">
      <mat-option [value]="''">Alle</mat-option>
      <mat-option *ngFor="let item of listCompany" [value]="item.id">{{ item.name }}</mat-option>
    </mat-select>
  </mat-form-field>
  <div>
    <mat-checkbox [(ngModel)]="showArchivedProjects" class="project-checkbox"> Archivierte Projekte anzeigen </mat-checkbox>
  </div>
</div>
<pa-projects-control
  [isCards]="group.value === 'Cards'"
  [filter]="filter.value"
  [filterStatus]="filterByStatus"
  [showArchivedProjects]="showArchivedProjects"
  [filterCompany]="filterByCompanyId"
  (selectRow)="selectProject($event)"
>
</pa-projects-control>
