import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatCheckboxChange, MatCheckbox } from '@angular/material/checkbox';
import { MatRipple } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTableDataSource,
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';

import { QuantityTakeOffCalculationType, QuantityTakeOffGet, QuantityTakeOffType } from 'app/generated-client/generated-client';
import { QuantityTakeOffsService } from 'app/shared/services/lightquery/quantity-take-offs.service';

import { UpperPaginatorComponent } from '../../../../../../../../shared/components/upper-paginator/upper-paginator.component';
import { QuantityTakeOffCalculationTypePipe } from '../../../../../../../../shared/pipes/ui-data-display/quantity-take-off-calculation-type.pipe';
import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-invoice-multi-select-qto-dialog',
  templateUrl: './invoice-multi-select-qto-dialog.component.html',
  styleUrls: ['./invoice-multi-select-qto-dialog.component.scss'],
  providers: [QuantityTakeOffsService],
  standalone: true,
  imports: [
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatRipple,
    MatCheckbox,
    MatSortHeader,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    UpperPaginatorComponent,
    FlexLayoutDirective,
    MatButton,
    AsyncPipe,
    DatePipe,
    QuantityTakeOffCalculationTypePipe
  ]
})
export class InvoiceMultiSelectQtoDialogComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) private sort: MatSort;
  columnsToDisplay = ['selectQto', 'name', 'calculationType', 'modifiedAtUtc'];
  selectedPages: QuantityTakeOffGet[] = [];
  dataSource: MatTableDataSource<QuantityTakeOffGet>;
  isChanged = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      selectedQto: QuantityTakeOffGet[];
      quantityTakeOffType: QuantityTakeOffType;
      calculationType: QuantityTakeOffCalculationType;
    },
    @Optional() public dialogRef: MatDialogRef<InvoiceMultiSelectQtoDialogComponent>,
    public quantityTakeOffsService: QuantityTakeOffsService
  ) {}

  ngOnInit(): void {
    if (this.data.selectedQto?.length) {
      this.selectedPages = [...this.data.selectedQto];
    }

    this.quantityTakeOffsService.initService({
      paging: { page: 1, pageSize: 5 },
      sorting: {
        propertyName: 'name',
        isDescending: true
      },
      sortTableObj: this.sort
    });
    this.quantityTakeOffsService.setQueryParameter('quantityTakeOffType', this.data.quantityTakeOffType);
    this.quantityTakeOffsService.setQueryParameter('calculationType', this.data.calculationType);
  }

  changeSelection(e: MatCheckboxChange, page: QuantityTakeOffGet): void {
    if (e.checked) {
      this.selectedPages.push(page);
    } else {
      this.selectedPages = this.selectedPages.filter((p) => p.id !== page.id);
    }
    this.isChanged = true;
  }

  getSelectedValue(id: string): boolean {
    return !!this.selectedPages?.find((v) => v.id === id);
  }

  saveSelection(): void {
    this.dialogRef.close(this.selectedPages);
  }

  dismiss(): void {
    this.dialogRef.close();
  }
}
