import { NgIf } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MatFormField, MatLabel, MatSuffix, MatHint } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTooltip } from '@angular/material/tooltip';

import { combineLatest, throwError } from 'rxjs';
import { filter, first, switchMap, take } from 'rxjs/operators';

import { InvoicePagesComponent } from '@serv-spec/components/invoice/components/invoice-pages/invoice-pages.component';
import { CheckErrorsAndOpenModalWindowService } from '@serv-spec/services/check-errors-and-open-modal-window.service';

import { QuantityTakeOffsService } from '@shared/services/lightquery/quantity-take-offs.service';
import { UserSettingsService } from '@shared/services/user-settings.service';
import { dateUTC } from '@shared/utilities/dateUTC';

import {
  ElementSelection,
  PageQuantityTakeOffOverviewGet,
  QuantityTakeOffBillDatePut,
  QuantityTakeOffCalculationType,
  QuantityTakeOffGet,
  QuantityTakeOffsClient,
  ReportsClient,
  ServiceSpecificationGroupDto,
  TextTemplateType,
  TextTemplatesClient,
  UserSettings
} from 'app/generated-client/generated-client';
import { ShowErrorsComponent } from 'app/shared/components/show-errors/show-errors.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { SelectedQuantityTakeOffMessengerService } from 'app/shared/services/messengers/selected-quantity-take-off-messenger.service';

import { AvaNotificationsService } from '../../../../../../../../shared/services/ava-notifications.service';
import { PrintViewMessengerService } from '../../../../../../../../shared/services/electron/print-view-messenger.service';
import { SelectedSpecificationMessengerService } from '../../../../../../../../shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from '../../../../../../../../shared/services/modal.service';
import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';
import { FlatElementsService } from '../../../../../../../tree/services/flat-elements.service';
import { SelectingElementButtonsComponent } from '../../../selecting-element-buttons/selecting-element-buttons.component';

import { InvoiceTextSelectionComponent } from '../invoice-text-selection/invoice-text-selection.component';

@Component({
  selector: 'pa-invoice-qto-export-dialog',
  templateUrl: './invoice-qto-export-dialog.component.html',
  styleUrls: ['./invoice-qto-export-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    NgIf,
    FlexLayoutDirective,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatSuffix,
    SelectingElementButtonsComponent,
    MatButton,
    MatHint,
    MatCheckbox,
    MatTooltip,
    MatProgressSpinner,
    MatDialogActions
  ]
})
export class InvoiceQtoExportDialogComponent implements OnInit {
  CORRECT_LINE = 'correctLine';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      avaProjectId: string;
      projectId: string;
      qto: QuantityTakeOffGet;
    },
    @Optional() public dialogRef: MatDialogRef<InvoiceQtoExportDialogComponent>,
    private reportsClient: ReportsClient,
    private printViewMessengerService: PrintViewMessengerService,
    private avaNotificationsService: AvaNotificationsService,
    private modalService: ModalService,
    private selectedQuantityTakeOffMessengerService: SelectedQuantityTakeOffMessengerService,
    private textTemplatesClient: TextTemplatesClient,
    private flatElementsService: FlatElementsService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private checkErrorsAndOpenModalWindowService: CheckErrorsAndOpenModalWindowService,
    private quantityTakeOffsClient: QuantityTakeOffsClient,
    private quantityTakeOffsService: QuantityTakeOffsService,
    private userSettingsService: UserSettingsService
  ) {}

  public showingPdf = false;
  isLoading = false;

  invoiceName: string;
  reportName: string;
  taxRate = 19;
  elementSelection: ElementSelection = null;
  extraMarginAfterPositionLinesInMm = 0;
  htmlOpeningText: string = null;
  htmlClosingText: string = null;
  pagesSelection: PageQuantityTakeOffOverviewGet[] | null = null;
  calculationType: QuantityTakeOffCalculationType;
  includePaymentTerms = true;
  includeHeaderOnlyOnFirstPage = false;
  includePreviousQuantities = true;
  hasAbsoluteDeduction = false;
  includeAbsoluteDeduction = false;
  invoiceNumber = '';
  percentageWithholding: number;
  currentUserSettings: UserSettings;
  markAsBilledAfterPrint = false;

  ngOnInit(): void {
    this.reportName = this.data?.qto?.name;
    this.invoiceName = this.data?.qto?.number + '. Rechnung';
    this.calculationType = this.data?.qto?.calculationType;

    if (this.data?.projectId && this.data?.avaProjectId) {
      this.textTemplatesClient
        .getLastUsedHtmlTextForAvaProject(this.data.projectId, this.data.avaProjectId, TextTemplateType.InvoiceStart)
        .subscribe({
          next: (text) => (this.htmlOpeningText = text.htmlText)
        });
      this.textTemplatesClient
        .getLastUsedHtmlTextForAvaProject(this.data.projectId, this.data.avaProjectId, TextTemplateType.InvoiceEnd)
        .subscribe({
          next: (text) => (this.htmlClosingText = text.htmlText)
        });
    }

    this.userSettingsService.currentUserSettings.pipe(take(1)).subscribe((setting: UserSettings) => {
      this.currentUserSettings = setting;
      this.markAsBilledAfterPrint = this.currentUserSettings.markInvoicesAutomaticallyAsBilledAfterExport;
    });

    this.checkIfAbsoluteDeductionPresent();
  }

  private checkIfAbsoluteDeductionPresent(): void {
    combineLatest([
      this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(filter((s) => s?.avaProject != null)),
      this.flatElementsService.flatElementsDto.pipe(filter((f) => f != null))
    ])
      .pipe(first())
      .subscribe(([specification, flatElements]) => {
        const hasAbsoluteDeductionInServSpec = specification.project.serviceSpecifications[0].absoluteDeduction != null;
        if (hasAbsoluteDeductionInServSpec) {
          this.hasAbsoluteDeduction = true;
          return;
        }

        for (let i = 0; i < flatElements.length; i++) {
          const element = flatElements[i];
          if (element.elementType == 'ServiceSpecificationGroupDto') {
            const group = element as ServiceSpecificationGroupDto;
            if (group.absoluteDeduction != null) {
              this.hasAbsoluteDeduction = true;
              return;
            }
          }
        }
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  selectOpeningText(): void {
    this.modalService
      .openModal(InvoiceTextSelectionComponent, {
        dialogType: ConfirmationType.General,
        data: {
          htmlText: this.htmlOpeningText
        }
      })
      .afterClosed()
      .subscribe((htmlOpeningText) => {
        this.htmlOpeningText = htmlOpeningText;
      });
  }

  selectClosingText(): void {
    this.modalService
      .openModal(InvoiceTextSelectionComponent, {
        dialogType: ConfirmationType.General,
        data: {
          htmlTexT: this.htmlClosingText
        }
      })
      .afterClosed()
      .subscribe((htmlClosingText) => {
        this.htmlClosingText = htmlClosingText;
      });
  }

  selectPages(): void {
    this.selectedQuantityTakeOffMessengerService.setSelectedQuantityTakeOff(this.data?.qto);
    this.selectedQuantityTakeOffMessengerService.setSelectedQuantityTakeOffPage(this.data.qto.id);
    this.modalService
      .openModal(InvoicePagesComponent, {
        dialogType: ConfirmationType.General,
        data: {
          iSModalMode: true,
          pagesSelection: this.pagesSelection
        },
        autoFocus: false,
        restoreFocus: false
      })
      .afterClosed()
      .subscribe((pages) => {
        this.pagesSelection = pages?.length ? pages : null;
        this.selectedQuantityTakeOffMessengerService.setSelectedQuantityTakeOff(null);
        this.selectedQuantityTakeOffMessengerService.setSelectedQuantityTakeOffPage(null);
      });
  }

  generatePdfAndShowPreview(): void {
    this.isLoading = true;

    const markAsBilledAfterPrint = this.markAsBilledAfterPrint;
    const pagesToPrint: string[] = this.pagesSelection && this.pagesSelection.length ? this.pagesSelection.map((p) => p.id) : null;
    const errorRequest = this.quantityTakeOffsClient.checkQuantityTakeOffCalculations(
      this.data.projectId,
      this.data.avaProjectId,
      this.data.qto.id
    );
    this.checkErrorsAndOpenModalWindowService
      .checkErrorsAndOpenModalWindow(errorRequest, ShowErrorsComponent, {
        dialogType: ConfirmationType.General,
        disableClose: false
      })
      .pipe(
        switchMap((e) => {
          if (e) {
            return throwError(() => new Error(this.CORRECT_LINE));
          }
          return this.reportsClient.getInvoiceReportForQuantityTakeOff(
            this.data.avaProjectId,
            this.data.qto.id,
            true,
            this.includeHeaderOnlyOnFirstPage,
            {
              invoiceName: this.invoiceName,
              reportName: this.reportName,
              taxRate: this.taxRate * 0.01,
              elementSelection: this.elementSelection,
              extraMarginAfterPositionLinesInMm: this.extraMarginAfterPositionLinesInMm,
              htmlClosingText: this.htmlClosingText,
              htmlOpeningText: this.htmlOpeningText,
              pagesToPrint: pagesToPrint,
              includePaymentTerms: this.includePaymentTerms,
              includePreviousQuantities: this.includePreviousQuantities,
              includeAbsoluteDeduction: this.includeAbsoluteDeduction,
              invoiceNumber: this.invoiceNumber,
              securityWitholdingRate: this.percentageWithholding
            }
          );
        })
      )
      .subscribe({
        next: (report) => {
          this.printViewMessengerService.showPdfPreview(
            report.htmlReport,
            this.invoiceName,
            report.isLandscape,
            report.pdfReportBase64,
            this.includeHeaderOnlyOnFirstPage
          );
          this.printViewMessengerService.waitForPrintViewReady().subscribe(() => {
            this.isLoading = false;
            this.showingPdf = true;
          });
          this.printViewMessengerService.printViewClosed.pipe(first()).subscribe(() => {
            this.showingPdf = false;
            this.isLoading = false;
          });
          if (markAsBilledAfterPrint) {
            this.markInvoiceAsBilledAndRefreshTable();
          }
        },
        error: (error) => {
          this.isLoading = false;
          if (error.message === this.CORRECT_LINE) {
            this.closeDialog();
          } else {
            this.avaNotificationsService.error('Fehler beim Erstellen der Rechnung');
          }
        }
      });
  }

  private markInvoiceAsBilledAndRefreshTable(): void {
    if (this.data.qto?.markedAsBilledByUserId) {
      return;
    }

    const quantityTakeOffBillDatePut: QuantityTakeOffBillDatePut = { billedAtUtc: dateUTC(new Date()) };
    this.quantityTakeOffsClient
      .markQuantityTakeOffAsBilled(this.data.projectId, this.data.avaProjectId, this.data.qto.id, quantityTakeOffBillDatePut)
      .subscribe(() => this.quantityTakeOffsService.forceRefresh());
  }
}
