<pa-table-wrapper [offsetFromMaxHeightTable]="56" measure="%">
  <table class="project-table" mat-table [dataSource]="projectsService" matSort (matSortChange)="projectsService.onSort($event)">
    <ng-container matColumnDef="identicon">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" matRipple>
        <img class="project-icon" [diIcon]="row.identiconId" [rectangleSize]="48" />
      </td>
    </ng-container>
    <ng-container matColumnDef="locking">
      <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row; let index = index" matRipple>
        <pa-lock-icon [project]="row"></pa-lock-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="constructedProjectNumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Nummer</th>
      <td mat-cell *matCellDef="let row" matRipple>
        {{ row.constructedProjectNumber }}
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let row" matRipple (contextmenu)="showContextMenu($event, row)">{{ row.name }}</td>
    </ng-container>
    <ng-container matColumnDef="contactName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Kontakt</th>
      <td mat-cell *matCellDef="let row" matRipple>{{ row.contactName }}</td>
    </ng-container>
    <ng-container matColumnDef="tendererContactName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Ausschreibende Stelle</th>
      <td mat-cell *matCellDef="let row" matRipple>{{ row.tendererContactName }}</td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let row" matRipple>{{ row.status | projectStatus }}</td>
    </ng-container>
    <ng-container matColumnDef="companyName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Firma</th>
      <td mat-cell *matCellDef="let row" matRipple>{{ row.companyName }}</td>
    </ng-container>
    <ng-container matColumnDef="costCenter">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Kostenstelle</th>
      <td mat-cell *matCellDef="let row" matRipple>{{ row.costCenter }}</td>
    </ng-container>
    <ng-container matColumnDef="totalPrice">
      <th mat-header-cell *matHeaderCellDef>Angebotssumme</th>
      <td mat-cell *matCellDef="let row" matRipple>{{ totalPrices[row.id] | currency: 'EUR' }}</td>
    </ng-container>
    <ng-container matColumnDef="lastAccessedAtUtc">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Zuletzt angesehen</th>
      <td mat-cell *matCellDef="let row" matRipple>
        {{ row.lastAccessedAtUtc | date: 'dd.MM.yyyy HH:mm' }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="selectProject(row)"
      [ngClass]="{ 'selected-row': selectedProject?.id === row.id && isContextMenu }"
    ></tr>
  </table>
</pa-table-wrapper>

<pa-upper-paginator
  *ngIf="projectsPaginated"
  [pageSizeOptions]="pageSizeOptions"
  [upperPaginated]="projectsPaginated"
  [upperService]="projectsService"
  [isSmaller]="isContextMenu"
  (page)="projectsService.onPage($event)"
>
</pa-upper-paginator>

<mat-menu #rowContextMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="importPositions(item)">Alle Kalkulationen Importieren</button>
  </ng-template>
</mat-menu>
<div
  style="position: fixed; visibility: hidden"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="rowContextMenu"
></div>
