import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatButtonToggleGroup, MatButtonToggle } from '@angular/material/button-toggle';
import { MatCheckbox } from '@angular/material/checkbox';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProjectGet, ProjectSettings, TreeViewDisplayType } from 'app/generated-client/generated-client';
import { GroupViewService } from 'app/shared/services/group-view.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { UserSettingsService } from 'app/shared/services/user-settings.service';

import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-project-settings',
  templateUrl: './project-settings.component.html',
  styleUrls: ['./project-settings.component.scss'],
  standalone: true,
  imports: [NgIf, FlexLayoutDirective, MatCheckbox, FormsModule, MatButtonToggleGroup, MatButtonToggle, MatButton]
})
export class ProjectSettingsComponent implements OnInit {
  currentProjectSettings: ProjectSettings;
  isChanged: boolean;
  groupView: TreeViewDisplayType;

  private $destroy = new Subject<boolean>();

  constructor(
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private userSettingsService: UserSettingsService,
    private groupViewService: GroupViewService
  ) {}

  ngOnInit(): void {
    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((p: ProjectGet) => {
      if (p) {
        this.getProjectSettings(p?.id);
      }
    });
    this.groupViewService.projectGroupView.pipe(takeUntil(this.$destroy)).subscribe((e) => {
      this.groupView = e;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  getProjectSettings(projectId): void {
    this.userSettingsService
      .getProjectSettings(projectId)
      .pipe(takeUntil(this.$destroy))
      .subscribe((e: ProjectSettings) => {
        if (e) {
          if (!e.treeViewDisplayType) {
            e.treeViewDisplayType = TreeViewDisplayType.Tree;
          }

          this.currentProjectSettings = e;
        }
      });
  }

  changeSettings(): void {
    this.userSettingsService.setProjectSettings(this.currentProjectSettings);
    this.isChanged = false;
  }

  changeGroupView(groupView: TreeViewDisplayType): void {
    this.groupViewService.setGroupView(groupView);
  }
}
