import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LvEditorService } from '@serv-spec/services/lv-editor.service';

import { IElementDto } from 'app/generated-client/generated-client';

import { LvEditorElementComponent } from '../lv-editor-element/lv-editor-element.component';

@Component({
  selector: 'pa-editor-element-modal',
  templateUrl: './editor-element-modal.component.html',
  styleUrls: ['./editor-element-modal.component.scss'],
  standalone: true,
  imports: [MatDialogContent, LvEditorElementComponent]
})
export class EditorElementModalComponent implements OnInit {
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IElementDto,
    @Optional() private matDialogRef: MatDialogRef<EditorElementModalComponent>,
    private lvEditorService: LvEditorService
  ) {}

  ngOnInit(): void {
    this.lvEditorService.editMode.pipe(takeUntil(this.$destroy)).subscribe((e: boolean) => {
      if (!e) {
        this.matDialogRef.close();
      }
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }
}
