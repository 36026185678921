import { Component } from '@angular/core';

import { PriceComponentType } from 'app/generated-client/generated-client';

import { MasterDataProductsComponent } from '../master-data-products/master-data-products.component';

@Component({
  selector: 'pa-casing',
  templateUrl: './casing.component.html',
  styleUrls: ['./casing.component.scss'],
  standalone: true,
  imports: [MasterDataProductsComponent]
})
export class CasingComponent {
  priceComponentType = PriceComponentType.Casing;
}
