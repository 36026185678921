<as-split
  fxFlexFill
  #asSplitRightRef
  (dragEnd)="onDragEnd($event.sizes, 'AS_SPLIT_TREE_COPY_RIGHT_SIZE')"
  (mouseenter)="enterMouse()"
  (mouseleave)="tryLeave()"
>
  <as-split-area [size]="20">
    <div *ngIf="serviceSpecification" fxFlexFill>
      <pa-main-tree
        [showedAvaProject]="serviceSpecification"
        [isInnerWindow]="true"
        [isSeparate]="true"
        [isCopyCalculation]="true"
        (dataForCopy)="getSourceForCopy($event)"
      ></pa-main-tree>
    </div>
  </as-split-area>
  <as-split-area [size]="80">
    <as-split direction="vertical" #asSplitPosDivider (dragEnd)="saveSizePositionDetailDivider($event.sizes)">
      <as-split-area [size]="40">
        <as-split
          direction="vertical"
          #topPartOfmainRightSideSplitArea
          (dragEnd)="onDragEnd($event.sizes, 'AS_SPLIT_COPY_CALC_PROJECTS_DIVIDER')"
        >
          <as-split-area [size]="40">
            <div class="wrapper top-part" #scrollEl [class.top-part-hidden]="isTopPart">
              <div style="height: 100%">
                <div fxLayoutGap="12px" class="project-filter">
                  <mat-form-field fxFlex="1 0 25">
                    <mat-label>Projektnummer</mat-label>
                    <input matInput [(ngModel)]="filterProjectNumber" (ngModelChange)="beginProject()" />
                    <button [style.opacity]="filterProjectNumber ? '1' : '0'" matSuffix mat-icon-button (click)="filterProjectNumber = ''">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                  <mat-form-field fxFlex="1 0 25">
                    <mat-label>Auftraggeber</mat-label>
                    <input matInput [(ngModel)]="filterContactName" (ngModelChange)="beginProject()" />
                    <button [style.opacity]="filterContactName ? '1' : '0'" matSuffix mat-icon-button (click)="filterContactName = ''">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                  <mat-form-field fxFlex="1 0">
                    <mat-label>Suche</mat-label>
                    <input matInput [(ngModel)]="filterProject" (ngModelChange)="beginProject()" />
                    <button [style.opacity]="filterProject ? '1' : '0'" matSuffix mat-icon-button (click)="filterProject = ''">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                  <mat-form-field appearance="fill" class="project-select">
                    <mat-label>Firma</mat-label>
                    <mat-select name="company" [(ngModel)]="filterByCompanyId" (ngModelChange)="beginProject()">
                      <mat-option [value]="''">Alle</mat-option>
                      <mat-option *ngFor="let item of listCompany" [value]="item.id">{{ item.name }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <pa-projects-control
                  [filter]="filterProject"
                  [filterProjectNumber]="filterProjectNumber"
                  [filterContactName]="filterContactName"
                  [filterCompany]="filterByCompanyId"
                  [isInnerWindow]="true"
                  (selectRow)="selectProject($event)"
                  (getContextMenu)="runContextMenu($event)"
                >
                </pa-projects-control>
              </div>
            </div>
          </as-split-area>
          <as-split-area [size]="40">
            <div class="wrapper top-part">
              <div *ngIf="selectedProject && !selectedAvaProject" class="mat-elevation-z8">
                <pa-service-specifications-table-flexible [projectId]="selectedProject.id" (selectRow)="selectAvaProject($event)">
                </pa-service-specifications-table-flexible>
              </div>
              <div *ngIf="selectedAvaProject && numberOfAvaProjectsForProject > 1" class="selected">
                <div class="selected__text">Ausgewähltes Leistungsverzeichnis - {{ selectedAvaProject.name }}</div>
                <button mat-raised-button color="warn" type="button" (click)="beginAvaProject()">Abbrechen</button>
              </div>
              <div *ngIf="selectedGroup" class="selected">
                <div class="selected__text">
                  Gruppe - {{ selectedGroup.itemNumber.stringRepresentation }} - {{ selectedGroup.shortText }}
                </div>
                <button mat-raised-button color="warn" type="button" (click)="beginGroup()">Abbrechen</button>
              </div>
            </div>
            <ng-container *ngIf="positionsList.length && selectedProject && selectedAvaProject">
              <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="start center" class="position__filter">
                <mat-form-field>
                  <mat-label>Suche</mat-label>
                  <input matInput [(ngModel)]="filterPosition" (ngModelChange)="beginPositions()" />
                </mat-form-field>
                <mat-checkbox [(ngModel)]="isLongText" *ngIf="!elementGroupId">Langtext</mat-checkbox>
                <mat-checkbox [(ngModel)]="isSearchAll" *ngIf="!elementGroupId">Alle Positionen</mat-checkbox>
                <div class="multi-select" *ngIf="multiSelectPosition?.length" fxLayoutAlign="start center">
                  <button mat-raised-button color="warn" (click)="clearMultiSelect()">
                    <span>({{ multiSelectPosition?.length }})</span>
                    Positionsauswahl <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>
              <pa-positions-table
                [limitHeight]="!selectedGroup ? 90 : 145"
                [filter]="filterPosition"
                [isLongText]="isLongText"
                [isSearchAll]="isSearchAll"
                [dataSource]="positionsList"
                [allPositions]="allPositionsListAvaProject"
                (selectRow)="selectPositionPlus($event)"
                (copyRow)="copyPosition($event)"
                [activeId]="selectedPosition?.id"
                [multiSelectPosition]="multiSelectPosition"
                (selectRowMulti)="selectPositionMulti($event)"
                *ngIf="!elementGroupId"
              >
              </pa-positions-table>
              <pa-positions-table
                [limitHeight]="90"
                [filter]="filterPosition"
                [dataSource]="groupsList"
                (selectRow)="selectGroupForCopy($event)"
                [activeId]="selectedPosition?.id"
                *ngIf="elementGroupId"
              >
              </pa-positions-table>
            </ng-container>
          </as-split-area>
        </as-split>
      </as-split-area>
      <as-split-area [size]="60" #bottomPartOfmainRightSideSplitArea>
        <mat-tab-group *ngIf="positionsList.length && !elementGroupId" class="project-tab">
          <mat-tab label="Kalkulation" #tabCalc>
            <div class="calculation__wrapper">
              <div *ngIf="positionCalculation && tabCalc.isActive">
                <div class="calculation__table">
                  <pa-calculation
                    [positionCalculationData]="positionCalculation"
                    [isLoadingOutside]="isLoading"
                    [isInnerWindow]="true"
                    [isReadOnly]="true"
                    [isHideReadOnlyMark]="true"
                  >
                  </pa-calculation>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Langtext">
            <div class="calculation__text">
              <div [innerHTML]="selectedPosition.htmlLongText | safeHtml" *ngIf="positionCalculation && selectedPosition"></div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </as-split-area>
    </as-split>
  </as-split-area>
</as-split>
