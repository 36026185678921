import { Injectable, inject } from '@angular/core';

import { SubPositionNestingLevelService } from '@serv-spec/services/sub-position-nesting-level.service';

import { SUBPOSITION_DEFAULT_COLORS } from '@shared/constants/constants';

import { CalculationEntry } from 'app/generated-client/generated-client';

@Injectable({
  providedIn: 'root'
})
export class SubpositionsForCurrentPositionService {
  private subPositionNestingLevelService = inject(SubPositionNestingLevelService);
  constructor() {}

  getSubpositionForCurrentPosition(row: CalculationEntry, entries: CalculationEntry[]): CalculationEntry[] {
    if (!row) {
      return [];
    }

    if (entries.length === 0) {
      return [];
    }
    const nestingLevel = this.subPositionNestingLevelService.getNestingLevel(row.subPositionIdentifier, SUBPOSITION_DEFAULT_COLORS);
    const indexOfElementWhichSubpositionNotInclude = entries.find((e) => {
      if (
        e.rowIndex > row.rowIndex &&
        e.subPositionIdentifier &&
        this.subPositionNestingLevelService.getNestingLevel(e.subPositionIdentifier, SUBPOSITION_DEFAULT_COLORS) <= nestingLevel
      ) {
        return e;
      }
    })?.rowIndex;
    if (!indexOfElementWhichSubpositionNotInclude) {
      return entries.slice(row.rowIndex - 1);
    }
    const rows = entries.filter((e) => e.rowIndex >= row.rowIndex && e.rowIndex < indexOfElementWhichSubpositionNotInclude);
    return rows;
  }
}
