import { NgIf, NgFor } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

import { Subject, takeUntil } from 'rxjs';

import { IfcSpatialStructure, IfcStructure } from '../../../../generated-client/generated-client';
import { BimShowViewService } from '../../services/bim-show-view.service';
import { BimStateService } from '../../services/bim-state.service';
import { BimStructureService } from '../../services/bim-structure.service';
import { BimViewService } from '../../services/bim-view.service';

@Component({
  selector: 'pa-structure-item-bim-view',
  templateUrl: './structure-item-bim-view.component.html',
  styleUrls: ['./structure-item-bim-view.component.scss'],
  standalone: true,
  imports: [MatIcon, NgIf, NgFor]
})
export class StructureItemBimViewComponent implements OnInit {
  @Input() structureItem: IfcSpatialStructure;
  listNames: { [key: string]: string };
  isShowChildren = false;
  isShowMode = false;
  selectedElement: number | null = null;
  showedElements: number[] | null = null;
  checkedGroup = false;
  indeterminateGroup = false;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private bimViewService: BimViewService,
    private bimStateService: BimStateService,
    private bimShowViewService: BimShowViewService,
    private bimStructureService: BimStructureService
  ) {}

  ngOnInit(): void {
    this.bimStateService.selectedElement.pipe(takeUntil(this.$destroy)).subscribe((e) => (this.selectedElement = e));
    this.bimViewService.structure.pipe(takeUntil(this.$destroy)).subscribe((structure: IfcStructure) => {
      this.listNames = structure.namesByEntityId;
    });

    this.bimStateService.showedElements.pipe(takeUntil(this.$destroy)).subscribe((e: number[] | null) => {
      this.showedElements = e;
      this.checkShowedGroup();
    });
    this.bimShowViewService.visibleStructureMode.pipe(takeUntil(this.$destroy)).subscribe((e) => (this.isShowMode = e));
    this.bimStructureService.openAll.pipe(takeUntil(this.$destroy)).subscribe((isOpen: boolean) => (this.isShowChildren = isOpen));
    this.checkShowedGroup();
  }

  selectElement(id: number): void {
    this.bimStateService.setSelectedElement(id);
    this.bimViewService.highLight(id);
  }

  toggleShowedElement(event: MouseEvent, value: boolean, id: number): void {
    event.stopPropagation();
    this.bimShowViewService.visibleSomeElements(value, [id]);
  }

  checkShowedGroup(): void {
    this.indeterminateGroup = false;
    if (!this.showedElements) {
      this.checkedGroup = true;
    } else {
      const listId = this.bimShowViewService.getEntityIds(this.structureItem);
      this.checkedGroup = listId.every((itemId) => this.showedElements.includes(itemId));
      if (!this.checkedGroup) {
        this.indeterminateGroup = listId.some((itemId) => this.showedElements.includes(itemId));
      }
    }
  }

  toggleShowedGroup(event: MouseEvent, value: boolean): void {
    event.stopPropagation();
    const listId = this.bimShowViewService.getEntityIds(this.structureItem);
    this.bimShowViewService.visibleSomeElements(value, listId);
  }
}
