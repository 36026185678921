import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionList, MatListOption } from '@angular/material/list';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { PositionDto } from 'app/generated-client/generated-client';

import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-duplicate-item-number-list',
  templateUrl: './duplicate-item-number-list.component.html',
  styleUrls: ['./duplicate-item-number-list.component.scss'],
  standalone: true,
  imports: [CommonModule, FlexLayoutDirective, MatProgressSpinner, MatButton, MatSelectionList, MatListOption]
})
export class DuplicateItemNumberListComponent implements OnInit {
  isLoading = false;
  duplicateItemNumber: PositionDto[];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PositionDto[],
    @Optional() private dialogRef: MatDialogRef<DuplicateItemNumberListComponent>
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.duplicateItemNumber = this.data;
  }

  selectedPosition(position: PositionDto): void {
    this.dialogRef.close(position);
  }

  close(): void {
    this.dialogRef.close();
  }
}
