import { Pipe, PipeTransform } from '@angular/core';

import { PriceTypeDto } from 'app/generated-client/generated-client';

@Pipe({
  name: 'priceType',
  standalone: true
})
export class PriceTypePipe implements PipeTransform {
  transform(value: PriceTypeDto): string {
    switch (value) {
      case PriceTypeDto.WithTotal:
        return 'Mit GP';
      case PriceTypeDto.WithoutTotal:
        return 'Ohne GP';
    }
    return null;
  }
}
