import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

import { Subject } from 'rxjs';

import { PositionDto } from 'app/generated-client/generated-client';

import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

export interface SubcontractorsSetPriceData {
  companyName: string;
  position: PositionDto;
  unitPrice?: number;
}

@Component({
  selector: 'pa-subcontractors-set-price',
  templateUrl: './subcontractors-set-price.component.html',
  styleUrls: ['./subcontractors-set-price.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    FlexLayoutDirective,
    MatButton
  ]
})
export class SubcontractorsSetPriceComponent implements OnInit, OnDestroy {
  private $destroy: Subject<boolean> = new Subject<boolean>();
  priceForm: FormGroup;
  constructor(
    @Optional() public matDialogRef: MatDialogRef<SubcontractorsSetPriceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SubcontractorsSetPriceData
  ) {}

  ngOnInit(): void {
    this.priceForm = new FormGroup({
      price: new FormControl(this.data.unitPrice, (c) => Validators.required(c))
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  cancelPriceSetting(): void {
    this.matDialogRef.close();
  }

  setPrice(): void {
    const unitPrice = this.priceForm.value.price != null ? this.priceForm.value.price : null;
    this.matDialogRef.close(unitPrice);
  }
}
