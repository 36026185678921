<div class="header-wrap" #headerWrap>
  <h1>Projekt Erstellen</h1>
</div>
<div class="stepper-wrap" [style.height]="sanitizer.bypassSecurityTrustStyle('calc(100% - ' + headerWrap.offsetHeight + 'px)')">
  <mat-vertical-stepper [linear]="true" #stepper>
    <mat-step>
      <ng-template matStepLabel>Datenübernahme</ng-template>
      <p>Du kannst Daten direkt aus einer GAEB oder ÖNorm Datei übernehmen, um das Projekt anzulegen.</p>
      <input hidden type="file" (change)="onFileSelected(fileInput.files)" #fileInput />
      <button mat-button mat-raised-button (click)="fileInput.click()" *ngIf="!performingAvaInspection && !avaFileInspectionResult">
        LV Datei Auswählen
      </button>
      <div *ngIf="selectedFile">
        <p>
          <span>Dateiname: </span><strong>{{ selectedFile.name }}</strong>
        </p>
      </div>
      <div *ngIf="avaFileInspectionResult">
        <p>Dateityp: {{ avaFileInspectionResult.origin | originDto }}</p>
        <p>Bereit zur Datenübernahme!</p>
        <ng-container *ngIf="avaFileInspectionResult?.containsPrices">
          <p>In der Datei sind Preise vorhanden, sollen diese in die Kalkulation des neuen LVs übernommen werden?</p>
          <mat-checkbox [(ngModel)]="includePricesFromAvaFile">Preise übernehmen</mat-checkbox>
          <mat-checkbox style="padding-left: 0.5rem" [(ngModel)]="importPricesAsFixedPrices" [disabled]="!includePricesFromAvaFile"
            >Als Festpreise</mat-checkbox
          >
        </ng-container>
        <div>
          <mat-checkbox [(ngModel)]="enableSupportForSkippedTiersInItemNumberSchema"
            >Ausgelassene Hierarchiestufen zulassen (falls in OZ vorhanden)</mat-checkbox
          >
        </div>
      </div>
      <div *ngIf="fileCouldNotBeRead">
        <p class="warning-text">Die Datei konnte nicht gelesen werden.</p>
      </div>
      <div fxFlexFill fxLayoutAlign="center center" *ngIf="performingAvaInspection">
        <mat-spinner></mat-spinner>
      </div>
      <div class="stepper-actions">
        <ng-container>
          <button mat-raised-button color="primary" matStepperNext *ngIf="!avaFileInspectionResult">Weiter ohne Datei</button>
          <button mat-raised-button color="primary" matStepperNext *ngIf="avaFileInspectionResult">Weiter mit Datei</button>
        </ng-container>
      </div>
    </mat-step>
    <mat-step [stepControl]="projectDataFormGroup">
      <form [formGroup]="projectDataFormGroup">
        <ng-template matStepLabel>Projektdaten</ng-template>
        <div>
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Projektname</mat-label>
            <input matInput formControlName="name" placeholder="Projektname" required />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Projektbeschreibung</mat-label>
            <input matInput formControlName="description" placeholder="Kurzbeschreibung des Projekts" />
          </mat-form-field>
        </div>
        <div>
          <p>
            Projektnummer
            <br />
            <i>Optional, wird automatisch erstellt</i>
          </p>
          <mat-form-field appearance="fill" class="project-field">
            <mat-label>Projektnummer</mat-label>
            <input matInput formControlName="manualProjectNumber" />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="fill" *ngIf="allTaxRates" class="project-field">
            <mat-label>Mehrwertsteuersatz</mat-label>
            <mat-select (ngModelChange)="selectTaxRate($event)" formControlName="selectedTaxRate">
              <mat-option *ngFor="let taxRate of allTaxRates" [value]="taxRate">
                {{ taxRate.country }} - {{ taxRate.rate | percent: '1.2-2' }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field appearance="fill" class="project-field">
          <mat-label>Mehrwertsteuersatz</mat-label>
          <input matInput type="number" placeholder="Mehrwertsteuersatz" formControlName="projectTaxRate" />
          <span matSuffix>%</span>
        </mat-form-field>

        <div fxLayoutGap="12px">
          <button mat-raised-button matStepperPrevious>
            <mat-icon class="back-arrow">arrow_upward</mat-icon>
            Zurück
          </button>
          <button
            mat-raised-button
            color="primary"
            matStepperNext
            [disabled]="!projectDataFormGroup.valid || projectDataFormGroup.value.projectTaxRate < 0"
          >
            Weiter
          </button>
        </div>
      </form>
    </mat-step>
    <ng-container>
      <mat-step [completed]="!!selectedCompany">
        <ng-template matStepLabel>Firma</ng-template>
        <p>Zu welcher Firma soll das Projekt zugeordnet werden?</p>
        <pa-company-list [isInProjectCreation]="true" (selectedCompany)="setSelectedCompany($event)"></pa-company-list>
        <ng-container *ngIf="selectedCompany">
          <p>
            Ausgewählte Firma: <span class="selected-name">{{ selectedCompany.name }}</span>
          </p>
        </ng-container>
        <div fxLayoutGap="12px">
          <button mat-raised-button matStepperPrevious>
            <mat-icon class="back-arrow">arrow_upward</mat-icon>
            Zurück
          </button>
          <button mat-raised-button color="primary" matStepperNext [disabled]="!selectedCompany">Weiter</button>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Auftraggeber</ng-template>

        <mat-button-toggle-group
          [value]="newOrExistingContactToggle"
          (valueChange)="newOrExistingContactToggle = $event"
          #group="matButtonToggleGroup"
        >
          <mat-button-toggle value="existingContact">Vorhandener Kontakt</mat-button-toggle>
          <mat-button-toggle value="newContact">Neuer Kontakt</mat-button-toggle>
        </mat-button-toggle-group>

        <div *ngIf="group.value === 'existingContact'">
          <pa-contacts [isInProjectCreation]="true" (selectContact)="onContactSelected($event)"></pa-contacts>
          <div *ngIf="contactsPaginated && contactsPaginated.data.length === 0">
            <span>Keine Suchergebnisse</span>
          </div>
          <div *ngIf="selectedContact" class="selected">
            Ausgewählt: <span class="selected-name">{{ selectedContact.contactNumber }} - {{ selectedContact.name }}</span>
            <button mat-raised-button color="warn" class="margin" (click)="selectedContact = null">Abbrechen</button>
          </div>
        </div>
        <form *ngIf="group.value === 'newContact'" [formGroup]="newContactFormGroup">
          <div>
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input matInput formControlName="name" placeholder="Name" required />
            </mat-form-field>
          </div>
        </form>
        <div fxLayoutGap="12px">
          <button mat-raised-button matStepperPrevious>
            <mat-icon class="back-arrow">arrow_upward</mat-icon>
            Zurück
          </button>
          <button
            mat-raised-button
            color="primary"
            matStepperNext
            *ngIf="
              (newOrExistingContactToggle === 'existingContact' && !selectedContact) ||
              (newOrExistingContactToggle === 'newContact' && !newContactFormGroup.valid)
            "
          >
            Weiter ohne Kontakt
          </button>
          <button
            mat-raised-button
            color="primary"
            matStepperNext
            *ngIf="
              (newOrExistingContactToggle === 'existingContact' && selectedContact) ||
              (newOrExistingContactToggle === 'newContact' && newContactFormGroup.valid)
            "
          >
            Weiter
          </button>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Ausschreibende Stelle</ng-template>

        <pa-contacts [isInProjectCreation]="true" (selectContact)="onTendererContactSelected($event)"></pa-contacts>
        <div *ngIf="contactsPaginated && contactsPaginated.data.length === 0">
          <span>Keine Suchergebnisse</span>
        </div>
        <div *ngIf="selectedTendererContact" class="selected">
          Ausgewählt: <span class="selected-name">{{ selectedTendererContact.contactNumber }} - {{ selectedTendererContact.name }}</span>
          <button mat-raised-button color="warn" class="margin" (click)="selectedTendererContact = null">Abbrechen</button>
        </div>
        <div fxLayoutGap="12px">
          <button mat-raised-button matStepperPrevious>
            <mat-icon class="back-arrow">arrow_upward</mat-icon>
            Zurück
          </button>
          <button mat-raised-button color="primary" matStepperNext *ngIf="!selectedTendererContact">Weiter ohne Kontakt</button>
          <button mat-raised-button color="primary" matStepperNext *ngIf="selectedTendererContact">Weiter</button>
        </div>
      </mat-step>
      <mat-step [stepControl]="servSpecNameFormGroup" *ngIf="!avaFileInspectionResult">
        <ng-template matStepLabel>Leistungsverzeichnis</ng-template>
        <div *ngIf="selectedFile">Wird aus Datei übernommen</div>
        <div *ngIf="!selectedFile">
          <form [formGroup]="servSpecNameFormGroup">
            <mat-form-field appearance="fill">
              <mat-label>Name</mat-label>
              <input matInput formControlName="name" required />
            </mat-form-field>
          </form>
        </div>
        <div fxLayoutGap="12px">
          <button mat-raised-button matStepperPrevious>
            <mat-icon class="back-arrow">arrow_upward</mat-icon>
            Zurück
          </button>
          <button mat-raised-button color="primary" matStepperNext [disabled]="!servSpecNameFormGroup.valid">Weiter</button>
        </div>
      </mat-step>
      <mat-step [stepControl]="masterAdditionGroupForm">
        <ng-template matStepLabel>Zuschlagsgruppe</ng-template>
        <form [formGroup]="masterAdditionGroupForm" class="addition-group-select">
          <mat-form-field appearance="fill" *ngIf="masterAdditionGroups">
            <mat-label>Typ</mat-label>
            <mat-select formControlName="masterAdditionGroupId">
              <mat-option *ngFor="let group of masterAdditionGroups" [value]="group.id">
                {{ group.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
        <mat-checkbox [checked]="isCopyAdditionGroup" (change)="copyAdditionGroup($event.checked)" class="copy-mode"
          >Aus bestehendem Projekt übernehmen</mat-checkbox
        >
        <div *ngIf="copyAdditionObj">
          <div fxLayout="row" fxLayoutGap="12px">
            <mat-form-field appearance="fill">
              <mat-label>Nummer</mat-label>
              <input matInput [value]="copyAdditionObj.project.constructedProjectNumber" disabled />
            </mat-form-field>
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Projektname</mat-label>
              <input matInput [value]="copyAdditionObj.project.name" disabled />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>LV-Name</mat-label>
              <input matInput [value]="copyAdditionObj.avaProject.name" disabled />
            </mat-form-field>
          </div>
        </div>
        <div fxLayoutGap="12px">
          <button mat-raised-button matStepperPrevious>
            <mat-icon class="back-arrow">arrow_upward</mat-icon>
            Zurück
          </button>
          <button
            mat-raised-button
            color="primary"
            matStepperNext
            [disabled]="!masterAdditionGroupForm.value.masterAdditionGroupId && !masterAdditionGroupForm.value.additionsSourceAvaProjectId"
          >
            Weiter
          </button>
        </div>
      </mat-step>
      <mat-step *ngIf="!hideStep">
        <ng-template matStepLabel>Abrechnungstypen</ng-template>
        <div class="type">
          {{ projectForQtoType | projectQtoType }}
        </div>
        <p>Optional können die möglichen Abrechenarten im Projekt eingeschränkt werden</p>
        <button mat-raised-button color="primary" (click)="tryChangeQtoType()">Typ {{ projectForQtoType ? 'Ändern' : 'Auswählen' }}</button>

        <div fxLayoutGap="12px" style="margin-top: 20px">
          <button mat-raised-button matStepperPrevious>
            <mat-icon class="back-arrow">arrow_upward</mat-icon>
            Zurück
          </button>
          <button mat-raised-button color="primary" matStepperNext>Weiter</button>
        </div>
      </mat-step>

      <mat-step *ngIf="!hideStep">
        <ng-template matStepLabel>Kostenstelle</ng-template>
        <p>Hier kann eine Kostenstelle eingetragen werden</p>
        <form [formGroup]="costCenterFormGroup">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Kostenstelle</mat-label>
            <input matInput formControlName="costCenter" />
          </mat-form-field>
        </form>
        <div fxLayoutGap="12px" style="margin-top: 20px">
          <button mat-raised-button matStepperPrevious>
            <mat-icon class="back-arrow">arrow_upward</mat-icon>
            Zurück
          </button>
          <button mat-raised-button color="primary" matStepperNext>Weiter</button>
        </div>
      </mat-step>
    </ng-container>
    <mat-step>
      <ng-template matStepLabel>Fertig</ng-template>
      <p>Das Projekt kann erstellt werden.</p>
      <div *ngIf="!requestEnRoute" fxLayoutGap="12px">
        <button mat-raised-button matStepperPrevious>
          <mat-icon class="back-arrow">arrow_upward</mat-icon>
          Zurück
        </button>
        <button mat-raised-button color="primary" (click)="createProject()">Projekt Erstellen</button>
        <button mat-raised-button color="warn" (click)="cancelProjectCreation()">Abbrechen</button>
      </div>
      <div fxFlexFill fxLayoutAlign="center center" *ngIf="requestEnRoute">
        <mat-spinner></mat-spinner>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>
