import { NgFor, NgIf } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';

import { TinyMceComponent } from '@dangl/angular-material-shared/tiny-mce';

import { CompanyGet, TextTemplateGet, TextTemplateType } from 'app/generated-client/generated-client';
import { CompaniesService } from 'app/shared/services/lightquery/companies.service';

import { TextTemplateTypePipe } from '../../../../shared/pipes/ui-data-display/text-template-type.pipe';
import { FlexLayoutDirective } from '../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-master-text-templates-add-modal',
  templateUrl: './master-text-templates-add-modal.component.html',
  styleUrls: ['./master-text-templates-add-modal.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    FormsModule,
    MatDialogContent,
    ReactiveFormsModule,
    FlexLayoutDirective,
    MatFormField,
    MatLabel,
    MatInput,
    MatSelect,
    NgFor,
    MatOption,
    MatCheckbox,
    TinyMceComponent,
    MatButton,
    NgIf,
    TextTemplateTypePipe
  ]
})
export class MasterTextTemplatesAddModalComponent implements OnInit {
  listCompany: CompanyGet[] = [];
  templateTypes: TextTemplateType[] = [];

  showDetail: boolean;
  changedHtmlTemplate: boolean;
  strState: string;

  templatesForm: FormGroup;
  defaultData: TextTemplateGet = {
    id: '',
    name: '',
    companyId: '',
    companyName: '',
    templateType: TextTemplateType.OfferStart,
    htmlText: '',
    isDefault: false
  };

  constructor(
    private companiesService: CompaniesService,
    @Inject(MAT_DIALOG_DATA) public data: TextTemplateGet,
    @Optional() private matDialogRef: MatDialogRef<MasterTextTemplatesAddModalComponent>,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.templateTypes = Object.keys(TextTemplateType).map((t) => t as TextTemplateType);
    this.companiesService.getAll().subscribe((c) => {
      this.listCompany = c;
      if (this.listCompany?.length > 0 && !this.templatesForm.value.companyId) {
        this.templatesForm.patchValue({
          companyId: this.listCompany[0].id
        });
      }
    });
    this.showDetail = !!this.data?.name;
    if (this.showDetail) {
      this.templatesForm = this.fb.group({ ...this.data });
    } else {
      this.templatesForm = this.fb.group({ ...this.defaultData });
    }
    this.strState = JSON.stringify(this.templatesForm.value);
  }

  saveTextItem(): void {
    const obj: TextTemplateGet = this.templatesForm.value;
    obj.companyName = this.listCompany.find((item) => item.id === obj.companyId)?.name;
    this.matDialogRef.close(obj);
  }

  cancel(): void {
    this.matDialogRef.close();
  }

  isChanges(): boolean {
    return JSON.stringify(this.templatesForm.value) !== this.strState;
  }

  deleteTextItem(): void {
    this.matDialogRef.close({});
  }
}
