import { ReplaySubject, take } from 'rxjs';

import { BimStateService } from './bim-state.service';
import { BimViewService } from './bim-view.service';
import { IfcSpatialStructure } from '../../../generated-client/generated-client';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BimShowViewService {
  private _structureMode = new ReplaySubject<boolean>(1);
  visibleStructureMode = this._structureMode.asObservable();

  constructor(
    private bimViewService: BimViewService,
    private stateService: BimStateService
  ) {}

  setStructureMode(value: boolean): void {
    this._structureMode.next(value);
  }

  getEntityIds(node: IfcSpatialStructure): number[] {
    const entityIds = node.containedEntityIds || [];
    if (node.children) {
      node.children.forEach((child) => {
        entityIds.push(...this.getEntityIds(child));
      });
    }
    return entityIds;
  }

  visibleSomeElements(isVisible: boolean, listId: number[]): void {
    this.stateService.showedElements.pipe(take(1)).subscribe((showedElements) => {
      const elements = showedElements || this.bimViewService.getListAllElements();
      const cleanList = elements.filter((itemId) => !listId.includes(itemId));
      const newList = isVisible ? [...cleanList, ...listId] : cleanList;
      this.bimViewService.hideAllElementsExcept(newList);
    });
  }
}
