import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProjectFileService } from '@project/components/project-files/services/project-file.service';

import { ErrorLogService } from '../../../../../../shared/services/error-log.service';
import { ModalService } from '../../../../../../shared/services/modal.service';

import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { ProjectFilesListComponent } from '../project-files-list/project-files-list.component';
import { ProjectFilesSelectComponent } from '../project-files-select/project-files-select.component';
import { ProjectFilesViewComponent } from '../project-files-view/project-files-view.component';

@Component({
  selector: 'pa-project-files',
  templateUrl: './project-files.component.html',
  styleUrls: ['./project-files.component.scss'],
  standalone: true,
  imports: [ProjectFilesSelectComponent, ProjectFilesListComponent, ProjectFilesViewComponent]
})
export class ProjectFilesComponent implements OnInit, OnDestroy {
  correlationId: string;
  $destroy: Subject<boolean> = new Subject<boolean>();
  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      modalData: string;
      correlationId: string;
      extensions: string[];
    },
    private errorLogService: ErrorLogService,
    private modalService: ModalService,
    private projectFileService: ProjectFileService
  ) {}

  ngOnInit(): void {
    if (this.dialogData) {
      this.correlationId = this.dialogData.correlationId;
    }
    this.errorLogService.error.pipe(takeUntil(this.$destroy)).subscribe((error) => {
      this.modalService.openModal(ErrorModalComponent, error);
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }
}
