import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { Subject, takeUntil } from 'rxjs';

import { ProjectsService } from '@shared/services/lightquery/projects.service';
import { SelectedProjectMessengerService } from '@shared/services/messengers/selected-project-messenger.service';

import { ProjectCostCentersClient, ProjectGet } from 'app/generated-client/generated-client';

import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-project-cost-center',
  templateUrl: './project-cost-center.component.html',
  styleUrls: ['./project-cost-center.component.scss'],
  standalone: true,
  imports: [NgIf, FlexLayoutDirective, MatProgressSpinner, FormsModule, MatFormField, MatLabel, MatInput, MatButton]
})
export class ProjectCostCenterComponent implements OnInit, OnDestroy {
  isChanged: boolean;
  hasLoaded: boolean;
  isLoading: boolean;
  currentProject: ProjectGet;
  private $destroy = new Subject<boolean>();

  constructor(
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private projectCostCentersClient: ProjectCostCentersClient,
    private projectsService: ProjectsService
  ) {}

  ngOnInit(): void {
    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((project: ProjectGet) => {
      this.currentProject = { ...project };
      this.hasLoaded = true;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  changeCostCenter(): void {
    if (!this.currentProject.costCenter) {
      this.deleteCostCenter();
      return;
    }
    this.isLoading = true;
    this.projectCostCentersClient
      .setCostCenterForProject(this.currentProject.id, this.currentProject.costCenter)
      .subscribe(() => this.change());
  }

  deleteCostCenter(): void {
    this.isLoading = true;
    this.currentProject.costCenter = undefined;
    this.projectCostCentersClient.deleteCostCenterAtProject(this.currentProject.id).subscribe(() => this.change());
  }

  private change(): void {
    this.selectedProjectMessengerService.setSelectedProject(this.currentProject);
    this.isChanged = false;
    this.isLoading = false;
    this.projectsService.forceRefresh();
  }
}
