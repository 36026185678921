import {
  ExecutionDescriptionDto,
  IElementDto,
  NoteTextDto,
  PositionDto,
  ServiceSpecificationGroupDto
} from '../../../../../generated-client/generated-client';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ElementFilterService {
  filterElements(elements: IElementDto[], filter?: string): IElementDto[] {
    const filteredElements: IElementDto[] = [];

    if (filter) {
      const filterSegments: string[] = ElementFilterService.getFilterSegments(filter);
      elements.forEach((element: IElementDto) => {
        let shouldAdd = false;

        switch (element.elementTypeDiscriminator) {
          case 'NoteTextDto':
            shouldAdd = this.checkFilterForNoteText(filterSegments, <NoteTextDto>element);
            break;
          case 'PositionDto':
            shouldAdd = this.checkFilterForPosition(filterSegments, <PositionDto>element);
            break;
          case 'ExecutionDescriptionDto':
            shouldAdd = this.checkFilterForExecutionDescription(filterSegments, <ExecutionDescriptionDto>element);
            break;
          case 'ServiceSpecificationGroupDto':
            shouldAdd = this.checkFilterForServiceSpecificationGroup(filterSegments, <ServiceSpecificationGroupDto>element);
            break;

          default:
            break;
        }

        if (shouldAdd) {
          filteredElements.push(element);
        }
      });
    }

    return filteredElements;
  }

  private checkFilterForNoteText(filter: string[], noteText: NoteTextDto): boolean {
    return this.checkValues([noteText.shortText, noteText.longText], filter);
  }

  private checkFilterForPosition(filter: string[], position: PositionDto): boolean {
    const values = [position.itemNumber?.stringRepresentation, position.shortText, position.longText];
    return this.checkValues(values, filter);
  }

  private checkFilterForExecutionDescription(filter: string[], executionDescription: ExecutionDescriptionDto): boolean {
    const values = [executionDescription.identifier, executionDescription.label];
    if (executionDescription.blocks) {
      executionDescription.blocks.forEach((block: NoteTextDto) => {
        values.push(block.shortText);
        values.push(block.longText);
      });
    }
    return this.checkValues(values, filter);
  }

  private checkFilterForServiceSpecificationGroup(filter: string[], serviceSpecificationGroup: ServiceSpecificationGroupDto): boolean {
    const values = [serviceSpecificationGroup.itemNumber?.stringRepresentation, serviceSpecificationGroup.shortText];
    return this.checkValues(values, filter);
  }

  private static getFilterSegments(filter: string): Array<string> {
    return filter.split(' ').map((s: string) => s.toUpperCase());
  }

  private checkValues(values: string[], filterSegments: string[]): boolean {
    return filterSegments.every((segment: string) => {
      return values.some(value => value?.toUpperCase().indexOf(segment) > -1);
    });
  }
}
