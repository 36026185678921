import { MatDialogConfig } from '@angular/material/dialog';

export enum ConfirmationType {
  Delete = 'Delete',
  General = 'General'
}

export interface IDialogConfig extends MatDialogConfig {
  dialogType: ConfirmationType;
}

export class DialogConfig extends MatDialogConfig implements IDialogConfig {
  dialogType: ConfirmationType;
  constructor() {
    super();
  }
}
