import { IElementDto, PositionDto, PositionTypeDto, PriceTypeDto, ServiceTypeDto } from '../../../generated-client/generated-client';

import { FlatElementsService } from './../../../areas/tree/services/flat-elements.service';
import { Injectable } from '@angular/core';
import { UiPositionType } from '../../models/ui-position-type';

@Injectable({
  providedIn: 'root'
})
export class PositionTypeConverterService {
  private complementedPositions: PositionDto[] = [];

  constructor(private flatElementsService: FlatElementsService) {
    flatElementsService.flatElementsDto.subscribe(elements => {
      if (elements) {
        this.complementedPositions = elements
          .filter(e => e.elementType === 'PositionDto')
          .map(e => e as PositionDto)
          .filter(p => p.complementedBy);
      } else {
        this.complementedPositions = [];
      }
    });
  }

  /** This method checks the actual type of the element and returns the correct UiPositionType.
   * If it's a NoteTextDto, it's always a text position. Otherwise, if it's not a PositionDto,
   * it returns Unknown. For PositionDto elements, it checks the position type and returns the
   * correct UiPositionType.
   * This only works for positions in the currently activated service specification, since
   * since position types have inverse relationships so other elements need to be checked as well.
   */
  public convertIElementToPositionType(element: IElementDto): UiPositionType {
    if (element.elementType === 'NoteTextDto') {
      return UiPositionType.NoteText;
    }

    if (element.elementType !== 'PositionDto') {
      return UiPositionType.Unknown;
    }

    const position = element as PositionDto;

    if (position.serviceType === ServiceTypeDto.HourlyPaidWork) {
      return UiPositionType.HourlyPaidWork;
    }

    if (position.positionType === PositionTypeDto.Alternative) {
      return UiPositionType.Alternative;
    }

    if (position.positionType === PositionTypeDto.Optional) {
      if (position.priceType === PriceTypeDto.WithoutTotal) {
        return UiPositionType.Eventual;
      }

      return UiPositionType.Optional;
    }

    const isComplementingPosition = this.complementedPositions.find(
      p => p.complementedBy && p.complementedBy.find(pId => pId === position.id)
    );
    if (isComplementingPosition) {
      return UiPositionType.Complementing;
    }

    return UiPositionType.Regular;
  }
}
