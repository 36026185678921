import { Pipe, PipeTransform } from '@angular/core';

import { PositionDto } from 'app/generated-client/generated-client';
import { ShortPositionAndPriceTypePipe } from './short-position-and-price-type.pipe';

@Pipe({
  name: 'positionText',
  standalone: true
})
export class PositionTextPipe implements PipeTransform {
  constructor(private shortPositionAndPriceTypePipe: ShortPositionAndPriceTypePipe) {}

  transform(value: PositionDto, excludePrefix?: boolean): string {
    const text = value?.shortText || '';
    if (value && !excludePrefix) {
      const prefix = this.shortPositionAndPriceTypePipe.transform(value);
      if (prefix && prefix !== 'NN') {
        // 'NN' is the regular prefix, we don't want to show that
        return `(${prefix}) ${text}`;
      }
    }
    return text;
  }
}
