import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient } from '@angular/common/http';
import localDe from '@angular/common/locales/de';
import { ApplicationRef, DoBootstrap, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HelpWindowModalComponent } from '@shared/components/help-window-modal/help-window-modal.component';

import { CopyElementViewComponent } from 'app/areas/copy-element-view/copy-element-view/copy-element-view.component';
import { PdfOverlayService } from 'app/shared/services/pdf-overlay.service';

import { AppComponent } from './app.component';
import { CopyCalculationViewComponent } from './areas/copy-calculation-view/components/copy-calculation-view/copy-calculation-view.component';
import { ElementViewComponent } from './areas/element-view/components/element-view/element-view.component';
import { LocalProjectIdInterceptor } from './areas/local-projects/interceptors/local-project-id.interceptor';
import { LocalProjectsModule } from './areas/local-projects/local-projects.module';
import { LongTextWindowComponent } from './areas/long-text-view/components/long-text-window/long-text-window.component';
import { ManagementModule } from './areas/management/management.module';
import { MasterDataModule } from './areas/master-data/master-data.module';
import { ProjectIdModule } from './areas/project-id/project-id.module';
import { ProjectsModule } from './areas/projects/projects.module';
import { SheetsViewComponent } from './areas/sheets-view/components/sheets-view/sheets-view.component';
import { TreeViewComponent } from './areas/tree-view/components/tree-view/tree-view.component';
import { MultiViewWindowComponent } from './areas/views/components/multi-view-window/multi-view-window.component';
import { HandlerElectronErrorsService } from './shared/services/electron/handler-electron-errors.service';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { SharedModule } from './shared/shared.module';
import {
  isInCopyCalculationView,
  isInCopyElementView,
  isInElementView,
  isInHelpView,
  isInLongTextView,
  isInSheetsView,
  isInTreeView
} from './shared/utilities/view-detection';
import { version } from './version';

import { AppConfig } from '../environments/environment';
import '../polyfills';
import 'reflect-metadata';

registerLocaleData(localDe);

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AppConfig.isQtoOnlyMode ? LocalProjectsModule : ProjectsModule,
    MasterDataModule,
    ManagementModule,
    ProjectIdModule,
    MultiViewWindowComponent,
    AppComponent
  ],
  providers: [
    provideHttpClient(),
    PdfOverlayService,
    HandlerElectronErrorsService,
    {
      provide: 'TINYMCE_BASE_URL',
      useValue: AppConfig.production ? 'assets/tinymce-assets/' : '/assets/tinymce-assets/'
    },
    {
      provide: 'APP_VERSION',
      useValue: version.version
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE'
    },
    AppConfig.isQtoOnlyMode
      ? {
          provide: HTTP_INTERCEPTORS,
          multi: true,
          useClass: LocalProjectIdInterceptor
        }
      : []
  ]
})
export class AppModule implements DoBootstrap {
  ngDoBootstrap(appRef: ApplicationRef): void {
    if (isInElementView()) {
      appRef.bootstrap(ElementViewComponent);
    } else if (isInCopyCalculationView()) {
      appRef.bootstrap(CopyCalculationViewComponent);
    } else if (isInTreeView()) {
      appRef.bootstrap(TreeViewComponent);
    } else if (isInHelpView()) {
      appRef.bootstrap(HelpWindowModalComponent);
    } else if (isInLongTextView()) {
      appRef.bootstrap(LongTextWindowComponent);
    } else if (isInSheetsView()) {
      appRef.bootstrap(SheetsViewComponent);
    } else if (isInCopyElementView()) {
      appRef.bootstrap(CopyElementViewComponent);
    } else {
      appRef.bootstrap(AppComponent);
    }
  }
}
