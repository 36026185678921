<div class="help-header" #header>
  <h1>Hilfe</h1>
  <mat-form-field class="full-width">
    <mat-label>
      <mat-icon>search</mat-icon>
      <span>Suche</span>
    </mat-label>
    <input matInput [(ngModel)]="filter" />
    <button *ngIf="filter" matSuffix mat-icon-button aria-label="Clear" (click)="filter = ''">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</div>
<div class="wrapper" [style.height]="hasStyle ? 'calc(100vh - ' + header.offsetHeight + 'px)' : null">
  <div class="wrapper-help-structure">
    <div class="spinner-container" *ngIf="!treeStructureData">
      <mat-spinner></mat-spinner>
    </div>
    <pa-help-window-tree-structure
      *ngIf="!filter && treeStructureData"
      [treeStructureData]="treeStructureData"
    ></pa-help-window-tree-structure>
    <pa-help-window-list-structure
      *ngIf="filter && flatStructureData"
      [flatStructureData]="flatStructureData"
      [filter]="filter"
    ></pa-help-window-list-structure>
  </div>
  <div class="wrapper-help-text">
    <div class="spinner-container" *ngIf="!flatStructureData">
      <mat-spinner></mat-spinner>
    </div>
    <pa-help-window-text *ngIf="flatStructureData" [flatStructureData]="flatStructureData"></pa-help-window-text>
  </div>
</div>
