import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Subject, take, takeUntil } from 'rxjs';

import { BimStructureFilterService } from 'app/areas/bim-view/services/bim-structure-filter.service';
import { BimViewService } from 'app/areas/bim-view/services/bim-view.service';
import { FILTER_VALUE_BY_DEFAULT } from 'app/areas/bim-view/constants/filter-value-by-default';
import { FilterSelectionComponent } from '../filter-selection/filter-selection.component';
import { IFilterType } from 'app/areas/bim-view/interface/filter-type.interface';
import { IPropertySetFilter } from 'app/areas/bim-view/interface/property-set-filter.interface';
import { IfcStructure } from '../../../../../../generated-client/generated-client';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'pa-element-properties-filter-selection',
  standalone: true,
  imports: [FilterSelectionComponent, MatCheckboxModule, MatIconModule, MatExpansionModule, MatButtonModule, ReactiveFormsModule],
  templateUrl: './element-properties-filter-selection.component.html',
  styleUrls: ['./element-properties-filter-selection.component.scss']
})
export class ElementPropertiesFilterSelectionComponent implements OnInit, OnDestroy {
  /**
   * This is necessary if in the future we need to set values for filters programmatically
   * Then we need to pass them a value, something like this
   * this.propertySetNameComponent.writeValue('some value')
   */

  @ViewChild('propertySetNameComponent') propertySetNameComponent: FilterSelectionComponent;
  @ViewChild('propertyNameComponent') propertyNameComponent: FilterSelectionComponent;
  @ViewChild('propertyValueComponent') propertyValueComponent: FilterSelectionComponent;

  @Input() filterIndex: number;
  @Input() set ifcTypes(type: string[]) {
    this._ifcTypes = type;
    if (this.structure) {
      this.propertySetNameControl.reset();
      this.propertyNameControl.reset();
      this.propertyValueControl.reset();
      this.propertySetName = [FILTER_VALUE_BY_DEFAULT, ...this.bimStructureFilterService.getPropertySetName(this.structure, this.ifcTypes)];
      this.propertyName = [FILTER_VALUE_BY_DEFAULT];
      this.propertyValue = [FILTER_VALUE_BY_DEFAULT];
    }
  }
  get ifcTypes() {
    return this._ifcTypes;
  }
  private _ifcTypes: string[] = [];
  @Output() remove: EventEmitter<void> = new EventEmitter<void>();
  @Output() update: EventEmitter<IPropertySetFilter> = new EventEmitter<IPropertySetFilter>();
  private $destroy: Subject<boolean> = new Subject<boolean>();

  propertySetName: IFilterType[] = [FILTER_VALUE_BY_DEFAULT];
  propertyName: IFilterType[] = [FILTER_VALUE_BY_DEFAULT];
  propertyValue: IFilterType[] = [FILTER_VALUE_BY_DEFAULT];
  structure: IfcStructure;

  propertySetNameControl = new FormControl<string | null>(null);
  propertyNameControl = new FormControl<string | null>(null);
  propertyValueControl = new FormControl<string | null>(null);
  invertFilterTypeControl = new FormControl<boolean>(false);

  private cdr = inject(ChangeDetectorRef);
  private bimViewService = inject(BimViewService);
  private bimStructureFilterService = inject(BimStructureFilterService);
  constructor() {}

  ngOnInit(): void {
    this.bimViewService.structure.pipe(take(1)).subscribe((structure: IfcStructure) => {
      this.structure = structure;
      this.propertySetName = [FILTER_VALUE_BY_DEFAULT, ...this.bimStructureFilterService.getPropertySetName(this.structure, this.ifcTypes)];
      this.cdr.detectChanges();
    });

    this.registrateUpdateFormControl();
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  registrateUpdateFormControl(): void {
    this.propertySetNameControl.valueChanges.pipe(takeUntil(this.$destroy)).subscribe((propertySetName) => {
      this.propertyNameControl.reset();
      this.propertyName = [FILTER_VALUE_BY_DEFAULT, ...this.bimStructureFilterService.getPropertyName(this.structure, propertySetName)];
      this.propertyValue = [FILTER_VALUE_BY_DEFAULT];
      if (propertySetName || propertySetName === '') {
        this.updatePropertySetFilter();
      }
    });
    this.propertyNameControl.valueChanges.pipe(takeUntil(this.$destroy)).subscribe((propertyName) => {
      this.propertyValueControl.reset();
      if (propertyName || propertyName === '') {
        this.propertyValue = [
          FILTER_VALUE_BY_DEFAULT,
          ...this.bimStructureFilterService.getPropertyValue(this.structure, this.propertySetNameControl.value, propertyName)
        ];
        this.updatePropertySetFilter();
      }
    });
    this.propertyValueControl.valueChanges.pipe(takeUntil(this.$destroy)).subscribe((propertyValue) => {
      if (propertyValue || propertyValue === '') {
        this.updatePropertySetFilter();
      }
    });
    this.invertFilterTypeControl.valueChanges.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.updatePropertySetFilter();
    });
  }

  removeFilterGroup(): void {
    this.remove.emit();
  }

  private updatePropertySetFilter(): void {
    const { value: propertySetName } = this.propertySetNameControl;
    const { value: propertyName } = this.propertyNameControl;
    const { value: propertyValue } = this.propertyValueControl;
    const { value: invertFilter } = this.invertFilterTypeControl;

    this.update.emit({
      propertySetName: propertySetName || '',
      propertyName: propertyName || '',
      propertyValue: propertyValue || '',
      invertFilter: invertFilter
    });
  }
}
