import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MasterProductCatalogueGet } from '../../../generated-client/generated-client';

import { PaginationMainService } from './main/pagination-main.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogsService extends PaginationMainService<MasterProductCatalogueGet> {
  constructor(protected http: HttpClient) {
    super(http);
    this.beginBaseUrl('/api/master-data/product-catalogues');
  }
}
