<div class="wrapper">
  <mat-form-field class="full-width">
    <mat-label>Suche</mat-label>
    <input matInput [ngModel]="filter" (ngModelChange)="onFilter($event)" />
  </mat-form-field>

  <div class="filter-wrapper" *ngIf="isFilter && paginationResult.data.length">
    <mat-selection-list id="filterList" [multiple]="false">
      <mat-list-option *ngFor="let paginationFile of paginationResult.data" (click)="selectedFile(paginationFile)">
        <div class="file-box">
          <pa-project-file-list-preview [projectFile]="paginationFile"></pa-project-file-list-preview>
          <div>
            {{ paginationFile.file.fileName }}
            <p>{{ paginationFile.file.createdAtUtc | date: 'dd.MM.yyyy HH:mm' }}</p>
          </div>
        </div>
      </mat-list-option>
    </mat-selection-list>
    <pa-upper-paginator
      *ngIf="isFilter"
      [pageSizeOptions]="[5, 10, 25, 100]"
      [upperPaginated]="paginationResult"
      [upperService]="projectFilesFilterService"
      (page)="projectFilesFilterService.onPage($event)"
    >
    </pa-upper-paginator>
  </div>
  <div *ngIf="isFilter && paginationResult.data.length === 0">
    <div class="no-files-present-label">
      <i>Keine Suchergebnisse</i>
    </div>
  </div>
  <div class="list-wrapper" *ngIf="!isFilter">
    <pa-project-files-explorer [extensions]="extensions"></pa-project-files-explorer>
  </div>
</div>
