<pa-general-equipment [mode]="modeEquipment" *ngIf="!noSeparate; else mainContent">
  <ng-container *ngTemplateOutlet="mainContent"></ng-container>
</pa-general-equipment>

<ng-template #mainContent>
  <as-split
    fxFlexFill
    #asSplitRightRef
    (dragEnd)="onDragEnd($event.sizes)"
    (mouseenter)="noSeparate && enterMouse()"
    (mouseleave)="noSeparate && tryLeave()"
  >
    <as-split-area [size]="100" #scrollEl>
      <div
        *ngIf="serviceSpecification"
        #scrollEl
        [style.height]="
          sanitizer.bypassSecurityTrustStyle(
            !noSeparate ? 'calc(100%  - ' + topPartComp?.componentInstance?.main?.offsetHeight + 'px )' : '100%'
          )
        "
      >
        <pa-main-tree
          [showedAvaProject]="serviceSpecification"
          [isInnerWindow]="true"
          [isSeparate]="true"
          [isCopyElementView]="true"
        ></pa-main-tree>
      </div>
    </as-split-area>
    <as-split-area [size]="0"> </as-split-area>
    <as-split></as-split
  ></as-split>
</ng-template>
