import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { TelemetryService } from './telemetry.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  constructor(injector: Injector) {
    this.telemetryService = injector.get(TelemetryService);
  }

  private telemetryService: TelemetryService;

  handleError(error: any): void {
    if (this.telemetryService
      && this.telemetryService.handleError) {
      this.telemetryService.handleError(error);
    }
  }
}
