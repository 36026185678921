<div class="wrapper" *ngIf="projectFile; else projectFolder">
  <mat-list>
    <mat-list-item>
      <div mat-line>
        <strong>
          {{ projectFile.file.fileName }}
        </strong>
      </div>
    </mat-list-item>
    <mat-list-item>
      <div mat-line>Größe: {{ projectFile.file.sizeInBytes | fileSize }}</div>
    </mat-list-item>
    <mat-list-item>
      <div mat-line>Erstellt am: {{ projectFile.file.createdAtUtc | date: 'dd.MM.yyyy HH:mm' }}</div>
    </mat-list-item>
    <mat-list-item>
      <div mat-line>Erstellt von: {{ projectFile.file.createdByUserName }}</div>
    </mat-list-item>
  </mat-list>

  <div fxLayoutGap="12px" class="btn">
    <button mat-raised-button color="primary" (click)="getBlobFile(projectFile)" *ngIf="!modalView" [disabled]="requestEnRoute">
      Speichern
    </button>
    <button
      class="btn-remove"
      mat-raised-button
      color="warn"
      (click)="removeFile(projectFile)"
      *ngIf="!modalView"
      [disabled]="!projectFile.canDelete"
    >
      Löschen
    </button>
  </div>
  <button mat-raised-button color="accent" *ngIf="modalView" (click)="addImgToTable(projectFile)">Auswählen</button>
  <div class="wrapper-photo" *ngIf="imageFileId">
    <img class="preview-image" src="{{ imageFileId | imgSrc | async }}" />
  </div>
</div>
<ng-template #projectFolder>
  <div *ngIf="showUploadFilesZone && selectedProjectFolder">
    <pa-upload-file [uploadFileSettings]="uploadFileSettings" (uploadFile)="onSelect($event)"></pa-upload-file>
  </div>
</ng-template>
