import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { Router, RouterLink } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';

import { AvaProjectGet, ProjectStatus } from '../../../../../../generated-client/generated-client';

import { ServiceSpecificationsTableComponent } from '../service-specifications-table/service-specifications-table.component';

@Component({
  selector: 'pa-service-specifications-list',
  templateUrl: './service-specifications-list.component.html',
  styleUrls: ['./service-specifications-list.component.scss'],
  standalone: true,
  imports: [MatButton, RouterLink, ServiceSpecificationsTableComponent]
})
export class ServiceSpecificationsListComponent implements OnInit, OnDestroy {
  isReadOnly: boolean;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private selectedProjectMessengerService: SelectedProjectMessengerService
  ) {}

  ngOnInit(): void {
    this.selectedProjectMessengerService.selectedProject
      .pipe(takeUntil(this.$destroy))
      .subscribe((p) => (this.isReadOnly = p?.status === ProjectStatus.Archived || p?.status === ProjectStatus.Locked));
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

  selectAvaProject(avaProject: AvaProjectGet): void {
    this.router.navigate([this.router.url, avaProject.id]);
  }
}
