import { NgIf } from '@angular/common';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router } from '@angular/router';

import { EMPTY, Subject } from 'rxjs';
import { first, switchMap, takeUntil } from 'rxjs/operators';

import { SelectedContactMessengerService } from 'app/areas/contacts/services/selected-contact-messenger.service';
import { ContactGet, ContactsClient, ProjectContactPut, ProjectContactsClient, ProjectGet } from 'app/generated-client/generated-client';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';

import { ContactAddressesComponent } from '../../../../../contacts/components/contact-addresses/contact-addresses.component';
import { ContactPersonsComponent } from '../../../../../contacts/components/contact-persons/contact-persons.component';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

import { ModalDeleteContactComponent } from '../modal-delete-contact/modal-delete-contact.component';
import { ModalToChangeContactComponent } from '../modal-to-change-contact/modal-to-change-contact.component';

@Component({
  selector: 'pa-project-contact',
  templateUrl: './project-contact.component.html',
  styleUrls: ['./project-contact.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FlexLayoutDirective,
    MatProgressSpinner,
    FormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    ContactAddressesComponent,
    ContactPersonsComponent,
    MatButton
  ]
})
export class ProjectContactComponent implements OnInit, OnDestroy {
  contact: ContactGet | null;
  isLoading = false;
  cachedProject: ProjectGet;
  isTenderer = false;
  private projectId: string;
  private $destroy: Subject<void> = new Subject<void>();

  constructor(
    private contactsClient: ContactsClient,
    private modalService: ModalService,
    private projectContactsClient: ProjectContactsClient,
    private router: Router,
    private route: ActivatedRoute,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private ngZone: NgZone,
    private selectedContactMessengerService: SelectedContactMessengerService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.route.data.pipe(first()).subscribe((routeData) => {
      this.isTenderer = routeData.isTenderer;
      this.selectedProjectMessengerService.selectedProject
        .pipe(
          takeUntil(this.$destroy),
          switchMap((project: ProjectGet) => {
            this.projectId = project.id;
            this.cachedProject = project;
            const contactId = this.isTenderer ? project.tendererContactId : project.contactId;
            if (contactId) {
              return this.contactsClient.getContactById(contactId);
            }

            this.contact = null;
            this.isLoading = false;

            return EMPTY;
          })
        )
        .subscribe((contact: ContactGet) => {
          this.contact = { ...contact };
          this.isLoading = false;
          this.selectedContactMessengerService.setSelectedContact(contact);
        });
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  changeContact(): void {
    this.modalService
      .openModal(ModalToChangeContactComponent, { dialogType: ConfirmationType.General })
      .afterClosed()
      .pipe(
        takeUntil(this.$destroy),
        switchMap((response: { contactId: string; updateAvaProjectsBuyer: boolean }) => {
          if (response) {
            const model: ProjectContactPut = { contactId: response.contactId, updateAvaProjectsBuyer: response.updateAvaProjectsBuyer };

            return this.isTenderer
              ? this.projectContactsClient.updateProjectTendererContact(this.projectId, model)
              : this.projectContactsClient.updateProjectContact(this.projectId, model);
          }
          return EMPTY;
        })
      )
      .subscribe((newContact: ContactGet) => {
        setTimeout(() => {
          this.ngZone.run(() => {
            this.contact = newContact;
            if (this.isTenderer) {
              this.cachedProject.tendererContactId = newContact.id;
              this.cachedProject.tendererContactName = newContact.name;
            } else {
              this.cachedProject.contactId = newContact.id;
              this.cachedProject.contactName = newContact.name;
            }
            this.selectedContactMessengerService.setSelectedContact(newContact);
          });
        }, 1);
      });
  }

  routeToContact(): void {
    this.router.navigate([`${this.router.url}/${this.contact.id}`]);
  }

  deleteContact(): void {
    this.modalService
      .openModal(ModalDeleteContactComponent, { dialogType: ConfirmationType.General })
      .afterClosed()
      .subscribe((isDelete: boolean) => {
        if (isDelete) {
          if (this.isTenderer) {
            this.projectContactsClient.removeTendererContactFromProject(this.projectId).subscribe(() => {
              this.contact = null;
              this.cachedProject.tendererContactId = null;
            });
          } else {
            this.projectContactsClient.removeContactFromProject(this.projectId).subscribe(() => (this.contact = null));
            this.contact = null;
            this.cachedProject.contactId = null;
          }
        }
      });
  }
}
