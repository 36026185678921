<pa-general-equipment [mode]="modeEquipment" *ngIf="!noSeparate; else mainContent">
  <ng-container *ngTemplateOutlet="mainContent"></ng-container>
</pa-general-equipment>

<ng-template #mainContent>
  <mat-tab-group
    animationDuration="0ms"
    (selectedTabChange)="beginSetting($event)"
    class="main-tabs"
    [ngClass]="{ 'margin-header': !noSeparate }"
  >
    <mat-tab [label]="elementGroupId ? 'Projekt Gruppe' : 'Projekt'" *ngIf="!noSeparate">
      <pa-other-project-tab [noSeparate]="noSeparate" [elementGroupId]="elementGroupId"></pa-other-project-tab>
    </mat-tab>

    <ng-container *ngIf="!elementGroupId">
      <mat-tab
        *ngFor="let itemPriceComponent of arrayPriceComponents"
        [label]="
          itemPriceComponent && itemPriceComponent !== 'Catalogues'
            ? (itemPriceComponent | priceComponentType)
            : itemPriceComponent === 'Catalogues'
            ? 'Produktkataloge'
            : 'Stammdaten'
        "
        #arrPriceTabs
      >
        <div class="wrapper" *ngIf="selectedPriceComponentType == itemPriceComponent && itemPriceComponent !== 'Catalogues'; else catalogs">
          <pa-master-data-products-table
            [noSeparate]="noSeparate"
            (dblSelectItem)="dblSelectMasterDataProduct($event)"
            (selectItem)="selectMasterDataProduct($event)"
            [priceComponentType]="itemPriceComponent"
            [showedId]="selectedMasterDataProduct?.id"
          ></pa-master-data-products-table>
        </div>
        <ng-template #catalogs>
          <div class="wrapper">
            <pa-catalogs [noSeparate]="noSeparate"></pa-catalogs>
          </div>
        </ng-template>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</ng-template>
