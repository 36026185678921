<pa-service-specification-tree fxFlexFill>
  <pa-invoice-positions
    [isReadOnlyOriginal]="isReadOnly"
    *ngIf="!currentUserSettings?.showPositionQtoInSingleTable; else allPosition"
  ></pa-invoice-positions>
</pa-service-specification-tree>
<ng-template #allPosition>
  <pa-invoice-positions-all
    [isReadOnlyOriginal]="isReadOnly"
    *ngIf="currentUserSettings?.showPositionQtoInSingleTable"
  ></pa-invoice-positions-all>
</ng-template>
