import { Subject, debounceTime, takeUntil } from 'rxjs';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SelectedProjectMessengerService } from './messengers/selected-project-messenger.service';
import { UserSettingsClient } from '../../generated-client/generated-client';

@Injectable({
  providedIn: 'root'
})
export class ProjectLocationService {
  private canSave: boolean;
  private hasFirstTime: boolean;
  private changedUrl: boolean;
  private projectId: string;
  private currentUrl: string;

  private changedIndicatorSource = new Subject<boolean>();
  changedIndicator = this.changedIndicatorSource.asObservable();
  private $destroy = new Subject<boolean>();

  constructor(
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private userSettingsClient: UserSettingsClient,
    private router: Router
  ) {
    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe(project => {
      if (this.projectId !== project?.id) {
        this.canSave = false;
        if (this.hasFirstTime) {
          this.projectId = project?.id;
          this.tryGetSavedUrl();
        }
      }
      this.projectId = project?.id;
    });

    this.changedIndicator.pipe(takeUntil(this.$destroy), debounceTime(1000)).subscribe(isSuccess => {
      this.saveUrl(isSuccess);
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  setChangedIndicator(isFail: boolean): void {
    if (this.canSave && this.hasFirstTime) {
      this.changedIndicatorSource.next(!isFail);
    }
  }

  startChangedIndicator(currentUrl: string): void {
    setTimeout(() => {
      if (this.projectId && this.canSave && this.hasFirstTime && this.currentUrl !== currentUrl) {
        this.currentUrl = currentUrl;
        this.changedUrl = true;
        this.setChangedIndicator(false);
      }
    }, 100);
  }

  saveUrl(isSuccess: boolean): void {
    if (this.projectId && this.currentUrl !== '/login' && this.currentUrl !== '/projects' && this.canSave && this.hasFirstTime) {
      const savedUrl = isSuccess ? this.currentUrl : null;

      if (savedUrl && this.changedUrl) {
        this.canSave = false;
        this.userSettingsClient
          .setUserProjectLocation(this.projectId, {
            projectLocationUrl: savedUrl,
            projectId: this.projectId
          })
          .subscribe(() => {
            this.canSave = true;
          });
      }
      this.changedUrl = false;
    }
  }

  tryGetSavedUrl(): void {
    this.canSave = true;
    if (this.projectId) {
      this.userSettingsClient.getUserProjectLocation(this.projectId).subscribe(location => {
        if (location?.projectLocationUrl) {
          this.router.navigateByUrl(location.projectLocationUrl);
        }
      });
    }
  }

  startAfterFirst(): void {
    this.hasFirstTime = true;
    this.canSave = true;
  }
}
