import {
  AvaProjectContentEditClient,
  AvaProjectContentEditOperation,
  AvaProjectContentEditResultGet,
  IElementDto
} from 'app/generated-client/generated-client';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { AvaNotificationsService } from '../../../../../shared/services/ava-notifications.service';
import { SelectedSpecificationMessengerService } from '../../../../../shared/services/messengers/selected-specification-messenger.service';
import { ServiceSpecificationsHasChangeService } from 'app/shared/services/messengers/service-specifications-has-change.service';

@Injectable({
  providedIn: 'root'
})
export class LvEditorService implements OnDestroy {
  private editModeSource = new Subject<boolean>();
  editMode = this.editModeSource.asObservable();

  private isElementChangedSource = new ReplaySubject<boolean>(1);
  isElementChanged = this.isElementChangedSource.asObservable();

  private projectId: string;
  private avaProjectId: string;
  $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private avaProjectContentEditClient: AvaProjectContentEditClient,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private avaNotificationsService: AvaNotificationsService,
    private serviceSpecificationsHasChangeService: ServiceSpecificationsHasChangeService
  ) {
    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        filter(p => !!p),
        takeUntil(this.$destroy)
      )
      .subscribe(p => {
        this.projectId = p.parentProjectId;
        this.avaProjectId = p.avaProjectId;
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private setMode(mode: boolean): void {
    this.editModeSource.next(mode);
  }

  setChangeElement(isElementChanged: boolean): void {
    this.isElementChangedSource.next(isElementChanged);
  }

  saveChangedElement(element: IElementDto): Observable<boolean> {
    const returnObservable = new Subject<boolean>();
    this.serviceSpecificationsHasChangeService.checkServiceSpecificationHasBeenEdited().subscribe(shouldBeEdited => {
      returnObservable.next(shouldBeEdited);
      returnObservable.complete();
      if (shouldBeEdited) {
        return this.avaProjectContentEditClient
          .editAvaProjectContent(this.projectId, this.avaProjectId, {
            operation: AvaProjectContentEditOperation.Edit,
            updateOperation: {
              element: element
            }
          })
          .subscribe({
            next: (editResponse: AvaProjectContentEditResultGet) => {
              this.avaNotificationsService.success('Element gespeichert');
              if (editResponse.project.serviceSpecifications[0].containsDuplicateItemNumbers) {
                this.avaNotificationsService.warning('Das LV hat Duplikate in den Ordnungszahlen');
              }
            },
            error: () => this.avaNotificationsService.error('Fehler beim Ändern des Elements')
          });
      }
    });

    return returnObservable;
  }

  startChangeElement(): void {
    this.setMode(true);
  }

  stopChangeElement(): void {
    this.setMode(false);
  }
}
