<div class="name" [class.selected]="selectedElement === structureItem?.entityId">
  <mat-icon (click)="isShowChildren = !isShowChildren">
    {{ !isShowChildren ? 'chevron_right' : 'expand_more' }}
  </mat-icon>
  <mat-icon
    color="warn"
    *ngIf="isShowMode"
    [class.indeterminate]="indeterminateGroup"
    [class.invisible]="!checkedGroup && !indeterminateGroup"
    (click)="toggleShowedGroup($event, !checkedGroup)"
  >
    {{ checkedGroup || indeterminateGroup ? 'visibility' : 'visibility_off' }}
  </mat-icon>
  <span (click)="selectElement(structureItem?.entityId)">
    (Id: {{ structureItem?.entityId }}) - {{ structureItem?.ifcType }} - {{ structureItem?.name }}
  </span>
</div>
<div *ngIf="structureItem?.children; else list" class="padding" [class.hide]="!isShowChildren">
  <pa-structure-item-bim-view *ngFor="let item of structureItem?.children" [structureItem]="item"></pa-structure-item-bim-view>
</div>
<ng-template #list>
  <div
    *ngFor="let oneId of structureItem?.containedEntityIds"
    class="padding list"
    [class.selected]="selectedElement === oneId"
    [class.hide]="!isShowChildren"
    (click)="selectElement(oneId)"
  >
    <mat-icon
      color="warn"
      *ngIf="isShowMode"
      class="middle"
      [class.invisible]="showedElements && !showedElements.includes(oneId)"
      (click)="toggleShowedElement($event, showedElements && !showedElements.includes(oneId), oneId)"
    >
      {{ !showedElements || showedElements.includes(oneId) ? 'visibility' : 'visibility_off' }}
    </mat-icon>
    (Id: {{ oneId }}) - {{ listNames[oneId] }}
  </div>
</ng-template>
