import { NgIf, NgFor } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSelectionList, MatListOption } from '@angular/material/list';

import { PositionTextPipe } from '../../pipes/ui-data-display/position-text.pipe';

import {
  ExecutionDescriptionDto,
  NoteTextDto,
  PositionDto,
  ServiceSpecificationGroupDto
} from './../../../generated-client/generated-client';

@Component({
  selector: 'pa-selection-list-with-number-and-text',
  templateUrl: './selection-list-with-number-and-text.component.html',
  styleUrls: ['./selection-list-with-number-and-text.component.scss'],
  standalone: true,
  imports: [NgIf, MatSelectionList, NgFor, MatListOption, PositionTextPipe]
})
export class SelectionListWithNumberAndTextComponent {
  @Input() listData: (ServiceSpecificationGroupDto | NoteTextDto | ExecutionDescriptionDto | PositionDto)[];
  @Output() selectedPosition = new EventEmitter<ServiceSpecificationGroupDto | NoteTextDto | ExecutionDescriptionDto | PositionDto>();

  selectPosition(position: ServiceSpecificationGroupDto | NoteTextDto | ExecutionDescriptionDto | PositionDto): void {
    this.selectedPosition.emit(position);
  }
}
