import { Injectable, OnDestroy } from '@angular/core';
import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, tap, mergeMap, finalize, takeUntil } from 'rxjs/operators';
import { HttpRequestsService } from '../services/http-requests.service';
import { from } from 'rxjs';
import { ErrorLoggingService } from '../services/error-logging.service';
import { AvaNotificationsService } from '../services/ava-notifications.service';
import { GeneralAppErrorType } from 'app/generated-client/generated-client';

@Injectable({ providedIn: 'root' })
export class AllRequestsInterceptor implements HttpInterceptor, OnDestroy {
  $destroy: Subject<boolean> = new Subject<boolean>();
  constructor(
    private httpRequestsService: HttpRequestsService,
    private errorLoggingService: ErrorLoggingService,
    private avaNotificationsService: AvaNotificationsService
  ) {}

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.httpRequestsService.incrementRunningRequests();
    let hasHandled = false;

    if (request.url.indexOf('/identity') === -1) {
      this.errorLoggingService.setHttpRequest(request);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorLoggingService.setHttpError(error);

        if (error.error && error.error instanceof Blob) {
          (<Blob>error.error).text().then(errorJson => {
            try {
              if (JSON.parse(errorJson)?.error === GeneralAppErrorType.ProjectArchivedCannotEdit) {
                this.avaNotificationsService.error(
                  'Das Projekt ist archiviert und kann nicht mehr bearbeitet werden. ' +
                    'Zum Bearbeiten bitte Kopie erstellen oder Status ändern.'
                );
              } else if (JSON.parse(errorJson)?.error === GeneralAppErrorType.ProjectLocked) {
                this.avaNotificationsService.error('Das Projekt ist gesperrt und nicht von Dir bearbeitbar.');
              }
            } catch {
              /* Ignore deserialization errors */
            }
          });
        }
        if (error.status === 401) {
          this.httpRequestsService.setRequest(request.url, false, error.status);
          this.httpRequestsService.requests.pipe(takeUntil(this.$destroy)).subscribe();
          hasHandled = true;
          return throwError(error);
        } else if (error.error instanceof ErrorEvent || error.error instanceof ProgressEvent) {
          this.httpRequestsService.setRequest(request.url, true, error.status);
          this.httpRequestsService.requests.pipe(takeUntil(this.$destroy)).subscribe();
          hasHandled = true;
          return throwError(error);
        } else {
          if (error.error && error.error instanceof Blob) {
            return from((<Blob>error.error).text()).pipe(
              mergeMap(() => {
                this.httpRequestsService.setRequest(request.url, true, error.status);
                this.httpRequestsService.requests.pipe(takeUntil(this.$destroy)).subscribe();
                hasHandled = true;
                return throwError(error);
              })
            );
          }

          return from(JSON.stringify(error)).pipe(
            mergeMap(() => {
              this.httpRequestsService.setRequest(request.url, true, error.status);
              this.httpRequestsService.requests.pipe(takeUntil(this.$destroy)).subscribe();
              hasHandled = true;
              return throwError(error);
            })
          );
        }
      }),
      tap(event => {
        if (event.type === HttpEventType.Response) {
          this.httpRequestsService.setRequest(request.url, false, event.status);
          this.httpRequestsService.requests.pipe(takeUntil(this.$destroy)).subscribe();
          hasHandled = true;
        }
      }),
      finalize(() => {
        if (!hasHandled) {
          this.httpRequestsService.setRequest(request.url, false, 999);
        }
      })
    );
  }
}
