import { Component, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

import { dateUTC } from 'app/shared/utilities/dateUTC';

import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-invoice-modal-add-bill',
  templateUrl: './invoice-modal-add-bill.component.html',
  styleUrls: ['./invoice-modal-add-bill.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatDatepicker,
    FlexLayoutDirective,
    MatButton
  ]
})
export class InvoiceModalAddBillComponent implements OnInit {
  newPageForm: FormGroup;

  constructor(@Optional() private matDialogRef: MatDialogRef<InvoiceModalAddBillComponent>) {}

  ngOnInit(): void {
    this.newPageForm = new FormGroup({ billedAtUtc: new FormControl('', Validators.required) });
  }

  cancel(): void {
    this.matDialogRef.close(null);
  }

  createPage(): void {
    const data = { ...this.newPageForm.value };
    data.billedAtUtc = dateUTC(data.billedAtUtc);
    this.matDialogRef.close(data);
  }
}
