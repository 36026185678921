import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'pa-projects-overview',
  templateUrl: './projects-overview.component.html',
  styleUrls: ['./projects-overview.component.scss'],
  standalone: true,
  imports: [RouterOutlet]
})
export class ProjectsOverviewComponent {}
