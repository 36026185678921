import { ReplaySubject, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SelectRowsService {
  listSelectedRows: number[] = [];
  selectedRowStart: number;
  selectedRowLastSaved: number;

  private selectedRowSummaSource = new ReplaySubject<number[]>(1);
  selectedRowSumma = this.selectedRowSummaSource.asObservable();

  private requestAllRowsSumsSource = new Subject<void>();
  requestAllRows = this.requestAllRowsSumsSource.asObservable();

  private selectedIndexRowSource = new ReplaySubject<number>(1);
  selectedIndexRow = this.selectedIndexRowSource.asObservable();

  private selectedRowTagSource = new ReplaySubject<string>(1);
  selectedRowTag = this.selectedRowTagSource.asObservable();

  constructor() {}

  init(): number[] {
    this.listSelectedRows = [];
    this.selectedRowStart = 0;
    this.selectedRowLastSaved = 0;
    this.selectedRowSummaSource.next(null);
    return this.listSelectedRows;
  }

  reset(): number[] {
    return this.init();
  }

  markFirst(rowIndex: number): void {
    this.selectedRowStart = rowIndex;
  }

  getNewList(selectedRowLast: number, isCtrl: boolean, isShift: boolean): number[] {
    if (!selectedRowLast || !this.selectedRowStart) {
      this.selectedRowStart = 0;
      this.selectedRowLastSaved = 0;
      return [...this.listSelectedRows];
    }

    const selected = this.createList(selectedRowLast, this.selectedRowStart);
    if (isCtrl) {
      const addition = selected.filter(item => !this.listSelectedRows.includes(item));
      this.listSelectedRows = this.listSelectedRows.filter(item => !selected.includes(item));
      this.listSelectedRows.push(...addition);
    } else if (isShift && this.selectedRowLastSaved && selected.length === 1) {
      this.listSelectedRows = this.createList(this.selectedRowLastSaved, this.selectedRowStart);
    } else {
      if (selected.length === 1 && this.listSelectedRows.includes(selected[0])) {
        this.listSelectedRows = [];
      } else {
        this.listSelectedRows = selected;
      }
    }

    if (selected.length === 1) {
      [this.selectedRowLastSaved] = selected;
    } else {
      this.selectedRowLastSaved = 0;
    }

    this.selectedRowStart = 0;
    return [...this.listSelectedRows];
  }

  private createList(first: number, second: number): number[] {
    const length = Math.abs(first - second) + 1;
    const min = Math.min(first, second);
    return [...Array(length)].map((_, i: number) => i + min);
  }

  raiseRequestAllRowsSums(): void {
    this.requestAllRowsSumsSource.next();
  }

  setSelectedRowSumma(totalSum: number, totalQuantity: number): void {
    if (totalSum == null && totalQuantity == null) {
      this.selectedRowSummaSource.next(null);
      return;
    }

    this.selectedRowSummaSource.next([totalSum, totalQuantity]);
  }

  setSelectedRowByIndex(index: number): void {
    this.selectedIndexRowSource.next(index);
  }

  setSelectedUnitTag(tag: string): void {
    this.selectedRowTagSource.next(tag);
  }
}
