import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

import { CalculationSelectionClient, PositionCalculation, PositionCalculationTotals } from 'app/generated-client/generated-client';

import { FocusRowService } from 'app/shared/services/focus-row.service';
import { SelectRowsService } from 'app/shared/services/select-rows.service';

@Injectable({
  providedIn: 'root'
})
export class CalculationTotalsService {
  private calculationTotalSource = new ReplaySubject<PositionCalculationTotals>(1);
  calculationTotal = this.calculationTotalSource.asObservable();
  private listWidthSource = new ReplaySubject<number[]>(1);
  listWidth = this.listWidthSource.asObservable();

  private changeActivePriceCompSource = new ReplaySubject<string>(1);
  changeActivePriceComp = this.changeActivePriceCompSource.asObservable();

  private scrollXSource = new Subject<number>();
  scrollX = this.scrollXSource.asObservable();

  constructor(
    private selectRowsService: SelectRowsService,
    private focusRowService: FocusRowService,
    private calculationSelectionClient: CalculationSelectionClient
  ) {}

  calculateDetailsForSelection(calculation: PositionCalculation): void {
    const selectedRows =
      this.selectRowsService.listSelectedRows.length || !this.focusRowService.focusedRowNumber
        ? [...this.selectRowsService.listSelectedRows]
        : [this.focusRowService.focusedRowNumber];
    if (selectedRows.length) {
      this.calculationSelectionClient
        .calculatePositionSelection({
          positionCalculation: calculation,
          selectedRowIndices: selectedRows
        })
        .subscribe((positionCalculationTotals: PositionCalculationTotals) => {
          this.setCurrentTotal(positionCalculationTotals);
        });
      return;
    }
    this.setCurrentTotal(calculation.positionCalculationTotals as PositionCalculationTotals);
  }

  setCurrentTotal(totals: PositionCalculationTotals): void {
    this.calculationTotalSource.next(totals);
  }

  setListWidth(list: number[]): void {
    this.listWidthSource.next(list);
  }

  setActivePriceComp(priceComp: string): void {
    this.changeActivePriceCompSource.next(priceComp);
  }

  setScrollX(scrollX: number): void {
    this.scrollXSource.next(scrollX);
  }
}
