import {Injectable} from '@angular/core';
import {ReplaySubject} from "rxjs";

interface InterceptorError {
  errors: {
    message: []
  }
}

@Injectable({
  providedIn: 'root'
})
export class ErrorLogService {
  private errorSubject = new ReplaySubject<any>(1);
  error = this.errorSubject.asObservable();

  setError(error: InterceptorError): void {
    this.errorSubject.next(error);
  }
}
