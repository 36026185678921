<div class="wrapper" [ngClass]="{ loading: loading }">
  <h2>BIM Mengenermittlung Erstellen</h2>
  <p>Mit den zugeordneten BIM-Mengen kann eine neue Mengenermittlung im Projekt erstellt werden.</p>

  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput placeholder="Name" [(ngModel)]="name" />
  </mat-form-field>

  <div class="buttons">
    <button mat-raised-button color="primary" (click)="createQto()" [disabled]="!name || loading">Erstellen</button>
    <button mat-raised-button color="warn" (click)="cancel()">Abbrechen</button>
  </div>
</div>

<div class="spinner" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>
