import { Injectable, OnDestroy } from '@angular/core';

import { AuthenticationMessenger } from '@dangl/angular-dangl-identity-client';
import { ProjectGet } from '../../generated-client/generated-client';
import { Subject } from 'rxjs';
import { UserInfo } from '@dangl/angular-dangl-identity-client/models/user-info';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LockProjectService implements OnDestroy {
  userInfo: UserInfo;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(private authenticationMessenger: AuthenticationMessenger) {
    this.authenticationMessenger.userInfo.pipe(takeUntil(this.$destroy)).subscribe(ui => (this.userInfo = ui));
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  currentUserHasAccess(project: ProjectGet): boolean {
    const hasAnyRestrictions = project?.projectUserAccessRestrictions?.length > 0;
    if (!hasAnyRestrictions) {
      return true;
    }

    return project.projectUserAccessRestrictions.some(access => access.userId === this.userInfo.id);
  }

  getCurrentUserId(): string {
    return this.userInfo?.id;
  }
}
