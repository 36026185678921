import { CommonModule } from '@angular/common';
import { Component, Inject, inject, Optional } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { CreatePriceInquiryRequestsComponent } from '@serv-spec/components/create-price-inquiry-requests/create-price-inquiry-requests.component';

import { UploadFileComponent, UploadFileSettings } from '@shared/components/upload-file/upload-file.component';
import { ConfirmationType } from '@shared/models/dialog-config.model';
import { AvaNotificationsService } from '@shared/services/ava-notifications.service';
import { ModalService } from '@shared/services/modal.service';

import { PriceInquiryRequestGet, PriceInquiryRequestsClient } from 'app/generated-client/generated-client';

import { PriceInquiryTableService } from '../../services/price-inquiry-table.service';

@Component({
  selector: 'pa-price-inquiries-uploader',
  standalone: true,
  imports: [CommonModule, UploadFileComponent, MatButtonModule, MatIconModule, MatDialogModule],
  templateUrl: './price-inquiries-uploader.component.html',
  styleUrls: ['./price-inquiries-uploader.component.scss']
})
export class PriceInquiriesUploaderComponent {
  uploadFileSettings: UploadFileSettings = {
    label: 'Datei zum Hochladen hierher ziehen, GAEB oder Excel',
    isMultipleSelection: false
  };

  modalService = inject(ModalService);
  notificationsService = inject(AvaNotificationsService);
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { requestIds: { projectId: string; avaProjectId: string; priceInquiryId: string } },
    @Optional() private matDialogRef: MatDialogRef<PriceInquiriesUploaderComponent>,
    private priceInquiryRequestsClient: PriceInquiryRequestsClient,
    private priceInquiryTableService: PriceInquiryTableService
  ) {}

  onSelect(addedFiles: File[]): void {
    if (addedFiles?.length) {
      const addedFile = addedFiles[0];
      const file = {
        data: addedFile,
        fileName: addedFile.name
      };
      this.modalService
        .openModal(CreatePriceInquiryRequestsComponent, {
          dialogType: ConfirmationType.General,
          autoFocus: false,
          height: '402px',
          data: { ...this.data, priceInquiryName: file.fileName.split('.')[0] }
        })
        .afterClosed()
        .subscribe((p: PriceInquiryRequestGet) => {
          if (!p) {
            this.matDialogRef.close();
            return;
          }
          const { projectId, avaProjectId } = this.data.requestIds;
          this.priceInquiryRequestsClient.importSubContractorOffer(projectId, avaProjectId, p.priceInquiryId, p.id, file).subscribe({
            next: () => {
              this.notificationsService.success('Angebot eingelesen');
              this.priceInquiryTableService.refresh();
            },
            error: () => this.notificationsService.error('Datei konnte nicht gespeichert werden')
          });
        });
    }
  }
}
