import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { CdkOverlayOrigin, CdkConnectedOverlay } from '@angular/cdk/overlay';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { NgIf, NgStyle, NgFor, NgClass, AsyncPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadge } from '@angular/material/badge';
import { MatButton } from '@angular/material/button';
import { MatButtonToggleGroup, MatButtonToggle } from '@angular/material/button-toggle';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatMenuTrigger, MatMenu, MatMenuItem, MatMenuContent } from '@angular/material/menu';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { GuidGenerator } from '@dangl/angular-material-shared/guid-generator';

import { Observable, Subject, Subscription, combineLatest, fromEvent, of, timer } from 'rxjs';
import { concatMap, debounceTime, filter, first, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { InvoicePdfFileComponent } from '@serv-spec/components/invoice/components/invoice-pdf-file/invoice-pdf-file.component';
import { UpdateInvoicePageService } from '@serv-spec/components/invoice/services/update-invoice-page.service';
import { DbclickSubscriptionService } from '@serv-spec/services/dbclick-subscription.service';
import { KeyupControlService } from '@serv-spec/services/keyup-control.service';
import { LimitColumnWidthService } from '@serv-spec/services/limit-column-width.service';
import { ManageInvoiceTableService } from '@serv-spec/services/manage-invoice-table.service';

import { ClipboardTableDataService } from '@shared/services/clipboard-table-data.service';

import { ProjectFilesComponent } from '@project/components/project-files/components/project-files/project-files.component';
import { ProjectCurrentItemsService } from '@project/components/project-files/services/project-current-items.service';
import { SelectedFileToUploadService } from '@project/components/project-files/services/selected-file-to-upload.service';

import { getAppConfig } from 'app/app-config-accessor';
import { SelectContactModalComponent } from 'app/areas/contacts/components/select-contact-modal/select-contact-modal.component';
import { getParentNode } from 'app/areas/tree/utils/fn';
import {
  ApiErrorOfFormulaError,
  AvaProjectInvoicedPositionsGet,
  BuildingElementCodeGet,
  BuildingElementCodesClient,
  ContactGet,
  ContactType,
  FormulaErrorType,
  IElementDto,
  ItemNumberSchemaDto,
  ItemNumberSchemaTierDto,
  ItemNumberSchemasClient,
  PageQuantityTakeOffCalculationDataModel,
  PageQuantityTakeOffGet,
  PageQuantityTakeOffRowModel,
  PageQuantityTakeOffsClient,
  PositionDto,
  PositionInvoiceTotalGet,
  ProjectDto,
  ProjectFileGet,
  ProjectGet,
  ProjectQuantityTakeOffType,
  QuantitySheetItem,
  QuantitySheetReport,
  QuantityTakeOffGet,
  QuantityTakeOffPositionSumsClient,
  QuantityTakeOffRowType,
  ReportsClient,
  ServiceSpecificationDto,
  ServiceSpecificationGroupDto,
  UserSettings
} from 'app/generated-client/generated-client';
import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { ShowingColumnsModalComponent } from 'app/shared/components/showing-columns-modal/showing-columns-modal.component';
import { StyleEnumType } from 'app/shared/models';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { ContextMenuSettingsService } from 'app/shared/services/context-menu-settings.service';
import { TreeViewMessengerService } from 'app/shared/services/electron/tree-view-messenger.service';
import { ContactsService } from 'app/shared/services/lightquery/contacts.service';
import { PageQuantityTakeOffsService } from 'app/shared/services/lightquery/page-quantity-take-offs.service';
import { PagesAllAvaProjectService } from 'app/shared/services/lightquery/pages-all-ava-project.service';
import { OverwriteModeMessengerService } from 'app/shared/services/messengers/overwrite-mode-messenger.service';
import { QuantityTakeOffInvoiceTotalsMessengerService } from 'app/shared/services/messengers/quantity-take-off-invoice-totals-messenger.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedQuantityTakeOffMessengerService } from 'app/shared/services/messengers/selected-quantity-take-off-messenger.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';
import { SelectRowsService } from 'app/shared/services/select-rows.service';
import { ShowingColumnsService } from 'app/shared/services/showing-columns.service';
import { AvaHubConnector } from 'app/shared/services/signalr/ava-hub-connector';
import { UserSettingsService } from 'app/shared/services/user-settings.service';
import { nameof } from 'app/shared/utilities/name-of';

import { ColorPickerComponent } from '../../../../../../../../shared/components/color-picker/color-picker.component';
import { PositionLineComponent } from '../../../../../../../../shared/components/position-line/position-line.component';
import { ProgressbarPercentageComponent } from '../../../../../../../../shared/components/progressbar-percentage/progressbar-percentage.component';
import { SelectAllRowsComponent } from '../../../../../../../../shared/components/select-all-rows/select-all-rows.component';
import { FormulaWarningDirective } from '../../../../../../../../shared/directives/formula-warning.directive';
import { InputMaskDirective } from '../../../../../../../../shared/directives/input-mask.directive';
import { OverwriteModeDirective } from '../../../../../../../../shared/directives/overwrite-mode.directive';
import { ImgSrcPipe } from '../../../../../../../../shared/pipes/img-src.pipe';
import { SafeHtmlPipe } from '../../../../../../../../shared/pipes/safe-html.pipe';
import { PositionTextPipe } from '../../../../../../../../shared/pipes/ui-data-display/position-text.pipe';
import { ProjectCurrencyPipe } from '../../../../../../../../shared/pipes/ui-data-display/project-currency.pipe';
import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';
import { TotalSumsComponent } from '../../../total-sums/total-sums.component';
import { ResizableDirective } from '../../directives/resizable.directive';
import { CheckFormulaErrorService } from '../../services/check-formula-error.service';
import { SetFontStyleService } from '../../services/set-font-style.service';
import { TreeDisplayService } from '../../services/tree-display.service';

import { InvoiceImageModalComponent } from '../invoice-image-modal/invoice-image-modal.component';
import { InvoiceModalForDeletingImageComponent } from '../invoice-modal-for-deleting-image/invoice-modal-for-deleting-image.component';
import { InvoicePagePaginatorComponent } from '../invoice-page-paginator/invoice-page-paginator.component';
import { Calculator } from 'antlr-calculator';
import { TableVirtualScrollDataSource, TableVirtualScrollModule } from 'ng-table-virtual-scroll';

type PositionNumberListType = { id: string; num: string; shortText: string; longText: string; parentId: string };

@Component({
  selector: 'pa-invoice-page',
  templateUrl: './invoice-page.component.html',
  styleUrls: ['./invoice-page.component.scss'],
  providers: [OverwriteModeMessengerService],
  standalone: true,
  imports: [
    NgIf,
    FlexLayoutDirective,
    MatProgressSpinner,
    MatButton,
    InvoicePagePaginatorComponent,
    MatCheckbox,
    FormsModule,
    MatTooltip,
    MatIcon,
    NgStyle,
    ColorPickerComponent,
    MatMenuTrigger,
    MatMenu,
    NgFor,
    MatMenuItem,
    MatInput,
    PositionLineComponent,
    CdkVirtualScrollViewport,
    TableVirtualScrollModule,
    MatTable,
    CdkDropList,
    NgClass,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    CdkDrag,
    SelectAllRowsComponent,
    MatCellDef,
    MatCell,
    CdkOverlayOrigin,
    CdkDragHandle,
    ResizableDirective,
    OverwriteModeDirective,
    FormulaWarningDirective,
    InputMaskDirective,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    TotalSumsComponent,
    MatMenuContent,
    MatButtonToggleGroup,
    MatButtonToggle,
    MatBadge,
    CdkConnectedOverlay,
    ProgressbarPercentageComponent,
    MatProgressBar,
    AsyncPipe,
    DecimalPipe,
    PercentPipe,
    SafeHtmlPipe,
    ProjectCurrencyPipe,
    ImgSrcPipe,
    PositionTextPipe
  ]
})
export class InvoicePageComponent implements OnInit, OnDestroy {
  @Input() isStandalonePage: boolean;
  @Input() isReadOnlyOriginal: boolean;
  get isReadOnly(): boolean {
    return this.isReadOnlyOriginal || this.isChecked || this.isSelectingFormulaRowMode;
  }
  @ViewChild('selectPictureOrAttachmentMenuTrigger') private selectPictureOrAttachmentMenuTrigger: MatMenuTrigger;
  @ViewChild('rowHeaderContextMenuTrigger') private rowHeaderContextMenuTrigger: MatMenuTrigger;
  @ViewChild('focused') private focused: ElementRef;
  @ViewChild(MatTable) private table: MatTable<any>;
  @ViewChild('changeSizeMenu') changeSizeMenu: MatMenuTrigger;
  @ViewChild(CdkVirtualScrollViewport) private viewTable: CdkVirtualScrollViewport;
  @ViewChild('tableViewport') private tableViewport: CdkVirtualScrollViewport;
  @HostListener('document:mouseup', ['$event']) handleMouseUpEvent(event: MouseEvent): void {
    this.stopSelectRow(event);
  }

  @HostListener('document:keyup', ['$event']) handleKeyboardUpEvent(event: KeyboardEvent): void {
    switch (event.key) {
      case 'c':
      case 'C':
        if (event.ctrlKey) {
          if (this.listSelectedRows.length) {
            this.formClipboardTable();
          } else {
            this.numRowsClipboardTable = null;
          }
        }
        break;
      case 'x':
      case 'X':
        if (event.ctrlKey) {
          if (this.listSelectedRows.length) {
            this.formClipboardTable(() => this.deleteSelectedRows());
          } else {
            this.numRowsClipboardTable = null;
          }
        }
        break;
      case 'F5':
        if (this.listSelectedRows.length) {
          this.cutSelectedRowsPlus();
        }
        break;
      case 'PageUp':
        if (this.viewTable) {
          const goUp = this.viewTable.measureScrollOffset('top') - this.viewTable.getViewportSize() + 50;
          this.viewTable.scrollToOffset(goUp > 0 ? goUp : 0);
        }
        break;
      case 'PageDown':
        if (this.viewTable) {
          const goDown = this.viewTable.measureScrollOffset('top') + this.viewTable.getViewportSize() - 50;
          this.viewTable.scrollToOffset(goDown);
        }
        break;
      case 'Delete':
        this.listSelectedRows = event.altKey ? [this.focusedRow?.rowIndex] : [...this.listSelectedRows];
        if (this.listSelectedRows.length && !this.isReadOnly && (!event.altKey || !!this.focusedRow?.rowIndex)) {
          this.deleteSelectedRowsPlus(event.altKey);
        }
        break;
      case 'F7':
        if (this.listSelectedRows.length) {
          this.isSelectingFormulaRowMode = true;
          this.notificationsService.info('Zeile zum Einfügen der Referenz auswählen');
        }
        break;
      case 'Escape':
        if (this.isSelectingFormulaRowMode) {
          this.isSelectingFormulaRowMode = false;
        }
        break;
      default:
    }
  }

  userSettings: UserSettings = {} as UserSettings;
  currentPage: PageQuantityTakeOffGet;

  // This is used internally if something is changed, so we can then revert it back
  // if the user e.g. cancels creating a new building element code
  private originalPageData: PageQuantityTakeOffGet;
  contextMenuPosition = { x: '0px', y: '0px' };

  get qtoRows(): PageQuantityTakeOffRowModel[] {
    return this.tableDataSource.data;
  }

  set qtoRows(value: PageQuantityTakeOffRowModel[]) {
    this.tableDataSource.data = value;
  }

  tableDataSource = new TableVirtualScrollDataSource<PageQuantityTakeOffRowModel>();

  savedSelectedRows: PageQuantityTakeOffRowModel[] = [];
  listSelectedRows: number[] = this.selectRowsService.init();
  _defaultDisplayedColumns: string[] = [
    'index',
    'rowType',
    'itemNumber',
    'formula',
    'shortText',
    'result',
    'referenceName',
    'unit',
    'totalCost',
    'image'
  ];
  defaultDisplayedColumns: string[];
  columnsToDisplaySearchTable: string[] = [];
  mainColumnsToDisplaySearchTable: string[] = [];
  filteredListArr = new TableVirtualScrollDataSource<PositionNumberListType>();

  displayedColumns: string[] = [];
  invoicePageNumber: string;
  invoicePageInvoiceName: string;
  invoicePageName: string;
  isLoading = true;
  positions: { [positionId: string]: PositionDto } = {};
  positionUnits: { [positionId: string]: string } = {};
  positionUnitPrice: { [positionId: string]: number } = {};
  positionQuantity: { [positionId: string]: number } = {};
  positionPercentage: { [itemNumber: string]: QuantitySheetItem } = {};
  isShowQuantityColumn: boolean;
  positionNumberListAll: PositionNumberListType[] = [];
  selectedElement: IElementDto;
  structureView: string;
  url: string;
  isCompact = true;
  isTreeOpen = false;
  tableView = 'position';
  buildingElementCode: string;
  buildingElementCodeId: string;
  subContractor: ContactGet;
  isShowReferenceName: boolean;
  isChecked: boolean;
  isSelectingFormulaRowMode: boolean;
  focusedRow: PageQuantityTakeOffRowModel = null;
  isOpenListNumber = false;
  trigger: CdkOverlayOrigin;
  isOpenListNumberLongText = false;
  triggerLongText: CdkOverlayOrigin;
  showedLongText: PositionDto;
  serviceSpecification: ServiceSpecificationDto;
  filter = '';
  selectedPosition: PositionDto = null;
  colors = ['#c41e3a', '#ff0000', '#ffa500', '#ffff00', '#9cee90', '#00cc00', '#add8e6', '#0000ff', '#00008b', '#000000'];
  sizes: { size: number; title: string }[] = [
    { size: -2, title: 'Klein' },
    { size: 0, title: 'Normal' },
    { size: 4, title: 'Mittel' },
    { size: 8, title: 'Groß' }
  ];

  activeIndex = 0;
  isMultiSelect: boolean;
  includeHeaderOnlyOnFirstPage = true;
  multiSelect = new Map<string, number>();
  isSearchLongText: boolean;
  maxHeight: string;
  isImageContextMenu: boolean;
  private clipboardWord = 'pageQto'; // variable to change by setting object
  private listMove: number[];
  private activeElement: PageQuantityTakeOffRowModel;
  private activeRow: HTMLElement;
  private activeRowId: string;
  private activeRowIndex: number;
  private avaProjectId: string;
  private calculationData: PageQuantityTakeOffCalculationDataModel;
  private calculationInProgress = false;
  private calculationQueue = 0;
  private dialogRef: MatDialogRef<ProjectFilesComponent>;
  private projectId: string;
  private quantityTakeOffId: string;
  private quantityTakeOffPageId: string;
  private selectedFile: File;
  private $destroy: Subject<boolean> = new Subject<boolean>();
  private lastFocusedRowIndex: number;
  private canLose = false;
  private flatPositions: PositionDto[] = [];
  isToggleTreeWidth: boolean;
  isCursorOutsideText = true;
  circularErrorRowIndizes: { [rowIndex: number]: boolean } = {};
  lastRecalculationHadError = false;
  selectOriginOverlay: CdkOverlayOrigin | null = null;
  totalSumHeight = 0;
  columnName: string;

  private filterSource = new Subject<boolean>();
  $filter = this.filterSource.asObservable();
  percentegByActivePosition: number;
  positionsTotals: PositionInvoiceTotalGet[] | null = null;
  mousemove$: Subscription;
  schema: ItemNumberSchemaDto;
  listContact: ContactGet[] = [];
  tryGoBack: boolean;
  projectForQtoType: ProjectQuantityTakeOffType;
  lastFocusedElementId: string | null = null;
  nameStorageOrderColumn = 'orderColumns-pageQto';
  numRowsClipboardTable: number | null = null;
  fieldOriginOverlay: CdkOverlayOrigin | null = null;
  avoidMultiSelect = false;
  isPasteNewNumberMode = false;
  newNumberForPaste: string | null = null;
  isReplaceRows = false;

  updateInvoicePageService = inject(UpdateInvoicePageService);

  constructor(
    private activatedRoute: ActivatedRoute,
    private contextMenuSettingsService: ContextMenuSettingsService,
    private dbclickSubscriptionService: DbclickSubscriptionService,
    private keyupControlService: KeyupControlService,
    private manageInvoiceTableService: ManageInvoiceTableService,
    private modalService: ModalService,
    private pageQuantityTakeOffsClient: PageQuantityTakeOffsClient,
    private projectCurrentItemsService: ProjectCurrentItemsService,
    private router: Router,
    private route: ActivatedRoute,
    private selectedFileToUploadService: SelectedFileToUploadService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private selectedQuantityTakeOffMessengerService: SelectedQuantityTakeOffMessengerService,
    private setFontStyleService: SetFontStyleService,
    private notificationsService: AvaNotificationsService,
    private userSettingsService: UserSettingsService,
    private selectRowsService: SelectRowsService,
    private avaHubConnector: AvaHubConnector,
    private quantityTakeOffInvoiceTotalsMessengerService: QuantityTakeOffInvoiceTotalsMessengerService,
    private treeViewMessengerService: TreeViewMessengerService,
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private buildingElementCodesClient: BuildingElementCodesClient,
    private treeDisplayService: TreeDisplayService,
    private showingColumnsService: ShowingColumnsService,
    private pageQuantityTakeOffsService: PageQuantityTakeOffsService,
    private pagesAllAvaProjectService: PagesAllAvaProjectService,
    public sanitizer: DomSanitizer,
    private quantityTakeOffPositionSumsClient: QuantityTakeOffPositionSumsClient,
    private el: ElementRef,
    private limitColumnWidthService: LimitColumnWidthService,
    private itemNumberSchemasClient: ItemNumberSchemasClient,
    private reportsClient: ReportsClient,
    private contactsService: ContactsService,
    private clipboardTableDataService: ClipboardTableDataService,
    private checkFormulaErrorService: CheckFormulaErrorService
  ) {}

  private formClipboardTable(fnAfter?: () => void, rows?: PageQuantityTakeOffRowModel[]): void {
    const selectedRows = rows || this.qtoRows.filter((item: PageQuantityTakeOffRowModel) => this.listSelectedRows.includes(item.rowIndex));
    // Now we're building a tab-separated table
    const result = selectedRows.reduce((res, row) => {
      const rowType =
        row.rowType === QuantityTakeOffRowType.Intermediate ? 'Z' : row.rowType === QuantityTakeOffRowType.Internal ? 'I' : '';
      const rowValues = [rowType, row.itemNumber, row.formula, row.result, row.referenceName];
      res += rowValues.join('\t') + '\r\n';
      return res;
    }, '');
    navigator.clipboard.writeText(result).then(() => {
      if (selectedRows.length) {
        const message = `${selectedRows.length} Zeile${selectedRows.length > 1 ? 'n' : ''}`;
        this.notificationsService.info(message, 'Zeilen kopiert:', 3000);
        if (fnAfter) {
          fnAfter();
        }
      }
    });
  }

  private handlePastedText(tableData: string[][]): void {
    if (!this.lastFocusedRowIndex) {
      return;
    }

    const pastedRows: PageQuantityTakeOffRowModel[] = tableData.map((row) => {
      const newRow: PageQuantityTakeOffRowModel = {};
      row.forEach((cell, index) => {
        switch (index) {
          case 0:
            switch (cell) {
              case 'Z':
                newRow.rowType = QuantityTakeOffRowType.Intermediate;
                break;
              case 'I':
                newRow.rowType = QuantityTakeOffRowType.Internal;
                break;
            }
            break;
          case 1:
            newRow.itemNumber = this.newNumberForPaste || cell;
            break;
          case 2:
            newRow.formula = cell;
            break;
          case 3:
            newRow.result = +cell;
            break;
          case 4:
            newRow.referenceName = cell;
            break;
        }
      });
      return newRow;
    });

    let index = this.lastFocusedRowIndex;
    if (index > 0) {
      index--;
    }

    const currentRow = this.tableDataSource.data[index];

    this.qtoRows = this.manageInvoiceTableService.addRow(currentRow, false, !this.isReplaceRows, this.qtoRows, pastedRows);
    this.recalculate();
    this.newNumberForPaste = null;
    this.isReplaceRows = false;
  }

  ngOnInit(): void {
    this.clipboardTableDataService.announceListener();

    this.clipboardTableDataService.tablePasted$.pipe(takeUntil(this.$destroy)).subscribe((tableData) => this.handlePastedText(tableData));

    this.defaultDisplayedColumns = this.showingColumnsService.getDefaultDisplayedColumns(
      this.nameStorageOrderColumn,
      this._defaultDisplayedColumns
    );
    this.structureView = this.router.url.includes('invoices') ? 'invoices' : 'estimations';
    if (this.structureView === 'estimations') {
      this.defaultDisplayedColumns = this.defaultDisplayedColumns.filter((item: string) => item !== 'totalCost');
    }
    this.url = 'invoice' + this.router.url;
    this.userSettingsService.currentFullSettings.pipe(takeUntil(this.$destroy)).subscribe((setting: UserSettings) => {
      this.userSettings = setting;
      if (!this.isLoading) {
        this.changeShowReferenceName();
      }
    });

    this.treeDisplayService.isTreeDisplay.pipe(takeUntil(this.$destroy)).subscribe((isTreeDisplay: boolean) => {
      this.isToggleTreeWidth = isTreeDisplay;
    });

    this.avaHubConnector.userKicked.pipe(takeUntil(this.$destroy)).subscribe((kickResult) => {
      if (kickResult.isSuccess) {
        this.isReadOnlyOriginal = true;
      }
    });

    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        takeUntil(this.$destroy),
        filter((servSpec) => !!servSpec)
      )
      .subscribe((servSpec) => {
        this.serviceSpecification = servSpec.project.serviceSpecifications[0];
        this.initializePositionsList(servSpec.project.serviceSpecifications[0].elements);
        this.loadPositionShortTexts();
      });

    this.selectedSpecificationElementMessengerService.keyboardNavigationTreePositionSelected
      .pipe(takeUntil(this.$destroy))
      .subscribe((chosenPosition: PositionDto) => {
        this.handlePositionSelectedViaKeyboardNavigationInTree(chosenPosition);
      });

    this.setActiveFirstCellOnDbClick();

    this.selectedQuantityTakeOffMessengerService.changedQuantityTakeOffPage
      .pipe(takeUntil(this.$destroy))
      .subscribe(() => this.getMainData());

    this.treeViewMessengerService.treeViewVisible.pipe(takeUntil(this.$destroy)).subscribe((isTreeOpen: boolean) => {
      this.isTreeOpen = isTreeOpen;
    });

    this.selectedSpecificationElementMessengerService.selectedElement.pipe(takeUntil(this.$destroy)).subscribe((selectedElement) => {
      if (selectedElement?.element?.elementTypeDiscriminator === 'PositionDto') {
        this.selectedPosition = selectedElement.element as PositionDto;
      } else {
        this.selectedPosition = null;
      }
    });

    this.showingColumnsService.settingsLoaded.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.displayedColumns = this.showingColumnsService.getFilteredColumns('PageQto', this.defaultDisplayedColumns);
    });

    this.selectRowsService.requestAllRows.pipe(takeUntil(this.$destroy)).subscribe(() => {
      const summa = this.qtoRows.reduce((sum: number, item: PageQuantityTakeOffRowModel) => sum + (item.totalCost ? item.totalCost : 0), 0);
      const quantity = this.qtoRows.reduce(
        (sum: number, item: PageQuantityTakeOffRowModel) => sum + (typeof item.result === 'number' ? item.result : 0),
        0
      );

      this.selectRowsService.setSelectedRowSumma(summa, quantity);
    });

    this.$filter
      .pipe(
        takeUntil(this.$destroy),
        debounceTime(500),
        concatMap(() => {
          this.filteredList();
          return timer(0);
        })
      )
      .subscribe(() => {
        this.checkHeight();
      });

    this.contactsService.getAll().subscribe((e: ContactGet[]) => {
      this.listContact = e;
      this.getMainData();
    });

    this.selectedQuantityTakeOffMessengerService.errorRowIndexQuantityTakeOff.pipe(takeUntil(this.$destroy)).subscribe((e) => {
      if (e?.isSameUrl) {
        this.tryScrollToErrorRow();
      }
    });
  }

  initializePositionsList(elements: IElementDto[]): void {
    this.flatPositions = [];
    this.loadFlatElements(elements);
  }

  private handlePositionSelectedViaKeyboardNavigationInTree(position: PositionDto): void {
    if (!position) {
      return;
    }

    if (this.lastFocusedRowIndex != null) {
      const focusedRow = this.qtoRows.find((r) => r.rowIndex === this.lastFocusedRowIndex);
      if (focusedRow) {
        focusedRow.avaPositionId = position.id;
        const focusElementId = `${this.lastFocusedRowIndex}-formula`;
        this.recalculate(this.lastFocusedRowIndex, focusElementId);
      }
    }
  }

  private loadFlatElements(elements: IElementDto[]) {
    elements.forEach((element: IElementDto) => {
      if (element.elementType === 'PositionDto') {
        this.flatPositions.push(element);
      }

      if (element.elementType === 'ServiceSpecificationGroupDto') {
        this.loadFlatElements((<ServiceSpecificationGroupDto>element).elements);
      }
    });
  }

  ngOnDestroy(): void {
    this.clipboardTableDataService.removeListener();

    this.$destroy.next(true);
    this.$destroy.complete();
    this.treeDisplayService.setTreeDisplay(false);
    this.selectedQuantityTakeOffMessengerService.setSelectedQuantityTakeOffPage(null);
    this.quantityTakeOffInvoiceTotalsMessengerService.resetTotals();
    if (this.projectId && this.avaProjectId && this.quantityTakeOffId && this.quantityTakeOffPageId) {
      this.avaHubConnector.notifyOfExitingQuantityTakeOffPage(
        this.projectId,
        this.avaProjectId,
        this.quantityTakeOffId,
        this.quantityTakeOffPageId
      );
    }
    if (this.mousemove$) {
      this.mousemove$.unsubscribe();
    }
  }

  private getMainData(): void {
    this.getQuantityTakeOff()
      .pipe(takeUntil(this.$destroy))
      .subscribe(
        (element: PageQuantityTakeOffGet) => {
          this.quantityTakeOffInvoiceTotalsMessengerService.setCurrentTotalSum({
            ...element.calculation,
            pageTotalSum: element.totalSum
          });

          this.circularErrorRowIndizes = {};
          this.currentPage = element;
          this.originalPageData = JSON.parse(JSON.stringify(element));
          this.invoicePageName = element.name;
          this.invoicePageNumber = element.pageNumber;
          this.calculationData = element.pageData;

          this.qtoRows = element.pageData.rows;
          this.updateMainData(element);
          this.changeShowReferenceName();
          this.isLoading = false;
          setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
          setTimeout(() => this.tryScrollToErrorRow(), 100);
        },
        (error) => console.error(error)
      );
  }

  tryScrollToErrorRow(): void {
    this.selectedQuantityTakeOffMessengerService.errorRowIndexQuantityTakeOff.pipe(take(1), takeUntil(this.$destroy)).subscribe((e) => {
      if (e) {
        this.checkIfRowShowed(e.errorRowIndex);
        this.listSelectedRows = [e.errorRowIndex];
        this.selectRowsService.listSelectedRows = [e.errorRowIndex];
        this.selectedQuantityTakeOffMessengerService.setErrorRowIndex(null);
      }
    });
  }

  checkIfRowShowed(errorRowIndex: number): void {
    const viewport = this.viewTable.getElementRef().nativeElement;
    const showedLine = viewport.querySelector(`.row-index${errorRowIndex}`);
    if (showedLine) {
      const viewportRect = viewport.getBoundingClientRect();
      const showedLineRect = showedLine.getBoundingClientRect();
      if (showedLineRect.bottom < viewportRect.bottom && showedLineRect.top > viewportRect.top) {
        return;
      }
    }
    this.viewTable.scrollToIndex(errorRowIndex - 1);
  }

  private loadPositionShortTexts() {
    this.flatPositions.forEach((position: PositionDto) => {
      this.positions[position.id] = position;
      this.positionUnits[position.id] = position.unitTag;
      this.positionUnitPrice[position.id] = position.unitPrice;
      this.positionQuantity[position.id] = position.quantity;
      this.positionNumberListAll.push({
        id: position.id,
        num: position.itemNumber.stringRepresentation,
        shortText: position.shortText,
        longText: position.htmlLongText,
        parentId: getParentNode(position.id, this.serviceSpecification).id
      });
    });
  }

  /**
   * This checks if the itemNumber property of the row is empty or null and if yes,
   * sets the avaPositionId value also to null to ensure the position reference is cleared.
   * @param row
   */
  removePositionReferenceFromRowIfItemNumberUnset(event: Event, row: PageQuantityTakeOffRowModel): void {
    row.avaPositionId = null;
    row.unitTag = null;
  }

  /**
   * This is called whenever the focus in a row in the table changes, so that the component can track
   * which row was active last.
   * @param row
   */
  setRowFocus(event: Event, row: PageQuantityTakeOffRowModel, selectOriginOverlay?: CdkOverlayOrigin): void {
    this.lastFocusedElementId = event.target['id'];
    this.lastFocusedRowIndex = row.rowIndex;
    this.getFocus(row);
    this.setPosition(row.avaPositionId);
    this.setOriginOverlay(selectOriginOverlay);
  }

  private setPosition(avaPositionId: string): void {
    this.selectedSpecificationElementMessengerService.trySelectElementById(avaPositionId);
  }

  private setActiveFirstCellOnDbClick(): void {
    this.dbclickSubscriptionService.dbclick.pipe(takeUntil(this.$destroy)).subscribe((position: PositionDto) => {
      if (position && this.lastFocusedRowIndex) {
        const row = this.qtoRows.find((r) => r.rowIndex === this.lastFocusedRowIndex);
        if (row) {
          row.itemNumber = position.itemNumber.stringRepresentation;
          row.avaPositionId = position.id;
          this.recalculate();
        }
      }
    });
  }

  private getIdsForQuantityRequest(): Observable<[ProjectGet, { avaProjectId: string; project: ProjectDto }, QuantityTakeOffGet, Params]> {
    return combineLatest([
      this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)),
      this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(
        takeUntil(this.$destroy),
        filter((v) => !!v)
      ),
      this.isStandalonePage
        ? of(null)
        : this.selectedQuantityTakeOffMessengerService.selectedQuantityTakeOff.pipe(
            filter((v) => !!v),
            takeUntil(this.$destroy)
          ),
      this.activatedRoute.params.pipe(first())
    ]).pipe(
      takeUntil(this.$destroy),
      tap((x) => {
        const projectId = x[0]?.id;
        const avaProjectId = x[1]?.avaProjectId;
        const quantityTakeOffId = x[2]?.id;
        const pageId: string = x[3]?.pageId;

        if (projectId && avaProjectId && quantityTakeOffId && pageId) {
          if (pageId !== this.quantityTakeOffPageId && this.quantityTakeOffPageId) {
            this.avaHubConnector.notifyOfExitingQuantityTakeOffPage(projectId, avaProjectId, quantityTakeOffId, this.quantityTakeOffPageId);
          }

          this.avaHubConnector.tryEnterQuantityTakeOffPage(projectId, avaProjectId, quantityTakeOffId, pageId).then((r) => {
            if (!r.isSuccess && r.pageId === this.quantityTakeOffPageId) {
              this.isReadOnlyOriginal = true;
              this.notificationsService.info('Dieses Blatt wird gerade von einem anderen Benutzer bearbeitet.');
            }
          });
        }
      })
    );
  }

  private getQuantityTakeOff(): Observable<PageQuantityTakeOffGet> {
    return this.getIdsForQuantityRequest().pipe(
      switchMap(
        ([project, avaProject, qto, params]: [ProjectGet, { avaProjectId: string; project: ProjectDto }, QuantityTakeOffGet, Params]) => {
          this.projectId = project.id;
          this.projectForQtoType = project.allowedQuantityTakeOffTypes;
          this.avaProjectId = avaProject.avaProjectId;
          this.invoicePageInvoiceName = qto?.name;
          this.quantityTakeOffId = qto?.id;
          if (qto?.markedAsBilledAtUtc) {
            this.isReadOnlyOriginal = true;
          }
          this.quantityTakeOffPageId = params['pageId'];
          this.getTotalInvoicedQuantityForAllPositionsInAvaProject();
          this.getSchema();
          this.getSheet();

          return this.pageQuantityTakeOffsClient.getPageQuantityTakeOffById(this.projectId, this.avaProjectId, this.quantityTakeOffPageId);
        }
      )
    );
  }

  private setNullValueToFieldsDataSource(item: PageQuantityTakeOffRowModel): void {
    this.manageInvoiceTableService.setNullValueToFieldsDataSource(item, this.qtoRows);
  }

  onContextMenu(
    event: MouseEvent,
    element: PageQuantityTakeOffRowModel,
    columnName?: string,
    selectOriginOverlay?: CdkOverlayOrigin
  ): void {
    if (!this.isReadOnly) {
      this.numRowsClipboardTable = this.clipboardTableDataService.checkData();
      this.fieldOriginOverlay = selectOriginOverlay;
      this.columnName = columnName;
      this.isImageContextMenu = !!this.columnName && columnName === 'image';
      this.activeRow = event.target['offsetParent'].parentNode;
      this.activeRowIndex = element?.rowIndex;
      this.activeElement = element;
      this.contextMenuSettingsService.setDefaultSettings(
        event,
        element,
        this.contextMenuPosition,
        this.selectPictureOrAttachmentMenuTrigger
      );
    }
  }

  addRow(nextRow: PageQuantityTakeOffRowModel, addAfter: boolean, isRecalculate = false): void {
    this.qtoRows = this.manageInvoiceTableService.addRow(nextRow, addAfter, true, this.qtoRows);
    const focusedElementId = `${nextRow.rowIndex}-formula`;
    if (isRecalculate) {
      if (this.activeRowId) {
        this.canLose = false;
        this.recalculate(0, focusedElementId);
      } else {
        this.recalculate();
      }
    }
  }

  private copyRowAbove(event: KeyboardEvent): void {
    if (this.lastFocusedRowIndex > 1) {
      const copyRow = { ...this.qtoRows[this.lastFocusedRowIndex - 2], rowIndex: this.lastFocusedRowIndex };
      this.qtoRows = this.qtoRows.map((item: PageQuantityTakeOffRowModel, i: number) =>
        i === this.lastFocusedRowIndex - 1 ? { ...copyRow } : { ...item }
      );
      this.recalculate(0, event.target['id']);
    }
  }

  private copyPropAbove(event: KeyboardEvent, nameProp: string): void {
    if (this.lastFocusedRowIndex > 1) {
      const rowToUpdate = this.qtoRows[this.lastFocusedRowIndex - 1];
      rowToUpdate[nameProp] = this.qtoRows[this.lastFocusedRowIndex - 2][nameProp];
      if (nameProp === nameof<PageQuantityTakeOffRowModel>('itemNumber')) {
        this.removePositionReferenceFromRowIfItemNumberUnset(event, rowToUpdate);
      }
      this.recalculate(this.lastFocusedRowIndex, event.target['id']);
    }
  }

  deleteRow(row): void {
    const warning = this.checkWarningForReference([row.rowIndex]);
    if (warning) {
      this.confirmationDeletingChecked(warning, () => this.deleteRowChecked(row));
    } else {
      this.deleteRowChecked(row);
    }
  }

  deleteRowChecked(item: PageQuantityTakeOffRowModel): void {
    const currentId = this.activeRowId;
    this.qtoRows = this.qtoRows.filter((el) => el.rowIndex !== item.rowIndex);
    this.qtoRows.forEach((row: PageQuantityTakeOffRowModel) =>
      this.manageInvoiceTableService.changeRelatingNumber(row, item.rowIndex - 1, -1, [item.rowIndex])
    );
    if (currentId) {
      this.canLose = false;
      const arr = currentId.split('-');
      if (arr[0] !== '1') {
        arr[0] = String(+arr[0] - 1);
      }
      this.recalculate(0, arr.join('-'));
    } else {
      this.recalculate();
    }
  }

  toggleFontStyle(type: string, row: PageQuantityTakeOffRowModel): void {
    const currentId = this.activeRowId;
    this.setFontStyleService.toggleFontStyle(StyleEnumType[type], row);
    if (currentId) {
      this.canLose = false;
      this.recalculate(row.rowIndex, currentId);
    } else {
      this.recalculate(row.rowIndex);
    }
  }

  onKey(event: KeyboardEvent): void {
    if (this.isCursorOutsideText) {
      this.keyupControlService.onKey(event, this.displayedColumns);
    }
  }

  preventKeyDownEvent(event: KeyboardEvent, element: PageQuantityTakeOffRowModel, nameProp: string, origin?: CdkOverlayOrigin): void {
    this.lastFocusedRowIndex = element.rowIndex;
    const target = event.target as HTMLInputElement;
    switch (event.key) {
      case 'c':
      case 'C':
      case 'x':
      case 'X':
        if (event.ctrlKey) {
          this.numRowsClipboardTable = null;
        }
        break;
      case 'F4':
        this.copyPropAbove(event, nameProp);
        break;
      case 'F5':
        this.listSelectedRows = [this.lastFocusedRowIndex];
        this.cutSelectedRowsPlus();
        break;
      case 'F2':
        if (nameProp === 'itemNumber' && origin) {
          this.openOverlayPanel(element, origin);
        }
        break;
      case 'Insert':
        if (this.userSettings.allowOverwriteMode && !event.altKey) {
          event.preventDefault();
        } else {
          this.addRow(element, false);
          this.recalculate(element.rowIndex, event.target['id']);
        }
        break;
      default:
    }
    if (
      ((event.key === 'ArrowRight' && target.selectionStart !== target.value.length) ||
        (event.key === 'ArrowLeft' && target.selectionStart !== 0)) &&
      target.type === 'text'
    ) {
      this.isCursorOutsideText = false;
    } else {
      this.keyupControlService.preventKeyDownEvent(event);
      this.isCursorOutsideText = true;
    }
  }

  openBrowseFileModal(): void {
    const correlationId = GuidGenerator.generatePseudoRandomGuid();

    this.setSelectedFile(correlationId);
    this.manageBrowseFiles();

    this.dialogRef = this.modalService.openModal(ProjectFilesComponent, {
      dialogType: ConfirmationType.General,
      data: {
        modalData: 'browseView',
        correlationId,
        extensions: ['.jpg', '.jpeg', '.png', '.gif']
      }
    });
    this.dialogRef.addPanelClass('modal-browse');
  }

  private setSelectedFile(correlationId: string): void {
    this.selectedFile = null;
    this.selectedFileToUploadService.selectedFile
      .pipe(
        takeUntil(this.$destroy),
        filter((r) => r.correlationId === correlationId),
        map((r) => r.file)
      )
      .subscribe((file: File | ProjectFileGet) => {
        if (file && !file['lastModified']) {
          this.qtoRows[this.activeElement?.rowIndex - 1].projectFileFolderId = file['folderId'];
          this.qtoRows[this.activeElement?.rowIndex - 1].projectFileId = file['id'];
          this.qtoRows[this.activeElement?.rowIndex - 1].fileId = file['file']['id'];

          this.recalculate();
          this.selectedFileToUploadService.setSelectedFile(null, null);
          this.dialogRef.close();
        }
      });
  }

  private manageBrowseFiles(): void {
    this.projectCurrentItemsService.projCurrItems.pipe(takeUntil(this.$destroy)).subscribe((filesList) => {
      if (filesList.includes('uploadedFile')) {
        this.uploadImage(filesList);
      }
    });
  }

  private uploadImage(filesList): void {
    let addedFile: ProjectFileGet;
    if (filesList && this.selectedFile) {
      addedFile = filesList.find((item) => item.file?.fileName === this.selectedFile.name);

      if (filesList.includes('uploadedFile') && this.selectedFile) {
        const reader: FileReader = new FileReader();
        reader.onload = () => {
          this.qtoRows[this.activeRowIndex - 1].fileId = addedFile.file.id;
          this.qtoRows[this.activeRowIndex - 1].projectFileId = addedFile.id;
          this.qtoRows[this.activeRowIndex - 1].projectFileFolderId = addedFile.folderId;

          this.recalculate();
          this.dialogRef.close();
        };
        reader.readAsDataURL(this.selectedFile);
        this.selectedFileToUploadService.setSelectedFile(null, null);
        filesList.pop();
        this.projectCurrentItemsService.setProjCurrItems(filesList, 'allItems');
      }
    }
  }

  deleteImage(element: PageQuantityTakeOffRowModel): void {
    this.modalService
      .openModal(InvoiceModalForDeletingImageComponent, {
        dialogType: ConfirmationType.General,
        data: {
          element: element
        }
      })
      .afterClosed()
      .subscribe({
        next: (response) => {
          if (response) {
            this.setNullValueToFieldsDataSource(element);
            this.recalculate(element.rowIndex);
          }
        },
        error: (error) => console.error('error', error)
      });
  }

  recalculate(numRow?: number, focusElementId?: string, forceRecalculateAll?: boolean): void {
    const prevPageId = this.quantityTakeOffPageId;
    if (
      !this.projectId ||
      !this.avaProjectId ||
      (!this.isStandalonePage && !this.quantityTakeOffId) ||
      !this.quantityTakeOffPageId ||
      !this.calculationData
    ) {
      return;
    }

    if (this.calculationInProgress) {
      this.calculationQueue++;
      return;
    }

    this.calculationInProgress = true;
    this.circularErrorRowIndizes = {};

    this.calculationData.rows = this.qtoRows;
    this.pageQuantityTakeOffsClient
      .updatePageQuantityTakeOffCalculationData(this.projectId, this.avaProjectId, this.quantityTakeOffPageId, this.calculationData)
      .subscribe(
        (r: PageQuantityTakeOffGet) => {
          this.quantityTakeOffInvoiceTotalsMessengerService.setCurrentTotalSum({
            ...r.calculation,
            pageTotalSum: r.totalSum
          });

          if (numRow) {
            const editedRow = r.pageData.rows.find((row) => row.rowIndex === numRow);
            if (editedRow?.avaPositionId) {
              // If the changed row has a position reference, we want to ensure that the position totals are
              // updated, so we send this message via the service that the totals component can then react to
              this.quantityTakeOffInvoiceTotalsMessengerService.setChangedAvaPositionId(editedRow.avaPositionId);
            }
          }

          const noEditColumns = ['shortText', 'result', 'unit', 'totalCost'];

          if (this.quantityTakeOffPageId !== prevPageId) {
            // This means that the page in the UI was already changed, so we shouldn't update
            // the current table with data from the other page.
            return;
          }

          const updateCell = (newCell, sourceCell) => {
            noEditColumns.forEach((key: string) => delete sourceCell[key]);
            Object.assign(sourceCell, newCell);
            for (const prop in sourceCell) {
              if (newCell[prop] == null && prop !== 'formula') {
                delete sourceCell[prop];
              }
            }
          };

          const updateAllCells = () => {
            r.pageData.rows.forEach((item, index) => {
              if (JSON.stringify(item) !== JSON.stringify(this.qtoRows[index])) {
                if (this.qtoRows.length <= index) {
                  this.qtoRows[index] = { ...item, rowIndex: index + 1 };
                } else {
                  updateCell(item, this.qtoRows[index]);
                }
              }
            });
          };

          if (forceRecalculateAll || this.lastRecalculationHadError) {
            updateAllCells();
          } else if (numRow) {
            const itemN = r.pageData.rows[numRow - 1];
            if (itemN.referenceName || this.qtoRows[numRow - 1].referenceName) {
              // If the reference was changed, we want to update all cells in case there's a dependency
              // on the reference somewhere that should be refreshed
              updateAllCells();
            } else {
              updateCell(itemN, this.qtoRows[numRow - 1]);
            }
          } else {
            updateAllCells();
          }

          this.lastRecalculationHadError = false;

          if (r.pageData.rows.length > this.qtoRows.length) {
            const afterToAdd = this.qtoRows[this.qtoRows.length - 1].rowIndex;
            const newRows = r.pageData.rows.filter((r) => r.rowIndex > afterToAdd);
            this.qtoRows.push(...newRows);
          }

          this.qtoRows = [...this.qtoRows];
          this.table?.renderRows();

          this.calculationInProgress = false;
          if (this.calculationQueue > 0) {
            this.calculationQueue = 0;
            this.recalculate();
          } else {
            this.selectedQuantityTakeOffMessengerService.setSomeChangesQuantityTakeOff();
            this.getSheet();
          }
          let focusId = this.lastFocusedElementId || focusElementId;
          focusId = this.checkFormulaErrorService.checkErrorInFormulaAndSendIdForFocus(
            this.qtoRows[numRow - 1],
            this.userSettings,
            focusId
          );
          if (focusId) {
            setTimeout(() => document.getElementById(focusId).focus(), 1);
          }
          this.getTotalInvoicedQuantityForAllPositionsInAvaProject();
        },
        (error: ApiErrorOfFormulaError) => {
          this.lastRecalculationHadError = true;
          this.calculationInProgress = false;
          if (error.error?.formulaErrorType === FormulaErrorType.CircularReference) {
            this.notificationsService.error('Zirkelbezug in einer Formel, die Berechnung wird nicht gespeichert.');

            if (error.error.rowIndizesWithErrors) {
              error.error.rowIndizesWithErrors.forEach((ri) => (this.circularErrorRowIndizes[ri] = true));
            }
          } else {
            this.notificationsService.error('Fehler beim Speichern.');
          }
        }
      );
  }

  startSelectRow(rowIndex: number): void {
    if (!this.isReadOnly) {
      this.listMove = [rowIndex, rowIndex];
      this.selectRowsService.markFirst(rowIndex);
      let currentRowIndex;
      this.mousemove$ = fromEvent(this.el.nativeElement, 'mousemove').subscribe((elEv: MouseEvent) => {
        // Added changes as per this comment https://github.com/Dangl-IT/Dangl.PfeifferAVA/pull/2469#issuecomment-1547509766
        const rowIndex = +(elEv.target as HTMLElement).innerText;
        if (rowIndex !== currentRowIndex) {
          currentRowIndex = rowIndex;
          this.moveSelectRow(elEv, currentRowIndex);
        }
      });
    }
  }

  stopSelectRow(event: MouseEvent, selectedRowStop = 0): void {
    if (!this.isReadOnly) {
      if (!event.button) {
        this.listSelectedRows = this.selectRowsService.getNewList(selectedRowStop, event.ctrlKey, event.shiftKey);
        let isShowQantity = false;
        const rowsWithSamePositions = this.qtoRows
          .filter((r) => this.listSelectedRows.includes(r.rowIndex) && r.itemNumber)
          .map((r) => r.itemNumber);
        if (rowsWithSamePositions.length) {
          isShowQantity = rowsWithSamePositions.every((v) => v === rowsWithSamePositions[0]);
        }
        const summa = this.qtoRows.reduce(
          (sum: number, item: PageQuantityTakeOffRowModel) =>
            sum + (item.totalCost && this.listSelectedRows.includes(item.rowIndex) ? item.totalCost : 0),
          0
        );
        const quantity = this.qtoRows.reduce(
          (sum: number, item: PageQuantityTakeOffRowModel) =>
            sum + (typeof item.result === 'number' && this.listSelectedRows.includes(item.rowIndex) ? item.result : 0),
          0
        );
        this.selectRowsService.setSelectedRowSumma(
          this.listSelectedRows.length ? summa : null,
          this.listSelectedRows.length && isShowQantity ? quantity : null
        );

        if (this.listSelectedRows.length && isShowQantity) {
          this.selectRowsService.setSelectedUnitTag(this.qtoRows.find((r) => r.rowIndex === this.listSelectedRows[0]).unitTag);
        }

        event.stopPropagation();
      }
      if (this.mousemove$) {
        this.mousemove$.unsubscribe();
      }
      this.listMove = [];
    }
  }

  moveSelectRow(event: MouseEvent, rowIndex: number): void {
    if (!this.isReadOnly && event.buttons === 1 && !event.button && this.listMove?.length) {
      this.listMove[1] = rowIndex;
    }
  }

  showMoveSelectRow(rowIndex: number): boolean {
    if (this.listMove?.length) {
      const min: number = Math.min(...this.listMove);
      const max: number = Math.max(...this.listMove);
      return rowIndex >= min && rowIndex <= max;
    }
    return false;
  }

  selectedRow(rowIndex: number): boolean {
    return this.listSelectedRows.includes(rowIndex);
  }

  public saveChecked(): void {
    this.pageQuantityTakeOffsClient
      .updatePageQuantityTakeOffIsChecked(this.projectId, this.avaProjectId, this.quantityTakeOffPageId, {
        isChecked: this.isChecked
      })
      .subscribe(
        (e: PageQuantityTakeOffGet) => {
          this.updateMainData(e);
          setTimeout(() => {
            this.pageQuantityTakeOffsService.forceRefresh();
            this.pagesAllAvaProjectService.forceRefresh();
          }, 50);
        },
        () => {
          this.notificationsService.error('Fehler beim Setzen von "Geprüft".');
          this.isChecked = !this.isChecked;
        }
      );
  }

  public saveMainData(): void {
    this.pageQuantityTakeOffsClient
      .updatePageQuantityTakeOffProperties(this.projectId, this.avaProjectId, this.quantityTakeOffPageId, {
        buildingElementCodeId: this.buildingElementCodeId,
        name: this.currentPage.name,
        pageNumber: this.currentPage.pageNumber,
        servicePeriodCodeId: this.currentPage.servicePeriodCodeId,
        subContractorId: this.subContractor?.id
      })
      .subscribe((e: PageQuantityTakeOffGet) => {
        this.updateMainData(e);
      });
  }

  private updateMainData(element: PageQuantityTakeOffGet): void {
    setTimeout(() => {
      this.buildingElementCodeId = element.buildingElementCodeId;
      this.buildingElementCode = element.buildingElementCode;
      if (this.buildingElementCodeId) {
        this.buildingElementCodesClient
          .getBuildingElementCodeById(this.projectId, this.buildingElementCodeId)
          .subscribe((e: BuildingElementCodeGet) => {
            this.buildingElementCode = e.name;
          });
      }
      this.getSubContractor(element);
      this.isChecked = element.isChecked;
    }, 1);
  }

  public inputRowType(event: KeyboardEvent, element: PageQuantityTakeOffRowModel, nameProp: string): void {
    this.preventKeyDownEvent(event, element, nameProp);
    const pattern = /[iz]/i;
    if (event.key.length === 1 && !pattern.test(event.key)) {
      event.preventDefault();
    }
  }

  saveRows(isClean?: boolean): void {
    if (!isClean) {
      this.formClipboardTable();
    }
  }

  copySavedRows(currentRow: PageQuantityTakeOffRowModel, isReplaceRows?: boolean): void {
    this.isReplaceRows = isReplaceRows;
    this.clipboardTableDataService.handlePaste();
  }

  private changeShowReferenceName(): void {
    this.isShowReferenceName =
      this.userSettings.showReferenceNameInQuantityTakeOff || this.qtoRows.some((item: PageQuantityTakeOffRowModel) => item.referenceName);
    this.changeShowingOfReferenceNameColumn(this.isShowReferenceName);
  }

  public changeShowingOfReferenceNameColumn(showReferenceNameColumn: boolean): void {
    this.displayedColumns = this.showingColumnsService.getFilteredColumns('PageQto', this.defaultDisplayedColumns);

    if (!showReferenceNameColumn) {
      this.displayedColumns = this.displayedColumns.filter((item: string) => item !== 'referenceName');
    }
    if (!this.userSettings.showUnitTagsInQuantityTakeOff) {
      this.displayedColumns = this.displayedColumns.filter((item: string) => item !== 'unit');
    }
    if (this.userSettings.showShortTextColumnsInQuantityTakeOff === false) {
      this.displayedColumns = this.displayedColumns.filter((item: string) => item !== 'shortText');
    }
    if (this.userSettings.showImageColumnsInQuantityTakeOff === false) {
      this.displayedColumns = this.displayedColumns.filter((item: string) => item !== 'image');
    }

    this.mainColumnsToDisplaySearchTable = this.userSettings.showPositionTotalInvoicedInSearchWindowInQuantityTakeOff
      ? ['itemNumber', 'shortText', 'unitPrice', 'unitTag', 'longText', 'quantity', 'percentage']
      : ['itemNumber', 'shortText', 'unitPrice', 'unitTag', 'longText'];
    this.changeColumnSearching();
  }

  changeInputFromOverrideMode(input: HTMLInputElement, element: PageQuantityTakeOffRowModel, prop: string): void {
    if (prop === 'itemNumber') {
      this.removePositionReferenceFromRowIfItemNumberUnset(event, element);
    }
    const value = input.value;
    let hasError = this.calculationData.rows.some((item: PageQuantityTakeOffRowModel) => !!item.formulaErrorType);
    if (prop === 'referenceName' && !this.checkedDoubleReferenceName(value)) {
      this.showErrorElement(input, 'Referenzname bereits vergeben');
      hasError = true;
    } else if (element.itemNumber && prop === 'formula' && !this.checkedValidFormula(value)) {
      this.showErrorElement(input, 'Falsche Referenz');
      element[prop] = input.value;
      element.result = null;
      element.formulaErrorMessage = 'Falsche Referenz';
      element.formulaErrorType = FormulaErrorType.GenericError;
      hasError = true;
    }

    element[prop] = input.value;
    const forceRecalculate = hasError || prop === 'referenceName' || prop === 'formula';
    this.recalculate(element.rowIndex, null, forceRecalculate);
  }

  changeInput(event, element: PageQuantityTakeOffRowModel, prop: string): void {
    if (prop === 'itemNumber') {
      this.removePositionReferenceFromRowIfItemNumberUnset(event, element);
    }
    const value = event.target.value;
    let hasError = this.calculationData.rows.some((item: PageQuantityTakeOffRowModel) => !!item.formulaErrorType);
    if (prop === 'referenceName' && !this.checkedDoubleReferenceName(value)) {
      this.showErrorElement(event.target, 'Referenzname bereits vergeben');
      hasError = true;
    } else if (element.itemNumber && prop === 'formula' && !this.checkedValidFormula(value)) {
      this.showErrorElement(event.target, 'Falsche Referenz');
      element[prop] = event.target.value;
      element.result = null;
      element.formulaErrorMessage = 'Falsche Referenz';
      element.formulaErrorType = FormulaErrorType.GenericError;
      hasError = true;
    }

    element[prop] = event.target.value;
    const forceRecalculate = hasError || prop === 'referenceName' || prop === 'formula';
    this.recalculate(element.rowIndex, null, forceRecalculate);
  }

  checkedDoubleReferenceName(valueReferenceName: string): boolean {
    if (valueReferenceName) {
      return !this.qtoRows.some((item) => item.referenceName === valueReferenceName);
    }
    return true;
  }

  showErrorElement(inputElement: HTMLInputElement, textError: string): void {
    const value = inputElement.value;
    this.notificationsService.error(`${textError}: ${value}`);
  }

  checkedValidFormula(formula): boolean {
    let hasFoundInvalidSubstitution = false;
    Calculator.calculate(formula, (substitution) => {
      const regExpr = /^#[\d]+$/g;
      if (regExpr.test(substitution)) {
        const referencedRow = this.calculationData.rows.find((r) => r.rowIndex === +substitution.slice(1));
        if (!referencedRow) {
          hasFoundInvalidSubstitution = true;
        } else {
          return referencedRow.result;
        }
      } else if (/^#[pP]/g.test(substitution)) {
        // Then it's likely a position sum reference, so we don't show any errors
      } else {
        const referencedRow = this.calculationData.rows.find((r) => r.referenceName?.endsWith(substitution.slice(1)));
        if (!referencedRow) {
          hasFoundInvalidSubstitution = true;
        } else {
          return referencedRow.result;
        }
      }
    });

    return !hasFoundInvalidSubstitution;
  }

  trackByFn(_: number, item: PageQuantityTakeOffRowModel): number {
    return item.rowIndex;
  }

  checkWarningForReference(list: number[]): string {
    let result = '';
    list.forEach((item) => {
      const foundRows = this.qtoRows.filter((row) => row.formula?.includes(`#${item}`));
      if (foundRows?.length) {
        result += foundRows.reduce((acc, referenceRow) => acc + `(#${referenceRow.rowIndex} -> #${item})`, '');
      }
    });
    return result;
  }

  confirmationDeletingChecked(warning: string, fn: () => void): void {
    this.modalService
      .openModal(ModalConfirmComponent, {
        dialogType: ConfirmationType.Delete,
        data: ['Löschen', 'Zeile wird referenziert', warning, 'red']
      })
      .afterClosed()
      .subscribe((e: boolean) => {
        if (e) {
          fn();
        }
      });
  }

  deleteSelectedRows(): void {
    const warning = this.checkWarningForReference(this.listSelectedRows);
    if (warning) {
      this.confirmationDeletingChecked(warning, () => this.deleteSelectedRowsChecked());
    } else {
      this.deleteSelectedRowsChecked();
    }
  }

  deleteSelectedRowsChecked(): void {
    const maxIndex = Math.max(...this.listSelectedRows);
    const currentRows: PageQuantityTakeOffRowModel[] = this.qtoRows.filter((row) => !this.listSelectedRows.includes(row.rowIndex));
    currentRows.forEach((row: PageQuantityTakeOffRowModel, index: number) => {
      row.rowIndex = index + 1;
      this.manageInvoiceTableService.changeRelatingNumber(
        row,
        maxIndex - this.listSelectedRows.length,
        -this.listSelectedRows.length,
        this.listSelectedRows
      );
    });
    this.listSelectedRows = [];
    this.selectRowsService.listSelectedRows = [];
    if (currentRows.length === 0) {
      currentRows.push({
        rowIndex: 1
      });
    }
    this.qtoRows = currentRows;
    setTimeout(() => {
      this.recalculate();
    }, 1);
  }

  getFocus(row: PageQuantityTakeOffRowModel): void {
    this.activeRowId = null;
    this.focusedRow = row;
    this.canLose = false;
  }
  setOriginOverlay(selectOriginOverlay: CdkOverlayOrigin): void {
    this.selectOriginOverlay = selectOriginOverlay;
  }

  lostFocus(event): void {
    this.lastFocusedElementId = null;
    this.canLose = true;
    this.activeRowId = event.target.id;

    setTimeout(() => {
      if (this.canLose) {
        this.activeRowId = null;
        this.focusedRow = null;
        this.selectOriginOverlay = null;
        this.canLose = false;
      }
    }, 200);
  }

  openOverlayPanel(row: PageQuantityTakeOffRowModel, origin: CdkOverlayOrigin) {
    this.isMultiSelect = false;
    this.multiSelect.clear();
    if (row.avaPositionId) {
      this.filter = '';
      this.filteredList();
      this.activeIndex = this.filteredListArr.data.findIndex((item) => row.avaPositionId === item.id);
      this.activeIndex = this.activeIndex < 1 ? 0 : this.activeIndex;
    } else {
      this.filter = row.itemNumber || '';
      this.filteredList();
      this.activeIndex = 0;
    }
    this.trigger = origin;
    this.isOpenListNumber = true;
    setTimeout(() => {
      this.checkHeight();
      this.tableViewport.scrollToIndex(this.activeIndex);

      const el = this.focused.nativeElement;
      if (el) {
        el.focus();
        this.canLose = false;
        const originEl = this.trigger.elementRef.nativeElement as HTMLInputElement;
        originEl.classList.add('fiction');
      }
    }, 50);

    this.getPercentegByActivePosition();
  }

  getTotalInvoicedQuantityForAllPositionsInAvaProject(): void {
    this.quantityTakeOffPositionSumsClient
      .getTotalInvoicedQuantityForAllPositionsInAvaProject(this.projectId, this.avaProjectId)
      .pipe(take(1))
      .subscribe({
        next: (positionsTotals: AvaProjectInvoicedPositionsGet) => {
          this.positionsTotals = positionsTotals.positions;
        },
        error: () => this.notificationsService.error('Fehler beim Abrufen der Abrechnungssummen')
      });
  }

  getPercentegByActivePosition(): void {
    if (this.userSettings.showPositionTotalInvoicedInSearchWindowInQuantityTakeOff) {
      const selectedElement = this.filteredListArr.data[this.activeIndex];
      const currentPositionTotals = this.positionsTotals.find((positionsTotals) => positionsTotals.avaPositionId === selectedElement.id);
      if (currentPositionTotals.avaProjectTotalPositionQuantity !== 0) {
        this.percentegByActivePosition =
          currentPositionTotals.totalInvoicedQuantity / currentPositionTotals.avaProjectTotalPositionQuantity;
      } else {
        this.percentegByActivePosition = 0;
      }
    }
  }

  checkHeight(): void {
    this.maxHeight = '';
    const main = (document.querySelector('.item-table') as HTMLDivElement)?.getBoundingClientRect();
    if (main && main.height < 400) {
      this.maxHeight = main.height < 400 ? `${main.height + 30}px` : '';
    }
  }

  selectNumber(event: MouseEvent, obj: { id: string; num: string }, index: number): void {
    if (event.ctrlKey && !this.avoidMultiSelect) {
      this.markPositionMultiRow(obj, index);
    } else {
      if (this.isMultiSelect) {
        this.setPositionNumberMulti();
      } else {
        this.setPositionNumber(obj);
      }
      this.closeOverlay(true);
    }
  }

  filteredList(): void {
    const cleanFilter = this.filter.replace(/[. ]/gm, '');
    this.filteredListArr.data = this.positionNumberListAll.filter(
      (item) =>
        item.num.replace(/[. ]/g, '').slice(0, cleanFilter.length) === cleanFilter ||
        (item.shortText && item.shortText.toLowerCase().indexOf(cleanFilter.toLowerCase()) > -1) ||
        (this.isSearchLongText && item.longText && item.longText.toLowerCase().indexOf(cleanFilter.toLowerCase()) > -1)
    );
  }

  cancelled(event: KeyboardEvent, origin?: CdkOverlayOrigin): void {
    event.stopPropagation();
    if (this.isOpenListNumberLongText) {
      this.closeOverlayLongText();
      return;
    }
    switch (event.key) {
      case 'Enter':
        if (this.filteredListArr.data.length) {
          if (event.ctrlKey && !this.avoidMultiSelect) {
            this.markPositionMultiRow(this.filteredListArr.data[this.activeIndex], this.activeIndex);
          } else {
            if (this.isMultiSelect) {
              this.setPositionNumberMulti();
              this.closeOverlay(true);
            } else {
              this.setPositionNumber(this.filteredListArr.data[this.activeIndex]);
            }
            this.closeOverlay();
          }
        }
        break;
      case 'Escape':
      case 'Tab':
      case 'ArrowRight':
      case 'ArrowLeft':
        this.closeOverlay();
        break;
      case 'ArrowUp':
        this.activeIndex = --this.activeIndex < 0 ? 0 : this.activeIndex;
        this.getPercentegByActivePosition();
        this.autoScroll();
        break;
      case 'ArrowDown':
        this.activeIndex = ++this.activeIndex >= this.filteredListArr.data.length ? this.filteredListArr.data.length - 1 : this.activeIndex;
        this.getPercentegByActivePosition();
        this.autoScroll();
        break;
      case 'F4':
        this.copyPropAbove(event, 'itemNumber');
        break;
      case 'F9':
        this.fastLongText(event, this.filteredListArr.data[this.activeIndex], this.activeIndex, origin);
        break;
      case 'Control':
        break;
      default:
        this.activeIndex = 0;
        setTimeout(() => {
          this.isMultiSelect = false;
          this.changeColumnSearching();
          this.multiSelect.clear();
          this.runFilter();
        }, 100);
    }
  }

  autoScroll(isSeek?: boolean): void {
    setTimeout(() => {
      const el = document.querySelector('.active-num') as HTMLElement;
      if (el) {
        const elRect: DOMRect = el.getBoundingClientRect();
        const container = document.querySelector('.list-number') as HTMLElement;
        const containerRect: DOMRect = container.getBoundingClientRect();
        if (el && (elRect.top < containerRect.top + 22 || elRect.bottom > containerRect.bottom)) {
          if (isSeek) {
            this.tableViewport.scrollToIndex(this.activeIndex);
          } else {
            if (elRect.top < containerRect.top + 22) {
              this.tableViewport.scrollToIndex(this.activeIndex);
            } else if (elRect.bottom > containerRect.bottom) {
              this.tableViewport.scrollToIndex(this.activeIndex);
            }
          }
        }
      }
    }, 50);
  }

  setPositionNumber(obj: { id: string; num: string }): void {
    if (this.isPasteNewNumberMode) {
      this.pasteRowNewItemNumber(obj.num);
    } else {
      this.focusedRow['avaPositionId'] = obj.id;
      this.recalculate(this.focusedRow.rowIndex);
    }
  }

  markPositionMultiRow(obj: { id: string; num: string }, index?: number): void {
    this.isMultiSelect = true;
    this.changeColumnSearching();
    const length = this.multiSelect.size;
    this.activeIndex = index;
    if (!this.multiSelect.has(obj.id)) {
      this.multiSelect.set(obj.id, length);
    }
    this.focused.nativeElement.focus();
  }

  changeColumnSearching(): void {
    this.columnsToDisplaySearchTable = this.isMultiSelect
      ? ['multi', ...this.mainColumnsToDisplaySearchTable]
      : [...this.mainColumnsToDisplaySearchTable];
  }

  setPositionNumberMulti(): void {
    const add: PageQuantityTakeOffRowModel[] = [];
    this.multiSelect.forEach((index, id) => {
      if (!index) {
        this.focusedRow['avaPositionId'] = id;
      } else {
        add.push({
          avaPositionId: id,
          formula: this.focusedRow.formula,
          color: this.focusedRow.color,
          isBold: this.focusedRow.isBold,
          isCursive: this.focusedRow.isCursive,
          isUnderlined: this.focusedRow.isUnderlined
        });
      }
    });

    if (this.multiSelect.size) {
      this.qtoRows = this.manageInvoiceTableService.addRow(this.focusedRow, true, true, this.qtoRows, add);
    }
    this.recalculate();
  }

  closeOverlay(isNext = false): void {
    this.avoidMultiSelect = false;
    this.isPasteNewNumberMode = false;
    this.isOpenListNumber = false;
    const originEl = this.trigger.elementRef.nativeElement as HTMLInputElement;
    originEl.classList.remove('fiction');
    originEl.focus();
    if (isNext) {
      this.onKey(new KeyboardEvent('keyup', { key: 'Tab' }));
    }
    // this is need for reset height of window when it close
    this.runFilter();
  }

  activeColor(): void {
    if (this.activeRowId) {
      this.canLose = false;
    }
  }

  addColor(color: string, row: PageQuantityTakeOffRowModel): void {
    this.focusedRow['color'] = color;
    row.color = color;
    if (this.activeRowId) {
      this.recalculate(row.rowIndex, this.activeRowId);
    } else {
      this.recalculate(row.rowIndex);
    }
  }

  blurColor() {
    const el = document.getElementById(this.activeRowId);
    if (el) {
      el.focus();
    }
  }

  clearSelectionRow(event: MouseEvent, row: PageQuantityTakeOffRowModel): void {
    if (!event?.button && this.listSelectedRows.length) {
      const tag = event?.target as HTMLInputElement;
      if (this.isSelectingFormulaRowMode) {
        if (!this.listSelectedRows.includes(row.rowIndex)) {
          this.changeFormula(row);
          this.isSelectingFormulaRowMode = false;
        }
      } else if (!tag || (tag['tagName'] === 'INPUT' && !tag.disabled)) {
        this.listSelectedRows = this.selectRowsService.init();
      }
    }
  }

  showHeaderContextMenu(event: MouseEvent): void {
    this.contextMenuSettingsService.setDefaultSettings(event, null, this.contextMenuPosition, this.rowHeaderContextMenuTrigger);
  }

  editListColumns(): void {
    this.modalService
      .openModal(ShowingColumnsModalComponent, {
        dialogType: ConfirmationType.General,
        data: {
          component: 'PageQto',
          columns: this.defaultDisplayedColumns
        },
        disableClose: true,
        autoFocus: false
      })
      .afterClosed()
      .subscribe(() => {});
  }

  openOverlayPanelLongText(row, origin: CdkOverlayOrigin) {
    this.showedLongText = this.flatPositions.find((item) => item.id === this.filteredListArr.data[this.activeIndex].id) as PositionDto;
    if (this.showedLongText) {
      this.triggerLongText = origin;
      this.isOpenListNumberLongText = true;
      setTimeout(() => {
        this.focused?.nativeElement?.focus();
      }, 50);
    }
  }

  closeOverlayLongText(): void {
    this.isOpenListNumberLongText = false;
    this.focused?.nativeElement?.focus();
  }

  fastLongText(event: MouseEvent | KeyboardEvent, row: { id: string; num: string }, index: number, origin?: CdkOverlayOrigin): void {
    event.stopPropagation();
    this.activeIndex = index;
    this.openOverlayPanelLongText(row, origin);
  }

  confirmationDeleting(listDel: number | number[], fn: () => void): void {
    let listDelString: string;
    if (Array.isArray(listDel)) {
      listDelString = `Zeilen: ${listDel.length < 10 ? listDel.join(', ') : 'Viele (' + listDel.length + ')'}`;
    } else {
      listDelString = `Zeile ${listDel}`;
    }
    this.modalService
      .openModal(ModalConfirmComponent, { dialogType: ConfirmationType.Delete, data: ['Löschen', 'Zeilen', listDelString, 'red'] })
      .afterClosed()
      .subscribe((e: boolean) => {
        if (e) {
          fn();
        }
      });
  }

  deleteRowPlus(item: PageQuantityTakeOffRowModel): void {
    if (this.userSettings.promptForConfirmationWhenDeletingRows) {
      this.confirmationDeleting(item.rowIndex, () => this.deleteRow(item));
    } else {
      this.deleteRow(item);
    }
  }

  deleteSelectedRowsPlus(isSkipConfirmation: boolean): void {
    if (this.userSettings.promptForConfirmationWhenDeletingRows && !isSkipConfirmation) {
      this.confirmationDeleting(this.listSelectedRows, () => this.deleteSelectedRows());
    } else {
      this.deleteSelectedRows();
    }
  }

  getSize(textSizeFactor: number): string {
    return textSizeFactor ? `${1 + textSizeFactor * 0.1}em` : '';
  }

  /**
   * This is called from the component. It basically reacts to a single keystroke
   * when the menu to change the size is available, and allows the user
   * to set some sizes via number buttons on the keyboard
   * @param event
   * @returns
   */
  tryChangeSize(event: KeyboardEvent): void {
    if (!event.key) {
      return;
    } else if (event.key === 'Escape') {
      this.blurColor();
    } else if (event.key === '-') {
      this.changeSize(this.sizes[0].size);
      this.changeSizeMenu.closeMenu();
    } else if (event.key >= '0' && event.key <= '9') {
      if (this.changeSizeMenu) {
        this.changeSize(+event.key);
        this.changeSizeMenu.closeMenu();
      }
    } else {
      this.changeSize(0);
      this.changeSizeMenu.closeMenu();
    }
  }

  changeSize(size: number): void {
    let val: number = size;
    val =
      val < this.sizes[0].size
        ? this.sizes[0].size
        : val > this.sizes[this.sizes.length - 1].size
        ? this.sizes[this.sizes.length - 1].size
        : val;
    this.focusedRow.textSizeFactor = val || undefined;
    if (this.activeRowId) {
      this.recalculate(this.focusedRow.rowIndex, this.activeRowId);
    } else {
      this.recalculate(this.focusedRow.rowIndex);
    }
  }

  usingLongText(): void {
    this.runFilter();
    this.focused.nativeElement.focus();
  }

  runFilter(): void {
    this.filterSource.next(true);
  }

  showImage(element: PageQuantityTakeOffRowModel): void {
    this.modalService
      .openModal(InvoiceImageModalComponent, {
        dialogType: ConfirmationType.General,
        data: {
          element: element,
          isReadOnly: this.isReadOnly
        },
        panelClass: 'window-padding'
      })
      .afterClosed()
      .subscribe((isDelete: boolean) => {
        if (isDelete) {
          this.deleteImage(element);
        }
      });
  }

  setTotalSumsHeight(totalSumHeight: number): void {
    this.totalSumHeight = totalSumHeight;
  }

  changeFormula(row: PageQuantityTakeOffRowModel): void {
    const listReferences = this.listSelectedRows.map((item) => `#${item}`).join(' + ');
    row.formula = listReferences;
    if (!row.itemNumber) {
      const lastRow = this.qtoRows[Math.max(...this.listSelectedRows) - 1];
      row.itemNumber = lastRow.itemNumber;
    }
    this.recalculate(row.rowIndex);
  }

  cutSelectedRowsPlus(): void {
    this.saveRows();
    // Since this is just a cut operation, we want to ignore the settings
    // that ask for confirmation before deletion and just delete the rows
    this.deleteSelectedRows();
  }

  limitWidth(isSame?: boolean): void {
    this.limitColumnWidthService.limitWidth('page', isSame);
  }

  private getSchema(): void {
    this.itemNumberSchemasClient
      .getItemNumberSchema(this.projectId, this.avaProjectId)
      .subscribe((schema: ItemNumberSchemaDto) => (this.schema = schema));
  }

  public inputItemNumber(event: KeyboardEvent, element: PageQuantityTakeOffRowModel, nameProp: string, origin?: CdkOverlayOrigin): void {
    this.preventKeyDownEvent(event, element, nameProp, origin);
    if ((event.key.length === 1 && !event.ctrlKey) || (event.key === 'v' && event.ctrlKey)) {
      setTimeout(() => {
        const el = event.target as HTMLInputElement;
        el.value = this.changeItemNumberValue(el.value);
      }, 1);
    }
  }

  changeItemNumberValue(value: string): string {
    const { separator } = this.schema;
    const cleanedValue = value.split(separator).join('');
    let currentPos = 0;
    let newValue = '';
    this.schema.tiers.forEach((item: ItemNumberSchemaTierDto, index: number) => {
      const { length } = item;
      let add = cleanedValue.slice(currentPos, currentPos + length);
      if (add.length === length && index < this.schema.tiers.length - 1) {
        add += separator;
      }
      currentPos += length;
      newValue += add;
    });
    return newValue;
  }

  getSheet(): void {
    this.reportsClient
      .getQuantitySheetReportData(this.avaProjectId, false, false, this.includeHeaderOnlyOnFirstPage, false)
      .subscribe((data: QuantitySheetReport) => {
        this.positionPercentage = {};
        data.quantities.forEach((item) => {
          this.positionPercentage[item.itemNumber] = item;
        });
      });
  }

  getSubContractor(element: PageQuantityTakeOffGet): void {
    this.subContractor =
      element.subContractorId && this.listContact?.length ? this.listContact.find((item) => item.id === element.subContractorId) : null;
  }

  changeSubContractor(): void {
    this.modalService
      .openModal(SelectContactModalComponent, {
        dialogType: ConfirmationType.General,
        data: { contactType: ContactType.SubContractor, showCloseCancelButtons: true }
      })
      .beforeClosed()
      .subscribe((result: { selection: ContactGet }) => {
        const shouldUpdate = !!result;
        if (shouldUpdate) {
          this.subContractor = result.selection;
          this.saveMainData();
        }
      });
  }

  openInvoicePdfFilesModal(): void {
    this.modalService
      .openModal(InvoicePdfFileComponent, {
        dialogType: ConfirmationType.General,
        restoreFocus: false,
        autoFocus: false,
        minWidth: '1040px',
        panelClass: 'invoice-pdf-file'
      })
      .afterClosed()
      .subscribe((attachmentIds: { quantityTakeOffAttachmentId?: string | null; quantityTakeOffAttachmentImageFileId?: string | null }) => {
        if (attachmentIds) {
          this.qtoRows[this.activeElement?.rowIndex - 1].quantityTakeOffAttachmentId = attachmentIds.quantityTakeOffAttachmentId;
          this.qtoRows[this.activeElement?.rowIndex - 1].quantityTakeOffAttachmentImageFileId =
            attachmentIds.quantityTakeOffAttachmentImageFileId;
          this.recalculate();
        }
      });
  }

  back(): void {
    this.tryGoBack = true;
    setTimeout(() => {
      if (this.tryGoBack) {
        if (this.projectForQtoType === ProjectQuantityTakeOffType.Pages) {
          this.router.navigate(['../../..', 'all-pages'], {
            relativeTo: this.route
          });
        } else {
          if (this.currentPage.quantityTakeOffId) {
            this.router.navigate(['..'], {
              relativeTo: this.route
            });
          } else {
            this.router.navigate(['../..', 'all-pages'], {
              relativeTo: this.route
            });
          }
        }
      }
    }, 200);
  }

  backCalculation(): void {
    if (getAppConfig().isQtoOnlyMode) {
      return;
    }
    this.tryGoBack = false;
    this.router.navigate(['../../../..', 'calculation'], {
      relativeTo: this.route
    });
  }

  copyCurrentLine(row: PageQuantityTakeOffRowModel): void {
    this.formClipboardTable(null, [row]);
  }

  dropHeader(event: CdkDragDrop<string[]>): void {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
      this.showingColumnsService.saveDefaultDisplayedColumns(
        this.nameStorageOrderColumn,
        this._defaultDisplayedColumns,
        this.displayedColumns
      );
    }
  }

  updateInvoicePage(value: string, page: PageQuantityTakeOffGet, propertyName: string): void {
    this.updateInvoicePageService.updateInvoicePage(this.projectId, this.avaProjectId, value, page, propertyName).subscribe({
      next: (updateResult) => {
        if (updateResult && updateResult[`${propertyName}Id`]) {
          page[`${propertyName}Id`] = updateResult[`${propertyName}Id`];
          this.originalPageData[`${propertyName}Id`] = updateResult[`${propertyName}Id`];
          this.originalPageData[propertyName] = updateResult[propertyName];
        }

        if (!updateResult) {
          // We're updating either when there were no changes saved (to reload the current values)
          this.pagesAllAvaProjectService.forceRefresh();

          // And we're also making sure we set the original data back
          this.currentPage[`${propertyName}Id`] = this.originalPageData[`${propertyName}Id`];
          this.currentPage[propertyName] = this.originalPageData[propertyName];
        }
      },
      error: () => {
        this.notificationsService.error('Fehler beim Speichern der Änderungen.');
        this.pagesAllAvaProjectService.forceRefresh();
      }
    });
  }

  pasteClipboardRows(row: PageQuantityTakeOffRowModel): void {
    this.avoidMultiSelect = true;
    this.isPasteNewNumberMode = true;
    this.openOverlayPanel(row, this.fieldOriginOverlay);
  }

  pasteRowNewItemNumber(newItemNumber: string): void {
    this.newNumberForPaste = newItemNumber;
    this.clipboardTableDataService.handlePaste();
  }
}
