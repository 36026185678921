import { Injectable, OnDestroy } from '@angular/core';
import { combineLatestWith, filter, takeUntil } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { PaginationMainService } from './main/pagination-main.service';
import { QuantityTakeOffAttachmentGet } from './../../../generated-client/generated-client';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PdfAttachmentsService extends PaginationMainService<QuantityTakeOffAttachmentGet> implements OnDestroy {
  private destroy$ = new Subject<boolean>();
  constructor(
    protected http: HttpClient,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService
  ) {
    super(http);
    this.selectedProjectMessengerService.selectedProject
      .pipe(
        combineLatestWith(this.selectedSpecificationMessengerService.selectedServiceSpecification),
        filter(([project, avaProject]) => !!project && !!avaProject),
        takeUntil(this.destroy$)
      )
      .subscribe(([project, avaProject]) => {
        this.beginBaseUrl(`/api/projects/${project.id}/ava-projects/${avaProject.avaProjectId}/quantity-take-off-attachments`);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
