import { Injectable, OnDestroy } from '@angular/core';

import { EMPTY, Observable, ReplaySubject, Subject, filter, iif, of, switchMap, take } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { ModalService } from 'app/shared/services/modal.service';

import { AvaProjectGet, AvaProjectsClient } from '../../../generated-client/generated-client';

import { SelectedSpecificationMessengerService } from './selected-specification-messenger.service';

import { AvaProjectsService } from '../lightquery/ava-projects.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceSpecificationsHasChangeService implements OnDestroy {
  private $destroy: Subject<boolean> = new Subject();
  private serviceSpecificationHasChangeSource = new ReplaySubject<boolean>(1);
  private serviceSpecificationHasChange = this.serviceSpecificationHasChangeSource.asObservable();
  private lastAvaProjectReference: AvaProjectGet | null = null;

  constructor(
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private modalService: ModalService,
    private avaProjectsService: AvaProjectsService,
    private avaProjectsClient: AvaProjectsClient
  ) {
    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        filter((s) => !!s),
        takeUntil(this.$destroy),
        switchMap((s) => {
          const avaProject = this.avaProjectsService.getItemById(s.avaProjectId);
          if (avaProject) {
            this.lastAvaProjectReference = avaProject;
            return of({ avaProjectId: s.avaProjectId, isManuallyEdited: !avaProject.isUneditedImport });
          }

          return this.avaProjectsClient
            .getAvaProjectById(s.parentProjectId, s.avaProjectId)
            .pipe(map((ap) => ({ avaProjectId: s.avaProjectId, isManuallyEdited: !ap.isUneditedImport })));
        })
      )
      .subscribe((r: { avaProjectId: string; isManuallyEdited: boolean }) => {
        this.setServiceSpecificationHasChange(r.isManuallyEdited);
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private setServiceSpecificationHasChange(isManuallyEdited: boolean): void {
    this.serviceSpecificationHasChangeSource.next(isManuallyEdited);
  }

  checkServiceSpecificationHasBeenEdited(): Observable<boolean> {
    return this.serviceSpecificationHasChange.pipe(
      take(1),
      switchMap((isManuallyEdited) => {
        if (isManuallyEdited) {
          return of(true);
        } else {
          return this.modalService
            .openModal(ModalConfirmComponent, {
              dialogType: ConfirmationType.General,
              data: [
                'LV Bearbeiten',
                'Dieses LV wurde aus einer Datei importiert und bisher noch nicht verändert. Soll es verändert werden'
              ],
              autoFocus: false,
              restoreFocus: false
            })
            .afterClosed()
            .pipe(
              switchMap((isConfirmEdited) => {
                if (isConfirmEdited) {
                  if (this.lastAvaProjectReference) {
                    this.lastAvaProjectReference.isUneditedImport = false;
                  }
                  this.setServiceSpecificationHasChange(true);
                  return of(true);
                } else {
                  return of(false);
                }
              })
            );
        }
      })
    );
  }
}
