import { NgIf } from '@angular/common';
import { Component, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

import { UploadFileSettings } from '@shared/components/upload-file/upload-file.component';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';

import { UploadFileComponent } from '../../../../shared/components/upload-file/upload-file.component';
import { ElectronService } from '../../../../shared/services/electron/electron.service';

@Component({
  selector: 'pa-create-model-dialog',
  templateUrl: './create-model-dialog.component.html',
  styleUrls: ['./create-model-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    UploadFileComponent,
    NgIf,
    MatButton,
    FlexLayoutDirective
  ]
})
export class CreateModelDialogComponent {
  name = '';
  uploadFileSettings: UploadFileSettings = {
    label: 'Datei zum Hochladen hierher ziehen',
    isMultipleSelection: false,
    height: '200px',
    accept: '.ifc'
  };

  selectedFile: {
    data: File;
    fileName: string;
  };

  constructor(
    @Optional() private matDialogRef: MatDialogRef<CreateModelDialogComponent>,
    private electronService: ElectronService
  ) {}

  createModel(): void {
    this.matDialogRef.close({ selectedFile: this.selectedFile, name: this.name });
  }

  dismiss(): void {
    this.matDialogRef.close();
  }

  onSelect(fileList: File[]): void {
    if (!fileList.length) {
      return;
    }

    const addedFile = fileList[0];
    /** here also need to check whether the application works as an electron,
     * because in the web version the browser does not have access to the path to the file*/
    if (addedFile.size === 0 || (!addedFile?.path && this.electronService.isElectron)) {
      return;
    }

    this.selectedFile = {
      data: addedFile,
      fileName: addedFile.name
    };
  }
}
