import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';

import { ChangeViewportHeightService } from '@serv-spec/services/change-viewport-height.service';

import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { ModalService } from 'app/shared/services/modal.service';
import { ShowedViewsService } from 'app/shared/services/showed-views.service';
import { getStorage, setStorage } from 'app/shared/utilities/storage';

import { FlexLayoutDirective } from '../../../flex-layout/flex-layout.directive';

import { OneViewWindowComponent } from '../one-view-window/one-view-window.component';
import { IOutputAreaSizes, SplitComponent, AngularSplitModule } from 'angular-split';

@Component({
  selector: 'pa-multi-view-window',
  templateUrl: './multi-view-window.component.html',
  styleUrls: ['./multi-view-window.component.scss'],
  standalone: true,
  imports: [CommonModule, AngularSplitModule, FlexLayoutDirective, OneViewWindowComponent]
})
export class MultiViewWindowComponent implements OnInit, OnDestroy {
  @Output() selectView = new EventEmitter<boolean>();
  @Input() listWindow: string[] = [] as string[];
  @ViewChild('asSplitVerticalRef') asSplitVerticalRef: SplitComponent;
  private $destroy = new Subject<boolean>();

  constructor(
    private modalService: ModalService,
    private showedViewsService: ShowedViewsService,
    private changeViewportHeightService: ChangeViewportHeightService
  ) {}

  ngOnInit(): void {
    this.changedCount();
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  onDragEnd(sizes: IOutputAreaSizes): void {
    setStorage<number[]>('AS_SPLIT_MAIN_VERTICAL_SIZE', sizes as number[]);
    this.changeViewportHeightService.updatedViewportHeight(true);
  }

  changedCount(): void {
    const saved = getStorage<number[]>('AS_SPLIT_MAIN_VERTICAL_SIZE', [] as number[]) as number[];
    setTimeout(() => {
      let list = this.getArrayFrom(this.asSplitVerticalRef?.displayedAreas).map((item) => item['size']);
      list = saved.length === list.length ? saved : list;
      this.asSplitVerticalRef?.setVisibleAreaSizes(list);
      this.onDragEnd(list);
    }, 1);
  }

  getArrayFrom(arr): any[] {
    const resArr = [];
    for (let i = 0; i < arr?.length; i++) {
      resArr.push(arr[i]);
    }
    return resArr;
  }

  removeWindow(name: string): void {
    this.listWindow = this.listWindow.filter((item) => item !== name);
    this.showedViewsService.setShowedViews([...this.listWindow]);
    this.changedCount();
  }

  separateWindow(data: { name: string; rect: DOMRect }, isRestore?: boolean): void {
    this.modalService
      .openModal(OneViewWindowComponent, {
        dialogType: ConfirmationType.General,
        data,
        position: { top: `${data.rect.top}px`, left: `${data.rect.left}px` },
        hasBackdrop: false,
        panelClass: 'pane-window'
      })
      .afterClosed()
      .subscribe((isRemove) => {
        if (isRemove) {
          if (isRemove === 1) {
            this.listWindow.push(data.name);
            this.showedViewsService.setShowedViews([...this.listWindow]);
            this.changedCount();
          }
          this.showedViewsService.delSeparatedViews(data.name);
        }
      });
    if (!isRestore) {
      this.removeWindow(data.name);
      this.showedViewsService.addSeparatedViews(data);
    }
  }

  selectWindow(): void {
    this.selectView.emit(true);
  }

  showSeparatedWindows(): void {
    this.showedViewsService.setShow();
  }

  tryLeave(): void {
    this.showedViewsService.tryHide();
  }
}
