<div>
  <h1>Produktkataloge</h1>
  <button mat-raised-button color="primary" (click)="createCatalog()">Neuer Katalog</button>
</div>
<div fxLayoutGap="12px" class="filter-wrap">
  <mat-form-field fxFlex="1 0">
    <mat-label>Suche</mat-label>
    <input matInput [(ngModel)]="filterText" (ngModelChange)="changeFilter()" />
  </mat-form-field>

  <div>
    <mat-checkbox [(ngModel)]="showArchivedCatalogs" (ngModelChange)="changeShowingArchivedCatalogs()">
      Archivierte Kataloge anzeigen
    </mat-checkbox>
  </div>
</div>

<div class="mat-elevation-z8">
  <pa-table-wrapper [offsetFromMaxHeightTable]="430">
    <table mat-table [dataSource]="catalogsService" matSort (matSortChange)="catalogsService.onSort($event)">
      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="createdAtUtc">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let element">{{ element.createdAtUtc | date: 'dd.MM.yyyy HH:mm' }}</td>
      </ng-container>

      <ng-container matColumnDef="isArchived">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          <ng-container [ngSwitch]="element.isArchived">
            <mat-icon color="primary" *ngSwitchCase="false" matTooltip="Aktiv"> unarchive </mat-icon>
            <mat-icon color="warn" *ngSwitchCase="true" matTooltip="Aktuell nicht (mehr) genutzt"> archive</mat-icon>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="contactName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Lieferant</th>
        <td mat-cell *matCellDef="let element">{{ element.contactName }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        class="catalogs-table-tr"
        *matRowDef="let row; columns: displayedColumns"
        [class.small]="noSeparate"
        (click)="selectElement(row)"
      ></tr>
    </table>
  </pa-table-wrapper>
  <pa-upper-paginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    [upperPaginated]="catalogsService.paginationResult | async"
    [upperService]="catalogsService"
    (page)="catalogsService.onPage($event)"
  >
  </pa-upper-paginator>
</div>
