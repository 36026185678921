<pa-general-equipment [mode]="modeEquipment" *ngIf="!noSeparate; else mainContent">
  <ng-container *ngTemplateOutlet="mainContent"></ng-container>
</pa-general-equipment>

<ng-template #mainContent>
  <div class="wrapper">
    <div class="btn-ground" *ngIf="canHaveLongText(currentElement)">
      <div class="btn-wrap" fxLayoutGap="12px">
        <button mat-raised-button color="primary" *ngIf="isChanged && editMode" (click)="saveChanges()">Speichern</button>
        <button mat-raised-button color="primary" (click)="changeEditMode()">{{ editMode ? 'Abbrechen' : 'Bearbeiten' }}</button>
      </div>
    </div>
    <ng-container *ngIf="!editMode; else editLongText">
      <pa-long-text
        *ngIf="htmlLongText; else noText"
        [longText]="htmlLongText"
        (changedLongText)="changedLongText($event)"
        [addition]="addition"
      >
      </pa-long-text>
      <ng-template #noText>Kein Langtext</ng-template>
    </ng-container>

    <ng-template #editLongText>
      <div *ngIf="editMode">
        <dangl-tiny-mce tinyMceLanguageCode="de" [(ngModel)]="htmlLongText" (ngModelChange)="changedTinyMce($event)"></dangl-tiny-mce>
      </div>
    </ng-template>
  </div>
</ng-template>
