import { Component, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

import { SelectRowsService } from 'app/shared/services/select-rows.service';

@Component({
  selector: 'pa-select-all-rows',
  templateUrl: './select-all-rows.component.html',
  styleUrls: ['./select-all-rows.component.scss'],
  standalone: true,
  imports: [MatTooltip]
})
export class SelectAllRowsComponent {
  @Input() dataList: any[];
  @Input() selectList: number[];
  @Input() disabled: boolean;

  constructor(private selectRowsService: SelectRowsService) {}

  selectAllRun(): void {
    if (!this.disabled) {
      this.selectRowsService.markFirst(1);
      this.selectList.length = 0;
      this.selectList.push(...this.selectRowsService.getNewList(this.dataList.length, false, false));
      this.selectRowsService.raiseRequestAllRowsSums();
    }
  }
}
