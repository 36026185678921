<div fxFlexFill fxLayoutAlign="center center" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>
<div class="wrapper" *ngIf="!isLoading">
  <div class="head">
    <div class="checkbox" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="start center">
      <button mat-raised-button color="primary" class="icon-min icon-text" (click)="back()" (dblclick)="backCalculation()">
        Übersicht
      </button>
      <pa-invoice-page-paginator></pa-invoice-page-paginator>
      <div *ngIf="isReadOnly" class="red noedit">Schreibgeschützt</div>
      <mat-checkbox [(ngModel)]="isChecked" (change)="saveChecked()" *ngIf="structureView !== 'estimations'">Geprüft</mat-checkbox>

      <div fxLayoutGap="2px" fxLayout="row">
        <button
          mat-raised-button
          color="primary"
          class="icon-min"
          [disabled]="!focusedRow || !selectOriginOverlay"
          (click)="openOverlayPanel(focusedRow, selectOriginOverlay)"
          matTooltip="Suche"
        >
          <mat-icon>search</mat-icon>
        </button>
        <button
          mat-raised-button
          color="primary"
          class="icon-min"
          [disabled]="!focusedRow"
          (click)="addRow(focusedRow, false, true)"
          matTooltip="Zeile Einfügen"
        >
          <mat-icon>playlist_add</mat-icon>
        </button>
        <button
          mat-raised-button
          color="primary"
          class="icon-min"
          [disabled]="!focusedRow"
          (click)="deleteRow(focusedRow)"
          matTooltip="Zeile Löschen"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <div fxLayoutGap="2px" fxLayout="row">
        <button
          mat-raised-button
          [color]="focusedRow?.isBold ? 'primary' : ''"
          class="icon-min"
          [disabled]="!focusedRow"
          (click)="toggleFontStyle('bold', focusedRow)"
          matTooltip="Fett"
        >
          <mat-icon>format_bold</mat-icon>
        </button>
        <button
          mat-raised-button
          [color]="focusedRow?.isCursive ? 'primary' : ''"
          class="icon-min"
          [disabled]="!focusedRow"
          (click)="toggleFontStyle('italic', focusedRow)"
          matTooltip="Kursiv"
        >
          <mat-icon>format_italic</mat-icon>
        </button>
        <button
          mat-raised-button
          [color]="focusedRow?.isUnderlined ? 'primary' : ''"
          class="icon-min"
          [disabled]="!focusedRow"
          (click)="toggleFontStyle('underline', focusedRow)"
          matTooltip="Unterstrichen"
        >
          <mat-icon>format_underlined</mat-icon>
        </button>
      </div>
      <div fxLayout="row" fxLayoutGap="5px">
        <button
          mat-raised-button
          [color]="focusedRow && focusedRow['color'] ? 'primary' : ''"
          class="icon-min color"
          [disabled]="!focusedRow"
          (click)="activeColor()"
          matTooltip="Textfarbe"
        >
          <mat-icon>format_color_text</mat-icon>
          <div
            *ngIf="focusedRow && focusedRow['color']"
            class="color-mark"
            [ngStyle]="{ background: focusedRow && focusedRow['color'] }"
          ></div>
          <pa-color-picker
            *ngIf="focusedRow"
            [currentColore]="focusedRow['color']"
            (updateCurrentColor)="addColor($event, focusedRow)"
            (blurColor)="blurColor()"
          ></pa-color-picker>
        </button>

        <button
          mat-raised-button
          [color]="focusedRow && focusedRow.textSizeFactor ? 'primary' : ''"
          class="icon-min size"
          [disabled]="!focusedRow"
          (click)="activeColor()"
          matTooltip="Textgröße"
          [matMenuTriggerFor]="sizeMenu"
          #changeSizeMenu="matMenuTrigger"
        >
          <mat-icon>format_size</mat-icon>
          <div *ngIf="focusedRow && focusedRow.textSizeFactor" class="size-mark">
            {{ focusedRow.textSizeFactor }}
          </div>

          <mat-menu #sizeMenu="matMenu">
            <ng-container *ngFor="let size of sizes">
              <button mat-menu-item (click)="changeSize(size.size)" (keyup)="tryChangeSize($event)">
                <div fxLayout="row" fxLayoutAlign="space-between">
                  <span>{{ size.size }} </span>
                  <span>{{ size.title }} </span>
                </div>
              </button>
            </ng-container>
          </mat-menu>
        </button>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px" class="page-info">
      <div fxLayout="column" fxLayoutAlign="start start">
        <label>Blattnummer</label>
        <input
          type="text"
          matInput
          [(ngModel)]="currentPage.pageNumber"
          (change)="updateInvoicePage(currentPage.pageNumber, currentPage, 'pageNumber')"
        />
      </div>
      <div fxLayout="column" fxLayoutAlign="start start">
        <label>Titel</label>
        <input type="text" matInput [(ngModel)]="currentPage.name" (change)="updateInvoicePage(currentPage.name, currentPage, 'name')" />
      </div>
      <div fxLayout="column" fxLayoutAlign="start start">
        <label>BT Code</label>
        <input
          type="text"
          matInput
          [(ngModel)]="currentPage.buildingElementCode"
          (change)="updateInvoicePage(currentPage.buildingElementCode, currentPage, 'buildingElementCode')"
        />
      </div>
      <div fxLayout="column" fxLayoutAlign="start start">
        <label>LZ</label>
        <input
          type="text"
          matInput
          [(ngModel)]="currentPage.servicePeriodCode"
          (change)="updateInvoicePage(currentPage.servicePeriodCode, currentPage, 'servicePeriodCode')"
        />
      </div>
      <div fxLayout="column" fxLayoutAlign="start start">
        <label>AZ</label>
        <input
          type="text"
          matInput
          [(ngModel)]="currentPage.invoicePeriodCode"
          (change)="updateInvoicePage(currentPage.invoicePeriodCode, currentPage, 'invoicePeriodCode')"
        />
      </div>
      <div fxLayout="column" fxLayoutAlign="start start">
        <label>RE</label>
        <input
          type="text"
          matInput
          [(ngModel)]="currentPage.invoiceRecipientCode"
          (change)="updateInvoicePage(currentPage.invoiceRecipientCode, currentPage, 'invoiceRecipientCode')"
        />
      </div>

      <div fxLayout="column" fxLayoutAlign="start start">
        <label>Subunternehmer</label>
        <input
          type="text"
          matInput
          [value]="subContractor?.name"
          [disabled]="isReadOnly"
          readonly
          matTooltip="Verknüpfter Subunternehmer"
          (click)="changeSubContractor()"
        />
      </div>
    </div>
    <pa-position-line [element]="selectedPosition"></pa-position-line>
  </div>

  <div class="sub-wrapper">
    <cdk-virtual-scroll-viewport
      tvsItemSize="22"
      headerHeight="4"
      class="table-wrapper mat-elevation-z8"
      [style.height]="sanitizer.bypassSecurityTrustStyle('calc(100% - ' + totalSumHeight + 'px )')"
    >
      <table
        mat-table
        [dataSource]="tableDataSource"
        resizeName="page"
        (keydown.tab)="$event.preventDefault()"
        (keyup)="onKey($event)"
        [ngClass]="{ readonly: isReadOnly, selecting: isSelectingFormulaRowMode }"
        [trackBy]="trackByFn"
        cdkDropList
        cdkDropListOrientation="horizontal"
        (cdkDropListDropped)="dropHeader($event)"
      >
        <ng-container matColumnDef="index">
          <th mat-header-cell *matHeaderCellDef class="index mat-table-sticky" cdkDrag [cdkDragDisabled]="true">
            <pa-select-all-rows [dataList]="tableDataSource.data" [selectList]="listSelectedRows"></pa-select-all-rows>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="grey-back index"
            (contextmenu)="onContextMenu($event, element, '', originOverlay_index)"
            (mousedown)="startSelectRow(element.rowIndex)"
            cdkOverlayOrigin
            #originOverlay_index="cdkOverlayOrigin"
          >
            <div class="div-wrap">
              <span id="{{ element.rowIndex }}-index">#{{ element.rowIndex }}</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="rowType">
          <th mat-header-cell *matHeaderCellDef class="row-type mat-table-sticky drag-head" cdkDrag>
            I/Z
            <div class="drag-head__handle" cdkDragHandle></div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (contextmenu)="onContextMenu($event, element, '', originOverlay_rowType)"
            class="row-type"
            cdkOverlayOrigin
            #originOverlay_rowType="cdkOverlayOrigin"
          >
            <input
              (change)="changeInput($event, element, 'rowType')"
              type="text"
              [value]="element.rowType || ''"
              (focus)="setRowFocus($event, element)"
              (keydown)="inputRowType($event, element, 'rowType')"
              maxlength="1"
              id="{{ element.rowIndex }}-rowType"
              [disabled]="isReadOnly"
              (blur)="lostFocus($event)"
            />
          </td>
        </ng-container>

        <ng-container matColumnDef="itemNumber">
          <th mat-header-cell *matHeaderCellDef resizeColumn class="mat-table-sticky drag-head" cdkDrag>
            <span>OZ</span>
            <div class="drag-head__handle" cdkDragHandle></div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (contextmenu)="onContextMenu($event, element, '', originOverlay)"
            [matTooltip]="
              element.itemNumber && element.invalidItemNumber
                ? element.itemNumber + ' ist keine gültige OZ'
                : userSettings.showTooltipsInQtoTable
                ? element.itemNumber
                : ''
            "
            [matTooltipClass]="{ 'tooltip-red': element.invalidItemNumber }"
          >
            <input
              paOverwriteMode
              (change)="changeInput($event, element, 'itemNumber')"
              (overwriteModeChanged)="changeInputFromOverrideMode($event, element, 'itemNumber')"
              type="text"
              id="{{ element.rowIndex }}-itemNumber"
              [value]="element.itemNumber || ''"
              (keydown)="inputItemNumber($event, element, 'itemNumber', originOverlay)"
              (focus)="setRowFocus($event, element, originOverlay)"
              [disabled]="isReadOnly"
              (blur)="lostFocus($event)"
              cdkOverlayOrigin
              #originOverlay="cdkOverlayOrigin"
              [class.number-invalid]="element.invalidItemNumber"
            />
          </td>
        </ng-container>

        <ng-container matColumnDef="formula">
          <th mat-header-cell *matHeaderCellDef resizeColumn class="drag-head mat-table-sticky" cdkDrag>
            Ansatz
            <div class="drag-head__handle" cdkDragHandle></div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (contextmenu)="onContextMenu($event, element, '', originOverlay_formula)"
            [ngClass]="{ bold: element.isBold, italic: element.isCursive, underline: element.isUnderlined }"
            [matTooltip]="userSettings.showTooltipsInQtoTable ? element.formula : ''"
            class="error"
            cdkOverlayOrigin
            #originOverlay_formula="cdkOverlayOrigin"
          >
            <input
              paFormulaWarning
              paOverwriteMode
              (overwriteModeChanged)="changeInputFromOverrideMode($event, element, 'formula')"
              (change)="changeInput($event, element, 'formula')"
              type="text"
              [value]="element.formula || ''"
              id="{{ element.rowIndex }}-formula"
              (focus)="setRowFocus($event, element)"
              (keydown)="preventKeyDownEvent($event, element, 'formula')"
              [disabled]="isReadOnly"
              (blur)="lostFocus($event)"
              [ngStyle]="{
                color: element['color'],
                'font-size': getSize(element.textSizeFactor)
              }"
              [ngClass]="{
                'number-invalid': element.itemNumber && element.formulaErrorType
              }"
              [matTooltipClass]="{ 'tooltip-red': true }"
              [matTooltip]="element.formulaErrorMessage"
            />
            <div
              class="error_line"
              *ngIf="element.formulaErrorMessage != null && element.formulaErrorPosition != null"
              [ngStyle]="{ 'font-size': getSize(element.textSizeFactor) }"
            >
              {{ element.formula?.slice(0, element.formulaErrorPosition) }}<span class="error_point">_</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="shortText">
          <th mat-header-cell *matHeaderCellDef resizeColumn class="drag-head mat-table-sticky" cdkDrag>
            Kurztext
            <div class="drag-head__handle" cdkDragHandle></div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (contextmenu)="onContextMenu($event, element, '', originOverlay_shortText)"
            class="grey-back nowrap"
            id="{{ element.rowIndex }}-shortText"
            [matTooltip]="userSettings.showTooltipsInQtoTable ? (positions[element.avaPositionId] | positionText) : ''"
            cdkOverlayOrigin
            #originOverlay_shortText="cdkOverlayOrigin"
          >
            <ng-container *ngIf="positions && element?.avaPositionId">
              <div class="nowrap-abs">
                {{ positions[element.avaPositionId] | positionText }}
              </div>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="result">
          <th mat-header-cell *matHeaderCellDef resizeColumn class="right-align drag-head mat-table-sticky" cdkDrag>
            Ergebnis
            <div class="drag-head__handle" cdkDragHandle></div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (contextmenu)="onContextMenu($event, element, '', originOverlay_result)"
            class="grey-back right-align"
            id="{{ element.rowIndex }}-result"
            [matTooltip]="userSettings.showTooltipsInQtoTable ? (element.result | number: '1.3-3') : ''"
            cdkOverlayOrigin
            #originOverlay_result="cdkOverlayOrigin"
          >
            <ng-container *ngIf="element.avaPositionId || element.rowType === 'Z' || element.rowType === 'I'">
              {{ element.result | number: '1.3-3' }}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="referenceName">
          <th mat-header-cell *matHeaderCellDef class="reference-name drag-head mat-table-sticky" resizeColumn cdkDrag>
            Referenz
            <div class="drag-head__handle" cdkDragHandle></div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (contextmenu)="onContextMenu($event, element, '', originOverlay_referenceName)"
            class="reference-name"
            [matTooltip]="userSettings.showTooltipsInQtoTable ? element.referenceName : ''"
            cdkOverlayOrigin
            #originOverlay_referenceName="cdkOverlayOrigin"
          >
            <input
              paOverwriteMode
              [paInputMask]="'[a-zA-Z_].*'"
              (change)="changeInput($event, element, 'referenceName')"
              (overwriteModeChanged)="changeInputFromOverrideMode($event, element, 'referenceName')"
              type="text"
              [value]="element.referenceName || ''"
              (focus)="setRowFocus($event, element)"
              id="{{ element.rowIndex }}-referenceName"
              (keydown)="preventKeyDownEvent($event, element, 'referenceName')"
              [disabled]="isReadOnly"
              (blur)="lostFocus($event)"
            />
          </td>
        </ng-container>

        <ng-container matColumnDef="unit">
          <th mat-header-cell *matHeaderCellDef resizeColumn class="drag-head mat-table-sticky" cdkDrag>
            Einheit
            <div class="drag-head__handle" cdkDragHandle></div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (contextmenu)="onContextMenu($event, element, '', originOverlay_unit)"
            class="grey-back nowrap"
            id="{{ element.rowIndex }}-unit"
            cdkOverlayOrigin
            #originOverlay_unit="cdkOverlayOrigin"
          >
            {{ element.unitTag }}
          </td>
        </ng-container>

        <ng-container matColumnDef="totalCost">
          <th mat-header-cell *matHeaderCellDef resizeColumn class="right-align drag-head mat-table-sticky" cdkDrag>
            Summe
            <div class="drag-head__handle" cdkDragHandle></div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (contextmenu)="onContextMenu($event, element, '', originOverlay_totalCost)"
            class="grey-back right-align nowrap"
            id="{{ element.rowIndex }}-totalCost"
            [matTooltip]="userSettings.showTooltipsInQtoTable ? (element.totalCost | projectCurrency) : ''"
            cdkOverlayOrigin
            #originOverlay_totalCost="cdkOverlayOrigin"
          >
            {{ element.totalCost | projectCurrency }}
          </td>
        </ng-container>

        <ng-container matColumnDef="image">
          <th mat-header-cell *matHeaderCellDef resizeColumn class="drag-head mat-table-sticky" cdkDrag>
            Bild
            <div class="drag-head__handle" cdkDragHandle></div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="table-image"
            id="{{ element.rowIndex }}-image"
            (contextmenu)="onContextMenu($event, element, 'image')"
          >
            <ng-container *ngIf="element.fileId">
              <ng-container *ngIf="userSettings.showImagesInQuantityTakeOff">
                <img id="{{ element.rowIndex }}-image" src="{{ element.fileId | imgSrc | async }}" *ngIf="element.fileId" />
              </ng-container>
              <ng-container *ngIf="!userSettings.showImagesInQuantityTakeOff">
                <span (click)="showImage(element)" matTooltip="Bild anzeigen" class="cross-text"> Bild </span>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="element.quantityTakeOffAttachmentId">
              <span (click)="showImage(element)" matTooltip="Anlage anzeigen" class="cross-text"> Anlage </span>
            </ng-container>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
          (contextmenu)="showHeaderContextMenu($event)"
          class="mat-header-row"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (mouseup)="stopSelectRow($event, row.rowIndex)"
          (click)="clearSelectionRow($event, row)"
          [class]="'row-index' + row.rowIndex"
          [ngClass]="{
            'selected-row': selectedRow(row.rowIndex),
            'showed-row': showMoveSelectRow(row.rowIndex),
            'circular-error': circularErrorRowIndizes[row.rowIndex] && !selectedRow(row.rowIndex) && !showMoveSelectRow(row.rowIndex)
          }"
        ></tr>
      </table>
    </cdk-virtual-scroll-viewport>
    <div
      class="totals"
      *ngIf="(isTreeOpen || isToggleTreeWidth) && structureView !== 'estimations' && userSettings.showsTotalsInQuantityTakeOffBelowTable"
    >
      <pa-total-sums [isTreeOpen]="isTreeOpen" [isToggleTreeWidth]="isToggleTreeWidth" (setTotalSumsHeight)="setTotalSumsHeight($event)">
      </pa-total-sums>
    </div>
  </div>
</div>

<div
  style="position: fixed; visibility: hidden"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
  #selectPictureOrAttachmentMenuTrigger="matMenuTrigger"
></div>
<mat-menu #contextMenu="matMenu" class="row-menu">
  <ng-template matMenuContent let-item="item">
    <ng-container *ngIf="isImageContextMenu; else withOutBtnForLoadPicture">
      <button *ngIf="item.fileId" mat-menu-item (click)="deleteImage(item)">Bild löschen</button>
      <button
        *ngIf="!item.fileId && !item.quantityTakeOffAttachmentId"
        mat-menu-item
        class="btn-for-load-picture"
        (click)="openBrowseFileModal()"
      >
        Bild hinzufügen
      </button>
      <button
        *ngIf="!item.quantityTakeOffAttachmentId && !item.fileId && !isStandalonePage"
        mat-menu-item
        class="btn-for-load-picture"
        (click)="openInvoicePdfFilesModal()"
      >
        Anlage hinzufügen
      </button>
      <button *ngIf="item.quantityTakeOffAttachmentId" mat-menu-item (click)="deleteImage(item)">Anlage löschen</button>
    </ng-container>
    <ng-template #withOutBtnForLoadPicture>
      <div class="btn-fontStyle" *ngIf="userSettings.showButtonsToManageFontStylesInContextMenu">
        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" multiple class="toggle-group-style">
          <mat-button-toggle class="flex-expand" [checked]="item.isBold" (change)="toggleFontStyle('bold', item)">Fett</mat-button-toggle>
          <mat-button-toggle class="flex-expand" [checked]="item.isCursive" (change)="toggleFontStyle('italic', item)"
            >Kursiv</mat-button-toggle
          >
          <mat-button-toggle class="flex-expand" [checked]="item.isUnderlined" (change)="toggleFontStyle('underline', item)"
            >Unterstrichen</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
      <button mat-menu-item (click)="copyCurrentLine(item)">Zeile Kopieren</button>
      <button mat-menu-item (click)="addRow(item, false, true)">Zeile davor hinzufügen</button>
      <button mat-menu-item (click)="addRow(item, true, true)">Zeile danach hinzufügen</button>
      <button mat-menu-item class="red" (click)="deleteRowPlus(item)" *ngIf="!listSelectedRows.length">Zeile löschen</button>
      <button
        mat-menu-item
        class="red"
        *ngIf="listSelectedRows.length && listSelectedRows.length === 1"
        (click)="deleteSelectedRowsPlus(false)"
      >
        Zeile löschen
      </button>
      <button
        mat-menu-item
        class="red"
        *ngIf="listSelectedRows.length && listSelectedRows.length > 1"
        (click)="deleteSelectedRowsPlus(false)"
      >
        {{ listSelectedRows.length }} Zeilen löschen
      </button>
      <button mat-menu-item class="red" *ngIf="listSelectedRows.length && listSelectedRows.length === 1" (click)="cutSelectedRowsPlus()">
        Zeile ausschneiden
      </button>
      <button mat-menu-item class="red" *ngIf="listSelectedRows.length && listSelectedRows.length > 1" (click)="cutSelectedRowsPlus()">
        {{ listSelectedRows.length }} Zeilen ausschneiden
      </button>
      <button mat-menu-item *ngIf="listSelectedRows.length" (click)="saveRows()">Ausgewählte Zeilen Kopieren</button>
      <div fxLayoutAlign="start center" class="btn-fontStyle" *ngIf="numRowsClipboardTable">
        <div [matBadge]="numRowsClipboardTable" class="btn-selected-rows">Zeilen</div>
        <mat-button-toggle-group>
          <mat-button-toggle (click)="copySavedRows(item)">Einfügen</mat-button-toggle>
          <mat-button-toggle (click)="copySavedRows(item, true)">Überschreiben</mat-button-toggle>
          <mat-button-toggle (click)="saveRows(true)">Vergessen</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <button mat-menu-item *ngIf="numRowsClipboardTable" (click)="pasteClipboardRows(item)">
        Einfügen mit neuer OZ {{ numRowsClipboardTable }} Zeile{{ numRowsClipboardTable > 1 ? 'n' : '' }}
      </button>
      <button mat-menu-item *ngIf="listSelectedRows.length" (click)="changeFormula(item)">Referenz aus Selektion einfügen</button>
    </ng-template>
  </ng-template>
</mat-menu>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpenListNumber"
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayLockPosition
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  (backdropClick)="closeOverlay()"
>
  <div class="list">
    <div class="search-wrapper">
      <input
        type="text"
        [(ngModel)]="filter"
        (keydown)="cancelled($event, originOverlay1)"
        (keyup)="$event.stopPropagation()"
        cdkOverlayOrigin
        #originOverlay1="cdkOverlayOrigin"
        #focused
        class="search"
      />
      <mat-checkbox [(ngModel)]="isSearchLongText" (ngModelChange)="usingLongText()" style="white-space: nowrap"
        >Suche inkl. Langtext</mat-checkbox
      >
      <span class="mark-multi" *ngIf="isMultiSelect">multi</span>
    </div>
    <div class="list-number" [style.max-height]="maxHeight">
      <div class="list-wrapper" [class.extended]="userSettings.showPositionTotalInvoicedInSearchWindowInQuantityTakeOff">
        <cdk-virtual-scroll-viewport #tableViewport tvsItemSize="24" headerHeight="24" [style.height]="maxHeight ? maxHeight : '300px'">
          <table mat-table [dataSource]="filteredListArr" class="item-table">
            <ng-container matColumnDef="multi">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <div *ngIf="isMultiSelect" class="multi-number">
                  {{ multiSelect.has(element.id) ? multiSelect.get(element.id) + 1 : '' }}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="itemNumber">
              <th mat-header-cell *matHeaderCellDef class="item-number">OZ</th>
              <td mat-cell *matCellDef="let element" class="item-number">
                {{ element.num }}
              </td>
            </ng-container>

            <ng-container matColumnDef="shortText">
              <th mat-header-cell *matHeaderCellDef>Text</th>
              <td mat-cell *matCellDef="let element" class="search-menu-short-text-cell">
                {{ positions[element.id] | positionText }}
              </td>
            </ng-container>

            <ng-container matColumnDef="unitPrice">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="right"
                (click)="isShowQuantityColumn = !isShowQuantityColumn"
                matTooltip="Klicken um zwischen EP und LV-M zu wechseln"
              >
                <div fxLayoutAlign="end center">
                  <span>{{ isShowQuantityColumn ? 'LV-M' : 'EP' }}</span>
                  <mat-icon class="loop-icon">loop</mat-icon>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" class="right">
                <ng-container *ngIf="isShowQuantityColumn">
                  {{ positionQuantity[element.id] | number: '1.3-3' }}
                </ng-container>
                <ng-container *ngIf="!isShowQuantityColumn">
                  {{ positionUnitPrice[element.id] | number: '1.2-2' }}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="unitTag">
              <th mat-header-cell *matHeaderCellDef>Einheit</th>
              <td mat-cell *matCellDef="let element">
                {{ positionUnits[element.id] }}
              </td>
            </ng-container>

            <ng-container matColumnDef="longText">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element; let i = index" class="icon-text">
                <div *ngIf="element.longText">
                  <mat-icon
                    class="item-icon"
                    (click)="fastLongText($event, element, i, originOverlay2)"
                    cdkOverlayOrigin
                    #originOverlay2="cdkOverlayOrigin"
                    >subject</mat-icon
                  >
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="quantity">
              <th mat-header-cell *matHeaderCellDef class="right">Menge</th>
              <td mat-cell *matCellDef="let element" class="right">
                <span *ngIf="positionPercentage[element.num]">{{ positionPercentage[element.num].quantityTakeOff | number: '1.3-3' }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="percentage">
              <th mat-header-cell *matHeaderCellDef>% Abgerechnet</th>
              <td mat-cell *matCellDef="let element">
                <pa-progressbar-percentage
                  [value]="positionPercentage[element.num].quantityTakeOffPercentage"
                  *ngIf="positionPercentage[element.num]"
                ></pa-progressbar-percentage>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplaySearchTable; sticky: true"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: columnsToDisplaySearchTable; let i = index"
              class="item"
              [matTooltip]="positions[row.id] | positionText"
              matTooltipPosition="left"
              (click)="selectNumber($event, row, i)"
              [ngClass]="{
                'active-num': row.id === filteredListArr.data[activeIndex].id,
                multi: multiSelect.has(row.id),
                'separation-border':
                  filteredListArr.data[i - 1] && filteredListArr.data[i].parentId !== filteredListArr.data[i - 1].parentId
              }"
            ></tr>
          </table>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center " *ngIf="userSettings.showPositionTotalInvoicedInSearchWindowInQuantityTakeOff">
      <div>
        <span>Σ Position: </span>
        <span>{{ percentegByActivePosition | percent }}</span>
      </div>
      <mat-progress-bar
        [ngClass]="{
          'red-bg': percentegByActivePosition > 1.1,
          'green-bg': 0.9 <= percentegByActivePosition && 1.1 >= percentegByActivePosition
        }"
        [value]="percentegByActivePosition * 100"
        [bufferValue]="100"
      >
      </mat-progress-bar>
    </div>
    <div class="info">
      <span *ngIf="!isMultiSelect">Klick/Enter - Auswahl</span>
      <span *ngIf="!isMultiSelect && !avoidMultiSelect">Strg + Klick/Enter - Mehrfachauswahl</span>
      <span *ngIf="isMultiSelect">Mehrfachauswahl aktiv - Klick/Enter zum Übernehmen</span>
    </div>
  </div>
</ng-template>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="triggerLongText"
  [cdkConnectedOverlayOpen]="isOpenListNumberLongText"
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayLockPosition
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  (backdropClick)="closeOverlayLongText()"
>
  <div class="list list-text" [innerHTML]="showedLongText.htmlLongText || '' | safeHtml"></div>
</ng-template>

<mat-menu #rowHeaderContextMenu="matMenu">
  <ng-template matMenuContent>
    <div mat-menu-item class="context-menu-calc">
      <button mat-button (click)="editListColumns()" class="overlay-button">Spalten Verwalten</button>
    </div>
    <div mat-menu-item class="context-menu-calc">
      <button mat-button (click)="limitWidth(true)" class="overlay-button">Spalten gleich aufteilen</button>
    </div>
    <div mat-menu-item class="context-menu-calc">
      <button mat-button (click)="limitWidth()" class="overlay-button">Spalten an Inhalt anpassen</button>
    </div>
  </ng-template>
</mat-menu>

<div
  style="position: fixed; visibility: hidden"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="rowHeaderContextMenu"
  #rowHeaderContextMenuTrigger="matMenuTrigger"
></div>
