import { Injectable } from '@angular/core';
import { TreeNodeStateService } from 'app/areas/tree/services/tree-node-state.service';
import { CopyCalculationViewMessengerService } from 'app/shared/services/electron/copy-calculation-view-messenger.service';
import { UserSettingsService } from 'app/shared/services/user-settings.service';
import { ProjectsClient } from '../generated-client/generated-client';
import { ProjectsService } from '../shared/services/lightquery/projects.service';
import { SelectedProjectMessengerService } from '../shared/services/messengers/selected-project-messenger.service';

@Injectable({
  providedIn: 'root'
})
export class CurrentProjectIdService {
  constructor(
    private projectsClient: ProjectsClient,
    private projectsService: ProjectsService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private copyCalculationViewMessengerService: CopyCalculationViewMessengerService,
    private userSettingsService: UserSettingsService,
    private treeNodeStateService: TreeNodeStateService
  ) {}

  selectProject(projectId: string | null): void | null {
    if (!projectId) {
      this.selectedProjectMessengerService.setSelectedProject(null);
      this.treeNodeStateService.resetTreeState();
    } else {
      const project = this.projectsService.tryGetProjectById(projectId);
      this.userSettingsService.changeFullSettings();
      if (project) {
        this.selectedProjectMessengerService.setSelectedProject(project);
        this.copyCalculationViewMessengerService.sendSelectedProjectToViewWindow(project);
      } else {
        this.projectsClient.getProjectById(projectId).subscribe(p => {
          this.selectedProjectMessengerService.setSelectedProject(p);
          this.copyCalculationViewMessengerService.sendSelectedProjectToViewWindow(p);
        });
      }
    }
  }
}
