<h1>Projektbenutzer Entfernen</h1>
<p>Falls der Benutzer inaktiv ist und eine Kalkulation oder Abrechnung blockiert, kannst Du ihn rauswerfen.</p>
<p><strong>Achtung:</strong> Der Benutzer kann dabei nicht gespeicherte Änderungen verlieren.</p>
<p>Nachdem der Benutzer entfernt wurde ist die Position oder das Aufmaßblatt wieder für andere Benutzer freigegeben.</p>

<p>
  Möchtest Du <strong>{{ data.userInfo?.username }}</strong> rauswerfen?
</p>

<div>
  <mat-checkbox [(ngModel)]="confirmKick">Bestätigen</mat-checkbox>
</div>

<button mat-raised-button color="warn" [disabled]="!confirmKick" (click)="tryKickUser()">Benutzer rauswerfen</button>
<button mat-raised-button color="primary" (click)="cancel()">Abbrechen</button>
