import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { PriceComponentType } from '../../../generated-client/generated-client';

@Injectable({
  providedIn: 'root'
})
export class SelectedPriceComponentTypeService {
  private selectedPriceComponentTypeSource = new ReplaySubject<PriceComponentType | null>(1);
  selectedPriceComponentType = this.selectedPriceComponentTypeSource.asObservable();

  private updatingSource = new Subject<boolean>();
  updating = this.updatingSource.asObservable();

  constructor() {}

  setSelectedPriceComponentType(selectedValue: PriceComponentType | null): void {
    this.selectedPriceComponentTypeSource.next(selectedValue);
  }

  setUpdating(value: boolean): void {
    this.updatingSource.next(value);
  }
}
