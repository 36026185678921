<div mat-dialog-content>
  <h2>Benachrichtigungen</h2>
  <div *ngIf="hasId" class="note">
    <div>Datum: {{ currentNote.date | date: 'dd.MM.yyyy HH:mm' }}</div>
    <div fxLayoutAlign="row center" fxLayoutGap="5px">
      <span>Typ:</span>
      <pa-note-icon [mode]="currentNote.type"></pa-note-icon>
      <span>{{ currentNote.type | notificationType }}</span>
    </div>
    <div *ngIf="currentNote.title">
      Titel: <span class="note-title">{{ currentNote.title }}</span>
    </div>
    <div>
      Nachricht: <span class="note-message">{{ currentNote.message }}</span>
    </div>
  </div>

  <div *ngIf="!hasId" class="note-list">
    <div *ngFor="let note of notifications" class="note">
      <div>Datum: {{ note.date | date: 'dd.MM.yyyy HH:mm' }}</div>
      <div fxLayoutAlign="row center" fxLayoutGap="5px">
        <span>Typ:</span>
        <pa-note-icon [mode]="note.type"></pa-note-icon>
        <span>{{ note.type | notificationType }}</span>
      </div>
      <div *ngIf="note.title">
        Titel: <span class="note-title">{{ note.title }}</span>
      </div>
      <div>
        Nachricht: <span class="note-message">{{ note.message }}</span>
      </div>
    </div>
  </div>

  <div class="buttons" fxLayoutGap="12px">
    <button mat-raised-button mat-dialog-close color="warn">Schließen</button>
    <ng-container *ngIf="hasId">
      <button mat-raised-button color="primary" [disabled]="!hasPrevious" (click)="getClosest()">Vorherige</button>
      <button mat-raised-button color="primary" [disabled]="!hasNext" (click)="getClosest(true)">Nächste</button>
    </ng-container>
  </div>
</div>
