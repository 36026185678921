import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { NgIf, NgTemplateOutlet, NgFor } from '@angular/common';
import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatDivider } from '@angular/material/divider';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelectionList, MatListOption } from '@angular/material/list';
import { MatSelect } from '@angular/material/select';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';

import { TinyMceComponent } from '@dangl/angular-material-shared/tiny-mce';

import { Subject } from 'rxjs';
import { filter, first, take, takeUntil } from 'rxjs/operators';

import { LvEditorService } from '@serv-spec/services/lv-editor.service';

import {
  AdditionTypeDto,
  ComissionStatusDto,
  ItemNumberDto,
  PositionDto,
  PositionTypeDto,
  PriceTypeDto,
  ServiceTypeDto,
  StandardReferenceTypeDto,
  UserSettings
} from 'app/generated-client/generated-client';
import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { FilterDialogType } from 'app/shared/models';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { ProjectQuantityEstimationService } from 'app/shared/services/messengers/project-quantity-estimation.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';
import { SpecificationSourceService } from 'app/shared/services/specification-source.service';
import { UserSettingsService } from 'app/shared/services/user-settings.service';

import { AdditionTypePipe } from '../../../../../../../../shared/pipes/ui-data-display/addition-type.pipe';
import { CatalogueTypePipe } from '../../../../../../../../shared/pipes/ui-data-display/catalogue-type.pipe';
import { ComissionStatusPipe } from '../../../../../../../../shared/pipes/ui-data-display/comission-status.pipe';
import { PositionTypePipe } from '../../../../../../../../shared/pipes/ui-data-display/position-type.pipe';
import { PriceTypePipe } from '../../../../../../../../shared/pipes/ui-data-display/price-type.pipe';
import { ServiceTypePipe } from '../../../../../../../../shared/pipes/ui-data-display/service-type.pipe';
import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';
import { ItemNumberEditingComponent } from '../../../../../../../service-specification-editing/components/item-number-editing/item-number-editing.component';

@Component({
  selector: 'pa-lv-editor-position',
  templateUrl: './lv-editor-position.component.html',
  styleUrls: ['./lv-editor-position.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutDirective,
    MatDivider,
    NgIf,
    NgTemplateOutlet,
    MatTabGroup,
    MatTab,
    CdkConnectedOverlay,
    MatSelectionList,
    NgFor,
    MatListOption,
    MatFormField,
    MatLabel,
    FormsModule,
    MatInput,
    CdkOverlayOrigin,
    MatSuffix,
    MatSelect,
    MatOption,
    MatCheckbox,
    TinyMceComponent,
    ItemNumberEditingComponent,
    MatButton,
    PositionTypePipe,
    AdditionTypePipe,
    CatalogueTypePipe,
    PriceTypePipe,
    ComissionStatusPipe,
    ServiceTypePipe
  ]
})
export class LvEditorPositionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() element: PositionDto;
  @Input() isEditableAll: boolean;
  @Input() isModal: boolean;
  @HostListener('document:keyup', ['$event']) handleKeyboardUpEvent(event: KeyboardEvent): void {
    switch (event.key) {
      case 'F2':
        if (this.isUnitTagFocused) {
          this.openOverlayPanel();
        }
        break;
      case 'Escape':
        this.closeOverlay();
        break;
      default:
    }
  }

  elementChanged: PositionDto = null;
  elementList: FilterDialogType[];
  executionDescriptionsList: FilterDialogType[];
  projectCurrency: string;
  isChanged: boolean;
  isElementChanged: boolean;
  showLangText: boolean;
  positionQuantity: number;
  isOpenListUnitTag = false;
  isUnitTagFocused = false;
  typesOfTags = ['psch', 'm', 'm2', 'm3', 'kg', 't', 'St', 'h', 'd', 'Wo', 'VE'];

  positionTypes = Object.keys(PositionTypeDto) as PositionTypeDto[];
  priceTypes = Object.keys(PriceTypeDto) as PriceTypeDto[];
  additionTypes = Object.keys(AdditionTypeDto) as AdditionTypeDto[];
  standardReferenceTypes = Object.keys(StandardReferenceTypeDto) as StandardReferenceTypeDto[];
  comissionStatuses = Object.keys(ComissionStatusDto) as ComissionStatusDto[];
  serviceTypes = Object.keys(ServiceTypeDto) as ServiceTypeDto[];

  private settings: UserSettings;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private lvEditorService: LvEditorService,
    private sourceService: SpecificationSourceService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private projectQuantityEstimationService: ProjectQuantityEstimationService,
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private userSettingsService: UserSettingsService
  ) {}

  ngOnInit(): void {
    this.getElement();

    this.sourceService.loadAllData.pipe(takeUntil(this.$destroy)).subscribe((list: FilterDialogType[]) => {
      this.elementList = list.filter((item: FilterDialogType) => item.type === 'PositionDto');
      this.executionDescriptionsList = list.filter((item: FilterDialogType) => item.type === 'ExecutionDescriptionDto');
    });

    this.selectedSpecificationMessengerService.projectCurrency
      .pipe(takeUntil(this.$destroy))
      .subscribe((currency: string) => (this.projectCurrency = currency));

    this.userSettingsService.currentFullSettings.pipe(takeUntil(this.$destroy)).subscribe((setting: UserSettings) => {
      this.settings = setting;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isChanged) {
      this.openConfirmWindow();
    } else {
      this.getElement();
      if (changes.element.previousValue && changes.element.currentValue && changes.element.previousValue !== changes.element.currentValue) {
        setTimeout(() => {
          if (!this.isEditableAll) {
            this.lvEditorService.stopChangeElement();
          }
        }, 1);
      }
    }
  }

  ngOnDestroy(): void {
    this.lvEditorService.isElementChanged.pipe(take(1)).subscribe((isElementChanged) => (this.isElementChanged = isElementChanged));
    if (this.isChanged && this.isElementChanged) {
      this.openConfirmWindow();
    }
    setTimeout(() => {
      this.lvEditorService.stopChangeElement();
    }, 1);
    this.lvEditorService.setChangeElement(false);
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  getElement(): void {
    if (this.element) {
      this.elementChanged = JSON.parse(JSON.stringify(this.element));
      this.positionQuantity = this.element.quantity;
    }
  }

  exit(save: boolean = false): void {
    if (save) {
      if (this.elementChanged.quantity != this.positionQuantity) {
        this.elementChanged.quantityOverride = this.positionQuantity;
      }

      this.lvEditorService.saveChangedElement(this.elementChanged);
    }
    this.lvEditorService.stopChangeElement();
    this.isChanged = false;
    this.lvEditorService.setChangeElement(this.isChanged);
  }

  changedTinyMce(event, obj, prop) {
    if (this.showLangText && event !== obj[prop]) {
      this.isChanged = true;
      this.lvEditorService.setChangeElement(this.isChanged);
      obj[prop] = event;
    }
  }

  onItemNumberChanged(itemNumber: ItemNumberDto): void {
    this.isChanged = true;
    this.lvEditorService.setChangeElement(this.isChanged);
    this.elementChanged.itemNumber = itemNumber;
  }

  showTinyMce(e) {
    if (e.index === 2) {
      this.showLangText = true;
    }
  }
  hideTinyMce(e) {
    if (e !== 2) {
      this.showLangText = false;
    }
  }

  closeOverlay(): void {
    this.isOpenListUnitTag = false;
  }

  openOverlayPanel(): void {
    this.isOpenListUnitTag = true;
  }

  chooseUnitTag(unitTag: string): void {
    this.elementChanged.unitTag = unitTag;
    this.isChanged = true;
    this.lvEditorService.setChangeElement(this.isChanged);
    this.closeOverlay();
  }

  changeElement(): void {
    this.isChanged = true;
    this.lvEditorService.setChangeElement(this.isChanged);
  }

  openConfirmWindow(): void {
    this.modalService
      .openModal(ModalConfirmComponent, {
        dialogType: ConfirmationType.General,
        data: ['Verlassen', 'Position', 'Änderungen wurden nicht gespeichert. Speichern?'],
        autoFocus: false
      })
      .afterClosed()
      .subscribe((r) => {
        this.exit(!!r);
      });
  }

  dblClicking(): void {
    if (this.router.url.includes('lv-editor') && this.projectQuantityEstimationService.quantityEstimationId) {
      // When we switch to the position QTO view, we exit the tree, and therefore
      // the selected element is cleared. However, we want to ensure that the
      // current position is selected once we have switched, so we select it again.
      this.selectedSpecificationElementMessengerService.selectedElement
        .pipe(
          filter((e) => e == null),
          first()
        )
        .subscribe(() => {
          this.selectedSpecificationElementMessengerService.trySelectElementById(this.element?.id);
        });
      this.router.navigate(['..', 'estimations', this.projectQuantityEstimationService.quantityEstimationId, 'positions'], {
        relativeTo: this.route,
        skipLocationChange: true,
        queryParams: {
          // When opening a QTO, the default is to open the last opened position in this QTO.
          // However, since we want to open the position that is currently selected in this calculation
          // view, we want to skip the default behaviour
          isIgnoreAutomaticPath: true
        }
      });
    }
  }
}
