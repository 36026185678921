import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { DOCUMENT, NgIf, NgClass, AsyncPipe, DecimalPipe } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadge } from '@angular/material/badge';
import { MatButton } from '@angular/material/button';
import { MatButtonToggleGroup, MatButtonToggle } from '@angular/material/button-toggle';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatMenuTrigger, MatMenu, MatMenuContent, MatMenuItem } from '@angular/material/menu';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { GuidGenerator } from '@dangl/angular-material-shared/guid-generator';

import { EMPTY, Observable, Subject, combineLatest, Subscription, fromEvent } from 'rxjs';
import { filter, finalize, first, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { InvoicePdfFileComponent } from '@serv-spec/components/invoice/components/invoice-pdf-file/invoice-pdf-file.component';
import { TotalSumsComponent } from '@serv-spec/components/total-sums/total-sums.component';
import { ChangeTotalService } from '@serv-spec/services/change-total.service';
import { KeyupControlService } from '@serv-spec/services/keyup-control.service';
import { LimitColumnWidthService } from '@serv-spec/services/limit-column-width.service';
import { ManageInvoiceTableService } from '@serv-spec/services/manage-invoice-table.service';

import { OverwriteModeMessengerService } from '@shared/services/messengers/overwrite-mode-messenger.service';
import { ProjectQuantityEstimationService } from '@shared/services/messengers/project-quantity-estimation.service';

import { ProjectFilesComponent } from '@project/components/project-files/components/project-files/project-files.component';
import { ProjectCurrentItemsService } from '@project/components/project-files/services/project-current-items.service';
import { SelectedFileToUploadService } from '@project/components/project-files/services/selected-file-to-upload.service';

import { getAppConfig } from 'app/app-config-accessor';
import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';
import {
  ApiErrorOfFormulaError,
  AvaProjectGet,
  FormulaErrorType,
  IElementDto,
  PositionDto,
  PositionQuantityTakeOffGet,
  PositionQuantityTakeOffModel,
  PositionQuantityTakeOffRowModel,
  PositionQuantityTakeOffsClient,
  ProjectDto,
  ProjectFileGet,
  ProjectGet,
  QuantityTakeOffGet,
  QuantityTakeOffRowType,
  UserSettings
} from 'app/generated-client/generated-client';
import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { ShowingColumnsModalComponent } from 'app/shared/components/showing-columns-modal/showing-columns-modal.component';
import { ShowedTableQuantitiesType, StyleEnumType } from 'app/shared/models';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { ClipboardService } from 'app/shared/services/clipboard.service';
import { ContextMenuSettingsService } from 'app/shared/services/context-menu-settings.service';
import { TreeViewMessengerService } from 'app/shared/services/electron/tree-view-messenger.service';
import { QuantityTakeOffInvoiceTotalsMessengerService } from 'app/shared/services/messengers/quantity-take-off-invoice-totals-messenger.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedQuantityTakeOffMessengerService } from 'app/shared/services/messengers/selected-quantity-take-off-messenger.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';
import { SelectRowsService } from 'app/shared/services/select-rows.service';
import { ShowingColumnsService } from 'app/shared/services/showing-columns.service';
import { AvaHubConnector } from 'app/shared/services/signalr/ava-hub-connector';
import { UserSettingsService } from 'app/shared/services/user-settings.service';
import { dateUTC } from 'app/shared/utilities/dateUTC';

import { PositionLineComponent } from '../../../../../../../../shared/components/position-line/position-line.component';
import { SelectAllRowsComponent } from '../../../../../../../../shared/components/select-all-rows/select-all-rows.component';
import { FormulaWarningDirective } from '../../../../../../../../shared/directives/formula-warning.directive';
import { InputMaskDirective } from '../../../../../../../../shared/directives/input-mask.directive';
import { ImgSrcPipe } from '../../../../../../../../shared/pipes/img-src.pipe';
import { ProjectCurrencyPipe } from '../../../../../../../../shared/pipes/ui-data-display/project-currency.pipe';
import { ResizableDirective } from '../../directives/resizable.directive';
import { CheckFormulaErrorService } from '../../services/check-formula-error.service';
import { SetFontStyleService } from '../../services/set-font-style.service';
import { TreeDisplayService } from '../../services/tree-display.service';

import { InvoiceImageModalComponent } from '../invoice-image-modal/invoice-image-modal.component';
import { InvoiceModalForDeletingImageComponent } from '../invoice-modal-for-deleting-image/invoice-modal-for-deleting-image.component';
import { Calculator } from 'antlr-calculator';
import { TableVirtualScrollDataSource, TableVirtualScrollModule } from 'ng-table-virtual-scroll';

@Component({
  selector: 'pa-invoice-positions',
  templateUrl: './invoice-positions.component.html',
  styleUrls: ['./invoice-positions.component.scss'],
  providers: [OverwriteModeMessengerService],
  standalone: true,
  imports: [
    NgIf,
    MatProgressSpinner,
    MatButton,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatTooltip,
    MatDatepicker,
    PositionLineComponent,
    CdkVirtualScrollViewport,
    TableVirtualScrollModule,
    MatTable,
    CdkDropList,
    NgClass,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    CdkDrag,
    SelectAllRowsComponent,
    MatCellDef,
    MatCell,
    CdkDragHandle,
    ResizableDirective,
    FormulaWarningDirective,
    InputMaskDirective,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    TotalSumsComponent,
    MatMenuTrigger,
    MatMenu,
    MatMenuContent,
    MatMenuItem,
    MatButtonToggleGroup,
    MatButtonToggle,
    MatBadge,
    AsyncPipe,
    DecimalPipe,
    ProjectCurrencyPipe,
    ImgSrcPipe,
    FlexLayoutDirective
  ]
})
export class InvoicePositionsComponent implements OnInit, OnDestroy {
  @Input() isCompact: boolean;
  @Input() isReadOnlyOriginal: boolean;
  get isReadOnly(): boolean {
    return this.isReadOnlyOriginal || this.isSelectingFormulaRowMode;
  }
  @ViewChildren(MatMenuTrigger) private contextMenu: QueryList<MatMenuTrigger>;
  @ViewChild(CdkVirtualScrollViewport) private viewTable: CdkVirtualScrollViewport;
  @ViewChild(MatTable) private table: MatTable<any>;
  @HostListener('document:mouseup', ['$event']) handleMouseUpEvent(event: MouseEvent): void {
    this.stopSelectRow(event);
  }
  @HostListener('document:keydown', ['$event'])
  handleGlobalKeyboardEvent(event: KeyboardEvent): void {
    switch (event.key) {
      case 'F11':
      case 'F12':
        if (this.isChanged) {
          this.recalculate(-1);
        }
        break;
      case 'c':
      case 'C':
        if (event.ctrlKey) {
          if (this.listSelectedRows.length && !getSelection().toString()) {
            this.saveRows();
            event.preventDefault();
          }
          if (getSelection().toString()) {
            this.saveRows(true);
            this.notificationsService.info('Text kopiert', 'Kopie:', 3000);
          }
        }
        break;
      case 'F5':
        if (this.listSelectedRows.length) {
          this.cutSelectedRowsPlus();
        }
        break;
      case 'Delete':
        this.listSelectedRows = event.altKey ? [this.lastFocusedRowIndex] : [...this.listSelectedRows];
        if (this.listSelectedRows.length && !this.isReadOnly) {
          this.deleteSelectedRowsPlus(event.altKey);
        }
        break;
      case 'F7':
        if (this.listSelectedRows.length) {
          this.isSelectingFormulaRowMode = true;
          this.notificationsService.info('Zeile zum Einfügen der Referenz auswählen');
        }
        break;
      case 'Escape':
        if (this.isSelectingFormulaRowMode) {
          this.isSelectingFormulaRowMode = false;
        }
        break;
      default:
    }
  }

  userSettings: UserSettings = {} as UserSettings;
  isShowReferenceName: boolean;
  isSelectingFormulaRowMode: boolean;
  contextMenuPosition = { x: '0px', y: '0px' };
  tableDataSource = new TableVirtualScrollDataSource<PositionQuantityTakeOffRowModel>();
  get dataSource(): PositionQuantityTakeOffRowModel[] {
    return this.tableDataSource.data;
  }
  set dataSource(value: PositionQuantityTakeOffRowModel[]) {
    this.tableDataSource.data = value;
  }
  savedSelectedRows: PositionQuantityTakeOffRowModel[] = [] as PositionQuantityTakeOffRowModel[];
  _defaultDisplayedColumns: string[] = ['index', 'rowType', 'formula', 'result', 'unit', 'referenceName', 'totalCost', 'image'];
  defaultDisplayedColumns: string[];
  displayedColumns: string[] = [];
  listSelectedRows: number[] = this.selectRowsService.init();
  isLoading = true;
  invoicePositionInvoiceName: string;
  isLoadReport = false;
  structureView: string;
  quantityTakeOffsRunning: { [avaPositionId: string]: boolean } = {};
  lastFocusedRowIndex: number;
  avaPositionId: string = null;
  isChanged: boolean;
  unitTag: string;
  elementDto: IElementDto;
  isTreeOpen = false;
  selectedPosition: PositionDto = null;
  isToggleTreeWidth = false;
  circularErrorRowIndizes: { [rowIndex: number]: boolean } = {};
  lastRecalculationHadError = false;
  isImageContextMenu: boolean;
  tryGoBack: boolean;
  performanceQtoDate = new FormGroup({
    performancePeriodStartUtc: new FormControl(),
    performancePeriodEndUtc: new FormControl()
  });

  private clipboardWord = 'positionQto'; // variable to change by setting object
  private quantityTakeOffGetResults = new Subject<PositionQuantityTakeOffGet>();
  private listMove: number[];
  private activeElement: PositionQuantityTakeOffRowModel;
  private activeRow: HTMLElement;
  private activeRowIndex: number;
  private avaProjectId: string;
  private calculationData: PositionQuantityTakeOffModel;
  private calculationInProgress = false;
  private calculationQueue = 0;
  private dialogRef: MatDialogRef<ProjectFilesComponent>;
  private projectId: string;
  private quantityTakeOffId: string;
  private selectedFile: File;
  private $destroy: Subject<boolean> = new Subject<boolean>();
  isCursorOutsideText = true;
  private lastEnteredPosition: { projectId: string; avaProjectId: string; quantityTakeOffId: string; positionId: string };
  totalSumHeight = 0;
  columnName: string;
  mousemove$: Subscription;
  lastFocusedElementId: string | null = null;
  nameStorageOrderColumn = 'orderColumns-positionsQto';

  constructor(
    private contextMenuSettingsService: ContextMenuSettingsService,
    private keyupControlService: KeyupControlService,
    private manageInvoiceTableService: ManageInvoiceTableService,
    private modalService: ModalService,
    private positionQuantityTakeOffsClient: PositionQuantityTakeOffsClient,
    private projectCurrentItemsService: ProjectCurrentItemsService,
    private router: Router,
    private route: ActivatedRoute,
    private selectedFileToUploadService: SelectedFileToUploadService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedQuantityTakeOffMessengerService: SelectedQuantityTakeOffMessengerService,
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private setFontStyleService: SetFontStyleService,
    private notificationsService: AvaNotificationsService,
    private userSettingsService: UserSettingsService,
    private changeTotalService: ChangeTotalService,
    private selectRowsService: SelectRowsService,
    private avaHubConnector: AvaHubConnector,
    private quantityTakeOffInvoiceTotalsMessengerService: QuantityTakeOffInvoiceTotalsMessengerService,
    private clipboardService: ClipboardService,
    private treeViewMessengerService: TreeViewMessengerService,
    private treeDisplayService: TreeDisplayService,
    private showingColumnsService: ShowingColumnsService,
    @Inject(DOCUMENT) private document: Document,
    public sanitizer: DomSanitizer,
    private el: ElementRef,
    private limitColumnWidthService: LimitColumnWidthService,
    private projectQuantityEstimationService: ProjectQuantityEstimationService,
    private checkFormulaErrorService: CheckFormulaErrorService
  ) {}

  ngOnInit(): void {
    this.defaultDisplayedColumns = this.showingColumnsService.getDefaultDisplayedColumns(
      this.nameStorageOrderColumn,
      this._defaultDisplayedColumns
    );
    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(takeUntil(this.$destroy), take(1))
      .subscribe((selection) => {
        // To ensure that we've set the project id before we've selected a position
        this.projectId = selection?.parentProjectId;
        this.avaProjectId = selection?.avaProjectId;
      });

    this.structureView = this.router.url.includes('invoices') ? 'invoices' : 'estimations';
    if (this.structureView === 'estimations') {
      this.displayedColumns = this.displayedColumns.filter((item: string) => item !== 'totalCost');
    }

    if (!this.isCompact) {
      this.selectedQuantityTakeOffMessengerService.selectedQuantityTakeOff
        .pipe(
          takeUntil(this.$destroy),
          filter((qto) => !!qto),
          take(1)
        )
        .subscribe((qto) => {
          // To ensure that we've set the qto id before we've selected a position
          this.quantityTakeOffId = qto.id;
          if (this.structureView === 'invoices' && !this.performanceQtoDate.value.performancePeriodStartUtc) {
            this.performanceQtoDate = new FormGroup({
              performancePeriodStartUtc: new FormControl({ value: dateUTC(qto.performancePeriodStartUtc, true), disabled: true }),
              performancePeriodEndUtc: new FormControl({ value: dateUTC(qto.performancePeriodEndUtc, true), disabled: true })
            });
          }
        });
    }

    this.userSettingsService.currentFullSettings.pipe(takeUntil(this.$destroy)).subscribe((setting: UserSettings) => {
      this.userSettings = setting;
      if (!this.isLoading) {
        this.changeShowReferenceName();
      }
    });

    this.avaHubConnector.userKicked.pipe(takeUntil(this.$destroy)).subscribe((kickResult) => {
      if (kickResult.isSuccess) {
        this.isReadOnlyOriginal = true;
      }
    });

    const request = this.isCompact ? this.getCompactQuantityTakeOff() : this.getQuantityTakeOff();
    request.pipe(takeUntil(this.$destroy)).subscribe(
      (element: PositionQuantityTakeOffGet) => {
        this.setTotal(element);
        if (element.avaPositionId === this.avaPositionId) {
          this.calculationData = element.positionData;
          this.dataSource = element.positionData.rows;
          this.changeShowReferenceName();
          setTimeout(() => this.tryScrollToErrorRow(), 100);
        }

        this.isLoading = false;
      },
      (error) => console.error(error)
    );

    this.selectedSpecificationElementMessengerService.selectedElement.pipe(takeUntil(this.$destroy)).subscribe((selectedElement) => {
      this.ensureRowSelectionReset();
      if (selectedElement?.element?.elementTypeDiscriminator === 'PositionDto') {
        this.selectedPosition = selectedElement.element as PositionDto;
        this.unitTag = this.selectedPosition.unitTag;
        this.avaPositionId = this.selectedPosition.id;
      } else {
        this.avaPositionId = null;
        this.selectedPosition = null;
      }
    });

    this.clipboardService.getData(this.clipboardWord, this.savedSelectedRows);
    this.treeViewMessengerService.treeViewVisible.pipe(takeUntil(this.$destroy)).subscribe((isTreeOpen: boolean) => {
      this.isTreeOpen = isTreeOpen;
    });

    this.treeDisplayService.isTreeDisplay.pipe(takeUntil(this.$destroy)).subscribe((isTreeDisplay: boolean) => {
      this.isToggleTreeWidth = isTreeDisplay;
    });

    this.showingColumnsService.settingsLoaded.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.displayedColumns = this.showingColumnsService.getFilteredColumns('PositionQto', this.defaultDisplayedColumns);
    });

    this.selectRowsService.requestAllRows.pipe(takeUntil(this.$destroy)).subscribe(() => {
      const summa = this.dataSource.reduce(
        (sum: number, item: PositionQuantityTakeOffRowModel) => sum + (typeof item.totalCost === 'number' ? item.totalCost : 0),
        0
      );
      const quantity = this.dataSource.reduce(
        (sum: number, item: PositionQuantityTakeOffRowModel) => sum + (typeof item.result === 'number' ? item.result : 0),
        0
      );

      this.selectRowsService.setSelectedRowSumma(summa, quantity);
    });

    this.selectedQuantityTakeOffMessengerService.errorRowIndexQuantityTakeOff.pipe(takeUntil(this.$destroy)).subscribe((e) => {
      if (e?.isSameUrl) {
        this.tryScrollToErrorRow();
      }
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
    this.selectRowsService.setSelectedRowSumma(null, null);
    if (
      !this.isCompact &&
      this.lastEnteredPosition &&
      this.lastEnteredPosition.projectId &&
      this.lastEnteredPosition.avaProjectId &&
      this.lastEnteredPosition.quantityTakeOffId &&
      this.lastEnteredPosition.positionId
    ) {
      this.avaHubConnector.notifyOfExitingQuantityTakeOffPosition(
        this.lastEnteredPosition.projectId,
        this.lastEnteredPosition.avaProjectId,
        this.lastEnteredPosition.quantityTakeOffId,
        this.lastEnteredPosition.positionId
      );
    }
    if (this.mousemove$) {
      this.mousemove$.unsubscribe();
    }

    this.tableDataSource.disconnect();
    this.tableDataSource = null;
  }

  tryScrollToErrorRow(): void {
    if (this.isCompact) {
      return;
    }
    this.selectedQuantityTakeOffMessengerService.errorRowIndexQuantityTakeOff.pipe(take(1), takeUntil(this.$destroy)).subscribe((e) => {
      if (e) {
        this.checkIfRowShowed(e.errorRowIndex);
        this.listSelectedRows = [e.errorRowIndex];
        this.selectRowsService.listSelectedRows = [e.errorRowIndex];
        this.selectedQuantityTakeOffMessengerService.setErrorRowIndex(null);
      }
    });
  }

  checkIfRowShowed(errorRowIndex: number): void {
    const viewport = this.viewTable.getElementRef().nativeElement;
    const showedLine = viewport.querySelector(`.row-index${errorRowIndex}`);
    if (showedLine) {
      const viewportRect = viewport.getBoundingClientRect();
      const showedLineRect = showedLine.getBoundingClientRect();
      if (showedLineRect.bottom < viewportRect.bottom && showedLineRect.top > viewportRect.top) {
        return;
      }
    }
    this.viewTable.scrollToIndex(errorRowIndex - 1);
  }

  changeShowReferenceName(): void {
    this.isShowReferenceName =
      this.userSettings.showReferenceNameInQuantityTakeOff ||
      this.dataSource.some((item: PositionQuantityTakeOffRowModel) => item.referenceName);
    this.changeShowingOfReferenceNameColumn(this.isShowReferenceName);
  }

  private setTotal(positionQto: PositionQuantityTakeOffGet): void {
    const positionQuantity = positionQto.positionData.rows
      .filter((r) => r.rowType === QuantityTakeOffRowType.Normal)
      .reduce((sum: number, item: PositionQuantityTakeOffRowModel) => (typeof item.result === 'number' ? item.result + sum : sum), 0);
    this.quantityTakeOffInvoiceTotalsMessengerService.setCurrentTotalSum({
      ...positionQto.calculation,
      positionTotalSum: positionQto.totalSum,
      positionQuantity
    });
  }

  private getIdsForQuantityRequest(): Observable<
    [ProjectGet, { avaProjectId: string; project: ProjectDto }, QuantityTakeOffGet, { id: string; element: IElementDto }]
  > {
    return combineLatest([
      this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)),
      this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(takeUntil(this.$destroy)),
      this.selectedQuantityTakeOffMessengerService.selectedQuantityTakeOff.pipe(
        takeUntil(this.$destroy),
        filter((qto) => !!qto)
      ),
      this.selectedSpecificationElementMessengerService.selectedElement.pipe(
        takeUntil(this.$destroy),
        filter((element) => element?.element?.elementType === 'PositionDto')
      )
    ]).pipe(
      takeUntil(this.$destroy),
      tap((x) => {
        let lastEnteredPositionLocal = this.lastEnteredPosition;
        if (
          lastEnteredPositionLocal &&
          lastEnteredPositionLocal.projectId &&
          lastEnteredPositionLocal.avaProjectId &&
          lastEnteredPositionLocal.quantityTakeOffId &&
          lastEnteredPositionLocal.positionId
        ) {
          this.avaHubConnector.notifyOfExitingQuantityTakeOffPosition(
            lastEnteredPositionLocal.projectId,
            lastEnteredPositionLocal.avaProjectId,
            lastEnteredPositionLocal.quantityTakeOffId,
            lastEnteredPositionLocal.positionId
          );
        }

        this.lastEnteredPosition = {
          projectId: x[0]?.id,
          avaProjectId: x[1]?.avaProjectId,
          quantityTakeOffId: x[2]?.id,
          positionId: x[3]?.id
        };

        lastEnteredPositionLocal = this.lastEnteredPosition;

        if (
          lastEnteredPositionLocal.projectId &&
          lastEnteredPositionLocal.avaProjectId &&
          lastEnteredPositionLocal.quantityTakeOffId &&
          lastEnteredPositionLocal.positionId
        ) {
          this.avaHubConnector
            .tryEnterQuantityTakeOffPosition(
              lastEnteredPositionLocal.projectId,
              lastEnteredPositionLocal.avaProjectId,
              lastEnteredPositionLocal.quantityTakeOffId,
              lastEnteredPositionLocal.positionId
            )
            .then((r) => {
              if (!r.isSuccess && this.avaPositionId === r.positionId) {
                this.isReadOnlyOriginal = true;
                this.notificationsService.info('Diese Position wird gerade von einem anderen Benutzer bearbeitet.');
              }
            });
        }
      })
    );
  }

  private getCompactQuantityTakeOff(): Observable<PositionQuantityTakeOffGet | never> {
    return combineLatest([
      this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)),
      this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(takeUntil(this.$destroy)),
      this.selectedSpecificationElementMessengerService.selectedElement.pipe(
        takeUntil(this.$destroy),
        filter((element) => element?.element?.elementType === 'PositionDto')
      ),
      this.projectQuantityEstimationService.mainShowedTableQuantities.pipe(takeUntil(this.$destroy))
    ]).pipe(
      takeUntil(this.$destroy),
      switchMap(
        ([project, avaProject, elementDto, mainShowedTableQuantities]: [
          ProjectGet,
          { avaProjectId: string; project: ProjectDto; avaProject: AvaProjectGet },
          { id: string; element: IElementDto },
          ShowedTableQuantitiesType
        ]) => {
          this.projectId = project.id;
          this.avaProjectId = avaProject.avaProjectId;
          this.avaPositionId = elementDto.element.id;
          if (mainShowedTableQuantities === ShowedTableQuantitiesType.CalculatedQuantities) {
            this.quantityTakeOffId = avaProject.avaProject.quantityCalculationQuantityTakeOffId;
          } else if (mainShowedTableQuantities === ShowedTableQuantitiesType.AssumedQuantities) {
            this.quantityTakeOffId = avaProject.avaProject.assumedQuantitiesQuantityTakeOffId;
          }

          this.quantityTakeOffsRunning[this.avaPositionId] = true;

          this.positionQuantityTakeOffsClient
            .getPositionQuantityTakeOffById(this.projectId, this.avaProjectId, this.quantityTakeOffId, this.avaPositionId)
            .pipe(
              tap((qto) => this.quantityTakeOffGetResults.next(qto)),
              finalize(() => (this.quantityTakeOffsRunning[this.avaPositionId] = false))
            )
            .subscribe((qto) => this.quantityTakeOffGetResults.next(qto));

          return this.quantityTakeOffGetResults.pipe(
            filter((qto) => qto.avaPositionId === this.avaPositionId),
            first()
          );
        }
      )
    );
  }

  private getQuantityTakeOff(): Observable<PositionQuantityTakeOffGet | never> {
    return this.getIdsForQuantityRequest().pipe(
      switchMap(
        ([project, avaProject, qto, elementDto]: [
          ProjectGet,
          { avaProjectId: string; project: ProjectDto },
          QuantityTakeOffGet,
          { id: string; element: IElementDto }
        ]) => {
          this.circularErrorRowIndizes = {};
          this.projectId = project.id;
          this.avaProjectId = avaProject.avaProjectId;
          this.invoicePositionInvoiceName = qto.name;
          this.quantityTakeOffId = qto.id;
          if (this.structureView === 'invoices' && !this.performanceQtoDate.value.performancePeriodStartUtc) {
            this.performanceQtoDate = new FormGroup({
              performancePeriodStartUtc: new FormControl({ value: dateUTC(qto.performancePeriodStartUtc, true), disabled: true }),
              performancePeriodEndUtc: new FormControl({ value: dateUTC(qto.performancePeriodEndUtc, true), disabled: true })
            });
          }

          if (qto.markedAsBilledAtUtc) {
            this.isReadOnlyOriginal = true;
          }
          if (elementDto?.element.elementTypeDiscriminator === 'PositionDto') {
            const avaPositionId = elementDto.element.id;
            this.elementDto = elementDto.element;

            if (this.quantityTakeOffsRunning[avaPositionId]) {
              return this.quantityTakeOffGetResults.pipe(
                filter((qto) => qto.avaPositionId === avaPositionId),
                first()
              );
            }

            this.quantityTakeOffsRunning[avaPositionId] = true;

            this.positionQuantityTakeOffsClient
              .getPositionQuantityTakeOffById(this.projectId, this.avaProjectId, this.quantityTakeOffId, avaPositionId)
              .pipe(
                tap((qto) => {
                  this.quantityTakeOffGetResults.next(qto);
                }),
                finalize(() => {
                  this.quantityTakeOffsRunning[avaPositionId] = false;
                })
              )
              .subscribe((qto) => this.quantityTakeOffGetResults.next(qto));

            return this.quantityTakeOffGetResults.pipe(
              filter((qto) => qto.avaPositionId === avaPositionId),
              first()
            );
          } else {
            this.dataSource = [];
            return EMPTY;
          }
        }
      )
    );
  }

  private setNullValueToFieldsDataSource(item: PositionQuantityTakeOffRowModel): void {
    this.manageInvoiceTableService.setNullValueToFieldsDataSource(item, this.dataSource);
    this.recalculate();
  }

  onContextMenu(event: MouseEvent, element: PositionQuantityTakeOffRowModel, columnName?: string): void {
    if (!this.isReadOnly) {
      this.columnName = columnName;
      this.isImageContextMenu = !!this.columnName && (event.target as HTMLElement).nodeName === 'IMG';
      this.activeRow = event.target['offsetParent'].parentNode;
      this.activeRowIndex = element?.rowIndex;
      this.activeElement = element;
      this.contextMenuSettingsService.setDefaultSettings(event, element, this.contextMenuPosition, this.contextMenu.first);
    }
  }

  addRow(nextRow: PositionQuantityTakeOffRowModel, addAfter: boolean, isRecalculate = false): void {
    this.dataSource = this.manageInvoiceTableService.addRow(nextRow, addAfter, true, this.dataSource);
    if (isRecalculate) {
      this.recalculate();
    }
  }

  private copyRowAbove(event: KeyboardEvent): void {
    if (this.lastFocusedRowIndex > 1) {
      const copyRow = { ...this.dataSource[this.lastFocusedRowIndex - 2], rowIndex: this.lastFocusedRowIndex };
      this.dataSource = this.dataSource.map((item: PositionQuantityTakeOffRowModel, i: number) =>
        i === this.lastFocusedRowIndex - 1 ? { ...copyRow } : { ...item }
      );
      this.recalculate(0, event.target['id']);
    }
  }

  private copyPropAbove(event: KeyboardEvent, nameProp: string): void {
    if (this.lastFocusedRowIndex > 1) {
      this.dataSource[this.lastFocusedRowIndex - 1][nameProp] = this.dataSource[this.lastFocusedRowIndex - 2][nameProp];
      this.recalculate(this.lastFocusedRowIndex, event.target['id']);
    }
  }

  deleteRowPlus(item: PositionQuantityTakeOffRowModel): void {
    if (this.userSettings.promptForConfirmationWhenDeletingRows) {
      this.confirmationDeleting(item.rowIndex, () => this.deleteRow(item));
    } else {
      this.deleteRow(item);
    }
  }

  deleteRow(row): void {
    const warning = this.checkWarningForReference([row.rowIndex]);
    if (warning) {
      this.confirmationDeletingChecked(warning, () => this.deleteRowChecked(row));
    } else {
      this.deleteRowChecked(row);
    }
  }

  deleteRowChecked(item: PositionQuantityTakeOffRowModel): void {
    this.dataSource = this.dataSource.filter((el) => el.rowIndex !== item.rowIndex);
    this.dataSource.forEach((row: PositionQuantityTakeOffRowModel) =>
      this.manageInvoiceTableService.changeRelatingNumber(row, item.rowIndex - 1, -1, [item.rowIndex])
    );
    this.recalculate();
  }

  toggleFontStyle(type: string, row: PositionQuantityTakeOffRowModel): void {
    this.setFontStyleService.toggleFontStyle(StyleEnumType[type], row);
    this.recalculate();
  }

  onKey(event: KeyboardEvent): void {
    if (this.isCursorOutsideText) {
      this.keyupControlService.onKey(event, this.displayedColumns);
    }
  }

  setRowFocus(event: Event): void {
    this.lastFocusedElementId = event.target['id'];
  }

  lostFocus(): void {
    this.lastFocusedElementId = null;
  }

  preventKeyDownEvent(event: KeyboardEvent, element: PositionQuantityTakeOffRowModel, nameProp: string): void {
    const target = event.target as HTMLInputElement;
    this.lastFocusedRowIndex = element.rowIndex;
    switch (event.key) {
      case 'F11':
      case 'F12':
        // The reason for this is that when we've changed a cell and then directly hit F11 or F12 to navigate,
        // the change event isn't fired correctly. This means that the last change will otherwise not be saved.
        (<any>this.document.activeElement)?.blur();
        break;
      case 'F4':
        this.copyPropAbove(event, nameProp);
        break;
      case 'F5':
        this.listSelectedRows = [this.lastFocusedRowIndex];
        this.cutSelectedRowsPlus();
        break;
      case 'Insert':
        if (this.userSettings.allowOverwriteMode && !event.altKey) {
          event.preventDefault();
        } else {
          this.addRow(element, false);
          this.recalculate(element.rowIndex, event.target['id']);
        }
        break;
      case 'v':
      case 'V':
        if (event.ctrlKey && this.savedSelectedRows.length) {
          this.copySavedRows(element, true);
          event.preventDefault();
        }
        break;
    }
    if (
      ((event.key === 'ArrowRight' && target.selectionStart !== target.value.length) ||
        (event.key === 'ArrowLeft' && target.selectionStart !== 0)) &&
      target.type === 'text'
    ) {
      this.isCursorOutsideText = false;
    } else {
      this.keyupControlService.preventKeyDownEvent(event);
      this.isCursorOutsideText = true;
    }
  }

  openBrowseFileModal(): void {
    const correlationId = GuidGenerator.generatePseudoRandomGuid();

    this.setSelectedFile(correlationId);
    this.manageBrowseFiles();

    this.dialogRef = this.modalService.openModal(ProjectFilesComponent, {
      dialogType: ConfirmationType.General,
      data: {
        modalData: 'browseView',
        correlationId,
        extensions: ['.jpg', '.jpeg', '.png', '.gif']
      }
    });
    this.dialogRef.addPanelClass('modal-browse');
  }

  private setSelectedFile(correlationId: string): void {
    this.selectedFile = null;
    this.selectedFileToUploadService.selectedFile
      .pipe(
        takeUntil(this.$destroy),
        filter((r) => r.correlationId === correlationId),
        map((r) => r.file)
      )
      .subscribe((file: File | ProjectFileGet) => {
        if (file && !file['lastModified']) {
          this.dataSource[this.activeElement?.rowIndex - 1].projectFileFolderId = file['folderId'];
          this.dataSource[this.activeElement?.rowIndex - 1].projectFileId = file['id'];
          this.dataSource[this.activeElement?.rowIndex - 1].fileId = file['file']['id'];

          this.recalculate();
          this.selectedFileToUploadService.setSelectedFile(null, null);
          this.dialogRef.close();
        }
      });
  }

  private manageBrowseFiles(): void {
    this.projectCurrentItemsService.projCurrItems.pipe(takeUntil(this.$destroy)).subscribe((filesList) => {
      if (filesList.includes('uploadedFile')) {
        this.uploadImage(filesList);
      }
    });
  }

  private uploadImage(filesList): void {
    let addedFile: ProjectFileGet;
    if (filesList && this.selectedFile) {
      addedFile = filesList.find((item) => item.file?.fileName === this.selectedFile.name);

      if (filesList.includes('uploadedFile') && this.selectedFile) {
        const reader: FileReader = new FileReader();
        reader.onload = () => {
          this.dataSource[this.activeRowIndex - 1].fileId = addedFile.file.id;
          this.dataSource[this.activeRowIndex - 1].projectFileId = addedFile.id;
          this.dataSource[this.activeRowIndex - 1].projectFileFolderId = addedFile.folderId;

          this.recalculate();
          this.dialogRef.close();
        };
        reader.readAsDataURL(this.selectedFile);
        this.selectedFileToUploadService.setSelectedFile(null, null);
        filesList.pop();
        this.projectCurrentItemsService.setProjCurrItems(filesList, 'allItems');
      }
    }
  }

  deleteImage(element: PositionQuantityTakeOffRowModel): void {
    this.modalService
      .openModal(InvoiceModalForDeletingImageComponent, {
        dialogType: ConfirmationType.General,
        data: {
          element: element
        }
      })
      .afterClosed()
      .subscribe({
        next: (response) => {
          if (response) {
            this.setNullValueToFieldsDataSource(element);
          }
        },
        error: (error) => console.error('error', error)
      });
  }

  recalculate(numRow?: number, focusElementId?: string, forceRecalculateAll?: boolean): void {
    if (!this.projectId || !this.avaProjectId || !this.quantityTakeOffId || !this.avaPositionId || !this.calculationData) {
      return;
    }

    if (this.calculationInProgress) {
      this.calculationQueue++;
      return;
    }
    this.isChanged = false;
    this.calculationInProgress = true;
    this.circularErrorRowIndizes = {};

    this.calculationData.rows = this.dataSource;
    this.positionQuantityTakeOffsClient
      .updatePositionQuantityTakeOffById(
        this.projectId,
        this.avaProjectId,
        this.quantityTakeOffId,
        this.avaPositionId,
        this.calculationData
      )
      .subscribe(
        (r: PositionQuantityTakeOffGet) => {
          this.setTotal(r);
          const noEditColumns = ['result', 'totalCost'];

          const updateCell = (newCell, sourceCell) => {
            noEditColumns.forEach((key: string) => delete sourceCell[key]);
            Object.assign(sourceCell, newCell);
            for (const prop in sourceCell) {
              if (newCell[prop] == null && prop !== 'formula') {
                delete sourceCell[prop];
              }
            }
          };

          const updateAllCells = () => {
            r.positionData.rows.forEach((item, index) => {
              if (JSON.stringify(item) !== JSON.stringify(this.dataSource[index])) {
                if (this.dataSource.length <= index) {
                  this.dataSource[index] = { ...item, rowIndex: index + 1 };
                } else {
                  updateCell(item, this.dataSource[index]);
                }
              }
            });
          };

          if (forceRecalculateAll || this.lastRecalculationHadError) {
            updateAllCells();
          } else if (numRow) {
            const itemN = r.positionData.rows[numRow - 1];
            if (itemN?.referenceName || r.positionData.rows[numRow - 1].referenceName) {
              // If the reference was changed, we want to update all cells in case there's a dependency
              // on the reference somewhere that should be refreshed
              updateAllCells();
            } else {
              updateCell(itemN, this.dataSource[numRow - 1]);
            }
          } else {
            updateAllCells();
          }

          this.lastRecalculationHadError = false;

          if (r.positionData.rows.length > this.dataSource.length) {
            const afterToAdd = this.dataSource[this.dataSource.length - 1].rowIndex;
            const newRows = r.positionData.rows.filter((r) => r.rowIndex > afterToAdd);
            this.dataSource.push(...newRows);
          }

          this.dataSource = [...this.dataSource];
          this.table?.renderRows();

          this.calculationInProgress = false;
          if (this.calculationQueue > 0) {
            this.calculationQueue = 0;
            this.recalculate();
          } else {
            this.selectedQuantityTakeOffMessengerService.setSomeChangesQuantityTakeOff();
          }
          let focusId = this.lastFocusedElementId || focusElementId;
          focusId = this.checkFormulaErrorService.checkErrorInFormulaAndSendIdForFocus(
            this.dataSource[numRow - 1],
            this.userSettings,
            focusId
          );
          if (focusId) {
            document.getElementById(focusId).focus();
          }
        },
        (error: ApiErrorOfFormulaError) => {
          this.lastRecalculationHadError = true;
          this.calculationInProgress = false;
          if (error.error?.formulaErrorType === FormulaErrorType.CircularReference) {
            this.notificationsService.error('Zirkelbezug in einer Formel, die Berechnung wird nicht gespeichert.');

            if (error.error.rowIndizesWithErrors) {
              error.error.rowIndizesWithErrors.forEach((ri) => (this.circularErrorRowIndizes[ri] = true));
            }
          } else {
            this.notificationsService.error('Fehler beim Speichern.');
          }
        }
      );
  }

  public changeShowingOfReferenceNameColumn(showReferenceNameColumn: boolean): void {
    this.displayedColumns = this.showingColumnsService.getFilteredColumns('PositionQto', this.defaultDisplayedColumns);
    if (!showReferenceNameColumn) {
      this.displayedColumns = this.displayedColumns.filter((item: string) => item !== 'referenceName');
    }
    if (!this.userSettings.showUnitTagsInQuantityTakeOff) {
      this.displayedColumns = this.displayedColumns.filter((item: string) => item !== 'unit');
    }
    if (this.userSettings.showImageColumnsInQuantityTakeOff === false) {
      this.displayedColumns = this.displayedColumns.filter((item: string) => item !== 'image');
    }
  }

  public inputRowType(event: KeyboardEvent, element: PositionQuantityTakeOffRowModel, nameProp: string): void {
    this.preventKeyDownEvent(event, element, nameProp);
    const pattern = /[iz]/i;
    if (event.key.length === 1 && !pattern.test(event.key)) {
      event.preventDefault();
    }
  }

  startSelectRow(rowIndex: number): void {
    if (!this.isReadOnly) {
      this.listMove = [rowIndex, rowIndex];
      this.selectRowsService.markFirst(rowIndex);
      let currentRowIndex;
      this.mousemove$ = fromEvent(this.el.nativeElement, 'mousemove').subscribe((elEv: MouseEvent) => {
        // Added changes as per this comment https://github.com/Dangl-IT/Dangl.PfeifferAVA/pull/2469#issuecomment-1547509766
        const rowIndex = +(elEv.target as HTMLElement).innerText;
        if (rowIndex !== currentRowIndex) {
          currentRowIndex = rowIndex;
          this.moveSelectRow(elEv, currentRowIndex);
        }
      });
    }
  }

  stopSelectRow(event?: MouseEvent, selectedRowStop = 0): void {
    if (!this.isReadOnly) {
      if (!event?.button) {
        this.listSelectedRows = this.selectRowsService.getNewList(selectedRowStop, event.ctrlKey, event.shiftKey);
        const summa = this.dataSource.reduce(
          (sum: number, item: PositionQuantityTakeOffRowModel) =>
            sum + (typeof item.totalCost === 'number' && this.listSelectedRows.includes(item.rowIndex) ? item.totalCost : 0),
          0
        );
        const quantity = this.dataSource.reduce(
          (sum: number, item: PositionQuantityTakeOffRowModel) =>
            sum + (typeof item.result === 'number' && this.listSelectedRows.includes(item.rowIndex) ? item.result : 0),
          0
        );
        this.selectRowsService.setSelectedRowSumma(
          this.listSelectedRows.length ? summa : null,
          this.listSelectedRows.length ? quantity : null
        );
        event.stopPropagation();
      }
      if (this.mousemove$) {
        this.mousemove$.unsubscribe();
      }
      this.listMove = [];
    }
  }

  moveSelectRow(event: MouseEvent, rowIndex: number): void {
    if (!this.isReadOnly && event.buttons === 1 && !event.button && this.listMove?.length) {
      this.listMove[1] = rowIndex;
    }
  }

  showMoveSelectRow(rowIndex: number): boolean {
    if (this.listMove?.length) {
      const min: number = Math.min(...this.listMove);
      const max: number = Math.max(...this.listMove);
      return rowIndex >= min && rowIndex <= max;
    } else {
      return false;
    }
  }

  private ensureRowSelectionReset(): void {
    this.listSelectedRows = this.selectRowsService.reset();
  }

  selectedRow(rowIndex: number): boolean {
    return this.listSelectedRows.includes(rowIndex);
  }

  saveRows(isClean?: boolean): void {
    if (isClean) {
      this.savedSelectedRows = [];
    } else {
      this.savedSelectedRows = this.dataSource
        .filter((item: PositionQuantityTakeOffRowModel) => this.listSelectedRows.includes(item.rowIndex))
        .map((r) => ({ ...r }));
      this.clipboardService.copyData(this.clipboardWord, this.savedSelectedRows);
    }
  }

  copySavedRows(currentRow: PositionQuantityTakeOffRowModel, isAddNewRows?: boolean): void {
    this.dataSource = this.manageInvoiceTableService.addRow(currentRow, false, isAddNewRows, this.dataSource, this.savedSelectedRows);
    this.recalculate();
  }

  changeInputFromOverrideMode(input: HTMLInputElement, element: PositionQuantityTakeOffRowModel, prop: string): void {
    const value = input.value;
    let hasError = this.calculationData.rows.some((item: PositionQuantityTakeOffRowModel) => !!item.formulaErrorType);
    if (prop === 'referenceName' && !this.checkedDoubleReferenceName(value)) {
      this.showErrorElement(input, 'Referenzname bereits vergeben');
      hasError = true;
    } else if (prop === 'formula' && !this.checkedValidFormula(input.value)) {
      this.showErrorElement(input, 'Falsche Referenz');
      element[prop] = input.value;
      element.result = null;
      element.formulaErrorMessage = 'Falsche Referenz';
      element.formulaErrorType = FormulaErrorType.GenericError;
      hasError = true;
    }

    element[prop] = input.value;
    const forceRecalculate = hasError || prop === 'referenceName' || prop === 'formula';
    this.recalculate(element.rowIndex, null, forceRecalculate);
  }

  changeInput(event, element: PositionQuantityTakeOffRowModel, prop: string): void {
    const value = event.target.value;
    let hasError = this.calculationData.rows.some((item: PositionQuantityTakeOffRowModel) => !!item.formulaErrorType);
    if (prop === 'referenceName' && !this.checkedDoubleReferenceName(value)) {
      this.showErrorElement(event.target, 'Referenzname bereits vergeben');
      hasError = true;
    } else if (prop === 'formula' && !this.checkedValidFormula(event.target.value)) {
      this.showErrorElement(event.target, 'Falsche Referenz');
      element[prop] = event.target.value;
      element.result = null;
      element.formulaErrorMessage = 'Falsche Referenz';
      element.formulaErrorType = FormulaErrorType.GenericError;
      hasError = true;
    }

    element[prop] = event.target.value;
    const forceRecalculate = hasError || prop === 'referenceName' || prop === 'formula';
    this.recalculate(element.rowIndex, null, forceRecalculate);
  }

  checkedDoubleReferenceName(valueReferenceName: string): boolean {
    if (valueReferenceName) {
      return !this.dataSource.some((item) => item.referenceName === valueReferenceName);
    }
    return true;
  }

  showErrorElement(inputElement: HTMLInputElement, textError: string): void {
    const value = inputElement.value;
    this.notificationsService.error(`${textError}: ${value}`);
  }

  checkedValidFormula(formula): boolean {
    let hasFoundInvalidSubstitution = false;
    Calculator.calculate(formula, (substitution) => {
      const regExpr = /^#[\d]+$/g;
      if (regExpr.test(substitution)) {
        const referencedRow = this.calculationData.rows.find((r) => r.rowIndex === +substitution.slice(1));
        if (!referencedRow) {
          hasFoundInvalidSubstitution = true;
        } else {
          return referencedRow.result;
        }
      } else {
        const referencedRow = this.calculationData.rows.find((r) => r.referenceName?.endsWith(substitution.slice(1)));
        if (!referencedRow) {
          hasFoundInvalidSubstitution = true;
        } else {
          return referencedRow.result;
        }
      }
    });

    return !hasFoundInvalidSubstitution;
  }

  trackByFn(index, item): void {
    return item.rowIndex;
  }

  deleteSelectedRowsPlus(isSkipConfirmation: boolean): void {
    if (this.userSettings.promptForConfirmationWhenDeletingRows && !isSkipConfirmation) {
      this.confirmationDeleting(this.listSelectedRows, () => this.deleteSelectedRows());
    } else {
      this.deleteSelectedRows();
    }
  }

  checkWarningForReference(list: number[]): string {
    let result = '';
    list.forEach((item) => {
      const foundRows = this.dataSource.filter((row) => row.formula?.includes(`#${item}`));
      if (foundRows?.length) {
        result += foundRows.reduce((acc, referenceRow) => acc + `(#${referenceRow.rowIndex} -> #${item})`, '');
      }
    });
    return result;
  }

  confirmationDeletingChecked(warning: string, fn: () => void): void {
    this.modalService
      .openModal(ModalConfirmComponent, {
        dialogType: ConfirmationType.Delete,
        data: ['Löschen', 'Zeile wird referenziert', warning, 'red']
      })
      .afterClosed()
      .subscribe((e: boolean) => {
        if (e) {
          fn();
        }
      });
  }

  deleteSelectedRows(): void {
    const warning = this.checkWarningForReference(this.listSelectedRows);
    if (warning) {
      this.confirmationDeletingChecked(warning, () => this.deleteSelectedRowsChecked());
    } else {
      this.deleteSelectedRowsChecked();
    }
  }

  deleteSelectedRowsChecked(): void {
    const maxIndex = Math.max(...this.listSelectedRows);
    const currentRows: PositionQuantityTakeOffRowModel[] = this.dataSource.filter((row) => !this.listSelectedRows.includes(row.rowIndex));
    currentRows.forEach((row: PositionQuantityTakeOffRowModel, index: number) => {
      row.rowIndex = index + 1;
      this.manageInvoiceTableService.changeRelatingNumber(
        row,
        maxIndex - this.listSelectedRows.length,
        -this.listSelectedRows.length,
        this.listSelectedRows
      );
    });
    this.listSelectedRows = [];
    this.selectRowsService.listSelectedRows = [];
    if (currentRows.length === 0) {
      currentRows.push({
        rowIndex: 1
      });
    }
    this.dataSource = currentRows;
    setTimeout(() => {
      this.recalculate();
    }, 1);
  }

  confirmationDeleting(listDel: number | number[], fn: () => void): void {
    let listDelString: string;
    if (Array.isArray(listDel)) {
      listDelString = `Zeilen: ${listDel.length < 10 ? listDel.join(', ') : 'Viele (' + listDel.length + ')'}`;
    } else {
      listDelString = `Zeile ${listDel}`;
    }
    this.modalService
      .openModal(ModalConfirmComponent, { dialogType: ConfirmationType.Delete, data: ['Löschen', 'Zeilen', listDelString, 'red'] })
      .afterClosed()
      .subscribe((e: boolean) => {
        if (e) {
          fn();
        }
      });
  }

  clearSelectionRow(event: MouseEvent, row: PositionQuantityTakeOffRowModel): void {
    if (!event?.button && this.listSelectedRows.length) {
      const tag = event?.target as HTMLInputElement;
      if (this.isSelectingFormulaRowMode) {
        if (!this.listSelectedRows.includes(row.rowIndex)) {
          this.changeFormula(row);
          this.isSelectingFormulaRowMode = false;
        }
      } else if (!tag || (tag['tagName'] === 'INPUT' && !tag.disabled)) {
        this.listSelectedRows = this.selectRowsService.init();
      }
    }
  }

  showHeaderContextMenu(event: MouseEvent): void {
    this.contextMenuSettingsService.setDefaultSettings(event, null, this.contextMenuPosition, this.contextMenu.last);
  }

  editListColumns(): void {
    this.modalService.openModal(ShowingColumnsModalComponent, {
      dialogType: ConfirmationType.General,
      data: {
        component: 'PositionQto',
        columns: this.defaultDisplayedColumns
      },
      disableClose: true,
      autoFocus: false
    });
  }

  showImage(element: PositionQuantityTakeOffRowModel): void {
    this.modalService
      .openModal(InvoiceImageModalComponent, {
        dialogType: ConfirmationType.General,
        data: {
          element: element,
          isReadOnly: this.isReadOnly
        },
        panelClass: 'window-padding'
      })
      .afterClosed()
      .subscribe((isDelete: boolean) => {
        if (isDelete) {
          this.deleteImage(element);
        }
      });
  }

  setTotalSumsHeight(totalSumHeight: number): void {
    this.totalSumHeight = totalSumHeight;
  }

  changeFormula(row: PositionQuantityTakeOffRowModel): void {
    const listReferences = this.listSelectedRows.map((item) => `#${item}`).join(' + ');
    row.formula = listReferences;
    this.recalculate(row.rowIndex);
  }

  cutSelectedRowsPlus(): void {
    this.saveRows();
    // Since this is just a cut operation, we want to ignore the settings
    // that ask for confirmation before deletion and just delete the rows
    this.deleteSelectedRows();
  }

  limitWidth(isSame?: boolean): void {
    this.limitColumnWidthService.limitWidth('positions', isSame);
  }

  openInvoicePdfFilesModal(): void {
    this.modalService
      .openModal(InvoicePdfFileComponent, {
        dialogType: ConfirmationType.General,
        restoreFocus: false,
        autoFocus: false,
        minWidth: '1040px',
        panelClass: 'invoice-pdf-file'
      })
      .afterClosed()
      .subscribe((attachmentIds: { quantityTakeOffAttachmentId?: string | null; quantityTakeOffAttachmentImageFileId?: string | null }) => {
        if (attachmentIds) {
          this.dataSource[this.activeElement?.rowIndex - 1].quantityTakeOffAttachmentId = attachmentIds.quantityTakeOffAttachmentId;
          this.dataSource[this.activeElement?.rowIndex - 1].quantityTakeOffAttachmentImageFileId =
            attachmentIds.quantityTakeOffAttachmentImageFileId;
          this.recalculate();
        }
      });
  }

  back(): void {
    this.tryGoBack = true;
    setTimeout(() => {
      if (this.tryGoBack) {
        this.router.navigate(['../..'], {
          relativeTo: this.route
        });
      }
    }, 200);
  }

  showQuantity(): void {
    this.router.navigate(['quantity'], {
      relativeTo: this.route
    });
  }

  backCalculation(): void {
    if (getAppConfig().isQtoOnlyMode) {
      return;
    }
    this.tryGoBack = false;
    this.router.navigate(['../../..', 'calculation'], {
      relativeTo: this.route
    });
  }

  saveQTOData(): void {
    const data = {
      performancePeriodStartUtc: dateUTC(this.performanceQtoDate.value.performancePeriodStartUtc),
      performancePeriodEndUtc: dateUTC(this.performanceQtoDate.value.performancePeriodEndUtc)
    };

    this.selectedQuantityTakeOffMessengerService.changeQuantityTakeOffDate(this.projectId, this.avaProjectId, this.quantityTakeOffId, data);
  }

  copyCurrentLine(row: PositionQuantityTakeOffRowModel): void {
    this.savedSelectedRows = [{ ...row }];
    this.clipboardService.copyData(this.clipboardWord, this.savedSelectedRows);
  }

  dropHeader(event: CdkDragDrop<string[]>): void {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
      this.showingColumnsService.saveDefaultDisplayedColumns(
        this.nameStorageOrderColumn,
        this._defaultDisplayedColumns,
        this.displayedColumns
      );
    }
  }
}
