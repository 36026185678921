import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';

import { Subject } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';

import { ProjectDto, ProjectGet } from 'app/generated-client/generated-client';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { CopyCalculationViewMessengerService } from 'app/shared/services/electron/copy-calculation-view-messenger.service';
import { ElementViewMessengerService } from 'app/shared/services/electron/element-view-messenger.service';
import { LongTextViewMessengerService } from 'app/shared/services/electron/long-text-view-messenger.service';
import { SheetsViewMessengerService } from 'app/shared/services/electron/sheets-view-messenger.service';
import { TreeViewMessengerService } from 'app/shared/services/electron/tree-view-messenger.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ProjectLocationService } from 'app/shared/services/project-location.service';
import { getStorage, setStorage } from 'app/shared/utilities/storage';
import { isInElementView } from 'app/shared/utilities/view-detection';

import { AppConfig } from '../../../../environments/environment';
import { ModalService } from '../../services/modal.service';

import { FindProjectComponent } from '../find-project/find-project.component';

type ListOpenedWindows = {
  treeViewVisible: boolean;
  elementViewVisible: boolean;
  longTextViewVisible: boolean;
  sheetsViewVisible: boolean;
};

@Component({
  selector: 'pa-category-inform',
  templateUrl: './category-inform.component.html',
  styleUrls: ['./category-inform.component.scss'],
  standalone: true,
  imports: [NgIf]
})
export class CategoryInformComponent implements OnInit, OnDestroy {
  category: string;
  listOfIgnoreUrlToSave = ['master-data', 'management'];
  selectedProject: ProjectGet;
  serviceSpecification: ProjectDto;
  subCategory: string;
  treeViewVisible: boolean;
  elementViewVisible: boolean;
  longTextViewVisible: boolean;
  sheetsViewVisible: boolean;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private treeViewMessengerService: TreeViewMessengerService,
    private elementViewMessengerService: ElementViewMessengerService,
    private longTextViewMessengerService: LongTextViewMessengerService,
    private sheetsViewMessengerService: SheetsViewMessengerService,
    private projectLocationService: ProjectLocationService,
    private modalService: ModalService,
    private copyCalculationViewMessengerService: CopyCalculationViewMessengerService
  ) {}

  ngOnInit(): void {
    this.serviceSpecification = null;

    this.selectedProjectMessengerService.selectedProject
      .pipe(takeUntil(this.$destroy))
      .subscribe((project: ProjectGet) => (this.selectedProject = project ? project : null));

    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(takeUntil(this.$destroy))
      .subscribe((ss: { avaProjectId: string; project: ProjectDto }) => (this.serviceSpecification = ss?.project));

    this.copyCalculationViewMessengerService.copyCalculationViewVisible.pipe(takeUntil(this.$destroy)).subscribe((isOpen: boolean) => {
      if (isOpen) {
        this.copyCalculationViewMessengerService.sendSelectedProjectToViewWindow(this.selectedProject);
        this.copyCalculationViewMessengerService.sendSelectedAvaProjectToViewWindow(this.serviceSpecification?.id);
      }
    });
    //Here add time out because we have some case when url is equal to '/'
    setTimeout(() => {
      this.getRoutesUrl();
    }, 0);

    if (!AppConfig.isQtoOnlyMode) {
      this.restoreLocation();
    }

    this.controlDetachedWindows();
    setTimeout(() => {
      this.restoreListWindows();
    }, 4000);
  }

  private getRoutesUrl(): void {
    this.router.events
      .pipe(
        startWith(new RoutesRecognized(0, this.router.url, this.router.url, null)),
        filter((e) => e instanceof RoutesRecognized),
        takeUntil(this.$destroy)
      )
      .subscribe((resp: RoutesRecognized) => {
        this.setCategoryName(resp);
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private setCategoryName(resp: RoutesRecognized): void {
    const currUrl: string = resp.urlAfterRedirects;
    this.saveUrl(currUrl);

    this.category = null;
    this.subCategory = null;
    if (isInElementView()) {
      this.category = 'Element Detailansicht';
    } else if (currUrl.includes('/projects')) {
      this.category = 'Projekte';
      this.subCategory = null;
      if (currUrl.includes('/lv-editor')) {
        this.subCategory = 'LV-Editor';
      } else if (currUrl.includes('/scenario')) {
        this.subCategory = 'Zuschläge';
      } else if (currUrl.includes('calculation')) {
        this.subCategory = 'Kalkulation';
      } else if (currUrl.includes('/invoice')) {
        this.subCategory = 'Abrechnung';
      } else if (currUrl.includes('/estimations')) {
        this.subCategory = 'Mengenermittlung';
      } else if (currUrl.includes('/dashboard')) {
        this.subCategory = 'Auswertung';
      } else if (currUrl.includes('/project-files')) {
        this.subCategory = 'Dateien';
      } else if (currUrl.includes('/project-management')) {
        this.subCategory = 'Projektverwaltung';
      } else if (currUrl.includes('/settings')) {
        this.subCategory = 'Einstellungen';
      } else if (currUrl.includes('/selection')) {
        this.subCategory = 'Sub LVs';
      } else if (currUrl.includes('/inquiries')) {
        this.subCategory = 'Preisanfragen';
      } else if (currUrl.includes('/quick-edit')) {
        this.subCategory = 'LV-Tabelle';
      }
    } else if (currUrl.includes('/contacts')) {
      this.category = 'Kontakte';
    } else if (currUrl.includes('/master-data')) {
      this.category = 'Stammdaten';
    } else if (currUrl.includes('/management')) {
      this.category = 'Verwaltung';
    } else if (currUrl.includes('/login')) {
      this.category = 'Login';
      this.selectedProject = null;
    }
  }

  saveUrl(currUrl: string): void {
    if (this.listOfIgnoreUrlToSave.some((v) => currUrl.includes(v))) {
      return;
    }
    setStorage<string>('APP_LOCATION_URL', currUrl);
    this.projectLocationService.startChangedIndicator(currUrl);
  }

  restoreLocation(): void {
    const savedUrl: string = getStorage<string>('APP_LOCATION_URL', '') as string;
    this.router.navigateByUrl(savedUrl);
    setTimeout(() => {
      this.projectLocationService.startAfterFirst();
    }, 1000);
  }

  controlDetachedWindows(): void {
    this.treeViewMessengerService.treeViewVisible.pipe(takeUntil(this.$destroy)).subscribe((treeViewVisible) => {
      this.treeViewVisible = treeViewVisible;
      this.saveListWindows();
    });
    this.elementViewMessengerService.elementViewVisible.pipe(takeUntil(this.$destroy)).subscribe((elementViewVisible) => {
      this.elementViewVisible = elementViewVisible;
      this.saveListWindows();
    });
    this.longTextViewMessengerService.longTextViewVisible.pipe(takeUntil(this.$destroy)).subscribe((longTextViewVisible) => {
      this.longTextViewVisible = longTextViewVisible;
      this.saveListWindows();
    });
    this.sheetsViewMessengerService.sheetsViewVisible.pipe(takeUntil(this.$destroy)).subscribe((sheetsViewVisible) => {
      this.sheetsViewVisible = sheetsViewVisible;
      this.saveListWindows();
    });
  }

  getListWindows(): ListOpenedWindows {
    return {
      treeViewVisible: !!this.treeViewVisible,
      elementViewVisible: !!this.elementViewVisible,
      longTextViewVisible: !!this.longTextViewVisible,
      sheetsViewVisible: !!this.sheetsViewVisible
    };
  }

  saveListWindows(): void {
    setStorage<ListOpenedWindows>('APP_OPENED_WINDOWS', this.getListWindows());
  }

  restoreListWindows(): void {
    const savedListWindows: ListOpenedWindows = getStorage<ListOpenedWindows>(
      'APP_OPENED_WINDOWS',
      this.getListWindows()
    ) as ListOpenedWindows;
    if (savedListWindows.treeViewVisible) {
      this.treeViewMessengerService.showTreeViewWindow();
    }
    if (savedListWindows.elementViewVisible) {
      this.elementViewMessengerService.showElementViewWindow();
    }
    if (savedListWindows.longTextViewVisible) {
      this.longTextViewMessengerService.showLongTextViewWindow();
    }
    if (savedListWindows.sheetsViewVisible) {
      this.sheetsViewMessengerService.showSheetsViewWindow();
    }
  }

  findProject(): void {
    if (this.category === 'Projekte') {
      this.modalService.openModal(FindProjectComponent, { dialogType: ConfirmationType.General });
    }
  }
}
