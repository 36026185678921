import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/** This component wraps the table and adds scrolling to it.
 *  To do this correctly, the table must have a sticky header
 * and we must set the required offset.
 * To use this component, it must be imported either into the standalone component imports
 * or into the module where the component with the table is registered */

@Component({
  selector: 'pa-table-wrapper',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './table-wrapper.component.html',
  styleUrls: ['./table-wrapper.component.scss']
})
export class TableWrapperComponent {
  @Input() offsetFromMaxHeightTable: number;
  @Input() measure: string = 'vh';
  constructor(public sanitizer: DomSanitizer) {}
}
