<div class="wrapper">
  <p class="current">
    Aktueller Status: <span color="primary">{{ currentStatus | projectStatus }}</span>
  </p>
  <div fxLayoutGap="12px">
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Neuer Status</mat-label>
        <mat-select name="status" [(value)]="newStatus">
          <mat-option *ngFor="let item of listStatus" [value]="item" (click)="moveFocus()">{{ item | projectStatus }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-checkbox color="primary" [(ngModel)]="setPricesToLocked"> Kalkulationen festschreiben </mat-checkbox>
    <div>
      <mat-form-field fxFlex="1 0" class="comment">
        <mat-label>Kommentar</mat-label>
        <input matInput [(ngModel)]="comment" [disabled]="!newStatus" #inputComment />
      </mat-form-field>
    </div>
  </div>
  <div class="buttons">
    <button mat-raised-button color="primary" (click)="saveStatus()" [disabled]="!newStatus">Neuen Status speichern</button>
  </div>

  <h3>Statusverlauf</h3>
  <div class="mat-elevation-z8 table-data" *ngIf="dataSource?.length; else historyNone">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="createdAtUtc">
        <th mat-header-cell *matHeaderCellDef>Datum</th>
        <td mat-cell *matCellDef="let row">
          {{ row.createdAtUtc | date: 'dd.MM.yyyy HH:mm' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="oldStatus">
        <th mat-header-cell *matHeaderCellDef>Alt</th>
        <td mat-cell *matCellDef="let row">
          {{ row.oldStatus | projectStatus }}
        </td>
      </ng-container>
      <ng-container matColumnDef="newStatus">
        <th mat-header-cell *matHeaderCellDef>Neu</th>
        <td mat-cell *matCellDef="let row">
          {{ row.newStatus | projectStatus }}
        </td>
      </ng-container>
      <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef>Benutzer</th>
        <td mat-cell *matCellDef="let row">
          {{ row.userName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="comment">
        <th mat-header-cell *matHeaderCellDef>Kommentar</th>
        <td mat-cell *matCellDef="let row" class="table-comment">
          {{ row.comment }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns"></tr>
    </table>
  </div>
  <ng-template #historyNone> Für dieses Projekt gibt es noch keine Statushistorie. </ng-template>
</div>
