import { Injectable } from '@angular/core';

import { CalculationEntry } from '../../../../../generated-client/generated-client';

@Injectable({
  providedIn: 'root'
})
export class SubpositionIdentifierService {
  constructor() {}

  validateSubpositionIdentifier(currentCalcData: CalculationEntry[], checkRows: CalculationEntry[]): CalculationEntry[] {
    if (!currentCalcData || !Array.isArray(currentCalcData) || !checkRows || !Array.isArray(checkRows)) {
      return [];
    }
    let maxSubPositionIdentifier = this.getMaxSubPositionIdentifier(currentCalcData);
    checkRows.forEach((r) => {
      if (r.subPositionIdentifier && !r.subPositionIdentifier.includes('.')) {
        ++maxSubPositionIdentifier;
        r.subPositionIdentifier = `${maxSubPositionIdentifier}`;
      } else if (r.subPositionIdentifier && r.subPositionIdentifier.includes('.')) {
        const newIdentifier = r.subPositionIdentifier
          .split('.')
          .map((part, i) => (i === 0 ? maxSubPositionIdentifier : part))
          .join('.');
        r.subPositionIdentifier = newIdentifier;
      }
    });
    return checkRows;
  }

  getMaxSubPositionIdentifier(currentCalcData: CalculationEntry[]): number {
    const maxIdentifier = JSON.parse(JSON.stringify(currentCalcData)).reduce((prev, current) => {
      if (!prev || !prev.subPositionIdentifier) {
        return current;
      }
      if (!current || !current.subPositionIdentifier) {
        return prev;
      }
      const prevNumber = +prev.subPositionIdentifier.split('.')[0];
      const currentNumber = +current.subPositionIdentifier.split('.')[0];
      if (prevNumber !== currentNumber) {
        return prevNumber > currentNumber ? prev : current;
      } else {
        const prevSubNumber = prev.subPositionIdentifier.slice(prev.subPositionIdentifier.indexOf('.') + 1);
        const currentSubNumber = current.subPositionIdentifier.slice(current.subPositionIdentifier.indexOf('.') + 1);
        return prevSubNumber > currentSubNumber ? prev : current;
      }
    })?.subPositionIdentifier;

    return maxIdentifier?.includes('.') ? +maxIdentifier.split('.')[0] : +maxIdentifier;
  }

  updateNestingForSubPositionIdentifier(currentCalcData: CalculationEntry[], row: CalculationEntry): CalculationEntry[] {
    let maxIdentifier = ['1'];
    if (currentCalcData.slice(0, row.rowIndex - 1).length) {
      for (let i = row.rowIndex - 1; i >= 0; i--) {
        if (currentCalcData[i].subPositionIdentifier) {
          const arr = currentCalcData[i].subPositionIdentifier.split('.');
          arr[arr.length - 1] = `${+arr[arr.length - 1] + 1}`;
          maxIdentifier = arr;
          break;
        }
      }
    }

    for (let i = row.rowIndex - 1; i < currentCalcData.length; i++) {
      if (i === row.rowIndex - 1) {
        currentCalcData[i].subPositionIdentifier = maxIdentifier.join('.');
      } else if (currentCalcData[i].subPositionIdentifier) {
        const newIdentifier = currentCalcData[i].subPositionIdentifier.split('.');
        if (currentCalcData[i].subPositionIdentifier.length >= maxIdentifier.join('.').length) {
          newIdentifier.splice(0, maxIdentifier.length - 1, maxIdentifier.join('.'));
          currentCalcData[i].subPositionIdentifier = newIdentifier.join('.');
        }
      }
    }
    return currentCalcData;
  }
}
