<div class="wrapper">
  <h1>Projektdateien</h1>
  <div class="sub-wrapper">
    <h2>
      <span *ngIf="selectedProject">{{ selectedProject }}</span>
    </h2>
    <div class="select" *ngIf="isShowSortingFilter">
      <span style="display: inline-block; margin-right: 15px">Sortierung</span>
      <mat-form-field appearance="fill">
        <mat-label>Reihenfolge</mat-label>
        <mat-select [(ngModel)]="sorting" (ngModelChange)="setSelectedGroup()">
          <mat-option *ngFor="let sort of sortBy" [value]="sort.value">{{ sort.displayValue }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
