import { NgFor } from '@angular/common';
import { Component, Inject, OnInit, HostListener, QueryList, ViewChildren, ViewChild, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose
} from '@angular/material/dialog';

import { ShowingColumnsService } from 'app/shared/services/showing-columns.service';

import { AutoFocusDirective } from '../../directives/auto-focus.directive';
import { ColumnNamesPipe } from '../../pipes/ui-data-display/column-names.pipe';

@Component({
  selector: 'pa-showing-columns-modal',
  templateUrl: './showing-columns-modal.component.html',
  styleUrls: ['./showing-columns-modal.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    NgFor,
    MatCheckbox,
    FormsModule,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    AutoFocusDirective,
    ColumnNamesPipe
  ]
})
export class ShowingColumnsModalComponent implements OnInit {
  @HostListener('keydown', ['$event'])
  onKeydown(event) {
    const target = event['target'];
    switch (event.key) {
      case 'Escape':
        this.matDialogRef.close();
        break;
      case 'ArrowRight':
        target.nextSibling ? target.nextSibling.focus() : target.previousElementSibling.focus();
        break;
      case 'ArrowLeft':
        target.previousElementSibling ? target.previousElementSibling.focus() : target.nextSibling.focus();
        break;
      case 'ArrowUp':
        this.changeFocus(1);
        break;
      case 'ArrowDown':
        this.changeFocus(-1);
        break;
    }
  }

  @ViewChildren('checkboxRef') checkBox: QueryList<any>;
  @ViewChild('btnRef') buttonRef;

  listColumnsObj: { [key: string]: { show: boolean; name: string } };
  listColumns: string[];
  currentFocusedElementIndex: number;

  constructor(
    private showingColumnsService: ShowingColumnsService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      component: string;
      columns: string[];
    },
    @Optional() private matDialogRef: MatDialogRef<ShowingColumnsModalComponent>
  ) {}

  ngOnInit(): void {
    const currentColumns = this.showingColumnsService.getFilteredColumnsObject(this.data.component, this.data.columns);
    this.listColumnsObj = {};
    this.listColumns = [];
    currentColumns.forEach((cc) => {
      this.listColumns.push(cc.columnName);
      this.listColumnsObj[cc.columnName] = {
        show: !cc.isHidden,
        name: cc.columnName
      };
    });
  }

  save(): void {
    const currentColumns = Object.keys(this.listColumnsObj).map((columnName) => {
      return {
        columnName: columnName,
        isHidden: !this.listColumnsObj[columnName].show,
        component: this.data.component
      };
    });

    this.showingColumnsService.saveChanges(this.data.component, currentColumns);
    this.matDialogRef.close();
  }

  changeFocus(offsetIndex: number): void {
    if (!this.currentFocusedElementIndex && offsetIndex === 1 && this.currentFocusedElementIndex !== 0) {
      this.currentFocusedElementIndex = this.checkBox.toArray().length;
    }

    if (!this.currentFocusedElementIndex && offsetIndex === -1 && this.currentFocusedElementIndex !== 0) {
      this.currentFocusedElementIndex = -1;
    }

    if (offsetIndex === 1) {
      this.currentFocusedElementIndex--;
      if (this.currentFocusedElementIndex >= 0) {
        this.checkBox.toArray()[this.currentFocusedElementIndex].focus();
      }
    }

    if (offsetIndex === -1) {
      this.currentFocusedElementIndex++;
      if (this.currentFocusedElementIndex <= this.checkBox.toArray().length - 1) {
        this.checkBox.toArray()[this.currentFocusedElementIndex].focus();
      }
    }

    if (this.currentFocusedElementIndex <= -1 || this.currentFocusedElementIndex >= this.checkBox.toArray().length) {
      this.currentFocusedElementIndex = null;
      this.buttonRef.focus();
    }
  }
}
