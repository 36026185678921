import { AsyncPipe, CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { Subject } from 'rxjs';

import { TableWrapperComponent } from '@shared/components/table-wrapper/table-wrapper.component';
import { UpperPaginatorComponent } from '@shared/components/upper-paginator/upper-paginator.component';

import { MasterDataProductGet, PriceComponentType } from 'app/generated-client/generated-client';
import { MasterDataProductsService } from 'app/shared/services/lightquery/master-data-products.service';

@Component({
  selector: 'pa-master-data-products-table',
  templateUrl: './master-data-products-table.component.html',
  styleUrls: ['./master-data-products-table.component.scss'],
  standalone: true,
  imports: [
    UpperPaginatorComponent,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    CurrencyPipe,
    AsyncPipe,
    TableWrapperComponent
  ]
})
export class MasterDataProductsTableComponent implements OnInit, OnDestroy {
  @Input() noSeparate: boolean;
  @Input() showArticleNumbers = false;
  @ViewChild(MatSort, { static: true }) private sort: MatSort;
  @Output() selectItem = new EventEmitter<MasterDataProductGet>();
  @Output() dblSelectItem = new EventEmitter<MasterDataProductGet>();
  @Input() showedId: string;
  @Input() set priceComponentType(value: PriceComponentType) {
    this.masterDataProductsService.setPriceComponentType(value);
  }
  @Input() filter: string;

  displayedColumns: string[];
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(public masterDataProductsService: MasterDataProductsService) {}

  ngOnInit(): void {
    this.displayedColumns = this.showArticleNumbers
      ? ['name', 'articleNumber', 'description', 'price', 'unit']
      : ['name', 'description', 'price', 'unit'];

    this.setDataSource();
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private setDataSource(): void {
    this.masterDataProductsService.initService({
      filtering: this.filter,
      paging: { page: 1, pageSize: 10 },
      sorting: { propertyName: 'name', isDescending: false },
      sortTableObj: this.sort
    });
    this.masterDataProductsService.forceRefresh();
  }

  public selectElement(element: MasterDataProductGet): void {
    this.selectItem.emit(element);
  }

  public dblSelectElement(element: MasterDataProductGet): void {
    this.dblSelectItem.emit(element);
  }
}
