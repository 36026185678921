import { Injectable } from '@angular/core';
import { ReplaySubject } from "rxjs";

import { PositionDto } from "../../../../../generated-client/generated-client";

@Injectable({
  providedIn: 'root'
})
export class DbclickSubscriptionService {
  private dbclickSource = new ReplaySubject<PositionDto>(1);
  dbclick = this.dbclickSource.asObservable();

  setDbclick(dbclick: PositionDto): void {
      this.dbclickSource.next(dbclick);
  }
}
