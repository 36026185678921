<mat-form-field appearance="fill" class="percenteg-input">
  <mat-label>{{ label }}</mat-label>
  <input
    #fieldInput
    name="{{ fieldName }}"
    required
    matInput
    [value]="formattedValue"
    (blur)="updateValue(fieldInput.value)"
    type="text"
    [disabled]="!isEditing"
    allowNumericWithDecimal
  />
  <span matSuffix>%</span>
</mat-form-field>
