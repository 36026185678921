import { NgIf, NgFor } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSelect } from '@angular/material/select';

import { combineLatestWith, filter, first } from 'rxjs/operators';

import { CalculationCheckModalWindowComponent } from '@serv-spec/components/calculation-check-modal-window/calculation-check-modal-window.component';
import { CheckErrorsAndShowNotificationService } from '@serv-spec/services/check-errors-and-show-notification.service';

import {
  AvaFileExportPhase,
  AvaFileTargetType,
  AvaProjectAvaFileExportsClient,
  CalculationsChecksClient,
  ElementSelection,
  FileResponse,
  OriginDto
} from 'app/generated-client/generated-client';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { FileSaverService } from 'app/shared/services/file-saver.service';
import { AvaProjectAvaFileExportsService } from 'app/shared/services/lightquery/ava-project-ava-file-exports.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';

import { AvaFileExportPhasePipe } from '../../../../../../shared/pipes/ui-data-display/ava-file-export-phase.pipe';
import { AvaFileTargetTypePipe } from '../../../../../../shared/pipes/ui-data-display/ava-file-target-type.pipe';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

import { SelectingElementButtonsComponent } from '../selecting-element-buttons/selecting-element-buttons.component';

@Component({
  selector: 'pa-calculation-ava-export',
  templateUrl: './calculation-ava-export.component.html',
  styleUrls: ['./calculation-ava-export.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    FlexLayoutDirective,
    MatFormField,
    MatLabel,
    MatInput,
    MatSelect,
    NgFor,
    MatOption,
    SelectingElementButtonsComponent,
    MatCheckbox,
    MatButton,
    MatProgressSpinner,
    AvaFileTargetTypePipe,
    AvaFileExportPhasePipe
  ]
})
export class CalculationAvaExportComponent implements OnInit {
  @Output() exportFinished = new EventEmitter<boolean>();
  exportFormGroup: FormGroup;
  targetTypes: AvaFileTargetType[];
  exportPhases: AvaFileExportPhase[];
  requestEnRoute = false;
  elementSelection: ElementSelection = null;

  private projectId: string;
  private serviceSpecificationId: string;

  constructor(
    private avaProjectAvaFileExportsClient: AvaProjectAvaFileExportsClient,
    private notificationsService: AvaNotificationsService,
    private avaProjectAvaFileExportsService: AvaProjectAvaFileExportsService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private fileSaverService: FileSaverService,
    private modalService: ModalService,
    private calculationChecksClient: CalculationsChecksClient,
    private checkErrorsAndShowNotificationService: CheckErrorsAndShowNotificationService
  ) {}

  ngOnInit(): void {
    this.targetTypes = <AvaFileTargetType[]>Object.keys(AvaFileTargetType).filter((f) => f !== AvaFileTargetType.Excel);
    this.exportPhases = <AvaFileExportPhase[]>Object.keys(AvaFileExportPhase);

    this.exportFormGroup = new FormGroup({
      exportName: new FormControl('', (c: AbstractControl) => Validators.required(c)),
      targetType: new FormControl('', (c: AbstractControl) => Validators.required(c)),
      exportPhase: new FormControl('', (c: AbstractControl) => Validators.required(c)),
      persistFile: new FormControl(''),
      alwaysIncludeDescriptionsInGaeb: new FormControl('')
    });

    this.selectedProjectMessengerService.selectedProject.pipe(first()).subscribe((p) => (this.projectId = p.id));
    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        filter((r) => !!r),
        first()
      )
      .subscribe((s) => {
        this.serviceSpecificationId = s.avaProjectId;
        let targetType = AvaFileTargetType.GaebXmlV32;
        switch (s.project.serviceSpecifications[0].origin) {
          case OriginDto.Gaeb90:
            targetType = AvaFileTargetType.Gaeb90;
            break;
          case OriginDto.Gaeb2000:
            targetType = AvaFileTargetType.Gaeb2000;
            break;
          case OriginDto.Oenorm:
            targetType = AvaFileTargetType.OenormLv2015;
            break;
        }

        this.exportFormGroup.patchValue({
          exportName: 'Angebot',
          targetType: targetType,
          exportPhase: AvaFileExportPhase.Offer,
          persistFile: true
        });
      });
  }

  createExport(): void {
    this.requestEnRoute = true;

    this.calculationChecksClient
      .getCalculationCheckResult(this.projectId, this.serviceSpecificationId)
      .pipe(
        combineLatestWith(
          this.avaProjectAvaFileExportsClient.createAvaFileExport(this.projectId, this.serviceSpecificationId, {
            elementSelection: this.elementSelection,
            ...this.exportFormGroup.value
          })
        )
      )
      .subscribe({
        next: ([checkResult, file]) => {
          this.requestEnRoute = false;

          this.avaProjectAvaFileExportsService.forceRefresh();
          const hasErrorsInCalculation = this.checkErrorsAndShowNotificationService.checkForErrorsAndShowErrorNotifications(checkResult);
          if (hasErrorsInCalculation) {
            this.modalService
              .openModal(CalculationCheckModalWindowComponent, { dialogType: ConfirmationType.General, disableClose: true })
              .afterClosed()
              .subscribe(() => {
                this.saveFile(file);
              });
          } else {
            this.saveFile(file);
          }
        },
        error: () => {
          this.requestEnRoute = false;
          this.notificationsService.error('Fehler beim LV Export.');
        }
      });
  }

  saveFile(file: FileResponse): void {
    this.notificationsService.success('Das LV wurde exportiert.');
    this.fileSaverService.saveAs(file);
    this.exportFinished.next(true);
  }
}
