import { BimFileQuantityAssignmentModel } from '../../../generated-client/generated-client';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BimModelAssignedQuantitiesMessengerService {
  private $bimFileQuantityAssignment = new Subject<BimFileQuantityAssignmentModel>();
  bimFileQuantityAssignment = this.$bimFileQuantityAssignment.asObservable();

  setBimFileQuantityAssignment(assignedQuantities: BimFileQuantityAssignmentModel): void {
    this.$bimFileQuantityAssignment.next(assignedQuantities);
  }
}
