import { NgIf, NgFor, PercentPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatLabel, MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSelect } from '@angular/material/select';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

import { Observable, Subject, combineLatest, of } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';

import { SelectProjectAvaProjectComponent } from 'app/areas/projects/components/select-project-ava-project/select-project-ava-project.component';
import {
  AvaFileInspectionResultGet,
  AvaFilesClient,
  AvaProjectGet,
  AvaProjectPost,
  AvaProjectType,
  AvaProjectsClient,
  ItemNumberSchemaDto,
  ItemNumberSchemasClient,
  MasterAdditionGroupGet,
  MasterAdditionGroupsClient,
  ProjectGet,
  TaxRateGet
} from 'app/generated-client/generated-client';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { AvaProjectsService } from 'app/shared/services/lightquery/ava-projects.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';

import { AvaProjectTypePipe } from '../../../../../../shared/pipes/ui-data-display/ava-project-type.pipe';
import { OriginDtoPipe } from '../../../../../../shared/pipes/ui-data-display/origin-dto.pipe';
import { TaxRatesService } from '../../../../../../shared/services/lightquery/tax-rates.service';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

import { SettingSchemaComponent } from '../settings/components/setting-schema/setting-schema.component';

@Component({
  selector: 'pa-add-service-specification',
  templateUrl: './add-service-specification.component.html',
  styleUrls: ['./add-service-specification.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FlexLayoutDirective,
    MatLabel,
    MatButton,
    MatCheckbox,
    FormsModule,
    MatProgressSpinner,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatSelect,
    NgFor,
    MatOption,
    RouterLink,
    PercentPipe,
    OriginDtoPipe,
    AvaProjectTypePipe
  ]
})
export class AddServiceSpecificationComponent implements OnInit, OnDestroy {
  avaFileInspectionResult: AvaFileInspectionResultGet;
  fileCouldNotBeRead = false;
  masterAdditionGroups: MasterAdditionGroupGet[];
  performingAvaInspection = false;
  requestEnRoute = false;
  isCopyAdditionGroup: boolean;
  copyAdditionObj: any;
  standardGroupId: string;
  selectedFile: File;
  servSpecFormGroup: FormGroup;
  includePricesFromAvaFile = true;
  importPricesAsFixedPrices = true;
  avaProjectTypes = Object.keys(AvaProjectType).map((ps) => <AvaProjectType>ps);
  listSchemas: { id?: string; name?: string; example?: string; schema?: ItemNumberSchemaDto }[] = [];
  private projectId: string;
  private $destroy: Subject<boolean> = new Subject<boolean>();
  allTaxRates: TaxRateGet[];
  enableSupportForSkippedTiersInItemNumberSchema = true;

  constructor(
    private avaFilesClient: AvaFilesClient,
    private avaProjectsClient: AvaProjectsClient,
    private masterAdditionGroupsClient: MasterAdditionGroupsClient,
    private notificationsService: AvaNotificationsService,
    private route: ActivatedRoute,
    private router: Router,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private modalService: ModalService,
    private itemNumberSchemasClient: ItemNumberSchemasClient,
    private avaProjectsService: AvaProjectsService,
    private taxRatesService: TaxRatesService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.servSpecFormGroup = new FormGroup({
      name: new FormControl('', (c: AbstractControl) => Validators.required(c)),
      masterAdditionGroupId: new FormControl(''),
      additionsSourceAvaProjectId: new FormControl(''),
      type: new FormControl(AvaProjectType.Normal, (c: AbstractControl) => Validators.required(c)),
      selectedSchema: new FormControl(null),
      selectedTaxRate: new FormControl(null),
      taxRate: new FormControl(0, () => Validators.min(0))
    });

    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((p: ProjectGet) => {
      this.projectId = p?.id;
      this.getAvaProjectList();

      this.servSpecFormGroup.patchValue({
        name: p?.name
      });
    });

    this.masterAdditionGroupsClient.getAllMasterAdditionGroups().subscribe((g: MasterAdditionGroupGet[]) => {
      this.masterAdditionGroups = g;
      this.standardGroupId = g.find((gg: MasterAdditionGroupGet) => gg.isDefault)?.id;
      if (this.standardGroupId) {
        this.servSpecFormGroup.patchValue({
          masterAdditionGroupId: this.standardGroupId
        });
      }
    });

    this.taxRatesService.getAll().subscribe((allRates) => {
      this.allTaxRates = allRates;
      const germanRate = allRates.find((r) => r.countryCode.toLowerCase() === 'de');
      if (germanRate) {
        this.servSpecFormGroup.patchValue({
          selectedTaxRate: germanRate,
          taxRate: Math.round(germanRate.rate * 10000) / 100
        });
      } else if (allRates.length > 0) {
        this.servSpecFormGroup.patchValue({
          selectedTaxRate: allRates[0],
          taxRate: Math.round(allRates[0].rate * 10000) / 100
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  selectTaxRate(taxRate: TaxRateGet): void {
    this.servSpecFormGroup.patchValue({
      taxRate: Math.round(taxRate.rate * 10000) / 100
    });
  }

  getAvaProjectList(): void {
    this.avaProjectsService
      .getAll()
      .pipe(
        take(1),
        switchMap((avaProjectList: AvaProjectGet[]) => {
          this.listSchemas = avaProjectList.map((item) => ({ id: item.id, name: item.name }));
          const listSchemaRequests = avaProjectList.map((item) =>
            this.itemNumberSchemasClient.getItemNumberSchema(this.projectId, item.id)
          );
          return combineLatest(listSchemaRequests);
        })
      )
      .subscribe((listSchema: ItemNumberSchemaDto[]) => {
        this.listSchemas.forEach((item, index) => {
          item.schema = listSchema[index];
          item.example = SettingSchemaComponent.getExample(item.schema);
        });
      });
  }

  onFileSelected(files: FileList): void {
    if (files && files.length > 0) {
      this.selectedFile = files[0];

      this.performingAvaInspection = true;
      this.avaFilesClient
        .inspectAvaFile({
          fileName: this.selectedFile.name,
          data: this.selectedFile
        })
        .subscribe(
          (ava: AvaFileInspectionResultGet) => {
            this.fileCouldNotBeRead = false;
            this.performingAvaInspection = false;
            this.avaFileInspectionResult = ava;

            if (ava.serviceSpecificationName) {
              this.servSpecFormGroup.patchValue({
                name: ava.serviceSpecificationName
              });
            } else {
              this.servSpecFormGroup.patchValue({
                name: ava.projectInformation?.name ?? 'Standard'
              });
            }
          },
          () => {
            this.performingAvaInspection = false;
            this.fileCouldNotBeRead = true;
            this.notificationsService.error('Die Datei konnte nicht gelesen werden.');
            this.selectedFile = null;
          }
        );
    }
  }

  addServSpec(): void {
    this.requestEnRoute = true;
    this.addServSpecInternal();
  }

  private async addServSpecInternal(): Promise<void> {
    const masterAdditionGroupId = this.servSpecFormGroup.value.masterAdditionGroupId || this.standardGroupId;
    const sourceAvaProjectIdForAdditions = this.servSpecFormGroup.value.additionsSourceAvaProjectId;

    const model: AvaProjectPost = {
      name: this.servSpecFormGroup.value.name,
      existingAvaProjectIdForAdditions: sourceAvaProjectIdForAdditions,
      masterAdditionGroupId: sourceAvaProjectIdForAdditions ? null : masterAdditionGroupId,
      skipImportingPrices: !this.includePricesFromAvaFile,
      importPricesAsFixedPrices: this.importPricesAsFixedPrices,
      type: this.servSpecFormGroup.value.type,
      taxRate: Math.round(this.servSpecFormGroup.value.taxRate * 100) * 0.0001,
      enableSupportForSkippedTiersInItemNumberSchema: this.enableSupportForSkippedTiersInItemNumberSchema
    };

    if (this.selectedFile) {
      model.serviceSpecificationFile = {
        base64Content: await this.getBase64ForSelectedFile(),
        fileName: this.selectedFile.name,
        mimeType: 'application/octet-stream'
      };
    }

    this.avaProjectsClient.createAvaProject(this.projectId, model).subscribe(
      (ap: AvaProjectGet) => {
        const requestUpdateSchema: Observable<ItemNumberSchemaDto | boolean> = this.servSpecFormGroup.value.selectedSchema
          ? this.itemNumberSchemasClient.updateItemNumberSchemaForAvaProject(
              this.projectId,
              ap.id,
              this.servSpecFormGroup.value.selectedSchema.schema
            )
          : of(true);
        this.requestEnRoute = false;
        this.avaProjectsService.forceRefresh();
        this.notificationsService.success('LV hinzugefügt.');
        requestUpdateSchema.subscribe(() => {
          this.router.navigate(['..', ap.id], {
            relativeTo: this.route
          });
        });
      },
      () => {
        this.notificationsService.error('Das LV konnte nicht erstellt werden.');
        this.requestEnRoute = false;
      }
    );
  }

  private getBase64ForSelectedFile(): Promise<string> {
    return new Promise<string>((resolve) => {
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(this.selectedFile);
      reader.onload = function () {
        let encoded: string = reader.result.toString().replace(/^data:(.*,)?/, '');
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
    });
  }

  changeSchema(): void {
    this.modalService
      .openModal(SettingSchemaComponent, { dialogType: ConfirmationType.General })
      .afterClosed()
      .subscribe((e) => {
        if (e) {
          const newValue = {
            name: 'Benutzerdefiniert',
            schema: e,
            example: SettingSchemaComponent.getExample(e)
          };
          this.listSchemas.push(newValue);
          this.servSpecFormGroup.get('selectedSchema').patchValue(newValue);
        }
      });
  }

  copyAdditionGroup(value?: boolean): void {
    this.isCopyAdditionGroup = value;
    const masterAddition = this.servSpecFormGroup.get('masterAdditionGroupId');
    const copyAddition = this.servSpecFormGroup.get('additionsSourceAvaProjectId');
    if (this.isCopyAdditionGroup) {
      this.modalService
        .openModal(SelectProjectAvaProjectComponent, { dialogType: ConfirmationType.General })
        .afterClosed()
        .subscribe((obj) => {
          this.copyAdditionObj = obj;
          if (obj) {
            copyAddition.setValue(this.copyAdditionObj.avaProject.id);
            masterAddition.disable();
            masterAddition.setValue('');
          } else {
            this.isCopyAdditionGroup = false;
          }
        });
    } else {
      masterAddition.setValue(this.standardGroupId);
      masterAddition.enable();
      copyAddition.setValue('');
      this.copyAdditionObj = null;
    }
  }
}
