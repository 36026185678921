import { Component } from '@angular/core';

import { SpecificProjectProductsTableComponent } from '../specific-project-products-table/specific-project-products-table.component';

@Component({
  selector: 'pa-specific-all-products',
  templateUrl: './specific-all-products.component.html',
  styleUrls: ['./specific-all-products.component.scss'],
  standalone: true,
  imports: [SpecificProjectProductsTableComponent]
})
export class SpecificAllProductsComponent {}
