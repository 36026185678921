import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatRipple } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';

import { Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

import { SelectionElementNewComponent } from '@serv-spec/components/selection-element-new/selection-element-new.component';

import { ElementSelection, ElementSelectionGet, ElementSelectionsClient, SelectedElement } from 'app/generated-client/generated-client';
import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { SelectionElementService } from 'app/shared/services/lightquery/selection-element.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';

import { UpperPaginatorComponent } from '../../../../../../shared/components/upper-paginator/upper-paginator.component';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-selection-element-list-table',
  templateUrl: './selection-element-list-table.component.html',
  styleUrls: ['./selection-element-list-table.component.scss'],
  standalone: true,
  imports: [
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    MatRipple,
    FlexLayoutDirective,
    MatIconButton,
    MatIcon,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    UpperPaginatorComponent,
    AsyncPipe,
    DatePipe
  ]
})
export class SelectionElementListTableComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Output() selected = new EventEmitter<ElementSelectionGet>();
  @Input() isDelete: boolean;
  @Input() isOpenInModalWindow: boolean;
  columnToDisplay: string[] = ['name', 'createdAtUtc'];
  private projectId: string;
  private avaProjectId: string;
  private $destroy = new Subject<boolean>();

  constructor(
    public selectionElementService: SelectionElementService,
    private elementSelectionsClient: ElementSelectionsClient,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private modalService: ModalService,
    private avaNotificationsService: AvaNotificationsService
  ) {}

  ngOnInit(): void {
    if (this.isDelete && !this.isOpenInModalWindow) {
      this.columnToDisplay = ['name', 'createdAtUtc', 'action'];
    }
    this.getData();
    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(takeUntil(this.$destroy))
      .subscribe((e: { avaProjectId: string; parentProjectId: string }) => {
        this.projectId = e?.parentProjectId;
        this.avaProjectId = e?.avaProjectId;
      });
    this.selectionElementService.forceRefresh();
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  getData(): void {
    this.selectionElementService.initService({
      paging: { page: 1, pageSize: 10 },
      sortTableObj: this.sort
    });
  }

  chooseItem(line: ElementSelectionGet): void {
    this.selected.emit(line);
  }

  editSelection(event: MouseEvent, row: ElementSelectionGet): void {
    event.stopPropagation();
    this.elementSelectionsClient
      .getElementSelectionDetailById(this.projectId, this.avaProjectId, row.id)
      .pipe(
        map((e: ElementSelectionGet) => e.data.selectedElements),
        switchMap((e: SelectedElement[]) => {
          return this.modalService
            .openModal(SelectionElementNewComponent, {
              dialogType: ConfirmationType.General,
              restoreFocus: false,
              autoFocus: false,
              data: { name: row.name, selectedElement: e }
            })
            .afterClosed();
        }),
        filter((r) => !!r),
        switchMap((r: { data: ElementSelection; name: string }) =>
          this.elementSelectionsClient.updateElementSelection(this.projectId, this.avaProjectId, row.id, {
            id: row.id,
            name: r.name,
            data: r.data
          })
        )
      )
      .subscribe({
        next: () => {
          this.refresh();
        },
        error: () => {
          this.avaNotificationsService.error('Fehler beim Speichern der Elementauswahl');
        }
      });
  }

  delSelection(event: MouseEvent, row: ElementSelectionGet): void {
    event.stopPropagation();
    this.modalService
      .openModal(ModalConfirmComponent, {
        dialogType: ConfirmationType.Delete,
        data: ['Löschen', 'Elementauswahl', `Auswahl : ${row.name}`]
      })
      .afterClosed()
      .subscribe((e) => {
        if (e) {
          this.elementSelectionsClient.deleteElementSelection(this.projectId, this.avaProjectId, row.id).subscribe(() => {
            this.refresh();
          });
        }
      });
  }

  refresh(): void {
    this.selectionElementService.forceRefresh();
  }
}
