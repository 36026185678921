import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger, MatMenu, MatMenuContent, MatMenuItem } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';

import { EMPTY, Subject, filter, map, switchMap, take, takeUntil } from 'rxjs';

import { FolderModalForNameComponent } from '@project/components/project-files/components/folder-modal-for-name/folder-modal-for-name.component';
import { FolderModalToRemoveComponent } from '@project/components/project-files/components/folder-modal-to-remove/folder-modal-to-remove.component';
import { ProjectFileService } from '@project/components/project-files/services/project-file.service';

import {
  ProjectFileFolderGet,
  ProjectFileFolderPost,
  ProjectFileFolderPut,
  ProjectFileGet,
  ProjectFilesClient
} from 'app/generated-client/generated-client';
import { FileElement } from 'app/shared/models';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { ErrorLogService } from 'app/shared/services/error-log.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';

import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

import { ProjectFileListPreviewComponent } from '../project-file-list-preview/project-file-list-preview.component';

@Component({
  selector: 'pa-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatIcon,
    MatTooltip,
    FlexLayoutDirective,
    NgFor,
    NgClass,
    MatMenuTrigger,
    ProjectFileListPreviewComponent,
    MatMenu,
    MatMenuContent,
    MatMenuItem,
    DatePipe
  ]
})
export class FileManagerComponent implements OnInit, OnDestroy {
  @Input() fileElements: FileElement[] = [];
  @Input() canNavigateUp: boolean;
  @Input() path: string;
  @Input() currentRoot: FileElement;

  @Output() folderAdded = new EventEmitter<ProjectFileFolderGet>();
  @Output() elementRemoved = new EventEmitter<FileElement>();
  @Output() navigatedDown = new EventEmitter<FileElement>();
  @Output() navigatedUp = new EventEmitter();
  @Output() elementRenamed = new EventEmitter<ProjectFileFolderGet>();

  $destroy: Subject<boolean> = new Subject<boolean>();

  selectedFileId: string | null = null;
  projectId: string;

  constructor(
    private modalService: ModalService,
    private projectFilesClient: ProjectFilesClient,
    private errorLogService: ErrorLogService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private notificationsService: AvaNotificationsService,
    private projectFileService: ProjectFileService
  ) {}

  ngOnInit(): void {
    this.selectedProjectMessengerService.selectedProject
      .pipe(
        map((p) => p.id),
        take(1)
      )
      .subscribe((id) => {
        this.projectId = id;
      });

    this.projectFileService.selectedProjFile
      .pipe(
        takeUntil(this.$destroy),
        filter((selectedProjFile) => !!selectedProjFile)
      )
      .subscribe((selectedProjFile) => {
        this.selectedFileId = selectedProjFile.id;
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  deleteElement(element: FileElement): void {
    this.modalService
      .openModal(FolderModalToRemoveComponent, { dialogType: ConfirmationType.General })
      .afterClosed()
      .pipe(
        switchMap((response: boolean | void) => {
          if (response) {
            return this.projectFilesClient.deleteProjectFolder(this.projectId, element.id);
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe({
        next: () => {
          this.elementRemoved.emit(element);
          this.notificationsService.success('Ordner gelöscht');
        },
        error: () => this.notificationsService.error('Ordner konnte nicht gelöscht werden')
      });
  }

  navigate(element: FileElement): void {
    if (!element.isFolder && this.selectedFileId === element.id) {
      this.projectFileService.setSelectedProjFile(null);
      this.selectedFileId = null;
      return;
    }
    if (element.isFolder) {
      this.navigatedDown.emit(element);
      this.selectedFileId = null;
      this.projectFileService.setSelectedProjFile(null);
    } else {
      const file: ProjectFileGet = {
        id: element.id,
        fileId: element.fileId,
        file: element.file,
        folderId: element.parent,
        canDelete: element.canDelete
      };
      this.selectedFileId = element.id;
      this.projectFileService.setSelectedProjFile(file);
    }
  }

  navigateUp(): void {
    this.navigatedUp.emit();
    this.selectedFileId = null;
    this.projectFileService.setSelectedProjFile(null);
  }

  createFolder(): void {
    this.modalService
      .openModal(FolderModalForNameComponent, { dialogType: ConfirmationType.General })
      .afterClosed()
      .pipe(
        switchMap((fileName: string) => {
          if (fileName) {
            const newFolder: ProjectFileFolderPost = {
              projectId: this.projectId,
              name: fileName,
              parentFolderId: this.currentRoot?.id || null
            };
            return this.projectFilesClient
              .createProjectFolder(this.projectId, newFolder)
              .pipe(map((res) => res.find((r) => r.name === fileName && r.parentFolderId == newFolder.parentFolderId)));
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe({
        next: (response: ProjectFileFolderGet) => {
          this.folderAdded.emit(response);
        },
        error: (error) => this.errorLogService.setError(error)
      });
  }

  openRenameDialog(element: FileElement): void {
    this.modalService
      .openModal(FolderModalForNameComponent, { dialogType: ConfirmationType.General, data: element })
      .afterClosed()
      .pipe(
        switchMap((fileName: string) => {
          if (fileName && fileName !== element.name) {
            const editFolder: ProjectFileFolderPut = {
              id: element.id,
              projectId: this.projectId,
              name: fileName,
              parentFolderId: this.currentRoot?.id || null
            };
            return this.projectFilesClient
              .editProjectFolder(this.projectId, element.id, editFolder)
              .pipe(map((res) => res.find((r) => r.name === fileName)));
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe({
        next: (response: ProjectFileFolderGet) => {
          this.elementRenamed.emit(response);
        },
        error: (error) => this.errorLogService.setError(error)
      });
  }

  openMenu(event: MouseEvent, viewChild: MatMenuTrigger): void {
    event.preventDefault();
    viewChild.openMenu();
  }
}
