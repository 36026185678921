import { NgIf, NgClass } from '@angular/common';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatSidenavContainer, MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { Router, RoutesRecognized } from '@angular/router';

import { AuthenticationMessenger } from '@dangl/angular-dangl-identity-client';
import { UserInfo } from '@dangl/angular-dangl-identity-client/models/user-info';
import { HeaderComponent } from '@dangl/angular-material-shared';

import { EMPTY, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';
import { ProjectGet } from 'app/generated-client/generated-client';
import { HttpRequestsService } from 'app/shared/services/http-requests.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';

import { getAppConfig } from '../../../app-config-accessor';
import { ElectronService } from '../../../shared/services/electron/electron.service';
import { MainThreadHttpInterceptorHelperService } from '../../../shared/services/electron/main-thread-http-interceptor-helper.service';
import { version } from '../../../version';
import { AutoUpdateService } from '../../services/auto-update.service';
import { ProjectLocationService } from '../../services/project-location.service';
import { isInElementView } from '../../utilities/view-detection';

import { FailRequestCheckService } from './../../services/fail-request-check.service';

import { ButtonBackComponent } from '../button-back/button-back.component';
import { CategoryInformComponent } from '../category-inform/category-inform.component';
import { LockIconComponent } from '../lock-icon/lock-icon.component';
import { LoginPartialComponent } from '../login-partial/login-partial.component';
import { NotificationListComponent } from '../notification-list/notification-list.component';
import { ProjectUsersComponent } from '../project-users/project-users.component';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { TopLevelMenuComponent } from '../top-level-menu/top-level-menu.component';
import { ZoomerComponent } from '../zoomer/zoomer.component';
import { DanglIconsConfigService } from 'ng-dangl-icons';

@Component({
  selector: 'pa-general-header',
  templateUrl: './general-header.component.html',
  styleUrls: ['./general-header.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutDirective,
    HeaderComponent,
    CategoryInformComponent,
    TopLevelMenuComponent,
    LockIconComponent,
    NgIf,
    ZoomerComponent,
    NgClass,
    NotificationListComponent,
    ProjectUsersComponent,
    LoginPartialComponent,
    MatSidenavContainer,
    MatSidenav,
    SideNavComponent,
    MatSidenavContent,
    ButtonBackComponent
  ]
})
export class GeneralHeaderComponent implements OnInit, OnDestroy {
  public readonly localstorageLastMenuStateIdentifier = 'LOCALSTORAGE_LAST_MENU_STATE_IDENTIFIER';
  logoInitials = 'DAA';
  menuOpened = false;
  prefix = getAppConfig().appPrefix;
  preReleaseBuildDate: Date = null;
  preReleaseLiveSiteLink: string = null;
  preReleaseVersion: string = null;
  postfix = '.AVA.App';
  userInfo: UserInfo;
  isInElementDetailView = false;
  private requestsArray: { url: string; isError: boolean; statusCode: number }[] = [];
  requestsRunning: number;
  hasFailedRequests = false;
  project: ProjectGet;
  $destroy: Subject<boolean> = new Subject<boolean>();
  isQtoOnlyMode = getAppConfig().isQtoOnlyMode;

  constructor(
    authenticationMessenger: AuthenticationMessenger,
    autoUpdateService: AutoUpdateService,
    danglIconsConfigService: DanglIconsConfigService,
    public electronService: ElectronService,
    mainThreadHttpInterceptorHelperService: MainThreadHttpInterceptorHelperService,
    router: Router,
    private httpRequestsService: HttpRequestsService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private ngZone: NgZone,
    private failRequestCheckService: FailRequestCheckService,
    private projectLocationService: ProjectLocationService
  ) {
    const isInElementViewWindow = isInElementView();
    const versionMessage = getAppConfig().appPrefix + 'AVA Version ';
    console.log(`${versionMessage}${version.informationalVersion}`);

    this.logoInitials = getAppConfig().logoInitials;

    router.events
      .pipe(
        filter((e) => e instanceof RoutesRecognized),
        switchMap((e) => {
          if ((e as RoutesRecognized)?.url === '/login') {
            this.menuOpened = false;
            localStorage.setItem(this.localstorageLastMenuStateIdentifier, this.menuOpened ? 'opened' : 'closed');
          }

          if (autoUpdateService.hasCheckedForVersion) {
            return EMPTY;
          }

          autoUpdateService.hasCheckedForVersion = true;
          if (!isInElementViewWindow) {
            return autoUpdateService.checkForVersionAndPromptUser();
          } else {
            // This means we're in the element detail view
            this.isInElementDetailView = true;

            return EMPTY;
          }
        }),
        takeUntil(this.$destroy)
      )
      .subscribe((newVersionAvailable: boolean) => {
        if (newVersionAvailable) {
          console.log('Newer version available');
        }
      });

    if (electronService.isElectron) {
      mainThreadHttpInterceptorHelperService.setupMainThreadBearerTokenExchange();
    }

    danglIconsConfigService.setConfig({
      baseUrl: getAppConfig().danglIconsBaseUrl
    });

    if (getAppConfig().environment !== 'PROD' && getAppConfig().environment !== 'Production' && !getAppConfig().production) {
      this.preReleaseVersion = version.version;
      this.preReleaseBuildDate = version.buildDateUtc;
    }

    if (!isInElementViewWindow) {
      authenticationMessenger.userInfo.pipe(takeUntil(this.$destroy)).subscribe((ui) => (this.userInfo = ui));
      authenticationMessenger.tokenRefreshFinished.pipe(takeUntil(this.$destroy)).subscribe((tr) => {
        if (!tr) {
          // This means that the token refresh has failed
          router.navigate(['/login']);
        }
      });
    }

    if (localStorage.getItem(this.localstorageLastMenuStateIdentifier) === 'opened') {
      this.menuOpened = true;
    }
  }

  ngOnInit(): void {
    this.httpRequestsService.requests
      .pipe(takeUntil(this.$destroy))
      .subscribe((resp: { url: string; isError: boolean; statusCode: number }) => {
        const entryIndex = this.requestsArray.findIndex((item) => item.url === resp.url);
        if (entryIndex > -1) {
          this.requestsArray[entryIndex] = resp;
        } else {
          this.requestsArray.push(resp);
        }

        this.setRequestIndicator();
      });

    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((p: ProjectGet) => (this.project = p));

    this.httpRequestsService.requestsRunning.pipe(takeUntil(this.$destroy)).subscribe((r: number) => {
      // If any requests are running, the indicator should be blue to signal a running request.
      setTimeout(() => {
        // We're placing this in a setTimeout call, since otherwise we might get an
        // ExpressionChangedAfterItHasBeenCheckedError from time to time
        this.ngZone.run(() => (this.requestsRunning = r));
      }, 0);
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  resetRequestsArray(): void {
    this.requestsArray = [];
    this.setRequestIndicator();
  }

  private setRequestIndicator(): void {
    this.hasFailedRequests = this.failRequestCheckService.checkRequest(this.requestsArray);
    this.projectLocationService.setChangedIndicator(this.hasFailedRequests);
  }

  toggleMenuButton(): void {
    this.menuOpened = !this.menuOpened;
    localStorage.setItem(this.localstorageLastMenuStateIdentifier, this.menuOpened ? 'opened' : 'closed');
  }

  stoppedChange() {
    document.dispatchEvent(new MouseEvent('mouseup'));
  }
}
