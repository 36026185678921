import { PriceComponentType, ProjectProductGet } from '../../../generated-client/generated-client';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationMainService } from './main/pagination-main.service';
import { SelectedSpecificationMessengerService } from '../messengers/selected-specification-messenger.service';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProjectProductsService extends PaginationMainService<ProjectProductGet> {
  constructor(protected http: HttpClient, selectedSpecificationMessengerService: SelectedSpecificationMessengerService) {
    super(http);
    selectedSpecificationMessengerService.selectedServiceSpecification.pipe(filter(s => !!s)).subscribe(s => {
      this.beginBaseUrl(`/api/projects/${s.parentProjectId}/ava-projects/${s.avaProjectId}/products`);
    });
  }

  setPriceComponentType(priceComponentType: PriceComponentType): void {
    this.setQueryParameter('priceComponentType', priceComponentType);
  }
}
