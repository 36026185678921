import { Injectable, OnDestroy } from '@angular/core';

import { ReplaySubject, Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ServiceSpecificationGroupDto, UserSettings } from 'app/generated-client/generated-client';
import { WindowType } from 'app/shared/models/window-type.models';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { CopyCalculationViewMessengerService } from 'app/shared/services/electron/copy-calculation-view-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';
import { ShowedViewsService } from 'app/shared/services/showed-views.service';
import { UserSettingsService } from 'app/shared/services/user-settings.service';
import { getStorage } from 'app/shared/utilities/storage';

@Injectable({
  providedIn: 'root'
})
export class CopyCalculationForGroupService implements OnDestroy {
  private startCopyingGroupSource = new ReplaySubject<{ groupNode: ServiceSpecificationGroupDto; isModal?: boolean }>(1);
  startCopyingGroup = this.startCopyingGroupSource.asObservable();

  private $destroy: Subject<boolean> = new Subject<boolean>();

  private copyCalculationViewOpen = false;
  private userSettings: UserSettings;
  private dataMainView: [string[], { name: string; rect: DOMRect }[]];
  private group: ServiceSpecificationGroupDto;

  constructor(
    private avaNotificationsService: AvaNotificationsService,
    private modalService: ModalService,
    private userSettingsService: UserSettingsService,
    private copyCalculationViewMessengerService: CopyCalculationViewMessengerService,
    private showedViewsService: ShowedViewsService
  ) {
    this.copyCalculationViewMessengerService.copyCalculationViewVisible
      .pipe(takeUntil(this.$destroy))
      .subscribe((copyCalculationViewVisible) => {
        this.copyCalculationViewOpen = copyCalculationViewVisible;
        if (this.group) {
          this.sendGroupForCopy(this.group);
        }
      });

    this.userSettingsService.currentUserSettings.pipe(takeUntil(this.$destroy)).subscribe((currentUserSettings) => {
      this.userSettings = currentUserSettings;
    });

    combineLatest([this.showedViewsService.showedViews, this.showedViewsService.showedSeparatedViews])
      .pipe(takeUntil(this.$destroy))
      .subscribe((e: [string[], { name: string; rect: DOMRect }[]]) => {
        this.dataMainView = e;
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  setStartCopyingGroup(data: { groupNode: ServiceSpecificationGroupDto; isModal?: boolean }): void {
    this.startCopyingGroupSource.next(data);
  }

  copyGroup(data: { groupNode: ServiceSpecificationGroupDto; isModal?: boolean }): void {
    if (!data) {
      return;
    }
    this.group = data && data.groupNode;
    this.avaNotificationsService.info('Bitte jetzt eine Gruppe auswählen, aus der die Kalkulationen kopiert werden sollen.');
    if (data && data.isModal) {
      this.sendGroupForCopy(data.groupNode);
      this.group = null;
      return;
    }

    if (this.copyCalculationViewOpen || this.hasMainView(WindowType.CopyCalc)) {
      this.sendGroupForCopy(data.groupNode);
      this.group = null;
      return;
    }

    if (!this.userSettings.showMultiComponentsViewInMainWindow) {
      this.copyCalculationViewMessengerService.showCopyCalculationViewWindow();
      return;
    }

    const showedList = getStorage<string[]>('listViews', [] as string[]) as string[];
    if (!showedList.includes(WindowType.CopyCalc)) {
      this.showedViewsService.setShowedViews([...showedList, WindowType.CopyCalc]);
    }
  }

  private hasMainView(name: string): boolean {
    return (
      this.userSettings.showMultiComponentsViewInMainWindow &&
      (this.dataMainView[0].includes(name) || this.dataMainView[1].some((item) => item.name === name))
    );
  }

  sendGroupForCopy(groupNode: ServiceSpecificationGroupDto): void {
    this.copyCalculationViewMessengerService.setListCopyCalculation({
      method: { getCalculation: ['elementGroup', groupNode?.id] }
    });
    this.copyCalculationViewMessengerService.sendSelectedElementGroupToViewWindow(groupNode?.id);
  }

  delMarkCopyGroup(): void {
    this.startCopyingGroupSource.next(null);
  }
}
