import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';

import { Subject, combineLatest } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';

import { PageQuantityTakeOffOverviewGet, PageQuantityTakeOffsClient } from 'app/generated-client/generated-client';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedQuantityTakeOffMessengerService } from 'app/shared/services/messengers/selected-quantity-take-off-messenger.service';

import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-invoice-page-paginator',
  templateUrl: './invoice-page-paginator.component.html',
  styleUrls: ['./invoice-page-paginator.component.scss'],
  standalone: true,
  imports: [NgIf, FlexLayoutDirective, MatButton, MatIcon]
})
export class InvoicePagePaginatorComponent implements OnInit, OnDestroy {
  isPages = false;
  modePage: string;
  listPages: { id: string; pageNumber: string }[] = [];
  indexPage = 0;
  quantityTakeOffPageId: string;
  constructor(
    private pageQuantityTakeOffsClient: PageQuantityTakeOffsClient,
    private router: Router,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedQuantityTakeOffMessengerService: SelectedQuantityTakeOffMessengerService
  ) {}

  private $destroy: Subject<boolean> = new Subject<boolean>();

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

  ngOnInit(): void {
    this.selectedQuantityTakeOffMessengerService.manualPageChangeRequested.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.setPage();
    });

    this.setPage();
  }

  private setPage(): void {
    const arrUrl = this.router.url.split('/');
    this.modePage = `invoice.${arrUrl.slice(5).join('.')}`;
    this.isPages = this.modePage.includes('pages');
    this.quantityTakeOffPageId = arrUrl.pop();
    if (this.isPages) {
      // This is loading all the pages, so we can do an easy navigation with the arrow buttons.
      combineLatest([
        this.selectedProjectMessengerService.selectedProject,
        this.selectedQuantityTakeOffMessengerService.selectedQuantityTakeOff.pipe(filter((qto) => qto != null))
      ])
        .pipe(first())
        .subscribe(([project, qto]) => {
          let hasMore = true;
          let currentPage = 1;
          const getPagesList = () => {
            if (!hasMore) {
              this.listPages.sort((a1, a2) => (a1.pageNumber > a2.pageNumber ? 1 : -1));
              return;
            }
            this.pageQuantityTakeOffsClient
              .getAllPageQuantityTakeOffs(project.id, qto.avaProjectId, qto.id, false, false, null, '', '', 500, currentPage++)
              .subscribe((c) => {
                if (c.page !== currentPage - 1) {
                  hasMore = false;
                } else if (c.data.length > 0) {
                  c.data.forEach((item: PageQuantityTakeOffOverviewGet) =>
                    this.listPages.push({ id: item.id, pageNumber: item.pageNumber })
                  );
                  hasMore = true;
                }
                getPagesList();
              });
          };
          this.listPages = [];
          getPagesList();
        });
    }
  }

  isBorderPageIndex(isLast?: boolean): boolean {
    this.indexPage = this.listPages.findIndex(({ id }) => id === this.quantityTakeOffPageId);
    return this.indexPage === (isLast ? this.listPages.length - 1 : 0);
  }

  otherPage(isNextPage?: boolean, index?: number): void {
    const arrUrl = this.router.url.split('/');
    const nextIndex =
      index || index === 0 ? index : this.listPages.findIndex(({ id }) => id === this.quantityTakeOffPageId) + (isNextPage ? 1 : -1);
    if (nextIndex >= 0) {
      const nextPageId = this.listPages[nextIndex].id;
      arrUrl[arrUrl.length - 1] = nextPageId;
      this.router.navigate(arrUrl);
      setTimeout(() => {
        this.selectedQuantityTakeOffMessengerService.setChangedQuantityTakeOffPage(nextPageId);
        this.quantityTakeOffPageId = arrUrl.pop();
      }, 1);
    }
  }
}
