import { Component, Optional } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';

@Component({
  selector: 'pa-help-window-toc',
  templateUrl: './help-window-toc.component.html',
  styleUrls: ['./help-window-toc.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, MatButton]
})

//TODO perhaps we can remove it, because we don't use it in new help window design
export class HelpWindowTocComponent {
  constructor(@Optional() private matDialogRef: MatDialogRef<HelpWindowTocComponent>) {}

  selectSection(section: string): void {
    this.matDialogRef.close(section);
  }

  cancel(): void {
    this.matDialogRef.close();
  }
}
