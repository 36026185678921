import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { MasterAdditionGroupGet } from '../../../generated-client/generated-client';

@Injectable({
  providedIn: 'root'
})
export class MasterDataSelectedGroupService {
  private selectedGroupSource = new ReplaySubject<MasterAdditionGroupGet>(1);
  selectedGroup = this.selectedGroupSource.asObservable();

  setSelectedGroup(selectedGroup: MasterAdditionGroupGet): void {
    if (selectedGroup) {
      this.selectedGroupSource.next(selectedGroup);
    }
  }
}
