import { Pipe, PipeTransform } from '@angular/core';

import { UiPositionType } from '../../models/ui-position-type';

@Pipe({
  name: 'uiPositionType',
  standalone: true
})
export class UiPositionTypePipe implements PipeTransform {
  transform(value: UiPositionType): string {
    switch (value) {
      default:
      case UiPositionType.Unknown:
        return 'Unbekannter Positionstyp';

      case UiPositionType.Regular:
        return 'Normalposition';

      case UiPositionType.Alternative:
        return 'Alternativposition';

      case UiPositionType.Optional:
        return 'Bedarfsposition';

      case UiPositionType.Eventual:
        return 'Eventualposition';

      case UiPositionType.HourlyPaidWork:
        return 'Regiearbeit';

      case UiPositionType.Complementing:
        return 'Umlageposition';

      case UiPositionType.NoteText:
        return 'Hinweistext';
    }
  }
}
