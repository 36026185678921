import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelpWindowScrollService {
  private scrollElementIdSource = new BehaviorSubject<string>('');
  scrollElementId$ = this.scrollElementIdSource.asObservable();

  constructor() {}

  setScrollElementId(elementId: string) {
    this.scrollElementIdSource.next(elementId);
  }
}
