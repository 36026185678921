<div mat-dialog-content class="wrapper">
  <div mat-dialog-title>Neuer Ordner</div>
  <form [formGroup]="newItemForm">
    <mat-form-field appearance="fill">
      <mat-label>Name</mat-label>
      <input matInput required formControlName="folderName" />
    </mat-form-field>
    <div mat-dialog-actions>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [mat-dialog-close]="newItemForm.value.folderName"
        [disabled]="newItemForm.invalid"
      >
        Speichern
      </button>
      <button mat-raised-button mat-dialog-close>Abbrechen</button>
    </div>
  </form>
</div>
