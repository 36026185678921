import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InvoiceRecipientCodeGet } from 'app/generated-client/generated-client';
import { PaginationMainService } from './main/pagination-main.service';
import { SelectedProjectMessengerService } from '../messengers/selected-project-messenger.service';
import { takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvoiceRecipientCodesService extends PaginationMainService<InvoiceRecipientCodeGet> {
  constructor(
    protected http: HttpClient,
    private selectedProjectMessengerService: SelectedProjectMessengerService
  ) {
    super(http);
    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((p) => {
      if (p) {
        this.beginBaseUrl(`/api/projects/${p.id}/invoice-recipient-codes`);
      }
    });
  }
}
