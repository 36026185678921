import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckboxChange, MatCheckbox } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

import { ContactsComponent } from 'app/areas/contacts/components/contacts/contacts.component';
import { ContactGet, PriceInquiryRequestPost, PriceInquiryRequestsClient } from 'app/generated-client/generated-client';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';

import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-create-price-inquiry-requests',
  templateUrl: './create-price-inquiry-requests.component.html',
  styleUrls: ['./create-price-inquiry-requests.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutDirective,
    MatFormField,
    MatLabel,
    MatInput,
    MatButton,
    MatCheckbox
  ]
})
export class CreatePriceInquiryRequestsComponent implements OnInit {
  createPriceInquiryRequestForm: FormGroup;
  selectedContac: ContactGet;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { requestIds?: { projectId: string; avaProjectId: string; priceInquiryId: string }; priceInquiryName?: string },
    private fb: FormBuilder,
    private priceInquiryRequestsClient: PriceInquiryRequestsClient,
    @Optional() private matDialogRef: MatDialogRef<CreatePriceInquiryRequestsComponent>,
    private notificationsService: AvaNotificationsService,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.createPriceInquiryRequestForm = this.fb.group({
      name: ['', Validators.required],
      contactId: '',
      comments: '',
      isSelectedContact: [{ value: false, disabled: true }]
    });
    if (this.data?.priceInquiryName) {
      this.createPriceInquiryRequestForm.patchValue({
        name: this.data?.priceInquiryName
      });
    }
  }

  create(): void {
    const priceInquiryRequest: PriceInquiryRequestPost = {
      name: this.createPriceInquiryRequestForm.value.name,
      contactId: this.createPriceInquiryRequestForm.value.contactId,
      comments: this.createPriceInquiryRequestForm.value.comments
    };
    if (!this.data || !this.data.requestIds) {
      return;
    }
    const { projectId, avaProjectId, priceInquiryId } = this.data.requestIds;
    this.priceInquiryRequestsClient.createPriceInquiryRequest(projectId, avaProjectId, priceInquiryId, priceInquiryRequest).subscribe({
      next: (p) => {
        this.matDialogRef.close(p);
      },
      error: () => {
        this.notificationsService.error('Price inquiry request not created');
      }
    });
  }

  chooseContact(): void {
    this.matDialog
      .open(ContactsComponent, { restoreFocus: false, autoFocus: false, data: { isCreatePriceInuiry: true } })
      .afterClosed()
      .subscribe((contact: ContactGet) => {
        this.selectedContac = contact;
        this.changeSelectedContact();
      });
  }

  changeSelectedContact(event?: MatCheckboxChange): void {
    if (this.selectedContac && !event) {
      this.createPriceInquiryRequestForm.patchValue({
        isSelectedContact: true,
        name: this.selectedContac.name,
        contactId: this.selectedContac.id
      });
      this.createPriceInquiryRequestForm.get('isSelectedContact').enable();
    } else {
      this.selectedContac = null;
      this.createPriceInquiryRequestForm.patchValue({
        name: '',
        contactId: ''
      });
      this.createPriceInquiryRequestForm.get('isSelectedContact').disable();
    }
  }
}
