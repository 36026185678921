import { Injectable } from '@angular/core';
import { QuantityTakeOffAttachmentGet } from 'app/generated-client/generated-client';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectedAttachmentService {
  private selectedAttachmentSource = new ReplaySubject<QuantityTakeOffAttachmentGet>(1);
  selectedAttachment = this.selectedAttachmentSource.asObservable();
  constructor() {}

  setAttachment(attachment: QuantityTakeOffAttachmentGet): void {
    if (attachment && attachment.quantityTakeOffAttachmentImageFiles.length) {
      attachment.quantityTakeOffAttachmentImageFiles.sort((a, b) => a.pageNumber - b.pageNumber);
    }
    this.selectedAttachmentSource.next(attachment);
  }
}
