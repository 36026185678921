<div class="table-wrapper" *ngIf="dataSource && dataSource.data.length > 0">
  <table class="mat-elevation-z8" mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
    <ng-container matColumnDef="entityId">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element">#{{ element.entityId }}</td>
      <td mat-footer-cell *matFooterCellDef>Gesamt</td>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="quantity">Menge</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.quantity !== 0">
          {{ element.quantity | number: '1.3-3' }}
        </ng-container>
        <ng-container *ngIf="element.quantity === 0 || element.quantity == null">
          <mat-icon color="warn">warning</mat-icon>
        </ng-container>
      </td>
      <ng-container> </ng-container>
      <td mat-footer-cell *matFooterCellDef>
        {{ totalQuantity | number: '1.3-3' }}
      </td>
    </ng-container>

    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" class="table-footer-row"></tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
