import { Component, Inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';

import { PageQuantityTakeOffRowModel } from 'app/generated-client/generated-client';

@Component({
  selector: 'pa-invoice-modal-for-deleting-image',
  templateUrl: './invoice-modal-for-deleting-image.component.html',
  styleUrls: ['./invoice-modal-for-deleting-image.component.scss'],
  standalone: true,
  imports: [MatDialogContent, MatDialogActions, MatButton, MatDialogClose]
})
export class InvoiceModalForDeletingImageComponent {
  contentText: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { element: PageQuantityTakeOffRowModel }) {
    switch (true) {
      case !!data?.element?.fileId:
        this.contentText = 'Möchtest Du die Referenz zum Bild wirklich löschen?';
        break;
      case !!data?.element?.quantityTakeOffAttachmentId:
        this.contentText = 'Möchtest Du die Referenz zur Anlage wirklich löschen?';
        break;
    }
  }
}
