import { NgIf, AsyncPipe, DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationMessenger } from '@dangl/angular-dangl-identity-client';
import { UserInfo } from '@dangl/angular-dangl-identity-client/models/user-info';

import { Subject, combineLatest, filter, takeUntil } from 'rxjs';

import { ConfirmationType } from '@shared/models/dialog-config.model';
import { AvaNotificationsService } from '@shared/services/ava-notifications.service';
import { ModalService } from '@shared/services/modal.service';

import { BimFileGet, BimFilesClient, BimOperationStatus } from '../../../../generated-client/generated-client';
import { UpperPaginatorComponent } from '../../../../shared/components/upper-paginator/upper-paginator.component';
import { FileSizePipe } from '../../../../shared/pipes/file-size.pipe';
import { SelectedSpecificationMessengerService } from '../../../../shared/services/messengers/selected-specification-messenger.service';
import { BimFileStatusPipe } from '../../pipes/bim-file-status.pipe';
import { BimModelFileLightqueryService } from '../../services/bim-model-file-lightquery.service';

import { CreateModelDialogComponent } from '../create-model-dialog/create-model-dialog.component';
import { PaginationResult } from 'ng-lightquery';

@Component({
  selector: 'pa-model-bim-list',
  templateUrl: './model-bim-list.component.html',
  styleUrls: ['./model-bim-list.component.scss'],
  standalone: true,
  imports: [
    MatButton,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    NgIf,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    UpperPaginatorComponent,
    AsyncPipe,
    DatePipe,
    FileSizePipe,
    BimFileStatusPipe
  ]
})
export class ModelBimListComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatTable) private table: MatTable<any>;
  displayedColumns = [
    'fileName',
    'name',
    'sizeInBytes',
    'createdAtUtc',
    'geometryOperationStatus',
    'structureOperationStatus',
    'errorMessage'
  ];
  filter = '';
  bimFileStatus = BimOperationStatus;
  listProcessing: BimFileGet[] = [];
  showedData: BimFileGet[];
  stopInterval: NodeJS.Timer;
  userInfo: UserInfo;
  private $destroy: Subject<boolean> = new Subject<boolean>();
  private projectId: string;
  private avaProjectId: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private authenticationMessenger: AuthenticationMessenger,
    private notificationsService: AvaNotificationsService,
    public bimModelFileLightqueryService: BimModelFileLightqueryService,
    private bimFilesClient: BimFilesClient,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService
  ) {}

  ngOnInit(): void {
    this.bimModelFileLightqueryService.initService({
      sorting: { propertyName: 'createdAtUtc', isDescending: true },
      sortTableObj: this.sort
    });

    this.authenticationMessenger.userInfo.pipe(takeUntil(this.$destroy)).subscribe((ui) => (this.userInfo = ui));

    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        takeUntil(this.$destroy),
        filter((s) => !!s)
      )
      .subscribe((s) => {
        this.projectId = s.parentProjectId;
        this.avaProjectId = s.avaProjectId;
      });

    this.bimModelFileLightqueryService.paginationResult.pipe(takeUntil(this.$destroy)).subscribe((result: PaginationResult<BimFileGet>) => {
      this.showedData = result.data;
      this.checkListWithProcessing();
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.stopInterval);
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  onPage(pageEvent: PageEvent): void {
    this.bimModelFileLightqueryService.page = pageEvent.pageIndex + 1;
    this.bimModelFileLightqueryService.pageSize = pageEvent.pageSize;
  }

  selectModel(row: BimFileGet): void {
    if (row.structureOperationStatus === BimOperationStatus.Succeeded && row.geometryOperationStatus === BimOperationStatus.Succeeded) {
      this.router.navigate(['bim-viewer', row.id], { relativeTo: this.route });
    }
  }

  onFilter(filter): void {
    this.bimModelFileLightqueryService.onFilter(filter);
  }

  createModel(): void {
    this.modalService
      .openModal(CreateModelDialogComponent, { dialogType: ConfirmationType.General })
      .afterClosed()
      .subscribe((modelInfo: { selectedFile: { fileName: string; data: File }; name: string }) => {
        if (modelInfo) {
          this.bimFilesClient
            .uploadIfcFile(this.projectId, this.avaProjectId, modelInfo.name, {
              data: modelInfo.selectedFile.data,
              fileName: modelInfo.selectedFile.fileName
            })
            .subscribe(() => {
              this.updateTable();
            });
        }
      });
  }

  updateTable(): void {
    this.bimModelFileLightqueryService.forceRefresh();
    this.checkListWithProcessing();
  }

  checkListWithProcessing(): void {
    clearInterval(this.stopInterval);
    this.listProcessing = this.showedData.filter(
      (item) => item.geometryOperationStatus === BimOperationStatus.Pending || item.structureOperationStatus === BimOperationStatus.Pending
    );

    if (this.listProcessing.length) {
      this.stopInterval = setInterval(() => {
        this.getProcessing();
      }, 10000);
    }
  }

  getProcessing(): void {
    const listRequest = this.listProcessing.map((item) => {
      return this.bimFilesClient.getBimFileById(this.projectId, this.avaProjectId, item.id);
    });

    combineLatest(listRequest)
      .pipe(takeUntil(this.$destroy))
      .subscribe((listResult: BimFileGet[]) => {
        const newListProcessing = listResult.filter(
          (item) =>
            item.geometryOperationStatus === BimOperationStatus.Pending || item.structureOperationStatus === BimOperationStatus.Pending
        );
        if (this.listProcessing.length !== newListProcessing.length) {
          const changedListProcessing = listResult.filter(
            (item) =>
              item.geometryOperationStatus !== BimOperationStatus.Pending && item.structureOperationStatus !== BimOperationStatus.Pending
          );
          changedListProcessing.forEach((item) => {
            this.showNotification(item);
            const targetRow = this.showedData.find((itemMock) => itemMock.id === item.id);
            if (targetRow) {
              targetRow.structureOperationStatus = item.structureOperationStatus;
              targetRow.geometryOperationStatus = item.geometryOperationStatus;
            }
          });
          this.updateTable();
        }
      });
  }

  showNotification(item: BimFileGet): void {
    switch (item.geometryOperationStatus) {
      case BimOperationStatus.Failed:
        this.notificationsService.error('Fehler beim Erstellen der Geometrie für ' + item.name);
        break;
      case BimOperationStatus.Succeeded:
        this.notificationsService.success('Geometry fertig: ' + item.name);
        break;
    }
    switch (item.structureOperationStatus) {
      case BimOperationStatus.Failed:
        this.notificationsService.error('Fehler beim Erstellen der Eigenschaften für ' + item.name);
        break;
      case BimOperationStatus.Succeeded:
        this.notificationsService.success('Eigenschaften fertig: ' + item.name);
        break;
    }
  }
}
