<div #readOnlyMark>
  <h2 *ngIf="isReadOnly && !isHideReadOnlyMark" class="read-only-mark red">Schreibgeschützt</h2>
</div>

<!-- For the resizable directive to work correctly,
  we need to wrap the table in a container with the "table-wrapper" class.
  We use this class to search for a parent in the LimitColumnWidth service  -->
<div class="table-wrapper" #wrapElement>
  <cdk-virtual-scroll-viewport
    updateViewportSizeDirective
    *ngIf="hasLoaded && !(isLoadingOutside || isLoadingInside)"
    headerHeight="22"
    [tvsItemSize]="22"
    [style.height.px]="viewportHeight - readOnlyMark?.offsetHeight - 1"
    (scroll)="scrolled($event)"
  >
    <table
      mat-table
      [dataSource]="dataSource"
      (keydown.tab)="$event.preventDefault()"
      (keyup)="onKey($event)"
      id="calculation"
      cdkDropList
      #calculation="cdkDropList"
      (cdkDropListDropped)="drop($event)"
      resizeName="calculations"
      [trackBy]="trackByFn"
      [ngClass]="{
        unselectable: isInnerWindow
      }"
    >
      <ng-container matColumnDef="rowNumber">
        <th mat-header-cell *matHeaderCellDef #cellWidth class="mat-table-sticky">
          <pa-select-all-rows [dataList]="dataSource.data" [selectList]="listSelectedRows"></pa-select-all-rows>
        </th>
        <td mat-cell *matCellDef="let element" (mousedown)="startSelectRow(element.rowIndex)" [class.mark]="isMark(element)">
          <span>{{ element.rowIndex }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="isInternal">
        <th mat-header-cell *matHeaderCellDef class="width1 mat-table-sticky">I</th>
        <td mat-cell *matCellDef="let element" class="width1">
          <label class="internal">
            <input
              type="checkbox"
              id="{{ element.rowIndex }}-isInternal"
              [checked]="element.isInternal"
              [disabled]="isReadOnly || element.rowIndex > lastEditableRowIndex"
              (click)="changeInternal(element)"
            />
            <span></span>
          </label>
        </td>
      </ng-container>

      <ng-container matColumnDef="subPositionIdentifier">
        <th mat-header-cell *matHeaderCellDef class="text left mat-table-sticky" resizeColumn>U</th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            'disabled-td': isReadOnly || element.rowIndex > lastEditableRowIndex
          }"
          (keydown)="preventKeyDownEvent($event, element, true)"
        >
          <ng-container *ngIf="!(element.rowIndex > lastEditableRowIndex)">
            <pa-sub-position-item-number
              [subPositionIdentifier]="element.subPositionIdentifier"
              [rowIndex]="element.rowIndex"
              (updateSubPositionIdentifier)="handlerInputChangeEvent($event, element)"
              (keyDownEvent)="preventKeyDownEvent($event, element)"
              [disabled]="isReadOnly || element.rowIndex > lastEditableRowIndex"
            ></pa-sub-position-item-number>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="text">
        <th mat-header-cell *matHeaderCellDef class="text left mat-table-sticky" resizeColumn>Text</th>
        <td
          mat-cell
          *matCellDef="let element"
          class="text"
          (change)="recalculate(element)"
          [ngClass]="{
            'disabled-td': isReadOnly || element.rowIndex > lastEditableRowIndex
          }"
        >
          <input
            matInput
            id="{{ element.rowIndex }}-text"
            [(ngModel)]="element.text"
            (keydown)="preventKeyDownEvent($event, element)"
            [disabled]="isReadOnly || element.rowIndex > lastEditableRowIndex"
            (ngModelChange)="isChanged = true"
            (blur)="getBlur()"
            (focus)="getFocus(element)"
            onfocus="this.select()"
            (dblclick)="setCursorToEdit($event, false)"
          />
        </td>
      </ng-container>

      <ng-container matColumnDef="formulaFactor">
        <th mat-header-cell *matHeaderCellDef class="formula left mat-table-sticky" resizeColumn>Rechenzeichen Faktor</th>
        <td
          mat-cell
          *matCellDef="let element"
          class="formula"
          (change)="recalculate(element)"
          [attr.title]="element.formulaError"
          [ngClass]="{
            'disabled-td':
              isReadOnly ||
              element.rowIndex > lastEditableRowIndex ||
              (element.formulaFactor == null && element.factor) ||
              (element.importedFromPriceInquiryRequestId && element.subPositionIdentifier),
            'error-border': element.hasFormulaError,
            'formula-col': element.formulaFactor == null && element.factor
          }"
        >
          <input
            type="text"
            #formulaFactorR
            id="{{ element.rowIndex }}-formulaFactor"
            [(ngModel)]="element.formulaFactor"
            [disabled]="
              isReadOnly ||
              element.rowIndex > lastEditableRowIndex ||
              (element.formulaFactor == null && element.factor) ||
              (element.factor === 0 && element.subPositionIdentifier) ||
              (element.importedFromPriceInquiryRequestId && element.subPositionIdentifier)
            "
            (ngModelChange)="isChanged = true"
            (keydown)="preventKeyDownEvent($event, element)"
            matInput
            matInputDecimalPlaces
            [doNotForceDefaultScale]="true"
            (blur)="getBlur()"
            (focus)="getFocus(element)"
            onfocus="this.select()"
            [ngClass]="{
              'formula-col': element.formulaFactor == null && element.factor
            }"
            (dblclick)="setCursorToEdit($event, false)"
          />
        </td>
      </ng-container>

      <ng-container matColumnDef="factor">
        <th mat-header-cell *matHeaderCellDef class="right width5 mat-table-sticky" resizeColumn>Faktor</th>
        <td
          mat-cell
          *matCellDef="let element"
          class="factor width5"
          (change)="recalculate(element)"
          [ngClass]="{
            'disabled-td':
              isReadOnly ||
              element.rowIndex > lastEditableRowIndex ||
              !!element.formulaFactor ||
              (element.importedFromPriceInquiryRequestId && element.subPositionIdentifier),
            'factor-col': element.factor
          }"
        >
          <input
            *ngIf="element.formulaFactor"
            id="{{ element.rowIndex }}-factor"
            type="text"
            [value]="element.factor | number: '1.5-5'"
            matInputDecimalPlaces
            matInput
            disabled
            [ngClass]="{ 'factor-col': element.factor }"
          />
          <input
            *ngIf="!element.formulaFactor"
            id="{{ element.rowIndex }}-factor"
            type="text"
            [(ngModel)]="element.factor"
            (keydown)="preventKeyDownEvent($event, element)"
            [disabled]="
              isReadOnly ||
              element.rowIndex > lastEditableRowIndex ||
              !!element.formulaFactor ||
              (element.importedFromPriceInquiryRequestId && element.subPositionIdentifier)
            "
            (ngModelChange)="isChanged = true"
            allowNumericWithDecimal
            matInput
            matInputDecimalPlaces
            [scale]="5"
            (blur)="getBlur()"
            (focus)="getFocus(element)"
            onfocus="this.select()"
            [ngClass]="{ 'factor-col': element.factor }"
            (dblclick)="setCursorToEdit($event, true)"
          />
        </td>
      </ng-container>

      <ng-container matColumnDef="divisor">
        <th mat-header-cell *matHeaderCellDef #cellWidth class="right width5 mat-table-sticky" resizeColumn>Divisor</th>
        <td
          mat-cell
          *matCellDef="let element"
          class="width5"
          (change)="recalculate(element)"
          [ngClass]="{
            'disabled-td':
              element.rowIndex > lastEditableRowIndex ||
              isReadOnly ||
              (element.importedFromPriceInquiryRequestId && element.subPositionIdentifier),
            'divisor-col': element.divisor
          }"
        >
          <input
            id="{{ element.rowIndex }}-divisor"
            type="text"
            [disabled]="
              element.rowIndex > lastEditableRowIndex ||
              isReadOnly ||
              (element.importedFromPriceInquiryRequestId && element.subPositionIdentifier)
            "
            [(ngModel)]="element.divisor"
            (keydown)="preventKeyDownEvent($event, element)"
            (ngModelChange)="isChanged = true"
            matInput
            matInputDecimalPlaces
            [scale]="3"
            allowNumericWithDecimal
            (blur)="getBlur()"
            (focus)="getFocus(element)"
            onfocus="this.select()"
            [ngClass]="{
              'divisor-col': element.divisor
            }"
            (dblclick)="setCursorToEdit($event, true)"
          />
        </td>
      </ng-container>

      <ng-container matColumnDef="labourHours">
        <th mat-header-cell *matHeaderCellDef #cellWidth class="right width5 mat-table-sticky" resizeColumn>Lohnstunden</th>
        <td
          mat-cell
          *matCellDef="let element"
          class="width5"
          (change)="recalculate(element)"
          [ngClass]="{
            'disabled-td':
              isReadOnly ||
              element.rowIndex > lastEditableRowIndex ||
              (element.importedFromPriceInquiryRequestId && element.subPositionIdentifier)
          }"
        >
          <input
            id="{{ element.rowIndex }}-labourHours"
            type="text"
            [(ngModel)]="element.labourHours"
            (ngModelChange)="isChanged = true"
            (keydown)="preventKeyDownEvent($event, element)"
            [disabled]="
              isReadOnly ||
              element.rowIndex > lastEditableRowIndex ||
              (element.importedFromPriceInquiryRequestId && element.subPositionIdentifier)
            "
            allowNumericWithDecimal
            matInput
            matInputDecimalPlaces
            [scale]="3"
            (blur)="getBlur()"
            (focus)="getFocus(element)"
            onfocus="this.select()"
            (dblclick)="setCursorToEdit($event, true)"
          />
        </td>
      </ng-container>

      <ng-container matColumnDef="labourTotalSum">
        <th mat-header-cell *matHeaderCellDef #cellWidth class="right width5 mat-table-sticky" resizeColumn>Lohnbetrag</th>
        <td mat-cell *matCellDef="let element" (change)="recalculate(element)" class="width5" [ngClass]="{ 'disabled-td': true }">
          <input
            matInput
            matInputDecimalPlaces
            [scale]="2"
            id="{{ element.rowIndex }}-labourTotalSum"
            [ngModel]="element.labourTotalSum"
            disabled
            type="text"
          />
        </td>
      </ng-container>

      <ng-container *ngFor="let priceComp of additions.priceComponents; let i = index" matColumnDef="pc_{{ priceComp.priceComponent }}">
        <th mat-header-cell *matHeaderCellDef #cellWidth class="right width5 mat-table-sticky" resizeColumn>
          {{ priceComp.priceComponent }}
        </th>

        <td
          mat-cell
          *matCellDef="let element"
          class="width5"
          (change)="recalculate(element, { priceComponentIndex: i })"
          [ngClass]="{
            'disabled-td':
              isReadOnly ||
              element.rowIndex > lastEditableRowIndex ||
              element.masterProductId ||
              (element.importedFromPriceInquiryRequestId && element.subPositionIdentifier)
          }"
        >
          <div class="cell-wrap text-wrap">
            <input
              id="{{ element.rowIndex }}-pc_{{ priceComp.priceComponent }}"
              [(ngModel)]="element.priceComponents[i].sum"
              (keydown)="preventKeyDownEvent($event, element)"
              [disabled]="
                isReadOnly ||
                element.rowIndex > lastEditableRowIndex ||
                element.masterProductId ||
                (element.importedFromPriceInquiryRequestId && element.subPositionIdentifier)
              "
              (ngModelChange)="isChanged = true"
              matInput
              matInputDecimalPlaces
              [scale]="2"
              (focus)="getFocus(element, priceComp.priceComponent)"
              (blur)="getBlur()"
              onfocus="this.select()"
              (dblclick)="setCursorToEdit($event, true)"
            />
            <mat-icon
              class="tooltip-icon"
              *ngIf="element.masterProductId && !!element.priceComponents[i].sum"
              matTooltip="Aus Stammdaten übernommen"
              (click)="removeProductReferenceInRow(element)"
            >
              error
            </mat-icon>
            <mat-icon
              class="tooltip-icon"
              *ngIf="element.importedFromPriceInquiryRequestId && !!element.priceComponents[i].sum"
              matTooltip="Aus Preisanfrage übernommen"
              color="primary"
            >
              error
            </mat-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="sum">
        <th mat-header-cell *matHeaderCellDef class="width5 right mat-table-sticky" style="max-width: 76px" #cellWidth resizeColumn>
          Summe
        </th>
        <td mat-cell *matCellDef="let element" class="width5" [ngClass]="{ 'disabled-td': true }">
          <input
            matInput
            matInputDecimalPlaces
            [scale]="2"
            id="{{ element.rowIndex }}-sum"
            [ngModel]="element.sum ? element.sum : null"
            disabled
          />
        </td>
      </ng-container>

      <ng-container matColumnDef="sumAfterAdditions">
        <th mat-header-cell *matHeaderCellDef class="width5 right mat-table-sticky" resizeColumn #cellWidth>m. Zschl.</th>
        <td mat-cell *matCellDef="let element" class="width5" [ngClass]="{ 'disabled-td': true }">
          <input
            matInput
            matInputDecimalPlaces
            [scale]="2"
            id="{{ element.rowIndex }}-sumAfterAdditions"
            [ngModel]="element.sumAfterAdditions && !element.isInternal ? element.sumAfterAdditions : null"
            disabled
          />
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
        (contextmenu)="showHeaderContextMenu($event)"
        class="mat-header-row"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        paNestingColor
        [paNestingColor]="row.subPositionIdentifier"
        [nestingColorSettings]="colorSettings"
        (contextmenu)="showContextMenu($event, row)"
        (dblclick)="onDblClick($event, row)"
        (click)="setActiveRow($event, row)"
        (mouseup)="stopSelectRow($event, row.rowIndex)"
        [class]="'row-index' + row.rowIndex"
        [ngClass]="{
          'selected-row': selectedRow(row.rowIndex),
          'showed-row': showMoveSelectRow(row.rowIndex),
          'sub-position-row': row.subPositionIdentifier
        }"
        [style.fontSize.px]="userSettings?.calculationTableFontSize || 14"
      ></tr>
    </table>
  </cdk-virtual-scroll-viewport>
</div>
<mat-menu #rowContextMenu="matMenu" class="row-menu">
  <ng-template matMenuContent let-row="item">
    <pa-context-menu-gear-in-top [menuType]="currentContextMenuTable"></pa-context-menu-gear-in-top>
    <div mat-menu-item class="context-menu-calc" *ngIf="currentListContextMenuTable.saveOneRow && listSelectedRows.length <= 1">
      <button mat-button (click)="copyCurrentLine(row)" class="overlay-button">Zeile Kopieren</button>
    </div>
    <div mat-menu-item class="context-menu-calc" *ngIf="selectedText">
      <button mat-button [cdkCopyToClipboard]="selectedText" class="overlay-button">Selektion Kopieren</button>
    </div>
    <div mat-menu-item class="context-menu-calc" *ngIf="listSelectedRows.length > 1 && currentListContextMenuTable.saveRows">
      <button mat-button class="overlay-button" (click)="saveRows()">Ausgewählte Zeilen Kopieren</button>
    </div>
    <div mat-menu-item class="context-menu-calc" *ngIf="!listSelectedRows.length && currentListContextMenuTable.removeRow">
      <button mat-button color="warn" (click)="deleteRowPlus(row)" class="overlay-button">Löschen</button>
    </div>
    <div mat-menu-item class="context-menu-calc" *ngIf="listSelectedRows.length && currentListContextMenuTable.removeRow">
      <button mat-button color="warn" (click)="deleteSelectedRowsPlus(false)" class="overlay-button" *ngIf="listSelectedRows.length === 1">
        Zeile löschen
      </button>
      <button mat-button color="warn" (click)="deleteSelectedRowsPlus(false)" class="overlay-button" *ngIf="listSelectedRows.length > 1">
        {{ listSelectedRows.length }} Zeilen löschen
      </button>
    </div>
    <div mat-menu-item class="context-menu-calc" *ngIf="listSelectedRows.length && currentListContextMenuTable.cutRow">
      <button mat-button color="warn" (click)="cutSelectedRowsPlus()" class="overlay-button" *ngIf="listSelectedRows.length === 1">
        Zeile ausschneiden
      </button>
      <button mat-button color="warn" (click)="cutSelectedRowsPlus()" class="overlay-button" *ngIf="listSelectedRows.length > 1">
        {{ listSelectedRows.length }} Zeilen ausschneiden
      </button>
    </div>
    <div mat-menu-item class="context-menu-calc" *ngIf="!copyCalculationViewOpen && currentListContextMenuTable.copyCalculation">
      <button mat-button (click)="openDialogListFilter(row)" class="overlay-button" [disabled]="copyCalculationViewOpen">
        Kalkulation Kopieren
      </button>
    </div>
    <div mat-menu-item class="context-menu-calc" *ngIf="currentListContextMenuTable.addRowBefore">
      <button mat-button (click)="addRow(row, false)" class="overlay-button">Zeile davor hinzufügen</button>
    </div>
    <div mat-menu-item class="context-menu-calc" *ngIf="currentListContextMenuTable.addRowAfter">
      <button mat-button (click)="addRow(row, true)" class="overlay-button">Zeile danach hinzufügen</button>
    </div>
    <div mat-menu-item class="context-menu-calc" *ngIf="currentListContextMenuTable.addMaterials">
      <button mat-button class="overlay-button" (click)="openModalToAddItem(row, priceComponentType.Materials)">Artikel</button>
    </div>
    <div mat-menu-item class="context-menu-calc" *ngIf="currentListContextMenuTable.addEquipment">
      <button mat-button class="overlay-button" (click)="openModalToAddItem(row, priceComponentType.Equipment)">Gerät</button>
    </div>
    <div mat-menu-item class="context-menu-calc" *ngIf="currentListContextMenuTable.addCasing">
      <button mat-button class="overlay-button" (click)="openModalToAddItem(row, priceComponentType.Casing)">Schalung</button>
    </div>

    <div fxLayoutAlign="start center" class="btn-fontStyle" *ngIf="savedSelectedRows.length && currentListContextMenuTable.addSavedRow">
      <div [matBadge]="savedSelectedRows.length" class="btn-selected-rows">Zeilen</div>
      <mat-button-toggle-group>
        <mat-button-toggle (click)="copySavedRows(null, row)">Einfügen</mat-button-toggle>
        <mat-button-toggle (click)="copySavedRows(null, row, true)">Überschreiben</mat-button-toggle>
        <mat-button-toggle (click)="saveRows(true)">Vergessen</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </ng-template>
</mat-menu>

<div
  style="position: fixed; visibility: hidden"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="rowContextMenu"
></div>

<mat-menu #rowHeaderContextMenu="matMenu">
  <ng-template matMenuContent>
    <div mat-menu-item class="context-menu-calc">
      <button mat-button (click)="editListColumns()" class="overlay-button">Spalten Verwalten</button>
    </div>
    <div mat-menu-item class="context-menu-calc">
      <button mat-button (click)="limitWidth(true)" class="overlay-button">Spalten gleich aufteilen</button>
    </div>
    <div mat-menu-item class="context-menu-calc">
      <button mat-button (click)="limitWidth()" class="overlay-button">Spalten an Inhalt anpassen</button>
    </div>
  </ng-template>
</mat-menu>

<div
  style="position: fixed; visibility: hidden"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="rowHeaderContextMenu"
></div>
