import { DOCUMENT, NgIf } from '@angular/common';
import { Component, OnInit, Inject, AfterViewInit, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { first } from 'rxjs';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';

import { ErrorReportsClient } from '../../../generated-client/generated-client';
import { AvaNotificationsService } from '../../services/ava-notifications.service';
import { ElectronService } from '../../services/electron/electron.service';
import { ErrorLoggingService } from '../../services/error-logging.service';

@Component({
  selector: 'pa-error-report',
  templateUrl: './error-report.component.html',
  styleUrls: ['./error-report.component.scss'],
  standalone: true,
  imports: [FlexLayoutDirective, MatDialogContent, FormsModule, NgIf, MatFormField, MatLabel, MatInput, MatCheckbox, MatButton]
})
export class ErrorReportComponent implements OnInit, AfterViewInit {
  constructor(
    @Optional() private dialogRef: MatDialogRef<ErrorReportComponent>,
    private _sanitizer: DomSanitizer,
    private errorReportsClient: ErrorReportsClient,
    private notificationsService: AvaNotificationsService,
    private errorLoggingService: ErrorLoggingService,
    private electronService: ElectronService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) private pngImageBase64: string
  ) {}

  base64Image: string;
  base64ImageUrl: SafeHtml;
  errorDescription: string;
  attachImage = true;

  ngOnInit(): void {
    this.base64Image = this.pngImageBase64;
    this.base64ImageUrl = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + this.base64Image);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.document.getElementById('error-input')?.focus();
    }, 10);
  }

  sendReport(): void {
    this.electronService
      .getLatestAppLogMessages()
      .pipe(first())
      .subscribe((logMessages) => {
        let errorLog: string;
        try {
          errorLog = JSON.stringify(
            {
              console: logMessages,
              requests: this.errorLoggingService.getErrorLog()
            },
            null,
            2
          );
        } catch {
          /* Ignoring serialization issues here */
        }

        this.errorReportsClient
          .createErrorReport({
            base64PngImage: this.attachImage ? this.base64Image : null,
            description: this.errorDescription,
            applicationUrl: window.location.href,
            errorLog: errorLog
          })
          .subscribe(
            () => {
              this.notificationsService.success('Der Fehlerbericht wurde gesendet');
              this.dialogRef.close();
            },
            (error) => {
              console.error(error);
              this.notificationsService.error('Fehler beim Erstellen des Berichts');
            }
          );
      });
  }

  dismiss(): void {
    this.dialogRef.close();
  }
}
