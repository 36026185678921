import { BimFileGet, IfcStructure } from '../../../generated-client/generated-client';
import { ReplaySubject, Subject } from 'rxjs';

import { BimStateType } from '../models';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BimStateService {
  private showedElementsSource = new ReplaySubject<number[] | null>(1);
  showedElements = this.showedElementsSource.asObservable();

  private _state: BimStateType = {} as BimStateType;
  private onResetSource = new Subject<void>();
  onReset = this.onResetSource.asObservable();

  state = new ReplaySubject<BimStateType>(1);

  private _selectedElement = new ReplaySubject<number | null>(1);
  selectedElement = this._selectedElement.asObservable();

  constructor() {
    this.reset();
    this.setShowedElements(null);
  }

  reset(): void {
    this.nullState();
    this.onResetSource.next();
  }

  nullState(): void {
    this._state = {
      jsonLoaded: false,
      wexbimLoaded: false,
      isStructure: false,
      viewerModelId: null,
      selectedElementId: null,
      structure: null,
      bimFile: null
    };
    this.state.next(this._state);
  }

  setJsonLoaded(): void {
    this._state = { ...this._state, jsonLoaded: true };
    this.state.next(this._state);
  }

  setWexbimLoaded(): void {
    this._state = { ...this._state, wexbimLoaded: true };
    this.state.next(this._state);
  }

  setSelectedElement(selectedElementId: number | null): void {
    this._state = { ...this._state, selectedElementId };
    this.state.next(this._state);
    this._selectedElement.next(selectedElementId);
  }

  setViewerModelId(viewerModelId: number | null): void {
    this._state = { ...this._state, viewerModelId };
    this.state.next(this._state);
  }

  setIsStructure(isStructure: boolean): void {
    this._state = { ...this._state, isStructure };
    this.state.next(this._state);
  }

  setStructure(structure: IfcStructure): void {
    this._state = { ...this._state, structure };
    this.state.next(this._state);
  }

  setBimFile(bimFile: BimFileGet): void {
    this._state = { ...this._state, bimFile };
    this.state.next(this._state);
  }

  getIfcTypeById(structure: IfcStructure, elementId: number | null): string {
    if (!elementId || !structure) {
      return '';
    }
    const ifcType = Object.keys(structure.entityIdsByIfcType).find((key) =>
      structure.entityIdsByIfcType[key].find((id) => id === elementId)
    );
    return ifcType ? ifcType : '';
  }

  setShowedElements(listIds: number[] | null): void {
    this.showedElementsSource.next(listIds);
  }
}
