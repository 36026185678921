<pa-file-manager
  [fileElements]="fileElements | async"
  [path]="currentPath"
  [canNavigateUp]="canNavigateUp"
  [currentRoot]="currentRoot"
  (folderAdded)="addFolder($event)"
  (elementRemoved)="removeElement($event)"
  (navigatedDown)="navigateToFolder($event)"
  (navigatedUp)="navigateUp()"
  (elementRenamed)="renameElement($event)"
>
</pa-file-manager>
