import { IfcStructure } from '../../../generated-client/generated-client';
import { Injectable } from '@angular/core';
import { QuantityAttribute } from '../models/index';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuantityAttributeService {
  private _quantityAttributeList = new ReplaySubject<QuantityAttribute[]>(1);
  quantityAttributeList = this._quantityAttributeList.asObservable();
  private _quantity = new ReplaySubject<number | null>(1);
  // quantity = this._quantity.asObservable();
  constructor() {}

  setQuantityAttribute(structure: IfcStructure, ids: number[], propertySetName: string, propertyName: string, factor: number): void {
    let propertyValueById: QuantityAttribute[] = [];

    if (!propertyName) {
      this._quantityAttributeList.next([]);
      return;
    }

    if (ids) {
      ids.forEach((id) => {
        if (structure.propertySetIdsByEntityId[id]) {
          const list = structure.propertySetIdsByEntityId[id]
            .filter((propertySet) => structure.propertySetsById[propertySet].propertySet === propertySetName)
            .map((propertySet) => {
              const quantityAttribute = structure.propertySetsById[propertySet].properties.find((prop) => prop.property === propertyName);
              if (quantityAttribute && !isNaN(+quantityAttribute.value)) {
                return {
                  entityId: id,
                  quantity: +quantityAttribute.value
                };
              }
              return {
                entityId: id,
                quantity: null
              };
            })
            .filter((val) => Object.entries(val).length !== 0);
          if (list.length) {
            propertyValueById.push(...list);
          }
        }
      });
      if (factor) {
        propertyValueById = propertyValueById.map((v) => {
          if (v.quantity) {
            v.quantity *= factor;
            return v;
          } else return v;
        });
      }
      this._quantityAttributeList.next(propertyValueById);
    }
  }
}
