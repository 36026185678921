<h2>Rechnungsdatum</h2>
<p>Hier kann eingetragen werden, wann die Abrechnung in Rechnung gestellt wurde.</p>
<form [formGroup]="newPageForm" (submit)="createPage()">
  <mat-form-field appearance="fill">
    <mat-label>Rechnungsdatum</mat-label>
    <input matInput [matDatepicker]="pickerStart" formControlName="billedAtUtc" />
    <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
    <mat-datepicker #pickerStart [disabled]="false"></mat-datepicker>
  </mat-form-field>
  <div fxLayoutGap="12px">
    <button mat-raised-button [disabled]="!newPageForm.valid">Erstellen</button>
    <button mat-raised-button color="warn" type="button" (click)="cancel()">Abbrechen</button>
  </div>
</form>
