import { Injectable } from '@angular/core';

import { NestingColorSettings } from '@shared/models';

@Injectable({
  providedIn: 'root'
})
export class SubPositionNestingLevelService {
  constructor() {}

  getNestingLevel(str: string, listColors: NestingColorSettings): number {
    if (!str) {
      return 0;
    }

    const nestingLevel = str.replace(/\.+$/, '').split('.').length;
    const count = Object.keys(listColors).length;
    return nestingLevel <= count ? nestingLevel : (nestingLevel % (count + 1)) + 1;
  }
}
