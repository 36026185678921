<div mat-dialog-content>
  <h1>Bauteilcodes</h1>
  <div class="list">
    <pa-building-element-codes-list (selected)="selecting($event)"></pa-building-element-codes-list>
  </div>
  <div class="buttons">
    <button mat-button mat-raised-button color="warn" type="button" mat-dialog-close>Abbrechen</button>
    <button mat-raised-button color="primary" type="button" [mat-dialog-close]="null">Löschen</button>
  </div>
</div>
