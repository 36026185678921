import {
  BuildingElementCodesClient,
  InvoicePeriodCodesClient,
  InvoiceRecipientCodesClient,
  PageQuantityTakeOffGet,
  PageQuantityTakeOffPropertiesModel,
  PageQuantityTakeOffsClient,
  QuantityTakeOffCalculationType,
  QuantityTakeOffGet,
  QuantityTakeOffType,
  QuantityTakeOffsClient,
  ServicePeriodCodesClient
} from 'app/generated-client/generated-client';
import { Injectable, inject } from '@angular/core';
import { Observable, map, of, switchMap } from 'rxjs';

import { AvaNotificationsService } from '../../../../../../../shared/services/ava-notifications.service';
import { BuildingElementCodesNewModalComponent } from '@serv-spec/components/invoice/components/building-element-codes-new-modal/building-element-codes-new-modal.component';
import { ConfirmationType } from '@shared/models/dialog-config.model';
import { ModalService } from '@shared/services/modal.service';
import { QuantityTakeOffsService } from '../../../../../../../shared/services/lightquery/quantity-take-offs.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateInvoicePageService {
  private pageQuantityTakeOffsClient = inject(PageQuantityTakeOffsClient);
  private quantityTakeOffsClient = inject(QuantityTakeOffsClient);
  private buildingElementCodesClient = inject(BuildingElementCodesClient);
  private servicePeriodCodesClient = inject(ServicePeriodCodesClient);
  private modalService = inject(ModalService);
  private quantityTakeOffsService = inject(QuantityTakeOffsService);
  private avaNotificationsService = inject(AvaNotificationsService);
  private invoicePeriodCodesClient = inject(InvoicePeriodCodesClient);
  private invoiceRecipientCodesClient = inject(InvoiceRecipientCodesClient);

  private numberOfElements = 500;
  constructor() {}

  updateInvoicePage(
    projectId: string,
    avaProjectId: string,
    value: string,
    page: PageQuantityTakeOffGet,
    propertyName: string
  ): Observable<PageQuantityTakeOffGet | QuantityTakeOffGet | null> {
    const propertiesModel: PageQuantityTakeOffPropertiesModel = {
      name: page?.name || null,
      pageNumber: page?.pageNumber || null,
      buildingElementCodeId: page?.buildingElementCodeId || null,
      servicePeriodCodeId: page?.servicePeriodCodeId || null,
      invoicePeriodCodeId: page?.invoicePeriodCodeId || null,
      invoiceRecipientCodeId: page?.invoiceRecipientCodeId || null,
      subContractorId: page?.subContractorId || null
    };

    if (propertyName === 'quantityTakeOffName') {
      return this.quantityTakeOffsClient.setNameForQuantityTakeOff(projectId, avaProjectId, page.quantityTakeOffId, { name: value });
    }

    if (propertyName === 'quantityTakeOffNumber') {
      return this.updateQuantityTakeOffNumber(value, projectId, avaProjectId, page);
    }

    if (propertyName === 'servicePeriodCode') {
      return this.updateCodeProperty(
        value,
        propertyName,
        propertiesModel,
        projectId,
        avaProjectId,
        page,
        'Neuer LZ',
        this.servicePeriodCodesClient.getAllServicePeriodCodes.bind(this.servicePeriodCodesClient),
        this.servicePeriodCodesClient.createServicePeriodCode.bind(this.servicePeriodCodesClient)
      );
    }

    if (propertyName === 'buildingElementCode') {
      return this.updateCodeProperty(
        value,
        propertyName,
        propertiesModel,
        projectId,
        avaProjectId,
        page,
        'Neuer BT-Code',
        this.buildingElementCodesClient.getAllBuildingElementCodes.bind(this.buildingElementCodesClient),
        this.buildingElementCodesClient.createBuildingElementCode.bind(this.buildingElementCodesClient)
      );
    }

    if (propertyName === 'invoicePeriodCode') {
      return this.updateCodeProperty(
        value,
        propertyName,
        propertiesModel,
        projectId,
        avaProjectId,
        page,
        'Neuer AZ',
        this.invoicePeriodCodesClient.getAllInvoicePeriodCodes.bind(this.invoicePeriodCodesClient),
        this.invoicePeriodCodesClient.createInvoicePeriodCode.bind(this.invoicePeriodCodesClient)
      );
    }

    if (propertyName === 'invoiceRecipientCode') {
      return this.updateCodeProperty(
        value,
        propertyName,
        propertiesModel,
        projectId,
        avaProjectId,
        page,
        'Neuer RE',
        this.invoiceRecipientCodesClient.getAllInvoiceRecipientCodes.bind(this.invoiceRecipientCodesClient),
        this.invoiceRecipientCodesClient.createInvoiceRecipientCode.bind(this.invoiceRecipientCodesClient)
      );
    }

    propertiesModel[propertyName] = value;
    return this.pageQuantityTakeOffsClient.updatePageQuantityTakeOffProperties(projectId, avaProjectId, page.id, propertiesModel);
  }

  private updateQuantityTakeOffNumber(
    value: string,
    projectId: string,
    avaProjectId: string,
    page: PageQuantityTakeOffGet
  ): Observable<PageQuantityTakeOffGet | null> {
    if ((value == null || value.length === 0) && page.quantityTakeOffId != null) {
      // This means we're deleting the current value
      return this.pageQuantityTakeOffsClient.movePageQuantityTakeOff(projectId, avaProjectId, page.id, {
        targetParentId: null
      });
    }

    return this.quantityTakeOffsService
      .getAll({
        quantityTakeOffType: QuantityTakeOffType.Invoice, // At the moment, this is only supported for invoices, so we're not offering any others
        calculationType: QuantityTakeOffCalculationType.ByPage
      })
      .pipe(
        switchMap((quantityTakeOffs) => {
          if (quantityTakeOffs.length) {
            const matchingQto = quantityTakeOffs.find((qto) => qto.number === +value);
            if (matchingQto) {
              return this.pageQuantityTakeOffsClient.movePageQuantityTakeOff(projectId, avaProjectId, page.id, {
                targetParentId: matchingQto.id
              });
            }

            this.avaNotificationsService.info('Es gibt keine Abrechnung mit der Nummer ' + value);
            return of(null);
          }
        })
      );
  }

  private updateCodeProperty(
    value: string,
    propertyName: string,
    propertiesModel: PageQuantityTakeOffPropertiesModel,
    projectId: string,
    avaProjectId: string,
    page: PageQuantityTakeOffGet,
    headingForNewElement: string,
    getAllFunction: (
      projectId: string,
      filter: string | null | undefined,
      sort: string | undefined,
      thenSort: string | undefined,
      pageSize: number | undefined,
      page: number | undefined
    ) => Observable<any>,
    createMethod: (project: string, data: any) => Observable<any>
  ): Observable<PageQuantityTakeOffGet | null> {
    if ((value == null || value.length === 0) && propertiesModel[`${propertyName}Id`] != null) {
      propertiesModel[`${propertyName}Id`] = null;
      return this.pageQuantityTakeOffsClient.updatePageQuantityTakeOffProperties(projectId, avaProjectId, page.id, propertiesModel);
    }

    return getAllFunction(projectId, value, undefined, undefined, this.numberOfElements, undefined).pipe(
      map((r) => r.data),
      switchMap((codes) => {
        if (codes.length) {
          const matchingCode = codes.find((code) => code.name === value);
          if (matchingCode) {
            propertiesModel[`${propertyName}Id`] = matchingCode.id;
            return this.pageQuantityTakeOffsClient.updatePageQuantityTakeOffProperties(projectId, avaProjectId, page.id, propertiesModel);
          }
        }

        return this.modalService
          .openModal(BuildingElementCodesNewModalComponent, {
            dialogType: ConfirmationType.General,
            data: {
              content: { name: value },
              heading: headingForNewElement
            }
          })
          .afterClosed()
          .pipe(
            switchMap((modalData) => {
              if (modalData) {
                return createMethod(projectId, { ...modalData, projectId: projectId }).pipe(
                  switchMap((createdCode) => {
                    propertiesModel[`${propertyName}Id`] = createdCode.id;
                    return this.pageQuantityTakeOffsClient.updatePageQuantityTakeOffProperties(
                      projectId,
                      avaProjectId,
                      page.id,
                      propertiesModel
                    );
                  })
                );
              } else {
                return of(null);
              }
            })
          );
      })
    );
  }
}
