export enum TreeViewCommandType {
  ChangeFixedPrice = 'FixedPrice',
  ChangeSelectedElement = 'ChangeSelectedElement',
  SelectElementByDblClick = 'SelectElementByDblClick',
  SelectSubPosition = 'SelectSubPosition',
  ChangeGroupeView = 'ChangeGroupeView',
  ChangePosition = 'ChangePosition',
  ChangeSelectRow = 'ChangeSelectRow',
  ChangeQTOTotal = 'ChangeQTOTotal',
  GetQTO = 'GetQTO',
  ChangeTreeTotals = 'ChangeTreeTotals',
  Stop = 'Stop',
  Clear = 'Clear',
  Start = 'Start',
  Empty = 'Empty',
  UpdateSubPosition = 'UpdateSubPosition',
  Closed = 'Closed',
  OpenCopyElementView = 'OpenCopyElementView'
}
