import { NgFor } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

import { BehaviorSubject, Subject, Subscription, first, takeUntil } from 'rxjs';

import { IElementDto } from '../../../../generated-client/generated-client';
import { UiPositionType } from '../../../../shared/models/ui-position-type';
import { SinglePositionTypePipe } from '../../../../shared/pipes/single-position-type.pipe';
import { UiPositionTypePipe } from '../../../../shared/pipes/ui-data-display/ui-position-type.pipe';
import { PositionTypeChangeService } from '../../services/position-type-change.service';

@Component({
  selector: 'pa-position-type',
  templateUrl: './position-type.component.html',
  styleUrls: ['./position-type.component.scss'],
  standalone: true,
  imports: [MatSelect, FormsModule, NgFor, MatOption, UiPositionTypePipe]
})
export class PositionTypeComponent implements OnInit, OnDestroy {
  private _element: IElementDto;
  private _currentPositionPipeSubscription: Subscription;

  @Input() set element(value: IElementDto) {
    this._element = value;
    if (this._currentPositionPipeSubscription) {
      this._currentPositionPipeSubscription.unsubscribe();
    }

    if (value == null) {
      return;
    }

    if (this._element.elementType === 'NoteTextDto') {
      this.positionTypes = [UiPositionType.NoteText, UiPositionType.Regular];
    } else {
      this.positionTypes = Object.keys(UiPositionType)
        .map((key) => UiPositionType[key])
        .filter((v) => v !== UiPositionType.Unknown);
    }

    this.setPositionTypeSubscription();
  }

  get element(): IElementDto {
    return this._element;
  }

  selectedPositionType: UiPositionType;

  positionTypes: UiPositionType[] = [];
  positionType$ = new BehaviorSubject<UiPositionType>(UiPositionType.Unknown);
  private destroy$ = new Subject<void>();

  constructor(
    private singlePositionTypePipe: SinglePositionTypePipe,
    private positionTypeChangeService: PositionTypeChangeService
  ) {}

  private setPositionTypeSubscription(): void {
    if (this._currentPositionPipeSubscription) {
      this._currentPositionPipeSubscription.unsubscribe();
    }

    this._currentPositionPipeSubscription = this.singlePositionTypePipe
      .transform(this.element)
      .pipe(takeUntil(this.destroy$))
      .subscribe((positionType) => {
        this.positionType$.next(positionType);
      });
  }

  ngOnInit(): void {
    this.positionType$.pipe(takeUntil(this.destroy$)).subscribe((pt) => {
      this.selectedPositionType = pt;
    });
  }

  ngOnDestroy(): void {
    if (this._currentPositionPipeSubscription) {
      this._currentPositionPipeSubscription.unsubscribe();
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  changeType(type: UiPositionType): void {
    this.singlePositionTypePipe
      .transform(this.element)
      .pipe(first())
      .subscribe((oldType) => {
        this.positionTypeChangeService
          .tryChangePositionType({
            currentType: oldType,
            newType: type,
            element: this.element
          })
          .subscribe((hasUpdated) => {
            if (!hasUpdated) {
              // We want to ensure that non-async operations
              // are applied correctly
              setTimeout(() => {
                this.positionType$.next(oldType);
              }, 0);
            }
          });
      });
  }
}
