import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';

@Component({
  selector: 'pa-folder-modal-to-remove',
  templateUrl: './folder-modal-to-remove.component.html',
  styleUrls: ['./folder-modal-to-remove.component.scss'],
  standalone: true,
  imports: [MatDialogContent, MatDialogActions, MatButton, MatDialogClose]
})
export class FolderModalToRemoveComponent {}
