import { NgIf, NgFor } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatAnchor } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { RouterLinkActive, RouterLink } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { getAppConfig } from 'app/app-config-accessor';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

import { SelectedProjectMessengerService } from '../../../shared/services/messengers/selected-project-messenger.service';

interface MenuItem {
  label: string;
  link: string;
  icon?: string;
  children?: MenuItem[];
  isOpen?: boolean;
  queryParams?: { [key: string]: string };
}

@Component({
  selector: 'pa-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  standalone: true,
  imports: [NgIf, MatAnchor, RouterLinkActive, RouterLink, MatIcon, MatDivider, NgFor]
})
export class SideNavComponent implements OnInit, OnDestroy {
  projectId;
  serviceSpecificationId;
  isQtoOnlyMode = getAppConfig().isQtoOnlyMode;
  $destroy: Subject<boolean> = new Subject<boolean>();
  openSubMenu: string | null = null;

  menuItems: MenuItem[] = this.isQtoOnlyMode
    ? [
        {
          label: 'Abrechnung',
          link: 'invoices',
          icon: 'euro_symbol',
          isOpen: false
        },
        { label: 'LV-Tabelle', link: 'quick-edit', icon: 'table_chart', isOpen: false }
      ]
    : [
        { label: 'Kalkulation', link: 'calculation', icon: 'calculate', isOpen: false },
        {
          label: 'Abrechnung',
          link: 'invoices',
          icon: 'euro_symbol',
          isOpen: false,
          children: [
            { label: 'Aufmaßblattverzeichnis', link: 'all-pages', queryParams: { ignoreLoadingLastQtoLocation: 'true' } },
            { label: 'Mengenvergleich', link: 'sheets', queryParams: { ignoreLoadingLastQtoLocation: 'true' } },
            { label: 'Aufmaßblatt Drucken', link: 'print-pages', queryParams: { ignoreLoadingLastQtoLocation: 'true' } },
            { label: 'Summenblatt Drucken', link: 'print-sum-pages', queryParams: { ignoreLoadingLastQtoLocation: 'true' } },
            { label: 'Rechnung Drucken', link: 'print-invoices', queryParams: { ignoreLoadingLastQtoLocation: 'true' } },
            {
              label: 'Suchtabelle',
              link: 'search-table',
              queryParams: { ignoreLoadingLastQtoLocation: 'true' }
            }
          ]
        },
        { label: 'Einstellungen', link: 'settings', icon: 'show_chart', isOpen: false },
        { label: 'LV-Editor', link: 'lv-editor', icon: 'playlist_add', isOpen: false },
        { label: 'LV-Tabelle', link: 'quick-edit', icon: 'table_chart', isOpen: false },
        { label: 'BIM', link: 'bim-list', icon: 'store_mall_directory', isOpen: false }
      ];

  constructor(
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService
  ) {}

  ngOnInit(): void {
    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((data) => {
      this.projectId = data ? data.id : null;
    });

    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(takeUntil(this.$destroy))
      .subscribe((selectedServiceSpecification) => {
        this.serviceSpecificationId = selectedServiceSpecification?.avaProjectId;
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  toggleSubMenu(item?: MenuItem, forceOpen?: boolean): void {
    if (item) {
      item.isOpen = forceOpen || !item.isOpen;
    }
  }
}
