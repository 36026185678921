<div mat-dialog-title fxLayoutAlign="space-between top">
  <h2>Kalkulationsprüfung</h2>
  <mat-icon color="warn" class="close-icon" (click)="dialogRef.close()">close</mat-icon>
</div>
<mat-dialog-content>
  <mat-checkbox [(ngModel)]="showChecksWithoutErrors">Prüfungen ohne Fehler anzeigen</mat-checkbox>
  <div class="wrapper">
    <div class="spinner" *ngIf="!checkResult || !hasFilteredResult; else content">
      <div fxFlex fxLayoutAlign="center center">
        <mat-spinner></mat-spinner>
      </div>
    </div>
    <ng-template #content>
      <div
        fxFlex
        fxLayoutAlign="center center"
        *ngIf="
          checkResult && !checkResult.positionIdsWithMissingCalculation.length && !checkResult.elementIdsWithMissingTextAdditions.length;
          else missingList
        "
      >
        <mat-icon color="primary" class="ok-message-icon">check_circle</mat-icon>
        <span class="message">Alles ist ok!</span>
      </div>
      <ng-template #missingList>
        <ng-container *ngIf="showChecksWithoutErrors || missingCalculationsPosition?.length">
          <pa-selection-list-with-number-and-text
            [listData]="missingCalculationsPosition"
            (selectedPosition)="selectedMissingPosition($event)"
          >
            <h3 class="warn-color">Positionen ohne Kalkulation</h3>
          </pa-selection-list-with-number-and-text>
        </ng-container>

        <ng-container *ngIf="showChecksWithoutErrors || missingTextAdditionsPosition?.length">
          <pa-selection-list-with-number-and-text
            [listData]="missingTextAdditionsPosition"
            (selectedPosition)="selectedMissingPosition($event)"
          >
            <h3 class="warn-color">Positionen ohne Textergänzung</h3>
          </pa-selection-list-with-number-and-text>
        </ng-container>

        <ng-container *ngIf="showChecksWithoutErrors || negativePricePosition?.length">
          <pa-selection-list-with-number-and-text [listData]="negativePricePosition" (selectedPosition)="selectedMissingPosition($event)">
            <h3 class="warn-color">Positionen mit negativem Preis</h3>
          </pa-selection-list-with-number-and-text>
        </ng-container>

        <ng-container *ngIf="showChecksWithoutErrors || zeroPricePosition?.length">
          <pa-selection-list-with-number-and-text [listData]="zeroPricePosition" (selectedPosition)="selectedMissingPosition($event)">
            <h3 class="warn-color">Positionen mit Nullpreis</h3>
          </pa-selection-list-with-number-and-text>
        </ng-container>

        <ng-container *ngIf="showChecksWithoutErrors || missingUnitTagPositions?.length">
          <pa-selection-list-with-number-and-text [listData]="missingUnitTagPositions" (selectedPosition)="selectedMissingPosition($event)">
            <h3 class="warn-color">Positionen ohne Einheit</h3>
          </pa-selection-list-with-number-and-text>
        </ng-container>

        <ng-container *ngIf="showChecksWithoutErrors || zeroQuantityPositions?.length">
          <pa-selection-list-with-number-and-text [listData]="zeroQuantityPositions" (selectedPosition)="selectedMissingPosition($event)">
            <h3 class="warn-color">Positionen mit Nullmenge</h3>
          </pa-selection-list-with-number-and-text>
        </ng-container>

        <ng-container *ngIf="showChecksWithoutErrors || negativeQuantityPositions?.length">
          <pa-selection-list-with-number-and-text
            [listData]="negativeQuantityPositions"
            (selectedPosition)="selectedMissingPosition($event)"
          >
            <h3 class="warn-color">Positionen mit negativer Menge</h3>
          </pa-selection-list-with-number-and-text>
        </ng-container>

        <ng-container *ngIf="showChecksWithoutErrors || positionsWithErrorsInCalculation?.length">
          <pa-selection-list-with-number-and-text
            [listData]="positionsWithErrorsInCalculation"
            (selectedPosition)="selectedMissingPosition($event)"
          >
            <h3 class="warn-color">Ansätze mit Fehlern</h3>
          </pa-selection-list-with-number-and-text>
        </ng-container>
      </ng-template>
    </ng-template>
  </div>
</mat-dialog-content>
