import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';

import { first, take } from 'rxjs/operators';

import { PersonsClient, PersonGet, PersonPost, ContactGet } from 'app/generated-client/generated-client';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { PersonsService } from 'app/shared/services/lightquery/persons.service';

import { SelectedContactMessengerService } from '../../services/selected-contact-messenger.service';
import { SelectedPersonMessengerService } from '../../services/selected-person-messenger.service';

@Component({
  selector: 'pa-persons-form',
  templateUrl: './persons-form.component.html',
  styleUrls: ['./persons-form.component.scss'],
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, FormsModule, MatInputModule, MatButtonModule, ReactiveFormsModule]
})
export class PersonsFormComponent implements OnInit {
  person: PersonGet | PersonPost;
  private contactId: string;
  personForm: FormGroup;
  requestEnRoute = false;

  constructor(
    private notificationsService: AvaNotificationsService,
    private personsService: PersonsService,
    private personsClient: PersonsClient,
    private router: Router,
    private selectedContactMessengerService: SelectedContactMessengerService,
    private selectedPersonMessengerService: SelectedPersonMessengerService,
    @Inject(MAT_DIALOG_DATA) private data: string,
    @Optional() private matDialogRef: MatDialogRef<PersonsFormComponent>,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.personForm = this.fb.group({
      name: [''],
      notes: ['']
    });
    this.getPersonData();
  }

  private getPersonData(): void {
    this.selectedContactMessengerService.selectedContact.pipe(take(1)).subscribe((contact: ContactGet) => {
      this.contactId = contact.id;
      if (this.data) {
        const personId = this.data;
        this.selectPerson(personId);
        this.selectedPersonMessengerService.selectedPerson.pipe(first()).subscribe((person: PersonGet) => {
          this.personForm.patchValue({
            ...person
          });
          this.person = person;
        });
      } else {
        this.person = {
          name: null,
          notes: null
        };
      }
    });
  }

  private selectPerson(personId: string): void {
    const person = this.personsService.getPerson(personId);
    if (person) {
      this.selectedPersonMessengerService.setSelectedPerson(person);
      return;
    }
    if (!person) {
      this.personsClient.getPersonById(personId).subscribe((p: PersonGet) => this.selectedPersonMessengerService.setSelectedPerson(p));
    }
  }

  editPerson(): void {
    this.requestEnRoute = true;
    if (this.person['id']) {
      this.personsClient
        .editPerson(this.person['id'], {
          id: this.person['id'],
          ...this.personForm.value
        })
        .subscribe({
          next: () => {
            this.personsService.forceRefresh();
            this.matDialogRef.close();
          },
          error: () => {
            this.notificationsService.error('Fehler beim aktualisieren des Kontakts.');
            this.matDialogRef.close();
          }
        });
    } else {
      this.personsClient.createPersonForContact(this.contactId, this.personForm.value).subscribe({
        next: () => {
          this.personsService.forceRefresh();
          this.matDialogRef.close();
        },
        error: () => {
          this.notificationsService.error('Fehler beim Erstellen des Kontakts.');
          this.matDialogRef.close();
        }
      });
    }
  }

  dismiss(): void {
    this.matDialogRef.close();
  }
}
