import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

import { Observable, Subject, filter, takeUntil } from 'rxjs';

import {
  CanalQuantityTakeOffExportOptionsPost,
  CanalQuantityTakeOffsClient
} from '../../../../../../../../generated-client/generated-client';
import { AvaNotificationsService } from '../../../../../../../../shared/services/ava-notifications.service';
import { FileSaverService } from '../../../../../../../../shared/services/file-saver.service';
import { SelectedProjectMessengerService } from '../../../../../../../../shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationMessengerService } from '../../../../../../../../shared/services/messengers/selected-specification-messenger.service';
import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-canal-export-modal',
  templateUrl: './canal-export-modal.component.html',
  styleUrls: ['./canal-export-modal.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatButton, NgIf, FlexLayoutDirective]
})
export class CanalExportModalComponent implements OnInit, OnDestroy {
  private $destroy: Subject<boolean> = new Subject<boolean>();
  private projectId: string;
  private avaProjectId: string;
  requestEnRoute = false;
  canalExportForm: FormGroup;
  selectedFileName: string;
  private selectedFile: File;
  private x51Base64File: string;

  constructor(
    private canalQuantityTakeOffsClient: CanalQuantityTakeOffsClient,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    @Optional() private dialogRef: MatDialogRef<CanalExportModalComponent>,
    private fb: FormBuilder,
    private avaNotificationService: AvaNotificationsService,
    private fileSaverService: FileSaverService
  ) {}

  ngOnInit(): void {
    this.canalExportForm = this.fb.group({
      invoiceNumber: [''],
      invoiceName: [''],
      orderName: [''],
      emptyCanalSegments: [''],
      x51File: ['']
    });

    this.selectedProjectMessengerService.selectedProject
      .pipe(
        takeUntil(this.$destroy),
        filter((p) => !!p)
      )
      .subscribe((project) => {
        this.projectId = project.id;
      });
    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        takeUntil(this.$destroy),
        filter((p) => !!p)
      )
      .subscribe((avaProject) => {
        this.avaProjectId = avaProject.avaProjectId;
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  setFile(addedFiles: File[]): void {
    if (addedFiles?.length) {
      const restoreFile = addedFiles[0];
      this.selectedFileName = restoreFile.name;
      this.selectedFile = restoreFile;
      this.convertFileToBase64String(this.selectedFile).subscribe((base64File) => {
        this.x51Base64File = base64File;
      });
    }
  }

  dismiss(): void {
    this.dialogRef.close();
  }

  createCanalExport(): void {
    this.requestEnRoute = true;

    const options: CanalQuantityTakeOffExportOptionsPost = this.canalExportForm.value;
    if (this.x51Base64File) {
      options.x51FileBase64 = this.x51Base64File;
    }

    this.canalQuantityTakeOffsClient.exportAvaProjectAsCanalQuantityExcel(this.projectId, this.avaProjectId, options).subscribe({
      next: (r) => {
        this.requestEnRoute = false;
        this.avaNotificationService.success('Exceldatei erfolgreich erstellt');
        this.fileSaverService.saveAs(r);
      },
      error: () => {
        this.requestEnRoute = false;
        this.avaNotificationService.error('Fehler beim Erstellen der Exceldatei');
      }
    });
  }

  private convertFileToBase64String(file: File): Observable<string> {
    const subject = new Subject<string>();
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const base64Data = (reader.result as string).split(',')[1];
      subject.next(base64Data);
      subject.complete();
    };
    reader.onerror = function (error) {
      subject.error(error);
    };

    return subject;
  }
}
