import { NgIf } from '@angular/common';
import { Component, OnDestroy, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCard, MatCardHeader, MatCardTitle, MatCardContent } from '@angular/material/card';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatFormField, MatLabel, MatPrefix, MatError } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '@dangl/angular-dangl-identity-client';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutDirective,
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatCardContent,
    FormsModule,
    MatFormField,
    MatLabel,
    MatPrefix,
    MatIcon,
    MatInput,
    NgIf,
    MatError,
    MatCheckbox,
    MatButton
  ]
})
export class LoginComponent implements OnDestroy, OnInit, AfterViewInit {
  @ViewChild('loginInput') loginInput: ElementRef;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  showLoginError = false;
  errorCount = 0;
  credentials = {
    identifier: null,
    password: null
  };
  requestEnRoute: boolean;
  activeLocale: string;
  savePassword = false;

  ngOnInit(): void {
    this.credentials.identifier = this.getStoredUserIdentifier();
    this.credentials.password = this.getStoredUserPassword();
    if (this.credentials.password) {
      this.savePassword = true;
    }
  }

  ngAfterViewInit(): void {
    //Otherwise Angular throws error: Expression has changed after it was checked.
    setTimeout(() => {
      this.loginInput.nativeElement.focus();
    });
  }

  ngOnDestroy(): void {
    if (this.credentials) {
      this.credentials.identifier = null;
      this.credentials.password = null;
    }
  }

  login(): void {
    this.requestEnRoute = true;

    const identifier = this.credentials.identifier;
    const password = this.credentials.password;

    this.authenticationService.loginWithToken(identifier, password).subscribe((r) => {
      this.requestEnRoute = false;
      if (r) {
        this.showLoginError = false;
        this.errorCount = 0;
        this.storeUserIdentifier(identifier);
        if (this.savePassword) {
          this.storeUserPassword(password);
        }
        this.router.navigateByUrl('/');
      } else {
        this.showLoginError = true;
        this.errorCount++;
      }
    });
  }

  resetErrorDisplay(): void {
    this.showLoginError = false;
  }

  private readonly userIdentifierStorageIdentifier = 'LOGIN_COMPONENT_LAST_USER_IDENTIFIER';

  private getStoredUserIdentifier(): string | null {
    return localStorage.getItem(this.userIdentifierStorageIdentifier) || null;
  }

  private storeUserIdentifier(identifier: string): void {
    localStorage.setItem(this.userIdentifierStorageIdentifier, identifier);
  }

  private readonly userPasswordStorageIdentifier = 'LOGIN_COMPONENT_LAST_USER_PASSWORD';

  private getStoredUserPassword(): string | null {
    return localStorage.getItem(this.userPasswordStorageIdentifier) || null;
  }

  private storeUserPassword(password: string): void {
    localStorage.setItem(this.userPasswordStorageIdentifier, password);
  }
}
