import { NgIf, NgFor, DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSelect } from '@angular/material/select';

import {
  AvaProjectPriceComponentAdditionGet,
  MasterAdditionGroupDetailGet,
  MasterPriceComponentAdditionGet,
  PriceComponentType
} from 'app/generated-client/generated-client';

import { PercentageInputFieldComponent } from '../../../../shared/components/percentage-input-field/percentage-input-field.component';
import { PriceComponentTypePipe } from '../../../../shared/pipes/ui-data-display/price-component-type.pipe';
import { FlexLayoutDirective } from '../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-master-addition-group',
  templateUrl: './master-addition-group.component.html',
  styleUrls: ['./master-addition-group.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    FlexLayoutDirective,
    MatProgressSpinner,
    MatFormField,
    MatLabel,
    MatInput,
    MatCheckbox,
    PercentageInputFieldComponent,
    MatSuffix,
    MatButton,
    MatIcon,
    NgFor,
    MatSelect,
    MatOption,
    DecimalPipe,
    PriceComponentTypePipe
  ]
})
export class MasterAdditionGroupComponent {
  @Input() isEditing: boolean;
  @Input() isLoading: boolean;
  @Output() recalculateTransferWage = new EventEmitter<void>();

  priceComponentTypes = Object.keys(PriceComponentType) as PriceComponentType[];

  private _selectedAdditionGroup: MasterAdditionGroupDetailGet;
  get selectedAdditionGroup(): MasterAdditionGroupDetailGet {
    return this._selectedAdditionGroup;
  }
  @Input() set selectedAdditionGroup(group: MasterAdditionGroupDetailGet) {
    if (group) {
      this._selectedAdditionGroup = group;
    }
  }

  constructor() {}

  removePriceComponent(component: MasterPriceComponentAdditionGet | AvaProjectPriceComponentAdditionGet): void {
    const componentIndex = this.selectedAdditionGroup.masterPriceComponentAdditions.findIndex(
      (pc: MasterPriceComponentAdditionGet) => pc === component
    );
    if (componentIndex > -1) {
      this.selectedAdditionGroup.masterPriceComponentAdditions.splice(componentIndex, 1);
      let newIndex = 0;
      this.selectedAdditionGroup.masterPriceComponentAdditions.forEach((pc: MasterPriceComponentAdditionGet) => (pc.index = newIndex++));
    }
  }

  addPriceComponent(): void {
    let newIndex =
      Math.max(...this.selectedAdditionGroup.masterPriceComponentAdditions.map((pc: MasterPriceComponentAdditionGet) => pc.index)) + 1;
    if (!(this.selectedAdditionGroup.masterPriceComponentAdditions?.length > 0)) {
      newIndex = 1;
    }
    this.selectedAdditionGroup.masterPriceComponentAdditions.push({
      companyOperationCostsAddition: null,
      priceComponent: null,
      masterAdditionGroupId: this.selectedAdditionGroup.id,
      siteOperationCostsAddition: null,
      index: newIndex
    });
  }

  updateSelectedAdditionGroup(newValue: any, updatedValue: string, index?: number): void {
    switch (updatedValue) {
      case 'riskAndProfitAddition':
        this.selectedAdditionGroup.riskAndProfitAddition = newValue;
        this.recalculateTransferWage.emit();
        break;
      case 'laborSiteOperationCostsAddition':
        this.selectedAdditionGroup.laborSiteOperationCostsAddition = newValue;
        this.recalculateTransferWage.emit();
        break;
      case 'laborCompanyOperationCostsAddition':
        this.selectedAdditionGroup.laborCompanyOperationCostsAddition = newValue;
        this.recalculateTransferWage.emit();
        break;
      case 'siteOperationCostsAddition':
        this.selectedAdditionGroup.masterPriceComponentAdditions[index - 1].siteOperationCostsAddition = newValue;
        break;
      case 'companyOperationCostsAddition':
        this.selectedAdditionGroup.masterPriceComponentAdditions[index - 1].companyOperationCostsAddition = newValue;
        break;
      default:
        return;
    }
  }
}
