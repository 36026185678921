<div mat-dialog-content>
  <div class="wrapper">
    <div class="filter-field">
      <mat-form-field class="filter-field__input">
        <mat-label>Suche</mat-label>
        <input matInput [ngModel]="filter" (ngModelChange)="contactsService.onFilter($event)" />
      </mat-form-field>
    </div>

    <div class="mat-elevation-z8">
      <div class="wrapper-table">
        <table mat-table [dataSource]="contactsService" matSort (matSortChange)="contactsService.onSort($event)">
          <ng-container matColumnDef="contactNumber">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let element">{{ element.contactNumber }}</td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="table-project-contact__tr"
            (click)="setSelectedContact(row)"
          ></tr>
        </table>
      </div>
      <pa-upper-paginator
        [pageSizeOptions]="[5, 10, 25, 100]"
        [upperPaginated]="contactsService.paginationResult | async"
        [upperService]="contactsService"
        (page)="contactsService.onPage($event)"
      >
      </pa-upper-paginator>
    </div>
  </div>

  <div *ngIf="selectedContact">
    <h2>{{ selectedContact.name }}</h2>
    <mat-checkbox [(ngModel)]="updateAvaProjectsBuyer">Alle LVs aktualisieren</mat-checkbox>
    <br />
    <small
      >Wenn diese Option aktiviert ist, werden die Kontaktdaten des Auftraggebers für alle Leistungsverzeichnisse im Projekt ersetzt.
      Wichtig z.B beim GAEB Export.</small
    >
  </div>
</div>

<mat-dialog-actions>
  <button mat-raised-button color="warn" type="button" mat-dialog-close>Abbrechen</button>
  <button
    *ngIf="selectedContact"
    mat-raised-button
    color="primary"
    [mat-dialog-close]="{ contactId: selectedContact.id, updateAvaProjectsBuyer: updateAvaProjectsBuyer }"
  >
    Speichern
  </button>
</mat-dialog-actions>
