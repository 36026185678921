import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';

import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';

import { ReportsClient } from '../../../../../../generated-client/generated-client';
import { PrintViewMessengerService } from '../../../../../../shared/services/electron/print-view-messenger.service';
import { SelectedSpecificationMessengerService } from '../../../../../../shared/services/messengers/selected-specification-messenger.service';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-calculation-products-export',
  templateUrl: './calculation-products-export.component.html',
  styleUrls: ['./calculation-products-export.component.scss'],
  standalone: true,
  imports: [NgIf, FlexLayoutDirective, MatCheckbox, FormsModule, MatButton, MatProgressSpinner]
})
export class CalculationProductsExportComponent implements OnInit, OnDestroy {
  showingPdf = false;
  waitingForPdf = false;
  requestEnRoute = false;
  serviceSpecificationId: string;
  projectId: string;
  includePricesInPdfExport = false;
  includePositionsWithoutTotal = false;
  includeHeaderOnlyOnFirstPage = false;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private printViewMessengerService: PrintViewMessengerService,
    private reportsClient: ReportsClient,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private avaNotificationsService: AvaNotificationsService
  ) {}

  ngOnInit(): void {
    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        takeUntil(this.$destroy),
        filter((s) => !!s)
      )
      .subscribe((s) => {
        this.serviceSpecificationId = s.avaProjectId;
        this.projectId = s.parentProjectId;
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  generatePdfAndShowPrintView(): void {
    this.waitingForPdf = true;
    this.requestEnRoute = true;

    this.reportsClient
      .getProjectProductsReport(this.projectId, this.serviceSpecificationId, true, this.includeHeaderOnlyOnFirstPage, {
        avaProjectId: this.serviceSpecificationId,
        projectId: this.projectId,
        excludePrices: !this.includePricesInPdfExport,
        includePositionsWithoutTotalPrice: this.includePositionsWithoutTotal
      })
      .subscribe({
        next: (report) => {
          this.printViewMessengerService.showPdfPreview(
            report.htmlReport,
            'Kalkulation',
            report.isLandscape,
            report.pdfReportBase64,
            this.includeHeaderOnlyOnFirstPage
          );
          this.printViewMessengerService.waitForPrintViewReady().subscribe(() => {
            this.showingPdf = true;
            this.waitingForPdf = false;
            this.requestEnRoute = false;
          });
          this.printViewMessengerService.printViewClosed.pipe(first()).subscribe(() => {
            this.showingPdf = false;
            this.waitingForPdf = false;
            this.requestEnRoute = false;
          });
        },
        error: () => {
          this.showingPdf = false;
          this.waitingForPdf = false;
          this.requestEnRoute = false;
          this.avaNotificationsService.error('Fehler beim Erstellen der PDF-Datei');
        }
      });
  }
}
