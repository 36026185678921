<mat-menu #rowContextMenu="matMenu">
  <ng-template matMenuContent let-node="item">
    <ng-container *ngIf="isCopyCalculation">
      <div mat-menu-item class="context-menu-element">
        <button mat-button (click)="startSourceCopy(node)">
          {{ node.elementType === 'ServiceSpecificationGroupDto' ? 'Gruppe' : 'Position' }} kopieren (Kalkulation)
        </button>
      </div>
    </ng-container>
    <ng-container>
      <div mat-menu-item class="context-menu-element" *ngIf="isCopyElementView">
        <button mat-button (click)="startCopyElement(node)">{{ node.elementType | elementTypeClass }} kopieren</button>
      </div>
    </ng-container>
    <ng-container *ngIf="modePageService.modePage | async as modePage">
      <ng-container *ngIf="modePage === 'lv-editor'">
        <h3>Neues Element</h3>
        <div mat-menu-item class="context-menu-element">
          <button mat-button (click)="addElement(node, 'end')">Am Ende</button>
        </div>
        <div mat-menu-item class="context-menu-element" *ngIf="node">
          <button mat-button (click)="addElement(node, 'before')">Vor</button>
        </div>
        <div mat-menu-item class="context-menu-element" *ngIf="node">
          <button mat-button (click)="addElement(node, 'after')">Nach</button>
        </div>
        <div mat-menu-item class="context-menu-element" *ngIf="node && node.elementType === 'ServiceSpecificationGroupDto'">
          <button mat-button (click)="addElement(node, 'endGroup')">Ende der Gruppe</button>
        </div>
        <div mat-menu-item class="context-menu-element" *ngIf="treeCopyElementService.treeElementForCopy | async as treeElementForCopy">
          <button mat-button (click)="insertElementCopy(node, treeElementForCopy)">Element Einfügen</button>
        </div>
        <div
          mat-menu-item
          class="context-menu-element"
          [disabled]="showedList.includes(windowTypes.CopyElement) || (copyElementViewVisible$ | async) === true"
        >
          <button mat-button (click)="openCopyElementView()">Elementkopieransicht öffnen</button>
        </div>
      </ng-container>
      <ng-container *ngIf="modePage === 'calculation' && !isCopyCalculation">
        <div mat-menu-item class="context-menu-element" *ngIf="node && node.elementType">
          <button mat-button (click)="deleteElementContext(node)">Element Entfernen</button>
        </div>
        <div mat-menu-item class="context-menu-element" *ngIf="treeCopyElementService.treeElementForCopy | async as treeElementForCopy">
          <button mat-button (click)="insertElementCopy(node, treeElementForCopy)">Element Einfügen</button>
        </div>
        <div mat-menu-item class="context-menu-element" *ngIf="node.elementType === 'PositionDto' && valueForCopy?.position">
          <button mat-button (click)="copyPositionToNode(node)">
            Aus Position [{{ valueForCopy?.position?.itemNumber?.stringRepresentation }}] Kopieren
          </button>
        </div>
        <div mat-menu-item class="context-menu-element" *ngIf="node.elementType === 'PositionDto' && valueForCopy?.position">
          <button mat-button (click)="clearValueForCopy()">
            Position [{{ valueForCopy?.position?.itemNumber?.stringRepresentation }}] zum Kopieren vergessen
          </button>
        </div>
        <ng-container *ngIf="positionListForCopy?.sourcePositionIds?.length">
          <div mat-menu-item class="context-menu-element">
            <button mat-button (click)="copyPositionList(node)">
              Positionen als Elemente kopieren ({{ positionListForCopy?.sourcePositionIds?.length }})
            </button>
          </div>
          <div mat-menu-item class="context-menu-element">
            <button mat-button (click)="copyCalculationList(node)">
              Positionskalkulationen kopieren ({{ positionListForCopy?.sourcePositionIds?.length }})
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="node.elementType === 'ServiceSpecificationGroupDto'">
          <div mat-menu-item class="context-menu-element" *ngIf="selectedGroupForCopy?.id !== node.id">
            <button mat-button (click)="startCopyGroup(node)">Kalkulationen aus Gruppe kopieren (ohne Dialog)</button>
          </div>
          <div mat-menu-item class="context-menu-element" *ngIf="selectedGroupForCopy?.id !== node.id">
            <button mat-button (click)="startCopyGroup(node, true)">Kalkulationen aus Gruppe kopieren (mit Dialog)</button>
          </div>
          <div mat-menu-item class="context-menu-element" *ngIf="selectedGroupForCopy?.id === node.id">
            <button mat-button (click)="delMarkCopyGroup()">Kalkulationskopie abbrechen</button>
          </div>
          <div mat-menu-item class="context-menu-element" *ngIf="valueForCopy?.group">
            <button mat-button (click)="copyGroupToNode(node, valueForCopy.project)">
              Aus Gruppe [{{ valueForCopy?.group?.itemNumber?.stringRepresentation }}] Kopieren
            </button>
          </div>
          <div mat-menu-item class="context-menu-element" *ngIf="valueForCopy?.group">
            <button mat-button (click)="clearValueForCopy()">
              Gruppe [{{ valueForCopy?.group?.itemNumber?.stringRepresentation }}] zum Kopieren vergessen
            </button>
          </div>
        </ng-container>
        <div
          mat-menu-item
          class="context-menu-element"
          [disabled]="showedList.includes(windowTypes.CopyElement) || (copyElementViewVisible$ | async) === true"
        >
          <button mat-button (click)="openCopyElementView()">Elementkopieransicht öffnen</button>
        </div>
      </ng-container>
      <ng-container *ngIf="!isCopyCalculation && !isCopyElementView">
        <ng-container *ngIf="!(markedElements$ | async)[node.id]">
          <div mat-menu-item class="context-menu-element">
            <button mat-button (click)="addElementAsMarked(node.id)">Element markieren</button>
          </div>
        </ng-container>
        <ng-container *ngIf="(markedElements$ | async)[node.id]">
          <div mat-menu-item class="context-menu-element">
            <button mat-button (click)="removeElementAsMarked(node.id)">Markierung aufheben</button>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-template>
</mat-menu>

<div
  style="position: fixed; visibility: hidden"
  [matMenuTriggerFor]="rowContextMenu"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
></div>
