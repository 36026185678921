import { NgFor, AsyncPipe, KeyValuePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatList, MatListItem } from '@angular/material/list';

import { Observable, Subject, map, takeUntil } from 'rxjs';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';

import { IfcStructure } from '../../../../generated-client/generated-client';
import { IfcElementTypePipe } from '../../pipes/ifc-element-type.pipe';
import { BimModelFilterService } from '../../services/bim-model-filter.service';
import { BimViewService } from '../../services/bim-view.service';

@Component({
  selector: 'pa-element-types-bim-view',
  templateUrl: './element-types-bim-view.component.html',
  styleUrls: ['./element-types-bim-view.component.scss'],
  standalone: true,
  imports: [MatList, MatListItem, NgFor, AsyncPipe, KeyValuePipe, IfcElementTypePipe, FlexLayoutDirective]
})
export class ElementTypesBimViewComponent implements OnInit, OnDestroy {
  selectedElementTypes: string[] = [];
  entityIdsByIfcType$: Observable<{ [key: string]: number[] }>;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private bimViewService: BimViewService,
    private bimModelFilterService: BimModelFilterService
  ) {}

  ngOnInit(): void {
    this.entityIdsByIfcType$ = this.bimViewService.structure.pipe(
      takeUntil(this.$destroy),
      map((structure: IfcStructure) => structure.entityIdsByIfcType)
    );
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  selectElement(elementName?: string, event?: MouseEvent): void {
    if (elementName && event?.ctrlKey && this.selectedElementTypes.includes(elementName)) {
      this.selectedElementTypes = this.selectedElementTypes.filter((itemType) => itemType !== elementName);
    } else if (elementName) {
      this.selectedElementTypes = event?.ctrlKey ? [...this.selectedElementTypes, elementName] : [elementName];
    } else {
      this.selectedElementTypes = [];
    }

    this.bimModelFilterService.setSelectedElementTypes(this.selectedElementTypes);
  }
}
