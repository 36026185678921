import { NgIf } from '@angular/common';
import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogClose } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { Subject, takeUntil } from 'rxjs';

import { AvaNotificationsService } from '@shared/services/ava-notifications.service';
import { PrintViewMessengerService } from '@shared/services/electron/print-view-messenger.service';
import { FileSaverService } from '@shared/services/file-saver.service';

import {
  ElementSelection,
  QuantityTakeOffPagesRebExportOptionsPost,
  QuantityTakeOffRebExportResultGet,
  QuantityTakeOffsRebExportClient,
  RebExportType,
  ReportsClient
} from 'app/generated-client/generated-client';

import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-invoice-export-address-modal',
  templateUrl: './invoice-export-address-modal.component.html',
  styleUrls: ['./invoice-export-address-modal.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    NgIf,
    FlexLayoutDirective,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatCheckbox,
    MatButton,
    MatDialogClose,
    MatProgressSpinner
  ]
})
export class InvoiceExportAddressModalComponent implements OnInit, OnDestroy {
  startAddress: string | null = null;
  includePreviousSums = false;
  isResponse = false;
  isLoading = false;
  isChanged = true;
  showingPdf = false;
  private rebResponse: QuantityTakeOffRebExportResultGet;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      projectId: string;
      avaProjectId: string;
      quantityTakeOffId?: string;
      pageExportOptions?: QuantityTakeOffPagesRebExportOptionsPost;
      pagesToPrint: string[] | null;
      elementSelection: ElementSelection;
      exportType: RebExportType;
    },
    private quantityTakeOffsRebExportClient: QuantityTakeOffsRebExportClient,
    private fileSaverService: FileSaverService,
    private avaNotificationsService: AvaNotificationsService,
    private reportsClient: ReportsClient,
    private printViewMessengerService: PrintViewMessengerService,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.printViewMessengerService.printViewClosed.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.ngZone.run(() => {
        this.showingPdf = false;
      });
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  saveFile(): void {
    this.fileSaverService.saveAsFromBase64(this.rebResponse.fileContentBase64, this.rebResponse.fileName);
  }

  saveReport(): void {
    this.isLoading = true;
    this.reportsClient.getRebExportReport(this.data.projectId, this.data.avaProjectId, this.rebResponse.rebExportReport).subscribe({
      next: (report) => {
        this.printViewMessengerService.showPdfPreview(report.htmlReport, 'REB Export', report.isLandscape, report.pdfReportBase64, false);
        this.printViewMessengerService.waitForPrintViewReady().subscribe(() => {
          this.isLoading = false;
          this.showingPdf = true;
        });
      },
      error: () => {
        this.isLoading = false;
        this.avaNotificationsService.error('Fehler beim Laden des Exportberichts');
      }
    });
  }

  change(): void {
    this.isLoading = true;

    if (!this.data.quantityTakeOffId && !this.data.pageExportOptions) {
      this.avaNotificationsService.error(`Fehler bei ${this.data.exportType} Erzeugung`);
      this.isLoading = false;
      return;
    }

    const request = this.data.quantityTakeOffId
      ? this.quantityTakeOffsRebExportClient.exportQuantityTakeOffToRebFile(
          this.data.projectId,
          this.data.avaProjectId,
          this.data.quantityTakeOffId,
          this.data.exportType,
          this.data.pagesToPrint,
          this.startAddress || null,
          {
            elementSelection: this.data.elementSelection
          }
        )
      : this.quantityTakeOffsRebExportClient.exportQuantityTakeOffPagesToRebFile(
          this.data.projectId,
          this.data.avaProjectId,
          this.data.exportType,
          this.startAddress || null,
          this.data.pageExportOptions
        );

    request.subscribe({
      next: (r: QuantityTakeOffRebExportResultGet) => {
        this.isResponse = true;
        this.isLoading = false;
        this.isChanged = false;
        this.rebResponse = r;
      },
      error: () => {
        this.isLoading = false;
        this.isResponse = false;
        this.avaNotificationsService.error(`Fehler bei ${this.data.exportType} Erzeugung`);
      }
    });
  }
}
