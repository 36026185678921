<div mat-dialog-content>
  <h1>LV Export</h1>
  <mat-tab-group id="export-tabs" dynamicHeight>
    <mat-tab label="Neuer Export">
      <ng-template matTabContent>
        <pa-create-service-specification-export (exportFinished)="closeModal($event)" [serviceSpecification]="avaProject">
        </pa-create-service-specification-export>
      </ng-template>
    </mat-tab>
    <mat-tab label="Drucken & PDF">
      <ng-template matTabContent>
        <pa-create-service-specification-pdf (exportFinished)="closeModal($event)" [serviceSpecification]="avaProject">
        </pa-create-service-specification-pdf>
      </ng-template>
    </mat-tab>
    <mat-tab label="Vorherige Exporte">
      <ng-template matTabContent>
        <pa-service-specification-exports-list></pa-service-specification-exports-list>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
