import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { MatTabGroup, MatTab } from '@angular/material/tabs';

import { BimFileGet } from '../../../../generated-client/generated-client';

import { ElementTypesBimViewComponent } from '../element-types-bim-view/element-types-bim-view.component';
import { ManagedAssignElementQuantityComponent } from '../filtering-bim-view/components/managed-assign-element-quantity/managed-assign-element-quantity.component';
import { QuantityAttributeSelectionTableComponent } from '../filtering-bim-view/components/quantity-attribute-selection-table/quantity-attribute-selection-table.component';
import { QuantityAttributeSelectionComponent } from '../filtering-bim-view/components/quantity-attribute-selection/quantity-attribute-selection.component';
import { ShowAssignedElementQuantityComponent } from '../filtering-bim-view/components/show-assigned-element-quantity/show-assigned-element-quantity.component';
import { FilteringBimViewComponent } from '../filtering-bim-view/filtering-bim-view.component';
import { StructureBimViewComponent } from '../structure-bim-view/structure-bim-view.component';

@Component({
  selector: 'pa-left-side-bim-view',
  templateUrl: './left-side-bim-view.component.html',
  styleUrls: ['./left-side-bim-view.component.scss'],
  standalone: true,
  imports: [
    MatTabGroup,
    MatTab,
    ElementTypesBimViewComponent,
    StructureBimViewComponent,
    FilteringBimViewComponent,
    QuantityAttributeSelectionComponent,
    ShowAssignedElementQuantityComponent,
    QuantityAttributeSelectionTableComponent,
    ManagedAssignElementQuantityComponent
  ]
})
export class LeftSideBimViewComponent {
  @Input() set leftSideAreaSize(size: number) {
    window.dispatchEvent(new Event('resize'));
  }
  @Input() bimFile: BimFileGet;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  @HostListener('document:keydown', ['$event'])
  keyboardHandler(event: KeyboardEvent) {
    if (event.key === 'ArrowRight') {
      event.preventDefault();
      this.selectNextTab();
    } else if (event.key === 'ArrowLeft') {
      event.preventDefault();
      this.selectPreviousTab();
    }
  }
  constructor() {}

  selectNextTab() {
    const selectedIndex = this.tabGroup.selectedIndex;
    const tabCount = this.tabGroup._tabs.length;
    // Switch to the next tab
    this.tabGroup.selectedIndex = (selectedIndex + 1) % tabCount;
  }

  selectPreviousTab() {
    const selectedIndex = this.tabGroup.selectedIndex;
    const tabCount = this.tabGroup._tabs.length;
    // Switch to the previous tab
    this.tabGroup.selectedIndex = (selectedIndex - 1 + tabCount) % tabCount;
  }
}
