<table mat-table [dataSource]="selectionElementService" matSort (matSortChange)="selectionElementService.onSort($event)">
  <ng-container matColumnDef="name">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
    <td mat-cell matRipple *matCellDef="let row">
      {{ row.name }}
    </td>
  </ng-container>
  <ng-container matColumnDef="createdAtUtc">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Erstellt</th>
    <td mat-cell matRipple *matCellDef="let row">
      {{ row.createdAtUtc | date: 'dd.MM.yyyy HH:mm' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef class="action"></th>
    <td mat-cell *matCellDef="let row" class="action">
      <div fxLayoutGap="10px" fxLayout>
        <button mat-icon-button color="primary" (click)="editSelection($event, row)">
          <mat-icon color="primary">edit</mat-icon>
        </button>
        <button mat-icon-button color="warn" (click)="delSelection($event, row)">
          <mat-icon color="warn">close</mat-icon>
        </button>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columnToDisplay"></tr>
  <tr mat-row *matRowDef="let row; columns: columnToDisplay" (click)="chooseItem(row)"></tr>
</table>
<pa-upper-paginator
  [pageSizeOptions]="[5, 10, 25, 100]"
  [upperPaginated]="selectionElementService.paginationResult | async"
  [upperService]="selectionElementService"
  (page)="selectionElementService.onPage($event)"
>
</pa-upper-paginator>
