import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';

import { MasterAdditionGroupGet, MasterAdditionGroupsClient } from '../../../generated-client/generated-client';
import { MasterDataAdditionGroupsService } from './master-data-addition-groups.service';
import { MasterDataSelectedGroupService } from './master-data-selected-group.service';

@Injectable({
  providedIn: 'root'
})
export class MasterDataReloadGroupsService {
  constructor(
    private masterAdditionGroupsClient: MasterAdditionGroupsClient,
    private masterDataAdditionGroupsService: MasterDataAdditionGroupsService,
    private masterDataSelectedGroupService: MasterDataSelectedGroupService
  ) {}

  public reload(): void {
    this.masterAdditionGroupsClient
      .getAllMasterAdditionGroups()
      .subscribe((groups: MasterAdditionGroupGet[]) => this.masterDataAdditionGroupsService.setAdditionGroup(groups));
  }

  public reloadGroups(selectedId?: string): void {
    this.masterAdditionGroupsClient
      .getAllMasterAdditionGroups()
      .pipe(
        switchMap((groups: MasterAdditionGroupGet[]) => {
          this.masterDataAdditionGroupsService.setAdditionGroup(groups);
          return this.masterDataAdditionGroupsService.additionGroup;
        })
      )
      .subscribe((additionGroup: MasterAdditionGroupGet[]) => {
        if (additionGroup && additionGroup.length > 0) {
          if (selectedId) {
            const groupIndex = additionGroup.findIndex((g: MasterAdditionGroupGet) => g.id === selectedId);
            if (groupIndex > -1) {
              this.masterDataSelectedGroupService.setSelectedGroup(additionGroup[groupIndex]);
            }
          } else {
            this.masterDataSelectedGroupService.setSelectedGroup(additionGroup[0]);
          }
        }
      });
  }
}
