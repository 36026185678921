import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Subject, combineLatest, switchMap, take, takeUntil } from 'rxjs';

import { BimModelFilterService } from '../../../../services/bim-model-filter.service';
import { BimStateService } from 'app/areas/bim-view/services/bim-state.service';
import { BimStructureFilterService } from 'app/areas/bim-view/services/bim-structure-filter.service';
import { BimViewService } from 'app/areas/bim-view/services/bim-view.service';
import { CommonModule } from '@angular/common';
import { FILTER_VALUE_BY_DEFAULT } from '../../../../constants/filter-value-by-default';
import { FilterSelectionComponent } from '../filter-selection/filter-selection.component';
import { IFilterType } from '../../../../interface/filter-type.interface';
import { IfcStructure } from '../../../../../../generated-client/generated-client';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { QuantityAttributeService } from 'app/areas/bim-view/services/quantity-attribute.service';

@Component({
  selector: 'pa-quantity-attribute-selection',
  templateUrl: './quantity-attribute-selection.component.html',
  styleUrls: ['./quantity-attribute-selection.component.scss'],
  standalone: true,
  imports: [CommonModule, FilterSelectionComponent, ReactiveFormsModule, MatFormFieldModule, MatInputModule]
})
export class QuantityAttributeSelectionComponent implements OnInit, OnDestroy {
  propertySetName: IFilterType[] = [FILTER_VALUE_BY_DEFAULT];
  propertyName: IFilterType[] = [FILTER_VALUE_BY_DEFAULT];

  propertySetNameControl = new FormControl<string | null>(null);
  propertyNameControl = new FormControl<string | null>(null);
  factorControl = new FormControl<number | null>(null);
  structure: IfcStructure;

  private $destroy: Subject<boolean> = new Subject<boolean>();

  bimViewService = inject(BimViewService);
  bimStructureFilterService = inject(BimStructureFilterService);
  bimStateService = inject(BimStateService);
  quantityAttributeService = inject(QuantityAttributeService);
  bimModelFilterService = inject(BimModelFilterService);

  showedElementIds: number[] = [];

  constructor() {}

  ngOnInit(): void {
    combineLatest([this.bimModelFilterService.selectedElementTypes, this.bimViewService.structure])
      .pipe(takeUntil(this.$destroy))
      .subscribe(([selectedElementTypes, structure]) => {
        this.structure = structure;
        this.propertySetName = [
          FILTER_VALUE_BY_DEFAULT,
          ...this.bimStructureFilterService.getPropertySetName(structure, selectedElementTypes)
        ];
      });

    this.bimViewService.structure
      .pipe(
        take(1),
        switchMap(() => {
          return this.bimStateService.showedElements.pipe(takeUntil(this.$destroy));
        })
      )
      .subscribe((showedElements) => {
        this.showedElementIds = showedElements === null ? [...this.structure.entityIds] : [...showedElements];
        this.onSelectpropertyName();
      });

    this.factorControl.valueChanges.pipe(takeUntil(this.$destroy)).subscribe(() => {
      if (this.propertySetNameControl.value && this.propertyNameControl.value) {
        this.quantityAttributeService.setQuantityAttribute(
          this.structure,
          this.showedElementIds,
          this.propertySetNameControl.value,
          this.propertyNameControl.value,
          this.factorControl.value
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  onSelectPropertySetName(): void {
    this.propertyNameControl.reset();
    this.propertyName = [
      FILTER_VALUE_BY_DEFAULT,
      ...this.bimStructureFilterService.getPropertyName(this.structure, this.propertySetNameControl.value)
    ];
  }

  onSelectpropertyName(): void {
    this.quantityAttributeService.setQuantityAttribute(
      this.structure,
      this.showedElementIds,
      this.propertySetNameControl.value,
      this.propertyNameControl.value,
      this.factorControl.value
    );
  }
}
