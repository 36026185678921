<div [style.padding.px]="24">
  <div class="header-wrap">
    <h2>Markierte Elemente</h2>
    <mat-icon color="warn" class="close-icon" (click)="dialogRef.close()">close</mat-icon>
  </div>

  <mat-selection-list [multiple]="false">
    <mat-list-option color="primary" *ngFor="let position of listData" (click)="selectPosition(position)">
      <ng-container *ngIf="markedElements$ | async as markedElements">
        <div class="wrapper">
          <button mat-icon-button class="node-toggle-button" color="warn">
            <mat-icon class="mat-icon-rtl-mirror">flag</mat-icon>
          </button>
          <span
            *ngIf="
              position.elementTypeDiscriminator === 'ServiceSpecificationGroupDto' || position.elementTypeDiscriminator === 'PositionDto';
              else withOutItemNumber
            "
          >
            {{ $any(position)?.itemNumber?.stringRepresentation }} - {{ position | positionText }}
          </span>
          <ng-template #withOutItemNumber>
            {{ position | positionText }}
          </ng-template>
          <span>&nbsp;|&nbsp;</span>
          <span>{{ markedElements[position.id].markedByUserName }}</span>
          <span>&nbsp;|&nbsp;</span>
          <span>{{ markedElements[position.id].markedAtUtc | date: 'dd.MM.yyyy HH:mm' }}</span>
        </div>
      </ng-container>
    </mat-list-option>
  </mat-selection-list>
</div>
