<h1 mat-dialog-title>Modell Erstellen</h1>
<div mat-dialog-content>
  <div>
    <mat-form-field class="name">
      <mat-label>Name</mat-label>
      <input matInput [(ngModel)]="name" required />
    </mat-form-field>
  </div>
  <div class="field">
    <pa-upload-file [uploadFileSettings]="uploadFileSettings" (uploadFile)="onSelect($event)"></pa-upload-file>
  </div>
  <div class="file" *ngIf="selectedFile">
    Datei: <span>{{ selectedFile.fileName }}</span>
  </div>
  <div fxLayout="row" fxLayoutGap="12px">
    <button mat-raised-button color="primary" (click)="createModel()" [disabled]="!name || !selectedFile">Erstellen</button>
    <button mat-raised-button color="warn" (click)="dismiss()">Abbrechen</button>
  </div>
</div>
