<div class="company-wrap">
  <div class="filter-field">
    <mat-form-field class="filter-field__input">
      <mat-label>Suche</mat-label>
      <input matInput [ngModel]="filter" (ngModelChange)="companiesService.onFilter($event)" />
    </mat-form-field>
  </div>

  <button *ngIf="!isInProjectCreation" mat-raised-button type="button" color="primary" class="btn-add" (click)="addCompany()">
    Neue Firma
  </button>

  <div class="table-wrap">
    <table mat-table [dataSource]="companiesService" matSort (matSortChange)="companiesService.onSort($event)">
      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="street">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Straße</th>
        <td mat-cell *matCellDef="let element">{{ element.street }}</td>
      </ng-container>

      <ng-container matColumnDef="zipCode">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>PLZ</th>
        <td mat-cell *matCellDef="let element">{{ element.zipCode }}</td>
      </ng-container>

      <ng-container matColumnDef="city">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Ort</th>
        <td mat-cell *matCellDef="let element">{{ element.city }}</td>
      </ng-container>

      <ng-container matColumnDef="country">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Land</th>
        <td mat-cell *matCellDef="let element">{{ element.country }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <ng-container *ngIf="!isInProjectCreation">
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="master-table-tr" [routerLink]="['company-info', row.id]"></tr>
      </ng-container>
      <ng-container *ngIf="isInProjectCreation">
        <tr mat-row *matRowDef="let company; columns: displayedColumns" class="master-table-tr" (click)="selectCompany(company)"></tr>
      </ng-container>
    </table>
    <pa-upper-paginator
      [pageSizeOptions]="[5, 10, 25, 100]"
      [upperPaginated]="companiesService.paginationResult | async"
      [upperService]="companiesService"
      (page)="companiesService.onPage($event)"
    >
    </pa-upper-paginator>
  </div>
</div>
