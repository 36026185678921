<h2 mat-dialog-title>Preiskomponente</h2>
<div mat-dialog-content>
  <p>Auf welche Preisekomponente sollen die Preise übernommen werden?</p>
  <mat-spinner *ngIf="isLoading"></mat-spinner>
  <form [formGroup]="priceComponentForm" (submit)="selecting()" *ngIf="!isLoading && priceComponents?.length">
    <div fxLayoutGap="20px" fxLayout="row">
      <mat-form-field appearance="fill">
        <mat-select formControlName="priceComponent">
          <mat-option *ngFor="let priceComponent of priceComponents" [value]="priceComponent.priceComponent">
            {{ priceComponent.priceComponent }} ({{ priceComponent.priceComponentType | priceComponentType }})
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-radio-group class="radio-group" formControlName="appendType">
        <mat-radio-button value="NewSubPosition" color="primary">Als neue Unterposition anfügen</mat-radio-button>
        <mat-radio-button value="NewRow" color="primary">Als neue Zeile anfügen</mat-radio-button>
      </mat-radio-group>
    </div>
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Nachlass</mat-label>
        <input matInput type="number" formControlName="discountRate" />
        <span matSuffix>%</span>
      </mat-form-field>
    </div>
    <div>
      <mat-checkbox name="setExistingRowsInCalculationsToInternal" formControlName="setExistingRowsInCalculationsToInternal"
        >Alle bisherigen Kalkulationsansätze auf intern setzen</mat-checkbox
      >
    </div>
    <div fxLayoutGap="10px" fxLayout="row" class="buttons">
      <button mat-raised-button type="submit" color="primary">Speichern</button>
      <button mat-raised-button type="button" color="warn" (click)="cancel()">Abbrechen</button>
    </div>
  </form>
</div>
