import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll } from '@angular/cdk/scrolling';
import { NgIf, NgClass } from '@angular/common';
import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatMenuTrigger, MatMenu, MatMenuContent, MatMenuItem } from '@angular/material/menu';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';

import { Subject, Subscription, fromEvent, interval, of, take } from 'rxjs';
import { delayWhen, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { KeyupControlService } from '@serv-spec/services/keyup-control.service';
import { LvEditorService } from '@serv-spec/services/lv-editor.service';

import { getStorage, setStorage } from '@shared/utilities/storage';

import { CalculationFixedPriceService } from '@project/services/calculation-fixed-price.service';

import { getAppConfig } from 'app/app-config-accessor';
import { FlatElementsService } from 'app/areas/tree/services/flat-elements.service';
import { TreeNodeService } from 'app/areas/tree/services/tree-node.service';
import { TreeViewCommandType } from 'app/shared/models/tree-view-command.model';
import { TreeViewMessengerService } from 'app/shared/services/electron/tree-view-messenger.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { SpinnerOverlayService } from 'app/shared/services/spinner-overlay.service';

import { PositionComponent } from '../../../../../elements/components/position/position.component';
import { ServiceSpecificationGroupComponent } from '../../../../../elements/components/service-specification-group/service-specification-group.component';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';
import { ShowPositionAbbreviationPipe } from '../../../../../tree/pipes/show-position-abbreviation.pipe';
import { AllowNumericWithDecimalDirective } from '../../directives/allow-numeric-with-decimal.directive';
import { MatInputDecimalPlacesDirective } from '../../directives/mat-input-decimal-places.directive';

import {
  AvaProjectContentEditResultGet,
  ExecutionDescriptionDto,
  IElementDto,
  NoteTextDto,
  PositionCalculationsClient,
  PositionDto,
  ProjectDto,
  ServiceSpecificationGroupDto
} from './../../../../../../generated-client/generated-client';

import { ResizableDirective } from '../invoice/directives/resizable.directive';
import { TableVirtualScrollDataSource, TableVirtualScrollModule } from 'ng-table-virtual-scroll';

@Component({
  selector: 'pa-quick-edit-positions-table',
  templateUrl: './quick-edit-positions-table.component.html',
  styleUrls: ['./quick-edit-positions-table.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    NgIf,
    CdkVirtualScrollViewport,
    TableVirtualScrollModule,
    CdkFixedSizeVirtualScroll,
    MatTable,
    CdkDropList,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    CdkDrag,
    MatCellDef,
    MatCell,
    NgClass,
    MatIcon,
    MatTooltip,
    MatCheckbox,
    ResizableDirective,
    CdkDragHandle,
    FlexLayoutDirective,
    MatInputDecimalPlacesDirective,
    AllowNumericWithDecimalDirective,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatMenuTrigger,
    MatMenu,
    MatMenuContent,
    MatMenuItem,
    ServiceSpecificationGroupComponent,
    PositionComponent,
    ShowPositionAbbreviationPipe
  ]
})
export class QuickEditPositionsTableComponent implements OnInit, OnDestroy {
  @ViewChildren(MatInput) inputElements: QueryList<MatInput>;
  @ViewChild('searchInput') searchInput!: ElementRef;
  @HostListener('document:keydown', ['$event'])
  handlerKeyboardEvent(event: KeyboardEvent): void {
    switch (event.key) {
      case 'Insert': {
        if (this.checkIsActiveInput()) {
          return;
        }
        if (this.selectedElementForEdit) {
          this.preventKeyDownEvent(event, this.selectedElementForEdit);
        }
        break;
      }
    }
  }
  isQtoOnlyMode = getAppConfig().isQtoOnlyMode;
  //This is used to disable editing and only be able to change the selected position, we don't use it now.
  @Input() isEditMode = !this.isQtoOnlyMode;
  // this is used if the component opens in a detached window, we don't use it now.
  @Input() isInnerWindow = false;
  private $destroy: Subject<boolean> = new Subject<boolean>();
  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  windowClickSubscription: Subscription;
  menuSubscription: Subscription;
  contextMenuPosition = { x: 0, y: 0 };
  projectId: string;
  avaProjectId: string;
  isCursorOutsideText = true;
  dataSource = new TableVirtualScrollDataSource<ServiceSpecificationGroupDto | NoteTextDto | ExecutionDescriptionDto | PositionDto>([]);
  flatElements: (ServiceSpecificationGroupDto | NoteTextDto | ExecutionDescriptionDto | PositionDto)[] = [];
  selectedElementForEdit: IElementDto;
  selectedElementForChange: IElementDto;
  columnsToDisplay = [
    'icon',
    'priceCompositionRequired',
    'itemNumber',
    'shortText',
    'unitPrice',
    'quantity',
    'unitTag',
    'totalPrice',
    'deductionFactor'
  ];
  listOfItemsWithFixedPrice: { [positionId: string]: boolean } = {};
  get filter(): string {
    return this.dataSource.filter;
  }
  set filter(value: string) {
    this.dataSource.filter = value;
  }

  constructor(
    private lvEditorService: LvEditorService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private treeViewMessengerService: TreeViewMessengerService,
    private treeNodeService: TreeNodeService,
    private spinnerOverlayService: SpinnerOverlayService,
    private keyupControlService: KeyupControlService,
    private flatElementsService: FlatElementsService,
    private positionCalculationsClient: PositionCalculationsClient,
    private calculationFixedPriceService: CalculationFixedPriceService
  ) {}

  ngOnInit(): void {
    const orderOFcolumns: string[] = getStorage('Order_Of_QuickEditTable_Columns');
    if (Object.keys(orderOFcolumns).length !== 0) {
      this.columnsToDisplay = [...orderOFcolumns];
    }

    this.setUpDatasourceFilter();

    this.flatElementsService.flatElementsDto.pipe(takeUntil(this.$destroy)).subscribe((flatElementsDto) => {
      this.dataSource.data = flatElementsDto;
      console.log(this.dataSource.data);
      this.spinnerOverlayService.hide();
    });

    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        takeUntil(this.$destroy),
        filter((s) => !!s)
      )
      .subscribe((s: { avaProjectId: string; project: ProjectDto }) => {
        this.avaProjectId = s.project.id;
      });

    this.windowClickSubscription = fromEvent(window, 'click').subscribe(() => {
      if (this.contextMenu.menuOpen) {
        this.contextMenu.closeMenu();
      }
    });

    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((selectedProject) => {
      this.projectId = selectedProject.id;
    });

    this.selectedSpecificationElementMessengerService.selectedElement.pipe(takeUntil(this.$destroy)).subscribe((e) => {
      this.selectedElementForChange = e?.element;
    });

    this.calculationFixedPriceService.listOfItemsWithFixedPrice.pipe(takeUntil(this.$destroy)).subscribe((e) => {
      this.listOfItemsWithFixedPrice = e;
    });
  }

  private setUpDatasourceFilter(): void {
    this.dataSource.filterPredicate = (element, filter) => {
      filter = filter?.toLowerCase() ?? '';
      if (!filter) {
        return true;
      }

      switch (element.elementTypeDiscriminator) {
        case 'PositionDto': {
          const position = element as PositionDto;
          return (
            position?.shortText?.toLowerCase().indexOf(filter) > -1 ||
            position?.longText?.toLowerCase().indexOf(filter) > -1 ||
            position?.itemNumber?.stringRepresentation?.toLowerCase().indexOf(filter) > -1
          );
        }
        case 'NoteTextDto': {
          const noteText = element as NoteTextDto;
          return (
            noteText?.shortText?.toLowerCase().indexOf(filter) > -1 ||
            noteText?.longText?.toLowerCase().indexOf(filter) > -1 ||
            noteText?.identifier?.toLowerCase().indexOf(filter) > -1
          );
        }
        case 'ServiceSpecificationGroupDto': {
          const group = element as ServiceSpecificationGroupDto;
          return (
            group?.shortText?.toLowerCase().indexOf(filter) > -1 ||
            group?.itemNumber?.stringRepresentation?.toLowerCase().indexOf(filter) > -1
          );
        }
        case 'ExecutionDescriptionDto': {
          const execDescr = element as ExecutionDescriptionDto;
          return execDescr?.label?.toLowerCase().indexOf(filter) > -1 || execDescr?.identifier?.toLowerCase().indexOf(filter) > -1;
        }
      }

      return true;
    };
  }

  ngOnDestroy(): void {
    this.windowClickSubscription && this.windowClickSubscription.unsubscribe();
    this.menuSubscription && this.menuSubscription.unsubscribe();
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  saveChangedElement(element: PositionDto, field = ''): void {
    //this is necessary to exclude cases when entering,
    // for example, 1,25 or 1.25 we get 125,00
    if (field) {
      element[field] = element[field].toString().replace(',', '.');
    }
    if (element.elementType !== 'NoteTextDto' && element.elementType !== 'ExecutionDescriptionDto') {
      if (element.itemNumber.identifiers.join('.') !== element.itemNumber.stringRepresentation) {
        element.itemNumber.stringRepresentation = element.itemNumber.identifiers.join('.');
      }
    }
    if (this.isInnerWindow) {
      this.treeViewMessengerService.sendDataFromTreeView({
        command: TreeViewCommandType.ChangeSelectedElement,
        data: [element.id && element]
      });
    }
    this.lvEditorService.saveChangedElement(element);
  }

  selectedRowForEdit(element: IElementDto): void {
    if (this.selectedElementForEdit?.id !== element.id) {
      this.selectedElementForEdit = element;
    } else {
      this.selectedElementForEdit = null;
    }
  }

  onContextMenu(event: MouseEvent): void {
    event.preventDefault();
    this.menuSubscription && this.menuSubscription.unsubscribe();
    this.menuSubscription = of(1)
      .pipe(
        tap(() => {
          if (this.contextMenu.menuOpen) {
            this.contextMenu.closeMenu();
          }
          this.contextMenuPosition.x = event.clientX;
          this.contextMenuPosition.y = event.clientY;
        }),
        delayWhen(() => (this.contextMenu.menuOpen ? interval(200) : of(undefined))),
        tap(async () => {
          this.contextMenu.openMenu();
          let backdrop: HTMLElement = null;
          do {
            await this.delay(100);
            backdrop = document.querySelector(
              'div.cdk-overlay-backdrop.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing'
            ) as HTMLElement;
          } while (backdrop === null);
          backdrop.style.pointerEvents = 'none';
        })
      )
      .subscribe();
  }

  delay(delayInms: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, delayInms);
    });
  }

  quickAddPostion(): void {
    const selectedElement = this.selectedElementForEdit;
    this.treeNodeService.addNode(selectedElement).subscribe((editResult) => {
      if (editResult) {
        this.selectedRowForEdit(this.dataSource.data.find((el) => el.id === editResult.affectedElementId));
      }
    });
  }

  dropHeader(event: CdkDragDrop<string[]>): void {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.columnsToDisplay, event.previousIndex, event.currentIndex);
    }
    setStorage('Order_Of_QuickEditTable_Columns', this.columnsToDisplay);
  }

  quickRemovePosition(): void {
    const selectedElement = this.selectedElementForEdit;
    this.spinnerOverlayService.show();
    this.treeNodeService
      .deleteNode(selectedElement)
      .pipe(take(1))
      .subscribe((editResult?: AvaProjectContentEditResultGet) => {
        if (editResult) {
          this.selectedElementForEdit = null;
        } else {
          this.spinnerOverlayService.hide();
        }
      });
  }

  getParentNode(currentChildId: string): IElementDto {
    return this.flatElements
      .filter((e: IElementDto) => e.elementType === 'ServiceSpecificationGroupDto')
      .find((e: IElementDto) => (<ServiceSpecificationGroupDto>e).elements.find((ce: IElementDto) => ce.id === currentChildId));
  }

  //this function is used when we turn off the edit mode, if the mode is not used in the future it can be removed
  selectRowForChangeSelectedElement(element: IElementDto): void {
    this.selectedSpecificationElementMessengerService.trySelectElementById(element.id);
    this.treeViewMessengerService.sendDataFromTreeView({
      command: TreeViewCommandType.ChangeSelectedElement,
      data: [element.id && element]
    });
  }

  setFixedPrice(element: PositionDto): void {
    const fixedPrice = element.unitPrice.toString().replace(',', '.');
    this.calculationFixedPriceService.setFixedPriceForCalculation(element.id, element.unitPrice);
    this.positionCalculationsClient
      .getPositionCalculation(this.projectId, this.avaProjectId, element.id)
      .pipe(
        map((positionCalculation) => {
          positionCalculation.positionCalculation.fixedPrice = fixedPrice === '' ? null : +fixedPrice;
          return positionCalculation.positionCalculation;
        }),
        switchMap((positionCalculation) => {
          return this.positionCalculationsClient.calculatePosition(
            this.projectId,
            this.avaProjectId,
            element.id,
            false,
            positionCalculation
          );
        })
      )
      .subscribe(() => {
        this.calculationFixedPriceService.reloadListOfItemsWithFixedPrice();
      });
  }

  onKey(event: KeyboardEvent): void {
    if (this.isCursorOutsideText) {
      this.keyupControlService.onKey(event, this.columnsToDisplay, null);
    }
  }

  preventKeyDownEvent(event: KeyboardEvent, element: IElementDto): void {
    const target = event.target as HTMLInputElement;
    switch (event.key) {
      case 'Insert':
        {
          this.treeNodeService.addNode(element).subscribe();
        }
        break;
      default:
    }
    if (
      ((event.key === 'ArrowRight' && target.selectionStart !== target.value.length) ||
        (event.key === 'ArrowLeft' && target.selectionStart !== 0)) &&
      target.type === 'text'
    ) {
      this.isCursorOutsideText = false;
    } else {
      this.keyupControlService.preventKeyDownEvent(event);
      this.isCursorOutsideText = true;
    }
  }

  checkIsActiveInput(): boolean {
    const inputs = this.inputElements.toArray();
    const isActive = inputs.some((input) => input.focused && input.id !== this.searchInput.nativeElement.id);
    return isActive;
  }

  trackByFn(index, item): void {
    return item.rowIndex;
  }
}
