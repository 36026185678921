import { NgIf, NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';

import { CompanyGet, ProjectExportsClient, ProjectsClient } from '../../../../generated-client/generated-client';
import { AvaNotificationsService } from '../../../../shared/services/ava-notifications.service';
import { CompaniesService } from '../../../../shared/services/lightquery/companies.service';
import { ProjectsService } from '../../../../shared/services/lightquery/projects.service';

@Component({
  selector: 'pa-project-restore',
  templateUrl: './project-restore.component.html',
  styleUrls: ['./project-restore.component.scss'],
  standalone: true,
  imports: [MatButton, NgIf, MatFormField, MatLabel, MatSelect, FormsModule, NgFor, MatOption, MatProgressSpinner]
})
export class ProjectRestoreComponent implements OnInit {
  constructor(
    private projectExportsClient: ProjectExportsClient,
    private avaNotificationsService: AvaNotificationsService,
    private router: Router,
    private projectsClient: ProjectsClient,
    private projectsService: ProjectsService,
    private companiesService: CompaniesService
  ) {}

  selectedFileName: string;
  isRunning = false;
  companies: CompanyGet[];
  selectedCompany: CompanyGet = null;
  private selectedFile: File;

  ngOnInit(): void {
    this.companiesService.getAll().subscribe((c) => {
      this.companies = c;
      if (this.companies?.length) {
        this.selectedCompany = this.companies[0];
      }
    });
  }

  setFile(addedFiles: File[]): void {
    if (addedFiles?.length) {
      const restoreFile = addedFiles[0];
      this.selectedFileName = restoreFile.name;
      this.selectedFile = restoreFile;
    }
  }

  restoreProject(): void {
    this.avaNotificationsService.info('Projektwiederherstellung gestartet.');
    this.isRunning = true;
    this.projectExportsClient
      .importProject(this.selectedCompany.id, {
        data: this.selectedFile,
        fileName: this.selectedFileName
      })
      .subscribe({
        next: (project) => {
          this.avaNotificationsService.info('Projektwiederherstellung abgeschlossen.');
          this.isRunning = false;
          this.router.navigate(['projects', project.id, 'service-specifications']);
          this.projectsClient.setUserHasAccessedProject(project.id).subscribe(() => this.projectsService.forceRefresh());
        },
        error: () => {
          this.isRunning = false;
          this.avaNotificationsService.error('Fehler beim Wiederherstellen des Projekts.');
        }
      });
  }
}
