import { NgIf, NgFor } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';
import { MatFormField, MatLabel, MatHint } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';

import { filter, first } from 'rxjs/operators';

import { StandalonePagesService } from '@shared/services/lightquery/standalone-pages.service';

import { SelectContactModalComponent } from 'app/areas/contacts/components/select-contact-modal/select-contact-modal.component';
import {
  BuildingElementCodeGet,
  ContactGet,
  ContactType,
  PageQuantityTakeOffGet,
  PageQuantityTakeOffsClient,
  ProjectDto,
  ProjectGet,
  QuantityTakeOffCalculationType,
  QuantityTakeOffGet
} from 'app/generated-client/generated-client';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { PageQuantityTakeOffsService } from 'app/shared/services/lightquery/page-quantity-take-offs.service';
import { QuantityTakeOffsService } from 'app/shared/services/lightquery/quantity-take-offs.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedQuantityTakeOffMessengerService } from 'app/shared/services/messengers/selected-quantity-take-off-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';

import { BuildingElementCodesModalComponent } from '../building-element-codes-modal/building-element-codes-modal.component';

@Component({
  selector: 'pa-new-invoice-page',
  templateUrl: './new-invoice-page.component.html',
  styleUrls: ['./new-invoice-page.component.scss'],
  standalone: true,
  imports: [
    MatDialogContent,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatHint,
    MatSelect,
    MatOption,
    NgFor,
    MatButton
  ]
})
export class NewInvoicePageComponent implements OnInit {
  newPageForm: FormGroup;
  private avaProjectId: string;
  private projectId: string;
  private quantityTakeOffId: string;
  isSubContractor: boolean;
  selectedContact: ContactGet;
  listParentQTO: QuantityTakeOffGet[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      structureView: string;
      page?: PageQuantityTakeOffGet;
      quantityTakeOffId?: string;
    },
    @Optional() private matDialogRef: MatDialogRef<NewInvoicePageComponent>,
    private notificationsService: AvaNotificationsService,
    private pageQuantityTakeOffsClient: PageQuantityTakeOffsClient,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedQuantityTakeOffMessengerService: SelectedQuantityTakeOffMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private modalService: ModalService,
    private avaNotificationsService: AvaNotificationsService,
    private pageQuantityTakeOffsService: PageQuantityTakeOffsService,
    private quantityTakeOffsService: QuantityTakeOffsService,
    private standalonePagesService: StandalonePagesService
  ) {}

  ngOnInit(): void {
    this.newPageForm = new FormGroup({
      name: new FormControl(''),
      subContractorId: new FormControl(null),
      customPageNumber: new FormControl(''),
      newBuildingElementCode: new FormControl({ value: null, disabled: !!this.data.page }),
      existingBuildingElementCodeId: new FormControl(null)
    });
    if (this.data.page) {
      if (this.data.structureView === 'invoices') {
        this.newPageForm.patchValue({
          name: this.data.page.name,
          customPageNumber: this.data.page.pageNumber,
          newBuildingElementCode: this.data.page.buildingElementCode,
          existingBuildingElementCodeId: this.data.page.buildingElementCodeId,
          subContractorId: this.data.page.subContractorId
        });
      } else {
        this.newPageForm.patchValue({
          name: this.data.page.name,
          customPageNumber: this.data.page.pageNumber,
          newBuildingElementCode: this.data.page.buildingElementCode,
          existingBuildingElementCodeId: this.data.page.buildingElementCodeId
        });
      }

      if (this.data.page.subContractorName) {
        this.isSubContractor = true;
        this.selectedContact = {
          name: this.data.page.subContractorName
        };
      }
    }

    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        filter((r) => !!r),
        first()
      )
      .subscribe((s: { avaProjectId: string; project: ProjectDto }) => {
        this.avaProjectId = s.avaProjectId;
      });

    this.selectedProjectMessengerService.selectedProject.pipe(first()).subscribe((p: ProjectGet) => (this.projectId = p.id));

    this.selectedQuantityTakeOffMessengerService.selectedQuantityTakeOff
      .pipe(first())
      .subscribe((qto: QuantityTakeOffGet) => (this.quantityTakeOffId = qto?.id));

    this.quantityTakeOffsService.getAll().subscribe((e) => {
      this.listParentQTO = e?.filter((item: QuantityTakeOffGet) => item.calculationType === QuantityTakeOffCalculationType.ByPage);
      if (this.data?.quantityTakeOffId) {
        const qto = this.listParentQTO.find((item) => item.id === this.data.quantityTakeOffId);
        if (qto) {
          this.quantityTakeOffId = qto.id;
        }
      }
    });
  }

  cancel(): void {
    this.matDialogRef.close(false);
  }

  createPage(): void {
    const data = { ...this.newPageForm.value };
    if (data.existingBuildingElementCodeId) {
      data.newBuildingElementCode = null;
    }

    if (this.quantityTakeOffId) {
      data.quantityTakeOffId = this.quantityTakeOffId;
    }

    const request = this.pageQuantityTakeOffsClient.createPageQuantityTakeOff(this.projectId, this.avaProjectId, data);

    request.subscribe(
      (qto: PageQuantityTakeOffGet) => {
        if (!this.quantityTakeOffId) {
          this.standalonePagesService.forceRefresh();
        }

        this.matDialogRef.close(qto);
        this.notificationsService.success('Das Aufmaßblatt wurde erstellt.');
      },
      () => this.notificationsService.error('Das Aufmaßblatt konnte nicht erstellt werden.')
    );
  }

  listBuildingElementCode(): void {
    this.modalService
      .openModal(BuildingElementCodesModalComponent, { dialogType: ConfirmationType.General, autoFocus: false })
      .afterClosed()
      .subscribe((e: BuildingElementCodeGet) => {
        if (e) {
          this.newPageForm.get('existingBuildingElementCodeId').setValue(e.id);
          this.newPageForm.get('newBuildingElementCode').setValue(e.name);
        } else if (e === null) {
          this.newPageForm.get('existingBuildingElementCodeId').setValue(null);
          this.newPageForm.get('newBuildingElementCode').setValue(null);
        }
      });
  }

  selectContact(value: boolean): void {
    this.isSubContractor = false;
    this.selectedContact = null;
    this.newPageForm.get('subContractorId').setValue(null);
    if (value) {
      this.modalService
        .openModal(SelectContactModalComponent, { dialogType: ConfirmationType.General, data: { contactType: ContactType.SubContractor } })
        .beforeClosed()
        .subscribe((contact: ContactGet) => {
          if (contact) {
            this.isSubContractor = true;
            this.selectedContact = contact;
            this.newPageForm.get('subContractorId').setValue(this.selectedContact.id);
          }
        });
    }
  }

  editPage(): void {
    const editModel = { ...this.newPageForm.value };
    editModel.pageNumber = this.newPageForm.value.customPageNumber;
    editModel.buildingElementCodeId = this.newPageForm.value.existingBuildingElementCodeId;

    this.pageQuantityTakeOffsClient
      .updatePageQuantityTakeOffProperties(this.projectId, this.avaProjectId, this.data.page.id, editModel)
      .subscribe({
        next: () => {
          this.avaNotificationsService.success('Das Aufmaßblatt wurde aktualisiert.');
          if (!this.quantityTakeOffId) {
            this.standalonePagesService.forceRefresh();
          } else {
            this.pageQuantityTakeOffsService.forceRefresh();
          }
          this.matDialogRef.close(true);
        },
        error: () => {
          this.avaNotificationsService.error('Das Aufmaßblatt konnte nicht aktualisiert werden.');
        }
      });
  }

  changedQto(event): void {
    this.quantityTakeOffId = event.value;
  }
}
