import { PageQuantityTakeOffRowModel, UserSettings } from 'app/generated-client/generated-client';

import { AvaNotificationsService } from '../../../../../../../shared/services/ava-notifications.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CheckFormulaErrorService {
  constructor(private avaNotificationsService: AvaNotificationsService) {}

  checkErrorInFormulaAndSendIdForFocus(lastEditedRow: PageQuantityTakeOffRowModel, userSettings: UserSettings, focusedId: string): string {
    if (!lastEditedRow) {
      return '';
    }

    if (!userSettings.stayOnInvalidQtoFormula) {
      return focusedId;
    }

    if (!lastEditedRow.formulaErrorType) {
      return focusedId;
    }

    this.avaNotificationsService.error('Formelfehler');
    return `${lastEditedRow.rowIndex}-formula`;
  }
}
