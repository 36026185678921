// If you import a module but never use any of the imported values other than as TypeScript types,
// the resulting javascript file will look as if you never imported the module at all.import * as childProcess from 'child_process';
import { Injectable, NgZone } from '@angular/core';

import { Observable, Subject, from, of } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import * as childProcess from 'child_process';
import { ipcRenderer, shell, webFrame } from 'electron';
import * as fs from 'fs';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class ElectronService {
  ipcRenderer: typeof ipcRenderer;
  webFrame: typeof webFrame;
  childProcess: typeof childProcess;
  fs: typeof fs;
  shell: typeof shell;

  private static hasCheckedForWindowObject = false;
  private static hasElectronWindowObject = false;
  private screenshotsSource = new Subject<{ id: string; imageBase64: string }>();
  private logMessagesSource = new Subject<{ id: string; logMessages: any[] }>();

  get isElectron(): boolean {
    const mightBeElectron = !!(window && window.process && window.process.type);
    if (mightBeElectron) {
      if (ElectronService.hasCheckedForWindowObject) {
        return ElectronService.hasElectronWindowObject;
      } else {
        ElectronService.hasElectronWindowObject = window.require && !!window.require('electron');
        ElectronService.hasCheckedForWindowObject = true;
        return ElectronService.hasElectronWindowObject;
      }
    }

    return false;
  }

  getLatestAppLogMessages(): Observable<any[]> {
    if (!this.isElectron) {
      return of(null);
    }

    const id = uuidv4();
    this.ipcRenderer.send('GetAppLogMessages', id);
    return this.logMessagesSource.pipe(
      filter((d) => d.id === id),
      first(),
      map((r) => r.logMessages)
    );
  }

  takeScreenshotAsBase64(): Observable<string> | null {
    if (this.isElectron) {
      const id = uuidv4();
      const source = new Subject<string>();
      this.ipcRenderer.send('TakeScreenshot', id);

      this.screenshotsSource
        .pipe(
          filter((d) => d.id === id),
          first()
        )
        .subscribe((data) => {
          source.next(data.imageBase64);
          source.complete();
        });

      return source;
    }

    return null;
  }

  constructor(ngZone: NgZone) {
    // Conditional imports
    if (this.isElectron) {
      this.ipcRenderer = window.require('electron').ipcRenderer;
      this.webFrame = window.require('electron').webFrame;

      this.shell = window.require('electron').shell;

      this.childProcess = window.require('child_process');
      this.fs = window.require('fs');

      this.ipcRenderer.on('ScreenshotTaken', (_, data: { id: string; imageBase64: string }) => {
        ngZone.run(() => this.screenshotsSource.next(data));
      });

      this.ipcRenderer.on('AppLogMessages', (_, data: { requestId: string; logMessages: any[] }) => {
        ngZone.run(() => {
          this.logMessagesSource.next({ id: data.requestId, logMessages: data.logMessages });
        });
      });
    }
  }
}
