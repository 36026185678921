<ul
  *ngIf="(flatElementsDto && flatElementsDto.length) || !filterInitializing; else noList"
  cdkDropList
  cdkDropListSortingDisabled
  id="tree"
  [cdkDropListConnectedTo]="['calculation', 'tree']"
  (cdkDropListDropped)="drop($event)"
>
  <li
    *ngFor="let element of flatElementsDto"
    [ngClass]="{
      'choose-node': element.id === choseSelectedElementId && (selectedElementId$ | async) !== choseSelectedElementId,
      'selected-node': element.id === (selectedElementId$ | async)
    }"
    (click)="selectElementBySingl(element)"
    (dblclick)="selectElementByDouble(element)"
    (contextmenu)="modePage === 'lv-editor' || modePage === 'calculation' ? showContextMenu($event, element) : null"
    [attr.data-id]="element.id"
  >
    <ng-container
      *ngIf="element.elementTypeDiscriminator !== 'PositionDto' && (modePageService.modePage | async) !== 'lv-editor'; else withDragElement"
    >
      <div class="wrap">
        <ng-container [ngTemplateOutlet]="blockOfelementAndText"></ng-container>
      </div>
    </ng-container>

    <ng-template #blockOfelementAndText>
      <ng-container *ngIf="(markedElements$ | async) != null && (markedElements$ | async)[element.id]; else emptyIcon">
        <button mat-icon-button class="node-toggle-button" color="warn">
          <mat-icon class="mat-icon-rtl-mirror">flag</mat-icon>
        </button>
      </ng-container>
      <ng-template #emptyIcon>
        <button class="node-toggle-button emty" mat-icon-button *ngIf="(isShowEmptyIcon$ | async) === true">
          <mat-icon></mat-icon>
        </button>
      </ng-template>
      <button mat-icon-button class="node-toggle-button">
        <mat-icon class="mat-icon-rtl-mirror" *ngIf="element.elementTypeDiscriminator === 'NoteTextDto'">subject</mat-icon>
        <mat-icon class="mat-icon-rtl-mirror" *ngIf="element.elementTypeDiscriminator === 'ExecutionDescriptionDto'">event</mat-icon>
        <mat-icon class="mat-icon-rtl-mirror" *ngIf="element.elementTypeDiscriminator === 'ServiceSpecificationGroupDto'"
          >folder_open</mat-icon
        >
        <ng-container *ngIf="element.elementTypeDiscriminator === 'PositionDto'">
          <mat-icon *ngIf="element | nodeWithoutCalculation: (positionIdsWithoutCalculations$ | async); else tableBlock">add</mat-icon>
        </ng-container>

        <ng-template #tableBlock>
          <mat-icon color="warn">block</mat-icon>
        </ng-template>
      </button>
      <mat-checkbox
        *ngIf="isSelectingMode; else textNoteText"
        [indeterminate]="checkedElements[element.id]?.indeterminate"
        [(ngModel)]="checkedElements[element.id].checked"
        (ngModelChange)="changeSelecting(element, $event)"
        (click)="$event.stopPropagation()"
      >
        <ng-container *ngTemplateOutlet="textNoteText"></ng-container>
      </mat-checkbox>
    </ng-template>
    <ng-template #textNoteText>
      <span
        *ngIf="element.elementTypeDiscriminator === 'ServiceSpecificationGroupDto' || element.elementTypeDiscriminator === 'PositionDto'"
        >{{ $any(element)?.itemNumber.stringRepresentation }}
        {{ $any(element).positionType | showPositionAbbreviation: $any(element).priceType }}
        -&nbsp;
      </span>
      <span
        [ngClass]="{
          'text-group': element.elementTypeDiscriminator === 'ServiceSpecificationGroupDto'
        }"
      >
        {{ element | nodeText }}</span
      >
    </ng-template>
    <ng-template #withDragElement>
      <div class="drag-placeholder"></div>
      <div class="wrap" cdkDrag [cdkDragData]="element" (cdkDragStarted)="dragStart($event)" (cdkDragEnded)="dragEnded()">
        <ng-container [ngTemplateOutlet]="blockOfelementAndText"></ng-container>
      </div>
    </ng-template>
  </li>
</ul>
<pa-tree-menu [contextMenuPosition]="contextMenuPosition"></pa-tree-menu>
<ng-template #noList>
  <p class="no-list">Keine Elemente gefunden.</p>
</ng-template>
