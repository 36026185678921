import { Injectable } from '@angular/core';

import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { getStorage, setStorage } from '../utilities/storage';

@Injectable({
  providedIn: 'root'
})
export class ShowedViewsService {
  private showedViewsSource = new ReplaySubject<string[]>(1);
  public showedViews = this.showedViewsSource.asObservable();

  private showedSeparatedViewsSource = new ReplaySubject<{ name: string; rect: DOMRect }[]>(1);
  public showedSeparatedViews = this.showedSeparatedViewsSource.asObservable();

  private canLeave: boolean;
  private hiddenWindowsSource = new ReplaySubject<boolean>(1);
  hiddenWindows = this.hiddenWindowsSource.asObservable();

  private showedBottomViewSource = new BehaviorSubject<boolean>(false);
  showedBottomView = this.showedBottomViewSource.asObservable();

  private showedMainSeparatedSource = new ReplaySubject<boolean>(1);
  showedMainSeparated = this.showedMainSeparatedSource.asObservable();

  private backAllWindowSource = new Subject<boolean>();
  backAllWindow = this.backAllWindowSource.asObservable();

  constructor() {
    this.setShowedViews(getStorage<string[]>('listViews', [] as string[]) as string[]);
    this.showedSeparatedViewsSource.next(
      getStorage<{ name: string; rect: DOMRect }[]>('listSeparatedViews', [] as { name: string; rect: DOMRect }[])
    );
  }

  public setShowedViews(views: string[]): void {
    setStorage<string[]>('listViews', views);
    this.showedViewsSource.next(views);
  }

  public addSeparatedViews(view: { name: string; rect: DOMRect }): void {
    let list = getStorage<{ name: string; rect: DOMRect }[]>('listSeparatedViews', [] as { name: string; rect: DOMRect }[]);
    const newList = list.filter((item) => item.name !== view.name);
    newList.push(view);
    setStorage<{ name: string; rect: DOMRect }[]>('listSeparatedViews', newList);
    this.showedSeparatedViewsSource.next(newList);
  }

  public delSeparatedViews(name: string): void {
    const list = getStorage<{ name: string; rect: DOMRect }[]>('listSeparatedViews', [] as { name: string; rect: DOMRect }[]);
    const newList = list.filter((item) => item.name !== name);
    setStorage<{ name: string; rect: DOMRect }[]>('listSeparatedViews', newList);
    this.showedSeparatedViewsSource.next(newList);
  }

  tryHide(): void {
    this.canLeave = true;
    setTimeout(() => {
      if (this.canLeave) {
        this.hiddenWindowsSource.next(true);
      }
    }, 100);
  }

  stopHiding(): void {
    this.canLeave = false;
  }

  setShow(): void {
    this.hiddenWindowsSource.next(false);
    this.canLeave = false;
  }

  changeShowedBottomView(value: boolean): void {
    if (value !== this.showedBottomViewSource.value) {
      this.showedBottomViewSource.next(value);
    }
  }

  setShowedMain(value: boolean): void {
    this.showedMainSeparatedSource.next(value);
  }

  setBackAllWindow(): void {
    this.backAllWindowSource.next(true);
  }
}
