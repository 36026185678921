<div mat-dialog-content>
  <h2>Nachlass</h2>
  <div fxLayoutGap="12px">
    <mat-checkbox [(ngModel)]="showGroups">Gruppen</mat-checkbox>
    <mat-checkbox [(ngModel)]="showPositions">Positionen</mat-checkbox>
  </div>
  <form [formGroup]="itemForm" (ngSubmit)="save()" *ngIf="itemForm">
    <div class="list-wrapper mat-elevation-z8">
      <div class="list">
        <ng-container formArrayName="items" *ngFor="let item of getArray().controls; let i = index">
          <div
            [formGroupName]="i"
            *ngIf="
              (showGroups && item.value.elementType === 'ServiceSpecificationGroupDto') ||
              (showPositions && item.value.elementType === 'PositionDto') ||
              !item.value.elementType
            "
            fxLayout="row"
            fxLayoutAlign="start start"
            class="item"
          >
            <div class="type" *ngIf="item.value.elementType">
              {{ item.value.elementType | elementTypeClass }}
            </div>
            <div class="type" *ngIf="!item.value.elementType">LV</div>
            <div class="text">
              {{ item.value.text }}
              <div>
                {{ deductedPrices[i] | projectCurrency }}
              </div>
            </div>
            <mat-form-field appearance="fill">
              <mat-label>Rabatt %</mat-label>
              <input matInput formControlName="deductionFactor" />
              <span matSuffix>%</span>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Rabatt Absolut</mat-label>
              <input matInput formControlName="absoluteDeduction" class="right" />
              <span matSuffix></span>
            </mat-form-field>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="buttons" fxLayoutGap="12px">
      <button mat-raised-button color="primary" type="submit" [disabled]="!isChanged || isSaving">Speichern</button>
      <button mat-raised-button color="warn" mat-dialog-close [disabled]="isSaving">Abbrechen</button>
    </div>
  </form>
</div>
