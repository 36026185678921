import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdditionSubscriptionService {
  private selectedAdditionSubSource = new ReplaySubject<string>(1);
  selectedAdditionSub = this.selectedAdditionSubSource.asObservable();

  setSelectedAdditionSub(selectedAdditionSub: string): void {
    this.selectedAdditionSubSource.next(selectedAdditionSub);
  }
}
