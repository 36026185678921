import { NgFor, NgIf, DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';
import {
  MatCard,
  MatCardHeader,
  MatCardAvatar,
  MatCardTitle,
  MatCardSubtitle,
  MatCardImage,
  MatCardContent,
  MatCardActions
} from '@angular/material/card';

import { ProjectGet } from 'app/generated-client/generated-client';
import { ProjectsService } from 'app/shared/services/lightquery/projects.service';

import { UpperPaginatorComponent } from '../../../../shared/components/upper-paginator/upper-paginator.component';
import { FlexLayoutDirective } from '../../../flex-layout/flex-layout.directive';

import { NgDanglIconsModule } from 'ng-dangl-icons';
import { PaginationResult } from 'ng-lightquery';

@Component({
  selector: 'pa-projects-cards',
  templateUrl: './projects-cards.component.html',
  styleUrls: ['./projects-cards.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutDirective,
    NgFor,
    MatCard,
    MatCardHeader,
    MatCardAvatar,
    MatCardTitle,
    MatCardSubtitle,
    MatCardImage,
    NgDanglIconsModule,
    MatCardContent,
    MatCardActions,
    MatButton,
    NgIf,
    UpperPaginatorComponent,
    DatePipe
  ]
})
export class ProjectsCardsComponent implements OnInit, OnDestroy {
  @Output() selectRow = new EventEmitter<ProjectGet>();
  @Input() projectsPaginated: PaginationResult<ProjectGet>;
  private originalPageSize: number;
  pageSizeOptions = [5, 10, 25, 100];
  constructor(public projectsService: ProjectsService) {}

  ngOnInit(): void {
    this.projectsService.page = 1;
    this.originalPageSize = this.projectsService.pageSize;
    this.projectsService.pageSize = 25;
    this.projectsService.sort = {
      propertyName: 'lastAccessedAtUtc',
      isDescending: true
    };
  }

  ngOnDestroy(): void {
    this.projectsService.pageSize = this.originalPageSize;
    this.projectsService.page = 1;
  }

  selectProject(project: ProjectGet): void {
    this.selectRow.emit(project);
  }
}
