<h2 mat-dialog-title>{{ data.exportType }} Optionen</h2>
<div mat-dialog-content class="wrapper">
  <h1 *ngIf="showingPdf">Bitte Druckvorschau beachten.</h1>
  <ng-container *ngIf="!showingPdf">
    <div fxLayoutGap="10px">
      <mat-form-field class="input-base">
        <mat-label>Letzte Adresse</mat-label>
        <input matInput [(ngModel)]="startAddress" (change)="isChanged = true" [disabled]="isLoading" />
      </mat-form-field>
    </div>
    <p class="hint">
      Falls gewünscht, kann hier die letzte Adresse eines vorherigen {{ data.exportType }} Exports eingegeben werden. Die
      {{ data.exportType }} Datei wird dann mit der nächsten Adresse starten.
    </p>
    <div>
      <mat-checkbox [(ngModel)]="includePreviousSums" (click)="isChanged = true" [disabled]="isLoading">
        Vorherige Abrechnungssummen einschließen
      </mat-checkbox>
    </div>
    <div class="generate">
      <button color="primary" mat-raised-button (click)="change()" [disabled]="!isChanged || isLoading">Datei Erzeugen</button>
    </div>
    <div fxLayoutGap="10px">
      <button color="primary" mat-raised-button (click)="saveReport()" [disabled]="!isResponse || isChanged || isLoading">
        Bericht Speichern
      </button>
      <button color="primary" mat-raised-button (click)="saveFile()" [disabled]="!isResponse || isChanged || isLoading">
        {{ data.exportType }} Speichern
      </button>
      <button mat-raised-button mat-dialog-close>Abbrechen</button>
    </div>
    <div fxLayoutAlign="center center" class="spinner" *ngIf="isLoading">
      <mat-spinner></mat-spinner>
    </div>
  </ng-container>
</div>
