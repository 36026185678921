import { IElementDto, PositionDto } from '../../../generated-client/generated-client';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';

import { ElectronService } from '../electron/electron.service';
import { FlatElementsService } from '../../../areas/tree/services/flat-elements.service';

@Injectable({
  providedIn: 'root'
})
export class SelectedSpecificationElementMessengerService implements OnDestroy {
  private $destroy = new Subject<void>();

  private selectedElementSource = new ReplaySubject<{ id: string; element: IElementDto }>(1);
  selectedElement = this.selectedElementSource.asObservable();

  private keyboardNavigationTreePositionSelectedSource = new Subject<PositionDto>();
  keyboardNavigationTreePositionSelected = this.keyboardNavigationTreePositionSelectedSource.asObservable();

  private currentElementsById: { [id: string]: IElementDto } = {};
  private lastElementIdToSelect: string | null = null;
  private lastSelectedElementId: string | null = null;

  constructor(private electronService: ElectronService, ngZone: NgZone, private flatElementsService: FlatElementsService) {
    electronService.ipcRenderer?.on('KeyboardNavigationTreeViewPositionSelectionReceived', (_, position: PositionDto) => {
      ngZone.run(() => this.keyboardNavigationTreePositionSelectedSource.next(position));
    });

    this.flatElementsService.flatElementsDto.pipe(takeUntil(this.$destroy)).subscribe(flatElements => {
      this.currentElementsById = {};
      flatElements.forEach(flatElement => {
        this.currentElementsById[flatElement.id] = flatElement;
      });

      if (this.lastElementIdToSelect) {
        this.trySelectElementById(this.lastElementIdToSelect);
        // We also want to clear it in case it's not found
        this.lastElementIdToSelect = null;
      }
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  private setSelectedElement(selectedElement: IElementDto): void {
    if (selectedElement) {
      this.selectedElementSource.next({
        id: selectedElement.id,
        element: selectedElement
      });
    } else {
      this.selectedElementSource.next(null);
    }
  }

  /**
   * This method first tries to select the element by id to get the locally cached data.
   * If that fails, it will return false. This is usfeul if element data is selected e.g. via IPC,
   * where we would otherwise lose the local object reference. When keeping the reference, we make
   * sure that updates to the elements are correctly applied when the backend is sending
   * AVA Project Diffs.
   * If the element is not found locally cached in this service, its id is stored
   * and the next time the flat elements are loaded, the element is selected.
   */
  trySelectElementById(elementId: string): boolean {
    if (elementId == this.lastSelectedElementId && elementId !== this.lastElementIdToSelect) {
      return false;
    }

    this.lastSelectedElementId = elementId || null;

    if (!elementId) {
      this.setSelectedElement(null);
      return true;
    }

    const cachedElement = this.currentElementsById[elementId];
    if (cachedElement) {
      this.setSelectedElement(cachedElement);
      this.lastElementIdToSelect = null;
      return true;
    } else {
      this.lastElementIdToSelect = elementId;
      return false;
    }
  }

  clearSelectedElement(): void {
    this.lastElementIdToSelect = null;
    this.lastSelectedElementId = null;
    this.selectedElementSource.next(null);
  }

  setPositionSelectedViaKeyboardNavigationInTree(position: PositionDto): void {
    if (position) {
      this.electronService?.ipcRenderer.send('KeyboardNavigationTreeViewPositionSelectionBroadcast', position);
    }
  }
}
