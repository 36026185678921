import { Injectable, NgZone } from '@angular/core';

import { ElectronService } from './electron.service';
import { IElementDto } from 'app/generated-client/generated-client';
import { ReplaySubject } from 'rxjs';
import { SelectedSpecificationElementMessengerService } from '../messengers/selected-specification-element-messenger.service';

@Injectable({
  providedIn: 'root'
})
export class ElementViewMessengerService {
  private elementViewVisibleSource = new ReplaySubject<boolean>(1);
  elementViewVisible = this.elementViewVisibleSource.asObservable();
  private selectedElementSource = new ReplaySubject<{ element?: IElementDto; options?: any }>(1);
  /**
   * This is relaying data sent via Electron IPC
   */
  selectedElement = this.selectedElementSource.asObservable();

  // This is used as a local cache when we're sending element data to Electron, to make sure that
  // any applied diffs to the element are also present in the main process (from which they are
  // sent to the element view window)
  private cachedElementDataForElementViewWindow: { element?: IElementDto; options?: any } | undefined;

  private dataSource = new ReplaySubject<{ command: string; data: any[] }>(1);
  data = this.dataSource.asObservable();

  private longTextFromElementViewSource = new ReplaySubject<string>(1);
  longTextFromElementView = this.longTextFromElementViewSource.asObservable();

  private dataFromElementViewSource = new ReplaySubject<any>(1);
  dataFromElementView = this.dataFromElementViewSource.asObservable();

  constructor(
    private electronService: ElectronService,
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    ngZone: NgZone
  ) {
    if (electronService.isElectron) {
      electronService.ipcRenderer.on('ElementViewWindowVisible', () => ngZone.run(() => this.elementViewVisibleSource.next(true)));
      electronService.ipcRenderer.on('ElementViewWindowClosed', () => ngZone.run(() => this.elementViewVisibleSource.next(false)));
      electronService.ipcRenderer.on('SelectedElementData', (_, data: { element?: IElementDto; options?: any }) =>
        ngZone.run(() => {
          this.selectedElementSource.next(data);
          if (data?.element) {
            // Since this data might come via IPC, we want to first try to select the element by id
            // to get the locally cached data. If that fails, we'll select the element by the data
            if (!this.selectedSpecificationElementMessengerService.trySelectElementById(data.element.id)) {
              this.selectedSpecificationElementMessengerService.trySelectElementById(data.element?.id);
            }
          } else {
            this.selectedSpecificationElementMessengerService.clearSelectedElement();
          }
        })
      );
      electronService.ipcRenderer.on('SendToViewData', (_, data: { command: string; data: any[] }) =>
        ngZone.run(() => {
          this.dataSource.next(data);
        })
      );

      electronService.ipcRenderer.on('LongTextFromElementView', (_, data: string) => {
        ngZone.run(() => this.longTextFromElementViewSource.next(data));
      });

      electronService.ipcRenderer.on('DataFromElementView', (_, data: any) => {
        ngZone.run(() => this.dataFromElementViewSource.next(data));
      });
    }
  }

  ensureLatestSelectedElementIsLoaded(): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('GetSelectedElementData');
    }
  }

  showElementViewWindow(): void {
    if (this.electronService.isElectron) {
      if (this.cachedElementDataForElementViewWindow) {
        // In case we have already sent an element to the Electron main process,
        // we want to send it again to make sure the element view window is up to date
        // Otherwise, applied diffs would not be reflected there.
        this.setSelectedElement(this.cachedElementDataForElementViewWindow);
      }
      this.electronService.ipcRenderer.send('ShowElementViewWindow');
    }
  }

  setSelectedElement(data: { element?: IElementDto; options?: any }): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('SetSelectedElementData', data);
      this.cachedElementDataForElementViewWindow = data;
    }
  }

  setOnTopElement(alwaysOnTop: boolean): void {
    if (this.electronService?.ipcRenderer) {
      this.electronService.ipcRenderer.send('ElementViewWindowOnTop', alwaysOnTop);
    }
  }

  setDataToElementView(data: { command: string; data: any[] }): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('SetDataToElementView', data);
    }
  }

  sendLongTextFromElementView(newLongText: string): void {
    this.electronService.ipcRenderer.send('SendLongTextFromElementView', newLongText);
  }

  sendDataFromElementView(data: any): void {
    this.electronService.ipcRenderer.send('SendDataFromElementView', data);
  }

  closeElementViewWindow(): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('CloseElementViewWindow');
    }
  }
}
