import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { getAppConfig } from '../../app-config-accessor';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!/^https?:\/\//i.test(request.url)) {
      request = request.clone({
        url: getAppConfig().backendUrl + request.url
      });
    }

    return next.handle(request);
  }
}
