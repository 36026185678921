import { Component } from '@angular/core';

import { combineLatestWith, switchMap, take } from 'rxjs/operators';

import {
  PdfConversionClient,
  QuantityTakeOffAttachmentPost,
  QuantityTakeOffAttachmentsClient
} from 'app/generated-client/generated-client';
import { UploadFileSettings } from 'app/shared/components/upload-file/upload-file.component';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedQuantityTakeOffMessengerService } from 'app/shared/services/messengers/selected-quantity-take-off-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

import { UploadFileComponent } from '../../../../../../../../shared/components/upload-file/upload-file.component';

import { PdfAttachmentsService } from './../../../../../../../../shared/services/lightquery/pdf-attachments.service';
import { SelectedAttachmentService } from './../../../../../../../../shared/services/messengers/selected-attachment.service';
import { SpinnerOverlayService } from './../../../../../../../../shared/services/spinner-overlay.service';

@Component({
  selector: 'pa-upload-invoice-pdf-file',
  templateUrl: './upload-invoice-pdf-file.component.html',
  styleUrls: ['./upload-invoice-pdf-file.component.scss'],
  standalone: true,
  imports: [UploadFileComponent]
})
export class UploadInvoicePdfFileComponent {
  uploadFileSettings: UploadFileSettings = {
    label: 'PDF Datei hochladen',
    isMultipleSelection: true,
    accept: '.pdf',
    height: '300px'
  };
  quantityTakeOffAttachment: QuantityTakeOffAttachmentPost = {};
  constructor(
    private quantityTakeOffAttachmentsClient: QuantityTakeOffAttachmentsClient,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private selectedQuantityTakeOffMessengerService: SelectedQuantityTakeOffMessengerService,
    private pdfAttachmentsService: PdfAttachmentsService,
    private selectedAttachmentService: SelectedAttachmentService,
    private spinnerOverlayService: SpinnerOverlayService,
    private avaNotificationsService: AvaNotificationsService,
    private pdfConversionClient: PdfConversionClient
  ) {}

  onSelect(addedFiles: File[]): void {
    if (addedFiles?.length) {
      let spinnersOpened = 0;

      for (let i = 0; i < addedFiles.length; i++) {
        const addedFile = addedFiles[i];

        if (spinnersOpened === 0) {
          this.spinnerOverlayService.show();
        }

        spinnersOpened++;

        const file = {
          data: addedFile,
          fileName: addedFile.name
        };
        const reader = new FileReader();
        reader.onload = async (e) => {
          if (e.target) {
            const data = e.target.result as Float64Array;
            this.quantityTakeOffAttachment.base64SourceFilePDF = this.arrayBufferToBase64(data);

            this.pdfConversionClient
              .convertPdfToPngByPage({
                data: addedFile,
                fileName: addedFile.name
              })
              .subscribe({
                next: (convertedPdf) => {
                  this.quantityTakeOffAttachment.quantityTakeOffAttachmentImageFiles = convertedPdf.pages.map((page) => {
                    return {
                      base64ImageFilePng: page.pngBase64,
                      pageNumber: page.pageNumber
                    };
                  });
                  this.quantityTakeOffAttachment.sourceFileName = file.fileName;
                  this.selectedProjectMessengerService.selectedProject
                    .pipe(
                      combineLatestWith(this.selectedSpecificationMessengerService.selectedServiceSpecification),
                      take(1),
                      switchMap(([project, avaProject]) => {
                        return this.quantityTakeOffAttachmentsClient.createQuantityTakeOffAttachment(
                          project.id,
                          avaProject.avaProjectId,
                          this.quantityTakeOffAttachment
                        );
                      })
                    )
                    .subscribe({
                      next: (quantityTakeOffAttachment) => {
                        this.selectedAttachmentService.setAttachment(quantityTakeOffAttachment);
                        this.pdfAttachmentsService.forceRefresh();
                        spinnersOpened--;
                        if (spinnersOpened === 0) {
                          this.spinnerOverlayService.hide();
                        }
                      },
                      error: () => {
                        spinnersOpened--;
                        if (spinnersOpened === 0) {
                          this.spinnerOverlayService.hide();
                        }
                      }
                    });
                },
                error: () => {
                  this.avaNotificationsService.error(
                    'Die Anlage "' + addedFile.name + '" ist kein PDF, oder kann nicht korrekt gelesen werden.'
                  );

                  spinnersOpened--;
                  if (spinnersOpened === 0) {
                    this.spinnerOverlayService.hide();
                  }
                }
              });
          } else {
            spinnersOpened--;
            if (spinnersOpened === 0) {
              this.spinnerOverlayService.hide();
            }
          }
        };

        reader.onerror = () => {
          spinnersOpened--;
          if (spinnersOpened === 0) {
            this.spinnerOverlayService.hide();
          }
        };

        reader.readAsArrayBuffer(file.data);
      }
    }
  }

  private arrayBufferToBase64(buffer: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
}
