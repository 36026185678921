import { NgStyle } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogActions, MatDialogClose } from '@angular/material/dialog';

import { take } from 'rxjs';

import { UserSettings } from 'app/generated-client/generated-client';
import { ConfirmationType, DialogConfig } from 'app/shared/models/dialog-config.model';
import { UserSettingsService } from 'app/shared/services/user-settings.service';

@Component({
  selector: 'pa-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss'],
  standalone: true,
  imports: [NgStyle, MatDialogActions, MatButton, MatDialogClose]
})
export class ModalConfirmComponent implements OnInit, AfterViewInit {
  @HostListener('keydown', ['$event'])
  onKeydown(event) {
    const target = event['target'];
    switch (event.key) {
      case 'ArrowRight':
        target.nextSibling ? target.nextSibling.focus() : target.previousElementSibling.focus();
        break;
      case 'Enter':
        if (this.isBtnCloseFocused) {
          this.close(false);
        }
        if (this.isBtnConfirmFocused) {
          this.close(true);
        }
        break;
      case 'ArrowLeft':
        target.previousElementSibling ? target.previousElementSibling.focus() : target.nextSibling.focus();
        break;
    }
  }
  @ViewChild('confirmButton', {
    read: ElementRef
  })
  confirmButton: ElementRef;
  action: string;
  name: string;
  detail: string;
  color: string;
  isBtnCloseFocused: boolean;
  isBtnConfirmFocused: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string[],
    @Optional() private matDialogRef: MatDialogRef<ModalConfirmComponent>,
    private userSettingsService: UserSettingsService
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.confirmButton?.nativeElement?.focus();
    }, 0);
  }

  close(value: boolean): void {
    this.matDialogRef.close(value);
  }

  ngOnInit(): void {
    const dialogConfig = this.matDialogRef?._containerInstance?._config as DialogConfig;
    if (dialogConfig?.dialogType === ConfirmationType.Delete) {
      this.userSettingsService.currentUserSettings.pipe(take(1)).subscribe((setting: UserSettings) => {
        if (setting.disableConfirmModalWindow) {
          this.matDialogRef.close(true);
        }
      });
    }
    [this.action, this.name, this.detail, this.color] = this.data;
  }
}
