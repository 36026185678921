<ng-container *ngIf="!iSModalMode">
  <ng-container *ngIf="invoice && structureView === 'estimations'">
    <h1>Mengenermittlung {{ invoice.number }} - {{ invoice.name }}</h1>
  </ng-container>
  <ng-container *ngIf="invoice && structureView === 'invoices'">
    <h1>Abrechnung {{ invoice.number }} - {{ invoice.name }}</h1>
  </ng-container>
  <h2>Aufmaßblätter</h2>

  <div class="buttons">
    <button mat-raised-button color="primary" class="icon-min" (click)="back()" (dblclick)="backCalculation()" *ngIf="!data?.iSModalMode">
      Zurück
    </button>
    <button mat-raised-button color="primary" (click)="showAddNewModal()" [disabled]="!canAddNewPages || isReadOnly">
      Neues Aufmaßblatt
    </button>
    <button mat-raised-button color="primary" (click)="exportData()">Export</button>
    <button mat-raised-button color="primary" (click)="showSheet()" *ngIf="invoice && structureView === 'invoices'">Mengenvergleich</button>
    <button mat-raised-button color="primary" (click)="showQuantity()" *ngIf="invoice && structureView === 'estimations'">
      Projektmengen
    </button>
  </div>
  <h2 *ngIf="!canAddNewPages" class="red">Schreibgeschützt</h2>
</ng-container>

<div *ngIf="!iSModalMode" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px" class="head">
  <ng-container *ngIf="structureView === 'invoices'" [formGroup]="performanceQtoDate">
    <div class="flex-item" [class.hidden]="!performanceQtoDate.value.performancePeriodStartUtc">
      <mat-form-field appearance="fill" class="datepicker field-short">
        <mat-label>Ausführungsbeginn</mat-label>
        <input matInput [matDatepicker]="pickerStart" formControlName="performancePeriodStartUtc" (dateChange)="saveQTOData()" />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerStart"
          [matTooltip]="performanceQtoDate.value.performancePeriodStartUtc ? '' : 'Ausführungsbeginn'"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerStart [disabled]="isReadOnly"></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="flex-item" [class.hidden]="!performanceQtoDate.value.performancePeriodEndUtc">
      <mat-form-field appearance="fill" class="datepicker field-short">
        <mat-label>Ausführungsende</mat-label>
        <input matInput [matDatepicker]="pickerEnd" formControlName="performancePeriodEndUtc" (dateChange)="saveQTOData()" />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerEnd"
          [matTooltip]="performanceQtoDate.value.performancePeriodEndUtc ? '' : 'Ausführungsende'"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerEnd [disabled]="isReadOnly"></mat-datepicker>
      </mat-form-field>
    </div>
  </ng-container>
</div>

<div class="table-wrapper" [style.maxHeight]="'calc(100%  - 285px )'">
  <table mat-table [dataSource]="pageQuantityTakeOffsService" matSort (matSortChange)="pageQuantityTakeOffsService.onSort($event)">
    <ng-container matColumnDef="pageNumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Nummer</th>
      <td mat-cell *matCellDef="let row" matRipple>{{ row.pageNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="selectPage">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Ausgewählt</th>
      <td mat-cell *matCellDef="let row" matRipple>
        <mat-checkbox [checked]="getSelectedPageValue(row.id)" (change)="changeSelectionPage($event, row)"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="isChecked">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Geprüft</th>
      <td mat-cell *matCellDef="let row" matRipple>
        <mat-checkbox [(ngModel)]="row.isChecked" disabled></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let row" matRipple>
        {{ row.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="subContractor">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Subunternehmer</th>
      <td mat-cell *matCellDef="let row" matRipple>
        {{ row.subContractorName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="buildingElementCode">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>BT Code</th>
      <td mat-cell *matCellDef="let row" matRipple>
        {{ row.buildingElementCode }}
      </td>
    </ng-container>
    <ng-container matColumnDef="modifiedAtUtc">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Letzte Änderung</th>
      <td mat-cell *matCellDef="let row" matRipple>
        {{ row.modifiedAtUtc | date: 'dd.MM.yyyy HH:mm' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="totalSum" matRipple>
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="right">Abrechnungssumme</th>
      <td mat-cell *matCellDef="let row" class="right">
        <ng-container *ngIf="row.totalSum != null"> {{ row.totalSum | projectCurrency }} </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="center" (click)="$event.stopPropagation()">
        <button mat-icon-button color="primary" (click)="showContextMenu($event, row)">
          <mat-icon>more_vert</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-header-row"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selectRow(row.id)"></tr>
  </table>
</div>

<pa-upper-paginator
  [pageSizeOptions]="[5, 10, 20, 25, 100]"
  [upperPaginated]="pageQuantityTakeOffsService.paginationResult | async"
  [upperService]="pageQuantityTakeOffsService"
  (page)="pageQuantityTakeOffsService.onPage($event)"
>
</pa-upper-paginator>

<div fxLayoutGap="12px" class="btn-wrap" *ngIf="iSModalMode">
  <button mat-button mat-raised-button color="primary" (click)="saveSelectedPages()">Speichern</button>
  <button mat-button mat-raised-button color="warn" type="button" (click)="dismiss()">Abbrechen</button>
</div>
<pa-invoice-table-menu
  [isPages]="true"
  [contextMenuPosition]="contextMenuPosition"
  (invoiceMenuActions)="handlerMenuAction($event)"
  [disabledObject]="disabledObject"
></pa-invoice-table-menu>
