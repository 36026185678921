<div *ngIf="contact">
  <button mat-raised-button color="primary" (click)="back()">Zurück</button>
  <dt>
    <strong>Nummer</strong>
  </dt>
  <dd>
    {{ contact.contactNumber }}
  </dd>
  <dt>
    <strong>Name</strong>
  </dt>
  <dd>
    {{ contact.name }}
  </dd>
  <dt *ngIf="contact.nameAddition">
    <strong>Namenszusatz</strong>
  </dt>
  <dd *ngIf="contact.nameAddition">
    {{ contact.nameAddition }}
  </dd>
  <dt>
    <strong>Typ</strong>
  </dt>
  <dd>
    {{ contact.contactType | contactType }}
  </dd>
  <dt *ngIf="contact.vatId">
    <strong>USt Id</strong>
  </dt>
  <dd *ngIf="contact.vatId">
    {{ contact.vatId }}
  </dd>
  <div>
    <button class="edit-contact-button" mat-raised-button (click)="editContact()">Bearbeiten</button>
    <button class="edit-contact-button" mat-raised-button color="warn" (click)="removeContact()">Löschen</button>
  </div>
</div>
<h3>Adressen</h3>
<pa-contact-addresses></pa-contact-addresses>
<h3>Kontaktpersonen</h3>
<pa-contact-persons></pa-contact-persons>
<pa-contact-terms></pa-contact-terms>
