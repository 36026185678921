import { Injectable, Inject } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { getAppConfig } from '../../app-config-accessor';
import { StatusClient } from '../../generated-client/generated-client';

import { ElectronService } from './electron/electron.service';

@Injectable({
  providedIn: 'root'
})
export class AutoUpdateCheckerService {
  private readonly localStorageSkipVersionKey = 'AUTO_UPDATE_SKIP_VERSION';
  private latestVersion: string;

  constructor(
    private statusClient: StatusClient,
    @Inject('APP_VERSION') public version: string,
    private electronService: ElectronService
  ) {}

  checkForNewVersion(): Observable<boolean> {
    return this.statusClient.getStatus().pipe(
      map((status) => {
        // The check basically just makes sure that the app version
        // is the same as the server version. Since we're never expecting
        // any downgrades in the server side, a different version means
        // always a newer version.
        this.latestVersion = status.version;
        return status.version !== this.version;
      })
    );
  }

  /**
   * This will return true if the user has selected to no longer prompt for updates
   * for the given version.
   */
  shouldSkipThisVersion(version: string): boolean {
    return localStorage.getItem(this.localStorageSkipVersionKey) === version;
  }

  getLatestVersion(): string | null {
    return this.latestVersion || null;
  }

  skipCurrentVersion(): void {
    localStorage.setItem(this.localStorageSkipVersionKey, this.latestVersion);
  }

  goToDownloadPage(latestVersion: string): void {
    latestVersion = latestVersion || this.latestVersion;
    const downloadUrl = `https://docs.dangl-it.com/ProjectAssets/${getAppConfig().danglDocuProjectName}/${latestVersion}`;
    if (this.electronService.isElectron) {
      // This will only work in Electron environments
      this.electronService.shell.openExternal(downloadUrl);
    } else {
      // Outside of electron, this doesnt really make sense, so we're just redirecting:
      window.open(downloadUrl);
    }
  }
}
