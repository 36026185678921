<div class="wrapper">
  <div fxLayoutAlign="center center" class="spinner" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>
  <div class="actions">
    <div fxLayout="column" fxLayoutGap="12px" fxLayoutAlign="space-between" class="actions-row">
      <div #expansionPanelWrap class="expansion-panel-wrap">
        <mat-expansion-panel hideToggle [(expanded)]="isExpansionPanelExpanded">
          <mat-expansion-panel-header>
            <mat-panel-title>Druckoptionen</mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row" fxLayoutGap="10px" class="btn-selection-wrap">
            <div fxLayout="column">
              <mat-checkbox [(ngModel)]="includeSumsTable">Summenblatt</mat-checkbox>
              <mat-checkbox [(ngModel)]="includeSumsPerPage" [disabled]="!includeSumsTable">Summen je Blatt</mat-checkbox>
              <mat-checkbox [(ngModel)]="includeTotalsByServicePeriodCode" *ngIf="!isQtoOnlyMode" [disabled]="!includeSumsTable"
                >Summen je LZ</mat-checkbox
              >
              <mat-checkbox [(ngModel)]="includeSumsByServicePeriodAndPage" *ngIf="!isQtoOnlyMode" [disabled]="!includeSumsTable"
                >Summen je LZ & Blatt</mat-checkbox
              >
              <mat-checkbox [(ngModel)]="includePagesOverview" [disabled]="!includePagesOverview">Aufmaßblattübersicht</mat-checkbox>
            </div>
            <div fxLayout="column">
              <mat-checkbox [(ngModel)]="includeTotalsTable" *ngIf="!isQtoOnlyMode">Rechnungsblatt</mat-checkbox>
              <mat-checkbox [(ngModel)]="includeAttachments">Anlagen ausgeben</mat-checkbox>
            </div>
            <div fxLayout="column">
              <mat-checkbox [(ngModel)]="includeQtoRows">Ansatzzeilen mit ausgeben</mat-checkbox>
              <mat-checkbox [(ngModel)]="includeHeaderOnlyOnFirstPage" *ngIf="!isQtoOnlyMode"
                >Kopfzeile nur auf der ersten Seite drucken</mat-checkbox
              >
              <mat-checkbox [(ngModel)]="includePreviousQuantities" *ngIf="!isQtoOnlyMode" [disabled]="!includeSumsTable"
                >Mengen aus vorherigen Abrechnungen im Summenblatt</mat-checkbox
              >
            </div>
            <div fxLayout="column" fxLayoutAlign="end">
              <mat-form-field class="input-base">
                <mat-label>Zeilenabstand in mm</mat-label>
                <input matInput type="number" min="0" max="100" [(ngModel)]="addMargin" class="add-margin" />
              </mat-form-field>
              <mat-form-field class="input-base">
                <mat-label>Start Seitennummer</mat-label>
                <input matInput type="number" min="1" [(ngModel)]="startPage" class="add-margin" />
              </mat-form-field>
            </div>
          </div>
          <pa-selecting-element-buttons
            (elementSelectionChanged)="elementSelection = $event"
            [isShowElementSelectionTableButton]="false"
          ></pa-selecting-element-buttons>
          <div class="margin-bottom-10" *ngIf="pages && pages.pages.length > 1">
            <div fxLayoutGap="10px" class="btn-selection-wrap">
              <button color="primary" mat-raised-button (click)="selectPages()">Blätter</button>
              <button color="warn" mat-raised-button (click)="pagesSelection = null" [disabled]="!pagesSelection">Alle Blätter</button>
            </div>
            <mat-hint *ngIf="pagesSelection?.length">Nur ausgewählte Blätter werden gedruckt</mat-hint>
          </div>
        </mat-expansion-panel>
      </div>
      <div fxLayoutGap="10px" class="btn">
        <ng-template #positionReport>
          <button color="primary" [disabled]="showingPdf || isLoading" mat-raised-button (click)="generatePdfAndShowPrintView()">
            Druckansicht
          </button>
        </ng-template>

        <button
          *ngIf="isPagesReport; else positionReport"
          color="primary"
          [disabled]="showingPdf || isLoading"
          mat-raised-button
          (click)="generateGroupedPageReportPdfAndShowPrintView()"
        >
          Druckansicht
        </button>
        <button color="primary" [disabled]="showingPdf || isLoading" mat-raised-button (click)="generateAndDownloadFile(ExportType.D11)">
          D11 Export
        </button>
        <button color="primary" [disabled]="showingPdf || isLoading" mat-raised-button (click)="generateAndDownloadFile(ExportType.X31)">
          X31 Export
        </button>
        <button mat-raised-button mat-dialog-close>Abbrechen</button>
      </div>
    </div>
  </div>
  <ng-container *ngIf="positions">
    <div class="header">
      {{ word }}
      <ng-container *ngTemplateOutlet="main"></ng-container>
      <div>Nummer {{ report.quantityTakeOffNumber }}</div>
      <div>{{ word }}: {{ report.quantityTakeOffName }}</div>
    </div>

    <div
      class="table nofloat"
      [style.height]="isExpansionPanelExpanded ? 'calc(100% - 270px - ' + expansionPanelWrap.offsetHeight + 'px)' : 'calc(100% - 270px)'"
    >
      <table>
        <ng-container *ngFor="let position of positions.positionQuantities">
          <tr class="grey">
            <th colspan="3" class="left">{{ position.quantityTakeOff.itemNumber }} {{ position.position | positionText }}</th>
            <th class="right">{{ position.position.unitTag }}</th>
          </tr>
          <tr>
            <th>#</th>
            <th class="left">Ansatz</th>
            <th class="summ right">Ergebnis</th>
            <th class="summ right">Summe</th>
          </tr>
          <ng-container *ngFor="let row of position.quantityTakeOff.rows">
            <tr [class.internal]="row.rowType === 'I'" [matTooltip]="innerTooltip(row.rowType)">
              <td class="center">#{{ row.rowIndex }}</td>
              <td [ngClass]="{ bold: row.isBold, cursive: row.isCursive, underlined: row.isUnderlined }" class="formula">
                <ng-container *ngIf="row.rowType === 'Z'">( Z )</ng-container>
                {{ row.formula }}
                <div *ngIf="row.referenceName">
                  <span class="bold">{{ row.referenceName }}</span>
                </div>
              </td>
              <td class="summ right" [ngStyle]="row.rowType === 'Z' && { color: 'grey' }">
                <ng-container *ngIf="row.rowType === 'Z'">( Z )</ng-container>{{ row.result | number: '1.3-3' }}
              </td>
              <td class="summ right">{{ row.totalCost | projectCurrency }}</td>
            </tr>
            <tr *ngIf="row.fileId">
              <td colspan="4" class="image">
                <img src="{{ row.fileId | imgSrc | async }}" />
              </td>
            </tr>
            <tr class="attachments" *ngIf="row.quantityTakeOffAttachmentImageFileId">
              <td class="center">Referenz zu Anlage</td>
              <td colspan="3" class="right">{{ row.quantityTakeOffAttachmentImageFileId }}</td>
            </tr>
          </ng-container>
          <tr>
            <td colspan="2" class="bold">Summe</td>
            <td class="summ right bold">
              {{ summ(position.quantityTakeOff.rows, 'result') | number: '1.3-3' }} {{ position.position.unitTag }}
            </td>
            <td class="summ right bold">{{ position.quantityTakeOff.totalSum | projectCurrency }}</td>
          </tr>
        </ng-container>
      </table>
    </div>
  </ng-container>

  <ng-container *ngIf="pages">
    <div class="header">
      {{ word }}
      <ng-container *ngTemplateOutlet="main"></ng-container>
      <div>Nummer {{ report.quantityTakeOffNumber }}</div>
      <div>{{ word }}: {{ report.quantityTakeOffName }}</div>
    </div>
    <div
      class="table nofloat"
      [style.height]="isExpansionPanelExpanded ? 'calc(100% - 270px - ' + expansionPanelWrap.offsetHeight + 'px)' : 'calc(100% - 270px)'"
    >
      <table>
        <ng-container *ngFor="let page of pages.pages">
          <tr class="unborder">
            <td colspan="4">
              <div class="title">{{ page.pageNumber }} - {{ page.pageName }}</div>
            </td>
          </tr>
          <tr>
            <th>#</th>
            <th class="left">Ansatz</th>
            <th class="summ right">Ergebnis</th>
            <th class="left">Einheit</th>
          </tr>
          <ng-container *ngFor="let row of page.rows">
            <tr [class.internal]="$any(row)?.rowType === 'I'" [matTooltip]="innerTooltip($any(row)?.rowType)">
              <td class="center" *ngIf="!$any(row)?.totalQuantity">
                <span *ngIf="$any(row)?.result">{{ $any(row)?.rowIndex }}</span>
                <span></span>
              </td>
              <td
                *ngIf="!$any(row)?.itemNumber"
                [ngClass]="{ bold: $any(row)?.isBold, cursive: $any(row)?.isCursive, underlined: $any(row)?.isUnderlined }"
                [ngStyle]="{ color: $any(row)?.color, 'font-size': getSize($any(row)?.textSizeFactor) }"
                class="formula"
              >
                <ng-container *ngIf="$any(row)?.rowType === 'Z'">( Z )</ng-container>
                {{ $any(row)?.textFormula }}
                <div *ngIf="$any(row)?.referenceName">
                  <span class="bold">{{ $any(row)?.referenceName }}</span>
                </div>
              </td>
              <td
                *ngIf="$any(row)?.itemNumber"
                [ngClass]="{ bold: !$any(row)?.totalQuantity }"
                [attr.colspan]="$any(row)?.totalQuantity ? 2 : 1"
              >
                <span *ngIf="$any(row)?.totalQuantity">Summe</span>
                {{ $any(row)?.itemNumber }}-{{ $any(row)?.shortText }}
              </td>

              <td class="summ right" [ngStyle]="$any(row)?.rowType === 'Z' && { color: 'grey' }">
                <span *ngIf="$any(row)?.rowType === 'Z'" [style.paddingRight]="'0.6rem'">( Z )</span
                >{{ $any(row)?.result || $any(row)?.totalQuantity | number: '1.3-3' }}
              </td>
              <td>{{ $any(row)?.unitTag }}</td>
            </tr>
            <tr *ngIf="$any(row)?.fileId">
              <td colspan="4" class="image">
                <img src="{{ $any(row)?.fileId | imgSrc | async }}" />
              </td>
            </tr>
            <tr class="attachments" *ngIf="$any(row).quantityTakeOffAttachmentImageFileId">
              <td class="center">Referenz zu Anlage</td>
              <td colspan="3" class="right">{{ $any(row).quantityTakeOffAttachmentImageFileId }}</td>
            </tr>
          </ng-container>
        </ng-container>

        <tr class="unborder">
          <td colspan="4" class="title">Summenblatt</td>
        </tr>
        <tr *ngFor="let total of pages.elementSums">
          <ng-container *ngIf="!total.hideInSumPage">
            <td colspan="2" [class.bold]="total.isBold">
              {{ total.name }}
            </td>
            <td class="summ right">
              {{ total.totalQuantity | number: '1.3-3' }}
            </td>
            <td>
              {{ total.unitTag }}
            </td>
          </ng-container>
        </tr>
      </table>
    </div>
  </ng-container>

  <ng-template #main>
    <img class="logo" [src]="report.companyLogoBase64DataUrl" alt="" />
    <div>Projekt: {{ report.projectNumber }}</div>
    <div>
      {{ report.projectName }}
    </div>
    <div>Leistungsverzeichnis: {{ report.serviceSpecificationName }}</div>
    <div>
      <strong>Summe: {{ report.totalSum | projectCurrency }}</strong>
    </div>
    <div>LV-Summe: {{ report.serviceSpecificationSum | projectCurrency }}</div>
    <div *ngIf="report.donePercentage != null">Abgerechnet: {{ report.donePercentage | percent: '1.2-2' }}</div>
  </ng-template>
</div>
