import { NgIf } from '@angular/common';
import { Component, Inject, Optional } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { UploadFileSettings } from '@shared/components/upload-file/upload-file.component';
import { AvaNotificationsService } from '@shared/services/ava-notifications.service';

import { AvaProjectGet, AvaProjectsClient } from 'app/generated-client/generated-client';

import { UploadFileComponent } from '../../../../../../shared/components/upload-file/upload-file.component';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-service-specification-update-dialog',
  templateUrl: './service-specification-update-dialog.component.html',
  styleUrls: ['./service-specification-update-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    UploadFileComponent,
    NgIf,
    MatDialogActions,
    MatButton,
    FlexLayoutDirective,
    MatProgressSpinner
  ]
})
export class ServiceSpecificationUpdateDialogComponent {
  uploadFileSettings: UploadFileSettings = {
    label: 'Datei zum Hochladen hierher ziehen',
    isMultipleSelection: false,
    height: '200px'
  };

  selectedFile: {
    data: File;
    fileName: string;
  };
  isLoading = false;

  constructor(
    @Optional() private matDialogRef: MatDialogRef<ServiceSpecificationUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      avaProject: AvaProjectGet;
      projectId: string;
    },
    private avaNotificationsService: AvaNotificationsService,
    private avaProjectsClient: AvaProjectsClient
  ) {}

  onSelect(fileList: File[]): void {
    if (!fileList.length) {
      return;
    }

    const addedFile = fileList[0];
    /** here also need to check whether the application works as an electron,
     * because in the web version the browser does not have access to the path to the file*/
    if (addedFile.size === 0) {
      return;
    }

    this.selectedFile = {
      data: addedFile,
      fileName: addedFile.name
    };
  }

  async update(): Promise<void> {
    this.isLoading = true;

    this.avaProjectsClient
      .updateAvaProjectContentFromAvaFile(this.data.projectId, this.data.avaProject.id, {
        serviceSpecificationFile: {
          base64Content: await this.getBase64ForSelectedFile(),
          fileName: this.selectedFile.fileName,
          mimeType: 'application/octet-stream'
        }
      })
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.avaNotificationsService.success('Das LV wurde aktualisiert');
          this.matDialogRef.close(true);
        },
        error: () => {
          this.isLoading = false;
          this.avaNotificationsService.error('Fehler beim Aktualisieren des LVs');
        }
      });
  }

  private getBase64ForSelectedFile(): Promise<string> {
    return new Promise<string>((resolve) => {
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(this.selectedFile.data);
      reader.onload = function () {
        let encoded: string = reader.result.toString().replace(/^data:(.*,)?/, '');
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
    });
  }

  cancel(): void {
    this.matDialogRef.close();
  }
}
