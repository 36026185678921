import { NgIf } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { MatHint } from '@angular/material/form-field';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { first } from 'rxjs';

import {
  PageQuantityTakeOffOverviewGet,
  QuantityTakeOffCalculationType,
  QuantityTakeOffGet,
  ReportsClient
} from 'app/generated-client/generated-client';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { PrintViewMessengerService } from 'app/shared/services/electron/print-view-messenger.service';
import { QuantityTakeOffsService } from 'app/shared/services/lightquery/quantity-take-offs.service';
import { ModalService } from 'app/shared/services/modal.service';

import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';

import { InvoiceMultiSelectQtoDialogComponent } from '../invoice-multi-select-qto-dialog/invoice-multi-select-qto-dialog.component';
import { InvoicesPagesMultiSelectComponent } from '../invoices-pages-multi-select/invoices-pages-multi-select.component';

@Component({
  selector: 'pa-invoice-bulk-qto-export-dialog',
  templateUrl: './invoice-bulk-qto-export-dialog.component.html',
  styleUrls: ['./invoice-bulk-qto-export-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, NgIf, FlexLayoutDirective, MatButton, MatHint, MatProgressSpinner]
})
export class InvoiceBulkQtoExportDialogComponent implements OnInit {
  public showingPdf = false;
  isLoading = false;
  pagesSelection: PageQuantityTakeOffOverviewGet[] | null = null;
  qtoSelection: QuantityTakeOffGet[] | null = null;
  calculationType: QuantityTakeOffCalculationType;
  hasOnlyOne = true;
  allQtoCurrentType: QuantityTakeOffGet[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      avaProjectId: string;
      projectId: string;
      qto: QuantityTakeOffGet;
    },
    @Optional() private dialogRef: MatDialogRef<InvoiceBulkQtoExportDialogComponent>,
    private reportsClient: ReportsClient,
    private printViewMessengerService: PrintViewMessengerService,
    private avaNotificationsService: AvaNotificationsService,
    private modalService: ModalService,
    private quantityTakeOffsService: QuantityTakeOffsService
  ) {}

  ngOnInit(): void {
    this.calculationType = this.data?.qto?.calculationType;
    this.checkOneQtoType();
  }

  selectPages(): void {
    this.modalService
      .openModal(InvoicesPagesMultiSelectComponent, {
        dialogType: ConfirmationType.General,
        data: {
          selectedQto: this.getArrayQto(),
          quantityTakeOffType: this.data.qto.quantityTakeOffType,
          selectedPage: this.pagesSelection
        }
      })
      .afterClosed()
      .subscribe((pages) => {
        if (pages?.length) {
          this.pagesSelection = pages;
        }
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  generatePdfAndShowPreview(): void {
    this.isLoading = true;

    const pagesToPrint: string[] = this.pagesSelection?.length ? this.pagesSelection.map((p) => p.id) : null;
    this.reportsClient
      .getQuantityTakeOffSummaryReport(this.data.avaProjectId, false, true, false, {
        includePrices: true,
        quantityTakeOffIds: this.getArrayQto().map((qto) => qto.id),
        pageIdsToInclude: pagesToPrint
      })
      .subscribe({
        next: (report) => {
          this.printViewMessengerService.showPdfPreview(report.htmlReport, '', report.isLandscape, report.pdfReportBase64, false);
          this.printViewMessengerService.waitForPrintViewReady().subscribe(() => {
            this.isLoading = false;
            this.showingPdf = true;
          });
          this.printViewMessengerService.printViewClosed.pipe(first()).subscribe(() => {
            this.showingPdf = false;
            this.isLoading = false;
          });
        },
        error: () => {
          this.isLoading = false;
          this.avaNotificationsService.error('Fehler beim Erstellen der Rechnung');
        }
      });
  }

  selectQTOs(): void {
    this.modalService
      .openModal(InvoiceMultiSelectQtoDialogComponent, {
        dialogType: ConfirmationType.General,
        data: {
          selectedQto: this.getArrayQto(),
          quantityTakeOffType: this.data.qto.quantityTakeOffType,
          calculationType: this.calculationType
        }
      })
      .afterClosed()
      .subscribe((qtoSelection) => {
        if (qtoSelection?.length) {
          this.qtoSelection = qtoSelection;
          this.checkRemovedQtoForPages();
        }
      });
  }

  checkRemovedQtoForPages(): void {
    if (this.pagesSelection?.length) {
      const listID = this.getArrayQto().map((qto) => qto.id);
      this.pagesSelection = this.pagesSelection.filter((page) => listID.includes(page.quantityTakeOffId));
    }
  }

  getArrayQto(): QuantityTakeOffGet[] {
    return this.qtoSelection ?? [this.data.qto];
  }

  checkOneQtoType(): void {
    this.quantityTakeOffsService
      .getAll({ quantityTakeOffType: this.data.qto?.quantityTakeOffType, calculationType: this.calculationType })
      .subscribe((list) => {
        this.allQtoCurrentType = list;
        this.hasOnlyOne = !(this.allQtoCurrentType?.length > 1);
      });
  }
}
