import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

/**
 * This directive enables row selection in a table by capturing mouse events and emitting selected row indices
 * It can be used to select lines when we need to select them by holding down the mouse button or
 * using the Shift button.
 * Example
 *  <table paRowSelection (rowSelected)="onRowSelected($event)">
 *   <tr>
 *     <td class="index">1</td>
 *     <!-- other cells -->
 *
 *   </tr>
 *   <!-- more rows -->
 * </table>
 *
 *    Or for material tables, the column should have the name index
    <ng-container matColumnDef="index">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let row; let idx = index">
        {{ idx + 1 }}
      </td>
    </ng-container>
 */
@Directive({
  selector: '[paRowSelection]',
  standalone: true
})
export class RowSelectionDirective {
  @Output() rowSelected = new EventEmitter<{
    firstSelectedIndex: number | null;
    lastSelectedIndex: number | null;
  }>();
  @Output() selectionProcessActivated: EventEmitter<{
    firstSelectedIndex: number | null;
    isSelectionActive: boolean;
  }> = new EventEmitter();

  firstSelectedIndex: number | null = null;
  lastSelectedIndex: number | null = null;

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (event.target['className'].includes('index')) {
      const rowIndex = +(event.target as HTMLElement).innerText;
      if (event.shiftKey && this.firstSelectedIndex) {
        return;
      }
      if (!isNaN(rowIndex)) {
        this.firstSelectedIndex = rowIndex;
      } else {
        this.firstSelectedIndex = null;
      }
    }
    this.selectionProcessActivated.emit({
      firstSelectedIndex: this.firstSelectedIndex,
      isSelectionActive: true
    });
  }

  @HostListener('mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    let startIndex, endIndex;

    this.selectionProcessActivated.emit({
      firstSelectedIndex: null,
      isSelectionActive: false
    });

    if (this.firstSelectedIndex === null) {
      return;
    }

    if (event.target['className'].includes('index')) {
      const rowIndex = +(event.target as HTMLElement).innerText;
      if (!isNaN(rowIndex)) {
        this.lastSelectedIndex = rowIndex;
      } else {
        this.lastSelectedIndex = null;
      }
    }

    if (this.firstSelectedIndex !== null && this.lastSelectedIndex !== null) {
      startIndex = Math.min(this.firstSelectedIndex, this.lastSelectedIndex) - 1;
      endIndex = Math.max(this.firstSelectedIndex, this.lastSelectedIndex) - 1;
    }

    if (this.firstSelectedIndex === this.lastSelectedIndex) {
      return;
    }

    if (this.lastSelectedIndex === null) {
      startIndex = this.firstSelectedIndex - 1;
      endIndex = null;
    }

    this.rowSelected.emit({
      firstSelectedIndex: startIndex,
      lastSelectedIndex: endIndex
    });

    this.resetSelection();
  }

  constructor() {}

  private resetSelection(): void {
    this.firstSelectedIndex = null;
    this.lastSelectedIndex = null;
  }
}
