<div mat-dialog-content>
  <h3>Fehlerbericht erstellen</h3>
  <p>
    Du kannst kurz beschreiben, was nicht geklappt hat und den Screenshot der App anhängen. Bitte schreib, was Du machen wolltest und was
    nicht geklappt hat.
  </p>

  <form #errorReportForm="ngForm" (ngSubmit)="sendReport()">
    <div fxLayout="row" fxLayoutGap="8px">
      <img class="preview-image" fxFlex="60" *ngIf="base64Image" [src]="base64ImageUrl" />
      <div fxLayout="column" fxFlex="40">
        <mat-form-field class="full-width">
          <mat-label>Fehlerbericht</mat-label>
          <textarea id="error-input" required matInput [(ngModel)]="errorDescription" name="errorDescriptionInput"></textarea>
        </mat-form-field>
        <div *ngIf="base64Image">
          <mat-checkbox [(ngModel)]="attachImage" name="attachImageCheckbox">Bild anhängen</mat-checkbox>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <button mat-button mat-raised-button type="submit" [disabled]="!errorReportForm.valid">Abschicken</button>
          <button mat-button mat-raised-button color="warn" type="button" (click)="dismiss()">Abbrechen</button>
        </div>
      </div>
    </div>
  </form>
</div>
