// This file is automatically generated as part of the build process

export const version = {
    version: "0.1.8-beta8707",
    commitInfo: "Branch.develop.Sha.17b4530ad8b08e24ee00af81879706cf5a7a608e",
    commitDate: "2024-08-29",
    commitHash: "17b4530ad8b08e24ee00af81879706cf5a7a608e",
    informationalVersion: "0.1.8-beta.8707+Branch.develop.Sha.17b4530ad8b08e24ee00af81879706cf5a7a608e",
    buildDateUtc: new Date(Date.UTC(2024, 7, 29, 12, 38, 10))
}
