import { Component, Inject, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogContent, MatDialogClose } from '@angular/material/dialog';

import { ModalService } from '../../../../../../shared/services/modal.service';

@Component({
  selector: 'pa-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
  standalone: true,
  imports: [MatDialogContent, MatButton, MatDialogClose]
})
export class ErrorModalComponent implements OnInit {
  errorMessage: string;

  constructor(@Inject(ModalService) private modalData: any) {}

  ngOnInit(): void {
    this.errorMessage = this.modalData.modalData;
  }
}
