import { CdkDragDrop, CdkDragMove, CdkDragStart, CdkDropList, CdkDrag } from '@angular/cdk/drag-drop';
import { NgIf, NgFor, NgClass, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatIcon } from '@angular/material/icon';

import { Observable, Subject, Subscription } from 'rxjs';
import { combineLatestWith, debounceTime, map, take, takeUntil, throttleTime } from 'rxjs/operators';

import { ElementFilterService } from '@serv-spec/services/element-filter.service';

import { ClickerService } from 'app/areas/tree/services/clicker.service';
import { FlatElementsService } from 'app/areas/tree/services/flat-elements.service';
import { ModePageService } from 'app/areas/tree/services/mode-page.service';
import { TreeNodeMarkService } from 'app/areas/tree/services/tree-node-mark.service';
import { TreeNodeSelectingService } from 'app/areas/tree/services/tree-node-selecting.service';
import {
  ExecutionDescriptionDto,
  IElementDto,
  NoteTextDto,
  PositionDto,
  ServiceSpecificationGroupDto,
  TreeViewDisplayType
} from 'app/generated-client/generated-client';
import { SelectingElementsType } from 'app/shared/models';
import { GroupViewService } from 'app/shared/services/group-view.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';
import { PositionHasCalculationService } from 'app/shared/services/position-has-calculation.service';

import { NodeTextPipe } from '../../pipes/node-text.pipe';
import { NodeWithoutCalculationPipe } from '../../pipes/node-without-calculation.pipe';
import { ShowPositionAbbreviationPipe } from '../../pipes/show-position-abbreviation.pipe';

import { ContextMenuSettingsService } from './../../../../shared/services/context-menu-settings.service';
import { PositionTextAdditionService } from './../../services/position-text-addition.service';
import { TreeNodeDragingService } from './../../services/tree-node-draging.service';
import { TreeMenuComponent } from './../tree-menu/tree-menu.component';

@Component({
  selector: 'pa-tree-list-view',
  templateUrl: './tree-list-view.component.html',
  styleUrls: ['./tree-list-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    CdkDropList,
    NgFor,
    NgClass,
    NgTemplateOutlet,
    MatIconButton,
    MatIcon,
    MatCheckbox,
    FormsModule,
    CdkDrag,
    TreeMenuComponent,
    AsyncPipe,
    ShowPositionAbbreviationPipe,
    NodeWithoutCalculationPipe,
    NodeTextPipe
  ]
})
export class TreeListViewComponent implements OnInit, OnDestroy {
  @Input() isSelectingMode = false;

  @Input() set choseElementId(id: string | null) {
    this.choseSelectedElementId = id;
  }
  choseSelectedElementId: string | null;

  @Input() filter: Subject<string> = new Subject();

  @ViewChild(TreeMenuComponent) treeMenuComponent: TreeMenuComponent;

  private $destroy: Subject<boolean> = new Subject<boolean>();
  positionIdsWithoutCalculations$ = this.positionHasCalculationService.positionIdsWithoutCalculations;
  selectedElementId$ = this.selectedSpecificationElementMessengerService.selectedElement.pipe(map((e) => e?.id));
  markedElements$ = this.treeNodeMarkService.treeNodeMarkElements;
  dragMovedEvent$: Subscription;
  isShowEmptyIcon$: Observable<boolean>;

  flatElementsDto: (ServiceSpecificationGroupDto | NoteTextDto | ExecutionDescriptionDto | PositionDto)[];
  projectGroupView: TreeViewDisplayType;
  checkedElements: {
    [elementId: string]: SelectingElementsType;
  } = {};
  contextMenuPosition = { x: '0px', y: '0px' };
  modePage: string;
  /**  This property is used to indicate if the search filter for the tree elements is just initializing,
   * meaning it was null before and just got a value. Due to the debounce time of the filter function
   * there would otherwise be a short gap where we already show the filtered elements, but don't have any
   * filter applied yet, so it would show as "no elements found" for a short time when we enter a filter */
  filterInitializing = false;
  constructor(
    public flatElementsService: FlatElementsService,
    private positionHasCalculationService: PositionHasCalculationService,
    public selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private cdr: ChangeDetectorRef,
    private clickerService: ClickerService,
    private groupViewService: GroupViewService,
    private elementFilterService: ElementFilterService,
    public modePageService: ModePageService,
    private ngZone: NgZone,
    private treeNodeDragingService: TreeNodeDragingService,
    private contextMenuSettingsService: ContextMenuSettingsService,
    private treeNodeSelectingService: TreeNodeSelectingService,
    private positionTextAdditionService: PositionTextAdditionService,
    private treeNodeMarkService: TreeNodeMarkService
  ) {}

  ngOnInit(): void {
    this.flatElementsService.flatElementsDto
      .pipe(
        combineLatestWith(
          this.groupViewService.projectGroupView,
          this.filter.pipe(debounceTime(250)),
          this.positionTextAdditionService.modePositionTextAddition
        ),
        takeUntil(this.$destroy)
      )
      .subscribe(([flatElementsDto, projectGroupView, filter, modePositionTextAddition]) => {
        this.projectGroupView = projectGroupView;
        const elements = !filter ? flatElementsDto : this.elementFilterService.filterElements(flatElementsDto, filter);
        if (modePositionTextAddition) {
          const listPositions = elements.filter((e) => e.elementTypeDiscriminator === 'PositionDto');
          this.positionTextAdditionService.listPositionTextAddition.pipe(take(1)).subscribe((list) => {
            this.flatElementsDto = listPositions.filter((e) => list.includes(e.id));
            this.filterInitializing = true;
            this.cdr.markForCheck();
          });
          return;
        } else if (projectGroupView === TreeViewDisplayType.PositionList) {
          this.flatElementsDto = elements.filter((e) => e.elementTypeDiscriminator === 'PositionDto');
        } else {
          this.flatElementsDto = elements;
        }
        this.filterInitializing = true;
        this.cdr.markForCheck();
      });

    this.modePageService.modePage.pipe(takeUntil(this.$destroy)).subscribe((e) => {
      this.modePage = e;
    });

    if (this.isSelectingMode) {
      this.treeNodeSelectingService.treeNodeSelecting.pipe(takeUntil(this.$destroy)).subscribe((treeNodeSelecting) => {
        this.checkedElements = treeNodeSelecting;
        this.cdr.detectChanges();
      });
    }
    this.isShowEmptyIcon$ = this.treeNodeMarkService.treeNodeMarkElements.pipe(map((e) => !!Object.keys(e).length));
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  selectElementBySingl(e: IElementDto): void {
    this.clickerService.clickedBySingle(e);
  }

  selectElementByDouble(e: IElementDto): void {
    this.clickerService.clickedByDbl(e);
  }

  dragStart(event: CdkDragStart): void {
    if (this.modePage === 'lv-editor') {
      this.ngZone.runOutsideAngular(() => {
        //Perhaps the interval value for throttleTime can be increased
        this.dragMovedEvent$ = event.source.moved.pipe(throttleTime(100)).subscribe((e: CdkDragMove<any>) => {
          this.treeNodeDragingService.dragMoved(e);
        });
      });
    }
  }

  dragEnded(): void {
    if (this.dragMovedEvent$) {
      this.dragMovedEvent$.unsubscribe();
    }
  }

  drop(event: CdkDragDrop<IElementDto[]>): void {
    if (this.modePage !== 'lv-editor') {
      return;
    }
    this.treeNodeDragingService.drop(event);
  }

  showContextMenu(event: MouseEvent, node: any): void {
    // Taken from https://stackblitz.com/edit/angular-material-context-menu-table?file=app%2Fcontext-menu-example.ts
    this.contextMenuSettingsService.setDefaultSettings(event, node as any, this.contextMenuPosition, this.treeMenuComponent.menu);
  }

  changeSelecting(element: IElementDto, event: boolean): void {
    this.treeNodeSelectingService.changeSelecting(event, element.id);
  }
}
