<div class="wrapper" *ngIf="!isLoadingPdf; else spiner">
  <button
    class="generate-pdf-button"
    mat-raised-button
    color="primary"
    (click)="generateReport()"
    [disabled]="
      !reportsForm.value.companyId &&
      !(reportsForm.value.projectsId && reportsForm.value.projectsId.length) &&
      !reportsForm.value.avaProjectsIds &&
      !(reportsForm.value.productsIds && reportsForm.value.productsIds.length) &&
      !reportsForm.value.startYear &&
      !reportsForm.value.endYear &&
      !reportsForm.value.projectStatus
    "
  >
    Nutzungsauswertung
  </button>

  <div [formGroup]="reportsForm">
    <div>
      <mat-form-field appearance="fill" class="qto">
        <mat-label>Mengentyp</mat-label>
        <mat-select formControlName="projectSheetQuantityType">
          <mat-option *ngFor="let itemType of listProjectSheetQuantityType" [value]="itemType.projectSheetQuantityType">{{
            itemType.text
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="column">
      <div fxLayout="column">
        <mat-checkbox name="includeProjectsReport" formControlName="includeProjectsReport">Mit Projektauflistung</mat-checkbox>
        <mat-checkbox name="includeHeaderOnlyOnFirstPage" formControlName="includeHeaderOnlyOnFirstPage"
          >Kopfzeile nur auf der ersten Seite drucken</mat-checkbox
        >
      </div>
      <div fxLayoutGap="12px" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="fill">
          <mat-label>Unternehmen</mat-label>
          <mat-select formControlName="companyId">
            <mat-option [value]="null">-</mat-option>
            <mat-option *ngFor="let companny of companiesList" [value]="companny.id">{{ companny.name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" class="product">
          <mat-label>Produkt</mat-label>
          <mat-select formControlName="productsIds" [multiple]="true" panelClass="product-pane">
            <pa-select-search [formControl]="productFilterCtrl"></pa-select-search>
            <mat-option *ngFor="let product of filteredProducts | async" [value]="product.id">
              <span class="select-option">{{ product.name }}</span>
              <span class="select-option">({{ product.unit }})</span>
              <mat-chip-listbox class="product-chiplist">
                <mat-chip-option>{{ product.priceComponentType | priceComponentType }} </mat-chip-option>
              </mat-chip-listbox>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div fxLayout="column">
          <mat-form-field appearance="fill">
            <mat-label>Projekte</mat-label>
            <mat-select formControlName="projectsId" [multiple]="false" (selectionChange)="selectedProject($event)">
              <pa-select-search [formControl]="projectsFilterCtrl"></pa-select-search>
              <mat-option *ngFor="let project of projectsList | async" [value]="project.id"
                >{{ project.constructedProjectNumber }} {{ project.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>LVs</mat-label>
            <mat-select formControlName="avaProjectsIds" multiple>
              <mat-option *ngFor="let avaProject of avaProjectList" [value]="avaProject.id">{{ avaProject.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <ul *ngFor="let selectedProject of selectedAvaProjects">
            <li>{{ selectedProject.name }}</li>
          </ul>
        </div>
        <mat-form-field appearance="fill">
          <mat-label>Projektstatus</mat-label>
          <mat-select name="status" formControlName="projectStatus">
            <mat-option *ngFor="let item of listStatus" [value]="item">{{ item | projectStatus }}</mat-option>
          </mat-select>
        </mat-form-field>
        <div fxLayout="column">
          <mat-form-field appearance="fill">
            <mat-label>Startdatum auswählen</mat-label>
            <input matInput [matDatepicker]="startYear" formControlName="startYear" />
            <mat-datepicker-toggle matSuffix [for]="startYear"></mat-datepicker-toggle>
            <mat-datepicker #startYear></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Enddatum auswählen</mat-label>
            <input matInput [matDatepicker]="endYear" formControlName="endYear" />
            <mat-datepicker-toggle matSuffix [for]="endYear"></mat-datepicker-toggle>
            <mat-datepicker #endYear></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #spiner>
  <div fxFlex fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
