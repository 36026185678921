<div class="wrapper">
  <div class="btn-wrap" fxLayoutGap="12px">
    <button mat-raised-button color="primary" (click)="goBack()" [disabled]="isLoading || showingPdf || isUpdating">Zurück</button>
    <button mat-raised-button color="primary" (click)="print()" [disabled]="isLoading || showingPdf || isUpdating || rla2.isActive">
      Drucken
    </button>
    <mat-checkbox [(ngModel)]="includePricesInPdfExport">Preise im Druck ausgeben</mat-checkbox>
    <mat-checkbox [(ngModel)]="includePositionsWithoutTotal">Eventualpositionen mit ausgeben</mat-checkbox>
    <mat-checkbox [(ngModel)]="includeHeaderOnlyOnFirstPage">Kopfzeile nur auf der ersten Seite drucken</mat-checkbox>
    <span class="summ"
      >LV-Summe: <span class="primary-color">{{ currentServiceSpecification?.totalPrice | projectCurrency }}</span></span
    >
  </div>
  <nav mat-tab-nav-bar [tabPanel]="tabPanel">
    <a
      mat-tab-link
      #rla="routerLinkActive"
      [routerLink]="'all'"
      routerLinkActive="active"
      [active]="rla.isActive"
      [disabled]="isLoading || showingPdf || isUpdating"
    >
      Alle
    </a>
    <a
      mat-tab-link
      #rla="routerLinkActive"
      *ngFor="let product of listPriceComponentType"
      [routerLink]="['product', product]"
      routerLinkActive="active"
      [active]="rla.isActive"
      [disabled]="isLoading || showingPdf || isUpdating"
    >
      {{ product | priceComponentType }}
    </a>
    <a
      mat-tab-link
      #rla2="routerLinkActive"
      [routerLink]="'catalogs'"
      routerLinkActive="active"
      [active]="rla2.isActive"
      [disabled]="isLoading || showingPdf || isUpdating"
    >
      Produktkataloge
    </a>
  </nav>
  <router-outlet #tabPanel></router-outlet>
  <div class="spinner" *ngIf="isLoading || isUpdating">
    <mat-spinner></mat-spinner>
  </div>
</div>
