import { NgClass, AsyncPipe, DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';

import { Subject, switchMap, take } from 'rxjs';
import { combineLatestWith, takeUntil } from 'rxjs/operators';

import { FilesClient, QuantityTakeOffAttachmentGet, QuantityTakeOffAttachmentsClient } from 'app/generated-client/generated-client';
import { FileSaverService } from 'app/shared/services/file-saver.service';
import { SelectedAttachmentService } from 'app/shared/services/messengers/selected-attachment.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedQuantityTakeOffMessengerService } from 'app/shared/services/messengers/selected-quantity-take-off-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

import { UpperPaginatorComponent } from '../../../../../../../../shared/components/upper-paginator/upper-paginator.component';

import { PdfAttachmentsService } from './../../../../../../../../shared/services/lightquery/pdf-attachments.service';

@Component({
  selector: 'pa-invoice-pdf-table',
  templateUrl: './invoice-pdf-table.component.html',
  styleUrls: ['./invoice-pdf-table.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    MatIconButton,
    MatIcon,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    NgClass,
    UpperPaginatorComponent,
    AsyncPipe,
    DatePipe
  ]
})
export class InvoicePdfTableComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  private $destroy = new Subject<boolean>();
  filter: string;
  showedColumns = ['sourceFileName', 'createdAtUtc', 'page', 'action'];
  selectedAttchmentId: string | null = null;

  constructor(
    public pdfAttachmentsService: PdfAttachmentsService,
    private selectedAttachmentService: SelectedAttachmentService,
    private fileSaverService: FileSaverService,
    private quantityTakeOffAttachmentsClient: QuantityTakeOffAttachmentsClient,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private selectedQuantityTakeOffMessengerService: SelectedQuantityTakeOffMessengerService,
    private filesClient: FilesClient
  ) {}

  ngOnInit(): void {
    this.pdfAttachmentsService.initService({
      filtering: this.filter,
      paging: { page: 1, pageSize: 5 },
      sorting: { propertyName: 'createdAtUtc', isDescending: true },
      sortTableObj: this.sort
    });

    this.selectedAttachmentService.selectedAttachment.pipe(takeUntil(this.$destroy)).subscribe((selectedAttachment) => {
      this.selectedAttchmentId = selectedAttachment ? selectedAttachment.id : null;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  selectItem(attachment: QuantityTakeOffAttachmentGet): void {
    if (this.selectedAttchmentId === attachment.id) {
      this.selectedAttachmentService.setAttachment(null);
      return;
    }
    this.selectedAttachmentService.setAttachment(attachment);
  }

  deleteAttachment(e: Event, attachment: QuantityTakeOffAttachmentGet): void {
    e.stopPropagation();
    if (this.selectedAttchmentId === attachment.id) {
      this.selectedAttachmentService.setAttachment(null);
    }
    this.selectedProjectMessengerService.selectedProject
      .pipe(
        combineLatestWith(this.selectedSpecificationMessengerService.selectedServiceSpecification),
        take(1),
        switchMap(([project, avaProject]) => {
          return this.quantityTakeOffAttachmentsClient.deleteQuantityTakeOffAttachments(project.id, avaProject.avaProjectId, attachment.id);
        })
      )
      .subscribe(() => {
        this.pdfAttachmentsService.forceRefresh();
      });
  }

  downloadAttachment(e: Event, attachment: QuantityTakeOffAttachmentGet): void {
    e.stopPropagation();
    this.filesClient.downloadFile(attachment.sourceFileId).subscribe((r) => {
      this.fileSaverService.saveAs(r);
    });
  }
}
