import { Component } from '@angular/core';

import { PriceComponentType } from 'app/generated-client/generated-client';

import { MasterDataProductsComponent } from '../master-data-products/master-data-products.component';

@Component({
  selector: 'pa-equipments',
  templateUrl: './miscellaneous.component.html',
  styleUrls: ['./miscellaneous.component.scss'],
  standalone: true,
  imports: [MasterDataProductsComponent]
})
export class MiscellaneousComponent {
  priceComponentType = PriceComponentType.Miscellaneous;
}
