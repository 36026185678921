import { Pipe, PipeTransform } from '@angular/core';

import { PriceComponentType } from '../../../generated-client/generated-client';

@Pipe({
  name: 'priceComponentType',
  standalone: true
})
export class PriceComponentTypePipe implements PipeTransform {
  transform(value?: PriceComponentType): string {
    if (!value) {
      return 'Keine Zuordnung';
    }

    switch (value) {
      case PriceComponentType.Equipment:
        return 'Geräte';

      case PriceComponentType.Materials:
        return 'Material';

      case PriceComponentType.Miscellaneous:
        return 'Sonstiges';

      case PriceComponentType.Subcontractor:
        return 'Nachunternehmer';

      case PriceComponentType.Casing:
        return 'Schalung';

      default:
        return null;
    }
  }
}
