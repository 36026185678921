import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pa-color-picker',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorPickerComponent {
  @Input() currentColore: string;
  @Output() updateCurrentColor = new EventEmitter<string>();
  @Output() blurColor = new EventEmitter<boolean>();
  colors = ['#c41e3a', '#ff0000', '#ffa500', '#ffff00', '#9cee90', '#00cc00', '#add8e6', '#0000ff', '#00008b', '#000000'];
  constructor() {}

  addColor(event: Event): void {
    const color = (event.target as HTMLInputElement).value;
    this.updateCurrentColor.emit(color);
  }

  onBlurColor(): void {
    this.blurColor.emit(true);
  }
}
