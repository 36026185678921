import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, inject, type OnInit } from '@angular/core';
import { HelpWindowService, IHelpInformation } from '@shared/components/help-window-modal/services/help-window.service';
import { HelpWindowScrollService } from '@shared/components/help-window-modal/services/help-window-scroll.service';
import { HoverEffectDirective } from 'app/areas/tree/directives/hover-effect.directive';
import { BehaviorSubject, Observable, Subject, debounceTime, map, takeUntil } from 'rxjs';

@Component({
  selector: 'pa-help-window-list-structure',
  standalone: true,
  imports: [CommonModule, HoverEffectDirective],
  templateUrl: './help-window-list-structure.component.html',
  styleUrls: ['./help-window-list-structure.component.scss']
})
export class HelpWindowListStructureComponent implements OnInit, OnDestroy {
  @Input() flatStructureData: IHelpInformation[];
  @Input() set filter(value: string) {
    this.filterSource.next(value?.toLowerCase());
  }

  private filterSource: Subject<string> = new BehaviorSubject('');
  private $destroy: Subject<boolean> = new Subject<boolean>();

  helpWindowService = inject(HelpWindowService);
  helpWindowScrollService = inject(HelpWindowScrollService);

  filteredFlatStructureDataa$: Observable<IHelpInformation[]>;

  ngOnInit(): void {
    this.filteredFlatStructureDataa$ = this.filterSource.pipe(
      takeUntil(this.$destroy),
      debounceTime(250),
      map((filter) => this.helpWindowService.filterHelpInformation(this.flatStructureData, filter))
    );
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  onTreeNodeClick(scrollElementId: string): void {
    this.helpWindowScrollService.setScrollElementId(scrollElementId);
  }
}
