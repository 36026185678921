import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { MasterAdditionGroupGet } from '../../../generated-client/generated-client';

@Injectable({
  providedIn: 'root'
})
export class MasterDataAdditionGroupsService {
  private additionGroupsSource = new ReplaySubject<MasterAdditionGroupGet[]>(1);
  additionGroup = this.additionGroupsSource.asObservable();

  setAdditionGroup(additionGroup: MasterAdditionGroupGet[]): void {
    if (additionGroup) {
      this.additionGroupsSource.next(additionGroup);
    }
  }
}
