import { FrontendConfigGet } from './generated-client/generated-client';
import { IAppConfig } from './shared/models/app-config';

import { AppConfig } from '../environments/environment';

let cachedAppConfig: IAppConfig | null = null;

export function getAppConfig(): IAppConfig {
  if (cachedAppConfig) {
    return cachedAppConfig;
  }

  const config = AppConfig;
  if (window['danglAvaFrontendConfig']) {
    const backendConfig = window['danglAvaFrontendConfig'] as FrontendConfigGet;
    if (backendConfig.appPrefix) {
      config.appPrefix = backendConfig.appPrefix;
    }
    if (backendConfig.applicationInsightsKey) {
      config.applicationInsightsKey = backendConfig.applicationInsightsKey;
    }
    if (backendConfig.backendUrl) {
      config.backendUrl = backendConfig.backendUrl;
    }
    if (backendConfig.danglDocuProjectName) {
      config.danglDocuProjectName = backendConfig.danglDocuProjectName;
    }
    if (backendConfig.danglIconsBaseUrl) {
      config.danglIconsBaseUrl = backendConfig.danglIconsBaseUrl;
    }
    if (backendConfig.enableCanalConvertExport) {
      config.enableCanalConvertExport = backendConfig.enableCanalConvertExport;
    }
    if (backendConfig.environment) {
      config.environment = backendConfig.environment;
    }
    if (backendConfig.logoInitials) {
      config.logoInitials = backendConfig.logoInitials;
    }
  }

  config.backendUrl = config.backendUrl.replace(/\/$/, '');
  config.danglIconsBaseUrl = config.danglIconsBaseUrl.replace(/\/$/, '');

  cachedAppConfig = config;
  return config;
}
