import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

type LoggingType = {
  date: number;
  log: any;
  type: 'httpError' | 'http';
};

@Injectable({
  providedIn: 'root'
})
export class ErrorLoggingService {
  private log: LoggingType[] = [];
  private maxLogSize = 10;

  constructor() {}

  setHttpRequest(request: HttpRequest<any>): void {
    const clonedRequest = JSON.parse(JSON.stringify(request));
    clonedRequest.headers = null;
    this.log.push({
      date: Date.now(),
      type: 'http',
      log: JSON.stringify(clonedRequest)
    });
    if (this.log.length > this.maxLogSize) {
      this.log.shift();
    }
  }

  setHttpError(httpError: HttpErrorResponse): void {
    this.log.push({
      date: Date.now(),
      type: 'httpError',
      log: httpError
    });
    if (this.log.length > this.maxLogSize) {
      this.log.shift();
    }
  }

  getErrorLog(): LoggingType[] {
    return this.log;
  }
}
