import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { LimitColumnWidthService } from '@serv-spec/services/limit-column-width.service';

import { ObjectNumberType } from 'app/shared/models';
import { getStorage, setStorage } from 'app/shared/utilities/storage';

@Directive({
  selector: '[resizeColumn]',
  standalone: true
})
export class ResizableDirective implements OnInit, OnDestroy {
  private isLimit: boolean;
  private startX: number;
  private startWidth: number;
  private startWidthNext: number;
  private currentWidth: number;
  private currentWidthNext: number;
  private column: HTMLElement;
  private columnNext: HTMLElement;
  private table: HTMLElement;
  private wrapper: HTMLElement;
  private pressed: boolean;
  private storage: ObjectNumberType;
  private name: string;
  private cellName: string;
  private cellNameNext: string;
  private cellsTarget: HTMLElement[];
  private cellsTargetNext: HTMLElement[];
  private listPREFIX: string[] = ['mat-column-', 'cdk-column-'];
  private PREFIX = this.listPREFIX[0];
  // This is to unsubscribe from the window event listeners after the component has been destroyed
  private unsubscribeEventCallbacks: (() => void)[] = [];
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private limitColumnWidthService: LimitColumnWidthService
  ) {}

  ngOnInit(): void {
    this.column = this.el.nativeElement;
    this.renderer.setStyle(this.column, 'position', 'relative');
    this.renderer.setStyle(this.column, 'box-sizing', 'border-box');
    this.renderer.setStyle(this.column, 'cursor', 'pointer');
    this.columnNext = this.column.nextElementSibling as HTMLElement;

    const row = this.renderer.parentNode(this.column);
    const thead = this.renderer.parentNode(row);
    this.table = this.renderer.parentNode(thead);
    this.wrapper = this.table.closest('.table-wrapper');
    const nameRaw = this.table.getAttribute('resizeName');
    this.isLimit = this.table.hasAttribute('resizeLimit');
    if (!nameRaw) {
      return;
    }
    this.name = `resize-${nameRaw}`;
    // we will add this element as a marker for triggering resize.
    const resizer = this.renderer.createElement('span');
    this.renderer.addClass(resizer, 'resize-holder');
    [
      { name: 'width', val: '20px' },
      { name: 'height', val: '100%' },
      { name: 'position', val: 'absolute' },
      { name: 'right', val: '-10px' },
      { name: 'top', val: '0' },
      { name: 'z-index', val: '200' },
      { name: 'cursor', val: 'col-resize' }
    ].forEach((item) => this.renderer.setStyle(resizer, item.name, item.val));
    this.renderer.appendChild(this.column, resizer);

    this.cellName = Array.from(this.column.classList)
      .find((item: string) => item.includes(this.PREFIX))
      ?.slice(this.PREFIX.length);
    if (!this.cellName) {
      this.PREFIX = this.listPREFIX[1];
      this.cellName = Array.from(this.column.classList)
        .find((item: string) => item.includes(this.PREFIX))
        ?.slice(this.PREFIX.length);
      if (!this.cellName) {
        return;
      }
    }
    this.cellsTarget = Array.from(this.table.querySelectorAll(`.${this.PREFIX}${this.cellName}`));

    this.realWidth(true);
    this.setWidth();
    setTimeout(() => this.realWidth(), 1);

    if (this.columnNext) {
      this.cellNameNext = Array.from(this.columnNext.classList)
        .find((item: string) => item.includes(this.PREFIX))
        .slice(this.PREFIX.length);
      this.cellsTargetNext = Array.from(this.table.querySelectorAll(`.${this.PREFIX}${this.cellNameNext}`));
    }
    this.unsubscribeEventCallbacks.push(this.renderer.listen(resizer, 'mousedown', this.onMouseDown));
    this.unsubscribeEventCallbacks.push(this.renderer.listen('document', 'mouseup', this.onMouseUp));
    this.unsubscribeEventCallbacks.push(
      this.renderer.listen('window', 'resize', () => {
        this.realWidth();
        this.setWidth();
      })
    );
    this.unsubscribeEventCallbacks.push(this.renderer.listen(this.column, 'dblclick', this.onDblClick));

    this.limitColumnWidthService.columnWidth.pipe(takeUntil(this.$destroy)).subscribe((e: { name: string; value: number }) => {
      if (e.name === nameRaw) {
        setTimeout(() => {
          if (e.value) {
            this.currentWidth = e.value;
            this.setWidth();
            this.storage = getStorage<ObjectNumberType>(this.name);
            this.storage[this.cellName] = this.currentWidth;
            setStorage<ObjectNumberType>(this.name, this.storage);
          } else {
            this.onDblClick({} as MouseEvent);
          }
          this.limitColumnWidthService.setCallBack();
        }, 1);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeEventCallbacks.forEach((callback) => callback());
    if (this.unsubscribeListener) {
      this.unsubscribeListener();
      this.unsubscribeListener = null;
    }
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private unsubscribeListener: () => void;

  onDblClick = (event: MouseEvent): void => {
    if (event.ctrlKey) {
      // TODO DOCUMENT THIS SOMEWHERE, IN DOCS AND HOT KEY OVERVIEW
      this.currentWidth += 100;
    } else {
      this.currentWidth = this.getContentWidth();
    }
    this.setWidth();
    setTimeout(() => {
      this.realWidth();
      this.setWidth();
    }, 1);
  };

  getContentWidth(): number {
    let isImage = false;
    const lengthList = Array.from(this.table.querySelectorAll(`.${this.PREFIX}${this.cellName}`)).map((item) => {
      const input = item.querySelector(`input`);
      if (!isImage) {
        isImage = item.classList.contains('table-image');
      }
      if (input) {
        return input.value.length;
      }
      return item['innerText']?.length || 0;
    });
    const maxLengths = Math.max(...lengthList);
    const maxWidth = maxLengths * 7 + 8;
    const elementTableWidth = this.table.closest('.table-wrapper');
    const widthTable = parseInt(window.getComputedStyle(elementTableWidth).width);
    if (isImage) {
      return Math.min(maxWidth, 120);
    }
    return Math.min(maxWidth, widthTable * 0.2);
  }

  onMouseDown = (event: MouseEvent): void => {
    this.pressed = true;

    this.unsubscribeListener = this.renderer.listen(this.table, 'mousemove', this.onMouseMove);

    this.startX = event.pageX;
    this.startWidth = parseInt(window.getComputedStyle(this.column).width);
    this.currentWidth = this.startWidth;
    if (this.columnNext) {
      this.startWidthNext = parseInt(window.getComputedStyle(this.columnNext).width);
      this.currentWidthNext = this.startWidthNext;
    }
    this.setWidth(true);
  };

  onMouseMove = (event: MouseEvent): void => {
    if (this.pressed && event.buttons) {
      const delta = event.pageX - this.startX + 2;
      this.currentWidth = this.startWidth + delta;
      if (this.columnNext && !event.ctrlKey) {
        this.currentWidthNext = this.startWidthNext - delta;
      }
      this.setWidth(true);
    }
  };

  onMouseUp = (): void => {
    if (this.pressed) {
      this.pressed = false;
      this.realWidth(false, true);
    }

    if (this.unsubscribeListener) {
      this.unsubscribeListener();
      this.unsubscribeListener = null;
    }
  };

  realWidth(isFirstTime: boolean = false, isNextColumn: boolean = false): void {
    if (!this) {
      // There sometimes might be a reference to this function left but the component has already been destroyed.
      return;
    }

    this.storage = getStorage<ObjectNumberType>(this.name);

    if (this.isLimit && !isFirstTime) {
      this.checkLimitWidth(isNextColumn);
    }

    this.currentWidth = (isFirstTime && this.storage[this.cellName]) || parseInt(window.getComputedStyle(this.column).width);
    this.storage[this.cellName] = this.currentWidth;
    if (isNextColumn && this.columnNext) {
      this.currentWidthNext = parseInt(window.getComputedStyle(this.columnNext).width);
      this.storage[this.cellNameNext] = this.currentWidthNext;
    }
    setStorage<ObjectNumberType>(this.name, this.storage);
  }

  checkLimitWidth(isNextColumn: boolean): void {
    const wrapperWidth = this.wrapper.getBoundingClientRect().width;
    const tableWidth = this.table.getBoundingClientRect().width;
    if (wrapperWidth - tableWidth < 19) {
      const countList = Object.keys(this.storage || {}).length;
      const widthReduce = Math.ceil(tableWidth - wrapperWidth + countList * 2 + 6);
      this.currentWidth -= widthReduce;
      if (isNextColumn && this.columnNext) {
        this.currentWidthNext -= widthReduce;
      }
      this.setWidth(isNextColumn);
    }
  }

  setWidth(isNextColumn: boolean = false): void {
    this.cellsTarget.forEach((cell) => this.renderer.setStyle(cell, 'min-width', `${this.currentWidth}px`));
    this.cellsTarget.forEach((cell) => this.renderer.setStyle(cell, 'max-width', `${this.currentWidth}px`));
    this.cellsTarget.forEach((cell) => this.renderer.setStyle(cell, 'width', `${this.currentWidth}px`));
    if (isNextColumn && this.columnNext && this.cellsTargetNext) {
      this.cellsTargetNext.forEach((cell) => this.renderer.setStyle(cell, 'min-width', `${this.currentWidthNext}px`));
      this.cellsTargetNext.forEach((cell) => this.renderer.setStyle(cell, 'max-width', `${this.currentWidthNext}px`));
      this.cellsTargetNext.forEach((cell) => this.renderer.setStyle(cell, 'width', `${this.currentWidthNext}px`));
    }
  }
}
