import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogTitle, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

import { FileElement } from 'app/shared/models';

@Component({
  selector: 'pa-folder-modal-for-name',
  templateUrl: './folder-modal-for-name.component.html',
  styleUrls: ['./folder-modal-for-name.component.scss'],
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogTitle,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatDialogActions,
    MatButton,
    MatDialogClose
  ]
})
export class FolderModalForNameComponent {
  newItemForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) private data: FileElement) {}
  ngOnInit(): void {
    this.newItemForm = new FormGroup({
      folderName: new FormControl('')
    });
    if (this.data) {
      this.newItemForm.patchValue({
        folderName: this.data.name
      });
    }
  }
}
