<h1 mat-dialog-title>Rechnungsexport</h1>
<div mat-dialog-content>
  <div class="wrapper">
    <div *ngIf="!showingPdf">
      <div fxLayoutGap="10px">
        <mat-form-field>
          <mat-label>Titel</mat-label>
          <input matInput [(ngModel)]="reportName" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Rechnungsname</mat-label>
          <input matInput [(ngModel)]="invoiceName" />
        </mat-form-field>
      </div>
      <div fxLayoutGap="10px">
        <mat-form-field>
          <mat-label>UST</mat-label>
          <input matInput [(ngModel)]="taxRate" type="number" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Extra Zeilenabstand</mat-label>
          <input matInput [(ngModel)]="extraMarginAfterPositionLinesInMm" min="0" type="number" />
          <span matSuffix>mm</span>
        </mat-form-field>
      </div>
      <div fxLayoutGap="10px">
        <mat-form-field>
          <mat-label>Rechnungsnummer</mat-label>
          <input matInput [(ngModel)]="invoiceNumber" type="text" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Sicherheitseinbehalt</mat-label>
          <input matInput [(ngModel)]="percentageWithholding" type="number" />
          <span matSuffix>%</span>
        </mat-form-field>
      </div>

      <pa-selecting-element-buttons
        (elementSelectionChanged)="elementSelection = $event"
        [isShowElementSelectionTableButton]="false"
      ></pa-selecting-element-buttons>

      <div class="margin-bottom-10">
        <div fxLayoutGap="10px" class="btn-selection-wrap">
          <button color="primary" mat-raised-button (click)="selectOpeningText()">Vorbemerkung</button>
          <button color="warn" mat-raised-button (click)="htmlOpeningText = null" [disabled]="!htmlOpeningText">
            Vorbemerkung löschen
          </button>
        </div>
        <mat-hint *ngIf="htmlOpeningText">Vorbemerkung gesetzt</mat-hint>
      </div>

      <div class="margin-bottom-10">
        <div fxLayoutGap="10px" class="btn-selection-wrap">
          <button color="primary" mat-raised-button (click)="selectClosingText()">Schlusstext</button>
          <button color="warn" mat-raised-button (click)="htmlClosingText = null" [disabled]="!htmlClosingText">Schlusstext löschen</button>
        </div>
        <mat-hint *ngIf="htmlClosingText">Schlusstext gesetzt</mat-hint>
      </div>

      <div class="margin-bottom-10" *ngIf="'ByPage' === calculationType">
        <div fxLayoutGap="10px" class="btn-selection-wrap">
          <button color="primary" mat-raised-button (click)="selectPages()">Blätter</button>
          <button color="warn" mat-raised-button (click)="pagesSelection = null" [disabled]="!pagesSelection">Alle Blätter</button>
        </div>
        <mat-hint *ngIf="pagesSelection?.length">Nur ausgewählte Blätter werden gedruckt</mat-hint>
      </div>

      <div class="margin-bottom-10">
        <div fxLayoutGap="10px" class="btn-selection-wrap" fxLayout="column">
          <mat-checkbox [(ngModel)]="includePaymentTerms">Zahlungsbedingungen ausgeben</mat-checkbox>
          <mat-checkbox [(ngModel)]="includeHeaderOnlyOnFirstPage">Kopfzeile nur auf der ersten Seite drucken</mat-checkbox>
          <mat-checkbox [(ngModel)]="includePreviousQuantities">Vorherige Abrechnungssumme ausgeben</mat-checkbox>
          <mat-checkbox [(ngModel)]="markAsBilledAfterPrint">Nach Druck als abgerechnet markieren</mat-checkbox>
          <mat-checkbox
            *ngIf="hasAbsoluteDeduction"
            matTooltip="In diesem Projekt wurde ein absoluter Nachlass gewährt. Wenn diese Option aktiviert ist, wird der absolute Nachlass in dieser Rechnungen berücksichtigt. Typischerweise nur bei Schlussrechnungen."
            [(ngModel)]="includeAbsoluteDeduction"
            >Absoluten Nachlass einbeziehen</mat-checkbox
          >
        </div>
      </div>

      <button color="primary" *ngIf="!showingPdf && !isLoading" mat-raised-button (click)="generatePdfAndShowPreview()">
        PDF Erzeugen
      </button>
    </div>
    <h1 *ngIf="showingPdf">Bitte Druckvorschaufenster beachten.</h1>
    <div class="wrapper-loader" *ngIf="isLoading" fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-raised-button (click)="closeDialog()">Abbrechen</button>
</div>
