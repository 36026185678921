import { Pipe, PipeTransform } from '@angular/core';

import { IElementDto } from 'app/generated-client/generated-client';

@Pipe({
  name: 'nodeText',
  standalone: true
})
export class NodeTextPipe implements PipeTransform {
  //For the pipe to work with the OnPush strategy, you need to pass the changed text as an argument
  transform(element: IElementDto): string {
    switch (element && element.elementTypeDiscriminator) {
      case 'NoteTextDto':
        return element['shortText'] || 'Hinweis';
      case 'ExecutionDescriptionDto':
        return `${element['identifier'] || ''} - ${element['label'] || ''}`;
      case 'ServiceSpecificationGroupDto':
        return element['shortText'] || '';
      case 'PositionDto':
        return element['shortText'] || '';
      default:
        return '';
    }
  }
}
