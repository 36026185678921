import { NgIf, NgFor } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';

import { TinyMceComponent } from '@dangl/angular-material-shared/tiny-mce';

import { LvEditorService } from '@serv-spec/services/lv-editor.service';

import { ExecutionDescriptionDto, NoteTextDto } from 'app/generated-client/generated-client';

import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-lv-editor-execution-description',
  templateUrl: './lv-editor-execution-description.component.html',
  styleUrls: ['./lv-editor-execution-description.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, NgFor, TinyMceComponent, MatButton, FlexLayoutDirective]
})
export class LvEditorExecutionDescriptionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() element: ExecutionDescriptionDto;
  @Input() isEditableAll: boolean;
  elementChanged: ExecutionDescriptionDto = null;
  isChanged: boolean;

  constructor(private lvEditorService: LvEditorService) {}

  ngOnInit(): void {
    this.getElement();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getElement();
    if (changes.element.previousValue && changes.element.currentValue && changes.element.previousValue !== changes.element.currentValue) {
      setTimeout(() => {
        if (!this.isEditableAll) {
          this.lvEditorService.stopChangeElement();
        }
      }, 1);
    }
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this.lvEditorService.stopChangeElement();
    }, 1);
  }

  getElement(): void {
    if (this.element) {
      this.elementChanged = JSON.parse(JSON.stringify(this.element));
    }
  }

  exit(save: boolean = false): void {
    if (save) {
      this.lvEditorService.saveChangedElement(this.elementChanged);
    }
    this.lvEditorService.stopChangeElement();
  }

  deleteBlock(index): void {
    this.elementChanged.blocks.splice(index, 1);
    this.isChanged = true;
  }

  addBlock(): void {
    this.elementChanged.blocks.push({
      shortText: '',
      htmlLongText: ''
    } as NoteTextDto);
    this.isChanged = true;
  }

  changedTinyMce(event, obj, prop) {
    if (event !== obj[prop]) {
      this.isChanged = true;
      obj[prop] = event;
    }
  }
}
