<div #headerWrap class="header">
  <h1>Neues LV erstellen</h1>
</div>
<div #subHeaderWrap class="subheader">
  <h2>Datenübernahme</h2>
</div>
<div
  class="wrapper"
  [style.height]="sanitizer.bypassSecurityTrustStyle('calc(100% - ' + (headerWrap.offsetHeight + subHeaderWrap.offsetHeight) + 'px)')"
>
  <div *ngIf="!performingAvaInspection && !avaFileInspectionResult" fxLayout="column" fxLayoutAlign="start start">
    <mat-label>Du kannst Daten direkt aus einer GAEB oder ÖNorm Datei übernehmen, um das LV zu erstellen.</mat-label>
    <input hidden type="file" (change)="onFileSelected(fileInput.files)" #fileInput />
    <button mat-raised-button (click)="fileInput.click()" [disabled]="!!selectedFile">LV Datei Auswählen</button>
  </div>
  <div *ngIf="selectedFile">
    <p>
      <span>Dateiname:</span><strong>{{ selectedFile.name }}</strong>
    </p>
  </div>
  <div *ngIf="avaFileInspectionResult">
    <p>Dateityp: {{ avaFileInspectionResult.origin | originDto }}</p>
    <p>Bereit zur Datenübernahme!</p>
    <ng-container *ngIf="avaFileInspectionResult?.containsPrices">
      <p>In der Datei sind Preise vorhanden, sollen diese in die Kalkulation des neuen LVs übernommen werden?</p>
      <mat-checkbox [(ngModel)]="includePricesFromAvaFile">Preise übernehmen</mat-checkbox>
      <mat-checkbox [(ngModel)]="importPricesAsFixedPrices" [disabled]="!includePricesFromAvaFile">Als Festpreise</mat-checkbox>
    </ng-container>
    <div>
      <mat-checkbox [(ngModel)]="enableSupportForSkippedTiersInItemNumberSchema"
        >Ausgelassene Hierarchiestufen zulassen (falls in OZ vorhanden)</mat-checkbox
      >
    </div>
  </div>
  <div *ngIf="fileCouldNotBeRead">
    <p class="warning-text">Die Datei konnte nicht gelesen werden.</p>
  </div>
  <div fxFlexFill fxLayoutAlign="center center" *ngIf="performingAvaInspection">
    <mat-spinner></mat-spinner>
  </div>
  <div>
    <form [formGroup]="servSpecFormGroup" (submit)="addServSpec()">
      <div>
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" required />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill" *ngIf="masterAdditionGroups">
          <mat-label>Zuschlagsgruppe</mat-label>
          <mat-select formControlName="masterAdditionGroupId">
            <mat-option *ngFor="let group of masterAdditionGroups" [value]="group.id">
              {{ group.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-checkbox [checked]="isCopyAdditionGroup" (change)="copyAdditionGroup($event.checked)" class="copy-mode"
        >Aus bestehendem Projekt übernehmen</mat-checkbox
      >
      <div *ngIf="copyAdditionObj">
        <div fxLayout="row" fxLayoutGap="12px">
          <mat-form-field appearance="fill">
            <mat-label>Nummer</mat-label>
            <input matInput [value]="copyAdditionObj.project.constructedProjectNumber" disabled />
          </mat-form-field>
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Projektname</mat-label>
            <input matInput [value]="copyAdditionObj.project.name" disabled />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>LV-Name</mat-label>
            <input matInput [value]="copyAdditionObj.avaProject.name" disabled />
          </mat-form-field>
        </div>
      </div>
      <div>
        <mat-form-field appearance="fill" *ngIf="masterAdditionGroups">
          <mat-label>Typ</mat-label>
          <mat-select formControlName="type">
            <mat-option *ngFor="let type of avaProjectTypes" [value]="type">
              {{ type | avaProjectType }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="!selectedFile">
        <mat-form-field appearance="fill" *ngIf="listSchemas?.length">
          <mat-label>OZ-Maske</mat-label>
          <mat-select formControlName="selectedSchema">
            <mat-option [value]="null">Keine</mat-option>
            <mat-option *ngFor="let itemSchema of listSchemas" [value]="itemSchema">
              {{ itemSchema.example }} ( {{ itemSchema.name }} )
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-raised-button type="button" (click)="changeSchema()">Schema Konfigurieren</button>
        <div *ngIf="servSpecFormGroup.value.selectedSchema">
          OZ-Maske: <span class="example">{{ servSpecFormGroup.value.selectedSchema?.example }}</span>
        </div>
      </div>

      <div>
        <mat-form-field appearance="fill" *ngIf="allTaxRates">
          <mat-label>Mehrwertsteuersatz</mat-label>
          <mat-select (ngModelChange)="selectTaxRate($event)" formControlName="selectedTaxRate">
            <mat-option *ngFor="let taxRate of allTaxRates" [value]="taxRate">
              {{ taxRate.country }} - {{ taxRate.rate | percent: '1.2-2' }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-form-field>
        <mat-label>Mehrwertsteuersatz</mat-label>
        <input matInput type="number" formControlName="taxRate" />
      </mat-form-field>

      <div class="buttons">
        <button
          mat-raised-button
          color="primary"
          *ngIf="!requestEnRoute"
          [disabled]="
            !servSpecFormGroup.valid ||
            servSpecFormGroup.value.taxRate < 0 ||
            (!servSpecFormGroup.value.masterAdditionGroupId && !servSpecFormGroup.value.additionsSourceAvaProjectId)
          "
        >
          Erstellen
        </button>
        <button mat-raised-button routerLink="..">Abbrechen</button>
      </div>
      <div fxFlexFill fxLayoutAlign="center center" *ngIf="requestEnRoute">
        <mat-spinner></mat-spinner>
      </div>
    </form>
  </div>
</div>
