import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { NgIf, NgClass, AsyncPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';

import { Subject, combineLatestWith, debounceTime, map, take, takeUntil } from 'rxjs';
import { filter, skip } from 'rxjs/operators';

import { EditorElementModalComponent } from '@serv-spec/components/lv-editor/components/editor-element-modal/editor-element-modal.component';
import { ElementFilterService } from '@serv-spec/services/element-filter.service';
import { LvEditorService } from '@serv-spec/services/lv-editor.service';

import { CalculationFixedPriceService } from '@project/services/calculation-fixed-price.service';

import { TreeMenuComponent } from 'app/areas/tree/components/tree-menu/tree-menu.component';
import { ClickerService } from 'app/areas/tree/services/clicker.service';
import { FlatElementsService } from 'app/areas/tree/services/flat-elements.service';
import { ModePageService } from 'app/areas/tree/services/mode-page.service';
import {
  ExecutionDescriptionDto,
  IElementDto,
  NoteTextDto,
  PositionDto,
  ServiceSpecificationGroupDto
} from 'app/generated-client/generated-client';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { TreeViewCommandType } from 'app/shared/models/tree-view-command.model';
import { ContextMenuSettingsService } from 'app/shared/services/context-menu-settings.service';
import { TreeViewMessengerService } from 'app/shared/services/electron/tree-view-messenger.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';
import { PositionHasCalculationService } from 'app/shared/services/position-has-calculation.service';

import { NodeTextPipe } from '../../pipes/node-text.pipe';
import { NodeWithoutCalculationPipe } from '../../pipes/node-without-calculation.pipe';
import { ShowPositionAbbreviationPipe } from '../../pipes/show-position-abbreviation.pipe';
import { PositionTextAdditionService } from '../../services/position-text-addition.service';

import { TableVirtualScrollDataSource, TableVirtualScrollModule } from 'ng-table-virtual-scroll';

@Component({
  selector: 'pa-tree-table-view',
  templateUrl: './tree-table-view.component.html',
  styleUrls: ['./tree-table-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    CdkVirtualScrollViewport,
    TableVirtualScrollModule,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatIconButton,
    MatIcon,
    MatInput,
    FormsModule,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    NgClass,
    TreeMenuComponent,
    AsyncPipe,
    DecimalPipe,
    PercentPipe,
    ShowPositionAbbreviationPipe,
    NodeWithoutCalculationPipe,
    NodeTextPipe
  ]
})
export class TreeTableViewComponent implements OnInit, OnDestroy {
  @Input() set choseElementId(id: string | null) {
    this.choseSelectedElementId = id;
  }
  @Input() filter: Subject<string> = new Subject();
  @Input() isInnerWindow = false;

  @ViewChild(TreeMenuComponent) treeMenuComponent: TreeMenuComponent;

  private $destroy: Subject<boolean> = new Subject<boolean>();
  positionIdsWithoutCalculations$ = this.positionHasCalculationService.positionIdsWithoutCalculations;
  selectedElementId$ = this.selectedSpecificationElementMessengerService.selectedElement.pipe(map((e) => e?.id));

  modePage: string;
  choseSelectedElementId: string | null;
  contextMenuPosition = { x: '0px', y: '0px' };

  flatElementsDto = new TableVirtualScrollDataSource<ServiceSpecificationGroupDto | NoteTextDto | ExecutionDescriptionDto | PositionDto>();
  /**  This property is used to indicate if the search filter for the tree elements is just initializing,
   * meaning it was null before and just got a value. Due to the debounce time of the filter function
   * there would otherwise be a short gap where we already show the filtered elements, but don't have any
   * filter applied yet, so it would show as "no elements found" for a short time when we enter a filter */
  filterInitializing = false;

  constructor(
    private positionHasCalculationService: PositionHasCalculationService,
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private lvEditorService: LvEditorService,
    private treeViewMessengerService: TreeViewMessengerService,
    private calculationFixedPriceService: CalculationFixedPriceService,
    private modePageService: ModePageService,
    private clickerService: ClickerService,
    private modalService: ModalService,
    private flatElementsService: FlatElementsService,
    private elementFilterService: ElementFilterService,
    private cdr: ChangeDetectorRef,
    private positionTextAdditionService: PositionTextAdditionService,
    private contextMenuSettingsService: ContextMenuSettingsService
  ) {}

  ngOnInit(): void {
    this.flatElementsService.flatElementsDto
      .pipe(
        combineLatestWith(this.filter.pipe(debounceTime(250)), this.positionTextAdditionService.modePositionTextAddition),
        takeUntil(this.$destroy)
      )
      .subscribe(([flatElementsDto, filter, modePositionTextAddition]) => {
        const elements = !filter ? flatElementsDto : this.elementFilterService.filterElements(flatElementsDto, filter);
        if (!modePositionTextAddition) {
          this.flatElementsDto.data = [...elements];
          this.filterInitializing = true;

          this.cdr.markForCheck();
        } else {
          const listPositions = elements.filter((e) => e.elementTypeDiscriminator === 'PositionDto');
          this.positionTextAdditionService.listPositionTextAddition.pipe(take(1)).subscribe((list) => {
            this.flatElementsDto.data = listPositions.filter((e) => list.includes(e.id));
            this.filterInitializing = true;
            this.cdr.markForCheck();
          });
        }
      });

    this.modePageService.modePage.pipe(takeUntil(this.$destroy)).subscribe((e) => {
      this.modePage = e;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  saveChangedElement(element: IElementDto): void {
    this.lvEditorService.saveChangedElement(element);
  }

  setFixedPrice(element: IElementDto): void {
    if (this.modePage !== 'calculation') {
      return;
    }
    if (element.elementTypeDiscriminator === 'PositionDto') {
      this.calculationFixedPriceService.setFixedPriceForCalculation(element.id, (element as PositionDto).unitPrice);
      if (this.isInnerWindow) {
        this.treeViewMessengerService.sendDataFromTreeView({
          command: TreeViewCommandType.ChangeFixedPrice,
          data: [element]
        });
      }
    }
  }

  selectElementBySingl(e: IElementDto): void {
    this.clickerService.clickedBySingle(e);
  }

  editElementByDbl(element: IElementDto): void {
    //get element here if click on new row
    const source$ = this.selectedSpecificationElementMessengerService.selectedElement
      .pipe(skip(1), take(1))
      .subscribe((selectedElement) => {
        this.modalService.openModal(EditorElementModalComponent, { dialogType: ConfirmationType.General, data: selectedElement });
      });

    // get element here if click on the already selected row
    this.selectedSpecificationElementMessengerService.selectedElement
      .pipe(
        take(1),
        filter((selectedElement) => selectedElement && selectedElement.id === element.id)
      )
      .subscribe((selectedElement) => {
        this.modalService.openModal(EditorElementModalComponent, { dialogType: ConfirmationType.General, data: selectedElement });
        source$.unsubscribe();
      });
  }

  showContextMenu(event: MouseEvent, node: any): void {
    event.stopPropagation();
    // Taken from https://stackblitz.com/edit/angular-material-context-menu-table?file=app%2Fcontext-menu-example.ts
    this.contextMenuSettingsService.setDefaultSettings(event, node as any, this.contextMenuPosition, this.treeMenuComponent.menu);
  }
}
