<div [ngStyle]="{ width: width + 'px', height: height + 'px' }" #wrapElement class="wrapper one-view" (mouseup)="overlayUp()">
  <header fxLayout="row" class="header">
    <div class="header-title movable" (mousedown)="startResize($event, 'move')">Hauptfenster</div>
    <button mat-flat-button color="warn" *ngIf="!isFullSize" (click)="fullSize()">
      <mat-icon>crop_din</mat-icon>
    </button>
    <button mat-flat-button color="warn" *ngIf="isFullSize" (click)="middleSize()">
      <mat-icon style="transform: rotate(0.5turn) scale(0.8)">filter_none</mat-icon>
    </button>
    <button mat-flat-button color="warn" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div class="wrap">
    <pa-general-header>
      <router-outlet></router-outlet>
    </pa-general-header>
  </div>
  <ng-container *ngIf="!isFullSize">
    <div class="border-dialog border-dialog-top" (mousedown)="startResize($event, 'top')"></div>
    <div class="border-dialog border-dialog-right" (mousedown)="startResize($event, 'right')"></div>
    <div class="border-dialog border-dialog-bottom" (mousedown)="startResize($event, 'bottom')"></div>
    <div class="border-dialog border-dialog-left" (mousedown)="startResize($event, 'left')"></div>
    <div class="border-dialog border-dialog-bottomright" (mousedown)="startResize($event, 'bottomRight')">
      <div class="corner">
        <mat-icon class="corner-icon">reorder</mat-icon>
      </div>
    </div>
  </ng-container>
</div>
