import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { filter } from 'rxjs';

import { PaginationMainService } from '@shared/services/lightquery/main/pagination-main.service';

import { BimFileGet } from '../../../generated-client/generated-client';
import { SelectedSpecificationMessengerService } from '../../../shared/services/messengers/selected-specification-messenger.service';

@Injectable({
  providedIn: 'root'
})
export class BimModelFileLightqueryService extends PaginationMainService<BimFileGet> {
  constructor(protected http: HttpClient, private selectedSpecificationMessengerService: SelectedSpecificationMessengerService) {
    super(http);
    this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(filter((s) => !!s)).subscribe((s) => {
      this.beginBaseUrl(`/api/projects/${s.parentProjectId}/ava-projects/${s.avaProjectId}/bim-files`);
    });
  }

  getModelById(modelId: string): BimFileGet | undefined {
    return this.getItemById(modelId);
  }
}
