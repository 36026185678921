import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Router } from '@angular/router';

import { first } from 'rxjs/operators';

import { ContactsClient, ContactGet, ContactPost, ContactType } from 'app/generated-client/generated-client';
import { ContactTypePipe } from 'app/shared/pipes/ui-data-display/contact-type.pipe';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { ContactsService } from 'app/shared/services/lightquery/contacts.service';

import { SelectedContactMessengerService } from '../../services/selected-contact-messenger.service';

@Component({
  selector: 'pa-contacts-form',
  templateUrl: './contacts-form.component.html',
  styleUrls: ['./contacts-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatFormFieldModule,
    ContactTypePipe,
    FormsModule,
    MatSelectModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatInputModule
  ]
})
export class ContactsFormComponent implements OnInit {
  contact: ContactGet | ContactPost;
  contactTypes = Object.keys(ContactType) as ContactType[];
  private contactId: string;
  contactForm: FormGroup;

  constructor(
    private contactsClient: ContactsClient,
    private contactsService: ContactsService,
    private notificationsService: AvaNotificationsService,
    private selectedContactMessengerService: SelectedContactMessengerService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) private data: string,
    @Optional() private matDialogRef: MatDialogRef<ContactsFormComponent>,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      contactNumber: [],
      name: [''],
      contactType: [ContactType.Customer],
      nameAddition: [''],
      vatId: ['']
    });
    this.getContactData();
  }

  private getContactData(): void {
    if (this.data) {
      this.contactId = this.data;
      this.selectContact();
      this.selectedContactMessengerService.selectedContact.pipe(first()).subscribe((contact: ContactGet) => {
        this.contactForm.patchValue({ ...contact });
        this.contact = contact;
      });
    } else {
      this.contact = {
        contactType: ContactType.Customer,
        name: null,
        nameAddition: null,
        vatId: null
      };
    }
  }

  private selectContact(): void {
    console.log('this.contactId', this.contactId);

    const contact = this.contactsService.getContacts(this.contactId);
    if (contact) {
      console.log('got', contact);

      this.selectedContactMessengerService.setSelectedContact(contact);
      return;
    } else {
      console.log('start Client', this.contactId);

      this.contactsClient
        .getContactById(this.contactId)
        .subscribe((c: ContactGet) => this.selectedContactMessengerService.setSelectedContact(c));
    }
  }

  saveContact(): void {
    if (this.contact['id']) {
      this.contactsClient
        .editContact(this.contactId, {
          id: this.contactId,
          ...this.contactForm.value
        })
        .subscribe(
          () => {
            this.contactsService.forceRefresh();
            this.notificationsService.success('Kontakt gespeichert!');
            this.matDialogRef.close(this.contactForm.value);
          },
          () => {
            this.notificationsService.error('Fehler beim Speichern des Kontakts');
            this.matDialogRef.close();
          }
        );
    } else {
      this.contactsClient.createContact(this.contactForm.value).subscribe(
        (createdContact: ContactGet) => {
          this.contactsService.forceRefresh();
          this.router.navigate([`${this.router.url}/${createdContact.id}`]);
          this.notificationsService.success('Kontakt erstellt!');
          this.matDialogRef.close();
        },
        () => {
          this.notificationsService.error('Fehler beim Speichern des Kontakts');
          this.matDialogRef.close();
        }
      );
    }
  }

  dismiss(): void {
    this.matDialogRef.close();
  }
}
