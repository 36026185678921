<div fxFlexFill class="wrapper-tree">
  <as-split fxFlexFill [class.toggle-view]="isToggleTreeWidth || isOpenTreeWindow" #asSplitRightRef (dragEnd)="onDragEnd($event.sizes)">
    <as-split-area [size]="20" [minSize]="10" [class.toggle-view-tree]="isToggleTreeWidth || isOpenTreeWindow">
      <pa-main-tree [withoutSplitArea]="false"></pa-main-tree>
    </as-split-area>
    <as-split-area
      [class.toggle-view-table]="isToggleTreeWidth || isOpenTreeWindow"
      [size]="80"
      [ngClass]="{ 'hide-split-gutter': isToggleTreeWidth || isOpenTreeWindow }"
    >
      <div class="relative-parent uniq-content-field">
        <ng-content></ng-content>
      </div>
    </as-split-area>
  </as-split>
</div>
