import {
  AvaProjectDiff,
  IElementDto,
  Operation,
  ProjectDto,
  ServiceSpecificationDto,
  ServiceSpecificationGroupDto
} from '../../../generated-client/generated-client';
import { Operation as JsonPatchOperation, applyOperation } from 'fast-json-patch';

import { FlatElementsService } from './../../../areas/tree/services/flat-elements.service';
import { Injectable } from '@angular/core';
import { SelectedSpecificationMessengerService } from '../messengers/selected-specification-messenger.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AvaProjectDiffApplier {
  private projectId: string;
  private avaProjectId: string;
  private avaProject: ProjectDto;
  private elementsById: { [id: string]: IElementDto };
  private containers: { id?: string; elements?: IElementDto[] }[];

  private avaProjectDiffAppliedSource = new Subject<{
    avaProjectId: string;
    serviceSpecificationId: string;
    avaProjectDiff: AvaProjectDiff;
  }>();
  avaProjectDiffApplied = this.avaProjectDiffAppliedSource.asObservable();

  constructor(
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private flatElementsService: FlatElementsService
  ) {
    this.selectedSpecificationMessengerService.selectedServiceSpecification.subscribe(s => {
      this.projectId = s?.parentProjectId;
      this.avaProjectId = s?.avaProjectId;
      this.avaProject = s?.project;
      this.setElementsFromAvaProject();
    });
  }

  private setElementsFromAvaProject(): void {
    if (!this.avaProject) {
      return;
    }

    this.elementsById = {};
    this.containers = [];
    const flatElements = FlatElementsService.getFlatElements(this.avaProject.serviceSpecifications[0]);
    this.containers.push(this.avaProject.serviceSpecifications[0]);
    flatElements.forEach(element => {
      this.elementsById[element.id] = element;
      if (element.elementType === 'ServiceSpecificationGroupDto') {
        this.containers.push(element);
      }
    });
  }

  public static applySingleElementDiffOperation(originalElement: IElementDto, operation: Operation): void {
    applyOperation(originalElement, this.transformToJsonPatchOperation(operation));
  }

  public static applyAvaProjectDiffToSpecificServiceSpecification(
    serviceSpecification: ServiceSpecificationDto,
    avaProjectDiff: AvaProjectDiff
  ) {
    const flatElements = FlatElementsService.getFlatElements(serviceSpecification);
    const elementsById: { [id: string]: IElementDto } = {};
    const containers: { id?: string; elements?: IElementDto[] }[] = [];
    flatElements.forEach(element => {
      elementsById[element.id] = element;
      if (element.elementType === 'ServiceSpecificationGroupDto') {
        containers.push(element);
      }
    });
    containers.push(serviceSpecification);

    this.internalServiceSpecificationDiffApply(serviceSpecification, avaProjectDiff, elementsById, containers);
  }

  public tryApplyAvaProjectDiff(projectId: string, avaProjectId: string, avaProjectDiff: AvaProjectDiff) {
    if (this.projectId != projectId || this.avaProjectId != avaProjectId || !this.avaProject) {
      return;
    }

    AvaProjectDiffApplier.internalAvaProjectDiffApply(this.avaProject, avaProjectDiff, this.elementsById, this.containers);

    this.avaProjectDiffAppliedSource.next({
      avaProjectDiff: avaProjectDiff,
      avaProjectId: this.avaProject.id,
      serviceSpecificationId: this.avaProject.serviceSpecifications[0].id
    });

    this.flatElementsService.setElementsDto(this.avaProject.serviceSpecifications[0]);
  }

  private static internalAvaProjectDiffApply(
    avaProject: ProjectDto,
    avaProjectDiff: AvaProjectDiff,
    elementsById: { [elementId: string]: IElementDto },
    containers: { id?: string; elements?: IElementDto[] }[]
  ) {
    if (!avaProject || !avaProjectDiff) {
      return;
    }

    if (avaProjectDiff.projectDiff?.length > 0) {
      avaProjectDiff.projectDiff.forEach(operation => {
        applyOperation(avaProject, this.transformToJsonPatchOperation(operation));
      });
    }

    this.internalServiceSpecificationDiffApply(avaProject.serviceSpecifications[0], avaProjectDiff, elementsById, containers);
  }

  private static internalServiceSpecificationDiffApply(
    serviceSpecification: ServiceSpecificationDto,
    avaProjectDiff: AvaProjectDiff,
    elementsById: { [elementId: string]: IElementDto },
    containers: { id?: string; elements?: IElementDto[] }[]
  ) {
    if (avaProjectDiff.elementDiffs != null) {
      for (const elementId in avaProjectDiff.elementDiffs) {
        const originalElement = elementsById[elementId];
        if (originalElement) {
          avaProjectDiff.elementDiffs[elementId].forEach(operation => {
            this.applySingleElementDiffOperation(originalElement, operation);
          });
        }
      }
    }

    // Here, it's important that we first remove the elements that are to be deleted,
    // because a move between two containers is represented as a deletion and a move, so we
    // first delete the element and then add it again to the new container.
    if (avaProjectDiff.removedElementIds?.length > 0) {
      avaProjectDiff.removedElementIds.forEach(removedElementId => {
        if (elementsById[removedElementId]) {
          delete elementsById[removedElementId];
        }

        const parentContainer = containers.find(c => c.elements?.some(e => e.id === removedElementId));
        if (parentContainer) {
          const elementIndex = parentContainer.elements.findIndex(e => e.id === removedElementId);
          if (elementIndex >= 0) {
            parentContainer.elements.splice(elementIndex, 1);
          }

          const cachedContainerIndex = containers.findIndex(c => c.id === removedElementId);
          if (cachedContainerIndex >= 0) {
            containers.splice(cachedContainerIndex, 1);
          }
        }
      });
    }

    if (avaProjectDiff.newElements?.length > 0) {
      avaProjectDiff.newElements.forEach(newElement => {
        elementsById[newElement.element.id] = newElement.element;

        const parentContainer = containers.find(c => c.id === newElement.parentElementId);
        if (parentContainer) {
          parentContainer.elements.splice(newElement.elementIndex, 0, newElement.element);

          if (newElement.element.elementType === 'ServiceSpecificationGroupDto') {
            const group = newElement.element as ServiceSpecificationGroupDto;
            if (group.elements == null) {
              group.elements = [];
            }

            containers.push(newElement.element);
          }
        }
      });
    }

    if (avaProjectDiff.serviceSpecificationDiff?.length > 0) {
      avaProjectDiff.serviceSpecificationDiff.forEach(operation => {
        applyOperation(serviceSpecification, this.transformToJsonPatchOperation(operation));
      });
    }

    if (avaProjectDiff.moveOperations?.length > 0) {
      avaProjectDiff.moveOperations.forEach(moveOperation => {
        const sourceContainer = containers.find(c => c.id === moveOperation.sourceContainerId);
        const targetContainer = containers.find(c => c.id === moveOperation.targetContainerId);
        const sourceIndex = sourceContainer.elements.findIndex(e => e.id === moveOperation.elementId);
        const element = sourceContainer.elements[sourceIndex];
        sourceContainer.elements.splice(sourceIndex, 1);
        // Reordering happens in the next step, so we only need to move the element to the new container
        targetContainer.elements.push(element);
      });
    }

    if (avaProjectDiff.containerReorderOperations?.length > 0) {
      avaProjectDiff.containerReorderOperations.forEach(reorderOperation => {
        const localCachedContainer = containers.find(c => c.id === reorderOperation.containerId);
        if (localCachedContainer) {
          const newElements = reorderOperation.elementIds.map(elementId => elementsById[elementId]);
          // To keep the reference for the array but just set the new values, we're directly using
          // Object.assign to set the values. We need to additionally also set the length property.
          // Taken from here: https://stackoverflow.com/a/44660117/4190785
          Object.assign(localCachedContainer.elements, newElements, {
            length: newElements.length
          });
        }
      });
    }
  }

  private static transformToJsonPatchOperation(operation: Operation): JsonPatchOperation {
    return operation as JsonPatchOperation;
  }
}
