import { Pipe, PipeTransform } from '@angular/core';

import { IElementDto } from '../../../generated-client/generated-client';

import { ShortPositionAndPriceTypePipe } from './short-position-and-price-type.pipe';

@Pipe({
  name: 'elementType',
  standalone: true
})
export class ElementTypePipe implements PipeTransform {
  constructor(private shortPositionAndPriceTypePipe: ShortPositionAndPriceTypePipe) {}

  transform(value: IElementDto): string {
    switch (value.elementTypeDiscriminator) {
      case 'NoteTextDto':
        return 'Hinweis';

      case 'ExecutionDescriptionDto':
        return 'Ausführungsbeschreibung';

      case 'ServiceSpecificationGroupDto':
        return 'Gruppe';

      case 'PositionDto': {
        const posType = this.shortPositionAndPriceTypePipe.transform(value);
        return `Position (${posType})`;
      }
    }

    return null;
  }
}
