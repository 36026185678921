<div class="wrapper">
  <h2 *ngIf="data.element?.fileId">Bild</h2>
  <h2 *ngIf="attachmentImageFileId">Anlage</h2>
  <div *ngIf="data.element?.fileId" class="wrapper-photo" [class.full-screen]="false">
    <img src="{{ data.element?.fileId | imgSrc | async }}" />
  </div>
  <div *ngIf="attachmentImageFileId" class="wrapper-photo" [class.full-screen]="false">
    <img src="{{ attachmentImageFileId | imgSrc | async }}" />
  </div>
  <div class="buttons" fxLayoutGap="12px">
    <button mat-raised-button (click)="closeDialog()">Abbrechen</button>
    <button mat-raised-button (click)="deleteImage()" *ngIf="!data.isReadOnly">Löschen</button>
  </div>
</div>
