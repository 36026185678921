<form mat-dialog-content [formGroup]="formCompany" (ngSubmit)="createCompany()" class="form-wrap">
  <div>
    <mat-form-field appearance="fill">
      <mat-label>Name</mat-label>
      <input type="text" matInput formControlName="name" required />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Straße</mat-label>
      <input type="text" matInput formControlName="street" required />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="fill">
      <mat-label>PLZ</mat-label>
      <input type="text" matInput formControlName="zipCode" required />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Ort</mat-label>
      <input type="text" matInput formControlName="city" required />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="fill">
      <mat-label>Land</mat-label>
      <input type="text" matInput formControlName="country" required />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Ländercode</mat-label>
      <input type="text" matInput formControlName="countryCode" />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="fill">
      <mat-label>Telefon</mat-label>
      <input type="text" matInput formControlName="phone" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Email</mat-label>
      <input type="text" matInput formControlName="email" />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="fill">
      <mat-label>USt Id</mat-label>
      <input type="text" matInput formControlName="vatId" />
    </mat-form-field>
  </div>

  <button mat-raised-button type="submit" color="primary" [disabled]="formCompany.invalid">Firma hinzufügen</button>
</form>
