<h1>Stammdaten</h1>
<nav mat-tab-nav-bar *ngIf="!(router.url | includes: 'new-group')" [tabPanel]="tabPanel">
  <a
    mat-tab-link
    (click)="selectedTab(tab.path)"
    #rla="routerLinkActive"
    *ngFor="let tab of tabs; let i = index"
    [routerLink]="tab.path"
    routerLinkActive="active"
    [active]="rla.isActive"
    >{{ tab.viewValue }}
  </a>
</nav>
<div class="wrapper">
  <router-outlet #tabPanel></router-outlet>
</div>
