<div mat-dialog-content class="wrapper-inquiry">
  <h1>Preisanfrage exportieren</h1>

  <mat-tab-group animationDuration="0ms">
    <mat-tab label="Neuer Export">
      <div fxLayoutAlign="center center" fxFlexFill *ngIf="requestEnRoute">
        <mat-spinner></mat-spinner>
      </div>
      <div class="wrapper-export" *ngIf="!requestEnRoute" fxLayout="column" fxLayoutAlign="space-between">
        <mat-form-field appearance="fill">
          <mat-label>Typ</mat-label>
          <mat-select [formControl]="targetType">
            <mat-option *ngFor="let targetType of targetTypes" [value]="targetType">
              {{ targetType | avaFileTargetType }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button color="primary" mat-raised-button (click)="createOfferRequest()">Speichern</button>
      </div>
    </mat-tab>
    <mat-tab label="Drucken & PDF">
      <div class="wrapper-pdf">
        <div *ngIf="!showingPdf && !requestEnRoute" fxLayout="column">
          <mat-checkbox [(ngModel)]="includeSignatureField">Signaturfeld im Angebot drucken</mat-checkbox>
          <mat-checkbox [(ngModel)]="includeHeaderOnlyOnFirstPage">Kopfzeile nur auf der ersten Seite drucken</mat-checkbox>
          <mat-checkbox [(ngModel)]="includeLongTexts">Langtexte ausgeben</mat-checkbox>
        </div>
        <div fxLayout="column" fxLayoutGap="10px">
          <div fxLayout="row" fxLayoutGap="10px">
            <button mat-raised-button color="primary" (click)="selectStartingText()">Vortext</button>
            <button mat-raised-button color="warn" (click)="offerStartText = null" [disabled]="!offerStartText">Löschen</button>
          </div>
          <div fxLayout="row" fxLayoutGap="10px">
            <button mat-raised-button color="primary" (click)="selectEndingText()">Schlusstext</button>
            <button mat-raised-button color="warn" (click)="offerEndText = null" [disabled]="!offerEndText">Löschen</button>
          </div>
        </div>
        <button
          class="mt-10"
          color="primary"
          mat-raised-button
          (click)="generatePdfAndShowPrintView()"
          *ngIf="!showingPdf && !requestEnRoute"
        >
          PDF Erzeugen
        </button>
        <h1 *ngIf="showingPdf && !requestEnRoute && !waitingForPdf">Bitte Druckvorschaufenster beachten.</h1>
        <div fxLayoutAlign="center center" fxFlexFill *ngIf="requestEnRoute || waitingForPdf">
          <mat-spinner></mat-spinner>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
