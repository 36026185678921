<div class="header-wrap" #headerWrap>
  <h1>Projekt Erstellen</h1>
</div>
<div class="stepper-wrap" [style.height]="sanitizer.bypassSecurityTrustStyle('calc(100% - ' + headerWrap.offsetHeight + 'px)')">
  <mat-vertical-stepper [linear]="true" #stepper>
    <mat-step>
      <ng-template matStepLabel>Datenübernahme</ng-template>
      <p>Du kannst Daten direkt aus einer GAEB oder ÖNorm Datei übernehmen, um das Projekt anzulegen.</p>
      <input hidden type="file" (change)="onFileSelected(fileInput.files)" #fileInput />
      <button mat-button mat-raised-button (click)="fileInput.click()" *ngIf="!performingAvaInspection && !avaFileInspectionResult">
        LV Datei Auswählen
      </button>
      <div *ngIf="selectedFile">
        <p>
          <span>Dateiname: </span><strong>{{ selectedFile.name }}</strong>
        </p>
      </div>
      <div *ngIf="avaFileInspectionResult">
        <p>Dateityp: {{ avaFileInspectionResult.origin | originDto }}</p>
        <p>Bereit zur Datenübernahme!</p>
      </div>
      <div *ngIf="fileCouldNotBeRead">
        <p class="warning-text">Die Datei konnte nicht gelesen werden.</p>
      </div>
      <div fxFlexFill fxLayoutAlign="center center" *ngIf="performingAvaInspection">
        <mat-spinner></mat-spinner>
      </div>
      <div class="stepper-actions">
        <button mat-raised-button color="primary" matStepperNext [disabled]="!avaFileInspectionResult">Weiter mit Datei</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="projectDataFormGroup">
      <form [formGroup]="projectDataFormGroup">
        <ng-template matStepLabel>Projektdaten</ng-template>
        <div>
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Projektname</mat-label>
            <input matInput formControlName="name" placeholder="Projektname" required />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Auftraggeber</mat-label>
            <input matInput formControlName="buyer" placeholder="Auftraggeber" required />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Projektbeschreibung</mat-label>
            <input matInput formControlName="description" placeholder="Kurzbeschreibung des Projekts" />
          </mat-form-field>
        </div>

        <div fxLayoutGap="12px">
          <button mat-raised-button matStepperPrevious>
            <mat-icon class="back-arrow">arrow_upward</mat-icon>
            Zurück
          </button>
          <button
            mat-raised-button
            color="primary"
            matStepperNext
            [disabled]="!projectDataFormGroup.valid || projectDataFormGroup.value.projectTaxRate < 0"
          >
            Weiter
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Speicherort</ng-template>
      <p>Bitte wähle aus, wo das Projekt gespeichert werden soll.</p>
      <input hidden type="file" (change)="onFileSelected(projectSaveLocationFileInput.files)" #projectSaveLocationFileInput />
      <button mat-button mat-raised-button (click)="selectProjectSaveLocationInQtoOnlyMode()">Speicherort Auswählen</button>
      <div *ngIf="selectedFile">
        <p>
          <span>Speicherort: </span><strong>{{ selectedDatabaseFileLocation }}</strong>
        </p>
      </div>
      <div class="stepper-actions">
        <button mat-raised-button color="primary" matStepperNext [disabled]="!selectedDatabaseFileLocation">Weiter</button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Fertig</ng-template>
      <p>Das Projekt kann erstellt werden.</p>
      <div *ngIf="!requestEnRoute" fxLayoutGap="12px">
        <button mat-raised-button matStepperPrevious>
          <mat-icon class="back-arrow">arrow_upward</mat-icon>
          Zurück
        </button>
        <button mat-raised-button color="primary" (click)="createProject()">Projekt Erstellen</button>
        <button mat-raised-button color="warn" (click)="cancelProjectCreation()">Abbrechen</button>
      </div>
      <div fxFlexFill fxLayoutAlign="center center" *ngIf="requestEnRoute">
        <mat-spinner></mat-spinner>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>
