<div class="wrapper">
  <div fxFlexFill class="spinner" fxLayoutAlign="center center" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>
  <div [ngClass]="{ opacity: isLoading }">
    <div fxLayout="row" fxLayoutGap="30px">
      <div>
        <div fxLayoutGap="12px" class="buttons">
          <button mat-raised-button color="primary" (click)="back()">Zurück</button>
          <button mat-raised-button color="primary" (click)="current()" [disabled]="saved">Wiederherstellen</button>
          <button mat-raised-button color="primary" (click)="saveAdditions(false)" [disabled]="saved">Zuschlagssätze übernehmen</button>
        </div>
        <div *ngIf="tryAddition">
          <div fxLayoutGap="12px">
            <mat-form-field appearance="fill">
              <mat-label>Wagnis und Gewinn (WuG)</mat-label>
              <input
                name="riskAndProfit"
                required
                matInput
                [(ngModel)]="tryAddition.riskAndProfitAddition"
                (change)="isChanged = true; saved = false; selectAdditions()"
              />
              <span matSuffix>%</span>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Kalkulationslohn</mat-label>
              <input
                name="calculationWage"
                number
                matInput
                matInputDecimalPlaces
                [(ngModel)]="tryAddition.calculationWage"
                [disabled]="hasDedicatedWageCalculation"
                (change)="isChanged = true; saved = false; selectAdditions()"
              />
              <span matSuffix>€</span>
            </mat-form-field>
          </div>

          <strong>Zuschläge</strong>
          <div class="bottom-form">
            <div fxLayout="row" fxLayoutGap="12px">
              <div fxFlex="0 0 140px"></div>
              <div fxFlex="0 0 100px">
                <strong>BGK</strong>
              </div>
              <div fxFlex="0 0 100px">
                <strong>AGK</strong>
              </div>
              <div fxFlex="0 0 160px">
                <strong>Typ</strong>
              </div>
            </div>

            <div fxLayout="row" fxLayoutGap="12px">
              <div fxFlex="0 0 140px" class="hours">
                <span>Lohnstunden</span>
              </div>
              <div fxFlex="0 0 100px">
                <mat-form-field appearance="fill">
                  <input
                    name="labourSiteAddition"
                    required
                    matInput
                    [(ngModel)]="tryAddition.laborSiteOperationCostsAddition"
                    (change)="isChanged = true; saved = false; selectAdditions()"
                  />
                  <span matSuffix>%</span>
                </mat-form-field>
              </div>
              <div fxFlex="0 0 100px">
                <mat-form-field appearance="fill">
                  <input
                    name="laboutCompanyAddition"
                    required
                    matInput
                    [(ngModel)]="tryAddition.laborCompanyOperationCostsAddition"
                    (change)="isChanged = true; saved = false; selectAdditions()"
                  />
                  <span matSuffix>%</span>
                </mat-form-field>
              </div>
            </div>

            <div fxLayout="row" fxLayoutGap="12px" *ngFor="let priceComponent of tryAddition.priceComponents">
              <div fxFlex="0 0 140px">
                <mat-form-field appearance="fill">
                  <input
                    matInput
                    name="pc-{{ priceComponent.index }}-name"
                    [disabled]="!isChangeNamePriceComponentMode"
                    [(ngModel)]="priceComponent.priceComponent"
                  />
                  <button
                    matSuffix
                    mat-icon-button
                    *ngIf="!isChangeNamePriceComponentMode"
                    (click)="isChangeNamePriceComponentMode = true"
                    class="edit"
                  >
                    <mat-icon>mode_edit</mat-icon>
                  </button>
                  <button
                    matSuffix
                    mat-icon-button
                    *ngIf="isChangeNamePriceComponentMode"
                    (click)="changeNamePriceComponent()"
                    color="primary"
                  >
                    <mat-icon>done</mat-icon>
                  </button>
                  <button
                    matSuffix
                    mat-icon-button
                    *ngIf="isChangeNamePriceComponentMode"
                    (click)="cancelChangingNamePriceComponent()"
                    color="warn"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <div fxFlex="0 0 100px">
                <mat-form-field appearance="fill">
                  <input
                    name="pc-{{ priceComponent.index }}-siteAddition"
                    matInput
                    [(ngModel)]="priceComponent.siteOperationCostsAddition"
                    (change)="isChanged = true; saved = false; selectAdditions()"
                  />
                  <span matSuffix>%</span>
                </mat-form-field>
              </div>
              <div fxFlex="0 0 100px">
                <mat-form-field appearance="fill">
                  <input
                    name="pc-{{ priceComponent.index }}-companyAddition"
                    required
                    matInput
                    [(ngModel)]="priceComponent.companyOperationCostsAddition"
                    (change)="isChanged = true; saved = false; selectAdditions()"
                  />
                  <span matSuffix>%</span>
                </mat-form-field>
              </div>
              <div fxFlex="0 0 160px">
                <mat-form-field appearance="fill">
                  <mat-select
                    disabled
                    name="pc-{{ priceComponent.index }}-priceComponentType"
                    [ngModel]="priceComponent.priceComponentType"
                  >
                    <mat-option *ngFor="let priceComponentType of priceComponentTypes" [value]="priceComponentType">{{
                      priceComponentType | priceComponentType
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <table class="total">
          <tr>
            <td class="title">LV Summe:</td>
            <td [class.green]="totalPrice < originalTotalPrice" [class.red]="totalPrice > originalTotalPrice">
              {{ totalPrice | projectCurrency }}
            </td>
            <td [class.green]="totalPrice < originalTotalPrice" [class.red]="totalPrice > originalTotalPrice">
              <ng-container *ngIf="totalPriceDeltaAbsolute">
                (
                {{ totalPriceDeltaAbsolute > 0 ? '+' : '' }}{{ totalPriceDeltaAbsolute | number: '1.2-2' }} /
                {{ totalPriceDeltaAbsolute > 0 ? '+' : '' }}{{ totalPriceDeltaPercent | percent: '1.2-2' }}
                )
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="title">LV Summe Original:</td>
            <td>
              {{ originalTotalPrice | projectCurrency }}
            </td>
          </tr>
        </table>
      </div>
      <pa-calculation-middle-wages
        #wageCalculationEditor
        [scenarioMode]="true"
        [laborSiteOperationCostsAddition]="laborSiteOperationCostsAddition"
        [laborCompanyOperationCostsAddition]="laborCompanyOperationCostsAddition"
        [riskAndProfitAddition]="riskAndProfitAddition"
        (wageCalculationLoaded)="onWageCalculationLoaded($event)"
        (calculationChange)="onCalculationChange($event)"
        [transferWage]="transferWage"
      ></pa-calculation-middle-wages>
    </div>
    <div fxLayout="row">
      <mat-form-field fxFlex="1 0">
        <mat-label>Suche</mat-label>
        <input matInput [(ngModel)]="filter" (ngModelChange)="filterList()" />
      </mat-form-field>
    </div>
    <div *ngIf="filteredList?.length" class="table-wrapper">
      <table mat-table [dataSource]="filteredList">
        <ng-container matColumnDef="itemNumber">
          <th mat-header-cell *matHeaderCellDef>OZ</th>
          <td mat-cell *matCellDef="let row">{{ row.itemNumber.stringRepresentation }}</td>
        </ng-container>
        <ng-container matColumnDef="shortText">
          <th mat-header-cell *matHeaderCellDef>Kurztext</th>
          <td mat-cell *matCellDef="let row">{{ row | positionText }}</td>
        </ng-container>
        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef>Menge</th>
          <td mat-cell *matCellDef="let row" class="right">{{ row.quantity }}</td>
        </ng-container>
        <ng-container matColumnDef="unitTag">
          <th mat-header-cell *matHeaderCellDef>Einheit</th>
          <td mat-cell *matCellDef="let row" class="right">{{ row.unitTag }}</td>
        </ng-container>
        <ng-container matColumnDef="unitPrice">
          <th mat-header-cell *matHeaderCellDef>EP</th>
          <td mat-cell *matCellDef="let row" class="right">{{ row.unitPrice | number: '1.2-2' }}</td>
        </ng-container>
        <ng-container matColumnDef="totalPrice">
          <th mat-header-cell *matHeaderCellDef>GP</th>
          <td mat-cell *matCellDef="let row" class="right">{{ row.totalPrice | number: '1.2-2' }}</td>
        </ng-container>
        <ng-container matColumnDef="delta">
          <th mat-header-cell *matHeaderCellDef>Δ</th>
          <td
            mat-cell
            [class.green]="originalPrices[row.id].deltaPercent < 0"
            [class.red]="originalPrices[row.id].deltaPercent > 0"
            *matCellDef="let row"
            class="right"
          >
            {{ originalPrices[row.id].deltaPercent > 0 ? '+' : '' }}{{ originalPrices[row.id].deltaPercent | percent: '1.2-2' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="originalUnitPrice">
          <th mat-header-cell *matHeaderCellDef>EP Orig.</th>
          <td mat-cell *matCellDef="let row" class="right">{{ originalPrices[row.id].unitPrice | number: '1.2-2' }}</td>
        </ng-container>
        <ng-container matColumnDef="originalTotalPrice">
          <th mat-header-cell *matHeaderCellDef>GP Orig.</th>
          <td mat-cell *matCellDef="let row" class="right">{{ originalPrices[row.id].totalPrice | number: '1.2-2' }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: columns"></tr>
      </table>
    </div>
  </div>
</div>
