import { ReplaySubject, Subject, of } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { CalculationsChecksClient } from '../../generated-client/generated-client';
import { Injectable } from '@angular/core';
import { SelectedSpecificationMessengerService } from './messengers/selected-specification-messenger.service';

@Injectable({
  providedIn: 'root'
})
export class PositionHasCalculationService {
  private positionIdsWithoutCalculationsSource = new ReplaySubject<string[]>(1);
  positionIdsWithoutCalculations = this.positionIdsWithoutCalculationsSource.asObservable();

  private $destroy = new Subject<boolean>();

  private projectId: string;
  private avaProjectId: string;

  constructor(
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private calculationsChecksClient: CalculationsChecksClient
  ) {
    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        takeUntil(this.$destroy),
        filter((e: { avaProjectId: string; parentProjectId: string }) => !!(e?.avaProjectId && e?.parentProjectId))
      )
      .subscribe((e: { avaProjectId: string; parentProjectId: string }) => {
        this.projectId = e.parentProjectId;
        this.avaProjectId = e.avaProjectId;
        this.updateList();
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  updateList(): void {
    const projectId = this.projectId;
    const avaProjectId = this.avaProjectId;

    this.calculationsChecksClient.getCalculationCheckResult(projectId, avaProjectId).subscribe(e => {
      if (projectId == this.projectId && avaProjectId == this.avaProjectId) {
        this.positionIdsWithoutCalculationsSource.next(e.positionIdsWithMissingCalculation);
      }
    });
  }
}
