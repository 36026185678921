import { CdkDragDrop, CdkDragMove, CdkDragStart, CdkDropList, CdkDrag, CdkDragPlaceholder } from '@angular/cdk/drag-drop';
import { NgIf, NgClass, NgFor, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatIcon } from '@angular/material/icon';

import { Observable, Subject, Subscription, map, takeUntil, throttleTime } from 'rxjs';

import { TreeMenuComponent } from 'app/areas/tree/components/tree-menu/tree-menu.component';
import { ClickerService } from 'app/areas/tree/services/clicker.service';
import { ModePageService } from 'app/areas/tree/services/mode-page.service';
import { TreeNodeDragingService } from 'app/areas/tree/services/tree-node-draging.service';
import { TreeNodeMarkService } from 'app/areas/tree/services/tree-node-mark.service';
import { TreeNodeSelectingService } from 'app/areas/tree/services/tree-node-selecting.service';
import { TreeNodeStateService } from 'app/areas/tree/services/tree-node-state.service';
import { IElementDto, ServiceSpecificationDto, ServiceSpecificationGroupDto, SubPositionGet } from 'app/generated-client/generated-client';
import { SelectingElementsType } from 'app/shared/models';
import { ContextMenuSettingsService } from 'app/shared/services/context-menu-settings.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';
import { PositionHasCalculationService } from 'app/shared/services/position-has-calculation.service';
import { AvaProjectDiffApplier } from 'app/shared/services/signalr/ava-project-diff-applier';

import { HoverEffectDirective } from '../../directives/hover-effect.directive';
import { NodeWithoutCalculationPipe } from '../../pipes/node-without-calculation.pipe';
import { ShowPositionAbbreviationPipe } from '../../pipes/show-position-abbreviation.pipe';

import { PositionNodeComponent } from '../position-node/position-node.component';

@Component({
  selector: 'pa-tree-structure-view',
  templateUrl: './tree-structure-view.component.html',
  styleUrls: ['./tree-structure-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    CdkDropList,
    NgClass,
    NgFor,
    HoverEffectDirective,
    MatIconButton,
    MatIcon,
    CdkDrag,
    MatCheckbox,
    FormsModule,
    NgTemplateOutlet,
    CdkDragPlaceholder,
    PositionNodeComponent,
    TreeMenuComponent,
    AsyncPipe,
    ShowPositionAbbreviationPipe,
    NodeWithoutCalculationPipe
  ]
})
export class TreeStructureViewComponent implements OnInit, OnDestroy {
  @Input() set choseElementId(id: string | null) {
    this.choseSelectedElementId = id;
  }
  choseSelectedElementId: string | null;

  @Input() set selectedGroupForCopy(selectedGroup: ServiceSpecificationGroupDto | null) {
    this.selectedGroup = selectedGroup ? JSON.parse(JSON.stringify(selectedGroup)) : null;
  }
  selectedGroup: ServiceSpecificationGroupDto | null = null;

  @Input() set subPositionFromParentComponent(subPosition: { [key: string]: SubPositionGet[] }) {
    if (subPosition) {
      this.subPositions = JSON.parse(JSON.stringify(subPosition));
    }
  }
  subPositions: { [key: string]: SubPositionGet[] };

  @Input() elements: IElementDto[];
  @Input() treeState: { [elementId: string]: boolean } = {};
  @Input() parentNode: IElementDto | ServiceSpecificationDto;
  @Input() hierarchyLevel: number;
  @Input() isSelectingMode = false;
  @Input() isInnerWindow = false;
  @Input() isCopyCalculation = false;
  @Input() isCopyElementView = false;
  @Input() addExtraMargin: boolean;
  @ViewChild(TreeMenuComponent) treeMenuComponent: TreeMenuComponent;

  private $destroy: Subject<boolean> = new Subject<boolean>();
  positionIdsWithoutCalculations$ = this.positionHasCalculationService.positionIdsWithoutCalculations;
  selectedElementId$ = this.selectedSpecificationElementMessengerService.selectedElement.pipe(map((e) => e?.id));
  markedElements$ = this.treeNodeMarkService.treeNodeMarkElements;
  isShowEmptyIcon$: Observable<boolean>;
  dragMovedEvent$: Subscription;

  checkedElements: {
    [elementId: string]: SelectingElementsType;
  } = {};
  contextMenuPosition = { x: '0px', y: '0px' };

  modePage: string;

  constructor(
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private contextMenuSettingsService: ContextMenuSettingsService,
    private clickerService: ClickerService,
    private modePageService: ModePageService,
    private treeNodeStateService: TreeNodeStateService,
    private cdr: ChangeDetectorRef,
    private treeNodeDragingService: TreeNodeDragingService,
    private positionHasCalculationService: PositionHasCalculationService,
    private treeNodeSelectingService: TreeNodeSelectingService,
    private ngZone: NgZone,
    private avaProjectDiffApplier: AvaProjectDiffApplier,
    private treeNodeMarkService: TreeNodeMarkService
  ) {}

  ngOnInit(): void {
    this.modePageService.modePage.pipe(takeUntil(this.$destroy)).subscribe((e) => {
      this.modePage = e;
    });

    if (this.isSelectingMode) {
      this.treeNodeSelectingService.treeNodeSelecting.pipe(takeUntil(this.$destroy)).subscribe((treeNodeSelecting) => {
        this.checkedElements = treeNodeSelecting;
        this.cdr.detectChanges();
      });
    }

    this.avaProjectDiffApplier.avaProjectDiffApplied.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.cdr.detectChanges();
    });
    this.treeNodeStateService.updateTreeNodeState.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.cdr.detectChanges();
    });

    this.isShowEmptyIcon$ = this.treeNodeMarkService.treeNodeMarkElements.pipe(
      map((e) => Object.keys(e)),
      map((ids) => !!this.elements?.filter((el) => ids?.includes(el.id)).length)
    );
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  drop(event: CdkDragDrop<IElementDto[]>): void {
    if (this.modePage !== 'lv-editor') {
      return;
    }
    this.treeNodeDragingService.drop(event);
  }

  switchStateNode(element: IElementDto, e: Event): void {
    e.stopPropagation();
    if (!this.treeState[element.id]) {
      this.treeNodeStateService.set(element);
    } else {
      this.treeNodeStateService.remove(element.id);
    }
  }

  showContextMenu(event: MouseEvent, node: any): void {
    if (this.isCopyCalculation && node.elementType !== 'ServiceSpecificationGroupDto' && node.elementType !== 'PositionDto') {
      return;
    }
    // Taken from https://stackblitz.com/edit/angular-material-context-menu-table?file=app%2Fcontext-menu-example.ts
    this.contextMenuSettingsService.setDefaultSettings(event, node as any, this.contextMenuPosition, this.treeMenuComponent.menu);
  }

  selectElementBySingl(e: IElementDto): void {
    this.clickerService.clickedBySingle(e);
  }

  selectElementByDouble(e: IElementDto): void {
    this.clickerService.clickedByDbl(e);
  }

  dragStart(event: CdkDragStart): void {
    if (this.modePage === 'lv-editor') {
      this.ngZone.runOutsideAngular(() => {
        //Perhaps the interval value for throttleTime can be increased
        this.dragMovedEvent$ = event.source.moved.pipe(throttleTime(100)).subscribe((e: CdkDragMove<any>) => {
          this.treeNodeDragingService.dragMoved(e);
        });
      });
    }
  }

  dragEnded(): void {
    if (this.dragMovedEvent$) {
      this.dragMovedEvent$.unsubscribe();
    }
  }

  changeSelecting(element: IElementDto, event: boolean): void {
    this.treeNodeSelectingService.changeSelecting(event, element.id);
  }

  trackByFn(index: number, el: IElementDto) {
    return el.id;
  }
}
