<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding (click)="onTreeNodeClick(node)">
    <!-- use a disabled button to provide padding for tree leaf -->
    <button mat-icon-button disabled></button>
    <div class="node-text" [paHoverEffect]="{ apply: true }">
      <span class="wrap hover-underline-animation">
        {{ node.title }}
      </span>
    </div>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding (click)="onTreeNodeClick(node)">
    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.title">
      <mat-icon class="mat-icon-rtl-mirror">
        {{ treeControl.isExpanded(node) ? 'remove' : 'add' }}
      </mat-icon>
    </button>
    <div class="node-text" [paHoverEffect]="{ apply: true }">
      <span class="wrap hover-underline-animation">
        {{ node.title }}
      </span>
    </div>
  </mat-tree-node>
</mat-tree>
