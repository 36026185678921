import { CdkOverlayOrigin, CdkConnectedOverlay } from '@angular/cdk/overlay';
import { PercentPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';
import { MatTooltip } from '@angular/material/tooltip';

import { Subscription, fromEvent } from 'rxjs';

import { getStorage, setStorage } from '@shared/utilities/storage';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';
import { ElectronService } from 'app/shared/services/electron/electron.service';

@Component({
  selector: 'pa-zoomer',
  templateUrl: './zoomer.component.html',
  styleUrls: ['./zoomer.component.scss'],
  standalone: true,
  imports: [
    MatTooltip,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    FlexLayoutDirective,
    MatIcon,
    MatSlider,
    MatSliderThumb,
    FormsModule,
    PercentPipe
  ]
})
export class ZoomerComponent implements OnInit, OnDestroy {
  private wheelSubscription: Subscription;
  zoomFactor: number;
  zoomPercent: number;
  valueOfChangeZoom = 0.05;
  isOpenSlider: boolean;
  valueByButton = 5;
  trigger: CdkOverlayOrigin;
  constructor(private electronService: ElectronService) {}

  ngOnInit(): void {
    if (this.electronService.isElectron) {
      this.zoomFactor = getStorage<number>('zoomLevel', 0);
      this.electronService.webFrame.setZoomLevel(this.zoomFactor);
      setStorage('zoomLevel', this.zoomFactor);
      this.zoomPercent = this.convertZoom(this.zoomFactor, true);
      this.electronService.ipcRenderer.on('zoom-changed', (event, zoomFactor) => {
        this.zoomFactor = zoomFactor;
        setStorage('zoomLevel', this.zoomFactor);
        this.zoomPercent = this.convertZoom(this.zoomFactor, true);
      });
    }
  }

  ngOnDestroy(): void {
    this.electronService.ipcRenderer.removeAllListeners('zoom-changed');
  }

  closeOverlay(): void {
    this.isOpenSlider = false;
    this.wheelSubscription.unsubscribe();
  }

  openOverlayPanel(origin: CdkOverlayOrigin): void {
    this.trigger = origin;
    this.isOpenSlider = true;
    this.wheelSubscription = fromEvent(document, 'wheel').subscribe((event: WheelEvent) => {
      if (!event.ctrlKey && !event.shiftKey) {
        let newZoomFactor;
        if (event['deltaY'] < 0) {
          newZoomFactor = Math.min(this.zoomFactor + this.valueOfChangeZoom, 2);
        } else if (event['deltaY'] > 0) {
          newZoomFactor = Math.max(this.zoomFactor - this.valueOfChangeZoom, -2);
        } else {
          newZoomFactor = this.zoomFactor;
        }

        if (newZoomFactor !== this.zoomFactor) {
          this.setZoomFactor(newZoomFactor);
          this.zoomPercent = this.convertZoom(this.zoomFactor, true);
        }
      }
    });
  }

  putValue(zoom: number): void {
    const factor = this.convertZoom(zoom, false);
    this.setZoomFactor(factor);
  }

  changeValueByButton(isIncrese: boolean): void {
    if (isIncrese) {
      this.zoomPercent = Math.min(this.zoomPercent + this.valueByButton, 140);
    } else {
      this.zoomPercent = Math.max(this.zoomPercent - this.valueByButton, 60);
    }
    const factor = this.convertZoom(this.zoomPercent, false);
    this.setZoomFactor(factor);
  }

  setZoomFactor(factor: number) {
    this.electronService.webFrame.setZoomLevel(factor);
    this.zoomFactor = factor;
    setStorage('zoomLevel', this.zoomFactor);
  }

  convertZoom(input: number, inputIsFactor: boolean): number {
    if (inputIsFactor) {
      const zoomPercentage = ((input + 2) / 4) * 80 + 60;
      return Math.round(zoomPercentage);
    } else {
      const zoomFactor = ((input - 60) / 80) * 4 - 2;
      return +zoomFactor.toFixed(2);
    }
  }
}
