import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProjectGet, ProjectsClient } from '../../../../../../generated-client/generated-client';
import { SelectedProjectMessengerService } from '../../../../../../shared/services/messengers/selected-project-messenger.service';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-project-award-id',
  templateUrl: './project-award-id.component.html',
  styleUrls: ['./project-award-id.component.scss'],
  standalone: true,
  imports: [NgIf, FlexLayoutDirective, MatProgressSpinner, FormsModule, MatFormField, MatLabel, MatInput, MatButton]
})
export class ProjectAwardIdComponent implements OnInit {
  isChanged: boolean;
  hasLoaded: boolean;
  isLoading: boolean;
  currentProject: ProjectGet;
  private $destroy = new Subject<boolean>();

  constructor(
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private projectsClient: ProjectsClient
  ) {}

  ngOnInit(): void {
    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((project: ProjectGet) => {
      this.currentProject = { ...project };
      this.hasLoaded = true;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  changeDetail(): void {
    this.isLoading = true;
    this.projectsClient
      .setAwardIdentifierForProject(this.currentProject.id, this.currentProject.awardIdentifier)
      .subscribe((project: ProjectGet) => {
        this.selectedProjectMessengerService.setSelectedProject(project);
        this.isChanged = false;
        this.isLoading = false;
      });
  }
}
