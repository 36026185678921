import { NgIf, DecimalPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';
import { PositionDto, PositionTypeDto, PriceTypeDto } from 'app/generated-client/generated-client';

import { PositionTextPipe } from '../../pipes/ui-data-display/position-text.pipe';
import { PositionTypePipe } from '../../pipes/ui-data-display/position-type.pipe';
import { PriceTypePipe } from '../../pipes/ui-data-display/price-type.pipe';
import { UserSettingsService } from '../../services/user-settings.service';

@Component({
  selector: 'pa-position-line',
  templateUrl: './position-line.component.html',
  styleUrls: ['./position-line.component.scss'],
  standalone: true,
  imports: [MatTooltip, FlexLayoutDirective, NgIf, DecimalPipe, PositionTextPipe, PositionTypePipe, PriceTypePipe]
})
export class PositionLineComponent implements OnInit, OnDestroy {
  @Input() element: PositionDto;
  regularPositionType = PositionTypeDto.Regular;
  regularPriceType = PriceTypeDto.WithTotal;
  showPositionAndPriceType = true;

  private $destroy = new Subject<void>();

  constructor(private userSettingsService: UserSettingsService) {}

  ngOnInit(): void {
    this.userSettingsService.currentUserSettings.pipe(takeUntil(this.$destroy)).subscribe((us) => {
      this.showPositionAndPriceType = us.showPositionTypesInQuantityTakeOff;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }
}
