<pa-projects-table
  *ngIf="!isCards"
  [projectsPaginated]="projectsPaginated"
  [pageSizeOptions]="pageSizeOptions"
  [isContextMenu]="isInnerWindow"
  (selectRow)="selectProject($event)"
  (getContextMenu)="runContextMenu($event)"
  (isMenuOpened)="getMenuStatus($event)"
>
</pa-projects-table>
<pa-projects-cards *ngIf="isCards" [projectsPaginated]="projectsPaginated" (selectRow)="selectProject($event)"> </pa-projects-cards>
