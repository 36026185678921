<form #savePriceComponentForm="ngForm">
  <ng-container *ngIf="selectedAdditionGroup">
    <div class="spinner" *ngIf="isLoading">
      <div fxFlex fxLayoutAlign="center center">
        <mat-spinner></mat-spinner>
      </div>
    </div>
    <ng-container>
      <div fxLayoutGap="12px">
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Name</mat-label>
          <input name="groupName" required matInput [(ngModel)]="selectedAdditionGroup.name" [disabled]="!isEditing" />
        </mat-form-field>
        <mat-checkbox name="isDefault" [(ngModel)]="selectedAdditionGroup.isDefault" [disabled]="!isEditing"> Standard </mat-checkbox>
      </div>
    </ng-container>
    <div fxLayoutGap="12px">
      <pa-percentage-input-field
        [label]="'Wagnis und Gewinn (WuG)'"
        [value]="selectedAdditionGroup.riskAndProfitAddition"
        [isEditing]="isEditing"
        (valueUpdated)="updateSelectedAdditionGroup($event, 'riskAndProfitAddition')"
      ></pa-percentage-input-field>

      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Kalkulationslohn</mat-label>
        <input name="calculationWage" required matInput [value]="selectedAdditionGroup.calculationWage | number: '1.2-2'" disabled />
        <span matSuffix>€</span>
      </mat-form-field>
    </div>
    <strong>Zuschläge</strong>
    <div class="bottom-form">
      <div>
        <div fxLayout="row" fxLayoutGap="24px">
          <div fxFlex="0 0 120px"></div>
          <div fxFlex="0 0 100px">
            <strong>BGK</strong>
          </div>
          <div fxFlex="0 0 100px">
            <strong>AGK</strong>
          </div>
          <div fxFlex="0 0 160px">
            <strong>Typ</strong>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="24px">
          <div fxFlex="0 0 120px" class="hours">
            <span>Lohnstunden</span>
          </div>
          <div fxFlex="0 1 100px">
            <pa-percentage-input-field
              [value]="selectedAdditionGroup.laborSiteOperationCostsAddition"
              [isEditing]="isEditing"
              (valueUpdated)="updateSelectedAdditionGroup($event, 'laborSiteOperationCostsAddition')"
            ></pa-percentage-input-field>
          </div>
          <div fxFlex="0 1 100px">
            <pa-percentage-input-field
              [value]="selectedAdditionGroup.laborCompanyOperationCostsAddition"
              [isEditing]="isEditing"
              (valueUpdated)="updateSelectedAdditionGroup($event, 'laborCompanyOperationCostsAddition')"
            ></pa-percentage-input-field>
          </div>
          <div *ngIf="isEditing" fxFlex="0 1 160px"></div>
          <div fxFlex="10" *ngIf="isEditing">
            <button mat-button mat-raised-button (click)="addPriceComponent()" color="primary">
              <mat-icon>add</mat-icon>
              Neu
            </button>
          </div>
        </div>
        <ng-container>
          <div fxLayout="row" fxLayoutGap="24px" *ngFor="let priceComponent of selectedAdditionGroup.masterPriceComponentAdditions">
            <div fxFlex="0 1 120px">
              <mat-form-field appearance="fill">
                <mat-label>Name</mat-label>
                <input
                  name="pc-{{ priceComponent.index }}-name"
                  required
                  matInput
                  [(ngModel)]="priceComponent.priceComponent"
                  [disabled]="!isEditing"
                />
              </mat-form-field>
            </div>
            <div fxFlex="0 1 100px">
              <pa-percentage-input-field
                [value]="priceComponent.siteOperationCostsAddition"
                [isEditing]="isEditing"
                (valueUpdated)="updateSelectedAdditionGroup($event, 'siteOperationCostsAddition', priceComponent.index)"
              ></pa-percentage-input-field>
            </div>
            <div fxFlex="0 1 100px">
              <pa-percentage-input-field
                [value]="priceComponent.companyOperationCostsAddition"
                [isEditing]="isEditing"
                (valueUpdated)="updateSelectedAdditionGroup($event, 'companyOperationCostsAddition', priceComponent.index)"
              ></pa-percentage-input-field>
            </div>
            <div fxFlex="0 1 160px">
              <mat-form-field appearance="fill">
                <mat-select
                  name="pc-{{ priceComponent.index }}-priceComponentType"
                  [(ngModel)]="priceComponent.priceComponentType"
                  [disabled]="!isEditing"
                >
                  <mat-option *ngFor="let priceComponentType of priceComponentTypes" [value]="priceComponentType">{{
                    priceComponentType | priceComponentType
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div fxFlex="10" *ngIf="isEditing">
              <button mat-button mat-raised-button (click)="removePriceComponent(priceComponent)">Löschen</button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</form>
