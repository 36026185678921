import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialogRef, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { Subject } from 'rxjs';
import { filter, first, take } from 'rxjs/operators';

import { CalculationCheckGet, CalculationsChecksClient, PositionDto } from 'app/generated-client/generated-client';
import { filterElementsByIds } from 'app/shared/utilities/filter-elements-by-ids';

import { SelectionListWithNumberAndTextComponent } from '../../../../../../shared/components/selection-list-with-number-and-text/selection-list-with-number-and-text.component';
import { SelectedSpecificationMessengerService } from '../../../../../../shared/services/messengers/selected-specification-messenger.service';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';
import { FlatElementsService } from '../../../../../tree/services/flat-elements.service';

@Component({
  selector: 'pa-calculation-check-modal-window',
  templateUrl: './calculation-check-modal-window.component.html',
  styleUrls: ['./calculation-check-modal-window.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    FlexLayoutDirective,
    MatIcon,
    MatDialogContent,
    MatCheckbox,
    FormsModule,
    NgIf,
    MatProgressSpinner,
    SelectionListWithNumberAndTextComponent
  ]
})
export class CalculationCheckModalWindowComponent implements OnInit, OnDestroy {
  private $destroy: Subject<boolean> = new Subject<boolean>();

  missingCalculationsPosition: PositionDto[];
  missingTextAdditionsPosition: PositionDto[];
  negativePricePosition: PositionDto[];
  zeroPricePosition: PositionDto[];
  missingUnitTagPositions: PositionDto[];
  zeroQuantityPositions: PositionDto[];
  negativeQuantityPositions: PositionDto[];
  positionsWithErrorsInCalculation: PositionDto[];

  checkResult: CalculationCheckGet;
  hasFilteredResult = false;
  showChecksWithoutErrors = false;

  constructor(
    private flatElementsService: FlatElementsService,
    @Optional() public dialogRef: MatDialogRef<CalculationCheckModalWindowComponent>,
    private calculationChecksClient: CalculationsChecksClient,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService
  ) {}

  ngOnInit(): void {
    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        filter((r) => !!r),
        first()
      )
      .subscribe((s) => {
        this.calculationChecksClient.getCalculationCheckResult(s.project.id, s.avaProjectId).subscribe((checkResult) => {
          this.checkResult = checkResult;
          this.filterMissingElements();
        });
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  filterMissingElements(): void {
    this.flatElementsService.flatElementsDto.pipe(take(1)).subscribe((elements) => {
      this.missingCalculationsPosition = filterElementsByIds(elements, this.checkResult.positionIdsWithMissingCalculation);
      this.missingTextAdditionsPosition = filterElementsByIds(elements, this.checkResult.elementIdsWithMissingTextAdditions);
      this.negativePricePosition = filterElementsByIds(elements, this.checkResult.positionIdsWithNegativePrice);
      this.zeroPricePosition = filterElementsByIds(elements, this.checkResult.positionIdsWithZeroPrice);
      this.missingUnitTagPositions = filterElementsByIds(elements, this.checkResult.positionIdsWithMissingUnitTag);
      this.zeroQuantityPositions = filterElementsByIds(elements, this.checkResult.positionIdsWithZeroQuantity);
      this.negativeQuantityPositions = filterElementsByIds(elements, this.checkResult.positionIdsWithNegativeQuantity);
      this.positionsWithErrorsInCalculation = filterElementsByIds(elements, this.checkResult.positionIdsWithErrorsInCalculation);
      this.hasFilteredResult = true;
    });
  }

  selectedMissingPosition(position: PositionDto): void {
    this.dialogRef.close(position);
  }
}
