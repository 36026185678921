import { Injectable } from '@angular/core';

import { CopyCalculationViewMessengerService } from 'app/shared/services/electron/copy-calculation-view-messenger.service';

import { AvaNotificationsService } from './ava-notifications.service';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {
  constructor(
    private avaNotificationsService: AvaNotificationsService,
    private copyCalculationViewMessengerService: CopyCalculationViewMessengerService
  ) {}

  getData(type: string, dataTarget: any): void {
    try {
      navigator.clipboard.readText().then(
        (ss: string) => {
          let parse: any;
          try {
            parse = JSON.parse(ss);
          } catch (e) {
            parse = null;
          }
          if (parse?.type === type) {
            const { data } = parse;
            if (Array.isArray(data)) {
              dataTarget.length = 0;
              dataTarget.push(...data);
            }
          }
        },
        () => {}
      );
    } catch (e) {
      // We want to wrap reading the text here in a try-catch block to avoid the error being thrown to the user.
      // There could be reasons for this not working, e.g. when running in a browser environment where access
      // to the clipboard is not allowed.
      console.error(e);
    }
  }

  copyData(type: string, data: any): void {
    navigator.clipboard.writeText(JSON.stringify({ type, data }));
    const message = data.length === 1 ? '1 Zeile' : `${data.length} Zeilen`;
    this.avaNotificationsService.info(message, 'Zeilen kopiert:', 3000);
    this.copyCalculationViewMessengerService.sendSaveCalculationRowsFromCopyToMainWindow(data);
  }
}
