<h1>Ausführungsbeschreibung</h1>
<ng-container *ngIf="!elementViewOpen && element">
  <h2>{{ element.label }}</h2>
  <p>Nr. {{ element.identifier }}</p>

  <ng-container *ngIf="element.blocks?.length">
    <ng-container *ngFor="let block of element.blocks">
      <strong> {{ block.shortText }} </strong>
      <div [innerHTML]="block.htmlLongText | safeHtml"></div>
      <hr />
    </ng-container>
  </ng-container>
</ng-container>
