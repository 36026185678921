import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DetailTableModalService {
  private infoToDetailTableModalSource = new ReplaySubject<DOMRect>(1);
  infoToDetailTableModal = this.infoToDetailTableModalSource.asObservable();
  private tryChangePositionSource = new Subject<boolean>();
  tryChangePosition = this.tryChangePositionSource.asObservable();

  constructor() {}

  setToDetailTableModal(rect: DOMRect): void {
    this.infoToDetailTableModalSource.next(rect);
  }

  startChangePosition(): void {
    this.tryChangePositionSource.next(true);
  }
}
