<ng-container *ngIf="!readOnly || totalCount !== 0; else emptyList">
  <table mat-table [dataSource]="personsService" matSort (matSortChange)="personsService.onSort($event)">
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let row">{{ row.name }}</td>
    </ng-container>

    <ng-container matColumnDef="notes">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Notiz</th>
      <td mat-cell *matCellDef="let row">{{ row.notes }}</td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell mat-sort-header class="small-column" *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="small-column">
        <button class="btn-edit" (click)="confirmDeletion($event, row)">
          <mat-icon color="warn">close</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayColumn"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumn" (click)="person(row)"></tr>
  </table>
  <pa-upper-paginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    [upperPaginated]="personsService.paginationResult | async"
    [upperService]="personsService"
    (page)="personsService.onPage($event)"
  >
  </pa-upper-paginator>
  <a mat-raised-button (click)="person()" *ngIf="!readOnly">Person Hinzufügen</a>
</ng-container>
<ng-template #emptyList>
  <p>Keine Ansprechpartner zum Kontakt gespeichert</p>
</ng-template>
