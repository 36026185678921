import { NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subject, combineLatest, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { ChangeTotalService } from '@serv-spec/services/change-total.service';
import { LvEditorService } from '@serv-spec/services/lv-editor.service';

import { AvaNotificationsService } from '@shared/services/ava-notifications.service';
import { SelectedSpecificationMessengerService } from '@shared/services/messengers/selected-specification-messenger.service';

import { CalculationFixedPriceService } from '@project/services/calculation-fixed-price.service';

import {
  IElementDto,
  PositionCalculation,
  PositionCalculationGet,
  PositionCalculationsClient
} from 'app/generated-client/generated-client';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';

import { ExecutionDescriptionComponent } from '../../../../../../../elements/components/execution-description/execution-description.component';
import { NoteTextComponent } from '../../../../../../../elements/components/note-text/note-text.component';
import { PositionComponent } from '../../../../../../../elements/components/position/position.component';
import { ServiceSpecificationGroupComponent } from '../../../../../../../elements/components/service-specification-group/service-specification-group.component';

import { LvEditorExecutionDescriptionComponent } from '../lv-editor-execution-description/lv-editor-execution-description.component';
import { LvEditorNoteTextComponent } from '../lv-editor-note-text/lv-editor-note-text.component';
import { LvEditorPositionComponent } from '../lv-editor-position/lv-editor-position.component';
import { LvEditorServiceSpecificationGroupComponent } from '../lv-editor-service-specification-group/lv-editor-service-specification-group.component';

@Component({
  selector: 'pa-lv-editor-element',
  templateUrl: './lv-editor-element.component.html',
  styleUrls: ['./lv-editor-element.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    LvEditorNoteTextComponent,
    LvEditorPositionComponent,
    LvEditorServiceSpecificationGroupComponent,
    LvEditorExecutionDescriptionComponent,
    NoteTextComponent,
    ExecutionDescriptionComponent,
    ServiceSpecificationGroupComponent,
    PositionComponent
  ]
})
export class LvEditorElementComponent implements OnInit, OnDestroy {
  @Input() isEditableAll: boolean;
  @Input() isModal: boolean;
  selectedElement: IElementDto;
  editMode: boolean;
  projectId: string;
  serviceSpecificationId: string;
  positionCalculation: PositionCalculation;
  calculateUnitPrice: number;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private lvEditorService: LvEditorService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private positionCalculationsClient: PositionCalculationsClient,
    private calculationFixedPriceService: CalculationFixedPriceService,
    private notificationsService: AvaNotificationsService,
    private changeTotalService: ChangeTotalService
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.selectedSpecificationElementMessengerService.selectedElement,
      this.selectedSpecificationMessengerService.selectedServiceSpecification
    ])
      .pipe(
        takeUntil(this.$destroy),
        switchMap(([e, p]: [{ element: IElementDto }, { avaProjectId: string; parentProjectId: string }]) => {
          this.selectedElement = e?.element;
          this.projectId = p?.parentProjectId;
          this.serviceSpecificationId = p?.avaProjectId;
          return this.selectedElement &&
            this.selectedElement.elementTypeDiscriminator === 'PositionDto' &&
            this.projectId &&
            this.serviceSpecificationId
            ? this.getPositionCalculation(this.selectedElement?.id)
            : of(null);
        })
      )
      .subscribe((e: { positionCalculation: PositionCalculation }) => {
        this.setPositionCalculation(e?.positionCalculation);
      });

    this.lvEditorService.editMode.pipe(takeUntil(this.$destroy)).subscribe((e: boolean) => (this.editMode = e));

    this.calculationFixedPriceService.calculationFixedPrice.pipe(takeUntil(this.$destroy)).subscribe((fixedPriceInfo) => {
      if (fixedPriceInfo?.positionId === this.selectedElement?.id) {
        this.saveFixedPrice(fixedPriceInfo?.positionId, fixedPriceInfo?.fixedPrice);
      }
    });
  }

  ngOnDestroy(): void {
    this.lvEditorService.stopChangeElement();
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private getPositionCalculation(positionId: string): Observable<PositionCalculationGet> {
    return this.positionCalculationsClient.getPositionCalculation(this.projectId, this.serviceSpecificationId, positionId);
  }

  setPositionCalculation(positionCalculation?: PositionCalculation): void {
    if (positionCalculation) {
      this.positionCalculation = positionCalculation;
      const calculationFixedPrice = positionCalculation?.fixedPrice || null;
      this.calculateUnitPrice = positionCalculation.unitPriceAfterAdditions;
      this.calculationFixedPriceService.setFixedPriceForPosition(this.selectedElement.id, calculationFixedPrice);
    }
  }

  saveFixedPrice(positionId, positionFixedPrice): void {
    this.positionCalculation.fixedPrice = positionFixedPrice;
    this.positionCalculationsClient
      .calculatePosition(this.projectId, this.serviceSpecificationId, positionId, false, this.positionCalculation)
      .subscribe({
        next: (r: { positionCalculation?: PositionCalculation }) => {
          this.changeTotalService.setChangedTotal(true);
          this.setPositionCalculation(r.positionCalculation);
        },
        error: () => {
          this.notificationsService.error('Fehler beim Speichern der Kalkulation');
        }
      });
  }
}
