import { NgIf, NgClass, NgFor, DecimalPipe, PercentPipe } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subject, combineLatest, of } from 'rxjs';
import { filter, first, takeUntil, timeout } from 'rxjs/operators';

import {
  QuantitySheetItem,
  QuantitySheetReport,
  QuantityTakeOffGet,
  ReportGetOfQuantitySheetReport,
  ReportsClient
} from 'app/generated-client/generated-client';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { PrintViewMessengerService } from 'app/shared/services/electron/print-view-messenger.service';
import { SelectedQuantityTakeOffMessengerService } from 'app/shared/services/messengers/selected-quantity-take-off-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { getStorage, setStorage } from 'app/shared/utilities/storage';

import { ProgressbarPercentageComponent } from '../../../../../../../../shared/components/progressbar-percentage/progressbar-percentage.component';
import { SafeHtmlPipe } from '../../../../../../../../shared/pipes/safe-html.pipe';
import { ProjectCurrencyPipe } from '../../../../../../../../shared/pipes/ui-data-display/project-currency.pipe';
import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-invoice-sheets',
  templateUrl: './invoice-sheets.component.html',
  styleUrls: ['./invoice-sheets.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatProgressSpinner,
    FlexLayoutDirective,
    MatCheckbox,
    FormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatButton,
    NgClass,
    NgFor,
    ProgressbarPercentageComponent,
    DecimalPipe,
    PercentPipe,
    SafeHtmlPipe,
    ProjectCurrencyPipe
  ]
})
export class InvoiceSheetsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isSeparated: boolean;
  @Input() isHideControls: boolean;
  @Input() serviceSpecificationId: string;
  @Input() quantityTakeOffId: string;
  isAllQTOs: boolean = getStorage<boolean>('SHEET_SHOW_ALL', false);
  isLoaded = false;
  isLoading = false;
  isShowedPdf = false;
  includeLongTexts = false;
  onlyBilledQuantities = false;
  includeHeaderOnlyOnFirstPage = false;
  includePositionsWithoutQuantity = false;
  filter = '';
  quantitiesSource: QuantitySheetItem[];
  filteredSource: QuantitySheetItem[];
  serviceSpecificationTotalSum: number;
  totalSum: number;
  addMargin: number;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private reportsClient: ReportsClient,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private selectedQuantityTakeOffMessengerService: SelectedQuantityTakeOffMessengerService,
    private printViewMessengerService: PrintViewMessengerService,
    private router: Router,
    private route: ActivatedRoute,
    private avaNotificationsService: AvaNotificationsService
  ) {}

  ngOnInit(): void {
    if (!this.isSeparated) {
      this.getData().subscribe(([aa, bb]: [{ avaProjectId: string }, QuantityTakeOffGet]) => {
        this.serviceSpecificationId = aa?.avaProjectId;
        this.quantityTakeOffId = bb?.id;
        if (this.serviceSpecificationId) {
          this.showSheet();
        }
      });
    }

    this.selectedQuantityTakeOffMessengerService.someChangesQuantityTakeOff.pipe(takeUntil(this.$destroy)).subscribe(() => {
      if (this.serviceSpecificationId) {
        this.showSheet();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isSeparated && (changes.serviceSpecificationId || changes.quantityTakeOffId)) {
      if (changes.serviceSpecificationId?.currentValue) {
        this.showSheet();
      }
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private getData(): Observable<[{ avaProjectId: string }, QuantityTakeOffGet]> {
    return combineLatest([
      this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(filter((s) => s?.avaProjectId != null)),
      this.selectedQuantityTakeOffMessengerService.selectedQuantityTakeOff.pipe(
        // We're doing a pretty short timeout, because this page might be opened before any QTO is selected,
        // in that case we're just loading the full project QTO data
        timeout({
          each: 50,
          with: () => of(null)
        })
      )
    ]).pipe(first());
  }

  showSheet(): void {
    this.isLoading = true;
    const req =
      this.quantityTakeOffId && !this.isAllQTOs
        ? this.reportsClient.getQuantitySheetReportDataForQuantityTakeOff(
            this.serviceSpecificationId,
            this.quantityTakeOffId,
            this.onlyBilledQuantities,
            this.includeLongTexts,
            this.includeHeaderOnlyOnFirstPage,
            this.includePositionsWithoutQuantity
          )
        : this.reportsClient.getQuantitySheetReportData(
            this.serviceSpecificationId,
            this.onlyBilledQuantities,
            this.includeLongTexts,
            this.includeHeaderOnlyOnFirstPage,
            this.includePositionsWithoutQuantity
          );
    req.subscribe((data: QuantitySheetReport) => {
      this.quantitiesSource = data.quantities;
      this.changeFilter(this.filter);
      this.serviceSpecificationTotalSum = data.serviceSpecificationSum;
      this.totalSum = data.totalSum;
      this.isLoading = false;
      this.isLoaded = true;
    });
  }

  showSheetReport(): void {
    this.isLoading = true;
    const req =
      this.quantityTakeOffId && !this.isAllQTOs
        ? this.reportsClient.getQuantitySheetReportForQuantityTakeOff(
            this.serviceSpecificationId,
            this.quantityTakeOffId,
            this.onlyBilledQuantities,
            this.includeLongTexts,
            true,
            this.addMargin || 0,
            this.includeHeaderOnlyOnFirstPage,
            this.includePositionsWithoutQuantity
          )
        : this.reportsClient.getQuantitySheetReport(
            this.serviceSpecificationId,
            this.onlyBilledQuantities,
            this.includeLongTexts,
            true,
            this.addMargin || 0,
            this.includeHeaderOnlyOnFirstPage,
            this.includePositionsWithoutQuantity
          );

    req.subscribe({
      next: (report: ReportGetOfQuantitySheetReport) => {
        this.printViewMessengerService.showPdfPreview(
          report.htmlReport,
          'sheet',
          report.isLandscape,
          report.pdfReportBase64,
          this.includeHeaderOnlyOnFirstPage
        );
        this.printViewMessengerService.waitForPrintViewReady().subscribe(() => {
          this.isShowedPdf = true;
          this.isLoading = false;
        });
        this.printViewMessengerService.printViewClosed.pipe(first()).subscribe(() => {
          this.isShowedPdf = false;
          this.isLoading = false;
        });
      },
      error: () => {
        this.isShowedPdf = false;
        this.isLoading = false;
        this.avaNotificationsService.error('Fehler beim Erstellen der PDF-Datei');
      }
    });
  }

  changeFilter(filter: string): void {
    if (filter) {
      const lowFilter = filter.toLocaleLowerCase();
      this.filteredSource = this.quantitiesSource.filter(
        (item: QuantitySheetItem) =>
          item.itemNumber?.toLocaleLowerCase().includes(lowFilter) ||
          item.shortText?.toLocaleLowerCase().includes(lowFilter) ||
          item.htmlLongText?.toLocaleLowerCase().includes(lowFilter)
      );
    } else {
      this.filteredSource = this.quantitiesSource;
    }
    this.filter = filter;
  }

  goBack(): void {
    const position = this.route.snapshot.paramMap.get('position');
    if (position) {
      this.router.navigate(['..', { position }], { relativeTo: this.route });
    } else {
      this.router.navigate(['..'], { relativeTo: this.route });
    }
  }

  changeAllQuantities(): void {
    setStorage<boolean>('SHEET_SHOW_ALL', this.isAllQTOs);
    this.showSheet();
  }
}
