import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Self,
  SimpleChanges,
  ViewChild,
  forwardRef
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatTabGroup, MatTab } from '@angular/material/tabs';

import { Subject, combineLatest, of } from 'rxjs';
import { catchError, switchMap, take, takeUntil } from 'rxjs/operators';

import { ChangeViewportHeightService } from '@serv-spec/services/change-viewport-height.service';
import { CurrentPositionCalculationGetService } from '@serv-spec/services/current-position-calculation-get.service';

import { SelectedSpecificationMessengerService } from '@shared/services/messengers/selected-specification-messenger.service';

import { CalculationFixedPriceService } from '@project/services/calculation-fixed-price.service';

import { CopyCalculationForGroupService } from 'app/areas/tree/services/copy-calculation-for-group.service';
import { FlatElementsService } from 'app/areas/tree/services/flat-elements.service';
import { ModePageService } from 'app/areas/tree/services/mode-page.service';
import { TreeNodeStateService } from 'app/areas/tree/services/tree-node-state.service';
import {
  AvaProjectGet,
  AvaProjectsClient,
  CalculationEntry,
  CompanyGet,
  ExecutionDescriptionDto,
  IElementDto,
  NoteTextDto,
  PositionCalculation,
  PositionCalculationGet,
  PositionCalculationsClient,
  PositionDto,
  ProjectDto,
  ProjectGet,
  ServiceSpecificationDto,
  ServiceSpecificationGroupDto,
  TreeViewDisplayType,
  UserSettingsClient
} from 'app/generated-client/generated-client';
import { CopyMatchPositionModalComponent } from 'app/shared/components/copy-match-position-modal/copy-match-position-modal.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { CopyCalculationService } from 'app/shared/services/copy-calculation.service';
import { CopyCalculationViewMessengerService } from 'app/shared/services/electron/copy-calculation-view-messenger.service';
import { GroupViewService } from 'app/shared/services/group-view.service';
import { CompaniesService } from 'app/shared/services/lightquery/companies.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';
import { setAsSplitSize } from 'app/shared/utilities/area-size';
import { setStorage } from 'app/shared/utilities/storage';

import { SafeHtmlPipe } from '../../../../shared/pipes/safe-html.pipe';
import { FlexLayoutDirective } from '../../../flex-layout/flex-layout.directive';
import { CalculationComponent } from '../../../project-id/components/service-specifications/components/calculation/calculation.component';
import { PositionsTableComponent } from '../../../project-id/components/service-specifications/components/positions-table/positions-table.component';
import { ServiceSpecificationsTableFlexibleComponent } from '../../../project-id/components/service-specifications/components/service-specifications-table-flexible/service-specifications-table-flexible.component';
import { ProjectsControlComponent } from '../../../projects/components/projects-control/projects-control.component';
import { MainTreeComponent } from '../../../tree/components/main-tree/main-tree.component';
import { CopyCalculationTopPartService } from '../../services/copy-calculation-top-part.service';

import { CopyCalculationTopPartComponent } from '../copy-calculation-top-part/copy-calculation-top-part.component';
import { IOutputAreaSizes, AngularSplitModule } from 'angular-split';

@Component({
  selector: 'pa-other-project-tab',
  templateUrl: './other-project-tab.component.html',
  styleUrls: ['./other-project-tab.component.scss'],
  providers: [
    ChangeViewportHeightService,
    ModePageService,
    SelectedSpecificationElementMessengerService,
    GroupViewService,
    FlatElementsService,
    CopyCalculationForGroupService,
    TreeNodeStateService,
    CalculationFixedPriceService
  ],
  standalone: true,
  imports: [
    AngularSplitModule,
    FlexLayoutDirective,
    CommonModule,
    forwardRef(() => MainTreeComponent),
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatIconButton,
    MatSuffix,
    MatIcon,
    MatSelect,
    MatOption,
    ProjectsControlComponent,
    ServiceSpecificationsTableFlexibleComponent,
    MatButton,
    MatCheckbox,
    PositionsTableComponent,
    MatTabGroup,
    MatTab,
    CalculationComponent,
    SafeHtmlPipe
  ]
})
export class OtherProjectTabComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() noSeparate: boolean;
  @Input() isTopPart: boolean;
  @ViewChild('asSplitRightRef') asSplitRightRef: ElementRef;
  @ViewChild('asSplitPosDivider') asSplitPosDividerRef: ElementRef;
  @ViewChild('topPartOfmainRightSideSplitArea') topPartOfmainRightSideSplitArea: ElementRef;
  @ViewChild('bottomPartOfmainRightSideSplitArea') bottomPartOfmainRightSideSplitArea: ElementRef;
  @ViewChild('scrollEl') scrollElement: ElementRef;
  @Input() elementGroupId: string;
  listCompany: CompanyGet[] = [];
  filterByCompanyId = '';
  selectedProject: ProjectGet | null;
  selectedAvaProject: AvaProjectGet | null;
  serviceSpecification: ServiceSpecificationDto;
  positionsList: PositionDto[] = [];
  allPositionsListAvaProject: PositionDto[] = [];
  groupsList: ServiceSpecificationGroupDto[] = [];
  filterPosition: string;
  selectedPosition: PositionDto | null;
  selectedGroup: ServiceSpecificationGroupDto | null;
  positionCalculation: PositionCalculation | null;
  isLoading: boolean;
  filterProject = '';
  filterProjectNumber = '';
  filterContactName = '';
  isLongText: boolean;
  isSearchAll = true;
  modePage = 'calculation';
  groupView: TreeViewDisplayType = TreeViewDisplayType.Tree;
  private $destroy = new Subject<boolean>();
  isShowTopPart = false;
  topPartComp: any;
  numberOfAvaProjectsForProject: number;
  treeState: { [elementId: string]: boolean } = {};
  additionalIndentationHeight = 26;
  flatElementsDto: (ServiceSpecificationGroupDto | NoteTextDto | ExecutionDescriptionDto | PositionDto)[] = [];
  multiSelectPosition: string[] = [];

  constructor(
    private copyCalculationViewMessengerService: CopyCalculationViewMessengerService,
    private companiesService: CompaniesService,
    private avaProjectsClient: AvaProjectsClient,
    private notificationsService: AvaNotificationsService,
    private positionCalculationsClient: PositionCalculationsClient,
    private modalService: ModalService,
    private copyCalculationTopPartService: CopyCalculationTopPartService,
    private userSettingsClient: UserSettingsClient,
    private copyCalculationService: CopyCalculationService,
    @Self() private changeViewportHeightService: ChangeViewportHeightService,
    private currentPositionCalculationGetService: CurrentPositionCalculationGetService,
    private groupViewService: GroupViewService,
    private modePageService: ModePageService,
    private flatElementsService: FlatElementsService,
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private treeNodeStateService: TreeNodeStateService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private calculationFixedPriceService: CalculationFixedPriceService
  ) {}

  ngOnInit(): void {
    this.companiesService.getAll().subscribe((c) => (this.listCompany = c));
    this.initTree();

    this.selectedSpecificationElementMessengerService.selectedElement.pipe(takeUntil(this.$destroy)).subscribe((selectedElement) => {
      if (selectedElement) {
        if (selectedElement.element.elementTypeDiscriminator === 'PositionDto') {
          if (this.selectedGroup && !this.positionsList.find((item) => item.id === selectedElement.element.id)) {
            if (this.isTopPart) {
              this.copyCalculationTopPartService.setToTopPart('getGroupSelecting', null);
            }
            this.beginGroup();
          }
          this.selectPosition(selectedElement.element);
        } else if (selectedElement.element.elementTypeDiscriminator === 'ServiceSpecificationGroupDto') {
          this.getGroupSelecting(selectedElement.element);
        }
      } else {
        this.copyCalculationTopPartService.setToTopPart('getGroupSelecting', null);
        this.beginGroup();
      }
    });
    this.copyCalculationService.sendedData.pipe(take(1)).subscribe((data) => {
      this.selectedProject = data.selectedProject;
      this.selectedAvaProject = data.selectedAvaProject;
      this.serviceSpecification = data.serviceSpecification;
      this.flatElementsService.setElementsDto(this.serviceSpecification);
      this.getProjectDto();
      this.treeState = { ...data.treeState };
    });

    if (this.isTopPart) {
      this.topPartComp = this.modalService.openModal(CopyCalculationTopPartComponent, {
        dialogType: ConfirmationType.General,
        data: {},
        position: { bottom: `0px`, left: `0px` },
        hasBackdrop: false,
        panelClass: 'top-part-window'
      });

      this.copyCalculationTopPartService.infoFromTopPart.pipe(takeUntil(this.$destroy)).subscribe((e: { command: string; data?: any }) => {
        switch (e.command) {
          case 'beginProject':
            this.beginProject();
            break;
          case 'beginAvaProject':
            this.beginAvaProject();
            break;
          case 'beginGroup':
            this.beginGroup();
            break;
          case 'selectProject':
            this.selectProject(e.data);
            break;
          case 'selectAvaProject':
            this.selectAvaProject(e.data);
            break;
        }
      });

      this.copyCalculationTopPartService.hiddenTop.pipe(takeUntil(this.$destroy)).subscribe((e) => {
        if (e) {
          this.hideTop();
        } else {
          if (!this.isShowTopPart) {
            this.showTop();
          }
        }
      });

      this.copyCalculationTopPartService.changes.pipe(takeUntil(this.$destroy)).subscribe(() => {
        this.changePosition();
      });
    } else {
      combineLatest([
        this.copyCalculationViewMessengerService.selectedProject,
        this.userSettingsClient.getLastOpenedProjectAsCopyCalculationSource().pipe(catchError(() => of(null)))
      ])
        .pipe(takeUntil(this.$destroy))
        .subscribe(([selectedProject, savedProject]) => {
          setTimeout(() => {
            if (!this.selectedProject) {
              const project = savedProject || selectedProject;
              this.selectProject(project);
            }
          }, 500);
        });
    }
  }

  ngAfterViewInit(): void {
    setAsSplitSize('AS_SPLIT_TREE_COPY_RIGHT_SIZE', this.asSplitRightRef);
    setAsSplitSize('AS_SPLIT_COPY_CALC_POSITION_DIVIDER', this.asSplitPosDividerRef);
    setAsSplitSize('AS_SPLIT_COPY_CALC_PROJECTS_DIVIDER', this.topPartOfmainRightSideSplitArea);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.elementGroupId) {
      if (changes.elementGroupId.currentValue) {
        this.clearMultiSelect();
      } else {
        this.selectedPosition = null;
      }
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
    if (this.isTopPart) {
      this.topPartComp.close();
    }
    this.clearMultiSelect();
  }

  public beginProject(): void {
    this.beginAvaProject();
    this.selectedProject = null;
  }

  beginAvaProject(): void {
    this.beginPositions();
    this.beginGroup();
    this.selectedAvaProject = null;
    this.serviceSpecification = null;
    this.positionsList = [];
    this.groupsList = [];
  }

  beginGroup(): void {
    this.selectedGroup = null;
    this.positionsList = this.allPositionsListAvaProject;
  }

  public beginPositions(): void {
    this.beginCalculation();
    this.selectedPosition = null;
  }

  private beginCalculation(): void {
    this.positionCalculation = null;
  }

  private initTree(): void {
    this.modePageService.setModePage('other-mode');
    this.groupViewService.setGroupView(TreeViewDisplayType.Tree);
    this.treeNodeStateService.setTreeState({});
  }

  selectProject(project: ProjectGet): void {
    this.beginProject();
    setTimeout(() => {
      this.selectedProject = project;
      this.avaProjectsClient
        .getAllAvaProjectsForProject(project.id, false, undefined, undefined, undefined, undefined)
        .pipe(take(1))
        .subscribe((r) => {
          this.numberOfAvaProjectsForProject = r.totalCount;
        });
      this.filterByCompanyId = '';
      this.saveSelectedProject();
    }, 1);
  }

  selectAvaProject(avaProject: AvaProjectGet): void {
    this.beginAvaProject();
    this.selectedAvaProject = avaProject;
    if (this.selectedProject && this.selectedAvaProject) {
      this.calculationFixedPriceService.reloadListOfItemsWithFixedPrice(this.selectedProject.id, this.selectedAvaProject.id);
      this.avaProjectsClient
        .getAvaProjectContentById(this.selectedProject.id, this.selectedAvaProject.id)
        .subscribe((projectDto: ProjectDto) => {
          this.serviceSpecification = projectDto.serviceSpecifications[0];
          this.flatElementsService.setElementsDto(this.serviceSpecification);
          this.getProjectDto();
        });
    }
  }

  getProjectDto(): void {
    this.groupsList = [];
    this.positionsList = [];
    this.createList(this.serviceSpecification.elements);
    this.allPositionsListAvaProject = this.positionsList;
    this.moveScroll();
    this.clearMultiSelect();
  }

  private createList(elements: IElementDto[]): void {
    elements.forEach((element: IElementDto) => {
      switch (element.elementType) {
        case 'ServiceSpecificationGroupDto':
          this.groupsList.push(element as ServiceSpecificationGroupDto);
          this.createList((<ServiceSpecificationGroupDto>element).elements);
          break;
        case 'PositionDto':
          this.positionsList.push(element as PositionDto);
          break;
      }
    });
  }

  public selectPositionPlus(element: PositionDto): void {
    this.selectedSpecificationElementMessengerService.trySelectElementById(element.id);
  }

  public selectPosition(element: PositionDto, isCallFromAnotherComponent = false): void {
    if (isCallFromAnotherComponent && element) {
      this.selectedSpecificationElementMessengerService.trySelectElementById(element.id);
      return;
    }
    this.beginPositions();
    if (element) {
      this.selectedPosition = JSON.parse(JSON.stringify(element));
      this.getCalculation(this.selectedPosition.id);
    }
  }

  copyPosition(element: PositionDto): void {
    this.positionCalculationsClient
      .getPositionCalculation(this.selectedProject.id, this.selectedAvaProject.id, element.id)
      .subscribe((positionCalculation: PositionCalculationGet) => {
        this.copyCalculationViewMessengerService.sendFullPositionToCopyToMainWindow({ element, positionCalculation });
      });
  }

  getCalculation(id: string): void {
    this.isLoading = true;
    this.positionCalculationsClient
      .getPositionCalculation(this.selectedProject.id, this.selectedAvaProject.id, id)
      .subscribe(({ positionCalculation }: PositionCalculationGet) => {
        this.positionCalculation = positionCalculation;
        this.isLoading = false;
      });
    this.currentPositionCalculationGetService.isCalculationDataLoading.pipe(take(1)).subscribe(() => {
      if (this.bottomPartOfmainRightSideSplitArea) {
        const viewportHeight = this.bottomPartOfmainRightSideSplitArea.nativeElement.offsetHeight - this.additionalIndentationHeight;
        this.changeViewportHeightService.setViewportHeight(viewportHeight);
      }
    });
  }

  sendCalculationToCalculationCopyService(): void {
    let rows: CalculationEntry[];
    if ('calculationEntries' in this.positionCalculation) {
      rows = JSON.parse(JSON.stringify(this.positionCalculation.calculationEntries));
    } else {
      this.notificationsService.error('Interner Fehler beim Kopieren der Kalkulation.');
      return;
    }
    this.copyCalculationViewMessengerService.sendSelectedCalculationRowsToCopyToMainWindow(rows);
  }

  moveScroll(): void {
    setTimeout(() => {
      this.scrollElement.nativeElement.scrollIntoView(false);
    }, 300);
  }

  runContextMenu(event: { command: string; data: any }): void {
    switch (event.command) {
      case 'allCalculations':
        this.copyAllCalculation({ sourceProject: event.data });
        break;
      default:
    }
  }

  copyAllCalculation(data: { sourceProject?: ProjectGet; sourceGroup?: ServiceSpecificationGroupDto }): void {
    const request = this.noSeparate
      ? this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(switchMap((s) => of(s?.avaProjectId)))
      : this.copyCalculationViewMessengerService.selectedAvaProjectId;
    request.pipe(take(1)).subscribe(() => {
      this.allowedCopyAllCalculation(data);
    });
  }

  allowedCopyAllCalculation(data: { sourceProject?: ProjectGet; sourceGroup?: ServiceSpecificationGroupDto }): void {
    this.modalService
      .openModal(CopyMatchPositionModalComponent, {
        dialogType: ConfirmationType.General,
        data: {
          ...data,
          isInMainView: this.noSeparate
        }
      })
      .afterClosed()
      .subscribe((hasUpdatedCalculation) => {
        if (hasUpdatedCalculation) {
          // This one is sending the message if we're in a detached view
          this.copyCalculationViewMessengerService.sendReloadCurrentPositionCalculation();
          // This one is sending the message if we're in main view
          this.copyCalculationViewMessengerService.raiseReloadCurrentPositionCalculationDirectly();
        }
      });
  }

  getTreeSelecting(elementId: string): void {
    if (elementId && !this.elementGroupId) {
      let position: PositionDto = this.positionsList.find((item) => item.id === elementId);
      if (!position) {
        this.beginGroup();
        position = this.positionsList.find((item) => item.id === elementId);
      }
      this.selectPosition(position);
    }
  }

  getGroupSelecting(elementGroupDto: ServiceSpecificationGroupDto): void {
    if (!this.elementGroupId) {
      this.selectedGroup = elementGroupDto;
      this.beginPositions();
      this.filterPosition = '';
      this.positionsList = [];
      this.createList(elementGroupDto.elements);
      if (this.isTopPart) {
        this.copyCalculationTopPartService.setToTopPart('getGroupSelecting', elementGroupDto);
      }
    } else {
      this.selectGroupForCopy(elementGroupDto);
    }
  }

  onDragEnd(sizes: IOutputAreaSizes, nameOfArea?: string): void {
    setStorage<number[]>(nameOfArea, sizes as number[]);
  }

  saveSizePositionDetailDivider(sizes: IOutputAreaSizes): void {
    if (this.bottomPartOfmainRightSideSplitArea) {
      setStorage<number[]>('AS_SPLIT_COPY_CALC_POSITION_DIVIDER', sizes as number[]);
      const viewportHeight = this.bottomPartOfmainRightSideSplitArea.nativeElement.offsetHeight - this.additionalIndentationHeight;
      this.changeViewportHeightService.setViewportHeight(viewportHeight);
    }
  }

  enterMouse(): void {
    if (this.isTopPart) {
      this.copyCalculationTopPartService.setShow();
    }
  }

  tryLeave(): void {
    if (this.isTopPart) {
      this.copyCalculationTopPartService.tryHide();
    }
  }

  showTop(): void {
    this.isShowTopPart = true;
    this.copyCalculationTopPartService.setToTopPart('show');
    this.changePosition();
  }

  hideTop(): void {
    this.isShowTopPart = false;
    this.copyCalculationTopPartService.setToTopPart('hide');
  }

  changePosition(): void {
    setTimeout(() => {
      const scroll: DOMRect = this.scrollElement.nativeElement.getBoundingClientRect();
      const rect: DOMRect = this.scrollElement.nativeElement.closest('.one-view').getBoundingClientRect();
      if (scroll && rect) {
        this.copyCalculationTopPartService.setToTopPart('position', {
          width: scroll.width,
          left: scroll.left,
          bottom: window.innerHeight - rect.top
        });
      }
    }, 1);
  }

  selectGroupForCopy(data): void {
    this.selectedPosition = data;
    this.copyAllCalculation({ sourceProject: this.selectedProject, sourceGroup: data });

    this.copyCalculationService.setDataToCopyCalculation({
      selectedProject: this.selectedProject,
      selectedAvaProject: this.selectedAvaProject,
      serviceSpecification: this.serviceSpecification,
      treeState: this.treeState
    });
  }

  saveSelectedProject(): void {
    if (this.selectedProject) {
      this.userSettingsClient
        .setLastOpenedProjectAsCopyCalculationSource({
          projectId: this.selectedProject.id
        })
        .subscribe(() => {});
    }
  }

  getSourceForCopy(data: PositionDto | ServiceSpecificationGroupDto): void {
    if (data.elementTypeDiscriminator === 'PositionDto') {
      this.positionCalculationsClient
        .getPositionCalculation(this.selectedProject.id, this.selectedAvaProject.id, data.id)
        .subscribe((positionCalculation: PositionCalculationGet) => {
          this.copyCalculationViewMessengerService.sendDataForCopyToMainWindow({
            position: data,
            positionCalculation,
            avaProject: this.selectedAvaProject,
            project: this.selectedProject
          });
        });
    } else {
      this.copyCalculationViewMessengerService.sendDataForCopyToMainWindow({
        group: data,
        avaProject: this.selectedAvaProject,
        project: this.selectedProject
      });
      this.elementGroupId = '';
    }
  }

  selectPositionMulti(position: PositionDto): void {
    if (this.multiSelectPosition?.find((id) => id === position.id)) {
      this.multiSelectPosition = this.multiSelectPosition?.filter((id) => id !== position.id);
    } else {
      this.multiSelectPosition = [...this.multiSelectPosition, position.id];
    }
    this.sendDataList();
  }

  clearMultiSelect(): void {
    this.multiSelectPosition = [];
    this.sendDataList();
  }

  sendDataList(): void {
    this.copyCalculationViewMessengerService.sendListPositionToCopyToMainWindow({
      sourceProjectId: this.selectedProject?.id,
      sourceAvaProjectId: this.selectedAvaProject?.id,
      sourcePositionIds: this.multiSelectPosition
    });
  }
}
