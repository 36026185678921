import { NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatButtonToggleGroup, MatButtonToggle } from '@angular/material/button-toggle';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { Router, RouterLink } from '@angular/router';

import { ProjectsService } from '@shared/services/lightquery/projects.service';

import { ProjectFileService } from '@project/components/project-files/services/project-file.service';

import { CompanyGet, ProjectGet, ProjectStatus } from 'app/generated-client/generated-client';
import { CompaniesService } from 'app/shared/services/lightquery/companies.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

import { ProjectStatusPipe } from '../../../../shared/pipes/ui-data-display/project-status.pipe';
import { FlexLayoutDirective } from '../../../flex-layout/flex-layout.directive';

import { ProjectsControlComponent } from '../projects-control/projects-control.component';

@Component({
  selector: 'pa-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  providers: [ProjectsService],
  standalone: true,
  imports: [
    MatButtonToggleGroup,
    MatButtonToggle,
    MatButton,
    RouterLink,
    FlexLayoutDirective,
    MatFormField,
    MatLabel,
    MatInput,
    MatSelect,
    MatOption,
    NgFor,
    MatCheckbox,
    FormsModule,
    ProjectsControlComponent,
    ProjectStatusPipe
  ]
})
export class ProjectsComponent implements OnInit {
  listCompany: CompanyGet[] = [];
  listStatus = Object.keys(ProjectStatus).map((ps) => <ProjectStatus>ps);
  filterByCompanyId = '';
  filterByStatus = '';
  showArchivedProjects = false;

  constructor(
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private companiesService: CompaniesService,
    private router: Router,
    private projectFileService: ProjectFileService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private projectsService: ProjectsService
  ) {}

  ngOnInit(): void {
    this.selectedProjectMessengerService.setSelectedProject(null);
    this.selectedSpecificationMessengerService.clearSelectedServiceSpecification();
    this.projectFileService.reset();
    this.projectsService.pageSize = 25;

    this.companiesService.getAll().subscribe((c) => (this.listCompany = c));
  }

  selectProject(project: ProjectGet): void {
    this.router.navigate(['projects', project.id, 'service-specifications']);
  }
}
