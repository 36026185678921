import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-terms-new',
  templateUrl: './terms-new.component.html',
  styleUrls: ['./terms-new.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatFormFieldModule, MatInputModule, FormsModule, MatDialogModule, FlexLayoutDirective]
})
export class TermsNewComponent {
  data: { deadline: number; name: string; rate: number } = { name: null, deadline: null, rate: 0 };

  constructor() {}

  getData(): { deadline: number; rate: number } {
    return { ...this.data, rate: this.data.rate / 100 };
  }

  isValid(): boolean {
    return this.data.rate > 0;
  }
}
