import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Subject, combineLatest, filter, takeUntil } from 'rxjs';

import { BimModelAssignedQuantitiesMessengerService } from '../../../../services/bim-model-assigned-quantities-messenger.service';
import { CommonModule } from '@angular/common';
import { CountingQuantityAssignmentsService } from '../../../../services/counting-quantity-assignments.service';
import { PositionDto } from 'app/generated-client/generated-client';
import { SelectedSpecificationElementMessengerService } from '@shared/services/messengers/selected-specification-element-messenger.service';

@Component({
  selector: 'pa-show-assigned-element-quantity',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './show-assigned-element-quantity.component.html',
  styleUrls: ['./show-assigned-element-quantity.component.scss']
})
export class ShowAssignedElementQuantityComponent implements OnInit, OnDestroy {
  private $destroy: Subject<boolean> = new Subject<boolean>();

  selectedSpecificationElementMessengerService = inject(SelectedSpecificationElementMessengerService);
  countingQuantityAssignmentsService = inject(CountingQuantityAssignmentsService);
  bimModelAssignedQuantitiesMessengerService = inject(BimModelAssignedQuantitiesMessengerService);
  quantityAssignmentsForPosition$ = this.countingQuantityAssignmentsService.quantityAssignmentsForPosition;
  selectedPosition: PositionDto;
  constructor() {}

  ngOnInit(): void {
    combineLatest([
      this.bimModelAssignedQuantitiesMessengerService.bimFileQuantityAssignment,
      this.selectedSpecificationElementMessengerService.selectedElement.pipe(
        filter((element) => element != null && element.element.elementTypeDiscriminator === 'PositionDto')
      )
    ])
      .pipe(takeUntil(this.$destroy))
      .subscribe(([bimFileQuantityAssignment, selectedElement]) => {
        this.selectedPosition = selectedElement.element as PositionDto;
        this.countingQuantityAssignmentsService.countingQuantity(bimFileQuantityAssignment, selectedElement.id);
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }
}
