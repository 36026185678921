import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger, MatMenu, MatMenuContent, MatMenuItem } from '@angular/material/menu';

import { QuantityTakeOffGet } from 'app/generated-client/generated-client';

import { InvoiceMenuAction, InvoiceMenuActions } from './../../../../../../../../shared/models/invoice-menu-actions';

@Component({
  selector: 'pa-invoice-table-menu',
  templateUrl: './invoice-table-menu.component.html',
  styleUrls: ['./invoice-table-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatMenu, MatMenuContent, MatMenuItem, MatIcon, NgIf, MatMenuTrigger]
})
export class InvoiceTableMenuComponent {
  @Input() contextMenuPosition: { x: string; y: string } = { x: '0px', y: '0px' };
  @Input() structureView: string;
  @Input() hasAnyQtoCalculationReferences: boolean;
  @Input() hasAnyQtoAssumedQuantities: boolean;
  @Input() isPages: boolean;
  @Input() disabledObject: { [key: string]: boolean } = {};
  @Output() invoiceMenuActions = new EventEmitter<InvoiceMenuAction>();
  @ViewChild(MatMenuTrigger) menu: MatMenuTrigger;

  menuActions = InvoiceMenuActions;

  doInvoiceMenuAction(action: InvoiceMenuActions, item: QuantityTakeOffGet): void {
    this.invoiceMenuActions.emit({ action, item });
  }
}
