<div fxFlexFill #wrapElement *ngIf="!isModal" class="one-view">
  <header fxLayout="row" class="header">
    <div class="header-title" (mousedown)="onClick($event)">
      <ng-container *ngTemplateOutlet="titleName"></ng-container>
    </div>
    <button mat-flat-button color="warn" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <ng-container *ngTemplateOutlet="mainView"></ng-container>
</div>

<div
  [ngStyle]="{ width: width + 'px', height: height + 'px' }"
  *ngIf="isModal"
  #wrapElement
  class="wrapper one-view"
  (mouseup)="overlayUp()"
  (mouseenter)="stopLeave()"
  (mouseleave)="tryLeave()"
>
  <header fxLayout="row" class="header">
    <div class="header-title movable" (mousedown)="startResize($event, 'move')">
      <ng-container *ngTemplateOutlet="titleName"></ng-container>
    </div>
    <button mat-flat-button color="warn" (click)="remove($event)" (dblclick)="removeAll()" *ngIf="!isMainSeparated">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <button mat-flat-button color="warn" *ngIf="!isFullSize" (click)="fullSize()">
      <mat-icon>crop_din</mat-icon>
    </button>
    <button mat-flat-button color="warn" *ngIf="isFullSize" (click)="middleSize()">
      <mat-icon style="transform: rotate(0.5turn) scale(0.8)">filter_none</mat-icon>
    </button>
    <button mat-flat-button color="warn" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <ng-container *ngTemplateOutlet="mainView"></ng-container>

  <ng-container *ngIf="!isFullSize">
    <div class="border-dialog border-dialog-top" (mousedown)="startResize($event, 'top')"></div>
    <div class="border-dialog border-dialog-right" (mousedown)="startResize($event, 'right')"></div>
    <div class="border-dialog border-dialog-bottom" (mousedown)="startResize($event, 'bottom')"></div>
    <div class="border-dialog border-dialog-left" (mousedown)="startResize($event, 'left')"></div>
    <div class="border-dialog border-dialog-bottomright" (mousedown)="startResize($event, 'bottomRight')">
      <div class="corner">
        <mat-icon class="corner-icon">reorder</mat-icon>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #titleName>
  <span>{{ name | embeddedViewNames }}</span>
</ng-template>

<ng-template #mainView>
  <div class="wrap" *ngIf="name === windowType.LongText">
    <pa-long-text-window [noSeparate]="true"></pa-long-text-window>
  </div>
  <div class="wrap" *ngIf="name === windowType.CopyCalc">
    <pa-other-project-tab
      [noSeparate]="true"
      [elementGroupId]="elementGroupId"
      [isTopPart]="isProjectSearchShowAbove"
    ></pa-other-project-tab>
  </div>
  <div class="wrap" *ngIf="name === windowType.Product">
    <pa-copy-calculation-view [noSeparate]="true"></pa-copy-calculation-view>
  </div>
  <div class="wrap" *ngIf="name === windowType.CompareQTO">
    <pa-invoice-sheets [isHideControls]="true"></pa-invoice-sheets>
  </div>
  <div class="wrap" *ngIf="name === windowType.CopyElement">
    <pa-copy-element-view [noSeparate]="true"></pa-copy-element-view>
  </div>
</ng-template>
