import { Injectable } from '@angular/core';

import { MedianHourlyWageCalculation, PriceRoundingModeDto } from '../../../../../generated-client/generated-client';

import { RoundingService } from './rounding.service';

export interface WageCalculationDetails {
  additionalWageCosts: number;
  nonWageCosts: number;
  toolsCosts: number;
  numberOfWorkers: number;
  productiveWorkers: number;
  amountPerHour: number;
}

@Injectable({
  providedIn: 'root'
})
export class WageCalculationService {
  static recalculate(calculation: MedianHourlyWageCalculation | null): {
    isSuccess: boolean;
    value?: MedianHourlyWageCalculation;
    additionalData?: WageCalculationDetails;
    errorMessage?: string;
  } {
    if (calculation == null) {
      throw new Error('calculation can not be null');
    }

    calculation.resources ??= [];

    calculation.supplementalWageCostsPercentage = this.withRounding(calculation.supplementalWageCostsPercentage, 4);
    calculation.incidentalWageCostsPercentage = this.withRounding(calculation.incidentalWageCostsPercentage, 4);
    calculation.toolsAndMinorDevicesPercentage = this.withRounding(calculation.toolsAndMinorDevicesPercentage, 4);

    const resources = calculation.resources;

    if (resources.filter((r) => this.isNullOrWhitespace(r.description)).length > 0) {
      return {
        isSuccess: false,
        errorMessage: 'Some resources have an empty description.',
        additionalData: null,
        value: null
      };
    }

    let totalAmountDivisor = resources.reduce((prev, cur) => prev + cur.amount, 0);
    const totalWorkers = totalAmountDivisor;

    let medianHourlyWage = 0;

    if (totalAmountDivisor != 0) {
      totalAmountDivisor -= calculation.resourcesSupervisionPart;
      if (totalAmountDivisor != 0) {
        medianHourlyWage = this.withRounding(
          resources.reduce((prev, cur) => prev + cur.amount * cur.medianWage, 0) / totalAmountDivisor,
          2
        );
      }
    }

    calculation.medianHourlyWage = medianHourlyWage;

    if (calculation.medianHourlyWageOverride != null) {
      medianHourlyWage = calculation.medianHourlyWageOverride;
    }

    calculation.calculationWage = this.withRounding(
      medianHourlyWage +
        this.withRounding(medianHourlyWage * calculation.supplementalWageCostsPercentage, 2) +
        this.withRounding(medianHourlyWage * calculation.incidentalWageCostsPercentage, 2) +
        this.withRounding(medianHourlyWage * calculation.toolsAndMinorDevicesPercentage, 2),
      2
    );

    return {
      isSuccess: true,
      value: calculation,
      errorMessage: null,
      additionalData: {
        additionalWageCosts: this.withRounding(medianHourlyWage * calculation.supplementalWageCostsPercentage, 2),
        nonWageCosts: this.withRounding(medianHourlyWage * calculation.incidentalWageCostsPercentage, 2),
        toolsCosts: this.withRounding(medianHourlyWage * calculation.toolsAndMinorDevicesPercentage, 2),
        numberOfWorkers: totalWorkers,
        productiveWorkers: totalAmountDivisor,
        amountPerHour: this.withRounding(
          resources.reduce((prev, cur) => prev + cur.amount * cur.medianWage, 0),
          2
        )
      }
    };
  }

  private static withRounding(value: number, precision: number): number {
    return RoundingService.withRounding(value, precision, PriceRoundingModeDto.Normal);
  }

  private static isNullOrWhitespace(value: string): boolean {
    if (!value || value.length === 0) {
      return true;
    }

    return /^\s*$/.test(value);
  }
}
