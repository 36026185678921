<div class="wrapper" [ngClass]="place">
  <div class="btn" *ngIf="wordOn">
    <button mat-raised-button color="primary" (click)="isSwitch = !isSwitch">{{ isSwitch ? wordOn : wordOff }}</button>
  </div>
  <button mat-icon-button color="primary" (click)="isSwitch = !isSwitch" *ngIf="!wordOn">
    <mat-icon *ngIf="!isSwitch">keyboard_arrow_down</mat-icon>
    <mat-icon *ngIf="isSwitch">keyboard_arrow_left</mat-icon>
  </button>
  <ng-content></ng-content>
</div>
