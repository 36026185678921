import { Pipe, PipeTransform } from '@angular/core';

import { ShowedTableQuantitiesType } from 'app/shared/models';

@Pipe({
  name: 'showedTableQuantities',
  standalone: true
})
export class ShowedTableQuantitiesPipe implements PipeTransform {
  transform(value: ShowedTableQuantitiesType): string {
    switch (value) {
      case ShowedTableQuantitiesType.CalculatedQuantities:
        return 'Projektmengen';
      case ShowedTableQuantitiesType.AssumedQuantities:
        return 'VA-Mengen';
    }
    return null;
  }
}
