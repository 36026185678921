<ng-container *ngIf="showingPdf; else showingExportTemplate">
  <h1>Bitte Druckvorschaufenster beachten</h1>
</ng-container>
<ng-template #showingExportTemplate>
  <div class="wrapper">
    <div fxFlexFill class="spinner" fxLayoutAlign="center center" *ngIf="isLoading">
      <mat-spinner></mat-spinner>
    </div>
    <div [ngClass]="{ opacity: isLoading }">
      <div fxLayoutGap="12px" class="buttons">
        <button mat-raised-button color="primary" (click)="back()">Zurück</button>
        <button mat-raised-button color="primary" (click)="trySetQuantity()" [disabled]="saved">Projektmengen aktualisieren</button>
        <button mat-raised-button color="primary" (click)="openEstimationInPdfView()">
          <mat-icon>print</mat-icon>
        </button>
      </div>

      <h2 [ngClass]="{ red: noChangedStatus }">
        {{ QtoName }}
        <div *ngIf="noChangedStatus">Status: {{ projectStatus | projectStatus }}</div>
      </h2>

      <table class="total">
        <tr>
          <td class="title">LV Summe:</td>
          <td [class.green]="totalPrice < originalTotalPrice" [class.red]="totalPrice > originalTotalPrice">
            {{ totalPrice | projectCurrency }}
          </td>
          <td [class.green]="totalPrice < originalTotalPrice" [class.red]="totalPrice > originalTotalPrice">
            <ng-container *ngIf="totalPriceDeltaAbsolute">
              (
              {{ totalPriceDeltaAbsolute > 0 ? '+' : '' }}{{ totalPriceDeltaAbsolute | number: '1.2-2' }} /
              {{ totalPriceDeltaAbsolute > 0 ? '+' : '' }}{{ totalPriceDeltaPercent | percent: '1.2-2' }}
              )
            </ng-container>
          </td>
        </tr>
        <tr>
          <td class="title">LV Summe Original:</td>
          <td>
            {{ originalTotalPrice | projectCurrency }}
          </td>
        </tr>
      </table>
      <div>
        <mat-form-field fxFlex="1 0">
          <mat-label>Suche</mat-label>
          <input matInput [(ngModel)]="filter" (ngModelChange)="filterList()" />
        </mat-form-field>
      </div>
      <div *ngIf="filteredList?.length" class="table-wrapper">
        <table mat-table [dataSource]="filteredList">
          <ng-container matColumnDef="itemNumber">
            <th mat-header-cell *matHeaderCellDef>OZ</th>
            <td mat-cell *matCellDef="let row">{{ row.itemNumber.stringRepresentation }}</td>
          </ng-container>
          <ng-container matColumnDef="shortText">
            <th mat-header-cell *matHeaderCellDef>Kurztext</th>
            <td mat-cell *matCellDef="let row">{{ row | positionText }}</td>
          </ng-container>
          <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef>Menge</th>
            <td mat-cell *matCellDef="let row" class="right">{{ row.quantity }}</td>
          </ng-container>
          <ng-container matColumnDef="unitTag">
            <th mat-header-cell *matHeaderCellDef>Einheit</th>
            <td mat-cell *matCellDef="let row" class="right">{{ row.unitTag }}</td>
          </ng-container>
          <ng-container matColumnDef="unitPrice">
            <th mat-header-cell *matHeaderCellDef>EP</th>
            <td mat-cell *matCellDef="let row" class="right">{{ row.unitPrice | number: '1.2-2' }}</td>
          </ng-container>
          <ng-container matColumnDef="totalPrice">
            <th mat-header-cell *matHeaderCellDef>GP</th>
            <td mat-cell *matCellDef="let row" class="right">{{ row.totalPrice | number: '1.2-2' }}</td>
          </ng-container>
          <ng-container matColumnDef="delta">
            <th mat-header-cell *matHeaderCellDef>Δ</th>
            <td
              mat-cell
              [ngClass]="{ green: originalPrices[row.id].deltaPercent < 0, red: originalPrices[row.id].deltaPercent > 0 }"
              *matCellDef="let row"
              class="right"
            >
              {{ originalPrices[row.id].deltaPercent > 0 ? '+' : '' }}{{ originalPrices[row.id].deltaPercent | percent: '1.2-2' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="originalUnitPrice">
            <th mat-header-cell *matHeaderCellDef>EP Orig.</th>
            <td mat-cell *matCellDef="let row" class="right">{{ originalPrices[row.id].unitPrice | number: '1.2-2' }}</td>
          </ng-container>
          <ng-container matColumnDef="originalTotalPrice">
            <th mat-header-cell *matHeaderCellDef>GP Orig.</th>
            <td mat-cell *matCellDef="let row" class="right">{{ originalPrices[row.id].totalPrice | number: '1.2-2' }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: columns"></tr>
        </table>
      </div>
    </div>
  </div>
</ng-template>
