import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router, RouterOutlet } from '@angular/router';

import { Subject, combineLatest, filter, of, switchMap, takeUntil } from 'rxjs';

import {
  ProjectDto,
  ProjectGet,
  ProjectQuantityTakeOffType,
  QuantityTakeOffGet,
  QuantityTakeOffsClient
} from 'app/generated-client/generated-client';
import { QuantityTakeOffsService } from 'app/shared/services/lightquery/quantity-take-offs.service';
import { QuantityTakeOffInvoiceTotalsMessengerService } from 'app/shared/services/messengers/quantity-take-off-invoice-totals-messenger.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedQuantityTakeOffMessengerService } from 'app/shared/services/messengers/selected-quantity-take-off-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

@Component({
  selector: 'pa-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
  standalone: true,
  imports: [RouterOutlet]
})
export class InvoiceComponent implements OnInit, OnDestroy {
  private $destroy: Subject<boolean> = new Subject<boolean>();
  private avaProjectId: string;
  private projectId: string;
  private quantityTakeOffId: string;
  projectForQtoType: ProjectQuantityTakeOffType = null;

  constructor(
    private quantityTakeOffsClient: QuantityTakeOffsClient,
    private quantityTakeOffsService: QuantityTakeOffsService,
    private route: ActivatedRoute,
    private router: Router,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedQuantityTakeOffMessengerService: SelectedQuantityTakeOffMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private quantityTakeOffInvoiceTotalsMessengerService: QuantityTakeOffInvoiceTotalsMessengerService
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.selectedProjectMessengerService.selectedProject,
      this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(filter((v) => !!v)),
      this.route.params
    ])
      .pipe(
        takeUntil(this.$destroy),
        switchMap(([p, ap, params]: [ProjectGet, { avaProjectId: string; project: ProjectDto }, Params]) => {
          this.projectId = p?.id;
          this.projectForQtoType = p?.allowedQuantityTakeOffTypes;
          if (!this.projectForQtoType) {
            this.goBack();
          }
          this.avaProjectId = ap?.avaProjectId;
          this.quantityTakeOffId = params['invoiceId'];
          const cachedQuantityTakeOff: QuantityTakeOffGet = this.quantityTakeOffsService.tryGetQuantityTakeOffById(this.quantityTakeOffId);
          if (cachedQuantityTakeOff) {
            return of(cachedQuantityTakeOff);
          } else {
            return this.quantityTakeOffsClient.getQuantityTakeOffById(this.projectId, this.avaProjectId, this.quantityTakeOffId);
          }
        })
      )
      .subscribe((invoice: QuantityTakeOffGet) => {
        this.selectedQuantityTakeOffMessengerService.setSelectedQuantityTakeOff(invoice);
        this.quantityTakeOffInvoiceTotalsMessengerService.setCurrentTotalSum({ ...invoice.calculation });
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
    this.selectedQuantityTakeOffMessengerService.setSelectedQuantityTakeOff(null);
    this.quantityTakeOffInvoiceTotalsMessengerService.setCurrentTotalSum(null);
  }

  goBack(): void {
    const newUrl = this.router.url.split('/').slice(0, 6).join('/');
    this.router.navigateByUrl(newUrl);
  }
}
