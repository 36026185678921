import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AvaProjectTaxRatePut, AvaProjectsClient, ProjectDto } from 'app/generated-client/generated-client';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';
import { MatInputDecimalPlacesDirective } from '../../../../directives/mat-input-decimal-places.directive';

@Component({
  selector: 'pa-setting-tax-rates',
  templateUrl: './setting-tax-rates.component.html',
  styleUrls: ['./setting-tax-rates.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FlexLayoutDirective,
    MatProgressSpinner,
    MatFormField,
    MatLabel,
    FormsModule,
    MatInput,
    MatInputDecimalPlacesDirective,
    MatSuffix,
    MatButton
  ]
})
export class SettingTaxRatesComponent implements OnInit, OnDestroy {
  private $destroy = new Subject<boolean>();
  taxRate: number;
  projectId: string;
  avaProjectId: string;
  isLoading = false;
  isChanged = false;
  constructor(
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private avaProjectsClient: AvaProjectsClient,
    private notificationsService: AvaNotificationsService
  ) {}

  ngOnInit(): void {
    this.loadTaxRate();
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  loadTaxRate(): void {
    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(takeUntil(this.$destroy))
      .subscribe((e: { avaProjectId: string; parentProjectId: string; project: ProjectDto }) => {
        this.projectId = e?.parentProjectId;
        this.avaProjectId = e?.avaProjectId;
        this.taxRate = e?.project.serviceSpecifications[0].projectTaxRate * 100;
        this.isLoading = true;
      });
  }

  setTaxRates(): void {
    const taxRate: AvaProjectTaxRatePut = { taxRate: this.taxRate * 0.01 };
    this.avaProjectsClient.setTaxRateForAvaProject(this.projectId, this.avaProjectId, taxRate).subscribe({
      next: () => {
        this.notificationsService.success('MwSt. angepasst');
        this.isChanged = false;
      },
      error: () => {
        this.notificationsService.error('Fehler beim anpassen der Mehrwertsteuer');
      }
    });
  }
}
