<div class="filter-field">
  <mat-form-field class="filter-field__input">
    <mat-label>Suche</mat-label>
    <input matInput [(ngModel)]="filter" (ngModelChange)="projectProductsService.onFilter($event)" />
  </mat-form-field>
</div>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="projectProductsService" matSort (matSortChange)="projectProductsService.onSort($event)">
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">
        {{ element.name }}
        <span *ngIf="element.catalogueName">({{ element.catalogueName }})</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="articleNumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Artikelnummer</th>
      <td mat-cell *matCellDef="let element">{{ element.masterProductArticleNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Beschreibung</th>
      <td mat-cell *matCellDef="let element">{{ element.description }}</td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell class="header-right" mat-sort-header *matHeaderCellDef>EP</th>
      <td mat-cell *matCellDef="let element" class="price" fxLayout="row" fxLayoutAlign="end">
        <mat-form-field appearance="fill" floatLabel="always">
          <input
            type="number"
            matInput
            matInputDecimalPlaces
            [(ngModel)]="element.productUnitPrice"
            (ngModelChange)="changePrice(element)"
            (change)="saveChanges()"
          />
          <span matSuffix>{{ selectedSpecificationMessengerService.getCurrency() }}</span>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="header-right">Menge</th>
      <td mat-cell *matCellDef="let element" class="right">{{ element.totalQuantity | number: '1.3-3' }}</td>
    </ng-container>

    <ng-container matColumnDef="unit">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Einheit</th>
      <td mat-cell *matCellDef="let element">{{ element.unit }}</td>
    </ng-container>

    <ng-container matColumnDef="totalPrice">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="header-right">EK-GP</th>
      <td mat-cell *matCellDef="let element" class="right">
        {{ element.totalPrice | number: '1.2-2' }} {{ selectedSpecificationMessengerService.getCurrency() }}
      </td>
    </ng-container>

    <ng-container matColumnDef="masterProductUnitPrice">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="header-right">Stamm-EP</th>
      <td mat-cell *matCellDef="let element" class="right">
        {{ element.masterProductUnitPrice | number: '1.2-2' }} {{ selectedSpecificationMessengerService.getCurrency() }}
      </td>
    </ng-container>

    <ng-container matColumnDef="productUnitPriceAfterAdditions">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="header-right">VK</th>
      <td mat-cell *matCellDef="let element" class="right">
        {{ element.productUnitPriceAfterAdditions | number: '1.2-2' }} {{ selectedSpecificationMessengerService.getCurrency() }}
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <mat-icon color="primary" (click)="openInformationWindow(element)">info</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" class="master-table-tr"></tr>
  </table>
  <pa-upper-paginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    [upperPaginated]="projectProductsService.paginationResult | async"
    [upperService]="projectProductsService"
    (page)="projectProductsService.onPage($event)"
  >
  </pa-upper-paginator>
</div>
