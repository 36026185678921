import { Injectable } from '@angular/core';
import { PageQuantityTakeOffRowModel } from 'app/generated-client/generated-client';
import { StyleEnumType } from 'app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class SetFontStyleService {
  toggleFontStyle(type: StyleEnumType, row: PageQuantityTakeOffRowModel): void {
    switch (type) {
      case StyleEnumType.bold:
        row.isBold = !row.isBold;
        break;
      case StyleEnumType.italic:
        row.isCursive = !row.isCursive;
        break;
      case StyleEnumType.underline:
        row.isUnderlined = !row.isUnderlined;
        break;
    }
  }
}
