import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';

import { CompanyGet } from 'app/generated-client/generated-client';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { CompaniesService } from 'app/shared/services/lightquery/companies.service';
import { ModalService } from 'app/shared/services/modal.service';

import { MasterTextTemplatesAddModalComponent } from '../master-text-templates-add-modal/master-text-templates-add-modal.component';
import { MasterTextTemplatesTableComponent } from '../master-text-templates-table/master-text-templates-table.component';

@Component({
  selector: 'pa-master-text-templates',
  templateUrl: './master-text-templates.component.html',
  styleUrls: ['./master-text-templates.component.scss'],
  standalone: true,
  imports: [MatButton, MasterTextTemplatesTableComponent]
})
export class MasterTextTemplatesComponent implements OnInit {
  listCompany: CompanyGet[] = [];
  selectedCompanyId: string;
  @ViewChild(MasterTextTemplatesTableComponent) templatesTable: MasterTextTemplatesTableComponent;

  constructor(
    private companiesService: CompaniesService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.companiesService.getAll().subscribe((c) => (this.listCompany = c));
  }

  createTextItem(): void {
    this.modalService
      .openModal(MasterTextTemplatesAddModalComponent, { dialogType: ConfirmationType.General })
      .afterClosed()
      .subscribe((e) => {
        if (e) {
          this.templatesTable.add(e);
        }
      });
  }
}
