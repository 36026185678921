import { NgIf, NgFor } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelectionList, MatListOption } from '@angular/material/list';

import { FilterDialogType } from '../../../shared/models';

type DataDialog = {
  list: FilterDialogType[];
};

@Component({
  selector: 'pa-filter-list-dialog',
  templateUrl: './filter-list-dialog.component.html',
  styleUrls: ['./filter-list-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogContent,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    NgIf,
    MatSelectionList,
    NgFor,
    MatListOption,
    MatButton,
    MatDialogActions,
    MatDialogClose
  ]
})
export class FilterListDialogComponent implements OnInit {
  filteredData: FilterDialogType[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DataDialog,
    @Optional() public dialogRef: MatDialogRef<FilterListDialogComponent>
  ) {}

  ngOnInit(): void {
    this.filteredData = this.data.list;
  }

  public onFilterList(e): void {
    if (e) {
      this.filteredData = this.data.list.filter((item) => item.text.indexOf(e) > -1);
    } else {
      this.filteredData = this.data.list;
    }
  }

  public selectElement(id): void {
    this.dialogRef.close(id);
  }
}
