import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TreeDisplayService {
  private _isTreeDisplay = new ReplaySubject<boolean>(1);
  isTreeDisplay = this._isTreeDisplay.asObservable();

  constructor() {}
  setTreeDisplay(isTreeDisplay: boolean): void {
    this._isTreeDisplay.next(isTreeDisplay);
  }
}
