import { NgIf, NgFor } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';

import { Subject, combineLatest, fromEvent, of, takeUntil } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import {
  AvaProjectGet,
  AvaProjectsClient,
  CompanyGet,
  ProjectGet,
  ServiceSpecificationDto,
  ServiceSpecificationGroupDto,
  TreeViewDisplayType,
  UserSettingsClient
} from 'app/generated-client/generated-client';
import { CopyMatchPositionModalComponent } from 'app/shared/components/copy-match-position-modal/copy-match-position-modal.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { CopyCalculationService } from 'app/shared/services/copy-calculation.service';
import { CopyCalculationViewMessengerService } from 'app/shared/services/electron/copy-calculation-view-messenger.service';
import { CompaniesService } from 'app/shared/services/lightquery/companies.service';
import { ModalService } from 'app/shared/services/modal.service';
import { ShowedViewsService } from 'app/shared/services/showed-views.service';

import { FlexLayoutDirective } from '../../../flex-layout/flex-layout.directive';
import { ServiceSpecificationsTableFlexibleComponent } from '../../../project-id/components/service-specifications/components/service-specifications-table-flexible/service-specifications-table-flexible.component';
import { ProjectsControlComponent } from '../../../projects/components/projects-control/projects-control.component';
import { CopyCalculationTopPartService } from '../../services/copy-calculation-top-part.service';

@Component({
  selector: 'pa-copy-calculation-top-part',
  templateUrl: './copy-calculation-top-part.component.html',
  styleUrls: ['./copy-calculation-top-part.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FlexLayoutDirective,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatSelect,
    MatOption,
    NgFor,
    ProjectsControlComponent,
    MatButton,
    ServiceSpecificationsTableFlexibleComponent
  ]
})
export class CopyCalculationTopPartComponent implements OnInit, AfterViewInit, OnDestroy {
  listCompany: CompanyGet[] = [];
  filterByCompanyId = '';
  selectedProject: ProjectGet | null;
  selectedAvaProject: AvaProjectGet | null;
  serviceSpecification: ServiceSpecificationDto;
  selectedGroup: ServiceSpecificationGroupDto | null;
  isLoading: boolean;
  filterProject = '';
  isLongText: boolean;
  isMenuOpened = true;
  modePage = 'calculation';
  groupView: TreeViewDisplayType = TreeViewDisplayType.Tree;
  private $destroy = new Subject<boolean>();
  isShowTopPart = false;
  @ViewChild('wrapElement') wrapElement: ElementRef;
  main: HTMLDivElement;
  width: number;
  numberOfAvaProjectsForProject: number;

  constructor(
    private copyCalculationViewMessengerService: CopyCalculationViewMessengerService,
    private companiesService: CompaniesService,
    private copyCalculationTopPartService: CopyCalculationTopPartService,
    private modalService: ModalService,
    private showedViewsService: ShowedViewsService,
    private avaProjectsClient: AvaProjectsClient,
    private userSettingsClient: UserSettingsClient,
    private copyCalculationService: CopyCalculationService
  ) {}

  ngOnInit(): void {
    this.companiesService.getAll().subscribe((c) => (this.listCompany = c));

    this.copyCalculationService.sendedData.pipe(take(1)).subscribe((data) => {
      this.selectedProject = data.selectedProject;
      this.selectedAvaProject = data.selectedAvaProject;
    });

    combineLatest([
      this.copyCalculationViewMessengerService.selectedProject,
      this.userSettingsClient.getLastOpenedProjectAsCopyCalculationSource().pipe(catchError(() => of(null)))
    ])
      .pipe(takeUntil(this.$destroy))
      .subscribe(([selectedProject, savedProject]) => {
        setTimeout(() => {
          if (!this.selectedProject) {
            const project = savedProject || selectedProject;
            this.selectProject(project);
          }
        }, 500);
      });
    this.copyCalculationTopPartService.infoToTopPart.pipe(takeUntil(this.$destroy)).subscribe((e: { command: string; data?: any }) => {
      switch (e.command) {
        case 'show':
          this.main = this.wrapElement?.nativeElement.closest('.top-part-window');
          if (this.main) {
            this.main.style.opacity = '1';
            this.main.style.pointerEvents = 'auto';
            const surface = this.main.querySelector('.mat-mdc-dialog-surface') as HTMLDivElement;
            if (surface) {
              surface.style.pointerEvents = 'auto';
            }
          }
          break;
        case 'hide':
          this.hide();
          break;
        case 'getGroupSelecting':
          this.getGroupSelecting(e.data);
          break;
        case 'position':
          if (this.wrapElement) {
            this.wrapElement.nativeElement.style.width = e.data.width + 'px';
            this.main = this.wrapElement.nativeElement.closest('.top-part-window');
            this.main.style['margin-bottom'] = e.data.bottom + 'px';
            this.main.style['margin-left'] = e.data.left + 'px';
            setTimeout(() => {
              this.wrapElement.nativeElement.style.minHeight = 'auto';
            }, 1);
          }
          break;
      }
    });
  }

  ngAfterViewInit(): void {
    this.hide();
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  public beginProject(isAuto?: boolean): void {
    this.beginAvaProject(true);
    this.selectedProject = null;
    if (!isAuto) {
      this.copyCalculationTopPartService.setFromTopPart('beginProject');
    }
  }

  beginAvaProject(isAuto?: boolean): void {
    this.beginGroup(true);
    this.selectedAvaProject = null;
    this.serviceSpecification = null;
    if (!isAuto) {
      this.copyCalculationTopPartService.setFromTopPart('beginAvaProject');
    }
  }

  beginGroup(isAuto?: boolean): void {
    this.selectedGroup = null;
    if (!isAuto) {
      this.copyCalculationTopPartService.setFromTopPart('beginGroup');
    }
  }

  selectProject(project: ProjectGet): void {
    this.beginProject(true);
    setTimeout(() => {
      this.selectedProject = project;
      this.avaProjectsClient
        .getAllAvaProjectsForProject(project.id, false, undefined, undefined, undefined, undefined)
        .pipe(take(1))
        .subscribe((r) => {
          this.numberOfAvaProjectsForProject = r.totalCount;
        });
      this.filterProject = '';
      this.filterByCompanyId = '';
      this.copyCalculationTopPartService.setFromTopPart('selectProject', project);
    }, 1);
  }

  selectAvaProject(avaProject: AvaProjectGet): void {
    this.beginAvaProject(true);
    this.selectedAvaProject = avaProject;
    this.copyCalculationTopPartService.setFromTopPart('selectAvaProject', avaProject);
  }

  getGroupSelecting(elementGroupDto: ServiceSpecificationGroupDto): void {
    this.selectedGroup = elementGroupDto;
  }

  runContextMenu(event: { command: string; data: any }): void {
    switch (event.command) {
      case 'allCalculations':
        this.copyAllCalculation(event.data);
        break;
      default:
    }
  }

  copyAllCalculation(fromProject: ProjectGet): void {
    this.modalService
      .openModal(CopyMatchPositionModalComponent, {
        dialogType: ConfirmationType.General,
        data: {
          sourceProject: fromProject,
          isInMainView: true
        }
      })
      .afterClosed()
      .subscribe((hasUpdatedCalculation) => {
        if (hasUpdatedCalculation) {
          this.copyCalculationViewMessengerService.raiseReloadCurrentPositionCalculationDirectly();
        }
      });
  }

  tryLeave(): void {
    this.showedViewsService.tryHide();
    this.copyCalculationTopPartService.tryHide();
  }

  enterMouse(): void {
    this.isShowTopPart = true;
    this.showedViewsService.stopHiding();
    this.copyCalculationTopPartService.stopHiding();
  }

  hide(): void {
    this.main = this.wrapElement?.nativeElement.closest('.top-part-window');
    if (this.main) {
      this.main.style.opacity = '0';
      this.main.style.pointerEvents = 'none';
      const surface = this.main.querySelector('.mat-mdc-dialog-surface') as HTMLDivElement;
      if (surface) {
        surface.style.pointerEvents = 'none';
      }
      this.wrapElement.nativeElement.style.minHeight = 'auto';
    }
  }

  getMenuStatus(e: boolean): void {
    this.isMenuOpened = e;
    fromEvent(document, 'click')
      .pipe(take(1))
      .subscribe((e: MouseEvent) => {
        const wrapRect = this.wrapElement.nativeElement.getBoundingClientRect();
        if (e.pageY < wrapRect.top || e.pageY > wrapRect.bottom) {
          this.copyCalculationTopPartService.tryHide();
        } else if (e.pageX < wrapRect.left || e.pageX > wrapRect.right) {
          this.copyCalculationTopPartService.tryHide();
        }
      });
  }
}
