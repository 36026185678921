<div mat-dialog-content class="wrapper" *ngIf="finishedLoading; else loader">
  <ng-container *ngIf="showingPdf">
    <h2>Bitte Druckvorschaufenster beachten.</h2>
  </ng-container>
  <ng-container *ngIf="!showingPdf">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
      <h2>LV Elemente</h2>
      <div fxLayoutAlign="end center" fxLayoutGap="10px" style="padding-right: 8px">
        <div fxLayout="column">
          <mat-checkbox [(ngModel)]="includeSignatureField">Signaturfeld drucken</mat-checkbox>
          <mat-checkbox [(ngModel)]="exportOnlyPositions">Nur Positionen anzeigen</mat-checkbox>
        </div>
        <div fxLayout="column">
          <mat-checkbox [(ngModel)]="generateLongTexts">Langtext beim Export in PDF anzeigen</mat-checkbox>
          <mat-checkbox [(ngModel)]="includeHeaderOnlyOnFirstPage">Kopfzeile nur auf der ersten Seite drucken</mat-checkbox>
        </div>
        <fieldset class="texts" fxLayoutGap="15px">
          <legend>Textbausteine</legend>
          <mat-checkbox [checked]="!!startText" (click)="selectStartingText($event)">Vortext</mat-checkbox>
          <mat-checkbox [checked]="!!endText" (click)="selectEndingText($event)">Schlusstext</mat-checkbox>
        </fieldset>
        <button mat-raised-button color="primary" (click)="generatePdfAndShowPrintView()">PDF Export</button>
      </div>
    </div>
    <div class="table-container mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
        <ng-container matColumnDef="positionType">
          <th mat-header-cell *matHeaderCellDef>Typ</th>
          <td mat-cell *matCellDef="let element">{{ element | elementType }}</td>
        </ng-container>

        <ng-container matColumnDef="itemNumber">
          <th mat-header-cell *matHeaderCellDef>OZ</th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.elementType === 'PositionDto' || element.elementType === 'ServiceSpecificationGroupDto'">{{
              element.itemNumber.stringRepresentation
            }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="shortText">
          <th mat-header-cell *matHeaderCellDef>Text</th>
          <td mat-cell *matCellDef="let element">{{ element?.shortText }}</td>
        </ng-container>

        <ng-container matColumnDef="unitPrice">
          <th mat-header-cell *matHeaderCellDef class="right">EP</th>
          <td mat-cell *matCellDef="let element" class="right">
            <span *ngIf="element.elementType === 'PositionDto'">
              {{ element?.unitPrice | projectCurrency }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef class="right">Menge</th>
          <td mat-cell *matCellDef="let element" class="right">
            <span *ngIf="element.elementType == 'PositionDto' && !this.showAssumedQuantities">
              {{ element?.quantity | number: '1.3-3' }}
            </span>
            <span
              *ngIf="
                element.elementType == 'PositionDto' && this.showAssumedQuantities && this.assumedQuantities?.assumedQuantitiesByPositionId
              "
            >
              {{ element?.quantity | number: '1.3-3' }}
              ({{ assumedQuantities.assumedQuantitiesByPositionId[element.id] || 0 | number: '1.3-3' }} VA-M)
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="unitTag">
          <th mat-header-cell *matHeaderCellDef>Einheit</th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.elementType === 'PositionDto'">
              {{ element.unitTag }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="longText">
          <th mat-header-cell *matHeaderCellDef>Langtext</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.htmlLongText != null">
              <ng-container *ngIf="expandedElement !== element; else visible">
                <mat-icon class="visibility-icon" (click)="expandedElement = expandedElement === element ? null : element"
                  >visibility_off</mat-icon
                >
              </ng-container>
              <ng-template #visible>
                <mat-icon class="visibility-icon" (click)="expandedElement = expandedElement === element ? null : element"
                  >visibility</mat-icon
                >
              </ng-template>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="totalPrice">
          <th mat-header-cell *matHeaderCellDef class="right">GP</th>
          <td mat-cell *matCellDef="let element" class="right">
            <span *ngIf="element.elementType === 'PositionDto' || element.elementType === 'ServiceSpecificationGroupDto'">
              {{ element.totalPrice | projectCurrency }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length" class="expanded-detail">
            <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div *ngIf="expandedElement" class="element-description-attribution" [innerHTML]="element.htmlLongText | safeHtml"></div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: columnsToDisplay"
          class="element-row"
          [class.expanded-row]="expandedElement === element"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
      </table>
    </div>
  </ng-container>
</div>
<ng-template #loader>
  <div class="spinner">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
