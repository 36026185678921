<div mat-dialog-content>
  <h1 *ngIf="structureView === 'invoices'">Neue Abrechnung Erstellen</h1>
  <h1 *ngIf="structureView === 'estimations'">Neue Mengenermittlung Erstellen</h1>
  <div *ngIf="data" class="copy">
    <div>Kopieren aus:</div>
    <div class="copy_name">{{ data.name }}</div>
  </div>
  <form [formGroup]="newInvoiceForm" (submit)="createInvoice()">
    <div>
      <mat-form-field class="field">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" required />
      </mat-form-field>
    </div>
    <div *ngIf="data">
      <mat-form-field class="field">
        <mat-label>Nummer</mat-label>
        <input matInput type="number" formControlName="number" placeholder="Optional" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill" class="field">
        <mat-label>Abrechnungsmethode</mat-label>
        <mat-select formControlName="calculationType">
          <mat-option *ngFor="let type of calculationTypes" [value]="type">
            {{ type | quantityTakeOffCalculationType }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <ng-container *ngIf="structureView === 'invoices'">
      <div>
        <mat-form-field appearance="fill" class="field">
          <mat-label>Leistungszeitraum Start</mat-label>
          <input matInput [matDatepicker]="pickerStart" formControlName="performancePeriodStartUtc" />
          <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
          <mat-datepicker #pickerStart [disabled]="false"></mat-datepicker>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill" class="field">
          <mat-label>Leistungszeitraum Ende</mat-label>
          <input matInput [matDatepicker]="pickerEnd" formControlName="performancePeriodEndUtc" />
          <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
          <mat-datepicker #pickerEnd [disabled]="false"></mat-datepicker>
        </mat-form-field>
      </div>
    </ng-container>
    <div *ngIf="structureView === 'estimations'">
      <mat-checkbox formControlName="useCurrentProjectQuantities"> Mengen aus LV übernehmen </mat-checkbox>
    </div>
    <button mat-raised-button color="primary" [disabled]="newInvoiceForm.invalid || requestInFlight">Erstellen</button>
    <button mat-raised-button color="warn" type="button" (click)="cancel()">Abbrechen</button>
  </form>
</div>
