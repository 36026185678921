import { Pipe, PipeTransform } from '@angular/core';

import { ContactType } from '../../../generated-client/generated-client';

@Pipe({
  name: 'contactType',
  standalone: true
})
export class ContactTypePipe implements PipeTransform {
  transform(value: ContactType): unknown {
    switch (value) {
      case ContactType.Customer:
        return 'Kunde';

      case ContactType.Tenderer:
        return 'Ausschreibende Stelle';

      case ContactType.Supplier:
        return 'Lieferant';

      case ContactType.Unknown:
        return 'Unbekannt';

      case ContactType.SubContractor:
        return 'Subunternehmer';
    }

    return null;
  }
}
