<table mat-table [dataSource]="avaProjectsService" matSort (matSortChange)="avaProjectsService.onSort($event)">
  <ng-container matColumnDef="name">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let row" matRipple>{{ row.name }}</td>
  </ng-container>
  <ng-container matColumnDef="modifiedAtUtc">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Letzte Änderung</th>
    <td mat-cell *matCellDef="let row" matRipple>{{ row.modifiedAtUtc | date: 'dd.MM.yyyy HH:mm' }}</td>
  </ng-container>
  <ng-container matColumnDef="totalPrice">
    <th mat-header-cell *matHeaderCellDef class="right">Gesamtpreis</th>
    <td mat-cell *matCellDef="let row" matRipple class="right">
      {{ totalPrices[row.id]?.totalPrice | number: '1.2-2' }} {{ totalPrices[row.id]?.currency }}
    </td>
  </ng-container>
  <ng-container matColumnDef="servSpecType">
    <th mat-header-cell *matHeaderCellDef>Typ</th>
    <td mat-cell *matCellDef="let row" matRipple>{{ row.type | avaProjectType }}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selectAvaProject(row)"></tr>
</table>
<pa-upper-paginator
  [pageSizeOptions]="pageSizeOptions"
  [upperPaginated]="avaProjectsService.paginationResult | async"
  [upperService]="avaProjectsService"
  (page)="avaProjectsService.onPage($event)"
>
</pa-upper-paginator>
