import { DecimalPipe } from '@angular/common';
import { Directive, ElementRef, HostListener, Input, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';

@Directive({
  selector: 'input[matInputDecimalPlaces]',
  providers: [
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: MatInputDecimalPlacesDirective },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatInputDecimalPlacesDirective),
      multi: true
    },
    { provide: NG_VALIDATORS, useExisting: MatInputDecimalPlacesDirective, multi: true }
  ],
  standalone: true
})
export class MatInputDecimalPlacesDirective {
  private _value: string | null | number;
  private isInFocus = false;

  constructor(
    private elementRef: ElementRef<HTMLInputElement>,
    private decimalPipe: DecimalPipe
  ) {}

  get value(): string | null | number {
    return this._value;
  }

  @Input() scale: number | null;
  @Input() doNotForceDefaultScale = false;
  @Input()
  set value(value: string | null | number) {
    this._value = value;
    this.formatValue(value);
  }

  /**
   *If we need to render zero to input value,
   *we need to set this parameter to true
   **/
  @Input() isShowZero = false;

  /**
   * If this is set to true, then the formatting will only be applied
   * when the value is not in focus
   */
  @Input() formatOnlyOnFocusOut = false;

  private formatValue(value: string | null | number) {
    if (value !== null && value !== '') {
      const numberWithCommas = this.numberWithCommas(value);
      if (numberWithCommas) {
        this.elementRef.nativeElement.value = numberWithCommas.toString();
      }
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  private unFormatValue() {
    this._value = this.elementRef.nativeElement.value;
    if (!this._value) {
      this.elementRef.nativeElement.value = '';
    }
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    if (this.formatOnlyOnFocusOut && this.isInFocus) {
      this._value = this.numberWithCommas(value);
    } else if (!this.formatOnlyOnFocusOut) {
      this._value = this.numberWithCommas(value);
    }
    this._onChange(this._value);
  }

  @HostListener('blur')
  _onBlur(): void {
    this.formatValue(this._value);
  }

  @HostListener('focus')
  onFocus(): void {
    this.isInFocus = true;
    this.unFormatValue();
  }

  @HostListener('focusout')
  onFocusOut(): void {
    this.isInFocus = false;
    if (this.formatOnlyOnFocusOut) {
      this.formatValue(this._value);
    }
  }

  _onChange(_value: string | number | null): void {}

  writeValue(value: string | number | null): void {
    this._value = value;
    this.formatValue(this._value);
  }

  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(): void {}

  private numberWithCommas(num: string | number | null): string | number {
    if (this.formatOnlyOnFocusOut && this.isInFocus) {
      return num;
    }

    if (typeof num === 'string') {
      const changedNum = num.replace(',', '.');
      if (isNaN(+changedNum)) {
        return num;
      }
      num = changedNum;
    }

    let withDecimalPlaces;
    let scale: number;
    if (num || this.isShowZero) {
      if (this.scale != null) {
        scale = this.scale;
      } else if (num.toString().includes('.')) {
        const x = num.toString().split('.').pop().length <= 5 ? num.toString().split('.').pop().length : 5;
        scale = x;
      } else if (this.doNotForceDefaultScale) {
        scale = 0;
      } else {
        scale = 2;
      }

      withDecimalPlaces = this.decimalPipe.transform(num, `1.${scale}-${scale}`, 'de-DE');
      // string.replace only replaces the first occurence, so we need to potentially
      // call it multiple times.
      withDecimalPlaces = withDecimalPlaces?.replace(/\./g, '');
    }

    if (withDecimalPlaces) {
      // Some inputs actually only show decimals, but aren't of `type="number"` themselves.
      // That's the case, for example, with price components in the calculation. They cant user the
      // number type, because users are also able to enter string values to open the search in
      // the master data with it.
      if (this.elementRef?.nativeElement?.type === 'number') {
        return withDecimalPlaces.replace(',', '.');
      } else {
        return withDecimalPlaces;
      }
    }

    return '';
  }

  validate(control: AbstractControl): ValidationErrors | null {
    control.markAsTouched();
    return null;
  }
}
