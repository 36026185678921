<div class="wrapper">
  <h2>Kontakte Importieren</h2>
  <p>
    Kontakte können aus einer CSV-Datei importiert werden. Dabei werden Kontakte neu angelegt, oder die Informationen werden aktualisiert.
  </p>
  <div class="spinner" *ngIf="isLoading">
    <div fxFlex fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <pa-upload-file *ngIf="!isLoading" [uploadFileSettings]="uploadFileSettings" (uploadFile)="importFile($event)"></pa-upload-file>
</div>
