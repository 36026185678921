<div class="spinner" *ngIf="isLoading">
  <div fxFlex fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
  </div>
</div>
<div class="wrapper" *ngIf="!isLoading">
  <div class="wrapper-field" fxLayout="column">
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="currentProject.name" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Beschreibung</mat-label>
        <input matInput [(ngModel)]="currentProject.description" />
      </mat-form-field>
    </div>
  </div>
  <button mat-raised-button color="primary" (click)="editProject()" [disabled]="isLoading">Speichern</button>
</div>
