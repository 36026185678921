import { BimFileQuantityAssignmentModel } from '../../../generated-client/generated-client';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountingQuantityAssignmentsService {
  private quantityAssignmentsForPositionSource = new Subject<number | null>();
  quantityAssignmentsForPosition = this.quantityAssignmentsForPositionSource.asObservable();

  constructor() {}

  setQuantity(quantity: number | null): void {
    this.quantityAssignmentsForPositionSource.next(quantity);
  }

  countingQuantity(quantityAssignmentsForModel: BimFileQuantityAssignmentModel, positionId: string): void {
    if (quantityAssignmentsForModel.quantityAssignmentsByPositionId) {
      const quantityAvaElementId = quantityAssignmentsForModel.quantityAssignmentsByPositionId[positionId]?.map((v) => v.elementQuantity);
      if (quantityAvaElementId?.length) {
        const quantity = quantityAvaElementId.reduce((acc, cur) => {
          return acc + cur;
        });
        this.setQuantity(quantity || 0);
      } else {
        this.setQuantity(0);
      }
    }
  }
}
