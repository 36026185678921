<p>Diese Ansicht ist für die Entwicklung des Programms, um gefundene Fehler oder Verbesserungswünsche aufzulisten.</p>
<mat-form-field class="full-width">
  <mat-label>Suche</mat-label>
  <input matInput [ngModel]="filter" (ngModelChange)="errorReportsService.onFilter($event)" />
</mat-form-field>
<mat-checkbox [(ngModel)]="showResolved">Erledigte Anzeigen</mat-checkbox>
<table mat-table [dataSource]="errorReportsService" matSort (matSortChange)="errorReportsService.onSort($event)">
  <ng-container matColumnDef="id">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Nummer</th>
    <td mat-cell *matCellDef="let row" (click)="showDetail(row)">
      {{ row.id }}
    </td>
  </ng-container>
  <ng-container matColumnDef="createdAtUtc">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Datum</th>
    <td mat-cell *matCellDef="let row" (click)="showDetail(row)">
      {{ row.createdAtUtc | date: 'dd.MM.yyyy HH:mm' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="createdByUserName">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Kontakt</th>
    <td mat-cell *matCellDef="let row" (click)="showDetail(row)">{{ row.createdByUserName }}</td>
  </ng-container>
  <ng-container matColumnDef="isResolved">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Erledigt</th>
    <td mat-cell *matCellDef="let row" (click)="showDetail(row)">
      <mat-checkbox [(ngModel)]="row.isResolved" disabled></mat-checkbox>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="['id', 'createdAtUtc', 'createdByUserName', 'isResolved']"></tr>
  <tr mat-row *matRowDef="let row; columns: ['id', 'createdAtUtc', 'createdByUserName', 'isResolved']"></tr>
</table>
<pa-upper-paginator
  [pageSizeOptions]="[5, 10, 25, 100]"
  [upperPaginated]="errorReportsService.paginationResult | async"
  [upperService]="errorReportsService"
  (page)="errorReportsService.onPage($event)"
>
</pa-upper-paginator>
