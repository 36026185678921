<span class="page-number">Aktuelle Seite:{{ numberOfPage }}</span>
<div class="wrapper-preview" *ngIf="selectedAttchment && !isLoading; else loader" #imageContainer>
  <div class="page" [id]="i + 1" *ngFor="let imgFile of selectedAttchment.quantityTakeOffAttachmentImageFiles; let i = index">
    <img src="{{ imgFile.imageFileId | imgSrc | async }}" (click)="selectPage(i + 1)" class="image-container" />
  </div>
</div>
<ng-template #loader>
  <div fxLayoutAlign="center center" class="loader">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<div fxLayoutGap="10px" class="wrapper-action" *ngIf="selectedAttchment">
  <mat-form-field appearance="fill" *ngIf="isSelectedMode">
    <mat-label>Seite</mat-label>
    <mat-select [formControl]="pageNumber" required>
      <mat-option *ngFor="let imgFile of selectedAttchment.quantityTakeOffAttachmentImageFiles" [value]="imgFile.pageNumber">
        {{ imgFile.pageNumber }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div fxLayoutGap="10px" class="wrapper-action__button">
    <button
      mat-button
      mat-raised-button
      color="primary"
      type="button"
      *ngIf="isSelectedMode"
      (click)="saveSelectedAttachment()"
      [disabled]="pageNumber.invalid"
    >
      Speichern
    </button>
    <button mat-button mat-raised-button color="warn" type="button" (click)="cancel()">Abbrechen</button>
  </div>
</div>
