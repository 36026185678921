import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LimitColumnWidthService {
  private columnWidthSource = new Subject<{ name: string; value: number }>();
  columnWidth = this.columnWidthSource.asObservable();

  private callBackSource = new Subject<void>();
  callBack = this.callBackSource.asObservable();

  constructor() {}

  setWidth(name: string, value: number): void {
    this.columnWidthSource.next({ name, value });
  }

  setCallBack(): void {
    this.callBackSource.next();
  }

  limitWidth(name: string, isSame?: boolean): void {
    if (isSame) {
      const tableOriginal = document.querySelector(`table[resizeName="${name}"]`);
      const table = tableOriginal.closest('.table-wrapper');
      const allWidth = parseInt(window.getComputedStyle(table).width) - 4;

      const tableHeaderRow = table.querySelector('thead tr');
      const arrColumn = Array.from(tableHeaderRow.querySelectorAll('th'));

      const listPREFIX: string[] = ['mat-column-', 'cdk-column-'];
      let PREFIX = listPREFIX[0];
      const arrClass = Array.from(arrColumn[0].classList);
      if (!arrClass.some(cls => cls.includes(PREFIX))) {
        PREFIX = listPREFIX[1];
      }
      let countResized = 0;
      const freezeWidth = arrColumn.reduce((sum, item) => {
        if (item.hasAttribute('resizecolumn')) {
          countResized += 1;
          return sum;
        } else {
          return sum + parseInt(window.getComputedStyle(item).width);
        }
      }, 0);

      const otherWidth = Math.floor((allWidth - freezeWidth - 2) / countResized);
      this.setWidth(name, otherWidth);
    } else {
      this.setWidth(name, 0);
    }
  }
}
