<div class="wrapper" matTooltip="Zoom" #originOverlay="cdkOverlayOrigin" cdkOverlayOrigin (click)="openOverlayPanel(originOverlay)">
  <span class="zoom-percent">{{ zoomPercent }}%</span>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpenSlider"
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayLockPosition
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  (backdropClick)="closeOverlay()"
>
  <div class="slider">
    <div fxLayoutAlign="center end">
      <span>Zoom</span>
      <span class="percent">{{ zoomPercent / 100 | percent }}</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <mat-icon color="primary" class="btn-icon" (click)="changeValueByButton(false)">remove_circle_outline</mat-icon>

      <mat-slider color="primary" #zoomSize [max]="140" [min]="60" [step]="1" aria-labelledby="example-name-label" [disableRipple]="true">
        <input matSliderThumb [(ngModel)]="zoomPercent" (ngModelChange)="putValue($event)" />
        <div class="thumb" [style.left.%]="((zoomPercent - 60) * 100) / 80"></div>
      </mat-slider>
      <mat-icon color="primary" class="btn-icon" (click)="changeValueByButton(true)">add_circle_outline</mat-icon>
    </div>
  </div>
</ng-template>
