import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSelect } from '@angular/material/select';

import { fromEvent, Subject, takeUntil } from 'rxjs';

import { QuantityTakeOffAttachmentGet } from 'app/generated-client/generated-client';
import { SelectedAttachmentService } from 'app/shared/services/messengers/selected-attachment.service';

import { ImgSrcPipe } from '../../../../../../../../shared/pipes/img-src.pipe';
import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-invoice-pdf-preview',
  templateUrl: './invoice-pdf-preview.component.html',
  styleUrls: ['./invoice-pdf-preview.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    FlexLayoutDirective,
    MatProgressSpinner,
    MatFormField,
    MatLabel,
    MatSelect,
    FormsModule,
    ReactiveFormsModule,
    MatOption,
    MatButton,
    AsyncPipe,
    ImgSrcPipe
  ]
})
export class InvoicePdfPreviewComponent implements OnInit, OnDestroy {
  private $destroy = new Subject<boolean>();
  @Input() isSelectedMode: boolean;
  @Output() saveAttachment = new EventEmitter<{
    quantityTakeOffAttachmentId?: string | null;
    quantityTakeOffAttachmentImageFileId?: string | null;
  }>();
  isLoading: boolean;
  sections: (string | number)[][];
  numberOfPage = 1;
  private imageContainer: ElementRef;

  @ViewChild('imageContainer') set content(content: ElementRef) {
    if (content) {
      this.numberOfPage = 1;
      this.imageContainer = content;
      Promise.all(
        Array.from(document.images)
          .filter((img) => !img.complete)
          .map(
            (img) =>
              new Promise((resolve) => {
                img.onload = img.onerror = resolve;
              })
          )
      ).then(() => {
        this.sections = Array.from(this.imageContainer.nativeElement.querySelectorAll('.page')).map((section: any) => {
          return [section.id, section.offsetTop];
        });
      });
      fromEvent(this.imageContainer.nativeElement, 'scroll')
        .pipe(takeUntil(this.$destroy))
        .subscribe(() => {
          if (this.sections) {
            let foundIndex = 0;
            this.sections.forEach((sec, index) => {
              if (this.imageContainer.nativeElement.scrollTop >= (sec[1] as number) - this.imageContainer.nativeElement.offsetHeight / 2) {
                foundIndex = index;
              }
            });
            this.numberOfPage = +this.sections[foundIndex][0];
          }
        });
    }
  }

  pageNumber = new FormControl<number | null>(null, [Validators.required]);
  selectedAttchment: QuantityTakeOffAttachmentGet;
  constructor(private selectedAttachmentService: SelectedAttachmentService) {}

  ngOnInit(): void {
    this.selectedAttachmentService.selectedAttachment.pipe(takeUntil(this.$destroy)).subscribe((selectedAttachment) => {
      this.isLoading = true;
      this.pageNumber.patchValue(1);
      setTimeout(() => {
        this.selectedAttchment = selectedAttachment;
        this.isLoading = false;
      }, 1);
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
    this.selectedAttachmentService.setAttachment(null);
  }

  cancel(): void {
    this.selectedAttachmentService.setAttachment(null);
  }

  saveSelectedAttachment(): void {
    if (this.selectedAttchment && this.selectedAttchment.quantityTakeOffAttachmentImageFiles.length) {
      const saveAttachment = this.selectedAttchment.quantityTakeOffAttachmentImageFiles.find((a) => a.pageNumber === this.pageNumber.value);
      this.saveAttachment.emit({
        quantityTakeOffAttachmentId: saveAttachment.quantityTakeOffAttachmentId,
        quantityTakeOffAttachmentImageFileId: saveAttachment.id
      });
    }
  }

  selectPage(page: number): void {
    this.pageNumber.patchValue(page);
  }
}
