<h2>Filter</h2>
<div class="button-wrapper">
  <button mat-raised-button color="primary" (click)="addFilterGroup()">
    Neuer Filter
    <mat-icon>add_box</mat-icon>
  </button>
  <button mat-raised-button color="warn" (click)="resetFilters()">
    Filter zurücksetzen
    <mat-icon>delete_sweep</mat-icon>
  </button>
</div>

<ng-container *ngFor="let filter of numberOfFilters; let i = index">
  <pa-element-properties-filter-selection
    (remove)="removeFilterGroup(i)"
    (update)="updatePropertySetFilter($event, i)"
    [filterIndex]="i"
    [ifcTypes]="ifcTypes | async"
  ></pa-element-properties-filter-selection>
</ng-container>
