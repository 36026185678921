<h1 *ngIf="activatedRoute.snapshot.data && !activatedRoute.snapshot.data?.isShortTabs">Projekteinstellungen</h1>
<nav mat-tab-nav-bar [tabPanel]="tabPanel">
  <a
    mat-tab-link
    #rla="routerLinkActive"
    *ngFor="let tab of managmentTabs; let i = index"
    [routerLink]="tab.link"
    routerLinkActive="active"
    [active]="rla.isActive"
    >{{ tab.label }}
  </a>
</nav>
<router-outlet #tabPanel></router-outlet>
