<table mat-table [dataSource]="projectsService">
  <ng-container matColumnDef="identicon">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let row" matRipple>
      <img class="project-icon" [diIcon]="row.identiconId" [rectangleSize]="48" />
    </td>
  </ng-container>
  <ng-container matColumnDef="constructedProjectNumber">
    <th mat-header-cell *matHeaderCellDef>Nummer</th>
    <td mat-cell *matCellDef="let row" matRipple>
      {{ row.constructedProjectNumber }}
    </td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let row" matRipple>{{ row.name }}</td>
  </ng-container>
  <ng-container matColumnDef="contactName">
    <th mat-header-cell *matHeaderCellDef>Kontakt</th>
    <td mat-cell *matCellDef="let row" matRipple>{{ row.contactName }}</td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let row" matRipple>{{ row.status | projectStatus }}</td>
  </ng-container>
  <ng-container matColumnDef="companyName">
    <th mat-header-cell *matHeaderCellDef>Firma</th>
    <td mat-cell *matCellDef="let row" matRipple>{{ row.companyName }}</td>
  </ng-container>
  <ng-container matColumnDef="lastAccessedAtUtc">
    <th mat-header-cell *matHeaderCellDef>Zuletzt angesehen</th>
    <td mat-cell *matCellDef="let row" matRipple>
      {{ row.lastAccessedAtUtc | date: 'dd.MM.yyyy HH:mm' }}
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns" (dblclick)="selectedProject(row)"></tr>
</table>
<pa-upper-paginator
  [pageSizeOptions]="pageSizeOptions"
  [upperPaginated]="projectsService?.paginationResult | async"
  [upperService]="projectsService"
  (page)="projectsService.onPage($event)"
>
</pa-upper-paginator>
