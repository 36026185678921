import { Injectable } from '@angular/core';
import { AvaProjectGet, IElementDto, PositionDto, ProjectDto, ServiceSpecificationGroupDto } from 'app/generated-client/generated-client';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectedSpecificationMessengerService {
  private selectedSpecificationSource = new ReplaySubject<{
    avaProjectId: string;
    project: ProjectDto;
    parentProjectId: string;
    avaProject: AvaProjectGet;
  }>(1);
  selectedServiceSpecification = this.selectedSpecificationSource.asObservable();

  _currency: string = '';
  private projectCurrencySource = new ReplaySubject<string | null>(1);
  projectCurrency = this.projectCurrencySource.asObservable();

  private _positionsList: PositionDto[] = [];
  private positionsListSource = new ReplaySubject<PositionDto[] | null>(1);
  positionsList = this.positionsListSource.asObservable();

  constructor() {
    this.clearSelectedServiceSpecification();
  }

  setSelectedServiceSpecification(
    avaProjectId: string,
    serviceSpecification: ProjectDto,
    parentProjectId: string,
    avaProject: AvaProjectGet
  ): void {
    this.selectedSpecificationSource.next({ avaProjectId, project: serviceSpecification, parentProjectId, avaProject });
    this.setProjectCurrency(serviceSpecification.projectInformation.currencyShort);
  }

  clearSelectedServiceSpecification(): void {
    this.selectedSpecificationSource.next(null);
    this.setProjectCurrency(null);
  }

  setProjectCurrency(projectCurrency: string | null): void {
    this._currency = projectCurrency || '';
    this.projectCurrencySource.next(projectCurrency);
  }

  getCurrency(): string {
    return this._currency;
  }

  setElementDtoList(project: ProjectDto | null): void {
    this.createList(project?.serviceSpecifications[0].elements);
  }

  private createList(elements: IElementDto[] | null): void {
    if (elements) {
      elements.forEach((element: IElementDto) => {
        switch (element.elementType) {
          case 'ServiceSpecificationGroupDto':
            this.createList((<ServiceSpecificationGroupDto>element).elements);
            break;
          case 'PositionDto':
            this._positionsList.push(element as PositionDto);
            break;
        }
      });
    } else {
      this._positionsList = [];
    }
    this.positionsListSource.next(this._positionsList);
  }
}
