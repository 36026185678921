import { Injectable, NgZone } from '@angular/core';

import { EMPTY, Observable, ReplaySubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { FileSaverService } from 'app/shared/services/file-saver.service';
import { ModalService } from 'app/shared/services/modal.service';
import { UserSettingsService } from 'app/shared/services/user-settings.service';

import { UserSettings } from './../../../generated-client/generated-client';
import { ElectronService } from './electron.service';

@Injectable({
  providedIn: 'root'
})
export class PrintViewMessengerService {
  private printViewClosedSource = new Subject<void>();
  printViewClosed = this.printViewClosedSource.asObservable();
  private isSubscribedToPrintViewClosed = false;

  private printViewVisibleSource = new ReplaySubject<boolean>(1);
  printViewVisible = this.printViewVisibleSource.asObservable();

  private printTookTooLongSource = new Subject<void>();
  printTookTooLong = this.printTookTooLongSource.asObservable();

  private maxTimeIntervalBeforSendError = 10;
  private interval: NodeJS.Timer;
  private currentPdfWindowId = 0;

  constructor(
    private electronService: ElectronService,
    private ngZone: NgZone,
    private userSettingsService: UserSettingsService,
    private modalService: ModalService,
    private fileSaverService: FileSaverService
  ) {
    this.ensurePrintViewClosedIsSubscribed();
  }

  ensurePrintViewClosedIsSubscribed(): void {
    if (this.isSubscribedToPrintViewClosed) {
      return;
    }

    this.isSubscribedToPrintViewClosed = true;
    this.electronService.ipcRenderer?.on('PrintViewClosed', () => {
      this.ngZone.run(() => {
        this.printViewClosedSource.next();
        this.printViewVisibleSource.next(false);
      });
    });
  }

  waitForPrintViewReady(): Observable<void> {
    const subject = new ReplaySubject<void>(1);
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.once('PrintViewWindowReady', () => {
        this.printViewVisibleSource.next(true);
        this.ngZone.run(() => {
          subject.next();
          subject.complete();
        });
      });

      return subject;
    }

    subject.next();
    subject.complete();

    //Here need to add time out that first of all returned subject emitted value and only then these send
    setTimeout(() => {
      this.printViewClosedSource.next();
      this.printViewVisibleSource.next(false);
    }, 0);
    return subject;
  }

  showPdfPreview(
    html: string,
    fileNameWithoutExtension: string,
    isLandscape: boolean,
    pdfBase64: string,
    includeHeaderOnlyOnFirstPage: boolean
  ): void {
    if (!this.electronService.isElectron) {
      this.openOrDownloadPdfFileForWebMode(pdfBase64);
      return;
    }
    let intervalCounter = 0;
    this.interval = setInterval(() => {
      intervalCounter++;
      this.checkCounterTimer(intervalCounter).subscribe((isSave) => {
        if (isSave) {
          this.fileSaverService.saveAsFromBase64(pdfBase64, `${fileNameWithoutExtension}.pdf`);
        }
      });
    }, 1000);
    this.userSettingsService.currentUserSettings.pipe(take(1)).subscribe((setting: UserSettings) => {
      this.electronService.ipcRenderer.send(
        'GeneratePdfAndShowPrintViewWindow',
        {
          html: html,
          fileName: fileNameWithoutExtension,
          isLandscape: isLandscape,
          pdfBase64: pdfBase64,
          includeHeaderOnlyOnFirstPage: includeHeaderOnlyOnFirstPage,
          openInModalWindow: !setting.showFullViewOverlayWhenOpeningPdfPreview
        },
        this.currentPdfWindowId
      );
      this.currentPdfWindowId++;
    });
    this.waitForPrintViewReady().subscribe(() => {
      clearInterval(this.interval);
    });
  }
  // TODO: Maybe we should also remove this function as it is not used anywhere
  getGeneratedPdfData(): Observable<{ pdfData: Uint8Array; html: string; fileNameWithoutExtension: string }> {
    const subject = new ReplaySubject<any>(1);
    this.electronService.ipcRenderer.once(
      'PdfData',
      (event, data: { pdfData: Uint8Array; html: string; fileNameWithoutExtension: string }) => {
        this.ngZone.run(() => {
          subject.next(data);
          subject.complete();
        });
      }
    );

    this.electronService.ipcRenderer.send('GetPdfData');
    return subject;
  }

  closePdfViewWindow(): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('PrintViewClosed');
    }
  }

  private checkCounterTimer(intervalCounter: number): Observable<any> {
    if (intervalCounter > this.maxTimeIntervalBeforSendError) {
      clearInterval(this.interval);
      this.electronService.ipcRenderer.send('PrintViewClosed');
      this.printTookTooLongSource.next();
      return this.modalService
        .openModal(ModalConfirmComponent, {
          dialogType: ConfirmationType.General,
          data: ['Speichern', 'PDF', 'Die Anzeige des PDFs dauert zu lange, aber das PDF kann direkt gespeichert werden.'],
          disableClose: true
        })
        .afterClosed();
    }
    return EMPTY;
  }

  openOrDownloadPdfFileForWebMode(base64: string): void {
    const byteArray = new Uint8Array(
      window
        .atob(base64)
        .split('')
        .map((char) => char.charCodeAt(0))
    );

    const file = new Blob([byteArray], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    const downloadFile = () => {
      const a = document.createElement('a');
      a.href = fileURL;
      a.download = 'document.pdf';
      a.click();
      URL.revokeObjectURL(fileURL);
    };

    const newWindow = window.open(fileURL);
    if (newWindow === null || typeof newWindow === 'undefined') {
      downloadFile();
    }
  }
}
