<h1 *ngIf="structureView === 'estimations'">Mengenermittlungen</h1>
<h1 *ngIf="structureView === 'invoices'">Aufmaßblattverzeichnis</h1>
<div fxLayoutGap="12px" class="wrapper-btn">
  <button
    mat-raised-button
    color="primary"
    (click)="showAddNewModal()"
    *ngIf="structureView === 'estimations'"
    [disabled]="isReadOnly || isLoading"
  >
    Neue Mengenermittlung
  </button>
  <ng-container *ngIf="structureView === 'invoices'">
    <button mat-raised-button color="primary" (click)="showAddNewModal()" [disabled]="isReadOnly || isLoading">Neue Abrechnung</button>
  </ng-container>
  <button mat-raised-button color="primary" (click)="changeOnlyPagesMode()" [disabled]="isLoading">Alle Abrechnungen</button>
  <button mat-raised-button color="primary" (click)="createPagesForLastQTO()" (contextmenu)="onContextMenu($event)" [disabled]="isLoading">
    Neues Aufmaßblatt
  </button>
  <button mat-raised-button color="primary" (click)="printPages()" [disabled]="!isHavePages || isLoading">Verzeichnis Drucken</button>
</div>
<pa-invoices-pages [typeQTO]="typeQTO"></pa-invoices-pages>

<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent>
    <h3 class="title-qto">Abrechnung Auswählen</h3>
    <button
      mat-menu-item
      (click)="changeLastQto(page)"
      class="overlay-button"
      *ngFor="let page of onlyPagesQTO"
      [class.current]="page.id === latestQTO.id"
    >
      {{ page.name }}
    </button>
  </ng-template>
</mat-menu>

<div
  style="position: fixed; visibility: hidden"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
></div>

<div fxLayoutAlign="center center" class="spinner" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>
