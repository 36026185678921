import { NgIf, NgTemplateOutlet, NgFor, NgClass, AsyncPipe, DecimalPipe, PercentPipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatDivider } from '@angular/material/divider';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';

import { TinyMceComponent } from '@dangl/angular-material-shared/tiny-mce';

import { Observable, Subject, delay, fromEvent } from 'rxjs';
import { filter, first, take, takeUntil } from 'rxjs/operators';

import { TotalSumsComponent } from '@serv-spec/components/total-sums/total-sums.component';
import { CalculationTotalsService } from '@serv-spec/services/calculation-totals.service';
import { ChangeViewportHeightService } from '@serv-spec/services/change-viewport-height.service';

import { WindowType } from '@shared/models/window-type.models';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';
import { ModePageService } from 'app/areas/tree/services/mode-page.service';
import {
  AdditionTypeDto,
  AvaProjectAssumedQuantitiesGet,
  ComissionStatusDto,
  ItemNumberDto,
  ItemNumberSchemaDto,
  ItemNumberSchemasClient,
  PositionCalculationTotals,
  PositionDto,
  PositionTypeDto,
  PriceTypeDto,
  ProjectDto,
  ServiceTypeDto,
  StandardReferenceTypeDto,
  UserSettings
} from 'app/generated-client/generated-client';
import { FilterDialogType, ShowedTableQuantitiesType } from 'app/shared/models';
import { ElementViewMessengerService } from 'app/shared/services/electron/element-view-messenger.service';
import { LongTextViewMessengerService } from 'app/shared/services/electron/long-text-view-messenger.service';
import { ElementHasWithoutTotalGroupService } from 'app/shared/services/element-has-without-total-group.service';
import { ProjectQuantityEstimationService } from 'app/shared/services/messengers/project-quantity-estimation.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ShowedViewsService } from 'app/shared/services/showed-views.service';
import { UserSettingsService } from 'app/shared/services/user-settings.service';
import { setAsSplitSize } from 'app/shared/utilities/area-size';
import { getStorage, setStorage } from 'app/shared/utilities/storage';

import { LongTextComponent } from '../../../../shared/components/long-text/long-text.component';
import { IncludesPipe } from '../../../../shared/pipes/includes.pipe';
import { AdditionTypePipe } from '../../../../shared/pipes/ui-data-display/addition-type.pipe';
import { CatalogueTypePipe } from '../../../../shared/pipes/ui-data-display/catalogue-type.pipe';
import { ComissionStatusPipe } from '../../../../shared/pipes/ui-data-display/comission-status.pipe';
import { PositionTypePipe } from '../../../../shared/pipes/ui-data-display/position-type.pipe';
import { PriceTypePipe } from '../../../../shared/pipes/ui-data-display/price-type.pipe';
import { ServiceTypePipe } from '../../../../shared/pipes/ui-data-display/service-type.pipe';
import { CalculationRowLineComponent } from '../../../project-id/components/service-specifications/components/calculation-row-line/calculation-row-line.component';
import { InvoicePositionsComponent } from '../../../project-id/components/service-specifications/components/invoice/components/invoice-positions/invoice-positions.component';
import { MatInputDecimalPlacesDirective } from '../../../project-id/components/service-specifications/directives/mat-input-decimal-places.directive';
import { LvEditorService } from '../../../project-id/components/service-specifications/services/lv-editor.service';
import { CalculationFixedPriceService } from '../../../project-id/services/calculation-fixed-price.service';
import { ItemNumberEditingComponent } from '../../../service-specification-editing/components/item-number-editing/item-number-editing.component';
import { DetailTableModalService } from '../../services/detail-table-modal.service';

import { GroupTotalsTableComponent } from '../group-totals-table/group-totals-table.component';
import { ItemNumberFieldComponent } from '../item-number-field/item-number-field.component';
import { PositionTypeComponent } from '../position-type/position-type.component';
import { SwitcherTableQtoCompactComponent } from '../switcher-table-qto-compact/switcher-table-qto-compact.component';
import { IOutputAreaSizes, SplitComponent, AngularSplitModule } from 'angular-split';

@Component({
  selector: 'pa-position',
  templateUrl: './position.component.html',
  styleUrls: ['./position.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    AngularSplitModule,
    NgTemplateOutlet,
    MatIcon,
    MatTooltip,
    MatDivider,
    MatButton,
    MatTabGroup,
    MatTab,
    MatFormField,
    MatLabel,
    MatInput,
    ItemNumberEditingComponent,
    MatSelect,
    FormsModule,
    NgFor,
    MatOption,
    MatCheckbox,
    LongTextComponent,
    TinyMceComponent,
    ItemNumberFieldComponent,
    MatInputDecimalPlacesDirective,
    NgClass,
    SwitcherTableQtoCompactComponent,
    PositionTypeComponent,
    CalculationRowLineComponent,
    InvoicePositionsComponent,
    GroupTotalsTableComponent,
    AsyncPipe,
    DecimalPipe,
    PercentPipe,
    PositionTypePipe,
    AdditionTypePipe,
    CatalogueTypePipe,
    PriceTypePipe,
    ComissionStatusPipe,
    ServiceTypePipe,
    IncludesPipe,
    TotalSumsComponent,
    FlexLayoutDirective
  ]
})
export class PositionComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.changeViewportHeightService.updatedViewportHeight(true);
  }
  private _element: PositionDto;
  @Input() set element(value: PositionDto) {
    const prevEl = this._element ? JSON.parse(JSON.stringify(this._element)) : null;
    if (prevEl && prevEl.elementTypeDiscriminator === 'ServiceSpecificationGroupDto' && this.asSplitPositionDividerRef) {
      setTimeout(() => {
        setAsSplitSize('AS_SPLIT_POSITION_DIVIDER', this.asSplitPositionDividerRef);
      }, 0);
    }
    this._element = value;
  }

  get element(): PositionDto {
    return this._element;
  }
  @Input() elementList?: FilterDialogType[];
  @Input() addition: string[];
  @Input() isInnerWindow: boolean;
  @Input() calculateUnitPrice: number;
  @Input() showOnlyCompactView: boolean;

  @ViewChild('tabLangText') tabLangText: MatTab;
  @ViewChild('position_details_wrapper') positionDetailsWrapperElement: ElementRef<HTMLElement>;
  @ViewChild('asSplitPositionDividerRef') asSplitPositionDividerRef: SplitComponent;
  @ViewChild('asSplitCalcArea') asSplitCalcArea: ElementRef;
  schema: ItemNumberSchemaDto;
  assumedQuantities: AvaProjectAssumedQuantitiesGet = {
    assumedQuantitiesByPositionId: {}
  };

  onKeydownHandler(event: KeyboardEvent) {
    if (!(event.target instanceof HTMLElement)) {
      return;
    }

    const eventTarget = event.target;
    const id = event.target.id;

    switch (event['key']) {
      case 'Enter':
        this.ngZone.run(() => {
          eventTarget.blur();
          this.focusedField = null;
        });
        break;
      case 'Escape':
        if (id) {
          this.ngZone.run(() => {
            let value = this.backElement[id];
            if (id === 'fixedPrice') {
              value = this.lastFixedPrice;
            }
            if (!isNaN(value)) {
              value = parseFloat(value).toFixed(2);
            }
            this.isDoBlurOperation = false;
            eventTarget.blur();
            this.focusedField = null;
            this.renderer.setProperty(eventTarget, 'value', value);
            this.isDoBlurOperation = true;
          });
        }
        break;
      default:
    }
  }

  positionFixedPrice: number | null = null;

  elementViewOpen = false;
  positionTypes = Object.keys(PositionTypeDto) as PositionTypeDto[];
  priceTypes = Object.keys(PriceTypeDto) as PriceTypeDto[];
  additionTypes = Object.keys(AdditionTypeDto) as AdditionTypeDto[];
  standardReferenceTypes = Object.keys(StandardReferenceTypeDto) as StandardReferenceTypeDto[];
  comissionStatuses = Object.keys(ComissionStatusDto) as ComissionStatusDto[];
  serviceTypes = Object.keys(ServiceTypeDto) as ServiceTypeDto[];
  currentProjectHasQuantityEstimation = false;
  currentProjectHasAssumedQuantities = false;

  projectCurrency: string;
  projectId: string;
  avaProjectId: string;
  extend: boolean;
  additionLongText: string[] = [];
  backElement: PositionDto = {} as PositionDto;
  editMode: boolean;
  isChanged: boolean;
  isDoBlurOperation = true;
  showedPositionsEstimationCompact = false;
  showLangText: boolean;
  noTotalGroup: boolean;
  private $destroy: Subject<boolean> = new Subject<boolean>();
  // This property is used to track if the 'fixedPrice' property was changed after entering the fixed price input
  // We only want to save it if it was changed
  lastFixedPrice: number | null = null;
  focusedField: HTMLElement | null = null;
  avaProject: ProjectDto;
  currentTotals: PositionCalculationTotals;
  showedBottomView = false;
  showedTableQuantitiesType = ShowedTableQuantitiesType;

  constructor(
    private elementViewMessengerService: ElementViewMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private userSettingsService: UserSettingsService,
    private calculationFixedPriceService: CalculationFixedPriceService,
    private longTextViewMessengerService: LongTextViewMessengerService,
    private route: ActivatedRoute,
    private router: Router,
    private lvEditorService: LvEditorService,
    private renderer: Renderer2,
    private elementHasWithoutTotalGroupService: ElementHasWithoutTotalGroupService,
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private ngZone: NgZone,
    private itemNumberSchemasClient: ItemNumberSchemasClient,
    public projectQuantityEstimationService: ProjectQuantityEstimationService,
    public modePageService: ModePageService,
    private calculationTotalsService: CalculationTotalsService,
    private changeViewportHeightService: ChangeViewportHeightService,
    public showedViewsService: ShowedViewsService,
    private detailTableModalService: DetailTableModalService
  ) {}

  ngAfterViewInit(): void {
    setAsSplitSize('AS_SPLIT_POSITION_DIVIDER', this.asSplitPositionDividerRef);
    this.tryChangeHeightCalc();
    this.changeViewportHeightService.updateViewportHeight.pipe(takeUntil(this.$destroy), delay(100)).subscribe(() => {
      this.tryChangeHeightCalc();
    });
    if (this.positionDetailsWrapperElement && this.positionDetailsWrapperElement.nativeElement) {
      this.ngZone.runOutsideAngular(() => {
        fromEvent(this.positionDetailsWrapperElement.nativeElement, 'keyup')
          .pipe(takeUntil(this.$destroy))
          .subscribe((event: KeyboardEvent) => {
            this.onKeydownHandler(event);
          });
      });
    }
  }

  ngOnInit(): void {
    this.showedViewsService.showedBottomView.pipe(takeUntil(this.$destroy)).subscribe((showedBottomView) => {
      this.showedBottomView = showedBottomView;
    });
    this.calculationFixedPriceService.positionFixedPrice.pipe(takeUntil(this.$destroy)).subscribe((fixedPrice) => {
      if (this.element.id === fixedPrice?.positionId) {
        this.positionFixedPrice = fixedPrice.fixedPrice;
      } else {
        this.positionFixedPrice = null;
      }
    });

    this.elementViewMessengerService.elementViewVisible.pipe(takeUntil(this.$destroy)).subscribe((isOpen) => {
      if (this.elementViewOpen && isOpen) {
        this.elementViewMessengerService.setDataToElementView({
          command: 'elementList',
          data: this.elementList
        });
      }
      this.elementViewOpen = isOpen;
    });

    this.selectedSpecificationMessengerService.projectCurrency
      .pipe(takeUntil(this.$destroy))
      .subscribe((currency: string) => (this.projectCurrency = currency));

    this.userSettingsService.currentFullSettings.pipe(takeUntil(this.$destroy)).subscribe((setting: UserSettings) => {
      this.extend = !setting['showCompactPositionsViewByDefaultInCalculation'];
    });

    this.elementHasWithoutTotalGroupService.hasWithoutTotalGroup
      .pipe(takeUntil(this.$destroy))
      .subscribe((noTotalGroup) => (this.noTotalGroup = noTotalGroup));

    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        takeUntil(this.$destroy),
        filter((s) => !!s)
      )
      .subscribe((s) => {
        this.projectId = s.parentProjectId;
        this.avaProjectId = s.avaProjectId;
        this.avaProject = s.project;
        this.getSchema();
      });

    this.projectQuantityEstimationService.currentProjectHasQuantityCalculation
      .pipe(takeUntil(this.$destroy))
      .subscribe((hasEstimation) => (this.currentProjectHasQuantityEstimation = hasEstimation));

    this.projectQuantityEstimationService.currentProjectHasAssumedQuantities
      .pipe(takeUntil(this.$destroy))
      .subscribe((hasAssumedQuantities) => (this.currentProjectHasAssumedQuantities = hasAssumedQuantities));

    this.projectQuantityEstimationService.assumedQuantities
      .pipe(takeUntil(this.$destroy))
      .subscribe((assumedQuantities) => (this.assumedQuantities = assumedQuantities));

    this.calculationTotalsService.calculationTotal.pipe(takeUntil(this.$destroy)).subscribe((e: PositionCalculationTotals) => {
      this.currentTotals = e;
    });

    this.projectQuantityEstimationService.showedPositionsEstimationCompact.pipe(takeUntil(this.$destroy)).subscribe((e) => {
      const listAreas = this.asSplitPositionDividerRef?.getVisibleAreaSizes() as number[];
      this.showedPositionsEstimationCompact = e;
      if (listAreas) {
        let newLIstArea: number[];
        if (e) {
          const [first, calcTable, ...rest] = listAreas;
          newLIstArea = [first, 15, calcTable - 15, ...rest];
        } else {
          const [first, qto, calcTable, ...rest] = listAreas;
          newLIstArea = [first, qto + calcTable, ...rest];
        }
        setTimeout(() => {
          this.asSplitPositionDividerRef.setVisibleAreaSizes(newLIstArea);
          this.tryChangeHeightCalc();
        }, 0);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.focusedField) {
      this.focusedField.blur();
      this.focusedField = null;
    }
    if (changes.element) {
      this.backElement = JSON.parse(JSON.stringify(this.element));
    }
    if ((changes.element || changes.addition) && this.addition?.length) {
      this.additionLongText = [...this.addition, this.element.id];
    }
    this.editMode = false;
    this.isChanged = false;
    this.showLangText = this.tabLangText?.isActive;
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  tryChangeHeightCalc(): void {
    if (this.asSplitCalcArea) {
      this.changeViewportHeightService.setViewportHeight(this.asSplitCalcArea.nativeElement.offsetHeight);
    }
  }

  setFixedPrice(): void {
    if (this.lastFixedPrice !== this.positionFixedPrice) {
      this.lastFixedPrice = null;
      this.modePageService.modePage.pipe(take(1)).subscribe((modePage) => {
        if (modePage === 'calculation' || modePage === 'lv-editor') {
          this.calculationFixedPriceService.setFixedPriceForCalculation(this.backElement.id, this.positionFixedPrice);
        }
      });
      if (this.isInnerWindow) {
        this.elementViewMessengerService.sendDataFromElementView({ command: 'fixedPrice', data: this.positionFixedPrice });
      }
    }
  }

  changedLongText(newLongText: string): void {
    this.element.htmlLongText = newLongText;
    if (this.isInnerWindow) {
      this.elementViewMessengerService.sendLongTextFromElementView(newLongText);
    }
  }

  saveChanges(): Observable<boolean> {
    this.isChanged = false;
    this.editMode = false;
    return this.lvEditorService.saveChangedElement(this.backElement);
  }

  showTinyMce(e): void {
    if (e.index === 2) {
      this.showLangText = true;
    }
  }

  hideTinyMce(e): void {
    if (e !== 2) {
      this.showLangText = false;
    }
  }

  changedTinyMce(event) {
    if (this.showLangText && event !== this.element.htmlLongText) {
      this.isChanged = true;
    }
  }

  changeEditMode(): void {
    this.editMode = !this.editMode;
    if (this.editMode) {
      this.backElement = JSON.parse(JSON.stringify(this.element));
    } else {
      this.isChanged = false;
    }
  }

  onItemNumberChanged(itemNumber: ItemNumberDto): void {
    this.isChanged = true;
    this.backElement.itemNumber = itemNumber;
  }

  shortEditor(s: HTMLInputElement | HTMLTextAreaElement | MatSelect): void {
    if (this.backElement[s.id] != s.value) {
      const originalValue = this.backElement[s.id];
      this.backElement[s.id] = s.value;
      this.saveChanges().subscribe((hasChanged) => {
        if (!hasChanged) {
          s.value = originalValue;
          this.backElement[s.id] = originalValue;
        }
      });
    }
  }

  setFocusedField(filed: FocusEvent): void {
    this.focusedField = filed['target'] as HTMLElement;
  }

  openLongTextWindow(): void {
    this.userSettingsService.currentUserSettings.pipe(take(1)).subscribe((currentUserSettings) => {
      if (!currentUserSettings.showMultiComponentsViewInMainWindow) {
        this.longTextViewMessengerService.showLongTextViewWindow();
      } else {
        const showedList = getStorage<string[]>('listViews', [] as string[]) as string[];
        if (!showedList.includes(WindowType.LongText)) {
          this.showedViewsService.setShowedViews([...showedList, WindowType.LongText]);
        }
      }
    });
  }

  withoutTotal(element: PositionDto): boolean {
    return element['priceType'] === PriceTypeDto.WithoutTotal;
  }

  private getSchema(): void {
    this.itemNumberSchemasClient
      .getItemNumberSchema(this.projectId, this.avaProjectId)
      .subscribe((schema: ItemNumberSchemaDto) => (this.schema = schema));
  }

  tryEnterPositionQuantityAssumedQuantities(): void {
    if (this.currentProjectHasAssumedQuantities) {
      // When we switch to the position QTO view, we exit the tree, and therefore
      // the selected element is cleared. However, we want to ensure that the
      // current position is selected once we have switched, so we select it again.
      this.selectedSpecificationElementMessengerService.selectedElement
        .pipe(
          filter((e) => e == null),
          first()
        )
        .subscribe(() => {
          this.selectedSpecificationElementMessengerService.trySelectElementById(this.element?.id);
        });

      this.projectQuantityEstimationService.assumedQuantities.pipe(first()).subscribe((assumedQuantities) => {
        if (assumedQuantities) {
          const qtoId = assumedQuantities.quantityTakeOffId;
          this.router.navigate(['..', 'estimations', qtoId, 'positions'], {
            relativeTo: this.route,
            skipLocationChange: true,
            queryParams: {
              // When opening a QTO, the default is to open the last opened position in this QTO.
              // However, since we want to open the position that is currently selected in this calculation
              // view, we want to skip the default behaviour
              ignoreLoadingLastQtoLocation: true
            }
          });
        }
      });
    }
  }

  tryEnterPositionQuantityCalculation(): void {
    if (this.currentProjectHasQuantityEstimation) {
      // When we switch to the position QTO view, we exit the tree, and therefore
      // the selected element is cleared. However, we want to ensure that the
      // current position is selected once we have switched, so we select it again.
      this.selectedSpecificationElementMessengerService.selectedElement
        .pipe(
          filter((e) => e == null),
          first()
        )
        .subscribe(() => {
          this.selectedSpecificationElementMessengerService.trySelectElementById(this.element?.id);
        });
      this.router.navigate(['..', 'estimations', this.projectQuantityEstimationService.quantityEstimationId, 'positions'], {
        relativeTo: this.route,
        skipLocationChange: true,
        queryParams: {
          // When opening a QTO, the default is to open the last opened position in this QTO.
          // However, since we want to open the position that is currently selected in this calculation
          // view, we want to skip the default behaviour
          ignoreLoadingLastQtoLocation: true
        }
      });
    }
  }

  onDragEnd(sizes: IOutputAreaSizes): void {
    this.tryChangeHeightCalc();
    setStorage<number[]>('AS_SPLIT_POSITION_DIVIDER', sizes as number[]);
    this.detailTableModalService.startChangePosition();
  }

  tryStopPropagation(event: KeyboardEvent): void {
    if (event.key !== 'F7') {
      event.stopPropagation();
    }
  }
}
