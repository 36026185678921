import { AsyncPipe, DecimalPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AvaProjectGet, AvaProjectsClient } from 'app/generated-client/generated-client';
import { AvaProjectsFlexibleService } from 'app/shared/services/lightquery/ava-projects-flexible.service';

import { UpperPaginatorComponent } from '../../../../../../shared/components/upper-paginator/upper-paginator.component';
import { AvaProjectTypePipe } from '../../../../../../shared/pipes/ui-data-display/ava-project-type.pipe';

import { PaginationResult } from 'ng-lightquery';

@Component({
  selector: 'pa-service-specifications-table-flexible',
  templateUrl: './service-specifications-table-flexible.component.html',
  styleUrls: ['./service-specifications-table-flexible.component.scss'],
  standalone: true,
  imports: [
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    MatRipple,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    UpperPaginatorComponent,
    AsyncPipe,
    DecimalPipe,
    DatePipe,
    AvaProjectTypePipe
  ]
})
export class ServiceSpecificationsTableFlexibleComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) private sort: MatSort;
  @Input() projectId: string;
  @Output() selectRow = new EventEmitter<AvaProjectGet>();
  pageSizeOptions: number[];
  displayedColumns: string[];
  totalPrices: { [avaProjectId: string]: { totalPrice: number; currency: string } } = {};
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    public avaProjectsService: AvaProjectsFlexibleService,
    private avaProjectsClient: AvaProjectsClient
  ) {}

  ngOnInit(): void {
    this.avaProjectsService.start(this.projectId);
    this.avaProjectsService.initService({
      sorting: { propertyName: 'modifiedAtUtc', isDescending: true },
      sortTableObj: this.sort
    });
    this.pageSizeOptions = [5];
    this.displayedColumns = ['name', 'modifiedAtUtc', 'servSpecType', 'totalPrice'];

    this.avaProjectsService.paginationResult.pipe(takeUntil(this.$destroy)).subscribe((r: PaginationResult<AvaProjectGet>) => {
      if (r.totalCount === 1 && this.projectId === r.data[0].projectId) {
        setTimeout(() => {
          this.selectAvaProject(r.data[0]);
        }, 1);
      } else {
        r.data.forEach((ap) => this.getTotalPrice(ap));
      }
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  selectAvaProject(avaProject: AvaProjectGet): void {
    this.selectRow.emit(avaProject);
  }

  getTotalPrice(row: AvaProjectGet): void {
    if (this.totalPrices[row.id] == null) {
      this.avaProjectsClient.getAvaProjectTotalPriceById(row.projectId, row.id).subscribe((totals) => {
        this.totalPrices[row.id] = {
          totalPrice: totals.totalPrice,
          currency: totals.currency
        };
      });
    }
  }
}
