<div class="wrapper">
  <h1>Einstellungen</h1>
  <nav mat-tab-nav-bar [tabPanel]="tabPanel">
    <a
      mat-tab-link
      #rla="routerLinkActive"
      *ngFor="let tab of tabs; let i = index"
      [routerLink]="tab.path"
      routerLinkActive="active"
      [active]="rla.isActive"
      >{{ tab.viewValue }}
    </a>
  </nav>
  <router-outlet #tabPanel></router-outlet>
</div>
