import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SaveAdditionGroupService {
  private saveAdditionGroupSource = new Subject<boolean>();
  saveAdditionGroup$ = this.saveAdditionGroupSource.asObservable();

  constructor() {}
  saveAdditionGroup(isSave: boolean): void {
    this.saveAdditionGroupSource.next(isSave);
  }
}
