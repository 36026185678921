import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TelemetryService } from '../services/telemetry.service';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ErrorsInterceptor implements HttpInterceptor {
  constructor(private telemetryService: TelemetryService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (error.error && error.error instanceof Blob) {
          (<Blob>error.error)
            .text()
            .then(errorText => {
              try {
                console.error(errorText);
                this.telemetryService.logError(error, errorText);
              } catch {
                this.telemetryService.logError(error);
              }
            })
            .catch(() => {
              this.telemetryService.logError(error);
            });
        } else {
          this.telemetryService.logError(error);
        }
        return throwError(error);
      })
    );
  }
}
