import { NgClass, NgIf } from '@angular/common';
import { Component, Inject, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';

import { BimFilesQuantitiesClient } from '../../../../../../generated-client/generated-client';
import { AvaNotificationsService } from '../../../../../../shared/services/ava-notifications.service';

@Component({
  selector: 'pa-create-bim-qto',
  templateUrl: './create-bim-qto.component.html',
  styleUrls: ['./create-bim-qto.component.scss'],
  standalone: true,
  imports: [NgClass, MatFormField, MatLabel, MatInput, FormsModule, MatButton, NgIf, MatProgressSpinner]
})
export class CreateBimQtoComponent {
  constructor(
    @Optional() private dialogRef: MatDialogRef<CreateBimQtoComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { projectId: string; avaProjectId: string; bimFileId: string },
    private bimFilesQuantitiesClient: BimFilesQuantitiesClient,
    private notificationsService: AvaNotificationsService,
    private router: Router
  ) {}

  name = '';
  loading = false;

  cancel(): void {
    this.dialogRef.close();
  }

  createQto(): void {
    this.loading = true;
    this.bimFilesQuantitiesClient
      .createQuantityTakeOffEstimationForBimFile(this.data.projectId, this.data.avaProjectId, this.data.bimFileId, {
        name: this.name
      })
      .subscribe({
        next: (qto) => {
          this.loading = false;
          this.notificationsService.success('Mengenermittlung erstellt');
          this.dialogRef.close();
          this.router.navigate(
            ['projects', this.data.projectId, 'service-specifications', this.data.avaProjectId, 'estimations', qto.id, 'positions'],
            {
              relativeTo: this.router.routerState.root,
              queryParams: { ignoreLoadingLastQtoLocation: true }
            }
          );
        },
        error: () => {
          this.notificationsService.error('Fehler beim Erstellen der Mengenermittlung');
          this.loading = false;
        }
      });
  }
}
