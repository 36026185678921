import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { LocalProjectsService } from '../services/local-projects.service';

@Injectable()
export class LocalProjectIdInterceptor implements HttpInterceptor {
  constructor(private localProjectsService: LocalProjectsService) {}

  private routeSegmentsWhichNeedLocalProjectLocation = ['/api/files/', '/quantity-sheet/data', '/pages/grouped', '/api/reports/'];

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.localProjectsService.activeProjectFilePath && this.requestMatchesRouteSegmentsWhichNeedLocalProjectLocation(request)) {
      const newRequest = request.clone({
        headers: request.headers.set('LOCAL-PROJECT-LOCATION', this.localProjectsService.activeProjectFilePath)
      });

      return next.handle(newRequest);
    }

    return next.handle(request);
  }

  private requestMatchesRouteSegmentsWhichNeedLocalProjectLocation(request: HttpRequest<unknown>): boolean {
    return this.routeSegmentsWhichNeedLocalProjectLocation.some((routeSegment) => request.url.indexOf(routeSegment) > -1);
  }
}
