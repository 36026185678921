import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ElectronService } from '@shared/services/electron/electron.service';

import { ChooseSubpositionsColorComponent } from '@project/components/project-management/components/choose-subpositions-color/choose-subpositions-color.component';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';
import { UserSettings } from 'app/generated-client/generated-client';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { ModalService } from 'app/shared/services/modal.service';

import { UserSettingsService } from '../../services/user-settings.service';

@Component({
  selector: 'pa-modal-user-settings',
  templateUrl: './modal-user-settings.component.html',
  styleUrls: ['./modal-user-settings.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    NgIf,
    FlexLayoutDirective,
    MatCheckbox,
    FormsModule,
    MatTooltip,
    MatLabel,
    MatSelect,
    MatOption,
    MatButton,
    MatDialogActions,
    MatDialogClose
  ]
})
export class ModalUserSettingsComponent implements OnInit, OnDestroy {
  currentUserSettings: UserSettings;
  isShowSettingForPdfWindow = true;
  private $destroy = new Subject<boolean>();
  private modalService = inject(ModalService);
  isElectron = inject(ElectronService).isElectron;
  constructor(private userSettingsService: UserSettingsService) {}

  ngOnInit(): void {
    if (/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)) {
      this.isShowSettingForPdfWindow = false;
    }

    this.userSettingsService.currentUserSettings.pipe(takeUntil(this.$destroy)).subscribe((setting: UserSettings) => {
      this.currentUserSettings = setting;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  changeSettings(): void {
    this.userSettingsService.setCurrentUserSettings(this.currentUserSettings);
  }

  openSubPositionColorSettings(): void {
    this.modalService
      .openModal(ChooseSubpositionsColorComponent, {
        dialogType: ConfirmationType.General
      })
      .afterClosed();
  }
}
