import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAnchor } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';

import { Subject } from 'rxjs';

import { AvaNotificationsService } from '@shared/services/ava-notifications.service';
import { ElectronService } from '@shared/services/electron/electron.service';
import { FileSaverService } from '@shared/services/file-saver.service';

import { AvaAppFileGet, FilesClient } from 'app/generated-client/generated-client';
import { FilesService } from 'app/shared/services/lightquery/files.service';

import { getAppConfig } from '../../../../app-config-accessor';
import { UpperPaginatorComponent } from '../../../../shared/components/upper-paginator/upper-paginator.component';
import { FileSizePipe } from '../../../../shared/pipes/file-size.pipe';
import { LocalProjectsService } from '../../../local-projects/services/local-projects.service';

@Component({
  selector: 'pa-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    MatAnchor,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    UpperPaginatorComponent,
    AsyncPipe,
    DatePipe,
    FileSizePipe
  ]
})
export class FilesComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  backendUrl: string;
  filter = '';
  selectedClientId: string = null;
  private isQtoOnlyMode = getAppConfig().isQtoOnlyMode;
  private $destroy: Subject<boolean> = new Subject<boolean>();
  private fileSaverService = inject(FileSaverService);
  constructor(
    public filesService: FilesService,
    private filesClient: FilesClient,
    private avaNotificationsService: AvaNotificationsService,
    private electronService: ElectronService,
    private localProjectsService: LocalProjectsService
  ) {}

  ngOnInit(): void {
    this.backendUrl = getAppConfig().backendUrl;

    this.filesService.initService({
      filtering: this.filter,
      paging: { page: 1, pageSize: 10 },
      sorting: { propertyName: 'createdAtUtc', isDescending: true },
      sortTableObj: this.sort
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  downloadFile(row: AvaAppFileGet): void {
    if (this.electronService.isElectron) {
      const link = document.createElement('a');
      let queryString = '';
      if (this.isQtoOnlyMode) {
        const filePath = this.localProjectsService.activeProjectFilePath;
        queryString = `?localProjectLocation=${encodeURI(filePath)}`;
      }

      link.href = `${this.backendUrl}/api/files/${row.id}${queryString}`;
      link.click();
      return;
    }
    this.filesClient.downloadFile(row.id).subscribe({
      next: (r) => {
        this.fileSaverService.saveAs(r);
      },
      error: () => {
        this.avaNotificationsService.error('Datei kann nicht abgerufen werden');
      }
    });
  }
}
