<div fxLayoutGap="12px" class="filter">
  <mat-form-field fxFlex="1 0">
    <mat-label>Suche</mat-label>
    <input matInput [(ngModel)]="filterText" (ngModelChange)="changeFilter()" />
  </mat-form-field>
  <mat-form-field appearance="fill" class="filter-firma">
    <mat-label>Firma</mat-label>
    <mat-select name="company" [(ngModel)]="filterByCompanyId" (ngModelChange)="changeFilter()">
      <mat-option [value]="''">Alle</mat-option>
      <mat-option *ngFor="let item of listCompany" [value]="item.id">{{ item.name }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="mat-elevation-z8 wrapper">
  <table mat-table [dataSource]="textTemplatesService" matSort (matSortChange)="textTemplatesService.onSort($event)">
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="companyName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Firma</th>
      <td mat-cell *matCellDef="let element">{{ element.companyName }}</td>
    </ng-container>

    <ng-container matColumnDef="templateType">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Typ</th>
      <td mat-cell *matCellDef="let element">{{ element.templateType | textTemplateType }}</td>
    </ng-container>

    <ng-container matColumnDef="default">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Standard</th>
      <td mat-cell *matCellDef="let element">{{ element.isDefault ? '✔' : '' }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" class="master-table-tr" (click)="selectItem(row)"></tr>
  </table>
  <pa-upper-paginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    [upperPaginated]="textTemplatesPaginated"
    [upperService]="textTemplatesService"
    (page)="textTemplatesService.onPage($event)"
  >
  </pa-upper-paginator>
</div>
