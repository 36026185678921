<h1>Projekt Blockiert</h1>
<p>Du wurdest aus dem Projekt entfernt, weil ein anderer Benutzer diesen Bereich gerade bearbeiten möchte.</p>
<p>
  Diese Funktion wird dazu genutzt, um Blockierungen aufheben zu können, falls ein Benutzer inaktiv ist und vergessen hat aus dem Projekt zu
  gehen.
</p>
<div *ngIf="data.userInfo">
  <p>
    Du wurdest von Benutzer <strong>{{ data.userInfo.username }}</strong> aus dem Projekt entfernt.
  </p>
</div>
<button mat-raised-button color="primary" (click)="close()">Ok</button>
