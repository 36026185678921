import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { PersonGet, PersonsClient } from 'app/generated-client/generated-client';

import { AvaNotificationsService } from '../../../../shared/services/ava-notifications.service';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { ModalService } from 'app/shared/services/modal.service';
import { PersonsFormComponent } from '../persons-form/persons-form.component';
import { PersonsService } from 'app/shared/services/lightquery/persons.service';
import { SelectedContactMessengerService } from '../../services/selected-contact-messenger.service';
import { UpperPaginatorComponent } from 'app/shared/components/upper-paginator/upper-paginator.component';

@Component({
  selector: 'pa-contact-persons',
  templateUrl: './contact-persons.component.html',
  styleUrls: ['./contact-persons.component.scss'],
  standalone: true,
  imports: [CommonModule, MatTableModule, MatSortModule, MatIconModule, MatButtonModule, UpperPaginatorComponent]
})
export class ContactPersonsComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() readOnly: boolean;
  totalCount: number;
  displayColumn = ['name', 'notes', 'delete'];
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private modalService: ModalService,
    private personsClient: PersonsClient,
    public personsService: PersonsService,
    private selectedContactMessengerService: SelectedContactMessengerService,
    private avaNotificationsService: AvaNotificationsService
  ) {}

  ngOnInit(): void {
    if (this.readOnly) {
      this.displayColumn = this.displayColumn.filter(item => item !== 'delete');
      this.personsService.paginationResult.pipe(takeUntil(this.$destroy)).subscribe(result => {
        this.totalCount = result.totalCount;
      });
    }
    this.personsService.initService({
      sortTableObj: this.sort
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  confirmDeletion(event: MouseEvent, person: PersonGet): void {
    event.stopPropagation();
    this.modalService
      .openModal(ModalConfirmComponent, {
        dialogType: ConfirmationType.Delete,
        data: ['Löschen', 'Person', `${person.name} (${person.notes})`],
        autoFocus: false
      })
      .afterClosed()
      .subscribe(res => {
        if (res === true) {
          this.deletePerson(person.id);
        }
      });
  }

  private deletePerson(personId: string): void {
    this.selectedContactMessengerService.selectedContact.pipe(take(1)).subscribe(contact => {
      this.personsClient.removePersonFromContact(contact.id, personId).subscribe(
        () => {
          this.personsService.forceRefresh();
        },
        error => {
          this.avaNotificationsService.error('Fehler beim Löschen');
        }
      );
    });
  }

  person(row?: PersonGet): void {
    this.modalService.openModal(PersonsFormComponent, { dialogType: ConfirmationType.General, data: row?.id, restoreFocus: false });
  }
}
