import { Directive, HostListener } from '@angular/core';

import { AvaNotificationsService } from '../services/ava-notifications.service';

@Directive({
  selector: '[paFormulaWarning]',
  standalone: true
})
export class FormulaWarningDirective {
  constructor(private avaNotificationsService: AvaNotificationsService) {}
  @HostListener('input', ['$event'])
  change($event: any) {
    const item = $event.target;
    const value = item.value;
    if (typeof value === 'string' && (value.includes('--') || value.includes('//'))) {
      if (value.includes('--')) {
        this.avaNotificationsService.warning('Diese Formel enthält ein Doppelminus "--", bitte prüfen ob beabsichtigt.', 'Achtung');
      } else if (value.includes('//')) {
        this.avaNotificationsService.warning('Diese Formel enthält ein Doppelgeteilt "//", bitte prüfen ob beabsichtigt.', 'Achtung');
      }
    }
  }
}
