import { IElementDto, ServiceSpecificationDto, ServiceSpecificationGroupDto } from './../../../generated-client/generated-client';

export function getElementDtoById(id: string, elements: IElementDto[]): IElementDto {
  let result = elements.find(e => e.id === id);
  if (result) {
    return result;
  }

  for (let i = 0; i < elements.length; i++) {
    if (elements[i].elementType === 'ServiceSpecificationGroupDto') {
      result = getElementDtoById(id, (<ServiceSpecificationGroupDto>elements[i]).elements);
    }

    if (result) {
      return result;
    }
  }
}

export function getParentNode(elementId: string, container: ServiceSpecificationDto): IElementDto {
  if (container.elements.some(e => e.id === elementId)) {
    return container as IElementDto;
  }

  for (let i = 0; i < container.elements.length; i++) {
    if (container.elements[i].elementType === 'ServiceSpecificationGroupDto') {
      const childResult = getParentNode(elementId, container.elements[i]);
      if (childResult) {
        return childResult;
      }
    }
  }
}
