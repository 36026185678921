<div class="wrapper" fxFlexFill>
  <as-split direction="horizontal" fxFlexFill #asBimSplitRef (dragEnd)="dragEnd($event)">
    <as-split-area [size]="20" [order]="1" *ngIf="!isHideLeftSide">
      <pa-left-side-bim-view [leftSideAreaSize]="leftSideAreaSize" *ngIf="bimFile" [bimFile]="bimFile"></pa-left-side-bim-view>
    </as-split-area>
    <as-split-area class="model" [size]="60" [order]="2">
      <pa-model-bim-view *ngIf="bimFile" [bimFile]="bimFile"></pa-model-bim-view>
      <div class="btn btn-left">
        <button mat-mini-fab color="primary" (click)="toggleLeftSide()">
          <mat-icon>{{ !isHideLeftSide ? 'keyboard_arrow_left' : 'keyboard_arrow_right' }}</mat-icon>
        </button>
      </div>
      <div class="btn btn-right">
        <button mat-mini-fab color="primary" (click)="toggleRightSide()">
          <mat-icon>{{ !isHideRightSide ? 'keyboard_arrow_right' : 'keyboard_arrow_left' }}</mat-icon>
        </button>
      </div>
    </as-split-area>
    <as-split-area [size]="20" [order]="3" *ngIf="!isHideRightSide">
      <pa-right-side-bim-view></pa-right-side-bim-view>
    </as-split-area>
  </as-split>
</div>
