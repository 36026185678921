<input
  type="color"
  class="color-input"
  (change)="addColor($event)"
  (keyup.escape)="onBlurColor()"
  [value]="currentColore || '#000000'"
  list="presetColors"
/>
<datalist id="presetColors">
  <option *ngFor="let color of colors">{{ color }}</option>
</datalist>
