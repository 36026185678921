import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PriceComponentType } from 'app/generated-client/generated-client';

import { SpecificProjectProductsTableComponent } from '../specific-project-products-table/specific-project-products-table.component';

@Component({
  selector: 'pa-specific-product',
  templateUrl: './specific-product.component.html',
  styleUrls: ['./specific-product.component.scss'],
  standalone: true,
  imports: [SpecificProjectProductsTableComponent]
})
export class SpecificProductComponent implements OnInit, OnDestroy {
  priceComponentType: PriceComponentType;

  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.$destroy)).subscribe((params) => {
      const { product } = params;
      this.priceComponentType = PriceComponentType[product];
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }
}
