<h2 *ngIf="address && address['id']">Adresse Bearbeiten</h2>
<h2 *ngIf="address && !address['id']">Adresse Erstellen</h2>
<form class="address-form" [formGroup]="addressForm" *ngIf="address" (ngSubmit)="saveAddress()">
  <mat-form-field appearance="fill">
    <mat-label>Straße</mat-label>
    <input matInput type="text" name="street" formControlName="street" />
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Postleitzahl</mat-label>
    <input matInput type="text" name="zipCode" formControlName="zipCode" />
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Ort</mat-label>
    <input matInput type="text" name="city" formControlName="city" />
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Ländercode</mat-label>
    <mat-select name="countryCode" formControlName="countryCode" (ngModelChange)="updateCountryCode($event)">
      <mat-option *ngFor="let code of countryCodes" [value]="code.countryCode">
        <ng-container *ngIf="!code.countryCode">
          <i>Leer</i>
        </ng-container>
        <ng-container *ngIf="code.countryCode">
          {{ code.countryCode }}
        </ng-container>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Land</mat-label>
    <input matInput type="text" name="country" formControlName="country" />
  </mat-form-field>

  <mat-checkbox name="isPrimaryAddress" formControlName="isPrimaryAddress">Hauptadresse</mat-checkbox>
  <mat-checkbox name="isDefaultInvoiceAddress" formControlName="isDefaultInvoiceAddress">Rechnungsadresse </mat-checkbox>
  <div class="button-actions">
    <button
      mat-button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="addressForm.invalid || (!address.country && !address.city && !address.zipCode && !address.street) || requestEnRoute"
    >
      Speichern
    </button>
    <button mat-button mat-raised-button color="warn" type="button" (click)="dismiss()">Abbrechen</button>
  </div>
</form>
