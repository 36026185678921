<h1>Gruppe <span class="mode">Bearbeitungsmodus</span></h1>
<ng-container *ngIf="elementChanged">
  <pa-item-number-editing
    label="OZ"
    [itemNumber]="elementChanged.itemNumber"
    (itemNumberChange)="onItemNumberChanged($event)"
  ></pa-item-number-editing>
  <input class="changing broad" type="text" [(ngModel)]="elementChanged.shortText" (ngModelChange)="changeElement()" />

  <ng-container *ngIf="isEditableAll">
    <div fxLayoutGap="12px">
      <mat-form-field appearance="fill" class="width200">
        <mat-label>Beauftragung</mat-label>
        <mat-select [(ngModel)]="elementChanged.comissionStatus" (ngModelChange)="changeElement()">
          <mat-option [value]="''"></mat-option>
          <mat-option *ngFor="let comissionStatusItem of comissionStatusList" [value]="comissionStatusItem">
            {{ comissionStatusItem | comissionStatus }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" class="width200">
        <mat-label>Preistyp</mat-label>
        <mat-select [(ngModel)]="elementChanged.priceType" (ngModelChange)="changeElement()">
          <mat-option *ngFor="let priceType of priceTypes" [value]="priceType">
            {{ priceType | priceType }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row wrap" fxLayoutGap="12px">
      <mat-form-field appearance="fill">
        <mat-label>GP nach Nachlass</mat-label>
        <input matInput type="number" [ngModel]="elementChanged.deductedPrice" disabled />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>GP</mat-label>
        <input matInput type="number" [ngModel]="elementChanged.totalPrice" disabled />
      </mat-form-field>
    </div>
    <div fxLayout="row wrap" fxLayoutGap="12px">
      <mat-form-field appearance="fill">
        <mat-label>GP Brutto</mat-label>
        <input matInput type="number" [ngModel]="elementChanged.totalPriceGross" disabled />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>GP Brutto nach Nachlass</mat-label>
        <input matInput type="number" [ngModel]="elementChanged.totalPriceGrossDeducted" disabled />
      </mat-form-field>
    </div>

    <div fxLayoutGap="12px">
      <mat-form-field appearance="fill" class="width200">
        <mat-label>Projekt MwST Satz</mat-label>
        <input matInput type="number" [(ngModel)]="elementChanged.projectTaxRate" (ngModelChange)="changeElement()" />
        <span matSuffix>%</span>
      </mat-form-field>
      <mat-form-field appearance="fill" class="width200">
        <mat-label>Nachlass</mat-label>
        <input matInput type="number" [(ngModel)]="elementChanged.deductionFactor" (ngModelChange)="changeElement()" />
        <span matSuffix>%</span>
      </mat-form-field>
    </div>
  </ng-container>
</ng-container>

<div fxLayout="row" fxLayoutGap="12px" class="buttons">
  <button mat-raised-button color="primary" [disabled]="!isChanged" (click)="exit(true)">Speichern</button>
  <button mat-raised-button color="warn" (click)="exit()">Abbrechen</button>
</div>
