import { FlatTreeControl } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { Component, Input, inject, type OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule } from '@angular/material/tree';
import { HelpWindowScrollService } from '@shared/components/help-window-modal/services/help-window-scroll.service';
import { IHelpInformation } from '@shared/components/help-window-modal/services/help-window.service';
import { HoverEffectDirective } from 'app/areas/tree/directives/hover-effect.directive';

interface HelpFlatTreeNode extends IHelpInformation {
  expandable: boolean;
  level: number;
}

@Component({
  selector: 'pa-help-window-tree-structure',
  standalone: true,
  imports: [CommonModule, MatTreeModule, MatButtonModule, MatIconModule, HoverEffectDirective],
  templateUrl: './help-window-tree-structure.component.html',
  styleUrls: ['./help-window-tree-structure.component.scss']
})
export class HelpWindowTreeStructureComponent implements OnInit {
  @Input() treeStructureData: IHelpInformation[];

  helpWindowScrollService = inject(HelpWindowScrollService);

  private _transformer = (node: IHelpInformation, level: number): HelpFlatTreeNode => {
    return {
      id: node.id,
      expandable: !!node.children && node.children.length > 0,
      title: node.title,
      htmlContent: node.htmlContent,
      level: level
    };
  };

  treeControl = new FlatTreeControl<HelpFlatTreeNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: HelpFlatTreeNode) => node.expandable;

  constructor() {}

  ngOnInit(): void {
    if (this.treeStructureData && this.treeStructureData.length) {
      this.dataSource.data = this.treeStructureData;
    }
  }

  onTreeNodeClick(node: HelpFlatTreeNode): void {
    if (!this.treeControl.isExpanded(node)) {
      this.treeControl.expand(node);
    }

    this.helpWindowScrollService.setScrollElementId(node.id);
  }
}
