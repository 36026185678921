import { Injectable } from '@angular/core';

import { ReplaySubject } from 'rxjs';

import { ProjectGet } from 'app/generated-client/generated-client';

@Injectable({
  providedIn: 'root'
})
export class SelectedProjectMessengerService {
  private selectedProjectSource = new ReplaySubject<ProjectGet>(1);
  selectedProject = this.selectedProjectSource.asObservable();

  private _hasSetAnyProject = false;
  get hasSetAnyProject(): boolean {
    return this._hasSetAnyProject;
  }

  constructor() {}

  setSelectedProject(selectedProject: ProjectGet): void {
    this._hasSetAnyProject = true;
    this.selectedProjectSource.next(selectedProject);
  }
}
