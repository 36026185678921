import { Injectable, NgZone } from '@angular/core';

import { ReplaySubject, Subject } from 'rxjs';

import { ElectronService } from './electron.service';

@Injectable({
  providedIn: 'root'
})
export class LongTextViewMessengerService {
  private longTextViewVisibleSource = new ReplaySubject<boolean>(1);
  longTextViewVisible = this.longTextViewVisibleSource.asObservable();

  private dataToLongTextViewSource = new ReplaySubject<any>(1);
  dataToLongTextView = this.dataToLongTextViewSource.asObservable();

  private longTextFromLongTextViewSource = new ReplaySubject<string>(1);
  longTextFromLongTextView = this.longTextFromLongTextViewSource.asObservable();

  private commandToConfirmSource = new Subject<boolean>();
  commandToConfirm = this.commandToConfirmSource.asObservable();

  constructor(private electronService: ElectronService, ngZone: NgZone) {
    if (electronService.isElectron) {
      electronService.ipcRenderer.on('LongTextViewWindowVisible', () => ngZone.run(() => this.longTextViewVisibleSource.next(true)));

      electronService.ipcRenderer.on('LongTextViewWindowClosed', () => ngZone.run(() => this.longTextViewVisibleSource.next(false)));

      electronService.ipcRenderer.on('ConfirmToSave', () =>
        ngZone.run(() => {
          this.commandToConfirmSource.next(true);
        })
      );

      electronService.ipcRenderer.on('DataToLongTextView', (_, data: any) => ngZone.run(() => this.dataToLongTextViewSource.next(data)));

      electronService.ipcRenderer.on('LongTextFromLongTextView', (_, data: string) => {
        ngZone.run(() => this.longTextFromLongTextViewSource.next(data));
      });
    }
  }

  showLongTextViewWindow(): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('ShowLongTextViewWindow');
    }
  }

  setOnTopLongTextView(alwaysOnTop: boolean): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('LongTextViewWindowOnTop', alwaysOnTop);
    }
  }

  sendDataToLongTextView(data: any): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('SendDataToLongTextView', data);
    }
    this.dataToLongTextViewSource.next(data);
  }

  sendLongTextFromLongTextView(newLongText: string): void {
    this.electronService.ipcRenderer.send('SendLongTextFromLongTextView', newLongText);
  }

  closeLongTextViewWindow(): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('CloseLongTextViewWindow');
    }
  }

  forceClose(): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('SendCommandToClose');
    }
  }

  switchValueChanged(value: boolean): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('SwitchValueChanged', value);
    }
  }
}
