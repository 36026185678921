import { NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, HostListener, Input, NgZone, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';

import { TinyMceComponent } from '@dangl/angular-material-shared/tiny-mce';

import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AvaProjectContentEditClient, AvaProjectContentEditOperation, IElementDto } from 'app/generated-client/generated-client';
import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { LongTextViewMessengerService } from 'app/shared/services/electron/long-text-view-messenger.service';
import { LocalStorageViewService } from 'app/shared/services/local-storage-view.service';
import { ModalService } from 'app/shared/services/modal.service';

import { GeneralEquipmentComponent } from '../../../../shared/components/general-equipment/general-equipment.component';
import { LongTextComponent } from '../../../../shared/components/long-text/long-text.component';
import { FlexLayoutDirective } from '../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-long-text-window',
  templateUrl: './long-text-window.component.html',
  styleUrls: ['./long-text-window.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    GeneralEquipmentComponent,
    NgTemplateOutlet,
    FlexLayoutDirective,
    MatButton,
    LongTextComponent,
    TinyMceComponent,
    FormsModule
  ]
})
export class LongTextWindowComponent implements OnInit {
  @Input() noSeparate: boolean;
  @HostListener('document:keyup', ['$event']) handleMouseUpEvent(event: KeyboardEvent): void {
    if (event.key === 'F9') {
      this.closeLongTextWindow();
    }
  }
  htmlLongText: string;
  backHtmlLongText: string;
  modeEquipment = 'longText';
  addition: string[] = [];
  currentElement: IElementDto;
  editMode: boolean;
  isChanged: boolean;
  private $destroy: Subject<boolean> = new Subject();

  constructor(
    private longTextViewMessengerService: LongTextViewMessengerService,
    private localStorageViewService: LocalStorageViewService,
    private modalService: ModalService,
    private avaProjectContentEditClient: AvaProjectContentEditClient,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.longTextViewMessengerService.dataToLongTextView.pipe(takeUntil(this.$destroy)).subscribe((getData: any) => {
      if (this.isChanged && this.currentElement && this.canHaveLongText(this.currentElement)) {
        this.modalService
          .openModal(ModalConfirmComponent, { dialogType: ConfirmationType.General, data: ['Save', 'LongText'] })
          .afterClosed()
          .subscribe((isSave) => {
            if (isSave) {
              this.saveChangesLongText(this.currentElement, this.addition, this.htmlLongText);
            }
            this.updateData(getData);
          });
      } else {
        this.updateData(getData);
      }
    });

    this.longTextViewMessengerService.commandToConfirm.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.modalService
        .openModal(ModalConfirmComponent, { dialogType: ConfirmationType.General, data: ['Save', 'LongText'] })
        .afterClosed()
        .subscribe((isSave) => {
          if (isSave) {
            this.saveChanges();
          }
          this.longTextViewMessengerService.switchValueChanged(false);
          setTimeout(() => {
            this.longTextViewMessengerService.forceClose();
          }, 100);
        });
    });

    if (!this.noSeparate) {
      this.settingView();
      this.ngZone.runOutsideAngular(() => {
        fromEvent(document, 'dragstart')
          .pipe(takeUntil(this.$destroy))
          .subscribe((event: DragEvent) => {
            event.preventDefault();
          });
      });
    }
  }

  private settingView(): void {
    const { alwaysOnTop } = this.localStorageViewService.getSettingView(this.modeEquipment);
    this.longTextViewMessengerService.setOnTopLongTextView(alwaysOnTop);
  }

  changedLongText(newLongText: string): void {
    this.longTextViewMessengerService.sendLongTextFromLongTextView(newLongText);
    this.saveChangesLongText(this.currentElement, this.addition, newLongText);
  }

  updateData({ element, texts }): void {
    let projectId: string, avaProjectId: string, elementId: string;
    [projectId, avaProjectId, elementId, this.htmlLongText] = texts;
    this.addition = [projectId, avaProjectId, elementId];
    this.currentElement = element;
    this.isChanged = false;
    this.editMode = false;
    this.longTextViewMessengerService.switchValueChanged(this.isChanged);
  }

  changeEditMode(): void {
    this.editMode = !this.editMode;
    if (this.editMode) {
      this.backHtmlLongText = this.htmlLongText;
    } else {
      this.htmlLongText = this.backHtmlLongText;
      this.isChanged = false;
      this.longTextViewMessengerService.switchValueChanged(this.isChanged);
    }
  }

  saveChanges(): void {
    this.changedLongText(this.htmlLongText);
    this.isChanged = false;
    this.editMode = false;
    this.longTextViewMessengerService.switchValueChanged(this.isChanged);
  }

  changedTinyMce(event) {
    if (event !== this.backHtmlLongText) {
      this.isChanged = true;
      this.longTextViewMessengerService.switchValueChanged(this.isChanged);
    }
  }

  saveChangesLongText(currentElement: IElementDto, addition: string[], htmlLongText: string): void {
    currentElement['htmlLongText'] = htmlLongText;
    const [projectId, avaProjectId] = addition;
    this.avaProjectContentEditClient
      .editAvaProjectContent(projectId, avaProjectId, {
        operation: AvaProjectContentEditOperation.Edit,
        updateOperation: {
          element: currentElement
        }
      })
      .subscribe(() => {});
  }

  canHaveLongText(currentElement: IElementDto): boolean {
    return currentElement?.elementType === 'NoteTextDto' || currentElement?.elementType === 'PositionDto';
  }

  closeLongTextWindow() {
    this.longTextViewMessengerService.showLongTextViewWindow();
  }
}
