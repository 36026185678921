import { Component, Inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';

import { PositionDto } from '../../../../generated-client/generated-client';

@Component({
  selector: 'pa-position-selection',
  templateUrl: './position-selection.component.html',
  styleUrls: ['./position-selection.component.scss'],
  standalone: true,
  imports: [
    MatDialogContent,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatDialogActions,
    MatButton
  ]
})
export class PositionSelectionComponent {
  constructor(
    private matDialogRef: MatDialogRef<PositionSelectionComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      positions: PositionDto[];
      header: string;
      text: string;
    }
  ) {}

  selectPosition(position: PositionDto) {
    this.matDialogRef.close(position);
  }

  cancel(): void {
    this.matDialogRef.close();
  }
}
