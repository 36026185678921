import { NgFor, NgIf, DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  ProjectGet,
  ProjectsClient,
  ProjectStatus,
  ProjectStatusHistoryGet,
  ProjectStatusPut
} from 'app/generated-client/generated-client';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';

import { ProjectStatusPipe } from '../../../../../../shared/pipes/ui-data-display/project-status.pipe';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-project-status',
  templateUrl: './project-status.component.html',
  styleUrls: ['./project-status.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutDirective,
    MatFormField,
    MatLabel,
    MatSelect,
    NgFor,
    MatOption,
    MatCheckbox,
    FormsModule,
    MatInput,
    MatButton,
    NgIf,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    DatePipe,
    ProjectStatusPipe
  ]
})
export class ProjectStatusComponent implements OnInit, OnDestroy {
  listStatus = Object.keys(ProjectStatus).map((ps) => <ProjectStatus>ps);
  newStatus: ProjectStatus = null;
  currentStatus: ProjectStatus;
  projectId: string;
  comment: string;
  dataSource: ProjectStatusHistoryGet[];
  columns: string[] = ['createdAtUtc', 'oldStatus', 'newStatus', 'userName', 'comment'];
  @ViewChild('inputComment') commentElement: ElementRef;
  setPricesToLocked = false;
  private $destroy = new Subject<boolean>();

  constructor(
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private projectsClient: ProjectsClient
  ) {}

  ngOnInit(): void {
    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((project: ProjectGet) => {
      this.projectId = project?.id;
      this.currentStatus = project?.status;
      if (this.projectId) {
        this.getHistory();
      }
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private getHistory(): void {
    this.projectsClient.getProjectStatusHistory(this.projectId).subscribe((history: ProjectStatusHistoryGet[]) => {
      this.dataSource = history;
    });
  }

  saveStatus(): void {
    const model: ProjectStatusPut = {
      projectId: this.projectId,
      status: this.newStatus,
      comment: this.comment,
      setPricesToLocked: this.setPricesToLocked
    };
    this.projectsClient.setStatus(this.projectId, model).subscribe(() => {
      this.changeProject();
    });
  }

  private changeProject(): void {
    this.projectsClient.getProjectById(this.projectId).subscribe((project: ProjectGet) => {
      this.selectedProjectMessengerService.setSelectedProject(project);
      setTimeout(() => {
        this.comment = '';
        this.newStatus = null;
      }, 1);
    });
  }

  moveFocus(): void {
    setTimeout(() => {
      this.commentElement.nativeElement.focus();
    }, 100);
  }
}
