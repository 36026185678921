import { Injectable, OnDestroy } from '@angular/core';

import { ReplaySubject, Subject, combineLatest, filter, first, switchMap, takeUntil } from 'rxjs';

import {
  AvaProjectAssumedQuantitiesGet,
  AvaProjectsClient,
  QuantityTakeOffType,
  UserSettings
} from 'app/generated-client/generated-client';
import { ShowedTableQuantitiesType } from 'app/shared/models';

import { SelectedProjectMessengerService } from './selected-project-messenger.service';
import { SelectedSpecificationMessengerService } from './selected-specification-messenger.service';

import { AvaProjectsService } from '../lightquery/ava-projects.service';
import { QuantityTakeOffsService } from '../lightquery/quantity-take-offs.service';
import { UserSettingsService } from '../user-settings.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectQuantityEstimationService implements OnDestroy {
  quantityEstimationId: string;
  settings: UserSettings = {};

  private mainShowedTableQuantitiesSource = new ReplaySubject<ShowedTableQuantitiesType | null>(1);
  mainShowedTableQuantities = this.mainShowedTableQuantitiesSource.asObservable();

  private currentProjectHasQuantityCalculationSource = new ReplaySubject<boolean>(1);
  currentProjectHasQuantityCalculation = this.currentProjectHasQuantityCalculationSource.asObservable();

  private currentProjectHasAssumedQuantitiesSource = new ReplaySubject<boolean>(1);
  currentProjectHasAssumedQuantities = this.currentProjectHasAssumedQuantitiesSource.asObservable();

  private showedPositionsEstimationCompactSource = new ReplaySubject<boolean>(1);
  showedPositionsEstimationCompact = this.showedPositionsEstimationCompactSource.asObservable();

  private assumedQuantitiesSource = new ReplaySubject<AvaProjectAssumedQuantitiesGet>(1);
  assumedQuantities = this.assumedQuantitiesSource.asObservable();

  private $destroy: Subject<boolean> = new Subject<boolean>();
  projectId: string;
  avaProjectId: string;

  constructor(
    private userSettingsService: UserSettingsService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private avaProjectService: AvaProjectsService,
    private quantityTakeOffsService: QuantityTakeOffsService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private avaProjectsClient: AvaProjectsClient
  ) {
    this.hidePositionsEstimationCompact();
    this.userSettingsService.currentFullSettings.pipe(takeUntil(this.$destroy)).subscribe((settings: UserSettings) => {
      this.settings = settings;
    });

    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        takeUntil(this.$destroy),
        filter((s) => !!s),
        switchMap(() => {
          return this.quantityTakeOffsService.getAll({
            quantityTakeOffType: QuantityTakeOffType.QuantityEstimation
          });
        })
      )
      .subscribe((allQtos) => {
        if (allQtos) {
          this.currentProjectHasAssumedQuantitiesSource.next(allQtos.some((qto) => qto.useAsAssumedQuantities));
          this.refreshAssumedQuantities();
        }
      });

    this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(takeUntil(this.$destroy)).subscribe((e) => {
      this.projectId = e?.parentProjectId;
      this.avaProjectId = e?.avaProjectId;
      this.currentProjectHasQuantityCalculationSource.next(e?.avaProject?.quantityCalculationQuantityTakeOffId != null);
      this.currentProjectHasAssumedQuantitiesSource.next(e?.avaProject?.assumedQuantitiesQuantityTakeOffId != null);
      this.quantityEstimationId = e?.avaProject?.quantityCalculationQuantityTakeOffId;

      this.changeMainShowedTable();
    });
  }

  refreshAssumedQuantities(): void {
    combineLatest([
      this.selectedProjectMessengerService.selectedProject,
      this.selectedSpecificationMessengerService.selectedServiceSpecification
    ])
      .pipe(first())
      .subscribe(([project, specification]) => {
        if (project && specification && specification.avaProject.assumedQuantitiesQuantityTakeOffId) {
          this.avaProjectsClient
            .getAssumedQuantitiesForAvaProject(project.id, specification.avaProjectId)
            .subscribe((assumedQuantities) => {
              this.assumedQuantitiesSource.next(assumedQuantities);
            });
        }
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  setHasQuantityCalculationForCurrentProject(isEnabled: boolean, qtoId?: string): void {
    this.currentProjectHasQuantityCalculationSource.next(isEnabled);

    if (this.avaProjectId) {
      const cachedAvaProject = this.avaProjectService.getItemById(this.avaProjectId);
      if (cachedAvaProject) {
        cachedAvaProject.quantityCalculationQuantityTakeOffId = isEnabled ? qtoId : null;
      }
    }

    if (isEnabled) {
      this.quantityEstimationId = qtoId;
    } else {
      this.quantityEstimationId = null;
    }
  }

  setHasAssumedQuantitiesForCurrentProject(isEnabled: boolean): void {
    this.currentProjectHasAssumedQuantitiesSource.next(isEnabled);

    if (isEnabled) {
      this.refreshAssumedQuantities();
    }
  }

  showPositionsEstimationCompact(): void {
    this.showedPositionsEstimationCompactSource.next(true);
  }

  hidePositionsEstimationCompact(): void {
    this.showedPositionsEstimationCompactSource.next(false);
  }

  togglePositionsEstimationCompact(): void {
    this.showedPositionsEstimationCompact.pipe(first()).subscribe((e: boolean) => {
      if (e) {
        this.hidePositionsEstimationCompact();
      } else {
        this.showPositionsEstimationCompact();
      }
    });
  }

  changeMainShowedTable(): void {
    combineLatest([this.currentProjectHasQuantityCalculationSource, this.currentProjectHasAssumedQuantitiesSource])
      .pipe(takeUntil(this.$destroy))
      .subscribe(([HasQuantityCalculation, HasAssumedQuantities]) => {
        if (!HasQuantityCalculation && !HasAssumedQuantities) {
          this.mainShowedTableQuantitiesSource.next(null);
        } else if (HasQuantityCalculation && !HasAssumedQuantities) {
          this.mainShowedTableQuantitiesSource.next(ShowedTableQuantitiesType.CalculatedQuantities);
        } else if (!HasQuantityCalculation && HasAssumedQuantities) {
          this.mainShowedTableQuantitiesSource.next(ShowedTableQuantitiesType.AssumedQuantities);
        } else {
          this.mainShowedTableQuantitiesSource.next(ShowedTableQuantitiesType.CalculatedQuantities);
        }
      });
  }

  setMainShowedTable(value: ShowedTableQuantitiesType): void {
    this.mainShowedTableQuantitiesSource.next(value);
  }
}
