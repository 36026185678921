import {
  AvaProjectContentEditClient,
  AvaProjectContentEditOperation,
  AvaProjectElementType,
  IElementDto,
  NoteTextDto,
  PositionDto,
  PositionTypeDto,
  PriceTypeDto,
  ServiceTypeDto
} from '../../../generated-client/generated-client';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, Subject, combineLatest, map, mergeMap, of, takeUntil } from 'rxjs';

import { ConfirmationType } from '../../../shared/models/dialog-config.model';
import { FlatElementsService } from '../../tree/services/flat-elements.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmComponent } from '../../../shared/components/modal-confirm/modal-confirm.component';
import { ModalService } from '../../../shared/services/modal.service';
import { PositionSelectionComponent } from '../components/position-selection/position-selection.component';
import { SelectedSpecificationMessengerService } from '../../../shared/services/messengers/selected-specification-messenger.service';
import { UiPositionType } from '../../../shared/models/ui-position-type';

@Injectable({
  providedIn: 'root'
})
export class PositionTypeChangeService implements OnDestroy {
  private destroy$ = new Subject<void>();
  private positions: PositionDto[] = [];
  private projectId: string;
  private avaProjectId: string;

  constructor(
    flatElementsService: FlatElementsService,
    private avaProjectContentEditClient: AvaProjectContentEditClient,
    selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private matDialog: MatDialog,
    private modalService: ModalService
  ) {
    flatElementsService.flatElementsDto.pipe(takeUntil(this.destroy$)).subscribe(flatElementsDto => {
      this.positions = flatElementsDto.filter(e => e.elementType === 'PositionDto') as PositionDto[];
    });
    selectedSpecificationMessengerService.selectedServiceSpecification.pipe(takeUntil(this.destroy$)).subscribe(spec => {
      this.projectId = spec?.parentProjectId;
      this.avaProjectId = spec?.avaProjectId;
    });
  }

  public tryChangePositionType(options: {
    currentType: UiPositionType;
    newType: UiPositionType;
    element: IElementDto;
  }): Observable<boolean> {
    // We might have code paths that don't do any
    // async work, so we need to make sure that
    // subscribers have the chance to receive the
    // result before we complete the observable
    // or in cases where the result is announced
    // before a subscription is created.
    const result = new ReplaySubject<boolean>(1);

    const updateObservable =
      options.element.elementType === 'PositionDto'
        ? this.preparePositionForUpdate(options.currentType, options.newType, options.element)
        : this.prepareNoteTextForUpdate(options.currentType, options.newType, options.element);

    updateObservable.subscribe(positionsToUpdate => {
      if (positionsToUpdate && positionsToUpdate.length > 0) {
        combineLatest(
          positionsToUpdate.map(p =>
            this.avaProjectContentEditClient.editAvaProjectContent(this.projectId, this.avaProjectId, {
              operation: AvaProjectContentEditOperation.Edit,
              updateOperation: {
                element: p
              }
            })
          )
        ).subscribe(() => {
          result.next(true);
          result.complete();
        });
      } else if (positionsToUpdate?.length === 0) {
        result.next(true);
        result.complete();
      } else {
        result.next(false);
        result.complete();
      }
    });

    return result;
  }

  private prepareNoteTextForUpdate(
    currentType: UiPositionType,
    newType: UiPositionType,
    noteText: NoteTextDto
  ): Observable<PositionDto[] | null> {
    if (currentType === UiPositionType.NoteText && newType === UiPositionType.Regular) {
      return this.avaProjectContentEditClient
        .editAvaProjectContent(this.projectId, this.avaProjectId, {
          operation: AvaProjectContentEditOperation.ChangeElementType,
          changeElementOperation: {
            elementId: noteText.id,
            targetElementType: AvaProjectElementType.Position
          }
        })
        .pipe(
          map(() => {
            return [];
          })
        );
    }

    return of(null);
  }

  private preparePositionForUpdate(
    currentType: UiPositionType,
    newType: UiPositionType,
    position: PositionDto
  ): Observable<PositionDto[] | null> {
    position = JSON.parse(JSON.stringify(position));

    const positionsToUpdate: PositionDto[] = [];

    if (currentType === UiPositionType.Complementing) {
      const complementedPositions = this.getComplementedPositions(position.id);
      complementedPositions.forEach(p => {
        p = JSON.parse(JSON.stringify(p));
        positionsToUpdate.push(p);
        p.complementedBy = p.complementedBy.filter(id => id !== position.id);
        p.complemented = p.complementedBy.length > 0;
      });
    }

    position.priceType = PriceTypeDto.WithTotal;
    position.positionType = PositionTypeDto.Regular;
    position.serviceType = ServiceTypeDto.Regular;

    switch (newType) {
      case UiPositionType.Alternative:
        return this.selectPositionInDialog('Positionsauswahl', 'Bitte eine Grundposition für die Alternative auswählen').pipe(
          map(basePosition => {
            if (!basePosition) {
              return null;
            } else {
              position.alternativeTo = basePosition.id;
              position.positionType = PositionTypeDto.Alternative;
              position.priceType = PriceTypeDto.WithoutTotal;
              positionsToUpdate.push(position);
              return positionsToUpdate;
            }
          })
        );
      case UiPositionType.Complementing:
        return this.selectPositionInDialog('Positionsauswahl', 'Bitte eine Grundposition für diese Umlageposition auswählen').pipe(
          map(basePosition => {
            if (!basePosition) {
              return null;
            } else {
              basePosition = JSON.parse(JSON.stringify(basePosition));
              basePosition.complemented = true;
              if (!basePosition.complementedBy) {
                basePosition.complementedBy = [];
              }
              basePosition.complementedBy.push(position.id);
              positionsToUpdate.push(basePosition);
              positionsToUpdate.push(position);
              return positionsToUpdate;
            }
          })
        );
      case UiPositionType.Eventual:
        position.priceType = PriceTypeDto.WithoutTotal;
        position.positionType = PositionTypeDto.Optional;
        positionsToUpdate.push(position);
        return of(positionsToUpdate);
      case UiPositionType.HourlyPaidWork:
        position.serviceType = ServiceTypeDto.HourlyPaidWork;
        positionsToUpdate.push(position);
        return of(positionsToUpdate);
      case UiPositionType.Optional:
        position.positionType = PositionTypeDto.Optional;
        positionsToUpdate.push(position);
        return of(positionsToUpdate);
      case UiPositionType.Regular:
        positionsToUpdate.push(position);
        return of(positionsToUpdate);
      case UiPositionType.NoteText:
        return this.modalService
          .openModal(ModalConfirmComponent, {
            dialogType: ConfirmationType.General,
            data: [
              'Typänderung',
              position.itemNumber?.stringRepresentation,
              `Positionsdaten wie Kalkulation und Abrechnung gehen dabei verloren.`
            ],
            autoFocus: false
          })
          .afterClosed()
          .pipe(
            mergeMap((userHasConfirmed: boolean) => {
              if (userHasConfirmed) {
                return this.avaProjectContentEditClient
                  .editAvaProjectContent(this.projectId, this.avaProjectId, {
                    operation: AvaProjectContentEditOperation.ChangeElementType,
                    changeElementOperation: {
                      elementId: position.id,
                      targetElementType: AvaProjectElementType.NoteText
                    }
                  })
                  .pipe(
                    map(() => {
                      return [];
                    })
                  );
              } else {
                return of(null);
              }
            })
          );
    }
    return of(null);
  }

  private selectPositionInDialog(header: string, text: string): Observable<PositionDto | null> {
    return this.matDialog
      .open(PositionSelectionComponent, {
        data: {
          header: header,
          text: text,
          positions: this.positions
        }
      })
      .afterClosed();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getComplementedPositions(positionId: string): PositionDto[] {
    return this.positions.filter(p => p.complementedBy && p.complementedBy.find(id => id === positionId));
  }
}
