import { Component } from '@angular/core';
import { MatDialogTitle, MatDialogContent } from '@angular/material/dialog';

import { CalculationEstimationTableComponent } from '../calculation-estimation-table/calculation-estimation-table.component';

@Component({
  selector: 'pa-calculation-estimation-modal',
  templateUrl: './calculation-estimation-modal.component.html',
  styleUrls: ['./calculation-estimation-modal.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, CalculationEstimationTableComponent]
})
export class CalculationEstimationModalComponent {}
