import { Directive, ElementRef, NgZone, OnDestroy, OnInit } from '@angular/core';

import { Subject, fromEvent, takeUntil } from 'rxjs';

@Directive({
  selector: 'input[allowNumericWithDecimal]',
  standalone: true
})
export class AllowNumericWithDecimalDirective implements OnInit, OnDestroy {
  private $destroy: Subject<boolean> = new Subject<boolean>();

  private regex = new RegExp(/^\d+[.,]?\d*$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef, private ngZone: NgZone) {}

  ngOnInit(): void {
    if (this.ngZone) {
      this.ngZone.runOutsideAngular(() => {
        fromEvent(this.el.nativeElement, 'keydown')
          .pipe(takeUntil(this.$destroy))
          .subscribe((event: KeyboardEvent) => {
            this.onKeyDown(event);
          });
      });
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.includes(event.key)) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
