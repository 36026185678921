<div class="wrapper">
  <h3>Zahlungsbedingungen</h3>
  <table mat-table [dataSource]="dataSource" matSort matSortActive="deadline" matSortDisableClear matSortDirection="asc">
    <ng-container matColumnDef="index">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let idx = index">{{ idx + 1 }}</td>
    </ng-container>
    <ng-container matColumnDef="deadline">
      <th mat-header-cell *matHeaderCellDef>Frist in Tagen</th>
      <td mat-cell *matCellDef="let row">{{ row.deadline }}</td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let row">{{ row.name }}</td>
    </ng-container>
    <ng-container matColumnDef="rate">
      <th mat-header-cell *matHeaderCellDef>Nachlass</th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="row.rate">{{ row.rate | percent: '1.2-2' }}</ng-container>
        <ng-container *ngIf="!row.rate">rein netto</ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="del">
      <th mat-header-cell *matHeaderCellDef class="del"></th>
      <td mat-cell *matCellDef="let row" class="del">
        <button class="del-btn" (click)="delTerm(row)">
          <mat-icon color="warn">close</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>
  </table>
</div>
<div class="buttons">
  <button mat-raised-button (click)="newTerm()">Zahlungsbedingung Hinzufügen</button>
</div>
