// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `index.ts`, but if you do
// `ng build --env=prod` then `index.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const AppConfig = {
  production: false,
  environment: 'DEV',
  backendUrl: 'https://localhost:14347',
  danglIconsBaseUrl: 'https://icons-dev.dangl-it.com',
  applicationInsightsKey: '',
  showConcurrentUsers: true,
  appPrefix: 'Dangl',
  danglDocuProjectName: 'Dangl.AVA.App',
  enableCanalConvertExport: false,
  isWebBuild: true,
  logoInitials: 'DAA',
  isQtoOnlyMode: false
};
