import { Injectable, OnDestroy } from '@angular/core';

import { AuthenticationMessenger } from '@dangl/angular-dangl-identity-client';

import { Observable, ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';

import { ElectronService } from '@shared/services/electron/electron.service';

import {
  ProjectSettings,
  ProjectSettingsClient,
  TreeViewDisplayType,
  UserSettings,
  UserSettingsClient
} from 'app/generated-client/generated-client';

import { SelectedProjectMessengerService } from './messengers/selected-project-messenger.service';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService implements OnDestroy {
  currentUserSettingsSource = new ReplaySubject<UserSettings>(1);
  currentUserSettings = this.currentUserSettingsSource.asObservable();

  private currentFullSettingsSource = new ReplaySubject<UserSettings>(1);
  currentFullSettings = this.currentFullSettingsSource.asObservable();

  projectId: string;

  private $destroy = new Subject<boolean>();

  constructor(
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private userSettingsClient: UserSettingsClient,
    private authenticationMessenger: AuthenticationMessenger,
    private projectSettingsClient: ProjectSettingsClient,
    private electronService: ElectronService
  ) {
    this.authenticationMessenger.isAuthenticated
      .pipe(
        takeUntil(this.$destroy),
        filter((isAuthenticated) => !!isAuthenticated),
        switchMap(() => this.userSettingsClient.getUserSettings())
      )
      .subscribe((setting: UserSettings) => {
        if (this.electronService.isElectron) {
          this.currentUserSettingsSource.next(setting);
        } else {
          setting.showMultiComponentsViewInMainWindow = true;
          this.currentUserSettingsSource.next(setting);
        }

        // We're initializing the full settings once, so that consumers that require it
        // have access right away, even without having a loaded project context before
        this.changeFullSettings();
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
    this.currentFullSettingsSource.complete();
    this.currentUserSettingsSource.complete();
  }

  setCurrentUserSettings(newSetting: UserSettings): void {
    this.userSettingsClient
      .setUserSettings(newSetting)
      .pipe(takeUntil(this.$destroy))
      .subscribe((setting: UserSettings) => {
        this.currentUserSettingsSource.next(setting);
        this.changeFullSettings();
      });
  }

  setProjectSettings(newSetting: ProjectSettings): void {
    this.selectedProjectMessengerService.selectedProject
      .pipe(
        filter((p) => !!p),
        switchMap((selectedProject) => this.projectSettingsClient.setProjectSettings(selectedProject?.id, newSetting)),
        take(1)
      )
      .subscribe(() => {
        this.changeFullSettings();
      });
  }

  changeFullSettings(): void {
    if (this.selectedProjectMessengerService.hasSetAnyProject) {
      this.selectedProjectMessengerService.selectedProject
        .pipe(
          filter((p) => !!p),
          switchMap((selectedProject) =>
            combineLatest([this.currentUserSettings, this.projectSettingsClient.getProjectSettings(selectedProject?.id)])
          ),
          take(1)
        )
        .subscribe(([userSettings, projectSettings]: [UserSettings, ProjectSettings]) => {
          const newFullSettings = {
            ...projectSettings,
            ...userSettings,
            showReferenceNameInQuantityTakeOff:
              projectSettings.showReferenceNameInQuantityTakeOff || userSettings.showReferenceNameInQuantityTakeOff,
            treeViewDisplayType: projectSettings.treeViewDisplayType || TreeViewDisplayType.Tree
          };
          this.currentFullSettingsSource.next(newFullSettings);
        });
    } else {
      // If there was never any project context set before, this means the user doesn't have any active project.
      // This is the case e.g. in the separate CopyCalculationView, where there's never any selected project context.
      // To ensure other calls work when there's no project context but a consumer requests the full settings, we'll
      // just provide the user settings then.
      this.currentUserSettings.pipe(take(1)).subscribe((userSettings) => {
        this.currentFullSettingsSource.next(userSettings);
      });
    }
  }

  getProjectSettings(projectId?: string): Observable<ProjectSettings> {
    if (projectId) {
      return this.projectSettingsClient.getProjectSettings(projectId);
    } else {
      return this.selectedProjectMessengerService.selectedProject.pipe(
        switchMap((selectedProject) => (selectedProject ? this.projectSettingsClient.getProjectSettings(selectedProject?.id) : of(null)))
      );
    }
  }
}
