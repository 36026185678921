<div mat-dialog-content>
  <mat-form-field class="full-width">
    <mat-label>Suche</mat-label>
    <input matInput ngModel (ngModelChange)="onFilterList($event)" />
  </mat-form-field>
  <div class="selection-wrapper">
    <mat-selection-list [multiple]="false" *ngIf="filteredData?.length">
      <mat-list-option *ngFor="let item of filteredData">
        <div>
          <button class="select-button" mat-raised-button color="primary" (click)="selectElement(item.id)">Übernehmen</button>
          {{ item.text }}
        </div>
      </mat-list-option>
    </mat-selection-list>
  </div>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-raised-button mat-dialog-close>Abbrechen</button>
</div>
