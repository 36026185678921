<div #additionalInfo class="additional-info" *ngIf="modePage | includes: 'invoice'">
  <ng-container *ngIf="quantityTakeOffInvoiceTotalsMessengerService.currentInvoiceTotalSum | async as invoiceTotalSum">
    <table
      [ngClass]="{
        'table-display': (isTreeOpen || isToggleTreeWidth) && !isCompact
      }"
      (contextmenu)="showHeaderContextMenu($event)"
    >
      <ng-container *ngIf="!(modePage | includes: 'estimations')">
        <tr *ngIf="showingColumns | includes: 'totalSum'">
          <th matTooltip="Summe Abrechnung">{{ currentInvoiceName }}</th>
          <td>{{ invoiceTotalSum.totalSum | projectCurrency }}</td>
        </tr>

        <ng-container *ngIf="!isCompact">
          <tr
            [ngClass]="{
              red: invoiceTotalSum.serviceSpecificationTotalSum * 1.1 < invoiceTotalSum.allTotalSum,
              green:
                invoiceTotalSum.serviceSpecificationTotalSum * 0.9 <= allTotalSum &&
                invoiceTotalSum.serviceSpecificationTotalSum * 1.1 >= invoiceTotalSum.allTotalSum
            }"
            *ngIf="showingColumns | includes: 'allTotalSum'"
          >
            <th matTooltip="Bisher abgerechnet">Bisher abgerechnet:</th>
            <td>{{ invoiceTotalSum.allTotalSum | projectCurrency }}</td>
          </tr>
          <tr *ngIf="showingColumns | includes: 'serviceSpecificationTotalSum'">
            <th matTooltip="LV-Summe">Σ LV:</th>
            <td>{{ invoiceTotalSum.serviceSpecificationTotalSum | projectCurrency }}</td>
          </tr>

          <tr *ngIf="(modePage | includes: 'positions') && (showingColumns | includes: 'positionTotal')">
            <th matTooltip="Summe Position">Σ Position :</th>
            <td>
              <ng-container *ngIf="selectedElement?.elementType === 'PositionDto'; else positionNone">
                {{ invoiceTotalSum.positionTotalSum | projectCurrency }}
              </ng-container>
            </td>
          </tr>

          <tr
            *ngIf="
              !(modePage | includes: 'estimations') &&
              (showingColumns | includes: 'totalInvoicedPerPosition') &&
              totalQuantityPerPosition !== 0 &&
              (selectedSpecificationElementMessengerService.selectedElement | async)
            "
            [ngClass]="{
              red: percentegByActivePosition > 1.1,
              green: percentegByActivePosition >= 0.9 && percentegByActivePosition <= 1.1
            }"
          >
            <th matTooltip="Σ Position alle Abrechnungen">Σ Position alle Abrechnungen :</th>
            <td>
              <ng-container *ngIf="selectedElement?.elementType === 'PositionDto'; else positionNone">
                <span> {{ totalQuantityPerPosition | number: '1.3-3' }} </span>
                <span> {{ selectedElement.unitTag || '' }} </span>
              </ng-container>
            </td>
          </tr>

          <tr
            *ngIf="
              !(modePage | includes: 'estimations') &&
              (showingColumns | includes: 'totalQuantityPerPosition') &&
              totalInvoicedPerPosition !== 0 &&
              (selectedSpecificationElementMessengerService.selectedElement | async)
            "
            [ngClass]="{
              red: percentegByActivePosition > 1.1,
              green: percentegByActivePosition >= 0.9 && percentegByActivePosition <= 1.1
            }"
          >
            <th matTooltip="Menge Position alle Abrechnungen">Menge Position alle Abrechnungen:</th>
            <td>
              <ng-container *ngIf="selectedElement?.elementType === 'PositionDto'; else positionNone">
                {{ totalInvoicedPerPosition | projectCurrency }}
              </ng-container>
            </td>
          </tr>

          <tr *ngIf="(modePage | includes: 'pages') && (showingColumns | includes: 'pageQTOTotal')">
            <th matTooltip="Summe Position">Σ Blatt:</th>
            <td>{{ invoiceTotalSum.pageTotalSum | projectCurrency }}</td>
          </tr>

          <tr *ngIf="selectedRowsSumma !== null && (showingColumns | includes: 'selectedRowsSumma')">
            <th matTooltip="Summe Auswahl">Σ Auswahl:</th>
            <td>{{ selectedRowsSumma | projectCurrency }}</td>
          </tr>

          <tr
            *ngIf="(modePage | includes: 'pages') && selectedRowsQuantity !== null && (showingColumns | includes: 'selectedRowsQuantity')"
          >
            <th matTooltip="Menge Auswahl">Menge Auswahl:</th>
            <td>
              {{ selectedRowsQuantity | number: '1.3-3' }} {{ (selectRowsService.selectedRowTag | async) || selectedElement?.unitTag }}
            </td>
          </tr>
        </ng-container>

        <tr *ngIf="showingColumns.length === 0" class="empty-row mat-elevation-z8">
          <th></th>
          <td></td>
        </tr>
      </ng-container>

      <tr *ngIf="(modePage | includes: 'positions') && !isCompact && (showingColumns | includes: 'positionQuantity')">
        <th matTooltip="Menge Position">Menge Position:</th>
        <td>
          <ng-container *ngIf="selectedElement?.elementType === 'PositionDto'; else positionNone">
            {{ invoiceTotalSum.positionQuantity | number: '1.3-3' }} {{ selectedElement?.unitTag }}
          </ng-container>
        </td>
      </tr>
      <tr
        *ngIf="
          selectedElement?.elementType === 'PositionDto' &&
          selectedRowsQuantity !== null &&
          !isCompact &&
          (modePage | includes: 'positions') &&
          (showingColumns | includes: 'selectedRowsQuantity')
        "
      >
        <th matTooltip=">Menge Auswahl">Menge Auswahl:</th>
        <td>{{ selectedRowsQuantity | number: '1.3-3' }} {{ selectedElement?.unitTag }}</td>
      </tr>

      <tr *ngIf="!(modePage | includes: 'estimations')" [ngClass]="{ 'compact-view': isTreeOpen || isToggleTreeWidth }">
        <th matTooltip="percentage">
          <span>Σ insgesamt abgerechnet: </span>
          <span>{{ invoiceTotalSum.allTotalSum / serviceSpecificationTotalSum | percent }}</span>
        </th>
        <td class="progress">
          <mat-progress-bar
            [ngClass]="{
              'red-bg': invoiceTotalSum.allTotalSum / invoiceTotalSum.serviceSpecificationTotalSum > 1.1,
              'green-bg':
                0.9 <= invoiceTotalSum.allTotalSum / invoiceTotalSum.serviceSpecificationTotalSum &&
                1.1 >= invoiceTotalSum.allTotalSum / invoiceTotalSum.serviceSpecificationTotalSum
            }"
            [value]="(invoiceTotalSum.allTotalSum / invoiceTotalSum.serviceSpecificationTotalSum) * 100"
            [bufferValue]="100"
          >
          </mat-progress-bar>
        </td>
      </tr>
      <ng-container
        *ngIf="
          (percentegByActivePosition || percentegByActivePosition === 0) &&
          (selectedSpecificationElementMessengerService.selectedElement | async) &&
          !(modePage | includes: 'estimations')
        "
      >
        <tr
          [ngClass]="{
            'compact-view': isTreeOpen || isToggleTreeWidth
          }"
        >
          <th matTooltip="percentage">
            <span>Σ Position: </span>
            <span>{{ percentegByActivePosition | percent }}</span>
          </th>
          <td class="progress">
            <mat-progress-bar
              [ngClass]="{
                'red-bg': percentegByActivePosition > 1.1,
                'green-bg': 0.9 <= percentegByActivePosition && 1.1 >= percentegByActivePosition
              }"
              [value]="percentegByActivePosition * 100"
              [bufferValue]="100"
            >
            </mat-progress-bar>
          </td>
        </tr>
      </ng-container>
    </table>
    <ng-template #positionNone>-</ng-template>
  </ng-container>
</div>

<div fxLayout="column" *ngIf="modePage === 'calculation' && currentElementCalc">
  <ng-container *ngIf="currentPositionCalculationGetService.currentPositionCalc | async as positionCalc">
    <div fxLayout="row " fxLayoutAlign="space-between" class="total-info bold">
      <span>Gesamtpreis LV </span>
      <span>{{ positionCalc.serviceSpecificationTotal | projectCurrency }}</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between" class="total-info bold">
      <span>Kalkulierte Positionen&nbsp;</span>
      <span>
        {{ rangeOfCalcPositions }}
      </span>
      <span>&nbsp;({{ positionsCalculatedPercent | percent }}) </span>
    </div>

    <mat-progress-bar class="percentage-bar" mode="determinate" color="primary" [value]="percentOfCalcPositions"> </mat-progress-bar>

    <div fxLayout="row" fxLayoutAlign="space-between" class="total-info bold">
      <span>Pos. ohne Kalkulation&nbsp;</span>
      <span>
        {{ positionCalc.totalPositions - positionCalc.positionsCalculated }}
      </span>
      <span>/</span>
      <span>
        {{ positionCalc.totalPositions }}
      </span>
      <span>&nbsp;({{ (positionCalc.totalPositions - positionCalc.positionsCalculated) / positionCalc.totalPositions | percent }}) </span>
    </div>

    <mat-progress-bar
      class="percentage-bar"
      mode="determinate"
      color="warn"
      [value]="((positionCalc.totalPositions - positionCalc.positionsCalculated) / positionCalc.totalPositions) * 100"
    >
    </mat-progress-bar>
  </ng-container>
</div>

<mat-menu #rowHeaderContextMenu="matMenu">
  <ng-template matMenuContent>
    <div mat-menu-item>
      <button mat-button (click)="editListColumns()">Spalten Verwalten</button>
    </div>
  </ng-template>
</mat-menu>

<div
  style="position: fixed; visibility: hidden"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="rowHeaderContextMenu"
></div>
