import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCard, MatCardHeader, MatCardTitle, MatCardContent } from '@angular/material/card';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';

import { MasterAdditionGroupsClient, MasterAdditionGroupPost, MasterAdditionGroupGet } from 'app/generated-client/generated-client';

import { FlexLayoutDirective } from '../../../flex-layout/flex-layout.directive';
import { MasterDataSelectedGroupService } from '../../services/master-data-selected-group.service';

@Component({
  selector: 'pa-master-addition-new-group',
  templateUrl: './master-addition-new-group.component.html',
  styleUrls: ['./master-addition-new-group.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutDirective,
    MatCard,
    MatCardHeader,
    MatCardTitle,
    FormsModule,
    ReactiveFormsModule,
    MatCardContent,
    MatFormField,
    MatLabel,
    MatInput,
    MatCheckbox,
    MatSuffix,
    MatButton
  ]
})
export class MasterAdditionNewGroupComponent implements OnInit {
  newGroupForm: FormGroup;

  constructor(
    private masterAdditionGroupsClient: MasterAdditionGroupsClient,
    private masterDataSelectedGroupService: MasterDataSelectedGroupService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.newGroupForm = this.fb.group({
      name: '',
      isDefault: false,
      riskAndProfitAddition: '',
      calculationWage: ''
    });
  }

  addGroup(): void {
    const postModel = <MasterAdditionGroupPost>JSON.parse(JSON.stringify(this.newGroupForm.value));
    postModel.riskAndProfitAddition = postModel.riskAndProfitAddition * 0.01;
    this.masterAdditionGroupsClient.createMasterAdditionGroup(postModel).subscribe((data: MasterAdditionGroupGet) => {
      this.masterDataSelectedGroupService.setSelectedGroup(data);
      this.router.navigate(['..', 'detail', data.id], { relativeTo: this.route });
    });
  }

  dismiss(): void {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
