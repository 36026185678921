import { RouterModule, Routes } from '@angular/router';

import { AddLocalProjectComponent } from './components/add-local-project/add-local-project.component';
import { CommonModule } from '@angular/common';
import { LocalProjectsComponent } from './components/local-projects/local-projects.component';
import { LocalProjectsOverviewComponent } from './components/local-projects-overview/local-projects-overview.component';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: 'projects',
    component: LocalProjectsOverviewComponent,
    children: [
      {
        path: '',
        component: LocalProjectsComponent
      },
      {
        path: 'add-project',
        component: AddLocalProjectComponent
      }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LocalProjectsRoutingModule {}
