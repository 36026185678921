import { NgIf, NgFor } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatBadge } from '@angular/material/badge';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';

import { AuthenticationMessenger } from '@dangl/angular-dangl-identity-client';

import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';
import {
  IElementDto,
  PageQuantityTakeOffsClient,
  PositionDto,
  ProjectDto,
  ProjectUsers,
  QuantityTakeOffType,
  QuantityTakeOffsClient,
  ServiceSpecificationGroupDto,
  UserDisplayInfoGet,
  UsersClient
} from 'app/generated-client/generated-client';

import { getAppConfig } from '../../../app-config-accessor';
import { PageQuantityTakeOffsService } from '../../services/lightquery/page-quantity-take-offs.service';
import { SelectedProjectMessengerService } from '../../services/messengers/selected-project-messenger.service';
import { SelectedSpecificationMessengerService } from '../../services/messengers/selected-specification-messenger.service';
import { AvaHubConnector } from '../../services/signalr/ava-hub-connector';

import { KickProjectUserComponent } from '../kick-project-user/kick-project-user.component';
import { UserKickNotificationComponent } from '../user-kick-notification/user-kick-notification.component';
import { NgDanglIconsModule } from 'ng-dangl-icons';

@Component({
  selector: 'pa-project-users',
  templateUrl: './project-users.component.html',
  styleUrls: ['./project-users.component.scss'],
  standalone: true,
  imports: [NgIf, FlexLayoutDirective, MatIcon, MatBadge, MatMenuTrigger, MatMenu, NgFor, MatMenuItem, NgDanglIconsModule]
})
export class ProjectUsersComponent implements OnInit, OnDestroy {
  constructor(
    private avaHubConnector: AvaHubConnector,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private usersClient: UsersClient,
    private quantityTakeOffsClient: QuantityTakeOffsClient,
    private pageQuantityTakeOffsClient: PageQuantityTakeOffsClient,
    private matDialog: MatDialog,
    private authenticationMessenger: AuthenticationMessenger,
    private pageQuantityTakeOffsService: PageQuantityTakeOffsService
  ) {}
  isShowUsers = false;
  currentlyInProject = false;
  currentUsers: UserDisplayInfoGet[];
  statesByUserId: { [userId: string]: string } = {};
  private currentProjectId: string;
  private usersById: { [userId: string]: UserDisplayInfoGet } = {};
  private quantityTakeOffInfos: { [quantityTakeOffId: string]: { number: number; type: QuantityTakeOffType } } = {};
  private quantityTakeOffPageNumbers: { [pageId: string]: string } = {};
  private currentUserId: string;
  $destroy: Subject<boolean> = new Subject<boolean>();

  ngOnInit(): void {
    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((sp) => {
      this.currentlyInProject = !!sp;
      this.currentProjectId = sp?.id;
    });

    this.authenticationMessenger.userInfo.pipe(takeUntil(this.$destroy)).subscribe((ui) => (this.currentUserId = ui?.id));

    let avaProjectId: string = null;
    let avaProject: ProjectDto = null;
    this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(takeUntil(this.$destroy)).subscribe((ssp) => {
      avaProjectId = ssp?.avaProjectId;
      avaProject = ssp?.project;
      if (avaProjectId && this.currentProjectId) {
        this.avaHubConnector
          .getCurrentProjectUsers(this.currentProjectId)
          .then((r) => this.setCurrentUserLocations(this.currentProjectId, avaProjectId, avaProject, r));
      }
    });

    this.avaHubConnector.userKicked.pipe(takeUntil(this.$destroy)).subscribe((uk) => {
      if (uk.isSuccess && uk.kickedUserId === this.currentUserId) {
        this.matDialog.open(UserKickNotificationComponent, {
          disableClose: true,
          data: {
            userInfo: this.usersById[uk.initiatedByUserId]
          }
        });
      }
    });

    this.avaHubConnector.currentProjectUsers.pipe(takeUntil(this.$destroy)).subscribe((currentUsers) => {
      this.getInfosForCurrentProjectUsers(currentUsers);
      if (avaProjectId && this.currentProjectId) {
        this.setCurrentUserLocations(this.currentProjectId, avaProjectId, avaProject, currentUsers);
      }
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private setCurrentUserLocations(
    currentProjectId: string,
    currentAvaProjectId: string,
    project: ProjectDto,
    projectUsers: ProjectUsers
  ): void {
    this.statesByUserId = {};
    const calculationUsers = projectUsers.calculationUsers.find((cu) => cu.avaProjectId === currentAvaProjectId);
    if (calculationUsers) {
      Object.keys(calculationUsers.userIdsByPositionId).forEach((positionId) => {
        const positionItemNumber = this.getPositionItemNumberById(positionId, project);
        this.statesByUserId[calculationUsers.userIdsByPositionId[positionId]] = `Kalkulation ${positionItemNumber}`;
      });
    }

    const allQtoUsers = projectUsers.quantityTakeOffUsers.filter((qtou) => qtou.avaProjectId === currentAvaProjectId);
    if (allQtoUsers?.length > 0) {
      allQtoUsers.forEach((qtoUsers) => {
        this.getQuantityTakeOffInfo(currentProjectId, currentAvaProjectId, qtoUsers.quantityTakeOffId).then((qtoInfo) => {
          Object.keys(qtoUsers.userIdsByPositionId).forEach((positionId) => {
            const positionItemNumber = this.getPositionItemNumberById(positionId, project);
            this.statesByUserId[qtoUsers.userIdsByPositionId[positionId]] =
              qtoInfo.type === QuantityTakeOffType.QuantityEstimation
                ? `ME, Pos. ${positionItemNumber}`
                : `AR ${qtoInfo.number}, Pos. ${positionItemNumber}`;
          });

          Object.keys(qtoUsers.usersIdsByPageId).forEach((pageId) => {
            this.getQuantityTakeOffPageNumber(currentProjectId, currentAvaProjectId, qtoUsers.quantityTakeOffId, pageId).then(
              (pageNumber) => {
                this.statesByUserId[qtoUsers.usersIdsByPageId[pageId]] =
                  qtoInfo.type === QuantityTakeOffType.QuantityEstimation
                    ? `ME, Blatt ${pageNumber}`
                    : `AR ${qtoInfo.number}, Blatt ${pageNumber}`;
              }
            );
          });

          qtoUsers.userIdsInAllPositionsTable.forEach((userId) => {
            this.statesByUserId[userId] = `AR ${qtoInfo.number}, Positionsabrechnung`;
          });
        });
      });
    }
  }

  private async getQuantityTakeOffInfo(
    currentProjectId: string,
    currentAvaProjectId: string,
    quantityTakeOffId: string
  ): Promise<{ number: number; type: QuantityTakeOffType }> | null {
    if (this.quantityTakeOffInfos[quantityTakeOffId]) {
      return this.quantityTakeOffInfos[quantityTakeOffId];
    } else {
      const qto = await this.quantityTakeOffsClient
        .getQuantityTakeOffById(currentProjectId, currentAvaProjectId, quantityTakeOffId)
        .toPromise();
      this.quantityTakeOffInfos[quantityTakeOffId] = {
        number: qto.number,
        type: qto.quantityTakeOffType
      };
      qto.calculationType;

      return this.quantityTakeOffInfos[quantityTakeOffId];
    }
  }

  private async getQuantityTakeOffPageNumber(
    currentProjectId: string,
    currentAvaProjectId: string,
    quantityTakeOffId: string,
    quantityTakeOffPageId: string
  ): Promise<string> {
    if (this.quantityTakeOffPageNumbers[quantityTakeOffPageId]) {
      return this.quantityTakeOffPageNumbers[quantityTakeOffPageId];
    } else {
      return this.pageQuantityTakeOffsService
        .getAll()
        .pipe(
          map(async (qtoPages) => {
            if (qtoPages?.length > 0) {
              qtoPages.forEach((qtoPage) => {
                this.quantityTakeOffPageNumbers[qtoPage.id] = qtoPage.pageNumber;
              });
            }

            if (this.quantityTakeOffPageNumbers[quantityTakeOffPageId]) {
              return this.quantityTakeOffPageNumbers[quantityTakeOffPageId];
            }

            const qtoPage = await this.pageQuantityTakeOffsClient
              .getPageQuantityTakeOffById(currentProjectId, currentAvaProjectId, quantityTakeOffPageId)
              .toPromise();
            this.quantityTakeOffPageNumbers[quantityTakeOffPageId] = qtoPage.pageNumber;
            return qtoPage.pageNumber;
          })
        )
        .toPromise();
    }
  }

  private getPositionItemNumberById(positionId: string, project: ProjectDto): string {
    return this.getPositionItemNumber(project.serviceSpecifications[0].elements, positionId);
  }

  private getPositionItemNumber(elements: IElementDto[], positionId: string): string {
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].elementTypeDiscriminator === 'PositionDto' && elements[i].id === positionId) {
        return (elements[i] as PositionDto).itemNumber.stringRepresentation;
      }

      if (
        elements[i].elementTypeDiscriminator === 'ServiceSpecificationGroupDto' &&
        (elements[i] as ServiceSpecificationGroupDto).elements
      ) {
        const childItemNumber = this.getPositionItemNumber((elements[i] as ServiceSpecificationGroupDto).elements, positionId);
        if (childItemNumber) {
          return childItemNumber;
        }
      }
    }

    return null;
  }

  private async getInfosForCurrentProjectUsers(projectUsers: ProjectUsers): Promise<void> {
    const userIdsToFetch = projectUsers.allProjectUsers
      .filter((projectUser) => !this.usersById[projectUser.userId])
      .map((projectUser) =>
        this.usersClient
          .getUserInfo(projectUser.userId)
          .pipe(tap((userInfo) => (this.usersById[userInfo.id] = userInfo)))
          .toPromise()
      );

    await Promise.all([...userIdsToFetch]);
    this.currentUsers = projectUsers.allProjectUsers.map((projectUser) => this.usersById[projectUser.userId]);
    this.isShowUsers = (!getAppConfig().production && getAppConfig().showConcurrentUsers) || this.currentUsers?.length > 1;
  }

  tryKickUser(userId: string): void {
    if (!this.statesByUserId[userId]) {
      return;
    }

    this.matDialog.open(KickProjectUserComponent, {
      data: {
        projectId: this.currentProjectId,
        id: userId,
        userInfo: this.currentUsers.find((u) => u.id == userId)
      }
    });
  }
}
