import { Injectable } from '@angular/core';
import { PositionDto, ServiceSpecificationGroupDto } from 'app/generated-client/generated-client';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TreeMessagesSeparateService {
  private nodeForCopySource = new Subject<PositionDto | ServiceSpecificationGroupDto>();
  nodeForCopy = this.nodeForCopySource.asObservable();

  constructor() {}

  setNodeForCopy(node: PositionDto | ServiceSpecificationGroupDto): void {
    this.nodeForCopySource.next(node);
  }
}
