import { Clipboard, CdkCopyToClipboard } from '@angular/cdk/clipboard';
import { CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { NgIf, NgClass, NgFor, DecimalPipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  inject
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadge } from '@angular/material/badge';
import { MatButton } from '@angular/material/button';
import { MatButtonToggleGroup, MatButtonToggle } from '@angular/material/button-toggle';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatMenuTrigger, MatMenu, MatMenuContent, MatMenuItem } from '@angular/material/menu';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';

import { Subject, Subscription, combineLatest, fromEvent, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, skip, switchMap, take, takeUntil } from 'rxjs/operators';

import { CalculationTotalsService } from '@serv-spec/services/calculation-totals.service';
import { ChangeFormulaFactorService } from '@serv-spec/services/change-formula-factor.service';
import { ChangeViewportHeightService } from '@serv-spec/services/change-viewport-height.service';
import { CurrentPositionCalculationGetService } from '@serv-spec/services/current-position-calculation-get.service';
import { SubPositionNestingLevelService } from '@serv-spec/services/sub-position-nesting-level.service';

import { SUBPOSITION_DEFAULT_COLORS, TABLE_REPL_PRICE_COMPONENT } from '@shared/constants/constants';

import { DetailTableModalComponent } from 'app/areas/elements/components/detail-table-modal/detail-table-modal.component';
import { DetailTableModalService } from 'app/areas/elements/services/detail-table-modal.service';
import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';
import {
  AvaProjectAdditionGet,
  AvaProjectPriceComponentAdditionGet,
  CalculationEntry,
  CalculationEntryPriceComponent,
  MasterDataProductGet,
  PositionCalculation,
  PositionCalculationGet,
  PositionCalculationTotals,
  PositionCalculationsClient,
  PositionDto,
  PriceComponentType,
  ProjectDto,
  ProjectGet,
  UserSettings
} from 'app/generated-client/generated-client';
import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { ShowingColumnsModalComponent } from 'app/shared/components/showing-columns-modal/showing-columns-modal.component';
import { ContextListMenuSettingType, ContextMenuSettingType, NestingColorSettings } from 'app/shared/models';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { WindowType } from 'app/shared/models/window-type.models';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { ClipboardService } from 'app/shared/services/clipboard.service';
import { ContextMenuSettingService } from 'app/shared/services/context-menu-setting.service';
import { ContextMenuSettingsService } from 'app/shared/services/context-menu-settings.service';
import { CopyCalculationViewMessengerService } from 'app/shared/services/electron/copy-calculation-view-messenger.service';
import { FocusRowService } from 'app/shared/services/focus-row.service';
import { ProjectQuantityEstimationService } from 'app/shared/services/messengers/project-quantity-estimation.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';
import { SelectRowsService } from 'app/shared/services/select-rows.service';
import { ShowedViewsService } from 'app/shared/services/showed-views.service';
import { ShowingColumnsService } from 'app/shared/services/showing-columns.service';
import { UserSettingsService } from 'app/shared/services/user-settings.service';
import { getStorage } from 'app/shared/utilities/storage';

import { ContextMenuGearInTopComponent } from '../../../../../../shared/components/context-menu-gear-in-top/context-menu-gear-in-top.component';
import { SelectAllRowsComponent } from '../../../../../../shared/components/select-all-rows/select-all-rows.component';
import { ClipboardTableDataService } from '../../../../../../shared/services/clipboard-table-data.service';
import { CalculationFixedPriceService } from '../../../../services/calculation-fixed-price.service';
import { AllowNumericWithDecimalDirective } from '../../directives/allow-numeric-with-decimal.directive';
import { MatInputDecimalPlacesDirective } from '../../directives/mat-input-decimal-places.directive';
import { NestingColorDirective } from '../../directives/nesting-color.directive';
import { UpdateViewportSizeDirective } from '../../directives/update-viewport-size.directive';
import { CalculationService } from '../../services/calculation.service';
import { KeyupControlService } from '../../services/keyup-control.service';
import { LimitColumnWidthService } from '../../services/limit-column-width.service';
import { SubpositionIdentifierService } from '../../services/subposition-identifier.service';

import { KeyboardPositionSelectionService } from './../../../../../../shared/services/keyboard-position-selection.service';
import { SubpositionsForCurrentPositionService } from './../../services/subpositions-for-current-position.service';

import { ResizableDirective } from '../invoice/directives/resizable.directive';
import { MasterDataPriceComponentSelectionComponent } from '../master-data-price-component-selection/master-data-price-component-selection.component';
import { ModalAddItemToTableComponent } from '../modal-add-item-to-table/modal-add-item-to-table.component';
import { SubPositionItemNumberComponent } from '../sub-position-item-number/sub-position-item-number.component';
import { TableVirtualScrollDataSource, TableVirtualScrollModule } from 'ng-table-virtual-scroll';

type MethodsType = { getCalculation: string[] };
interface ICalculationRows {
  errorMessage?: string;
  isSuccess: boolean;
  value?: PositionCalculation;
}

@Component({
  selector: 'pa-calculation',
  templateUrl: './calculation.component.html',
  styleUrls: ['./calculation.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    CdkVirtualScrollViewport,
    TableVirtualScrollModule,
    UpdateViewportSizeDirective,
    MatTable,
    CdkDropList,
    NgClass,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    SelectAllRowsComponent,
    MatCellDef,
    MatCell,
    ResizableDirective,
    SubPositionItemNumberComponent,
    MatInput,
    FormsModule,
    MatInputDecimalPlacesDirective,
    AllowNumericWithDecimalDirective,
    NgFor,
    MatIcon,
    MatTooltip,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    NestingColorDirective,
    MatMenu,
    MatMenuContent,
    ContextMenuGearInTopComponent,
    MatMenuItem,
    MatButton,
    CdkCopyToClipboard,
    MatBadge,
    MatButtonToggleGroup,
    MatButtonToggle,
    MatMenuTrigger,
    DecimalPipe,
    FlexLayoutDirective
  ]
})
export class CalculationComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild(CdkVirtualScrollViewport) private viewTable: CdkVirtualScrollViewport;
  @ViewChild(MatTable) private table: MatTable<any>;
  @ViewChild('wrapElement') wrapElement: ElementRef<HTMLDivElement>;
  @Input() isReadOnly: boolean;
  @Input() isHideReadOnlyMark: boolean;
  @Input() positionCalculationData: PositionCalculation;
  @Input() currentTotals: PositionCalculationTotals;
  @Output() save = new EventEmitter<PositionCalculation>();
  @Input() methods?: MethodsType;
  @Input() isInnerWindow: boolean;
  @Input() positionId: string;
  @Input() calcEntries: CalculationEntry[];

  @ViewChildren(MatMenuTrigger) contextMenu: QueryList<MatMenuTrigger>;
  @ViewChildren('cellWidth') headCellWidth: QueryList<HTMLTableCellElement>;
  @Input() isLoadingOutside: boolean;
  isLoadingInside = false;
  isNotActivePriceComponent = false;
  isCursorInsideContentField = false;
  @HostListener('window:resize', ['$event']) onWindowResize(): void {
    if (!this.isInnerWindow) {
      this.getListWidth();
      this.detailTableModalService.startChangePosition();
    }
  }

  private initializeMouseUpEventListeners(): void {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(document, 'mouseup')
        .pipe(takeUntil(this.$destroy))
        .subscribe(() => {
          this.globalHandleMouseUpEvent();
          if (!this.isInnerWindow) {
            this.getListWidth();
            this.detailTableModalService.startChangePosition();
          }
        });

      fromEvent(this.el.nativeElement, 'mouseup')
        .pipe(takeUntil(this.$destroy))
        .subscribe((event) => {
          if (event instanceof MouseEvent) {
            this.handleMouseUpEvent(event);
          }
        });

      fromEvent(document, 'mousemove')
        .pipe(takeUntil(this.$destroy), debounceTime(200))
        .subscribe((event: MouseEvent) => {
          const el = document.elementFromPoint(event.clientX, event.clientY);
          this.isCursorInsideContentField = !!el?.closest('.uniq-content-field');
        });
    });
  }

  globalHandleMouseUpEvent(): void {
    if (this.currentFocusedRowIndex) {
      this.ngZone.run(() => {
        this.changeTotalSumByRowIndex(null);
      });
    }
  }

  handleMouseUpEvent(event: MouseEvent): void {
    if (this.listMove.length) {
      this.ngZone.run(() => {
        this.stopSelectRow(event);
      });
    }
    //add event.button === 0, so that the event fires only for the left mouse button
    if (!this.isInnerWindow && this.listCells?.length && event.button === 0 && !!(event.target as HTMLElement).closest('th')) {
      this.ngZone.run(() => {
        this.getListWidth();
      });
    }
  }

  savedSelectedRows: CalculationEntry[] = [];
  listSelectedRows: number[] = this.selectRowsService.init();
  listCells: HTMLTableCellElement[];
  additions: AvaProjectAdditionGet;
  contextMenuPosition = { x: '0px', y: '0px' };
  copyCalculationViewOpen = false;
  dataSource = new TableVirtualScrollDataSource<CalculationEntry>();
  defaultDisplayedColumns: string[] = [];
  displayedColumns: string[] = [];
  hasLoaded = false;
  lastFocusedRow: { rowIndex?: number; formulaFactor?: string } = { rowIndex: null, formulaFactor: null };
  lastEditableRowIndex = Number.MAX_VALUE;
  lastFocusedRowIndex: number;
  currentFocusedRowIndex: number | null = null;
  selectedRowTable: CalculationEntry;
  isChanged: boolean;
  arrWidth: number[] = [];
  userSettings: UserSettings = {} as UserSettings;
  private clipboardWord = 'calc';
  selectedText = '';
  private listMove: number[] = [];
  private selectedElementId: string | null = null;
  private $destroy: Subject<boolean> = new Subject<boolean>();
  mousemove$: Subscription;
  isCursorOutsideText = true;
  priceComponentType = PriceComponentType;

  private positionFixedPrice: number | null;
  private keyboardNavigationCallbackId: number | null = null;
  currentContextMenuTable: ContextMenuSettingType = ContextMenuSettingType.CalculationTable;
  currentListContextMenuTable: ContextListMenuSettingType = {};
  viewportHeight = 0;
  private $scrollViewport: Subject<number> = new Subject<number>();
  showedBottomView = false;
  hiddenWindows = true;
  detailTableModal: MatDialogRef<DetailTableModalComponent>;
  colorSettings: NestingColorSettings;
  subpositionIdentifier = inject(SubpositionIdentifierService);

  constructor(
    private contextMenuSettingsService: ContextMenuSettingsService,
    private keyupControlService: KeyupControlService,
    private modalService: ModalService,
    private ngZone: NgZone,
    private notificationsService: AvaNotificationsService,
    private copyCalculationViewMessengerService: CopyCalculationViewMessengerService,
    private selectRowsService: SelectRowsService,
    private clipboardService: ClipboardService,
    private calculationFixedPriceService: CalculationFixedPriceService,
    private showingColumnsService: ShowingColumnsService,
    private positionCalculationsClient: PositionCalculationsClient,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private userSettingsService: UserSettingsService,
    private focusRowService: FocusRowService,
    private el: ElementRef,
    private limitColumnWidthService: LimitColumnWidthService,
    private currentPositionCalculationGetService: CurrentPositionCalculationGetService,
    @Optional() private keyboardPositionSelectionService: KeyboardPositionSelectionService,
    private projectQuantityEstimationService: ProjectQuantityEstimationService,
    private subpositionsForCurrentPositionService: SubpositionsForCurrentPositionService,
    private showedViewsService: ShowedViewsService,
    private calculationTotalsService: CalculationTotalsService,
    private changeFormulaFactorService: ChangeFormulaFactorService,
    private contextMenuSettingService: ContextMenuSettingService,
    private changeViewportHeightService: ChangeViewportHeightService,
    private detailTableModalService: DetailTableModalService,
    private subPositionNestingLevelService: SubPositionNestingLevelService,
    private clipboardTableDataService: ClipboardTableDataService,
    private clipboard: Clipboard
  ) {}

  private handlePastedText(tableData: string[][]): void {
    if (this.isNotActivePriceComponent) {
      return;
    }

    const priceComponentIndices: { [priceComponent: string]: number } = {};
    this.additions.priceComponents.forEach((pc, index) => {
      priceComponentIndices[pc.priceComponent] = index;
    });

    // We're trying to create new rows from the pasted data.
    const newRows: CalculationEntry[] = tableData.map((row) => {
      const newRow: CalculationEntry = this.getEmptyEntry(this.dataSource.data.length + 1);
      row.forEach((cell, index) => {
        index = index + 1; // We're ignoring the index column
        const column = this.displayedColumns[index];
        if (column) {
          if (column === 'isInternal') {
            newRow[column] = cell === 'true';
          } else if (column.startsWith('pc_')) {
            const priceComponentIndex = priceComponentIndices[column.replace('pc_', '')];
            const pcValue = Number(cell.replace(',', '.'));
            if (pcValue !== 0) {
              newRow.priceComponents[priceComponentIndex].sum = pcValue;
            }
          } else if (column === 'labourTotalSum' || column === 'sum' || column === 'sumAfterAdditions') {
            // We're skipping those.
          } else if (cell !== '' && cell != null) {
            newRow[column] = cell;
          }
        }
      });
      return newRow;
    });

    let currentRow = this.selectedRowTable;
    if (currentRow.rowIndex > 1) {
      // In that case, we actually want to insert it one before.
      currentRow = this.dataSource.data[currentRow.rowIndex - 2];
    }

    this.addRow(currentRow, true, newRows, false);
  }

  ngOnInit(): void {
    this.clipboardTableDataService.announceListener();
    this.clipboardTableDataService.tablePasted$.pipe(takeUntil(this.$destroy)).subscribe((tableData) => this.handlePastedText(tableData));

    this.ngZone.runOutsideAngular(() => {
      fromEvent(document, 'keydown')
        .pipe(takeUntil(this.$destroy))
        .subscribe((event: KeyboardEvent) => {
          this.handleGlobalKeyboardEvent(event);
        });
    });

    if (this.keyboardPositionSelectionService) {
      this.keyboardNavigationCallbackId = this.keyboardPositionSelectionService.addCallbackAndReturnId(() => {
        if (this.isChanged) {
          if (this.lastFocusedRowIndex != null) {
            const lastFocusedRow = this.positionCalculationData.calculationEntries.find((e) => e.rowIndex === this.lastFocusedRowIndex);
            if (lastFocusedRow) {
              // In case we've changed some inputs, we might also have to ensure that strings inputs in the current users locale
              // are properly transformed to numerical values
              this.ensureCalculationEntryHasCorrectNumericalFormats(lastFocusedRow);
            }
          }

          // We need to set it to null, otherwise it might be saved as a last focused row
          // and later changes to cells would always again select this cell, leading to a jumpy
          // cursor that always goes back to a last saved position.
          this.lastFocusedRow.rowIndex = null;
          // Here's the same, this prevents the keyboard navigation from jumping to the last focused row
          this.selectedElementId = null;
          this.isChanged = true;
          this.recalculate();
        }
      });
    }
    if (!this.isInnerWindow) {
      this.showedViewsService.showedBottomView.pipe(takeUntil(this.$destroy)).subscribe((showedBottomView) => {
        this.showedBottomView = showedBottomView;
      });
      this.showedViewsService.hiddenWindows.pipe(takeUntil(this.$destroy)).subscribe((showDetailWindow) => {
        if (this.showedBottomView && showDetailWindow) {
          this.detailTableModalService.startChangePosition();
        }
      });
      this.detailTableModalService.tryChangePosition.pipe(debounceTime(50), takeUntil(this.$destroy)).subscribe(() => {
        this.changeDetailPosition();
      });
      this.$scrollViewport.pipe(debounceTime(20)).subscribe((scrollNumber) => {
        this.calculationTotalsService.setScrollX(scrollNumber);
      });
      this.contextMenuSettingService.listCalculationMenu
        .pipe(takeUntil(this.$destroy))
        .subscribe((menuOptions) => (this.currentListContextMenuTable = menuOptions));

      this.calculationFixedPriceService.calculationFixedPrice.pipe(takeUntil(this.$destroy)).subscribe((fixedPriceInfo) => {
        if (fixedPriceInfo?.positionId === this.positionId) {
          this.positionFixedPrice = fixedPriceInfo.fixedPrice;
          this.isChanged = true;
          this.recalculate();
        }
      });

      this.copyCalculationViewMessengerService.selectedCalculationRows.pipe(takeUntil(this.$destroy)).subscribe((rows) => {
        this.applyCopiedCalculationRows(rows);
        this.goTopMain();
      });

      this.copyCalculationViewMessengerService.saveCalculationRows
        .pipe(takeUntil(this.$destroy))
        .subscribe((rows) => (this.savedSelectedRows = rows));

      // TODO UPDATE DOCUMENTATION WITH COPY ROW FEATURE -> HOW IT WORKS
      this.copyCalculationViewMessengerService.selectedCalculationRow.pipe(takeUntil(this.$destroy)).subscribe((row) => {
        this.appliedCopiedCalculationRow(row);
        this.goTopMain();
      });

      this.copyCalculationViewMessengerService.selectedArticleOrEquipment.pipe(takeUntil(this.$destroy)).subscribe((selection) => {
        this.applyCopiedArticleRow(selection);
        this.goTopMain();
      });

      this.subscriberCopyCalcMessenger();

      this.clipboardService.getData(this.clipboardWord, this.savedSelectedRows);

      this.userSettingsService.currentFullSettings.pipe(debounceTime(200), takeUntil(this.$destroy)).subscribe((setting: UserSettings) => {
        this.userSettings = setting;
      });

      this.limitColumnWidthService.callBack.pipe(takeUntil(this.$destroy)).subscribe(() => this.getListWidth());

      this.changeFormulaFactorService.changedValueOfFormulaFactor
        .pipe(takeUntil(this.$destroy))
        .subscribe((changedValueOfFormulaFactor) => {
          this.isChanged = true;
          this.selectedRowTable.formulaFactor = changedValueOfFormulaFactor;
          this.recalculate(this.selectedRowTable);
        });

      this.copyCalculationViewMessengerService.selectedPosition.pipe(takeUntil(this.$destroy)).subscribe((e) => {
        this.applyCopiedCalculationRows(e.positionCalculation.positionCalculation.calculationEntries);
        this.goTopMain();
      });
    }

    this.showingColumnsService.settingsLoaded.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.displayedColumns = this.showingColumnsService.getFilteredColumns('Calculation', this.defaultDisplayedColumns);
    });

    this.keyupControlService.selectedElementId.pipe(takeUntil(this.$destroy)).subscribe((elementId) => {
      this.selectedElementId = elementId;
    });

    this.userSettingsService.currentUserSettings
      .pipe(
        takeUntil(this.$destroy),
        switchMap((s: any) => {
          if (s && s.subPositionColorSettings) {
            return of(s.subPositionColorSettings);
          } else {
            return of(SUBPOSITION_DEFAULT_COLORS);
          }
        })
      )
      .subscribe((subPositionsColors) => {
        this.colorSettings = subPositionsColors;
      });
  }

  ngOnChanges(e: SimpleChanges): void {
    if (this.positionCalculationData && e.positionCalculationData) {
      const priceComponents = [...this.positionCalculationData.additions.priceComponents.map((e) => e)];
      priceComponents.sort((a1, a2) => a1.index - a2.index);
      this.additions = {
        ...this.positionCalculationData.additions,
        priceComponents
      };
      this.defaultDisplayedColumns = [
        'rowNumber',
        'isInternal',
        'subPositionIdentifier',
        'text',
        'formulaFactor',
        'factor',
        'divisor',
        'labourHours',
        'labourTotalSum',
        ...this.additions.priceComponents
          .filter((pc: AvaProjectPriceComponentAdditionGet) => !!pc.priceComponent)
          .map((pc: AvaProjectPriceComponentAdditionGet) => `pc_${pc.priceComponent}`),
        'sum',
        'sumAfterAdditions'
      ];
      this.displayedColumns = this.showingColumnsService.getFilteredColumns('Calculation', this.defaultDisplayedColumns);
      this.setCalculationEntries(this.positionCalculationData);
      this.ensureRowSelectionReset();
      this.positionFixedPrice = this.positionCalculationData.fixedPrice ? this.positionCalculationData.fixedPrice : null;
      this.getListWidth();
      this.scrollToTop();
      this.detailTableModalService.startChangePosition();
      this.changeViewportHeightService.updatedViewportHeight(true);
    }
    if (e.calcEntries?.currentValue && !e.calcEntries.firstChange) {
      for (let i = 0; i < this.dataSource.data.length && i < e.calcEntries.currentValue.length; i++) {
        this.dataSource.data[i].labourTotalSum = [...e.calcEntries.currentValue][i].labourTotalSum;
        this.dataSource.data[i].sum = [...e.calcEntries.currentValue][i].sum;
        this.dataSource.data[i].sumAfterAdditions = [...e.calcEntries.currentValue][i].sumAfterAdditions;
        if (this.dataSource.data[i].subPositionIdentifier) {
          this.dataSource.data[i].labourHours = [...e.calcEntries.currentValue][i].labourHours;
          this.dataSource.data[i].priceComponents = [...e.calcEntries.currentValue][i].priceComponents;
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.clipboardTableDataService.removeListener();

    this.$destroy.next(true);
    this.$destroy.complete();
    this.listCells = [];
    this.selectRowsService.init();
    this.selectRowsService.setSelectedRowByIndex(0);
    if (this.keyboardNavigationCallbackId != null) {
      this.keyboardPositionSelectionService.removeCallback(this.keyboardNavigationCallbackId);
    }

    if (this.mousemove$) {
      this.mousemove$.unsubscribe();
    }
    this.detailTableModal?.close();
  }

  ngAfterViewInit(): void {
    this.headCellWidth.changes.pipe(take(1), takeUntil(this.$destroy)).subscribe((r: QueryList<HTMLTableCellElement>) => {
      this.listCells = r.map((item: any) => item.nativeElement);
      if (!this.isInnerWindow && this.currentTotals) {
        this.getListWidth();
      }
    });

    this.selectRowsService.selectedIndexRow.pipe(takeUntil(this.$destroy)).subscribe((selectedIndexRow) => {
      this.startSelectRow(selectedIndexRow);
      this.stopSelectRow(null, selectedIndexRow);
    });

    this.changeViewportHeightService.viewportHeight.pipe(takeUntil(this.$destroy), distinctUntilChanged()).subscribe((viewportHeight) => {
      this.viewportHeight = viewportHeight;
      this.detailTableModalService.startChangePosition();
    });

    this.initializeMouseUpEventListeners();
  }

  handleGlobalKeyboardEvent(event: KeyboardEvent): void {
    switch (event.key) {
      case 'F5':
        if (this.listSelectedRows.length) {
          this.ngZone.run(() => {
            this.cutSelectedRowsPlus();
          });
        }
        break;
      case 'F7':
        if (!this.isInnerWindow) {
          combineLatest([
            this.projectQuantityEstimationService.currentProjectHasQuantityCalculation,
            this.projectQuantityEstimationService.currentProjectHasAssumedQuantities
          ])
            .pipe(first())
            .subscribe(([hasQuantityCalculation, hasAssumedQuantities]: [boolean, boolean]) => {
              if (hasQuantityCalculation || hasAssumedQuantities) {
                this.ngZone.run(() => {
                  this.projectQuantityEstimationService.togglePositionsEstimationCompact();
                  this.changeViewportHeightService.updatedViewportHeight(true);
                });
              }
            });
        }
        break;
      case 'c':
      case 'C':
        if (event.ctrlKey) {
          if (this.listSelectedRows.length) {
            const selectedRows = this.dataSource.data.filter((item: CalculationEntry) => this.listSelectedRows.includes(item.rowIndex));

            const priceComponentIndices: { [priceComponent: string]: number } = {};
            this.additions.priceComponents.forEach((pc, index) => {
              priceComponentIndices[pc.priceComponent] = index;
            });

            // Now we're building a tab-separated table
            let result = '';
            selectedRows.forEach((row) => {
              const rowValues = this.displayedColumns
                .filter((column) => column !== 'rowNumber')
                .map((column) => {
                  const columnValue = row[column];
                  if (column === 'isInternal') {
                    return columnValue ? 'true' : 'false';
                  } else if (typeof columnValue === 'number') {
                    return columnValue.toString().replace('.', ',');
                  } else if (column.startsWith('pc_')) {
                    const priceComponentIndex = priceComponentIndices[column.replace('pc_', '')];
                    return row.priceComponents[priceComponentIndex].sum?.toString().replace('.', ',');
                  } else {
                    return columnValue;
                  }
                });
              result += rowValues.join('\t') + '\r\n';
            });

            navigator.clipboard.writeText(result);
            const message = selectedRows.length === 1 ? '1 Zeile' : `${selectedRows.length} Zeilen`;
            this.notificationsService.info(message, 'Zeilen kopiert:', 3000);
          }
        }
        break;
      case 'Delete':
        this.listSelectedRows = event.altKey ? [this.lastFocusedRowIndex] : [...this.listSelectedRows];
        if (this.listSelectedRows.length && !this.isInnerWindow) {
          this.ngZone.run(() => {
            this.deleteSelectedRowsPlus(event.altKey);
          });
        }
        break;
      case 'Insert':
        if (this.isCursorInsideContentField && this.listSelectedRows.length == 1) {
          const row = this.dataSource.data[this.listSelectedRows[0] - 1];
          this.selectedElementId = row['id'];
          this.addRow(row, false);
          this.listSelectedRows[0] += 1;
        }
        break;
      default:
    }
  }

  private subscriberCopyCalcMessenger(): void {
    // send prepared data to copy calculation view when it have just opened

    this.copyCalculationViewMessengerService.copyCalculationViewVisible.pipe(takeUntil(this.$destroy)).subscribe((isOpen: boolean) => {
      this.copyCalculationViewOpen = isOpen;
      if (this.copyCalculationViewOpen) {
        this.checkValueSelectedRowTable();
        this.copyCalculationViewMessengerService.setListCopyCalculation({
          method: this.methods
        });
      } else {
        this.checkValueSelectedRowTable(true);
      }
    });
  }

  private getListWidth(): void {
    setTimeout(() => {
      if (this.listCells?.length) {
        let start = 0;
        const arrWidth: number[] = this.listCells.map((item: HTMLTableCellElement, i: number) => {
          const rect: DOMRect = item.getBoundingClientRect();
          if (!rect) {
            return;
          }
          switch (i) {
            case 0:
              start = rect.left;
              return;
            case 1:
              return rect.right - start;
            default:
              return rect.width;
          }
        });
        arrWidth[1] = arrWidth[1] - 1;
        this.arrWidth = arrWidth.slice(1);
        this.calculationTotalsService.setListWidth(this.arrWidth);
      }
    }, 1);
  }

  changeInternal(el: CalculationEntry): void {
    const isInternal = !el.isInternal;
    el.isInternal = isInternal;

    if (el.subPositionIdentifier) {
      const levelOfNesting = this.subPositionNestingLevelService.getNestingLevel(el.subPositionIdentifier, this.colorSettings);
      const rowsUnderSubPosition = this.positionCalculationData.calculationEntries.slice(
        el.rowIndex,
        this.positionCalculationData.calculationEntries.findIndex((calculationEntrie, index) => {
          if (
            (el.rowIndex < calculationEntrie.rowIndex && this.checkIfRowIsEmpty(calculationEntrie)) ||
            (el.rowIndex < calculationEntrie.rowIndex &&
              el.rowIndex !== calculationEntrie.rowIndex &&
              levelOfNesting >=
                this.subPositionNestingLevelService.getNestingLevel(calculationEntrie.subPositionIdentifier, this.colorSettings) &&
              this.subPositionNestingLevelService.getNestingLevel(calculationEntrie.subPositionIdentifier, this.colorSettings) !== 0)
          ) {
            return index;
          }
        })
      );
      rowsUnderSubPosition.forEach((el) => {
        this.dataSource.data[el.rowIndex - 1].isInternal = isInternal;
      });
    }
    setTimeout(() => {
      this.ngZone.run(() => {
        this.isChanged = true;
        this.recalculate();
      });
    }, 10);
  }

  recalculate(activeRow?: CalculationEntry, priceComponentInfo?: { priceComponentIndex: number }): void {
    if (!this.isChanged) {
      return;
    }
    this.recalculateInternal(activeRow, priceComponentInfo);
  }

  private recalculateInternal(activeRow?: CalculationEntry, priceComponentInfo?: { priceComponentIndex: number }): void {
    if (activeRow && priceComponentInfo) {
      const priceComponent = this.additions.priceComponents[priceComponentInfo.priceComponentIndex];
      const rowValue = activeRow.priceComponents[priceComponentInfo.priceComponentIndex].sum;
      let rowValueString: any = rowValue;
      if (rowValueString != null && (typeof rowValueString === 'string' || rowValueString instanceof String)) {
        rowValueString = rowValueString.replace(',', '.');
      }
      const rowValueNumerical = Number(rowValueString);
      if (isNaN(rowValueNumerical)) {
        this.modalService
          .openModal(MasterDataPriceComponentSelectionComponent, {
            dialogType: ConfirmationType.General,
            data: {
              filter: rowValueString,
              priceComponentType: priceComponent.priceComponentType
            }
          })
          .afterClosed()
          .subscribe((selectedElement?: MasterDataProductGet) => {
            if (selectedElement) {
              this.applyCopiedArticleRow(selectedElement, activeRow);
              this.keyupControlService.moveFocus(0, 1, this.displayedColumns);
            } else {
              activeRow.priceComponents[priceComponentInfo.priceComponentIndex].sum = null;
            }
          });
        return;
      }
    }
    if (activeRow) {
      this.ensureCalculationEntryHasCorrectNumericalFormats(activeRow);
    }

    const positionCalculation = {
      fixedPrice: this.positionFixedPrice,
      additions: this.additions,
      calculationEntries: this.dataSource.data
    };

    this.isChanged = false;

    let hasSubPositionInternal = false;
    positionCalculation.calculationEntries.forEach((entry) => {
      if (entry.subPositionIdentifier) {
        hasSubPositionInternal = entry.isInternal;
      } else {
        if (hasSubPositionInternal && !this.checkIfRowIsEmpty(entry)) {
          entry.isInternal = true;
        }
      }
    });

    if (activeRow) {
      this.lastFocusedRow.rowIndex = activeRow.rowIndex;
    }
    if (activeRow && this.lastFocusedRow) {
      const changedEntry: CalculationEntry = positionCalculation.calculationEntries.find(
        (e) => e.rowIndex === this.lastFocusedRow.rowIndex
      );

      if (changedEntry.formulaFactor != this.lastFocusedRow.formulaFactor) {
        changedEntry.factor = null;
        changedEntry.formulaFactor = changedEntry.formulaFactor || null;
      }
    }

    if (!positionCalculation.calculationEntries || positionCalculation.calculationEntries.length === 0) {
      positionCalculation.calculationEntries = [this.getEmptyEntry(1)];
    }

    const localCalcResult: ICalculationRows = CalculationService.recalculateCalculationRows(positionCalculation);
    if (localCalcResult.isSuccess) {
      this.dataSource.data = [...this.addNewLineIfLastOneNotEmpty(localCalcResult.value.calculationEntries)];
      this.table?.renderRows();

      if (!localCalcResult.value.entriesWithErrors) {
        this.positionCalculationData.calculationEntries = this.dataSource.data;
        this.positionCalculationData.fixedPrice = this.positionFixedPrice;
        this.save.emit(this.positionCalculationData);
      } else {
        this.notificationsService.error('Formelfehler, die Kalkulation wird nicht gespeichert.');
      }

      if (this.selectedElementId) {
        document.getElementById(this.selectedElementId)?.focus();
      }
    } else {
      this.notificationsService.error('Fehler beim Speichern der Kalkulation.');
      console.error(localCalcResult.errorMessage);
    }

    this.ensureEmptyLinesPresentAtEnd(10);
    this.setLastEditableRow(this.dataSource.data);
    this.isLoadingInside = false;
  }

  private ensureCalculationEntryHasCorrectNumericalFormats(calculationEntry: CalculationEntry): void {
    for (const [key, value] of Object.entries(calculationEntry)) {
      if (isNaN(value) && value !== 0 && !Array.isArray(value) && key !== 'formulaFactor' && key !== 'text') {
        calculationEntry[key] = this.checkValueAndTransformIfItNumber(value) ?? value;
      }
    }
  }

  private ensureEmptyLinesPresentAtEnd(countOfEmptyLines: number): void {
    let emptyLines = 0;
    for (let i = this.dataSource.data.length - 1; i > 0; i--) {
      if (this.checkIfRowIsEmpty(this.dataSource.data[i])) {
        emptyLines++;
      } else {
        break;
      }
    }

    let lastRowIndex = this.dataSource.data[this.dataSource.data.length - 1].rowIndex;
    if (countOfEmptyLines > emptyLines) {
      const rowsToAdd = countOfEmptyLines - emptyLines;
      for (let i = 0; i < rowsToAdd; i++) {
        this.dataSource.data.push(this.getEmptyEntry(++lastRowIndex));
      }

      this.dataSource.data = [...this.dataSource.data];
    }
  }

  private getEmptyEntry(index: number): CalculationEntry {
    const emptyEntry: CalculationEntry = {
      rowIndex: index,
      isInternal: false,
      subPositionIdentifier: null,
      text: null,
      formulaFactor: null,
      factor: null,
      divisor: null,
      labourHours: null,
      labourTotalSum: null,
      priceComponents: this.additions.priceComponents.map((pc: AvaProjectPriceComponentAdditionGet) => {
        const entryPriceComponent: CalculationEntryPriceComponent = {
          label: pc.priceComponent,
          sum: null
        };
        return entryPriceComponent;
      }),
      sum: null,
      sumAfterAdditions: null
    };

    return emptyEntry;
  }

  private setCalculationEntries(positionCalculation: PositionCalculation): void {
    const entries: CalculationEntry[] = positionCalculation.calculationEntries || [];
    setTimeout(() => {
      this.ngZone.run(() => {
        this.dataSource.data = [...this.addNewLineIfLastOneNotEmpty(entries)];
        this.setLastEditableRow(this.dataSource.data);
        this.hasLoaded = true;
        if (!this.isInnerWindow) {
          this.checkValueSelectedRowTable(true);
        }
      });
    }, 1);
  }

  private addNewLineIfLastOneNotEmpty(entries?: CalculationEntry[]): CalculationEntry[] {
    // Add a new line if the last one is not completely empty or if there are none
    if (entries.length === 0) {
      entries.push(this.getEmptyEntry(0));
    } else {
      const lastEntry: CalculationEntry = entries[entries.length - 1];
      const lastEntryIsNotEmpty: boolean = Object.keys(lastEntry)
        .filter((p: string) => p !== 'rowIndex' && p !== 'sum' && p !== 'sumAfterAdditions' && p !== 'labourTotalSum')
        .some((e: string) => {
          let result = false;
          if (e === 'subPositionIdentifier') {
            return !!lastEntry.subPositionIdentifier;
          } else if (e === 'priceComponents' && lastEntry.priceComponents) {
            result = lastEntry.priceComponents.some((pc) => !!pc.sum);
          } else {
            result = !!lastEntry[e];
          }

          return result;
        });
      if (lastEntryIsNotEmpty) {
        entries.push(this.getEmptyEntry(lastEntry.rowIndex + 1));
      }
    }

    return entries;
  }

  onKey(event: KeyboardEvent): void {
    if (this.isCursorOutsideText) {
      this.keyupControlService.onKey(event, this.displayedColumns, this.clipboardWord);
    }
  }

  removeProductReferenceInRow(row: CalculationEntry): void {
    this.modalService
      .openModal(ModalConfirmComponent, {
        dialogType: ConfirmationType.Delete,
        data: ['Löschen', 'Produkt'],
        autoFocus: false
      })
      .afterClosed()
      .subscribe((res: boolean) => {
        if (res) {
          this.deleteRow(row);
        }
      });
  }

  public preventKeyDownEvent(event: KeyboardEvent, row: CalculationEntry, ignoreNavigation?: boolean): void {
    const target = event.target as HTMLInputElement;
    this.lastFocusedRowIndex = row.rowIndex;
    switch (event.key) {
      case 'Insert':
        this.selectedElementId = event.target['id'];
        this.addRow(row, false);
        event.stopPropagation();
        break;
      case 'F5':
        this.listSelectedRows = [this.lastFocusedRowIndex];
        this.cutSelectedRowsPlus();
        break;
      case 'F6':
        for (const itemName of Object.keys(TABLE_REPL_PRICE_COMPONENT)) {
          if (target.id.includes(itemName)) {
            this.openModalToAddItem(row, TABLE_REPL_PRICE_COMPONENT[itemName]);
            break;
          }
        }
        break;
      case 'ArrowRight':
      case 'ArrowLeft':
      case 'ArrowDown':
      case 'ArrowUp':
        if (ignoreNavigation) {
          return;
        }

        if (
          ((event.key === 'ArrowRight' && target.selectionStart !== target.value.length) ||
            (event.key === 'ArrowLeft' && target.selectionStart !== 0)) &&
          target.type === 'text' &&
          // The following condition ensure that moving via arrow keys still works
          // when the full text (or part of it) is selected, since we don't want to
          // just move the cursor in this case.
          // For example, when we enter focus in an input, then we select all. Now, when
          // the user continues navigation via keyboard arrow, the next cell should be
          // focused, and not move the cursor just inside the current cell
          target.selectionStart === target.selectionEnd
        ) {
          this.isCursorOutsideText = false;
        } else {
          if ((row.rowIndex === 1 && event.key === 'ArrowUp') || row.rowIndex === this.lastEditableRowIndex) {
            return;
          }

          const index =
            event.key === 'ArrowDown'
              ? this.focusRowService.focusedRowNumber + 1
              : event.key === 'ArrowUp'
              ? this.focusRowService.focusedRowNumber - 1
              : this.focusRowService.focusedRowNumber;
          this.changeTotalSumByRowIndex(index);
          this.keyupControlService.preventKeyDownEvent(event);
          this.isCursorOutsideText = true;
        }
        break;
    }
  }

  showContextMenu(event: MouseEvent, row: CalculationEntry): void {
    // Taken from https://stackblitz.com/edit/angular-material-context-menu-table?file=app%2Fcontext-menu-example.ts
    if (!this.isReadOnly) {
      this.contextMenuSettingsService.setDefaultSettings(event, row as any, this.contextMenuPosition, this.contextMenu.first);
    }
  }

  deleteRow(row: CalculationEntry, ignoreRecalculateCall?: boolean): void {
    this.isChanged = true;
    const currentRows: CalculationEntry[] = [...this.dataSource.data];
    const delIndex: number = currentRows.findIndex((r) => r === row);
    if (delIndex > -1) {
      currentRows.splice(delIndex, 1);
      currentRows.forEach((row: CalculationEntry, newIndex) => {
        row.rowIndex = ++newIndex;
      });
      this.dataSource.data = currentRows;

      if (row.subPositionIdentifier) {
        this.deleteSubRow(delIndex, row.subPositionIdentifier);
      }

      if (!ignoreRecalculateCall) {
        this.recalculate();
      }
      this.checkValueSelectedRowTable(true);
    }
  }

  deleteSubRow(delIndex: number, subPositionIdentifier: string): void {
    const rowsAfterDeletedRow = [...this.dataSource.data].slice(delIndex);
    const nextSubRow = rowsAfterDeletedRow.findIndex((v) => v.subPositionIdentifier);
    const nextEmptyRow = this.getLastNonEmptyRowIndex(rowsAfterDeletedRow);
    const subRows = rowsAfterDeletedRow.slice(0, nextSubRow > -1 ? nextSubRow : nextEmptyRow);
    if (subRows.length) {
      subRows.forEach((row) => {
        // We need to set 'ignoreRecalculateCall' to true, otherwise we'd trigger a
        // recalculation for every deleted row
        this.deleteRow(row, true);
      });
    }

    if (subPositionIdentifier) {
      this.changeSubPositionIdentifierAfterDeleting(subPositionIdentifier);
    }
  }

  private changeSubPositionIdentifierAfterDeleting(subPositionIdentifier: string): void {
    const listSubPosition = this.dataSource.data.filter((item) => item.subPositionIdentifier);
    let startChange = false;
    let finishChange = false;
    let identifierFirstMain = '';
    listSubPosition.forEach((row) => {
      const listSubPositionBefore = listSubPosition.filter((item) => item.rowIndex < row.rowIndex);
      if (
        !finishChange &&
        row.subPositionIdentifier &&
        row.subPositionIdentifier.slice(0, subPositionIdentifier.length) === subPositionIdentifier
      ) {
        identifierFirstMain = this.correctIdentifier(listSubPositionBefore, row, identifierFirstMain, subPositionIdentifier);
        startChange = true;
      } else if (startChange) {
        identifierFirstMain = this.correctIdentifier(listSubPositionBefore, row, identifierFirstMain);
        finishChange = true;
      }
    });
  }

  correctIdentifier(
    listSubPositionBefore: CalculationEntry[],
    row: CalculationEntry,
    identifierFirstMain: string,
    subPositionIdentifier?: string
  ): string {
    let newSubPositionIdentifier = subPositionIdentifier
      ? row.subPositionIdentifier.slice(subPositionIdentifier.length + 1)
      : row.subPositionIdentifier;

    const indexFirst = newSubPositionIdentifier.indexOf('.');
    if (indexFirst >= 0) {
      const identifierFirst = identifierFirstMain || newSubPositionIdentifier.slice(0, indexFirst);
      const identifierLastPart = newSubPositionIdentifier.slice(indexFirst);
      newSubPositionIdentifier = `${Number(identifierFirst)}${identifierLastPart}`;
    } else {
      while (listSubPositionBefore.find((item) => item.subPositionIdentifier === newSubPositionIdentifier)) {
        newSubPositionIdentifier = `${Number(newSubPositionIdentifier) + 1}`;
      }
      identifierFirstMain = newSubPositionIdentifier;
    }
    row.subPositionIdentifier = newSubPositionIdentifier;
    return identifierFirstMain;
  }

  public openDialogListFilter(row: CalculationEntry): void {
    this.userSettingsService.currentUserSettings.pipe(take(1)).subscribe((currentUserSettings) => {
      if (!currentUserSettings.showMultiComponentsViewInMainWindow) {
        this.copyCalculationViewMessengerService.showCopyCalculationViewWindow();
      } else {
        const showedList = getStorage<string[]>('listViews', [] as string[]) as string[];
        if (!showedList.includes(WindowType.CopyCalc)) {
          this.showedViewsService.setShowedViews([...showedList, WindowType.CopyCalc]);
        }
      }
    });
    this.selectedRowTable = row;
    this.changeFormulaFactorService.setRowForChangeFormulaFactor(this.selectedRowTable);
  }

  private applyCopiedCalculationRows(rows: CalculationEntry[]): void {
    this.selectedElementId = null;
    if (rows && rows.length) {
      // There might be cases where the price components don't match, so we have to adjust them in advance.
      const sourcePriceComponents = rows[0].priceComponents;
      if (sourcePriceComponents.length > this.additions.priceComponents.length) {
        this.notificationsService.error('Die Preiskomponenten der Kalkulation passen nicht zum aktuellen Projekt.');
        return;
      } else if (sourcePriceComponents.length < this.additions.priceComponents.length) {
        for (let i = 0; i < this.additions.priceComponents.length; i++) {
          while (rows[0].priceComponents.length <= i) {
            rows.forEach((row) => {
              row.priceComponents.push({
                label: this.additions.priceComponents[i].priceComponent,
                sum: null
              });
            });
          }
        }
      } else {
        const priceComponentsNameMismatch = this.additions.priceComponents.some(
          (pc, index) => pc.priceComponent !== sourcePriceComponents[index].label
        );

        if (priceComponentsNameMismatch) {
          rows.forEach((row) => {
            row.priceComponents.forEach((pc, index) => {
              pc.label = this.additions.priceComponents[index].priceComponent;
            });
          });
        }
      }

      // When copying a full calculation, we don't want to copy non-empty rows as well
      let lastNonEmptyRowIndex = 0;
      for (let i = rows.length - 1; i >= 0; i--) {
        const row = rows[i];
        if (!this.checkIfRowIsEmpty(row)) {
          lastNonEmptyRowIndex = row.rowIndex;
          break;
        }
      }

      const rowsToCopy = rows.filter((r) => r.rowIndex <= lastNonEmptyRowIndex);
      this.copyRows(rowsToCopy, this.userSettings?.insertCopiedCalculationToRedMarker ?? false);
      this.nextSelectedRow();
    }
  }

  private appliedCopiedCalculationRow(row: CalculationEntry): void {
    this.selectedElementId = null;
    if (this.selectedRowTable) {
      // There might be cases where the price components don't match, so we have to adjust them in advance.
      const sourcePriceComponents = row.priceComponents;
      if (sourcePriceComponents.length > this.additions.priceComponents.length) {
        this.notificationsService.error('Die Preiskomponenten der Kalkulation passen nicht zum aktuellen Projekt.');
        return;
      } else if (sourcePriceComponents.length < this.additions.priceComponents.length) {
        for (let i = 0; i < this.additions.priceComponents.length; i++) {
          while (row.priceComponents.length <= i) {
            row.priceComponents.push({
              label: this.additions.priceComponents[i].priceComponent,
              sum: null
            });
          }
        }
      } else {
        const priceComponentsNameMismatch = this.additions.priceComponents.some(
          (pc, index) => pc.priceComponent !== sourcePriceComponents[index].label
        );

        if (priceComponentsNameMismatch) {
          row.priceComponents.forEach((pc, index) => {
            pc.label = this.additions.priceComponents[index].priceComponent;
          });
        }
      }

      this.copyRows([row], this.userSettings?.insertCopiedCalculationToRedMarker ?? false);
      this.nextSelectedRow();
    }
  }

  private checkValueSelectedRowTable(isForceChange = false): void {
    if (!this.selectedRowTable || isForceChange) {
      let emptyRowIndex = 0;
      for (let i = this.dataSource.data.length - 1; i >= 0; i--) {
        if (!this.checkIfRowIsEmpty(this.dataSource.data[i])) {
          this.selectedRowTable = this.dataSource.data[emptyRowIndex];
          this.changeFormulaFactorService.setRowForChangeFormulaFactor(this.selectedRowTable);
          break;
        }
        emptyRowIndex = i;
      }
    }
  }

  private applyCopiedArticleRow(selection: MasterDataProductGet, row?: CalculationEntry): void {
    this.selectedElementId = null;
    // TODO -> Should set which items are connected from the master data
    if (!row) {
      row = this.selectedRowTable;
    }
    if (row) {
      const priceComponentWithSum = this.additions.priceComponents.find((pc) => pc.priceComponentType === selection.priceComponentType);
      if (!priceComponentWithSum) {
        this.notificationsService.error('Es ist keine Preiskomponente für die Auswahl im Projekt angelegt.');
      } else {
        const newRow: CalculationEntry = {
          ...row,
          text: `${selection.name} (${selection.unit})`,
          masterProductId: selection.id,
          priceComponents: this.additions.priceComponents.map((apc) => {
            return {
              label: apc.priceComponent,
              sum: apc.priceComponent === priceComponentWithSum.priceComponent ? selection.price : null
            };
          })
        };
        this.dataSource.data = [
          ...this.dataSource.data.filter((r) => r.rowIndex < row.rowIndex),
          newRow,
          ...this.dataSource.data.filter((r) => r.rowIndex > row.rowIndex)
        ];
        this.dataSource.data.forEach((row: CalculationEntry, index: number) => (row.rowIndex = ++index));
        this.isChanged = true;
        this.recalculate();
        this.nextSelectedRow();

        this.currentPositionCalculationGetService.currentPositionCalc.pipe(take(2), skip(1)).subscribe((calc: PositionCalculationGet) => {
          const index = row.rowIndex - 1;
          const newSum = calc.positionCalculation.calculationEntries[index].sum;
          if (newSum !== this.dataSource.data[index].sum) {
            this.dataSource.data[index].sum = newSum;
            this.dataSource.data[index].priceComponents = calc.positionCalculation.calculationEntries[index].priceComponents;
          }
        });
      }
    } else {
      this.notificationsService.info('Keine Zeile zum Einfügen ausgewählt, bitte erst in eine Zeile klicken.');
    }
  }

  private copyRows(newRowList: CalculationEntry[], isCopiedToEnd: boolean): void {
    if (newRowList?.length > 0) {
      // There might be cases where the price components don't match, so we have to adjust them in advance.
      const sourcePriceComponents = newRowList[0].priceComponents;
      if (sourcePriceComponents.length > this.additions.priceComponents.length) {
        this.notificationsService.error('Die Preiskomponenten der Kalkulation passen nicht zum aktuellen Projekt.');
        return;
      } else {
        const priceComponentsNameMismatch = this.additions.priceComponents.some(
          (pc, index) => pc.priceComponent !== sourcePriceComponents[index].label
        );
        if (priceComponentsNameMismatch) {
          newRowList.forEach((row) => {
            row.priceComponents.forEach((pc, index) => {
              pc.label = this.additions.priceComponents[index].priceComponent;
            });
          });
        }
      }
    }

    if (this.selectedRowTable && newRowList?.length) {
      let indexWhereCopiedRowsInserted = 0;
      if (isCopiedToEnd) {
        indexWhereCopiedRowsInserted = this.selectedRowTable.rowIndex;
      } else {
        indexWhereCopiedRowsInserted = this.getLastNonEmptyRowIndex(this.dataSource.data) + 1;
        if (indexWhereCopiedRowsInserted === 2) {
          // Could mean that the first row is also empty
          if (this.dataSource.data?.length > 0 && this.checkIfRowIsEmpty(this.dataSource.data[0])) {
            indexWhereCopiedRowsInserted = 1;
          }
        }
      }
      if (this.selectedRowTable && newRowList?.length && !!newRowList.find((r) => r.subPositionIdentifier)) {
        newRowList = this.subpositionIdentifier.validateSubpositionIdentifier(this.dataSource.data, newRowList);
      }

      if (isCopiedToEnd) {
        this.subpositionIdentifier.validateSubpositionIdentifier(this.dataSource.data, newRowList);
      }

      this.dataSource.data = [
        ...this.dataSource.data.filter((r) => r.rowIndex < indexWhereCopiedRowsInserted),
        ...newRowList,
        ...this.dataSource.data.filter((r) => r.rowIndex >= indexWhereCopiedRowsInserted)
      ];
      this.dataSource.data.forEach((row: CalculationEntry, index: number) => (row.rowIndex = ++index));

      this.isChanged = true;
      this.recalculate();
    }
  }

  addRow(nextRow: CalculationEntry, addAfter: boolean, additionRows?: CalculationEntry[], reWrite?: boolean): void {
    const newRows = additionRows || [
      {
        rowIndex: 0,
        text: null,
        isInternal: false,
        labourTotalSum: null,
        priceComponents: nextRow.priceComponents.map((pc) => {
          return {
            label: pc.label
          };
        }),
        sum: null,
        sumAfterAdditions: null
      }
    ];

    this.fixDoubledSubPosition(newRows);

    if (reWrite) {
      const start = this.dataSource.data.filter((r) => r.rowIndex < nextRow.rowIndex);
      this.dataSource.data = [...start, ...newRows, ...this.dataSource.data.filter((r) => r.rowIndex >= nextRow.rowIndex + newRows.length)];
    } else if (addAfter) {
      this.dataSource.data = [
        ...this.dataSource.data.filter((r) => r.rowIndex <= nextRow.rowIndex),
        ...newRows,
        ...this.dataSource.data.filter((r) => r.rowIndex > nextRow.rowIndex)
      ];
    } else {
      this.dataSource.data = [
        ...this.dataSource.data.filter((r) => r.rowIndex < nextRow.rowIndex),
        ...newRows,
        ...this.dataSource.data.filter((r) => r.rowIndex >= nextRow.rowIndex)
      ];
    }
    this.dataSource.data = this.dataSource.data.map((row, index) => {
      row.rowIndex = index + 1;
      return row;
    });
    this.isChanged = true;
    this.recalculate();
  }

  public onDblClick(e: MouseEvent, row: CalculationEntry): void {
    if (!this.isInnerWindow) {
      return;
    }

    this.userSettingsService.currentFullSettings.pipe(take(1)).subscribe((setting: UserSettings) => {
      if (!setting.copyWholeSubPositionWhenCopyingSubPositionRow) {
        if (row.subPositionIdentifier && e.ctrlKey) {
          const rows = this.subpositionsForCurrentPositionService.getSubpositionForCurrentPosition(row, this.dataSource.data);
          this.copyCalculationViewMessengerService.sendSelectedCalculationRowsToCopyToMainWindow(rows);
        } else {
          this.copyCalculationViewMessengerService.sendSelectedCalculationRowToCopyToMainWindow(row);
        }
      } else {
        if (row.subPositionIdentifier && !e.ctrlKey) {
          const rows = this.subpositionsForCurrentPositionService.getSubpositionForCurrentPosition(row, this.dataSource.data);
          this.copyCalculationViewMessengerService.sendSelectedCalculationRowsToCopyToMainWindow(rows);
        } else {
          this.copyCalculationViewMessengerService.sendSelectedCalculationRowToCopyToMainWindow(row);
        }
      }
    });
  }

  openModalToAddItem(row: CalculationEntry, priceComponentType: PriceComponentType): void {
    this.selectedRowTable = row;
    this.changeFormulaFactorService.setRowForChangeFormulaFactor(this.selectedRowTable);
    this.modalService
      .openModal(ModalAddItemToTableComponent, { dialogType: ConfirmationType.General, data: priceComponentType })
      .afterClosed()
      .subscribe((masterProduct: MasterDataProductGet) => {
        if (masterProduct) {
          this.applyCopiedArticleRow(masterProduct);
        }
      });
  }

  setLastEditableRow(rows: CalculationEntry[]): void {
    // This is a bit of a hack, but we're just setting something up here to ensure that all rows are editable by default
    this.lastEditableRowIndex = Number.MAX_VALUE;

    const freeRowsBelow = 2;

    const lastNonEmptyRowIndex = this.getLastNonEmptyRowIndex(rows);

    if (lastNonEmptyRowIndex === Number.MAX_VALUE) {
      this.lastEditableRowIndex = 3;
    } else {
      this.lastEditableRowIndex = lastNonEmptyRowIndex + freeRowsBelow;
    }
    this.currentPositionCalculationGetService.setIsCalculationDataLoading(true);
  }

  private getLastNonEmptyRowIndex(rows: CalculationEntry[]): number {
    for (let i = rows.length - 1; i > 0; i--) {
      const row = rows[i];
      const rowIsEmpty = this.checkIfRowIsEmpty(row);
      if (!rowIsEmpty) {
        return row.rowIndex;
      }
    }

    return 1;
  }

  private checkIfRowIsEmpty(row: CalculationEntry): boolean {
    return (
      !row.subPositionIdentifier &&
      !row.sum &&
      !row.text &&
      !row.formulaFactor &&
      row.factor == null &&
      !row.divisor &&
      row.labourHours == null &&
      (row.priceComponents == null || !row.priceComponents.some((pc) => pc.sum != null))
    );
  }

  setActiveRow(event: MouseEvent, row: CalculationEntry): void {
    const tag = event.target as HTMLInputElement;
    if (!this.isInnerWindow) {
      if (!event.button && this.listSelectedRows.length) {
        if (tag['tagName'] === 'INPUT' && !tag.disabled) {
          this.clearSelection();
        }
      }
      if (!this.isInnerWindow) {
        this.selectedRowTable = row;
        this.changeFormulaFactorService.setRowForChangeFormulaFactor(this.selectedRowTable);
      }
      if (this.listSelectedRows.length < 1 && tag['tagName'] === 'INPUT' && !tag.disabled && tag['type'] !== 'checkbox') {
        this.changeTotalSumByRowIndex(row.rowIndex);
      } else {
        this.changeTotalSumByRowIndex(null);
      }
    }
  }

  startSelectRow(rowIndex: number): void {
    this.focusRowService.setFocusedRowByIndex(null);
    this.listMove = [rowIndex, rowIndex];
    this.selectRowsService.markFirst(rowIndex);
    let currentRowIndex;
    this.mousemove$ = fromEvent(this.el.nativeElement, 'mousemove').subscribe((elEv: MouseEvent) => {
      // Added changes as per this comment https://github.com/Dangl-IT/Dangl.PfeifferAVA/pull/2469#issuecomment-1547509766
      const rowIndex = +(elEv.target as HTMLElement).innerText;
      if (rowIndex !== currentRowIndex) {
        currentRowIndex = rowIndex;
        this.moveSelectRow(elEv, currentRowIndex);
      }
    });
  }

  stopSelectRow(event?: MouseEvent | null, selectedRowStop = 0): void {
    if (!event?.button) {
      const newList = this.selectRowsService.getNewList(selectedRowStop, event?.ctrlKey, event?.shiftKey);
      if (this.listSelectedRows.length !== newList.length || !this.listSelectedRows.every((item) => newList.includes(item))) {
        this.listSelectedRows = newList;
        this.changeTotalSumByRowIndex(null);
        if (this.listSelectedRows.length && this.isReadOnly) {
          this.saveRows();
        }
      }
    }
    if (this.mousemove$) {
      this.mousemove$.unsubscribe();
    }
    this.listMove = [];
    this.saveSelectedText();
  }

  moveSelectRow(event: MouseEvent, rowIndex: number): void {
    if (event.buttons === 1 && !event.button && this.listMove?.length) {
      this.listMove[1] = rowIndex;
    }
  }

  showMoveSelectRow(rowIndex: number): boolean {
    if (this.listMove?.length) {
      const min: number = Math.min(...this.listMove);
      const max: number = Math.max(...this.listMove);
      return rowIndex >= min && rowIndex <= max;
    } else {
      return false;
    }
  }

  private ensureRowSelectionReset(): void {
    this.listSelectedRows = this.selectRowsService.reset();
  }

  selectedRow(rowIndex: number): boolean {
    return this.listSelectedRows.includes(rowIndex);
  }

  saveRows(isClean?: boolean): void {
    if (isClean) {
      this.savedSelectedRows = [];
    } else {
      this.savedSelectedRows = this.dataSource.data
        .filter((item: CalculationEntry) => this.listSelectedRows.includes(item.rowIndex))
        .map((r) => ({ ...r, priceComponents: r.priceComponents.map((rr) => ({ ...rr })) }));
      this.clipboardService.copyData(this.clipboardWord, this.savedSelectedRows);
    }
  }

  copySavedRows(event, currentRow: CalculationEntry, reWrite = false): void {
    const id = event?.target['id'] || `${currentRow.rowIndex + (reWrite ? 0 : 1)}-text`;
    this.selectedElementId = this.getNextId(id, this.savedSelectedRows.length);
    this.addRow(
      currentRow,
      false,
      this.savedSelectedRows.map((r) => ({ ...r, priceComponents: r.priceComponents.map((rr) => ({ ...rr })) })),
      reWrite
    );
  }

  deleteSelectedRows(): void {
    const currentRows: CalculationEntry[] = this.dataSource.data.filter((row) => this.listSelectedRows.includes(row.rowIndex));
    currentRows.forEach((oneRow) => this.deleteRow(oneRow, true));
    this.recalculate();
    this.listSelectedRows = [];
    this.selectRowsService.listSelectedRows = [];
  }

  nextSelectedRow(): void {
    this.selectedRowTable = this.dataSource.data[this.getLastNonEmptyRowIndex(this.dataSource.data)];
    this.changeFormulaFactorService.setRowForChangeFormulaFactor(this.selectedRowTable);
  }

  isMark(element: CalculationEntry): boolean {
    return element.rowIndex === this.selectedRowTable?.rowIndex;
  }

  clearSelection(): void {
    this.listSelectedRows = this.selectRowsService.init();
  }

  showHeaderContextMenu(event: MouseEvent): void {
    this.contextMenuSettingsService.setDefaultSettings(event, null, this.contextMenuPosition, this.contextMenu.last);
  }

  editListColumns(): void {
    this.modalService
      .openModal(ShowingColumnsModalComponent, {
        dialogType: ConfirmationType.General,
        data: {
          component: 'Calculation',
          columns: this.defaultDisplayedColumns
        },
        disableClose: true,
        autoFocus: false
      })
      .afterClosed()
      .subscribe(() => {});
  }

  drop(event: CdkDragDrop<PositionDto>): void {
    if (event.item.data?.rowIndex) {
      this.dropSubPosition(event);
      return;
    }
    this.isLoadingInside = true;
    combineLatest([
      this.selectedProjectMessengerService.selectedProject,
      this.selectedSpecificationMessengerService.selectedServiceSpecification
    ])
      .pipe(
        takeUntil(this.$destroy),
        switchMap(([project, s]: [ProjectGet, { avaProjectId: string; project: ProjectDto }]) => {
          return this.positionCalculationsClient.getPositionCalculation(project.id, s.avaProjectId, event.item.data.id);
        })
      )
      .subscribe(({ positionCalculation }: PositionCalculationGet) => {
        const dataToCopy = this.getDataForCopyingCalculatedEntries(positionCalculation.calculationEntries);
        dataToCopy.copyCalculationEntries = this.subpositionIdentifier.validateSubpositionIdentifier(
          this.dataSource.data,
          dataToCopy.copyCalculationEntries
        );
        this.positionCalculationData.calculationEntries.splice(
          dataToCopy.indexOfEmptyRow,
          dataToCopy.copyCalculationEntries.length,
          ...dataToCopy.copyCalculationEntries
        );
        this.setCalculationEntries(this.positionCalculationData);
        this.isChanged = true;
        this.dataSource.data = [...this.positionCalculationData.calculationEntries];
        this.recalculate();
        this.isLoadingInside = false;
      });
  }

  dropSubPosition(event: CdkDragDrop<PositionDto>): void {
    const indexOfEmptyRow = this.getLastNonEmptyRowIndex(this.positionCalculationData.calculationEntries);
    const sourceAvaPositionId = event.item.data.avaPositionId as string;
    const sourceStartRowIndex = event.item.data.rowIndex as number;
    const localPositionId = this.selectedElementId;

    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        filter((p) => !!p && !!p.avaProjectId && !!p.parentProjectId),
        takeUntil(this.$destroy),
        first()
      )
      .subscribe((selectedServSpec) => {
        this.positionCalculationsClient
          .getPositionCalculation(selectedServSpec.parentProjectId, selectedServSpec.avaProjectId, sourceAvaPositionId)
          .subscribe((sourceCalculation) => {
            if (this.selectedElementId != localPositionId) {
              return;
            }

            let shouldTake = false;
            let rowsToCopy = sourceCalculation.positionCalculation.calculationEntries.filter((entry) => {
              if (!shouldTake && entry.rowIndex === sourceStartRowIndex) {
                shouldTake = true;
                return true;
              }

              if (shouldTake) {
                if (entry.subPositionIdentifier && !entry.subPositionIdentifier.includes('.')) {
                  shouldTake = false;
                  return false;
                }

                return true;
              }

              return false;
            });

            let currentIndex = indexOfEmptyRow + 1;
            rowsToCopy.forEach((r) => (r.rowIndex = currentIndex++));
            rowsToCopy = this.subpositionIdentifier.validateSubpositionIdentifier(this.dataSource.data, rowsToCopy);
            this.isLoadingInside = true;
            this.positionCalculationData.calculationEntries.splice(indexOfEmptyRow, rowsToCopy.length, ...rowsToCopy);

            this.setCalculationEntries(this.positionCalculationData);
            this.isChanged = true;
            this.dataSource.data = [...this.positionCalculationData.calculationEntries];
            this.recalculate();
          });
      });
  }

  trackByFn(index, item): void {
    return item.rowIndex;
  }

  getBlur(): void {
    this.isNotActivePriceComponent = true;
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        if (this.isNotActivePriceComponent) {
          this.ngZone.run(() => this.calculationTotalsService.setActivePriceComp(''));
        }
      }, 100);
    });
  }

  getFocus(row: CalculationEntry, priceComponent?: string): void {
    this.calculationTotalsService.setActivePriceComp(priceComponent || 'labourHoursSum');
    this.isNotActivePriceComponent = false;

    this.selectedRowTable = row;
    this.changeFormulaFactorService.setRowForChangeFormulaFactor(this.selectedRowTable);
    this.focusRowService.setFocusedRowByIndex(row.rowIndex);
    this.detailTableModalService.startChangePosition();
  }

  confirmationDeleting(listDel: number | number[], fn: () => void): void {
    let listDelString: string;
    if (Array.isArray(listDel)) {
      listDelString = `Zeilen: ${listDel.length < 10 ? listDel.join(', ') : 'Viele (' + listDel.length + ')'}`;
    } else {
      listDelString = `Zeile ${listDel}`;
    }
    this.modalService
      .openModal(ModalConfirmComponent, { dialogType: ConfirmationType.Delete, data: ['Löschen', 'Zeilen', listDelString, 'red'] })
      .afterClosed()
      .subscribe((e: boolean) => {
        if (e) {
          fn();
        }
      });
  }

  deleteRowPlus(item: CalculationEntry): void {
    if (this.userSettings.promptForConfirmationWhenDeletingRows) {
      this.confirmationDeleting(item.rowIndex, () => this.deleteRow(item));
    } else {
      this.deleteRow(item);
    }
  }

  deleteSelectedRowsPlus(isSkipConfirmation: boolean): void {
    if (this.userSettings.promptForConfirmationWhenDeletingRows && !isSkipConfirmation) {
      this.confirmationDeleting(this.listSelectedRows, () => this.deleteSelectedRows());
    } else {
      this.deleteSelectedRows();
    }
  }

  private checkValueAndTransformIfItNumber(value: string | number): number | void {
    if (typeof value === 'number') {
      return value;
    }

    if (value.includes(',')) {
      return +value.replace(',', '.');
    }

    if (!isNaN) {
      return +value;
    }
  }

  private scrollToTop(): void {
    this.viewTable?.scrollToOffset(0);
  }

  cutSelectedRowsPlus(): void {
    this.saveRows();
    // Since this is just a cut operation, we want to ignore the settings
    // that ask for confirmation before deletion and just delete the rows
    this.deleteSelectedRows();
  }

  limitWidth(isSame?: boolean): void {
    this.limitColumnWidthService.limitWidth('calculations', isSame);
  }

  private getDataForCopyingCalculatedEntries(calculationEntries: CalculationEntry[]): {
    indexOfEmptyRow: number;
    copyCalculationEntries: CalculationEntry[];
  } {
    const indexOfEmptyRow = this.positionCalculationData.calculationEntries.findIndex((calculationEntrie) => {
      if (this.checkIfRowIsEmpty(calculationEntrie)) {
        return calculationEntrie.rowIndex;
      }
    });

    const indexOfEmptyRowForCopyPosition = calculationEntries.reverse().find((e) => !this.checkIfRowIsEmpty(e))?.rowIndex;

    const copyCalculationEntries = calculationEntries
      .reverse()
      .filter((calculationEntrie, index) => {
        if (indexOfEmptyRowForCopyPosition > index) {
          return calculationEntrie;
        }
      })
      .map((calculationEntrie, index) => {
        calculationEntrie.rowIndex = indexOfEmptyRow + 1 + index;
        return calculationEntrie;
      });
    return {
      indexOfEmptyRow: indexOfEmptyRow,
      copyCalculationEntries: copyCalculationEntries
    };
  }

  getNextId(currentRowId: string, additionLength: number): string {
    const arrId = currentRowId.split('-');
    arrId[0] = `${Number(arrId[0]) + additionLength - 1}`;
    return arrId.join('-');
  }

  private changeTotalSumByRowIndex(index: number | null): void {
    this.currentFocusedRowIndex = index;
    this.currentPositionCalculationGetService.currentPositionCalc.pipe(take(1)).subscribe((calc: PositionCalculationGet) => {
      this.focusRowService.setFocusedRowByIndex(index);
      this.calculationTotalsService.calculateDetailsForSelection(calc.positionCalculation);
    });
  }

  setCursorToEdit(event: MouseEvent, isRightAligned: boolean): void {
    if (!this.isInnerWindow) {
      event.preventDefault();

      const getCssStyle = (element, prop) => {
        return window.getComputedStyle(element, null).getPropertyValue(prop);
      };

      const getCanvasFont = (el = document.body) => {
        const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
        const fontSize = getCssStyle(el, 'font-size') || '16px';
        const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman';

        return `${fontWeight} ${fontSize} ${fontFamily}`;
      };

      const inputElement = event.target as HTMLInputElement;

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context.font = getCanvasFont(inputElement);

      const metrics = context.measureText(inputElement.value);
      const totalTextWidth = metrics.width;

      if (isRightAligned) {
        const inputX = inputElement.getBoundingClientRect().right;
        const clickWidth = inputX - event.x;
        // Now we try to calculate how many characters inside
        // the text the click was at
        let charIndex = inputElement.value.length - Math.floor((clickWidth / totalTextWidth) * inputElement.value.length);
        if (charIndex < 0) {
          charIndex = 0;
        }
        inputElement.selectionStart = charIndex;
        inputElement.selectionEnd = charIndex;
      } else {
        const inputX = inputElement.getBoundingClientRect().x;
        const clickWidth = event.x - inputX;
        // Now we try to calculate how many characters inside
        // the text the click was at
        const charIndex = Math.floor((clickWidth / totalTextWidth) * inputElement.value.length);
        inputElement.selectionStart = charIndex;
        inputElement.selectionEnd = charIndex;
      }
    }
  }

  changedFormulaFactorEvent(element): void {
    this.isChanged = true;
    this.recalculate(element);
  }

  copyCurrentLine(row: CalculationEntry): void {
    this.savedSelectedRows = [{ ...row, priceComponents: row.priceComponents.map((rr) => ({ ...rr })) }];
    this.clipboardService.copyData(this.clipboardWord, this.savedSelectedRows);
  }

  scrolled(event): void {
    this.$scrollViewport.next(event.target.scrollLeft);
    this.detailTableModalService.startChangePosition();
  }

  changeDetailPosition(): void {
    const rect: DOMRect = this.wrapElement.nativeElement.getBoundingClientRect();
    if (rect) {
      this.detailTableModalService.setToDetailTableModal(rect);
    }
  }

  handlerInputChangeEvent(identifier: string, row: CalculationEntry): void {
    this.isChanged = true;
    if (identifier === 'U') {
      const maxSubPositionIdentifier = +this.subpositionIdentifier.getMaxSubPositionIdentifier(this.dataSource.data);
      row.subPositionIdentifier = `${maxSubPositionIdentifier + 1}`;
    } else if (identifier === 'O') {
      this.dataSource.data = [
        ...this.subpositionIdentifier.updateNestingForSubPositionIdentifier(JSON.parse(JSON.stringify(this.dataSource.data)), row)
      ];
    } else {
      row.subPositionIdentifier = identifier !== '' ? identifier : null;
    }
    this.recalculate(row);
  }

  fixDoubledSubPosition(newRows: CalculationEntry[]): void {
    const listIdentifier = this.dataSource.data.filter((item) => item.subPositionIdentifier).map((item) => item.subPositionIdentifier);
    newRows.forEach((item) => {
      if (item.subPositionIdentifier && listIdentifier.includes(item.subPositionIdentifier)) {
        const arrSubPosition = item.subPositionIdentifier.split('.');
        let newSubPosition = '';
        do {
          arrSubPosition[arrSubPosition.length - 1] = `${Number(arrSubPosition[arrSubPosition.length - 1]) + 1}`;
          newSubPosition = arrSubPosition.join('.');
        } while (listIdentifier.includes(newSubPosition));
        item.subPositionIdentifier = newSubPosition;
        listIdentifier.push(newSubPosition);
      }
    });
  }

  goTopMain(): void {
    if (!this.isInnerWindow) {
      const clickEvent = new MouseEvent('mouseup');
      const el = document.querySelector('pa-main-frame > div');
      el?.dispatchEvent(clickEvent);
    }
  }

  private saveSelectedText(): void {
    this.selectedText = window.getSelection().toString().trim();
  }
}
