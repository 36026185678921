<h2>Projekt Löschen</h2>
<p>Wenn das Projekt gelöscht wird, können die Daten nicht wiederhergestellt werden.</p>
<p>
  Um das Projekt zu löschen, gib bitte den Projektnamen <b>{{ projectName }}</b> in das Textfeld ein und Drücke dann auf bestätigen:
</p>
<mat-form-field>
  <mat-label>Projektname</mat-label>
  <input matInput type="text" [(ngModel)]="enteredProjectName" />
</mat-form-field>
<button mat-raised-button color="warn" (click)="deleteProject()" [disabled]="!canDelete">Projekt Löschen</button>
