import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';

import { combineLatest } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { getElementDtoById } from 'app/areas/tree/utils/fn';
import { ProjectQuantityTakeOffType, QuantityTakeOffCalculationType, QuantityTakeOffType } from 'app/generated-client/generated-client';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { AvaHubConnector } from 'app/shared/services/signalr/ava-hub-connector';
import { UserSettingsService } from 'app/shared/services/user-settings.service';

@Component({
  selector: 'pa-invoices-main',
  templateUrl: './invoices-main.component.html',
  styleUrls: ['./invoices-main.component.scss'],
  standalone: true,
  imports: [RouterOutlet]
})
export class InvoicesMainComponent implements OnInit {
  constructor(
    private userSettingsService: UserSettingsService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private route: ActivatedRoute,
    private router: Router,
    private avaHubConnector: AvaHubConnector,
    private ngZone: NgZone,
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.userSettingsService.currentFullSettings,
      this.selectedProjectMessengerService.selectedProject,
      this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(filter((ap) => !!ap)),
      this.route.data,
      this.route.queryParams
    ])
      .pipe(first())
      .subscribe(([userSettings, project, avaProject, routeData, queryParams]) => {
        if (!project?.allowedQuantityTakeOffTypes) {
          this.stopPath();
        } else {
          if (userSettings.automaticallyOpenLastVisitedElement && !queryParams.ignoreLoadingLastQtoLocation) {
            const qtoType = routeData.quantityTakeOffType;
            if (
              qtoType !== QuantityTakeOffType.QuantityEstimation ||
              (qtoType === QuantityTakeOffType.QuantityEstimation && userSettings.automaticallyOpenLastVisitedQuantityEstimationElement)
            ) {
              this.avaHubConnector.getLastVisitedQuantityTakeOffAsync(avaProject.avaProjectId, qtoType).then((lastVisitedQto) => {
                if (lastVisitedQto) {
                  const qtoCalculationType = lastVisitedQto.quantityTakeOffCalculationType;
                  const qtoId = lastVisitedQto.quantityTakeOffId;
                  if (qtoCalculationType === QuantityTakeOffCalculationType.ByPage && !!lastVisitedQto.pageId) {
                    this.router.navigate([qtoId, 'pages', lastVisitedQto.pageId], {
                      relativeTo: this.route
                    });
                  } else if (qtoCalculationType === QuantityTakeOffCalculationType.ByPosition && !!lastVisitedQto.positionId) {
                    const selectedPosition = getElementDtoById(
                      lastVisitedQto.positionId,
                      avaProject.project.serviceSpecifications[0].elements
                    );
                    if (selectedPosition) {
                      this.router.navigate([qtoId, 'positions'], {
                        relativeTo: this.route
                      });
                      setTimeout(() => {
                        this.ngZone.run(() => this.selectedSpecificationElementMessengerService.trySelectElementById(selectedPosition?.id));
                      }, 0);
                    }
                  } else if (
                    qtoCalculationType === QuantityTakeOffCalculationType.ByPosition &&
                    !lastVisitedQto.positionId &&
                    userSettings.showPositionQtoInSingleTable
                  ) {
                    this.router.navigate([qtoId, 'positions'], {
                      relativeTo: this.route
                    });
                  } else {
                    console.warn('Unknown QTO calculation type encountered: ' + qtoCalculationType);
                  }
                } else {
                  if (project?.allowedQuantityTakeOffTypes === ProjectQuantityTakeOffType.Pages) {
                    const newUrl = this.router.url.split('/').slice(0, 6).join('/') + '/all-pages';
                    this.router.navigateByUrl(newUrl);
                  }
                }
              });
            }
          }
        }
      });
  }

  stopPath(): void {
    const newUrl = this.router.url.split('/').slice(0, 6).join('/');
    this.router.navigateByUrl(newUrl);
  }
}
