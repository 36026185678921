<h2>Elementauswahl</h2>
<div fxLayout="row" fxLayoutGap="12px" class="buttons">
  <button mat-raised-button color="primary" type="button" (click)="back()">Zurück</button>
</div>
<div class="main">
  <as-split fxFlexFill #asSplitRightRef (dragEnd)="onDragEnd($event.sizes)">
    <as-split-area [size]="20" [minSize]="10">
      <div *ngIf="formSelection" class="forms">
        <form [formGroup]="formSelection" (ngSubmit)="saveSelection()">
          <div fxLayout="row" fxLayoutGap="12px" class="buttons">
            <button mat-raised-button color="primary" type="button" (click)="changeSelecting()">Auswahl Ändern</button>
            <button mat-raised-button color="primary" type="submit" [disabled]="formSelection.invalid || !isChanged">Speichern</button>
          </div>
          <div fxLayout="column" class="forms-wrap">
            <mat-form-field appearance="fill" *ngIf="formSelection.value.createdAtUtc">
              <mat-label>Erstellt</mat-label>
              <input matInput [matDatepicker]="pickerCreate" formControlName="createdAtUtc" readonly />
              <mat-datepicker-toggle matSuffix [for]="pickerCreate"></mat-datepicker-toggle>
              <mat-datepicker #pickerCreate disabled></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Name</mat-label>
              <input type="text" matInput formControlName="name" required />
            </mat-form-field>
          </div>
        </form>
        <div class="tree" *ngIf="showedAvaProject">
          <pa-main-tree
            [withoutSplitArea]="true"
            [showedAvaProject]="showedAvaProject"
            [updateShowedAvaProject]="isShowedAvaProjectUpdated"
          ></pa-main-tree>
        </div>
      </div>
    </as-split-area>
    <as-split-area [size]="80">
      <div class="relative-parent" fxFlexFill>
        <pa-element [forceReadOnly]="true" [selectedElement]="selectedElement"></pa-element>
      </div>
    </as-split-area>
  </as-split>
</div>
