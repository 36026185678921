import { Pipe, PipeTransform } from '@angular/core';

import { NotificationType } from '../../models/notification-type.model';

@Pipe({
  name: 'notificationType',
  standalone: true
})
export class NotificationTypePipe implements PipeTransform {
  transform(value: NotificationType): string {
    switch (value) {
      case NotificationType.Info:
        return 'Info';
      case NotificationType.Error:
        return 'Fehler';
      case NotificationType.Success:
        return 'Erfolg';
      case NotificationType.Warning:
        return 'Warnung';
    }

    return null;
  }
}
