<div class="main-wrap">
  <pa-project-files-select></pa-project-files-select>
  <div class="wrapper">
    <pa-project-files-list [extensions]="dialogData?.extensions"></pa-project-files-list>
    <pa-project-files-view
      [correlationId]="correlationId"
      modalView="{{ dialogData?.modalData?.length ? dialogData?.modalData : null }}"
      [extensions]="dialogData?.extensions"
    ></pa-project-files-view>
  </div>
</div>
