import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { AddressGet, AddressesClient, ContactGet } from 'app/generated-client/generated-client';

import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { AddressFormComponent } from '../address-form/address-form.component';
import { AddressesService } from 'app/shared/services/lightquery/addresses.service';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { ModalService } from 'app/shared/services/modal.service';
import { SelectedContactMessengerService } from '../../services/selected-contact-messenger.service';
import { UpperPaginatorComponent } from 'app/shared/components/upper-paginator/upper-paginator.component';

@Component({
  selector: 'pa-contact-addresses',
  templateUrl: './contact-addresses.component.html',
  styleUrls: ['./contact-addresses.component.scss'],
  standalone: true,
  imports: [CommonModule, MatTableModule, MatIconModule, MatButtonModule, UpperPaginatorComponent, MatSortModule]
})
export class ContactAddressesComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() readOnly: boolean;
  totalCount: number;
  private contactId: string;
  private $destroy: Subject<boolean> = new Subject<boolean>();
  displayColumn = ['zipCode', 'city', 'street', 'countryCode', 'country', 'isPrimaryAddress', 'isDefaultInvoiceAddress', 'delete'];

  constructor(
    public addressesService: AddressesService,
    private addressesClient: AddressesClient,
    private modalService: ModalService,
    private notificationsService: AvaNotificationsService,
    private selectedContactMessengerService: SelectedContactMessengerService
  ) {}

  ngOnInit(): void {
    if (this.readOnly) {
      this.displayColumn = this.displayColumn.filter(item => item !== 'delete');
      this.addressesService.paginationResult.pipe(takeUntil(this.$destroy)).subscribe(result => {
        this.totalCount = result.totalCount;
      });
    }
    this.selectedContactMessengerService.selectedContact
      .pipe(
        takeUntil(this.$destroy),
        filter((c: ContactGet) => !!c)
      )
      .subscribe((c: ContactGet) => (this.contactId = c.id));

    this.addressesService.initService({
      sorting: { propertyName: 'isPrimaryAddress', isDescending: true },
      sortTableObj: this.sort
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  confirmDeletion(event: MouseEvent, address: AddressGet): void {
    event.stopPropagation();
    this.modalService
      .openModal(ModalConfirmComponent, {
        dialogType: ConfirmationType.Delete,
        data: ['Löschen', 'Adresse', `${address.country} ${address.zipCode} ${address.city} ${address.street}`],
        autoFocus: false
      })
      .afterClosed()
      .subscribe((res: boolean) => {
        if (res) {
          this.addressesClient.deleteAddressForContact(this.contactId, address.id).subscribe(
            () => {
              this.notificationsService.success('Adresse gelöscht');
              this.addressesService.forceRefresh();
            },
            () => this.notificationsService.error('Fehler beim Löschen der Adresse')
          );
        }
      });
  }

  address(row?: AddressGet): void {
    this.modalService.openModal(AddressFormComponent, { dialogType: ConfirmationType.General, data: row?.id, restoreFocus: false });
  }
}
