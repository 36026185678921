import { NgIf, NgClass, NgFor, DecimalPipe, PercentPipe } from '@angular/common';
import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSelect } from '@angular/material/select';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

import { combineLatest, forkJoin, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';

import { CalculateTransferWageService } from '@serv-spec/services/calculate-transfer-wage.service';

import {
  AvaProjectAdditionCalculation,
  AvaProjectAdditionGet,
  AvaProjectAdditionPut,
  AvaProjectAdditionsClient,
  IElementDto,
  MedianHourlyWageCalculation,
  PositionDto,
  PriceComponentType,
  ProjectDto,
  ProjectGet,
  ServiceSpecificationGroupDto
} from 'app/generated-client/generated-client';
import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationAdditionsMessengerService } from 'app/shared/services/messengers/selected-specification-additions-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';

import { PositionTextPipe } from '../../../../../../shared/pipes/ui-data-display/position-text.pipe';
import { PriceComponentTypePipe } from '../../../../../../shared/pipes/ui-data-display/price-component-type.pipe';
import { ProjectCurrencyPipe } from '../../../../../../shared/pipes/ui-data-display/project-currency.pipe';
import { AvaNotificationsService } from '../../../../../../shared/services/ava-notifications.service';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';
import { MatInputDecimalPlacesDirective } from '../../directives/mat-input-decimal-places.directive';

import { CalculationMiddleWagesComponent } from '../calculation-middle-wages/calculation-middle-wages.component';

@Component({
  selector: 'pa-scenario-additions',
  templateUrl: './scenario-additions.component.html',
  styleUrls: ['./scenario-additions.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FlexLayoutDirective,
    MatProgressSpinner,
    NgClass,
    MatButton,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatSuffix,
    MatInputDecimalPlacesDirective,
    NgFor,
    MatIconButton,
    MatIcon,
    MatSelect,
    MatOption,
    CalculationMiddleWagesComponent,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    DecimalPipe,
    PercentPipe,
    PriceComponentTypePipe,
    ProjectCurrencyPipe,
    PositionTextPipe
  ]
})
export class ScenarioAdditionsComponent implements OnInit, OnDestroy {
  @ViewChild('wageCalculationEditor') wageCalculationEditor: CalculationMiddleWagesComponent;

  currentAddition: AvaProjectAdditionGet;
  tryAddition: AvaProjectAdditionCalculation;
  projectId: string;
  avaProjectId: string;
  isChanged: boolean;
  saved: boolean;
  isLoading: boolean;
  positionsList: PositionDto[];
  filteredList: PositionDto[];
  originalPrices: {
    [positionId: string]: {
      unitPrice: number;
      totalPrice: number;
      deltaPercent: number;
    };
  } = {};
  filter = '';
  transferWage = 0;

  // These three additions below are used to show the actual
  // BGK, AGK and WuG parts in the wage calculation part
  laborSiteOperationCostsAddition: number | null;
  laborCompanyOperationCostsAddition: number | null;
  riskAndProfitAddition: number | null;

  totalPrice: number;
  originalTotalPrice: number;
  totalPriceDeltaPercent: number;
  totalPriceDeltaAbsolute: number;
  priceComponentTypes = Object.keys(PriceComponentType) as PriceComponentType[];
  columns = [
    'itemNumber',
    'shortText',
    'quantity',
    'unitTag',
    'unitPrice',
    'totalPrice',
    'delta',
    'originalUnitPrice',
    'originalTotalPrice'
  ];
  private hasSetOriginalPrices = false;
  private $destroy: Subject<boolean> = new Subject<boolean>();
  hasDedicatedWageCalculation = false;
  isChangeNamePriceComponentMode = false;
  savedNamePriceComponentList: string[];
  private lastUsedCalculationWage = 0;
  private currentCalculationWageCalculation: MedianHourlyWageCalculation | null = null;

  calculateTransferWageService = inject(CalculateTransferWageService);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private avaProjectAdditionsClient: AvaProjectAdditionsClient,
    private selectedSpecificationAdditionsMessengerService: SelectedSpecificationAdditionsMessengerService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private avaNotificationsService: AvaNotificationsService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.calculationWageChange.pipe(takeUntil(this.$destroy), distinctUntilChanged(), debounceTime(500)).subscribe(() => {
      if (this.lastUsedCalculationWage !== this.tryAddition.calculationWage) {
        this.lastUsedCalculationWage = this.tryAddition.calculationWage;
        this.selectAdditions();
        this.saved = false;
      }
    });

    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(takeUntil(this.$destroy))
      .subscribe((serviceSpecification) => {
        this.avaProjectId = serviceSpecification?.avaProjectId;
      });

    combineLatest([
      this.selectedProjectMessengerService.selectedProject.pipe(filter((r) => !!r)),
      this.selectedSpecificationAdditionsMessengerService.selectedAdditions.pipe(filter((r) => !!r))
    ])
      .pipe(takeUntil(this.$destroy))
      .subscribe(([project, additions]: [ProjectGet, AvaProjectAdditionGet]) => {
        if (project && additions) {
          this.projectId = project?.id;
          this.currentAddition = additions;
          if (this.currentAddition) {
            this.laborSiteOperationCostsAddition = this.currentAddition.laborSiteOperationCostsAddition;
            this.laborCompanyOperationCostsAddition = this.currentAddition.laborCompanyOperationCostsAddition;
            this.riskAndProfitAddition = this.currentAddition.riskAndProfitAddition;
          }
          this.current();
          this.saved = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  back(): void {
    if (!this.saved) {
      this.modalService
        .openModal(ModalConfirmComponent, {
          dialogType: ConfirmationType.General,
          data: ['Speichern', 'Zuschlagssätze'],
          autoFocus: false
        })
        .afterClosed()
        .subscribe((res: boolean) => {
          if (res) {
            this.saveAdditions(res);
          } else {
            this.goBack();
          }
        });
    } else {
      this.goBack();
    }
  }

  goBack(): void {
    const position = this.route.snapshot.paramMap.get('position');
    if (position) {
      this.router.navigate(['..', { position }], { relativeTo: this.route });
    } else {
      this.router.navigate(['..'], { relativeTo: this.route });
    }
  }

  current(): void {
    setTimeout(() => (this.saved = true), 1);
    this.tryAddition = this.dataToDisplay(JSON.parse(JSON.stringify(this.currentAddition)));
    this.savedNamePriceComponentList = this.tryAddition.priceComponents.map((item) => item.priceComponent);
    this.isChangeNamePriceComponentMode = false;

    this.selectAdditions();
    if (this.wageCalculationEditor) {
      this.wageCalculationEditor.loadOriginalValues();
    }
  }

  onWageCalculationLoaded(wage: MedianHourlyWageCalculation): void {
    this.hasDedicatedWageCalculation = true;
    this.lastUsedCalculationWage = wage.calculationWage;
  }

  selectAdditions(): void {
    this.isLoading = true;
    this.calculateTransferWageService.calculateTransferWage(this.tryAddition);

    this.avaProjectAdditionsClient
      .calculateAvaProjectAdditions(this.projectId, this.currentAddition.avaProjectId, this.dataToSave(this.tryAddition))
      .subscribe((projectDto: ProjectDto) => {
        this.totalPrice = projectDto.serviceSpecifications[0].totalPrice;
        if (!this.hasSetOriginalPrices) {
          this.originalTotalPrice = this.totalPrice;
        } else if (this.originalTotalPrice != 0) {
          this.totalPriceDeltaPercent = this.totalPrice / this.originalTotalPrice - 1;
          this.totalPriceDeltaAbsolute = this.totalPrice - this.originalTotalPrice;
        }

        this.isChanged = false;
        this.positionsList = [];
        this.createList(projectDto.serviceSpecifications[0].elements);
        this.filterList();
        this.hasSetOriginalPrices = true;
        this.isLoading = false;
      });
  }

  saveAdditions(isGoBack: boolean): void {
    this.isLoading = true;
    const projectId = this.projectId;
    const avaProjectId = this.currentAddition.avaProjectId;

    const additionsToSave = this.dataToSave(this.tryAddition);

    this.avaProjectAdditionsClient.editAvaProjectAdditions(projectId, avaProjectId, additionsToSave).subscribe(
      (additions: AvaProjectAdditionGet) => {
        this.selectedSpecificationAdditionsMessengerService.setSelectedAdditions(additions);
        this.hasSetOriginalPrices = false;
        this.totalPriceDeltaAbsolute = 0;

        this.avaNotificationsService.success('Die Zuschlagssätze wurden gespeichert.');
        this.isLoading = false;
        if (isGoBack) {
          this.goBack();
        }
      },
      () => {
        this.avaNotificationsService.error('Fehler beim Speichern der Zuschlagssätze');
        this.isLoading = false;
      }
    );
  }

  private createList(elements: IElementDto[]): void {
    elements.forEach((element: IElementDto) => {
      switch (element.elementType) {
        case 'ServiceSpecificationGroupDto':
          this.createList((<ServiceSpecificationGroupDto>element).elements);
          break;
        case 'PositionDto':
          this.positionsList.push(element as PositionDto);
          if (!this.hasSetOriginalPrices) {
            this.originalPrices[element.id] = {
              unitPrice: (element as PositionDto).unitPrice,
              totalPrice: (element as PositionDto).totalPrice,
              deltaPercent: 0
            };
          } else {
            const newTotal = (element as PositionDto).totalPrice;
            const previousTotal = this.originalPrices[element.id].totalPrice;

            if (previousTotal !== 0) {
              const delta = newTotal / previousTotal - 1;
              this.originalPrices[element.id].deltaPercent = delta;
            } else {
              this.originalPrices[element.id].deltaPercent = 0;
            }
          }
          break;
      }
    });
  }

  private dataToDisplay(data: AvaProjectAdditionCalculation): AvaProjectAdditionCalculation {
    const newData = {
      ...data,
      riskAndProfitAddition: this.transformPercent(data.riskAndProfitAddition),
      laborSiteOperationCostsAddition: this.transformPercent(data.laborSiteOperationCostsAddition),
      laborCompanyOperationCostsAddition: this.transformPercent(data.laborCompanyOperationCostsAddition),
      calculationWage: this.transformNumberToDisplay(data.calculationWage),
      priceComponents: data.priceComponents.map((item) => {
        return {
          ...item,
          siteOperationCostsAddition: this.transformPercent(item.siteOperationCostsAddition),
          companyOperationCostsAddition: this.transformPercent(item.companyOperationCostsAddition)
        };
      })
    };
    return newData;
  }

  private dataToSave(calculation: AvaProjectAdditionCalculation): AvaProjectAdditionPut {
    const newData: AvaProjectAdditionPut = {
      ...calculation,
      riskAndProfitAddition: this.transformDigit(calculation.riskAndProfitAddition),
      laborSiteOperationCostsAddition: this.transformDigit(calculation.laborSiteOperationCostsAddition),
      laborCompanyOperationCostsAddition: this.transformDigit(calculation.laborCompanyOperationCostsAddition),
      calculationWage: this.transformNumberToBackend(calculation.calculationWage),
      priceComponents: calculation.priceComponents.map((item) => {
        return {
          ...item,
          siteOperationCostsAddition: this.transformDigit(item.siteOperationCostsAddition),
          companyOperationCostsAddition: this.transformDigit(item.companyOperationCostsAddition)
        };
      })
    };

    this.laborSiteOperationCostsAddition = newData.laborSiteOperationCostsAddition;
    this.laborCompanyOperationCostsAddition = newData.laborCompanyOperationCostsAddition;
    this.riskAndProfitAddition = newData.riskAndProfitAddition;

    if (this.hasDedicatedWageCalculation) {
      newData.medianHourlyWageCalculation = this.currentCalculationWageCalculation;
    }

    return newData;
  }

  private transformPercent(data: number): number {
    const rounded: number = Math.round((data + Number.EPSILON) * 10000) / 100;
    return (<any>rounded.toFixed(2).replace('.', ',')) as number;
  }

  private transformDigit(data: number): number {
    if (typeof data === 'string') {
      data = +(<string>data).replace(',', '.');
    }
    return data / 100;
  }

  private transformNumberToDisplay(data: number): number {
    if (typeof data === 'string') {
      data = +(<string>data).replace(',', '.');
    }

    return <number>(<any>data.toFixed(2).replace('.', ','));
  }

  private transformNumberToBackend(data: number): number {
    if (typeof data === 'string') {
      data = +(<string>data).replace(',', '.');
    }

    return data;
  }

  filterList(): void {
    const filterWord = this.filter.toLowerCase();
    this.filteredList = this.positionsList.filter((item: PositionDto) => {
      return item.itemNumber.stringRepresentation.toLowerCase().includes(filterWord) || item.shortText.toLowerCase().includes(filterWord);
    });
  }

  private calculationWageChange = new Subject<number>();

  onCalculationChange(medianHourlyWageCalculation: MedianHourlyWageCalculation): void {
    if (
      this.tryAddition &&
      medianHourlyWageCalculation &&
      medianHourlyWageCalculation?.calculationWage !== this.tryAddition?.calculationWage
    ) {
      this.tryAddition.calculationWage = medianHourlyWageCalculation.calculationWage;
      this.currentCalculationWageCalculation = medianHourlyWageCalculation;
      this.calculationWageChange.next(medianHourlyWageCalculation.calculationWage);
    }
  }

  changeNamePriceComponent(): void {
    const requests = this.tryAddition.priceComponents
      .map((item, index) => {
        if (item.priceComponent != this.savedNamePriceComponentList[index]) {
          return this.avaProjectAdditionsClient
            .editPriceComponentNameAtAvaProjectPriceComponentAddition(this.projectId, this.avaProjectId, item.id, {
              additionPriceComponentId: item.id,
              newName: item.priceComponent
            })
            .pipe(
              tap((updated) => {
                this.currentAddition.priceComponents[index].priceComponent = updated.priceComponent;
              })
            );
        }

        return null;
      })
      .filter((r) => !!r);

    if (requests.length > 0) {
      forkJoin(requests).subscribe(() => {
        setTimeout(() => {
          this.selectedSpecificationAdditionsMessengerService.setSelectedAdditions(this.currentAddition);
        }, 1);
        this.isChangeNamePriceComponentMode = false;
      });
    }
  }

  cancelChangingNamePriceComponent(): void {
    this.tryAddition.priceComponents.forEach((item, index) => {
      item.priceComponent = this.savedNamePriceComponentList[index];
    });
    this.isChangeNamePriceComponentMode = false;
  }
}
