import { Injectable } from '@angular/core';

import { ReplaySubject } from 'rxjs';

import { Notification } from '../models';
import { NotificationType } from '../models/notification-type.model';
import { ToastrService } from 'ngx-toastr';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class AvaNotificationsService {
  private _notes: Notification[] = [];
  private maxCount = 10;

  private notificationsSource = new ReplaySubject<Notification[]>(1);
  notifications = this.notificationsSource.asObservable();

  constructor(private toastr: ToastrService) {
    this.notificationsSource.next(this._notes);
  }

  private readonly notificationDurationMs = 7 * 1000; //  seconds

  public success(message: string, title?: string): void {
    this.successWithSubstitutions(message, null, title);
  }

  public successWithSubstitutions(message: string, substitution: string[], title?: string): void {
    this.toastr.success(message, title, {
      timeOut: this.notificationDurationMs
    });
    this.saveNote(message, title, NotificationType.Success);
  }

  public error(message: string, title?: string): void {
    this.toastr.error(message, title, {
      timeOut: this.notificationDurationMs
    });
    this.saveNote(message, title, NotificationType.Error);
  }

  public info(message: string, title?: string, timeOut = 0): void {
    this.toastr.info(message, title, {
      timeOut: timeOut || this.notificationDurationMs
    });
    this.saveNote(message, title, NotificationType.Info);
  }

  public warning(message: string, title?: string, timeOut = 0): void {
    this.toastr.warning(message, title, {
      timeOut: timeOut || this.notificationDurationMs
    });
    this.saveNote(message, title, NotificationType.Info);
  }

  saveNote(message: string, title: string, notificationType: NotificationType): void {
    this._notes.push({
      id: uuidv4(),
      date: new Date(),
      title,
      message,
      type: notificationType
    });
    if (this._notes.length > this.maxCount) {
      this._notes.shift();
    }
    this.notificationsSource.next(this._notes.map((item) => ({ ...item })));
  }

  clearNotifiation(id: string): void {
    this._notes = this._notes.filter((item) => item.id !== id);
    this.notificationsSource.next(this._notes.map((item) => ({ ...item })));
  }

  clearAllNotifications(): void {
    this._notes = [];
    this.notificationsSource.next(this._notes);
  }
}
