import { NgIf } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatTabChangeEvent, MatTabGroup, MatTab, MatTabContent } from '@angular/material/tabs';

import { Observable, Subject, combineLatest } from 'rxjs';
import { debounceTime, filter, finalize, first, map, take, takeUntil } from 'rxjs/operators';

import { CalculationTotalsService } from '@serv-spec/services/calculation-totals.service';
import { ChangeTotalService } from '@serv-spec/services/change-total.service';
import { CurrentPositionCalculationGetService } from '@serv-spec/services/current-position-calculation-get.service';

import {
  AvaProjectGet,
  CalculationEntry,
  CalculationSelectionClient,
  GroupCalculationTotalsClient,
  GroupCalculationTotalsGet,
  IElementDto,
  PositionCalculation,
  PositionCalculationGet,
  PositionCalculationTotals,
  PositionCalculationUpdateResultGet,
  PositionCalculationsClient,
  PositionCalculationsSubPositionsClient,
  PositionDto,
  PositionQuantityTakeOffRowModel,
  PositionQuantityTakeOffsClient,
  ProjectDto,
  ProjectGet,
  ProjectStatus,
  ServiceSpecificationDto,
  SubPositionGet
} from 'app/generated-client/generated-client';
import { FilterDialogType } from 'app/shared/models';
import { TreeViewCommandType } from 'app/shared/models/tree-view-command.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { CopyCalculationViewMessengerService } from 'app/shared/services/electron/copy-calculation-view-messenger.service';
import { TreeViewMessengerService } from 'app/shared/services/electron/tree-view-messenger.service';
import { ProjectQuantityEstimationService } from 'app/shared/services/messengers/project-quantity-estimation.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { AvaHubConnector } from 'app/shared/services/signalr/ava-hub-connector';
import { SpecificationSourceService } from 'app/shared/services/specification-source.service';

import { IncludesPipe } from '../../../../shared/pipes/includes.pipe';
import { SubPositionsMessengerService } from '../../../../shared/services/messengers/sub-positions-messenger.service';
import { PositionHasCalculationService } from '../../../../shared/services/position-has-calculation.service';
import { AvaProjectDiffApplier } from '../../../../shared/services/signalr/ava-project-diff-applier';
import { CalculationComponent } from '../../../project-id/components/service-specifications/components/calculation/calculation.component';
import { CalculationFixedPriceService } from '../../../project-id/services/calculation-fixed-price.service';
import { FlatElementsService } from '../../../tree/services/flat-elements.service';

import { ExecutionDescriptionComponent } from '../execution-description/execution-description.component';
import { GroupTotalsTableComponent } from '../group-totals-table/group-totals-table.component';
import { NoteTextComponent } from '../note-text/note-text.component';
import { PositionComponent } from '../position/position.component';

@Component({
  selector: 'pa-element',
  templateUrl: './element.component.html',
  styleUrls: ['./element.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NoteTextComponent,
    PositionComponent,
    MatCheckbox,
    FormsModule,
    GroupTotalsTableComponent,
    CalculationComponent,
    MatTabGroup,
    MatTab,
    MatTabContent,
    ExecutionDescriptionComponent,
    IncludesPipe
  ]
})
export class ElementComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isInnerWindow: boolean;
  @Input() selectedElement: IElementDto;
  @Input() addition: string[];
  currentPositionId: string;
  currentPositionCalculation: PositionCalculation;
  calcEntries: CalculationEntry[];
  elementList: FilterDialogType[];
  currentTotals: PositionCalculationTotals;
  serviceSpecification: ServiceSpecificationDto;
  listCellWidth: number[];
  isLoading = false;
  methods: any;
  positionCalculationBlocked = false;
  @Input() forceReadOnly;
  isReadOnly = false;
  isOnFullReplacement = false;
  projectId: string;
  avaProjectId: string;
  private avaProject: AvaProjectGet;
  serviceSpecificationId: string;
  modePage = '';
  projectCurrency: string;
  calculateUnitPrice: number;
  private projectStatusIsReadOnly = false;
  private $destroy: Subject<boolean> = new Subject<boolean>();
  private currentProjectHasQuantityEstimation = false;
  private _includePositionsWithoutTotalPrice = false;
  set includePositionsWithoutTotalPrice(value: boolean) {
    this._includePositionsWithoutTotalPrice = value;
    this.tryGetGroupTotals();
  }
  get includePositionsWithoutTotalPrice() {
    return this._includePositionsWithoutTotalPrice;
  }

  hasLoadedGroupTotals = false;
  groupTotals: GroupCalculationTotalsGet;

  private calculationRunning: { [positionId: string]: boolean } = {};
  private lastCalculationToSend: { [positionId: string]: PositionCalculation } = {};
  private calculationFinished = new Subject<{ positionId: string; calculation: PositionCalculationGet }>();
  private positionId: string;
  isShowCalculationTab = false;
  showedPositionsEstimationCompact = false;
  equipmentRows: PositionQuantityTakeOffRowModel[] = [];
  copiedPositionData = [];

  constructor(
    private currentPositionCalculationGetService: CurrentPositionCalculationGetService,
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private sourceService: SpecificationSourceService,
    private positionCalculationsClient: PositionCalculationsClient,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private changeTotalService: ChangeTotalService,
    private avaHubConnector: AvaHubConnector,
    private avaNotificationsService: AvaNotificationsService,
    private calculationTotalsService: CalculationTotalsService,
    private calculationFixedPriceService: CalculationFixedPriceService,
    private copyCalculationViewMessengerService: CopyCalculationViewMessengerService,
    private positionHasCalculationService: PositionHasCalculationService,
    private subPositionsMessengerService: SubPositionsMessengerService,
    private positionCalculationsSubPositionsClient: PositionCalculationsSubPositionsClient,
    public projectQuantityEstimationService: ProjectQuantityEstimationService,
    private positionQuantityTakeOffsClient: PositionQuantityTakeOffsClient,
    private groupCalculationTotalsClient: GroupCalculationTotalsClient,
    private avaProjectDiffApplier: AvaProjectDiffApplier,
    private flatElementsService: FlatElementsService,
    private calculationSelectionClient: CalculationSelectionClient,
    private treeViewMessengerService: TreeViewMessengerService
  ) {}

  ngOnInit(): void {
    this.sourceService.loadAllData.pipe(takeUntil(this.$destroy)).subscribe((list: FilterDialogType[]) => {
      this.elementList = list;
    });

    this.calculationTotalsService.calculationTotal.pipe(takeUntil(this.$destroy)).subscribe((e: PositionCalculationTotals) => {
      this.currentTotals = e;
    });

    this.avaProjectDiffApplier.avaProjectDiffApplied.pipe(takeUntil(this.$destroy)).subscribe((avaProjectDiff) => {
      if (this.currentPositionId && this.currentPositionCalculation && avaProjectDiff.avaProjectDiff.elementDiffs[this.currentPositionId]) {
        const changedPositionId = this.currentPositionId;
        this.flatElementsService.flatElementsDto.pipe(take(1), takeUntil(this.$destroy)).subscribe((flatElements) => {
          if (changedPositionId === this.currentPositionId && this.currentPositionCalculation) {
            const position = flatElements.find((x) => x.id === this.currentPositionId) as PositionDto;
            if (this.currentPositionCalculation.positionQuantity != position.quantity) {
              this.currentPositionCalculation.positionQuantity = position.quantity;

              // We should also recalculate the totals for the whole calculation,
              // since that's otherwise not being updated
              this.calculationSelectionClient
                .calculatePositionSelection({
                  positionCalculation: this.currentPositionCalculation,
                  selectedRowIndices: this.currentPositionCalculation.calculationEntries.map((e) => e.rowIndex)
                })
                .subscribe((recalculatedTotals) => {
                  if (changedPositionId === this.currentPositionId && this.currentPositionCalculation) {
                    this.currentPositionCalculation.positionCalculationTotals = recalculatedTotals;
                    this.currentTotals = recalculatedTotals;
                    this.calculationTotalsService.setCurrentTotal(this.currentTotals);
                  }
                });
            }
          }
        });
      }
    });

    if (!this.isInnerWindow) {
      this.copyCalculationViewMessengerService.reloadCurrentPositionCalculation
        .pipe(
          takeUntil(this.$destroy),
          // We're debouncing here, since there are cases where we do copy all calculations inside a group.
          // This can happen either in the main view, or in the external view. So it's possible that two events
          // are fired close to each other, one via IPC, the other directly. We don't want to reload
          // the current calculation multiple times.
          debounceTime(30)
        )
        .subscribe(() => {
          this.tryLoadCalculationData();
          this.positionHasCalculationService.updateList();
          this.refreshSubpositionInTree(this.positionId);
        });

      combineLatest([
        this.selectedProjectMessengerService.selectedProject,
        this.selectedSpecificationMessengerService.selectedServiceSpecification
      ])
        .pipe(takeUntil(this.$destroy))
        .subscribe(([project, s]: [ProjectGet, { avaProjectId: string; project: ProjectDto; avaProject: AvaProjectGet }]) => {
          this.projectId = project?.id;
          this.serviceSpecification = s?.project.serviceSpecifications[0];
          this.avaProjectId = s?.avaProjectId;
          this.avaProject = s?.avaProject;
          this.projectStatusIsReadOnly = project?.status === ProjectStatus.Archived || project?.status === ProjectStatus.Locked;
          this.isReadOnly = this.projectStatusIsReadOnly || this.forceReadOnly;
          const serviceSpecificationIdHasNotChanged = this.serviceSpecificationId === s?.avaProjectId;
          this.serviceSpecificationId = s?.avaProjectId;
          this.methods = { getCalculation: ['positionCalculation', this.projectId, this.serviceSpecificationId] };

          if (!serviceSpecificationIdHasNotChanged) {
            // In case the service specification id has not changed, it's probably just reloaded. This can happen when a position
            // was deleted and the service specification is directly reloaded. Then, we don't want to reload the calculation.
            this.tryLoadCalculationData();
          }

          if (this.serviceSpecificationId) {
            this.avaHubConnector.getLastVisitedCalculationAsync(this.serviceSpecificationId).then((lastVisitedCalc) => {
              if (lastVisitedCalc && lastVisitedCalc.lastAccessedCalculationId) {
                this.selectedSpecificationElementMessengerService.trySelectElementById(lastVisitedCalc.lastAccessedCalculationId);
              }
            });
          }
        });

      this.avaHubConnector.userKicked.pipe(takeUntil(this.$destroy)).subscribe((kickResult) => {
        if (kickResult.isSuccess) {
          this.positionCalculationBlocked = true;
        }
      });

      //According issue #1817, the full replacement feature is temporarily disabled
      if (this.isOnFullReplacement) {
        this.copyCalculationViewMessengerService.selectedPosition.pipe(takeUntil(this.$destroy)).subscribe((e) => {
          this.currentPositionCalculation = e.positionCalculation.positionCalculation;
          this.changeTable(this.currentPositionCalculation);
        });
      }
      this.projectQuantityEstimationService.showedPositionsEstimationCompact.pipe(takeUntil(this.$destroy)).subscribe((e) => {
        this.showedPositionsEstimationCompact = e;
        if (this.showedPositionsEstimationCompact) {
          this.getRows();
        }
      });
    } else {
      this.tryLoadCalculationData();
    }

    this.projectQuantityEstimationService.currentProjectHasQuantityCalculation
      .pipe(takeUntil(this.$destroy))
      .subscribe((hasEstimation) => (this.currentProjectHasQuantityEstimation = hasEstimation));

    this.tryGetGroupTotals();
  }

  ngOnChanges(): void {
    const previousPositionId = this.positionId;
    this.positionId = this.selectedElement?.id;
    const idHasChanged = previousPositionId !== this.positionId;
    if (idHasChanged) {
      if (previousPositionId && this.projectId && this.serviceSpecificationId) {
        this.avaHubConnector.notifyOfExitingCalculation(this.projectId, this.serviceSpecificationId, previousPositionId);
      }
      this.tryLoadCalculationData();
    }

    if (this.isInnerWindow && this.addition?.length) {
      [this.projectId, this.serviceSpecificationId, this.modePage, this.projectCurrency] = this.addition;
      if (!this.modePage) {
        this.modePage = '';
      }
      this.selectedSpecificationMessengerService.setProjectCurrency(this.projectCurrency);
    }
    this.getRows();

    this.tryGetGroupTotals();
  }

  ngOnDestroy(): void {
    this.currentPositionCalculationGetService.setCurrentPositionCalc(null);
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private tryLoadCalculationData(): void {
    if (this.selectedElement?.id && this.serviceSpecificationId && this.selectedElement.elementTypeDiscriminator === 'PositionDto') {
      this.positionCalculationBlocked = false;
      this.avaHubConnector.tryEnterPositionCalculation(this.projectId, this.serviceSpecificationId, this.selectedElement.id).then((r) => {
        if (!r.isSuccess && r.positionId === this.positionId) {
          this.avaNotificationsService.info('Diese Position wird gerade von einem anderen Benutzer bearbeitet.');
          this.positionCalculationBlocked = !r.isSuccess;
        }
      });

      const selectedElementId = this.selectedElement.id;
      this.isLoading = true;
      this.getPositionCalculation(selectedElementId)
        .pipe(takeUntil(this.$destroy))
        .subscribe({
          next: (e: PositionCalculationGet) => {
            this.currentPositionCalculationGetService.setCurrentPositionCalc(e);
            this.currentPositionId = selectedElementId;
            this.currentPositionCalculation = e.positionCalculation;
            this.calcEntries = null;
            this.calculateUnitPrice =
              e.positionCalculation.unitPriceAfterAdditionsIgnoringFixed ?? e.positionCalculation.unitPriceAfterAdditions;
            this.isLoading = false;

            if (!this.projectStatusIsReadOnly) {
              this.isReadOnly = (e.positionCalculation?.priceLocked ?? false) || this.forceReadOnly;
            }

            const calculationFixedPrice = e.positionCalculation?.fixedPrice == null ? null : e.positionCalculation.fixedPrice;
            this.calculationFixedPriceService.setFixedPriceForPosition(selectedElementId, calculationFixedPrice);
            this.getTotals();
          },
          error: () => {
            this.avaNotificationsService.error('Fehler beim Laden der Kalkulation');
            this.isLoading = false;
          }
        });
    } else {
      this.currentPositionCalculation = null;
    }
  }

  private savePositionCalculationData(positionCalculation: PositionCalculation): void {
    const localPositionId = this.positionId;
    this.updateCalculation(positionCalculation).subscribe({
      next: (r) => {
        this.currentPositionCalculationGetService.setCurrentPositionCalc(r);
        if (this.currentPositionCalculation) {
          this.currentPositionCalculation.positionCalculationTotals = r.positionCalculation.positionCalculationTotals;
          this.changeTotalService.setChangedTotal(true);
          this.calculateUnitPrice = r.positionCalculation?.unitPriceAfterAdditionsIgnoringFixed ?? r.updatedPosition.unitPrice;
          if (localPositionId === this.positionId) {
            this.calcEntries = [...r.positionCalculation.calculationEntries];
            this.getTotals();
          }
        }
      },
      error: () => {
        this.avaNotificationsService.error('Fehler beim Speichern der Kalkulation');
      }
    });
  }

  getTotals(): void {
    this.calculationTotalsService.calculateDetailsForSelection(this.currentPositionCalculation);
  }

  private getPositionCalculation(positionId: string): Observable<PositionCalculationGet> {
    return this.positionCalculationsClient.getPositionCalculation(this.projectId, this.serviceSpecificationId, positionId);
  }

  public changeTable(positionCalculation: PositionCalculation): void {
    this.savePositionCalculationData(positionCalculation);
  }

  private refreshSubpositionInTree(localPositionId: string): void {
    this.positionCalculationsSubPositionsClient
      .getSubPositionsForAvaProjectCalculationForPosition(this.projectId, this.serviceSpecificationId, localPositionId)
      .subscribe((subPositions) => {
        // We're refreshing the sub positions here in case they've been changed
        const positionData = subPositions?.subPositionsByAvaPositionId && subPositions?.subPositionsByAvaPositionId[localPositionId];
        this.treeViewMessengerService.treeViewVisible.pipe(take(1)).subscribe((treeViewVisible) => {
          if (treeViewVisible && !this.isInnerWindow) {
            this.treeViewMessengerService.sendDataToTreeView({
              command: TreeViewCommandType.UpdateSubPosition,
              data: positionData
            });
          }
        });

        const result = this.compareCurrentAndPreviousValue(positionData, this.copiedPositionData);
        if (positionData && result.length) {
          this.subPositionsMessengerService.setSubPositionsForSpecificPosition(localPositionId, this.avaProjectId, positionData);
          this.copiedPositionData = JSON.parse(JSON.stringify(positionData));
        }
      });
  }

  private updateCalculation(positionCalculation: PositionCalculation, positionId?: string): Observable<PositionCalculationUpdateResultGet> {
    const localPositionId = positionId || this.positionId;
    if (!localPositionId) {
      console.error('Tried to update a calculation when no position is selected.');
      return;
    }
    if (this.calculationRunning[localPositionId]) {
      // If the calculation is updated while we're still in the process
      // of sending one to the server, we're delaying the sending of the updated calculation
      // until the server is up to date. This is because the server might otherwise reject requests
      // when the same position calculation is updated concurrently from multiple requests, resuling
      // in a possible scenario where the last saved calculation is different than what the user
      // sees in the UI before they exit.
      this.lastCalculationToSend[localPositionId] = JSON.parse(JSON.stringify(positionCalculation));
      return this.calculationFinished.pipe(
        filter((c) => c.positionId === localPositionId),
        map((c) => c.calculation),
        first()
      );
    }

    this.calculationRunning[localPositionId] = true;
    return this.positionCalculationsClient
      .calculatePosition(this.projectId, this.serviceSpecificationId, localPositionId, false, positionCalculation)
      .pipe(
        finalize(() => {
          this.refreshSubpositionInTree(localPositionId);

          this.calculationRunning[localPositionId] = false;
          if (this.lastCalculationToSend[localPositionId]) {
            const localCalc = this.lastCalculationToSend[localPositionId];
            this.lastCalculationToSend[localPositionId] = null;
            this.updateCalculation(localCalc, localPositionId).subscribe((pc) => {
              if (localPositionId === this.positionId) {
                this.calcEntries = [...pc.positionCalculation.calculationEntries];
              }
              this.calculationFinished.next({ positionId: localPositionId, calculation: pc });
            });
          }

          this.positionHasCalculationService.updateList();
        })
      );
  }

  changeTabs(e: MatTabChangeEvent): void {
    this.isShowCalculationTab = e.index === 1 ? true : false;
  }

  private getRows(): void {
    if (
      this.currentProjectHasQuantityEstimation &&
      this.selectedElement &&
      this.avaProject?.quantityCalculationQuantityTakeOffId &&
      this.selectedElement.elementTypeDiscriminator === 'PositionDto'
    ) {
      this.positionQuantityTakeOffsClient
        .getPositionQuantityTakeOffById(
          this.projectId,
          this.serviceSpecificationId,
          this.avaProject.quantityCalculationQuantityTakeOffId,
          this.selectedElement.id
        )
        .subscribe((pqto) => {
          this.equipmentRows = pqto.positionData.rows;
        });
    } else {
      this.projectQuantityEstimationService.hidePositionsEstimationCompact();
    }
  }

  private tryGetGroupTotals(): void {
    this.hasLoadedGroupTotals = false;
    if (
      this.projectId &&
      this.serviceSpecificationId &&
      this.selectedElement?.elementTypeDiscriminator === 'ServiceSpecificationGroupDto'
    ) {
      this.groupCalculationTotalsClient
        .getGroupTotals(this.projectId, this.serviceSpecificationId, this.selectedElement.id, this.includePositionsWithoutTotalPrice)
        .pipe(takeUntil(this.$destroy))
        .subscribe((totals: GroupCalculationTotalsGet) => {
          this.groupTotals = totals;
          this.hasLoadedGroupTotals = true;
        });
    } else {
      this.groupTotals = null;
      this.hasLoadedGroupTotals = true;
    }
  }

  private compareCurrentAndPreviousValue(curr: SubPositionGet[], prev: SubPositionGet[]): SubPositionGet[] {
    // A comparer used to determine if two entries are equal.
    const isSameSubPosition = (a: SubPositionGet, b: SubPositionGet) =>
      a.rowIndex === b.rowIndex && a.text === b.text && a.id === b.id && a.avaPositionId === b.avaPositionId;

    // Get items that only occur in the left array,
    // using the compareFunction to determine equality.
    const onlyInLeft = (left: SubPositionGet[], right: SubPositionGet[], compareFunction) =>
      left.filter((leftValue) => !right.some((rightValue) => compareFunction(leftValue, rightValue)));

    const onlyInCurr = onlyInLeft(curr, prev, isSameSubPosition);
    const onlyInPrev = onlyInLeft(prev, curr, isSameSubPosition);

    return [...onlyInCurr, ...onlyInPrev];
  }
}
