import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'pa-service-specifications',
  templateUrl: './service-specifications.component.html',
  styleUrls: ['./service-specifications.component.scss'],
  standalone: true,
  imports: [RouterOutlet]
})
export class ServiceSpecificationsComponent {}
