import {
  AvaProjectContentEditClient,
  AvaProjectContentEditOperation,
  AvaProjectContentEditResultGet,
  IElementDto,
  ProjectDto,
  ProjectGet,
  ServiceSpecificationDto
} from 'app/generated-client/generated-client';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, combineLatest, filter, of, switchMap, takeUntil, EMPTY, tap } from 'rxjs';

import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { LvEditorNewElementModalComponent } from '@serv-spec/components/lv-editor/components/lv-editor-new-element-modal/lv-editor-new-element-modal.component';
import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { ModalService } from 'app/shared/services/modal.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ServiceSpecificationsHasChangeService } from 'app/shared/services/messengers/service-specifications-has-change.service';
import { getParentNode } from 'app/areas/tree/utils/fn';

@Injectable({
  providedIn: 'root'
})
export class TreeNodeService implements OnDestroy {
  private projectId: string;
  private avaProjectId: string;
  private selectedElement: IElementDto;
  private serviceSpecification: ServiceSpecificationDto;
  private isAddingModalOpened: boolean;
  private $destroy = new Subject<void>();

  constructor(
    private modalService: ModalService,
    private avaProjectContentEditClient: AvaProjectContentEditClient,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private avaNotificationsService: AvaNotificationsService,
    private serviceSpecificationsHasChangeService: ServiceSpecificationsHasChangeService
  ) {
    combineLatest([
      this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)),
      this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(
        takeUntil(this.$destroy),
        filter(s => !!s)
      )
    ])
      .pipe(takeUntil(this.$destroy))
      .subscribe(([project, avaProject]: [ProjectGet, { avaProjectId: string; project: ProjectDto }]) => {
        this.projectId = project?.id;
        this.avaProjectId = avaProject?.avaProjectId;
        this.serviceSpecification = avaProject.project.serviceSpecifications[0];
      });

    this.selectedSpecificationElementMessengerService.selectedElement.pipe(takeUntil(this.$destroy)).subscribe(selectedElement => {
      this.selectedElement = selectedElement?.element;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  deleteNode(element?: IElementDto): Observable<AvaProjectContentEditResultGet | null> {
    const delElement = element || this.selectedElement;

    let elementName = delElement?.['shortText']?.slice(0, 50);
    if (!elementName) {
      if (delElement.elementType === 'NoteTextDto') {
        elementName = 'Hinweistext';
      } else if (delElement.elementType === 'PositionDto') {
        elementName = 'Position';
      } else if (delElement.elementType === 'ServiceSpecificationGroupDto') {
        elementName = 'Gruppe';
      } else if (delElement.elementType === 'ExecutionDescriptionGroupDto') {
        elementName = 'Ausführungsbeschreibung';
      }
    }

    const resultAfterDeletion = new Subject<AvaProjectContentEditResultGet | null>();
    const shouldDeleeteObservable: Observable<boolean> =
      delElement.elementType !== 'PositionDto'
        ? this.modalService
            .openModal(ModalConfirmComponent, {
              dialogType: ConfirmationType.Delete,
              data: ['Löschen', 'Element', `Element: ${elementName}`, 'red'],
              autoFocus: false,
              restoreFocus: false
            })
            .afterClosed()
        : // If we're deleting a position, we first want to check if it may be used in some quantity take offs
          this.avaProjectContentEditClient
            .checkIfPositionHasReferences(this.projectId, this.avaProjectId, delElement.id)
            .pipe(
              switchMap(res =>
                res.positionIsReferenced
                  ? this.modalService
                      .openModal(ModalConfirmComponent, {
                        dialogType: ConfirmationType.Delete,
                        data: ['Löschen', 'Element', `Position wird in Abrechnung oder Mengenermittlung verwendet: ${elementName}`, 'red'],
                        autoFocus: false,
                        restoreFocus: false
                      })
                      .afterClosed()
                  : of(true)
              )
            )
            .pipe(
              switchMap(res => {
                if (res) {
                  return this.modalService
                    .openModal(ModalConfirmComponent, {
                      dialogType: ConfirmationType.Delete,
                      data: ['Löschen', 'Element', `Element: ${elementName}`, 'red'],
                      autoFocus: false,
                      restoreFocus: false
                    })
                    .afterClosed();
                } else {
                  resultAfterDeletion.next(null);
                  return EMPTY;
                }
              })
            );

    shouldDeleeteObservable.subscribe((e: boolean) => {
      if (e) {
        const waitForDeletionSubject = new Subject<boolean>();
        waitForDeletionSubject.subscribe(confirmDeletion => {
          if (confirmDeletion) {
            this.serviceSpecificationsHasChangeService
              .checkServiceSpecificationHasBeenEdited()
              .pipe(
                switchMap(r => {
                  if (r) {
                    return this.avaProjectContentEditClient.editAvaProjectContent(this.projectId, this.avaProjectId, {
                      operation: AvaProjectContentEditOperation.Delete,
                      deleteOperation: {
                        elementId: delElement.id
                      }
                    });
                  }
                })
              )
              .subscribe({
                next: (editResult: AvaProjectContentEditResultGet) => {
                  if (delElement.id === this.selectedElement?.id) {
                    this.selectedSpecificationElementMessengerService.clearSelectedElement();
                  }
                  resultAfterDeletion.next(editResult);
                  this.avaNotificationsService.success('Element gelöscht');
                },
                error: () => this.avaNotificationsService.error('Fehler beim Löschen des Elements')
              });
          }
        });

        if (delElement.elementType === 'ServiceSpecificationGroupDto' && delElement['elements']?.length) {
          this.modalService
            .openModal(ModalConfirmComponent, {
              dialogType: ConfirmationType.Delete,
              data: ['Löschen', 'Gruppe', 'Gruppe enthält Unterelemente', 'red']
            })
            .afterClosed()
            .subscribe(confirmDelete => {
              if (!confirmDelete) {
                this.avaNotificationsService.info('Löschvorgang abgebrochen');
                resultAfterDeletion.next(null);
              }
              waitForDeletionSubject.next(confirmDelete);
              waitForDeletionSubject.complete();
            });
        } else {
          waitForDeletionSubject.next(true);
          waitForDeletionSubject.complete();
        }
      } else {
        resultAfterDeletion.next(null);
      }
    });

    return resultAfterDeletion;
  }

  addNode(element: IElementDto, place?: string): Observable<AvaProjectContentEditResultGet> {
    if (this.isAddingModalOpened) {
      return of(null);
    }
    this.isAddingModalOpened = true;

    const parentOfElement = element
      ? getParentNode(element.id, this.serviceSpecification)
      : getParentNode(this.selectedElement?.id, this.serviceSpecification);

    const modalData: {
      selectedElement?: IElementDto;
      parentElement?: IElementDto;
      preselection?: string;
    } = {};

    modalData.selectedElement = element;
    modalData.parentElement = parentOfElement;

    switch (place) {
      case 'end':
        modalData.selectedElement = null;
        break;

      case 'before':
        modalData.preselection = 'Davor';
        break;

      case 'after':
        modalData.preselection = 'Danach';
        break;

      case 'endGroup':
        modalData.selectedElement = null;
        modalData.parentElement = element;
        break;
    }

    return this.modalService
      .openModal(LvEditorNewElementModalComponent, {
        dialogType: ConfirmationType.General,
        data: modalData
      })
      .afterClosed()
      .pipe(tap(() => (this.isAddingModalOpened = false)));
  }
}
