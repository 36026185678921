import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContactGet } from 'app/generated-client/generated-client';
import { PaginationMainService } from './main/pagination-main.service';

@Injectable({
  providedIn: 'root'
})
export class ContactsService extends PaginationMainService<ContactGet> {
  constructor(protected http: HttpClient) {
    super(http);
    this.beginBaseUrl('/api/contacts');
  }

  getContacts(contactId: string): ContactGet | undefined {
    return this.getItemById(contactId);
  }
}
