<div class="wrapper" *ngIf="serviceSpecificationId">
  <div class="wrapper-loader" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>
  <h2>Mengenvergleich</h2>
  <div fxLayout="column" fxLayoutGap="12px" *ngIf="!isHideControls">
    <mat-checkbox [(ngModel)]="isAllQTOs" (ngModelChange)="changeAllQuantities()" *ngIf="this.quantityTakeOffId"
      >Alle Abrechnungen</mat-checkbox
    >
    <mat-checkbox [(ngModel)]="onlyBilledQuantities" (ngModelChange)="showSheet()">Nur abgerechnete</mat-checkbox>
    <mat-checkbox [(ngModel)]="includePositionsWithoutQuantity" (ngModelChange)="showSheet()">Positionen ohne Menge</mat-checkbox>
    <mat-checkbox [(ngModel)]="includeLongTexts">Langtexte ausgeben</mat-checkbox>
    <mat-checkbox [(ngModel)]="includeHeaderOnlyOnFirstPage">Kopfzeile nur auf der ersten Seite drucken</mat-checkbox>
  </div>
  <div fxLayout="row" fxLayoutGap="12px" *ngIf="isHideControls" fxLayoutAlign="start center">
    <mat-checkbox [(ngModel)]="isAllQTOs" (ngModelChange)="changeAllQuantities()" *ngIf="this.quantityTakeOffId"
      >Alle Abrechnungen</mat-checkbox
    >
    <mat-checkbox [(ngModel)]="onlyBilledQuantities" (ngModelChange)="showSheet()">Nur abgerechnete</mat-checkbox>
    <mat-checkbox [(ngModel)]="includePositionsWithoutQuantity" (ngModelChange)="showSheet()">Positionen ohne Menge</mat-checkbox>
    <mat-checkbox [(ngModel)]="includeLongTexts">Langtexte ausgeben</mat-checkbox>
    <mat-checkbox [(ngModel)]="includeHeaderOnlyOnFirstPage">Kopfzeile nur auf der ersten Seite drucken</mat-checkbox>
    <div class="input-wrap">
      <mat-form-field class="input-row">
        <mat-label>Zeilenabstand in mm</mat-label>
        <input matInput type="number" min="0" max="100" [(ngModel)]="addMargin" />
      </mat-form-field>
    </div>
    <button color="primary" mat-raised-button (click)="showSheetReport()" [disabled]="isShowedPdf || !quantitiesSource?.length">
      Druckansicht
    </button>
  </div>

  <div *ngIf="!isHideControls">
    <div fxLayout="row" fxLayoutGap="12px" class="buttons">
      <button color="primary" mat-raised-button (click)="showSheetReport()" [disabled]="isShowedPdf || !quantitiesSource?.length">
        Druckansicht
      </button>
      <button mat-raised-button (click)="goBack()">Abbrechen</button>
      <div class="input-wrap">
        <mat-form-field class="input-base">
          <mat-label>Zeilenabstand in mm</mat-label>
          <input matInput type="number" min="0" max="100" [(ngModel)]="addMargin" />
        </mat-form-field>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isLoaded">
    <h3 *ngIf="!quantitiesSource?.length">Keine Daten</h3>
    <div>
      <table class="totals">
        <tr>
          <td>LV-Summe:</td>
          <td class="right">{{ serviceSpecificationTotalSum | projectCurrency }}</td>
        </tr>
        <tr>
          <td>
            Gesamt abgerechnet<span *ngIf="isHideControls && serviceSpecificationTotalSum !== 0">
              ({{ totalSum / serviceSpecificationTotalSum | percent: '1.2-2' }})</span
            >:
          </td>
          <td class="right">{{ totalSum | projectCurrency }}</td>
        </tr>
        <tr *ngIf="!isHideControls">
          <td>Gesamt abgerechnet:</td>
          <td class="right" *ngIf="serviceSpecificationTotalSum !== 0">{{ totalSum / serviceSpecificationTotalSum | percent: '1.2-2' }}</td>
        </tr>
      </table>
    </div>
    <ng-container *ngIf="quantitiesSource?.length">
      <mat-form-field class="full-width">
        <mat-label>Suche</mat-label>
        <input matInput [ngModel]="filter" (ngModelChange)="changeFilter($event)" />
      </mat-form-field>

      <div class="mat-elevation-z8 table-data" [class.short]="isHideControls">
        <table [ngClass]="{ opacity: isLoading }">
          <thead>
            <tr>
              <th class="boq-head border-top border-bottom left">OZ</th>
              <th class="boq-head border-top border-bottom left">Kurztext</th>
              <th class="boq-head border-top border-bottom right">Einheitspreis</th>
              <th class="boq-head border-top border-bottom">
                <div class="right">LV-Menge</div>
                <div class="right">LV-Summe</div>
              </th>
              <th class="border-top border-bottom"></th>
              <th class="boq-head border-top border-bottom">
                <div class="right">Menge</div>
                <div class="right">Summe</div>
              </th>
              <th class="boq-head border-top border-bottom right">Delta</th>
              <th class="boq-head border-top border-bottom" style="min-width: 100px">Abgerechnet</th>
            </tr>
          </thead>

          <tbody>
            <ng-container *ngFor="let element of filteredSource; let odd = odd">
              <tr [ngClass]="{ odd: odd }">
                <td class="bold">{{ element.itemNumber }}</td>
                <td class="ellipsis-td">
                  <div class="bold ellipsis">
                    {{ element.shortText }}
                  </div>
                </td>
                <td class="right">
                  {{ element.unitPrice | number: '1.2-3' }}
                </td>
                <td class="right">
                  {{ element.quantity | number: '1.3-3' }}
                </td>
                <td class="right">
                  {{ element.unitTag }}
                </td>
                <td class="right">
                  {{ element.quantityTakeOff | number: '1.3-3' }}
                </td>
                <td class="right">
                  {{ element.quantityTakeOff - element.quantity | number: '1.3-3' }}
                </td>
                <td rowspan="2">
                  <pa-progressbar-percentage [value]="element.quantityTakeOffPercentage"></pa-progressbar-percentage>
                </td>
              </tr>

              <tr [ngClass]="{ odd: odd }">
                <td></td>
                <td></td>
                <td></td>
                <td class="right">
                  {{ element.totalPrice | number: '1.2-3' }}
                </td>
                <td></td>
                <td class="right">
                  {{ element.quantityTakeOffTotalPrice | number: '1.2-3' }}
                </td>
                <td class="right">
                  {{ element.quantityTakeOffTotalPrice - element.totalPrice | number: '1.2-3' }}
                </td>
              </tr>
              <tr *ngIf="includeLongTexts" [ngClass]="{ odd: odd }">
                <td colspan="8" class="border-bottom">
                  <div [innerHtml]="element.htmlLongText | safeHtml"></div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </ng-container>
  </ng-container>
</div>
