import { NgFor } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';

import { Subject, takeUntil } from 'rxjs';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';

import { IfcSpatialStructure, IfcStructure } from '../../../../generated-client/generated-client';
import { BimShowViewService } from '../../services/bim-show-view.service';
import { BimStateService } from '../../services/bim-state.service';
import { BimStructureService } from '../../services/bim-structure.service';
import { BimViewService } from '../../services/bim-view.service';

import { StructureItemBimViewComponent } from '../structure-item-bim-view/structure-item-bim-view.component';

@Component({
  selector: 'pa-structure-bim-view',
  templateUrl: './structure-bim-view.component.html',
  styleUrls: ['./structure-bim-view.component.scss'],
  standalone: true,
  imports: [MatIcon, MatTooltip, MatButton, NgFor, StructureItemBimViewComponent, FlexLayoutDirective]
})
export class StructureBimViewComponent implements OnInit, OnDestroy {
  spatialStructure: IfcSpatialStructure[];
  isShowMode = false;
  showedElements: number[] | null = null;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private bimViewService: BimViewService,
    private bimShowViewService: BimShowViewService,
    private bimStateService: BimStateService,
    private bimStructureService: BimStructureService
  ) {}

  ngOnInit(): void {
    this.bimViewService.structure.pipe(takeUntil(this.$destroy)).subscribe((structure: IfcStructure) => {
      setTimeout(() => {
        this.spatialStructure = structure.spatialStructure;
      }, 1);
    });

    this.bimShowViewService.visibleStructureMode.pipe(takeUntil(this.$destroy)).subscribe((e) => (this.isShowMode = e));

    this.bimStateService.showedElements.pipe(takeUntil(this.$destroy)).subscribe((e: number[] | null) => {
      this.showedElements = e;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  toggleShowedMode(): void {
    this.bimShowViewService.setStructureMode(!this.isShowMode);
  }

  showedAllModel(): void {
    this.bimViewService.hideAllElementsExcept(null);
  }

  toggleAll(isOpen: boolean = false): void {
    this.bimStructureService.setShowChildren(isOpen);
  }
}
