import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';

import { CalculationEntry } from 'app/generated-client/generated-client';

@Component({
  selector: 'pa-sub-position-item-number',
  templateUrl: './sub-position-item-number.component.html',
  styleUrls: ['./sub-position-item-number.component.scss'],
  standalone: true,
  imports: [NgIf, MatInput, FormsModule, ReactiveFormsModule]
})
export class SubPositionItemNumberComponent {
  @Input() set disabled(v: boolean) {
    if (!v) {
      this.itemNumber.enable();
    } else {
      this.itemNumber.disable();
    }
  }
  @Input() set subPositionIdentifier(identifier: string) {
    this.itemNumber.patchValue(identifier);
  }

  @Input() rowIndex: number;

  @Output() updateSubPositionIdentifier = new EventEmitter<string>();
  @Output() keyDownEvent = new EventEmitter<KeyboardEvent>();

  itemNumber = new FormControl('', [Validators.pattern(/^([UO]|[UO](?=\d)|\d*\.?\d*[UO]?|[0-9]+)$/)]);

  calcEntry: CalculationEntry;

  constructor() {}

  onKeyDown(event: KeyboardEvent) {
    const inputIsEmpty = !this.itemNumber.value;
    const allowedKeys = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '.',
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
      'Enter',
      'u',
      'U',
      'o',
      'O'
    ];

    if (!allowedKeys.includes(event.key) || (!inputIsEmpty && ['u', 'U', 'o', 'O'].includes(event.key))) {
      event.preventDefault();
      return;
    }

    if (inputIsEmpty && ['u', 'U', 'o', 'O'].includes(event.key)) {
      this.itemNumber.patchValue(event.key.toUpperCase());
      event.preventDefault();
      return;
    }

    if (
      this.itemNumber.value &&
      (this.itemNumber.value === 'U' || this.itemNumber.value === 'O') &&
      ['Backspace', 'Delete'].includes(event.key)
    ) {
      this.itemNumber.setValue('');
      this.itemNumber.enable();
      event.preventDefault();
      return;
    }

    if (this.itemNumber.value && ['u', 'U', 'o', 'O'].includes(event.key)) {
      this.itemNumber.disable();
      event.preventDefault();
      return;
    }

    if (this.itemNumber.value && this.itemNumber.value.includes('U') && (event.key === '.' || /[0-9]/.test(event.key))) {
      event.preventDefault();
      return;
    }

    if (this.itemNumber.value && this.itemNumber.value.includes('O') && (event.key === '.' || /[0-9]/.test(event.key))) {
      event.preventDefault();
      return;
    }

    this.keyDownEvent.emit(event);
  }

  onInputChange(): void {
    this.updateSubPositionIdentifier.emit(this.itemNumber.value);
  }

  onBlur(): void {
    if (this.itemNumber.value && (this.itemNumber.value.includes('U') || this.itemNumber.value.includes('O'))) {
      this.onInputChange();
    }
  }
}
