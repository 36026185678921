<mat-form-field class="full-width">
  <mat-label>Suche</mat-label>
  <input #searchInput matInput [(ngModel)]="filter" />
</mat-form-field>
<div class="table-container quick-edit-position" *ngIf="dataSource.data.length">
  <cdk-virtual-scroll-viewport tvsItemSize="22" headerHeight="22" class="table-wrapper">
    <table
      mat-table
      [dataSource]="dataSource"
      resizeName="edit-table"
      class="mat-elevation-z8"
      (keyup)="onKey($event)"
      cdkDropList
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="dropHeader($event)"
      [trackBy]="trackByFn"
    >
      <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef class="w-30 mat-table-sticky" cdkDrag [cdkDragDisabled]="true"></th>
        <td mat-cell *matCellDef="let element; let i = index" (click)="isEditMode && selectedRowForEdit(element)" [ngClass]="'w-30'">
          <mat-icon class="mat-icon-rtl-mirror element-icon" *ngIf="element.elementTypeDiscriminator === 'PositionDto'">add</mat-icon>
          <mat-icon class="mat-icon-rtl-mirror element-icon" *ngIf="element.elementTypeDiscriminator === 'NoteTextDto'">subject</mat-icon>
          <mat-icon class="mat-icon-rtl-mirror element-icon" *ngIf="element.elementTypeDiscriminator === 'ExecutionDescriptionDto'"
            >event</mat-icon
          >
          <mat-icon class="mat-icon-rtl-mirror element-icon" *ngIf="element.elementTypeDiscriminator === 'ServiceSpecificationGroupDto'"
            >folder_open</mat-icon
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="priceCompositionRequired">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="w-22 mat-table-sticky"
          matTooltip="Preisaufteilung erforderlich"
          cdkDrag
          [cdkDragDisabled]="true"
        >
          Preisaufteilung erforderlich
        </th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="'w-22'" (keydown)="preventKeyDownEvent($event, element)">
          <ng-container *ngIf="element.elementType === 'PositionDto'; else noCheckBox">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              [disabled]="!isEditMode"
              [(ngModel)]="element.priceCompositionRequired"
              (change)="saveChangedElement(element)"
            ></mat-checkbox>
            <input
              type="text"
              id="{{ i + 1 }}-priceCompositionRequired"
              class="hidden"
              (keydown.enter)="element.priceCompositionRequired = !element.priceCompositionRequired; saveChangedElement(element)"
            />
          </ng-container>
          <ng-template #noCheckBox>
            <input type="text" id="{{ i + 1 }}-priceCompositionRequired" class="hidden" readonly />
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="itemNumber">
        <th mat-header-cell *matHeaderCellDef resizeColumn class="drag-head mat-table-sticky" cdkDrag>
          OZ
          <div class="drag-head__handle" cdkDragHandle></div>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          [ngClass]="{ 'disabled-td': !(element.elementType !== 'NoteTextDto' && element.elementType !== 'ExecutionDescriptionDto') }"
        >
          <div
            class="itemNumber-wrap"
            *ngIf="element.elementType !== 'NoteTextDto' && element.elementType !== 'ExecutionDescriptionDto'; else withOutNumber"
          >
            <input
              [style.width]="
                element?.itemNumber.identifiers.slice(0, -1).join('.').length *
                  (element?.itemNumber.identifiers.slice(0, -1).join('.').length < 4 ? 10 : 7.2) +
                'px'
              "
              matInput
              type="text"
              readonly
              disabled
              [value]="element?.itemNumber.identifiers.slice(0, -1).join('.') + '.'"
            />
            <input
              matInput
              type="text"
              [disabled]="!isEditMode"
              [(ngModel)]="element.itemNumber.identifiers[element?.itemNumber.identifiers.length - 1]"
              (keydown)="preventKeyDownEvent($event, element)"
              (change)="saveChangedElement(element)"
              id="{{ i + 1 }}-itemNumber"
            />
            {{ $any(element).positionType | showPositionAbbreviation: $any(element).priceType }}
          </div>
          <ng-template #withOutNumber>
            <input type="text" id="{{ i + 1 }}-itemNumber" class="hidden" readonly />
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="shortText">
        <th mat-header-cell *matHeaderCellDef resizeColumn cdkDrag class="drag-head mat-table-sticky">
          Text
          <div class="drag-head__handle" cdkDragHandle></div>
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          <mat-form-field class="short-text" *ngIf="!element.label">
            <mat-label
              *ngIf="element.elementType === 'NoteTextDto' && !element.shortText && element.elementType === 'NoteTextDto' && !element.label"
              >Hinweis</mat-label
            >
            <mat-label *ngIf="element.elementType === 'ExecutionDescriptionDto' && (!element.shortText || !element.label)"
              >Ausführungsbeschreibung</mat-label
            >
            <input
              [disabled]="!isEditMode"
              matInput
              type="text"
              [(ngModel)]="element.shortText"
              (change)="saveChangedElement(element)"
              (keydown)="preventKeyDownEvent($event, element)"
              id="{{ i + 1 }}-shortText"
            />
          </mat-form-field>
          <mat-form-field class="short-text" *ngIf="element.label">
            <mat-label
              *ngIf="element.elementType === 'NoteTextDto' && !element.shortText && element.elementType === 'NoteTextDto' && !element.label"
              >Hinweis</mat-label
            >
            <mat-label *ngIf="element.elementType === 'ExecutionDescriptionDto' && (!element.shortText || !element.label)"
              >Ausführungsbeschreibung</mat-label
            >
            <input
              [disabled]="!isEditMode"
              matInput
              type="text"
              [(ngModel)]="element.label"
              (change)="saveChangedElement(element)"
              (keydown)="preventKeyDownEvent($event, element)"
              id="{{ i + 1 }}-shortText"
            />
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="unitPrice">
        <th mat-header-cell *matHeaderCellDef class="right drag-head mat-table-sticky" resizeColumn cdkDrag>
          EP
          <div class="drag-head__handle" cdkDragHandle></div>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          [ngClass]="{ 'disabled-td': element.elementType !== 'PositionDto' }"
          class="right"
        >
          <div fxLayout="row" fxLayoutAlign="end center">
            <mat-icon class="unitPrice-icon" color="primary" *ngIf="listOfItemsWithFixedPrice?.[element.id]">change_circle</mat-icon>
            <input
              *ngIf="element.elementTypeDiscriminator === 'PositionDto'; else withOutUnitPrice"
              matInput
              type="text"
              matInputDecimalPlaces
              allowNumericWithDecimal
              [scale]="2"
              [disabled]="!isEditMode"
              [(ngModel)]="element.unitPrice"
              (change)="setFixedPrice(element)"
              (keydown)="preventKeyDownEvent($event, element)"
              id="{{ i + 1 }}-unitPrice"
            />
          </div>
          <ng-template #withOutUnitPrice>
            <input type="text" id="{{ i + 1 }}-unitPrice" class="hidden" readonly />
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef class="right drag-head mat-table-sticky" resizeColumn cdkDrag>
          Menge
          <div class="drag-head__handle" cdkDragHandle></div>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          [ngClass]="{ 'disabled-td': element.elementType !== 'PositionDto' }"
          class="right"
        >
          <input
            *ngIf="element.elementTypeDiscriminator === 'PositionDto'; else withOutQuantity"
            matInput
            type="text"
            matInputDecimalPlaces
            allowNumericWithDecimal
            [scale]="2"
            [disabled]="!isEditMode"
            [(ngModel)]="element.quantity"
            (change)="saveChangedElement(element, 'quantity')"
            (keydown)="preventKeyDownEvent($event, element)"
            id="{{ i + 1 }}-quantity"
          />
          <ng-template #withOutQuantity>
            <input type="text" id="{{ i + 1 }}-quantity" class="hidden" readonly />
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="unitTag">
        <th mat-header-cell *matHeaderCellDef class="right drag-head mat-table-sticky" resizeColumn cdkDrag>
          Einheit
          <div class="drag-head__handle" cdkDragHandle></div>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          [ngClass]="{ 'disabled-td': element.elementType !== 'PositionDto' }"
          class="right"
        >
          <input
            *ngIf="element.elementTypeDiscriminator === 'PositionDto'; else withOutUnitTag"
            matInput
            type="text"
            [disabled]="!isEditMode"
            [(ngModel)]="element.unitTag"
            (change)="saveChangedElement(element)"
            (keydown)="preventKeyDownEvent($event, element)"
            id="{{ i + 1 }}-unitTag"
          />
          <ng-template #withOutUnitTag>
            <input type="text" id="{{ i + 1 }}-unitTag" class="hidden" readonly />
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="totalPrice">
        <th mat-header-cell *matHeaderCellDef class="right drag-head mat-table-sticky" resizeColumn cdkDrag>
          Gesamtpreis
          <div class="drag-head__handle" cdkDragHandle></div>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          [ngClass]="{ 'disabled-td': !(element.elementType === 'PositionDto' || element.elementType === 'ServiceSpecificationGroupDto') }"
          class="right"
        >
          <input
            *ngIf="element.elementType === 'PositionDto' || element.elementType === 'ServiceSpecificationGroupDto'; else withOutTotalPrice"
            matInput
            matInputDecimalPlaces
            [scale]="2"
            type="number"
            [disabled]="true"
            [(ngModel)]="element.totalPrice"
            (change)="saveChangedElement(element)"
            (keydown)="preventKeyDownEvent($event, element)"
            id="{{ i + 1 }}-totalPrice"
          />
          <ng-template #withOutTotalPrice>
            <input type="text" id="{{ i + 1 }}-totalPrice" class="hidden" readonly />
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="deductionFactor">
        <th mat-header-cell *matHeaderCellDef class="right drag-head mat-table-sticky" resizeColumn cdkDrag>
          Nachlassfaktor
          <div class="drag-head__handle" cdkDragHandle></div>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          [ngClass]="{ 'disabled-td': !(element.elementType === 'PositionDto' || element.elementType === 'ServiceSpecificationGroupDto') }"
          class="right"
        >
          <input
            *ngIf="
              element.elementTypeDiscriminator === 'PositionDto' || element.elementTypeDiscriminator === 'ServiceSpecificationGroupDto';
              else withOutDeductionFactor
            "
            matInput
            type="text"
            allowNumericWithDecimal
            [disabled]="!isEditMode"
            [(ngModel)]="element.deductionFactor"
            (change)="saveChangedElement(element)"
            (keydown)="preventKeyDownEvent($event, element)"
            id="{{ i + 1 }}-deductionFactor"
          />
          <ng-template #withOutDeductionFactor>
            <input type="text" id="{{ i + 1 }}-deductionFactor" class="hidden" readonly />
          </ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true" class="mat-header-row"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: columnsToDisplay"
        [ngClass]="{
          'selected-row-edit': element.id === selectedElementForEdit?.id && isEditMode,
          'selected-row-change': element.id === selectedElementForChange?.id && !isEditMode
        }"
        (contextmenu)="isEditMode && onContextMenu($event)"
        (click)="!isEditMode && selectRowForChangeSelectedElement(element)"
      ></tr>
    </table>
  </cdk-virtual-scroll-viewport>
</div>

<div
  style="visibility: hidden; position: fixed"
  [style.left.px]="contextMenuPosition.x"
  [style.top.px]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
></div>
<mat-menu #contextMenu="matMenu" #contextMenu2="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="quickAddPostion()">Neues Element</button>
    <button mat-menu-item (click)="quickRemovePosition()" [disabled]="!selectedElementForEdit">Element Entfernen</button>
  </ng-template>
</mat-menu>

<pa-service-specification-group
  [element]="isEditMode ? selectedElementForEdit : selectedElementForChange"
  *ngIf="
    (isEditMode && selectedElementForEdit?.elementTypeDiscriminator === 'ServiceSpecificationGroupDto') ||
    (!isEditMode && selectedElementForChange?.elementTypeDiscriminator === 'ServiceSpecificationGroupDto')
  "
>
</pa-service-specification-group>
<pa-position
  [showOnlyCompactView]="true"
  [element]="isEditMode ? selectedElementForEdit : selectedElementForChange"
  *ngIf="
    (isEditMode && selectedElementForEdit?.elementTypeDiscriminator === 'PositionDto') ||
    (!isEditMode && selectedElementForChange?.elementTypeDiscriminator === 'PositionDto')
  "
>
</pa-position>
