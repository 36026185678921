<div class="wrapper" #wrapElement (mouseenter)="enterMouse()" (mouseleave)="isMenuOpened && tryLeave()">
  <div *ngIf="!selectedProject">
    <div fxLayoutGap="12px" class="project-filter">
      <mat-form-field fxFlex="1 0">
        <mat-label>Suche</mat-label>
        <input matInput [(ngModel)]="filterProject" (ngModelChange)="beginProject()" />
      </mat-form-field>
      <mat-form-field appearance="fill" class="project-select">
        <mat-label>Firma</mat-label>
        <mat-select name="company" [(ngModel)]="filterByCompanyId" (ngModelChange)="beginProject()">
          <mat-option [value]="''">Alle</mat-option>
          <mat-option *ngFor="let item of listCompany" [value]="item.id">{{ item.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="mat-elevation-z8">
      <pa-projects-control
        [filter]="filterProject"
        [filterCompany]="filterByCompanyId"
        [isInnerWindow]="true"
        (selectRow)="selectProject($event)"
        (getContextMenu)="runContextMenu($event)"
        (isMenuOpened)="getMenuStatus($event)"
      >
      </pa-projects-control>
    </div>
  </div>
  <div *ngIf="selectedProject" class="selected">
    <div class="selected__text">Ausgewähltes Projekt - {{ selectedProject.constructedProjectNumber }} {{ selectedProject.name }}</div>
    <button mat-raised-button color="warn" type="button" (click)="beginProject()">Abbrechen</button>
  </div>
  <div *ngIf="selectedProject && !selectedAvaProject" class="mat-elevation-z8">
    <pa-service-specifications-table-flexible [projectId]="selectedProject.id" (selectRow)="selectAvaProject($event)">
    </pa-service-specifications-table-flexible>
  </div>
  <div *ngIf="selectedAvaProject && numberOfAvaProjectsForProject > 1" class="selected">
    <div class="selected__text">Ausgewähltes Leistungsverzeichnis - {{ selectedAvaProject.name }}</div>
    <button mat-raised-button color="warn" type="button" (click)="beginAvaProject()">Abbrechen</button>
  </div>
  <div *ngIf="selectedGroup" class="selected">
    <div class="selected__text">Gruppe - {{ selectedGroup.itemNumber.stringRepresentation }} - {{ selectedGroup.shortText }}</div>
    <button mat-raised-button color="warn" type="button" (click)="beginGroup()">Abbrechen</button>
  </div>
</div>
