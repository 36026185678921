<h1>Modelle</h1>
<div>
  <button mat-raised-button color="primary" (click)="createModel()">Neues Modell</button>
</div>
<div class="filter">
  <mat-form-field class="filter__input">
    <mat-label>Suche</mat-label>
    <input matInput [(ngModel)]="filter" (ngModelChange)="onFilter($event)" />
  </mat-form-field>
</div>
<div>
  <table mat-table [dataSource]="bimModelFileLightqueryService" matSort (matSortChange)="bimModelFileLightqueryService.onSort($event)">
    <ng-container matColumnDef="fileName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Dateiname</th>
      <td mat-cell *matCellDef="let element">{{ element.fileName }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="sizeInBytes">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Größe</th>
      <td mat-cell *matCellDef="let element">{{ element.sizeInBytes | fileSize }}</td>
    </ng-container>

    <ng-container matColumnDef="createdAtUtc">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Erstellt</th>
      <td mat-cell *matCellDef="let element">{{ element.createdAtUtc | date: 'dd.MM.yyyy' }}</td>
    </ng-container>

    <ng-container matColumnDef="geometryOperationStatus">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Geometrie</th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex; align-items: center" *ngIf="element.geometryOperationStatus === bimFileStatus.Pending">
          {{ element.geometryOperationStatus | bimFileStatus }}
          <div class="process"></div>
        </div>
        <ng-container *ngIf="element.geometryOperationStatus !== bimFileStatus.Pending">
          {{ element.geometryOperationStatus | bimFileStatus }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="structureOperationStatus">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Eigenschaften</th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex; align-items: center" *ngIf="element.structureOperationStatus === bimFileStatus.Pending">
          {{ element.structureOperationStatus | bimFileStatus }}
          <div class="process"></div>
        </div>
        <ng-container *ngIf="element.structureOperationStatus !== bimFileStatus.Pending">
          {{ element.structureOperationStatus | bimFileStatus }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="errorMessage">
      <th mat-header-cell *matHeaderCellDef>Fehler</th>
      <td mat-cell *matCellDef="let element">
        <p *ngIf="element.geometryOperationErrorMessage">{{ element.geometryOperationErrorMessage }}</p>
        <p *ngIf="element.structureOperationErrorMessage">{{ element.structureOperationErrorMessage }}</p>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="master-table-tr"
      [class.available]="
        row.geometryOperationStatus === bimFileStatus.Succeeded && row.structureOperationStatus === bimFileStatus.Succeeded
      "
      (click)="selectModel(row)"
    ></tr>
  </table>
  <pa-upper-paginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    [upperPaginated]="bimModelFileLightqueryService.paginationResult | async"
    [upperService]="bimModelFileLightqueryService"
    (page)="bimModelFileLightqueryService.onPage($event)"
  >
  </pa-upper-paginator>
</div>
