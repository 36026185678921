import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChangeViewportHeightService {
  private viewportHeightSource = new BehaviorSubject<number>(0);
  viewportHeight = this.viewportHeightSource.asObservable();

  private updateViewportHeigthSource = new Subject<boolean>();
  updateViewportHeight = this.updateViewportHeigthSource.asObservable();
  constructor() {}

  setViewportHeight(viewportHeight: number): void {
    this.viewportHeightSource.next(viewportHeight);
  }

  updatedViewportHeight(isUpdate: boolean): void {
    this.updateViewportHeigthSource.next(isUpdate);
  }
}
