import { NgIf, NgFor } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { MatTabNav, MatTabLink } from '@angular/material/tabs';
import { ActivatedRoute, RouterLinkActive, RouterLink, RouterOutlet } from '@angular/router';

import { getAppConfig } from 'app/app-config-accessor';

const InvoiceTabsConstant = [
  {
    link: 'service-periods',
    label: 'Leistungszeiträume (LZ)'
  },
  {
    link: 'invoice-periods',
    label: 'Abrechnungszeiträume (AZ)'
  },
  {
    link: 'invoice-recipients',
    label: 'Rechnungsempfänger (RE)'
  },
  {
    link: 'building-element-codes',
    label: 'Bauteilcodes (BT-C)'
  }
];

const ManagmentTabsConstant = [
  {
    link: 'contact',
    label: 'Auftraggeber'
  },
  {
    link: 'tenderer-contact',
    label: 'Ausschreibende Stelle'
  },
  {
    link: 'project-company',
    label: 'Firma'
  },
  {
    link: 'project-award',
    label: 'Vergabe'
  },
  {
    link: 'project-settings',
    label: 'Einstellungen'
  },
  {
    link: 'project-terms',
    label: 'Zahlungsbedingungen'
  },
  {
    link: 'copy',
    label: 'Projektkopie'
  },
  {
    link: 'status',
    label: 'Status'
  },
  ...InvoiceTabsConstant,
  {
    link: 'edit-project',
    label: 'Projektdaten'
  },
  {
    link: 'lock-project',
    label: 'Projektsperre'
  },
  {
    link: 'delete-project',
    label: 'Projekt Löschen'
  },
  // We're not using that at the moment
  /*
  {
    link: 'qto-type',
    label: 'Abrechnungstyp'
  },
  */
  {
    link: 'cost-center',
    label: 'Kostenstelle'
  }
];

@Component({
  selector: 'pa-project-management',
  templateUrl: './project-management.component.html',
  styleUrls: ['./project-management.component.scss'],
  standalone: true,
  imports: [NgIf, MatTabNav, NgFor, MatTabLink, RouterLinkActive, RouterLink, RouterOutlet]
})
export class ProjectManagementComponent implements OnInit {
  managmentTabs = [];
  activatedRoute = inject(ActivatedRoute);
  isQtoOnlyMode = getAppConfig().isQtoOnlyMode;
  ngOnInit(): void {
    this.managmentTabs = this.activatedRoute.snapshot.data.isShortTabs ? InvoiceTabsConstant : ManagmentTabsConstant;

    if (this.isQtoOnlyMode) {
      const excludedTabs = ['status', 'lock-project', 'qto-type', 'delete-project', 'copy', 'project-company'];

      this.managmentTabs = this.managmentTabs.filter((tab) => excludedTabs.indexOf(tab.link) === -1);
    }
  }
}
