import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

import { ConfirmationType } from 'app/shared/models/dialog-config.model';

import { ModalService } from '../../services/modal.service';

import { ModalSettingWindowComponent } from '../modal-setting-window/modal-setting-window.component';

@Component({
  selector: 'pa-general-equipment',
  templateUrl: './general-equipment.component.html',
  styleUrls: ['./general-equipment.component.scss'],
  standalone: true,
  imports: [MatIcon]
})
export class GeneralEquipmentComponent {
  @Input() mode: string;

  constructor(private modalService: ModalService) {}

  showSetting(): void {
    this.modalService.openModal(ModalSettingWindowComponent, { dialogType: ConfirmationType.General, data: this.mode });
  }
}
