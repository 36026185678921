<div mat-dialog-content>
  <h2 cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Projektsuche</h2>
  <div class="wrapper">
    <div class="column gap">
      <div class="row">
        <div class="column gap">
          <div class="row">
            <div class="row">
              <div class="topic">Proj.Nr.</div>
              <input class="val" type="text" [(ngModel)]="projectNumber" (ngModelChange)="filter()" />
            </div>
            <div class="row">
              <div class="topic">AG</div>
              <input class="val" type="text" [(ngModel)]="contactName" (ngModelChange)="filter()" />
            </div>
          </div>

          <div class="row">
            <div class="topic">BV</div>
            <input class="val-long" type="text" [(ngModel)]="nameFull" (ngModelChange)="filter()" />
          </div>
          <div>
            <mat-checkbox [(ngModel)]="showArchivedProjects" (ngModelChange)="filter()"> Archivierte Projekte anzeigen </mat-checkbox>
          </div>
        </div>

        <mat-form-field appearance="fill" class="project-select">
          <mat-label>Status</mat-label>
          <mat-select name="status" [(value)]="filterByStatus" (selectionChange)="filter()">
            <mat-option [value]="''">Alle</mat-option>
            <mat-option *ngFor="let item of listStatus" [value]="item">{{ item | projectStatus }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="project-select">
          <mat-label>Firma</mat-label>
          <mat-select name="status" [(value)]="filterByCompanyId" (selectionChange)="filter()">
            <mat-option [value]="''">Alle</mat-option>
            <mat-option *ngFor="let item of listCompanies" [value]="item.id">{{ item?.name ?? 'Alle' }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <fieldset class="table">
        <legend>Projekte - Auswahl mit Doppelklick</legend>
        <pa-find-project-table [projectsService]="projectsService" [clickFunction]="dataClickFunction"></pa-find-project-table>
      </fieldset>
    </div>
  </div>

  <div class="buttons">
    <button color="warn" mat-raised-button mat-dialog-close>Abbrechen</button>
  </div>
</div>
