import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';

import { FooterComponent, HeaderComponent } from '@dangl/angular-material-shared';

import { CalculationMiddleWagesComponent } from '@serv-spec/components/calculation-middle-wages/calculation-middle-wages.component';
import { InvoicePositionsComponent } from '@serv-spec/components/invoice/components/invoice-positions/invoice-positions.component';
import { AllowNumericWithDecimalDirective } from '@serv-spec/directives/allow-numeric-with-decimal.directive';
import { NestingColorDirective } from '@serv-spec/directives/nesting-color.directive';
import { UpdateViewportSizeDirective } from '@serv-spec/directives/update-viewport-size.directive';

import { GroupTotalsTableComponent } from 'app/areas/elements/components/group-totals-table/group-totals-table.component';
import { ResizableDirective } from 'app/areas/project-id/components/service-specifications/components/invoice/directives/resizable.directive';
import { ContextMenuSettingComponent } from 'app/shared/components/context-menu-setting/context-menu-setting.component';
import { SpinnerOverlayComponent } from 'app/shared/components/spinner/spinner-overlay/spinner-overlay.component';

import { SubPositionItemNumberComponent } from './../areas/project-id/components/service-specifications/components/sub-position-item-number/sub-position-item-number.component';
import { AngularMaterialModule } from './angular-material.module';
import { AppRoutingModule } from './app-routing.module';
import { ButtonBackComponent } from './components/button-back/button-back.component';
import { CategoryInformComponent } from './components/category-inform/category-inform.component';
import { ChangeNameModalComponent } from './components/change-name-modal/change-name-modal.component';
import { ContextMenuGearInTopComponent } from './components/context-menu-gear-in-top/context-menu-gear-in-top.component';
import { CopyMatchPositionModalComponent } from './components/copy-match-position-modal/copy-match-position-modal.component';
import { ErrorReportComponent } from './components/error-report/error-report.component';
import { FilterListDialogComponent } from './components/filter-list-dialog/filter-list-dialog.component';
import { FindProjectTableComponent } from './components/find-project-table/find-project-table.component';
import { FindProjectComponent } from './components/find-project/find-project.component';
import { GeneralHeaderComponent } from './components/general-header/general-header.component';
import { HelpWindowModalComponent } from './components/help-window-modal/help-window-modal.component';
import { HelpWindowTocComponent } from './components/help-window-toc/help-window-toc.component';
import { KickProjectUserComponent } from './components/kick-project-user/kick-project-user.component';
import { LockIconComponent } from './components/lock-icon/lock-icon.component';
import { LoginPartialComponent } from './components/login-partial/login-partial.component';
import { LoginComponent } from './components/login/login.component';
import { LongTextEditModalComponent } from './components/long-text-edit-modal/long-text-edit-modal.component';
import { LongTextComponent } from './components/long-text/long-text.component';
import { MainFrameComponent } from './components/main-frame/main-frame.component';
import { MatSelectSearchComponent } from './components/mat-select-search/mat-select-search.component';
import { ModalConfirmComponent } from './components/modal-confirm/modal-confirm.component';
import { ModalSettingWindowComponent } from './components/modal-setting-window/modal-setting-window.component';
import { ModalUserSettingsComponent } from './components/modal-user-settings/modal-user-settings.component';
import { NoteIconComponent } from './components/note-icon/note-icon.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { NotificationWindowComponent } from './components/notification-window/notification-window.component';
import { PdfOverlayComponent } from './components/pdf-overlay/pdf-overlay.component';
import { PositionLineComponent } from './components/position-line/position-line.component';
import { ProgressbarPercentageComponent } from './components/progressbar-percentage/progressbar-percentage.component';
import { ProjectUsersComponent } from './components/project-users/project-users.component';
import { ProjectsSideNavComponent } from './components/projects-side-nav/projects-side-nav.component';
import { SelectAllRowsComponent } from './components/select-all-rows/select-all-rows.component';
import { SelectProjectQtoTypeModalComponent } from './components/select-project-qto-type-modal/select-project-qto-type-modal.component';
import { SelectViewsComponent } from './components/select-views/select-views.component';
import { SelectingPriceComponentModalComponent } from './components/selecting-price-component-modal/selecting-price-component-modal.component';
import { SelectionListWithNumberAndTextComponent } from './components/selection-list-with-number-and-text/selection-list-with-number-and-text.component';
import { ShowingColumnsModalComponent } from './components/showing-columns-modal/showing-columns-modal.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { SpinnerComponent } from './components/spinner/spinner/spinner.component';
import { SwitcherComponent } from './components/switcher/switcher.component';
import { TopLevelMenuComponent } from './components/top-level-menu/top-level-menu.component';
import { UpdateCheckComponent } from './components/update-check/update-check.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { UpperPaginatorComponent } from './components/upper-paginator/upper-paginator.component';
import { UserKickNotificationComponent } from './components/user-kick-notification/user-kick-notification.component';
import { ZoomerComponent } from './components/zoomer/zoomer.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { ChangePaginationButtonsDirective } from './directives/change-pagination-buttons.directive';
import { AllRequestsInterceptor } from './interceptors/all-requests.interceptor';
import { BaseUrlInterceptor } from './interceptors/base-url.interceptor';
import { ErrorsInterceptor } from './interceptors/errors.interceptor';
import { FileSizePipe } from './pipes/file-size.pipe';
import { ImgSrcPipe } from './pipes/img-src.pipe';
import { IncludesPipe } from './pipes/includes.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SinglePositionTypePipe } from './pipes/single-position-type.pipe';
import { AdditionTypePipe } from './pipes/ui-data-display/addition-type.pipe';
import { AvaFileExportPhasePipe } from './pipes/ui-data-display/ava-file-export-phase.pipe';
import { AvaFileTargetTypePipe } from './pipes/ui-data-display/ava-file-target-type.pipe';
import { AvaProjectTypePipe } from './pipes/ui-data-display/ava-project-type.pipe';
import { CatalogueTypePipe } from './pipes/ui-data-display/catalogue-type.pipe';
import { ColumnNamesPipe } from './pipes/ui-data-display/column-names.pipe';
import { ComissionStatusPipe } from './pipes/ui-data-display/comission-status.pipe';
import { ElementTypeClassPipe } from './pipes/ui-data-display/element-type-class.pipe';
import { ElementTypePipe } from './pipes/ui-data-display/element-type.pipe';
import { EmbeddedViewNamesPipe } from './pipes/ui-data-display/embedded-view-names.pipe';
import { ItemNumberSchemaTierTypePipe } from './pipes/ui-data-display/item-number-schema-tier-type.pipe';
import { ItemNumberTypePipe } from './pipes/ui-data-display/item-number-type.pipe';
import { NotificationTypePipe } from './pipes/ui-data-display/notification-type.pipe';
import { OriginDtoPipe } from './pipes/ui-data-display/origin-dto.pipe';
import { PositionTextPipe } from './pipes/ui-data-display/position-text.pipe';
import { PositionTypePipe } from './pipes/ui-data-display/position-type.pipe';
import { PriceComponentTypePipe } from './pipes/ui-data-display/price-component-type.pipe';
import { PriceInquiryStatusPipe } from './pipes/ui-data-display/price-inquiry-status.pipe';
import { PriceTypePipe } from './pipes/ui-data-display/price-type.pipe';
import { ProjectCurrencyPipe } from './pipes/ui-data-display/project-currency.pipe';
import { ProjectQtoTypePipe } from './pipes/ui-data-display/project-qto-type.pipe';
import { ProjectStatusPipe } from './pipes/ui-data-display/project-status.pipe';
import { QuantityTakeOffCalculationTypePipe } from './pipes/ui-data-display/quantity-take-off-calculation-type.pipe';
import { ServiceTypePipe } from './pipes/ui-data-display/service-type.pipe';
import { ShortPositionAndPriceTypePipe } from './pipes/ui-data-display/short-position-and-price-type.pipe';
import { ShowedTableQuantitiesPipe } from './pipes/ui-data-display/showed-table-quantities.pipe';
import { TextTemplateTypePipe } from './pipes/ui-data-display/text-template-type.pipe';
import { UiPositionTypePipe } from './pipes/ui-data-display/ui-position-type.pipe';
import { CustomPaginatorIntl } from './services/custom-paginator-intl.service';
import { DateFormatService } from './services/date-format.service';
import { ZonelessEventPluginService } from './services/zoneless-event-plugin.service';
import { ThirdPartyModule } from './third-party.module';

import { CalculationRowLineComponent } from '../areas/project-id/components/service-specifications/components/calculation-row-line/calculation-row-line.component';
import { CalculationComponent } from '../areas/project-id/components/service-specifications/components/calculation/calculation.component';
import { MatInputDecimalPlacesDirective } from '../areas/project-id/components/service-specifications/directives/mat-input-decimal-places.directive';
import { ItemNumberEditingComponent } from '../areas/service-specification-editing/components/item-number-editing/item-number-editing.component';
import { FormulaWarningDirective } from '../shared/directives/formula-warning.directive';
import { InputMaskDirective } from '../shared/directives/input-mask.directive';
import { RowSelectionDirective } from '../shared/directives/row-selection.directive';
import { NgxDropzoneModule } from 'ngx-dropzone';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    NgxDropzoneModule,
    ThirdPartyModule,
    AppRoutingModule,
    MatMomentDateModule,
    OverlayModule,
    UpperPaginatorComponent,
    PositionTextPipe,
    ElementTypeClassPipe,
    IncludesPipe,
    ProjectCurrencyPipe,
    UploadFileComponent,
    MatButtonModule,
    AllowNumericWithDecimalDirective,
    HelpWindowModalComponent,
    LoginPartialComponent,
    LoginComponent,
    SideNavComponent,
    ProjectsSideNavComponent,
    ErrorReportComponent,
    FilterListDialogComponent,
    GeneralHeaderComponent,
    CalculationComponent,
    UpdateCheckComponent,
    SafeHtmlPipe,
    OriginDtoPipe,
    FileSizePipe,
    PositionTypePipe,
    QuantityTakeOffCalculationTypePipe,
    AvaFileTargetTypePipe,
    AvaFileExportPhasePipe,
    PriceComponentTypePipe,
    ItemNumberTypePipe,
    ItemNumberSchemaTierTypePipe,
    ModalSettingWindowComponent,
    ModalUserSettingsComponent,
    ModalConfirmComponent,
    ProjectUsersComponent,
    KickProjectUserComponent,
    UserKickNotificationComponent,
    ProjectStatusPipe,
    AvaProjectTypePipe,
    AdditionTypePipe,
    CatalogueTypePipe,
    PriceTypePipe,
    ComissionStatusPipe,
    ServiceTypePipe,
    ChangePaginationButtonsDirective,
    ButtonBackComponent,
    SwitcherComponent,
    ImgSrcPipe,
    LongTextComponent,
    LongTextEditModalComponent,
    MatInputDecimalPlacesDirective,
    NotificationListComponent,
    NotificationWindowComponent,
    NoteIconComponent,
    NotificationTypePipe,
    MatSelectSearchComponent,
    ShortPositionAndPriceTypePipe,
    PriceInquiryStatusPipe,
    ElementTypePipe,
    ItemNumberEditingComponent,
    SelectingPriceComponentModalComponent,
    GroupTotalsTableComponent,
    ShowingColumnsModalComponent,
    ColumnNamesPipe,
    CopyMatchPositionModalComponent,
    ResizableDirective,
    SelectAllRowsComponent,
    SelectionListWithNumberAndTextComponent,
    CategoryInformComponent,
    CalculationRowLineComponent,
    ZoomerComponent,
    SelectViewsComponent,
    EmbeddedViewNamesPipe,
    TopLevelMenuComponent,
    ChangeNameModalComponent,
    LockIconComponent,
    TextTemplateTypePipe,
    ProgressbarPercentageComponent,
    FindProjectComponent,
    FindProjectTableComponent,
    CalculationMiddleWagesComponent,
    SpinnerComponent,
    SpinnerOverlayComponent,
    AutoFocusDirective,
    PdfOverlayComponent,
    SelectProjectQtoTypeModalComponent,
    ProjectQtoTypePipe,
    ContextMenuSettingComponent,
    ContextMenuGearInTopComponent,
    UpdateViewportSizeDirective,
    UiPositionTypePipe,
    SinglePositionTypePipe,
    SubPositionItemNumberComponent,
    NestingColorDirective,
    ShowedTableQuantitiesPipe,
    MainFrameComponent,
    HelpWindowTocComponent,
    InputMaskDirective,
    FormulaWarningDirective,
    PositionLineComponent,
    InvoicePositionsComponent,
    RowSelectionDirective,
    FooterComponent,
    HeaderComponent
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    ThirdPartyModule,
    LoginPartialComponent,
    SideNavComponent,
    AppRoutingModule,
    FilterListDialogComponent,
    GeneralHeaderComponent,
    CalculationComponent,
    NgxDropzoneModule,
    SafeHtmlPipe,
    OriginDtoPipe,
    FileSizePipe,
    PositionTypePipe,
    QuantityTakeOffCalculationTypePipe,
    AvaFileTargetTypePipe,
    AvaFileExportPhasePipe,
    PriceComponentTypePipe,
    ItemNumberTypePipe,
    ItemNumberSchemaTierTypePipe,
    ModalSettingWindowComponent,
    ModalConfirmComponent,
    ProjectStatusPipe,
    AvaProjectTypePipe,
    AdditionTypePipe,
    CatalogueTypePipe,
    PriceTypePipe,
    ComissionStatusPipe,
    ServiceTypePipe,
    ChangePaginationButtonsDirective,
    UpperPaginatorComponent,
    ButtonBackComponent,
    ProjectCurrencyPipe,
    SwitcherComponent,
    ShortPositionAndPriceTypePipe,
    ImgSrcPipe,
    LongTextComponent,
    LongTextEditModalComponent,
    MatInputDecimalPlacesDirective,
    NotificationTypePipe,
    MatSelectSearchComponent,
    MatMomentDateModule,
    PriceInquiryStatusPipe,
    ElementTypePipe,
    ElementTypeClassPipe,
    ItemNumberEditingComponent,
    IncludesPipe,
    GroupTotalsTableComponent,
    ResizableDirective,
    SelectAllRowsComponent,
    SelectionListWithNumberAndTextComponent,
    EmbeddedViewNamesPipe,
    PositionTextPipe,
    AllowNumericWithDecimalDirective,
    LockIconComponent,
    TextTemplateTypePipe,
    ProgressbarPercentageComponent,
    FindProjectComponent,
    FindProjectTableComponent,
    UploadFileComponent,
    CalculationMiddleWagesComponent,
    SpinnerComponent,
    OverlayModule,
    AutoFocusDirective,
    PdfOverlayComponent,
    CalculationRowLineComponent,
    ProjectQtoTypePipe,
    ContextMenuSettingComponent,
    ContextMenuGearInTopComponent,
    UpdateViewportSizeDirective,
    UiPositionTypePipe,
    SinglePositionTypePipe,
    NestingColorDirective,
    ShowedTableQuantitiesPipe,
    InputMaskDirective,
    FormulaWarningDirective,
    PositionLineComponent,
    InvoicePositionsComponent,
    RowSelectionDirective,
    FooterComponent,
    HeaderComponent
  ],
  providers: [
    DecimalPipe,
    DatePipe,
    PercentPipe,
    ImgSrcPipe,
    PriceInquiryStatusPipe,
    ShortPositionAndPriceTypePipe,
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: BaseUrlInterceptor
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: AllRequestsInterceptor
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: ErrorsInterceptor
    },
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: MAT_DATE_FORMATS,
      useClass: DateFormatService
    },
    {
      provide: EVENT_MANAGER_PLUGINS,
      useClass: ZonelessEventPluginService,
      multi: true
    }
  ]
})
export class SharedModule {}
