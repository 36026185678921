import { NgIf, AsyncPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

import { ProjectFileGet } from 'app/generated-client/generated-client';
import { FileElement } from 'app/shared/models';

import { ImgSrcPipe } from '../../../../../../shared/pipes/img-src.pipe';

@Component({
  selector: 'pa-project-file-list-preview',
  templateUrl: './project-file-list-preview.component.html',
  styleUrls: ['./project-file-list-preview.component.scss'],
  standalone: true,
  imports: [NgIf, MatIcon, AsyncPipe, ImgSrcPipe]
})
export class ProjectFileListPreviewComponent implements OnInit {
  @Input() projectFile: ProjectFileGet | FileElement;

  imageFileId: string;

  constructor() {}

  ngOnInit(): void {
    if (this.projectFile && this.projectFile.file && this.projectFile.file.mimeType && /^image.*/.test(this.projectFile.file.mimeType)) {
      this.imageFileId = this.projectFile.fileId;
    }
  }
}
