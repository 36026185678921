import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatMiniFabButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { NavigationEnd, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { RouteHistoryService } from '@shared/services/route-history.service';

import { AvaProjectsClient, ProjectGet } from 'app/generated-client/generated-client';
import { ProjectsService } from 'app/shared/services/lightquery/projects.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

@Component({
  selector: 'pa-button-back',
  templateUrl: './button-back.component.html',
  styleUrls: ['./button-back.component.scss'],
  standalone: true,
  imports: [MatMiniFabButton, MatTooltip, MatIcon]
})
export class ButtonBackComponent implements OnInit, OnDestroy {
  isHaveRoutes: boolean;
  private isBack = false;
  private $destroy: Subject<boolean> = new Subject<boolean>();
  selectedProject: ProjectGet;

  constructor(
    private router: Router,
    private projectsService: ProjectsService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private avaProjectsClient: AvaProjectsClient,
    private routeHistoryService: RouteHistoryService
  ) {}

  ngOnInit(): void {
    this.isHaveRoutes = this.routeHistoryService.getLength() > 1;
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.$destroy)
      )
      .subscribe((event: NavigationEnd) => {
        if (this.isBack) {
          this.isBack = false;
        } else {
          this.routeHistoryService.add(event.urlAfterRedirects);
          this.isHaveRoutes = this.routeHistoryService.getLength() > 1;
        }
      });

    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((selectedProject) => {
      this.selectedProject = selectedProject;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  goBack(): void {
    let projectId: string, serviceSpecificationId: string;
    this.routeHistoryService.back();
    const previousUrl = this.routeHistoryService.getPrevious();
    if (this.selectedProject === null) {
      this.selectedSpecificationMessengerService.clearSelectedServiceSpecification();
    }
    if (previousUrl) {
      projectId = previousUrl.split('/')[2];
      serviceSpecificationId = previousUrl.split('/')[4];
    }
    if (projectId) {
      const selectedProject = this.projectsService.tryGetProjectById(projectId);
      this.selectedProjectMessengerService.setSelectedProject(selectedProject);
    }
    if (serviceSpecificationId && serviceSpecificationId.length === 36) {
      this.selectedSpecificationMessengerService.selectedServiceSpecification
        .pipe(take(1), takeUntil(this.$destroy))
        .subscribe((selectedAvaProject) => {
          if (selectedAvaProject?.avaProjectId !== serviceSpecificationId) {
            this.avaProjectsClient
              .getAvaProjectContentById(projectId, serviceSpecificationId)
              .pipe(take(1))
              .subscribe((p) => {
                this.selectedSpecificationMessengerService.setSelectedServiceSpecification(
                  serviceSpecificationId,
                  p,
                  projectId,
                  selectedAvaProject.avaProject
                );
                this.router.navigateByUrl(previousUrl);
                this.isHaveRoutes = this.routeHistoryService.getLength() > 1;
                this.isBack = true;
              });
          } else {
            this.router.navigateByUrl(previousUrl);
            this.isHaveRoutes = this.routeHistoryService.getLength() > 1;
            this.isBack = true;
          }
        });
    } else {
      this.router.navigateByUrl(previousUrl);
      this.isHaveRoutes = this.routeHistoryService.getLength() > 1;
      this.isBack = true;
    }
  }
}
