<h1 *ngIf="!isInProjectCreation">Kontakte</h1>
<a mat-raised-button color="primary" class="button-new" (click)="newContact()" *ngIf="!isInProjectCreation">Neuer Kontakt</a>
<a mat-raised-button color="primary" class="button-new" (click)="openImportContactModal()" *ngIf="!isInProjectCreation"
  >Kontakte Importieren</a
>
<header>
  <mat-form-field class="full-width">
    <mat-label>Suche</mat-label>
    <input matInput [ngModel]="filter" (ngModelChange)="contactsService.onFilter($event)" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Typ</mat-label>
    <mat-select [(ngModel)]="selectedContactType">
      <mat-option [value]="''"> Alle </mat-option>
      <mat-option *ngFor="let contactType of contactTypes" [value]="contactType">
        {{ contactType | contactType }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</header>
<table mat-table [dataSource]="contactsService" matSort (matSortChange)="contactsService.onSort($event)">
  <ng-container matColumnDef="contactNumber">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Nummer</th>
    <td mat-cell *matCellDef="let row">
      {{ row.contactNumber }}
    </td>
  </ng-container>
  <ng-container matColumnDef="contactType">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Typ</th>
    <td mat-cell *matCellDef="let row">
      {{ row.contactType | contactType }}
    </td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let row">
      {{ row.name }}
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="['contactNumber', 'contactType', 'name']"></tr>
  <tr mat-row *matRowDef="let row; columns: ['contactNumber', 'contactType', 'name']" (click)="selectedContact(row)"></tr>
</table>
<pa-upper-paginator
  [pageSizeOptions]="[5, 10, 25, 100]"
  [upperPaginated]="contactsService.paginationResult | async"
  [upperService]="contactsService"
  (page)="contactsService.onPage($event)"
>
</pa-upper-paginator>
