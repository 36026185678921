import { IfcProperty, IfcStructure } from '../../../generated-client/generated-client';

import { IFilterType } from 'app/areas/bim-view/interface/filter-type.interface';
import { IPropertySetFilter } from 'app/areas/bim-view/interface/property-set-filter.interface';
import { Injectable } from '@angular/core';
import { dynamicSortProperty } from '../utilities/dynamic-sort-property';

@Injectable({
  providedIn: 'root'
})
export class BimStructureFilterService {
  constructor() {}

  public filterElements(structure: IfcStructure, propertyFilters: IPropertySetFilter[], ifcTypeFilter?: string): number[] {
    let elementIds = structure.entityIds;

    if (ifcTypeFilter) {
      if (!structure.entityIdsByIfcType) {
        return [];
      }

      elementIds = structure.entityIdsByIfcType[ifcTypeFilter];
      if (!elementIds) {
        return [];
      }
    }

    if (!propertyFilters || propertyFilters.length === 0) {
      return elementIds;
    }

    if (!structure.propertySetEntityIdsByPropertySetName) {
      return elementIds;
    }

    return this.reduceElementsFromListWithPropertySetFilters(elementIds, structure, propertyFilters);
  }

  reduceElementsFromListWithPropertySetFilters(
    entityIds: number[],
    structure: IfcStructure,
    propertyFilters: IPropertySetFilter[]
  ): number[] {
    // In case we're also filtering for property sets (and not just by IfcType), then
    // we check for each property filter all the elements that match
    propertyFilters.forEach((propertyFilter) => {
      let propertySetIds = structure.propertySetEntityIdsByPropertySetName[propertyFilter.propertySetName];

      if (propertyFilter.propertyName && propertySetIds) {
        // If a property name filter is included, we're only searching for property sets
        // that have a property with the given property name
        propertySetIds = propertySetIds
          .map((psetId) => ({
            pset: structure.propertySetsById[psetId],
            id: psetId
          }))
          .filter((pset) =>
            pset.pset.properties.find(
              (property) =>
                property.property === propertyFilter.propertyName &&
                // Additionally, if a property value is given as well, we're also filtering
                // for just the given property value
                (!propertyFilter.propertyValue || property.value === propertyFilter.propertyValue)
            )
          )
          .map((pset) => pset.id);
      }
      if (propertySetIds) {
        if (!propertyFilter.invertFilter) {
          // If we've got propertySetIds here, this means that we're just filtering for some specific
          // property sets, so we're filtering to just returns those elements that have one of the property
          // sets from the filter
          entityIds = entityIds.filter(
            (elementId) =>
              structure.propertySetIdsByEntityId[elementId] &&
              structure.propertySetIdsByEntityId[elementId].some((pSetId) => propertySetIds.includes(pSetId))
          );
        } else {
          entityIds = entityIds.filter(
            (elementId) =>
              structure.propertySetIdsByEntityId[elementId] &&
              !structure.propertySetIdsByEntityId[elementId].some((pSetId) => propertySetIds.includes(pSetId))
          );
        }
      }
    });

    return entityIds;
  }

  getTypes(data: { [key: string]: number[] }): IFilterType[] {
    return Object.keys(data)
      .map((key) => {
        return {
          value: key,
          viewValue: key
        };
      })
      .sort(dynamicSortProperty('viewValue'));
  }

  getPropertySetName(structure: IfcStructure, ifcTypes: string[]): IFilterType[] {
    if (!structure) {
      return [];
    }
    if (ifcTypes.length > 0) {
      const propertySetName: IFilterType[] = [];
      const uniquePropertySetName = new Set();
      let properties = [];
      ifcTypes.forEach((ifcType) => {
        properties = [...properties, ...structure.entityIdsByIfcType[ifcType].map((v) => structure.propertySetIdsByEntityId[v])];
      });
      properties.forEach((v) => v?.forEach((psetId) => uniquePropertySetName.add(structure.propertySetsById[psetId].propertySet)));
      for (const item of uniquePropertySetName) {
        propertySetName.push({
          value: item as string,
          viewValue: item as string
        });
      }
      return propertySetName.sort(dynamicSortProperty('viewValue'));
    }
    return this.getTypes(structure.propertySetEntityIdsByPropertySetName);
  }

  getPropertyName(structure: IfcStructure, propertySetName: string): IFilterType[] {
    if (!structure) {
      return [];
    }
    const properties: IfcProperty[] = this.getProperties(structure, propertySetName);
    const propertyName: IFilterType[] = [];
    const uniquePropertyName = new Set();
    properties.forEach((v) => uniquePropertyName.add(v.property));
    for (const item of uniquePropertyName) {
      propertyName.push({
        value: item as string,
        viewValue: item as string
      });
    }
    return propertyName.sort(dynamicSortProperty('viewValue'));
  }

  getPropertyValue(structure: IfcStructure, propertySetName: string, propertyName: string): IFilterType[] {
    if (!structure) {
      return [];
    }
    if (!propertySetName) {
      return [];
    }

    if (!propertyName) {
      return [];
    }
    const properties: IfcProperty[] = this.getProperties(structure, propertySetName);
    const propertyValue: IFilterType[] = [];
    const uniquePropertyValue = new Set();
    const propertiesBypropertyName = properties.filter((v) => v.property === propertyName);
    propertiesBypropertyName.forEach((v) => uniquePropertyValue.add(v.value));
    for (const item of uniquePropertyValue) {
      propertyValue.push({
        value: item as string,
        viewValue: item as string
      });
    }
    return propertyValue.sort(dynamicSortProperty('viewValue'));
  }

  private getProperties(structure: IfcStructure, propertySetName: string): IfcProperty[] {
    const properties: IfcProperty[] = [];
    if (structure.propertySetEntityIdsByPropertySetName[propertySetName]) {
      structure.propertySetEntityIdsByPropertySetName[propertySetName].forEach((v) => {
        properties.push(...structure.propertySetsById[v].properties);
      });
    }
    return properties;
  }
}
