import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
interface IHoverEffectOptions {
  apply: boolean;
}
@Directive({
  selector: '[paHoverEffect]',
  standalone: true
})
export class HoverEffectDirective {
  @Input('paHoverEffect') options: IHoverEffectOptions;
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  @HostListener('wheel', ['$event']) onScroll() {
    if (this.preventHoverFunctionalities()) {
      return;
    }
    this.onMouseLeave();
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (this.preventHoverFunctionalities()) {
      return;
    }

    const { top } = this.elementRef.nativeElement.getBoundingClientRect();

    for (let i = 0; i <= this.elementRef.nativeElement['children'].length - 1; i++) {
      if (this.elementRef.nativeElement['children'].item(i).className.includes('wrap')) {
        this.renderer.setStyle(this.elementRef?.nativeElement['children']?.item(i), 'position', 'fixed');
        this.renderer.setStyle(this.elementRef?.nativeElement['children']?.item(i), 'top', `${top}px`);
      }
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.preventHoverFunctionalities()) {
      return;
    }

    for (let i = 0; i <= this.elementRef.nativeElement['children'].length - 1; i++) {
      if (this.elementRef.nativeElement['children'].item(i).className.includes('wrap')) {
        this.renderer.setStyle(this.elementRef?.nativeElement['children']?.item(i), 'position', 'static');
        this.renderer.setStyle(this.elementRef?.nativeElement['children']?.item(i), 'top', '0px');
      }
    }
  }

  private preventHoverFunctionalities(): boolean {
    if (!this.elementRef || !this.renderer) {
      return true;
    }

    if (!this.options.apply) {
      return true;
    }

    return false;
  }
}
