<mat-tab-group animationDuration="0ms" class="infos">
  <mat-tab label="Typen">
    <pa-element-types-bim-view></pa-element-types-bim-view>
  </mat-tab>
  <mat-tab label="Raumstruktur">
    <pa-structure-bim-view></pa-structure-bim-view>
  </mat-tab>
  <mat-tab label="Filter">
    <pa-filtering-bim-view></pa-filtering-bim-view>
  </mat-tab>
  <mat-tab label="Menge">
    <pa-quantity-attribute-selection></pa-quantity-attribute-selection>
    <pa-show-assigned-element-quantity></pa-show-assigned-element-quantity>
    <pa-quantity-attribute-selection-table></pa-quantity-attribute-selection-table>
    <pa-managed-assign-element-quantity [bimFile]="bimFile"></pa-managed-assign-element-quantity>
  </mat-tab>
</mat-tab-group>
