<h2 mat-dialog-title>LV Update</h2>
<mat-dialog-content>
  <p>
    Das gesamte LV kann mit einer neuen GAEB-Datei aktualisiert werden.
    <span class="warn">Gelöschte Elemente werden dabei sofort entfernt und können nicht mehr wiederhergestellt werden.</span>
  </p>
  <pa-upload-file [uploadFileSettings]="uploadFileSettings" (uploadFile)="onSelect($event)"></pa-upload-file>
  <div *ngIf="selectedFile?.fileName">Ausgewählt: {{ selectedFile.fileName }}</div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="primary" [disabled]="!selectedFile" (click)="update()">Update</button>
  <button mat-raised-button color="warn" (click)="cancel()">Abbrechen</button>
</mat-dialog-actions>
<div class="spinner" fxFlexFill fxLayoutAlign="center center" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>
