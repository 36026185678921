import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

import { DataSource } from '@angular/cdk/table';
import { HttpClient } from '@angular/common/http';
import { MatSort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { PaginationBaseService } from 'ng-lightquery';

@Injectable({
  providedIn: 'root'
})
export class PaginationMainService<T> extends PaginationBaseService<T> implements DataSource<T>, OnDestroy {
  private filterSource: Subject<string> = new Subject<string>();
  $filter = this.filterSource.asObservable();
  $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(protected http: HttpClient) {
    super(http);
    this.initFiltering();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.$destroy.next(true);
    this.$destroy.complete();
    this.filterSource.complete();
  }

  connect(): Observable<T[]> {
    return this.paginationResult.pipe(map(r => r.data));
  }

  disconnect(): void {}

  beginBaseUrl(baseUrl: string): void {
    if (baseUrl != this.baseUrl) {
      this.baseUrl = baseUrl;
    }
  }

  getItemById(id: string): T {
    return this.lastPaginationResult?.data.find(c => c['id'] === id);
  }

  onPage(pageEvent: PageEvent): void {
    this.page = pageEvent.pageIndex + 1;
    this.pageSize = pageEvent.pageSize;
  }

  onSort(event: { active: string; direction: string }): void {
    if (!event.direction) {
      this.sort = null;
    } else {
      this.sort = { propertyName: event.active, isDescending: event.direction === 'desc' };
    }
  }

  onFilter(filter: string): void {
    this.filterSource.next(filter);
  }

  initService(init?: {
    filtering?: string | null;
    sorting?: { propertyName: string; isDescending: boolean };
    sortTableObj?: MatSort;
    paging?: { page: number; pageSize: number };
  }): void {
    this.onFilter(init?.filtering);
    if (init?.sorting !== null) {
      this.setSorting(init?.sorting, init?.sortTableObj);
    }
    if (init?.paging !== null) {
      this.setPaging(init?.paging);
    }
  }

  private initFiltering(): void {
    this.filterSource.pipe(debounceTime(250), takeUntil(this.$destroy)).subscribe((filterValue: string) => {
      this.setQueryParameter('filter', filterValue);
    });
  }

  private setPaging(paging: { page: number; pageSize: number } = { page: 1, pageSize: 5 }): void {
    this.page = paging.page;
    this.pageSize = paging.pageSize;
  }

  private setSorting(
    sorting: { propertyName: string; isDescending: boolean } = { propertyName: 'name', isDescending: false },
    sortTableObj?: MatSort
  ): void {
    this.sort = sorting;
    if (sortTableObj) {
      sortTableObj.sort({
        id: sorting.propertyName,
        start: sorting.isDescending ? 'desc' : 'asc',
        disableClear: true
      });
    }
  }
}
