import { Injectable } from '@angular/core';
import { AvaProjectAdditionGet } from '../../../generated-client/generated-client';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectedSpecificationAdditionsMessengerService {
  private selectedAdditionsSource = new ReplaySubject<AvaProjectAdditionGet>(1);
  selectedAdditions = this.selectedAdditionsSource.asObservable();

  setSelectedAdditions(serviceSpecification: AvaProjectAdditionGet): void {
    this.selectedAdditionsSource.next(serviceSpecification);
  }
}
