import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

import { AuthenticationMessenger } from '@dangl/angular-dangl-identity-client';

@Injectable({
  providedIn: 'root'
})
export class IsAuthenticatedGuard implements CanActivate, OnDestroy {
  $destroy: Subject<boolean> = new Subject<boolean>();
  constructor(private authenticationMessenger: AuthenticationMessenger, private router: Router) {
    this.authenticationMessenger.userInfo.pipe(takeUntil(this.$destroy)).subscribe(ui => this.isAuthenticatedSource.next(ui != null));
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private isAuthenticatedSource = new ReplaySubject<boolean>(1);

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isAuthenticatedSource.asObservable().pipe(
      takeUntil(this.$destroy),
      take(1),
      tap(isAuthenticated => {
        if (!isAuthenticated) {
          this.router.navigate(['/login'], {
            queryParams: {
              redirectAfterLogin: state.url
            }
          });
        }
      })
    );
  }
}
