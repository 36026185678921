<div fxLayoutAlign="space-between center">
  <h2>Preisspiegel</h2>
  <div fxLayoutGap="10px" style="padding-right: 8px">
    <button mat-button mat-raised-button color="primary" type="button" (click)="openExportModal()" [disabled]="isLoading">
      Exportieren
    </button>
  </div>
</div>

<div class="spinner" *ngIf="isLoading">
  <div fxFlex fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
  </div>
</div>

<div class="wrapper mat-elevation-z8" *ngIf="serviceSpecificationList?.length && !isLoading">
  <table mat-table [dataSource]="serviceSpecificationList">
    <ng-container matColumnDef="itemNumber">
      <th mat-header-cell *matHeaderCellDef>OZ</th>
      <td mat-cell *matCellDef="let position" class="bold">{{ position?.itemNumber?.stringRepresentation }}</td>
      <td mat-footer-cell *matFooterCellDef>
        <span class="bold"> Gesamt</span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="column-itemNumber">
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="column-shortText">
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="shortText">
      <th mat-header-cell *matHeaderCellDef>Kurztext</th>
      <td mat-cell *matCellDef="let position">{{ position | positionText }}</td>
      <td mat-footer-cell *matFooterCellDef></td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container *ngFor="let priceInquiryRequest of priceInquiryRequests">
      <ng-container [matColumnDef]="priceInquiryRequest.id">
        <th mat-header-cell *matHeaderCellDef [ngClass]="{ 'accepted-head': priceInquiryRequest.status === priceInquiryStatus.Accepted }">
          <div fxLayout="column">
            <span>{{ priceInquiryRequest.name }}</span>
            <span>({{ priceInquiryRequest.status | priceInquiryStatus }})</span>
          </div>
        </th>
        <td mat-cell *matCellDef="let position" [ngClass]="{ accepted: priceInquiryRequest.status === priceInquiryStatus.Accepted }">
          <span
            *ngIf="pricesByRequest[priceInquiryRequest.id][position.id]?.unitPrice != null; else positionWithOutPrice"
            [ngClass]="{
              'min-value':
                minMaxPositionPrices[position.id].minValue === pricesByRequest[priceInquiryRequest.id][position.id]?.unitPrice &&
                minMaxPositionPrices[position.id].maxValue !== pricesByRequest[priceInquiryRequest.id][position.id]?.unitPrice,
              'max-value':
                minMaxPositionPrices[position.id].maxValue === pricesByRequest[priceInquiryRequest.id][position.id]?.unitPrice &&
                minMaxPositionPrices[position.id].minValue !== pricesByRequest[priceInquiryRequest.id][position.id]?.unitPrice
            }"
          >
            {{ pricesByRequest[priceInquiryRequest.id][position.id].unitPrice | projectCurrency }}
          </span>
          <ng-container *ngIf="pricesByRequest[priceInquiryRequest.id][position.id]?.priceInquiryRequestPriceCommerceArticles?.length > 0">
            <span
              class="info"
              matTooltip="Artikel anzeigen"
              (click)="openArticles(pricesByRequest[priceInquiryRequest.id][position.id].priceInquiryRequestPriceCommerceArticles)"
            >
              i
            </span>
          </ng-container>

          <ng-template #positionWithOutPrice>
            <div fxLayout="row" (click)="openSetPriceModal(position.id, priceInquiryRequest.id)">
              <mat-icon class="add-price-icon" matTooltip="Kein Bieterpreis vorhanden, Klicken zur Eingabe">add_alert</mat-icon>
            </div>
          </ng-template>
        </td>

        <td mat-footer-cell *matFooterCellDef>
          <div>
            <span
              [ngClass]="{
                'min-value': minTotalValue === totalSumsByPriceInquiryRequestId[priceInquiryRequest.id],
                'max-value': maxTotalValue === totalSumsByPriceInquiryRequestId[priceInquiryRequest.id]
              }"
            >
              {{ totalSumsByPriceInquiryRequestId[priceInquiryRequest.id] | projectCurrency }}
            </span>
          </div>
        </td>
      </ng-container>
    </ng-container>

    <ng-container *ngFor="let priceInquiryRequest of priceInquiryRequests">
      <ng-container [matColumnDef]="'column-' + priceInquiryRequest.id">
        <td mat-footer-cell *matFooterCellDef>
          <div>
            <button mat-raised-button color="primary" class="accept" (click)="acceptPriceInquiry(priceInquiryRequest)">Übernehmen</button>
          </div>
        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-footer-row *matFooterRowDef="columnsForSecondFooter; sticky: true"></tr>
  </table>
</div>
