<div class="nav">
  <ng-container *ngIf="!projectId; else menuWithAdditionalData">
    <a routerLinkActive="active" mat-button routerLink="/projects">
      <mat-icon>business</mat-icon>
      Projekte
    </a>
    <a routerLinkActive="active" mat-button routerLink="/master-data" *ngIf="!isQtoOnlyMode">
      <mat-icon>settings</mat-icon>
      Stammdaten
    </a>
    <a routerLinkActive="active" mat-button routerLink="/management" *ngIf="!isQtoOnlyMode">
      <mat-icon>library_books</mat-icon>
      Verwaltung
    </a>
  </ng-container>
</div>
<ng-template #menuWithAdditionalData>
  <a
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    mat-button
    (click)="projectId = undefined"
    routerLink="/projects"
  >
    <mat-icon>business</mat-icon>
    Projekte
  </a>
  <a
    routerLinkActive="active"
    mat-button
    [routerLink]="['projects', projectId, 'service-specifications']"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <mat-icon>account_tree</mat-icon>
    Leistungsverzeichnisse
  </a>
  <a routerLinkActive="active" mat-button [routerLink]="['projects', projectId, 'project-management']">
    <mat-icon>build</mat-icon>
    Projekteinstellungen
  </a>
  <a routerLinkActive="active" mat-button [routerLink]="['projects', projectId, 'project-files']">
    <mat-icon>attach_file</mat-icon>
    Dateien
  </a>
  <ng-container *ngIf="serviceSpecificationId">
    <mat-divider></mat-divider>
    <ul *ngFor="let item of menuItems" class="top-level-menu">
      <div class="link">
        <a
          routerLinkActive="active"
          mat-button
          (click)="toggleSubMenu(item, true)"
          [routerLink]="['/projects', projectId, 'service-specifications', serviceSpecificationId, item.link]"
          [queryParams]="item.queryParams"
        >
          <mat-icon>{{ item.icon }}</mat-icon>
          {{ item.label }}
        </a>
        <ng-container *ngIf="item?.children?.length">
          <mat-icon class="toggle-icon" *ngIf="!item.isOpen" (click)="toggleSubMenu(item)">add</mat-icon>
          <mat-icon class="toggle-icon" *ngIf="item.isOpen" (click)="toggleSubMenu(item)"> remove </mat-icon>
        </ng-container>
      </div>
      <ul *ngIf="item?.children?.length && item.isOpen">
        <a
          mat-button
          *ngFor="let child of item.children"
          routerLinkActive="active"
          [routerLink]="['/projects', projectId, 'service-specifications', serviceSpecificationId, item.link, child.link]"
          [queryParams]="child.queryParams"
        >
          {{ child.label }}
        </a>
      </ul>
    </ul>
    <mat-divider></mat-divider>
    <a routerLinkActive="active" mat-button routerLink="/master-data" *ngIf="!isQtoOnlyMode">
      <mat-icon>settings</mat-icon>
      Stammdaten
    </a>
    <a routerLinkActive="active" mat-button routerLink="/management" *ngIf="!isQtoOnlyMode">
      <mat-icon>library_books</mat-icon>
      Verwaltung
    </a>
  </ng-container>
</ng-template>
