import { Injectable } from '@angular/core';
import { LoaderOverlay, Viewer } from '@xbim/viewer';

@Injectable({
  providedIn: 'root'
})
export class BimLoaderOverlayService extends LoaderOverlay {
  viever: Viewer;
  overlayLayer: HTMLDivElement | null;
  constructor() {
    super();
  }

  public init(viewer: Viewer): void {
    this.viever = viewer;
  }

  public show(image?: HTMLImageElement) {
    if (this.overlayLayer) return;
    // create an overlay
    const div = document.createElement('div');
    div.style.width = '100%';
    div.style.height = '100%';
    div.style.zIndex = '1000';
    div.style.overflow = 'hidden';
    div.style.position = 'absolute';
    div.style.top = '0';
    div.style.left = '0';

    // semi-transparent layer
    const over = document.createElement('div');
    over.style.height = '100%';
    over.style.width = '100%';
    over.style.backgroundColor = 'rgb(16,16,16)';
    over.style.opacity = '0.5';
    div.appendChild(over);

    if (image != null) {
      const imageContainer = document.createElement('div');
      imageContainer.style.position = 'absolute';
      imageContainer.style.top = '0';
      imageContainer.style.left = '0';
      imageContainer.style.height = '100%';
      imageContainer.style.width = '100%';
      imageContainer.style.display = 'flex';
      imageContainer.style.alignItems = 'center';
      imageContainer.style.justifyContent = 'center';
      div.appendChild(imageContainer);

      // blurred image
      image.style.height = '100%';
      image.style.filter = 'blur(15px)';
      imageContainer.appendChild(image);
    }

    // rotating loader
    const loaderContainer = document.createElement('div');
    loaderContainer.style.position = 'absolute';
    loaderContainer.style.top = '0';
    loaderContainer.style.left = '0';
    loaderContainer.style.height = '100%';
    loaderContainer.style.width = '100%';
    loaderContainer.style.display = 'flex';
    loaderContainer.style.alignItems = 'center';
    loaderContainer.style.justifyContent = 'center';

    const loader = document.createElement('div');
    loader.className = 'loader-wrapper';
    loaderContainer.appendChild(loader);
    div.appendChild(loaderContainer);

    this.overlayLayer = div;

    const parent = this.viever.canvas.parentElement;
    if (parent && parent.style.position !== 'relative' && parent.style.position !== 'absolute') {
      parent.style.position = 'relative';
    }

    const next = this.viever.canvas.nextElementSibling;
    if (parent) {
      if (next != null) {
        parent.insertBefore(div, next);
      } else {
        parent.appendChild(div);
      }
    }
  }

  public hide() {
    if (this.overlayLayer == null) {
      return;
    }

    if (this.overlayLayer.parentElement != null) {
      this.overlayLayer.parentElement.removeChild(this.overlayLayer);
    }

    this.overlayLayer = null;
  }
}
