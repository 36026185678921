import { UserWindowStateGet, UserWindowStatesClient, WindowState } from 'app/generated-client/generated-client';

import { AuthenticationMessenger } from '@dangl/angular-dangl-identity-client';
import { ElectronService } from './electron.service';
import { Injectable } from '@angular/core';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WindowStateService {
  private currentWindowState: UserWindowStateGet[] | null = null;
  private currentUserId: string;

  constructor(
    private electronService: ElectronService,
    private userWindowStatesClient: UserWindowStatesClient,
    private authenticationMessenger: AuthenticationMessenger
  ) {
    if (electronService.isElectron) {
      this.electronService.ipcRenderer.on('WindowStatesUpdate', (_, windowState: { windowName: string; state: WindowState }) => {
        this.saveWindowState(windowState.windowName, windowState.state);
      });
    }
  }

  public applyLastSavedWindowState(): void {
    if (this.electronService.isElectron) {
      this.authenticationMessenger.userInfo.subscribe(ui => {
        this.currentUserId = ui?.id;
      });

      this.authenticationMessenger.isAuthenticated.pipe(take(1)).subscribe(isAuthenticated => {
        if (isAuthenticated) {
          this.userWindowStatesClient.getAllWindowStatesForUser().subscribe(windowStates => {
            this.electronService.ipcRenderer.send('SetWindowFromSavedStates', windowStates);
            this.currentWindowState = windowStates;
          });
        }
      });
    }
  }

  private saveWindowState(windowName: string, windowState: WindowState): void {
    if (this.currentWindowState == null) {
      return;
    }

    const existingWindowState = this.currentWindowState.findIndex(w => w.windowName === windowName);
    if (existingWindowState === -1) {
      this.currentWindowState.push({
        userId: this.currentUserId,
        windowName,
        state: windowState
      });
    } else {
      this.currentWindowState[existingWindowState].state = windowState;
    }

    this.userWindowStatesClient.saveWindowStateForUser(windowName, windowState).subscribe(() => {});
  }
}
