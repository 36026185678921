<div mat-dialog-content>
  <h3>Fehlerbericht #{{ errorReport.id }}</h3>

  <p>Erstellt von {{ errorReport.createdByUserName }} am {{ errorReport.createdAtUtc | date: 'dd.MM.yyyy HH:mm' }}.</p>

  <strong>Beschreibung:</strong>
  <p>{{ errorReport.description }}</p>

  <strong>Adresse:</strong>
  <p>{{ errorReport.applicationUrl }}</p>

  <ng-container *ngIf="imageFileUrl">
    <img class="error-report-image" [src]="imageFileUrl" />
  </ng-container>

  <div>
    <button mat-button mat-raised-button (click)="resolve()" color="primary" *ngIf="!errorReport.isResolved">Als Erledigt Markieren</button>
    <mat-chip-listbox>
      <mat-chip-option *ngIf="errorReport.isResolved" class="resolved-chip">Erledigt</mat-chip-option>
    </mat-chip-listbox>

    <button mat-button mat-raised-button (click)="dismiss()">Abbrechen</button>
  </div>
</div>
