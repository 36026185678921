import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { UserSettings, UserSettingsColumnSetting } from 'app/generated-client/generated-client';

import { UserSettingsService } from './user-settings.service';
import { takeUntil } from 'rxjs/operators';
import { getStorage, setStorage } from '@shared/utilities/storage';

@Injectable({
  providedIn: 'root'
})
export class ShowingColumnsService implements OnDestroy {
  private userSetting: UserSettings;

  private settingsLoadedSource = new ReplaySubject<void>(1);
  settingsLoaded = this.settingsLoadedSource.asObservable();

  $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(private userSettingsService: UserSettingsService) {
    this.userSettingsService.currentUserSettings.pipe(takeUntil(this.$destroy)).subscribe((e: UserSettings) => {
      this.userSetting = e;
      this.settingsLoadedSource.next();
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
    this.settingsLoadedSource.complete();
  }

  getFilteredColumns(component: string, currentColumns: string[]): string[] {
    return currentColumns.filter((cc) => {
      if (!this.userSetting?.hiddenColumns) {
        return cc;
      }

      const columnEntry = this.userSetting.hiddenColumns.find((hc) => hc.component === component && hc.columnName === cc);
      if (!columnEntry) {
        return true;
      }

      return !columnEntry.isHidden;
    });
  }

  getFilteredColumnsObject(component: string, columns: string[]): UserSettingsColumnSetting[] {
    if (columns == null) {
      return [];
    }

    const visibleColumns = columns.map((c) => {
      return {
        isHidden: false,
        columnName: c,
        component: component
      };
    });

    if (!this.userSetting.hiddenColumns) {
      return visibleColumns;
    }

    const savedColumns = this.userSetting.hiddenColumns.filter((hc) => hc.component == component);
    savedColumns.forEach((sc) => {
      const visibleColumn = visibleColumns.find((vc) => vc.component === component && vc.columnName == sc.columnName);
      if (visibleColumn) {
        visibleColumn.isHidden = sc.isHidden;
      }
    });

    return visibleColumns;
  }

  saveChanges(component: string, columns: UserSettingsColumnSetting[]): void {
    const newUserSetting = JSON.parse(JSON.stringify(this.userSetting)) as UserSettings;
    if (newUserSetting.hiddenColumns == null) {
      newUserSetting.hiddenColumns = [];
    }

    columns.forEach((column) => {
      let existingColumnConfig = newUserSetting.hiddenColumns.find(
        (hc) => hc.component === component && hc.columnName === column.columnName
      );
      if (!existingColumnConfig) {
        existingColumnConfig = {
          columnName: column.columnName,
          isHidden: column.isHidden,
          component: component
        };
        newUserSetting.hiddenColumns.push(existingColumnConfig);
      } else {
        existingColumnConfig.isHidden = column.isHidden;
      }
    });

    this.userSettingsService.setCurrentUserSettings(newUserSetting);
  }

  getDefaultDisplayedColumns(nameStorageOrderColumn: string, _defaultDisplayedColumns: string[]): string[] {
    const savedDisplayedColumns: string[] = getStorage<string[]>(nameStorageOrderColumn);
    if (!savedDisplayedColumns?.length) {
      return _defaultDisplayedColumns;
    }
    const add = _defaultDisplayedColumns.filter((nameColumn) => !savedDisplayedColumns.includes(nameColumn));
    return [...savedDisplayedColumns, ...add];
  }

  saveDefaultDisplayedColumns(nameStorageOrderColumn: string, _defaultDisplayedColumns: string[], currentDisplayedColumns: string[]): void {
    const add = _defaultDisplayedColumns.filter((nameColumn) => !currentDisplayedColumns.includes(nameColumn));
    setStorage<string[]>(nameStorageOrderColumn, [...currentDisplayedColumns, ...add]);
  }
}
