import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatRipple } from '@angular/material/core';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTableDataSource,
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';
import { Router, RouterLink } from '@angular/router';

import { AvaNotificationsService } from '../../../../shared/services/ava-notifications.service';
import { LocalProjectsService } from '../../services/local-projects.service';

@Component({
  selector: 'pa-local-projects',
  templateUrl: './local-projects.component.html',
  styleUrls: ['./local-projects.component.scss'],
  standalone: true,
  imports: [
    MatButton,
    RouterLink,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    MatRipple,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    DatePipe
  ]
})
export class LocalProjectsComponent {
  localProjects: MatTableDataSource<{ id: string; name: string; filePath: string; lastOpenedAtUtc: Date }>;
  private isLoading = false;

  constructor(
    private localProjectsService: LocalProjectsService,
    private router: Router,
    private avaNotificationsService: AvaNotificationsService
  ) {
    const localProjects = localProjectsService.getLastUsedProjects();
    this.localProjects = new MatTableDataSource(localProjects);
  }

  selectProject(project: { id: string; name: string; filePath: string; lastOpenedAtUtc: Date }): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    this.localProjectsService.openProject(project.filePath, project.id, project.name).subscribe({
      next: (isSuccess) => {
        this.isLoading = false;
        if (!isSuccess) {
          this.avaNotificationsService.error('Die Projektdatei konnte nicht gefunden werden');
          const localProjects = this.localProjectsService.getLastUsedProjects();
          this.localProjects = new MatTableDataSource(localProjects);
        }
      },
      error: () => {
        this.isLoading = false;
        this.avaNotificationsService.error('Die Projektdatei konnte nicht gefunden werden');
        const localProjects = this.localProjectsService.getLastUsedProjects();
        this.localProjects = new MatTableDataSource(localProjects);
      }
    });
  }

  openProject(): void {
    this.localProjectsService.openLocalProjectFromFile();
  }
}
