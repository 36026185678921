import { NgIf, NgTemplateOutlet, NgClass, NgFor } from '@angular/common';
import { Component, Input, NgZone, OnDestroy, OnInit, QueryList, ViewChildren, inject } from '@angular/core';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { Title } from '@angular/platform-browser';

import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ModalService } from '@shared/services/modal.service';

import { MasterDataProductGet, PriceComponentType } from 'app/generated-client/generated-client';
import { CopyCalculationMethodsType } from 'app/shared/models';
import { CopyCalculationViewMessengerService } from 'app/shared/services/electron/copy-calculation-view-messenger.service';
import { LocalStorageViewService } from 'app/shared/services/local-storage-view.service';

import { getAppConfig } from '../../../../app-config-accessor';
import { GeneralEquipmentComponent } from '../../../../shared/components/general-equipment/general-equipment.component';
import { PriceComponentTypePipe } from '../../../../shared/pipes/ui-data-display/price-component-type.pipe';
import { CatalogsComponent } from '../../../catalogs/components/catalogs/catalogs.component';
import { MasterDataProductsTableComponent } from '../../../master-data/components/master-data-products-table/master-data-products-table.component';

import { OtherProjectTabComponent } from '../other-project-tab/other-project-tab.component';

@Component({
  selector: 'pa-copy-calculation-view',
  templateUrl: './copy-calculation-view.component.html',
  styleUrls: ['./copy-calculation-view.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    GeneralEquipmentComponent,
    NgTemplateOutlet,
    MatTabGroup,
    NgClass,
    MatTab,
    OtherProjectTabComponent,
    NgFor,
    MasterDataProductsTableComponent,
    CatalogsComponent,
    PriceComponentTypePipe
  ]
})
export class CopyCalculationViewComponent implements OnInit, OnDestroy {
  @Input() noSeparate: boolean;
  @ViewChildren('arrPriceTabs') arrPriceTabs: QueryList<MatTab>;

  modalService = inject(ModalService);

  modeEquipment = 'copyCalculation';
  selectedPriceComponentType?: PriceComponentType;
  arrayPriceComponents = [null, ...Object.keys(PriceComponentType).map((key) => PriceComponentType[key]), 'Catalogues'];
  selectedMasterDataProduct: MasterDataProductGet;
  elementGroupId: string;
  private $destroy: Subject<boolean> = new Subject();
  private method: CopyCalculationMethodsType;

  constructor(
    private copyCalculationViewMessengerService: CopyCalculationViewMessengerService,
    private title: Title,
    private localStorageViewService: LocalStorageViewService,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    if (!this.noSeparate) {
      const title = getAppConfig().appPrefix + 'AVA - Übernahme Kalkulation';
      this.title.setTitle(title);

      this.copyCalculationViewMessengerService.listData
        .pipe(takeUntil(this.$destroy))
        .subscribe(({ method }: { method?: CopyCalculationMethodsType }) => {
          this.method = method;
          const [command] = this.method.getCalculation;

          switch (command) {
            case 'elementGroup':
              this.elementGroupId = this.method.getCalculation[1];
              this.copyCalculationViewMessengerService.sendSelectedElementGroupToViewWindow(this.elementGroupId);
              break;
          }
        });
      this.settingView();
      this.ngZone.runOutsideAngular(() => {
        fromEvent(document, 'dragstart')
          .pipe(takeUntil(this.$destroy))
          .subscribe((event: DragEvent) => {
            event.preventDefault();
          });
      });
    } else {
      this.selectedPriceComponentType = this.arrayPriceComponents[0];
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  public selectMasterDataProduct(selectedProduct: MasterDataProductGet): void {
    this.selectedMasterDataProduct = selectedProduct;
  }

  public dblSelectMasterDataProduct(_selectedProduct: MasterDataProductGet): void {
    this.copyMasterDataProduct();
  }

  public copyMasterDataProduct(): void {
    if (this.selectedMasterDataProduct) {
      this.copyCalculationViewMessengerService.sendSelectedArticleOrEquipmentToCopyToMainWindow(this.selectedMasterDataProduct);
    }
  }

  public beginSetting(e: MatTabChangeEvent): void {
    if (this.arrayPriceComponents[e.index] !== 'Catalogues' && this.modalService.getDialog()?.id) {
      this.modalService.getDialog().close();
      this.modalService.setDialogRef(null);
    }
    this.selectedPriceComponentType = null;
    this.arrPriceTabs.forEach((item: MatTab, index: number) => {
      if (item.isActive) {
        this.selectedPriceComponentType = this.arrayPriceComponents[index];
      }
    });

    this.selectedMasterDataProduct = null;
  }

  private settingView(): void {
    const { alwaysOnTop } = this.localStorageViewService.getSettingView(this.modeEquipment);
    this.copyCalculationViewMessengerService.setOnTopCopyCalculation(alwaysOnTop);
  }
}
