import { NgIf, NgClass, DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatRipple } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { PriceInquiriesUploaderComponent } from '@serv-spec/components/price-inquiries-uploader/price-inquiries-uploader.component';
import { PriceInquiryTableService } from '@serv-spec/services/price-inquiry-table.service';

import {
  FileResponse,
  PriceInquiryGet,
  PriceInquiryRequestGet,
  PriceInquiryRequestsClient,
  PriceInquiryStatus
} from 'app/generated-client/generated-client';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

import { PriceInquiryStatusPipe } from '../../../../../../shared/pipes/ui-data-display/price-inquiry-status.pipe';
import { AvaNotificationsService } from '../../../../../../shared/services/ava-notifications.service';
import { FileSaverService } from '../../../../../../shared/services/file-saver.service';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

import { CreatePriceInquiryRequestsComponent } from '../create-price-inquiry-requests/create-price-inquiry-requests.component';
import { PriceInquiriesInputFileComponent } from '../price-inquiries-input-file/price-inquiries-input-file.component';
import { PriceInquiriesInputManuallyComponent } from '../price-inquiries-input-manually/price-inquiries-input-manually.component';
import { SendPriceInquiryComponent } from '../send-price-inquiry/send-price-inquiry.component';

@Component({
  selector: 'pa-price-inquiries-request-table',
  templateUrl: './price-inquiries-request-table.component.html',
  styleUrls: ['./price-inquiries-request-table.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutDirective,
    NgIf,
    MatIcon,
    MatIconButton,
    MatButton,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatRipple,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    NgClass,
    PriceInquiriesInputFileComponent,
    PriceInquiriesInputManuallyComponent,
    DatePipe,
    PriceInquiryStatusPipe
  ]
})
export class PriceInquiriesRequestTableComponent implements OnInit, OnDestroy {
  @Input() set selectedPriceInquiry(value: PriceInquiryGet) {
    this._selectedPriceInquiry = value;
    if (this.projectId && this.avaProjectId) {
      this.getData();
    }
  }

  get selectedPriceInquiry(): PriceInquiryGet {
    return this._selectedPriceInquiry;
  }

  private _selectedPriceInquiry: PriceInquiryGet;
  projectId: string;
  avaProjectId: string;
  dataSource: PriceInquiryRequestGet[];
  displayedColumns = ['index', 'name', 'createdAtUtc', 'status', 'submittedAtUtc', 'comments', 'action'];
  selectedRequest: PriceInquiryRequestGet;
  fun: number;
  private $destroy = new Subject<boolean>();
  priceInquiryStatus = PriceInquiryStatus;
  isDownloading = false;

  constructor(
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private priceInquiryRequestsClient: PriceInquiryRequestsClient,
    private avaNotificationsService: AvaNotificationsService,
    private fileSaverService: FileSaverService,
    private matDialog: MatDialog,
    private priceInquiryTableService: PriceInquiryTableService
  ) {}

  ngOnInit(): void {
    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        takeUntil(this.$destroy),
        filter((r) => !!r)
      )
      .subscribe((e: { avaProjectId: string; parentProjectId: string }) => {
        this.projectId = e?.parentProjectId;
        this.avaProjectId = e?.avaProjectId;
        if (this.selectedPriceInquiry) {
          this.getData();
        }
      });

    this.priceInquiryTableService.refreshPriceInquiryTable.pipe(takeUntil(this.$destroy)).subscribe(() => this.getData());
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  getData(fn?: () => void): void {
    this.priceInquiryRequestsClient
      .getAllPriceInquiryRequestsForPriceInquiry(this.projectId, this.avaProjectId, this.selectedPriceInquiry?.id)
      .subscribe((e: PriceInquiryRequestGet[]) => {
        this.dataSource = e;
        if (fn) {
          fn();
        }
      });
  }

  sendPriceInquiry(): void {
    const dataIds = {
      requestId: this.selectedRequest.id,
      projectId: this.projectId,
      avaProjectId: this.avaProjectId,
      priceInquiryId: this.selectedPriceInquiry?.id,
      priceInquiryName: this.selectedPriceInquiry?.name
    };
    this.matDialog
      .open(SendPriceInquiryComponent, { autoFocus: false, height: '402px', data: dataIds })
      .afterClosed()
      .subscribe(() => {
        this.getData((): void => {
          this.selectedRequest = this.dataSource.find((item) => item.id === this.selectedRequest.id);
        });
      });
  }

  createPackage(): void {
    this.isDownloading = true;
    this.priceInquiryRequestsClient
      .createOfferRequestPackageArchive(this.projectId, this.avaProjectId, this.selectedPriceInquiry.id)
      .subscribe({
        next: (r: FileResponse) => {
          this.isDownloading = false;
          this.fileSaverService.saveAs(r);
        },
        error: () => {
          this.isDownloading = false;
          this.avaNotificationsService.error('Fehler beim Erstellen der Datei');
        }
      });
  }

  createPriceInquiryRequests(): void {
    const dataIds = {
      projectId: this.projectId,
      avaProjectId: this.avaProjectId,
      priceInquiryId: this.selectedPriceInquiry?.id
    };
    this.matDialog
      .open(CreatePriceInquiryRequestsComponent, { autoFocus: false, height: '402px', data: { requestIds: dataIds } })
      .afterClosed()
      .subscribe(() => {
        this.getData();
      });
  }

  createPriceInquiryRequestsViaDragAndDrop(): void {
    const dataIds = {
      projectId: this.projectId,
      avaProjectId: this.avaProjectId,
      priceInquiryId: this.selectedPriceInquiry?.id
    };
    this.matDialog
      .open(PriceInquiriesUploaderComponent, { autoFocus: false, data: { requestIds: dataIds }, disableClose: true })
      .afterClosed()
      .subscribe(() => {
        this.getData();
      });
  }

  refresh(): void {
    this.selectedRequest = null;
    this.getData();
  }

  deletePriceInquiry(event: MouseEvent, row: PriceInquiryRequestGet): void {
    event.stopPropagation();

    this.priceInquiryRequestsClient.deletePriceInquiry(this.projectId, this.avaProjectId, row.priceInquiryId, row.id).subscribe({
      next: () => {
        this.avaNotificationsService.success('Anfrage erfolgreich gelöscht');
        this.getData();
      },
      error: () => {
        this.avaNotificationsService.error('Fehler beim Löschen der Anfrage');
      }
    });
  }
}
