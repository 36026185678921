<h1>Mengenprüfung</h1>
<p>Folgende Positionen haben eine Nullmenge</p>

<div class="wrapper">
  <div class="spinner" *ngIf="!isLoading; else content">
    <div fxFlex fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <ng-template #content>
    <ng-container>
      <mat-selection-list [multiple]="false" class="list">
        <mat-list-option color="primary" *ngFor="let position of positionListWithoutQuantity">
          {{ position.itemNumber.stringRepresentation }} - {{ position.shortText }}
        </mat-list-option>
      </mat-selection-list>
      <button mat-raised-button color="primary" (click)="close(true)" class="mt-10">Ignorieren</button>
      <button mat-raised-button color="warn" (click)="close(false)" class="mt-10">Abbrechen</button>
    </ng-container>
  </ng-template>
</div>
