import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ElementHasWithoutTotalGroupService {
  private hasWithoutTotalGroupService = new ReplaySubject<boolean>(1);
  hasWithoutTotalGroup = this.hasWithoutTotalGroupService.asObservable();

  setValue(value: boolean): void {
    this.hasWithoutTotalGroupService.next(value);
  }
}
