import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TinyMceComponent } from '@dangl/angular-material-shared/tiny-mce';

import { PercentageInputFieldComponent } from '@shared/components/percentage-input-field/percentage-input-field.component';

import { SharedModule } from 'app/shared/shared.module';

import { ArticlesComponent } from './components/articles/articles.component';
import { CasingComponent } from './components/casing/casing.component';
import { EquipmentsComponent } from './components/equipments/equipments.component';
import { MasterAdditionGroupDetailComponent } from './components/master-addition-group-detail/master-addition-group-detail.component';
import { MasterAdditionGroupComponent } from './components/master-addition-group/master-addition-group.component';
import { MasterAdditionGroupsTableComponent } from './components/master-addition-groups-table/master-addition-groups-table.component';
import { MasterAdditionNewGroupComponent } from './components/master-addition-new-group/master-addition-new-group.component';
import { MasterDataProductParentComponent } from './components/master-data-product-parent/master-data-product-parent.component';
import { MasterDataProductComponent } from './components/master-data-product/master-data-product.component';
import { MasterDataProductsTableComponent } from './components/master-data-products-table/master-data-products-table.component';
import { MasterDataProductsComponent } from './components/master-data-products/master-data-products.component';
import { MasterDataTaxRatesAddModalComponent } from './components/master-data-tax-rates-add-modal/master-data-tax-rates-add-modal.component';
import { MasterDataTaxRatesTableComponent } from './components/master-data-tax-rates-table/master-data-tax-rates-table.component';
import { MasterDataTaxRatesComponent } from './components/master-data-tax-rates/master-data-tax-rates.component';
import { MasterDataComponent } from './components/master-data/master-data.component';
import { MasterReportsComponent } from './components/master-reports/master-reports.component';
import { MasterTextTemplatesAddModalComponent } from './components/master-text-templates-add-modal/master-text-templates-add-modal.component';
import { MasterTextTemplatesTableComponent } from './components/master-text-templates-table/master-text-templates-table.component';
import { MasterTextTemplatesComponent } from './components/master-text-templates/master-text-templates.component';
import { MiscellaneousComponent } from './components/miscellaneous/miscellaneous.component';
import { ModalMasterItemsNewComponent } from './components/modal-master-items-articles-new/modal-master-items-new.component';
import { SubContractorServicesComponent } from './components/sub-contractor-services/sub-contractor-services.component';
import { MasterDataRoutingModule } from './master-data-routing.module';

@NgModule({
  imports: [
    CommonModule,
    MasterDataRoutingModule,
    SharedModule,
    MasterDataProductsTableComponent,
    PercentageInputFieldComponent,
    MasterAdditionGroupComponent,
    MasterAdditionNewGroupComponent,
    MasterDataComponent,
    ArticlesComponent,
    ModalMasterItemsNewComponent,
    MasterDataProductComponent,
    EquipmentsComponent,
    CasingComponent,
    MasterDataProductParentComponent,
    MasterDataProductsComponent,
    MasterReportsComponent,
    MasterAdditionGroupsTableComponent,
    MasterAdditionGroupDetailComponent,
    MasterTextTemplatesComponent,
    MasterTextTemplatesTableComponent,
    MasterTextTemplatesAddModalComponent,
    MiscellaneousComponent,
    SubContractorServicesComponent,
    MasterDataTaxRatesComponent,
    MasterDataTaxRatesTableComponent,
    MasterDataTaxRatesAddModalComponent,
    TinyMceComponent
  ],
  exports: [MasterDataProductsTableComponent, MasterTextTemplatesTableComponent]
})
export class MasterDataModule {}
