import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatInput } from '@angular/material/input';

import { Subject, takeUntil } from 'rxjs';

import { ChangeFormulaFactorService } from '@serv-spec/services/change-formula-factor.service';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';
import { CalculationEntry } from 'app/generated-client/generated-client';

@Component({
  selector: 'pa-calculation-row-line',
  templateUrl: './calculation-row-line.component.html',
  styleUrls: ['./calculation-row-line.component.scss'],
  standalone: true,
  imports: [MatInput, FlexLayoutDirective]
})
export class CalculationRowLineComponent implements OnInit, OnDestroy {
  @ViewChild('formula') formula: ElementRef;

  private $destroy: Subject<boolean> = new Subject<boolean>();

  currentRow: CalculationEntry;

  constructor(private changeFormulaFactorService: ChangeFormulaFactorService) {}

  ngOnInit(): void {
    this.changeFormulaFactorService.rowForChangeFormulaFactor.pipe(takeUntil(this.$destroy)).subscribe((rowForChangeFormulaFactor) => {
      this.currentRow = rowForChangeFormulaFactor;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  changeValue(): void {
    this.changeFormulaFactorService.setChangedValueOfFormulaFactor(this.formula.nativeElement.value);
  }
}
