import { AvaProjectGet } from 'app/generated-client/generated-client';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationMainService } from './main/pagination-main.service';
import { SelectedProjectMessengerService } from '../messengers/selected-project-messenger.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AvaProjectsService extends PaginationMainService<AvaProjectGet> {
  constructor(protected http: HttpClient, private selectedProjectMessengerService: SelectedProjectMessengerService) {
    super(http);
    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe(p => {
      if (p?.id) {
        this.beginBaseUrl(`/api/projects/${p?.id}/ava-projects`);
      } else {
        this.beginBaseUrl(null);
      }
    });
  }
}
