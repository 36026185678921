import { Pipe, PipeTransform } from '@angular/core';

import { AvaFileExportPhase } from '../../../generated-client/generated-client';

@Pipe({
  name: 'avaFileExportPhase',
  standalone: true
})
export class AvaFileExportPhasePipe implements PipeTransform {
  transform(value: AvaFileExportPhase): string {
    switch (value) {
      case AvaFileExportPhase.Base:
        return 'Basis (81)';

      case AvaFileExportPhase.None:
        return 'Keine';

      case AvaFileExportPhase.Grant:
        return 'Auftrag (86)';

      case AvaFileExportPhase.Offer:
        return 'Angebot (84)';

      case AvaFileExportPhase.OfferRequest:
        return 'Angebotsaufforderung (83)';

      case AvaFileExportPhase.SideOffer:
        return 'Nebenangebot (85)';
    }

    return null;
  }
}
