import { Component, Inject, OnInit, Self, OnDestroy, Optional } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';

import { delay, Subject, take } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TreeNodeSelectingService } from 'app/areas/tree/services/tree-node-selecting.service';
import { TreeNodeStateService } from 'app/areas/tree/services/tree-node-state.service';
import { ElementSelection, SelectedElement, TreeViewDisplayType } from 'app/generated-client/generated-client';
import { GroupViewService } from 'app/shared/services/group-view.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';

import { MainTreeComponent } from '../../../../../tree/components/main-tree/main-tree.component';

@Component({
  selector: 'pa-selecting-element-modal',
  templateUrl: './selecting-element-modal.component.html',
  styleUrls: ['./selecting-element-modal.component.scss'],
  providers: [TreeNodeStateService, SelectedSpecificationElementMessengerService, GroupViewService],
  standalone: true,
  imports: [MatDialogContent, MatButton, MatIcon, MainTreeComponent]
})
export class SelectingElementModalComponent implements OnInit, OnDestroy {
  private $destroy: Subject<boolean> = new Subject<boolean>();

  selectionList: SelectedElement[] = [];
  selectedElements: SelectedElement[];

  constructor(
    @Optional() private matDialogRef: MatDialogRef<SelectingElementModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ElementSelection,
    @Self() private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    @Self() private treeNodeStateService: TreeNodeStateService,
    @Self() private groupViewService: GroupViewService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private treeNodeSelectingService: TreeNodeSelectingService
  ) {}

  ngOnInit(): void {
    //TODO Consider another solution, so that the tree when opening a modal window is always displayed in a tree structure
    //Since there is a delay added here, because we are also getting the value for ProjectSettings
    this.selectedProjectMessengerService.selectedProject.pipe(take(1), delay(100)).subscribe(() => {
      this.groupViewService.setGroupView(TreeViewDisplayType.Tree);
    });

    this.selectedSpecificationElementMessengerService.clearSelectedElement();
    this.treeNodeStateService.resetTreeState();
    if (this.data) {
      this.selectedElements = this.data.selectedElements ? JSON.parse(JSON.stringify(this.data.selectedElements)) : [];
    }

    this.treeNodeSelectingService.selectingChanged.pipe(takeUntil(this.$destroy)).subscribe((selectingChanged) => {
      this.getSelecting(selectingChanged);
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  getSelecting(value: SelectedElement[]): void {
    this.selectionList = value;
  }

  saveSelection(): void {
    this.selectionList = this.selectionList?.length ? this.selectionList : null;
    this.matDialogRef.close({ selectedElements: this.selectionList });
  }

  cancelSelection(): void {
    this.matDialogRef.close();
  }
}
