import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { AddressGet } from '../../../generated-client/generated-client';

@Injectable({
  providedIn: 'root'
})
export class SelectedAddressMessengerService {
  private selectedAddressSource = new ReplaySubject<AddressGet>(1);
  selectedAddress = this.selectedAddressSource.asObservable();

  setSelectedAddress(selectedAddress: AddressGet): void {
    this.selectedAddressSource.next(selectedAddress);
  }
}
