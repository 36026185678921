import { DatePipe, NgIf, AsyncPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatRipple } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTableDataSource,
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { InvoiceTableMenuComponent } from '@serv-spec/components/invoice/components/invoice-table-menu/invoice-table-menu.component';

import {
  AvaProjectGet,
  AvaProjectsClient,
  ProjectGet,
  QuantityTakeOffBillDatePut,
  QuantityTakeOffCalculationType,
  QuantityTakeOffGet,
  QuantityTakeOffType,
  QuantityTakeOffsClient
} from 'app/generated-client/generated-client';
import { ChangeNameModalComponent } from 'app/shared/components/change-name-modal/change-name-modal.component';
import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { InvoiceMenuAction, InvoiceMenuActions } from 'app/shared/models/invoice-menu-actions';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { ContextMenuSettingsService } from 'app/shared/services/context-menu-settings.service';
import { QuantityTakeOffsService } from 'app/shared/services/lightquery/quantity-take-offs.service';
import { ProjectQuantityEstimationService } from 'app/shared/services/messengers/project-quantity-estimation.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';

import { UpperPaginatorComponent } from '../../../../../../../../shared/components/upper-paginator/upper-paginator.component';
import { ProjectCurrencyPipe } from '../../../../../../../../shared/pipes/ui-data-display/project-currency.pipe';
import { QuantityTakeOffCalculationTypePipe } from '../../../../../../../../shared/pipes/ui-data-display/quantity-take-off-calculation-type.pipe';
import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';

import { InvoiceBulkQtoExportDialogComponent } from '../invoice-bulk-qto-export-dialog/invoice-bulk-qto-export-dialog.component';
import { InvoiceModalAddBillComponent } from '../invoice-modal-add-bill/invoice-modal-add-bill.component';
import { InvoiceQtoExportDialogComponent } from '../invoice-qto-export-dialog/invoice-qto-export-dialog.component';
import { InvoiceTableMenuComponent as InvoiceTableMenuComponent_1 } from '../invoice-table-menu/invoice-table-menu.component';
import { NewInvoiceComponent } from '../new-invoice/new-invoice.component';

@Component({
  selector: 'pa-invoices-table',
  templateUrl: './invoices-table.component.html',
  styleUrls: ['./invoices-table.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    RouterLink,
    MatTooltip,
    MatRipple,
    MatIcon,
    MatIconButton,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    UpperPaginatorComponent,
    FlexLayoutDirective,
    MatProgressSpinner,
    InvoiceTableMenuComponent_1,
    AsyncPipe,
    DatePipe,
    QuantityTakeOffCalculationTypePipe,
    ProjectCurrencyPipe
  ]
})
export class InvoicesTableComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) private sort: MatSort;
  @Input() structureView: string;
  @Input() typeQTO: QuantityTakeOffType;
  @ViewChild(InvoiceTableMenuComponent) treeMenuComponent: InvoiceTableMenuComponent;
  dataSource = new MatTableDataSource();
  columnsToDisplay: string[];
  projectId: string;
  allTotalSum: number;
  private avaProjectId: string;
  private $destroy: Subject<boolean> = new Subject<boolean>();
  hasAnyQtoCalculationReferences = false;
  hasAnyQtoAssumedQuantities = false;
  isLoading = false;
  contextMenuPosition = { x: '0px', y: '0px' };
  private avaProject: AvaProjectGet;

  constructor(
    public quantityTakeOffsService: QuantityTakeOffsService,
    private modalService: ModalService,
    private pipeDate: DatePipe,
    private quantityTakeOffsClient: QuantityTakeOffsClient,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private avaNotificationsService: AvaNotificationsService,
    private avaProjectsClient: AvaProjectsClient,
    private projectQuantityEstimationService: ProjectQuantityEstimationService,
    private contextMenuSettingsService: ContextMenuSettingsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.columnsToDisplay = [
      'name',
      'number',
      'calculationType',
      'totalSum',
      'isProjectQuantityCalculation',
      'useAsAssumedQuantities',
      'modifiedAtUtc',
      'billed',
      'actions'
    ];
    if (this.structureView !== 'invoices') {
      const delColumn = ['number', 'totalSum', 'billed'];
      this.columnsToDisplay = this.columnsToDisplay.filter((item: string) => !delColumn.includes(item));
    } else if (this.structureView === 'invoices') {
      const delColumn = ['isProjectQuantityCalculation', 'useAsAssumedQuantities'];
      this.columnsToDisplay = this.columnsToDisplay.filter((item: string) => !delColumn.includes(item));
    }

    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((project: ProjectGet) => {
      this.projectId = project?.id;
      if (this.avaProjectId) {
        this.checkIfAnyQtoHasCalculationReference();
      }
    });

    this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(takeUntil(this.$destroy)).subscribe((p) => {
      this.avaProjectId = p?.avaProjectId;
      this.avaProject = p?.avaProject;
      if (this.projectId) {
        this.checkIfAnyQtoHasCalculationReference();
      }
    });

    this.quantityTakeOffsService.initService({
      paging: { page: 1, pageSize: 10 },
      sorting: {
        propertyName: this.structureView === 'invoices' ? 'number' : 'name',
        isDescending: true
      },
      sortTableObj: this.sort
    });
    this.quantityTakeOffsService.setQueryParameter('quantityTakeOffType', this.typeQTO);

    this.quantityTakeOffsService.paginationResult
      .pipe(
        takeUntil(this.$destroy),
        filter((v) => v?.data?.length === 0 || !!v.data.find((val) => val.quantityTakeOffType === this.typeQTO))
      )
      .subscribe((r) => {
        this.dataSource.data = r.data;
        if (r.data.length) {
          this.allTotalSum = r.data[0].calculation?.allTotalSum;
        } else {
          this.allTotalSum = 0;
        }
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
    this.quantityTakeOffsService.setQueryParameter('quantityTakeOffType', null);
  }

  private checkIfAnyQtoHasCalculationReference = () => {
    // The quantityTakeOffsService might not be initialized at the beginning, so we need to ensure
    // that it is initialized before we can get all the QTOs, because the service needs to
    // have set internally it's url
    if (
      this.quantityTakeOffsService.baseUrl &&
      this.quantityTakeOffsService.baseUrl.indexOf(this.projectId) > -1 &&
      this.quantityTakeOffsService.baseUrl.indexOf(this.avaProjectId) > -1
    ) {
      setTimeout(() => {
        this.quantityTakeOffsService
          .getAll({
            quantityTakeOffType: this.typeQTO
          })
          .subscribe((allQtos) => {
            this.hasAnyQtoCalculationReferences = allQtos.some((qto) => qto.isProjectQuantityCalculation);
            this.hasAnyQtoAssumedQuantities = allQtos.some((qto) => qto.useAsAssumedQuantities);
          });
      }, 500);
    }
  };

  exportInvoice(qto: QuantityTakeOffGet): void {
    this.modalService.openModal(InvoiceQtoExportDialogComponent, {
      dialogType: ConfirmationType.General,
      data: {
        avaProjectId: this.avaProjectId,
        qto: qto,
        projectId: this.projectId
      }
    });
  }

  selectBill(event: MouseEvent, row: QuantityTakeOffGet): void {
    event.stopPropagation();
    if (row.markedAsBilledAtUtc) {
      this.modalService
        .openModal(ModalConfirmComponent, {
          dialogType: ConfirmationType.Delete,
          data: ['Löschen', 'Abrechnungsdatum', `Abgerechnet: ${this.pipeDate.transform(row.markedAsBilledAtUtc, 'dd.MM.yyyy')}`]
        })
        .afterClosed()
        .subscribe((isConfirm: boolean) => {
          if (isConfirm) {
            this.quantityTakeOffsClient
              .unmarkQuantityTakeOffAsBilled(this.projectId, row.avaProjectId, row.id)
              .subscribe(() => this.quantityTakeOffsService.forceRefresh());
          }
        });
    } else {
      this.modalService
        .openModal(InvoiceModalAddBillComponent, { dialogType: ConfirmationType.General })
        .afterClosed()
        .subscribe((model: QuantityTakeOffBillDatePut) => {
          if (model) {
            this.quantityTakeOffsClient
              .markQuantityTakeOffAsBilled(this.projectId, row.avaProjectId, row.id, model)
              .subscribe(() => this.quantityTakeOffsService.forceRefresh());
          }
        });
    }
  }

  deleteQTO(row: QuantityTakeOffGet): void {
    const nameOfDocument =
      row.quantityTakeOffType === 'Invoice' ? 'Abrechnung' : row.quantityTakeOffType === 'QuantityEstimation' ? 'Mengenermittlung' : '';
    this.modalService
      .openModal(ModalConfirmComponent, {
        dialogType: ConfirmationType.Delete,
        data: ['Löschen', nameOfDocument, `${nameOfDocument}: ${row.name}`, 'red']
      })
      .afterClosed()
      .subscribe((isConfirm: boolean) => {
        if (isConfirm) {
          this.quantityTakeOffsClient.deleteQuantityTakeOff(this.projectId, this.avaProjectId, row.id).subscribe(
            () => {
              this.avaNotificationsService.success(`Die ${nameOfDocument} wurde gelöscht`);
              this.quantityTakeOffsService.forceRefresh();
            },
            () => this.avaNotificationsService.error(`Fehler beim Löschen der ${nameOfDocument}`)
          );
        }
      });
  }

  setProjectQuantityReferenceConfirm(qto: QuantityTakeOffGet): void {
    this.modalService
      .openModal(ModalConfirmComponent, {
        dialogType: ConfirmationType.General,
        data: [
          'Mengenverknüpfung',
          'Mengenverknüpfung erstellen',
          'Die Projektmengen werden mit der gewählten Mengenermittlung verknüpft. Dabei werden alle Projektmengen überschrieben.'
        ]
      })
      .afterClosed()
      .subscribe((e) => {
        if (e) {
          this.setProjectQuantityReference(qto);
        }
      });
  }

  private setProjectAssumedQuantities(qto: QuantityTakeOffGet): void {
    this.isLoading = true;
    this.avaProjectsClient
      .setQuantityCalculationAsAssumedQuantitiesForAvaProject(this.projectId, qto.avaProjectId, {
        quantityTakeOffId: qto.id
      })
      .subscribe({
        next: () => {
          this.quantityTakeOffsService.forceRefresh();
          this.checkIfAnyQtoHasCalculationReference();
          this.avaNotificationsService.success('Diese Mengenermittlung wird jetzt für die VA-Mengen verwendet.');
          if (this.avaProject && this.avaProject.id == qto.avaProjectId) {
            this.avaProject.assumedQuantitiesQuantityTakeOffId = qto.id;
          }
          this.projectQuantityEstimationService.setHasAssumedQuantitiesForCurrentProject(true);
        },
        error: () => {
          this.avaNotificationsService.error('Fehler beim Speichern.');
        },
        complete: () => {
          this.isLoading = false;
        }
      });
  }

  private setProjectQuantityReference(qto: QuantityTakeOffGet): void {
    this.isLoading = true;
    this.avaProjectsClient
      .setQuantityCalculationReferenceForAvaProject(this.projectId, qto.avaProjectId, {
        quantityTakeOffId: qto.id
      })
      .subscribe({
        next: () => {
          this.quantityTakeOffsService.forceRefresh();
          this.checkIfAnyQtoHasCalculationReference();
          this.avaNotificationsService.success('Diese Mengenermittlung wird jetzt für die Projektmengen verwendet.');
          this.projectQuantityEstimationService.setHasQuantityCalculationForCurrentProject(true, qto.id);
        },
        error: () => {
          this.avaNotificationsService.error('Fehler beim Speichern.');
        },
        complete: () => {
          this.isLoading = false;
        }
      });
  }

  deleteProjectAssumedQuantities(qto: QuantityTakeOffGet): void {
    this.isLoading = true;
    this.avaProjectsClient.removeQuantityCalculationAsAssumedForAvaProject(this.projectId, qto.avaProjectId).subscribe({
      next: () => {
        this.quantityTakeOffsService.forceRefresh();
        this.checkIfAnyQtoHasCalculationReference();
        this.avaNotificationsService.success('Diese Mengenermittlung wird nicht mehr für die VA-Mengen verwendet.');
        if (this.avaProject && this.avaProject.id == qto.avaProjectId) {
          this.avaProject.assumedQuantitiesQuantityTakeOffId = null;
        }
        this.projectQuantityEstimationService.setHasAssumedQuantitiesForCurrentProject(false);
      },
      error: () => {
        this.avaNotificationsService.error('Fehler beim Löschen.');
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  deleteProjectQuantityReference(qto: QuantityTakeOffGet): void {
    this.isLoading = true;
    this.avaProjectsClient.removeQuantityCalculationReferenceForAvaProject(this.projectId, qto.avaProjectId).subscribe({
      next: () => {
        this.quantityTakeOffsService.forceRefresh();
        this.checkIfAnyQtoHasCalculationReference();
        this.avaNotificationsService.success('Diese Mengenermittlung wird nicht mehr für die Projektmengen verwendet.');
        this.projectQuantityEstimationService.setHasQuantityCalculationForCurrentProject(false);
      },
      error: () => {
        this.avaNotificationsService.error('Fehler beim Löschen.');
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  changeNameQTO(row: QuantityTakeOffGet): void {
    this.modalService
      .openModal(ChangeNameModalComponent, { dialogType: ConfirmationType.General, data: { name: row.name } })
      .afterClosed()
      .subscribe((newName: string) => {
        if (newName) {
          this.quantityTakeOffsClient
            .setNameForQuantityTakeOff(this.projectId, this.avaProjectId, row.id, {
              name: newName
            })
            .subscribe({
              error: () => {
                this.avaNotificationsService.error('Fehler beim Speichern des Namens');
              },
              next: (newQto) => {
                row.name = newQto.name;
              }
            });
        }
      });
  }

  navigateToApplyQuantities(qto: QuantityTakeOffGet): void {
    if (qto.calculationType === QuantityTakeOffCalculationType.ByPosition) {
      this.router.navigate([qto.id, 'positions', 'quantity'], {
        relativeTo: this.route
      });
    } else {
      this.router.navigate([qto.id, 'pages', 'quantity'], {
        relativeTo: this.route
      });
    }
  }

  showContextMenu(event: MouseEvent, item: QuantityTakeOffGet): void {
    this.contextMenuSettingsService.setDefaultSettings(event, item, this.contextMenuPosition, this.treeMenuComponent.menu);
  }

  handlerMenuAction(e: InvoiceMenuAction): void {
    switch (e.action) {
      case InvoiceMenuActions.EditInvoiceName:
        this.changeNameQTO(e.item);
        break;
      case InvoiceMenuActions.RemoveInvoice:
        this.deleteQTO(e.item);
        break;
      case InvoiceMenuActions.AddProjectQuantityReference:
        this.setProjectQuantityReferenceConfirm(e.item);
        break;
      case InvoiceMenuActions.AddProjectQuantityReferenceAsAssumed:
        this.setProjectAssumedQuantities(e.item);
        break;
      case InvoiceMenuActions.DeleteProjectQuantityReference:
        this.deleteProjectQuantityReference(e.item);
        break;
      case InvoiceMenuActions.DeleteProjectQuantityReferenceAsAssumed:
        this.deleteProjectAssumedQuantities(e.item);
        break;
      case InvoiceMenuActions.PrintInvoice:
        this.exportInvoice(e.item);
        break;
      case InvoiceMenuActions.ApplyQuantities:
        this.navigateToApplyQuantities(e.item);
        break;
      case InvoiceMenuActions.CopyInvoice:
        this.copyQTO(e.item);
        break;
      case InvoiceMenuActions.BulkExportQuantities:
        this.bulkExportQuantities(e.item);
        break;
      default:
        break;
    }
  }

  copyQTO(fromQto: QuantityTakeOffGet): void {
    this.modalService
      .openModal(NewInvoiceComponent, { dialogType: ConfirmationType.General, data: fromQto })
      .afterClosed()
      .subscribe((qto: QuantityTakeOffGet) => {
        if (qto) {
          this.quantityTakeOffsService.forceRefresh();
          this.router.navigate([qto.id, qto.calculationType === QuantityTakeOffCalculationType.ByPage ? 'pages' : 'positions'], {
            relativeTo: this.route
          });
        }
      });
  }

  bulkExportQuantities(qto: QuantityTakeOffGet): void {
    this.modalService.openModal(InvoiceBulkQtoExportDialogComponent, {
      dialogType: ConfirmationType.General,
      data: {
        avaProjectId: this.avaProjectId,
        qto: qto,
        projectId: this.projectId
      }
    });
  }
}
