import { NgClass, NgIf, AsyncPipe, DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatCellDef,
  MatHeaderCell,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NewInvoicePageComponent } from '@serv-spec/components/invoice/components/new-invoice-page/new-invoice-page.component';
import { KeyupControlService } from '@serv-spec/services/keyup-control.service';

import { PageQtoMenuAction } from '@shared/models';
import { InvoiceMenuActions } from '@shared/models/invoice-menu-actions';
import { ContextMenuSettingsService } from '@shared/services/context-menu-settings.service';

import {
  BuildingElementCodeGet,
  PageQuantityTakeOffGet,
  PageQuantityTakeOffsClient,
  ProjectDto,
  ProjectGet,
  QuantityTakeOffType,
  QuantityTakeOffsClient,
  ServicePeriodCodesClient
} from 'app/generated-client/generated-client';
import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { PageQuantityTakeOffsService } from 'app/shared/services/lightquery/page-quantity-take-offs.service';
import { PagesAllAvaProjectService } from 'app/shared/services/lightquery/pages-all-ava-project.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';

import { TableWrapperComponent } from '../../../../../../../../shared/components/table-wrapper/table-wrapper.component';
import { UpperPaginatorComponent } from '../../../../../../../../shared/components/upper-paginator/upper-paginator.component';
import { ProjectCurrencyPipe } from '../../../../../../../../shared/pipes/ui-data-display/project-currency.pipe';
import { UpdateInvoicePageService } from '../../services/update-invoice-page.service';

import { CopyInvoicePageComponent } from '../copy-invoice-page/copy-invoice-page.component';
import { InvoiceTableMenuComponent } from '../invoice-table-menu/invoice-table-menu.component';

@Component({
  selector: 'pa-invoices-pages',
  templateUrl: './invoices-pages.component.html',
  styleUrls: ['./invoices-pages.component.scss'],
  standalone: true,
  imports: [
    TableWrapperComponent,
    MatTable,
    MatSort,
    NgClass,
    MatColumnDef,
    MatHeaderCellDef,
    MatCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCell,
    NgIf,
    MatInput,
    FormsModule,
    MatTooltip,
    MatIconButton,
    MatIcon,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    UpperPaginatorComponent,
    InvoiceTableMenuComponent,
    AsyncPipe,
    DatePipe,
    ProjectCurrencyPipe
  ]
})
export class InvoicesPagesComponent implements OnInit, OnDestroy {
  @Input() typeQTO: QuantityTakeOffType;
  @ViewChild(MatSort, { static: true }) private sort: MatSort;
  @ViewChild(InvoiceTableMenuComponent) contextMenuComponent: InvoiceTableMenuComponent;
  columnsToDisplay: string[] = [
    'index',
    'quantityTakeOff.name',
    'quantityTakeOff.number',
    'isChecked',
    'pageNumber',
    'name',
    'buildingElementCode',
    'servicePeriodCode',
    'invoicePeriodCode',
    'invoiceRecipientCode',
    'totalSum',
    'billed',
    'subContractor',
    'actions'
  ];
  editableColumns = [
    'quantityTakeOff.name',
    'quantityTakeOff.number',
    'servicePeriodCode',
    'buildingElementCode',
    'pageNumber',
    'name',
    'index',
    'invoiceRecipientCode',
    'invoicePeriodCode'
  ];
  private _buildingElementCode: BuildingElementCodeGet;
  QuantityTakeOffType = QuantityTakeOffType;
  @Input() set buildingElementCode(value: BuildingElementCodeGet) {
    this._buildingElementCode = value;
    this.pagesAllAvaProjectService.setQueryParameter('buildingElementCodeId', value?.id);
  }
  private $destroy: Subject<boolean> = new Subject<boolean>();
  structureView: string;
  projectId: string;
  avaProjectId: string;
  contextMenuPosition = { x: '0px', y: '0px' };
  disabledObject: { [key: string]: boolean } = {};

  constructor(
    public pagesAllAvaProjectService: PagesAllAvaProjectService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private pageQuantityTakeOffsClient: PageQuantityTakeOffsClient,
    private quantityTakeOffsClient: QuantityTakeOffsClient,
    private avaNotificationsService: AvaNotificationsService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private pageQuantityTakeOffsService: PageQuantityTakeOffsService,
    private contextMenuSettingsService: ContextMenuSettingsService,
    private servicePeriodCodesClient: ServicePeriodCodesClient,
    private keyupControlService: KeyupControlService,
    private updateInvoicePageService: UpdateInvoicePageService
  ) {}

  ngOnInit(): void {
    this.pagesAllAvaProjectService.forceRefresh();
    if (this.typeQTO === QuantityTakeOffType.QuantityEstimation) {
      this.columnsToDisplay = this.columnsToDisplay.filter((item: string) => item !== 'billed' && item !== 'isChecked' && item !== 'lz');
    }
    this.pagesAllAvaProjectService.initService({
      paging: { page: 1, pageSize: 100 },
      sortTableObj: this.sort
    });
    if (this.typeQTO === QuantityTakeOffType.Invoice) {
      this.pagesAllAvaProjectService.setQueryParameter('excludeQuantityEstimation', 'true');
    }

    this.pagesAllAvaProjectService.paginationResult.subscribe((result) => {
      if (result && result.data && result.data.length) {
        result.data.forEach((page) => {
          // We're actually putting that there so we can use it in the template and
          // bind to it via ngModel.
          page['quantityTakeOffNumber'] = page.quantityTakeOff?.number;
        });
      }
    });

    this.structureView = this.router.url.includes('invoices') ? 'invoices' : 'estimations';
    combineLatest([
      this.selectedProjectMessengerService.selectedProject,
      this.selectedSpecificationMessengerService.selectedServiceSpecification
    ])
      .pipe(takeUntil(this.$destroy))
      .subscribe(([project, s]: [ProjectGet, { avaProjectId: string; project: ProjectDto }]) => {
        this.projectId = project?.id;
        this.avaProjectId = s?.avaProjectId;
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
    this.pagesAllAvaProjectService.setQueryParameter('buildingElementCodeId', null);
    this.pagesAllAvaProjectService.setQueryParameter('excludeQuantityEstimation', null);
  }

  selectedPage(row: PageQuantityTakeOffGet): void {
    if (row.quantityTakeOffId) {
      this.router.navigate(['..', row.quantityTakeOffId, 'pages', row.id], {
        relativeTo: this.route
      });
    } else {
      this.router.navigate(['..', 'standalone-pages', row.id], {
        relativeTo: this.route
      });
    }
  }

  deletePage(page: PageQuantityTakeOffGet): void {
    this.modalService
      .openModal(ModalConfirmComponent, {
        dialogType: ConfirmationType.Delete,
        data: ['Löschen', 'Aufmaßblatt', `Blatt: ${page.name}`, 'red']
      })
      .afterClosed()
      .subscribe((isConfirm: boolean) => {
        if (isConfirm) {
          this.pageQuantityTakeOffsClient.deletePageQuantityTakeOff(this.projectId, this.avaProjectId, page.id).subscribe({
            next: () => {
              this.pagesAllAvaProjectService.forceRefresh();
              this.pageQuantityTakeOffsService.forceRefresh();
              this.avaNotificationsService.success('Aufmaßblatt gelöscht.');
            },
            error: () => this.avaNotificationsService.error('Das Aufmaßblatt konnte nicht gelöscht werden.')
          });
        }
      });
  }

  editPage(page: PageQuantityTakeOffGet): void {
    this.modalService
      .openModal(NewInvoicePageComponent, {
        dialogType: ConfirmationType.General,
        data: { structureView: this.structureView, page: page },
        restoreFocus: false
      })
      .afterClosed()
      .subscribe((pqto: PageQuantityTakeOffGet) => {
        if (pqto) {
          this.pagesAllAvaProjectService.forceRefresh();
        }
      });
  }

  showContextMenu(event: MouseEvent, item: PageQuantityTakeOffGet): void {
    this.disabledObject = {
      edit: item.isChecked,
      move: item.isChecked,
      delete: item.isChecked
    };
    this.contextMenuSettingsService.setDefaultSettings(event, item, this.contextMenuPosition, this.contextMenuComponent.menu);
  }

  handlerMenuAction(e: PageQtoMenuAction): void {
    switch (e.action) {
      case InvoiceMenuActions.EditInvoiceName:
        this.editPage(e.item);
        break;
      case InvoiceMenuActions.RemoveInvoice:
        this.deletePage(e.item);
        break;
      case InvoiceMenuActions.CopyInvoice:
        this.copyPage(e.item);
        break;
      case InvoiceMenuActions.MoveInvoice:
        this.copyPage(e.item, true);
        break;
      case InvoiceMenuActions.Open:
        this.selectedPage(e.item);
        break;
      default:
        break;
    }
  }

  copyPage(page: PageQuantityTakeOffGet, isRemovePage?: boolean): void {
    this.modalService
      .openModal(CopyInvoicePageComponent, {
        dialogType: ConfirmationType.General,
        data: { structureView: this.structureView, page, quantityTakeOffId: page.quantityTakeOffId, isRemovePage },
        restoreFocus: false
      })
      .afterClosed()
      .subscribe((newPage) => {
        if (newPage) {
          this.pagesAllAvaProjectService.forceRefresh();
          this.pageQuantityTakeOffsService.forceRefresh();
          if (newPage.quantityTakeOffId != null) {
            this.router.navigate(['..', newPage.quantityTakeOffId, 'pages', newPage.id], {
              relativeTo: this.route
            });
          } else {
            this.router.navigate(['..', 'standalone-pages', newPage.id], {
              relativeTo: this.route
            });
          }
        }
      });
  }

  updateInvoicePage(value: string, page: PageQuantityTakeOffGet, propertyName: string): void {
    this.updateInvoicePageService.updateInvoicePage(this.projectId, this.avaProjectId, value, page, propertyName).subscribe({
      next: (updateResult) => {
        if (updateResult && updateResult[`${propertyName}Id`]) {
          page[`${propertyName}Id`] = updateResult[`${propertyName}Id`];
        }

        if (!updateResult || propertyName === 'quantityTakeOffName' || propertyName == 'quantityTakeOffNumber') {
          // We're updating either when there were no changes saved (to reload the current values),
          // or if we've change the name of the QTO since that could affect multiple values
          this.pagesAllAvaProjectService.forceRefresh();
        }
      },
      error: () => {
        this.avaNotificationsService.error('Fehler beim Speichern der Änderungen.');
        this.pagesAllAvaProjectService.forceRefresh();
      }
    });
  }

  onKey(event: KeyboardEvent): void {
    this.keyupControlService.preventKeyDownEvent(event);
    this.keyupControlService.onKey(
      event,
      this.columnsToDisplay.filter((col) => this.editableColumns.includes(col)),
      null
    );
  }
}
