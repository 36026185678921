import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { InvoiceCodeValuesComponent } from '@serv-spec/components/invoice-code-values/invoice-code-values.component';
import { EstimationQuantityComponent } from '@serv-spec/components/invoice/components/estimation-quantity/estimation-quantity.component';
import { InvoiceSheetsComponent } from '@serv-spec/components/invoice/components/invoice-sheets/invoice-sheets.component';
import { InvoicesAllPagesComponent } from '@serv-spec/components/invoice/components/invoices-all-pages/invoices-all-pages.component';
import { TreeInvoicePageComponent } from '@serv-spec/components/invoice/components/tree-invoice-page/tree-invoice-page.component';
import { TreeInvoicePositionsComponent } from '@serv-spec/components/invoice/components/tree-invoice-positions/tree-invoice-positions.component';
import { PriceInquiriesDetailComponent } from '@serv-spec/components/price-inquiries-detail/price-inquiries-detail.component';
import { PriceInquiriesComponent } from '@serv-spec/components/price-inquiries/price-inquiries.component';
import { ProjectProductsComponent } from '@serv-spec/components/project-products/project-products.component';
import { QuickEditPositionsTableComponent } from '@serv-spec/components/quick-edit-positions-table/quick-edit-positions-table.component';
import { ScenarioAdditionsComponent } from '@serv-spec/components/scenario-additions/scenario-additions.component';
import { SelectionElementItemComponent } from '@serv-spec/components/selection-element-item/selection-element-item.component';
import { SelectionElementListComponent } from '@serv-spec/components/selection-element-list/selection-element-list.component';
import { SettingTaxRatesComponent } from '@serv-spec/components/settings/components/setting-tax-rates/setting-tax-rates.component';

import { KindInvoiceCodeType } from '@shared/models';

import { ContactDetailComponent } from '@contacts/components/contact-detail/contact-detail.component';

import { getAppConfig } from 'app/app-config-accessor';

import { QuantityTakeOffExportType, QuantityTakeOffType } from '../../generated-client/generated-client';
import { IsAuthenticatedGuard } from '../../shared/guards/is-authenticated.guard';

import { ProjectFilesComponent } from './components/project-files/components/project-files/project-files.component';
import { ProjectIdComponent } from './components/project-id/project-id.component';
import { CopyProjectOverviewComponent } from './components/project-management/components/copy-project-overview/copy-project-overview.component';
import { EditProjectComponent } from './components/project-management/components/edit-project/edit-project.component';
import { LockProjectComponent } from './components/project-management/components/lock-project/lock-project.component';
import { ProjectAwardIdComponent } from './components/project-management/components/project-award-id/project-award-id.component';
import { ProjectCompanyComponent } from './components/project-management/components/project-company/project-company.component';
import { ProjectContactComponent } from './components/project-management/components/project-contact/project-contact.component';
import { ProjectCostCenterComponent } from './components/project-management/components/project-cost-center/project-cost-center.component';
import { ProjectDeletionComponent } from './components/project-management/components/project-deletion/project-deletion.component';
import { ProjectManagementComponent } from './components/project-management/components/project-management/project-management.component';
import { ProjectQtoTypeComponent } from './components/project-management/components/project-qto-type/project-qto-type.component';
import { ProjectSettingsComponent } from './components/project-management/components/project-settings/project-settings.component';
import { ProjectStatusComponent } from './components/project-management/components/project-status/project-status.component';
import { ProjectTermsComponent } from './components/project-management/components/project-terms/project-terms.component';
import { AddServiceSpecificationComponent } from './components/service-specifications/components/add-service-specification/add-service-specification.component';
import { CalculationModeComponent } from './components/service-specifications/components/calculation-mode/components/calculation-mode/calculation-mode.component';
import { DashboardComponent } from './components/service-specifications/components/dashboard/components/dashboard/dashboard.component';
import { InvoicePagesComponent } from './components/service-specifications/components/invoice/components/invoice-pages/invoice-pages.component';
import { InvoiceComponent } from './components/service-specifications/components/invoice/components/invoice/invoice.component';
import { InvoicesMainComponent } from './components/service-specifications/components/invoice/components/invoices-main/invoices-main.component';
import { InvoicesComponent } from './components/service-specifications/components/invoice/components/invoices/invoices.component';
import { LvEditorComponent } from './components/service-specifications/components/lv-editor/components/lv-editor/lv-editor.component';
import { CanDeactivateGuard } from './components/service-specifications/components/lv-editor/guards/can-deactivate.guard';
import { SpecificAllProductsComponent } from './components/service-specifications/components/project-products/components/specific-all-products/specific-all-products.component';
import { SpecificProductComponent } from './components/service-specifications/components/project-products/components/specific-product/specific-product.component';
import { ServiceSpecificationComponent } from './components/service-specifications/components/service-specification/service-specification.component';
import { ServiceSpecificationsListComponent } from './components/service-specifications/components/service-specifications-list/service-specifications-list.component';
import { ServiceSpecificationsComponent } from './components/service-specifications/components/service-specifications/service-specifications.component';
import { SettingSchemaComponent } from './components/service-specifications/components/settings/components/setting-schema/setting-schema.component';
import { SettingsComponent } from './components/service-specifications/components/settings/components/settings/settings.component';

import { MainFrameBimViewComponent } from '../bim-view/components/main-frame-bim-view/main-frame-bim-view.component';
import { ModelBimListComponent } from '../bim-view/components/model-bim-list/model-bim-list.component';

const searchCodeTableRoute = [
  {
    path: 'building-element-codes',
    component: InvoiceCodeValuesComponent,
    data: { typeTable: KindInvoiceCodeType.ProjectBEC }
  },
  {
    path: 'service-periods',
    component: InvoiceCodeValuesComponent,
    data: { typeTable: KindInvoiceCodeType.ServicePeriod }
  },
  {
    path: 'invoice-periods',
    component: InvoiceCodeValuesComponent,
    data: { typeTable: KindInvoiceCodeType.InvoicePeriodCode }
  },
  {
    path: 'invoice-recipients',
    component: InvoiceCodeValuesComponent,
    data: { typeTable: KindInvoiceCodeType.InvoiceRecipientCode }
  }
];

const routeForInvoice = {
  path: ':invoiceId',
  component: InvoiceComponent,
  children: [
    {
      path: 'pages',
      component: InvoicePagesComponent
    },
    {
      path: 'pages/sheets',
      component: InvoiceSheetsComponent
    },
    {
      path: 'pages/quantity',
      component: EstimationQuantityComponent
    },
    {
      path: 'pages/:pageId',
      component: TreeInvoicePageComponent
    },
    {
      path: 'positions',
      component: TreeInvoicePositionsComponent
    },
    {
      path: 'positions/quantity',
      component: EstimationQuantityComponent
    },
    {
      path: 'positions/sheets',
      component: InvoiceSheetsComponent
    }
  ]
};

const routes: Routes = [
  {
    path: 'projects/:projectId',
    component: ProjectIdComponent,
    children: [
      {
        path: 'service-specifications',
        component: ServiceSpecificationsComponent,
        canActivate: [IsAuthenticatedGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: ServiceSpecificationsListComponent
          },
          {
            path: 'new',
            component: AddServiceSpecificationComponent
          },
          {
            path: ':serviceSpecificationId',
            component: ServiceSpecificationComponent,
            children: [
              {
                path: 'bim-list',
                children: [
                  {
                    path: '',
                    pathMatch: 'full',
                    component: ModelBimListComponent
                  },
                  {
                    path: 'bim-viewer',
                    children: [
                      {
                        path: ':bimFileId',
                        component: MainFrameBimViewComponent
                      }
                    ]
                  }
                ]
              },
              {
                path: 'calculation',
                component: CalculationModeComponent
              },
              {
                path: 'calculation/scenario',
                component: ScenarioAdditionsComponent
              },
              {
                path: 'calculation/project-products',
                component: ProjectProductsComponent,
                children: [
                  {
                    path: 'all',
                    component: SpecificAllProductsComponent
                  },
                  {
                    path: 'catalogs',
                    loadComponent: () =>
                      import('app/areas//catalogs/components/catalogs/catalogs.component').then((c) => c.CatalogsComponent),
                    data: { isSpecificProjectProductsTableComponent: true }
                  },
                  {
                    path: 'product/:product',
                    component: SpecificProductComponent
                  },
                  {
                    path: '',
                    pathMatch: 'full',
                    redirectTo: 'all'
                  }
                ]
              },
              {
                path: 'selection',
                component: SelectionElementListComponent
              },
              {
                path: 'selection/item',
                component: SelectionElementItemComponent
              },
              {
                path: 'inquiries',
                component: PriceInquiriesComponent
              },
              {
                path: 'inquiries/item',
                component: PriceInquiriesDetailComponent
              },
              {
                path: 'lv-editor',
                component: LvEditorComponent,
                canDeactivate: [CanDeactivateGuard]
              },
              {
                path: 'quick-edit',
                component: QuickEditPositionsTableComponent
              },
              {
                path: 'estimations',
                component: InvoicesMainComponent,
                data: {
                  quantityTakeOffType: QuantityTakeOffType.QuantityEstimation
                },
                children: [
                  {
                    path: 'all-pages',
                    component: InvoicesAllPagesComponent
                  },
                  {
                    path: '',
                    pathMatch: 'full',
                    component: InvoicesComponent
                  },
                  routeForInvoice
                ]
              },
              {
                path: 'invoices',
                component: InvoicesMainComponent,
                data: {
                  quantityTakeOffType: QuantityTakeOffType.Invoice
                },
                children: [
                  {
                    path: 'standalone-pages/:pageId',
                    component: TreeInvoicePageComponent,
                    data: { isStandalonePage: true }
                  },
                  {
                    path: 'sheets',
                    component: InvoiceSheetsComponent
                  },
                  {
                    path: 'all-pages',
                    component: InvoicesAllPagesComponent
                  },
                  {
                    path: 'print-pages',
                    loadComponent: () =>
                      import('@serv-spec/components/invoice/components/invoice-print-view/invoice-print-view.component').then(
                        (c) => c.InvoicePrintViewComponent
                      ),
                    data: { quantityTakeOffExportType: QuantityTakeOffExportType.Pages }
                  },
                  {
                    path: 'print-sum-pages',
                    loadComponent: () =>
                      import('@serv-spec/components/invoice/components/invoice-print-view/invoice-print-view.component').then(
                        (c) => c.InvoicePrintViewComponent
                      ),
                    data: { quantityTakeOffExportType: QuantityTakeOffExportType.Summary }
                  },
                  {
                    path: 'print-invoices',
                    loadComponent: () =>
                      import('@serv-spec/components/invoice/components/invoice-print-view/invoice-print-view.component').then(
                        (c) => c.InvoicePrintViewComponent
                      ),
                    data: { quantityTakeOffExportType: QuantityTakeOffExportType.Invoice }
                  },
                  {
                    path: 'search-table',
                    component: ProjectManagementComponent,
                    children: [
                      {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'service-periods'
                      },
                      ...searchCodeTableRoute
                    ],
                    data: { isShortTabs: true }
                  },
                  {
                    path: 'all-pages/sheets',
                    component: InvoiceSheetsComponent
                  },
                  {
                    path: '',
                    pathMatch: 'full',
                    component: InvoicesComponent
                  },
                  routeForInvoice
                ]
              },
              {
                path: 'dashboard',
                component: DashboardComponent
              },
              {
                path: 'settings',
                component: SettingsComponent,
                children: [
                  {
                    path: 'setting-schema',
                    component: SettingSchemaComponent
                  },
                  {
                    path: 'tax-rates',
                    component: SettingTaxRatesComponent
                  },
                  {
                    path: '',
                    pathMatch: 'full',
                    redirectTo: 'setting-schema'
                  }
                ]
              },
              {
                path: '',
                pathMatch: 'full',
                redirectTo: getAppConfig().isQtoOnlyMode ? 'invoices' : 'calculation'
              }
            ]
          }
        ]
      },
      { path: 'project-files', component: ProjectFilesComponent },
      {
        path: 'project-management',
        component: ProjectManagementComponent,
        canActivate: [IsAuthenticatedGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'contact'
          },
          {
            path: 'contact',
            component: ProjectContactComponent,
            data: {
              isTenderer: false
            }
          },
          {
            path: 'contact/:contactsId',
            loadComponent: () => import('@contacts/components/contact/contact.component').then((c) => c.ContactComponent),
            children: [
              {
                path: '',
                pathMatch: 'full',
                component: ContactDetailComponent
              }
            ]
          },
          {
            path: 'tenderer-contact',
            component: ProjectContactComponent,
            data: {
              isTenderer: true
            }
          },
          {
            path: 'tenderer-contact/:contactsId',
            loadComponent: () => import('@contacts/components/contact/contact.component').then((c) => c.ContactComponent),
            children: [
              {
                path: '',
                pathMatch: 'full',
                component: ContactDetailComponent
              }
            ]
          },
          {
            path: 'project-company',
            component: ProjectCompanyComponent
          },
          ...searchCodeTableRoute,
          {
            path: 'project-award',
            component: ProjectAwardIdComponent
          },
          {
            path: 'project-settings',
            component: ProjectSettingsComponent
          },
          {
            path: 'project-terms',
            component: ProjectTermsComponent
          },
          {
            path: 'copy',
            component: CopyProjectOverviewComponent
          },

          {
            path: 'status',
            component: ProjectStatusComponent
          },
          {
            path: 'edit-project',
            component: EditProjectComponent
          },
          {
            path: 'lock-project',
            component: LockProjectComponent
          },
          {
            path: 'delete-project',
            component: ProjectDeletionComponent
          },
          {
            path: 'qto-type',
            component: ProjectQtoTypeComponent
          },
          {
            path: 'cost-center',
            component: ProjectCostCenterComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [CanDeactivateGuard]
})
export class ProjectIdRoutingModule {}
