import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';

import { Subject } from 'rxjs';

import { ErrorReportGet } from 'app/generated-client/generated-client';
import { ErrorReportsService } from 'app/shared/services/lightquery/error-reports.service';

import { UpperPaginatorComponent } from '../../../../shared/components/upper-paginator/upper-paginator.component';

import { ErrorReportDetailComponent } from '../error-report-detail/error-report-detail.component';

@Component({
  selector: 'pa-error-reports',
  templateUrl: './error-reports.component.html',
  styleUrls: ['./error-reports.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatCheckbox,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    UpperPaginatorComponent,
    AsyncPipe,
    DatePipe
  ]
})
export class ErrorReportsComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  filter: string = '';
  private _showResolved = false;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  get showResolved(): boolean {
    return this._showResolved;
  }

  set showResolved(value: boolean) {
    this._showResolved = value;
    if (value) {
      this.errorReportsService.setQueryParameter('includeResolved', 'true');
    } else {
      this.errorReportsService.setQueryParameter('includeResolved', null);
    }
  }

  constructor(
    public errorReportsService: ErrorReportsService,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.errorReportsService.initService({
      paging: { page: 1, pageSize: 10 },
      sorting: { propertyName: 'id', isDescending: true },
      sortTableObj: this.sort
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
    this.errorReportsService.setQueryParameter('includeResolved', null);
  }

  showDetail(errorReport: ErrorReportGet): void {
    this.matDialog.open(ErrorReportDetailComponent, {
      data: errorReport
    });
  }
}
