<div class="color-options">
  <h2>Farbschema Unterpositionen</h2>
  <p>
    Hier kannst Du das Farbschema für die Darstellung von Unterpositionen in der Kalkulationstabelle auswählen. Es werden bis zu fünf Ebenen
    unterstützt. Danach werden die Farben wiederholt.
  </p>
  <div *ngFor="let color of colors; let i = index" class="option">
    <span>Ebene {{ i + 1 }}</span>
    <div class="color-preview" [style.background-color]="color">
      <pa-color-picker [currentColore]="color" (updateCurrentColor)="selectColor($event, i)"></pa-color-picker>
    </div>
  </div>
</div>
<button mat-raised-button color="primary" (click)="resetColorSettings()">Standard wiederherstellen</button>
