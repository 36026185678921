import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { filter, takeUntil } from 'rxjs/operators';

import { QuantityTakeOffGet } from '../../../generated-client/generated-client';

import { PaginationMainService } from './main/pagination-main.service';

import { SelectedSpecificationMessengerService } from '../messengers/selected-specification-messenger.service';

@Injectable({
  providedIn: 'root'
})
export class QuantityTakeOffsService extends PaginationMainService<QuantityTakeOffGet> {
  private projectId: string;
  private avaProjectId: string;

  constructor(protected http: HttpClient, selectedSpecificationMessengerService: SelectedSpecificationMessengerService) {
    super(http);
    selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        takeUntil(this.$destroy),
        filter((s) => !!s)
      )
      .subscribe((p) => {
        this.projectId = p?.parentProjectId;
        this.avaProjectId = p?.avaProjectId;
        this.setBaseUrl();
      });
  }

  private setBaseUrl(): void {
    if (this.projectId && this.avaProjectId) {
      this.beginBaseUrl(`/api/projects/${this.projectId}/ava-projects/${this.avaProjectId}/quantity-take-offs`);
    } else {
      this.baseUrl = null;
    }
  }

  tryGetQuantityTakeOffById(quantityTakeOffId: string): QuantityTakeOffGet | undefined {
    return this.getItemById(quantityTakeOffId);
  }
}
