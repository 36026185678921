import { DOCUMENT, NgTemplateOutlet, CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTooltip } from '@angular/material/tooltip';

import { BehaviorSubject, Subject, combineLatest, debounceTime, filter, fromEvent, of, take, takeUntil, zip } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import { LvEditorReorderModalComponent } from '@serv-spec/components/lv-editor/components/lv-editor-reorder-modal/lv-editor-reorder-modal.component';
import { TotalSumsComponent } from '@serv-spec/components/total-sums/total-sums.component';
import { CurrentPositionCalculationGetService } from '@serv-spec/services/current-position-calculation-get.service';
import { DbclickSubscriptionService } from '@serv-spec/services/dbclick-subscription.service';
import { ElementFilterService } from '@serv-spec/services/element-filter.service';
import { LvEditorService } from '@serv-spec/services/lv-editor.service';

import { CopyGroupModalComponent } from 'app/areas/copy-calculation-view/components/copy-group-modal/copy-group-modal.component';
import { TreeMenuComponent } from 'app/areas/tree/components/tree-menu/tree-menu.component';
import { ClickerService } from 'app/areas/tree/services/clicker.service';
import { CopyCalculationForGroupService } from 'app/areas/tree/services/copy-calculation-for-group.service';
import { FlatElementsService } from 'app/areas/tree/services/flat-elements.service';
import { ModePageService } from 'app/areas/tree/services/mode-page.service';
import { TreeCopyElementService } from 'app/areas/tree/services/tree-copy-element.service';
import { TreeNodeDragingService } from 'app/areas/tree/services/tree-node-draging.service';
import { TreeNodeSelectingService } from 'app/areas/tree/services/tree-node-selecting.service';
import { TreeNodeStateService } from 'app/areas/tree/services/tree-node-state.service';
import { TreeNodeService } from 'app/areas/tree/services/tree-node.service';
import { getElementDtoById } from 'app/areas/tree/utils/fn';
import {
  AvaProjectContentEditClient,
  AvaProjectContentEditOperation,
  AvaProjectGet,
  ExecutionDescriptionDto,
  IElementDto,
  NoteTextDto,
  PositionCalculationsSubPositionsClient,
  PositionDto,
  ProjectDto,
  ProjectGet,
  SelectedElement,
  ServiceSpecificationDto,
  ServiceSpecificationGroupDto,
  SubPositionGet,
  TreeViewDisplayType,
  UserSettings
} from 'app/generated-client/generated-client';
import { SelectingElementsType } from 'app/shared/models';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { TreeViewCommandType } from 'app/shared/models/tree-view-command.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { ContextMenuSettingsService } from 'app/shared/services/context-menu-settings.service';
import { CopyElementViewMessengerService } from 'app/shared/services/electron/copy-element-view-messenger.service';
import { TreeViewMessengerService } from 'app/shared/services/electron/tree-view-messenger.service';
import { GroupViewService } from 'app/shared/services/group-view.service';
import { KeyboardPositionSelectionService } from 'app/shared/services/keyboard-position-selection.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { SubPositionsMessengerService } from 'app/shared/services/messengers/sub-positions-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';
import { SelectRowsService } from 'app/shared/services/select-rows.service';
import { TopMenuButtonsService } from 'app/shared/services/top-menu-buttons.service';
import { UserSettingsService } from 'app/shared/services/user-settings.service';
import { setAsSplitSize } from 'app/shared/utilities/area-size';
import { setStorage } from 'app/shared/utilities/storage';

import { FlexLayoutDirective } from '../../../flex-layout/flex-layout.directive';
import { PositionTextAdditionService } from '../../services/position-text-addition.service';
import { TreeMessagesSeparateService } from '../../services/tree-messages-separate.service';
import { TreeNodeMarkService } from '../../services/tree-node-mark.service';

import { TreeListViewComponent } from '../tree-list-view/tree-list-view.component';
import { TreeStructureViewComponent } from '../tree-structure-view/tree-structure-view.component';
import { TreeTableViewComponent } from '../tree-table-view/tree-table-view.component';
import { IOutputAreaSizes, SplitComponent, AngularSplitModule } from 'angular-split';

@Component({
  selector: 'pa-main-tree',
  templateUrl: './main-tree.component.html',
  styleUrls: ['./main-tree.component.scss'],
  providers: [ClickerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AngularSplitModule,
    FlexLayoutDirective,
    NgTemplateOutlet,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatCheckbox,
    MatTooltip,
    MatIconButton,
    MatIcon,
    MatButton,
    TreeStructureViewComponent,
    TreeMenuComponent,
    TreeListViewComponent,
    TreeTableViewComponent,
    MatProgressSpinner,
    TotalSumsComponent
  ]
})
export class MainTreeComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('asSplitBottomRef') asSplitBottomRef: SplitComponent;
  @ViewChild('currentTree') currentTree: ElementRef;
  @ViewChild(TreeMenuComponent) treeMenuComponent: TreeMenuComponent;

  @Input() withoutSplitArea = true;
  @Input() isSelectingMode = false;
  @Input() isInnerWindow = false;
  @Input() selectedElements: SelectedElement[];
  @Input() showedAvaProject: ServiceSpecificationDto;
  @Input() set updateShowedAvaProject(v: boolean) {
    if (v) {
      this.serviceSpecification = this.showedAvaProject;
    }
  }
  @Input() isSeparate = false;
  @Input() isCopyCalculation = false;
  @Input() isCopyElementView = false;
  @Output() dataForCopy = new EventEmitter<PositionDto | ServiceSpecificationGroupDto>();

  private $destroy: Subject<boolean> = new Subject<boolean>();
  public filterSource$ = new BehaviorSubject<string>('');
  private buttonClicked$ = new Subject<IElementDto>();
  private addSubposition$ = new Subject<boolean>();
  private selectSubPosition$ = new Subject<{ selected: boolean; node: SubPositionGet }>();

  private userSettings: UserSettings;
  serviceSpecification: ServiceSpecificationDto;
  selectedGroupForCopy: ServiceSpecificationGroupDto;
  selectedElement: IElementDto;
  ProjectGroupView = TreeViewDisplayType;
  projectGroupView: TreeViewDisplayType;
  flatElementsDto: (ServiceSpecificationGroupDto | NoteTextDto | ExecutionDescriptionDto | PositionDto)[] = [];

  showTotalsInTreeQTO: boolean;
  isShowTree = false;
  private isSingleClick = true;
  private isCursorInsideTreeField = true;
  isPositionOnlyAddition = false;
  allowFilterPositionOnlyAddition = false;

  private keyboardNavigationCallbackId: number | null = null;

  filter: string;
  choseSelectedElementId = '';
  private projectId: string;
  private avaProjectId: string;
  private parentIdsForSelectedElements: string[] = [];
  flatSelectedListId: string[] = [];
  treeState: { [elementId: string]: boolean } = {};
  subPositions: { [key: string]: SubPositionGet[] } = {};
  contextMenuPosition = { x: '0px', y: '0px' };
  private selectingElementsTreeData: SelectingElementsType[] = [];

  constructor(
    public modePageService: ModePageService,
    private userSettingsService: UserSettingsService,
    @Optional() private keyboardPositionSelectionService: KeyboardPositionSelectionService,
    @SkipSelf() public groupViewService: GroupViewService,
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    @Inject(DOCUMENT) private document: Document,
    private ngZone: NgZone,
    @Self() private clickerService: ClickerService,
    private dbclickSubscriptionService: DbclickSubscriptionService,
    private treeViewMessengerService: TreeViewMessengerService,
    private flatElementsService: FlatElementsService,
    private elementFilterService: ElementFilterService,
    private cdr: ChangeDetectorRef,
    private treeNodeService: TreeNodeService,
    private treeNodeSelectingService: TreeNodeSelectingService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private treeNodeStateService: TreeNodeStateService,
    private treeNodeDragingService: TreeNodeDragingService,
    private avaProjectContentEditClient: AvaProjectContentEditClient,
    private avaNotificationsService: AvaNotificationsService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private positionCalculationsSubPositionsClient: PositionCalculationsSubPositionsClient,
    private subPositionsMessengerService: SubPositionsMessengerService,
    private selectRowsService: SelectRowsService,
    private currentPositionCalculationGetService: CurrentPositionCalculationGetService,
    private topMenuButtonsService: TopMenuButtonsService,
    private lvEditorService: LvEditorService,
    private modalService: ModalService,
    private copyCalculationForGroupService: CopyCalculationForGroupService,
    private treeMessagesSeparateService: TreeMessagesSeparateService,
    private contextMenuSettingsService: ContextMenuSettingsService,
    private treeNodeMarkService: TreeNodeMarkService,
    private copyElementViewMessengerService: CopyElementViewMessengerService,
    private positionTextAdditionService: PositionTextAdditionService,
    private treeCopyElementService: TreeCopyElementService
  ) {}

  ngOnInit(): void {
    this.addListenerEventOutsideAngularZone();
    this.preventMultipleClickOnButton();
    this.groupViewService.isCheckSelectedProject$.next(this.isSeparate);

    if (!this.isInnerWindow) {
      this.copyElementViewMessengerService.dataFromCopyElemenetView.pipe(takeUntil(this.$destroy)).subscribe((dataFromCopyElemenetView) => {
        this.treeCopyElementService.setTreeElementForCopy(dataFromCopyElemenetView);
      });
    }

    if (!this.isSelectingMode && this.keyboardPositionSelectionService) {
      this.keyboardNavigationCallbackId = this.keyboardPositionSelectionService.addCallbackAndReturnId((selectNext) => {
        if (selectNext) {
          this.selectPosition(1);
        } else {
          this.selectPosition(-1);
        }
        this.treeViewMessengerService.treeViewVisible.pipe(take(1)).subscribe((treeViewVisible) => {
          if (treeViewVisible && !this.isInnerWindow) {
            this.treeViewMessengerService.sendDataToTreeView({
              command: TreeViewCommandType.ChangePosition,
              data: [selectNext ? 'F12' : 'F11']
            });
          }
        });
      });
    }

    this.clickerService.clickEventSingle.pipe(takeUntil(this.$destroy)).subscribe((click) => {
      if (!this.isSelectingMode) {
        this.buttonClicked$.next(click);
      } else {
        if (!this.treeState[click.id] && this.projectGroupView === TreeViewDisplayType.Tree) {
          this.treeNodeStateService.set(click);
        }
      }
    });

    this.clickerService.clickEventDbl.pipe(takeUntil(this.$destroy)).subscribe((dblClick) => {
      if (!this.isSelectingMode) {
        this.isSingleClick = false;
        if (dblClick.elementTypeDiscriminator === 'PositionDto') {
          if (this.isInnerWindow) {
            this.treeViewMessengerService.sendDataFromTreeView({
              command: TreeViewCommandType.SelectElementByDblClick,
              data: [dblClick]
            });
          }
          this.dbclickSubscriptionService.setDbclick(dblClick);
        }
      }
    });

    this.groupViewService.projectGroupView.pipe(takeUntil(this.$destroy)).subscribe((projectGroupView) => {
      this.projectGroupView = projectGroupView;
      if (projectGroupView === TreeViewDisplayType.Tree) {
        this.getSubPositionForTreeView();
      }
    });

    this.selectedSpecificationElementMessengerService.selectedElement.pipe(takeUntil(this.$destroy)).subscribe((selectedElement) => {
      this.selectedElement = selectedElement ? Object.assign({}, selectedElement?.element) : null;
      if (this.isInnerWindow && !this.isCopyCalculation) {
        this.treeViewMessengerService.sendDataFromTreeView({
          command: TreeViewCommandType.ChangeSelectedElement,
          data: [this.selectedElement?.id && this.selectedElement]
        });
      }
      this.parentIdsForSelectedElements = [];
      this.changeStateForParentElementsOfSelectedPosition();
    });

    this.treeNodeStateService.treeNodeState.pipe(takeUntil(this.$destroy)).subscribe((treeState) => {
      this.treeState = treeState ? JSON.parse(JSON.stringify(treeState)) : {};
    });

    this.copyCalculationForGroupService.startCopyingGroup.pipe(takeUntil(this.$destroy)).subscribe((startCopyingGroup) => {
      if (startCopyingGroup) {
        this.copyGroup(startCopyingGroup);
        this.selectedGroupForCopy = JSON.parse(JSON.stringify(startCopyingGroup.groupNode));
      } else {
        this.selectedGroupForCopy = null;
        this.copyCalculationForGroupService.sendGroupForCopy(null);
        this.cdr.detectChanges();
      }
    });

    combineLatest([
      this.selectedProjectMessengerService.selectedProject,
      this.selectedSpecificationMessengerService.selectedServiceSpecification
    ])
      .pipe(takeUntil(this.$destroy))
      .subscribe(([project, s]: [ProjectGet, { avaProjectId: string; project: ProjectDto; avaProject: AvaProjectGet }]) => {
        this.projectId = project?.id;
        this.avaProjectId = s?.avaProjectId;
        if (s) {
          this.treeNodeMarkService.getMarkElementForCurrentServSpec();
        }
      });

    if (!this.showedAvaProject) {
      /**Here set state for parent elements of the selected tree element,
       * when the tree is first shown. */
      zip(
        this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(filter((v) => !!v)),
        this.groupViewService.projectGroupView.pipe(filter((v) => v === TreeViewDisplayType.Tree)),
        this.treeNodeStateService.treeNodeState,
        this.selectedSpecificationElementMessengerService.selectedElement.pipe(filter((v) => !!v))
      )
        .pipe(take(1))
        .subscribe(([selectedServiceSpecification, , treeNodeState, selectedElement]) => {
          this.serviceSpecification = selectedServiceSpecification?.project.serviceSpecifications[0];
          this.selectedElement = selectedElement ? selectedElement.element : null;
          this.treeState = treeNodeState;
          this.parentIdsForSelectedElements = [];
          this.changeStateForParentElementsOfSelectedPosition();
        });

      combineLatest([
        this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(filter((v) => !!v)),
        this.flatElementsService.flatElementsDto,
        this.groupViewService.projectGroupView,
        this.filterSource$.pipe(debounceTime(250))
      ])
        .pipe(takeUntil(this.$destroy))
        .subscribe(([selectedServiceSpecification, flatElementsDto, projectGroupView, filter]) => {
          this.serviceSpecification = selectedServiceSpecification?.project.serviceSpecifications[0];
          const elements = !filter ? flatElementsDto : this.elementFilterService.filterElements(flatElementsDto, filter);
          if (projectGroupView === TreeViewDisplayType.PositionList) {
            this.flatElementsDto = [...elements.filter((e) => e.elementTypeDiscriminator === 'PositionDto')];
          } else {
            this.flatElementsDto = [...elements];
          }

          if (this.isSelectingMode) {
            this.selectingElementsTreeData = this.treeNodeSelectingService.loadSelectingElements(this.serviceSpecification.elements);
            if (this.selectedElements || this.selectedElements?.length) {
              this.getFlatSelectedListId(this.selectedElements);
              this.flatSelectedListId.forEach((id) => {
                this.treeNodeSelectingService.changeSelecting(true, id);
              });
              const selectingTree = this.getTreeRoot(this.selectingElementsTreeData);
              this.treeNodeSelectingService.setSelectingChanged(selectingTree);
            }
          }

          this.isShowTree = true;

          /**Here is need setTimeout so DOM updated the classes of the elements
           * and then scroll to it,
           * but run it outside angular zone so as not to cause change detection cycles */
          this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
              this.ensureSelectedElementIsInViewport();
            }, 0);
          });
          this.cdr.detectChanges();
        });

      this.treeNodeDragingService.moveElement.pipe(takeUntil(this.$destroy)).subscribe((moveElement) => {
        this.moveElement(moveElement);
      });

      /**If select subposition in a position that is not currently selected,
       *  first load calculation for it and then select the line */
      this.selectSubPosition$
        .pipe(
          takeUntil(this.$destroy),
          switchMap((obj) => zip(of(obj.node), this.currentPositionCalculationGetService.isCalculationDataLoading.pipe(take(1))))
        )
        .subscribe(([node]) => {
          this.selectRowsService.setSelectedRowByIndex((node as SubPositionGet)?.rowIndex);
        });

      /**Subposition changes are tracked here if the tree is open in the tree structure */
      this.subPositionsMessengerService.positionSpecificSubPositions
        .pipe(takeUntil(this.$destroy))
        .subscribe((positionSpecificSubPositions) => {
          if (this.projectGroupView === TreeViewDisplayType.Tree) {
            this.addSubPostionToCurrentPosition(this.serviceSpecification, positionSpecificSubPositions);
          }
        });

      /**
       * Here once at the first loading or
       * after switching tree view in the form of a tree structure
       * and only for the calculation section add sub positions
       */
      zip(this.addSubposition$, this.subPositionsMessengerService.subPositions)
        .pipe(takeUntil(this.$destroy))
        .subscribe(([, subPositionData]) => {
          this.subPositions = JSON.parse(JSON.stringify(subPositionData.subPositions.subPositionsByAvaPositionId));
          if (this.serviceSpecification) {
            this.addSubPositionToList(this.serviceSpecification.elements);
          }
          this.cdr.detectChanges();
        });

      this.topMenuButtonsService.clickedButton.pipe(takeUntil(this.$destroy)).subscribe((name) => {
        this.clickedMenuButton(name);
      });

      this.treeNodeSelectingService.treeNodeSelecting.pipe(takeUntil(this.$destroy)).subscribe(() => {
        const selectingTree = this.getTreeRoot(this.selectingElementsTreeData);
        this.treeNodeSelectingService.setSelectingChanged(selectingTree);
      });
    } else {
      this.serviceSpecification = this.showedAvaProject;
      this.flatElementsDto = FlatElementsService.getFlatElements(this.serviceSpecification);
      this.isShowTree = true;
      this.cdr.detectChanges();
    }

    if (this.isCopyCalculation) {
      this.treeMessagesSeparateService.nodeForCopy.pipe(takeUntil(this.$destroy)).subscribe((node) => {
        this.dataForCopy.emit(node);
      });
    }
  }

  ngAfterViewInit(): void {
    this.userSettingsService.currentUserSettings.pipe(takeUntil(this.$destroy)).subscribe((settings: UserSettings) => {
      this.userSettings = settings;
      this.showTotalsInTreeQTO = settings.showsTotalsInQuantityTakeOffBelowTree;
      this.allowFilterPositionOnlyAddition = settings.showTreeFilterForPositionsWithTextAdditions;
      if (!this.withoutSplitArea && this.showTotalsInTreeQTO && this.asSplitBottomRef) {
        setAsSplitSize('AS_SPLIT_TREE_BOTTOM_SIZE', this.asSplitBottomRef);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.keyboardNavigationCallbackId != null) {
      this.keyboardPositionSelectionService.removeCallback(this.keyboardNavigationCallbackId);
    }
    if (!this.isInnerWindow) {
      this.copyCalculationForGroupService.delMarkCopyGroup();
    }
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  onFilter(filter?: string): void {
    this.filter = filter;
    this.filterSource$.next(this.filter);
  }

  onDragEnd(sizes: IOutputAreaSizes): void {
    setStorage<number[]>('AS_SPLIT_TREE_BOTTOM_SIZE', sizes as number[]);
  }

  private unMarkChoosing(): void {
    if (this.choseSelectedElementId) {
      this.choseSelectedElementId = null;
      this.cdr.detectChanges();
    }
  }

  private addListenerEventOutsideAngularZone(): void {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(this.document, 'click')
        .pipe(takeUntil(this.$destroy))
        .subscribe(() => {
          this.unMarkChoosing();
        });

      fromEvent(this.document, 'keydown')
        .pipe(takeUntil(this.$destroy))
        .subscribe((event: KeyboardEvent) => {
          this.handleGlobalKeyboardEvent(event);
        });

      fromEvent(document, 'mousemove')
        .pipe(takeUntil(this.$destroy), debounceTime(200))
        .subscribe((event: MouseEvent) => {
          const el = document.elementFromPoint(event.clientX, event.clientY);
          this.isCursorInsideTreeField = !!el?.closest('.main-wrapper-tree');
        });
    });
  }

  private preventMultipleClickOnButton(): void {
    const buttonClickedDebounced$ = this.buttonClicked$.pipe(debounceTime(200));
    buttonClickedDebounced$.pipe(takeUntil(this.$destroy)).subscribe((node: IElementDto) => {
      if (this.isSingleClick) {
        if (!this.treeState[node.id] && this.projectGroupView === TreeViewDisplayType.Tree) {
          this.treeNodeStateService.set(node);
        }
        if ((node as SubPositionGet)?.avaPositionId) {
          if (this.isInnerWindow) {
            this.treeViewMessengerService.sendDataFromTreeView({
              command: TreeViewCommandType.SelectSubPosition,
              data: [node as SubPositionGet]
            });
          }

          if ((node as SubPositionGet)?.avaPositionId === this.selectedElement?.id) {
            this.selectRowsService.setSelectedRowByIndex((node as SubPositionGet)?.rowIndex);
          } else {
            const parentElement = getElementDtoById((node as SubPositionGet)?.avaPositionId, this.serviceSpecification.elements);
            this.selectedSpecificationElementMessengerService.trySelectElementById(parentElement?.id);
            this.selectSubPosition$.next({ selected: true, node: node });
          }
        }

        if (node.id === this.selectedElement?.id && this.projectGroupView !== TreeViewDisplayType.Table) {
          this.selectedSpecificationElementMessengerService.clearSelectedElement();
        } else {
          this.selectedSpecificationElementMessengerService.trySelectElementById(node.id);
        }
      }
      this.isSingleClick = true;
    });
  }

  private handleGlobalKeyboardEvent(event: KeyboardEvent): void {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        this.moveSelectedElement(true);
        break;
      case 'ArrowDown':
        event.preventDefault();
        this.moveSelectedElement();
        break;
      case 'Enter':
        this.ngZone.run(() => {
          event.preventDefault();
          this.choosingSelectedElement();
        });
        break;
      case 'Escape':
        this.choosingSelectedElement(true);
        break;
      case 'Insert':
        // We only want to insert if we're currently active in the tree
        if (this.checkInTree()) {
          this.ngZone.run(() => {
            this.treeNodeService.addNode(this.selectedElement).subscribe();
          });
        }
        break;
      default:
    }
  }

  private moveSelectedElement(goBack = false): void {
    if (this.checkInTree() && !this.isSeparate) {
      const id = this.choseSelectedElementId || this.selectedElement?.id;
      const list = this.filter ? this.elementFilterService.filterElements(this.flatElementsDto, this.filter) : this.flatElementsDto;
      const index = list.findIndex((item) => item.id === id);
      let nextIndex = index + (goBack ? -1 : 1);
      nextIndex = nextIndex < 0 ? list.length - 1 : nextIndex >= list.length ? 0 : nextIndex;
      const chosen = list[nextIndex];

      if (this.selectedElement.id === chosen.id) {
        this.choseSelectedElementId = null;
      } else {
        this.choseSelectedElementId = { ...list[nextIndex] }.id;
        /**Here is need setTimeout so DOM updated the classes of the elements
         * and then scroll to it,
         * but run it outside angular zone so as not to cause change detection cycles */
        this.ngZone.runOutsideAngular(() => {
          setTimeout(() => {
            this.ensureSelectedElementIsInViewport(true);
          }, 0);
        });
      }
      if (this.selectedElement.id !== chosen.id && this.projectGroupView === TreeViewDisplayType.Tree) {
        this.changeStateForParentElementsOfSelectedPosition(this.choseSelectedElementId);
      }
      this.cdr.detectChanges();
    }
  }

  private checkInTree(): boolean {
    return (
      !this.isSelectingMode &&
      this.selectedElement &&
      (!!document.activeElement.closest('.main-wrapper-tree') ||
        (document.activeElement.tagName === 'BODY' && this.isCursorInsideTreeField))
    );
  }

  private ensureSelectedElementIsInViewport(isChoosing = false): void {
    const findClass = isChoosing ? 'choose-node' : 'selected-node';
    // If the selected node is not visible, we scroll to it
    const nodes: HTMLCollectionOf<Element> = (this.currentTree?.nativeElement || this.document).getElementsByClassName(findClass);
    if (nodes?.length) {
      const nodeRect: DOMRect = nodes[0].getClientRects()[0];
      const containerRect: DOMRect = nodes[0].closest('.main-wrapper-tree')?.getClientRects()[0];
      if (nodeRect && containerRect && (nodeRect.top < containerRect.top || nodeRect.bottom > containerRect.bottom)) {
        nodes[0].scrollIntoView();
      }
    }
  }

  /**
   *
   * @param offsetToCurrent Positive or negative offset from the current selected element
   */
  private selectPosition(offsetToCurrent: number): void {
    if (this.flatElementsDto.length && !this.isSeparate) {
      const currentSelectedIndex: number =
        (this.flatElementsDto.findIndex((e: IElementDto) => e.id === this.selectedElement?.id) + 1 || 1) - 1;
      let index: number = currentSelectedIndex + offsetToCurrent;
      while (index !== currentSelectedIndex) {
        index = index >= this.flatElementsDto.length ? 0 : index < 0 ? this.flatElementsDto.length - 1 : index;
        const shouldSelectNextElement = this.userSettings.onlySelectPositionsWithHotkeyNavigation
          ? this.flatElementsDto[index].elementType === 'PositionDto'
          : true;
        if (shouldSelectNextElement) {
          this.selectedSpecificationElementMessengerService.trySelectElementById(this.flatElementsDto[index].id);
          if (this.projectGroupView === TreeViewDisplayType.Tree) {
            this.parentIdsForSelectedElements = [];
            this.changeStateForParentElementsOfSelectedPosition();
          }
          /**Here is need setTimeout so DOM updated the classes of the elements
           * and then scroll to it,
           * but run it outside angular zone so as not to cause change detection cycles */
          this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
              this.ensureSelectedElementIsInViewport();
            }, 0);
          });
          return;
        }
        index = index + offsetToCurrent;
      }
    }
  }

  choosingSelectedElement(del = false): void {
    if (this.checkInTree() && this.choseSelectedElementId && !this.isSeparate) {
      if (del) {
        this.choseSelectedElementId = null;
        this.cdr.detectChanges();
      } else {
        this.selectedSpecificationElementMessengerService.trySelectElementById(this.choseSelectedElementId);
        this.choseSelectedElementId = null;
      }
    }
  }

  changeAll(value: boolean): void {
    this.treeNodeSelectingService.changeAll(value);
  }

  expandedAllElements(isExpand: boolean): void {
    if (isExpand) {
      this.flatElementsDto.forEach((element) => {
        if (element.elementType === 'ServiceSpecificationGroupDto') {
          this.treeNodeStateService.set(element);
          this.cdr.detectChanges();
        }
      });
    } else {
      this.treeNodeStateService.resetTreeState();
    }
  }

  changeStateForParentElementsOfSelectedPosition(id?: string) {
    const childId = id ?? this.selectedElement?.id;

    this.getParentNodeIds(childId);

    const reversed = this.parentIdsForSelectedElements.reverse();
    reversed.forEach((id) => {
      const el = getElementDtoById(id, this.serviceSpecification.elements);
      if (el) {
        this.treeNodeStateService.set(el);
      }
    });

    /**Here is need setTimeout so DOM updated the classes of the elements
     * and then scroll to it,
     * but run it outside angular zone so as not to cause change detection cycles */
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.ensureSelectedElementIsInViewport();
      }, 0);
    });

    this.cdr.detectChanges();
  }

  private getParentNodeIds(elementId: string, container?: { id?: string; elements?: IElementDto[] }): void {
    container = container || this.serviceSpecification;
    if (container?.elements?.some((e) => e.id === elementId) && (<ServiceSpecificationGroupDto>container).hierarchyLevel >= 0) {
      this.parentIdsForSelectedElements.push(container.id);
      this.getParentNodeIds(container.id, this.serviceSpecification);
    }

    for (let i = 0; i < container?.elements.length; i++) {
      if (container.elements[i].elementType === 'ServiceSpecificationGroupDto') {
        this.getParentNodeIds(elementId, container.elements[i]);
      }
    }
  }

  moveElement(data: { elementId: string; targetContainerId: string; targetPreviousId: string }): void {
    this.avaProjectContentEditClient
      .editAvaProjectContent(this.projectId, this.avaProjectId, {
        operation: AvaProjectContentEditOperation.MoveOperation,
        moveOperation: data
      })
      .subscribe({
        next: () => {
          this.avaNotificationsService.success('Elemente neu angeordnet');
        },
        error: () => {
          this.avaNotificationsService.error('Fehler beim Speichern der Änderungen');
        }
      });
  }

  private addSubPostionToCurrentPosition(element: any, pos: { positionId: string; subPositions: SubPositionGet[] }): void {
    if (element.id == pos.positionId) {
      (<any>element).elements = pos.subPositions;
      return;
    } else if (element.elements != null) {
      let i: number;
      const result = null;
      for (i = 0; result == null && i < element.elements.length; i++) {
        this.addSubPostionToCurrentPosition(element.elements[i], pos);
      }
      return;
    }
    return;
  }

  private addSubPositionToList(elements: IElementDto[]): void {
    elements.map((element) => {
      const subPositions = this.subPositions[element.id];
      if (element.elementType === 'ServiceSpecificationGroupDto' && (<ServiceSpecificationGroupDto>element).elements.length) {
        this.addSubPositionToList((<ServiceSpecificationGroupDto>element).elements);
      } else if (element.elementType === 'PositionDto') {
        // To build the tree, we're actually just putting the sub positions here
        // to the '.elements' property
        (<any>element).elements = subPositions;
      }
    });
  }

  /**
   * Here once at the first loading or
   * after switching tree view in the form of a tree structure
   * and only for the calculation section get sub positions
   */
  private getSubPositionForTreeView(): void {
    zip(
      this.selectedProjectMessengerService.selectedProject.pipe(
        filter((p) => !!p),
        first()
      ),
      this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(
        filter((s) => !!s),
        first()
      ),
      this.modePageService.modePage.pipe(
        first(),
        filter((modePage) => modePage === 'calculation')
      )
    )
      .pipe(
        switchMap(([project, s]) =>
          this.positionCalculationsSubPositionsClient.getSubPositionsForAvaProjectCalculation(project.id, s.avaProjectId)
        ),
        take(1)
      )
      .subscribe((subPositions) => {
        this.subPositionsMessengerService.setSubPositions({
          avaProjectId: this.avaProjectId,
          subPositions: subPositions
        });
        this.addSubposition$.next(true);
      });
  }

  private clickedMenuButton(name: string): void {
    switch (name) {
      case 'editorRemove':
        this.editorRemove();
        break;
      case 'editorEdit':
        this.editorEdit();
        break;
      case 'editorAdd':
        this.editorAdd();
        break;
      case 'editorReorder':
        this.editorReorder();
        break;
    }
  }

  private editorRemove(): void {
    this.treeNodeService.deleteNode();
  }

  private editorAdd(): void {
    this.treeNodeService.addNode(this.selectedElement).subscribe();
  }

  private editorEdit(): void {
    this.lvEditorService.startChangeElement();
  }

  private editorReorder(): void {
    const parent: ServiceSpecificationGroupDto | ServiceSpecificationDto =
      (this.selectedElement as ServiceSpecificationGroupDto) || (this.serviceSpecification as ServiceSpecificationDto);
    this.modalService
      .openModal(LvEditorReorderModalComponent, {
        dialogType: ConfirmationType.General,
        data: {
          elements: parent.elements,
          parentContainerId: this.selectedElement?.elementType === 'ServiceSpecificationGroupDto' ? parent.id : null
        }
      })
      .afterClosed()
      .subscribe(() => {});
  }

  private getTreeRoot(treeRoot: SelectingElementsType[]): SelectingElementsType[] {
    const list: SelectingElementsType[] = [];
    treeRoot.forEach((item: SelectingElementsType) => {
      if (item.checked || item.indeterminate) {
        const obj: SelectingElementsType = { elementId: item.elementId };
        if (item.children) {
          obj.children = this.getTreeRoot(item.children);
        }
        list.push(obj);
      }
    });
    return list;
  }

  private getFlatSelectedListId(selectedElements: SelectedElement[]): void {
    selectedElements.forEach((el) => {
      if (el.children) {
        this.getFlatSelectedListId(el.children);
      } else {
        this.flatSelectedListId.push(el.elementId);
      }
    });
  }

  copyGroup(data: { groupNode: ServiceSpecificationGroupDto; isModal?: boolean }): void {
    this.copyCalculationForGroupService.copyGroup(data);
    if (data && data.isModal) {
      this.modalService
        .openModal(CopyGroupModalComponent, { dialogType: ConfirmationType.General })
        .afterClosed()
        .subscribe(() => {
          this.copyCalculationForGroupService.delMarkCopyGroup();
        });
    }
  }

  showContextMenu(event: MouseEvent, node: any): void {
    // Taken from https://stackblitz.com/edit/angular-material-context-menu-table?file=app%2Fcontext-menu-example.ts
    this.contextMenuSettingsService.setDefaultSettings(event, node as any, this.contextMenuPosition, this.treeMenuComponent.menu);
  }

  changeOnlyAddition(): void {
    this.positionTextAdditionService.setModePositionTextAddition(this.isPositionOnlyAddition);
  }
}
