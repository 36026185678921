<div class="wrapper">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="panel-title-wrap">
          <span class="panel-title">Filter {{ filterIndex + 1 }}</span>
          <button mat-icon-button color="warn" (click)="removeFilterGroup()">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="filters-group">
      <div class="filters">
        <label>Eigenschaftsgruppe</label>
        <pa-filter-selection
          label="Eigenschaftsgruppe"
          [listOfFilteringElements]="propertySetName"
          [formControl]="propertySetNameControl"
          #propertySetNameComponent
        ></pa-filter-selection>
        <label>Eigenschaft</label>
        <pa-filter-selection
          label="Eigenschaft"
          [listOfFilteringElements]="propertyName"
          [formControl]="propertyNameControl"
          #propertyNameComponent
        ></pa-filter-selection>
        <label>Wert</label>
        <pa-filter-selection
          label="Wert"
          [listOfFilteringElements]="propertyValue"
          [formControl]="propertyValueControl"
          #propertyValueComponent
        ></pa-filter-selection>
      </div>
    </div>
    <mat-checkbox color="primary" labelPosition="after" [formControl]="invertFilterTypeControl"> Filter invertieren </mat-checkbox>
  </mat-expansion-panel>
</div>
