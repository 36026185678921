import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'pa-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, MatButton, MatIconButton, MatIcon]
})
export class SwitcherComponent {
  @Input() place: string;
  @Input() wordOn: string;
  @Input() wordOff: string;
  _isSwitch: boolean;
  @Input()
  get isSwitch(): boolean {
    return this._isSwitch;
  }
  set isSwitch(val: boolean) {
    this._isSwitch = val;
    this.isSwitchChange.emit(val);
  }
  @Output() isSwitchChange = new EventEmitter<boolean>();
}
