import { Injectable } from '@angular/core';
import { ProjectFileGet } from '../../../../../generated-client/generated-client';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectedFileToUploadService {
  private selectedFileSource = new ReplaySubject<{ file: File | ProjectFileGet; correlationId: string }>(1);
  selectedFile = this.selectedFileSource.asObservable();

  setSelectedFile(selectedFile: File | ProjectFileGet, correlationId: string): void {
    this.selectedFileSource.next({ file: selectedFile, correlationId });
  }
}
