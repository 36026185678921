<div mat-dialog-content>
  <h2>Abrechnung umbennen</h2>
  <mat-form-field appearance="fill" class="field">
    <mat-label>Name</mat-label>
    <input type="text" matInput [(ngModel)]="name" />
  </mat-form-field>
</div>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close color="warn">Abbrechen</button>
  <button mat-raised-button color="primary" type="button" (click)="save()" [disabled]="!name || !isChanged()">Speichern</button>
</mat-dialog-actions>
