<h1>{{ data.priceComponentType | priceComponentType }}</h1>

<div *ngIf="!didNotFindExactMatch">
  <div fxFlex fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
  </div>
</div>

<pa-master-data-products-table
  *ngIf="didNotFindExactMatch"
  (selectItem)="selectProduct($event)"
  [filter]="data.filter"
  [priceComponentType]="data.priceComponentType"
></pa-master-data-products-table>
