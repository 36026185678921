<div class="wrapper">
  <h2>Elementinformationen</h2>
  <div class="wrapper-info">
    <ng-container *ngIf="selectedElement">
      <div class="info" (click)="copyValue('entityId')" matTooltip="Id kopieren">
        <div class="info-title">
          Id:
          <mat-icon class="info-copy info-copy-done" *ngIf="currentCopiedKey === 'entityId'; else copy">done</mat-icon>
        </div>
        <div class="info-value">
          {{ selectedElement.entityId }}
        </div>
      </div>
      <div class="info" (click)="copyValue('ifcGuid')" matTooltip="IFC Guid kopieren">
        <div class="info-title">
          IFC Guid:
          <mat-icon class="info-copy info-copy-done" *ngIf="currentCopiedKey === 'ifcGuid'; else copy">done</mat-icon>
        </div>
        <div class="info-value">
          {{ selectedElement.ifcGuid }}
        </div>
      </div>
      <div class="info" (click)="copyValue('ifcType')" matTooltip="Typ kopieren">
        <div class="info-title">
          Typ:
          <mat-icon class="info-copy info-copy-done" *ngIf="currentCopiedKey === 'ifcType'; else copy">done</mat-icon>
        </div>
        <div class="info-value">
          {{ selectedElement.ifcType }}
        </div>
      </div>
      <div class="info" (click)="copyValue('name')" matTooltip="Name kopieren">
        <div class="info-title">
          Name:
          <mat-icon class="info-copy info-copy-done" *ngIf="currentCopiedKey === 'name'; else copy">done</mat-icon>
        </div>
        <div class="info-value">
          {{ selectedElement.name }}
        </div>
      </div>
      <div class="action-buttons">
        <button mat-button (click)="accordion.openAll()">Alle Ausklappen</button>
        <button mat-button (click)="accordion.closeAll()">Alle Zuklappen</button>
      </div>
      <div>
        <mat-accordion multi="true" style="max-width: 100%">
          <ng-container *ngFor="let prop of selectedElement.propertySets; let i = index">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <b>{{ prop.propertySet }}</b>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                <table mat-table [dataSource]="prop.properties" class="mat-elevation-z8" style="width: 100%">
                  <ng-container matColumnDef="property">
                    <th mat-header-cell *matHeaderCellDef>Eigenschaft</th>
                    <td mat-cell *matCellDef="let element">{{ element.property }}</td>
                  </ng-container>

                  <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef>Wert</th>
                    <td mat-cell *matCellDef="let element">{{ element.value }}</td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr class="table-row" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
              </div>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
      </div>
    </ng-container>
  </div>
</div>
<ng-template #copy>
  <mat-icon class="info-copy" color="primary">file_copy</mat-icon>
</ng-template>
