<ng-container *ngIf="selectedElement; else noElement">
  <ng-container *ngIf="editMode || isEditableAll; else simpleElement">
    <pa-lv-editor-note-text
      [isEditableAll]="isEditableAll"
      [element]="selectedElement"
      *ngIf="selectedElement?.elementTypeDiscriminator === 'NoteTextDto'"
    >
    </pa-lv-editor-note-text>
    <pa-lv-editor-position
      [isEditableAll]="isEditableAll"
      [element]="selectedElement"
      [isModal]="isModal"
      *ngIf="selectedElement?.elementTypeDiscriminator === 'PositionDto'"
    >
    </pa-lv-editor-position>
    <pa-lv-editor-service-specification-group
      [isEditableAll]="isEditableAll"
      [element]="selectedElement"
      *ngIf="selectedElement?.elementTypeDiscriminator === 'ServiceSpecificationGroupDto'"
    >
    </pa-lv-editor-service-specification-group>
    <pa-lv-editor-execution-description
      [isEditableAll]="isEditableAll"
      [element]="selectedElement"
      *ngIf="selectedElement?.elementTypeDiscriminator === 'ExecutionDescriptionDto'"
    >
    </pa-lv-editor-execution-description>
  </ng-container>
</ng-container>

<ng-template #simpleElement>
  <ng-container *ngIf="!isEditableAll">
    <pa-note-text [element]="selectedElement" *ngIf="selectedElement?.elementTypeDiscriminator === 'NoteTextDto'"> </pa-note-text>
    <pa-execution-description [element]="selectedElement" *ngIf="selectedElement?.elementTypeDiscriminator === 'ExecutionDescriptionDto'">
    </pa-execution-description>
    <pa-service-specification-group
      [element]="selectedElement"
      *ngIf="selectedElement?.elementTypeDiscriminator === 'ServiceSpecificationGroupDto'"
    >
    </pa-service-specification-group>
    <pa-position
      *ngIf="selectedElement?.elementTypeDiscriminator === 'PositionDto'"
      [element]="selectedElement"
      [calculateUnitPrice]="calculateUnitPrice"
    >
    </pa-position>
  </ng-container>
</ng-template>

<ng-template #noElement>
  <h2>Kein Element ausgewählt.</h2>
</ng-template>
