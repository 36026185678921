import { RouterModule, Routes } from '@angular/router';

import { AddProjectComponent } from './components/add-project/add-project.component';
import { CommonModule } from '@angular/common';
import { IsAuthenticatedGuard } from '../../shared/guards/is-authenticated.guard';
import { NgModule } from '@angular/core';
import { ProjectsComponent } from './components/projects/projects.component';
import { ProjectsOverviewComponent } from './components/projects-overview/projects-overview.component';

const routes: Routes = [
  {
    path: 'projects',
    component: ProjectsOverviewComponent,
    canActivate: [IsAuthenticatedGuard],
    children: [
      {
        path: '',
        component: ProjectsComponent
      },
      {
        path: 'add-project',
        component: AddProjectComponent
      }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectsRoutingModule {}
