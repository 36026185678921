import { AfterViewInit, Component, OnInit, inject } from '@angular/core';
import { HelpWindowService, IHelpInformation } from './services/help-window.service';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HelpWindowListStructureComponent } from './components/help-window-list-structure/help-window-list-structure.component';
import { HelpWindowTextComponent } from './components/help-window-text/help-window-text.component';
import { HelpWindowTreeStructureComponent } from './components/help-window-tree-structure/help-window-tree-structure.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'pa-help-window-modal',
  templateUrl: './help-window-modal.component.html',
  styleUrls: ['./help-window-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    HelpWindowTreeStructureComponent,
    HelpWindowListStructureComponent,
    HelpWindowTextComponent,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule
  ]
})
export class HelpWindowModalComponent implements OnInit, AfterViewInit {
  filter = '';
  helpWindowService = inject(HelpWindowService);
  treeStructureData: IHelpInformation[];
  flatStructureData: IHelpInformation[];
  hasStyle = false;

  constructor() {}

  ngOnInit(): void {
    this.helpWindowService.getHelpInformation().subscribe((r) => {
      this.treeStructureData = r;
      this.flatStructureData = this.helpWindowService.getFlatStructure(this.treeStructureData);
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.hasStyle = true;
    }, 0);
  }
}
