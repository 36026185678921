import { Component, Input } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  standalone: true,
  imports: [MatProgressSpinner, FlexLayoutDirective]
})
export class SpinnerComponent {
  @Input() message = '';
}
