import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { Subject, of, switchMap, takeUntil, tap } from 'rxjs';

import { SUBPOSITION_DEFAULT_COLORS } from '@shared/constants/constants';

import { UserSettings } from 'app/generated-client/generated-client';
import { ColorPickerComponent } from 'app/shared/components/color-picker/color-picker.component';
import { UserSettingsService } from 'app/shared/services/user-settings.service';
import { NestingColorSettings } from '@shared/models';

@Component({
  selector: 'pa-choose-subpositions-color',
  templateUrl: './choose-subpositions-color.component.html',
  styleUrls: ['./choose-subpositions-color.component.scss'],
  standalone: true,
  imports: [CommonModule, ColorPickerComponent, MatButtonModule]
})
export class ChooseSubpositionsColorComponent implements OnInit, OnDestroy {
  private $destroy = new Subject<boolean>();
  colors: string[] = [];
  userSettings: UserSettings;

  constructor(private userSettingsService: UserSettingsService) {}

  ngOnInit(): void {
    this.userSettingsService.currentUserSettings
      .pipe(
        takeUntil(this.$destroy),
        switchMap((s: UserSettings) => {
          this.userSettings = s;
          if (s && s.subPositionColorSettings) {
            return of(s.subPositionColorSettings);
          } else {
            return of(this.getDefaultSubPositionColorSettings());
          }
        }),
        tap((c) => (this.userSettings.subPositionColorSettings = c))
      )
      .subscribe((subPositionColorSettings: NestingColorSettings) => {
        this.colors = [...Object.values(subPositionColorSettings)];
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  selectColor(color: string, index: number) {
    this.colors[index] = color;
    this.userSettings.subPositionColorSettings[index + 1] = color;
    this.userSettingsService.setCurrentUserSettings(this.userSettings);
  }

  resetColorSettings(): void {
    of(this.getDefaultSubPositionColorSettings()).subscribe((c) => {
      this.userSettings.subPositionColorSettings = c;
      this.colors = [...Object.values(c)];
      this.userSettingsService.setCurrentUserSettings(this.userSettings);
    });
  }

  private getDefaultSubPositionColorSettings(): { [key: number]: string } {
    return SUBPOSITION_DEFAULT_COLORS;
  }
}
