<div *ngIf="isPages && this.listPages.length" fxLayout="row" class="group">
  <button mat-raised-button color="primary" title="Erste Seite" [disabled]="isBorderPageIndex()" (click)="otherPage(false, 0)">
    <mat-icon>first_page</mat-icon>
  </button>
  <button mat-raised-button color="primary" title="Vorherige Seite" [disabled]="isBorderPageIndex()" (click)="otherPage()">
    <mat-icon>chevron_left</mat-icon>
  </button>
  <button mat-raised-button title="Blatt" class="num">{{ indexPage + 1 }}/{{ listPages?.length }}</button>
  <button mat-raised-button color="primary" title="Nächste Seite" [disabled]="isBorderPageIndex(true)" (click)="otherPage(true)">
    <mat-icon>chevron_right</mat-icon>
  </button>
  <button
    mat-raised-button
    color="primary"
    title="Letzte Seite"
    [disabled]="isBorderPageIndex(true)"
    (click)="otherPage(false, this.listPages.length - 1)"
  >
    <mat-icon>last_page</mat-icon>
  </button>
</div>
