<div mat-dialog-content class="wrapper">
  <div *ngIf="!hasLoaded || isLoading" class="spinner">
    <div fxFlex fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
    </div>
  </div>

  <div *ngIf="hasLoaded && !isLoading">
    <div *ngIf="!!itemNumberSchema?.tiers?.length; else schemaEmpty">
      <p>
        Beispiel: <span class="example">{{ example }}</span>
      </p>
      <form #saveForm="ngForm" (ngSubmit)="updateSchema()" class="setting-schema-form">
        <div fxLayoutGap="12px" fxLayoutAlign="start center" class="cover">
          <mat-form-field appearance="fill">
            <mat-label>Filler</mat-label>
            <input
              type="text"
              maxlength="1"
              matInput
              name="filler"
              [(ngModel)]="itemNumberSchema.filler"
              (ngModelChange)="isChanged = true"
              [disabled]="!isEdit"
            />
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Trennzeichen</mat-label>
            <input
              type="text"
              maxlength="1"
              matInput
              name="separator"
              [(ngModel)]="itemNumberSchema.separator"
              (ngModelChange)="isChanged = true"
              [disabled]="!isEdit"
            />
          </mat-form-field>
        </div>
        <mat-divider></mat-divider>
        <div class="list-wrapper">
          <div
            *ngFor="let tier of itemNumberSchema?.tiers; let index = index; let last = last"
            fxLayout="row"
            fxLayoutGap="10px"
            fxLayoutAlign="start center"
            class="list"
            [class.last]="last"
          >
            <span class="index">{{ index + 1 }}</span>

            <mat-form-field appearance="fill">
              <mat-label>Name</mat-label>
              <input
                type="text"
                matInput
                name="n-{{ index }}-tierName"
                [(ngModel)]="tier.tierName"
                (ngModelChange)="isChanged = true"
                [disabled]="!isEdit"
              />
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Länge</mat-label>
              <input
                type="number"
                step="1"
                min="1"
                required
                matInput
                name="n-{{ index }}-length"
                [(ngModel)]="tier.length"
                (ngModelChange)="isChanged = true"
                [disabled]="!isEdit"
              />
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Schrittweite</mat-label>
              <input
                type="number"
                step="1"
                min="1"
                matInput
                name="n-{{ index }}-increment"
                [(ngModel)]="tier.increment"
                (ngModelChange)="isChanged = true"
                [disabled]="!isEdit"
              />
            </mat-form-field>

            <button mat-icon-button *ngIf="isEdit" color="warn" type="button" (click)="removeTier(index)">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
        <div class="list-wrapper">
          <button mat-raised-button *ngIf="isEdit" type="button" (click)="newTier()" color="primary">
            <mat-icon>add</mat-icon>
            Neue Ebene
          </button>
        </div>

        <div class="buttons" fxLayout="row" fxLayoutGap="10px">
          <button mat-raised-button type="button" [disabled]="isEdit" (click)="isEdit = true">Bearbeiten</button>
          <button mat-raised-button type="button" color="warn" [disabled]="!isEdit && !isCreateMode" (click)="cancelChange()">
            Abbrechen
          </button>
          <button mat-raised-button type="submit" color="primary" [disabled]="!isChanged || !saveForm.valid">Speichern</button>
          <button mat-raised-button type="button" color="primary" [disabled]="isEdit" *ngIf="isCreateMode" (click)="createSchema()">
            Schema auswählen
          </button>
        </div>
      </form>
    </div>
    <ng-template #schemaEmpty>
      <h3>Keine OZ-Maske vorhanden</h3>
    </ng-template>
  </div>
</div>
