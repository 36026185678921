import { Injectable, NgZone } from '@angular/core';

import { ReplaySubject, take } from 'rxjs';

import { TreeCopyElementService } from 'app/areas/tree/services/tree-copy-element.service';
import { WindowType } from 'app/shared/models/window-type.models';
import { ShowedViewsService } from 'app/shared/services/showed-views.service';
import { UserSettingsService } from 'app/shared/services/user-settings.service';

import { ElectronService } from './electron.service';

@Injectable({
  providedIn: 'root'
})
export class CopyElementViewMessengerService {
  private copyElementViewVisibleSource = new ReplaySubject<boolean>(1);
  copyElementViewVisible = this.copyElementViewVisibleSource.asObservable();

  private dataFromCopyElemenetViewSource = new ReplaySubject<any>(1);
  dataFromCopyElemenetView = this.dataFromCopyElemenetViewSource.asObservable();

  constructor(
    private electronService: ElectronService,
    ngZone: NgZone,
    private userSettingsService: UserSettingsService,
    private showedViewsService: ShowedViewsService,
    private treeCopyElementService: TreeCopyElementService
  ) {
    if (electronService.isElectron) {
      electronService.ipcRenderer.on('CopyElementViewWindowVisible', () => ngZone.run(() => this.copyElementViewVisibleSource.next(true)));

      electronService.ipcRenderer.on('CopyElementViewWindowClosed', () => ngZone.run(() => this.copyElementViewVisibleSource.next(false)));

      electronService.ipcRenderer.on('DataFromCopyElement', (_, data: any) => {
        ngZone.run(() => this.dataFromCopyElemenetViewSource.next(data));
      });
    }
  }

  private showCopyElementViewWindow(): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('ShowCopyElementViewWindow');
    }
  }

  checkSettingAndOpenCopyElementView(): void {
    this.userSettingsService.currentUserSettings.pipe(take(1)).subscribe((currentUserSettings) => {
      if (!currentUserSettings.showMultiComponentsViewInMainWindow) {
        this.showCopyElementViewWindow();
      } else {
        this.showedViewsService.showedViews.pipe(take(1)).subscribe((showedViews) => {
          this.showedViewsService.setShowedViews([...showedViews, WindowType.CopyElement]);
        });
      }
    });
  }

  setOnTopCopyElementView(alwaysOnTop: boolean): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('CopyElementViewWindowOnTop', alwaysOnTop);
    }
  }

  sendDataFromCopyElement(data: any): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('SendDataFromCopyElement', data);
      return;
    }
    this.treeCopyElementService.setTreeElementForCopy(data);
  }
}
