export function getStorage<T>(name: string, defaultObj: T = {} as T): T {
  const rawStorage: string = localStorage.getItem(name);
  let storage: T;
  if (rawStorage) {
    try {
      storage = JSON.parse(rawStorage);
    } catch (e) {
      storage = JSON.parse(JSON.stringify(defaultObj));
    }
  } else {
    storage = JSON.parse(JSON.stringify(defaultObj));
  }
  return storage;
}

export function setStorage<T>(name: string, storage: T): void {
  localStorage.setItem(name, JSON.stringify(storage));
}
