import { NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

import { Subject, combineLatest, take, takeUntil } from 'rxjs';

import { ShowedTableQuantitiesType } from 'app/shared/models';
import { ProjectQuantityEstimationService } from 'app/shared/services/messengers/project-quantity-estimation.service';

import { ShowedTableQuantitiesPipe } from '../../../../shared/pipes/ui-data-display/showed-table-quantities.pipe';

@Component({
  selector: 'pa-switcher-table-qto-compact',
  templateUrl: './switcher-table-qto-compact.component.html',
  styleUrls: ['./switcher-table-qto-compact.component.scss'],
  standalone: true,
  imports: [MatSelect, FormsModule, NgFor, MatOption, ShowedTableQuantitiesPipe]
})
export class SwitcherTableQtoCompactComponent implements OnInit {
  selectedPositionType: ShowedTableQuantitiesType;
  positionTypes = Object.values(ShowedTableQuantitiesType);
  canChange = false;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(private projectQuantityEstimationService: ProjectQuantityEstimationService) {}

  ngOnInit(): void {
    this.projectQuantityEstimationService.mainShowedTableQuantities
      .pipe(takeUntil(this.$destroy), take(1))
      .subscribe((e) => (this.selectedPositionType = e));

    combineLatest([
      this.projectQuantityEstimationService.currentProjectHasQuantityCalculation,
      this.projectQuantityEstimationService.currentProjectHasAssumedQuantities
    ])
      .pipe(takeUntil(this.$destroy))
      .subscribe(([hasEstimation, hasAssumedQuantities]) => (this.canChange = hasEstimation && hasAssumedQuantities));
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  changeType(type): void {
    this.projectQuantityEstimationService.setMainShowedTable(type);
  }
}
