import { DOCUMENT, NgStyle, NgIf } from '@angular/common';
import { Component, ElementRef, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { RouterOutlet } from '@angular/router';

import { Subject, fromEvent, take, takeUntil } from 'rxjs';

import { MainRect } from '@shared/models';
import { ShowedViewsService } from '@shared/services/showed-views.service';
import { setStorage } from '@shared/utilities/storage';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';

import { GeneralHeaderComponent } from '../general-header/general-header.component';

@Component({
  selector: 'pa-main-frame',
  templateUrl: './main-frame.component.html',
  styleUrls: ['./main-frame.component.scss'],
  standalone: true,
  imports: [NgStyle, FlexLayoutDirective, NgIf, MatButton, MatIcon, GeneralHeaderComponent, RouterOutlet, MatDialogModule]
})
export class MainFrameComponent implements OnInit, OnDestroy {
  @ViewChild('wrapElement') wrapElement: ElementRef;
  draggingCorner: boolean;
  x: number;
  y: number;
  px: number;
  py: number;
  width: number;
  height: number;
  currentFunctionName: string;
  savedWindow: any = null;
  isFullSize = false;
  private $destroy: Subject<boolean> = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<MainFrameComponent>,
    @Inject(DOCUMENT) private document: Document,
    private ngZone: NgZone,
    private showedViewsService: ShowedViewsService
  ) {}

  ngOnInit(): void {
    this.showedViewsService.setShowedMain(true);
    this.width = this.data?.rect?.width;
    this.height = this.data?.rect?.height;
    this.x = this.data?.rect?.left;
    this.y = this.data?.rect?.top;
    this.saveFrame();

    this.ngZone.runOutsideAngular(() => {
      fromEvent(this.document, 'mousemove')
        .pipe(takeUntil(this.$destroy))
        .subscribe((event: MouseEvent) => {
          this.onCornerMove(event);
        });
    });
  }

  ngOnDestroy(): void {
    this.showedViewsService.setShowedMain(false);
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  close(): void {
    this.matDialogRef.close();
  }

  startResize(event: MouseEvent, fnName: string): void {
    if (this.isFullSize) {
      return;
    }
    this.currentFunctionName = fnName;
    this.draggingCorner = true;
    this.px = event.clientX;
    this.py = event.clientY;
    const rect = (this.wrapElement.nativeElement as HTMLDivElement).closest('.mat-mdc-dialog-container').getBoundingClientRect();
    this.x = rect.left;
    this.y = rect.top;

    fromEvent(this.document, 'mouseup')
      .pipe(takeUntil(this.$destroy), take(1))
      .subscribe((event: MouseEvent) => {
        this.stopResize(event);
      });
  }

  fnList = {
    bottomRight: (offsetX: number, offsetY: number) => {
      this.width += offsetX;
      this.height += offsetY;
    },
    right: (offsetX: number, _offsetY: number) => {
      this.width += offsetX;
    },
    left: (offsetX: number, _offsetY: number) => {
      this.px += offsetX;
      this.x += offsetX;
      this.width -= offsetX;
      this.matDialogRef.updatePosition({ top: `${this.y}px`, left: `${this.x}px` });
    },
    move: (offsetX: number, offsetY: number) => {
      this.px += offsetX;
      this.x += offsetX;
      this.py += offsetY;
      this.y += offsetY;
      this.notAllowOutside();
      this.matDialogRef.updatePosition({ top: `${this.y}px`, left: `${this.x}px` });
    },
    top: (_offsetX: number, offsetY: number) => {
      this.py += offsetY;
      this.y += offsetY;
      this.height -= offsetY;
      this.notAllowOutside();
      this.matDialogRef.updatePosition({ top: `${this.y}px`, left: `${this.x}px` });
    },
    bottom: (_offsetX: number, offsetY: number) => {
      this.height += offsetY;
    }
  };

  notAllowOutside(): void {
    this.y = this.y < 0 ? 0 : this.y;
  }

  stopResize(_event: MouseEvent) {
    this.draggingCorner = false;
    this.saveFrame();
  }

  overlayUp(): void {
    const container = (this.wrapElement.nativeElement as HTMLDivElement).closest('.cdk-overlay-container') as HTMLDivElement;
    const count = container.querySelectorAll('.pane-window');

    if (count.length > 1) {
      const list = container.querySelectorAll('.cdk-global-overlay-wrapper');
      list.forEach((elem) => {
        if ((elem as HTMLDivElement).style.zIndex === '1000') {
          (elem as HTMLDivElement).style.zIndex = '999';
        } else {
          (elem as HTMLDivElement).style.zIndex = '998';
        }
      });
      const el = (this.wrapElement.nativeElement as HTMLDivElement).closest('.cdk-global-overlay-wrapper') as HTMLDivElement;
      el.style.zIndex = '1000';
    }

    this.generateResizeEvent();
  }

  onCornerMove(event: MouseEvent) {
    if (!this.draggingCorner) {
      return;
    }

    this.ngZone.run(() => {
      const offsetX = event.clientX - this.px;
      const offsetY = event.clientY - this.py;
      this.fnList[this.currentFunctionName](offsetX, offsetY);
      this.px = event.clientX;
      this.py = event.clientY;
    });
  }

  fullSize(): void {
    this.savedWindow = {
      y: this.y,
      x: this.x,
      width: this.width,
      height: this.height
    };
    const area = this.document.querySelector('.main-window').getBoundingClientRect();

    this.x = area.x;
    this.y = area.y;
    this.width = area.width - 10;
    this.height = area.height - 10;
    this.matDialogRef.updatePosition({ top: `${this.y}px`, left: `${this.x}px` });
    this.isFullSize = true;
    setTimeout(() => this.generateResizeEvent(), 500);
  }

  middleSize(): void {
    this.x = this.savedWindow.x;
    this.y = this.savedWindow.y;
    this.width = this.savedWindow.width;
    this.height = this.savedWindow.height;
    this.matDialogRef.updatePosition({ top: `${this.y}px`, left: `${this.x}px` });
    this.isFullSize = false;
    setTimeout(() => this.generateResizeEvent(), 500);
  }

  saveFrame(): void {
    const rect = { left: this.x, top: this.y, width: this.width, height: this.height } as MainRect;
    setStorage<MainRect>('MAIN_FRAME_RECT', rect);
  }

  generateResizeEvent(): void {
    const resizeEvent = new Event('resize');
    window.dispatchEvent(resizeEvent);
  }
}
