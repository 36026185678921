<p>Hier findest Du alle Dateien, die in der Anwendung gespeichert sind.</p>
<mat-form-field class="full-width">
  <mat-label>Suche</mat-label>
  <input matInput [ngModel]="filter" (ngModelChange)="filesService.onFilter($event)" />
</mat-form-field>
<table mat-table [dataSource]="filesService" matSort (matSortChange)="filesService.onSort($event)">
  <ng-container matColumnDef="fileName">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let row">
      {{ row.fileName }}
    </td>
  </ng-container>
  <ng-container matColumnDef="containerName">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Container</th>
    <td mat-cell *matCellDef="let row">{{ row.containerName }}</td>
  </ng-container>
  <ng-container matColumnDef="createdAtUtc">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Erstellt</th>
    <td mat-cell *matCellDef="let row">
      {{ row.createdAtUtc | date: 'dd.MM.yyyy HH:mm' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="sizeInBytes">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Größe</th>
    <td mat-cell *matCellDef="let row">
      {{ row.sizeInBytes | fileSize }}
    </td>
  </ng-container>
  <ng-container matColumnDef=" ">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let row">
      <a mat-raised-button (click)="downloadFile(row)">Speichern</a>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="['fileName', 'containerName', 'createdAtUtc', 'sizeInBytes', ' ']"></tr>
  <tr mat-row *matRowDef="let row; columns: ['fileName', 'containerName', 'createdAtUtc', 'sizeInBytes', ' ']"></tr>
</table>
<pa-upper-paginator
  [pageSizeOptions]="[5, 10, 25, 100]"
  [upperPaginated]="filesService.paginationResult | async"
  [upperService]="filesService"
  (page)="filesService.onPage($event)"
>
</pa-upper-paginator>
