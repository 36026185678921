<h1 mat-dialog-title>Inhalt</h1>
<div mat-dialog-content>
  <button mat-raised-button (click)="selectSection('general')" color="primary">Allgemein</button>
  <button mat-raised-button (click)="selectSection('login')" color="primary">Login & Account</button>
  <button mat-raised-button (click)="selectSection('projects')" color="primary">Projekte</button>
  <button mat-raised-button (click)="selectSection('add-project')" color="primary">Projekt Erstellen</button>
  <button mat-raised-button (click)="selectSection('service-specifications')" color="primary">Leistungsverzeichnisse</button>
  <button mat-raised-button (click)="selectSection('calculation')" color="primary">Kalkulation</button>
  <button mat-raised-button (click)="selectSection('lv-editor')" color="primary">LV Editor</button>
  <button mat-raised-button (click)="selectSection('invoices')" color="primary">Abrechnung</button>
  <button mat-raised-button (click)="selectSection('installation')" color="primary">Entwickler & IT</button>
  <button mat-raised-button (click)="cancel()" color="warn">Abbrechen</button>
</div>
