import { NgFor } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTabNav, MatTabLink } from '@angular/material/tabs';
import { NavigationEnd, Router, RouterLinkActive, RouterLink, RouterOutlet } from '@angular/router';

import { Subject } from 'rxjs';
import { filter, takeUntil, takeWhile } from 'rxjs/operators';

import { AdditionSubscriptionService } from 'app/shared/services/addition-subscription.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

@Component({
  selector: 'pa-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  standalone: true,
  imports: [MatTabNav, NgFor, MatTabLink, RouterLinkActive, RouterLink, RouterOutlet]
})
export class SettingsComponent implements OnInit, OnDestroy {
  currentRoute: string;
  projectId: string;
  tabs = [
    { path: 'setting-schema', viewValue: 'OZ Maske' },
    { path: 'tax-rates', viewValue: 'Mehrwertsteuersatz' }
  ];
  $destroy: Subject<boolean> = new Subject<boolean>();
  constructor(
    private additionSubscriptionService: AdditionSubscriptionService,
    public router: Router,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event: NavigationEnd) => event instanceof NavigationEnd),
        takeWhile((event: NavigationEnd) => event.id === 1),
        takeUntil(this.$destroy)
      )
      .subscribe((event: NavigationEnd) => {
        const index = this.tabs.findIndex((v) => event.url.includes(v.path) === true);
        this.currentRoute = this.tabs[index].path;
      });

    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        takeUntil(this.$destroy),
        filter((s) => !!s)
      )
      .subscribe((s) => {
        this.projectId = s.parentProjectId;
      });
    this.additionSubscriptionService.setSelectedAdditionSub('settings/additions');
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }
}
