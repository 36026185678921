import { Injectable, OnDestroy } from '@angular/core';

import { ReplaySubject, Subject, combineLatest, take } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

import { CalculationsChecksClient } from '../../../generated-client/generated-client';

@Injectable({
  providedIn: 'root'
})
export class CalculationFixedPriceService implements OnDestroy {
  private $destroy: Subject<boolean> = new Subject<boolean>();
  private positionFixedPriceSource = new ReplaySubject<{
    positionId: string;
    fixedPrice?: number;
  }>(1);
  positionFixedPrice = this.positionFixedPriceSource.asObservable();

  private calculationFixedPriceSource = new Subject<{
    positionId: string;
    fixedPrice?: number;
  }>();
  calculationFixedPrice = this.calculationFixedPriceSource.asObservable();

  private listOfItemsWithFixedPriceSource = new ReplaySubject<{ [positionId: string]: boolean }>();
  listOfItemsWithFixedPrice = this.listOfItemsWithFixedPriceSource.asObservable();

  constructor(
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private calculationsChecksClient: CalculationsChecksClient
  ) {
    this.reloadListOfItemsWithFixedPrice();
  }
  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  setFixedPriceForPosition(positionId: string, fixedPrice?: number): void {
    this.positionFixedPriceSource.next({
      positionId: positionId,
      fixedPrice: fixedPrice
    });
  }

  setFixedPriceForCalculation(positionId: string, fixedPrice: number): void {
    this.calculationFixedPriceSource.next({
      positionId: positionId,
      fixedPrice: fixedPrice
    });
    this.setFixedPriceForPosition(positionId, fixedPrice);
    this.reloadListOfItemsWithFixedPrice();
  }

  reloadListOfItemsWithFixedPrice(projectId?: string, avaProjectId?: string): void {
    /**if we need to update the list for a project different from the one opened in the main window,
     *  we can pass the necessary IDs. In this case,
     *  we also need to add the service to the component providers */
    const request =
      !projectId && !avaProjectId
        ? combineLatest([
            this.selectedProjectMessengerService.selectedProject,
            this.selectedSpecificationMessengerService.selectedServiceSpecification
          ]).pipe(
            filter((arr) => !!arr[0] && !!arr[1]),
            take(1),
            switchMap(([p, s]) => {
              return this.calculationsChecksClient.getPositionsWithFixedPrices(p.id, s.avaProjectId);
            })
          )
        : this.calculationsChecksClient.getPositionsWithFixedPrices(projectId, avaProjectId);

    request.subscribe((listOfItemsWithFixedPrice) => {
      this.listOfItemsWithFixedPriceSource.next(listOfItemsWithFixedPrice?.positionsWithFixedPricesById);
    });
  }
}
