<pa-note-text [element]="selectedElement" *ngIf="selectedElement?.elementTypeDiscriminator === 'NoteTextDto'"></pa-note-text>

<ng-container
  *ngIf="
    selectedElement?.elementTypeDiscriminator === 'PositionDto' ||
    selectedElement?.elementTypeDiscriminator === 'ServiceSpecificationGroupDto'
  "
>
  <pa-position
    [element]="selectedElement"
    *ngIf="!(modePage | includes: 'invoice')"
    [elementList]="elementList"
    [addition]="[this.projectId, this.serviceSpecificationId]"
    [isInnerWindow]="isInnerWindow"
    [calculateUnitPrice]="calculateUnitPrice"
  >
    <div groupTotalsTable *ngIf="!isInnerWindow" style="padding-top: 10px">
      <mat-checkbox
        *ngIf="selectedElement?.elementTypeDiscriminator === 'ServiceSpecificationGroupDto'"
        [(ngModel)]="includePositionsWithoutTotalPrice"
        >Positionen ohne Gesamtpreis einbeziehen</mat-checkbox
      >
      <pa-group-totals-table
        *ngIf="selectedElement?.elementTypeDiscriminator === 'ServiceSpecificationGroupDto'"
        [totalsData]="groupTotals"
        [isLoaded]="hasLoadedGroupTotals"
      ></pa-group-totals-table>
    </div>
    <div calculation *ngIf="!isInnerWindow">
      <pa-calculation
        *ngIf="selectedElement?.elementTypeDiscriminator === 'PositionDto'"
        [positionCalculationData]="currentPositionCalculation"
        [calcEntries]="calcEntries"
        (save)="changeTable($event)"
        [methods]="methods"
        [isReadOnly]="positionCalculationBlocked || isReadOnly"
        [positionId]="selectedElement?.id"
        [currentTotals]="currentTotals"
      >
      </pa-calculation>
    </div>
  </pa-position>

  <div *ngIf="isInnerWindow && modePage && modePage | includes: 'invoice'">
    <mat-tab-group (selectedTabChange)="changeTabs($event)">
      <mat-tab label="Element">
        <ng-template matTabContent>
          <pa-position
            *ngIf="!isShowCalculationTab"
            [element]="selectedElement"
            [addition]="[this.projectId, this.serviceSpecificationId]"
            [isInnerWindow]="isInnerWindow"
          ></pa-position>
        </ng-template>
      </mat-tab>
      <mat-tab label="Kalkulation">
        <ng-template matTabContent>
          <div class="table-wrap">
            <pa-calculation
              *ngIf="isShowCalculationTab"
              [isInnerWindow]="true"
              [positionCalculationData]="currentPositionCalculation"
              [isReadOnly]="true"
            >
            </pa-calculation>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-container>

<pa-execution-description
  [element]="selectedElement"
  *ngIf="selectedElement?.elementTypeDiscriminator === 'ExecutionDescriptionDto'"
></pa-execution-description>

<h2 *ngIf="!selectedElement">Kein Element ausgewählt.</h2>
