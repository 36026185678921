<h2 mat-dialog-title>{{ showDelete ? 'Mehrwertsteuersatz Bearbeiten' : 'Neuer Mehrwertsteuersatz' }}</h2>
<form mat-dialog-content class="contact-form" [formGroup]="templatesForm" (ngSubmit)="saveTaxRate()">
  <div fxLayout="row" fxLayoutGap="12px">
    <mat-form-field appearance="fill">
      <mat-label>Land</mat-label>
      <input required matInput formControlName="country" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Ländercode</mat-label>
      <input required matInput formControlName="countryCode" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Satz</mat-label>
      <input required type="number" min="0" matInput formControlName="rate" />
    </mat-form-field>
  </div>

  <div class="buttons" fxLayoutGap="12px">
    <button mat-raised-button color="warn" type="button" (click)="cancel()">Abbrechen</button>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!templatesForm.valid || templatesForm.controls['rate'].value < 0 || !isChanges()"
    >
      Speichern
    </button>
    <button mat-raised-button color="warn" type="button" (click)="deleteTaxRate()" *ngIf="showDelete">Löschen</button>
  </div>
</form>
