import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';

import { CalculationsChecksClient, ProjectExportsClient } from '../../../../../../generated-client/generated-client';
import { AvaNotificationsService } from '../../../../../../shared/services/ava-notifications.service';
import { FileSaverService } from '../../../../../../shared/services/file-saver.service';
import { AvaProjectsService } from '../../../../../../shared/services/lightquery/ava-projects.service';
import { SelectedProjectMessengerService } from '../../../../../../shared/services/messengers/selected-project-messenger.service';

import { CopyProjectComponent } from '../copy-project/copy-project.component';

@Component({
  selector: 'pa-copy-project-overview',
  templateUrl: './copy-project-overview.component.html',
  styleUrls: ['./copy-project-overview.component.scss'],
  standalone: true,
  imports: [MatButton, NgIf, MatProgressSpinner]
})
export class CopyProjectOverviewComponent {
  constructor(
    private matDialog: MatDialog,
    private fileSaverService: FileSaverService,
    private avaNotificationsService: AvaNotificationsService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private projectExportsClient: ProjectExportsClient,
    private avaProjectsService: AvaProjectsService,
    private calculationsChecksClient: CalculationsChecksClient
  ) {}

  exportRunning = false;

  prepareProjectCopy(): void {
    this.matDialog.open(CopyProjectComponent);
  }

  exportProject(): void {
    this.avaNotificationsService.info('Projektexport gestartet, bitte warten...');
    this.exportRunning = true;
    this.selectedProjectMessengerService.selectedProject.pipe(first()).subscribe((p) => {
      this.projectExportsClient.exportProjectToFile(p.id).subscribe(
        (projectFileResponse) => {
          this.exportRunning = false;
          this.avaNotificationsService.success('Sicherungskopie erstellt.');
          this.fileSaverService.saveAs(projectFileResponse);
        },
        () => {
          this.exportRunning = false;
          this.avaNotificationsService.error('Fehler beim Erstellen der Sicherungskopie');
        }
      );
    });
  }

  recalculateProject(): void {
    this.avaNotificationsService.info('Neukalkulation gestartet, bitte warten...');
    this.exportRunning = true;
    this.selectedProjectMessengerService.selectedProject.pipe(first()).subscribe((p) => {
      this.avaProjectsService.getAll().subscribe((avaProjects) => {
        const recalculationCalls = avaProjects.map((ap) => this.calculationsChecksClient.recalculateCalculationsInAvaProject(p.id, ap.id));
        combineLatest(recalculationCalls).subscribe({
          next: () => {
            this.avaNotificationsService.success('Neukalkulation erfolgreich.');
          },
          error: () => {
            this.avaNotificationsService.error('Fehler bei der Neukalkulation');
          },
          complete: () => {
            this.exportRunning = false;
          }
        });
      });
    });
  }
}
