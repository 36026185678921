import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel, MatPrefix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

import { ItemNumberDto } from 'app/generated-client/generated-client';

@Component({
  selector: 'pa-item-number-editing',
  templateUrl: './item-number-editing.component.html',
  styleUrls: ['./item-number-editing.component.scss'],
  standalone: true,
  imports: [MatFormField, NgIf, MatLabel, MatPrefix, MatInput, FormsModule]
})
export class ItemNumberEditingComponent implements OnInit {
  private _itemNumber: ItemNumberDto;
  @Input() label: string;
  @Input() set itemNumber(value: ItemNumberDto) {
    this._itemNumber = value;
    this.locallyStoredItemNumber = JSON.parse(JSON.stringify(value));
  }
  @Output() itemNumberChange = new EventEmitter<ItemNumberDto>();
  get itemNumber(): ItemNumberDto {
    return this._itemNumber;
  }
  locallyStoredItemNumber: ItemNumberDto;
  prefix: string;
  private _sufix: string;
  set sufix(value: string) {
    if (value !== this._sufix) {
      this._sufix = value;
      this.updateItemNumber();
    }
  }
  get sufix(): string {
    return this._sufix;
  }
  constructor() {}
  private hasLoaded = false;

  ngOnInit(): void {
    if (this.itemNumber) {
      const separator = this.itemNumber.itemNumberSchema?.separator ?? '.';
      this.prefix = this.itemNumber.identifiers.slice(0, this.itemNumber.identifiers.length - 1).join(separator) + separator;
      const trimStartRegex = new RegExp('^' + (separator === '.' ? '\\.' : separator) + '*');
      this.prefix = this.prefix.replace(trimStartRegex, '');
      this._sufix = this.itemNumber.identifiers[this.itemNumber.identifiers.length - 1];
    }

    this.hasLoaded = true;
  }

  private updateItemNumber(): void {
    if (!this.hasLoaded) {
      return;
    }

    this.locallyStoredItemNumber.identifiers[this.itemNumber.identifiers.length - 1] = this.sufix;
    this.locallyStoredItemNumber.stringRepresentation = this.prefix + this.sufix;
    this.itemNumberChange.emit(this.locallyStoredItemNumber);
  }
}
