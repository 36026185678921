<h2>Preisanfrage</h2>
<div fxLayout="row" fxLayoutGap="12px" class="buttons">
  <button mat-raised-button color="primary" type="button" (click)="back()">Zurück</button>
</div>
<div class="main">
  <as-split fxFlexFill #asSplitRightRef (dragEnd)="onDragEnd($event.sizes)">
    <as-split-area [size]="20" [minSize]="10">
      <div *ngIf="formInquiry" class="forms">
        <form [formGroup]="formInquiry">
          <div fxLayout="column" class="forms-wrap">
            <mat-form-field appearance="fill" *ngIf="formInquiry.value.createdAtUtc">
              <mat-label>Erstellt</mat-label>
              <input matInput [matDatepicker]="pickerCreate" formControlName="createdAtUtc" readonly />
              <mat-datepicker-toggle matSuffix [for]="pickerCreate"></mat-datepicker-toggle>
              <mat-datepicker #pickerCreate disabled></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Name</mat-label>
              <input type="text" matInput formControlName="name" readonly />
            </mat-form-field>
          </div>
        </form>

        <div class="tree" *ngIf="showedAvaProject">
          <pa-main-tree [withoutSplitArea]="true" [showedAvaProject]="showedAvaProject"></pa-main-tree>
        </div>
      </div>
    </as-split-area>
    <as-split-area [size]="80">
      <div class="relative-parent" fxFlexFill>
        <mat-tab-group animationDuration="0ms" fxFlexFill>
          <mat-tab label="LV-Ansicht" #tab1>
            <pa-service-specification-table-view
              *ngIf="tab1.isActive"
              [showedAvaProject]="showedAvaProject"
              [elementSelectionId]="elementSelectionId"
              [showAssumedQuantities]="showAssumedQuantities"
            ></pa-service-specification-table-view>
          </mat-tab>
          <mat-tab label="Anfragen" #tab2>
            <pa-price-inquiries-request-table
              #requestTable
              [selectedPriceInquiry]="selectedPriceInquiry"
              *ngIf="priceInquiryId"
              fxFlexFill
            ></pa-price-inquiries-request-table>
          </mat-tab>
          <mat-tab label="Preisspiegel" #tab3>
            <pa-subcontractors-price-level
              [showAssumedQuantities]="showAssumedQuantities"
              (accepted)="acceptedPriceInquiry()"
              *ngIf="tab3.isActive"
            ></pa-subcontractors-price-level>
          </mat-tab>
        </mat-tab-group>
      </div>
    </as-split-area>
  </as-split>
</div>
