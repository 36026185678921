import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { formatDate } from '@angular/common';

@Injectable()
export class PickerDateAdapter extends NativeDateAdapter {
  format(date: Date): string {
    return formatDate(date, 'dd.MM.YYYY', 'de');
  }
}
