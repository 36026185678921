import { FilterDialogType } from '../models';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpecificationSourceService {
  private loadAllDataSource = new ReplaySubject<FilterDialogType[]>(1);
  public loadAllData = this.loadAllDataSource.asObservable();

  constructor() {}

  public setAllSpecif(resp: FilterDialogType[]): void {
    this.loadAllDataSource.next(resp);
  }
}
