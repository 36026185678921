import { Injectable } from '@angular/core';
import { PersonGet } from '../../../generated-client/generated-client';
import { HttpClient } from '@angular/common/http';
import { SelectedContactMessengerService } from 'app/areas/contacts/services/selected-contact-messenger.service';
import { PaginationMainService } from './main/pagination-main.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PersonsService extends PaginationMainService<PersonGet> {
  constructor(protected http: HttpClient, private selectedContactMessengerService: SelectedContactMessengerService) {
    super(http);
    this.selectedContactMessengerService.selectedContact.pipe(takeUntil(this.$destroy)).subscribe(c => {
      if (c) {
        this.beginBaseUrl(`/api/contacts/${c.id}/persons`);
      }
    });
  }

  getPerson(contactId: string): PersonGet | undefined {
    return this.getItemById(contactId);
  }
}
