import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FailRequestCheckService {
  constructor() {}

  checkRequest(requestsArray: { url: string; isError: boolean; statusCode: number }[]): boolean {
    const request = requestsArray
      .filter(item => {
        try {
          const url = new URL(item.url);
          if (url.pathname.startsWith('/identity')) {
            // We don't want to show failed requests for errors during login or
            // token refresh, they aren't considered application errors
            return false;
          }
        } catch {
          /* Ignoring failures here */
        }

        return true;
      })
      .find(item => item.isError);

    return !!request?.isError;
  }
}
