<h2 *ngIf="person && person['id']">Person Bearbeiten</h2>
<h2 *ngIf="person && !person['id']">Person Erstellen</h2>

<form class="contact-form" [formGroup]="personForm" (ngSubmit)="editPerson()" *ngIf="person">
  <mat-form-field appearance="fill">
    <mat-label>Name</mat-label>
    <input matInput required type="text" name="name" formControlName="name" />
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Notizen</mat-label>
    <textarea matInput type="text" name="notes" formControlName="notes"></textarea>
  </mat-form-field>

  <div class="button-actions">
    <button mat-button mat-raised-button color="primary" type="submit" [disabled]="personForm.invalid || requestEnRoute">Speichern</button>
    <button mat-button mat-raised-button color="warn" type="button" (click)="dismiss()">Abbrechen</button>
  </div>
</form>
