import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { getAppConfig } from '../../app-config-accessor';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Injectable({
  providedIn: 'root'
})
export class TelemetryService implements OnDestroy {
  private appInsights: ApplicationInsights;
  $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(private router: Router) {
    const instrumentationKey = getAppConfig().applicationInsightsKey;
    if (instrumentationKey) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: instrumentationKey
        }
      });
      this.appInsights.loadAppInsights();
      this.router.events.pipe(takeUntil(this.$destroy)).subscribe((e) => {
        if (e instanceof NavigationEnd) {
          this.logPageView(e.url);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  logPageView(url: string): void {
    this.appInsights?.trackPageView({
      uri: url
    });
  }

  handleError(error: Error): void {
    this.logError(error);
    throw error;
  }

  logErrorMessage(message: string, name: string): void {
    this.appInsights?.trackException({
      exception: new Error(message),
      properties: {
        name: name
      }
    });
  }

  logError(error: Error, serverError?: string): void {
    this.appInsights?.trackException({
      exception: error,
      properties: {
        serverError: serverError
      }
    });
  }
}
