<h2 mat-dialog-title>{{ showDetail ? 'Textvorlage Bearbeiten' : 'Neue Textvorlage' }}</h2>
<form mat-dialog-content class="contact-form" [formGroup]="templatesForm" (ngSubmit)="saveTextItem()">
  <div fxLayout="row" fxLayoutGap="12px">
    <mat-form-field appearance="fill">
      <mat-label>Name</mat-label>
      <input required matInput formControlName="name" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Typ</mat-label>
      <mat-select formControlName="templateType">
        <mat-option *ngFor="let type of templateTypes" [value]="type">{{ type | textTemplateType }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="project-select">
      <mat-label>Firma</mat-label>
      <mat-select formControlName="companyId">
        <mat-option *ngFor="let item of listCompany" [value]="item.id">{{ item.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="detail">
    <mat-checkbox formControlName="isDefault">Als Standard verwenden?</mat-checkbox>
    <dangl-tiny-mce required tinyMceLanguageCode="de" formControlName="htmlText"></dangl-tiny-mce>
  </div>

  <div class="buttons" fxLayoutGap="12px">
    <button mat-raised-button color="warn" type="button" (click)="cancel()">Abbrechen</button>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!templatesForm.valid || !templatesForm.controls['companyId'].value || !isChanges()"
    >
      Speichern
    </button>
    <button mat-raised-button color="warn" type="button" (click)="deleteTextItem()" *ngIf="showDetail">Löschen</button>
  </div>
</form>
