import { NgIf, AsyncPipe, DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatList, MatListItem } from '@angular/material/list';

import { EMPTY, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { ElectronService } from '@shared/services/electron/electron.service';

import { ProjectFileService } from '@project/components/project-files/services/project-file.service';

import {
  FileResponse,
  FilesClient,
  ProjectFileFolderGet,
  ProjectFileGet,
  ProjectFilesClient,
  ProjectGet
} from 'app/generated-client/generated-client';
import { UploadFileSettings } from 'app/shared/components/upload-file/upload-file.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { FileSaverService } from 'app/shared/services/file-saver.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';

import { UploadFileComponent } from '../../../../../../shared/components/upload-file/upload-file.component';
import { FileSizePipe } from '../../../../../../shared/pipes/file-size.pipe';
import { ImgSrcPipe } from '../../../../../../shared/pipes/img-src.pipe';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';
import { SelectedFileToUploadService } from '../../services/selected-file-to-upload.service';

import { FileModalToRemoveComponent } from '../file-modal-to-remove/file-modal-to-remove.component';

@Component({
  selector: 'pa-project-files-view',
  templateUrl: './project-files-view.component.html',
  styleUrls: ['./project-files-view.component.scss'],
  standalone: true,
  imports: [NgIf, MatList, MatListItem, FlexLayoutDirective, MatButton, UploadFileComponent, AsyncPipe, DatePipe, FileSizePipe, ImgSrcPipe]
})
export class ProjectFilesViewComponent implements OnInit, OnDestroy {
  @Input() modalView: string;
  @Input() correlationId: string;
  @Input() extensions: string[];
  uploadFileSettings: UploadFileSettings = {
    label: 'Datei zum Hochladen hierher ziehen',
    isMultipleSelection: true
  };
  projectFile: ProjectFileGet;
  requestEnRoute = false;
  showUploadFilesZone = true;
  imageFileId: string;
  selectedProjectFolder: ProjectFileFolderGet;
  private selectedProject: ProjectGet;
  $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private filesClient: FilesClient,
    private modalService: ModalService,
    private notificationsService: AvaNotificationsService,
    private projectFilesClient: ProjectFilesClient,
    private selectedFileToUploadService: SelectedFileToUploadService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private fileSaverService: FileSaverService,
    private projectFileService: ProjectFileService,
    private electronService: ElectronService
  ) {}

  ngOnInit(): void {
    if (this.extensions?.length) {
      this.uploadFileSettings.accept = this.extensions.join();
    }
    this.selectedProjectMessengerService.selectedProject
      .pipe(takeUntil(this.$destroy))
      .subscribe((project: ProjectGet) => (this.selectedProject = project));
    this.projectFileService.selectedProjFile.pipe(takeUntil(this.$destroy)).subscribe((file: ProjectFileGet) => {
      this.projectFile = file;
      if (this.projectFile && this.projectFile.file && this.projectFile.file.mimeType && /^image.*/.test(this.projectFile.file.mimeType)) {
        this.imageFileId = this.projectFile.fileId;
      } else {
        this.imageFileId = null;
      }
    });

    this.projectFileService.selectedProjFolder.pipe(takeUntil(this.$destroy)).subscribe((folder: ProjectFileFolderGet) => {
      this.selectedProjectFolder = folder;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  onSelect(addedFiles: File[]): void {
    if (addedFiles && addedFiles.length) {
      for (let i = 0; i < addedFiles.length; i++) {
        const addedFile = addedFiles[i];

        /** here also need to check whether the application works as an electron,
         * because in the web version the browser does not have access to the path to the file*/
        if (addedFile.size === 0 || (!addedFile?.path && this.electronService.isElectron)) {
          continue;
        }

        const file = {
          data: addedFile,
          fileName: addedFile.name
        };
        this.selectedFileToUploadService.setSelectedFile(addedFile, this.correlationId);

        this.projectFilesClient
          .uploadProjectFile(this.selectedProject.id, this.selectedProjectFolder.id, file)
          .pipe(takeUntil(this.$destroy))
          .subscribe({
            next: (f: ProjectFileGet) => {
              this.projectFileService.add({
                id: f.id,
                name: f.file.fileName,
                isFolder: false,
                parent: f.folderId,
                fileId: f.file.id,
                file: f.file,
                canDelete: f.canDelete
              });
              this.projectFileService.queryInFolder(this.selectedProjectFolder.id);
              this.notificationsService.success('Datei gespeichert');
              this.selectFile(f);
            },
            error: () => this.notificationsService.error('Datei konnte nicht gespeichert werden')
          });
      }
    }
  }

  removeFile(projectFile: ProjectFileGet): void {
    this.modalService
      .openModal(FileModalToRemoveComponent, { dialogType: ConfirmationType.General })
      .afterClosed()
      .pipe(
        switchMap((response) => {
          if (response) {
            return this.projectFilesClient.deleteProjectFile(this.selectedProject.id, projectFile.id);
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe({
        next: () => {
          this.projectFileService.delete(projectFile.id);
          this.projectFileService.queryInFolder(projectFile.folderId);
          this.projectFileService.setSelectedProjFile(null);
          this.notificationsService.success('Datei gelöscht');
        },
        error: () => this.notificationsService.error('Datei konnte nicht gelöscht werden')
      });
  }

  getBlobFile(file: ProjectFileGet): void {
    this.requestEnRoute = true;
    this.filesClient.downloadFile(file.fileId).subscribe({
      next: (fileResponse: FileResponse) => {
        this.requestEnRoute = false;
        this.fileSaverService.saveAs(fileResponse);
      },
      error: () => {
        this.requestEnRoute = false;
        this.notificationsService.error('Datei kann nicht abgerufen werden');
      }
    });
  }

  addImgToTable(projectFile: ProjectFileGet): void {
    this.selectedFileToUploadService.setSelectedFile(projectFile, this.correlationId);
  }

  selectFile(selectedProjFile: ProjectFileGet): void {
    this.projectFileService.setSelectedProjFile(selectedProjFile);
  }
}
