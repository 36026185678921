import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgIf, NgClass, DecimalPipe } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import {
  MatHeaderCell,
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';

import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  PositionDto,
  PriceInquiryRequestPositionPriceGet,
  PriceInquiryRequestPositionPricesGet,
  PriceInquiryRequestsClient
} from 'app/generated-client/generated-client';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';
import { MatInputDecimalPlacesDirective } from '../../directives/mat-input-decimal-places.directive';

@Component({
  selector: 'pa-price-inquiries-input-manually',
  templateUrl: './price-inquiries-input-manually.component.html',
  styleUrls: ['./price-inquiries-input-manually.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('500ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
      transition('expanded <=> void', animate('500ms cubic-bezier(0.25, 0.8, 0.25, 1)'))
    ])
  ],
  standalone: true,
  imports: [
    NgIf,
    FlexLayoutDirective,
    MatButton,
    MatIcon,
    NgClass,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    FormsModule,
    MatInputDecimalPlacesDirective,
    MatIconButton,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    DecimalPipe
  ]
})
export class PriceInquiriesInputManuallyComponent implements OnInit, OnDestroy {
  @ViewChildren(MatHeaderCell, { read: ElementRef }) headerCells: QueryList<ElementRef>;
  @Input() priceInquiryId: string;
  @Input() requestId: string;
  @Input() subPositionElements: any;
  @Input() headerWidth: number[];
  projectId: string;
  avaProjectId: string;
  dataSource: PriceInquiryRequestPositionPriceGet[];
  positionsList = new Map<string, PositionDto>();
  displayedColumns = ['index', 'itemNumber', 'shortText', 'unitPrice', 'comment', 'wasSetManually', 'expand'];
  expandedElement: PriceInquiryRequestPositionPriceGet | null;
  isEditable: boolean;
  private $destroy = new Subject<boolean>();
  columnWidths: number[];
  expandedRows = new Set<number>();

  constructor(
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private priceInquiryRequestsClient: PriceInquiryRequestsClient
  ) {}

  ngOnInit(): void {
    if (!this.subPositionElements) {
      combineLatest([
        this.selectedSpecificationMessengerService.selectedServiceSpecification,
        this.selectedSpecificationMessengerService.positionsList
      ])
        .pipe(takeUntil(this.$destroy))
        .subscribe(([servSpec, positionsList]: [{ avaProjectId: string; parentProjectId: string }, PositionDto[]]) => {
          this.projectId = servSpec?.parentProjectId;
          this.avaProjectId = servSpec?.avaProjectId;
          positionsList.forEach((item) => {
            this.positionsList[item.id] = item;
          });
          this.getData();
        });
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  getData(): void {
    this.priceInquiryRequestsClient
      .getPositionPricesForPriceInquiryRequest(this.projectId, this.avaProjectId, this.priceInquiryId, this.requestId)
      .subscribe((e: PriceInquiryRequestPositionPricesGet) => {
        e.positionPrices.sort((a1, a2) =>
          this.positionsList[a1.avaPositionId].itemNumber.stringRepresentation >
          this.positionsList[a2.avaPositionId].itemNumber.stringRepresentation
            ? 1
            : -1
        );

        this.dataSource = e.positionPrices;
        this.getTableColumnWidths();
      });
  }

  saveChanges(positionId: string, unitPrice: number): void {
    this.priceInquiryRequestsClient
      .setPositionPrice(this.projectId, this.avaProjectId, this.priceInquiryId, this.requestId, { positionId, unitPrice })
      .subscribe(() => {
        const row = this.dataSource.find((rp) => rp.avaPositionId === positionId);
        if (row) {
          row.wasSetManually = true;
        }
      });
  }

  getTableColumnWidths() {
    setTimeout(() => {
      this.columnWidths = this.headerCells.map((cell) => {
        const computedStyle = getComputedStyle(cell.nativeElement);
        const paddingLeft = parseFloat(computedStyle.paddingLeft);
        const paddingRight = parseFloat(computedStyle.paddingRight);
        const totalPaddingWidth = paddingLeft + paddingRight;
        const widthWithoutPadding = cell.nativeElement.offsetWidth - totalPaddingWidth;
        return widthWithoutPadding;
      });
    }, 100);
  }

  changeSubpositionsVisibility(index: number): void {
    if (this.expandedRows.has(index)) {
      this.expandedRows.delete(index);
      return;
    }
    this.expandedRows.add(index);
  }

  toggleAll(isOpen = false): void {
    if (isOpen) {
      this.dataSource.forEach((e, i) => {
        if (e.priceInquiryRequestPriceCommerceArticles?.length > 0) {
          this.expandedRows.add(i);
        }
      });
      return;
    }
    this.expandedRows.clear();
  }
}
