<div mat-dialog-content>
  <h2>Format</h2>
  <ng-container *ngIf="showingPdf; else showingExportTemplate">
    <h1>Bitte Druckvorschaufenster beachten</h1>
  </ng-container>
</div>
<ng-template #showingExportTemplate>
  <h3>Bieter</h3>
  <div fxLayout="row" fxLayoutGap="10px">
    <mat-checkbox [(ngModel)]="showOwnPrices" color="primary">Eigene Preise</mat-checkbox>
  </div>
  <div fxLayout="row" fxLayoutGap="10px">
    <mat-checkbox [(ngModel)]="showIdealBidder" color="primary">Idealbieter</mat-checkbox>
  </div>
  <div *ngIf="requests">
    <div *ngFor="let request of requests" fxLayout="row" fxLayoutGap="10px">
      <mat-checkbox [(ngModel)]="request.isSelected" color="primary">{{ request.request.name }}</mat-checkbox>
    </div>
    <hr />
  </div>

  <div fxLayout="column" *ngIf="!requestEnRoute; else loader">
    <mat-checkbox [(ngModel)]="includeHeaderOnlyOnFirstPage">Kopfzeile nur auf der ersten Seite drucken</mat-checkbox>
    <mat-form-field appearance="fill">
      <mat-label>Typ</mat-label>
      <mat-select [formControl]="targetType">
        <mat-option *ngFor="let targetType of targetTypes" [value]="targetType">
          {{ targetType }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button color="primary" mat-raised-button (click)="createExport()">Erstellen</button>
  </div>
  <ng-template #loader>
    <div class="export-loader" fxFlexFill fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
</ng-template>
