import { NgIf, NgFor } from '@angular/common';
import { Component, Inject, OnInit, OnDestroy, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose
} from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';

import { EMPTY, Observable, Subject, combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';
import { CopyCalculationForGroupService } from 'app/areas/tree/services/copy-calculation-for-group.service';
import {
  AvaProjectGet,
  AvaProjectsClient,
  CalculationsImportClient,
  IElementDto,
  PositionDto,
  CalculationImportResultGet,
  ProjectDto,
  ProjectGet,
  ServiceSpecificationGroupDto,
  PositionCalculationsSubPositionsClient,
  MappedPosition
} from 'app/generated-client/generated-client';

import { PositionTextPipe } from '../../pipes/ui-data-display/position-text.pipe';
import { AvaNotificationsService } from '../../services/ava-notifications.service';
import { CopyCalculationViewMessengerService } from '../../services/electron/copy-calculation-view-messenger.service';
import { SelectedSpecificationMessengerService } from '../../services/messengers/selected-specification-messenger.service';
import { SubPositionsMessengerService } from '../../services/messengers/sub-positions-messenger.service';

@Component({
  selector: 'pa-copy-match-position-modal',
  templateUrl: './copy-match-position-modal.component.html',
  styleUrls: ['./copy-match-position-modal.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    NgIf,
    MatFormField,
    MatLabel,
    MatSelect,
    FormsModule,
    NgFor,
    MatOption,
    MatButton,
    FlexLayoutDirective,
    MatDialogActions,
    MatDialogClose,
    PositionTextPipe
  ]
})
export class CopyMatchPositionModalComponent implements OnInit, OnDestroy {
  avaProjects: AvaProjectGet[];
  requestEnRoute = false;
  notChooseAvaProject = false;
  targetElementGroupId: string;
  private _selectedAvaProject: AvaProjectGet;
  set selectedAvaProject(value: AvaProjectGet) {
    this._selectedAvaProject = value;
    this.canImportCalculation = false;
    this.sourcePositions = null;
    this.unmappedTargetPositions = [];
  }
  get selectedAvaProject(): AvaProjectGet {
    return this._selectedAvaProject;
  }

  sourcePositions: PositionDto[];

  unmappedTargetPositions: {
    targetPosition: PositionDto;
    ignore: boolean;
    sourcePosition?: PositionDto;
  }[] = [];

  mappedPositions: MappedPosition[] = [];

  private targetAvaProjectId: string;
  private targetProjectId: string;
  canImportCalculation = false;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      sourceProject?: ProjectGet;
      sourceAvaProject: AvaProjectGet;
      isInMainView: boolean;
      sourceGroup?: ServiceSpecificationGroupDto;
      sourcePositionIds?: string[];
      sourceProjectId?: string;
      targetContainerId?: string;
    },
    @Optional() private matDialogRef: MatDialogRef<CopyMatchPositionModalComponent>,
    private calculationsImportClient: CalculationsImportClient,
    private avaProjectsClient: AvaProjectsClient,
    private copyCalculationViewMessengerService: CopyCalculationViewMessengerService,
    private avaNotificationsService: AvaNotificationsService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private subPositionsMessengerService: SubPositionsMessengerService,
    private positionCalculationsSubPositionsClient: PositionCalculationsSubPositionsClient,
    private copyCalculationForGroupService: CopyCalculationForGroupService
  ) {}

  ngOnInit(): void {
    if (this.data?.isInMainView) {
      this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(takeUntil(this.$destroy)).subscribe((s) => {
        this.targetAvaProjectId = s?.avaProjectId;
      });
    } else {
      this.copyCalculationViewMessengerService.selectedAvaProjectId.pipe(takeUntil(this.$destroy)).subscribe((avaProjectId) => {
        this.targetAvaProjectId = avaProjectId;
      });
    }

    this.copyCalculationViewMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((project) => {
      this.targetProjectId = project?.id;
    });

    this.copyCalculationViewMessengerService.selectedElementGroupId.pipe(takeUntil(this.$destroy)).subscribe((elementGroupId) => {
      this.targetElementGroupId = elementGroupId;
    });

    this.getAllAvaProjectsForProject().subscribe((avaProjects) => {
      this.avaProjects = avaProjects;
      if (avaProjects.length === 1) {
        this.selectedAvaProject = avaProjects[0];
        this.notChooseAvaProject = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  save(): void {
    this.requestEnRoute = true;
    let request;
    if (!this.data.sourceGroup && !this.data.sourcePositionIds) {
      request = this.calculationsImportClient.importOtherProjectCalculation({
        dryRun: false,
        sourceAvaProjectId: this.selectedAvaProject.id,
        targetAvaProjectId: this.targetAvaProjectId,

        positionMapping: this.unmappedTargetPositions
          .filter((up) => !!up.sourcePosition)
          .map((up) => {
            return {
              sourceAvaPositionId: up.sourcePosition.id,
              targetAvaPositionId: up.targetPosition.id
            };
          })
          .concat(
            (this.mappedPositions || []).map((mapped) => ({
              sourceAvaPositionId: mapped.sourcePosition.id,
              targetAvaPositionId: mapped.targetPosition.id
            }))
          ),
        targetPositionIdsToIgnore: this.unmappedTargetPositions.filter((up) => up.ignore).map((up) => up.targetPosition.id)
      });
    } else if (this.data.sourcePositionIds?.length > 0) {
      request = this.calculationsImportClient.importOtherPositionCalculation({
        dryRun: false,
        sourceAvaProjectId: this.selectedAvaProject.id,
        targetAvaProjectId: this.targetAvaProjectId,
        sourcePositionIds: this.data.sourcePositionIds,
        targetServiceSpecificationGroupId: this.data.targetContainerId,
        positionMapping: this.unmappedTargetPositions
          .filter((up) => !!up.sourcePosition)
          .map((up) => {
            return {
              sourceAvaPositionId: up.sourcePosition.id,
              targetAvaPositionId: up.targetPosition.id
            };
          })
          .concat(
            (this.mappedPositions || []).map((mapped) => ({
              sourceAvaPositionId: mapped.sourcePosition.id,
              targetAvaPositionId: mapped.targetPosition.id
            }))
          ),
        targetPositionIdsToIgnore: this.unmappedTargetPositions.filter((up) => up.ignore).map((up) => up.targetPosition.id)
      });
    } else {
      request = this.calculationsImportClient.importOtherGroupCalculation({
        dryRun: false,
        sourceAvaProjectId: this.selectedAvaProject.id,
        targetAvaProjectId: this.targetAvaProjectId,
        sourceServiceSpecificationGroupId: this.data.sourceGroup.id,
        targetServiceSpecificationGroupId: this.targetElementGroupId,
        positionMapping: this.unmappedTargetPositions
          .filter((up) => !!up.sourcePosition)
          .map((up) => {
            return {
              sourceAvaPositionId: up.sourcePosition.id,
              targetAvaPositionId: up.targetPosition.id
            };
          })
          .concat(
            (this.mappedPositions || []).map((mapped) => ({
              sourceAvaPositionId: mapped.sourcePosition.id,
              targetAvaPositionId: mapped.targetPosition.id
            }))
          ),
        targetPositionIdsToIgnore: this.unmappedTargetPositions.filter((up) => up.ignore).map((up) => up.targetPosition.id)
      });
    }
    request.subscribe(
      () => {
        this.requestEnRoute = false;
        this.avaNotificationsService.success('Import abgeschlossen.');
        this.copyCalculationForGroupService.delMarkCopyGroup();

        const avaProjectId = this.targetAvaProjectId;
        const projectId = this.targetProjectId;
        if (avaProjectId && projectId) {
          this.positionCalculationsSubPositionsClient
            .getSubPositionsForAvaProjectCalculation(projectId, avaProjectId)
            .subscribe((subPositions) => {
              this.subPositionsMessengerService.setSubPositions({
                avaProjectId: avaProjectId,
                subPositions: subPositions
              });
            });
        }
        this.matDialogRef.close(true);
      },
      () => {
        this.requestEnRoute = false;
        this.avaNotificationsService.error('Fehler beim Import der Kalkulationen.');
      }
    );
  }

  check(): void {
    this.requestEnRoute = true;
    let request;
    if (!this.data.sourceGroup && !this.data.sourcePositionIds) {
      request = this.calculationsImportClient.importOtherProjectCalculation({
        dryRun: true,
        sourceAvaProjectId: this.selectedAvaProject.id,
        targetAvaProjectId: this.targetAvaProjectId
      });
    } else if (this.data.sourcePositionIds?.length > 0) {
      request = this.calculationsImportClient.importOtherPositionCalculation({
        dryRun: true,
        sourceAvaProjectId: this.selectedAvaProject.id,
        targetAvaProjectId: this.targetAvaProjectId,
        sourcePositionIds: this.data.sourcePositionIds,
        targetServiceSpecificationGroupId: this.data.targetContainerId
      });
    } else {
      request = this.calculationsImportClient.importOtherGroupCalculation({
        dryRun: true,
        sourceAvaProjectId: this.selectedAvaProject.id,
        targetAvaProjectId: this.targetAvaProjectId,
        sourceServiceSpecificationGroupId: this.data.sourceGroup.id,
        targetServiceSpecificationGroupId: this.targetElementGroupId
      });
    }

    request.subscribe(
      (c) => {
        if (c.isSuccess) {
          this.requestEnRoute = false;
          this.canImportCalculation = true;
          this.avaNotificationsService.success('Die Kalkulation kann direkt kopiert werden.');
        } else {
          this.avaNotificationsService.info('Nicht alle Positionen konnten automatisch zugeordnet werden.');
          combineLatest([
            this.avaProjectsClient.getAvaProjectContentById(this.targetProjectId, this.targetAvaProjectId),
            this.avaProjectsClient.getAvaProjectContentById(
              this.data.sourceProject?.id || this.data.sourceProjectId,
              this.selectedAvaProject.id
            )
          ]).subscribe(([targetAvaProject, sourceAvaProject]) => {
            if (this.data.sourceGroup) {
              this.buildPositionMappingDataGroup(
                c,
                sourceAvaProject,
                targetAvaProject,
                this.data.sourceGroup.id,
                this.targetElementGroupId
              );
            } else {
              this.buildPositionMappingData(c, sourceAvaProject, targetAvaProject);
            }
          });
        }
      },
      () => {
        this.requestEnRoute = false;
        this.avaNotificationsService.error(
          'Fehler bei der Prüfung, die Projektkalkulation kann nicht in das aktuelle Projekt kopiert werden.'
        );
      }
    );
  }

  handlePositionAssignmentChangeEvent(targetPositionId: string, event: any): void {
    const unmappedPosition = this.unmappedTargetPositions.find((up) => up.targetPosition.id === targetPositionId);
    if (event == null) {
      unmappedPosition.ignore = true;
    } else {
      unmappedPosition.ignore = false;
    }
  }

  private buildPositionMappingData(importResult: CalculationImportResultGet, sourceProject: ProjectDto, targetProject: ProjectDto): void {
    this.requestEnRoute = false;

    this.sourcePositions = this.getAllPositions(sourceProject.serviceSpecifications[0].elements);

    if (this.data.sourcePositionIds) {
      this.sourcePositions = this.sourcePositions.filter((sp) => this.data.sourcePositionIds.indexOf(sp.id) > -1);
    }
    this.setUpMappedPositions(importResult);

    this.unmappedTargetPositions = this.getAllPositions(targetProject.serviceSpecifications[0].elements)
      .filter((targetPosition) => importResult.unmappedTargetPositionIds.indexOf(targetPosition.id) > -1)
      .map((targetPosition) => {
        return {
          targetPosition: targetPosition,
          ignore: true,
          sourcePosition: null
        };
      });

    this.canImportCalculation = true;
  }

  private setUpMappedPositions(importResult: CalculationImportResultGet): void {
    this.mappedPositions = (importResult.mappedPositions || []).map((map) => ({
      sourcePosition: this.sourcePositions.find((sp) => sp.id === map.sourcePosition.id),
      targetPosition: map.targetPosition
    }));
  }

  private buildPositionMappingDataGroup(
    importResult: CalculationImportResultGet,
    sourceProject: ProjectDto,
    targetProject: ProjectDto,
    sourceElementGroupId: string,
    targetElementGroupId: string
  ): void {
    this.requestEnRoute = false;
    const sourceGroup = this.getAllElements(sourceProject.serviceSpecifications[0].elements).find(
      (item) => item.id === sourceElementGroupId
    ) as ServiceSpecificationGroupDto;
    const targetGroup = this.getAllElements(targetProject.serviceSpecifications[0].elements).find(
      (item) => item.id === targetElementGroupId
    ) as ServiceSpecificationGroupDto;
    this.sourcePositions = this.getAllPositions(sourceGroup.elements);

    if (this.data.sourcePositionIds) {
      this.sourcePositions = this.sourcePositions.filter((sp) => this.data.sourcePositionIds.indexOf(sp.id) > -1);
    }
    this.setUpMappedPositions(importResult);

    this.unmappedTargetPositions = this.getAllPositions(targetGroup.elements)
      .filter((targetPosition) => importResult.unmappedTargetPositionIds.includes(targetPosition.id))
      .map((targetPosition) => {
        return {
          targetPosition: targetPosition,
          ignore: true,
          sourcePosition: null
        };
      });

    this.canImportCalculation = true;
  }

  private getAllElements(elements: IElementDto[]): IElementDto[] {
    const positions: IElementDto[] = [];
    elements.forEach((e) => {
      positions.push(e);
      if (e.elementType === 'ServiceSpecificationGroupDto') {
        positions.push(...this.getAllElements((e as ServiceSpecificationGroupDto).elements));
      }
    });
    return positions;
  }

  private getAllPositions(elements: IElementDto[]): PositionDto[] {
    const positions: PositionDto[] = [];

    elements.forEach((e) => {
      if (e.elementType === 'PositionDto') {
        positions.push(e);
      } else if (e.elementType === 'ServiceSpecificationGroupDto') {
        positions.push(...this.getAllPositions((e as ServiceSpecificationGroupDto).elements));
      }
    });

    return positions;
  }

  getAllAvaProjectsForProject(): Observable<AvaProjectGet[]> {
    if (!this.data?.sourceProject && !this.data?.sourceProjectId) {
      return EMPTY;
    }

    const projectId = this.data?.sourceProject?.id || this.data?.sourceProjectId;

    let hasMore = true;
    let currentPage = 1;
    const listResultAll: AvaProjectGet[] = [];
    const listResultAllSource = new Subject<AvaProjectGet[]>();

    const getData = () => {
      if (!hasMore) {
        listResultAllSource.next(listResultAll);
        listResultAllSource.complete();
        return;
      }

      this.avaProjectsClient.getAllAvaProjectsForProject(projectId, false, '', '', 50, currentPage++).subscribe((avaProjectsResult) => {
        if (avaProjectsResult.page !== currentPage - 1) {
          hasMore = false;
        } else if (avaProjectsResult.data.length) {
          listResultAll.push(...avaProjectsResult.data);
          hasMore = true;
        }

        getData();
      });
    };
    getData();

    return listResultAllSource.asObservable().pipe(take(1));
  }
}
