import { CommonModule } from '@angular/common';
import { Component, Optional } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';
import { ContactsClient } from 'app/generated-client/generated-client';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { ContactsService } from 'app/shared/services/lightquery/contacts.service';

import { UploadFileComponent, UploadFileSettings } from '../../../../shared/components/upload-file/upload-file.component';

@Component({
  selector: 'pa-import-contact-file.component',
  templateUrl: './import-contact-file.component.html',
  styleUrls: ['./import-contact-file.component.scss'],
  standalone: true,
  imports: [CommonModule, MatCardModule, MatButtonModule, UploadFileComponent, MatProgressSpinnerModule, FlexLayoutDirective]
})
export class ImportContactFileComponent {
  uploadFileSettings: UploadFileSettings = {
    height: '200px',
    maxWidth: '400px',
    label: 'CSV Datei'
  };

  isLoading = false;

  constructor(
    private contactsClient: ContactsClient,
    private contactsService: ContactsService,
    private notificationsService: AvaNotificationsService,
    @Optional() private matDialogRef: MatDialogRef<ImportContactFileComponent>
  ) {}

  dismiss(): void {
    this.matDialogRef.close();
  }

  public importFile(addedFiles: File[]): void {
    this.isLoading = true;
    const file = addedFiles[0];
    this.contactsClient
      .importContacts({
        data: file,
        fileName: file.name
      })
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.notificationsService.success('Die Kontakte wurden importiert.');
          this.contactsService.forceRefresh();
          this.matDialogRef.close();
        },
        error: () => {
          this.isLoading = false;
          this.notificationsService.error('Fehler beim Importieren der Datei');
        }
      });
  }
}
