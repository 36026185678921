import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Directive, Inject, OnDestroy, OnInit, Self } from '@angular/core';

import { delay, Subject, takeUntil } from 'rxjs';

import { ChangeViewportHeightService } from './../services/change-viewport-height.service';

@Directive({
  selector: '[updateViewportSizeDirective]',
  standalone: true
})
export class UpdateViewportSizeDirective implements OnInit, OnDestroy {
  protected readonly destroy$ = new Subject();

  constructor(
    @Self() @Inject(CdkVirtualScrollViewport) private readonly viewportComponent: CdkVirtualScrollViewport,
    private changeViewportHeightService: ChangeViewportHeightService
  ) {}

  ngOnInit() {
    this.changeViewportHeightService.viewportHeight
      .pipe(
        takeUntil(this.destroy$),
        //delay is used to first apply a new height value to the viewport,
        // and then update the number of times, instead of setTimeout(0)
        delay(0)
      )
      .subscribe(() => {
        this.viewportComponent.checkViewportSize();
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
