import { Pipe, PipeTransform } from '@angular/core';

import { ComissionStatusDto } from 'app/generated-client/generated-client';

@Pipe({
  name: 'comissionStatus',
  standalone: true
})
export class ComissionStatusPipe implements PipeTransform {
  transform(value: ComissionStatusDto): string {
    switch (value) {
      case ComissionStatusDto.Undefined:
        return 'Unbekannt';
      case ComissionStatusDto.Removed:
        return 'Entfernt';
      case ComissionStatusDto.Postponed:
        return 'Verschoben';
      case ComissionStatusDto.Commissioned:
        return 'Beauftragt';
    }
    return null;
  }
}
