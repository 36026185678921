<as-split direction="vertical" fxFlexFill #asSplitHorizontalRef (dragEnd)="onDragEnd($event.sizes)">
  <as-split-area [size]="50" [minSize]="10" class="main-window">
    <pa-general-header *ngIf="!isSeparatedMain">
      <router-outlet></router-outlet>
    </pa-general-header>
  </as-split-area>
  <as-split-area [size]="50" [minSize]="3" *ngIf="multiView && !hidden">
    <pa-multi-view-window (selectView)="selectViews()" [listWindow]="showedListWindow"></pa-multi-view-window>
  </as-split-area>
</as-split>
<button
  mat-mini-fab
  color="warn"
  class="mark"
  *ngIf="multiView && hidden"
  (click)="selectViews()"
  matTooltip="Ansichten auswählen"
  (mouseenter)="showSeparatedWindows()"
  (mouseleave)="tryLeave()"
>
  <mat-icon>keyboard_arrow_up</mat-icon>
</button>
<button
  mat-mini-fab
  color="primary"
  class="main"
  (click)="toggleSeparate()"
  matTooltip="Hauptfenster verschiebber machen"
  *ngIf="multiView"
>
  <mat-icon>{{ !isSeparatedMain ? 'layers' : 'layers_clear' }}</mat-icon>
</button>
