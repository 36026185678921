import { Pipe, PipeTransform } from '@angular/core';

import { CatalogueTypeDto } from 'app/generated-client/generated-client';

@Pipe({
  name: 'catalogueType',
  standalone: true
})
export class CatalogueTypePipe implements PipeTransform {
  transform(value: CatalogueTypeDto): string {
    switch (value) {
      case CatalogueTypeDto.Unknown:
        return 'Unbekannt';
      case CatalogueTypeDto.Location:
        return 'Lokation';
      case CatalogueTypeDto.DIN276:
        return 'DIN 276';
      case CatalogueTypeDto.CostUnit:
        return 'Kostenelement';
      case CatalogueTypeDto.WorkCategory:
        return 'Arbeitsbereich';
      case CatalogueTypeDto.OenormB1801CostGroup:
        return 'ÖNorm B 1801 Kostengruppe';
      case CatalogueTypeDto.BIM:
        return 'BIM';
      case CatalogueTypeDto.Attachment:
        return 'Anlage';
    }
    return null;
  }
}
