import { BehaviorSubject, Subject, combineLatest, takeUntil } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

import { BimStructureFilterService } from './bim-structure-filter.service';
import { BimViewService } from './bim-view.service';
import { IPropertySetFilter } from '../interface/property-set-filter.interface';
import { IfcStructure } from '../../../generated-client/generated-client';

@Injectable({
  providedIn: 'root'
})
export class BimModelFilterService implements OnDestroy {
  private $ifcStructure = new BehaviorSubject<IfcStructure | null>(null);
  private $selectedElementTypes = new BehaviorSubject<string[]>([]);
  private $selectedPropertySetFilters = new BehaviorSubject<IPropertySetFilter[]>([]);
  private $showedElementIds = new BehaviorSubject<number[]>([]);

  public selectedElementTypes = this.$selectedElementTypes.asObservable();
  public showedElementIds = this.$showedElementIds.asObservable();

  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private bimViewService: BimViewService,
    private bimStructureFilterService: BimStructureFilterService
  ) {
    this.bimViewService.structure.pipe(takeUntil(this.$destroy)).subscribe((structure: IfcStructure) => {
      this.$ifcStructure.next(structure);
    });

    this.setUpFilterListener();
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();

    this.$ifcStructure.complete();
    this.$selectedElementTypes.complete();
  }

  setSelectedElementTypes(elementTypes: string[]): void {
    this.$selectedElementTypes.next(elementTypes || []);
  }

  setPropertySetFilters(propertySetFilters: IPropertySetFilter[]): void {
    this.$selectedPropertySetFilters.next(propertySetFilters || []);
  }

  private setUpFilterListener(): void {
    combineLatest([this.$ifcStructure, this.$selectedElementTypes, this.$selectedPropertySetFilters])
      .pipe(takeUntil(this.$destroy))
      .subscribe(([structure, selectedElementTypes, propertySetFilters]) => {
        if (structure) {
          this.handleFilter({ structure, selectedElementTypes, propertySetFilters });
        }
      });
  }

  private handleFilter(filter: {
    structure: IfcStructure;
    selectedElementTypes: string[];
    propertySetFilters: IPropertySetFilter[];
  }): void {
    const shouldShowAll = filter.selectedElementTypes.length === 0 && filter.propertySetFilters.length === 0;
    if (shouldShowAll) {
      // We're showing all if there's no actual filter options defined
      this.bimViewService.resetViewer();
      this.$showedElementIds.next(filter.structure.entityIds);
      return;
    }

    let listIds = this.getElementIdsForElementTypes(filter.structure, filter.selectedElementTypes);

    listIds = this.bimStructureFilterService.reduceElementsFromListWithPropertySetFilters(
      listIds,
      filter.structure,
      filter.propertySetFilters
    );

    this.$showedElementIds.next(listIds);
    this.bimViewService.hideAllElementsExcept(listIds);
  }

  private getElementIdsForElementTypes(structure: IfcStructure, selectedElementTypes: string[]): number[] {
    if (selectedElementTypes.length === 0) {
      return structure.entityIds;
    }

    const elementIds = [];

    selectedElementTypes.forEach((elementType) => {
      const list = structure.entityIdsByIfcType[elementType];
      if (list) {
        elementIds.push(...list);
      }
    });

    return elementIds;
  }
}
