import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BimStructureService {
  private openAllSource = new Subject<boolean>();
  openAll = this.openAllSource.asObservable();

  constructor() {}

  setShowChildren(value: boolean): void {
    this.openAllSource.next(value);
  }
}
