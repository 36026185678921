import { Injectable } from '@angular/core';

import { ReplaySubject, Subject } from 'rxjs';

import { TotalInvoiceSumType } from 'app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class QuantityTakeOffInvoiceTotalsMessengerService {
  private currentInvoiceTotalSumSource = new ReplaySubject<TotalInvoiceSumType>(1);
  currentInvoiceTotalSum = this.currentInvoiceTotalSumSource.asObservable();

  private changedAvaPositionIdSource = new Subject<string>();
  changedAvaPositionId = this.changedAvaPositionIdSource.asObservable();

  setCurrentTotalSum(total: TotalInvoiceSumType): void {
    this.currentInvoiceTotalSumSource.next(total);
  }

  resetTotals(): void {
    this.currentInvoiceTotalSumSource.next(null);
  }

  setChangedAvaPositionId(id: string): void {
    this.changedAvaPositionIdSource.next(id);
  }
}
