import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PriceInquiryRequestsClient } from 'app/generated-client/generated-client';
import { UploadFileSettings } from 'app/shared/components/upload-file/upload-file.component';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

import { UploadFileComponent } from '../../../../../../shared/components/upload-file/upload-file.component';

@Component({
  selector: 'pa-price-inquiries-input-file',
  templateUrl: './price-inquiries-input-file.component.html',
  styleUrls: ['./price-inquiries-input-file.component.scss'],
  standalone: true,
  imports: [UploadFileComponent]
})
export class PriceInquiriesInputFileComponent implements OnInit {
  @Input() priceInquiryId: string;
  @Input() requestId: string;
  @Output() importFile = new EventEmitter<boolean>();
  uploadFileSettings: UploadFileSettings = {
    label: 'Datei zum Hochladen hierher ziehen, GAEB oder Excel',
    isMultipleSelection: false
  };
  projectId: string;
  avaProjectId: string;
  private $destroy = new Subject<boolean>();

  constructor(
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private notificationsService: AvaNotificationsService,
    private priceInquiryRequestsClient: PriceInquiryRequestsClient
  ) {}

  ngOnInit(): void {
    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(takeUntil(this.$destroy))
      .subscribe((e: { avaProjectId: string; parentProjectId: string }) => {
        this.projectId = e?.parentProjectId;
        this.avaProjectId = e?.avaProjectId;
      });
  }

  onSelect(addedFiles: File[]): void {
    if (addedFiles?.length) {
      const addedFile = addedFiles[0];
      const file = {
        data: addedFile,
        fileName: addedFile.name
      };
      this.priceInquiryRequestsClient
        .importSubContractorOffer(this.projectId, this.avaProjectId, this.priceInquiryId, this.requestId, file)
        .subscribe({
          next: () => {
            this.notificationsService.success('Angebot eingelesen');
            this.importFile.emit(true);
          },
          error: () => this.notificationsService.error('Datei konnte nicht gespeichert werden')
        });
    }
  }
}
