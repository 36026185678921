<h1 *ngIf="structureView === 'estimations'">Mengenermittlungen</h1>
<h1 *ngIf="structureView === 'invoices'">Abrechnungen</h1>
<div class="wrapper-btn">
  <button
    mat-raised-button
    color="primary"
    (click)="showAddNewModal()"
    *ngIf="structureView === 'estimations'"
    [disabled]="isReadOnly || !project?.allowedQuantityTakeOffTypes"
  >
    Neue Mengenermittlung
  </button>
  <ng-container *ngIf="structureView === 'invoices'">
    <button mat-raised-button color="primary" (click)="showAddNewModal()" [disabled]="isReadOnly || !project?.allowedQuantityTakeOffTypes">
      Neue Abrechnung
    </button>
    <button mat-raised-button color="primary" (click)="showSheet()">Mengenvergleich</button>
  </ng-container>
  <button *ngIf="enableCanalConvertExport" mat-raised-button color="primary" (click)="initiateCanalExport()">
    Excel für Haltungen Exportieren
  </button>
  <button mat-raised-button color="primary" (click)="showAddNewPageModal()" *ngIf="structureView === 'invoices'">Neues Aufmaßblatt</button>
</div>
<pa-invoices-table [structureView]="structureView" [typeQTO]="typeQTO"></pa-invoices-table>
