import { NgIf, NgFor, DatePipe } from '@angular/common';
import { Component, Inject, OnInit, AfterContentInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogClose } from '@angular/material/dialog';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';
import { Notification } from 'app/shared/models';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';

import { NotificationTypePipe } from '../../pipes/ui-data-display/notification-type.pipe';

import { NoteIconComponent } from '../note-icon/note-icon.component';

@Component({
  selector: 'pa-notification-window',
  templateUrl: './notification-window.component.html',
  styleUrls: ['./notification-window.component.scss'],
  standalone: true,
  imports: [
    MatDialogContent,
    FlexLayoutDirective,
    NgIf,
    NoteIconComponent,
    NgFor,
    MatButton,
    MatDialogClose,
    DatePipe,
    NotificationTypePipe
  ]
})
export class NotificationWindowComponent implements OnInit, AfterContentInit {
  notifications: Notification[];
  hasId: boolean;
  currentNote: Notification;
  hasPrevious: boolean;
  hasNext: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dataNotes: { notifications: Notification[]; id: string },
    private avaNotificationsService: AvaNotificationsService
  ) {}

  ngOnInit(): void {
    this.notifications = this.dataNotes.notifications;
    this.hasId = !!this.dataNotes.id;
    if (this.hasId) {
      this.currentNote = { ...this.notifications.find((item) => item.id === this.dataNotes.id) };
    }
    setTimeout(() => {
      if (this.hasId) {
        this.avaNotificationsService.clearNotifiation(this.currentNote.id);
      } else {
        this.avaNotificationsService.clearAllNotifications();
      }
    }, 1);
  }

  ngAfterContentInit(): void {
    if (this.hasId) {
      this.hasClosest();
    }
  }

  hasClosest(): void {
    const index = this.notifications.findIndex((item) => item.id === this.currentNote.id);
    this.hasPrevious = index > 0;
    this.hasNext = index < this.notifications.length - 1;
  }

  getClosest(next: boolean = false): void {
    const currentId = this.currentNote.id;
    const index = this.notifications.findIndex((item) => item.id === currentId);
    this.currentNote = this.notifications[index + (next ? 1 : -1)];
    this.notifications = this.notifications.filter((item) => item.id !== currentId);
    this.hasClosest();
    this.avaNotificationsService.clearNotifiation(this.currentNote.id);
  }
}
