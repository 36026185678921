import { IElementDto, ServiceSpecificationGroupDto } from 'app/generated-client/generated-client';
import { Injectable } from '@angular/core';

import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { SelectingElementsType } from 'app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class TreeNodeSelectingService {
  private treeNodeSelectingSource = new BehaviorSubject<{ [elementId: string]: SelectingElementsType }>({});
  treeNodeSelecting = this.treeNodeSelectingSource.asObservable();
  private _selectingElementsTreeData: SelectingElementsType[];

  private selectingChangedSource = new ReplaySubject<SelectingElementsType[]>(1);
  selectingChanged = this.selectingChangedSource.asObservable();

  constructor() {}

  setSelectingChanged(selectingChanged: SelectingElementsType[]): void {
    this.selectingChangedSource.next(selectingChanged);
  }

  loadSelectingElements(elements: IElementDto[], parent: SelectingElementsType = null): SelectingElementsType[] {
    const list: SelectingElementsType[] = [];
    elements.forEach((element: IElementDto) => {
      const obj = {
        parent,
        elementId: element.id,
        checked: false
      } as SelectingElementsType;
      if (element.elementType === 'ServiceSpecificationGroupDto') {
        obj.indeterminate = false;
        obj.children = this.loadSelectingElements((<ServiceSpecificationGroupDto>element).elements, obj);
      }
      list.push(obj);
      this.treeNodeSelectingSource.value[element.id] = obj;
    });
    this._selectingElementsTreeData = list;
    return list;
  }

  getTreeData() {
    return this._selectingElementsTreeData;
  }

  private changeAllChild(children: SelectingElementsType[], value: boolean): void {
    children.forEach((item: SelectingElementsType) => {
      item.checked = value;
      if (item.children) {
        item.indeterminate = false;
        this.changeAllChild(item.children, value);
      }
    });
  }

  private changeAllParent(parent: SelectingElementsType, value: boolean): void {
    parent.checked = value;
    if (parent.parent) {
      this.changeAllParent(parent.parent, value);
    }
  }

  changeSelecting(value: boolean, id: string, isUserChanged: boolean = true): void {
    const selectingNode = this.treeNodeSelectingSource.value[id];
    selectingNode.checked = value;
    if (selectingNode.children) {
      selectingNode.indeterminate = false;
      if (isUserChanged) {
        this.changeAllChild(selectingNode.children, value);
      }
    }
    if (selectingNode.parent) {
      this.changeAllParent(selectingNode.parent, value);
    }
    this.getIndeterminate(selectingNode.parent);
    if (isUserChanged) {
      this.treeNodeSelectingSource.next(this.treeNodeSelectingSource.value);
    }
  }

  private getIndeterminate(parentNode: SelectingElementsType): void {
    if (!parentNode) {
      return;
    }
    let resultIndeterminateSome: boolean = false;
    let resultIndeterminateAll: boolean = false;
    parentNode.children.forEach((item: SelectingElementsType) => {
      if (item.checked || item.indeterminate) {
        resultIndeterminateSome = true;
      }
      if (!item.checked) {
        resultIndeterminateAll = true;
      }
    });
    if (!resultIndeterminateSome) {
      parentNode.indeterminate = false;
      parentNode.checked = false;
    } else if (resultIndeterminateAll) {
      parentNode.indeterminate = true;
      parentNode.checked = false;
    } else {
      parentNode.indeterminate = false;
      parentNode.checked = true;
    }
    this.getIndeterminate(parentNode.parent);
  }

  changeAll(value: boolean): void {
    Object.values(this.treeNodeSelectingSource.value).forEach(e => {
      this.changeSelecting(value, e.elementId, false);
    });
    this.treeNodeSelectingSource.next(this.treeNodeSelectingSource.value);
  }
}
