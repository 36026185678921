import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';

import { ProjectGet } from 'app/generated-client/generated-client';

import { LockProjectService } from '../../services/lock-project.service';

@Component({
  selector: 'pa-lock-icon',
  templateUrl: './lock-icon.component.html',
  styleUrls: ['./lock-icon.component.scss'],
  standalone: true,
  imports: [NgIf, MatIcon, MatTooltip]
})
export class LockIconComponent implements OnInit {
  @Input() project: ProjectGet;

  constructor(private lockProjectService: LockProjectService) {}

  userAccessList: string;
  canAccess = false;

  ngOnInit(): void {
    if (this.project?.projectUserAccessRestrictions?.length > 0) {
      this.userAccessList = this.project.projectUserAccessRestrictions.map((p) => p.userName).join(', ');
    }

    this.canAccess = this.lockProjectService.currentUserHasAccess(this.project);
  }
}
