import { Injectable } from '@angular/core';
import { ErrorReportGet } from '../../../generated-client/generated-client';
import { HttpClient } from '@angular/common/http';
import { PaginationMainService } from './main/pagination-main.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorReportsService extends PaginationMainService<ErrorReportGet> {
  constructor(protected http: HttpClient) {
    super(http);
    this.beginBaseUrl('/api/error-reports');
  }
}
