import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgIf, DecimalPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialogContent } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  MatTableDataSource,
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';

import { Observable, Subject } from 'rxjs';
import { filter, first, map, switchMap, takeUntil } from 'rxjs/operators';

import { FlatElementsService } from 'app/areas/tree/services/flat-elements.service';
import {
  AvaProjectAssumedQuantitiesGet,
  AvaProjectsClient,
  ExecutionDescriptionDto,
  NoteTextDto,
  PositionDto,
  ReportGetOfMainPositionsReport,
  ReportsClient,
  ServiceSpecificationDto,
  ServiceSpecificationGroupDto,
  TextTemplateType,
  TextTemplatesClient
} from 'app/generated-client/generated-client';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { PrintViewMessengerService } from 'app/shared/services/electron/print-view-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

import { SafeHtmlPipe } from '../../../../../../shared/pipes/safe-html.pipe';
import { ElementTypePipe } from '../../../../../../shared/pipes/ui-data-display/element-type.pipe';
import { ProjectCurrencyPipe } from '../../../../../../shared/pipes/ui-data-display/project-currency.pipe';
import { ModalService } from '../../../../../../shared/services/modal.service';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

import { ReportTextSelectionComponent } from '../report-text-selection/report-text-selection.component';

@Component({
  selector: 'pa-service-specification-table-view',
  templateUrl: './service-specification-table-view.component.html',
  styleUrls: ['./service-specification-table-view.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
  standalone: true,
  imports: [
    NgIf,
    MatDialogContent,
    FlexLayoutDirective,
    MatCheckbox,
    FormsModule,
    MatButton,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatIcon,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatProgressSpinner,
    DecimalPipe,
    SafeHtmlPipe,
    ProjectCurrencyPipe,
    ElementTypePipe
  ]
})
export class ServiceSpecificationTableViewComponent implements OnInit, OnDestroy {
  @Input() set showedAvaProject(v: ServiceSpecificationDto) {
    this._showedAvaProject = v;
    if (this._showedAvaProject) {
      this.flatElementsService.setElementsDto(this._showedAvaProject);
    }
  }

  private _showedAvaProject: ServiceSpecificationDto;
  @Input() elementSelectionId: string | null = null;
  private _showAssumedQuantities = false;
  @Input() set showAssumedQuantities(value: boolean) {
    this._showAssumedQuantities = value;
    if (value) {
      this.selectedSpecificationMessengerService.selectedServiceSpecification
        .pipe(
          filter((s) => !!s),
          first()
        )
        .subscribe((s) => {
          this.avaProjectsClient.getAssumedQuantitiesForAvaProject(s.parentProjectId, s.avaProjectId).subscribe((assumedQuantities) => {
            this.assumedQuantities = assumedQuantities;
          });
        });
    }
  }
  get showAssumedQuantities(): boolean {
    return this._showAssumedQuantities;
  }
  private $destroy: Subject<boolean> = new Subject<boolean>();
  dataSource = new MatTableDataSource<ServiceSpecificationGroupDto | NoteTextDto | ExecutionDescriptionDto | PositionDto>();
  columnsToDisplay = ['positionType', 'itemNumber', 'shortText', 'unitPrice', 'quantity', 'unitTag', 'totalPrice', 'longText'];
  expandedElement: PositionDto | null;
  finishedLoading = false;
  generateLongTexts = false;
  includeSignatureField = false;
  includeHeaderOnlyOnFirstPage = false;
  private _exportOnlyPositions = false;
  set exportOnlyPositions(value: boolean) {
    if (value !== this._exportOnlyPositions) {
      this._exportOnlyPositions = value;
      this.setDataSource();
    }
  }
  get exportOnlyPositions(): boolean {
    return this._exportOnlyPositions;
  }
  flatElements: (ServiceSpecificationGroupDto | NoteTextDto | ExecutionDescriptionDto | PositionDto)[];
  showingPdf = false;

  startText: string;
  endText: string;
  assumedQuantities: AvaProjectAssumedQuantitiesGet;
  private lastStartText: string;
  private lastEndText: string;

  constructor(
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private reportsClient: ReportsClient,
    private notificationsService: AvaNotificationsService,
    private printViewMessengerService: PrintViewMessengerService,
    private modalService: ModalService,
    private textTemplatesClient: TextTemplatesClient,
    private flatElementsService: FlatElementsService,
    private avaProjectsClient: AvaProjectsClient
  ) {}

  ngOnInit(): void {
    this.flatElementsService.flatElementsDto.pipe(takeUntil(this.$destroy)).subscribe((elementsDto) => {
      this.finishedLoading = false;
      this.flatElements = elementsDto;
      this.setDataSource();
    });
    this.printViewMessengerService.printViewClosed.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.showingPdf = false;
      this.finishedLoading = true;
    });

    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        filter((s) => !!s),
        first()
      )
      .subscribe((s) => {
        if (this.showAssumedQuantities) {
          this.avaProjectsClient.getAssumedQuantitiesForAvaProject(s.parentProjectId, s.avaProjectId).subscribe((assumedQuantities) => {
            this.assumedQuantities = assumedQuantities;
          });
        }

        this.textTemplatesClient
          .getLastUsedHtmlTextForAvaProject(s.parentProjectId, s.avaProjectId, TextTemplateType.ServiceSpecificationStart)
          .subscribe({
            next: (text) => (this.startText = text.htmlText)
          });
        this.textTemplatesClient
          .getLastUsedHtmlTextForAvaProject(s.parentProjectId, s.avaProjectId, TextTemplateType.ServiceSpecificationEnd)
          .subscribe({
            next: (text) => (this.endText = text.htmlText)
          });
      });
  }

  private setDataSource(): void {
    if (this.exportOnlyPositions) {
      this.dataSource.data = this.flatElements.filter((e) => e.elementType === 'PositionDto');
    } else {
      this.dataSource.data = this.flatElements;
    }
    setTimeout(() => {
      this.finishedLoading = true;
    }, 500);
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  selectStartingText(event: MouseEvent): void {
    event.preventDefault();
    if (!this.startText) {
      this.startText = this.lastStartText;
      this.selectText(this.startText, TextTemplateType.ServiceSpecificationStart).subscribe((t) => (this.startText = t));
    } else {
      this.lastStartText = this.startText;
      this.startText = null;
    }
  }

  selectEndingText(event: MouseEvent): void {
    event.preventDefault();
    if (!this.endText) {
      this.endText = this.lastEndText;
      this.selectText(this.endText, TextTemplateType.ServiceSpecificationEnd).subscribe((t) => (this.endText = t));
    } else {
      this.lastEndText = this.endText;
      this.endText = null;
    }
  }

  private selectText(currentText: string, textTemplateType: TextTemplateType): Observable<string> {
    return this.modalService
      .openModal(ReportTextSelectionComponent, {
        dialogType: ConfirmationType.General,
        data: {
          text: currentText,
          templateType: textTemplateType
        }
      })
      .afterClosed()
      .pipe(map((text) => text ?? currentText));
  }

  generatePdfAndShowPrintView(): void {
    this.finishedLoading = false;
    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        takeUntil(this.$destroy),
        switchMap((serviceSpecification) =>
          this.reportsClient.getServiceSpecificationReport(
            serviceSpecification.avaProjectId,
            true,
            this.generateLongTexts,
            true,
            this.exportOnlyPositions,
            this.includeSignatureField,
            this.includeHeaderOnlyOnFirstPage,
            true,
            {
              elementSelection: null,
              elementSelectionId: this.elementSelectionId,
              startHtmlText: this.startText,
              endHtmlText: this.endText
            }
          )
        )
      )
      .subscribe({
        next: (report: ReportGetOfMainPositionsReport | null) => {
          this.printViewMessengerService.showPdfPreview(
            report.htmlReport,
            report.reportData.projectName,
            report.isLandscape,
            report.pdfReportBase64,
            this.includeHeaderOnlyOnFirstPage
          );
          this.printViewMessengerService.waitForPrintViewReady().subscribe(() => {
            this.finishedLoading = true;
            this.showingPdf = true;
          });
        },
        error: () => {
          this.finishedLoading = true;
          this.notificationsService.error('Fehler beim Erstellen der Druckvorschau.');
        }
      });
  }
}
