import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag } from '@angular/cdk/drag-drop';
import { NgIf, NgFor } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogClose } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { filter, first, switchMap } from 'rxjs/operators';

import {
  AvaProjectContentEditClient,
  AvaProjectContentEditOperation,
  AvaProjectContentEditResultGet,
  IElementDto
} from 'app/generated-client/generated-client';
import { ServiceSpecificationsHasChangeService } from 'app/shared/services/messengers/service-specifications-has-change.service';

import { AvaNotificationsService } from '../../../../../../../../shared/services/ava-notifications.service';
import { SelectedSpecificationMessengerService } from '../../../../../../../../shared/services/messengers/selected-specification-messenger.service';
import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-lv-editor-reorder-modal',
  templateUrl: './lv-editor-reorder-modal.component.html',
  styleUrls: ['./lv-editor-reorder-modal.component.scss'],
  standalone: true,
  imports: [
    MatDialogContent,
    NgIf,
    FormsModule,
    CdkDropList,
    NgFor,
    CdkDrag,
    MatIcon,
    FlexLayoutDirective,
    MatButton,
    MatDialogClose,
    MatProgressSpinner
  ]
})
export class LvEditorReorderModalComponent implements OnInit {
  elements: any[];
  isLoading: boolean;
  private projectId: string;
  private avaProjectId: string;

  constructor(
    private avaProjectContentEditClient: AvaProjectContentEditClient,
    private avaNotificationsService: AvaNotificationsService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    @Optional() public matDialogRef: MatDialogRef<LvEditorReorderModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      elements: IElementDto[];
      parentContainerId?: string;
    },
    private serviceSpecificationsHasChangeService: ServiceSpecificationsHasChangeService
  ) {}

  ngOnInit(): void {
    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        filter((p) => !!p),
        first()
      )
      .subscribe((p) => {
        this.projectId = p.parentProjectId;
        this.avaProjectId = p.avaProjectId;
      });

    this.elements = this.data.elements?.map((item: IElementDto) => ({ ...item }));
  }

  saveElement(): void {
    this.serviceSpecificationsHasChangeService
      .checkServiceSpecificationHasBeenEdited()
      .pipe(
        switchMap((r) => {
          this.isLoading = true;
          if (r) {
            return this.avaProjectContentEditClient.editAvaProjectContent(this.projectId, this.avaProjectId, {
              operation: AvaProjectContentEditOperation.Reorder,
              reorderOperation: {
                parentContainerId: this.data.parentContainerId,
                elementIds: this.elements.map((element: IElementDto) => element.id)
              }
            });
          }
        })
      )
      .subscribe({
        next: (editResult: AvaProjectContentEditResultGet) => {
          this.avaNotificationsService.success('Elemente neu angeordnet');
          this.matDialogRef.close(editResult);
        },
        error: () => {
          this.avaNotificationsService.error('Fehler beim Speichern der Änderungen');
          this.isLoading = false;
        }
      });
  }

  drop(event: CdkDragDrop<IElementDto[]>) {
    moveItemInArray(this.elements, event.previousIndex, event.currentIndex);
  }
}
