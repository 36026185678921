import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

import { NestingColorSettings } from '@shared/models';

import { SubPositionNestingLevelService } from './../services/sub-position-nesting-level.service';

@Directive({
  selector: '[paNestingColor]',
  standalone: true
})
export class NestingColorDirective implements OnChanges {
  @Input('paNestingColor') item: string;
  @Input() nestingColorSettings: NestingColorSettings = {};

  constructor(
    private el: ElementRef,
    private subPositionNestingLevelService: SubPositionNestingLevelService
  ) {}

  ngOnChanges() {
    const nestingLevel = this.subPositionNestingLevelService.getNestingLevel(this.item, this.nestingColorSettings);
    const backgroundColor = !nestingLevel ? 'white' : this.nestingColorSettings[nestingLevel];
    this.el.nativeElement.style.backgroundColor = backgroundColor;
  }
}
