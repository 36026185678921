<div fxFlexFill fxLayoutAlign="center center">
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>Zuschlagsgruppe erstellen</mat-card-title>
    </mat-card-header>
    <form class="group-form" [formGroup]="newGroupForm" (ngSubmit)="addGroup()">
      <mat-card-content fxLayout="column">
        <mat-form-field appearance="fill">
          <mat-label>Name</mat-label>
          <input name="groupName" required matInput formControlName="name" />
        </mat-form-field>
        <mat-checkbox name="isDefault" formControlName="isDefault"> Als Standard festlegen </mat-checkbox>
        <mat-form-field appearance="fill">
          <mat-label>Wagnis und Gewinn (WuG)</mat-label>
          <input name="riskAndProfit" required matInput formControlName="riskAndProfitAddition" type="number" />
          <span matSuffix>%</span>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Kalkulationslohn</mat-label>
          <input name="calculationWage" required matInput formControlName="calculationWage" type="number" />
          <span matSuffix>€/h</span>
        </mat-form-field>
        <div class="button-actions" fxLayout="row" fxLayoutGap="8px">
          <button mat-button mat-raised-button color="primary" type="submit" [disabled]="!newGroupForm.valid">Speichern</button>
          <button mat-button mat-raised-button color="warn" type="button" (click)="dismiss()">Abbrechen</button>
        </div>
      </mat-card-content>
    </form>
  </mat-card>
</div>
