<div class="wrap-path">
  <mat-icon *ngIf="canNavigateUp" class="pointer path-icon" (click)="navigateUp()">reply</mat-icon>
  <span class="path">{{ path || 'Dateien' }}</span>
  <span class="spacer"></span>
  <mat-icon class="pointer path-icon" (click)="createFolder()" matTooltip="Neuer Ordner">create_new_folder</mat-icon>
</div>

<div class="container" fxFlex fxLayout="row" fxLayoutAlign="space-between stretch">
  <ul *ngIf="fileElements?.length; else noFile">
    <li
      *ngFor="let element of fileElements"
      class="file-or-folder pointer"
      [ngClass]="{ 'selected-file': selectedFileId === element.id }"
      (click)="navigate(element)"
      (contextmenu)="element.canDelete && element.isFolder && openMenu($event, menuTrigger)"
    >
      <span [matMenuTriggerFor]="rootMenu" [matMenuTriggerData]="{ element: element }" #menuTrigger="matMenuTrigger"></span>
      <div>
        <ng-container *ngIf="element.isFolder; else file">
          <div fxLayoutAlign="start center" fxLayoutGap="10px">
            <mat-icon class="file-or-folder-icon">folder</mat-icon>
            <span>{{ element.name }}</span>
          </div>
        </ng-container>
        <ng-template #file>
          <div class="file file-or-folder-icon" *ngIf="!element.isFolder" fxLayoutAlign="start center" fxLayoutGap="10px">
            <pa-project-file-list-preview [projectFile]="element"></pa-project-file-list-preview>
            <div fxLayout="column" fxLayoutAlign="start" class="file-text">
              <span>{{ element.name }}</span>
              <span>{{ element.file.createdAtUtc | date: 'dd.MM.yyyy HH:mm' }}</span>
            </div>
          </div>
        </ng-template>
      </div>
    </li>
  </ul>
  <ng-template #noFile>
    <i>Keine Dateien</i>
  </ng-template>
</div>

<mat-menu #rootMenu="matMenu" [overlapTrigger]="false">
  <ng-template matMenuContent let-element="element">
    <button mat-menu-item (click)="openRenameDialog(element)">
      <mat-icon>edit</mat-icon>
      <span>Rename</span>
    </button>
    <button mat-menu-item (click)="deleteElement(element)">
      <mat-icon>delete</mat-icon>
      <span>Löschen</span>
    </button>
  </ng-template>
</mat-menu>
