import { Pipe, PipeTransform } from '@angular/core';

import { TextTemplateType } from '../../../generated-client/generated-client';

@Pipe({
  name: 'textTemplateType',
  standalone: true
})
export class TextTemplateTypePipe implements PipeTransform {
  transform(value: TextTemplateType): string {
    switch (value) {
      case TextTemplateType.OfferEnd:
        return 'Schlusstext Angebot';
      case TextTemplateType.OfferStart:
        return 'Vortext Angebot';
      case TextTemplateType.SubContractorOfferRequestStart:
        return 'Vortext Angebotsaufforderung Subunternehmer';
      case TextTemplateType.SubContractorOfferRequestEnd:
        return 'Schlusstext Angebotsaufforderung Subunternehmer';
      case TextTemplateType.ServiceSpecificationStart:
        return 'Vortext LV';
      case TextTemplateType.ServiceSpecificationEnd:
        return 'Schlusstext LV';
      case TextTemplateType.InvoiceStart:
        return 'Vortext Rechnung';
      case TextTemplateType.InvoiceEnd:
        return 'Schlusstext Rechnung';
    }
    return null;
  }
}
