import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';
import { Router } from '@angular/router';

import { ProjectGet } from 'app/generated-client/generated-client';
import { ProjectsService } from 'app/shared/services/lightquery/projects.service';

import { ProjectStatusPipe } from '../../pipes/ui-data-display/project-status.pipe';

import { UpperPaginatorComponent } from '../upper-paginator/upper-paginator.component';
import { NgDanglIconsModule } from 'ng-dangl-icons';

type DataClickFunction = {
  dblClickRoute?: boolean; // default
  dblClickEvent?: boolean;
};

@Component({
  selector: 'pa-find-project-table',
  templateUrl: './find-project-table.component.html',
  styleUrls: ['./find-project-table.component.scss'],
  standalone: true,
  imports: [
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatRipple,
    NgDanglIconsModule,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    UpperPaginatorComponent,
    AsyncPipe,
    DatePipe,
    ProjectStatusPipe
  ]
})
export class FindProjectTableComponent {
  @Input() projectsService: ProjectsService;
  @Input() clickFunction: DataClickFunction;
  @Output() selectProject = new EventEmitter<ProjectGet>();
  displayedColumns = ['identicon', 'constructedProjectNumber', 'name', 'contactName', 'status', 'companyName', 'lastAccessedAtUtc'];
  pageSizeOptions: number[] = [5, 10];

  constructor(
    private router: Router,
    @Optional() private matDialogRef: MatDialogRef<FindProjectTableComponent>
  ) {}

  selectedProject(project: ProjectGet): void {
    if (this.clickFunction?.dblClickEvent) {
      this.selectProject.emit(project);
    } else {
      this.router.navigate(['projects']);
      setTimeout(() => {
        this.router.navigate(['projects', project.id, 'service-specifications']);
        this.matDialogRef.close();
      }, 100);
    }
  }
}
