import { Pipe, PipeTransform } from '@angular/core';

import { ProjectQuantityTakeOffType } from 'app/generated-client/generated-client';

@Pipe({
  name: 'projectQtoType',
  standalone: true
})
export class ProjectQtoTypePipe implements PipeTransform {
  transform(value: ProjectQuantityTakeOffType): unknown {
    switch (value) {
      case ProjectQuantityTakeOffType.Pages:
        return 'Seitenbasiert';
      case ProjectQuantityTakeOffType.Positions:
        return 'Positionsbasiert';
      case ProjectQuantityTakeOffType.All:
        return 'Alle';
      default:
        return 'Alle';
    }
  }
}
