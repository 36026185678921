import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'pa-change-name-modal',
  templateUrl: './change-name-modal.component.html',
  styleUrls: ['./change-name-modal.component.scss'],
  standalone: true,
  imports: [MatDialogContent, MatFormField, MatLabel, MatInput, FormsModule, MatDialogActions, MatButton, MatDialogClose]
})
export class ChangeNameModalComponent implements OnInit {
  name: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { name: string },
    @Optional() private matDialogRef: MatDialogRef<ChangeNameModalComponent>
  ) {}

  ngOnInit(): void {
    this.name = this.data?.name;
  }

  isChanged(): boolean {
    return this.name !== this.data?.name;
  }

  save(): void {
    this.matDialogRef.close(this.name);
  }
}
