import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ContactsComponent } from '@contacts/components/contacts/contacts.component';

import { ContactGet, ContactType } from 'app/generated-client/generated-client';

@Component({
  selector: 'pa-select-contact-modal',
  templateUrl: './select-contact-modal.component.html',
  styleUrls: ['./select-contact-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, ContactsComponent, MatButtonModule]
})
export class SelectContactModalComponent implements OnInit {
  contactType: ContactType;
  showCloseCancelButtons = false;
  selectedContact: ContactGet;
  constructor(
    @Optional() private matDialogRef: MatDialogRef<SelectContactModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { contactType: ContactType; showCloseCancelButtons?: boolean }
  ) {}

  ngOnInit(): void {
    if (this.data?.contactType) {
      this.contactType = this.data.contactType;
    }

    this.showCloseCancelButtons = !!this.data?.showCloseCancelButtons;
  }

  selectContact(event: ContactGet): void {
    if (this.showCloseCancelButtons) {
      this.selectedContact = event;
    } else {
      this.matDialogRef.close(event);
    }
  }

  close(): void {
    this.matDialogRef.close({ selection: this.selectedContact });
  }

  cancel(): void {
    this.matDialogRef.close({ selection: null });
  }
}
