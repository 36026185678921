import { NgIf } from '@angular/common';
import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { ElementSelectionGet, ElementSelectionPost, ElementSelectionsClient } from 'app/generated-client/generated-client';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';

import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

import { SelectionElementListTableComponent } from '../selection-element-list-table/selection-element-list-table.component';
import { SelectionElementNewComponent } from '../selection-element-new/selection-element-new.component';

@Component({
  selector: 'pa-selection-element-list',
  templateUrl: './selection-element-list.component.html',
  styleUrls: ['./selection-element-list.component.scss'],
  standalone: true,
  imports: [MatDialogContent, NgIf, FlexLayoutDirective, MatButton, SelectionElementListTableComponent]
})
export class SelectionElementListComponent implements OnInit {
  @ViewChild('listTable') listTable: SelectionElementListTableComponent;
  private $destroy = new Subject<boolean>();

  isOpenInModalWindow = false;
  projectId: string;
  avaProjectId: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private elementSelectionsClient: ElementSelectionsClient,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    @Inject(MAT_DIALOG_DATA) private data: { isOpenInModalWindow: boolean },
    @Optional() private matDialogRef: MatDialogRef<SelectionElementListComponent>
  ) {}

  ngOnInit(): void {
    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(takeUntil(this.$destroy))
      .subscribe((e: { avaProjectId: string; parentProjectId: string }) => {
        this.projectId = e?.parentProjectId;
        this.avaProjectId = e?.avaProjectId;
      });
    if (this.data) {
      this.isOpenInModalWindow = this.data.isOpenInModalWindow;
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  chooseItem(line: ElementSelectionGet): void {
    if (!this.isOpenInModalWindow) {
      this.router.navigate(['item', { item: line.id }], { relativeTo: this.route });
    } else {
      this.elementSelectionsClient
        .getElementSelectionDetailById(this.projectId, this.avaProjectId, line.id)
        .pipe(take(1))
        .subscribe((elementSelection) => {
          this.matDialogRef.close(elementSelection.data);
        });
    }
  }

  back(): void {
    this.router.navigate(['..', 'calculation'], { relativeTo: this.route });
  }

  newSelection(): void {
    this.modalService
      .openModal(SelectionElementNewComponent, { dialogType: ConfirmationType.General, restoreFocus: false, autoFocus: false })
      .afterClosed()
      .subscribe((e: ElementSelectionPost) => {
        if (e) {
          this.elementSelectionsClient.createElementSelectionForAvaProject(this.projectId, this.avaProjectId, e).subscribe(() => {
            this.listTable.refresh();
          });
        }
      });
  }
}
