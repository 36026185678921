import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AutoUpdateCheckerService } from './auto-update-checker.service';

import { UpdateCheckComponent } from '../components/update-check/update-check.component';

@Injectable({
  providedIn: 'root'
})
export class AutoUpdateService {
  hasCheckedForVersion = false;

  constructor(private autoUpdateCheckerService: AutoUpdateCheckerService, private matDialog: MatDialog) {}

  checkForVersionAndPromptUser(ignoreSkipped?: boolean): Observable<boolean> {
    return this.autoUpdateCheckerService.checkForNewVersion().pipe(
      tap((newVersionAvailable) => {
        if (newVersionAvailable) {
          const latestVersion = this.autoUpdateCheckerService.getLatestVersion();
          const shouldSkip = !ignoreSkipped && this.autoUpdateCheckerService.shouldSkipThisVersion(latestVersion);
          if (!shouldSkip) {
            if (!this.shouldIgnoreUpdateChecks()) {
              this.matDialog.open(UpdateCheckComponent, {
                data: latestVersion
              });
            }
          }
        }
      })
    );
  }

  private hasCheckedIgnoreUpdateChecks = false;
  private ignoreUpdateChecksCache = false;

  private shouldIgnoreUpdateChecks(): boolean {
    if (this.hasCheckedIgnoreUpdateChecks) {
      return this.ignoreUpdateChecksCache;
    }

    this.ignoreUpdateChecksCache = window.process?.argv?.includes('ignoreUpdateCheck');
    this.hasCheckedIgnoreUpdateChecks = true;
    return this.ignoreUpdateChecksCache;
  }
}
