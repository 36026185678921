import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';

import { DialogConfig } from './../models/dialog-config.model';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public modalData;
  private dialogRef: MatDialogRef<any> | null = null;
  constructor(private dialog: MatDialog) {}

  public openModal<TModal, TData>(modal: ComponentType<TModal>, dialogConfig: DialogConfig): MatDialogRef<TModal> {
    return this.dialog.open(modal, dialogConfig);
  }

  setDialogRef(dialogRef: MatDialogRef<any> | null): void {
    this.dialogRef = dialogRef;
  }

  getDialog(): MatDialogRef<any> {
    return this.dialogRef;
  }
}
