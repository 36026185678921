import { Component, Inject, Optional } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';

import { MasterDataProductGet, PriceComponentType } from '../../../../../../generated-client/generated-client';
import { MasterDataProductsTableComponent } from '../../../../../master-data/components/master-data-products-table/master-data-products-table.component';

@Component({
  selector: 'pa-modal-add-item-to-table',
  templateUrl: './modal-add-item-to-table.component.html',
  styleUrls: ['./modal-add-item-to-table.component.scss'],
  standalone: true,
  imports: [MatDialogContent, MasterDataProductsTableComponent, MatDialogActions, MatButton, MatDialogClose]
})
export class ModalAddItemToTableComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public priceComponentType: PriceComponentType,
    @Optional() private matDialogRef: MatDialogRef<ModalAddItemToTableComponent>
  ) {}

  public selectElement(item: MasterDataProductGet): void {
    this.matDialogRef.close(item);
  }
}
