import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PriceInquiryTableService {
  private refreshPriceInquiryTableSource = new Subject<boolean>();
  refreshPriceInquiryTable = this.refreshPriceInquiryTableSource.asObservable();

  refresh(): void {
    this.refreshPriceInquiryTableSource.next(true);
  }
}
