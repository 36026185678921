<div fxFlexFill>
  <h2>Raumstruktur</h2>
  <div class="btn-wrap">
    <mat-icon color="primary" (click)="toggleShowedMode()" matTooltip="Anzeigen / Verstecken von Elementen">
      {{ !isShowMode ? 'visibility_off' : 'visibility' }}
    </mat-icon>
    <mat-icon color="primary" (click)="showedAllModel()" matTooltip="Alle Elemente">
      {{ !showedElements ? 'check_box' : 'indeterminate_check_box' }}
    </mat-icon>
    <button mat-raised-button color="primary" (click)="toggleAll(true)"><mat-icon>add</mat-icon> Alle</button>
    <button mat-raised-button color="primary" (click)="toggleAll()"><mat-icon>remove</mat-icon> Alle</button>
  </div>
  <pa-structure-item-bim-view *ngFor="let item of spatialStructure" [structureItem]="item"></pa-structure-item-bim-view>
</div>
