import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

@Pipe({
  name: 'projectCurrency',
  standalone: true
})
export class ProjectCurrencyPipe implements PipeTransform {
  constructor(private selectedSpecificationMessengerService: SelectedSpecificationMessengerService, private pipeNumber: DecimalPipe) {}

  transform(value: number, format = '1.2-2'): string {
    const currency = this.selectedSpecificationMessengerService.getCurrency();
    return typeof value === 'number' ? `${this.pipeNumber.transform(value, format)} ${currency}` : '';
  }
}
