import { NgIf, AsyncPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';

import { Subject } from 'rxjs';

import { ContactGet } from 'app/generated-client/generated-client';
import { ContactsService } from 'app/shared/services/lightquery/contacts.service';

import { UpperPaginatorComponent } from '../../../../../../shared/components/upper-paginator/upper-paginator.component';

@Component({
  selector: 'pa-modal-to-change-contact',
  templateUrl: './modal-to-change-contact.component.html',
  styleUrls: ['./modal-to-change-contact.component.scss'],
  standalone: true,
  imports: [
    MatDialogContent,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    UpperPaginatorComponent,
    NgIf,
    MatCheckbox,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    AsyncPipe
  ]
})
export class ModalToChangeContactComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) private sort: MatSort;
  displayedColumns: string[] = ['contactNumber', 'name'];
  filter: string = '';
  updateAvaProjectsBuyer = false;
  selectedContact: ContactGet;
  private $destroy = new Subject<boolean>();

  constructor(
    @Optional() public dialogRef: MatDialogRef<ModalToChangeContactComponent>,
    public contactsService: ContactsService
  ) {}

  ngOnInit(): void {
    this.setDataSource();
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private setDataSource(): void {
    this.contactsService.initService({
      filtering: this.filter,
      sorting: { propertyName: 'contactNumber', isDescending: true },
      sortTableObj: this.sort
    });
  }

  public selectElement(id: string): void {
    this.dialogRef.close(id);
  }

  setSelectedContact(row: ContactGet): void {
    this.selectedContact = row;
  }
}
