<form [formGroup]="newItemForm" mat-dialog-content>
  <div fxLayout="column">
    <mat-form-field appearance="fill">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" required />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Beschreibung</mat-label>
      <input matInput formControlName="description" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>EP</mat-label>
      <input matInput formControlName="price" required type="number" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Einheit</mat-label>
      <input matInput formControlName="unit" required />
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutGap="12px">
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="newItemForm.invalid || !isChanged"
      [mat-dialog-close]="newItemForm.value"
    >
      Erstellen
    </button>
    <button mat-button mat-raised-button color="warn" type="button" mat-dialog-close>Abbrechen</button>
  </div>
</form>
