import { Observable, of, tap } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface IHelpInformation {
  id: string;
  title: string;
  htmlContent: string;
  children?: IHelpInformation[];
}
@Injectable({
  providedIn: 'root'
})
export class HelpWindowService {
  constructor(private httpClient: HttpClient) {}

  private helpInformation: IHelpInformation[];

  getHelpInformation(): Observable<IHelpInformation[]> {
    if (this.helpInformation) {
      return of(this.helpInformation);
    }

    return this.httpClient.get<IHelpInformation[]>('/docs/docs.json').pipe(tap((r) => (this.helpInformation = r)));
  }

  getFlatStructure(data: IHelpInformation[], result: IHelpInformation[] = []): IHelpInformation[] {
    for (const item of data) {
      const { children, ...rest } = item;
      result.push(rest);
      if (children) {
        this.getFlatStructure(children, result);
      }
    }
    return result;
  }

  filterHelpInformation(data: IHelpInformation[], filter: string): IHelpInformation[] {
    return data.filter((item) => {
      const titleMatch = item.title.toLowerCase().includes(filter);
      const htmlContentMatch = item.htmlContent?.toLowerCase().includes(filter);

      return titleMatch || htmlContentMatch;
    });
  }
}
