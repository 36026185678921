<div
  class="btn-wrap"
  *ngIf="
    (isInvoiceInfo && quantityTakeOffId && ((isPages && quantityTakeOffPageId) || !isPages)) ||
    isCalcMode ||
    isEditor ||
    isSetting ||
    isScenario
  "
  fxLayout="row nowrap"
  fxLayoutGap="5px"
  #btnWrap
>
  <ng-container *ngIf="!isEditor">
    <ng-container *ngIf="!isCompactMenu; else compactMenu">
      <button mat-raised-button color="primary" [matMenuTriggerFor]="menu1" *ngIf="isCalcMode || isInvoiceInfo">
        Ansicht <mat-icon>arrow_right</mat-icon>
      </button>

      <button mat-raised-button color="primary" [matMenuTriggerFor]="menu2" *ngIf="!isQtoOnlyMode">
        Projektverwaltung <mat-icon>arrow_right</mat-icon>
      </button>
      <button mat-raised-button color="primary" (click)="generatePdf()" *ngIf="isQtoOnlyMode">Export / Drucken</button>

      <button mat-raised-button color="primary" [matMenuTriggerFor]="menu3" *ngIf="!isQtoOnlyMode">
        Letzte Projekte <mat-icon>arrow_right</mat-icon>
      </button>

      <button mat-raised-button color="primary" (click)="openInquiries()" *ngIf="isCalcMode">Preisanfragen</button>
      <button mat-raised-button color="primary" (click)="showQuantity()" *ngIf="modePage | includes: 'estimations'">Projektmengen</button>
      <button mat-raised-button color="primary" (click)="openEstimations()" *ngIf="isCalcMode">
        <mat-icon>straighten</mat-icon>
        <span>Projektmengenermittlung</span>
      </button>
      <button mat-raised-button color="primary" (click)="openMasterData()" *ngIf="!isQtoOnlyMode">
        <mat-icon>settings</mat-icon>
        <span>Stammdaten</span>
      </button>
    </ng-container>

    <ng-template #compactMenu>
      <button mat-raised-button color="primary" [matMenuTriggerFor]="menuCompact1" class="burger">
        <mat-icon [matMenuTriggerFor]="menuCompact1">menu</mat-icon>
      </button>
      <mat-menu #menuCompact1="matMenu">
        <button mat-menu-item color="primary" [matMenuTriggerFor]="menu1" *ngIf="isCalcMode || isInvoiceInfo">Ansicht</button>
        <button mat-menu-item color="primary" [matMenuTriggerFor]="menu2" *ngIf="!isQtoOnlyMode">Projektverwaltung</button>
        <button mat-menu-item color="primary" (click)="generatePdf()" *ngIf="isQtoOnlyMode">Export / Drucken</button>
        <button mat-menu-item color="primary" [matMenuTriggerFor]="menu3" *ngIf="!isQtoOnlyMode">Letzte Projekte</button>
        <button mat-menu-item color="primary" (click)="openInquiries()" *ngIf="isCalcMode">Preisanfragen</button>
        <button mat-menu-item color="primary" (click)="openEstimations()" *ngIf="isCalcMode">
          <mat-icon>straighten</mat-icon>
          <span>Projektmengenermittlung</span>
        </button>
        <button mat-menu-item color="primary" (click)="showQuantity()" *ngIf="modePage | includes: 'estimations'">Projektmengen</button>
        <button mat-menu-item color="primary" (click)="openMasterData()" *ngIf="!isQtoOnlyMode">
          <mat-icon>settings</mat-icon>
          <span>Stammdaten</span>
        </button>
      </mat-menu>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="isEditor">
    <ng-container *ngIf="!isCompactMenu; else compactMenuEdit">
      <button mat-raised-button color="primary" (click)="editorAdd()" [disabled]="isEditMode">Neues Element</button>
      <button mat-raised-button color="primary" (click)="editorRemove()" [disabled]="!selectedElement || isEditMode">
        Element Entfernen
      </button>
      <button mat-raised-button color="primary" (click)="editorEdit()" [disabled]="!selectedElement || isEditMode">Bearbeiten</button>
      <button
        mat-raised-button
        color="primary"
        [matMenuTriggerFor]="menuSort"
        [disabled]="(selectedElement && selectedElement.elementTypeDiscriminator !== 'ServiceSpecificationGroupDto') || isEditMode"
      >
        Reihenfolge & OZ
      </button>
      <button mat-raised-button color="primary" [matMenuTriggerFor]="menu2">Verwaltung <mat-icon>arrow_right</mat-icon></button>
      <button mat-raised-button color="primary" [matMenuTriggerFor]="menu3">Projekte <mat-icon>arrow_right</mat-icon></button>
      <button mat-raised-button color="primary" (click)="openMasterData()">
        <mat-icon>settings</mat-icon>
        <span>Stammdaten</span>
      </button>
      <button mat-raised-button color="primary" [matMenuTriggerFor]="menu1">Ansicht</button>
    </ng-container>

    <ng-template #compactMenuEdit>
      <button mat-raised-button color="primary" [matMenuTriggerFor]="menuCompact1" class="burger">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #menuCompact1="matMenu">
        <button mat-menu-item color="primary" (click)="editorAdd()" [disabled]="isEditMode">Neues Element</button>
        <button mat-menu-item color="primary" (click)="editorRemove()" [disabled]="!selectedElement || isEditMode">
          Element Entfernen
        </button>
        <button mat-menu-item color="primary" (click)="editorEdit()" [disabled]="!selectedElement || isEditMode">Bearbeiten</button>
        <button
          mat-menu-item
          color="primary"
          [matMenuTriggerFor]="menuSort"
          [disabled]="(selectedElement && selectedElement.elementTypeDiscriminator !== 'ServiceSpecificationGroupDto') || isEditMode"
        >
          Reihenfolge & OZ
        </button>
        <button mat-menu-item color="primary" [matMenuTriggerFor]="menu2">Verwaltung</button>
        <button mat-menu-item color="primary" [matMenuTriggerFor]="menu3">Projekte</button>
        <button mat-menu-item color="primary" (click)="openMasterData()" *ngIf="!isQtoOnlyMode">
          <mat-icon>settings</mat-icon>
          <span>Stammdaten</span>
        </button>
        <button mat-menu-item color="primary" [matMenuTriggerFor]="menu1">Ansicht</button>
      </mat-menu>
    </ng-template>
    <mat-menu #menuSort="matMenu">
      <button
        mat-menu-item
        (click)="editorReorder()"
        [disabled]="(selectedElement && selectedElement.elementTypeDiscriminator !== 'ServiceSpecificationGroupDto') || isEditMode"
      >
        Elemente manuell sortieren
      </button>
      <button mat-menu-item (click)="editorAutoReorder()" class="btn-export">Elemente automatisch sortieren</button>
      <button mat-menu-item (click)="editorChangeNumbers()" class="btn-export">Ordnungszahlen neu erstellen</button>
      <button
        mat-menu-item
        (click)="editorChangeNumbersForCurrentGroup()"
        class="btn-export"
        [disabled]="!selectedElement || (selectedElement && selectedElement?.elementType !== 'ServiceSpecificationGroupDto')"
      >
        Ordnungszahlen in ausgewählter Gruppe neu erstellen
      </button>
    </mat-menu>
  </ng-container>
</div>

<mat-menu #menu1="matMenu">
  <ng-container *ngIf="!(modePage | includes: 'lv-editor')">
    <button mat-menu-item (click)="toggleTreeWidth()" [disabled]="isOpenTreeWindow">
      <mat-icon>list_alt</mat-icon>
      <mat-icon *ngIf="!isToggleTreeWidth">clear</mat-icon>
    </button>
    <ng-container *ngIf="isElectron && !isQtoOnlyMode">
      <button
        mat-menu-item
        matTooltip="LV Abdocken"
        (click)="openTreeWindow()"
        [disabled]="isOpenTreeWindow || isToggleTreeWidth"
        class="btn-flex"
      >
        <mat-icon>list_alt</mat-icon>
        <mat-icon>arrow_right_alt</mat-icon>
        <mat-icon>layers</mat-icon>
        <span class="spacer"></span>
        <mat-icon *ngIf="isOpenTreeWindow" class="right-align" color="warn" (click)="closeTreeWindow($event)">clear</mat-icon>
      </button>
      <button mat-menu-item (click)="openDetailWindow()" *ngIf="!isQtoOnlyMode" [disabled]="elementViewOpen" class="btn-flex">
        Abdocken
        <span class="spacer"></span>
        <mat-icon *ngIf="elementViewOpen" class="right-align" color="warn" (click)="closeElementWindow($event)">clear</mat-icon>
      </button>
      <button mat-menu-item (click)="openLongTextWindow()" *ngIf="!isQtoOnlyMode" [disabled]="isLongTextViewOpen" class="btn-flex">
        Langtext
        <span class="spacer"></span>
        <mat-icon *ngIf="isLongTextViewOpen" class="right-align" color="warn" (click)="closeLongTextWindow($event)">clear</mat-icon>
      </button>
    </ng-container>
    <ng-container *ngIf="!isCalcMode && !isQtoOnlyMode">
      <button mat-menu-item (click)="openSheetsWindow()" [disabled]="isOpenSheetsWindow">
        <div class="btn-flex">
          <span class="btn-text">Mengenvergleich</span>
          <span class="spacer"></span>
          <mat-icon *ngIf="isOpenSheetsWindow" class="right-align" color="warn" (click)="closeSheetsWindow($event)">clear</mat-icon>
        </div>
      </button>
    </ng-container>
  </ng-container>
  <mat-button-toggle-group [(ngModel)]="groupView" (ngModelChange)="changeGroupView($event)">
    <mat-button-toggle value="Tree">Hierarchie</mat-button-toggle>
    <mat-button-toggle value="List">Liste</mat-button-toggle>
    <mat-button-toggle value="PositionList">Positionen</mat-button-toggle>
    <mat-button-toggle value="Table">Tabelle</mat-button-toggle>
  </mat-button-toggle-group>
</mat-menu>

<mat-menu #menu2="matMenu">
  <ng-container *ngIf="!isEditor">
    <button mat-menu-item (click)="generatePdf()" class="btn-export" *ngIf="isCalcMode || isInvoiceInfo || isScenario">
      Export / Drucken
    </button>
    <button mat-menu-item (click)="openCheckWindow()" *ngIf="isCalcMode || isScenario">Prüfen</button>
    <button mat-menu-item (click)="openCalculationEstimation()" *ngIf="isCalcMode">Kalkulationsschätzung</button>
    <button mat-menu-item (click)="openMarkedElementsWindow()" *ngIf="isCalcMode || isScenario">Markierte Elemente</button>
    <button mat-menu-item (click)="showProjectArticles()" [disabled]="isOpenSheetsWindow">Projektartikel & Geräte</button>
    <button mat-menu-item (click)="showServiceSpecification()" *ngIf="isCalcMode || isScenario">LV-Tabelle</button>
    <button mat-menu-item (click)="showOtherAdditions()" *ngIf="isCalcMode">Zuschlagssimulation</button>
    <button mat-menu-item (click)="showMainPositionTable()" *ngIf="isCalcMode || isInvoiceInfo">Schwerpunkt LV</button>
    <button mat-menu-item (click)="openAttachmentsModalWindow()" *ngIf="isInvoiceInfo">Anlagen verwalten</button>
    <button mat-menu-item (click)="openCheckFormulaErrorsModalWindow()" *ngIf="isInvoiceInfo">Fehlerprüfung</button>
    <button mat-menu-item (click)="showDeduction()" *ngIf="isCalcMode">Vertragsbedingungen</button>
    <button mat-menu-item (click)="showSettingsData()" *ngIf="(isCalcMode || isInvoiceInfo) && !isQtoOnlyMode">
      <mat-icon>show_chart</mat-icon>
      <span>Einstellungen</span>
    </button>
    <button mat-menu-item (click)="showElementEditor()" *ngIf="(isCalcMode || isInvoiceInfo) && !isQtoOnlyMode">
      <mat-icon>playlist_add</mat-icon>
      <span>LV-Editor</span>
    </button>
    <button mat-menu-item (click)="showQuickEditTable()" *ngIf="isCalcMode || isInvoiceInfo">
      <mat-icon>table_chart</mat-icon>
      <span>LV-Tabelle</span>
    </button>
  </ng-container>
  <button mat-menu-item (click)="openServiceSpecificationTable()">
    <mat-icon>account_tree</mat-icon>
    <span>Leistungsverzeichnisse</span>
  </button>
  <button mat-menu-item (click)="openProjectManagement()">
    <mat-icon>build</mat-icon>
    <span>Projekteinstellungen</span>
  </button>
  <button mat-menu-item (click)="openProjectFiles()" *ngIf="!isCalcMode">
    <mat-icon>attach_file</mat-icon>
    <span>Dateien</span>
  </button>
</mat-menu>

<mat-menu #menu3="matMenu">
  <ng-container *ngFor="let project of fiveLastOpenedProjects">
    <button mat-menu-item (click)="selectProject(project)">{{ project.constructedProjectNumber }} - {{ project.name }}</button>
  </ng-container>
</mat-menu>
