import { NgIf, NgFor, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatFooterCellDef,
  MatFooterCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow,
  MatFooterRowDef,
  MatFooterRow
} from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';

import { Subject, combineLatest, forkJoin, of } from 'rxjs';
import { filter, first, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { SubcontractorsPriceLevelExportComponent } from '@serv-spec/components/subcontractors-price-level-export/subcontractors-price-level-export.component';

import { PositionDto } from 'app/generated-client/generated-client';
import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { SelectingPriceComponentModalComponent } from 'app/shared/components/selecting-price-component-modal/selecting-price-component-modal.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';
import { PositionHasCalculationService } from 'app/shared/services/position-has-calculation.service';

import { PositionTextPipe } from '../../../../../../shared/pipes/ui-data-display/position-text.pipe';
import { PriceInquiryStatusPipe } from '../../../../../../shared/pipes/ui-data-display/price-inquiry-status.pipe';
import { ProjectCurrencyPipe } from '../../../../../../shared/pipes/ui-data-display/project-currency.pipe';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';
import { FlatElementsService } from '../../../../../tree/services/flat-elements.service';

import {
  AvaProjectAssumedQuantitiesGet,
  AvaProjectsClient,
  PriceInquiryRequestBidderPriceImportPut,
  PriceInquiryRequestGet,
  PriceInquiryRequestPositionPriceGet,
  PriceInquiryRequestPositionPricesGet,
  PriceInquiryRequestPriceCommerceArticleGet,
  PriceInquiryRequestsClient,
  PriceInquiryStatus,
  ProjectDto,
  ProjectGet
} from './../../../../../../generated-client/generated-client';

import { PriceInquiriesArticlesModalComponent } from '../price-inquiries-articles-modal/price-inquiries-articles-modal.component';
import {
  SubcontractorsSetPriceComponent,
  SubcontractorsSetPriceData
} from '../subcontractors-set-price/subcontractors-set-price.component';

@Component({
  selector: 'pa-subcontractors-price-level',
  templateUrl: './subcontractors-price-level.component.html',
  styleUrls: ['./subcontractors-price-level.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutDirective,
    MatButton,
    NgIf,
    MatProgressSpinner,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatFooterCellDef,
    MatFooterCell,
    NgFor,
    NgClass,
    MatTooltip,
    MatIcon,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatFooterRowDef,
    MatFooterRow,
    ProjectCurrencyPipe,
    PriceInquiryStatusPipe,
    PositionTextPipe
  ]
})
export class SubcontractorsPriceLevelComponent implements OnInit, OnDestroy {
  @Output() accepted = new EventEmitter<boolean>();
  @Input() showAssumedQuantities = false;
  private $destroy: Subject<boolean> = new Subject<boolean>();
  priceInquiryRequests: PriceInquiryRequestGet[];
  priceInquiryRequestsById: { [priceInquiryId: string]: PriceInquiryRequestGet } = {};
  pricesByRequest: {
    [requestId: string]: {
      [avaPositionId: string]: PriceInquiryRequestPositionPriceGet;
    };
  } = {};
  displayedColumns: string[] = [];
  columnsForSecondFooter: string[] = [];
  minMaxPositionPrices: {
    [positionId: string]: {
      minValue: number;
      maxValue: number;
    };
  };
  totalSumsByPriceInquiryRequestId: { [priceInquiryRequestId: string]: number };
  maxTotalValue: number;
  minTotalValue: number;
  serviceSpecificationList: PositionDto[];
  priceInquiryId: string;
  projectId: string;
  avaProjectId: string;
  requestId: string;
  priceInquiryRequestPositionPrice: PriceInquiryRequestPositionPricesGet[] = [];
  isLoading = true;
  rewrite: boolean;
  priceInquiryStatus = PriceInquiryStatus;
  private assumedQuanities: AvaProjectAssumedQuantitiesGet;

  constructor(
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private modalService: ModalService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private priceInquiryRequestsClient: PriceInquiryRequestsClient,
    private route: ActivatedRoute,
    private flatElementsService: FlatElementsService,
    private notificationsService: AvaNotificationsService,
    private positionHasCalculationService: PositionHasCalculationService,
    private avaProjectsClient: AvaProjectsClient
  ) {}

  ngOnInit(): void {
    this.flatElementsService.flatElementsDto.pipe(takeUntil(this.$destroy)).subscribe((positions) => {
      this.serviceSpecificationList = positions.filter((p) => p.elementTypeDiscriminator === 'PositionDto');
      this.calculateValues();
    });

    this.loadPriceRequestsData();

    if (this.showAssumedQuantities) {
      this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(first()).subscribe((s) => {
        this.avaProjectsClient.getAssumedQuantitiesForAvaProject(s.parentProjectId, s.avaProjectId).subscribe((assumedQuanities) => {
          this.assumedQuanities = assumedQuanities;
          this.calculateValues();
        });
      });
    }
  }

  private loadPriceRequestsData(): void {
    combineLatest([
      this.selectedProjectMessengerService.selectedProject,
      this.selectedSpecificationMessengerService.selectedServiceSpecification
    ])
      .pipe(
        takeUntil(this.$destroy),
        switchMap(([project, s]: [ProjectGet, { avaProjectId: string; project: ProjectDto }]) => {
          this.projectId = project.id;
          this.avaProjectId = s.avaProjectId;
          this.priceInquiryId = this.route.snapshot.paramMap.get('item');
          return this.priceInquiryRequestsClient.getAllPriceInquiryRequestsForPriceInquiry(project.id, s.avaProjectId, this.priceInquiryId);
        })
      )
      .subscribe((p: PriceInquiryRequestGet[]) => {
        this.priceInquiryRequests = p;
        p.forEach((priceInquiryRequest) => {
          this.priceInquiryRequestsById[priceInquiryRequest.id] = priceInquiryRequest;
        });
        const staticColumns = ['itemNumber', 'shortText'];
        this.displayedColumns = staticColumns.concat(this.priceInquiryRequests.map((v) => v.id));
        this.columnsForSecondFooter = staticColumns.concat(this.priceInquiryRequests.map((v) => v.id)).map((column) => 'column-' + column);

        this.getPriceInquiryRequestPositionPrices();
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  getPriceInquiryRequestPositionPrices(): void {
    const requestArrays = this.priceInquiryRequests.map((v) => {
      return this.priceInquiryRequestsClient.getPositionPricesForPriceInquiryRequest(
        this.projectId,
        this.avaProjectId,
        this.priceInquiryId,
        v.id
      );
    });
    if (requestArrays.length) {
      forkJoin(requestArrays).subscribe((p: PriceInquiryRequestPositionPricesGet[]) => {
        this.priceInquiryRequestPositionPrice = p;
        p.forEach((priceInquiryRequestData) => {
          this.pricesByRequest[priceInquiryRequestData.requestId] = {};

          priceInquiryRequestData.positionPrices.forEach((positionPrice) => {
            this.pricesByRequest[priceInquiryRequestData.requestId][positionPrice.avaPositionId] = positionPrice;
          });

          this.calculateValues();
          this.isLoading = false;
        });
      });
    } else {
      this.isLoading = false;
    }
  }

  private calculateValues(): void {
    if (this.serviceSpecificationList == null) {
      return;
    }

    this.getMinValue();
    this.getTotalSum();
  }

  getMinValue(): void {
    this.minMaxPositionPrices = {};
    this.serviceSpecificationList.forEach((position) => {
      this.minMaxPositionPrices[position.id] = {
        maxValue: null,
        minValue: null
      };
    });

    Object.keys(this.pricesByRequest).forEach((requestId) => {
      Object.keys(this.pricesByRequest[requestId]).forEach((positionId) => {
        const requestPrice = this.pricesByRequest[requestId][positionId].unitPrice;
        const currentMinMax = this.minMaxPositionPrices[positionId];
        if (requestPrice != null) {
          if (currentMinMax.minValue === null || requestPrice < currentMinMax.minValue) {
            currentMinMax.minValue = requestPrice;
          }

          if (currentMinMax.maxValue === null || requestPrice > currentMinMax.maxValue) {
            currentMinMax.maxValue = requestPrice;
          }
        }
      });
    });
  }

  getTotalSum(): void {
    this.totalSumsByPriceInquiryRequestId = {};
    this.minTotalValue = null;
    this.maxTotalValue = null;

    this.priceInquiryRequestPositionPrice.forEach((priceInquiryRequestPositionPrice) => {
      this.totalSumsByPriceInquiryRequestId[priceInquiryRequestPositionPrice.requestId] = 0;
      priceInquiryRequestPositionPrice.positionPrices.forEach((positionPrice) => {
        if (positionPrice.unitPrice != null) {
          const position = this.serviceSpecificationList.find((p) => p.id == positionPrice.avaPositionId);
          if (position) {
            let quantity = position.quantity;
            if (
              this.assumedQuanities &&
              this.assumedQuanities.assumedQuantitiesByPositionId &&
              this.assumedQuanities.assumedQuantitiesByPositionId[position.id] != null
            ) {
              quantity = this.assumedQuanities.assumedQuantitiesByPositionId[position.id];
            }
            const positionTotal = quantity * positionPrice.unitPrice;
            this.totalSumsByPriceInquiryRequestId[priceInquiryRequestPositionPrice.requestId] += positionTotal;
          }
        }
      });

      if (
        this.minTotalValue == null ||
        this.totalSumsByPriceInquiryRequestId[priceInquiryRequestPositionPrice.requestId] < this.minTotalValue
      ) {
        this.minTotalValue = this.totalSumsByPriceInquiryRequestId[priceInquiryRequestPositionPrice.requestId];
      }

      if (
        this.maxTotalValue == null ||
        this.totalSumsByPriceInquiryRequestId[priceInquiryRequestPositionPrice.requestId] > this.maxTotalValue
      ) {
        this.maxTotalValue = this.totalSumsByPriceInquiryRequestId[priceInquiryRequestPositionPrice.requestId];
      }
    });
  }

  openSetPriceModal(positionId: string, priceInquiryRequestId: string): void {
    const currentPrice = this.pricesByRequest[priceInquiryRequestId][positionId]?.unitPrice;

    const modalData: SubcontractorsSetPriceData = {
      companyName: this.priceInquiryRequests.find((pr) => pr.id === priceInquiryRequestId)?.name,
      position: this.serviceSpecificationList.find((p) => p.id === positionId),
      unitPrice: currentPrice
    };

    this.modalService
      .openModal(SubcontractorsSetPriceComponent, { dialogType: ConfirmationType.General, data: modalData })
      .afterClosed()
      .pipe(take(1))
      .subscribe((userSetUnitPrice: number | null) => {
        if (userSetUnitPrice != null) {
          this.isLoading = true;
          this.priceInquiryRequestsClient
            .setPositionPrice(this.projectId, this.avaProjectId, this.priceInquiryId, priceInquiryRequestId, {
              positionId: positionId,
              unitPrice: userSetUnitPrice
            })
            .subscribe(() => {
              this.getPriceInquiryRequestPositionPrices();
            });
        }
      });
  }

  acceptPriceInquiry(request: PriceInquiryRequestGet): void {
    this.rewrite = false;
    (this.priceInquiryRequests.find((p) => p.status === PriceInquiryStatus.Accepted)
      ? this.modalService
          .openModal(ModalConfirmComponent, {
            dialogType: ConfirmationType.General,
            data: ['Erneut übernehmen', 'Bieterpreise', 'Für diese Preisanfragen wurden bereits Preise übernommen.'],
            autoFocus: false,
            restoreFocus: false
          })
          .afterClosed()
          .pipe(
            filter((res) => res === true),
            switchMap(() =>
              this.modalService
                .openModal(ModalConfirmComponent, {
                  dialogType: ConfirmationType.General,
                  data: ['Überschreiben', 'Bieterpreise', `Vorhandene Bieterpreise überschreiben mit: ${request.name}`],
                  autoFocus: false,
                  restoreFocus: false
                })
                .afterClosed()
                .pipe(
                  tap((isRewrite: boolean) => (this.rewrite = isRewrite)),
                  // We always return true, if the user doesn't chose to overwrite the existing prices,
                  // we'll just add them again
                  map(() => true)
                )
            )
          )
      : of(true)
    )
      .pipe(
        filter((res) => res === true),
        switchMap(() =>
          this.modalService
            .openModal(ModalConfirmComponent, {
              dialogType: ConfirmationType.General,
              data: ['Übernehmen', 'Bieterpreise', `Bieter: ${request.name}`],
              autoFocus: false,
              restoreFocus: false
            })
            .afterClosed()
        )
      )
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.modalService
            .openModal(SelectingPriceComponentModalComponent, {
              dialogType: ConfirmationType.General,
              restoreFocus: false,
              autoFocus: false
            })
            .afterClosed()
            .subscribe((priceComponent: PriceInquiryRequestBidderPriceImportPut) => {
              if (priceComponent) {
                priceComponent.overwriteExisting = this.rewrite;
                this.priceInquiryRequestsClient
                  .importBidderPrices(this.projectId, this.avaProjectId, this.priceInquiryId, request.id, priceComponent)
                  .subscribe({
                    next: () => {
                      this.isLoading = true;
                      this.notificationsService.success('Preise für Bieter wurden im LV eingespielt.');
                      this.accepted.emit(true);
                      this.positionHasCalculationService.updateList();
                      this.priceInquiryRequestsClient
                        .getAllPriceInquiryRequestsForPriceInquiry(this.projectId, this.avaProjectId, this.priceInquiryId)
                        .pipe(take(1))
                        .subscribe((p) => {
                          this.priceInquiryRequests = p;
                          this.isLoading = false;
                        });
                    },
                    error: () => this.notificationsService.error('Die Bieterpreise konnten nicht eingespielt werden.')
                  });
              }
            });
        }
      });
  }

  openExportModal(): void {
    this.modalService.openModal(SubcontractorsPriceLevelExportComponent, {
      dialogType: ConfirmationType.General,
      restoreFocus: false,
      autoFocus: false,
      data: { priceInquiryId: this.priceInquiryId }
    });
  }

  openArticles(list: PriceInquiryRequestPriceCommerceArticleGet[]): void {
    this.modalService.openModal(PriceInquiriesArticlesModalComponent, {
      dialogType: ConfirmationType.General,
      restoreFocus: false,
      autoFocus: false,
      data: { list }
    });
  }
}
