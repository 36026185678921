import { Pipe, PipeTransform } from '@angular/core';

import { AvaFileTargetType } from '../../../generated-client/generated-client';

@Pipe({
  name: 'avaFileTargetType',
  standalone: true
})
export class AvaFileTargetTypePipe implements PipeTransform {
  transform(value: AvaFileTargetType): string {
    switch (value) {
      case AvaFileTargetType.Gaeb90:
        return 'GAEB 90';

      case AvaFileTargetType.Gaeb2000:
        return 'GAEB 2000';

      case AvaFileTargetType.GaebXmlV31:
        return 'GAEB XML 3.1';

      case AvaFileTargetType.GaebXmlV32:
        return 'GAEB XML 3.2';

      case AvaFileTargetType.GaebXmlV33:
        return 'GAEB XML 3.3';

      case AvaFileTargetType.OenormLv2015:
        return 'ÖNorm A2063';

      case AvaFileTargetType.Excel:
        return 'Excel';
    }

    return null;
  }
}
