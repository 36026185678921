import { Component } from '@angular/core';
import { MatTabGroup, MatTab } from '@angular/material/tabs';

import { MainTreeComponent } from 'app/areas/tree/components/main-tree/main-tree.component';

import { DetailBimViewComponent } from '../detail-bim-view/detail-bim-view.component';

@Component({
  selector: 'pa-right-side-bim-view',
  templateUrl: './right-side-bim-view.component.html',
  styleUrls: ['./right-side-bim-view.component.scss'],
  standalone: true,
  imports: [MatTabGroup, MatTab, DetailBimViewComponent, MainTreeComponent]
})
export class RightSideBimViewComponent {}
