import { NgIf, NgFor } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSelect } from '@angular/material/select';

import { Subject, filter, first, switchMap, takeUntil } from 'rxjs';

import { AvaNotificationsService } from '@shared/services/ava-notifications.service';
import { QuantityTakeOffsService } from '@shared/services/lightquery/quantity-take-offs.service';
import { SelectedSpecificationMessengerService } from '@shared/services/messengers/selected-specification-messenger.service';

import {
  PageQuantityTakeOffCalculationDataModel,
  PageQuantityTakeOffGet,
  PageQuantityTakeOffsClient,
  QuantityTakeOffCalculationType,
  QuantityTakeOffGet
} from 'app/generated-client/generated-client';

import { getAppConfig } from '../../../../../../../../app-config-accessor';
import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-copy-invoice-page',
  templateUrl: './copy-invoice-page.component.html',
  styleUrls: ['./copy-invoice-page.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatDialogTitle,
    FormsModule,
    MatDialogContent,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatCheckbox,
    MatSelect,
    MatOption,
    NgFor,
    FlexLayoutDirective,
    MatButton,
    MatProgressSpinner
  ]
})
export class CopyInvoicePageComponent implements OnInit, OnDestroy {
  newPageForm: FormGroup;
  private avaProjectId: string;
  private projectId: string;
  quantityTakeOffId: string | null = null;
  listParentQTO: QuantityTakeOffGet[] = [];
  pageData: PageQuantityTakeOffCalculationDataModel = null;
  isLoading = false;
  isPageNumberManually = true;
  isQtoOnlyMode = getAppConfig().isQtoOnlyMode;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      structureView: string;
      page?: PageQuantityTakeOffGet;
      quantityTakeOffId?: string;
      isStandalonePage?: boolean;
      isRemovePage?: boolean;
    },
    @Optional() private matDialogRef: MatDialogRef<CopyInvoicePageComponent>,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private quantityTakeOffsService: QuantityTakeOffsService,
    private pageQuantityTakeOffsClient: PageQuantityTakeOffsClient,
    private notificationsService: AvaNotificationsService
  ) {}

  ngOnInit(): void {
    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        filter((r) => !!r),
        first(),
        takeUntil(this.$destroy),
        switchMap((s: { avaProjectId: string; parentProjectId: string }) => {
          this.avaProjectId = s.avaProjectId;
          this.projectId = s.parentProjectId;

          return this.pageQuantityTakeOffsClient.getPageQuantityTakeOffById(this.projectId, this.avaProjectId, this.data.page?.id);
        })
      )
      .subscribe((page: PageQuantityTakeOffGet) => {
        this.pageData = page.pageData;
      });

    this.quantityTakeOffsService.getAll().subscribe((e) => {
      this.listParentQTO = e?.filter((item: QuantityTakeOffGet) => item.calculationType === QuantityTakeOffCalculationType.ByPage);
      if (this.data.isRemovePage && !this.data.isStandalonePage) {
        this.listParentQTO = this.listParentQTO?.filter((item: QuantityTakeOffGet) => item.id !== this.data.quantityTakeOffId);
      }
    });

    this.newPageForm = new FormGroup({
      name: new FormControl(this.data?.isRemovePage ? this.data.page.name : this.data.page?.name || '', Validators.required),
      subContractorId: new FormControl(this.data.page?.subContractorId || null),
      customPageNumber: new FormControl(this.data.page?.pageNumber || ''),
      newBuildingElementCode: new FormControl(''),
      existingBuildingElementCodeId: new FormControl(''),
      identifier: new FormControl(''),
      pageNumber: new FormControl({ value: '', disabled: true })
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  cancel(): void {
    this.matDialogRef.close();
  }

  createPage(): void {
    this.isLoading = true;
    const data = { ...this.newPageForm.value };

    if (this.quantityTakeOffId) {
      data.quantityTakeOffId = this.quantityTakeOffId;
    }

    if (this.data?.isRemovePage && this.data.page?.id) {
      this.pageQuantityTakeOffsClient
        .movePageQuantityTakeOff(this.projectId, this.avaProjectId, this.data.page?.id, {
          targetParentId: data.quantityTakeOffId
        })
        .subscribe({
          next: (page) => {
            this.notificationsService.success('Das Aufmaßblatt wurde verschoben.');
            this.isLoading = false;
            this.matDialogRef.close(page);
          },
          error: () => {
            this.notificationsService.error('Das Aufmaßblatt konnte nicht verschoben werden.');
            this.isLoading = false;
          }
        });

      return;
    }

    this.pageQuantityTakeOffsClient.createPageQuantityTakeOff(this.projectId, this.avaProjectId, data).subscribe({
      next: (page: PageQuantityTakeOffGet) => {
        this.pageQuantityTakeOffsClient
          .updatePageQuantityTakeOffCalculationData(this.projectId, this.avaProjectId, page.id, this.pageData)
          .subscribe({
            next: () => {
              this.notificationsService.success('Das Aufmaßblatt wurde erstellt.');
              this.isLoading = false;
              this.matDialogRef.close(page);

              if (this.data?.isRemovePage) {
                this.pageQuantityTakeOffsClient.deletePageQuantityTakeOff(this.projectId, this.avaProjectId, this.data.page.id).subscribe({
                  error: () => {
                    this.notificationsService.error('Das Aufmaßblatt konnte nicht gelöscht werden.');
                    this.isLoading = false;
                  }
                });
              }
            },
            error: () => {
              this.notificationsService.error('Fehler beim Speichern.');
              this.isLoading = false;
            }
          });
      },
      error: () => {
        this.notificationsService.error('Das Aufmaßblatt konnte nicht erstellt werden.');
        this.isLoading = false;
      }
    });
  }

  changedQto(event): void {
    this.quantityTakeOffId = event.value;
  }

  changeSelection(event): void {
    this.isPageNumberManually = event.checked;
    if (this.isPageNumberManually) {
      this.newPageForm.get('pageNumber').enable();
    } else {
      this.newPageForm.get('pageNumber').disable();
    }
  }
}
