<div class="filter-field">
  <mat-form-field class="filter-field__input">
    <mat-label>Suche</mat-label>
    <input matInput [(ngModel)]="filter" (ngModelChange)="masterDataProductsService.onFilter($event)" />
  </mat-form-field>
</div>

<div class="mat-elevation-z8">
  <pa-table-wrapper [offsetFromMaxHeightTable]="480">
    <table mat-table [dataSource]="masterDataProductsService" matSort (matSortChange)="masterDataProductsService.onSort($event)">
      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="articleNumber">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Artikelnummer</th>
        <td mat-cell *matCellDef="let element">{{ element.articleNumber }}</td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Beschreibung</th>
        <td mat-cell *matCellDef="let element">{{ element.description }}</td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th mat-header-cell class="header-right" mat-sort-header *matHeaderCellDef>EP</th>
        <td mat-cell class="right" *matCellDef="let element">{{ element.price | currency: 'EUR' }}</td>
      </ng-container>

      <ng-container matColumnDef="unit">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Einheit</th>
        <td mat-cell *matCellDef="let element">{{ element.unit }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="master-table-tr"
        [class.small]="noSeparate"
        (click)="selectElement(row)"
        (dblclick)="dblSelectElement(row)"
        [class.mark]="showedId === row.id"
      ></tr>
    </table>
  </pa-table-wrapper>
  <pa-upper-paginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    [upperPaginated]="masterDataProductsService.paginationResult | async"
    [upperService]="masterDataProductsService"
    (page)="masterDataProductsService.onPage($event)"
  >
  </pa-upper-paginator>
</div>
