import { NgIf, NgClass, AsyncPipe, DecimalPipe, PercentPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatMenuTrigger, MatMenu, MatMenuContent, MatMenuItem } from '@angular/material/menu';
import { MatProgressBar } from '@angular/material/progress-bar';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilKeyChanged, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { CurrentPositionCalculationGetService } from '@serv-spec/services/current-position-calculation-get.service';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';
import { ModePageService } from 'app/areas/tree/services/mode-page.service';
import {
  PositionCalculationGet,
  PositionDto,
  PositionInvoiceTotalGet,
  QuantityTakeOffPositionSumsClient,
  UserSettings
} from 'app/generated-client/generated-client';
import { ShowingColumnsModalComponent } from 'app/shared/components/showing-columns-modal/showing-columns-modal.component';
import { TotalInvoiceSumType } from 'app/shared/models';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { TreeViewCommandType } from 'app/shared/models/tree-view-command.model';
import { ContextMenuSettingsService } from 'app/shared/services/context-menu-settings.service';
import { TreeViewMessengerService } from 'app/shared/services/electron/tree-view-messenger.service';
import { QuantityTakeOffInvoiceTotalsMessengerService } from 'app/shared/services/messengers/quantity-take-off-invoice-totals-messenger.service';
import { SelectedQuantityTakeOffMessengerService } from 'app/shared/services/messengers/selected-quantity-take-off-messenger.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';
import { SelectRowsService } from 'app/shared/services/select-rows.service';
import { ShowingColumnsService } from 'app/shared/services/showing-columns.service';
import { UserSettingsService } from 'app/shared/services/user-settings.service';

import { IncludesPipe } from '../../../../../../shared/pipes/includes.pipe';
import { ProjectCurrencyPipe } from '../../../../../../shared/pipes/ui-data-display/project-currency.pipe';
import { SelectedSpecificationMessengerService } from '../../../../../../shared/services/messengers/selected-specification-messenger.service';
import { AvaProjectDiffApplier } from '../../../../../../shared/services/signalr/ava-project-diff-applier';

@Component({
  selector: 'pa-total-sums',
  templateUrl: './total-sums.component.html',
  styleUrls: ['./total-sums.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    MatProgressBar,
    MatMenu,
    MatMenuContent,
    MatMenuItem,
    MatButton,
    MatMenuTrigger,
    AsyncPipe,
    DecimalPipe,
    PercentPipe,
    IncludesPipe,
    ProjectCurrencyPipe,
    FlexLayoutDirective
  ]
})
export class TotalSumsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() isCompact: boolean;
  @Input() isTreeOpen: boolean;
  @Input() isInnerWindow: boolean;
  @Input() selectedElement: PositionDto;
  @Input() isToggleTreeWidth: boolean;
  @ViewChildren(MatMenuTrigger) private contextMenu: QueryList<MatMenuTrigger>;
  @ViewChild('additionalInfo') additionalInfo: ElementRef;
  @Output() setTotalSumsHeight = new EventEmitter<number>();

  totalSum: number;
  allTotalSum: number;
  serviceSpecificationTotalSum: number;
  positionTotal: number;
  pageQTOTotal: number;
  positionQuantity: number;
  selectedRowsSumma: number = null;
  selectedRowsQuantity: number = null;
  avaPositionId: string | null = null;
  modePage: string;
  percentegByActivePosition: number;
  totalInvoicedPerPosition: number;
  totalQuantityPerPosition: number;

  currentElementCalc: PositionCalculationGet;
  percentOfCalcPositions: number;
  rangeOfCalcPositions: string;
  positionsCalculatedPercent: number;
  currentInvoiceName: string;
  private $destroy: Subject<boolean> = new Subject();

  contextMenuPosition = { x: '0px', y: '0px' };
  defaultDisplayedColumns: string[] = [];
  userSettings: UserSettings = {} as UserSettings;
  showingColumns: string[] = [];

  private projectId: string;
  private avaProjectId: string;
  resizeObserver: ResizeObserver;

  constructor(
    public quantityTakeOffInvoiceTotalsMessengerService: QuantityTakeOffInvoiceTotalsMessengerService,
    private treeViewMessengerService: TreeViewMessengerService,
    public selectRowsService: SelectRowsService,
    public currentPositionCalculationGetService: CurrentPositionCalculationGetService,
    private selectedQuantityTakeOffMessengerService: SelectedQuantityTakeOffMessengerService,
    private contextMenuSettingsService: ContextMenuSettingsService,
    private modalService: ModalService,
    private userSettingsService: UserSettingsService,
    private showingColumnsService: ShowingColumnsService,
    public selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private quantityTakeOffPositionSumsClient: QuantityTakeOffPositionSumsClient,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private zone: NgZone,
    private modePageService: ModePageService,
    private avaProjectDiffApplier: AvaProjectDiffApplier,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.changedModePage();
    this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(takeUntil(this.$destroy)).subscribe((avaProject) => {
      if (avaProject) {
        this.avaProjectId = avaProject.avaProjectId;
        this.projectId = avaProject.parentProjectId;
      }
    });

    let shouldForceRefreshPositionTotal = false;
    this.selectedSpecificationElementMessengerService.selectedElement
      .pipe(
        takeUntil(this.$destroy),
        filter(
          (selectedElement) =>
            selectedElement?.element?.elementTypeDiscriminator === 'PositionDto' && !!this.projectId && !!this.avaProjectId
        ),
        // We don't want to refresh the total if we're in the calculation mode, since
        // the total invoiced amount isn't shown there
        filter(() => this.modePage != 'calculation'),
        distinctUntilKeyChanged('id', (a, b) => a === b && !shouldForceRefreshPositionTotal),
        map((el) => el.id),
        debounceTime(300),
        switchMap((id) => {
          shouldForceRefreshPositionTotal = false;
          return this.quantityTakeOffPositionSumsClient.getTotalInvoicedQuantityForPositionInAvaProject(
            this.projectId,
            this.avaProjectId,
            id
          );
        })
      )
      .subscribe((positionTotals: PositionInvoiceTotalGet) => {
        this.setPositionInvoiceTotal(positionTotals);
      });

    this.quantityTakeOffInvoiceTotalsMessengerService.changedAvaPositionId.pipe(takeUntil(this.$destroy)).subscribe(() => {
      shouldForceRefreshPositionTotal = true;
    });

    this.quantityTakeOffInvoiceTotalsMessengerService.currentInvoiceTotalSum
      .pipe(takeUntil(this.$destroy))
      .subscribe((e: TotalInvoiceSumType) => {
        const selectedElementId = this.selectedElement?.id;
        if (selectedElementId) {
          this.quantityTakeOffPositionSumsClient
            .getTotalInvoicedQuantityForPositionInAvaProject(this.projectId, this.avaProjectId, selectedElementId)
            .subscribe((positionTotals) => this.setPositionInvoiceTotal(positionTotals));
        }

        this.totalSum = e?.totalSum;
        this.allTotalSum = e?.allTotalSum;
        this.serviceSpecificationTotalSum = e?.serviceSpecificationTotalSum;
        this.positionTotal = e?.positionTotalSum;
        this.pageQTOTotal = e?.pageTotalSum;
        this.positionQuantity = e?.positionQuantity;

        if (!this.isInnerWindow) {
          this.treeViewMessengerService.sendDataToTreeView({
            command: TreeViewCommandType.ChangeQTOTotal,
            data: [
              this.totalSum,
              this.allTotalSum,
              this.serviceSpecificationTotalSum,
              this.positionTotal,
              this.pageQTOTotal,
              this.positionQuantity
            ]
          });
        }
      });

    this.selectRowsService.selectedRowSumma.pipe(takeUntil(this.$destroy)).subscribe((summa: number[]) => {
      if (!this.isInnerWindow) {
        this.treeViewMessengerService.sendDataToTreeView({
          command: TreeViewCommandType.ChangeSelectRow,
          data: summa
        });
      }
      if (summa) {
        [this.selectedRowsSumma, this.selectedRowsQuantity] = summa;
      } else {
        [this.selectedRowsSumma, this.selectedRowsQuantity] = [null, null];
      }
      this.cdr.markForCheck();
    });

    this.currentPositionCalculationGetService.currentPositionCalc
      .pipe(takeUntil(this.$destroy))
      .subscribe((element: PositionCalculationGet) => {
        this.currentElementCalc = element;
        if (element) {
          this.percentOfCalcPositions = (element.positionsCalculated * 100) / element.totalPositions;
          this.rangeOfCalcPositions = `${element.positionsCalculated} / ${element.totalPositions}`;
          this.positionsCalculatedPercent = element.positionsCalculated / element.totalPositions;
        }
        this.cdr.markForCheck();
      });

    this.avaProjectDiffApplier.avaProjectDiffApplied.pipe(takeUntil(this.$destroy)).subscribe((appliedAvaProjectDiff) => {
      if (appliedAvaProjectDiff.avaProjectDiff?.serviceSpecificationDiff?.some((op) => op.path?.indexOf('totalPrice') > -1)) {
        this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(take(1)).subscribe((p) => {
          if (p && this.currentElementCalc) {
            this.currentElementCalc.serviceSpecificationTotal = p.project.serviceSpecifications[0].totalPrice;
            this.cdr.markForCheck();
          }
        });
      }
    });

    this.showingColumns = this.defaultDisplayedColumns;

    this.userSettingsService.currentFullSettings.pipe(takeUntil(this.$destroy)).subscribe((setting: UserSettings) => {
      this.userSettings = setting;
      // Here, we need to wait for the user settings to properly propagate everywhere, so the
      // showingColumnsService has loaded the latest settings
      setTimeout(() => {
        this.showingColumns = this.showingColumnsService.getFilteredColumns('TotalSums', this.defaultDisplayedColumns);
        this.cdr.markForCheck();
      }, 10);

      this.cdr.markForCheck();
    });

    this.selectedQuantityTakeOffMessengerService.selectedQuantityTakeOff
      .pipe(takeUntil(this.$destroy))
      .subscribe((e) => (this.currentInvoiceName = e?.name));

    this.modePageService.modePage.pipe(takeUntil(this.$destroy)).subscribe((modePage) => {
      this.modePage = modePage;
      this.changedModePage();
    });
  }

  ngAfterViewInit(): void {
    if (this.modePage?.includes('invoice')) {
      this.setTotalSumsHeight.emit(this.additionalInfo.nativeElement.offsetHeight);
      let height = this.additionalInfo.nativeElement.offsetHeight;
      this.resizeObserver = new ResizeObserver(() => {
        if (this.additionalInfo.nativeElement.offsetHeight !== 0 && height !== this.additionalInfo.nativeElement.offsetHeight) {
          height = this.additionalInfo.nativeElement.offsetHeight;
          this.zone.run(() => {
            this.setTotalSumsHeight.emit(height);
          });
        }
      });
      this.resizeObserver.observe(this.additionalInfo.nativeElement);
    }
  }

  private setPositionInvoiceTotal(positionTotals: PositionInvoiceTotalGet): void {
    if (this.selectedElement?.id === positionTotals.avaPositionId) {
      if (positionTotals.avaProjectTotalPositionQuantity !== 0) {
        this.totalQuantityPerPosition = positionTotals.totalInvoicedQuantity;
        this.totalInvoicedPerPosition = positionTotals.totalInvoicedSum;
        const percentage = positionTotals.totalInvoicedQuantity / positionTotals.avaProjectTotalPositionQuantity;
        this.percentegByActivePosition = percentage;
      } else {
        this.percentegByActivePosition = 0;
        this.totalQuantityPerPosition = 0;
        this.totalInvoicedPerPosition = 0;
      }
      this.cdr.markForCheck();
    }
  }

  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.additionalInfo.nativeElement);
    }
    this.setTotalSumsHeight.emit(0);
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  showHeaderContextMenu(event: MouseEvent): void {
    this.contextMenuSettingsService.setDefaultSettings(event, null, this.contextMenuPosition, this.contextMenu.first);
  }

  editListColumns(): void {
    this.modalService.openModal(ShowingColumnsModalComponent, {
      dialogType: ConfirmationType.General,
      data: {
        component: 'TotalSums',
        columns: this.defaultDisplayedColumns
      },
      disableClose: true,
      autoFocus: false
    });
  }

  changedModePage(): void {
    if (!this.modePage?.includes('positions')) {
      this.defaultDisplayedColumns = [
        'totalSum',
        'allTotalSum',
        'serviceSpecificationTotalSum',
        'selectedRowsSumma',
        'pageQTOTotal',
        'selectedRowsQuantity',
        'totalInvoicedPerPosition',
        'totalQuantityPerPosition'
      ];
    } else {
      this.defaultDisplayedColumns = [
        'totalSum',
        'allTotalSum',
        'serviceSpecificationTotalSum',
        'selectedRowsSumma',
        'pageQTOTotal',
        'positionTotal',
        'positionQuantity',
        'selectedRowsQuantity',
        'totalInvoicedPerPosition',
        'totalQuantityPerPosition'
      ];
    }
  }
}
