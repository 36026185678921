import { NgFor, NgIf } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';

import { take } from 'rxjs';

import { ShowedViewsService } from '@shared/services/showed-views.service';

import { EmbeddedViewNamesPipe } from '../../pipes/ui-data-display/embedded-view-names.pipe';

@Component({
  selector: 'pa-select-views',
  templateUrl: './select-views.component.html',
  styleUrls: ['./select-views.component.scss'],
  standalone: true,
  imports: [MatDialogContent, NgFor, MatCheckbox, FormsModule, NgIf, MatDialogActions, MatButton, MatDialogClose, EmbeddedViewNamesPipe]
})
export class SelectViewsComponent implements OnInit {
  list: { name: string; checked: boolean }[];
  separatedList: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { default: string[]; showed: string[] },
    @Optional() private matDialogRef: MatDialogRef<SelectViewsComponent>,
    private showedViewsService: ShowedViewsService
  ) {}

  ngOnInit(): void {
    this.list = this.data.default?.map((item) => ({
      name: item,
      checked: this.data.showed.includes(item)
    }));

    this.showedViewsService.showedSeparatedViews.pipe(take(1)).subscribe((separate) => {
      this.separatedList = separate.map((item) => item.name);
    });
  }

  save(): void {
    this.matDialogRef.close(this.list.filter((item) => item.checked).map((item) => item.name));
  }
}
