<p>Hier kann ein Projekt aus einer Sicherungsdatei wieder eingespielt werden.</p>

<input hidden type="file" [multiple]="false" (change)="setFile([fileInput.files.item(0)])" #fileInput />
<button mat-button mat-raised-button color="primary" (click)="fileInput.click()">Datei Auswählen</button>

<p *ngIf="selectedFileName">Datei: {{ selectedFileName }}</p>

<div>
  <mat-form-field *ngIf="companies" appearance="fill">
    <mat-label>Firma für Projektimport</mat-label>
    <mat-select name="newContact" [(ngModel)]="selectedCompany">
      <mat-option *ngFor="let contact of companies" [value]="contact">{{ contact.name }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<button mat-raised-button color="primary" *ngIf="selectedFileName && !isRunning && selectedCompany" (click)="restoreProject()">
  Projekt Wiederherstellen
</button>
<mat-spinner *ngIf="isRunning"></mat-spinner>
