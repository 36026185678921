import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { DOCUMENT, NgIf, NgClass, AsyncPipe, DecimalPipe } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadge } from '@angular/material/badge';
import { MatButton } from '@angular/material/button';
import { MatButtonToggleGroup, MatButtonToggle } from '@angular/material/button-toggle';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatMenuTrigger, MatMenu, MatMenuContent, MatMenuItem } from '@angular/material/menu';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { GuidGenerator } from '@dangl/angular-material-shared/guid-generator';

import { combineLatest, filter, fromEvent, map, Observable, Subject, Subscription, takeUntil } from 'rxjs';

import { InvoicePdfFileComponent } from '@serv-spec/components/invoice/components/invoice-pdf-file/invoice-pdf-file.component';
import { CheckFormulaErrorService } from '@serv-spec/components/invoice/services/check-formula-error.service';
import { ChangeTotalService } from '@serv-spec/services/change-total.service';
import { KeyupControlService } from '@serv-spec/services/keyup-control.service';
import { LimitColumnWidthService } from '@serv-spec/services/limit-column-width.service';
import { ManageInvoiceTableService } from '@serv-spec/services/manage-invoice-table.service';

import { OverwriteModeMessengerService } from '@shared/services/messengers/overwrite-mode-messenger.service';

import { ProjectFilesComponent } from '@project/components/project-files/components/project-files/project-files.component';
import { ProjectCurrentItemsService } from '@project/components/project-files/services/project-current-items.service';
import { SelectedFileToUploadService } from '@project/components/project-files/services/selected-file-to-upload.service';

import {
  ApiErrorOfFormulaError,
  FormulaErrorType,
  IElementDto,
  PositionDto,
  PositionQuantityTakeOffGet,
  PositionQuantityTakeOffModel,
  PositionQuantityTakeOffRowModel,
  PositionQuantityTakeOffsClient,
  ProjectDto,
  ProjectFileGet,
  ProjectGet,
  QuantityTakeOffGet,
  QuantityTakeOffRowType,
  ServiceSpecificationGroupDto,
  UserSettings
} from 'app/generated-client/generated-client';
import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { ShowingColumnsModalComponent } from 'app/shared/components/showing-columns-modal/showing-columns-modal.component';
import { StyleEnumType } from 'app/shared/models';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { ClipboardService } from 'app/shared/services/clipboard.service';
import { ContextMenuSettingsService } from 'app/shared/services/context-menu-settings.service';
import { TreeViewMessengerService } from 'app/shared/services/electron/tree-view-messenger.service';
import { QuantityTakeOffInvoiceTotalsMessengerService } from 'app/shared/services/messengers/quantity-take-off-invoice-totals-messenger.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedQuantityTakeOffMessengerService } from 'app/shared/services/messengers/selected-quantity-take-off-messenger.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';
import { SelectRowsService } from 'app/shared/services/select-rows.service';
import { ShowingColumnsService } from 'app/shared/services/showing-columns.service';
import { AvaHubConnector } from 'app/shared/services/signalr/ava-hub-connector';
import { UserSettingsService } from 'app/shared/services/user-settings.service';
import { dateUTC } from 'app/shared/utilities/dateUTC';

import { PositionLineComponent } from '../../../../../../../../shared/components/position-line/position-line.component';
import { SelectAllRowsComponent } from '../../../../../../../../shared/components/select-all-rows/select-all-rows.component';
import { FormulaWarningDirective } from '../../../../../../../../shared/directives/formula-warning.directive';
import { InputMaskDirective } from '../../../../../../../../shared/directives/input-mask.directive';
import { OverwriteModeDirective } from '../../../../../../../../shared/directives/overwrite-mode.directive';
import { ImgSrcPipe } from '../../../../../../../../shared/pipes/img-src.pipe';
import { ProjectCurrencyPipe } from '../../../../../../../../shared/pipes/ui-data-display/project-currency.pipe';
import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';
import { TotalSumsComponent } from '../../../total-sums/total-sums.component';
import { ResizableDirective } from '../../directives/resizable.directive';
import { SetFontStyleService } from '../../services/set-font-style.service';
import { TreeDisplayService } from '../../services/tree-display.service';

import { InvoiceImageModalComponent } from '../invoice-image-modal/invoice-image-modal.component';
import { InvoiceModalForDeletingImageComponent } from '../invoice-modal-for-deleting-image/invoice-modal-for-deleting-image.component';
import { Calculator } from 'antlr-calculator';
import { TableVirtualScrollDataSource, TableVirtualScrollModule } from 'ng-table-virtual-scroll';

interface AllPositionQuantityTakeOffRowModel extends PositionQuantityTakeOffRowModel {
  avaPositionId?: string;
  itemNumber?: string;
  isTopic?: boolean;
}

@Component({
  selector: 'pa-invoice-positions-all',
  templateUrl: './invoice-positions-all.component.html',
  styleUrls: ['./invoice-positions-all.component.scss'],
  providers: [OverwriteModeMessengerService],
  standalone: true,
  imports: [
    NgIf,
    MatProgressSpinner,
    FlexLayoutDirective,
    MatButton,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatTooltip,
    MatDatepicker,
    PositionLineComponent,
    CdkVirtualScrollViewport,
    TableVirtualScrollModule,
    MatTable,
    CdkDropList,
    NgClass,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    CdkDrag,
    MatCellDef,
    MatCell,
    SelectAllRowsComponent,
    CdkDragHandle,
    ResizableDirective,
    OverwriteModeDirective,
    FormulaWarningDirective,
    InputMaskDirective,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    TotalSumsComponent,
    MatMenuTrigger,
    MatMenu,
    MatMenuContent,
    MatMenuItem,
    MatButtonToggleGroup,
    MatButtonToggle,
    MatBadge,
    AsyncPipe,
    DecimalPipe,
    ProjectCurrencyPipe,
    ImgSrcPipe
  ]
})
export class InvoicePositionsAllComponent implements OnInit, OnDestroy {
  @Input() isReadOnlyOriginal: boolean;
  get isReadOnly(): boolean {
    return this.isReadOnlyOriginal || this.isSelectingFormulaRowMode;
  }
  @ViewChildren(MatMenuTrigger) private contextMenu: QueryList<MatMenuTrigger>;
  @ViewChild(MatTable) private table: MatTable<any>;
  @HostListener('document:mouseup', ['$event']) handleMouseUpEvent(event: MouseEvent): void {
    this.stopSelectRow(event);
  }
  @HostListener('document:keydown', ['$event'])
  handleGlobalKeyboardEvent(event: KeyboardEvent): void {
    switch (event.key) {
      case 'F11':
      case 'F12':
        if (this.isChanged) {
          this.recalculate(-1);
        }
        break;
      case 'c':
      case 'C':
        if (event.ctrlKey) {
          if (this.listSelectedRows.length && !getSelection().toString()) {
            this.saveRows();
            event.preventDefault();
          }
          if (getSelection().toString()) {
            this.saveRows(true);
            this.notificationsService.info('Text kopiert', 'Kopie:', 3000);
          }
        }
        break;
      case 'F5':
        if (this.listSelectedRows.length) {
          this.cutSelectedRowsPlus();
        }
        break;
      case 'Delete':
        this.listSelectedRows = event.altKey ? [this.lastFocusedRowIndex] : [...this.listSelectedRows];
        if (this.listSelectedRows.length && !this.isReadOnly) {
          this.deleteSelectedRowsPlus(event.altKey);
        }
        break;
      case 'F7':
        if (this.listSelectedRows.length) {
          this.isSelectingFormulaRowMode = true;
          this.notificationsService.info('Zeile zum Einfügen der Referenz auswählen');
        }
        break;
      case 'Escape':
        if (this.isSelectingFormulaRowMode) {
          this.isSelectingFormulaRowMode = false;
        }
        break;
      default:
    }
  }

  userSettings: UserSettings = {} as UserSettings;
  isShowReferenceName: boolean;
  isSelectingFormulaRowMode: boolean;
  contextMenuPosition = { x: '0px', y: '0px' };
  tableDataSource = new TableVirtualScrollDataSource<AllPositionQuantityTakeOffRowModel>();
  get dataSource(): AllPositionQuantityTakeOffRowModel[] {
    const avaPositionId = this.avaPositionId;
    return this.tableDataSource.data.filter((item) => item.avaPositionId === avaPositionId);
  }
  set dataSource(value: AllPositionQuantityTakeOffRowModel[]) {
    if (this.avaPositionId && this.positions[this.avaPositionId] && this.tableDataSource) {
      const avaPositionId = this.avaPositionId;
      const itemNumber = this.positions[avaPositionId].itemNumber.stringRepresentation;
      value.forEach((item) => {
        item['itemNumber'] = itemNumber;
        item['avaPositionId'] = avaPositionId;
      });
      const start = this.tableDataSource.data.findIndex((item) => item.avaPositionId === avaPositionId);
      if (!this.tableDataSource.data.length) {
        this.tableDataSource.data = value;
      } else if (start > -1) {
        const filteredData = this.tableDataSource.data.filter((item) => item.avaPositionId === avaPositionId);
        this.tableDataSource.data.splice(start, filteredData.length, ...value);
      } else {
        this.tableDataSource.data.push(...value);
      }
      this.tableDataSource.data = [...this.tableDataSource.data];
      this.table?.renderRows();
    }
  }
  savedSelectedRows: PositionQuantityTakeOffRowModel[] = [] as PositionQuantityTakeOffRowModel[];
  _defaultDisplayedColumns: string[] = [
    'itemNumber',
    'index',
    'rowType',
    'formula',
    'result',
    'unit',
    'referenceName',
    'totalCost',
    'image'
  ];
  defaultDisplayedColumns: string[];
  displayedColumns: string[] = [];
  listSelectedRows: number[] = this.selectRowsService.init();
  isLoading = true;
  invoicePositionInvoiceName: string;
  isLoadReport = false;
  structureView: string;
  lastFocusedRowIndex: number;
  avaPositionId: string = null;
  isChanged: boolean;
  unitTag: string;
  elementDto: IElementDto;
  url: string;
  isCompact = true;
  isTreeOpen = false;
  selectedPosition: PositionDto = null;
  isToggleTreeWidth = false;
  circularErrorRowIndizes: { [avaPositionId: string]: { [rowIndex: number]: boolean } } = {};
  lastRecalculationHadError = false;
  isImageContextMenu: boolean;
  performanceQtoDate = new FormGroup({
    performancePeriodStartUtc: new FormControl(),
    performancePeriodEndUtc: new FormControl()
  });

  private clipboardWord = 'positionQto'; // variable to change by setting object
  private quantityTakeOffGetResults = new Subject<PositionQuantityTakeOffGet>();
  private listMove: number[];
  private activeElement: PositionQuantityTakeOffRowModel;
  private activeRow: HTMLElement;
  private activeRowIndex: number;
  private avaProjectId: string;
  private calculationData: PositionQuantityTakeOffModel;
  private calculationInProgress = false;
  private calculationQueue: { avaPositionId: string; positionCalculationData: PositionQuantityTakeOffModel }[] = [];
  private dialogRef: MatDialogRef<ProjectFilesComponent>;
  private projectId: string;
  private quantityTakeOffId: string;
  private selectedFile: File;
  private $destroy: Subject<boolean> = new Subject<boolean>();
  isCursorOutsideText = true;
  private lastEnteredPosition: { projectId: string; avaProjectId: string; quantityTakeOffId: string; positionId?: string };
  totalSumHeight = 0;
  columnName: string;
  mousemove$: Subscription;
  positions: { [positionId: string]: PositionDto } = {};
  private flatPositions: PositionDto[] = [];
  lastFocusedElementId: string | null = null;
  nameStorageOrderColumn = 'orderColumns-allPositionsQto';

  constructor(
    private contextMenuSettingsService: ContextMenuSettingsService,
    private keyupControlService: KeyupControlService,
    private manageInvoiceTableService: ManageInvoiceTableService,
    private modalService: ModalService,
    private positionQuantityTakeOffsClient: PositionQuantityTakeOffsClient,
    private projectCurrentItemsService: ProjectCurrentItemsService,
    private router: Router,
    private route: ActivatedRoute,
    private selectedFileToUploadService: SelectedFileToUploadService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedQuantityTakeOffMessengerService: SelectedQuantityTakeOffMessengerService,
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private setFontStyleService: SetFontStyleService,
    private notificationsService: AvaNotificationsService,
    private userSettingsService: UserSettingsService,
    private changeTotalService: ChangeTotalService,
    private selectRowsService: SelectRowsService,
    private avaHubConnector: AvaHubConnector,
    private quantityTakeOffInvoiceTotalsMessengerService: QuantityTakeOffInvoiceTotalsMessengerService,
    private clipboardService: ClipboardService,
    private treeViewMessengerService: TreeViewMessengerService,
    private treeDisplayService: TreeDisplayService,
    private showingColumnsService: ShowingColumnsService,
    @Inject(DOCUMENT) private document: Document,
    public sanitizer: DomSanitizer,
    private el: ElementRef,
    private limitColumnWidthService: LimitColumnWidthService,
    private checkFormulaErrorService: CheckFormulaErrorService
  ) {}

  ngOnInit(): void {
    this.defaultDisplayedColumns = this.showingColumnsService.getDefaultDisplayedColumns(
      this.nameStorageOrderColumn,
      this._defaultDisplayedColumns
    );
    this.structureView = this.router.url.includes('invoices') ? 'invoices' : 'estimations';
    if (this.structureView === 'estimations') {
      this.displayedColumns = this.displayedColumns.filter((item: string) => item !== 'totalCost');
    }

    this.userSettingsService.currentFullSettings.pipe(takeUntil(this.$destroy)).subscribe((setting: UserSettings) => {
      this.userSettings = setting;
      if (!this.isLoading) {
        this.changeShowReferenceName();
      }
    });

    this.url = 'invoice' + this.router.url;

    this.avaHubConnector.userKicked.pipe(takeUntil(this.$destroy)).subscribe((kickResult) => {
      if (kickResult.isSuccess) {
        this.isReadOnlyOriginal = true;
      }
    });

    this.getQuantityTakeOff();

    this.selectedSpecificationElementMessengerService.selectedElement.pipe(takeUntil(this.$destroy)).subscribe((selectedElement) => {
      if (selectedElement?.element?.elementTypeDiscriminator === 'PositionDto') {
        this.selectedPosition = selectedElement.element as PositionDto;
        this.avaPositionId = this.selectedPosition.id;
        this.checkShowedPosition();
      }
    });

    this.clipboardService.getData(this.clipboardWord, this.savedSelectedRows);
    this.treeViewMessengerService.treeViewVisible.pipe(takeUntil(this.$destroy)).subscribe((isTreeOpen: boolean) => {
      this.isTreeOpen = isTreeOpen;
    });

    this.treeDisplayService.isTreeDisplay.pipe(takeUntil(this.$destroy)).subscribe((isTreeDisplay: boolean) => {
      this.isToggleTreeWidth = isTreeDisplay;
    });

    this.showingColumnsService.settingsLoaded.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.displayedColumns = this.showingColumnsService.getFilteredColumns('PositionQto', this.defaultDisplayedColumns);
    });

    this.selectRowsService.requestAllRows.pipe(takeUntil(this.$destroy)).subscribe(() => {
      const summa = this.dataSource.reduce(
        (sum: number, item: PositionQuantityTakeOffRowModel) => sum + (typeof item.totalCost === 'number' ? item.totalCost : 0),
        0
      );
      const quantity = this.dataSource.reduce(
        (sum: number, item: PositionQuantityTakeOffRowModel) => sum + (typeof item.result === 'number' ? item.result : 0),
        0
      );

      this.selectRowsService.setSelectedRowSumma(summa, quantity);
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
    this.selectRowsService.setSelectedRowSumma(null, null);
    if (this.projectId && this.avaProjectId && this.quantityTakeOffId) {
      this.avaHubConnector.notifyOfExitingQuantityTakeOffPositionQtoWithoutPosition(
        this.projectId,
        this.avaProjectId,
        this.quantityTakeOffId
      );
    }
    if (this.mousemove$) {
      this.mousemove$.unsubscribe();
    }

    this.tableDataSource.disconnect();
    this.tableDataSource = null;
  }

  changeShowReferenceName(): void {
    this.isShowReferenceName =
      this.userSettings.showReferenceNameInQuantityTakeOff ||
      this.dataSource.some((item: PositionQuantityTakeOffRowModel) => item.referenceName);
    this.changeShowingOfReferenceNameColumn(this.isShowReferenceName);
  }

  private setTotal(positionQto: PositionQuantityTakeOffGet): void {
    const positionQuantity = positionQto.positionData.rows
      .filter((r) => r.rowType === QuantityTakeOffRowType.Normal)
      .reduce((sum: number, item: PositionQuantityTakeOffRowModel) => (typeof item.result === 'number' ? item.result + sum : sum), 0);
    this.quantityTakeOffInvoiceTotalsMessengerService.setCurrentTotalSum({
      ...positionQto.calculation,
      positionTotalSum: positionQto.totalSum,
      positionQuantity
    });
  }

  private getIdsForQuantityRequest(): Observable<[ProjectGet, { avaProjectId: string; project: ProjectDto }, QuantityTakeOffGet]> {
    return combineLatest([
      this.selectedProjectMessengerService.selectedProject.pipe(
        takeUntil(this.$destroy),
        filter((project: ProjectDto) => !!project)
      ),
      this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(
        takeUntil(this.$destroy),
        filter((spec) => !!spec)
      ),
      this.selectedQuantityTakeOffMessengerService.selectedQuantityTakeOff.pipe(
        takeUntil(this.$destroy),
        filter((qto) => !!qto)
      )
    ]);
  }

  private getQuantityTakeOff(): void {
    this.getIdsForQuantityRequest()
      .pipe(takeUntil(this.$destroy))
      .subscribe(([project, avaProject, qto]: [ProjectGet, { avaProjectId: string; project: ProjectDto }, QuantityTakeOffGet]) => {
        this.initializePositionsList(avaProject.project.serviceSpecifications[0].elements);
        this.projectId = project.id;
        this.avaProjectId = avaProject.avaProjectId;
        this.invoicePositionInvoiceName = qto.name;
        this.quantityTakeOffId = qto.id;
        if (this.structureView === 'invoices' && !this.performanceQtoDate.value.performancePeriodStartUtc) {
          this.performanceQtoDate = new FormGroup({
            performancePeriodStartUtc: new FormControl({ value: dateUTC(qto.performancePeriodStartUtc, true), disabled: true }),
            performancePeriodEndUtc: new FormControl({ value: dateUTC(qto.performancePeriodEndUtc, true), disabled: true })
          });
        }

        if (qto.markedAsBilledAtUtc) {
          this.isReadOnlyOriginal = true;
        }

        this.avaHubConnector
          .tryEnterQuantityTakeOffPositionQtoWithoutPositionAsync(this.projectId, this.avaProjectId, this.quantityTakeOffId)
          .then((r) => {
            if (!r.isSuccess) {
              this.isReadOnlyOriginal = true;
              this.notificationsService.info('Diese Abrechnung wird gerade von einem anderen Benutzer bearbeitet.');
            }
          });

        this.positionQuantityTakeOffsClient
          .getAllPositionQuantityTakeOffs(this.projectId, this.avaProjectId, this.quantityTakeOffId)
          .pipe(takeUntil(this.$destroy))
          .subscribe((allPositions) => {
            allPositions.forEach((res: PositionQuantityTakeOffGet) => {
              this.avaPositionId = res.avaPositionId;
              if (this.positions[this.avaPositionId]) {
                this.dataSource = this.addingDataSource(res.positionData.rows);
              }
            });
            this.isLoading = false;
          });
        this.changeShowReferenceName();
      });
  }

  private setNullValueToFieldsDataSource(item: PositionQuantityTakeOffRowModel): void {
    this.manageInvoiceTableService.setNullValueToFieldsDataSource(item, this.dataSource);
    this.recalculate();
  }

  onContextMenu(event: MouseEvent, element: AllPositionQuantityTakeOffRowModel, columnName?: string): void {
    if (!this.isReadOnly && !element.isTopic) {
      if (this.avaPositionId !== element.avaPositionId) {
        this.avaPositionId = element.avaPositionId;
        this.selectedSpecificationElementMessengerService.trySelectElementById(this.avaPositionId);
      }
      this.columnName = columnName;
      this.isImageContextMenu = !!this.columnName && (event.target as HTMLElement).nodeName === 'IMG';
      this.activeRow = event.target['offsetParent'].parentNode;
      this.activeRowIndex = element?.rowIndex;
      this.activeElement = element;
      this.contextMenuSettingsService.setDefaultSettings(event, element, this.contextMenuPosition, this.contextMenu.first);
    }
  }

  addRow(nextRow: AllPositionQuantityTakeOffRowModel, addAfter: boolean, isRecalculate = false): void {
    const [topic, ...items] = this.dataSource;
    const tmpDataSource = this.manageInvoiceTableService.addRow(nextRow, addAfter, true, items);
    this.dataSource = [topic, ...tmpDataSource];

    if (isRecalculate) {
      this.recalculate();
    }
  }

  private copyRowAbove(event: KeyboardEvent): void {
    if (this.lastFocusedRowIndex > 1) {
      const copyRow = { ...this.dataSource[this.lastFocusedRowIndex - 2], rowIndex: this.lastFocusedRowIndex };
      this.dataSource = this.dataSource.map((item: PositionQuantityTakeOffRowModel, i: number) =>
        i === this.lastFocusedRowIndex - 1 ? { ...copyRow } : { ...item }
      );
      this.recalculate(0, event.target['id']);
    }
  }

  private copyPropAbove(event: KeyboardEvent, nameProp: string): void {
    const [topic, ...items] = this.dataSource;
    if (this.lastFocusedRowIndex > 1) {
      items[this.lastFocusedRowIndex - 1][nameProp] = items[this.lastFocusedRowIndex - 2][nameProp];
      this.dataSource = [topic, ...items];
      this.recalculate(this.lastFocusedRowIndex, event.target['id']);
    }
  }

  deleteRowPlus(item: AllPositionQuantityTakeOffRowModel): void {
    if (this.userSettings.promptForConfirmationWhenDeletingRows) {
      this.confirmationDeleting(item.rowIndex, () => this.deleteRow(item));
    } else {
      this.deleteRow(item);
    }
  }

  deleteRow(row): void {
    const warning = this.checkWarningForReference([row.rowIndex]);
    if (warning) {
      this.confirmationDeletingChecked(warning, () => this.deleteRowChecked(row));
    } else {
      this.deleteRowChecked(row);
    }
  }

  deleteRowChecked(item: AllPositionQuantityTakeOffRowModel): void {
    const [topic, ...items] = this.dataSource;
    const tmpDataSource = items.filter((el) => el.rowIndex !== item.rowIndex);
    tmpDataSource.forEach((row: AllPositionQuantityTakeOffRowModel) =>
      this.manageInvoiceTableService.changeRelatingNumber(row, item.rowIndex - 1, -1, [item.rowIndex])
    );
    this.dataSource = [topic, ...tmpDataSource];
    this.recalculate();
  }

  toggleFontStyle(type: string, row: PositionQuantityTakeOffRowModel): void {
    this.setFontStyleService.toggleFontStyle(StyleEnumType[type], row);
    this.recalculate();
  }

  onKey(event: KeyboardEvent): void {
    if (this.isCursorOutsideText) {
      this.keyupControlService.onKey(event, this.displayedColumns, null, {
        avaPositionId: this.avaPositionId,
        list: this.flatPositions
      });
    }
  }

  preventKeyDownEvent(event: KeyboardEvent, element: PositionQuantityTakeOffRowModel, nameProp: string): void {
    const target = event.target as HTMLInputElement;
    this.lastFocusedRowIndex = element.rowIndex;
    switch (event.key) {
      case 'F11':
      case 'F12':
        // The reason for this is that when we've changed a cell and then directly hit F11 or F12 to navigate,
        // the change event isn't fired correctly. This means that the last change will otherwise not be saved.
        (<any>this.document.activeElement)?.blur();
        break;
      case 'F4':
        this.copyPropAbove(event, nameProp);
        break;
      case 'F5':
        this.listSelectedRows = [this.lastFocusedRowIndex];
        this.cutSelectedRowsPlus();
        break;
      case 'Insert':
        if (this.userSettings.allowOverwriteMode && !event.altKey) {
          event.preventDefault();
        } else {
          this.addRow(element, false);
          this.recalculate(element.rowIndex, event.target['id']);
        }
        break;
      case 'v':
      case 'V':
        if (event.ctrlKey && this.savedSelectedRows.length) {
          this.copySavedRows(element, true);
          event.preventDefault();
        }
        break;
    }
    if (
      ((event.key === 'ArrowRight' && target.selectionStart !== target.value.length) ||
        (event.key === 'ArrowLeft' && target.selectionStart !== 0)) &&
      target.type === 'text'
    ) {
      this.isCursorOutsideText = false;
    } else {
      this.keyupControlService.preventKeyDownEvent(event);
      this.isCursorOutsideText = true;
    }
  }

  setElementFocus(event: Event): void {
    this.lastFocusedElementId = event.target['id'];
  }

  lostFocus(): void {
    this.lastFocusedElementId = null;
  }

  openBrowseFileModal(): void {
    const correlationId = GuidGenerator.generatePseudoRandomGuid();

    this.setSelectedFile(correlationId);
    this.manageBrowseFiles();

    this.dialogRef = this.modalService.openModal(ProjectFilesComponent, {
      dialogType: ConfirmationType.General,
      data: {
        modalData: 'browseView',
        correlationId,
        extensions: ['.jpg', '.jpeg', '.png', '.gif']
      }
    });
    this.dialogRef.addPanelClass('modal-browse');
  }

  private setSelectedFile(correlationId: string): void {
    this.selectedFile = null;
    this.selectedFileToUploadService.selectedFile
      .pipe(
        takeUntil(this.$destroy),
        filter((r) => r.correlationId === correlationId),
        map((r) => r.file)
      )
      .subscribe((file: File | ProjectFileGet) => {
        if (file && !file['lastModified']) {
          const [_topic, ...items] = this.dataSource;
          const row = items[this.activeElement?.rowIndex - 1];
          row.projectFileFolderId = file['folderId'];
          row.projectFileId = file['id'];
          row.fileId = file['file']['id'];

          this.recalculate();
          this.selectedFileToUploadService.setSelectedFile(null, null);
          this.dialogRef.close();
        }
      });
  }

  private manageBrowseFiles(): void {
    this.projectCurrentItemsService.projCurrItems.pipe(takeUntil(this.$destroy)).subscribe((filesList) => {
      if (filesList.includes('uploadedFile')) {
        this.uploadImage(filesList);
      }
    });
  }

  private uploadImage(filesList): void {
    let addedFile: ProjectFileGet;
    if (filesList && this.selectedFile) {
      addedFile = filesList.find((item) => item.file?.fileName === this.selectedFile.name);

      if (filesList.includes('uploadedFile') && this.selectedFile) {
        const reader: FileReader = new FileReader();
        reader.onload = () => {
          const [_topic, ...items] = this.dataSource;
          const row = items[this.activeElement?.rowIndex - 1];
          row.fileId = addedFile.file.id;
          row.projectFileId = addedFile.id;
          row.projectFileFolderId = addedFile.folderId;

          this.recalculate();
          this.dialogRef.close();
        };
        reader.readAsDataURL(this.selectedFile);
        this.selectedFileToUploadService.setSelectedFile(null, null);
        filesList.pop();
        this.projectCurrentItemsService.setProjCurrItems(filesList, 'allItems');
      }
    }
  }

  deleteImage(element: PositionQuantityTakeOffRowModel): void {
    this.modalService
      .openModal(InvoiceModalForDeletingImageComponent, {
        dialogType: ConfirmationType.General,
        data: {
          element: element
        }
      })
      .afterClosed()
      .subscribe({
        next: (response) => {
          if (response) {
            this.setNullValueToFieldsDataSource(element);
          }
        },
        error: (error) => console.error('error', error)
      });
  }

  recalculate(
    numRow?: number,
    focusElementId?: string,
    forceRecalculateAll?: boolean,
    avaPositionId?: string,
    positionCalculationData?: PositionQuantityTakeOffModel
  ): void {
    const currentAvaPositionId = avaPositionId || this.avaPositionId;
    const calculationData = positionCalculationData || this.calculationData;

    if (!this.projectId || !this.avaProjectId || !this.quantityTakeOffId || !currentAvaPositionId || !calculationData) {
      return;
    }

    if (this.calculationInProgress) {
      this.calculationQueue.push({
        avaPositionId: currentAvaPositionId,
        positionCalculationData: calculationData
      });
      return;
    }

    this.isChanged = false;
    this.calculationInProgress = true;
    this.circularErrorRowIndizes[currentAvaPositionId] = {};

    const tmpDataSource = this.cleanedDataSource(JSON.parse(JSON.stringify(this.getDataSource(currentAvaPositionId))));

    calculationData.rows = tmpDataSource;
    this.positionQuantityTakeOffsClient
      .updatePositionQuantityTakeOffById(this.projectId, this.avaProjectId, this.quantityTakeOffId, currentAvaPositionId, calculationData)
      .subscribe(
        (r: PositionQuantityTakeOffGet) => {
          this.setTotal(r);
          const noEditColumns = ['result', 'totalCost'];

          this.getDataSource(currentAvaPositionId).forEach((r) => {
            // Since we're working with a cached version here, we want to ensure that
            // we're setting the formulas of all the rows to the values that
            // are current in the UI.
            // If there's a (long) delay on the backend, then the user could already have
            // edited other formulas, which would otherwise be set back to their previous
            // state by the update parts below (from the cached 'tmpDataSource')
            const tmpRow = tmpDataSource.find((item) => item.rowIndex === r.rowIndex);
            if (tmpRow && tmpRow.formula !== r.formula) {
              tmpRow.formula = r.formula;
            }
          });

          const updateCell = (newCell, sourceCell) => {
            noEditColumns.forEach((key: string) => delete sourceCell[key]);
            const previousFormula = sourceCell.formula;
            Object.assign(sourceCell, newCell);
            if (previousFormula && previousFormula !== sourceCell.formula) {
              sourceCell.formula = previousFormula;
            }
            for (const prop in sourceCell) {
              if (newCell[prop] == null && prop !== 'formula') {
                delete sourceCell[prop];
              }
            }
          };

          const updateAllCells = () => {
            r.positionData.rows.forEach((item, index) => {
              if (JSON.stringify(item) !== JSON.stringify(tmpDataSource[index])) {
                if (tmpDataSource.length <= index) {
                  tmpDataSource[index] = { ...item, rowIndex: index + 1 };
                } else {
                  updateCell(item, tmpDataSource[index]);
                }
              }
            });
          };

          if (forceRecalculateAll || this.lastRecalculationHadError) {
            updateAllCells();
          } else if (numRow) {
            const itemN = r.positionData.rows[numRow - 1];
            if (itemN?.referenceName || r.positionData.rows[numRow - 1].referenceName) {
              // If the reference was changed, we want to update all cells in case there's a dependency
              // on the reference somewhere that should be refreshed
              updateAllCells();
            } else {
              updateCell(itemN, tmpDataSource[numRow - 1]);
            }
          } else {
            updateAllCells();
          }

          this.lastRecalculationHadError = false;

          if (r.positionData.rows.length > tmpDataSource.length) {
            const afterToAdd = tmpDataSource[tmpDataSource.length - 1].rowIndex;
            const newRows = r.positionData.rows.filter((r) => r.rowIndex > afterToAdd);
            tmpDataSource.push(...newRows);
          }

          this.setDataSource(currentAvaPositionId, this.addingDataSource(tmpDataSource));
          this.tableDataSource.data = [...this.tableDataSource.data];
          this.table?.renderRows();

          this.calculationInProgress = false;

          const updateScheduled = this.calculationQueue.length && this.calculationQueue[this.calculationQueue.length - 1];
          if (updateScheduled) {
            this.calculationQueue = this.calculationQueue.filter((id) => id.avaPositionId !== updateScheduled.avaPositionId);
            this.recalculate(null, null, null, updateScheduled.avaPositionId, updateScheduled.positionCalculationData);
          } else {
            this.selectedQuantityTakeOffMessengerService.setSomeChangesQuantityTakeOff();
          }

          let focusId = this.lastFocusedElementId || focusElementId;
          focusId = this.checkFormulaErrorService.checkErrorInFormulaAndSendIdForFocus(
            tmpDataSource[numRow - 1],
            this.userSettings,
            focusId
          );
          if (focusId) {
            setTimeout(() => {
              const el = document.querySelector(`.position${this.avaPositionId}.ind-${focusId}`) as HTMLInputElement;
              if (el) {
                el.focus();
              }
            }, 1);
          }
        },
        (error: ApiErrorOfFormulaError) => {
          this.lastRecalculationHadError = true;
          this.calculationInProgress = false;
          if (error.error?.formulaErrorType === FormulaErrorType.CircularReference) {
            this.notificationsService.error('Zirkelbezug in einer Formel, die Berechnung wird nicht gespeichert.');

            if (error.error.rowIndizesWithErrors) {
              error.error.rowIndizesWithErrors.forEach((ri) => (this.circularErrorRowIndizes[this.avaPositionId][ri] = true));
            }
          } else {
            this.notificationsService.error('Fehler beim Speichern.');
          }
        }
      );
  }

  public changeShowingOfReferenceNameColumn(showReferenceNameColumn: boolean): void {
    this.displayedColumns = this.showingColumnsService.getFilteredColumns('PositionQto', this.defaultDisplayedColumns);
    if (!showReferenceNameColumn) {
      this.displayedColumns = this.displayedColumns.filter((item: string) => item !== 'referenceName');
    }
    if (!this.userSettings.showUnitTagsInQuantityTakeOff) {
      this.displayedColumns = this.displayedColumns.filter((item: string) => item !== 'unit');
    }
    if (this.userSettings.showImageColumnsInQuantityTakeOff === false) {
      this.displayedColumns = this.displayedColumns.filter((item: string) => item !== 'image');
    }
  }

  public inputRowType(event: KeyboardEvent, element: PositionQuantityTakeOffRowModel, nameProp: string): void {
    this.preventKeyDownEvent(event, element, nameProp);
    const pattern = /[iz]/i;
    if (event.key.length === 1 && !pattern.test(event.key)) {
      event.preventDefault();
    }
  }

  startSelectRow(rowIndex: number): void {
    if (!this.isReadOnly) {
      this.listMove = [rowIndex, rowIndex];
      this.selectRowsService.markFirst(rowIndex);
      let currentRowIndex;
      this.mousemove$ = fromEvent(this.el.nativeElement, 'mousemove').subscribe((elEv: MouseEvent) => {
        // Added changes as per this comment https://github.com/Dangl-IT/Dangl.PfeifferAVA/pull/2469#issuecomment-1547509766
        const rowIndex = +(elEv.target as HTMLElement).innerText;
        if (rowIndex !== currentRowIndex) {
          currentRowIndex = rowIndex;
          this.moveSelectRow(elEv, currentRowIndex);
        }
      });
    }
  }

  stopSelectRow(event?: MouseEvent, selectedRowStop = 0): void {
    if (!this.isReadOnly) {
      if (!event?.button) {
        this.listSelectedRows = this.selectRowsService.getNewList(selectedRowStop, event.ctrlKey, event.shiftKey);
        const summa = this.dataSource.reduce(
          (sum: number, item: PositionQuantityTakeOffRowModel) =>
            sum + (typeof item.totalCost === 'number' && this.listSelectedRows.includes(item.rowIndex) ? item.totalCost : 0),
          0
        );
        const quantity = this.dataSource.reduce(
          (sum: number, item: PositionQuantityTakeOffRowModel) =>
            sum + (typeof item.result === 'number' && this.listSelectedRows.includes(item.rowIndex) ? item.result : 0),
          0
        );
        this.selectRowsService.setSelectedRowSumma(
          this.listSelectedRows.length ? summa : null,
          this.listSelectedRows.length ? quantity : null
        );
        event.stopPropagation();
      }
      if (this.mousemove$) {
        this.mousemove$.unsubscribe();
      }
      this.listMove = [];
    }
  }

  moveSelectRow(event: MouseEvent, rowIndex: number): void {
    if (!this.isReadOnly && event.buttons === 1 && !event.button && this.listMove?.length) {
      this.listMove[1] = rowIndex;
    }
  }

  showMoveSelectRow(rowIndex: number): boolean {
    if (this.listMove?.length) {
      const min: number = Math.min(...this.listMove);
      const max: number = Math.max(...this.listMove);
      return rowIndex >= min && rowIndex <= max;
    } else {
      return false;
    }
  }

  private ensureRowSelectionReset(): void {
    this.listSelectedRows = this.selectRowsService.reset();
  }

  selectedRow(rowIndex: number): boolean {
    return this.listSelectedRows.includes(rowIndex);
  }

  saveRows(isClean?: boolean): void {
    if (isClean) {
      this.savedSelectedRows = [];
    } else {
      this.savedSelectedRows = this.dataSource
        .filter((item: PositionQuantityTakeOffRowModel) => this.listSelectedRows.includes(item.rowIndex))
        .map((r) => ({ ...r }));
      this.clipboardService.copyData(this.clipboardWord, this.savedSelectedRows);
    }
  }

  copySavedRows(currentRow: PositionQuantityTakeOffRowModel, isAddNewRows?: boolean): void {
    const [topic, ...items] = this.dataSource;
    const tmpDataSource = this.manageInvoiceTableService.addRow(currentRow, false, isAddNewRows, items, this.savedSelectedRows);
    this.dataSource = [topic, ...tmpDataSource];
    this.recalculate();
  }

  changeInputFromOverrideMode(input: HTMLInputElement, element: AllPositionQuantityTakeOffRowModel, prop: string): void {
    const value = input.value;
    let hasError = this.calculationData.rows.some((item: PositionQuantityTakeOffRowModel) => !!item.formulaErrorType);
    if (prop === 'referenceName' && !this.checkedDoubleReferenceName(value)) {
      this.showErrorElement(input, 'Referenzname bereits vergeben');
      hasError = true;
    } else if (prop === 'formula' && !this.checkedValidFormula(input.value)) {
      this.showErrorElement(input, 'Falsche Referenz');
      element[prop] = input.value;
      element.result = null;
      element.formulaErrorMessage = 'Falsche Referenz';
      element.formulaErrorType = FormulaErrorType.GenericError;
      hasError = true;
    }

    element[prop] = input.value;
    const forceRecalculate = hasError || prop === 'referenceName' || prop === 'formula';
    this.recalculate(element.rowIndex, null, forceRecalculate, element.avaPositionId);
  }

  changeInput(event, element: AllPositionQuantityTakeOffRowModel, prop: string): void {
    const value = event.target.value;

    let hasError = this.calculationData.rows.some((item: PositionQuantityTakeOffRowModel) => !!item.formulaErrorType);
    if (prop === 'referenceName' && !this.checkedDoubleReferenceName(value)) {
      this.showErrorElement(event.target, 'Referenzname bereits vergeben');
      hasError = true;
    } else if (prop === 'formula' && !this.checkedValidFormula(event.target.value)) {
      this.showErrorElement(event.target, 'Falsche Referenz');
      element[prop] = event.target.value;
      element.result = null;
      element.formulaErrorMessage = 'Falsche Referenz';
      element.formulaErrorType = FormulaErrorType.GenericError;
      hasError = true;
    }

    element[prop] = event.target.value;
    const forceRecalculate = hasError || prop === 'referenceName' || prop === 'formula';
    this.recalculate(element.rowIndex, null, forceRecalculate, element.avaPositionId);
  }

  checkedDoubleReferenceName(valueReferenceName: string): boolean {
    if (valueReferenceName) {
      return !this.dataSource.some((item) => item.referenceName === valueReferenceName);
    }
    return true;
  }

  showErrorElement(inputElement: HTMLInputElement, textError: string): void {
    const value = inputElement.value;
    this.notificationsService.error(`${textError}: ${value}`);
  }

  checkedValidFormula(formula): boolean {
    let hasFoundInvalidSubstitution = false;
    Calculator.calculate(formula, (substitution) => {
      const regExpr = /^#[\d]+$/g;
      if (regExpr.test(substitution)) {
        const referencedRow = this.calculationData.rows.find((r) => r.rowIndex === +substitution.slice(1));
        if (!referencedRow) {
          hasFoundInvalidSubstitution = true;
        } else {
          return referencedRow.result;
        }
      } else {
        const referencedRow = this.calculationData.rows.find((r) => r.referenceName?.endsWith(substitution.slice(1)));
        if (!referencedRow) {
          hasFoundInvalidSubstitution = true;
        } else {
          return referencedRow.result;
        }
      }
    });

    return !hasFoundInvalidSubstitution;
  }

  trackByFn(index, item): void {
    return item.rowIndex;
  }

  deleteSelectedRowsPlus(isSkipConfirmation: boolean): void {
    if (this.userSettings.promptForConfirmationWhenDeletingRows && !isSkipConfirmation) {
      this.confirmationDeleting(this.listSelectedRows, () => this.deleteSelectedRows());
    } else {
      this.deleteSelectedRows();
    }
  }

  checkWarningForReference(list: number[]): string {
    let result = '';
    list.forEach((item) => {
      const foundRows = this.dataSource.filter((row) => row.formula?.includes(`#${item}`));
      if (foundRows?.length) {
        result += foundRows.reduce((acc, referenceRow) => acc + `(#${referenceRow.rowIndex} -> #${item})`, '');
      }
    });
    return result;
  }

  confirmationDeletingChecked(warning: string, fn: () => void): void {
    this.modalService
      .openModal(ModalConfirmComponent, {
        dialogType: ConfirmationType.Delete,
        data: ['Löschen', 'Zeile wird referenziert', warning, 'red']
      })
      .afterClosed()
      .subscribe((e: boolean) => {
        if (e) {
          fn();
        }
      });
  }

  deleteSelectedRows(): void {
    const warning = this.checkWarningForReference(this.listSelectedRows);
    if (warning) {
      this.confirmationDeletingChecked(warning, () => this.deleteSelectedRowsChecked());
    } else {
      this.deleteSelectedRowsChecked();
    }
  }

  deleteSelectedRowsChecked(): void {
    const maxIndex = Math.max(...this.listSelectedRows);
    const [topic, ...items] = this.dataSource;
    const currentRows: PositionQuantityTakeOffRowModel[] = items.filter((row) => !this.listSelectedRows.includes(row.rowIndex));
    currentRows.forEach((row: PositionQuantityTakeOffRowModel, index: number) => {
      row.rowIndex = index + 1;
      this.manageInvoiceTableService.changeRelatingNumber(
        row,
        maxIndex - this.listSelectedRows.length,
        -this.listSelectedRows.length,
        this.listSelectedRows
      );
    });
    this.listSelectedRows = [];
    this.selectRowsService.listSelectedRows = [];
    if (currentRows.length === 0) {
      currentRows.push({
        rowIndex: 1
      });
    }
    this.dataSource = [topic, ...currentRows];
    setTimeout(() => {
      this.recalculate();
    }, 1);
  }

  confirmationDeleting(listDel: number | number[], fn: () => void): void {
    let listDelString: string;
    if (Array.isArray(listDel)) {
      listDelString = `Zeilen: ${listDel.length < 10 ? listDel.join(', ') : 'Viele (' + listDel.length + ')'}`;
    } else {
      listDelString = `Zeile ${listDel}`;
    }
    this.modalService
      .openModal(ModalConfirmComponent, { dialogType: ConfirmationType.Delete, data: ['Löschen', 'Zeilen', listDelString, 'red'] })
      .afterClosed()
      .subscribe((e: boolean) => {
        if (e) {
          fn();
        }
      });
  }

  clearSelectionRow(event: MouseEvent, row: PositionQuantityTakeOffRowModel): void {
    if (!event?.button && this.listSelectedRows.length) {
      const tag = event?.target as HTMLInputElement;
      if (this.isSelectingFormulaRowMode) {
        if (!this.listSelectedRows.includes(row.rowIndex)) {
          this.changeFormula(row);
          this.isSelectingFormulaRowMode = false;
        }
      } else if (!tag || (tag['tagName'] === 'INPUT' && !tag.disabled)) {
        this.listSelectedRows = this.selectRowsService.init();
      }
    }
  }

  showHeaderContextMenu(event: MouseEvent): void {
    this.contextMenuSettingsService.setDefaultSettings(event, null, this.contextMenuPosition, this.contextMenu.last);
  }

  editListColumns(): void {
    this.modalService.openModal(ShowingColumnsModalComponent, {
      dialogType: ConfirmationType.General,
      data: {
        component: 'PositionQto',
        columns: this.defaultDisplayedColumns
      },
      disableClose: true,
      autoFocus: false
    });
  }

  showImage(element: PositionQuantityTakeOffRowModel): void {
    this.modalService
      .openModal(InvoiceImageModalComponent, {
        dialogType: ConfirmationType.General,
        data: {
          element: element,
          isReadOnly: this.isReadOnly
        },
        panelClass: 'window-padding'
      })
      .afterClosed()
      .subscribe((isDelete: boolean) => {
        if (isDelete) {
          this.deleteImage(element);
        }
      });
  }

  setTotalSumsHeight(totalSumHeight: number): void {
    this.totalSumHeight = totalSumHeight;
  }

  changeFormula(row: PositionQuantityTakeOffRowModel): void {
    const listReferences = this.listSelectedRows.map((item) => `#${item}`).join(' + ');
    row.formula = listReferences;
    this.recalculate(row.rowIndex);
  }

  cutSelectedRowsPlus(): void {
    this.saveRows();
    // Since this is just a cut operation, we want to ignore the settings
    // that ask for confirmation before deletion and just delete the rows
    this.deleteSelectedRows();
  }

  limitWidth(isSame?: boolean): void {
    this.limitColumnWidthService.limitWidth('positions', isSame);
  }

  private loadFlatElements(elements: IElementDto[]) {
    elements.forEach((element: IElementDto) => {
      if (element.elementType === 'PositionDto') {
        this.flatPositions.push(element);
      }

      if (element.elementType === 'ServiceSpecificationGroupDto') {
        this.loadFlatElements((<ServiceSpecificationGroupDto>element).elements);
      }
    });
  }

  initializePositionsList(elements: IElementDto[]): void {
    this.flatPositions = [];
    this.loadFlatElements(elements);
    this.flatPositions.forEach((position: PositionDto) => {
      this.positions[position.id] = position;
    });
  }

  setRowFocus(row: AllPositionQuantityTakeOffRowModel): void {
    if (row.avaPositionId) {
      if (this.avaPositionId !== row.avaPositionId) {
        this.listSelectedRows = this.selectRowsService.init();
        this.avaPositionId = row.avaPositionId;
        this.selectedSpecificationElementMessengerService.trySelectElementById(this.avaPositionId);
        this.positionQuantityTakeOffsClient
          .getPositionQuantityTakeOffById(this.projectId, this.avaProjectId, this.quantityTakeOffId, this.avaPositionId)
          .subscribe((positionQTO: PositionQuantityTakeOffGet) => {
            if (this.avaPositionId == positionQTO.avaPositionId) {
              this.setTotal(positionQTO);
              this.calculationData = positionQTO.positionData;
            }
          });
      }
    }
  }

  cleanedDataSource(data: AllPositionQuantityTakeOffRowModel[]): PositionQuantityTakeOffRowModel[] {
    const extraProp = ['itemNumber', 'avaPositionId', 'isTopic'];
    return data
      .filter((item) => !item.isTopic)
      .map((item) => {
        Object.keys(item).forEach((key) => {
          if (extraProp.includes(key)) {
            delete item[key];
          }
        });
        return item;
      });
  }

  addingDataSource(data: PositionQuantityTakeOffRowModel[]): AllPositionQuantityTakeOffRowModel[] {
    const avaPositionId = this.avaPositionId;
    const itemNumber = this.positions[this.avaPositionId].itemNumber.stringRepresentation;

    const rowTopic = {
      itemNumber,
      avaPositionId,
      isTopic: true,
      formula: this.positions[this.avaPositionId].shortText,
      result: this.positions[this.avaPositionId].quantity,
      totalCost: this.positions[this.avaPositionId].totalPrice
    } as AllPositionQuantityTakeOffRowModel;

    let firstEmptyLine = 0;
    for (let i = data.length - 1; i >= 0; i--) {
      if (this.checkIfRowIsEmpty(data[i])) {
        firstEmptyLine = i;
      } else {
        break;
      }
    }
    const filteredRows = data.slice(0, firstEmptyLine + 2) as AllPositionQuantityTakeOffRowModel[];

    filteredRows.forEach((item) => {
      item['itemNumber'] = itemNumber;
      item['avaPositionId'] = avaPositionId;
    });
    return [rowTopic, ...filteredRows];
  }

  checkIfRowIsEmpty(row: PositionQuantityTakeOffRowModel): boolean {
    return Object.keys(row).every((key) => {
      if (key === 'rowIndex') {
        return true;
      } else {
        return !row[key];
      }
    });
  }

  checkShowedPosition(): void {
    const table = this.document.querySelector('.table-wrapper');
    if (this.document.activeElement.nodeName === 'BODY' && this.avaPositionId && table) {
      const tableRect = table?.getBoundingClientRect();
      const elTopic = table?.querySelector(`.ind--formula.position${this.avaPositionId}`);
      if (elTopic) {
        const elTopicRect = elTopic.getBoundingClientRect();
        if (elTopicRect.top < tableRect.top || elTopicRect.bottom > tableRect.bottom) {
          elTopic.scrollIntoView();
          table.scrollTop -= 22;
        }
      } else {
        // Resolved problem with hidden virtual rows
        const index = this.tableDataSource.data.findIndex((item) => item.isTopic && item.avaPositionId === this.avaPositionId);
        table.scrollTop = index * 22;
      }
      setTimeout(() => {
        const list = this.cleanedDataSource(JSON.parse(JSON.stringify(this.dataSource)));
        let firstEmptyLine = 0;
        for (let i = list.length - 1; i >= 0; i--) {
          if (this.checkIfRowIsEmpty(list[i])) {
            firstEmptyLine = i;
          } else {
            break;
          }
        }
        const elFirst = table.querySelector(`.ind-${firstEmptyLine + 1}-formula.position${this.avaPositionId}`) as HTMLInputElement;
        if (elFirst) {
          elFirst.focus();
        }
      }, 1);
    }
  }

  getDataSource(avaPositionId: string): AllPositionQuantityTakeOffRowModel[] {
    return this.tableDataSource.data.filter((item) => item.avaPositionId === avaPositionId);
  }

  setDataSource(avaPositionId: string, value: AllPositionQuantityTakeOffRowModel[]) {
    if (avaPositionId && this.positions[avaPositionId]) {
      const itemNumber = this.positions[avaPositionId].itemNumber.stringRepresentation;
      value.forEach((item) => {
        item['itemNumber'] = itemNumber;
        item['avaPositionId'] = avaPositionId;
      });
      const start = this.tableDataSource.data.findIndex((item) => item.avaPositionId === avaPositionId);
      if (!this.tableDataSource.data.length) {
        this.tableDataSource.data = value;
      } else if (start > -1) {
        const filteredData = this.tableDataSource.data.filter((item) => item.avaPositionId === avaPositionId);
        this.tableDataSource.data.splice(start, filteredData.length, ...value);
      } else {
        this.tableDataSource.data.push(...value);
      }
      this.tableDataSource.data = [...this.tableDataSource.data];
      this.table?.renderRows();
    }
  }

  openInvoicePdfFilesModal(): void {
    this.modalService
      .openModal(InvoicePdfFileComponent, {
        dialogType: ConfirmationType.General,
        restoreFocus: false,
        autoFocus: false,
        minWidth: '1040px',
        panelClass: 'invoice-pdf-file'
      })
      .afterClosed()
      .subscribe((attachmentIds: { quantityTakeOffAttachmentId?: string | null; quantityTakeOffAttachmentImageFileId?: string | null }) => {
        if (attachmentIds) {
          this.dataSource[this.activeElement?.rowIndex].quantityTakeOffAttachmentId = attachmentIds.quantityTakeOffAttachmentId;
          this.dataSource[this.activeElement?.rowIndex].quantityTakeOffAttachmentImageFileId =
            attachmentIds.quantityTakeOffAttachmentImageFileId;
          this.recalculate();
        }
      });
  }

  back(): void {
    this.router.navigate(['../..'], {
      relativeTo: this.route
    });
  }

  showQuantity(): void {
    this.router.navigate(['quantity'], {
      relativeTo: this.route
    });
  }

  saveQTOData(): void {
    const data = {
      performancePeriodStartUtc: dateUTC(this.performanceQtoDate.value.performancePeriodStartUtc),
      performancePeriodEndUtc: dateUTC(this.performanceQtoDate.value.performancePeriodEndUtc)
    };
    this.selectedQuantityTakeOffMessengerService.changeQuantityTakeOffDate(this.projectId, this.avaProjectId, this.quantityTakeOffId, data);
  }

  copyCurrentLine(row: PositionQuantityTakeOffRowModel): void {
    this.savedSelectedRows = this.cleanedDataSource([{ ...row }]);
    this.clipboardService.copyData(this.clipboardWord, this.savedSelectedRows);
  }

  dropHeader(event: CdkDragDrop<string[]>): void {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
      this.showingColumnsService.saveDefaultDisplayedColumns(
        this.nameStorageOrderColumn,
        this._defaultDisplayedColumns,
        this.displayedColumns
      );
    }
  }
}
