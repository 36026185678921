<div class="users" [matBadge]="currentUsers?.length" matBadgeSize="small" matBadgeColor="warn">
  <mat-icon [matMenuTriggerFor]="usersMenu" *ngIf="isShowUsers && currentlyInProject && currentUsers?.length">group</mat-icon>
</div>
<mat-menu #usersMenu="matMenu">
  <div class="users__title">Aktiv im Projekt</div>
  <div mat-menu-item *ngFor="let user of currentUsers" class="users__item" (click)="tryKickUser(user.id)">
    <div fxLayout="row">
      <img class="profile-image" [diIcon]="user.identiconId" [rectangleSize]="30" />
      <div class="users__item-text">
        <div>{{ user.username }}</div>
        <div>{{ statesByUserId[user.id] }}</div>
      </div>
    </div>
  </div>
</mat-menu>
