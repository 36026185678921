<div class="ui-progressbar" [matTooltip]="value | percent">
  <div
    class="ui-progressbar-value"
    [ngClass]="'ui-progressbar-' + getColorFromPercentage(value)"
    [style.width]="getStringFromPercentageMax(value)"
  >
    <span class="ui-progressbar-text">{{ value | percent }}</span>
  </div>
  <div class="ui-progressbar-border"></div>
  <div class="tick tick-before"></div>
  <div class="tick"></div>
  <div class="tick tick-after"></div>
</div>
