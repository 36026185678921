import { CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf } from '@angular/cdk/scrolling';
import { DecimalPipe } from '@angular/common';
import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';

import { take } from 'rxjs';

import { PositionDto, ProjectProductGet, ProjectProductPositionGet } from 'app/generated-client/generated-client';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

import { PositionTextPipe } from '../../../../../../../../shared/pipes/ui-data-display/position-text.pipe';
import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-specific-product-usage-information',
  templateUrl: './specific-product-usage-information.component.html',
  styleUrls: ['./specific-product-usage-information.component.scss'],
  standalone: true,
  imports: [
    MatDialogContent,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    FlexLayoutDirective,
    DecimalPipe,
    PositionTextPipe
  ]
})
export class SpecificProductUsageInformationComponent implements OnInit {
  positionUsage: ProjectProductPositionGet[];
  positionUsageList: PositionDto[];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ProjectProductGet,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    @Optional() private matDialogRef: MatDialogRef<SpecificProductUsageInformationComponent>
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.positionUsage = this.data.positionUsage;
    }

    this.selectedSpecificationMessengerService.positionsList.pipe(take(1)).subscribe((positionsList) => {
      this.positionUsageList = positionsList.filter((p) => this.positionUsage.find((el) => el.positionId === p.id));
    });
  }

  selectPosition(position: PositionDto): void {
    this.matDialogRef.close(position);
  }
}
