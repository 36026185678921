<div fxLayout="column" fxLayoutAlign="space-between" class="wrapper">
  <div fxLayout="column">
    <h1>Position <span class="mode">Bearbeitungsmodus</span></h1>
    <div class="main-tabs">
      <mat-divider></mat-divider>
      <ng-container *ngIf="elementChanged">
        <ng-container [ngTemplateOutlet]="topic"></ng-container>
        <mat-tab-group
          class="tab-wrapper"
          animationDuration="0ms"
          (selectedTabChange)="showTinyMce($event)"
          (selectedIndexChange)="hideTinyMce($event)"
        >
          <mat-tab label="Preise">
            <ng-template
              cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="originOverlay"
              [cdkConnectedOverlayOpen]="isOpenListUnitTag"
              [cdkConnectedOverlayHasBackdrop]="true"
              cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
              (backdropClick)="closeOverlay()"
            >
              <div class="list">
                <div class="list-wrapper">
                  <mat-selection-list [multiple]="false">
                    <mat-list-option *ngFor="let tag of typesOfTags" [value]="tag" (click)="chooseUnitTag(tag)">
                      {{ tag }}
                    </mat-list-option>
                  </mat-selection-list>
                </div>
              </div>
            </ng-template>
            <div class="main-tabs-item" [class.modal]="isModal">
              <div fxLayout="row wrap" fxLayoutGap="12px" (dblclick)="dblClicking()">
                <mat-form-field appearance="fill">
                  <mat-label>Menge</mat-label>
                  <input type="number" matInput [(ngModel)]="positionQuantity" (ngModelChange)="changeElement()" />
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>Mengeneinheit</mat-label>
                  <input
                    matInput
                    (focus)="isUnitTagFocused = true"
                    (blur)="isUnitTagFocused = false"
                    [(ngModel)]="elementChanged['unitTag']"
                    (ngModelChange)="changeElement()"
                    cdkOverlayOrigin
                    #originOverlay="cdkOverlayOrigin"
                  />
                </mat-form-field>
              </div>
              <div fxLayout="row wrap" fxLayoutGap="12px">
                <mat-form-field appearance="fill" *ngIf="projectCurrency">
                  <mat-label>Währung</mat-label>
                  <input disabled type="text" matInput [value]="projectCurrency" />
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>MwSt.</mat-label>
                  <input matInput type="number" [(ngModel)]="elementChanged['taxRate']" (ngModelChange)="changeElement()" />
                  <span matSuffix>%</span>
                </mat-form-field>
              </div>
              <div fxLayout="row wrap" fxLayoutGap="12px">
                <mat-form-field appearance="fill">
                  <mat-label>EP</mat-label>
                  <input matInput type="number" [ngModel]="elementChanged.unitPrice" disabled />
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>GP</mat-label>
                  <input matInput type="number" [ngModel]="elementChanged.totalPrice" disabled />
                </mat-form-field>
              </div>
              <div fxLayout="row wrap" fxLayoutGap="12px">
                <mat-form-field appearance="fill">
                  <mat-label>Rabatt</mat-label>
                  <input matInput type="number" [(ngModel)]="elementChanged.deductionFactor" (ngModelChange)="changeElement()" />
                  <span matSuffix>%</span>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>GP nach Nachlass</mat-label>
                  <input matInput type="number" [ngModel]="elementChanged.deductedPrice" disabled />
                </mat-form-field>
              </div>
              <div fxLayout="row wrap" fxLayoutGap="12px">
                <mat-form-field appearance="fill">
                  <mat-label>GP Brutto</mat-label>
                  <input matInput type="number" [ngModel]="elementChanged.totalPriceGross" disabled />
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>GP Brutto nach Nachlass</mat-label>
                  <input matInput type="number" [ngModel]="elementChanged.totalPriceGrossDeducted" disabled />
                </mat-form-field>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Eigenschaften">
            <div class="main-tabs-item" [class.modal]="isModal">
              <div fxLayout="row wrap" fxLayoutGap="12px">
                <mat-form-field appearance="fill">
                  <mat-label>Typ</mat-label>
                  <mat-select [(ngModel)]="elementChanged.positionType" (ngModelChange)="changeElement()">
                    <mat-option *ngFor="let positionType of positionTypes" [value]="positionType">
                      {{ positionType | positionType }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>Leistungstyp</mat-label>
                  <mat-select [(ngModel)]="elementChanged.serviceType" (ngModelChange)="changeElement()">
                    <mat-option *ngFor="let serviceType of serviceTypes" [value]="serviceType">
                      {{ serviceType | serviceType }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxLayout="row wrap" fxLayoutGap="12px">
                <mat-form-field appearance="fill">
                  <mat-label>Auftragsstatus</mat-label>
                  <mat-select [(ngModel)]="elementChanged.comissionStatus" (ngModelChange)="changeElement()">
                    <mat-option *ngFor="let comissionStatus of comissionStatuses" [value]="comissionStatus">
                      {{ comissionStatus | comissionStatus }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>Preistyp</mat-label>
                  <mat-select [(ngModel)]="elementChanged.priceType" (ngModelChange)="changeElement()">
                    <mat-option *ngFor="let priceType of priceTypes" [value]="priceType">
                      {{ priceType | priceType }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxLayout="column">
                <mat-checkbox [ngModel]="elementChanged.complemented" disabled>Bezuschlagt</mat-checkbox>
                <mat-checkbox [(ngModel)]="elementChanged.amountToBeEnteredByBidder" (ngModelChange)="changeElement()"
                  >Menge vom Bieter anzugeben</mat-checkbox
                >
                <mat-checkbox [(ngModel)]="elementChanged.priceCompositionRequired" (ngModelChange)="changeElement()"
                  >Preisaufgliederung erfoderlich</mat-checkbox
                >
                <mat-checkbox [ngModel]="elementChanged.useDifferentTaxRate" disabled>Abweichender Steuersatz</mat-checkbox>
                <mat-checkbox [(ngModel)]="elementChanged.isLumpSum" (ngModelChange)="changeElement()">Pauschalposition</mat-checkbox>
                <mat-checkbox [(ngModel)]="elementChanged.notOffered" (ngModelChange)="changeElement()">Nicht angeboten</mat-checkbox>
              </div>
              <div>
                <mat-form-field appearance="fill" class="allWidth">
                  <mat-label>Ausführungsbeschreibung</mat-label>
                  <mat-select [(ngModel)]="elementChanged.executionDescriptionReference" (ngModelChange)="changeElement()">
                    <mat-option [value]="''"></mat-option>
                    <mat-option *ngFor="let execDescrItem of executionDescriptionsList" [value]="execDescrItem.id">
                      {{ execDescrItem.text }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div>
                <mat-form-field appearance="fill" class="allWidth">
                  <mat-label>Alternative zu</mat-label>
                  <mat-select [(ngModel)]="elementChanged.alternativeTo" (ngModelChange)="changeElement()">
                    <mat-option [value]="''"></mat-option>
                    <mat-option *ngFor="let positionItem of elementList" [value]="positionItem.id">
                      {{ positionItem.text }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div>
                <mat-form-field appearance="fill" class="allWidth">
                  <mat-label>Wiederholung, Grundposition</mat-label>
                  <mat-select [(ngModel)]="elementChanged.repetitionTo" (ngModelChange)="changeElement()">
                    <mat-option [value]="''"></mat-option>
                    <mat-option *ngFor="let positionItem of elementList" [value]="positionItem.id">
                      {{ positionItem.text }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div>
                <h3>Standardbeschreibung</h3>
                <div fxLayoutGap="12px" *ngIf="elementChanged.standardizedDescription">
                  <mat-form-field appearance="fill">
                    <mat-label>Standardreferenztyp</mat-label>
                    <input
                      type="text"
                      matInput
                      [(ngModel)]="elementChanged.standardizedDescription.standardReference"
                      (ngModelChange)="changeElement()"
                    />
                  </mat-form-field>
                  <mat-form-field appearance="fill" *ngIf="elementChanged.standardizedDescription?.stlbReference?.catalogueName">
                    <mat-label>STLB Referenz</mat-label>
                    <input
                      type="text"
                      matInput
                      [(ngModel)]="elementChanged.standardizedDescription.stlbReference.catalogueName"
                      (ngModelChange)="changeElement()"
                    />
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                    <mat-label>Typ</mat-label>
                    <mat-select
                      [(ngModel)]="elementChanged.standardizedDescription.standardReferenceType"
                      (ngModelChange)="changeElement()"
                    >
                      <mat-option [value]="''"></mat-option>
                      <mat-option *ngFor="let standardReferenceType of standardReferenceTypes" [value]="standardReferenceType">
                        {{ standardReferenceType }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div>
                <h3>Bezuschlagt</h3>
                <ol>
                  <li *ngFor="let item of elementChanged.complementedBy">
                    <mat-form-field appearance="fill" class="allWidth">
                      <mat-label>Bezuschlagt von</mat-label>
                      <mat-select [ngModel]="item">
                        <mat-option *ngFor="let positionItem of elementList" [value]="positionItem.id">
                          {{ positionItem.text }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </li>
                </ol>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Langtext">
            <div class="main-tabs-item" [class.modal]="isModal">
              <mat-form-field appearance="fill">
                <mat-label>Textergänzungstyp</mat-label>
                <mat-select [ngModel]="elementChanged.additionType" disabled>
                  <mat-option *ngFor="let additionType of additionTypes" [value]="additionType">
                    {{ additionType | additionType }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <div *ngIf="showLangText">
                <dangl-tiny-mce
                  tinyMceLanguageCode="de"
                  [ngModel]="elementChanged.htmlLongText"
                  (ngModelChange)="changedTinyMce($event, elementChanged, 'htmlLongText')"
                ></dangl-tiny-mce>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Referenzen">
            <div class="main-tabs-item" [class.modal]="isModal">
              <h3>Projektkataloge</h3>

              <div>
                <ol>
                  <li *ngFor="let item of elementChanged.projectCatalogues">
                    {{ item.name }} ( {{ item.catalogueType | catalogueType }} )
                  </li>
                </ol>
              </div>

              <div>
                <h3>Katalogreferenz</h3>
                <ol>
                  <li *ngFor="let item of elementChanged.catalogueReferences">
                    {{ item.catalogue?.name }} ( {{ item.catalogue?.catalogueType | catalogueType }} ):
                    <strong>{{ item.catalogueReferenceKey }}</strong>
                  </li>
                </ol>
              </div>

              <div>
                <h3>Mengenzuordnungen</h3>
                <ol>
                  <li *ngFor="let item of elementChanged.quantityAssignments">
                    <h3>Mengenzuordnung</h3>
                    <div fxLayout="row" fxLayoutGap="12px">
                      <div>
                        <h3>Projektkataloge</h3>
                        <ol>
                          <li *ngFor="let item2 of item.projectCatalogues">
                            {{ item2.name }} ( {{ item2.catalogueType | catalogueType }} )
                          </li>
                        </ol>
                      </div>
                      <div>
                        <h3>Katalogreferenzen</h3>
                        <ol>
                          <li *ngFor="let item2 of item.catalogueReferences">
                            {{ item2.catalogue?.name }} ( {{ item2.catalogue?.catalogueType | catalogueType }} )
                          </li>
                        </ol>
                      </div>
                    </div>
                  </li>
                </ol>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Unterbeschreibungen">
            <div class="main-tabs-item" [class.modal]="isModal">
              <ol>
                <li *ngFor="let item of elementChanged.subDescriptions">
                  <h3>{{ item.shortText }}</h3>
                  <div>ID: {{ item.identifier }}</div>
                  <div>
                    <mat-checkbox [checked]="item.amountToBeEnteredByBidder">Menge ist vom Bieter anzugeben</mat-checkbox>
                  </div>
                  <div>
                    <mat-form-field appearance="fill">
                      <mat-label>Referenz zu Ausführungsbeschreibung</mat-label>
                      <input disabled type="text" matInput [value]="item.executionDescriptionReference" />
                    </mat-form-field>
                  </div>
                  <div fxLayoutGap="12px">
                    <mat-form-field appearance="fill">
                      <mat-label>Menge</mat-label>
                      <input matInput type="number" [ngModel]="item.quantity" disabled />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                      <mat-label>Textergänzung</mat-label>
                      <mat-select disabled [(ngModel)]="item.additionType">
                        <mat-option *ngFor="let additionType of additionTypes" [value]="additionType">
                          {{ additionType | additionType }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div fxLayoutGap="12px">
                    <mat-form-field appearance="fill">
                      <mat-label>Standardreferenz</mat-label>
                      <input type="text" matInput [(ngModel)]="item.standardizedDescription.standardReference" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                      <mat-label>Typ</mat-label>
                      <mat-select disabled [(ngModel)]="item.standardizedDescription.standardReferenceType">
                        <mat-option *ngFor="let standardReferenceType of standardReferenceTypes" [value]="standardReferenceType">
                          {{ standardReferenceType }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </li>
              </ol>
            </div>
          </mat-tab>
        </mat-tab-group>
      </ng-container>
    </div>

    <ng-template #topic>
      <pa-item-number-editing
        label="OZ"
        [itemNumber]="elementChanged.itemNumber"
        (itemNumberChange)="onItemNumberChanged($event)"
      ></pa-item-number-editing>

      <input class="changing broad" type="text" [(ngModel)]="elementChanged.shortText" (ngModelChange)="changeElement()" />
    </ng-template>
  </div>

  <div fxLayout="row" fxLayoutGap="12px" class="buttons">
    <button mat-raised-button color="primary" [disabled]="!isChanged" (click)="exit(true)">Speichern</button>
    <button mat-raised-button color="warn" (click)="exit()">Abbrechen</button>
  </div>
</div>
