import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogContent, MatDialogClose } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FlexLayoutDirective } from '../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-modal-master-items-articles-new',
  templateUrl: './modal-master-items-articles-new.component.html',
  styleUrls: ['./modal-master-items-articles-new.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MatDialogContent,
    ReactiveFormsModule,
    FlexLayoutDirective,
    MatFormField,
    MatLabel,
    MatInput,
    MatButton,
    MatDialogClose
  ]
})
export class ModalMasterItemsNewComponent implements OnInit, OnDestroy {
  newItemForm: FormGroup;
  isChanged: boolean;
  $destroy: Subject<boolean> = new Subject<boolean>();

  constructor() {}

  ngOnInit(): void {
    this.newItemForm = new FormGroup({
      name: new FormControl('', (c) => Validators.required(c)),
      unit: new FormControl('', (c) => Validators.required(c)),
      description: new FormControl(''),
      price: new FormControl(0, (c) => Validators.required(c))
    });
    this.newItemForm.valueChanges.pipe(takeUntil(this.$destroy)).subscribe(() => (this.isChanged = true));
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }
}
