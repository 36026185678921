import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { PositionDto } from 'app/generated-client/generated-client';
import { ReplaySubject } from 'rxjs';
/**In order to use table navigation between inputs with the help of keys,
 * we should add the onKey function to the table that listens for navigation key presses,
 *  and also add an identifier that matches the column name to each input.
 * Also, for the time being, we ignore the first column,
 *  because that's where the row numbering is usually located */
@Injectable({
  providedIn: 'root'
})
export class KeyupControlService {
  selectedElementIdSource: ReplaySubject<string | null> = new ReplaySubject<string | null>();
  selectedElementId = this.selectedElementIdSource.asObservable();

  public previousFocusedElType: string;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  private setSelectedElementId(selectedElementId: string | null): void {
    this.selectedElementIdSource.next(selectedElementId);
  }

  onKey(
    event: KeyboardEvent,
    displayedColumns: string[],
    tableType?: string,
    extendPosition?: {
      avaPositionId: string;
      list: PositionDto[];
    }
  ): void {
    if (!this.document.activeElement) {
      return;
    }

    const handleEventNavigation = () => {
      if (event.key === 'ArrowUp') {
        this.moveFocus(0, -1, displayedColumns, tableType, extendPosition);
      } else if (event.key === 'ArrowDown') {
        this.moveFocus(0, 1, displayedColumns, tableType, extendPosition);
      } else if (event.key === 'ArrowLeft') {
        this.moveFocus(-1, 0, displayedColumns, tableType, extendPosition);
      } else if (event.key === 'ArrowRight' || event.key === 'Tab' || event.key === 'Enter') {
        this.moveFocus(1, 0, displayedColumns, tableType, extendPosition);
      }
    };

    handleEventNavigation();
  }

  moveFocus(
    horizontal: number,
    vertical: number,
    displayedColumns: string[],
    tableType?: string,
    extendPosition?: {
      avaPositionId: string;
      list: PositionDto[];
    }
  ): void {
    const currentElementRow: number = +this.document.activeElement.id.split('-')[0];
    const currentElementType: string = this.document.activeElement.id.split('-')[1];

    this.previousFocusedElType = currentElementType;

    const columnIndex: number = displayedColumns.findIndex((c) => c === currentElementType);

    let targetRowIndex: number = currentElementRow + vertical;
    let targetColumnIndex: number = columnIndex + horizontal;
    if (horizontal > 0 && targetColumnIndex >= displayedColumns.length) {
      targetColumnIndex = targetColumnIndex - displayedColumns.length;
      targetRowIndex += 1;
      if (targetColumnIndex === 0) {
        // The first row is always skipped, it contains the row number
        targetColumnIndex = 1;
      }
    } else if (horizontal < 0 && targetColumnIndex < 1) {
      targetColumnIndex = displayedColumns.length + horizontal;
      targetRowIndex = (targetRowIndex -= 1) < 0 ? 0 : targetRowIndex;
    }

    const targetElementType: string = displayedColumns[targetColumnIndex];
    const newElementId = `${targetRowIndex}-${targetElementType}`;

    if (newElementId) {
      this.setSelectedElementId(newElementId);
      let newElement: HTMLElement;
      if (extendPosition) {
        newElement = this.document.querySelector(`.position${extendPosition.avaPositionId}.ind-${newElementId}`);
      } else {
        newElement = this.document.getElementById(newElementId);
      }

      if (!newElement && !extendPosition) {
        return;
      } else if (!newElement && extendPosition) {
        const positionIndex = extendPosition.list.findIndex((item) => item.id === extendPosition.avaPositionId);
        const jumpIndex = horizontal > 0 || vertical > 0 ? positionIndex + 1 : positionIndex - 1;
        const jumpPosition = extendPosition.list[jumpIndex];

        if (!jumpPosition) {
          return;
        }

        const jumpRowElement =
          positionIndex > jumpIndex
            ? this.document.activeElement.closest('tr').previousElementSibling.previousElementSibling
            : this.document.activeElement.closest('tr').nextElementSibling.nextElementSibling;

        const rowNumber = Array.from(jumpRowElement.classList)
          .find((itemClass) => itemClass.includes('row-index'))
          .slice(9);
        const newElementId = `${rowNumber}-${targetElementType}`;
        newElement = jumpRowElement.querySelector(`.position${jumpPosition.id}.ind-${newElementId}`);
      }

      if (newElement['disabled'] || newElement.tagName !== 'INPUT') {
        if (vertical > 0) {
          this.moveFocus(0, vertical + 1, displayedColumns, tableType, extendPosition);
        } else if (vertical < 0) {
          this.moveFocus(0, vertical - 1, displayedColumns, tableType, extendPosition);
        } else if (horizontal >= 0) {
          this.moveFocus(horizontal + 1, 0, displayedColumns, tableType, extendPosition);
        } else if (horizontal < 0) {
          this.moveFocus(horizontal - 1, 0, displayedColumns, tableType, extendPosition);
        }

        return;
      }

      if (tableType === 'calc') {
        if (newElement['type'] === 'checkbox') {
          this.moveFocus(horizontal + 1, 0, displayedColumns, tableType, extendPosition);
          return;
        }
        const el = document.getElementById(newElementId);
        const tableWrapper = el.closest('as-split-area');
        const headerBottom = tableWrapper.querySelector('.mat-mdc-header-row > th').getBoundingClientRect().bottom;
        const rect = el.getBoundingClientRect();
        if (rect.top < headerBottom) {
          tableWrapper.scrollTop -= 22;
        }
      }
      newElement.focus();
    }
  }

  preventKeyDownEvent(event: KeyboardEvent): void {
    const keys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];
    if (keys.includes(event.key)) {
      event.preventDefault();
    }
  }
}
