import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChangeTotalService {
  changedTotalSource: Subject<boolean> = new Subject();
  changedTotal = this.changedTotalSource.asObservable();

  constructor() {}

  setChangedTotal(isChanged: boolean): void {
    this.changedTotalSource.next(isChanged);
  }
}
