import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RouteHistoryService {
  private _routeHistory: string[] = [];

  constructor() {}

  add(newUrl: string): void {
    this._routeHistory.push(newUrl);
  }

  back(): void {
    this._routeHistory.pop();
  }

  getLength(): number {
    return this._routeHistory.length;
  }

  getPrevious(): string {
    return this._routeHistory[this._routeHistory.length - 1];
  }
}
