import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';
import { MatOption } from '@angular/material/core';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSelectChange, MatSelect } from '@angular/material/select';

import { Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

import {
  AvaProjectGet,
  AvaProjectsClient,
  CompanyGet,
  MasterDataProductGet,
  ProjectGet,
  ProjectSheetQuantityType,
  ProjectStatus,
  ReportGetOfProductsSheetReport,
  ReportsClient
} from 'app/generated-client/generated-client';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { PrintViewMessengerService } from 'app/shared/services/electron/print-view-messenger.service';
import { CompaniesService } from 'app/shared/services/lightquery/companies.service';
import { MasterDataProductsService } from 'app/shared/services/lightquery/master-data-products.service';
import { ProjectsService } from 'app/shared/services/lightquery/projects.service';

import { MatSelectSearchComponent } from '../../../../shared/components/mat-select-search/mat-select-search.component';
import { PriceComponentTypePipe } from '../../../../shared/pipes/ui-data-display/price-component-type.pipe';
import { ProjectStatusPipe } from '../../../../shared/pipes/ui-data-display/project-status.pipe';
import { FlexLayoutDirective } from '../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-master-reports',
  templateUrl: './master-reports.component.html',
  styleUrls: ['./master-reports.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatButton,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatSelect,
    NgFor,
    MatOption,
    FlexLayoutDirective,
    MatCheckbox,
    MatSelectSearchComponent,
    MatChipListbox,
    MatChipOption,
    MatInput,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatDatepicker,
    MatProgressSpinner,
    AsyncPipe,
    PriceComponentTypePipe,
    ProjectStatusPipe
  ]
})
export class MasterReportsComponent implements OnInit, OnDestroy {
  private $destroy: Subject<boolean> = new Subject<boolean>();
  isLoadingPdf = false;
  reportsForm: FormGroup;
  companiesList: CompanyGet[] = [];
  productsList: MasterDataProductGet[] = [];
  avaProjectList: AvaProjectGet[] = [];
  selectedAvaProjects: AvaProjectGet[] = [];
  productFilterCtrl: FormControl = new FormControl();
  projectsFilterCtrl: FormControl = new FormControl();
  public filteredProducts: ReplaySubject<MasterDataProductGet[]> = new ReplaySubject<MasterDataProductGet[]>(1);
  projectsList: Observable<ProjectGet[]> = null;
  private filterSource: Subject<string> = new Subject<string>();
  listStatus = Object.keys(ProjectStatus).map((ps) => <ProjectStatus>ps);
  listProjectSheetQuantityType = [
    {
      projectSheetQuantityType: ProjectSheetQuantityType.Calculated,
      text: 'Auswertung von kalkulierten Mengen'
    },
    {
      projectSheetQuantityType: ProjectSheetQuantityType.Invoiced,
      text: 'Auswertung von abgerechneten Mengen'
    }
  ];

  constructor(
    private reportsClient: ReportsClient,
    private printViewMessengerService: PrintViewMessengerService,
    private fb: FormBuilder,
    private avaProjectsClient: AvaProjectsClient,
    private projectsService: ProjectsService,
    private companiesService: CompaniesService,
    private masterDataProductsService: MasterDataProductsService,
    private avaNotificationsService: AvaNotificationsService
  ) {}

  ngOnInit(): void {
    this.initReportsForm();
    this.getInitialDataForReportSetting();

    this.printViewMessengerService.printViewClosed.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.isLoadingPdf = false;
    });

    this.filterSource
      .pipe(takeUntil(this.$destroy), debounceTime(250))
      .subscribe((filterValue: string) => this.projectsService.setQueryParameter('filter', filterValue));
  }

  ngOnDestroy() {
    this.$destroy.next(true);
    this.$destroy.complete();
    this.filterSource.complete();
    this.projectsService.setQueryParameter('filter', null);
  }

  initReportsForm(): void {
    this.reportsForm = this.fb.group({
      companyId: '',
      projectsId: [''],
      avaProjectsIds: [{ value: '', disabled: true }],
      productsIds: [''],
      startYear: null,
      endYear: null,
      projectStatus: '',
      includeProjectsReport: false,
      includeHeaderOnlyOnFirstPage: false,
      projectSheetQuantityType: ProjectSheetQuantityType.Calculated
    });
    this.reportsForm
      .get('avaProjectsIds')
      .valueChanges.pipe(takeUntil(this.$destroy))
      .subscribe((v) => {
        this.selectedAvaProjects = this.avaProjectList.filter((val) => {
          if (Array.isArray(v)) {
            return v.find((id) => id === val.id);
          }
        });
      });
    this.reportsForm.get('');
  }

  generateReport(): void {
    const {
      companyId,
      avaProjectsIds,
      productsIds,
      startYear,
      endYear,
      projectStatus,
      includeProjectsReport,
      includeHeaderOnlyOnFirstPage,
      projectSheetQuantityType
    } = this.reportsForm.value;
    this.isLoadingPdf = true;
    this.reportsClient
      .getProductsSheetReport(
        companyId,
        avaProjectsIds,
        productsIds,
        startYear,
        endYear,
        projectStatus,
        includeProjectsReport,
        true,
        includeHeaderOnlyOnFirstPage,
        projectSheetQuantityType
      )
      .subscribe(
        (report: ReportGetOfProductsSheetReport) => {
          this.printViewMessengerService.showPdfPreview(
            report.htmlReport,
            'Auswertung',
            report.isLandscape,
            report.pdfReportBase64,
            includeHeaderOnlyOnFirstPage
          );
          this.printViewMessengerService.waitForPrintViewReady().subscribe(() => {
            this.isLoadingPdf = false;
          });
        },
        () => {
          this.isLoadingPdf = false;
          this.avaNotificationsService.error('Fehler beim Erstellen der Auswertung');
        }
      );
  }

  getInitialDataForReportSetting(): void {
    this.isLoadingPdf = true;

    let hasCompanies = false;
    let hasProducts = false;

    this.companiesService.getAll().subscribe((companies) => {
      this.companiesList = companies;
      hasCompanies = true;
      if (hasProducts) {
        this.isLoadingPdf = false;
      }
    });

    this.masterDataProductsService.getAll().subscribe((products) => {
      this.productsList = products;
      this.filteredProducts.next(this.productsList.slice());
      hasProducts = true;
      if (hasCompanies) {
        this.isLoadingPdf = false;
      }
    });

    this.projectsList = this.projectsService.paginationResult.pipe(
      takeUntil(this.$destroy),
      map((p) => {
        return p.data;
      })
    );

    this.productFilterCtrl.valueChanges.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.filterProducts();
    });

    this.projectsFilterCtrl.valueChanges.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.filterSource.next(this.projectsFilterCtrl.value);
    });
  }

  private filterProducts() {
    if (!this.productsList) {
      return;
    }
    // get the search keyword
    let search = this.productFilterCtrl.value;
    if (!search) {
      this.filteredProducts.next(this.productsList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredProducts.next(this.productsList.filter((product) => product.name.toLowerCase().indexOf(search) > -1));
  }

  selectedProject(e: MatSelectChange): void {
    this.reportsForm.get('avaProjectsIds').patchValue([]);
    this.avaProjectsClient
      .getAllAvaProjectsForProject(e.value, false, undefined, undefined, undefined, undefined)
      .pipe(takeUntil(this.$destroy))
      .subscribe((avaProjectList) => {
        this.avaProjectList = avaProjectList.data;
        this.reportsForm.get('avaProjectsIds').enable();
        if (avaProjectList.data.length === 1) {
          this.reportsForm.get('avaProjectsIds').patchValue([avaProjectList.data[0].id]);
        } else {
          this.reportsForm.get('avaProjectsIds').patchValue([]);
        }
      });
  }
}
