import { Component, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { CanComponentDeactivate } from '@serv-spec/components/lv-editor/guards/CanComponentDeactivate';
import { LvEditorService } from '@serv-spec/services/lv-editor.service';

import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { ModalService } from 'app/shared/services/modal.service';

import { TreeViewDisplayType } from '../../../../../../../../generated-client/generated-client';
import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';
import { ServiceSpecificationTreeComponent } from '../../../service-specification-tree/service-specification-tree.component';

import { LvEditorElementComponent } from '../lv-editor-element/lv-editor-element.component';

@Component({
  selector: 'pa-lv-editor',
  templateUrl: './lv-editor.component.html',
  styleUrls: ['./lv-editor.component.scss'],
  standalone: true,
  imports: [ServiceSpecificationTreeComponent, FlexLayoutDirective, LvEditorElementComponent]
})
export class LvEditorComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  unSaved: boolean = false;
  private $destroy: Subject<boolean> = new Subject<boolean>();
  readonly treeViewDisplayType = TreeViewDisplayType.Tree;
  constructor(
    private lvEditorService: LvEditorService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.lvEditorService.isElementChanged.pipe(takeUntil(this.$destroy)).subscribe((isElementChanged) => (this.unSaved = isElementChanged));
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.unSaved) {
      const dialog = this.modalService.openModal(ModalConfirmComponent, {
        dialogType: ConfirmationType.General,
        data: ['Verlassen', 'Position', 'Änderungen wurden nicht gespeichert. Speichern?'],
        autoFocus: false
      });
      return dialog.afterClosed().pipe(
        map((result: boolean) => {
          this.lvEditorService.setChangeElement(result);
          return result !== true;
        })
      );
    } else {
      return true;
    }
  }
}
