<!-- For the resizable directive to work correctly,
  we need to wrap the table in a container with the "table-wrapper" class.
  We use this class to search for a parent in the LimitColumnWidth service  -->
<div class="position__wrapper table-wrapper mat-elevation-z8" [style.maxHeight]="'calc(100% - ' + limitHeight + 'px)'">
  <table
    mat-table
    [dataSource]="filteredDataSource"
    class="position__table"
    matSort
    (matSortChange)="onSort($event)"
    resizeName="copyCalculationElementsTable"
    resizeLimit
    paRowSelection
    (rowSelected)="onRowSelected($event)"
    (selectionProcessActivated)="handleSelectionProcess($event)"
  >
    <ng-container matColumnDef="index">
      <th mat-header-cell *matHeaderCellDef resizeColumn class="right">#</th>
      <td mat-cell *matCellDef="let row; let idx = index" matRipple class="right">
        {{ idx + 1 }}
      </td>
    </ng-container>
    <ng-container matColumnDef="positionType">
      <th mat-header-cell *matHeaderCellDef resizeColumn>Typ</th>
      <td mat-cell *matCellDef="let row">{{ row.positionType | showPositionAbbreviation: row.priceType : true }}</td>
    </ng-container>
    <ng-container matColumnDef="itemNumber">
      <th mat-header-cell *matHeaderCellDef resizeColumn>OZ</th>
      <td mat-cell *matCellDef="let row" matRipple>
        {{ row.itemNumber.stringRepresentation }}
      </td>
    </ng-container>
    <ng-container matColumnDef="shortText">
      <th mat-header-cell *matHeaderCellDef resizeColumn>Kurztext</th>
      <td mat-cell *matCellDef="let row" matRipple>
        {{ row | positionText }}
      </td>
    </ng-container>
    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef resizeColumn>Menge</th>
      <td mat-cell *matCellDef="let row" matRipple>
        {{ row.quantity | number: '1.3-3' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="unitTag">
      <th mat-header-cell *matHeaderCellDef resizeColumn>Einheit</th>
      <td mat-cell *matCellDef="let row" matRipple>
        {{ row.unitTag }}
      </td>
    </ng-container>
    <ng-container matColumnDef="unitPrice">
      <th mat-header-cell *matHeaderCellDef resizeColumn>EP</th>
      <td mat-cell *matCellDef="let row" matRipple>
        {{ row.unitPrice | number: '1.2-2' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="fixedPrice">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>FP</th>
      <td mat-cell *matCellDef="let row" matRipple>
        <mat-icon
          aria-hidden="false"
          aria-label="Check icon"
          fontIcon="check"
          color="primary"
          [style.fontWeight]="'bold'"
          *ngIf="(listOfItemsWithFixedPrice$ | async)?.[row.id]"
        ></mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="totalPrice">
      <th mat-header-cell *matHeaderCellDef resizeColumn>GP</th>
      <td mat-cell *matCellDef="let row" matRipple>
        {{ row.totalPrice | number: '1.2-2' }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" (contextmenu)="showHeaderContextMenu($event)"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns; let i = index"
      (click)="selectPosition($event, row)"
      (dblclick)="copyPosition(row)"
      [class.active]="row.id === activeId"
      [class.multi]="multiSelectPosition?.includes(row.id)"
      [class.first-selected-row]="i + 1 === firstSelectedIndex"
      [class.hover-row]="isSelectionActive"
    ></tr>
  </table>
</div>
<mat-menu #rowHeaderContextMenu="matMenu">
  <ng-template matMenuContent>
    <div mat-menu-item class="context-menu-calc">
      <button mat-button (click)="editListColumns()" class="overlay-button">Spalten Verwalten</button>
    </div>
  </ng-template>
</mat-menu>
<div
  style="position: fixed; visibility: hidden"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="rowHeaderContextMenu"
></div>
