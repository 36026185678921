import { Injectable, NgZone } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ElectronService } from './electron.service';

@Injectable({
  providedIn: 'root'
})
export class SheetsViewMessengerService {
  private sheetsViewVisibleSource = new ReplaySubject<boolean>(1);
  sheetsViewVisible = this.sheetsViewVisibleSource.asObservable();

  private dataToSheetsViewSource = new ReplaySubject<any>(1);
  dataToSheetsView = this.dataToSheetsViewSource.asObservable();

  constructor(private electronService: ElectronService, ngZone: NgZone) {
    if (electronService.isElectron) {
      electronService.ipcRenderer.on('SheetsViewWindowVisible', () => ngZone.run(() => this.sheetsViewVisibleSource.next(true)));

      electronService.ipcRenderer.on('SheetsViewWindowClosed', () => ngZone.run(() => this.sheetsViewVisibleSource.next(false)));

      electronService.ipcRenderer.on('DataToSheetsView', (_, data: any) => ngZone.run(() => this.dataToSheetsViewSource.next(data)));
    }
  }

  showSheetsViewWindow(): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('ShowSheetsViewWindow');
    }
  }

  setOnTopSheetsView(alwaysOnTop: boolean): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('SheetsViewWindowOnTop', alwaysOnTop);
    }
  }

  sendDataToSheetsView(data: any): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('SendDataToSheetsView', data);
      this.dataToSheetsViewSource.next(data);
    }
  }

  closeSheetsViewWindow(): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('CloseSheetsViewWindow');
    }
  }
}
