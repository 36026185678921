import { NgIf, NgFor } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ExecutionDescriptionDto } from 'app/generated-client/generated-client';
import { ElementViewMessengerService } from 'app/shared/services/electron/element-view-messenger.service';

import { SafeHtmlPipe } from '../../../../shared/pipes/safe-html.pipe';

@Component({
  selector: 'pa-execution-description',
  templateUrl: './execution-description.component.html',
  styleUrls: ['./execution-description.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, SafeHtmlPipe]
})
export class ExecutionDescriptionComponent implements OnInit, OnDestroy {
  @Input() element: ExecutionDescriptionDto;
  elementViewOpen = false;
  $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(private elementViewMessengerService: ElementViewMessengerService) {}

  ngOnInit(): void {
    this.elementViewMessengerService.elementViewVisible
      .pipe(takeUntil(this.$destroy))
      .subscribe((isOpen) => (this.elementViewOpen = isOpen));
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }
}
