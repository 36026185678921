<ng-container *ngIf="currentCatalog">
  <h1>{{ currentCatalog.name }}</h1>
  <button mat-raised-button color="primary" (click)="back()">Zurück</button>
  <mat-checkbox [(ngModel)]="currentCatalog.isArchived" (ngModelChange)="changeArchivedCatalog()"> Katalog ist archiviert </mat-checkbox>
  <div
    class="table-wrap"
    [style.maxHeight]="sanitizer.bypassSecurityTrustStyle(noSeparate ? 'calc(' + dialogData.maxHeight + 'px' + ' - 85px )' : '100%')"
  >
    <pa-master-data-products-table
      [priceComponentType]="priceComponentType"
      [noSeparate]="noSeparate"
      [showArticleNumbers]="true"
      [showedId]="selectedProduct?.id"
      (dblSelectItem)="dblSelectMasterDataProduct()"
      (selectItem)="selectMasterDataProduct($event)"
    ></pa-master-data-products-table>
  </div>
</ng-container>
