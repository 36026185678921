import { NgIf, NgFor } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatButtonToggleGroup, MatButtonToggle } from '@angular/material/button-toggle';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { Router, RoutesRecognized } from '@angular/router';

import { Subject } from 'rxjs';
import { filter, startWith, switchMap, take, takeUntil } from 'rxjs/operators';

import { CalculationCheckModalWindowComponent } from '@serv-spec/components/calculation-check-modal-window/calculation-check-modal-window.component';
import { CalculationEstimationModalComponent } from '@serv-spec/components/calculation-estimation-modal/calculation-estimation-modal.component';
import { CalculationExportModalComponent } from '@serv-spec/components/calculation-export-modal/calculation-export-modal.component';
import { InvoiceExportPreviewComponent } from '@serv-spec/components/invoice/components/invoice-export-preview/invoice-export-preview.component';
import { TreeDisplayService } from '@serv-spec/components/invoice/services/tree-display.service';
import { ServiceSpecificationTableViewComponent } from '@serv-spec/components/service-specification-table-view/service-specification-table-view.component';
import { LvEditorService } from '@serv-spec/services/lv-editor.service';

import { ElectronService } from '@shared/services/electron/electron.service';

import { getAppConfig } from 'app/app-config-accessor';
import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';
import { ModePageService } from 'app/areas/tree/services/mode-page.service';
import {
  IElementDto,
  ProjectDto,
  ProjectGet,
  ProjectsClient,
  QuantityTakeOffGet,
  TreeViewDisplayType
} from 'app/generated-client/generated-client';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { TreeViewCommandType } from 'app/shared/models/tree-view-command.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { ElementViewMessengerService } from 'app/shared/services/electron/element-view-messenger.service';
import { LongTextViewMessengerService } from 'app/shared/services/electron/long-text-view-messenger.service';
import { SheetsViewMessengerService } from 'app/shared/services/electron/sheets-view-messenger.service';
import { TreeViewMessengerService } from 'app/shared/services/electron/tree-view-messenger.service';
import { GroupViewService } from 'app/shared/services/group-view.service';
import { ProjectsService } from 'app/shared/services/lightquery/projects.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedQuantityTakeOffMessengerService } from 'app/shared/services/messengers/selected-quantity-take-off-messenger.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';
import { TopMenuButtonsService } from 'app/shared/services/top-menu-buttons.service';

import { IncludesPipe } from '../../pipes/includes.pipe';

import { MarkedElementsModalWindowComponent } from './../marked-elements-modal-window/marked-elements-modal-window.component';

@Component({
  selector: 'pa-top-level-menu',
  templateUrl: './top-level-menu.component.html',
  styleUrls: ['./top-level-menu.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FlexLayoutDirective,
    MatButton,
    MatMenuTrigger,
    MatIcon,
    MatMenu,
    MatMenuItem,
    MatTooltip,
    MatButtonToggleGroup,
    FormsModule,
    MatButtonToggle,
    NgFor,
    IncludesPipe
  ]
})
export class TopLevelMenuComponent implements OnInit, OnDestroy {
  @Input() beforeBlock: HTMLDivElement;
  @ViewChild('btnWrap') btnWrap: ElementRef<HTMLDivElement>;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkMenuIsCompact();
  }
  isInvoiceInfo = false;
  isPages = false;
  isCalcMode = false;
  isEditor = false;
  isSetting = false;
  isScenario = false;
  isInProjectProductsView = false;

  isToggleTreeWidth = false;
  isOpenTreeWindow = false;
  elementViewOpen = false;
  isOpenSheetsWindow = false;
  isLongTextViewOpen = false;
  isEditMode = false;
  selectedElement: IElementDto;
  groupView: TreeViewDisplayType;
  modePage = 'home-page';
  avaProject: ProjectDto;
  avaProjectId: string;
  projectId: string;
  private currentUrl: string;
  private burgerUrl = ['invoices', 'estimations'];
  private calcUrl = ['calculation'];
  quantityTakeOffPageId: string;
  quantityTakeOffId: string;
  private $destroy: Subject<boolean> = new Subject<boolean>();
  isCompactMenu = true;
  innerWidth: number;
  fiveLastOpenedProjects: ProjectGet[];
  isElectron = inject(ElectronService).isElectron;
  isQtoOnlyMode = getAppConfig().isQtoOnlyMode;
  private cdr = inject(ChangeDetectorRef);

  constructor(
    private router: Router,
    private topMenuButtonsService: TopMenuButtonsService,
    private treeDisplayService: TreeDisplayService,
    private treeViewMessengerService: TreeViewMessengerService,
    private elementViewMessengerService: ElementViewMessengerService,
    private longTextViewMessengerService: LongTextViewMessengerService,
    private sheetsViewMessengerService: SheetsViewMessengerService,
    private selectedQuantityTakeOffMessengerService: SelectedQuantityTakeOffMessengerService,
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private lvEditorService: LvEditorService,
    private groupViewService: GroupViewService,
    private projectsService: ProjectsService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private projectsClient: ProjectsClient,
    private modalService: ModalService,
    private modePageService: ModePageService,
    private avaNotificationsService: AvaNotificationsService
  ) {
    //Here add time out because we have some case when url is equal to '/'
    setTimeout(() => {
      this.getRoutesUrl();
    }, 0);
  }

  ngOnInit(): void {
    this.groupViewService.projectGroupView.pipe(takeUntil(this.$destroy)).subscribe((e) => {
      if (e) {
        this.groupView = e;
        this.cdr.detectChanges();
      }
    });

    this.treeViewMessengerService.treeViewVisible.pipe(takeUntil(this.$destroy)).subscribe((isTreeOpen: boolean) => {
      this.isOpenTreeWindow = isTreeOpen;
    });

    this.elementViewMessengerService.elementViewVisible.pipe(takeUntil(this.$destroy)).subscribe((isOpen: boolean) => {
      this.elementViewOpen = isOpen;
    });

    this.longTextViewMessengerService.longTextViewVisible.pipe(takeUntil(this.$destroy)).subscribe((isOpen: boolean) => {
      this.isLongTextViewOpen = isOpen;
    });

    this.sheetsViewMessengerService.sheetsViewVisible.pipe(takeUntil(this.$destroy)).subscribe((isOpen: boolean) => {
      this.isOpenSheetsWindow = isOpen;
      this.trySendToSheet();
    });

    this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(takeUntil(this.$destroy)).subscribe((e) => {
      this.avaProjectId = e?.avaProjectId;
      this.projectId = e?.parentProjectId;
      this.trySendToSheet();
      setTimeout(() => this.checkMenuIsCompact(), 1);
    });

    this.selectedQuantityTakeOffMessengerService.someChangesQuantityTakeOff.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.trySendToSheet(true);
    });

    this.selectedQuantityTakeOffMessengerService.selectedQuantityTakeOff
      .pipe(takeUntil(this.$destroy))
      .subscribe((qto: QuantityTakeOffGet) => {
        this.quantityTakeOffId = qto?.id;
        this.trySendToSheet();
      });

    this.selectedSpecificationElementMessengerService.selectedElement.pipe(takeUntil(this.$destroy)).subscribe((e) => {
      this.selectedElement = e?.element;
      setTimeout(() => this.checkMenuIsCompact(), 1);
    });

    this.lvEditorService.editMode.pipe(takeUntil(this.$destroy)).subscribe((edit: boolean) => (this.isEditMode = edit));

    this.selectedProjectMessengerService.selectedProject
      .pipe(
        takeUntil(this.$destroy),
        switchMap(() => {
          return this.projectsClient.getAllProjects(
            null,
            null,
            null,
            false,
            false,
            null,
            null,
            null,
            null,
            'lastAccessedAtUtc desc',
            '',
            6,
            1
          );
        })
      )
      .subscribe((projects) => {
        if (projects && projects?.data?.length > 1) {
          this.fiveLastOpenedProjects = projects.data.slice(1);
        }
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  trySendToSheet(isChanges?: boolean): void {
    if (this.isOpenSheetsWindow) {
      const message = isChanges
        ? {
            command: 'changes',
            data: null
          }
        : {
            command: 'IDs',
            data: {
              avaProjectId: this.avaProjectId,
              quantityTakeOffId: this.modePage.includes('invoices') ? this.quantityTakeOffId : ''
            }
          };
      this.sheetsViewMessengerService.sendDataToSheetsView(message);
    }
  }

  private getRoutesUrl(): void {
    this.router.events
      .pipe(
        startWith(new RoutesRecognized(0, this.router.url, this.router.url, null)),
        filter((e) => e instanceof RoutesRecognized),
        takeUntil(this.$destroy)
      )
      .subscribe((resp: RoutesRecognized) => {
        this.currentUrl = resp.urlAfterRedirects;
        this.activeMode();
        setTimeout(() => this.checkMenuIsCompact(), 1);
      });
  }

  private checkMenuIsCompact(): void {
    if (this.btnWrap) {
      const gap =
        this.btnWrap.nativeElement.offsetLeft +
        this.btnWrap.nativeElement.offsetWidth -
        (this.beforeBlock.offsetLeft + this.beforeBlock.offsetWidth);
      if (this.isEditor) {
        if (gap < 960) {
          this.isCompactMenu = true;
        } else {
          this.isCompactMenu = false;
        }
      } else {
        if (this.isCalcMode) {
          if (gap < 910) {
            this.isCompactMenu = true;
          } else {
            this.isCompactMenu = false;
          }
        } else if (this.isInvoiceInfo) {
          if (this.currentUrl.includes('estimations')) {
            if (gap < 690) {
              this.isCompactMenu = true;
            } else {
              this.isCompactMenu = false;
            }
          } else {
            if (gap < 560) {
              this.isCompactMenu = true;
            } else {
              this.isCompactMenu = false;
            }
          }
        } else {
          if (gap < 460) {
            this.isCompactMenu = true;
          } else {
            this.isCompactMenu = false;
          }
        }
      }
    }
  }

  private activeMode(): void {
    this.isInvoiceInfo = this.burgerUrl.some((part: string) => this.currentUrl.includes(part));
    this.isCalcMode = this.calcUrl.some(
      (part: string) =>
        this.currentUrl.includes(part) && !this.currentUrl.includes('project-products') && !this.currentUrl.includes('scenario')
    );
    this.isEditor = this.currentUrl.includes('lv-editor');
    this.isScenario = this.currentUrl.includes('scenario');
    this.isSetting = this.currentUrl.includes('setting');
    this.isInProjectProductsView = this.currentUrl.includes('project-products');

    this.quantityTakeOffPageId = '';
    this.isPages = false;
    if (this.isCalcMode && !this.isInProjectProductsView) {
      this.modePage = 'calculation';
    } else if (this.isInvoiceInfo) {
      const arrUrl = this.currentUrl.split('/');
      this.modePage = `invoice.${arrUrl.slice(5).join('.')}`;
      const indexPages = arrUrl.findIndex((item) => item === 'pages');
      this.isPages = indexPages > -1;
      if (this.isPages && indexPages === arrUrl.length - 2) {
        this.quantityTakeOffPageId = arrUrl.pop();
      }
    } else if (this.isEditor) {
      this.modePage = 'lv-editor';
    } else if (this.isSetting) {
      this.modePage = 'setting';
    } else if (this.isInProjectProductsView) {
      this.modePage = 'project-products';
    } else if (this.isScenario) {
      this.modePage = 'scenario';
    } else if (this.currentUrl.includes('selection')) {
      this.modePage = 'selection';
    } else {
      this.modePage = 'other-mode';
    }
    this.modePageService.setModePage(this.modePage);

    this.elementViewMessengerService.elementViewVisible.pipe(take(1)).subscribe((isOpen: boolean) => {
      if (isOpen) {
        if (this.modePage === 'calculation' || this.modePage.includes('invoice')) {
          this.elementViewMessengerService.setDataToElementView({
            command: 'changeModePage',
            data: [this.modePage]
          });
        }
      }
    });
  }

  isInvoices(): boolean {
    return this.modePage?.includes('invoices') && !this.isPages;
  }

  toggleTreeWidth(): void {
    this.isToggleTreeWidth = !this.isToggleTreeWidth;
    this.treeDisplayService.setTreeDisplay(this.isToggleTreeWidth);
  }

  openTreeWindow(): void {
    this.topMenuButtonsService.setClickedButton('openTreeWindow');
  }

  openDetailWindow(): void {
    this.elementViewMessengerService.showElementViewWindow();
  }

  openLongTextWindow(): void {
    this.longTextViewMessengerService.showLongTextViewWindow();
  }

  changeGroupView(value): void {
    if (this.isOpenTreeWindow) {
      this.treeViewMessengerService.sendDataToTreeView({ command: TreeViewCommandType.ChangeGroupeView, data: [value] });
    }
    this.groupViewService.setGroupView(value);
  }

  generatePdf(): void {
    if (this.isCalcMode || this.isScenario) {
      this.modalService
        .openModal(CalculationExportModalComponent, { dialogType: ConfirmationType.General })
        .addPanelClass('export-calc-modal')
        .afterOpened()
        .pipe(takeUntil(this.$destroy))
        .subscribe();
    } else {
      const fileName: string = this.quantityTakeOffPageId ? 'Aufmaßblätter' : 'Abrechnung';

      const argumentsReport: string[] = this.quantityTakeOffPageId
        ? [this.quantityTakeOffId, this.quantityTakeOffPageId]
        : [this.quantityTakeOffId];
      this.modalService.openModal(InvoiceExportPreviewComponent, {
        dialogType: ConfirmationType.General,
        data: {
          fileName,
          reportType: this.modePage,
          argumentsReport
        }
      });
    }
  }

  openCheckWindow(): void {
    this.modalService
      .openModal(CalculationCheckModalWindowComponent, { dialogType: ConfirmationType.General, disableClose: true })
      .afterClosed()
      .subscribe((selectedMissingPosition) => {
        if (selectedMissingPosition) {
          this.selectedSpecificationElementMessengerService.trySelectElementById(selectedMissingPosition?.id);
        }
      });
  }

  showServiceSpecification(): void {
    this.modalService.openModal(ServiceSpecificationTableViewComponent, { dialogType: ConfirmationType.General, autoFocus: false });
  }

  openServiceSpecificationTable(): void {
    this.router.navigate(['projects', this.projectId, 'service-specifications']);
  }

  openProjectManagement(): void {
    this.router.navigate(['projects', this.projectId, 'project-management']);
  }

  openProjectFiles(): void {
    this.router.navigate(['projects', this.projectId, 'project-files']);
  }

  openMasterData(): void {
    this.router.navigate(['master-data']);
  }

  showMainPositionTable(): void {
    this.topMenuButtonsService.setClickedButton('showMainPositionTable');
  }

  showSettingsData(): void {
    this.router.navigate(['projects', this.projectId, 'service-specifications', this.avaProjectId, 'settings']);
  }

  showElementEditor(): void {
    this.router.navigate(['projects', this.projectId, 'service-specifications', this.avaProjectId, 'lv-editor']);
  }

  showQuickEditTable(): void {
    this.router.navigate(['projects', this.projectId, 'service-specifications', this.avaProjectId, 'quick-edit']);
  }

  openAttachmentsModalWindow(): void {
    this.topMenuButtonsService.setClickedButton('openAttachmentsModalWindow');
  }

  openCheckFormulaErrorsModalWindow(): void {
    this.topMenuButtonsService.setClickedButton('openCheckFormulaErrorsModalWindow');
  }

  showOtherAdditions(): void {
    this.topMenuButtonsService.setClickedButton('showOtherAdditions');
  }

  showDeduction(): void {
    this.topMenuButtonsService.setClickedButton('showDeduction');
  }

  openInquiries(): void {
    this.topMenuButtonsService.setClickedButton('openInquiries');
  }

  openEstimations(): void {
    this.topMenuButtonsService.setClickedButton('openEstimations');
  }

  showQuantity(): void {
    this.topMenuButtonsService.setClickedButton('showQuantity');
  }

  editorAdd(): void {
    this.topMenuButtonsService.setClickedButton('editorAdd');
  }

  editorRemove(): void {
    this.topMenuButtonsService.setClickedButton('editorRemove');
  }

  editorReorder(): void {
    this.topMenuButtonsService.setClickedButton('editorReorder');
  }

  editorEdit(): void {
    this.topMenuButtonsService.setClickedButton('editorEdit');
  }

  openSheetsWindow(): void {
    this.sheetsViewMessengerService.showSheetsViewWindow();
  }

  showProjectArticles(): void {
    this.router.navigate(['projects', this.projectId, 'service-specifications', this.avaProjectId, 'calculation', 'project-products']);
  }

  selectProject(project: ProjectGet): void {
    this.router.navigate(['projects', project.id, 'service-specifications']).then((res) => {
      if (res) {
        this.selectedProjectMessengerService.setSelectedProject(project);
        this.projectsClient
          .setUserHasAccessedProject(project.id)
          .pipe(take(1))
          .subscribe(() => this.projectsService.forceRefresh());
      }
    });
  }

  closeTreeWindow(event: MouseEvent): void {
    event.stopPropagation();
    this.treeViewMessengerService.closeTreeViewWindow();
  }

  closeElementWindow(event: MouseEvent): void {
    event.stopPropagation();
    this.elementViewMessengerService.closeElementViewWindow();
  }

  closeLongTextWindow(event: MouseEvent): void {
    event.stopPropagation();
    this.longTextViewMessengerService.closeLongTextViewWindow();
  }

  closeSheetsWindow(event: MouseEvent): void {
    event.stopPropagation();
    this.sheetsViewMessengerService.closeSheetsViewWindow();
  }

  editorAutoReorder(): void {
    this.topMenuButtonsService.setClickedButton('editorAutoReorder');
  }

  editorChangeNumbers(): void {
    this.topMenuButtonsService.setClickedButton('editorChangeNumbers');
  }

  editorChangeNumbersForCurrentGroup(): void {
    this.topMenuButtonsService.setClickedButton('editorChangeNumbersForCurrentGroup');
  }

  openMarkedElementsWindow(): void {
    this.modalService
      .openModal(MarkedElementsModalWindowComponent, { dialogType: ConfirmationType.General, disableClose: true })
      .afterClosed()
      .subscribe((e) => {
        if (e) {
          this.selectedSpecificationElementMessengerService.trySelectElementById(e?.id);
        } else if (e === null) {
          this.avaNotificationsService.info('Im LV sind keine markierten Elemente vorhanden.');
        }
      });
  }

  openCalculationEstimation(): void {
    this.modalService.openModal(CalculationEstimationModalComponent, { dialogType: ConfirmationType.General });
  }
}
