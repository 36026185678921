import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';

import { QuantityEstimationTransferRoundingPut } from '../../../generated-client/generated-client';

@Component({
  selector: 'pa-invoice-rounding',
  standalone: true,
  imports: [
    CommonModule,
    FlexLayoutDirective,
    MatRadioModule,
    MatCheckboxModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    MatButtonModule
  ],
  templateUrl: './invoice-rounding.component.html',
  styleUrls: ['./invoice-rounding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceRoundingComponent implements OnInit {
  isModal: boolean;
  roundingMargin: number | null = null;
  roundingOptions: QuantityEstimationTransferRoundingPut[] = [
    {
      alwaysRoundUp: true,
      lessThan: 1,
      roundToNearest: 1
    },
    {
      alwaysRoundUp: true,
      lessThan: 10,
      roundToNearest: 1
    },
    {
      alwaysRoundUp: true,
      lessThan: 100,
      roundToNearest: 10
    },
    {
      alwaysRoundUp: true,
      lessThan: 1000,
      roundToNearest: 100
    },
    {
      alwaysRoundUp: true,
      lessThan: 10000,
      roundToNearest: 1000
    },
    {
      alwaysRoundUp: true,
      lessThan: 100000,
      roundToNearest: 10000
    },
    {
      alwaysRoundUp: true,
      lessThan: 1000000,
      roundToNearest: 100000
    }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    @Optional() private matDialogRef: MatDialogRef<InvoiceRoundingComponent>
  ) {}

  ngOnInit(): void {
    if (this.data && this.data.isModal) {
      this.isModal = this.data.isModal;
    }
  }

  removeRoundingOption(option: QuantityEstimationTransferRoundingPut): void {
    this.roundingOptions = this.roundingOptions.filter((o) => o !== option);
  }

  addRoundingOption(): void {
    this.roundingOptions.push({
      alwaysRoundUp: true,
      lessThan: 0,
      roundToNearest: 0
    });
  }

  saveRounding(isSave: boolean): void {
    if (isSave) {
      this.matDialogRef.close({ roundingOptions: this.roundingOptions, roundingMargin: this.roundingMargin });
      return;
    }
    this.matDialogRef.close();
  }
}
