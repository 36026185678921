import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { NgFor } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogClose } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';

import { Subject, takeUntil, debounceTime } from 'rxjs';

import { CompanyGet, ProjectStatus } from 'app/generated-client/generated-client';
import { ProjectsService } from 'app/shared/services/lightquery/projects.service';

import { ProjectStatusPipe } from '../../pipes/ui-data-display/project-status.pipe';
import { CompaniesService } from '../../services/lightquery/companies.service';

import { FindProjectTableComponent } from '../find-project-table/find-project-table.component';

type DataClickFunction = {
  dblClickRoute?: boolean;
  dblClickEvent?: boolean;
};

@Component({
  selector: 'pa-find-project',
  templateUrl: './find-project.component.html',
  styleUrls: ['./find-project.component.scss'],
  providers: [ProjectsService],
  standalone: true,
  imports: [
    MatDialogContent,
    CdkDrag,
    CdkDragHandle,
    FormsModule,
    MatCheckbox,
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    NgFor,
    FindProjectTableComponent,
    MatButton,
    MatDialogClose,
    ProjectStatusPipe
  ]
})
export class FindProjectComponent implements OnInit {
  listStatus = Object.keys(ProjectStatus).map((ps) => <ProjectStatus>ps);
  filterByStatus = '';
  listCompanies: CompanyGet[] = [];
  filterByCompanyId = '';
  projectNumber = '';
  contactName = '';
  nameFull = '';
  showArchivedProjects = false;
  private filterSource: Subject<boolean> = new Subject<boolean>();
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private companiesService: CompaniesService,
    public projectsService: ProjectsService,
    @Inject(MAT_DIALOG_DATA) public dataClickFunction: DataClickFunction
  ) {}

  ngOnInit(): void {
    this.companiesService.getAll().subscribe((companies) => (this.listCompanies = companies));

    this.projectsService.initService({
      paging: { page: 1, pageSize: 5 }
    });

    this.filterSource.pipe(takeUntil(this.$destroy), debounceTime(250)).subscribe(() => this.runFilter());
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  filter(): void {
    this.filterSource.next(true);
  }

  runFilter(): void {
    this.projectsService.setQueryParameter('filter', this.nameFull);
    this.projectsService.setQueryParameter('companyId', this.filterByCompanyId);
    this.projectsService.setQueryParameter('status', this.filterByStatus);
    this.projectsService.setQueryParameter('includeArchived', this.showArchivedProjects ? this.showArchivedProjects.toString() : '');
    this.projectsService.setQueryParameter('projectNumberFilter', this.projectNumber);
    this.projectsService.setQueryParameter('contactNameFilter', this.contactName);
  }
}
