<div mat-dialog-content>
  <h1>{{ data.header }}</h1>
  <p>{{ data.text }}</p>

  <mat-dialog-content>
    <table mat-table [dataSource]="data.positions">
      <ng-container matColumnDef="itemNumber">
        <th mat-header-cell *matHeaderCellDef>OZ</th>
        <td mat-cell *matCellDef="let element">{{ element.itemNumber?.stringRepresentation }}</td>
      </ng-container>

      <ng-container matColumnDef="shortText">
        <th mat-header-cell *matHeaderCellDef>Kurztext</th>
        <td mat-cell *matCellDef="let element">{{ element.shortText }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['itemNumber', 'shortText']"></tr>
      <tr mat-row *matRowDef="let row; columns: ['itemNumber', 'shortText']" (click)="selectPosition(row)"></tr>
    </table>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="warn" (click)="cancel()">Abbrechen</button>
  </mat-dialog-actions>
</div>
