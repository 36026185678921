import { Pipe, PipeTransform } from '@angular/core';

import { IElementDto } from 'app/generated-client/generated-client';

@Pipe({
  name: 'nodeWithoutCalculation',
  standalone: true
})
export class NodeWithoutCalculationPipe implements PipeTransform {
  transform(value: IElementDto, arg: string[]): boolean {
    if (arg && arg.length) {
      return !arg.includes(value.id);
    }
    return true;
  }
}
