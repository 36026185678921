<div fxLayout="column" class="wrapper">
  <div fxLayout="row" class="title">
    <h2 *ngIf="mode === 'articles'">Stammartikel</h2>
    <h2 *ngIf="mode === 'equipments'">Stammgerät</h2>
  </div>

  <fieldset class="export">
    <legend>Export</legend>
    <mat-checkbox [(ngModel)]="includeHeaderOnlyOnFirstPage">Kopfzeile nur auf der ersten Seite drucken</mat-checkbox>
    <div class="generate-pdf-button">
      <button [disabled]="isLoadingPdf" mat-raised-button color="primary" (click)="generateReport()">Nutzungsauswertung</button>
    </div>
  </fieldset>

  <div *ngIf="isLoadingPdf" fxFlex fxLayoutAlign="center center" class="spinner">
    <mat-spinner></mat-spinner>
  </div>

  <form #savingForm="ngForm" (ngSubmit)="saveItemsForm()">
    <div class="wrap-input" fxLayout="row" fxLayoutGap="12px">
      <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input
          matInput
          required
          type="text"
          name="name"
          [(ngModel)]="formValues.name"
          [disabled]="!isEdit"
          (ngModelChange)="this.isChanged = true"
        />
        <mat-error> Der Name ist erforderlich </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Beschreibung</mat-label>
        <input
          matInput
          type="text"
          name="description"
          [(ngModel)]="formValues.description"
          [disabled]="!isEdit"
          (ngModelChange)="this.isChanged = true"
        />
      </mat-form-field>
    </div>
    <div class="wrap-input" fxLayout="row" fxLayoutGap="12px">
      <mat-form-field appearance="fill">
        <mat-label>EP</mat-label>
        <input
          matInput
          type="text"
          name="price"
          matInputDecimalPlaces
          [(ngModel)]="formValues.price"
          [disabled]="!isEdit"
          (ngModelChange)="this.isChanged = true"
        />
        <mat-icon matSuffix>euro</mat-icon>
        <mat-error> Bitte einen gültigen Zahlenwert eingeben </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Einheit</mat-label>
        <input
          matInput
          type="text"
          name="unit"
          [(ngModel)]="formValues.unit"
          [disabled]="!isEdit"
          (ngModelChange)="this.isChanged = true"
        />
      </mat-form-field>
    </div>

    <div fxLayout="row wrap" fxLayoutGap="12px" class="buttons">
      <button mat-raised-button [disabled]="isEdit" type="button" [color]="isEdit ? 'primary' : ''" (click)="isEdit = !isEdit">
        Bearbeiten
      </button>
      <button mat-raised-button color="primary" type="submit" [disabled]="!savingForm.valid || !isChanged || !isEdit">Speichern</button>
      <button mat-raised-button color="warn" type="button" (click)="dismiss()">Abbrechen</button>
      <button mat-raised-button color="warn" type="button" (click)="delete()">Löschen</button>
    </div>
  </form>
</div>
