<div class="app-content" fxFlexFill>
  <dangl-header
    [logoInitials]="logoInitials"
    [prefix]="prefix"
    [postfix]="postfix"
    [preReleaseVersion]="preReleaseVersion"
    [preReleaseBuildDate]="preReleaseBuildDate"
    [preReleaseLiveSiteLink]="preReleaseLiveSiteLink"
    (menuButtonClicked)="toggleMenuButton()"
    [showMenuButton]="userInfo != null"
  >
    <div class="pack pack-before" #beforeBlock>
      <pa-category-inform></pa-category-inform>
    </div>
    <div class="pack pack-after">
      <pa-top-level-menu [beforeBlock]="beforeBlock"></pa-top-level-menu>
      <pa-lock-icon [project]="project"></pa-lock-icon>
      <pa-zoomer *ngIf="electronService.isElectron"></pa-zoomer>
      <aside
        class="indicator"
        (click)="resetRequestsArray()"
        [ngClass]="{
          blue: requestsRunning > 0,
          green: !hasFailedRequests,
          red: hasFailedRequests
        }"
      ></aside>
      <pa-notification-list></pa-notification-list>
      <pa-project-users *ngIf="!isQtoOnlyMode"></pa-project-users>
      <pa-login-partial *ngIf="!isInElementDetailView"></pa-login-partial>
    </div>
  </dangl-header>
  <mat-sidenav-container>
    <mat-sidenav
      mode="side"
      [opened]="menuOpened && !isInElementDetailView"
      (closedStart)="menuOpened = false"
      (openedChange)="stoppedChange()"
    >
      <pa-side-nav></pa-side-nav>
    </mat-sidenav>
    <mat-sidenav-content class="app-flex-parent">
      <div [class.main-content]="userInfo != null" class="app-flex-view">
        <ng-content></ng-content>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <pa-button-back></pa-button-back>
</div>
