import { NgIf } from '@angular/common';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatStepper, MatStep, MatStepLabel, MatStepperNext, MatStepperPrevious } from '@angular/material/stepper';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';

import { AvaFileInspectionResultGet, AvaFilesClient } from 'app/generated-client/generated-client';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';

import { OriginDtoPipe } from '../../../../shared/pipes/ui-data-display/origin-dto.pipe';
import { ElectronService } from '../../../../shared/services/electron/electron.service';
import { LocalProjectsService } from '../../services/local-projects.service';

@Component({
  selector: 'pa-add-local-project',
  templateUrl: './add-local-project.component.html',
  styleUrls: ['./add-local-project.component.scss'],
  standalone: true,
  imports: [
    MatStepper,
    MatStep,
    MatStepLabel,
    NgIf,
    MatButton,
    MatProgressSpinner,
    MatStepperNext,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatStepperPrevious,
    MatIcon,
    OriginDtoPipe
  ]
})
export class AddLocalProjectComponent implements OnInit, OnDestroy {
  avaFileInspectionResult: AvaFileInspectionResultGet;
  fileCouldNotBeRead = false;
  performingAvaInspection = false;
  projectDataFormGroup: FormGroup;
  requestEnRoute = false;
  selectedFile: File;
  private $destroy: Subject<boolean> = new Subject<boolean>();
  selectedDatabaseFileLocation: string;

  constructor(
    private avaFilesClient: AvaFilesClient,
    private notificationsService: AvaNotificationsService,
    private router: Router,
    private _formBuilder: FormBuilder,
    public sanitizer: DomSanitizer,
    private electronService: ElectronService,
    private localProjectsService: LocalProjectsService,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.setFormBuilderParams();
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();

    this.electronService.ipcRenderer.removeListener('ProjectSaveLocationSelected', (event, data) =>
      this.handleProjectSaveLocationSelected(event, data)
    );
  }

  private setFormBuilderParams(): void {
    this.projectDataFormGroup = this._formBuilder.group({
      name: ['', (c) => Validators.required(c)],
      buyer: ['', (c) => Validators.required(c)],
      description: ['']
    });
  }

  onFileSelected(files: FileList): void {
    if (files && files.length > 0) {
      this.selectedFile = files[0];

      this.performingAvaInspection = true;
      this.avaFilesClient
        .inspectAvaFile({
          fileName: this.selectedFile.name,
          data: this.selectedFile
        })
        .subscribe(
          (ava: AvaFileInspectionResultGet) => {
            this.fileCouldNotBeRead = false;
            this.performingAvaInspection = false;
            this.avaFileInspectionResult = ava;
            this.patchFormGroupValues(ava);
          },
          () => {
            this.performingAvaInspection = false;
            this.fileCouldNotBeRead = true;
            this.notificationsService.error('Die Datei konnte nicht gelesen werden.');
          }
        );
    }
  }

  private patchFormGroupValues(ava: AvaFileInspectionResultGet): void {
    if (ava.projectInformation) {
      this.projectDataFormGroup.patchValue({
        name: ava.projectInformation.name,
        buyer: ava.projectInformation.buyer?.name,
        description: ava.projectInformation.description
      });
    }
  }

  createProject(): void {
    this.requestEnRoute = true;
    this.createProjectInternal();
  }

  private async createProjectInternal(): Promise<void> {
    this.localProjectsService
      .createLocalProject(this.selectedDatabaseFileLocation, {
        name: this.projectDataFormGroup.value.name,
        buyer: this.projectDataFormGroup.value.buyer,
        description: this.projectDataFormGroup.value.description,
        serviceSpecificationFile: {
          base64Content: await this.getBase64ForSelectedFile(),
          fileName: this.selectedFile.name,
          mimeType: 'application/octet-stream'
        }
      })
      .subscribe({
        next: (project) => {
          this.requestEnRoute = false;
          this.notificationsService.success('Projekt importiert.');
          this.localProjectsService.activeProjectFilePath = this.selectedDatabaseFileLocation;
          this.router.navigate(['/projects', project.id, 'service-specifications']);
        },
        error: () => {
          this.requestEnRoute = false;
          this.notificationsService.error('Projekt konnte nicht erstellt werden.');
        }
      });
  }

  cancelProjectCreation(): void {
    this.router.navigate(['/projects']);
  }

  private getBase64ForSelectedFile(): Promise<string> {
    return new Promise<string>((resolve) => {
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(this.selectedFile);
      reader.onload = function () {
        let encoded: string = reader.result.toString().replace(/^data:(.*,)?/, '');
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
    });
  }

  selectProjectSaveLocationInQtoOnlyMode(): void {
    this.electronService.ipcRenderer.send('SelectProjectSaveLocation', {
      projectName: this.projectDataFormGroup.value.name
    });

    this.electronService.ipcRenderer.on('ProjectSaveLocationSelected', (event, data) =>
      this.handleProjectSaveLocationSelected(event, data)
    );
  }

  private handleProjectSaveLocationSelected(event, data: string[]): void {
    this.ngZone.run(() => {
      this.selectedDatabaseFileLocation = data[0];
    });
  }
}
