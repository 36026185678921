import {
  CdkTable,
  CdkColumnDef,
  CdkHeaderCellDef,
  CdkHeaderCell,
  CdkCellDef,
  CdkCell,
  CdkHeaderRowDef,
  CdkHeaderRow,
  CdkRowDef,
  CdkRow
} from '@angular/cdk/table';
import { PercentPipe, NgClass, NgStyle, NgIf, NgFor, DecimalPipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  OnDestroy
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { CalculationTotalsService } from '@serv-spec/services/calculation-totals.service';

import { AvaProjectAdditionGet, GroupCalculationTotalsGet, PositionCalculationTotals } from 'app/generated-client/generated-client';
import { FocusRowService } from 'app/shared/services/focus-row.service';
import { SelectedSpecificationAdditionsMessengerService } from 'app/shared/services/messengers/selected-specification-additions-messenger.service';
import { SelectRowsService } from 'app/shared/services/select-rows.service';

type pcType = {
  [key: string]: number;
};

type GroupTotalsType = {
  text?: string;
  labourHours?: number;
  labourTotalPrice?: number;
  priceComponents?: pcType;
  sum?: number;
};

@Component({
  selector: 'pa-group-totals-table',
  templateUrl: './group-totals-table.component.html',
  styleUrls: ['./group-totals-table.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    CdkTable,
    NgStyle,
    CdkColumnDef,
    CdkHeaderCellDef,
    CdkHeaderCell,
    CdkCellDef,
    CdkCell,
    NgIf,
    NgFor,
    CdkHeaderRowDef,
    CdkHeaderRow,
    CdkRowDef,
    CdkRow,
    DecimalPipe
  ]
})
export class GroupTotalsTableComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() isLoaded: boolean;
  @Input() totalsData: PositionCalculationTotals | GroupCalculationTotalsGet;
  listCellWidth: number[];
  @ViewChild('scrolledElement') scrolledElement: ElementRef<HTMLDivElement>;
  @ViewChildren('cellTotalWidth') headCellTotalWidth: QueryList<HTMLTableCellElement>;
  avaAdditions: AvaProjectAdditionGet;
  listHeadCell: HTMLTableCellElement[] = [];
  dataSource: MatTableDataSource<GroupTotalsType>;
  displayedColumns: string[] = [];
  additions: string[];
  isLoading = true;
  isGroup = false;
  listRows = '';
  siteOperationCostsAddition: number;
  companyOperationCostsAddition: number;
  activePriceComp = '';
  percentsAdditions: { [nameAdd: string]: { siteOperationCostsAddition: number; companyOperationCostsAddition: number } } = {};
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private selectedSpecificationAdditionsMessengerService: SelectedSpecificationAdditionsMessengerService,
    private percentPipe: PercentPipe,
    private focusRowService: FocusRowService,
    private selectRowsService: SelectRowsService,
    private calculationTotalsService: CalculationTotalsService
  ) {}

  ngOnInit(): void {
    this.selectedSpecificationAdditionsMessengerService.selectedAdditions
      .pipe(takeUntil(this.$destroy))
      .subscribe((e: AvaProjectAdditionGet) => {
        this.avaAdditions = e;
        this.getDataTable();
      });

    this.calculationTotalsService.listWidth.pipe(takeUntil(this.$destroy)).subscribe((list: number[]) => {
      this.listCellWidth = list;
      this.trySetWidth();
    });

    this.calculationTotalsService.changeActivePriceComp.pipe(takeUntil(this.$destroy)).subscribe((priceComp: string) => {
      this.activePriceComp = priceComp;
      this.getPositionDataTable();
    });

    this.calculationTotalsService.scrollX.pipe(takeUntil(this.$destroy)).subscribe((scroll: number) => {
      this.scrolledElement.nativeElement.scrollLeft = scroll;
    });
  }

  ngOnChanges(): void {
    if (this.totalsData) {
      this.getDataTable();
      const list = this.focusRowService.focusedRowNumber
        ? [this.focusRowService.focusedRowNumber]
        : this.selectRowsService.listSelectedRows;

      if (list.length) {
        this.listRows = `Zeilen: ${list.sort((a1, a2) => a1 - a2).join(', ')}`;
      } else {
        this.listRows = '';
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.trySetWidth();
    }, 1);
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private getDataTable(): void {
    if ((this.totalsData as PositionCalculationTotals)?.unitPrice) {
      this.getPositionDataTable();
      this.isGroup = false;
    } else {
      this.getGroupDataTable();
      this.isGroup = true;
    }
  }

  trySetWidth(): void {
    if (!this.listHeadCell?.length) {
      this.listHeadCell = this.headCellTotalWidth?.map((item: any) => item.nativeElement);
    }
    if (!this.listHeadCell?.length) {
      this.headCellTotalWidth?.changes.pipe(take(1), takeUntil(this.$destroy)).subscribe((r: QueryList<ElementRef>) => {
        this.listHeadCell = r.map((item: any) => item.nativeElement);
        this.setCellWidth();
      });
    } else {
      this.setCellWidth();
    }
  }

  private setCellWidth(): void {
    if (this.listCellWidth?.length) {
      this.listHeadCell?.forEach((element, i) => {
        const delta = !i ? 1 : 0;
        const newWidth = `${this.listCellWidth[i] - delta}px`;
        element.style.width = newWidth;
        element.style['min-width'] = newWidth;
        element.style['max-width'] = newWidth;
      });
      setTimeout(() => {
        this.isLoading = false;
      }, 1);
    }
  }

  private getActivePriceComp(str: string): string {
    if (!this.activePriceComp) {
      return '';
    }

    const isLabour = this.activePriceComp === 'labourHoursSum';
    const result = isLabour ? this[str] : this.percentsAdditions[this.activePriceComp][str];
    const uiDisplayPriceComponent = isLabour ? 'Arbeitsstunden' : this.activePriceComp;

    return `(${uiDisplayPriceComponent} - ${this.percentPipe.transform(result, '1.2-2')})`;
  }

  private getPositionDataTable(): void {
    const positionTotals = this.totalsData as PositionCalculationTotals;

    if (!positionTotals) {
      return;
    }
    const wg = this.avaAdditions?.riskAndProfitAddition;

    if (!this.avaAdditions) {
      return;
    }

    this.additions = this.avaAdditions.priceComponents.sort((c, n) => c.index - n.index).map((c) => c.priceComponent);
    this.getPercents();

    const text = [
      'EP o. Zuschl.',
      `BGK ${this.getActivePriceComp('siteOperationCostsAddition')}`,
      `AGK ${this.getActivePriceComp('companyOperationCostsAddition')}`,
      `W&G (${this.percentPipe.transform(wg, '1.2-2')})`,
      'EP m. Zuschl.',
      'GP o.Zuschl.',
      'GP m. Zuschl.'
    ];

    const positionTotalArr = [
      positionTotals.perUnitWithoutAdditions,
      positionTotals.siteOperationCostsPerUnit,
      positionTotals.companyOperationCostsPerUnit,
      positionTotals.riskAndProfitCostsPerUnit,
      positionTotals.unitPrice,
      positionTotals.totalWithoutAdditions,
      positionTotals.totalWithAdditions
    ].map((r, index) => {
      const list = Object.keys(r.priceComponents);
      const sum = list.reduce((sumAll, prop) => sumAll + r.priceComponents[prop], 0) + (r.labourHoursSum ?? 0);
      return {
        text: text[index],
        labourHours: r.labourHours || 0,
        labourHoursSum: r.labourHoursSum || 0,
        priceComponents: r.priceComponents,
        sum
      };
    });

    this.displayedColumns = ['text', 'labourHours', 'labourHoursSum', ...this.additions.map((pc: string) => `pc_${pc}`), 'sum', 'tail'];

    this.dataSource = new MatTableDataSource(positionTotalArr);

    this.trySetWidth();
  }

  private getGroupDataTable(): void {
    if (this.totalsData) {
      const text = ['ohne Zuschlag', 'mit Zuschlag'];
      const groupTotalArr = [this.totalsData.totalWithoutAdditions, this.totalsData.totalWithAdditions].map((r, index) => {
        const list = Object.keys(r.priceComponents);
        const sum = list.reduce((sumAll, prop) => sumAll + r.priceComponents[prop], 0) + (r.labourHoursSum ?? 0);
        return {
          text: text[index],
          labourHours: r.labourHours || 0,
          labourHoursSum: r.labourHoursSum || 0,
          priceComponents: r.priceComponents,
          sum
        };
      });

      this.additions = Object.keys(groupTotalArr[0].priceComponents);
      this.getPercents();

      this.displayedColumns = ['text', 'labourHours', 'labourHoursSum', ...this.additions.map((pc: string) => `pc_${pc}`), 'sum', 'tail'];

      this.dataSource = new MatTableDataSource(groupTotalArr);
      this.trySetWidth();
    }
  }

  getPercents(): void {
    this.companyOperationCostsAddition = this.avaAdditions?.laborCompanyOperationCostsAddition;
    this.siteOperationCostsAddition = this.avaAdditions?.laborSiteOperationCostsAddition;

    this.percentsAdditions = {};
    this.avaAdditions?.priceComponents.forEach(
      (item) =>
        (this.percentsAdditions[item.priceComponent] = {
          siteOperationCostsAddition: item.siteOperationCostsAddition,
          companyOperationCostsAddition: item.companyOperationCostsAddition
        })
    );
  }
}
