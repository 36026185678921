import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { CalculationEntry } from 'app/generated-client/generated-client';

@Injectable({
  providedIn: 'root'
})
export class ChangeFormulaFactorService {
  private rowForChangeFormulaFactorSource = new Subject<CalculationEntry>();
  rowForChangeFormulaFactor = this.rowForChangeFormulaFactorSource.asObservable();

  private changedValueOfFormulaFactorSoruce = new Subject<any>();
  changedValueOfFormulaFactor = this.changedValueOfFormulaFactorSoruce.asObservable();

  constructor() {}

  setRowForChangeFormulaFactor(r: CalculationEntry) {
    this.rowForChangeFormulaFactorSource.next(r);
  }

  setChangedValueOfFormulaFactor(formulaFactorValue: string) {
    this.changedValueOfFormulaFactorSoruce.next(formulaFactorValue);
  }
}
