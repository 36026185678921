import { Component } from '@angular/core';

import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'pa-spinner-overlay',
  templateUrl: './spinner-overlay.component.html',
  styleUrls: ['./spinner-overlay.component.scss'],
  standalone: true,
  imports: [SpinnerComponent]
})
export class SpinnerOverlayComponent {}
