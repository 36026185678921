<div class="wrapper" [style.maxHeight]="'calc(100%  - 285px )'">
  <div class="buttons">
    <button mat-raised-button routerLink="add-project">Neues Projekt Erstellen</button>
    <button mat-raised-button (click)="openProject()">Projekt Öffnen</button>
  </div>

  <h2>Letzte Projekte</h2>

  <table class="project-table" mat-table [dataSource]="localProjects" matSort>
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let row" matRipple>{{ row.name }}</td>
    </ng-container>
    <ng-container matColumnDef="filePath">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Speicherort</th>
      <td mat-cell *matCellDef="let row" matRipple>{{ row.filePath }}</td>
    </ng-container>
    <ng-container matColumnDef="lastOpenedAtUtc">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Zuletzt angesehen</th>
      <td mat-cell *matCellDef="let row" matRipple>
        {{ row.lastOpenedAtUtc | date: 'dd.MM.yyyy HH:mm' }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="['name', 'filePath', 'lastOpenedAtUtc']; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: ['name', 'filePath', 'lastOpenedAtUtc']" (click)="selectProject(row)"></tr>
  </table>
</div>
