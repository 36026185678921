import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includes',
  standalone: true
})
export class IncludesPipe implements PipeTransform {
  transform(value: any[] | string, searchValue: any | string): boolean {
    return !!value?.includes(searchValue);
  }
}
