import { Injectable } from '@angular/core';

import { ReplaySubject } from 'rxjs';

import { MasterAdditionGroupGet, AvaProjectAdditionCalculation } from 'app/generated-client/generated-client';

@Injectable({
  providedIn: 'root'
})
export class CalculateTransferWageService {
  private transferWageSource = new ReplaySubject<number>(1);
  transferWage$ = this.transferWageSource.asObservable();
  constructor() {}

  private setTransferWage(transferWage: number): void {
    this.transferWageSource.next(transferWage);
  }

  calculateTransferWage(addition: MasterAdditionGroupGet | AvaProjectAdditionCalculation): void {
    let transferWage = 0;
    if (!addition) {
      return;
    }

    let calculationWage = addition.calculationWage;
    if (typeof calculationWage === 'string') {
      calculationWage = +(<string>calculationWage).replace(',', '.');
    }

    let laborSiteOperationCostsAddition = addition.laborSiteOperationCostsAddition;
    if (typeof laborSiteOperationCostsAddition === 'string') {
      laborSiteOperationCostsAddition = +(<string>laborSiteOperationCostsAddition).replace(',', '.') / 100;
    }

    let laborCompanyOperationCostsAddition = addition.laborCompanyOperationCostsAddition;
    if (typeof laborCompanyOperationCostsAddition === 'string') {
      laborCompanyOperationCostsAddition = +(<string>laborCompanyOperationCostsAddition).replace(',', '.') / 100;
    }

    let riskAndProfitAddition = addition.riskAndProfitAddition;
    if (typeof riskAndProfitAddition === 'string') {
      riskAndProfitAddition = +(<string>riskAndProfitAddition).replace(',', '.') / 100;
    }

    transferWage =
      calculationWage * (1 + laborSiteOperationCostsAddition) * (1 + laborCompanyOperationCostsAddition) * (1 + riskAndProfitAddition);

    if (transferWage == null || isNaN(transferWage)) {
      transferWage = 0;
    }
    this.setTransferWage(transferWage);
  }
}
