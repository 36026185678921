import { Injectable } from '@angular/core';
import { ReplaySubject } from "rxjs";

import { ContactGet } from "../../../generated-client/generated-client";

@Injectable({
  providedIn: 'root'
})
export class SelectedContactMessengerService {
  private selectedContactSource = new ReplaySubject<ContactGet>(1);
  selectedContact = this.selectedContactSource.asObservable();

  setSelectedContact(selectedContact: ContactGet): void {
    this.selectedContactSource.next(selectedContact);
  }
}
