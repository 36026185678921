<input
  *ngIf="rowIndex"
  id="{{ rowIndex }}-subPositionIdentifier"
  type="text"
  matInput
  [formControl]="itemNumber"
  (keydown)="onKeyDown($event)"
  (change)="onInputChange()"
  onfocus="this.select()"
  (blur)="onBlur()"
/>
