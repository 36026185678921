import { Injectable } from '@angular/core';
import { ProjectFileFolderGet, ProjectFileGet } from 'app/generated-client/generated-client';
import { FileElement } from 'app/shared/models';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectFileService {
  private querySubject = new BehaviorSubject<FileElement[]>([]);
  private map = new Map<string, FileElement>();

  private selectedProjFolderSource = new ReplaySubject<ProjectFileFolderGet>(1);
  selectedProjFolder = this.selectedProjFolderSource.asObservable();

  private currentPathSource = new ReplaySubject<string>(1);
  currentPath = this.currentPathSource.asObservable();

  private selectedProjFileSource = new ReplaySubject<ProjectFileGet>(1);
  selectedProjFile = this.selectedProjFileSource.asObservable();

  constructor() {}

  add(fileElement: FileElement): void {
    this.map.set(fileElement.id, this.clone(fileElement));
  }

  delete(id: string): void {
    this.map.delete(id);
  }

  update(id: string, update: Partial<FileElement>): void {
    let element = this.map.get(id);
    element = Object.assign(element, update);
    this.map.set(element.id, element);
  }

  queryInFolder(folderId: string): Observable<FileElement[]> {
    const result: FileElement[] = [];
    this.map.forEach(element => {
      if (element.parent === folderId) {
        result.push(this.clone(element));
      }
    });
    if (!this.querySubject) {
      this.querySubject = new BehaviorSubject(result);
    } else {
      this.querySubject.next(result);
    }
    return this.querySubject.asObservable();
  }

  get(id: string): FileElement {
    return this.map.get(id);
  }

  getAllOpenedFiles(): Observable<FileElement[]> {
    return this.querySubject;
  }

  clone(element: FileElement): FileElement {
    return JSON.parse(JSON.stringify(element));
  }

  setSelectedProjFolder(folder: ProjectFileFolderGet): void {
    this.selectedProjFolderSource.next(folder);
  }

  setSelectedProjFile(selectedProjFile: ProjectFileGet): void {
    this.selectedProjFileSource.next(selectedProjFile);
  }

  setCurrentPath(path: string): void {
    this.currentPathSource.next(path);
  }

  reset(): void {
    this.map.clear();
    this.querySubject.next([]);
    this.selectedProjFileSource.next(null);
    this.selectedProjFolderSource.next(null);
    this.currentPathSource.next('');
  }
}
