<h2 mat-dialog-title>Angezeigte Spalten</h2>

<div mat-dialog-content>
  <div *ngFor="let item of listColumns">
    <mat-checkbox #checkboxRef [(ngModel)]="listColumnsObj[item].show">
      {{ listColumnsObj[item].name | columnNames }}
    </mat-checkbox>
  </div>
</div>

<mat-dialog-actions>
  <button #btnRef mat-raised-button mat-dialog-close color="warn" paAutoFocus>Abbrechen</button>
  <button mat-raised-button color="primary" class="btn-action" (click)="save()">Speichern</button>
</mat-dialog-actions>
