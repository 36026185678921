<div mat-dialog-content>
  <h3>Update Verfügbar</h3>
  <p>
    Eine neue Version der App ist verfügbar: <strong>{{ version }}</strong
    >, <i>derzeit installiert: {{ currentVersion }}</i
    >.
  </p>
  <button mat-button mat-raised-button color="primary" (click)="goToDownloadPage()">Herunterladen</button>
  <button mat-button mat-raised-button (click)="dismiss()" id="ignore-update-check-button">Ignorieren</button>
  <button mat-button mat-raised-button color="warn" (click)="skipVersion()">Nicht mehr für diese Version erinnern</button>
</div>
