<ng-container *ngIf="modePageService.modePage | async as modePage">
  <as-split
    #asSplitPositionDividerRef
    direction="vertical"
    (dragEnd)="onDragEnd($event.sizes)"
    [style.height]="showOnlyCompactView ? 'auto' : '100%'"
  >
    <ng-container *ngIf="!isInnerWindow">
      <as-split-area [minSize]="5" class="position-area" [order]="0">
        <ng-container *ngTemplateOutlet="elementInfo"></ng-container>
      </as-split-area>
    </ng-container>
    <ng-container *ngIf="isInnerWindow">
      <ng-container *ngTemplateOutlet="elementInfo"></ng-container>
    </ng-container>

    <ng-template #elementInfo>
      <div *ngIf="element">
        <div class="main-tabs" *ngIf="!elementViewOpen; else topic" #position_details_wrapper>
          <ng-container *ngIf="extend; else compactElement">
            <mat-icon class="switcher" (click)="extend = !extend" matTooltip="Auf kompakte Ansicht wechseln"> short_text </mat-icon>
            <mat-divider></mat-divider>
            <h1>
              Position
              <div class="button-changes" *ngIf="!isInnerWindow" fxLayoutGap="12px">
                <button mat-raised-button color="primary" *ngIf="isChanged && editMode" (click)="saveChanges()">Speichern</button>
                <button mat-raised-button color="primary" (click)="changeEditMode()">{{ editMode ? 'Abbrechen' : 'Bearbeiten' }}</button>
              </div>
            </h1>
            <ng-container [ngTemplateOutlet]="topic"></ng-container>
            <mat-tab-group animationDuration="0ms" (selectedTabChange)="showTinyMce($event)" (selectedIndexChange)="hideTinyMce($event)">
              <mat-tab label="Preise">
                <div class="main-tabs-item">
                  <div fxLayout="row wrap" fxLayoutGap="12px">
                    <mat-form-field appearance="fill" (dblclick)="tryEnterPositionQuantityCalculation()">
                      <mat-label>Menge</mat-label>
                      <input disabled type="number" matInput [value]="element.quantity" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                      <mat-label>Mengeneinheit</mat-label>
                      <input disabled matInput [value]="element.unitTag" />
                    </mat-form-field>
                  </div>
                  <div fxLayout="row wrap" fxLayoutGap="12px">
                    <mat-form-field appearance="fill" *ngIf="projectCurrency">
                      <mat-label>Währung</mat-label>
                      <input disabled type="text" matInput [value]="projectCurrency" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                      <mat-label>MwSt.</mat-label>
                      <input disabled matInput [value]="element.taxRate | percent: '1.2-2'" />
                    </mat-form-field>
                  </div>
                  <div fxLayout="row wrap" fxLayoutGap="12px">
                    <mat-form-field appearance="fill">
                      <mat-label>EP</mat-label>
                      <input disabled matInput [value]="element.unitPrice | number: '1.2-2'" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                      <mat-label>GP</mat-label>
                      <input disabled matInput [value]="element.totalPrice | number: '1.2-2'" />
                    </mat-form-field>
                  </div>
                  <div fxLayout="row wrap" fxLayoutGap="12px">
                    <mat-form-field appearance="fill">
                      <mat-label>Rabatt</mat-label>
                      <input disabled matInput [value]="element.deductionFactor | percent: '1.2-2'" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                      <mat-label>GP nach Nachlass</mat-label>
                      <input disabled matInput [value]="element.deductedPrice | number: '1.2-2'" />
                    </mat-form-field>
                  </div>
                  <div fxLayout="row wrap" fxLayoutGap="12px">
                    <mat-form-field appearance="fill">
                      <mat-label>GP Brutto</mat-label>
                      <input disabled matInput [value]="element.totalPriceGross | number: '1.2-2'" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                      <mat-label>GP Brutto nach Nachlass</mat-label>
                      <input disabled matInput [value]="element.totalPriceGrossDeducted | number: '1.2-2'" />
                    </mat-form-field>
                  </div>
                  <div *ngIf="editMode">
                    <pa-item-number-editing
                      label="OZ"
                      [itemNumber]="backElement.itemNumber"
                      (itemNumberChange)="onItemNumberChanged($event)"
                    ></pa-item-number-editing>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Eigenschaften">
                <div class="main-tabs-item">
                  <div fxLayout="row wrap" fxLayoutGap="12px">
                    <mat-form-field appearance="fill">
                      <mat-label>Typ</mat-label>
                      <mat-select disabled [(ngModel)]="element.positionType" *ngIf="!editMode">
                        <mat-option *ngFor="let positionType of positionTypes" [value]="positionType">
                          {{ positionType | positionType }}
                        </mat-option>
                      </mat-select>
                      <mat-select [(ngModel)]="backElement.positionType" (ngModelChange)="isChanged = true" *ngIf="editMode">
                        <mat-option *ngFor="let positionType of positionTypes" [value]="positionType">
                          {{ positionType | positionType }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                      <mat-label>Leistungstyp</mat-label>
                      <mat-select disabled [(ngModel)]="element.serviceType">
                        <mat-option *ngFor="let serviceType of serviceTypes" [value]="serviceType">
                          {{ serviceType | serviceType }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div fxLayout="row wrap" fxLayoutGap="12px">
                    <mat-form-field appearance="fill">
                      <mat-label>Auftragsstatus</mat-label>
                      <mat-select disabled [(ngModel)]="element.comissionStatus">
                        <mat-option *ngFor="let comissionStatus of comissionStatuses" [value]="comissionStatus">
                          {{ comissionStatus | comissionStatus }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                      <mat-label>Preistyp</mat-label>
                      <mat-select disabled [(ngModel)]="element.priceType" *ngIf="!editMode">
                        <mat-option *ngFor="let priceType of priceTypes" [value]="priceType">
                          {{ priceType | priceType }}
                        </mat-option>
                      </mat-select>

                      <mat-select [(ngModel)]="backElement.priceType" (ngModelChange)="isChanged = true" *ngIf="editMode">
                        <mat-option *ngFor="let priceType of priceTypes" [value]="priceType">
                          {{ priceType | priceType }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div fxLayout="column">
                    <mat-checkbox disabled [checked]="element.complemented">Bezuschlagt</mat-checkbox>
                    <mat-checkbox disabled [checked]="element.amountToBeEnteredByBidder">Menge vom Bieter anzugeben</mat-checkbox>
                    <mat-checkbox disabled [checked]="element.priceCompositionRequired">Preisaufgliederung erfoderlich</mat-checkbox>
                    <mat-checkbox disabled [checked]="element.useDifferentTaxRate">Abweichender Steuersatz</mat-checkbox>
                    <mat-checkbox disabled [checked]="element.isLumpSum">Pauschalposition</mat-checkbox>
                    <mat-checkbox disabled [checked]="element.notOffered">Nicht angeboten</mat-checkbox>
                  </div>
                  <div *ngIf="element.executionDescriptionReference">
                    <mat-form-field appearance="fill" class="allWidth">
                      <mat-label>Ausführungsbeschreibung</mat-label>
                      <mat-select disabled [(ngModel)]="element.executionDescriptionReference">
                        <mat-option *ngFor="let positionItem of elementList" [value]="positionItem.id">
                          {{ positionItem.text }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div *ngIf="element.alternativeTo">
                    <mat-form-field appearance="fill" class="allWidth">
                      <mat-label>Alternative zu</mat-label>
                      <mat-select disabled [(ngModel)]="element.alternativeTo">
                        <mat-option *ngFor="let positionItem of elementList" [value]="positionItem.id">
                          {{ positionItem.text }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div *ngIf="element.repetitionTo">
                    <mat-form-field appearance="fill" class="allWidth">
                      <mat-label>Wiederholung, Grundposition</mat-label>
                      <mat-select disabled [(ngModel)]="element.repetitionTo">
                        <mat-option *ngFor="let positionItem of elementList" [value]="positionItem.id">
                          {{ positionItem.text }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div *ngIf="element.standardizedDescription">
                    <h3>Standardbeschreibung</h3>
                    <div fxLayoutGap="12px">
                      <mat-form-field appearance="fill" *ngIf="element.standardizedDescription.standardReference">
                        <mat-label>Standardreferenztyp</mat-label>
                        <input disabled type="text" matInput [value]="element.standardizedDescription.standardReference" />
                      </mat-form-field>
                      <mat-form-field appearance="fill" *ngIf="element.standardizedDescription.stlbReference">
                        <mat-label>STLB Referenz</mat-label>
                        <input disabled type="text" matInput [value]="element.standardizedDescription.stlbReference.catalogueName" />
                      </mat-form-field>
                      <mat-form-field appearance="fill" *ngIf="element.standardizedDescription.standardReferenceType">
                        <mat-label>Typ</mat-label>
                        <mat-select disabled [(ngModel)]="element.standardizedDescription.standardReferenceType">
                          <mat-option *ngFor="let standardReferenceType of standardReferenceTypes" [value]="standardReferenceType">
                            {{ standardReferenceType }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div *ngIf="element.complementedBy?.length">
                    <h3>Bezuschlagt</h3>
                    <ol>
                      <li *ngFor="let item of element.complementedBy">
                        <mat-form-field appearance="fill" class="allWidth">
                          <mat-label>Bezuschlagt von</mat-label>
                          <mat-select disabled [ngModel]="item">
                            <mat-option *ngFor="let positionItem of elementList" [value]="positionItem.id">
                              {{ positionItem.text }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </li>
                    </ol>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Text" #tabLangText>
                <div class="main-tabs-item">
                  <mat-form-field appearance="fill">
                    <mat-label>Textergänzungstyp</mat-label>
                    <mat-select disabled [(ngModel)]="element.additionType">
                      <mat-option *ngFor="let additionType of additionTypes" [value]="additionType">
                        {{ additionType | additionType }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <pa-long-text
                    *ngIf="!editMode"
                    [longText]="element.htmlLongText"
                    (changedLongText)="changedLongText($event)"
                    [addition]="additionLongText"
                  >
                  </pa-long-text>
                  <div *ngIf="editMode" style="width: 100%">
                    <mat-form-field appearance="fill" style="width: 100%">
                      <mat-label>Kurztext</mat-label>
                      <textarea
                        matInput
                        [(ngModel)]="backElement.shortText"
                        style="width: 100%"
                        (ngModelChange)="isChanged = true"
                      ></textarea>
                    </mat-form-field>
                  </div>
                  <div *ngIf="editMode && showLangText">
                    <dangl-tiny-mce
                      tinyMceLanguageCode="de"
                      [(ngModel)]="backElement.htmlLongText"
                      (ngModelChange)="changedTinyMce($event)"
                    ></dangl-tiny-mce>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Referenzen" *ngIf="element.catalogueReferences?.length || element.quantityAssignments?.length">
                <div class="main-tabs-item">
                  <h3>Projektkataloge</h3>
                  <ng-container *ngIf="element.catalogueReferences?.length">
                    <div *ngIf="element.projectCatalogues?.length">
                      <ol>
                        <li *ngFor="let item of element.projectCatalogues">{{ item.name }} ( {{ item.catalogueType | catalogueType }} )</li>
                      </ol>
                    </div>

                    <div *ngIf="element.catalogueReferences?.length">
                      <h3>Katalogreferenz</h3>
                      <ol>
                        <li *ngFor="let item of element.catalogueReferences">
                          {{ item.catalogue?.name }} ( {{ item.catalogue?.catalogueType | catalogueType }} ):
                          <strong>{{ item.catalogueReferenceKey }}</strong>
                        </li>
                      </ol>
                    </div>
                  </ng-container>

                  <div *ngIf="element.quantityAssignments?.length">
                    <h3>Mengenzuordnungen</h3>
                    <ol>
                      <li *ngFor="let item of element.quantityAssignments">
                        <h3>Mengenzuordnung</h3>
                        <div fxLayout="row" fxLayoutGap="12px">
                          <div *ngIf="item.projectCatalogues?.length">
                            <h3>Projektkataloge</h3>
                            <ol>
                              <li *ngFor="let item2 of item.projectCatalogues">
                                {{ item2.name }} ( {{ item2.catalogueType | catalogueType }} )
                              </li>
                            </ol>
                          </div>
                          <div *ngIf="item.catalogueReferences?.length">
                            <h3>Katalogreferenzen</h3>
                            <ol>
                              <li *ngFor="let item2 of item.catalogueReferences">
                                {{ item2.catalogue?.name }} ( {{ item2.catalogue?.catalogueType | catalogueType }} )
                              </li>
                            </ol>
                          </div>
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Unterbeschreibungen" *ngIf="element.subDescriptions?.length">
                <div class="main-tabs-item">
                  <ol>
                    <li *ngFor="let item of element.subDescriptions">
                      <h3>{{ item.shortText }}</h3>
                      <div>ID: {{ item.identifier }}</div>
                      <div>
                        <mat-checkbox disabled [checked]="item.amountToBeEnteredByBidder">Menge ist vom Bieter anzugeben</mat-checkbox>
                      </div>
                      <div *ngIf="item.executionDescriptionReference">
                        <mat-form-field appearance="fill">
                          <mat-label>Referenz zu Ausführungsbeschreibung</mat-label>
                          <input disabled type="text" matInput [value]="item.executionDescriptionReference" />
                        </mat-form-field>
                      </div>
                      <div fxLayoutGap="12px">
                        <mat-form-field appearance="fill">
                          <mat-label>Menge</mat-label>
                          <input disabled matInput [value]="item.quantity | number: '1.3-3'" />
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                          <mat-label>Textergänzung</mat-label>
                          <mat-select disabled [(ngModel)]="item.additionType">
                            <mat-option *ngFor="let additionType of additionTypes" [value]="additionType">
                              {{ additionType | additionType }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div *ngIf="item.standardizedDescription" fxLayoutGap="12px">
                        <mat-form-field appearance="fill" *ngIf="item.standardizedDescription.standardReference">
                          <mat-label>Standardreferenz</mat-label>
                          <input disabled type="text" matInput [value]="item.standardizedDescription.standardReference" />
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                          <mat-label>Typ</mat-label>
                          <mat-select disabled [(ngModel)]="item.standardizedDescription.standardReferenceType">
                            <mat-option *ngFor="let standardReferenceType of standardReferenceTypes" [value]="standardReferenceType">
                              {{ standardReferenceType }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </li>
                  </ol>
                </div>
              </mat-tab>
            </mat-tab-group>
          </ng-container>

          <ng-template #compactElement>
            <div fxLayout="row" fxLayoutGap="20px">
              <div fxLayout="column">
                <mat-icon class="switcher" (click)="extend = !extend" matTooltip="Auf Detailansicht wechseln"> view_list </mat-icon>
                <mat-icon class="switcher" matTooltip="Langtext" (click)="openLongTextWindow()">description</mat-icon>
              </div>
              <div
                class="compact"
                fxLayoutGap="12px"
                fxLayout="row"
                *ngIf="element.elementTypeDiscriminator === 'PositionDto'; else serviceSpecificationGroupDto"
              >
                <div>
                  <div fxLayout="row" fxLayoutGap="12px" class="row">
                    <div fxLayout="row" class="position">
                      <div class="topic">OZ</div>
                      <pa-item-number-field [element]="element"></pa-item-number-field>
                    </div>
                    <div fxLayout="row" class="position">
                      <div class="topic">LV-M</div>
                      <input
                        #quantity
                        id="quantity"
                        class="val right"
                        type="number"
                        matInputDecimalPlaces
                        [scale]="3"
                        [isShowZero]="true"
                        [ngClass]="{
                          green: currentProjectHasQuantityEstimation
                        }"
                        [matTooltip]="
                          currentProjectHasQuantityEstimation ? 'Doppelklick um zur Mengenermittlung zu gelangen, F7 zum anzeigen' : ''
                        "
                        (dblclick)="tryEnterPositionQuantityCalculation()"
                        (blur)="isDoBlurOperation && shortEditor(quantity)"
                        [value]="element.quantity || element.quantity === 0 ? element.quantity : ''"
                        (keydown)="tryStopPropagation($event)"
                      />
                      <input
                        #unitTag
                        id="unitTag"
                        class="topic"
                        type="text"
                        (blur)="isDoBlurOperation && shortEditor(unitTag)"
                        [value]="element.unitTag || ''"
                        (keydown)="tryStopPropagation($event)"
                      />
                    </div>
                    <div fxLayout="row" class="fixed-price-field position" *ngIf="currentProjectHasAssumedQuantities">
                      <div class="topic">VA-M</div>
                      <input
                        class="val right green"
                        matInputDecimalPlaces
                        (dblclick)="tryEnterPositionQuantityAssumedQuantities()"
                        [scale]="3"
                        [isShowZero]="true"
                        [value]="assumedQuantities?.assumedQuantitiesByPositionId[element.id] || ''"
                        (keydown)="tryStopPropagation($event)"
                      />
                    </div>
                    <div fxLayout="row" class="fixed-price-field position">
                      <div class="topic">FP</div>
                      <input
                        id="fixedPrice"
                        class="val right"
                        type="number"
                        matInputDecimalPlaces
                        [(ngModel)]="positionFixedPrice"
                        (focus)="lastFixedPrice = positionFixedPrice"
                        (blur)="isDoBlurOperation && setFixedPrice()"
                        (keydown)="tryStopPropagation($event)"
                      />
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutGap="12px" class="row">
                    <div fxLayout="row" class="position">
                      <div class="topic">KT</div>
                      <textarea
                        #shortText
                        id="shortText"
                        class="val-long"
                        [matTooltip]="element.shortText || ''"
                        [value]="element.shortText || ''"
                        rows="4"
                        (blur)="isDoBlurOperation && shortEditor(shortText)"
                        (focus)="setFocusedField($event)"
                        (keydown)="tryStopPropagation($event)"
                      ></textarea>
                    </div>
                    <div
                      class="switch"
                      *ngIf="
                        showedPositionsEstimationCompact &&
                        ((modePage | includes: 'calculation') || (modePage | includes: 'selection')) &&
                        (currentProjectHasQuantityEstimation || currentProjectHasAssumedQuantities)
                      "
                    >
                      <pa-switcher-table-qto-compact></pa-switcher-table-qto-compact>
                    </div>
                  </div>
                </div>
                <div>
                  <div fxLayout="row" fxLayoutGap="12px" class="row">
                    <div fxLayout="column" fxLayoutGap="5px">
                      <div fxLayout="row" fxLayoutAlign="space-between">
                        <div class="topic bold">EP</div>
                        <input class="right bold" type="text" readonly [value]="calculateUnitPrice | number: '1.2-2'" />
                      </div>
                      <div fxLayout="row" fxLayoutAlign="space-between">
                        <div class="topic bold">GP</div>
                        <input
                          class="right bold"
                          type="text"
                          readonly
                          [value]="(!positionFixedPrice ? element.totalPrice : positionFixedPrice * element.quantity) | number: '1.2-2'"
                        />
                      </div>
                      <div fxLayout="row">
                        <ng-container *ngIf="(modePageService.modePage | async) === 'calculation'">
                          <pa-total-sums> </pa-total-sums>
                        </ng-container>
                      </div>
                    </div>
                    <div fxLayout="row" class="position">
                      <pa-position-type [element]="element"></pa-position-type>
                    </div>
                    <mat-checkbox disabled [checked]="element?.oenormPositionProperties?.isMainPosition">Hauptposition</mat-checkbox>
                    <mat-checkbox disabled [checked]="!!element.repetitionTo">Leitbeschreibung</mat-checkbox>
                    <mat-checkbox disabled [checked]="!noTotalGroup && !withoutTotal(element)">In LV-GP enthalten</mat-checkbox>
                  </div>
                </div>
              </div>
              <ng-template #serviceSpecificationGroupDto>
                <div class="compact" fxLayoutGap="12px" fxLayout="row">
                  <div>
                    <div fxLayout="row" fxLayoutGap="12px" class="row">
                      <div fxLayout="row" class="position">
                        <div class="topic">OZ</div>
                        <pa-item-number-field [element]="element"></pa-item-number-field>
                      </div>
                      <div fxLayout="row">
                        <div class="topic">GP</div>
                        <input
                          class="val right"
                          type="text"
                          readonly
                          [value]="
                            (positionFixedPrice === null ? element.totalPrice : calculateUnitPrice * element.quantity) | number: '1.2-2'
                          "
                        />
                      </div>
                    </div>
                    <div fxLayout="row" class="row position">
                      <div class="topic">KT</div>
                      <textarea
                        #shortText
                        id="shortText"
                        class="val-long"
                        [matTooltip]="element.shortText || ''"
                        [value]="element.shortText || ''"
                        rows="4"
                        (blur)="isDoBlurOperation && shortEditor(shortText)"
                        (focus)="setFocusedField($event)"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </ng-template>
          <ng-content select="[groupTotalsTable]"></ng-content>
        </div>

        <ng-template #topic>
          <h2>{{ element.itemNumber?.stringRepresentation }} {{ element.shortText }}</h2>
        </ng-template>
      </div>
      <pa-calculation-row-line
        *ngIf="
          !isInnerWindow &&
          element.elementTypeDiscriminator === 'PositionDto' &&
          ((modePage | includes: 'calculation') || (modePage | includes: 'selection'))
        "
      ></pa-calculation-row-line>
    </ng-template>
    <as-split-area
      *ngIf="showedPositionsEstimationCompact && ((modePage | includes: 'calculation') || (modePage | includes: 'selection'))"
      [size]="15"
      [minSize]="15"
      [order]="1"
    >
      <pa-invoice-positions [isReadOnlyOriginal]="true" [isCompact]="true"></pa-invoice-positions>
    </as-split-area>
    <as-split-area
      *ngIf="
        element.elementTypeDiscriminator === 'PositionDto' && ((modePage | includes: 'calculation') || (modePage | includes: 'selection'))
      "
      #asSplitCalcArea
      class="calc-area"
      [order]="3"
    >
      <ng-content select="[calculation]"></ng-content>
    </as-split-area>
    <as-split-area
      *ngIf="
        element.elementTypeDiscriminator === 'PositionDto' &&
        !isInnerWindow &&
        ((modePage | includes: 'calculation') || (modePage | includes: 'selection'))
      "
      [minSize]="2"
      class="total-area"
      [order]="4"
    >
      <pa-group-totals-table [totalsData]="currentTotals"></pa-group-totals-table>
    </as-split-area>
  </as-split>
</ng-container>
