import { NgIf, NgFor } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProjectGet, TextTemplateGet, TextTemplateType } from 'app/generated-client/generated-client';
import { TextTemplatesService } from 'app/shared/services/lightquery/text-templates.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';

@Component({
  selector: 'pa-set-text-selection',
  templateUrl: './set-text-selection.component.html',
  styleUrls: ['./set-text-selection.component.scss'],
  standalone: true,
  imports: [NgIf, MatFormField, MatLabel, MatSelect, FormsModule, MatOption, NgFor]
})
export class SetTextSelectionComponent implements OnInit, OnDestroy {
  @Input() templateType: TextTemplateType;
  @Output() selectedName = new EventEmitter<string>();
  companyId: string;
  listNames: TextTemplateGet[];
  selectedTemplate: TextTemplateGet;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    public textTemplatesService: TextTemplatesService
  ) {}

  ngOnInit(): void {
    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((p: ProjectGet) => {
      this.companyId = p.companyId;
      this.getListName();
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  getListName(): void {
    this.textTemplatesService.getAll().subscribe((list: TextTemplateGet[]) => {
      this.listNames = list.filter((item) => item.companyId === this.companyId && item.templateType === this.templateType);
    });
  }

  changedName(): void {
    this.selectedName.emit(this.selectedTemplate?.htmlText);
  }
}
