<div *ngIf="(elements && elements.length) || elements === null">
  <ul
    [ngClass]="{
      'pl-20': hierarchyLevel !== 0,
      'ml-24': addExtraMargin
    }"
    cdkDropList
    cdkDropListSortingDisabled
    id="tree"
    [cdkDropListConnectedTo]="['tree', 'calculation']"
    (cdkDropListDropped)="drop($event)"
  >
    <li
      *ngFor="let element of elements; trackBy: trackByFn"
      [ngClass]="{
        'top-border': element.elementTypeDiscriminator === 'ServiceSpecificationGroupDto' && hierarchyLevel === 0
      }"
      [attr.data-id]="element?.id"
    >
      <div
        [ngClass]="{
          'choose-node': element.id === choseSelectedElementId,
          'selected-node': element.id === (selectedElementId$ | async)
        }"
        class="child-contents-container"
        (click)="selectElementBySingl(element)"
        (dblclick)="selectElementByDouble(element)"
        [paHoverEffect]="{ apply: true }"
        (contextmenu)="
          modePage === 'lv-editor' || modePage === 'calculation' || isCopyCalculation || isCopyElementView || modePage.includes('invoice')
            ? showContextMenu($event, element)
            : null
        "
        *ngIf="element"
      >
        <div class="drag-placeholder"></div>
        <button mat-icon-button class="node-toggle-button" color="warn" *ngIf="(markedElements$ | async)?.[element?.id]; else emptyIcon">
          <mat-icon class="mat-icon-rtl-mirror">flag</mat-icon>
        </button>
        <div class="wrap" cdkDrag [cdkDragData]="element" (cdkDragStarted)="dragStart($event)" (cdkDragEnded)="dragEnded()">
          <ng-container *ngIf="modePage === 'calculation'; else otherMode">
            <ng-container
              *ngIf="
                element.elementTypeDiscriminator === 'ServiceSpecificationGroupDto' ||
                (element.elementTypeDiscriminator === 'PositionDto' && $any(element).elements?.length)
              "
            >
              <button mat-icon-button class="node-toggle-button" (click)="switchStateNode(element, $event)">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{ !treeState[element.id] ? 'chevron_right' : 'expand_more' }}
                </mat-icon>
              </button>
            </ng-container>

            <button
              class="node-toggle-button"
              mat-icon-button
              *ngIf="
                (element.elementTypeDiscriminator !== 'ServiceSpecificationGroupDto' &&
                  element.elementTypeDiscriminator === 'PositionDto' &&
                  !$any(element).elements?.length) ||
                (element.elementTypeDiscriminator !== 'ServiceSpecificationGroupDto' && element.elementTypeDiscriminator !== 'PositionDto')
              "
            >
              <mat-icon></mat-icon>
            </button>
          </ng-container>
          <ng-template #otherMode>
            <ng-container *ngIf="element.elementTypeDiscriminator === 'ServiceSpecificationGroupDto'">
              <button mat-icon-button class="node-toggle-button" (click)="switchStateNode(element, $event)">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{ !treeState[element.id] ? 'chevron_right' : 'expand_more' }}
                </mat-icon>
              </button>
            </ng-container>
            <button class="node-toggle-button" mat-icon-button *ngIf="element.elementTypeDiscriminator !== 'ServiceSpecificationGroupDto'">
              <mat-icon></mat-icon>
            </button>
          </ng-template>

          <ng-container *ngIf="element.elementTypeDiscriminator === 'NoteTextDto'">
            <div class="flex-line">
              <button mat-icon-button class="node-toggle-button">
                <mat-icon class="mat-icon-rtl-mirror">subject</mat-icon>
              </button>
              <mat-checkbox
                *ngIf="isSelectingMode; else textNoteText"
                [indeterminate]="checkedElements[element.id]?.indeterminate"
                [(ngModel)]="checkedElements[element.id].checked"
                (ngModelChange)="changeSelecting(element, $event)"
                (click)="$event.stopPropagation()"
              >
                <ng-container *ngTemplateOutlet="textNoteText"></ng-container>
              </mat-checkbox>
              <ng-template #textNoteText>
                <ng-container *ngIf="$any(element).shortText; else noTextNoteText">
                  <span>{{ $any(element).shortText }}</span>
                </ng-container>
              </ng-template>
              <ng-template #noTextNoteText>Hinweis</ng-template>
            </div>
            <div *cdkDragPlaceholder></div>
          </ng-container>

          <ng-container *ngIf="element.elementTypeDiscriminator === 'ExecutionDescriptionDto'">
            <div class="flex-line">
              <button mat-icon-button class="node-toggle-button">
                <mat-icon class="mat-icon-rtl-mirror">event</mat-icon>
              </button>
              <mat-checkbox
                *ngIf="isSelectingMode; else textExecutionDescription"
                [indeterminate]="checkedElements[element.id]?.indeterminate"
                [(ngModel)]="checkedElements[element.id].checked"
                (ngModelChange)="changeSelecting(element, $event)"
              >
                <ng-container [ngTemplateOutlet]="textExecutionDescription"></ng-container>
              </mat-checkbox>
              <ng-template #textExecutionDescription> {{ $any(element).identifier }} - {{ $any(element).label }} </ng-template>
            </div>
            <div *cdkDragPlaceholder></div>
          </ng-container>

          <ng-container *ngIf="element.elementTypeDiscriminator === 'PositionDto'">
            <div class="flex-line">
              <button mat-icon-button class="node-toggle-button">
                <mat-icon *ngIf="element | nodeWithoutCalculation: (positionIdsWithoutCalculations$ | async); else block">add</mat-icon>
              </button>
              <mat-checkbox
                *ngIf="isSelectingMode; else textPosition"
                [indeterminate]="checkedElements[element.id]?.indeterminate"
                [(ngModel)]="checkedElements[element.id].checked"
                (ngModelChange)="changeSelecting(element, $event)"
              >
                <ng-container [ngTemplateOutlet]="textPosition"></ng-container>
              </mat-checkbox>
              <ng-template #textPosition>
                <span>
                  {{ $any(element).itemNumber?.stringRepresentation }}
                  {{ $any(element).positionType | showPositionAbbreviation: $any(element).priceType }} -
                  {{ $any(element).shortText ?? '' }}
                </span>
              </ng-template>
            </div>
            <div *cdkDragPlaceholder></div>
          </ng-container>

          <ng-container *ngIf="element.elementTypeDiscriminator === 'ServiceSpecificationGroupDto'">
            <div class="flex-line">
              <button mat-icon-button class="node-toggle-button">
                <mat-icon class="mat-icon-rtl-mirror" *ngIf="element.id !== selectedGroup?.id">folder_open</mat-icon>
                <mat-icon class="mat-icon-rtl-mirror" color="warn" *ngIf="element.id === selectedGroup?.id">folder</mat-icon>
              </button>
              <mat-checkbox
                *ngIf="isSelectingMode; else textGroup"
                [indeterminate]="checkedElements[element.id]?.indeterminate"
                [(ngModel)]="checkedElements[element.id].checked"
                (ngModelChange)="changeSelecting(element, $event)"
              >
                <ng-container [ngTemplateOutlet]="textGroup"></ng-container>
              </mat-checkbox>
              <ng-template #textGroup>
                <span class="text-group"
                  >{{ $any(element).itemNumber?.stringRepresentation }}
                  {{ $any(element).positionType | showPositionAbbreviation: $any(element).priceType }}
                  -
                  {{ $any(element).shortText }}
                </span>
              </ng-template>
            </div>
            <div *cdkDragPlaceholder></div>
          </ng-container>

          <ng-container *ngIf="!element.elementTypeDiscriminator">
            <pa-position-node [element]="element"></pa-position-node>
          </ng-container>

          <ng-template #block>
            <mat-icon color="warn">block</mat-icon>
          </ng-template>
        </div>
      </div>

      <div
        [ngClass]="{
          hidden: !treeState[element.id],
          visible: treeState[element.id]
        }"
      >
        <pa-tree-structure-view
          *ngIf="
            (element.elementTypeDiscriminator === 'ServiceSpecificationGroupDto' &&
              $any(element).elements &&
              $any(element).elements.length) ||
            (element.elementTypeDiscriminator === 'PositionDto' &&
              $any(element).elements &&
              $any(element).elements.length &&
              modePage === 'calculation')
          "
          [elements]="$any(element).elements"
          [hierarchyLevel]="hierarchyLevel + 1"
          [parentNode]="element"
          [subPositionFromParentComponent]="subPositions"
          [choseElementId]="choseSelectedElementId"
          [isSelectingMode]="isSelectingMode"
          [treeState]="treeState"
          [selectedGroupForCopy]="selectedGroup"
          [isInnerWindow]="isInnerWindow"
          [isCopyCalculation]="isCopyCalculation"
          [isCopyElementView]="isCopyElementView"
          [addExtraMargin]="isShowEmptyIcon$ | async"
        ></pa-tree-structure-view>
        <pa-tree-structure-view
          *ngIf="!$any(element)?.elements?.length && $any(element).elementTypeDiscriminator === 'ServiceSpecificationGroupDto'"
          [elements]="null"
          [hierarchyLevel]="hierarchyLevel + 1"
          [subPositionFromParentComponent]="subPositions"
          [choseElementId]="choseSelectedElementId"
          [isSelectingMode]="isSelectingMode"
          [treeState]="treeState"
          [selectedGroupForCopy]="selectedGroup"
          [isInnerWindow]="isInnerWindow"
          [isCopyCalculation]="isCopyCalculation"
          [isCopyElementView]="isCopyElementView"
          [addExtraMargin]="isShowEmptyIcon$ | async"
        >
          <li [attr.data-parent]="element?.id" [attr.data-id]="'without-id'">
            <div class="no-elements item-node">Keine Elemente</div>
          </li>
        </pa-tree-structure-view>
      </div>
    </li>
  </ul>
</div>
<ng-content></ng-content>
<pa-tree-menu
  [contextMenuPosition]="contextMenuPosition"
  [isInnerWindow]="isInnerWindow"
  [isCopyCalculation]="isCopyCalculation"
  [isCopyElementView]="isCopyElementView"
></pa-tree-menu>

<ng-template #emptyIcon>
  <button class="node-toggle-button emty" mat-icon-button *ngIf="(isShowEmptyIcon$ | async) === true">
    <mat-icon></mat-icon>
  </button>
</ng-template>
