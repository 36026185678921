<div class="wrapper">
  <mat-spinner *ngIf="avaPositionId && quantityTakeOffsRunning[avaPositionId]"></mat-spinner>
  <div class="head" #head [style.height]="isCompact ? '0' : 'auto'">
    <h2 *ngIf="invoicePositionInvoiceName">
      Positionsbasierte {{ structureView === 'estimations' ? 'Mengenermittlung' : 'Abrechnung' }} - {{ invoicePositionInvoiceName }}
    </h2>
    <h2 *ngIf="!invoicePositionInvoiceName">Positionsbasierte {{ structureView === 'estimations' ? 'Mengenermittlung' : 'Abrechnung' }}</h2>
    <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="start center" class="line-info">
      <button mat-raised-button color="primary" class="icon-min" (click)="back()" (dblclick)="backCalculation()">Übersicht</button>
      <button mat-raised-button color="primary" class="icon-min" (click)="showQuantity()" *ngIf="structureView === 'estimations'">
        Projektmengen
      </button>
      <div *ngIf="isReadOnly" class="red noedit">Schreibgeschützt</div>
      <ng-container *ngIf="structureView === 'invoices'" [formGroup]="performanceQtoDate">
        <div class="flex-item" [class.hidden]="!performanceQtoDate.value.performancePeriodStartUtc">
          <mat-form-field appearance="fill" class="datepicker field-short">
            <mat-label>Ausführungsbeginn</mat-label>
            <input matInput [matDatepicker]="pickerStart" formControlName="performancePeriodStartUtc" (dateChange)="saveQTOData()" />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerStart"
              [matTooltip]="performanceQtoDate.value.performancePeriodStartUtc ? '' : 'Ausführungsbeginn'"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerStart [disabled]="isReadOnly"></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="flex-item" [class.hidden]="!performanceQtoDate.value.performancePeriodEndUtc">
          <mat-form-field appearance="fill" class="datepicker field-short">
            <mat-label>Ausführungsende</mat-label>
            <input matInput [matDatepicker]="pickerEnd" formControlName="performancePeriodEndUtc" (dateChange)="saveQTOData()" />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerEnd"
              [matTooltip]="performanceQtoDate.value.performancePeriodEndUtc ? '' : 'Ausführungsende'"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerEnd [disabled]="isReadOnly"></mat-datepicker>
          </mat-form-field>
        </div>
      </ng-container>
    </div>
    <pa-position-line [element]="selectedPosition" *ngIf="avaPositionId && !quantityTakeOffsRunning[avaPositionId]"></pa-position-line>
  </div>
  <div
    class="sub-wrapper"
    *ngIf="avaPositionId && !quantityTakeOffsRunning[avaPositionId] && dataSource?.length; else noElement"
    [class.sub-wrapper-readonly]="isReadOnly"
    [style.height]="sanitizer.bypassSecurityTrustStyle('calc(100% - 10px - ' + head.offsetHeight + 'px )')"
  >
    <cdk-virtual-scroll-viewport
      class="mat-elevation-z8"
      tvsItemSize="22"
      headerHeight="22"
      class="table-wrapper"
      [style.height]="sanitizer.bypassSecurityTrustStyle('calc(100% - ' + totalSumHeight + 'px )')"
    >
      <table
        mat-table
        [dataSource]="tableDataSource"
        resizeName="positions"
        (keydown.tab)="$event.preventDefault()"
        (keyup)="onKey($event)"
        [ngClass]="{ readonly: isReadOnly, selecting: isSelectingFormulaRowMode }"
        [trackBy]="trackByFn"
        cdkDropList
        cdkDropListOrientation="horizontal"
        (cdkDropListDropped)="dropHeader($event)"
      >
        <ng-container matColumnDef="index">
          <th mat-header-cell *matHeaderCellDef cdkDrag class="index mat-table-sticky" [cdkDragDisabled]="true">
            <pa-select-all-rows [dataList]="dataSource" [selectList]="listSelectedRows"></pa-select-all-rows>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="grey-back index"
            (contextmenu)="onContextMenu($event, element)"
            (mousedown)="startSelectRow(element.rowIndex)"
          >
            <div class="div-wrap">
              <span id="{{ element.rowIndex }}-index">#{{ element.rowIndex }}</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="rowType">
          <th mat-header-cell *matHeaderCellDef class="row-type drag-head mat-table-sticky" cdkDrag>
            I/Z
            <div class="drag-head__handle" cdkDragHandle></div>
          </th>
          <td mat-cell *matCellDef="let element" (contextmenu)="onContextMenu($event, element)" class="row-type">
            <input
              (change)="isChanged = true; changeInput($event, element, 'rowType')"
              type="text"
              [value]="element.rowType || ''"
              (keydown)="inputRowType($event, element, 'rowType')"
              maxlength="1"
              id="{{ element.rowIndex }}-rowType"
              [disabled]="isReadOnly"
              (focus)="setRowFocus($event)"
              (blur)="lostFocus()"
            />
          </td>
        </ng-container>

        <ng-container matColumnDef="formula">
          <th mat-header-cell *matHeaderCellDef resizeColumn class="drag-head mat-table-sticky" cdkDrag>
            Ansatz
            <div class="drag-head__handle" cdkDragHandle></div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (contextmenu)="onContextMenu($event, element)"
            [ngClass]="{ bold: element.isBold, italic: element.isCursive, underline: element.isUnderlined }"
            [matTooltip]="userSettings.showTooltipsInQtoTable ? element.formula : ''"
            class="error"
          >
            <input
              paOverwriteMode
              paFormulaWarning
              (change)="isChanged = true; changeInput($event, element, 'formula')"
              (overwriteModeChanged)="isChanged = true; changeInputFromOverrideMode($any($event), element, 'formula')"
              type="text"
              [value]="element.formula || ''"
              id="{{ element.rowIndex }}-formula"
              (focus)="setRowFocus($event)"
              (blur)="lostFocus()"
              (keydown)="preventKeyDownEvent($event, element, 'formula')"
              [disabled]="isReadOnly"
              [ngClass]="{
                'number-invalid': element.formulaErrorType
              }"
              [matTooltipClass]="{ 'tooltip-red': true }"
              [matTooltip]="element.formulaErrorMessage"
            />
            <div class="error_line" *ngIf="element.formulaErrorMessage != null && element.formulaErrorPosition != null">
              {{ element.formula?.slice(0, element.formulaErrorPosition) }}<span class="error_point">_</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="result">
          <th mat-header-cell *matHeaderCellDef resizeColumn class="right-align drag-head mat-table-sticky" cdkDrag>
            Ergebnis
            <div class="drag-head__handle" cdkDragHandle></div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (contextmenu)="onContextMenu($event, element)"
            class="grey-back nowrap right-align"
            id="{{ element.rowIndex }}-result"
            [matTooltip]="userSettings.showTooltipsInQtoTable ? (element.result | number: '1.3-3') : ''"
          >
            {{ element.result | number: '1.3-3' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="unit">
          <th mat-header-cell *matHeaderCellDef resizeColumn class="drag-head mat-table-sticky" cdkDrag>
            Einheit
            <div class="drag-head__handle" cdkDragHandle></div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (contextmenu)="onContextMenu($event, element)"
            class="grey-back nowrap"
            id="{{ element.rowIndex }}-unit"
          >
            {{ element.result ? unitTag : '' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="referenceName" *ngIf="isShowReferenceName">
          <th mat-header-cell *matHeaderCellDef class="reference-name drag-head mat-table-sticky" resizeColumn cdkDrag>
            Referenz
            <div class="drag-head__handle" cdkDragHandle></div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (contextmenu)="onContextMenu($event, element)"
            class="reference-name"
            [matTooltip]="userSettings.showTooltipsInQtoTable ? element.referenceName : ''"
          >
            <input
              paOverwriteMode
              [paInputMask]="'[a-zA-Z_].*'"
              (change)="isChanged = true; changeInput($event, element, 'referenceName')"
              (overwriteModeChanged)="isChanged = true; changeInputFromOverrideMode($any($event), element, 'referenceName')"
              type="text"
              [value]="element.referenceName || ''"
              id="{{ element.rowIndex }}-referenceName"
              (keydown)="preventKeyDownEvent($event, element, 'referenceName')"
              [disabled]="isReadOnly"
              (focus)="setRowFocus($event)"
              (blur)="lostFocus()"
            />
          </td>
        </ng-container>

        <ng-container matColumnDef="totalCost">
          <th mat-header-cell *matHeaderCellDef resizeColumn class="right-align drag-head mat-table-sticky" cdkDrag>
            Summe
            <div class="drag-head__handle" cdkDragHandle></div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (contextmenu)="onContextMenu($event, element)"
            id="{{ element.rowIndex }}-totalCost"
            class="grey-back nowrap right-align"
          >
            {{ element.totalCost | projectCurrency }}
          </td>
        </ng-container>

        <ng-container matColumnDef="image">
          <th mat-header-cell *matHeaderCellDef resizeColumn class="drag-head mat-table-sticky" cdkDrag>
            Bild
            <div class="drag-head__handle" cdkDragHandle></div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="table-image"
            id="{{ element.rowIndex }}-image"
            (contextmenu)="onContextMenu($event, element, 'image')"
          >
            <ng-container *ngIf="element.fileId">
              <ng-container *ngIf="userSettings.showImagesInQuantityTakeOff">
                <img id="{{ element.rowIndex }}-image" src="{{ element.fileId | imgSrc | async }}" *ngIf="element.fileId" />
              </ng-container>
              <ng-container *ngIf="!userSettings.showImagesInQuantityTakeOff">
                <span (click)="showImage(element)" matTooltip="Bild anzeigen" class="cross-text"> Bild </span>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="element.quantityTakeOffAttachmentId">
              <span (click)="showImage(element)" matTooltip="Anlage anzeigen" class="cross-text"> Anlage </span>
            </ng-container>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
          (contextmenu)="showHeaderContextMenu($event)"
          class="mat-header-row"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (mouseup)="stopSelectRow($event, row.rowIndex)"
          (click)="clearSelectionRow($event, row)"
          [class]="'row-index' + row.rowIndex"
          [ngClass]="{
            'selected-row': selectedRow(row.rowIndex),
            'showed-row': showMoveSelectRow(row.rowIndex),
            'circular-error': circularErrorRowIndizes[row.rowIndex] && !selectedRow(row.rowIndex) && !showMoveSelectRow(row.rowIndex)
          }"
        ></tr>
      </table>
    </cdk-virtual-scroll-viewport>
    <div
      class="totals"
      *ngIf="
        (isTreeOpen || isToggleTreeWidth) &&
        structureView !== 'estimations' &&
        userSettings.showsTotalsInQuantityTakeOffBelowTable &&
        !isCompact
      "
    >
      <pa-total-sums
        (setTotalSumsHeight)="setTotalSumsHeight($event)"
        [isTreeOpen]="isTreeOpen"
        [isToggleTreeWidth]="isToggleTreeWidth"
        [selectedElement]="elementDto"
      >
      </pa-total-sums>
    </div>
  </div>
  <ng-template #noElement>
    <h2>Kein Element ausgewählt.</h2>
  </ng-template>
</div>

<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
></div>
<mat-menu #contextMenu="matMenu" class="row-menu">
  <ng-template matMenuContent let-item="item">
    <ng-container *ngIf="isImageContextMenu">
      <button *ngIf="item.fileId" mat-menu-item (click)="deleteImage(item)">Bild löschen</button>
    </ng-container>
    <button *ngIf="item.quantityTakeOffAttachmentId" mat-menu-item (click)="deleteImage(item)">Anlage löschen</button>
    <ng-container *ngIf="!isImageContextMenu">
      <ng-container *ngIf="columnName === 'image'; else withOutBtnForLoadPicture">
        <button
          *ngIf="!item.fileId && !item.quantityTakeOffAttachmentId"
          class="btn-for-load-picture"
          (click)="openBrowseFileModal()"
          mat-menu-item
        >
          Bild hinzufügen
        </button>
        <button
          *ngIf="!item.quantityTakeOffAttachmentId && !item.fileId"
          mat-menu-item
          class="btn-for-load-picture"
          (click)="openInvoicePdfFilesModal()"
        >
          Anlage hinzufügen
        </button>
      </ng-container>
      <ng-template #withOutBtnForLoadPicture>
        <div class="btn-fontStyle" *ngIf="userSettings.showButtonsToManageFontStylesInContextMenu">
          <mat-button-toggle-group name="fontStyle" aria-label="Font Style" multiple class="toggle-group-style">
            <mat-button-toggle class="flex-expand" [checked]="item.isBold" (change)="toggleFontStyle('bold', item)">Fett</mat-button-toggle>
            <mat-button-toggle class="flex-expand" [checked]="item.isCursive" (change)="toggleFontStyle('italic', item)"
              >Kursiv</mat-button-toggle
            >
            <mat-button-toggle class="flex-expand" [checked]="item.isUnderlined" (change)="toggleFontStyle('underline', item)"
              >Unterstrichen</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
        <button mat-menu-item (click)="copyCurrentLine(item)">Zeile Kopieren</button>
        <button mat-menu-item (click)="addRow(item, false, true)">Zeile davor hinzufügen</button>
        <button mat-menu-item (click)="addRow(item, true, true)">Zeile danach hinzufügen</button>
        <button mat-menu-item class="red" (click)="deleteRowPlus(item)" *ngIf="!listSelectedRows.length">Zeile löschen</button>
        <button
          mat-menu-item
          class="red"
          *ngIf="listSelectedRows.length && listSelectedRows.length === 1"
          (click)="deleteSelectedRowsPlus(false)"
        >
          Zeile löschen
        </button>
        <button
          mat-menu-item
          class="red"
          *ngIf="listSelectedRows.length && listSelectedRows.length > 1"
          (click)="deleteSelectedRowsPlus(false)"
        >
          {{ listSelectedRows.length }} Zeilen löschen
        </button>
        <button mat-menu-item class="red" *ngIf="listSelectedRows.length && listSelectedRows.length === 1" (click)="cutSelectedRowsPlus()">
          Zeile ausschneiden
        </button>
        <button mat-menu-item class="red" *ngIf="listSelectedRows.length && listSelectedRows.length > 1" (click)="cutSelectedRowsPlus()">
          {{ listSelectedRows.length }} Zeilen ausschneiden
        </button>
        <button mat-menu-item *ngIf="listSelectedRows.length" (click)="saveRows()">Ausgewählte Zeilen Kopieren</button>
        <div fxLayoutAlign="start center" class="btn-fontStyle" *ngIf="savedSelectedRows.length">
          <div [matBadge]="savedSelectedRows.length" class="btn-selected-rows">Zeilen</div>
          <mat-button-toggle-group>
            <mat-button-toggle (click)="copySavedRows(item, true)">Einfügen</mat-button-toggle>
            <mat-button-toggle (click)="copySavedRows(item)">Überschreiben</mat-button-toggle>
            <mat-button-toggle (click)="saveRows(true)">Vergessen</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <button mat-menu-item *ngIf="listSelectedRows.length" (click)="changeFormula(item)">Referenz aus Selektion einfügen</button>
      </ng-template>
    </ng-container>
  </ng-template>
</mat-menu>

<mat-menu #rowHeaderContextMenu="matMenu">
  <ng-template matMenuContent>
    <div mat-menu-item class="context-menu-calc">
      <button mat-button (click)="editListColumns()" class="overlay-button">Spalten Verwalten</button>
    </div>
    <div mat-menu-item class="context-menu-calc">
      <button mat-button (click)="limitWidth(true)" class="overlay-button">Spalten gleich aufteilen</button>
    </div>
    <div mat-menu-item class="context-menu-calc">
      <button mat-button (click)="limitWidth()" class="overlay-button">Spalten an Inhalt anpassen</button>
    </div>
  </ng-template>
</mat-menu>

<div
  style="position: fixed; visibility: hidden"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="rowHeaderContextMenu"
></div>
