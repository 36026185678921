import { NgIf, AsyncPipe, DecimalPipe } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { SpecificProductUsageInformationComponent } from '@serv-spec/components/project-products/components/specific-product-usage-information/specific-product-usage-information.component';

import { PriceComponentType, ProjectProductGet, ProjectProductsClient } from 'app/generated-client/generated-client';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { ProjectProductsService } from 'app/shared/services/lightquery/project-products.service';
import { SelectedPriceComponentTypeService } from 'app/shared/services/messengers/selected-price-component-type.service';
import { ModalService } from 'app/shared/services/modal.service';
import { AvaHubConnector } from 'app/shared/services/signalr/ava-hub-connector';

import { UpperPaginatorComponent } from '../../../../../../../../shared/components/upper-paginator/upper-paginator.component';
import { AvaNotificationsService } from '../../../../../../../../shared/services/ava-notifications.service';
import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';
import { MatInputDecimalPlacesDirective } from '../../../../directives/mat-input-decimal-places.directive';

import { SelectedSpecificationMessengerService } from './../../../../../../../../shared/services/messengers/selected-specification-messenger.service';

@Component({
  selector: 'pa-specific-project-products-table',
  templateUrl: './specific-project-products-table.component.html',
  styleUrls: ['./specific-project-products-table.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    NgIf,
    FlexLayoutDirective,
    MatInputDecimalPlacesDirective,
    MatSuffix,
    MatIcon,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    UpperPaginatorComponent,
    AsyncPipe,
    DecimalPipe
  ]
})
export class SpecificProjectProductsTableComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) private sort: MatSort;
  @Input() showedId: string;
  @Input() set priceComponentType(value: PriceComponentType) {
    this.projectProductsService.setPriceComponentType(value);
    this.selectedPriceComponentTypeService.setSelectedPriceComponentType(value);
  }
  @Input() filter: string;
  private projectId: string;
  private avaProjectId: string;

  displayedColumns: string[];
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    public projectProductsService: ProjectProductsService,
    public selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private projectProductsClient: ProjectProductsClient,
    private avaNotificationsService: AvaNotificationsService,
    private selectedPriceComponentTypeService: SelectedPriceComponentTypeService,
    private modalService: ModalService,
    private avaHubConnector: AvaHubConnector,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.setDataSource();
    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        takeUntil(this.$destroy),
        filter((s) => !!s)
      )
      .subscribe((s) => {
        this.projectId = s?.parentProjectId;
        this.avaProjectId = s?.avaProjectId;
      });
  }

  ngOnDestroy(): void {
    this.projectProductsService.setQueryParameter('catalogueId', null);
    this.projectProductsService.setPriceComponentType(null);
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private setDataSource(): void {
    this.projectProductsService.initService({
      filtering: this.filter,
      paging: { page: 1, pageSize: 10 },
      sorting: { propertyName: 'name', isDescending: false },
      sortTableObj: this.sort
    });
    if (this.data?.catalogue) {
      this.projectProductsService.setQueryParameter('catalogueId', this.data.catalogue.id);
      this.displayedColumns = [
        'name',
        'articleNumber',
        'description',
        'price',
        'masterProductUnitPrice',
        'productUnitPriceAfterAdditions',
        'quantity',
        'unit',
        'totalPrice',
        'action'
      ];
    } else {
      this.displayedColumns = [
        'name',
        'description',
        'price',
        'masterProductUnitPrice',
        'productUnitPriceAfterAdditions',
        'quantity',
        'unit',
        'totalPrice',
        'action'
      ];
    }

    if (this.projectProductsService.baseUrl) {
      this.projectProductsService.forceRefresh();
    }
  }

  private elementToChange: ProjectProductGet;

  changePrice(projectProduct: ProjectProductGet): void {
    this.elementToChange = projectProduct;
  }

  saveChanges(): void {
    const projectProduct = this.elementToChange;
    if (projectProduct) {
      projectProduct.totalPrice = projectProduct.productUnitPrice * projectProduct.totalQuantity;
      this.selectedPriceComponentTypeService.setUpdating(true);
      this.projectProductsClient
        .setPriceForMasterProductInAvaProject(this.projectId, this.avaProjectId, projectProduct.masterProductId, {
          masterProductId: projectProduct.masterProductId,
          price: projectProduct.productUnitPrice
        })
        .subscribe({
          next: () => this.selectedPriceComponentTypeService.setUpdating(false),
          error: () => {
            this.selectedPriceComponentTypeService.setUpdating(false);
            this.avaNotificationsService.error('Fehler beim Speichern des Preises');
            this.projectProductsService.forceRefresh();
          }
        });
    }

    this.elementToChange = null;
  }

  openInformationWindow(element: ProjectProductGet): void {
    this.modalService
      .openModal(SpecificProductUsageInformationComponent, {
        dialogType: ConfirmationType.General,
        data: element,
        autoFocus: false,
        restoreFocus: false,
        width: '100%'
      })
      .afterClosed()
      .pipe(filter((r) => !!r))
      .subscribe((position) => {
        this.avaHubConnector.tryEnterPositionCalculation(this.projectId, this.avaProjectId, position.id).finally(() => {
          this.router.navigateByUrl(`/projects/${this.projectId}/service-specifications/${this.avaProjectId}/calculation`);
        });
      });
  }
}
