import { NgIf, NgFor } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSelect } from '@angular/material/select';

import {
  AvaFileExportPhase,
  AvaFileTargetType,
  AvaProjectAvaFileExportsClient,
  AvaProjectGet,
  ElementSelection,
  FileResponse,
  OriginDto
} from 'app/generated-client/generated-client';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { FileSaverService } from 'app/shared/services/file-saver.service';
import { AvaProjectAvaFileExportsService } from 'app/shared/services/lightquery/ava-project-ava-file-exports.service';

import { AvaFileExportPhasePipe } from '../../../../../../shared/pipes/ui-data-display/ava-file-export-phase.pipe';
import { AvaFileTargetTypePipe } from '../../../../../../shared/pipes/ui-data-display/ava-file-target-type.pipe';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

import { SelectingElementButtonsComponent } from '../selecting-element-buttons/selecting-element-buttons.component';

@Component({
  selector: 'pa-create-service-specification-export',
  templateUrl: './create-service-specification-export.component.html',
  styleUrls: ['./create-service-specification-export.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    FlexLayoutDirective,
    MatFormField,
    MatLabel,
    MatInput,
    MatSelect,
    NgFor,
    MatOption,
    MatCheckbox,
    SelectingElementButtonsComponent,
    MatButton,
    MatProgressSpinner,
    AvaFileTargetTypePipe,
    AvaFileExportPhasePipe
  ]
})
export class CreateServiceSpecificationExportComponent implements OnInit {
  @Input() serviceSpecification: AvaProjectGet;
  @Output() exportFinished = new EventEmitter<boolean>();

  exportFormGroup: FormGroup;
  targetTypes: AvaFileTargetType[];
  exportPhases: AvaFileExportPhase[];
  requestEnRoute = false;
  elementSelection: ElementSelection = null;

  constructor(
    private avaProjectAvaFileExportsClient: AvaProjectAvaFileExportsClient,
    private notificationsService: AvaNotificationsService,
    private avaProjectAvaFileExportsService: AvaProjectAvaFileExportsService,
    private fileSaverService: FileSaverService
  ) {}

  ngOnInit(): void {
    this.targetTypes = <AvaFileTargetType[]>Object.keys(AvaFileTargetType);
    this.exportPhases = <AvaFileExportPhase[]>Object.keys(AvaFileExportPhase);

    this.exportFormGroup = new FormGroup({
      exportName: new FormControl('', (c: AbstractControl) => Validators.required(c)),
      targetType: new FormControl('', (c: AbstractControl) => Validators.required(c)),
      exportPhase: new FormControl('', (c: AbstractControl) => Validators.required(c)),
      persistFile: new FormControl(''),
      alwaysIncludeDescriptionsInGaeb: new FormControl('')
    });

    let targetType = AvaFileTargetType.GaebXmlV32;
    switch (this.serviceSpecification?.sourceType) {
      case OriginDto.Gaeb90:
        targetType = AvaFileTargetType.Gaeb90;
        break;
      case OriginDto.Gaeb2000:
        targetType = AvaFileTargetType.Gaeb2000;
        break;
      case OriginDto.Oenorm:
        targetType = AvaFileTargetType.OenormLv2015;
        break;
    }

    this.exportFormGroup.patchValue({
      exportName: this.serviceSpecification?.name,
      targetType: targetType,
      exportPhase: AvaFileExportPhase.Offer,
      persistFile: true
    });
  }

  createExport(): void {
    this.requestEnRoute = true;
    this.avaProjectAvaFileExportsClient
      .createAvaFileExport(this.serviceSpecification.projectId, this.serviceSpecification.id, {
        ...this.exportFormGroup.value,
        elementSelection: this.elementSelection
      })
      .subscribe(
        (file: FileResponse) => {
          this.notificationsService.success('Das LV wurde exportiert.');
          this.requestEnRoute = false;

          this.avaProjectAvaFileExportsService.forceRefresh();
          this.fileSaverService.saveAs(file);
          this.exportFinished.next(true);
        },
        () => {
          this.requestEnRoute = false;
          this.notificationsService.error('Fehler beim LV Export.');
        }
      );
  }
}
