import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { CommonModule, NgFor } from '@angular/common';

import { BimModelFilterService } from '../../services/bim-model-filter.service';
import { ElementPropertiesFilterSelectionComponent } from './components/element-properties-filter-selection/element-properties-filter-selection.component';
import { ElementTypeFilterSelectionComponent } from './components/element-type-filter-selection/element-type-filter-selection.component';
import { FilterSelectionComponent } from 'app/areas/bim-view/components/filtering-bim-view/components/filter-selection/filter-selection.component';
import { IPropertySetFilter } from 'app/areas/bim-view/interface/property-set-filter.interface';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Observable } from 'rxjs';

@Component({
  selector: 'pa-filtering-bim-view',
  standalone: true,
  imports: [
    NgFor,
    ElementTypeFilterSelectionComponent,
    ElementPropertiesFilterSelectionComponent,
    FilterSelectionComponent,
    MatIconModule,
    MatButtonModule,
    CommonModule
  ],
  templateUrl: './filtering-bim-view.component.html',
  styleUrls: ['./filtering-bim-view.component.scss']
})
export class FilteringBimViewComponent implements OnInit {
  numberOfFilters = [];
  propertySetFilters: IPropertySetFilter[] = [];
  ifcTypes: Observable<string[]>;

  private cdr = inject(ChangeDetectorRef);
  private bimModelFilterService = inject(BimModelFilterService);

  constructor() {}

  ngOnInit(): void {
    this.ifcTypes = this.bimModelFilterService.selectedElementTypes;
  }

  addFilterGroup() {
    this.numberOfFilters.push({});
  }

  removeFilterGroup(index: number) {
    this.numberOfFilters.splice(index, 1);
    this.propertySetFilters.splice(index, 1);
    this.bimModelFilterService.setPropertySetFilters(this.propertySetFilters);
  }

  resetFilters(): void {
    this.cdr.detectChanges();
    this.numberOfFilters = [];
    this.propertySetFilters = [];
    this.bimModelFilterService.setPropertySetFilters(this.propertySetFilters);
  }

  updatePropertySetFilter(propertySetFilter: IPropertySetFilter, index: number): void {
    this.propertySetFilters[index] = { ...propertySetFilter };
    this.bimModelFilterService.setPropertySetFilters(this.propertySetFilters);
  }
}
