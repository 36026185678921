import { Clipboard } from '@angular/cdk/clipboard';
import { NgIf, NgFor } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger } from '@angular/material/menu';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';

import { Subject, combineLatest, takeUntil } from 'rxjs';

import { IfcStructure } from '../../../../generated-client/generated-client';
import { IfcElementDetail } from '../../models';
import { BimStateService } from '../../services/bim-state.service';
import { BimViewService } from '../../services/bim-view.service';

@Component({
  selector: 'pa-detail-bim-view',
  templateUrl: './detail-bim-view.component.html',
  styleUrls: ['./detail-bim-view.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatTooltip,
    MatIcon,
    MatButton,
    MatAccordion,
    NgFor,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow
  ]
})
export class DetailBimViewComponent implements OnInit {
  @ViewChild(MatAccordion) accordion!: MatAccordion;
  @ViewChild(MatMenuTrigger) contextMenu!: MatMenuTrigger;
  displayedColumns: string[] = ['property', 'value'];
  contextMenuPosition = { x: '0px', y: '0px' };
  selectedElement: IfcElementDetail | null = null;
  structure: IfcStructure;
  currentCopiedKey = '';
  stopTimeout: NodeJS.Timeout;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private bimViewService: BimViewService,
    private bimStateService: BimStateService,
    private clipboard: Clipboard
  ) {}

  ngOnInit(): void {
    combineLatest([this.bimViewService.structure, this.bimStateService.selectedElement])
      .pipe(takeUntil(this.$destroy))
      .subscribe(([structure, e]: [IfcStructure, number]) => {
        this.structure = structure;
        this.selectedElement = this.getDetail(e);
      });
  }

  getDetail(entityId: number): IfcElementDetail {
    if (!this.structure) {
      return null;
    }

    const ifcGuid = this.structure.ifcGuidsByEntityId[entityId];
    if (ifcGuid == null) {
      return null;
    }

    const name = this.structure.namesByEntityId[entityId];
    const propertySetIds = this.structure.propertySetIdsByEntityId[entityId] || [];
    let elementIfcType = '';
    for (const ifcType in this.structure.entityIdsByIfcType) {
      if (this.structure.entityIdsByIfcType[ifcType].includes(entityId)) {
        elementIfcType = ifcType;
        break;
      }
    }

    const propertySets = propertySetIds.map((propertySetId) => this.structure!.propertySetsById[propertySetId]).filter((p) => p != null);

    return {
      entityId,
      ifcGuid,
      name,
      propertySets,
      ifcType: elementIfcType
    };
  }

  copyValue(key: string): void {
    this.currentCopiedKey = key;
    clearTimeout(this.stopTimeout);
    this.stopTimeout = setTimeout(() => {
      this.currentCopiedKey = '';
    }, 3000);
    this.clipboard.copy(this.selectedElement[key].toString());
  }
}
