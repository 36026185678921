import { NgIf, NgFor } from '@angular/common';
import { Component, OnInit, OnDestroy, Inject, Optional } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSelect } from '@angular/material/select';

import { combineLatest, first, Subject, takeUntil } from 'rxjs';

import {
  FileResponse,
  PriceInquiryPriceLevelsClient,
  PriceInquiryRequestGet,
  PriceInquiryRequestsClient,
  ProjectDto,
  ProjectGet,
  ReportGetOfPriceInquiryPriceLevelReport,
  ReportsClient
} from 'app/generated-client/generated-client';
import { PrintViewMessengerService } from 'app/shared/services/electron/print-view-messenger.service';
import { FileSaverService } from 'app/shared/services/file-saver.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

import { AvaNotificationsService } from './../../../../../../shared/services/ava-notifications.service';

@Component({
  selector: 'pa-subcontractors-price-level-export',
  templateUrl: './subcontractors-price-level-export.component.html',
  styleUrls: ['./subcontractors-price-level-export.component.scss'],
  standalone: true,
  imports: [
    MatDialogContent,
    NgIf,
    FlexLayoutDirective,
    MatCheckbox,
    FormsModule,
    NgFor,
    MatFormField,
    MatLabel,
    MatSelect,
    ReactiveFormsModule,
    MatOption,
    MatButton,
    MatProgressSpinner
  ]
})
export class SubcontractorsPriceLevelExportComponent implements OnInit, OnDestroy {
  private $destroy: Subject<boolean> = new Subject<boolean>();
  targetTypes = ['Excel', 'PDF'];
  targetType = new FormControl('');
  requestEnRoute = false;
  showingPdf = false;
  includeHeaderOnlyOnFirstPage = false;
  projectId: string;
  avaProjectId: string;
  priceInquiryId: string;
  requests: { request: PriceInquiryRequestGet; isSelected: boolean }[] | null = null;
  showOwnPrices = false;
  showIdealBidder = false;
  constructor(
    private reportsClient: ReportsClient,
    private priceInquiryPriceLevelsClient: PriceInquiryPriceLevelsClient,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    @Inject(MAT_DIALOG_DATA) private data: { priceInquiryId: string },
    private avaNotificationsService: AvaNotificationsService,
    private fileSaverService: FileSaverService,
    @Optional() private matDialogRef: MatDialogRef<SubcontractorsPriceLevelExportComponent>,
    private printViewMessengerService: PrintViewMessengerService,
    private priceInquiryRequestsClient: PriceInquiryRequestsClient
  ) {}

  ngOnInit(): void {
    this.targetType.patchValue(this.targetTypes[0]);

    if (this.data) {
      this.priceInquiryId = this.data.priceInquiryId;
    }
    combineLatest([
      this.selectedProjectMessengerService.selectedProject,
      this.selectedSpecificationMessengerService.selectedServiceSpecification
    ])
      .pipe(takeUntil(this.$destroy))
      .subscribe(([project, s]: [ProjectGet, { avaProjectId: string; project: ProjectDto }]) => {
        this.projectId = project?.id;
        this.avaProjectId = s?.avaProjectId;

        if (this.projectId && this.avaProjectId && this.priceInquiryId) {
          this.priceInquiryRequestsClient
            .getAllPriceInquiryRequestsForPriceInquiry(this.projectId, this.avaProjectId, this.priceInquiryId)
            .subscribe((requests) => {
              this.requests = requests.map((r) => ({
                request: r,
                isSelected: true
              }));
            });
        }
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private getPriceInquiryRequestIdsToExclude(): string[] {
    if (!this.requests) {
      return [];
    }

    return this.requests.filter((r) => !r.isSelected).map((r) => r.request.id);
  }

  createExport(): void {
    this.requestEnRoute = true;
    const exportType = this.targetType.value;
    switch (exportType) {
      case this.targetTypes[0]:
        this.priceInquiryPriceLevelsClient
          .getPriceLevelAsExcel(this.projectId, this.avaProjectId, this.priceInquiryId, {
            priceInquiryRequestsToExclude: this.getPriceInquiryRequestIdsToExclude(),
            includeIdealBidder: this.showIdealBidder,
            includeOwnPrices: this.showOwnPrices
          })
          .subscribe({
            next: (file: FileResponse) => {
              this.avaNotificationsService.success('Preisspiegel als Excel gespeichert.');
              this.requestEnRoute = false;
              this.fileSaverService.saveAs(file);
              this.matDialogRef.close();
            },
            error: () => {
              this.requestEnRoute = false;
              this.avaNotificationsService.error('Fehler beim Erstellen des Preisspiegels.');
            }
          });
        break;
      case this.targetTypes[1]:
        this.reportsClient
          .getPriceInquiryPriceLevel(this.projectId, this.avaProjectId, this.priceInquiryId, true, this.includeHeaderOnlyOnFirstPage, {
            priceInquiryRequestsToExclude: this.getPriceInquiryRequestIdsToExclude(),
            includeIdealBidder: this.showIdealBidder,
            includeOwnPrices: this.showOwnPrices
          })
          .subscribe({
            next: (report: ReportGetOfPriceInquiryPriceLevelReport) => {
              this.printViewMessengerService.showPdfPreview(
                report.htmlReport,
                report.reportData.priceInquiryName,
                report.isLandscape,
                report.pdfReportBase64,
                this.includeHeaderOnlyOnFirstPage
              );
              this.printViewMessengerService
                .waitForPrintViewReady()
                .pipe(takeUntil(this.$destroy))
                .subscribe(() => {
                  this.requestEnRoute = false;
                  this.showingPdf = true;
                });
              this.printViewMessengerService.printViewClosed.pipe(first()).subscribe(() => {
                this.showingPdf = false;
                this.requestEnRoute = false;
              });
            },
            error: () => {
              this.requestEnRoute = false;
              this.avaNotificationsService.error('Fehler beim Erstellen des Preisspiegels.');
            }
          });
        break;
    }
  }
}
