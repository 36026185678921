import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ProjectGet, AvaProjectGet, ServiceSpecificationDto } from '../../generated-client/generated-client';

type CopyCalculationDataType = {
  selectedProject: ProjectGet;
  selectedAvaProject: AvaProjectGet;
  serviceSpecification: ServiceSpecificationDto;
  treeState: { [elementId: string]: boolean };
};

@Injectable({
  providedIn: 'root'
})
export class CopyCalculationService {
  private sendedDataSource = new ReplaySubject<CopyCalculationDataType>(1);
  sendedData = this.sendedDataSource.asObservable();

  constructor() {}

  public setDataToCopyCalculation(data: CopyCalculationDataType): void {
    this.sendedDataSource.next(data);
  }
}
