<div class="btn-box">
  <a mat-raised-button color="primary" class="nav-btn" [routerLink]="['new-group']"> Neue Gruppe </a>
</div>
<div class="mat-elevation-z8 wrapper">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Beschreibung</th>
      <td mat-cell *matCellDef="let element">{{ element.description }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" class="master-table-tr" (click)="selectElement(row)"></tr>
  </table>
  <pa-upper-paginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    [upperPaginated]="paginationOptions"
    [upperService]="paginationOptions"
    (page)="onPage($event)"
  >
  </pa-upper-paginator>
</div>
