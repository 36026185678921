import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  PaginationResultOfProjectPaymentTermGet,
  ProjectPaymentTermGet,
  ProjectPaymentTermsClient
} from 'app/generated-client/generated-client';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';

import { TermsTableComponent } from '../../../../../contacts/components/terms-table/terms-table.component';

@Component({
  selector: 'pa-project-terms',
  templateUrl: './project-terms.component.html',
  styleUrls: ['./project-terms.component.scss'],
  standalone: true,
  imports: [TermsTableComponent]
})
export class ProjectTermsComponent implements OnInit, OnDestroy {
  termsItems: ProjectPaymentTermGet[];
  private projectId: string;
  private $destroy = new Subject<boolean>();

  constructor(
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private projectPaymentTermsClient: ProjectPaymentTermsClient
  ) {}

  ngOnInit(): void {
    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((p) => {
      this.projectId = p?.id;
      if (this.projectId) {
        this.getTerms(this.projectId);
      }
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private getTerms(projectId: string): void {
    this.projectPaymentTermsClient
      .getAllProjectPaymentTermsForProject(projectId, undefined, undefined, undefined, undefined)
      .pipe(takeUntil(this.$destroy))
      .subscribe((e: PaginationResultOfProjectPaymentTermGet) => {
        this.termsItems = e.data;
      });
  }

  deleteTerm(row: ProjectPaymentTermGet): void {
    this.projectPaymentTermsClient.deleteProjectPaymentTerm(row.projectId, row.id).subscribe(() => this.getTerms(this.projectId));
  }

  addTerm(data): void {
    const post = { ...data, projectId: this.projectId };
    this.projectPaymentTermsClient.createProjectPaymentTerm(this.projectId, post).subscribe(() => this.getTerms(this.projectId));
  }
}
