import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

import { ContextMenuSettingType } from 'app/shared/models';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { ModalService } from 'app/shared/services/modal.service';

import { ContextMenuSettingComponent } from '../context-menu-setting/context-menu-setting.component';

@Component({
  selector: 'pa-context-menu-gear-in-top',
  templateUrl: './context-menu-gear-in-top.component.html',
  styleUrls: ['./context-menu-gear-in-top.component.scss'],
  standalone: true,
  imports: [MatIcon]
})
export class ContextMenuGearInTopComponent {
  @Input() menuType: ContextMenuSettingType;

  constructor(private modalService: ModalService) {}

  settingMenu(event: MouseEvent): void {
    event.stopPropagation();
    this.modalService.openModal(ContextMenuSettingComponent, { dialogType: ConfirmationType.General, data: this.menuType });
  }
}
