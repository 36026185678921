let hasCheckedIsInElementView = false;
let elementViewCheckCache = false;

export function isInElementView(): boolean {
  if (hasCheckedIsInElementView) {
    return elementViewCheckCache;
  }

  elementViewCheckCache = window.process?.argv?.includes('showElementView');
  hasCheckedIsInElementView = true;
  return elementViewCheckCache;
}

let hasCheckedIsInCopyCalculationView = false;
let copyCalculationViewCheckCache = false;

export function isInCopyCalculationView(): boolean {
  if (hasCheckedIsInCopyCalculationView) {
    return copyCalculationViewCheckCache;
  }

  copyCalculationViewCheckCache = window.process?.argv?.includes('showCopyCalculationView');
  hasCheckedIsInCopyCalculationView = true;
  return copyCalculationViewCheckCache;
}

let hasCheckedIsInCopyElementView = false;
let copyElementViewCheckCache = false;

export function isInCopyElementView(): boolean {
  if (hasCheckedIsInCopyElementView) {
    return copyElementViewCheckCache;
  }
  copyElementViewCheckCache = window.process?.argv?.includes('showCopyElementView');
  hasCheckedIsInCopyElementView = true;
  return copyElementViewCheckCache;
}

let hasCheckedIsTreeView = false;
let treeViewCheckCache = false;

export function isInTreeView(): boolean {
  if (hasCheckedIsTreeView) {
    return treeViewCheckCache;
  }

  treeViewCheckCache = window.process?.argv?.includes('treeView');
  hasCheckedIsTreeView = true;
  return treeViewCheckCache;
}

let hasCheckedIsHelpView = false;
let helpViewCheckCache = false;

export function isInHelpView(): boolean {
  if (hasCheckedIsHelpView) {
    return helpViewCheckCache;
  }

  helpViewCheckCache = window.process?.argv?.includes('helpView');
  hasCheckedIsHelpView = true;
  return helpViewCheckCache;
}

let hasCheckedIsLongTextView = false;
let longTextViewCheckCache = false;

export function isInLongTextView(): boolean {
  if (hasCheckedIsLongTextView) {
    return longTextViewCheckCache;
  }

  longTextViewCheckCache = window.process?.argv?.includes('longTextView');
  hasCheckedIsLongTextView = true;
  return longTextViewCheckCache;
}

let hasCheckedIsSheetsView = false;
let sheetsViewCheckCache = false;

export function isInSheetsView(): boolean {
  if (hasCheckedIsSheetsView) {
    return sheetsViewCheckCache;
  }

  sheetsViewCheckCache = window.process?.argv?.includes('sheetsView');
  hasCheckedIsSheetsView = true;
  return sheetsViewCheckCache;
}
