import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, RouterOutlet } from '@angular/router';

import { Observable, Subject, combineLatest, of } from 'rxjs';
import { filter, first, switchMap, take, takeUntil } from 'rxjs/operators';

import { FlatElementsService } from 'app/areas/tree/services/flat-elements.service';
import {
  AvaProjectAdditionGet,
  AvaProjectAdditionsClient,
  AvaProjectGet,
  AvaProjectsClient,
  PositionDto,
  ProjectDto,
  ProjectGet
} from 'app/generated-client/generated-client';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationAdditionsMessengerService } from 'app/shared/services/messengers/selected-specification-additions-messenger.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';

import { AvaProjectsService } from '../../../../../../shared/services/lightquery/ava-projects.service';

import { DuplicateItemNumberListComponent } from '../duplicate-item-number-list/duplicate-item-number-list.component';

@Component({
  selector: 'pa-service-specification',
  templateUrl: './service-specification.component.html',
  styleUrls: ['./service-specification.component.scss'],
  standalone: true,
  imports: [RouterOutlet]
})
export class ServiceSpecificationComponent implements OnInit, OnDestroy {
  private $destroy: Subject<boolean> = new Subject<boolean>();
  itemsWithDuplicateNumber: PositionDto[] = [];
  duplicateNumbers: string[] = [];
  elementsDto: PositionDto[];
  constructor(
    private activatedRoute: ActivatedRoute,
    private avaProjectAdditionsClient: AvaProjectAdditionsClient,
    private avaProjectsClient: AvaProjectsClient,
    private avaProjectsService: AvaProjectsService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationAdditionsMessengerService: SelectedSpecificationAdditionsMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private modalService: ModalService,
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private flatElementsService: FlatElementsService
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)),
      this.activatedRoute.params.pipe(take(1))
    ])
      .pipe(take(1))
      .subscribe(([project, params]: [ProjectGet, Params]) => {
        if (project && params) {
          this.tryLoadData(project.id, params['serviceSpecificationId']);
        }
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
    this.selectedSpecificationMessengerService.setProjectCurrency(null);
    this.selectedSpecificationMessengerService.setElementDtoList(null);
    this.selectedSpecificationMessengerService.clearSelectedServiceSpecification();
  }

  private tryLoadData(projectId: string, serviceSpecificationId: string): void {
    if (!!projectId && !!serviceSpecificationId) {
      this.getAvaProjectById(projectId, serviceSpecificationId).subscribe((originalAvaProject) => {
        this.avaProjectsClient.getAvaProjectContentById(projectId, serviceSpecificationId).subscribe((avaProject: ProjectDto) => {
          if (avaProject.serviceSpecifications[0].containsDuplicateItemNumbers) {
            this.flatElementsService.flatElementsDto
              .pipe(
                filter((v) => !!v.length),
                take(1)
              )
              .subscribe((elementsDto) => {
                this.elementsDto = elementsDto.filter((e) => e.elementType === 'PositionDto');
                this.itemsWithDuplicateNumber = [];
                this.duplicateNumbers = [];
                this.duplicateNumbers = this.elementsDto
                  .map((e) => e.itemNumber.stringRepresentation)
                  .map((e, i, final) => final.indexOf(e) !== i && i)
                  .filter((obj) => this.elementsDto[obj])
                  .map((e) => this.elementsDto[e].itemNumber.stringRepresentation);
                this.itemsWithDuplicateNumber = this.elementsDto.filter((obj) =>
                  this.duplicateNumbers.includes(obj.itemNumber.stringRepresentation)
                );
                if (this.itemsWithDuplicateNumber.length) {
                  this.modalService
                    .openModal(DuplicateItemNumberListComponent, {
                      dialogType: ConfirmationType.General,
                      autoFocus: false,
                      restoreFocus: false,
                      disableClose: true,
                      data: this.itemsWithDuplicateNumber
                    })
                    .afterClosed()
                    .subscribe((selectedPosition) => {
                      if (selectedPosition) {
                        this.selectedSpecificationElementMessengerService.trySelectElementById(selectedPosition?.id);
                      }
                    });
                }
              });
          }
          this.selectedSpecificationMessengerService.setSelectedServiceSpecification(
            serviceSpecificationId,
            avaProject,
            projectId,
            originalAvaProject
          );
          this.selectedSpecificationMessengerService.setProjectCurrency(avaProject.projectInformation.currencyShort);
          this.flatElementsService.setElementsDto(avaProject.serviceSpecifications[0]);
          this.selectedSpecificationMessengerService.setElementDtoList(avaProject);
        });

        this.avaProjectAdditionsClient
          .getAvaProjectAdditions(projectId, serviceSpecificationId)
          .subscribe((additions: AvaProjectAdditionGet) => {
            this.selectedSpecificationAdditionsMessengerService.setSelectedAdditions(additions);
          });
      });
    }
  }

  private getAvaProjectById(projectId: string, avaProjectId: string): Observable<AvaProjectGet> {
    const cachedProject = this.avaProjectsService.getItemById(avaProjectId);
    if (cachedProject) {
      return of(cachedProject);
    }

    // We might actually have a situation where the AvaProjectsService has not loaded anything yet,
    // so we need to wait for it to load before we can get the project.
    // Only if it then still doesn't find a cached project, we will get a new value from the server
    return this.avaProjectsService.paginationResult.pipe(
      first(),
      switchMap(() => {
        const freshProjectFromCache = this.avaProjectsService.getItemById(avaProjectId);
        if (freshProjectFromCache) {
          return of(freshProjectFromCache);
        }
        return this.avaProjectsClient.getAvaProjectById(projectId, avaProjectId);
      })
    );
  }
}
