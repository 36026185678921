import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TinyMceComponent } from '@dangl/angular-material-shared/tiny-mce';

import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-invoice-text-selection',
  templateUrl: './invoice-text-selection.component.html',
  styleUrls: ['./invoice-text-selection.component.scss'],
  standalone: true,
  imports: [TinyMceComponent, FormsModule, FlexLayoutDirective, MatButton]
})
export class InvoiceTextSelectionComponent implements OnInit {
  htmlText: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      htmlText: string;
    },
    @Optional() public dialogRef: MatDialogRef<InvoiceTextSelectionComponent>
  ) {}

  ngOnInit(): void {
    this.htmlText = this.data?.htmlText;
  }

  saveText(): void {
    this.dialogRef.close(this.htmlText);
  }

  dismiss(): void {
    this.dialogRef.close(null);
  }
}
