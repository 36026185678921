import { NgIf } from '@angular/common';
import { Component, Inject, OnInit, Optional, Self } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogClose } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

import { delay, Subject, take, takeUntil } from 'rxjs';

import { TreeNodeSelectingService } from 'app/areas/tree/services/tree-node-selecting.service';
import { TreeNodeStateService } from 'app/areas/tree/services/tree-node-state.service';
import { SelectedElement, TreeViewDisplayType } from 'app/generated-client/generated-client';
import { GroupViewService } from 'app/shared/services/group-view.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';

import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';
import { MainTreeComponent } from '../../../../../tree/components/main-tree/main-tree.component';

@Component({
  selector: 'pa-selection-element-new',
  templateUrl: './selection-element-new.component.html',
  styleUrls: ['./selection-element-new.component.scss'],
  providers: [GroupViewService],
  standalone: true,
  imports: [
    MatDialogContent,
    NgIf,
    MainTreeComponent,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutDirective,
    MatFormField,
    MatLabel,
    MatInput,
    MatButton,
    MatDialogClose
  ]
})
export class SelectionElementNewComponent implements OnInit {
  private $destroy: Subject<boolean> = new Subject<boolean>();
  selectionList: SelectedElement[] = [];
  selectedElements: SelectedElement[];
  formSelection: FormGroup;
  isEdit = false;

  constructor(
    @Optional() private matDialogRef: MatDialogRef<SelectionElementNewComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: { name: string; selectedElement: SelectedElement[] },
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private treeNodeStateService: TreeNodeStateService,
    @Self() private groupViewService: GroupViewService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private treeNodeSelectingService: TreeNodeSelectingService
  ) {}

  ngOnInit(): void {
    //TODO Consider another solution, so that the tree when opening a modal window is always displayed in a tree structure
    //Since there is a delay added here, because we are also getting the value for ProjectSettings
    this.selectedProjectMessengerService.selectedProject.pipe(take(1), delay(100)).subscribe(() => {
      this.groupViewService.setGroupView(TreeViewDisplayType.Tree);
    });
    this.selectedSpecificationElementMessengerService.clearSelectedElement();
    this.treeNodeStateService.resetTreeState();
    this.formSelection = this.fb.group({
      data: [null, Validators.required],
      name: ['', Validators.required]
    });
    if (this.data) {
      this.isEdit = true;
      this.formSelection.patchValue({
        data: this.data.selectedElement,
        name: this.data.name
      });
      this.selectedElements = this.data.selectedElement;
    }

    this.treeNodeSelectingService.selectingChanged.pipe(takeUntil(this.$destroy)).subscribe((selectingChanged) => {
      this.getSelecting(selectingChanged);
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  createSelection(): void {
    this.matDialogRef.close(this.formSelection.value);
  }

  getSelecting(value: SelectedElement[]): void {
    this.selectionList = value;
    if (value.length) {
      this.formSelection.get('data').setValue({ selectedElements: value });
    } else {
      this.formSelection.get('data').setValue(null);
    }
  }
}
