import { Injectable, NgZone } from '@angular/core';

import { GuidGenerator } from '@dangl/angular-material-shared/guid-generator';

import { Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { FileResponse } from 'app/generated-client/generated-client';

import { AvaNotificationsService } from './ava-notifications.service';
import { ElectronService } from './electron/electron.service';

import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class FileSaverService {
  constructor(
    private electronService: ElectronService,
    private avaNotificationsService: AvaNotificationsService,
    ngZone: NgZone
  ) {
    if (electronService.isElectron) {
      electronService.ipcRenderer.on(
        'FileSavedLocally',
        (
          _,
          fileSaveData: {
            correlationId: string;
          },
          secondChannelData: any[]
        ) => {
          let correlationId = fileSaveData?.correlationId;
          if (!correlationId && secondChannelData?.length > 0) {
            correlationId = secondChannelData.find((d) => d.correlationId)?.correlationId;
          }
          ngZone.run(() => this.fileSavedSource.next(correlationId));
        }
      );
    }
  }

  private fileSavedSource = new Subject<string>();

  saveAs(r: FileResponse): void {
    let fileName = r.fileName;

    if (r.headers) {
      const contentDispositionHeaderName = Object.keys(r.headers).find((h) => h.toUpperCase() === 'Content-Disposition'.toUpperCase());
      if (contentDispositionHeaderName) {
        const contentDisposition = r.headers[contentDispositionHeaderName] as string;
        if (contentDisposition && contentDisposition.indexOf("filename*=UTF-8''") > -1) {
          const encodedFileName = contentDisposition.split("filename*=UTF-8''")[1];
          fileName = decodeURI(encodedFileName);
        }
      }
    }

    this.saveBlobWithFilename(r.data, fileName);
  }

  saveAsFromBase64(base64: string, fileName: string): void {
    const url = `data:application/octet-stream;base64,${base64}`;

    fetch(url)
      .then((res) => res.blob())
      .then((blob) => this.saveBlobWithFilename(blob, fileName));
  }

  private saveBlobWithFilename(data: Blob, fileName: string): void {
    if (this.electronService.isElectron) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = (<string>reader.result).split(',')[1];
        const correlationId = GuidGenerator.generatePseudoRandomGuid();
        this.fileSavedSource
          .pipe(
            filter((corrId) => corrId === correlationId),
            take(1)
          )
          .subscribe(() => {
            this.avaNotificationsService.success(fileName, 'Datei gespeichert');
          });

        this.electronService.ipcRenderer.send('SaveFileLocally', {
          fileName,
          dataBase64: base64String,
          correlationId
        });
      };
      reader.readAsDataURL(data);
    } else {
      saveAs(data, fileName);
    }
  }
}
