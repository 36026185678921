import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { Subject, map, switchMap, takeUntil } from 'rxjs';

import { TreeNodeMarkService } from 'app/areas/tree/services/tree-node-mark.service';
import {
  ExecutionDescriptionDto,
  IElementDto,
  NoteTextDto,
  PositionDto,
  ServiceSpecificationGroupDto
} from 'app/generated-client/generated-client';
import { PositionTextPipe } from 'app/shared/pipes/ui-data-display/position-text.pipe';

import { FlatElementsService } from './../../../areas/tree/services/flat-elements.service';

@Component({
  selector: 'pa-marked-elements-modal-window',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatListModule, PositionTextPipe, MatButtonModule],
  templateUrl: './marked-elements-modal-window.component.html',
  styleUrls: ['./marked-elements-modal-window.component.scss']
})
export class MarkedElementsModalWindowComponent implements OnInit, OnDestroy {
  private $destroy: Subject<boolean> = new Subject<boolean>();
  dialogRef = inject(MatDialogRef);
  listData: (ServiceSpecificationGroupDto | NoteTextDto | ExecutionDescriptionDto | PositionDto)[];
  markedElements$ = this.treeNodeMarkService.treeNodeMarkElements;
  constructor(private flatElementsService: FlatElementsService, private treeNodeMarkService: TreeNodeMarkService) {}

  ngOnInit(): void {
    this.treeNodeMarkService.treeNodeMarkElements
      .pipe(
        map(treeNodeMarkElements => Object.keys(treeNodeMarkElements)),
        switchMap(ids =>
          this.flatElementsService.flatElementsDto.pipe(map(flatElementsDto => flatElementsDto.filter(e => ids.includes(e.id))))
        ),
        takeUntil(this.$destroy)
      )
      .subscribe(elements => {
        if (elements && elements.length > 0) {
          this.listData = elements;
        } else {
          this.dialogRef.close(null);
        }
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  selectPosition(position: IElementDto): void {
    this.dialogRef.close(position);
  }
}
