import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProjectGet, ProjectsClient } from 'app/generated-client/generated-client';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { ProjectsService } from 'app/shared/services/lightquery/projects.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';

import { ModalConfirmComponent } from '../../../../../../shared/components/modal-confirm/modal-confirm.component';
import { ConfirmationType } from '../../../../../../shared/models/dialog-config.model';
import { ModalService } from '../../../../../../shared/services/modal.service';

@Component({
  selector: 'pa-project-deletion',
  templateUrl: './project-deletion.component.html',
  styleUrls: ['./project-deletion.component.scss'],
  standalone: true,
  imports: [MatFormField, MatLabel, MatInput, FormsModule, MatButton]
})
export class ProjectDeletionComponent implements OnInit, OnDestroy {
  project: ProjectGet;
  projectName: string;
  set enteredProjectName(value: string) {
    this._enteredProjectName = value;
    this.checkIfCanDelete();
  }
  get enteredProjectName(): string {
    return this._enteredProjectName;
  }

  canDelete = false;

  private _enteredProjectName: string;
  private $destroy: Subject<void> = new Subject<void>();
  constructor(
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private projectsService: ProjectsService,
    private router: Router,
    private avaNotificationsService: AvaNotificationsService,
    private projectsClient: ProjectsClient,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((p: ProjectGet) => {
      this.project = p;
      this.projectName = p?.name;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  private isSendingRequest = false;

  checkIfCanDelete(): boolean {
    const normalizedSource = (this.projectName || '').replace(/\s/g, '');
    const normalizedTarget = (this.enteredProjectName || '').replace(/\s/g, '');
    const canDelete = normalizedSource === normalizedTarget;
    this.canDelete = canDelete;
    return canDelete;
  }

  deleteProject(): void {
    if (this.checkIfCanDelete() && !this.isSendingRequest) {
      this.modalService
        .openModal(ModalConfirmComponent, {
          dialogType: ConfirmationType.Delete,
          data: ['Löschen', this.project.name, 'Das Projekt kann nicht wiederhergestellt werden.']
        })
        .afterClosed()
        .subscribe((result: boolean) => {
          if (result) {
            this.deleteProjectConfirmed();
          }
        });
    }
  }

  private deleteProjectConfirmed(): void {
    this.isSendingRequest = true;
    this.projectsClient.deleteProject(this.project.id).subscribe({
      next: () => {
        this.avaNotificationsService.success('Projekt gelöscht.');
        this.projectsService.forceRefresh();
        this.router.navigate(['/']);
        this.isSendingRequest = false;
      },
      error: () => {
        this.avaNotificationsService.error('Fehler beim Löschen des Projekts.');
        this.isSendingRequest = false;
      }
    });
  }
}
