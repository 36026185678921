import { NgIf, NgTemplateOutlet, NgFor, NgClass, NgStyle, AsyncPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose } from '@angular/material/dialog';
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatFormField, MatLabel, MatHint } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTooltip } from '@angular/material/tooltip';

import { Subject, combineLatest, switchMap, throwError } from 'rxjs';
import { filter, first, take, takeUntil } from 'rxjs/operators';

import {
  ElementSelection,
  PageQuantityTakeOffOverviewGet,
  PagesGroupedQuantityTakeOffReport,
  PositionQuantityTakeOffReport,
  PositionQuantityTakeOffRowModel,
  ProjectQuantityTakeOffType,
  QuantityTakeOffRowType,
  QuantityTakeOffsClient,
  RebExportType,
  ReportGetOfPagesGroupedQuantityTakeOffReport,
  ReportGetOfPositionQuantityTakeOffReport,
  ReportsClient
} from 'app/generated-client/generated-client';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { PrintViewMessengerService } from 'app/shared/services/electron/print-view-messenger.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';

import { getAppConfig } from '../../../../../../../../app-config-accessor';
import { ImgSrcPipe } from '../../../../../../../../shared/pipes/img-src.pipe';
import { PositionTextPipe } from '../../../../../../../../shared/pipes/ui-data-display/position-text.pipe';
import { ProjectCurrencyPipe } from '../../../../../../../../shared/pipes/ui-data-display/project-currency.pipe';
import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';
import { SelectingElementButtonsComponent } from '../../../selecting-element-buttons/selecting-element-buttons.component';

import { ShowErrorsComponent } from './../../../../../../../../shared/components/show-errors/show-errors.component';
import { CheckErrorsAndOpenModalWindowService } from './../../../../services/check-errors-and-open-modal-window.service';

import { InvoiceExportAddressModalComponent } from '../invoice-export-address-modal/invoice-export-address-modal.component';
import { InvoicePagesComponent } from '../invoice-pages/invoice-pages.component';

type DataDialogType = {
  fileName: string;
  reportType: string;
  argumentsReport: string[];
};

@Component({
  selector: 'pa-invoice-export-preview',
  templateUrl: './invoice-export-preview.component.html',
  styleUrls: ['./invoice-export-preview.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FlexLayoutDirective,
    MatProgressSpinner,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatCheckbox,
    FormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    SelectingElementButtonsComponent,
    MatButton,
    MatHint,
    MatDialogClose,
    NgTemplateOutlet,
    NgFor,
    MatTooltip,
    NgClass,
    NgStyle,
    AsyncPipe,
    DecimalPipe,
    PercentPipe,
    ProjectCurrencyPipe,
    ImgSrcPipe,
    PositionTextPipe
  ]
})
export class InvoiceExportPreviewComponent implements OnInit, OnDestroy {
  private $destroy: Subject<void> = new Subject<void>();
  report: PositionQuantityTakeOffReport | PagesGroupedQuantityTakeOffReport;
  positions: PositionQuantityTakeOffReport;
  pages: PagesGroupedQuantityTakeOffReport;
  word: string;
  isLoading = true;
  quantityTakeOffId: string;
  quantityTakeOffPageId: string;
  showingPdf = false;
  addMargin: number;
  startPage: number | null = null;
  isPagesReport = false;
  includeTotalsTable = false;
  includeSumsTable = true;
  includeSumsPerPage = true;
  pagesSelection: PageQuantityTakeOffOverviewGet[] | null = null;
  isSelectedPages: boolean;
  isExpansionPanelExpanded: boolean;

  includeAttachments = false;
  includePreviousQuantities = false;
  includeHeaderOnlyOnFirstPage = false;
  includeTotalsByServicePeriodCode = false;
  includePagesOverview = false;
  includeSumsByServicePeriodAndPage = false;
  includeQtoRows = true;
  elementSelection: ElementSelection = null;
  CORRECT_LINE = 'correctLine';
  isQtoOnlyMode = getAppConfig().isQtoOnlyMode;

  ExportType = RebExportType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DataDialogType,
    private reportsClient: ReportsClient,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private avaNotificationsService: AvaNotificationsService,
    private printViewMessengerService: PrintViewMessengerService,
    private modalService: ModalService,
    private checkErrorsAndOpenModalWindowService: CheckErrorsAndOpenModalWindowService,
    private quantityTakeOffsClient: QuantityTakeOffsClient,
    @Optional() private dialogRef: MatDialogRef<InvoiceExportPreviewComponent>
  ) {}

  ngOnInit(): void {
    const { argumentsReport } = this.data;
    if (this.data.reportType?.includes('positions')) {
      [this.quantityTakeOffId] = argumentsReport;
      this.reportsClient
        .getPositionQuantityTakeOffReport(
          this.quantityTakeOffId,
          false,
          this.addMargin || 0,
          false,
          false,
          this.includeAttachments,
          this.includeHeaderOnlyOnFirstPage,
          this.includeQtoRows,
          this.isQtoOnlyMode, // In this mode, we want to use the HVA template
          this.startPage > 1 ? this.startPage : null,
          {
            elementSelection: this.elementSelection,
            includePreviousQuantities: this.includePreviousQuantities
          }
        )
        .subscribe((report) => {
          this.report = report.reportData;
          this.positions = report.reportData;
          this.isLoading = false;
        });
    } else {
      this.isPagesReport = true;
      [this.quantityTakeOffId, this.quantityTakeOffPageId] = argumentsReport;
      const request = this.quantityTakeOffPageId
        ? this.reportsClient.getPageQuantityTakeOffReportGrouped(
            this.quantityTakeOffId,
            false,
            this.addMargin || 0,
            false,
            false,
            false,
            this.includeAttachments,
            [this.quantityTakeOffPageId],
            this.includeHeaderOnlyOnFirstPage,
            this.includeQtoRows,
            this.includeTotalsByServicePeriodCode,
            this.includePagesOverview,
            this.includeSumsByServicePeriodAndPage,
            this.isQtoOnlyMode, // In this mode, we want to use the HVA template
            this.startPage > 1 ? this.startPage : null,
            {
              elementSelection: this.elementSelection,
              includePreviousQuantities: this.includePreviousQuantities
            }
          )
        : this.reportsClient.getPageQuantityTakeOffReportGrouped(
            this.quantityTakeOffId,
            false,
            this.addMargin || 0,
            false,
            false,
            true,
            this.includeAttachments,
            null,
            this.includeHeaderOnlyOnFirstPage,
            this.includeQtoRows,
            this.includeTotalsByServicePeriodCode,
            this.includePagesOverview,
            this.includeSumsByServicePeriodAndPage,
            this.isQtoOnlyMode, // In this mode, we want to use the HVA template
            this.startPage > 1 ? this.startPage : null,
            {
              elementSelection: this.elementSelection,
              includePreviousQuantities: this.includePreviousQuantities
            }
          );

      request.subscribe((report: ReportGetOfPagesGroupedQuantityTakeOffReport) => {
        this.report = report.reportData;
        this.pages = report.reportData;
        this.isLoading = false;
      });
    }
    this.word = this.data.reportType?.includes('estimations') ? 'Mengenermittlung' : 'Abrechnung';
    this.printViewMessengerService.printViewClosed.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.showingPdf = false;
      this.isLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  summ(rows: PositionQuantityTakeOffRowModel[], prop: string): number {
    return rows
      .filter((r) => r.rowType === QuantityTakeOffRowType.Normal)
      .reduce((sum: number, item: any) => (typeof item[prop] === 'number' ? sum + item[prop] : sum), 0);
  }

  innerTooltip(rowType: string): string {
    return rowType === 'I' ? 'Interne Zeile, erscheint nicht im Ausdruck' : '';
  }

  generateGroupedPageReportPdfAndShowPrintView(): void {
    this.isLoading = true;
    const pagesToPrint: string[] = this.pagesSelection?.length ? this.pagesSelection.map((p) => p.id) : null;
    const request = this.quantityTakeOffPageId
      ? this.reportsClient.getPageQuantityTakeOffReportGrouped(
          this.quantityTakeOffId,
          true,
          this.addMargin || 0,
          this.includeSumsTable,
          this.includeTotalsTable,
          false,
          this.includeAttachments,
          [this.quantityTakeOffPageId],
          this.includeHeaderOnlyOnFirstPage,
          this.includeQtoRows,
          this.includeTotalsByServicePeriodCode,
          this.includePagesOverview,
          this.includeSumsByServicePeriodAndPage,
          this.isQtoOnlyMode, // In this mode, we want to use the HVA template
          this.startPage > 1 ? this.startPage : null,
          {
            elementSelection: this.elementSelection,
            includePreviousQuantities: this.includePreviousQuantities
          }
        )
      : this.reportsClient.getPageQuantityTakeOffReportGrouped(
          this.quantityTakeOffId,
          true,
          this.addMargin || 0,
          this.includeSumsTable,
          this.includeTotalsTable,
          this.includeSumsPerPage,
          this.includeAttachments,
          pagesToPrint,
          this.includeHeaderOnlyOnFirstPage,
          this.includeQtoRows,
          this.includeTotalsByServicePeriodCode,
          this.includePagesOverview,
          this.includeSumsByServicePeriodAndPage,
          this.isQtoOnlyMode, // In this mode, we want to use the HVA template
          this.startPage > 1 ? this.startPage : null,
          {
            elementSelection: this.elementSelection,
            includePreviousQuantities: this.includePreviousQuantities
          }
        );

    const errorRequest = this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(
      filter((r) => !!r),
      first(),
      switchMap((s) =>
        this.quantityTakeOffsClient.checkQuantityTakeOffCalculations(s.parentProjectId, s.avaProjectId, this.quantityTakeOffId)
      )
    );

    this.checkErrorsAndOpenModalWindowService
      .checkErrorsAndOpenModalWindow(errorRequest, ShowErrorsComponent, {
        dialogType: ConfirmationType.General,
        disableClose: false
      })
      .pipe(
        switchMap((e) => {
          if (e) {
            return throwError(() => new Error(this.CORRECT_LINE));
          }
          return request;
        })
      )
      .subscribe({
        next: (report: ReportGetOfPagesGroupedQuantityTakeOffReport) => {
          this.printViewMessengerService.showPdfPreview(
            report.htmlReport,
            this.data.fileName,
            report.isLandscape,
            report.pdfReportBase64,
            this.includeHeaderOnlyOnFirstPage
          );

          this.printViewMessengerService.printTookTooLong.pipe(takeUntil(this.$destroy), take(1)).subscribe(() => {
            this.isLoading = false;
            this.showingPdf = true;
          });

          this.printViewMessengerService.waitForPrintViewReady().subscribe(() => {
            this.isLoading = false;
            this.showingPdf = true;
          });
        },
        error: (error) => {
          this.isLoading = false;
          this.showingPdf = false;
          if (error.message === this.CORRECT_LINE) {
            this.dialogRef.close();
          } else {
            this.avaNotificationsService.error('Fehler beim Erstellen der PDF-Datei');
          }
        }
      });
  }

  x: {
    errors: {
      positionId?: string;
      countOfFormulasErrors: number;
      quantityTakeOffId?: string;
      pageId?: string;
    }[];
    quantityTakeOffType: ProjectQuantityTakeOffType;
  } = null;

  generatePdfAndShowPrintView(): void {
    this.isLoading = true;
    const errorRequest = this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(
      filter((r) => !!r),
      first(),
      switchMap((s) =>
        this.quantityTakeOffsClient.checkQuantityTakeOffCalculations(s.parentProjectId, s.avaProjectId, this.quantityTakeOffId)
      )
    );

    this.checkErrorsAndOpenModalWindowService
      .checkErrorsAndOpenModalWindow(errorRequest, ShowErrorsComponent, {
        dialogType: ConfirmationType.General,
        disableClose: false
      })
      .pipe(
        switchMap((e) => {
          if (e) {
            return throwError(() => new Error(this.CORRECT_LINE));
          }
          return this.reportsClient.getPositionQuantityTakeOffReport(
            this.quantityTakeOffId,
            true,
            this.addMargin || 0,
            this.includeSumsTable,
            this.includeTotalsTable,
            this.includeAttachments,
            this.includeHeaderOnlyOnFirstPage,
            this.includeQtoRows,
            this.isQtoOnlyMode, // In this mode, we want to use the HVA template,
            this.startPage > 1 ? this.startPage : null,
            {
              elementSelection: this.elementSelection,
              includePreviousQuantities: this.includePreviousQuantities
            }
          );
        })
      )
      .subscribe({
        next: (report: ReportGetOfPositionQuantityTakeOffReport) => {
          if (!report.pdfReportBase64) {
            this.isLoading = false;
            this.avaNotificationsService.error('Fehler beim Erstellen der PDF-Datei');
          } else {
            this.printViewMessengerService.showPdfPreview(
              report.htmlReport,
              this.data.fileName,
              report.isLandscape,
              report.pdfReportBase64,
              this.includeHeaderOnlyOnFirstPage
            );

            this.printViewMessengerService.printTookTooLong.pipe(takeUntil(this.$destroy), take(1)).subscribe(() => {
              this.isLoading = false;
              this.showingPdf = true;
            });

            this.printViewMessengerService.waitForPrintViewReady().subscribe(() => {
              this.isLoading = false;
              this.showingPdf = true;
            });
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.showingPdf = false;
          if (error.message === this.CORRECT_LINE) {
            this.dialogRef.close();
          } else {
            this.avaNotificationsService.error('Fehler beim Erstellen der PDF-Datei');
          }
        }
      });
  }

  generateAndDownloadFile(exportType: RebExportType): void {
    this.isLoading = true;
    const errorRequest = this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(
      filter((r) => !!r),
      first(),
      switchMap((s) =>
        this.quantityTakeOffsClient.checkQuantityTakeOffCalculations(s.parentProjectId, s.avaProjectId, this.quantityTakeOffId)
      )
    );
    this.checkErrorsAndOpenModalWindowService
      .checkErrorsAndOpenModalWindow(errorRequest, ShowErrorsComponent, {
        dialogType: ConfirmationType.General,
        disableClose: false
      })
      .pipe(
        switchMap(() => {
          return combineLatest([
            this.selectedProjectMessengerService.selectedProject,
            this.selectedSpecificationMessengerService.selectedServiceSpecification
          ]).pipe(
            filter((r) => !!r),
            first()
          );
        })
      )
      .subscribe({
        next: ([project, servSpec]) => {
          this.isLoading = false;
          const pagesToPrint: string[] = this.quantityTakeOffPageId
            ? [this.quantityTakeOffPageId]
            : this.pagesSelection && this.pagesSelection.length
            ? this.pagesSelection.map((p) => p.id)
            : null;

          this.modalService.openModal(InvoiceExportAddressModalComponent, {
            dialogType: ConfirmationType.General,
            data: {
              projectId: project.id,
              avaProjectId: servSpec.avaProjectId,
              quantityTakeOffId: this.quantityTakeOffId,
              pagesToPrint,
              elementSelection: this.elementSelection,
              includePreviousQuantities: this.includePreviousQuantities,
              exportType
            },
            autoFocus: false,
            restoreFocus: false
          });
        },
        error: () => {
          this.isLoading = false;
        }
      });
  }

  getSize(textSizeFactor: number): string {
    return textSizeFactor ? `${1 + textSizeFactor * 0.1}em` : '';
  }

  selectPages(): void {
    this.modalService
      .openModal(InvoicePagesComponent, {
        dialogType: ConfirmationType.General,
        data: {
          iSModalMode: true,
          pagesSelection: this.pagesSelection
        },
        autoFocus: false,
        restoreFocus: false
      })
      .afterClosed()
      .subscribe((pages) => {
        this.pagesSelection = pages?.length ? pages : null;
        this.isSelectedPages = !!pages?.length;
      });
  }
}
