<div *ngIf="contact || isLoading; else warningBlock">
  <div fxFlexFill fxLayoutAlign="center center" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>
  <ng-container *ngIf="contact">
    <form>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Nr</mat-label>
          <input name="contactNumber" required matInput [(ngModel)]="contact.contactNumber" disabled />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Name</mat-label>
          <input name="name" required matInput [(ngModel)]="contact.name" disabled />
        </mat-form-field>
      </div>
    </form>

    <h3>Adressen</h3>
    <pa-contact-addresses [readOnly]="true"></pa-contact-addresses>

    <h3>Ansprechpartner</h3>
    <pa-contact-persons [readOnly]="true"></pa-contact-persons>

    <div class="buttons-global">
      <button mat-raised-button color="primary" (click)="changeContact()">Kontakt ändern</button>
      <button mat-raised-button (click)="routeToContact()">Kontaktdetails</button>
      <button mat-raised-button color="warn" (click)="deleteContact()">Kontakt löschen</button>
    </div>
  </ng-container>
</div>
<ng-template #warningBlock>
  <div class="buttons-global">
    <button mat-raised-button color="primary" (click)="changeContact()">Kontakt hinzufügen</button>
  </div>
  <p>Mit diesem Projekt ist kein Kontakt verknüpft.</p>
</ng-template>
