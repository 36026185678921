import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleGroup, MatButtonToggle } from '@angular/material/button-toggle';

import { Subject, combineLatest, fromEvent } from 'rxjs';
import { first, take, takeUntil } from 'rxjs/operators';

import { ChangeTotalService } from '@serv-spec/services/change-total.service';
import { CurrentPositionCalculationGetService } from '@serv-spec/services/current-position-calculation-get.service';

import { MainTreeComponent } from 'app/areas/tree/components/main-tree/main-tree.component';
import { FlatElementsService } from 'app/areas/tree/services/flat-elements.service';
import { ModePageService } from 'app/areas/tree/services/mode-page.service';
import { TreeNodeStateService } from 'app/areas/tree/services/tree-node-state.service';
import {
  AvaProjectGet,
  AvaProjectsClient,
  IElementDto,
  PositionCalculationGet,
  PositionCalculationsClient,
  ProjectDto,
  ProjectGet,
  ProjectsClient,
  TreeViewDisplayType
} from 'app/generated-client/generated-client';
import { TreeViewMessageType } from 'app/shared/models';
import { TreeViewCommandType } from 'app/shared/models/tree-view-command.model';
import { TreeViewMessengerService } from 'app/shared/services/electron/tree-view-messenger.service';
import { GroupViewService } from 'app/shared/services/group-view.service';
import { KeyboardPositionSelectionService } from 'app/shared/services/keyboard-position-selection.service';
import { LocalStorageViewService } from 'app/shared/services/local-storage-view.service';
import { QuantityTakeOffInvoiceTotalsMessengerService } from 'app/shared/services/messengers/quantity-take-off-invoice-totals-messenger.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedQuantityTakeOffMessengerService } from 'app/shared/services/messengers/selected-quantity-take-off-messenger.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { SubPositionsMessengerService } from 'app/shared/services/messengers/sub-positions-messenger.service';
import { SelectRowsService } from 'app/shared/services/select-rows.service';
import { UserSettingsService } from 'app/shared/services/user-settings.service';

import { GeneralEquipmentComponent } from '../../../../shared/components/general-equipment/general-equipment.component';
import { AvaHubConnector } from '../../../../shared/services/signalr/ava-hub-connector';
import { FlexLayoutDirective } from '../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss'],
  providers: [KeyboardPositionSelectionService],
  standalone: true,
  imports: [GeneralEquipmentComponent, FlexLayoutDirective, MatButtonToggleGroup, FormsModule, MatButtonToggle, MainTreeComponent]
})
export class TreeViewComponent implements OnInit, OnDestroy {
  modePage: string;
  groupView: TreeViewDisplayType;
  projectId: string;
  serviceSpecificationId: string;
  nodeId: string;
  modeEquipment = 'tree';
  keyName: string;
  positionId: string;
  private $destroy: Subject<boolean> = new Subject<boolean>();
  selectedElement: IElementDto;
  treeNodeState: { [elementId: string]: boolean };
  isEditMode = true;

  constructor(
    private treeViewMessengerService: TreeViewMessengerService,
    private projectsClient: ProjectsClient,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private avaProjectsClient: AvaProjectsClient,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private positionCalculationsClient: PositionCalculationsClient,
    private currentPositionCalculationGetService: CurrentPositionCalculationGetService,
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private localStorageViewService: LocalStorageViewService,
    private changeTotalService: ChangeTotalService,
    private selectRowsService: SelectRowsService,
    private quantityTakeOffInvoiceTotalsMessengerService: QuantityTakeOffInvoiceTotalsMessengerService,
    private selectedQuantityTakeOffMessengerService: SelectedQuantityTakeOffMessengerService,
    private userSettingsService: UserSettingsService,
    private groupViewService: GroupViewService,
    private modePageService: ModePageService,
    private treeNodeStateService: TreeNodeStateService,
    private flatElementsService: FlatElementsService,
    // The AvaHubConnector is required in this component, to ensure that the connection is established.
    private avaHubConnector: AvaHubConnector,
    private ngZone: NgZone,
    private subPositionsMessengerService: SubPositionsMessengerService
  ) {}

  ngOnInit(): void {
    let previousServSpecId: string | null = null;
    let previousProjectId: string | null = null;

    this.treeViewMessengerService.dataToTreeView.pipe(takeUntil(this.$destroy)).subscribe((e: TreeViewMessageType) => {
      const { command, data, treeNodeState } = e;

      if (treeNodeState) {
        this.treeNodeStateService.setTreeState(treeNodeState);
      }
      switch (command) {
        case TreeViewCommandType.Start:
          [this.modePage, this.groupView, this.projectId, this.serviceSpecificationId, this.nodeId, this.selectedElement] = data as any[];

          if (previousProjectId !== this.projectId || previousServSpecId !== this.serviceSpecificationId) {
            this.getAvaProject(this.projectId, this.serviceSpecificationId);
          }

          previousServSpecId = this.serviceSpecificationId;
          previousProjectId = this.projectId;

          if (this.modePage) {
            this.modePageService.setModePage(this.modePage);
          }

          if (this.selectedElement) {
            this.selectedSpecificationElementMessengerService.trySelectElementById(this.selectedElement.id);
          }
          break;
        case TreeViewCommandType.Stop:
          this.nodeId = '';
          this.selectedSpecificationElementMessengerService.clearSelectedElement();
          this.treeViewMessengerService.sendDataFromTreeView({
            command: TreeViewCommandType.Clear,
            data: null
          });
          break;
        case TreeViewCommandType.ChangeSelectRow:
          if (data?.length) {
            this.selectRowsService.setSelectedRowSumma(data[0] as number, data[1] as number);
          } else {
            this.selectRowsService.setSelectedRowSumma(null, null);
          }
          break;
        case TreeViewCommandType.ChangeQTOTotal:
          this.quantityTakeOffInvoiceTotalsMessengerService.setCurrentTotalSum({
            totalSum: data[0],
            allTotalSum: data[1],
            serviceSpecificationTotalSum: data[2],
            positionTotalSum: data[3],
            pageTotalSum: data[4],
            positionQuantity: data[5]
          });
          break;
        case TreeViewCommandType.GetQTO:
          this.selectedQuantityTakeOffMessengerService.setSelectedQuantityTakeOff(data[0]);
          break;
        case TreeViewCommandType.ChangeTreeTotals:
          this.userSettingsService.currentUserSettings.pipe(takeUntil(this.$destroy), first()).subscribe((userSettings) => {
            userSettings.showsTotalsInQuantityTakeOffBelowTree = !!data[0];
            this.userSettingsService.setCurrentUserSettings(userSettings);
          });
          break;
        case TreeViewCommandType.ChangeGroupeView:
          [this.groupView] = data as any[];
          this.groupViewService.setGroupView(this.groupView);
          break;
        case TreeViewCommandType.ChangePosition:
          [this.keyName] = data as any[];
          document.dispatchEvent(
            new KeyboardEvent('keydown', {
              key: this.keyName
            })
          );
          break;
        case TreeViewCommandType.UpdateSubPosition:
          this.selectedSpecificationElementMessengerService.selectedElement.pipe(take(1)).subscribe((selectedElement) => {
            if (selectedElement) {
              this.subPositionsMessengerService.setSubPositionsForSpecificPosition(selectedElement?.id, this.serviceSpecificationId, data);
              this.treeNodeStateService.updateTree();
            }
          });
          break;
        default:
      }
    });
    this.settingView();
    this.treeNodeStateService.treeNodeState.pipe(takeUntil(this.$destroy)).subscribe((s) => {
      this.treeNodeState = s;
      this.treeViewMessengerService.sendDataFromTreeView({
        command: TreeViewCommandType.Empty,
        data: null,
        treeNodeState: this.treeNodeState
      });
    });

    this.ngZone.runOutsideAngular(() => {
      fromEvent(document, 'dragstart')
        .pipe(takeUntil(this.$destroy))
        .subscribe((event: DragEvent) => {
          event.preventDefault();
        });
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private getAvaProject(projectId: string, serviceSpecificationId: string): void {
    if (projectId && serviceSpecificationId) {
      combineLatest([
        this.projectsClient.getProjectById(projectId),
        this.avaProjectsClient.getAvaProjectById(projectId, serviceSpecificationId),
        this.avaProjectsClient.getAvaProjectContentById(projectId, serviceSpecificationId)
      ])
        .pipe(takeUntil(this.$destroy))
        .subscribe(([project, avaProject, servSpec]: [ProjectGet, AvaProjectGet, ProjectDto]) => {
          this.flatElementsService.setElementsDto(servSpec.serviceSpecifications[0]);
          this.selectedProjectMessengerService.setSelectedProject(project);
          this.selectedSpecificationMessengerService.setSelectedServiceSpecification(
            serviceSpecificationId,
            servSpec,
            projectId,
            avaProject
          );
        });
    }
  }

  selectPosition(positionId: string): void {
    if (positionId) {
      this.positionId = positionId;
      this.updatePosition();
    }
  }

  private updatePosition(): void {
    if (this.positionId) {
      this.positionCalculationsClient
        .getPositionCalculation(this.projectId, this.serviceSpecificationId, this.positionId)
        .pipe(takeUntil(this.$destroy))
        .subscribe((e: PositionCalculationGet) => {
          this.currentPositionCalculationGetService.setCurrentPositionCalc(e);
        });
    }
  }

  private settingView(): void {
    const { alwaysOnTop } = this.localStorageViewService.getSettingView(this.modeEquipment);
    this.treeViewMessengerService.setOnTopTreeView(alwaysOnTop);
  }

  changeGroupView(type: TreeViewDisplayType): void {
    this.groupViewService.setGroupView(type);
    this.treeViewMessengerService.sendDataFromTreeView({ command: TreeViewCommandType.ChangeGroupeView, data: [type] });
  }
}
