<p>Hier werden Projekteinstellungen verwaltet.</p>
<div class="modal__content" *ngIf="currentProjectSettings">
  <fieldset style="display: inline-block">
    <legend>Abrechnungen - Spalten</legend>
    <div fxLayout="column" fxLayoutGap="12px">
      <mat-checkbox
        color="primary"
        [(ngModel)]="currentProjectSettings.showShortTextColumnsInQuantityTakeOff"
        (ngModelChange)="isChanged = true"
      >
        Spalte für Kurztexte immer anzeigen
      </mat-checkbox>
      <mat-checkbox
        color="primary"
        [(ngModel)]="currentProjectSettings.showReferenceNameInQuantityTakeOff"
        (ngModelChange)="isChanged = true"
      >
        Spalte für Referenzen in Abrechnung immer anzeigen
      </mat-checkbox>
      <mat-checkbox
        color="primary"
        [(ngModel)]="currentProjectSettings.showImageColumnsInQuantityTakeOff"
        (ngModelChange)="isChanged = true"
      >
        Spalte für Bilder immer anzeigen
      </mat-checkbox>
    </div>
  </fieldset>
  <p>Standardeinstellung für LV-Baum</p>
  <div>
    <mat-button-toggle-group [(ngModel)]="groupView" (ngModelChange)="changeGroupView($event)">
      <mat-button-toggle value="Tree">Hierarchie</mat-button-toggle>
      <mat-button-toggle value="List">Liste</mat-button-toggle>
      <mat-button-toggle value="PositionList">Positionen</mat-button-toggle>
      <mat-button-toggle value="Table">Tabelle</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
<div class="buttons">
  <button mat-raised-button color="primary" type="submit" (click)="changeSettings()" [disabled]="!isChanged">Speichern</button>
</div>
