import { Directive, ElementRef, HostListener, OnDestroy, Output, Renderer2, inject } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { OverwriteModeMessengerService } from '../services/messengers/overwrite-mode-messenger.service';

@Directive({
  selector: '[paOverwriteMode]',
  standalone: true
})
export class OverwriteModeDirective implements OnDestroy {
  private isOverwriteMode$ = inject(OverwriteModeMessengerService).isOverwriteMode$;
  private isOverwriteMode: boolean;
  @Output() overwriteModeChanged = new Subject<HTMLInputElement>();

  private $destroy = new Subject<boolean>();

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.isOverwriteMode$.pipe(takeUntil(this.$destroy)).subscribe((isOverwriteMode) => {
      this.isOverwriteMode = isOverwriteMode;
    });
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    if (this.isOverwriteMode && event.key.length === 1) {
      const cursorStart = inputElement.selectionStart;
      const cursorEnd = inputElement.selectionEnd;
      const currentContent = inputElement.value;

      const newContent = currentContent.slice(0, cursorStart) + event.key + currentContent.slice(cursorEnd + 1);

      inputElement.value = newContent;

      inputElement.setSelectionRange(cursorStart + 1, cursorStart + 1);

      event.preventDefault();
      this.removeDivider();
      this.renderCustomDivider();

      this.overwriteModeChanged.next(inputElement);
    } else if (this.isOverwriteMode && event.key === 'Backspace') {
      // In this case, we need to move the cursor back one space
      const cursorStart = inputElement.selectionStart;
      setTimeout(() => {
        inputElement.setSelectionRange(cursorStart - 1, cursorStart);
        this.removeDivider();
        this.renderCustomDivider();
      }, 1);
    }

    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      this.removeDivider();
      this.renderCustomDivider();
    }

    if (event.key === 'Insert') {
      setTimeout(() => {
        if (this.isOverwriteMode) {
          this.renderCustomDivider();
        } else {
          this.removeDivider();
        }
      }, 0);
    }
  }

  @HostListener('focus', ['$event'])
  onFocus(): void {
    this.renderCustomDivider();
  }

  @HostListener('blur') onBlur() {
    this.removeDivider();
  }

  @HostListener('click', ['$event'])
  onClick(): void {
    this.removeDivider();
    this.renderCustomDivider();
  }

  private removeDivider(): void {
    setTimeout(() => {
      const dividers = this.el.nativeElement.parentNode.querySelectorAll('.separator');

      dividers.forEach((divider) => {
        this.renderer.removeChild(this.el.nativeElement.parentNode, divider);
      });

      this.renderer.removeClass(this.el.nativeElement, 'custom-caret');
    }, 0);
  }

  private renderCustomDivider(): void {
    if (!this.isOverwriteMode) {
      return;
    }
    setTimeout(() => {
      const inputElement = this.el.nativeElement as HTMLInputElement;

      this.renderer.addClass(this.el.nativeElement, 'custom-caret');

      const divider = this.renderer.createElement('div');
      const textNode = this.renderer.createText('b');

      this.renderer.appendChild(divider, textNode);
      this.renderer.addClass(divider, 'separator');
      this.renderer.appendChild(this.el.nativeElement.parentNode, divider);

      const textToMeasure = inputElement.value.substring(0, inputElement.selectionStart);

      const span = this.renderer.createElement('span');
      this.renderer.setStyle(span, 'fontSize', getComputedStyle(this.el.nativeElement).fontSize);
      this.renderer.setStyle(span, 'fontFamily', getComputedStyle(this.el.nativeElement).fontFamily);
      this.renderer.setProperty(span, 'textContent', textToMeasure);
      this.renderer.appendChild(document.body, span);

      const textWidth = span.offsetWidth;

      this.renderer.removeChild(document.body, span);
      this.renderer.setStyle(divider, 'left', textWidth + 'px');
    }, 0);
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
    this.overwriteModeChanged.complete();
  }
}
