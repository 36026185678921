import { ApplicationRef, enableProdMode } from '@angular/core';
import { Title, enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { getAppConfig } from './app/app-config-accessor';
import { AppModule } from './app/app.module';

const config = getAppConfig();

if (config.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    preserveWhitespaces: false
  })
  .then((moduleRef) => {
    if (!config.production) {
      const applicationRef = moduleRef.injector.get(ApplicationRef);
      const componentRef = applicationRef.components[0];
      // allows to run `ng.profiler.timeChangeDetection();`
      enableDebugTools(componentRef);
    }

    if (config.isWebBuild) {
      const titleService = moduleRef.injector.get(Title);
      titleService.setTitle(`${config.appPrefix}AVA`);
    }
  })
  .catch((err) => console.error(err));
