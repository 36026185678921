<table mat-table [dataSource]="avaProjectsService" matSort (matSortChange)="avaProjectsService.onSort($event)">
  <ng-container matColumnDef="name">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let row" matRipple>{{ row.name }}</td>
  </ng-container>
  <ng-container matColumnDef="modifiedAtUtc">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Letzte Änderung</th>
    <td mat-cell *matCellDef="let row" matRipple>{{ row.modifiedAtUtc | date: 'dd.MM.yyyy HH:mm' }}</td>
  </ng-container>
  <ng-container matColumnDef="totalPrice">
    <th mat-header-cell *matHeaderCellDef class="right">Gesamtpreis</th>
    <td mat-cell *matCellDef="let row" matRipple class="right">
      {{ totalPrices[row.id]?.totalPrice | number: '1.2-2' }} {{ totalPrices[row.id]?.currency }}
    </td>
  </ng-container>
  <ng-container matColumnDef="servSpecType">
    <th mat-header-cell *matHeaderCellDef>Typ</th>
    <td mat-cell *matCellDef="let row" matRipple>{{ row.type | avaProjectType }}</td>
  </ng-container>
  <ng-container matColumnDef="sourceType">
    <th mat-header-cell *matHeaderCellDef>Quelltyp</th>
    <td mat-cell *matCellDef="let row" matRipple>{{ row.sourceType | originDto }}</td>
  </ng-container>
  <ng-container matColumnDef="update">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let row" class="right">
      <button mat-raised-button color="primary" class="update" *ngIf="isAllowUpdate(row)">Update</button>
    </td>
  </ng-container>
  <ng-container matColumnDef="export" *ngIf="!isInnerWindow">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let row" class="right">
      <div class="action-wrapper" fxLayoutAlign="end center">
        <button mat-raised-button color="primary" class="export">Export</button>
        <mat-icon
          class="delete-icon"
          color="warn"
          *ngIf="(avaProjectsService.connect() | async)?.length > 1"
          (click)="deleteAvaProject($event, row)"
          >close</mat-icon
        >
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onClicked($event, row)"></tr>
</table>
<pa-upper-paginator
  [pageSizeOptions]="pageSizeOptions"
  [upperPaginated]="avaProjectsService.paginationResult | async"
  [upperService]="avaProjectsService"
  (page)="avaProjectsService.onPage($event)"
>
</pa-upper-paginator>
