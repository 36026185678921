import { Injectable, OnDestroy } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';

import { ComponentPortal } from '@angular/cdk/portal';
import { PdfOverlayComponent } from './../components/pdf-overlay/pdf-overlay.component';
import { PrintViewMessengerService } from '../services/electron/print-view-messenger.service';
import { Subject } from 'rxjs';
import { UserSettings } from 'app/generated-client/generated-client';
import { UserSettingsService } from 'app/shared/services/user-settings.service';
import { platform } from 'process';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class PdfOverlayService implements OnDestroy {
  private overlayRef: OverlayRef | null = null;
  private $destroy = new Subject<boolean>();
  private currentUserSettings: UserSettings;
  constructor(
    private overlay: Overlay,
    private printViewMessengerService: PrintViewMessengerService,
    private userSettingsService: UserSettingsService
  ) {
    this.userSettingsService.currentUserSettings.pipe(takeUntil(this.$destroy)).subscribe((setting: UserSettings) => {
      this.currentUserSettings = setting;
    });
    this.printViewMessengerService.printViewVisible.pipe(takeUntil(this.$destroy)).subscribe(printViewVisible => {
      if (printViewVisible) {
        if (/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)) {
          this.show();
        } else if (this.currentUserSettings.showFullViewOverlayWhenOpeningPdfPreview) {
          this.show();
        }
      } else {
        this.hide();
      }
    });
  }
  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  public show(message = '') {
    // Returns an OverlayRef (which is a PortalHost)

    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create();
    }

    // Create ComponentPortal that can be attached to a PortalHost
    const spinnerOverlayPortal = new ComponentPortal(PdfOverlayComponent);
    const component = this.overlayRef.attach(spinnerOverlayPortal); // Attach ComponentPortal to PortalHost
  }

  public hide() {
    if (!!this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}
