import { Pipe, PipeTransform } from '@angular/core';

import { WindowType } from '../../models/window-type.models';

@Pipe({
  name: 'embeddedViewNames',
  standalone: true
})
export class EmbeddedViewNamesPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case WindowType.Product:
        return 'Stammartikel';
      case WindowType.CopyCalc:
        return 'Kalkulation Kopieren';
      case WindowType.LongText:
        return 'Langtext';
      case WindowType.CompareQTO:
        return 'Mengenvergleich';
      case WindowType.CopyElement:
        return 'LV-Elemente Kopieren';
    }

    return 'Unbekannte Ansicht';
  }
}
