import { EventEmitter, Injectable } from '@angular/core';

import { IElementDto } from '../../../generated-client/generated-client';

@Injectable({
  providedIn: 'root'
})
export class ClickerService {
  clickEventSingle = new EventEmitter<IElementDto>();
  clickEventDbl = new EventEmitter<IElementDto>();

  clickedBySingle(item: IElementDto) {
    this.clickEventSingle.emit(item);
  }
  clickedByDbl(item: IElementDto) {
    this.clickEventDbl.emit(item);
  }
}
