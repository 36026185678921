import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';

import { TinyMceComponent } from '@dangl/angular-material-shared/tiny-mce';

import { TextTemplateType } from '../../../../../../generated-client/generated-client';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

import { SetTextSelectionComponent } from '../set-text-selection/set-text-selection.component';

@Component({
  selector: 'pa-report-text-selection',
  templateUrl: './report-text-selection.component.html',
  styleUrls: ['./report-text-selection.component.scss'],
  standalone: true,
  imports: [MatDialogContent, SetTextSelectionComponent, TinyMceComponent, FormsModule, FlexLayoutDirective, MatButton]
})
export class ReportTextSelectionComponent implements OnInit {
  htmlText: string;

  constructor(
    @Optional() private dialogRef: MatDialogRef<ReportTextSelectionComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      text: string;
      templateType: TextTemplateType;
    }
  ) {}

  ngOnInit(): void {
    this.htmlText = this.data.text;
  }

  save(): void {
    this.dialogRef.close(this.htmlText);
  }

  exitWithoutSaving(): void {
    this.dialogRef.close(null);
  }

  changedName(htmlText: string): void {
    this.htmlText = htmlText ?? this.data.text;
  }
}
