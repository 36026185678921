import { Pipe, PipeTransform } from '@angular/core';

import { JwtTokenService } from '@dangl/angular-dangl-identity-client';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { getAppConfig } from '../../app-config-accessor';
import { LocalProjectsService } from '../../areas/local-projects/services/local-projects.service';

@Pipe({
  name: 'imgSrc',
  standalone: true
})
export class ImgSrcPipe implements PipeTransform {
  constructor(
    private jwtTokenService: JwtTokenService,
    private localProjectsService: LocalProjectsService
  ) {}
  private isQtoOnlyMode = getAppConfig().isQtoOnlyMode;

  transform(fileId: string): Observable<string> {
    if (!fileId) {
      return of<string>(null);
    }

    return this.jwtTokenService.getToken().pipe(
      map((tokenStorage) => {
        const accessToken = tokenStorage.accessToken;
        let queryString = '';
        if (this.isQtoOnlyMode) {
          const filePath = this.localProjectsService.activeProjectFilePath;
          queryString = `&localProjectLocation=${encodeURI(filePath)}`;
        }
        const imageUrlWithAuth = getAppConfig().backendUrl + `/api/files/${fileId}?access_token=${accessToken}${queryString}`;
        return imageUrlWithAuth;
      })
    );
  }
}
