import { CommonModule } from '@angular/common';
import { Component, Input, inject, type OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { HelpWindowScrollService } from '@shared/components/help-window-modal/services/help-window-scroll.service';
import { IHelpInformation } from '@shared/components/help-window-modal/services/help-window.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'pa-help-window-text',
  standalone: true,
  imports: [CommonModule, MatDividerModule],
  templateUrl: './help-window-text.component.html',
  styleUrls: ['./help-window-text.component.scss']
})
export class HelpWindowTextComponent implements OnInit, OnDestroy {
  @Input() flatStructureData: IHelpInformation[];
  helpWindowScrollService = inject(HelpWindowScrollService);
  renderer = inject(Renderer2);

  private $destroy: Subject<boolean> = new Subject<boolean>();

  ngOnInit(): void {
    this.helpWindowScrollService.scrollElementId$.pipe(takeUntil(this.$destroy)).subscribe((elementId: string) => {
      if (elementId) {
        this.scrollToElement(elementId);
      }
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private scrollToElement(elementId: string): void {
    const element = document.getElementById(`${elementId}`);
    element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
