<div mat-dialog-content>
  <div class="wrapper">
    <div class="wrapper-table">
      <pa-master-data-products-table
        (selectItem)="selectElement($event)"
        [priceComponentType]="priceComponentType"
      ></pa-master-data-products-table>
    </div>
  </div>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-raised-button mat-dialog-close>Abbrechen</button>
</div>
