import { Component, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MatDialogContent } from '@angular/material/dialog';

import { Subject, takeUntil } from 'rxjs';

import { CopyCalculationViewMessengerService } from 'app/shared/services/electron/copy-calculation-view-messenger.service';

import { OtherProjectTabComponent } from '../other-project-tab/other-project-tab.component';

@Component({
  selector: 'pa-copy-group-modal',
  templateUrl: './copy-group-modal.component.html',
  styleUrls: ['./copy-group-modal.component.scss'],
  standalone: true,
  imports: [MatDialogContent, OtherProjectTabComponent]
})
export class CopyGroupModalComponent implements OnInit {
  elementGroupId: string;
  private $destroy = new Subject<boolean>();

  constructor(
    private copyCalculationViewMessengerService: CopyCalculationViewMessengerService,
    @Optional() private matDialogRef: MatDialogRef<CopyGroupModalComponent>
  ) {}

  ngOnInit(): void {
    this.copyCalculationViewMessengerService.selectedElementGroupId.pipe(takeUntil(this.$destroy)).subscribe((elementGroupId) => {
      this.elementGroupId = elementGroupId;
    });

    this.copyCalculationViewMessengerService.reloadCurrentPositionCalculation.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.matDialogRef.close();
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }
}
