import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';

import { Subject, takeUntil } from 'rxjs';

import { QuantityAttribute } from 'app/areas/bim-view/models';
import { QuantityAttributeService } from 'app/areas/bim-view/services/quantity-attribute.service';

@Component({
  selector: 'pa-quantity-attribute-selection-table',
  standalone: true,
  imports: [CommonModule, MatTableModule, MatIconModule],
  templateUrl: './quantity-attribute-selection-table.component.html',
  styleUrls: ['./quantity-attribute-selection-table.component.scss']
})
export class QuantityAttributeSelectionTableComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['entityId', 'quantity'];
  dataSource: MatTableDataSource<QuantityAttribute>;
  totalQuantity;
  quantityAssignments: { buildingElementEntityId: number; elementQuantity: number }[];
  private $destroy: Subject<boolean> = new Subject<boolean>();

  quantityAttributeService = inject(QuantityAttributeService);

  ngOnInit(): void {
    this.quantityAttributeService.quantityAttributeList.pipe(takeUntil(this.$destroy)).subscribe((list) => {
      if (list.length) {
        this.quantityAssignments = [...list]
          .filter((elem) => elem.quantity !== null)
          .map((v) => {
            return {
              buildingElementEntityId: v.entityId,
              elementQuantity: +v.quantity as number
            };
          });
      }
      this.dataSource = new MatTableDataSource(list);
      this.totalQuantity = this.getTotalQuantity(list);
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  onSort(): void {
    this.dataSource.sort = this.sort;
  }

  getTotalQuantity(list: QuantityAttribute[]): number {
    this.totalQuantity = 0;
    if (list.length) {
      list.forEach((v) => {
        if (v.quantity !== null) {
          this.totalQuantity += v.quantity;
        }
      });
    }
    return this.totalQuantity;
  }
}
