import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';

import { PrintViewMessengerService } from 'app/shared/services/electron/print-view-messenger.service';

@Component({
  selector: 'pa-pdf-overlay',
  templateUrl: './pdf-overlay.component.html',
  styleUrls: ['./pdf-overlay.component.scss'],
  standalone: true,
  imports: [MatButton]
})
export class PdfOverlayComponent {
  constructor(private printViewMessengerService: PrintViewMessengerService) {}

  closePdfViewWindow(): void {
    this.printViewMessengerService.closePdfViewWindow();
  }
}
