import { Injectable } from '@angular/core';

import { ReplaySubject } from 'rxjs';

import { AdditionTypeDto, IElementDto, PositionDto } from '../../../generated-client/generated-client';

import { FlatElementsService } from './flat-elements.service';

@Injectable({
  providedIn: 'root'
})
export class PositionTextAdditionService {
  private modePositionTextAdditionSource = new ReplaySubject<boolean>(1);
  modePositionTextAddition = this.modePositionTextAdditionSource.asObservable();

  private listPositionTextAdditionSource = new ReplaySubject<string[]>(1);
  listPositionTextAddition = this.listPositionTextAdditionSource.asObservable();

  private flatElements: IElementDto[];

  constructor(private flatElementsService: FlatElementsService) {
    this.modePositionTextAdditionSource.next(false);
    flatElementsService.flatElementsDto.subscribe((flatElements) => {
      this.flatElements = flatElements;
    });
  }

  setModePositionTextAddition(value: boolean): void {
    if (!value) {
      this.modePositionTextAdditionSource.next(value);
    } else {
      const positionIdsWithTextAdditions = this.flatElements
        .filter((element) => element.elementType === 'PositionDto' && (element as PositionDto).additionType !== AdditionTypeDto.None)
        .map((e) => e.id);

      this.listPositionTextAdditionSource.next(positionIdsWithTextAdditions);
      this.modePositionTextAdditionSource.next(value);
    }
  }
}
