import { Injectable } from '@angular/core';
import {ReplaySubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SelectedSortMethodService {
  private selectedSortMethodSource = new ReplaySubject<string>(1);
  selectedSortMethod = this.selectedSortMethodSource.asObservable();

  setSelectedSortMethod(item: string): void {
    this.selectedSortMethodSource.next(item);
  }
}
