import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ContactGet, ContactsClient } from 'app/generated-client/generated-client';

import { AddressFormComponent } from '@contacts/components/address-form/address-form.component';
import { AvaNotificationsService } from '../../../../shared/services/ava-notifications.service';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { ContactAddressesComponent } from '@contacts/components/contact-addresses/contact-addresses.component';
import { ContactPersonsComponent } from '@contacts/components/contact-persons/contact-persons.component';
import { ContactsFormComponent } from '../contacts-form/contacts-form.component';
import { ContactsService } from '../../../../shared/services/lightquery/contacts.service';
import { ContactTypePipe } from 'app/shared/pipes/ui-data-display/contact-type.pipe';
import { ContactTermsComponent } from '@contacts/components/contact-terms/contact-terms.component';
import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { ModalService } from 'app/shared/services/modal.service';
import { PersonsFormComponent } from '@contacts/components/persons-form/persons-form.component';
import { SelectedContactMessengerService } from '../../services/selected-contact-messenger.service';
import { TermsTableComponent } from '@contacts/components/terms-table/terms-table.component';

@Component({
  selector: 'pa-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    ContactTypePipe,
    ContactAddressesComponent,
    TermsTableComponent,
    ContactPersonsComponent,
    ContactTermsComponent,
    AddressFormComponent,
    PersonsFormComponent
  ]
})
export class ContactDetailComponent implements OnInit, OnDestroy {
  contact: ContactGet;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private selectedContactMessengerService: SelectedContactMessengerService,
    private modalService: ModalService,
    private contactsClient: ContactsClient,
    private avaNotificationsService: AvaNotificationsService,
    private contactsService: ContactsService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.selectedContactMessengerService.selectedContact
      .pipe(takeUntil(this.$destroy))
      .subscribe((selectedContact: ContactGet) => (this.contact = selectedContact));
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  editContact(): void {
    this.modalService
      .openModal(ContactsFormComponent, { dialogType: ConfirmationType.General, data: this.contact.id, restoreFocus: false })
      .afterClosed()
      .subscribe(contact => {
        if (contact) {
          Object.assign(this.contact, contact);
        }
      });
  }

  removeContact(): void {
    this.modalService
      .openModal(ModalConfirmComponent, { dialogType: ConfirmationType.General, data: ['Löschen', 'Kontakt', this.contact.name] })
      .afterClosed()
      .subscribe(isRemove => {
        if (isRemove) {
          this.contactsClient.deleteContact(this.contact.id).subscribe({
            next: () => {
              this.avaNotificationsService.success('Kontakt entfernt');
              this.contactsService.forceRefresh();
              this.location.back();
            },
            error: () =>
              this.avaNotificationsService.error('Fehler beim Entfernen des Kontakts. Dieser Kontakt wird in einem Projekt referenziert.')
          });
        }
      });
  }

  back(): void {
    this.location.back();
  }
}
