import { NgIf, AsyncPipe, DecimalPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatRipple } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';

import { Subject, combineLatest } from 'rxjs';
import { skipWhile, takeUntil } from 'rxjs/operators';

import { AvaProjectGet, AvaProjectsClient, OriginDto } from 'app/generated-client/generated-client';
import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaProjectsService } from 'app/shared/services/lightquery/ava-projects.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';

import { UpperPaginatorComponent } from '../../../../../../shared/components/upper-paginator/upper-paginator.component';
import { AvaProjectTypePipe } from '../../../../../../shared/pipes/ui-data-display/ava-project-type.pipe';
import { OriginDtoPipe } from '../../../../../../shared/pipes/ui-data-display/origin-dto.pipe';
import { AvaNotificationsService } from '../../../../../../shared/services/ava-notifications.service';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

import { ServiceSpecificationExportModalComponent } from '../service-specification-export-modal/service-specification-export-modal.component';
import { ServiceSpecificationUpdateDialogComponent } from '../service-specification-update-dialog/service-specification-update-dialog.component';

@Component({
  selector: 'pa-service-specifications-table',
  templateUrl: './service-specifications-table.component.html',
  styleUrls: ['./service-specifications-table.component.scss'],
  standalone: true,
  imports: [
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    MatRipple,
    NgIf,
    MatButton,
    FlexLayoutDirective,
    MatIcon,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    UpperPaginatorComponent,
    AsyncPipe,
    DecimalPipe,
    DatePipe,
    OriginDtoPipe,
    AvaProjectTypePipe
  ]
})
export class ServiceSpecificationsTableComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) private sort: MatSort;
  @Input() isInnerWindow: boolean;
  @Output() selectRow = new EventEmitter<AvaProjectGet>();

  pageSizeOptions: number[];
  displayedColumns: string[];
  private $destroy: Subject<boolean> = new Subject<boolean>();
  totalPrices: { [avaProjectId: string]: { totalPrice: number; currency: string } } = {};
  private projectId: string | null;

  constructor(
    public avaProjectsService: AvaProjectsService,
    private avaProjectsClient: AvaProjectsClient,
    private modalService: ModalService,
    private avaNotificationsService: AvaNotificationsService,
    private selectedProjectMessengerService: SelectedProjectMessengerService
  ) {}

  ngOnInit(): void {
    this.pageSizeOptions = this.isInnerWindow ? [5] : [5, 10, 25, 100];
    this.displayedColumns = this.isInnerWindow
      ? ['name', 'modifiedAtUtc', 'servSpecType', 'sourceType', 'totalPrice']
      : ['name', 'modifiedAtUtc', 'servSpecType', 'sourceType', 'totalPrice', 'update', 'export'];

    this.avaProjectsService.initService({
      sorting: { propertyName: 'modifiedAtUtc', isDescending: true },
      sortTableObj: this.sort
    });
    combineLatest([this.selectedProjectMessengerService.selectedProject, this.avaProjectsService.paginationResult])
      .pipe(
        takeUntil(this.$destroy),
        skipWhile(([sp, r]) => r.data.find((p) => p.projectId === sp?.id) == null)
      )
      .subscribe(([sp, r]) => {
        this.projectId = sp?.id;
        r.data.forEach((ap) => this.getTotalPrice(ap));
        if (this.isInnerWindow && r.totalCount === 1) {
          setTimeout(() => {
            this.selectAvaProject(r.data[0]);
          }, 200);
        }
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  showExportModal(serviceSpecification: AvaProjectGet): void {
    const dialogRef = this.modalService.openModal(ServiceSpecificationExportModalComponent, {
      dialogType: ConfirmationType.General,
      data: serviceSpecification
    });
    dialogRef.addPanelClass('modal-export');
  }

  onClicked(event: MouseEvent, avaProject: AvaProjectGet): void {
    const isExport = (event.target as HTMLElement).closest('.export');
    if (isExport) {
      this.showExportModal(avaProject);
    } else if ((event.target as HTMLElement).closest('.update')) {
      this.modalService.openModal(ServiceSpecificationUpdateDialogComponent, {
        data: {
          avaProject,
          projectId: this.projectId
        },
        dialogType: ConfirmationType.General
      });
    } else {
      this.selectAvaProject(avaProject);
    }
  }

  isAllowUpdate(row: AvaProjectGet): boolean {
    return row.sourceType !== OriginDto.Self;
  }

  selectAvaProject(avaProject: AvaProjectGet): void {
    this.selectRow.emit(avaProject);
  }

  getTotalPrice(row: AvaProjectGet): void {
    if (this.totalPrices[row.id] == null) {
      this.avaProjectsClient.getAvaProjectTotalPriceById(row.projectId, row.id).subscribe((totals) => {
        this.totalPrices[row.id] = {
          totalPrice: totals.totalPrice,
          currency: totals.currency
        };
      });
    }
  }

  deleteAvaProject(e: Event, row: AvaProjectGet): void {
    e.stopPropagation();
    this.modalService
      .openModal(ModalConfirmComponent, {
        dialogType: ConfirmationType.Delete,
        data: ['Löschen', 'Projekt-LV "' + row.name + '"'],
        autoFocus: false,
        restoreFocus: false
      })
      .afterClosed()
      .subscribe((isConfirm: boolean) => {
        if (isConfirm) {
          this.avaProjectsClient.deleteAvaProject(row.projectId, row.id).subscribe(
            () => {
              this.avaNotificationsService.success('LV Gelöscht');
              this.avaProjectsService.forceRefresh();
            },
            () => this.avaNotificationsService.error('Fehler beim Löschen des LVs')
          );
        }
      });
  }
}
