import { PriceComponentType } from 'app/generated-client/generated-client';

export const SUBPOSITION_DEFAULT_COLORS = {
  1: '#ffff00',
  2: '#ffa500',
  3: '#add8e6',
  4: '#9cee90',
  5: '#00acc1',
  6: '#d03ecb',
  7: '#e0ee11',
  8: '#cbb210',
  9: '#ec5177',
  10: '#00cc00'
};

export const TABLE_REPL_PRICE_COMPONENT: { [key: string]: PriceComponentType } = {
  Artikel: PriceComponentType.Materials,
  Gerät: PriceComponentType.Equipment,
  Schalung: PriceComponentType.Casing,
  Misc: PriceComponentType.Miscellaneous,
  Sub: PriceComponentType.Subcontractor
};
