import { QuantityTakeOffGet } from 'app/generated-client/generated-client';

export enum InvoiceMenuActions {
  EditInvoiceName = 'EditInvoiceName',
  CopyInvoice = 'CopyInvoice',
  MoveInvoice = 'MoveInvoice',
  RemoveInvoice = 'RemoveInvoice',
  AddProjectQuantityReference = 'AddProjectQuantityReference',
  AddProjectQuantityReferenceAsAssumed = 'ApplyQuantitiesAsAssumed',
  DeleteProjectQuantityReference = 'DeleteProjectQuantityReference',
  DeleteProjectQuantityReferenceAsAssumed = 'DeleteProjectQuantityReferenceAsAssumed',
  PrintInvoice = 'PrintInvoice',
  ApplyQuantities = 'ApplyQuantities',
  BulkExportQuantities = 'BulkExportQuantities',
  Open = 'Open'
}

export interface InvoiceMenuAction {
  action: InvoiceMenuActions;
  item: QuantityTakeOffGet;
}

export interface PageQtoMenuAction {
  action: InvoiceMenuActions;
  item: any;
}
