import { NgClass } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject, combineLatest, combineLatestWith, fromEvent } from 'rxjs';
import { filter, first, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { InvoicePdfFileComponent } from '@serv-spec/components/invoice/components/invoice-pdf-file/invoice-pdf-file.component';
import { CheckErrorsAndOpenModalWindowService } from '@serv-spec/services/check-errors-and-open-modal-window.service';
import { CurrentPositionCalculationGetService } from '@serv-spec/services/current-position-calculation-get.service';
import { LvEditorService } from '@serv-spec/services/lv-editor.service';

import { CalculationFixedPriceService } from '@project/services/calculation-fixed-price.service';

import { ModePageService } from 'app/areas/tree/services/mode-page.service';
import { TreeNodeMarkService } from 'app/areas/tree/services/tree-node-mark.service';
import { TreeNodeStateService } from 'app/areas/tree/services/tree-node-state.service';
import { getElementDtoById } from 'app/areas/tree/utils/fn';
import {
  AvaProjectContentEditClient,
  AvaProjectContentEditOperation,
  IElementDto,
  PositionCalculationGet,
  PositionDto,
  ProjectDto,
  ProjectGet,
  QuantityTakeOffGet,
  QuantityTakeOffsClient,
  ServiceSpecificationDto,
  ServiceSpecificationGroupDto,
  SubPositionGet,
  TreeViewDisplayType
} from 'app/generated-client/generated-client';
import { ShowErrorsComponent } from 'app/shared/components/show-errors/show-errors.component';
import { TotalInvoiceSumType, TreeViewMessageType } from 'app/shared/models';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { TreeViewCommandType } from 'app/shared/models/tree-view-command.model';
import { CopyCalculationViewMessengerService } from 'app/shared/services/electron/copy-calculation-view-messenger.service';
import { CopyElementViewMessengerService } from 'app/shared/services/electron/copy-element-view-messenger.service';
import { ElementViewMessengerService } from 'app/shared/services/electron/element-view-messenger.service';
import { LongTextViewMessengerService } from 'app/shared/services/electron/long-text-view-messenger.service';
import { TreeViewMessengerService } from 'app/shared/services/electron/tree-view-messenger.service';
import { ElementHasWithoutTotalGroupService } from 'app/shared/services/element-has-without-total-group.service';
import { GroupViewService } from 'app/shared/services/group-view.service';
import { KeyboardPositionSelectionService } from 'app/shared/services/keyboard-position-selection.service';
import { QuantityTakeOffInvoiceTotalsMessengerService } from 'app/shared/services/messengers/quantity-take-off-invoice-totals-messenger.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedQuantityTakeOffMessengerService } from 'app/shared/services/messengers/selected-quantity-take-off-messenger.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';
import { SelectRowsService } from 'app/shared/services/select-rows.service';
import { TopMenuButtonsService } from 'app/shared/services/top-menu-buttons.service';
import { UserSettingsService } from 'app/shared/services/user-settings.service';
import { setAsSplitSize } from 'app/shared/utilities/area-size';
import { setStorage } from 'app/shared/utilities/storage';

import { ModalConfirmComponent } from '../../../../../../shared/components/modal-confirm/modal-confirm.component';
import { AvaNotificationsService } from '../../../../../../shared/services/ava-notifications.service';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';
import { MainTreeComponent } from '../../../../../tree/components/main-tree/main-tree.component';
import { DbclickSubscriptionService } from '../../services/dbclick-subscription.service';

import { TreeDisplayService } from './../invoice/services/tree-display.service';

import { TreeInvoicePageComponent } from '../invoice/components/tree-invoice-page/tree-invoice-page.component';
import { MainPositionsTableComponent } from '../main-positions-table/main-positions-table.component';
import { SelectingElementModalComponent } from '../selecting-element-modal/selecting-element-modal.component';
import { ServiceSpecificationDeductionHandlingModalComponent } from '../service-specification-deduction-handling-modal/service-specification-deduction-handling-modal.component';
import { IOutputAreaSizes, AngularSplitModule } from 'angular-split';

@Component({
  selector: 'pa-service-specification-tree',
  templateUrl: './service-specification-tree.component.html',
  styleUrls: ['./service-specification-tree.component.scss'],
  providers: [KeyboardPositionSelectionService],
  standalone: true,
  imports: [FlexLayoutDirective, AngularSplitModule, MainTreeComponent, NgClass]
})
export class ServiceSpecificationTreeComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('asSplitRightRef') asSplitRightRef: ElementRef;
  handleKeyUpEvent(event: KeyboardEvent): void {
    if (event.key === 'F8') {
      this.ngZone.run(() => {
        this.openDetailWindow();
      });
    } else if (event.key === 'F9') {
      this.ngZone.run(() => {
        this.openLongTextWindow();
      });
    }
  }

  @Input() elementId: string;
  @Input() delSwitchingButtons: boolean;
  currentElementCalc: PositionCalculationGet;
  dataSource = new MatTreeNestedDataSource<ServiceSpecificationGroupDto>();
  elementViewOpen = false;
  isLongTextViewOpen = false;
  @Input() groupView = TreeViewDisplayType.Tree;

  isPages = false;
  isLoading = true;
  isToggleTreeWidth = false;
  showTotalsInTreeQTO = false;
  percentOfCalcPositions: number;
  rangeOfCalcPositions: string;
  projectCurrency: string;
  positionsCalculatedPercent: number;
  selectedNodeId: string;
  modePage = '';
  isOpenTreeWindow: boolean;
  nodeId: string;
  totalsQTO: TotalInvoiceSumType;
  selectedElement: IElementDto;
  avaProject: ProjectDto;

  private projectId: string;
  private quantityTakeOffPageId: string;
  private qto: QuantityTakeOffGet;
  private serviceSpecificationId: string;
  private $destroy: Subject<boolean> = new Subject();
  treeNodeState: { [elementId: string]: boolean };
  treeNodeStateFromTree: { [elementId: string]: boolean };
  serviceSpecification: ServiceSpecificationDto;

  constructor(
    private dbclickSubscriptionService: DbclickSubscriptionService,
    private elementViewMessengerService: ElementViewMessengerService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private selectedQuantityTakeOffMessengerService: SelectedQuantityTakeOffMessengerService,
    private treeViewMessengerService: TreeViewMessengerService,
    private selectRowsService: SelectRowsService,
    private currentPositionCalculationGetService: CurrentPositionCalculationGetService,
    private quantityTakeOffInvoiceTotalsMessengerService: QuantityTakeOffInvoiceTotalsMessengerService,
    private longTextViewMessengerService: LongTextViewMessengerService,
    private lvEditorService: LvEditorService,
    private treeDisplayService: TreeDisplayService,
    private elementHasWithoutTotalGroupService: ElementHasWithoutTotalGroupService,
    private topMenuButtonsService: TopMenuButtonsService,
    private userSettingsService: UserSettingsService,
    public groupViewService: GroupViewService,
    private copyCalculationViewMessengerService: CopyCalculationViewMessengerService,
    private treeNodeStateService: TreeNodeStateService,
    private modePageService: ModePageService,
    private avaProjectContentEditClient: AvaProjectContentEditClient,
    private avaNotificationsService: AvaNotificationsService,
    private calculationFixedPriceService: CalculationFixedPriceService,
    private ngZone: NgZone,
    private treeNodeMarkService: TreeNodeMarkService,
    private copyElementViewMessengerService: CopyElementViewMessengerService,
    private checkErrorsAndOpenModalWindowService: CheckErrorsAndOpenModalWindowService,
    private quantityTakeOffsClient: QuantityTakeOffsClient
  ) {}

  ngOnInit(): void {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(document, 'keyup')
        .pipe(takeUntil(this.$destroy))
        .subscribe((event: KeyboardEvent) => {
          this.handleKeyUpEvent(event);
        });
    });

    if (!this.delSwitchingButtons) {
      this.groupViewService.projectGroupView.pipe(takeUntil(this.$destroy)).subscribe((e) => {
        if (e) {
          this.groupView = e;
          if (this.isOpenTreeWindow) {
            this.setStartTree();
          }
        }
      });
    }

    this.modePageService.modePage.pipe(takeUntil(this.$destroy)).subscribe((modePage) => {
      this.modePage = modePage;
      if (modePage.includes('pages')) {
        this.quantityTakeOffPageId = this.router.url.split('/').pop();
      }
      this.setStartTree();
    });

    this.treeNodeStateService.treeNodeState.pipe(takeUntil(this.$destroy)).subscribe((treeNodeState) => {
      this.treeNodeState = treeNodeState;
    });

    this.selectedSpecificationElementMessengerService.clearSelectedElement();
    this.treeViewMessengerService.dataFromTreeView.pipe(takeUntil(this.$destroy)).subscribe((e: TreeViewMessageType) => {
      const { command, data, treeNodeState } = e;
      if (treeNodeState) {
        this.treeNodeStateFromTree = treeNodeState;
      }

      switch (command) {
        case TreeViewCommandType.ChangeFixedPrice: {
          const [element] = data;
          this.modePageService.modePage.pipe(take(1)).subscribe((modePage) => {
            if (modePage === 'calculation' && (element as IElementDto).elementTypeDiscriminator === 'PositionDto') {
              this.calculationFixedPriceService.setFixedPriceForCalculation(
                (element as PositionDto)?.id,
                (element as PositionDto).unitPrice
              );
            }
          });
          break;
        }
        case TreeViewCommandType.ChangeSelectedElement:
          {
            this.treeViewMessengerService.treeViewVisible
              .pipe(
                take(1),
                filter((treeViewVisible) => !!treeViewVisible)
              )
              .subscribe(() => {
                const [element] = data;
                const elementId = (element as IElementDto)?.id;
                this.selectedSpecificationElementMessengerService.trySelectElementById(elementId);

                this.nodeId = element?.['id'];
              });
          }
          break;
        case TreeViewCommandType.SelectElementByDblClick:
          {
            const [elementDbl] = data;
            this.dbclickSubscriptionService.setDbclick(elementDbl as IElementDto);
          }
          break;
        case TreeViewCommandType.ChangeGroupeView:
          [this.groupView] = data as TreeViewDisplayType[];
          this.groupViewService.setGroupView(this.groupView);
          break;
        case TreeViewCommandType.Clear:
          this.selectedSpecificationElementMessengerService.clearSelectedElement();
          break;
        case TreeViewCommandType.SelectSubPosition: {
          const [subPosition] = data as SubPositionGet[];
          if (subPosition?.avaPositionId === this.nodeId) {
            this.selectRowsService.setSelectedRowByIndex(subPosition?.rowIndex);
          } else {
            const parentElement = getElementDtoById(subPosition?.avaPositionId, this.serviceSpecification.elements);

            this.selectedSpecificationElementMessengerService.trySelectElementById(parentElement?.id);

            this.currentPositionCalculationGetService.isCalculationDataLoading
              .pipe(
                filter((v) => !!v),
                take(1)
              )
              .subscribe((isCalculationDataLoading) => {
                if (isCalculationDataLoading) {
                  this.selectRowsService.setSelectedRowByIndex(subPosition?.rowIndex);
                }
              });
          }
          break;
        }

        case TreeViewCommandType.Closed:
          this.treeNodeMarkService.getMarkElementForCurrentServSpec();
          break;
        case TreeViewCommandType.OpenCopyElementView:
          this.copyElementViewMessengerService.checkSettingAndOpenCopyElementView();
          break;
        default:
      }
    });

    this.elementViewMessengerService.dataFromElementView.pipe(takeUntil(this.$destroy)).subscribe((dataFromElementView) => {
      const { command, data } = dataFromElementView;
      switch (command) {
        case 'fixedPrice':
          this.modePageService.modePage.pipe(take(1)).subscribe((modePage) => {
            if (modePage === 'calculation' || modePage === 'lv-editor') {
              this.calculationFixedPriceService.setFixedPriceForCalculation(this.selectedElement?.id, data);
            }
          });
          break;
        default:
          break;
      }
    });

    this.elementViewMessengerService.elementViewVisible.pipe(takeUntil(this.$destroy)).subscribe((isOpen: boolean) => {
      this.elementViewOpen = isOpen;
      if (this.elementViewOpen) {
        this.elementHasWithoutTotalGroupService.hasWithoutTotalGroup.pipe(first()).subscribe((hasWithoutTotalGroup) => {
          this.elementViewMessengerService.setDataToElementView({
            command: 'hasWithoutTotalGroup',
            data: [hasWithoutTotalGroup]
          });
        });
        this.modePageService.modePage.pipe(first()).subscribe((modePage) => {
          if (modePage === 'calculation') {
            this.elementViewMessengerService.setDataToElementView({
              command: 'changeModePage',
              data: [modePage]
            });
          }
        });
      }
    });

    this.longTextViewMessengerService.longTextViewVisible.pipe(takeUntil(this.$destroy)).subscribe((isOpen: boolean) => {
      this.isLongTextViewOpen = isOpen;
      if (this.isLongTextViewOpen) {
        const dataToWindow = [this.projectId, this.avaProject?.id, this.selectedElement?.id, this.selectedElement?.['htmlLongText']];
        this.longTextViewMessengerService.sendDataToLongTextView({ element: this.selectedElement, texts: dataToWindow });
      }
    });
    combineLatest([
      this.selectedProjectMessengerService.selectedProject,
      this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(filter((s) => !!s))
    ])
      .pipe(takeUntil(this.$destroy))
      .subscribe(([project, avaProject]: [ProjectGet, { avaProjectId: string; project: ProjectDto }]) => {
        this.projectId = project?.id;
        this.serviceSpecificationId = avaProject?.avaProjectId;
        this.serviceSpecification = avaProject.project.serviceSpecifications[0];
        this.avaProject = avaProject?.project;
        this.trySendViewMessage();
      });

    this.treeViewMessengerService.treeViewVisible.pipe(takeUntil(this.$destroy)).subscribe((isTreeOpen: boolean) => {
      if (isTreeOpen) {
        this.setStartTree(true);

        setTimeout(() => {
          this.selectRowsService.selectedRowSumma.pipe(take(1)).subscribe((summa: number[]) => {
            this.treeViewMessengerService.sendDataToTreeView({
              command: TreeViewCommandType.ChangeSelectRow,
              data: summa
            });
          });

          this.treeViewMessengerService.sendDataToTreeView({
            command: TreeViewCommandType.ChangeQTOTotal,
            data: [
              this.totalsQTO?.totalSum,
              this.totalsQTO?.allTotalSum,
              this.totalsQTO?.serviceSpecificationTotalSum,
              this.totalsQTO?.positionTotalSum,
              this.totalsQTO?.pageTotalSum,
              this.totalsQTO?.positionQuantity
            ]
          });
          this.treeViewMessengerService.sendDataToTreeView({ command: TreeViewCommandType.GetQTO, data: [this.qto] });
          this.treeViewMessengerService.sendDataToTreeView({
            command: TreeViewCommandType.ChangeTreeTotals,
            data: [this.showTotalsInTreeQTO && 1]
          });
        }, 100);
      } else {
        if (this.treeNodeStateFromTree) {
          this.treeNodeStateService.setTreeState(this.treeNodeStateFromTree);
        }
        this.userSettingsService.currentFullSettings
          .pipe(
            take(1),
            map((currentFullSettings) => {
              if (currentFullSettings && currentFullSettings.treeViewDisplayType) {
                return currentFullSettings.treeViewDisplayType;
              } else {
                throw new Error('Received an unexpected value for full settings');
              }
            })
          )
          .subscribe((treeViewDisplayType: TreeViewDisplayType) => {
            this.groupViewService.setGroupView(treeViewDisplayType);
          });
      }
      this.isOpenTreeWindow = isTreeOpen;
    });

    this.userSettingsService.currentUserSettings.pipe(takeUntil(this.$destroy)).subscribe(({ showsTotalsInQuantityTakeOffBelowTree }) => {
      if (this.showTotalsInTreeQTO != showsTotalsInQuantityTakeOffBelowTree) {
        this.showTotalsInTreeQTO = showsTotalsInQuantityTakeOffBelowTree;
        if (this.isOpenTreeWindow) {
          this.treeViewMessengerService.sendDataToTreeView({
            command: TreeViewCommandType.ChangeTreeTotals,
            data: [this.showTotalsInTreeQTO && 1]
          });
        }
      }
    });

    this.selectedQuantityTakeOffMessengerService.selectedQuantityTakeOff
      .pipe(takeUntil(this.$destroy))
      .subscribe((qto: QuantityTakeOffGet) => {
        this.qto = qto;
        if (this.isOpenTreeWindow) {
          this.treeViewMessengerService.sendDataToTreeView({ command: TreeViewCommandType.GetQTO, data: [this.qto] });
        }
      });

    this.quantityTakeOffInvoiceTotalsMessengerService.currentInvoiceTotalSum
      .pipe(takeUntil(this.$destroy))
      .subscribe((totals: TotalInvoiceSumType) => (this.totalsQTO = totals));

    this.selectedSpecificationElementMessengerService.selectedElement.pipe(takeUntil(this.$destroy)).subscribe((e) => {
      if (this.selectedElement?.id !== e?.element?.id) {
        const dataToWindow = [this.projectId, this.avaProject?.id, e?.element?.id, e?.element?.['htmlLongText']];
        this.longTextViewMessengerService.sendDataToLongTextView({ element: e?.element, texts: dataToWindow });
      }
      this.selectedElement = e?.element;
      this.trySendViewMessage();
    });

    this.topMenuButtonsService.clickedButton.pipe(takeUntil(this.$destroy)).subscribe((name) => {
      this.clickedMenuButton(name);
    });

    this.treeDisplayService.isTreeDisplay.pipe(takeUntil(this.$destroy)).subscribe((e) => {
      this.isToggleTreeWidth = e;
    });

    this.selectedQuantityTakeOffMessengerService.selectedQuantityTakeOffPage.pipe(takeUntil(this.$destroy)).subscribe((e) => {
      this.quantityTakeOffPageId = e;
      if (this.modePage.includes('pages')) {
        this.modePage = this.modePage
          .split('.')
          .map((item, i, arr) => (i < arr.length - 1 ? item : this.quantityTakeOffPageId))
          .join('.');
      }
    });

    this.copyCalculationViewMessengerService.selectedPosition.pipe(takeUntil(this.$destroy)).subscribe((e) => {
      if (
        !(this.selectedElement as PositionDto).shortText &&
        !(this.selectedElement as PositionDto).quantity &&
        !(this.selectedElement as PositionDto).unitTag
      ) {
        Object.keys(e.element).forEach((key) => {
          if (key !== 'id' && key !== 'itemNumber') {
            this.selectedElement[key] = e.element[key];
          }
        });
        this.lvEditorService.saveChangedElement(this.selectedElement);
      }
    });
  }

  ngAfterViewInit(): void {
    setAsSplitSize('AS_SPLIT_TREE_RIGHT_SIZE', this.asSplitRightRef);
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
    this.treeViewMessengerService.sendDataToTreeView({ command: TreeViewCommandType.Stop, data: null });
  }

  openDetailWindow(): void {
    this.elementViewMessengerService.showElementViewWindow();
  }

  onDragEnd(sizes: IOutputAreaSizes): void {
    setStorage<number[]>('AS_SPLIT_TREE_RIGHT_SIZE', sizes as number[]);
  }

  openTreeWindow(): void {
    this.treeViewMessengerService.showTreeViewWindow();
    this.treeViewMessengerService.sendDataToTreeView({
      command: TreeViewCommandType.Empty,
      data: null
    });
  }

  showSheet(): void {
    if (this.nodeId) {
      this.router.navigate(['sheets', { position: this.nodeId }], { relativeTo: this.route });
    } else {
      this.router.navigate(['sheets'], { relativeTo: this.route });
    }
  }

  showOtherAdditions(): void {
    if (this.nodeId) {
      this.router.navigate(['scenario', { position: this.nodeId }], { relativeTo: this.route });
    } else {
      this.router.navigate(['scenario'], { relativeTo: this.route });
    }
  }

  showQuantity(): void {
    const prefix = this.isPages ? ['..'] : [];
    if (this.route.component === TreeInvoicePageComponent) {
      prefix.push('..');
    }

    this.router.navigate([...prefix, 'quantity'], {
      relativeTo: this.route
    });
  }

  openLongTextWindow(): void {
    this.longTextViewMessengerService.showLongTextViewWindow();
  }

  showMainPositionTable(): void {
    this.modalService.openModal(MainPositionsTableComponent, { dialogType: ConfirmationType.General, autoFocus: false });
  }

  showSelectingElements(): void {
    this.modalService.openModal(SelectingElementModalComponent, { dialogType: ConfirmationType.General });
  }

  openInquiries(): void {
    this.router.navigate(['..', 'inquiries'], { relativeTo: this.route });
  }

  openEstimations(): void {
    this.router.navigate(['..', 'estimations'], { relativeTo: this.route });
  }

  showDeduction(): void {
    this.modalService.openModal(ServiceSpecificationDeductionHandlingModalComponent, {
      dialogType: ConfirmationType.General,
      data: {
        avaProject: this.avaProject,
        projectId: this.projectId
      }
    });
  }

  openAttachmentsModalWindow(): void {
    this.modalService.openModal(InvoicePdfFileComponent, {
      dialogType: ConfirmationType.General,
      restoreFocus: false,
      autoFocus: false,
      minWidth: '1040px',
      data: {
        isSelectingMode: false
      },
      panelClass: 'invoice-pdf-file'
    });
  }

  openCheckFormulaErrorsModalWindow(): void {
    const errorRequest = this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(
      combineLatestWith(this.selectedQuantityTakeOffMessengerService.selectedQuantityTakeOff),
      filter(([s, i]) => !!s && !!i),
      first(),
      switchMap(([s, i]) => this.quantityTakeOffsClient.checkQuantityTakeOffCalculations(s.parentProjectId, s.avaProjectId, i.id))
    );

    this.checkErrorsAndOpenModalWindowService
      .checkErrorsAndOpenModalWindow(errorRequest, ShowErrorsComponent, {
        dialogType: ConfirmationType.General,
        disableClose: false
      })
      .subscribe();
  }

  clickedMenuButton(name: string): void {
    switch (name) {
      case 'openTreeWindow':
        this.openTreeWindow();
        break;
      case 'showMainPositionTable':
        this.showMainPositionTable();
        break;
      case 'showOtherAdditions':
        this.showOtherAdditions();
        break;
      case 'showDeduction':
        this.showDeduction();
        break;
      case 'openInquiries':
        this.openInquiries();
        break;
      case 'showSheet':
        this.showSheet();
        break;
      case 'showQuantity':
        this.showQuantity();
        break;
      case 'openEstimations':
        this.openEstimations();
        break;
      case 'openAttachmentsModalWindow':
        this.openAttachmentsModalWindow();
        break;
      case 'openCheckFormulaErrorsModalWindow':
        this.openCheckFormulaErrorsModalWindow();
        break;
      case 'editorAutoReorder':
        this.editorAutoReorder();
        break;
      case 'editorChangeNumbers':
        this.editorChangeNumbers();
        break;
      case 'editorChangeNumbersForCurrentGroup':
        this.editorChangeNumbersForCurrentGroup();
        break;
    }
  }

  setStartTree(isExpand?: true): void {
    this.treeViewMessengerService.sendDataToTreeView({
      command: TreeViewCommandType.Start,
      data: [this.modePage, this.groupView, this.projectId, this.serviceSpecificationId, this.nodeId, this.selectedElement],
      treeNodeState: isExpand ? this.treeNodeState : null
    });
  }

  editorAutoReorder(): void {
    this.modalService
      .openModal(ModalConfirmComponent, {
        dialogType: ConfirmationType.General,
        data: ['Ändern', 'Reihenfolge', 'Die Reihenfolge der Elemente wird automatisch angepasst. Fortfahren?'],
        autoFocus: false
      })
      .afterClosed()
      .subscribe((res: boolean) => {
        if (res) {
          this.avaProjectContentEditClient
            .editAvaProjectContent(this.projectId, this.avaProject.id, {
              operation: AvaProjectContentEditOperation.AutoReorderOperation,
              autoReorderOperation: {
                parentContainerId: this.selectedElement?.id
              }
            })
            .subscribe({
              next: () => this.avaNotificationsService.success('Reihenfolge erfolgreich angepasst'),
              error: () => this.avaNotificationsService.error('Fehler beim Ändern der Reihenfolge')
            });
        }
      });
  }

  editorChangeNumbers(): void {
    this.modalService
      .openModal(ModalConfirmComponent, {
        dialogType: ConfirmationType.General,
        data: ['Ändern', 'Ordnungszahlen', 'Die Ordnungszahlen aller Elemente im Projekt werden neu vergeben. Fortfahren?'],
        autoFocus: false
      })
      .afterClosed()
      .subscribe((res: boolean) => {
        if (res) {
          this.avaProjectContentEditClient
            .editAvaProjectContent(this.projectId, this.avaProject.id, {
              operation: AvaProjectContentEditOperation.ItemNumbersRebuildOperation
            })
            .subscribe({
              next: () => this.avaNotificationsService.success('Ordnungszahlen neu vergeben'),
              error: () => this.avaNotificationsService.error('Fehler beim Neuvergeben der Ordnungszahlen')
            });
        }
      });
  }

  editorChangeNumbersForCurrentGroup(): void {
    this.modalService
      .openModal(ModalConfirmComponent, {
        dialogType: ConfirmationType.General,
        data: ['Ändern', 'Ordnungszahlen', 'Die Ordnungszahlen aller Elemente in der ausgewählten Gruppe werden neu vergeben. Fortfahren?'],
        autoFocus: false
      })
      .afterClosed()
      .subscribe((res: boolean) => {
        if (res) {
          this.avaProjectContentEditClient
            .editAvaProjectContent(this.projectId, this.avaProject.id, {
              operation: AvaProjectContentEditOperation.ItemNumbersRebuildOperation,
              itemNumberRebuildOperation: {
                elementId: this.selectedElement.id
              }
            })
            .subscribe({
              next: () => this.avaNotificationsService.success('Ordnungszahlen neu vergeben'),
              error: () => this.avaNotificationsService.error('Fehler beim Neuvergeben der Ordnungszahlen')
            });
        }
      });
  }

  private trySendViewMessage(): void {
    if (this.projectId && this.serviceSpecificationId) {
      const projectCurrency = this.selectedSpecificationMessengerService.getCurrency();
      this.elementViewMessengerService.setSelectedElement({
        element: this.selectedElement,
        options: [this.projectId, this.serviceSpecificationId, this.modePage, projectCurrency]
      });
    }
  }
}
