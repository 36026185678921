import { Injectable } from '@angular/core';
import { AddressGet } from 'app/generated-client/generated-client';
import { HttpClient } from '@angular/common/http';
import { filter, takeUntil } from 'rxjs/operators';
import { SelectedContactMessengerService } from 'app/areas/contacts/services/selected-contact-messenger.service';
import { PaginationMainService } from './main/pagination-main.service';

@Injectable({
  providedIn: 'root'
})
export class AddressesService extends PaginationMainService<AddressGet> {
  constructor(protected http: HttpClient, private selectedContactMessengerService: SelectedContactMessengerService) {
    super(http);
    this.selectedContactMessengerService.selectedContact
      .pipe(
        filter(c => !!c),
        takeUntil(this.$destroy)
      )
      .subscribe(c => {
        this.beginBaseUrl(`/api/contacts/${c.id}/addresses`);
      });
  }

  getAddress(addressId: string): AddressGet | undefined {
    return this.getItemById(addressId);
  }
}
