import { PercentPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';

import { Subject, debounceTime, takeUntil } from 'rxjs';

import { TaxRateGet } from '../../../../generated-client/generated-client';
import { UpperPaginatorComponent } from '../../../../shared/components/upper-paginator/upper-paginator.component';
import { ConfirmationType } from '../../../../shared/models/dialog-config.model';
import { TaxRatesService } from '../../../../shared/services/lightquery/tax-rates.service';
import { ModalService } from '../../../../shared/services/modal.service';
import { FlexLayoutDirective } from '../../../flex-layout/flex-layout.directive';

import { MasterDataTaxRatesAddModalComponent } from '../master-data-tax-rates-add-modal/master-data-tax-rates-add-modal.component';
import { PaginationResult } from 'ng-lightquery';

@Component({
  selector: 'pa-master-data-tax-rates-table',
  templateUrl: './master-data-tax-rates-table.component.html',
  styleUrls: ['./master-data-tax-rates-table.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutDirective,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    UpperPaginatorComponent,
    PercentPipe
  ]
})
export class MasterDataTaxRatesTableComponent implements OnInit, OnDestroy {
  @ViewChild(UpperPaginatorComponent) paginatorComp: UpperPaginatorComponent;
  @ViewChild(MatSort, { static: true }) private sort: MatSort;
  filterText = '';
  displayedColumns: string[] = ['country', 'countryCode', 'rate'];
  taxRatesPaginated: PaginationResult<TaxRateGet>;
  private filterSource = new Subject<boolean>();
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private modalService: ModalService,
    public taxRatesService: TaxRatesService
  ) {}

  ngOnInit(): void {
    this.taxRatesService.initService({
      sortTableObj: this.sort,
      paging: {
        page: 1,
        pageSize: 10
      },
      sorting: {
        isDescending: true,
        propertyName: 'country'
      }
    });
    this.sort.sort({
      id: 'country',
      start: 'asc',
      disableClear: true
    });

    this.taxRatesService.paginationResult.pipe(takeUntil(this.$destroy)).subscribe((pr) => (this.taxRatesPaginated = pr));

    this.filterSource.pipe(takeUntil(this.$destroy), debounceTime(250)).subscribe(() => this.setFilter());
  }

  ngOnDestroy(): void {
    this.setFilter(true);
    this.$destroy.next(true);
    this.$destroy.complete();
    this.filterSource.complete();
  }

  selectItem(row: TaxRateGet): void {
    this.modalService
      .openModal(MasterDataTaxRatesAddModalComponent, { dialogType: ConfirmationType.General, data: row })
      .afterClosed()
      .subscribe((hasEdited) => {
        if (hasEdited) {
          this.taxRatesService.forceRefresh();
        }
      });
  }

  changeFilter(): void {
    this.filterSource.next(true);
  }

  setFilter(isSetNull?: boolean): void {
    this.taxRatesService.setQueryParameter('filter', isSetNull ? null : this.filterText);
  }
}
