import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Subject, fromEvent, takeUntil } from 'rxjs';

@Injectable()
export class KeyboardPositionSelectionService implements OnDestroy {
  private callbacks = new Map<number, (arg?: any) => void>();
  private $destroy: Subject<boolean> = new Subject<boolean>();
  private lastCallbackId = 1;
  constructor(private ngZone: NgZone) {
    ngZone.runOutsideAngular(() => {
      fromEvent(document, 'keydown')
        .pipe(takeUntil(this.$destroy))
        .subscribe((e: KeyboardEvent) => {
          this.handlerKeyDown(e);
        });
    });
  }

  private handlerKeyDown(event: KeyboardEvent): void {
    switch (event.key) {
      case 'F11':
        this.ngZone.run(() => {
          event.preventDefault();
          this.callbacks.forEach(cb => cb(false));
        });
        break;
      case 'F12':
        this.ngZone.run(() => {
          event.preventDefault();
          this.callbacks.forEach(cb => cb(true));
        });
        break;
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  public addCallbackAndReturnId(callBack: (selectNext: boolean) => void): number {
    const callbackId = this.lastCallbackId++;
    this.callbacks.set(callbackId, callBack);
    return callbackId;
  }

  public removeCallback(id: number): void {
    this.callbacks.delete(id);
  }
}
