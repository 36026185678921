<h1>Gruppe</h1>
<ng-container *ngIf="element">
  <h2>{{ element.itemNumber?.stringRepresentation }} {{ element.shortText }}</h2>
  <mat-checkbox *ngIf="!elementViewOpen && currentTotals" [(ngModel)]="includePositionsWithoutTotalPrice"
    >Positionen ohne Gesamtpreis einbeziehen</mat-checkbox
  >
  <ng-container *ngIf="!elementViewOpen && currentTotals">
    <div class="table-wrapper-all">
      <pa-group-totals-table [totalsData]="currentTotals" [isLoaded]="isLoaded"></pa-group-totals-table>
    </div>
  </ng-container>
</ng-container>
