<h1>Verwaltung</h1>
<nav mat-tab-nav-bar [tabPanel]="tabPanel">
  <a mat-tab-link routerLink="files" routerLinkActive #rla1="routerLinkActive" [active]="rla1.isActive"> Dateien </a>
  <a mat-tab-link routerLink="error-reports" routerLinkActive #rla2="routerLinkActive" [active]="rla2.isActive" *ngIf="!isQtoOnlyMode">
    Fehlerberichte
  </a>
  <a mat-tab-link routerLink="companies" routerLinkActive #rla3="routerLinkActive" [active]="rla3.isActive"> Unternehmensdaten </a>
  <a mat-tab-link routerLink="project-restore" routerLinkActive #rla4="routerLinkActive" [active]="rla4.isActive"
    >Projektwiederherstellung</a
  >
</nav>
<router-outlet #tabPanel></router-outlet>
