import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, forwardRef, inject } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { Subject, takeUntil } from 'rxjs';

import { IFilterType } from 'app/areas/bim-view/interface/filter-type.interface';

import { IfcElementTypePipe } from '../../../../pipes/ifc-element-type.pipe';

@Component({
  selector: 'pa-filter-selection',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule, IfcElementTypePipe],
  templateUrl: './filter-selection.component.html',
  styleUrls: ['./filter-selection.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterSelectionComponent),
      multi: true
    }
  ]
})
export class FilterSelectionComponent implements ControlValueAccessor, OnDestroy {
  filterSelectionControl = new FormControl<string | null>(null);
  private $destroy: Subject<boolean> = new Subject<boolean>();

  @Input() label = 'Bauteiltyp';

  @Input() set listOfFilteringElements(value: IFilterType[]) {
    this._listOfFilteringElements = value;
  }

  get listOfFilteringElements(): IFilterType[] {
    return this._listOfFilteringElements;
  }

  private _listOfFilteringElements: IFilterType[];

  private cdr = inject(ChangeDetectorRef);

  private onChange: any = () => {};
  private onTouched: any = () => {};

  constructor() {
    this.filterSelectionControl.valueChanges.pipe(takeUntil(this.$destroy)).subscribe((v) => {
      this.onChange(v);
      this.onTouched();
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.filterSelectionControl.setValue(value);
    this.cdr.markForCheck();
  }

  setDisabledState(isDisabled: boolean): void {
    // Implement later if it need
  }
}
