import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject, take, takeUntil } from 'rxjs';

import { MasterDataReloadGroupsService } from '@master/services/master-data-reload-groups.service';

import { MasterAdditionGroupGet, MasterAdditionGroupsClient, MedianHourlyWageCalculation } from 'app/generated-client/generated-client';
import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { ModalService } from 'app/shared/services/modal.service';

import { FlexLayoutDirective } from '../../../flex-layout/flex-layout.directive';
import { CalculationMiddleWagesComponent } from '../../../project-id/components/service-specifications/components/calculation-middle-wages/calculation-middle-wages.component';
import { CalculateTransferWageService } from '../../../project-id/components/service-specifications/services/calculate-transfer-wage.service';
import { SaveAdditionGroupService } from '../../services/save-addition-group.service';

import { MasterAdditionGroupComponent } from '../master-addition-group/master-addition-group.component';

@Component({
  selector: 'pa-master-addition-group-detail',
  templateUrl: './master-addition-group-detail.component.html',
  styleUrls: ['./master-addition-group-detail.component.scss'],
  standalone: true,
  imports: [FlexLayoutDirective, MasterAdditionGroupComponent, NgIf, CalculationMiddleWagesComponent, MatButton]
})
export class MasterAdditionGroupDetailComponent implements OnInit, OnDestroy {
  private $destroy: Subject<boolean> = new Subject<boolean>();

  selectedGroup: MasterAdditionGroupGet;
  additionId: string;
  isEditing: boolean;
  isLoading: boolean;
  isFirstCalculationChange = true;

  saveAdditionGroupService = inject(SaveAdditionGroupService);
  notificationsService = inject(AvaNotificationsService);
  calculateTransferWageService = inject(CalculateTransferWageService);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private masterDataReloadGroupsService: MasterDataReloadGroupsService,
    private modalService: ModalService,
    private masterAdditionGroupsClient: MasterAdditionGroupsClient,
    private avaNotificationsService: AvaNotificationsService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.additionId = this.route.snapshot.paramMap.get('additionId');
    if (this.additionId) {
      this.masterAdditionGroupsClient
        .getMasterAdditionGroupById(this.additionId)
        .pipe(takeUntil(this.$destroy))
        .subscribe((additionGroup) => {
          this.selectedGroup = { ...additionGroup };
          this.checkMaserPriceComponent();
          this.recalculateTransferWage();
        });
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  showConfirmDeletionModal(): void {
    this.modalService
      .openModal(ModalConfirmComponent, {
        dialogType: ConfirmationType.Delete,
        data: ['Löschen', 'Gruppe', `Gruppe addition: ${this.selectedGroup.name}`, 'red']
      })
      .afterClosed()
      .subscribe((e: boolean) => {
        if (e) {
          this.masterAdditionGroupsClient.deleteMasterAdditionGroup(this.selectedGroup.id).subscribe({
            next: () => {
              this.masterDataReloadGroupsService.reload();
              this.avaNotificationsService.success('Zuschlagsgruppe gelöscht.');
              this.allGroups();
            },
            error: () => this.avaNotificationsService.error('Fehler beim Löschen.')
          });
        }
      });
  }

  dismiss(): void {
    if (this.isEditing) {
      this.isEditing = false;
      this.selectedGroup = null;
      this.masterAdditionGroupsClient
        .getMasterAdditionGroupById(this.additionId)
        .pipe(take(1))
        .subscribe((additionGroup) => {
          this.selectedGroup = { ...additionGroup };
          this.recalculateTransferWage();
          this.isFirstCalculationChange = true;
        });
    } else {
      this.allGroups();
    }
  }

  save(): void {
    this.isLoading = true;
    this.checkMaserPriceComponent();
    this.saveAdditionGroupService.saveAdditionGroup(true);
    this.masterAdditionGroupsClient.editMasterAdditionGroup(this.selectedGroup.id, this.selectedGroup).subscribe({
      next: () => {
        this.isLoading = false;
        this.isEditing = false;
        this.recalculateTransferWage();
        this.notificationsService.success('Die Zuschlagsgruppe wurde gespeichert.');
      },
      error: (error) => {
        this.isLoading = false;
        console.warn(error);
        this.notificationsService.error('Die Zuschlagsgruppe konnte nicht gespeichert werden.');
      }
    });
  }

  allGroups(): void {
    this.router.navigate(['../..'], { relativeTo: this.route });
  }

  onCalculationChange(medianHourlyWageCalculation: MedianHourlyWageCalculation): void {
    this.selectedGroup.calculationWage = medianHourlyWageCalculation.calculationWage;
    if (!this.isFirstCalculationChange) {
      this.isEditing = true;
    }
    this.recalculateTransferWage();
    this.isFirstCalculationChange = false;
  }

  recalculateTransferWage(): void {
    if (this.selectedGroup) {
      this.calculateTransferWageService.calculateTransferWage(this.selectedGroup);
    }
  }

  checkMaserPriceComponent(): void {
    if (this.selectedGroup && !this.selectedGroup.masterPriceComponentAdditions.length) {
      this.notificationsService.warning(
        'Damit die Gruppe benutzt werden kann, muss mindestens eine Preiskomponente definiert werden.',
        '',
        10000
      );
    }
  }
}
