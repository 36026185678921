<h2 mat-dialog-title class="title">
  <ng-container *ngIf="isCalculation"> Kontextmenu für Kalkulationstabelle </ng-container>
</h2>
<div mat-dialog-content fxLayout="column">
  <ng-container *ngIf="isCalculation">
    <mat-checkbox color="primary" [(ngModel)]="currentContextListMenu.saveOneRow" (ngModelChange)="isChanged = true"
      >Zeile Kopieren</mat-checkbox
    >
    <mat-checkbox color="primary" [(ngModel)]="currentContextListMenu.removeRow" (ngModelChange)="isChanged = true">Löschen</mat-checkbox>
    <mat-checkbox color="primary" [(ngModel)]="currentContextListMenu.cutRow" (ngModelChange)="isChanged = true"
      >Zeile ausschneiden</mat-checkbox
    >
    <mat-checkbox color="primary" [(ngModel)]="currentContextListMenu.copyCalculation" (ngModelChange)="isChanged = true"
      >Kalkulation Kopieren</mat-checkbox
    >
    <mat-checkbox color="primary" [(ngModel)]="currentContextListMenu.addRowBefore" (ngModelChange)="isChanged = true"
      >Zeile davor hinzufügen</mat-checkbox
    >
    <mat-checkbox color="primary" [(ngModel)]="currentContextListMenu.addRowAfter" (ngModelChange)="isChanged = true"
      >Zeile danach hinzufügen</mat-checkbox
    >
    <mat-checkbox color="primary" [(ngModel)]="currentContextListMenu.addMaterials" (ngModelChange)="isChanged = true"
      >Artikel</mat-checkbox
    >
    <mat-checkbox color="primary" [(ngModel)]="currentContextListMenu.addEquipment" (ngModelChange)="isChanged = true">Gerät</mat-checkbox>
    <mat-checkbox color="primary" [(ngModel)]="currentContextListMenu.addCasing" (ngModelChange)="isChanged = true">Schalung</mat-checkbox>
    <mat-checkbox color="primary" [(ngModel)]="currentContextListMenu.saveRows" (ngModelChange)="isChanged = true"
      >Zeilen kopieren</mat-checkbox
    >
    <mat-checkbox color="primary" [(ngModel)]="currentContextListMenu.addSavedRow" (ngModelChange)="isChanged = true"
      >Zeilen einfügen</mat-checkbox
    >
  </ng-container>
</div>
<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close color="warn">Abbrechen</button>
  <button mat-raised-button color="primary" type="button" (click)="save()" [disabled]="!isChanged">Speichern</button>
</mat-dialog-actions>
