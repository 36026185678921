<as-split
  fxFlexFill
  #asSplitVerticalRef
  (dragEnd)="onDragEnd($event.sizes)"
  (mouseenter)="showSeparatedWindows()"
  (mouseleave)="tryLeave()"
  class="multi-area"
>
  <as-split-area [minSize]="3" *ngFor="let nameWindow of listWindow">
    <pa-one-view-window
      [nameWindow]="$any(nameWindow)"
      (removeWindow)="removeWindow($event)"
      (separateWindow)="separateWindow($event)"
      (selectWindow)="selectWindow()"
    ></pa-one-view-window>
  </as-split-area>
</as-split>
