import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { IsAuthenticatedGuard } from '../../shared/guards/is-authenticated.guard';

import { CompanyInfoComponent } from './components/company-info/company-info.component';
import { CompanyListComponent } from './components/company-list/company-list.component';
import { ErrorReportsComponent } from './components/error-reports/error-reports.component';
import { FilesComponent } from './components/files/files.component';
import { ManagementComponent } from './components/management/management.component';
import { ProjectRestoreComponent } from './components/project-restore/project-restore.component';

const routes: Routes = [
  {
    path: 'management',
    component: ManagementComponent,
    canActivate: [IsAuthenticatedGuard],
    children: [
      {
        path: 'companies',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: CompanyListComponent
          },
          {
            path: 'company-info/:id',
            component: CompanyInfoComponent
          }
        ]
      },
      {
        path: 'error-reports',
        component: ErrorReportsComponent
      },
      {
        path: 'project-restore',
        component: ProjectRestoreComponent
      },
      {
        path: 'files',
        component: FilesComponent
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'files'
      }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManagementRoutingModule {}
