import { Component, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute } from '@angular/router';

import { Subject, takeUntil } from 'rxjs';

import { KindInvoiceCodeType } from '@shared/models';

import { InvoicePeriodCodesListComponent } from '@project/components/project-management/components/invoice-period-codes-list/invoice-period-codes-list.component';
import { InvoiceRecipientCodesListComponent } from '@project/components/project-management/components/invoice-recipient-codes-list/invoice-recipient-codes-list.component';

import { BuildingElementCodesListComponent } from '../invoice/components/building-element-codes-list/building-element-codes-list.component';
import { ServicePeriodCodesListComponent } from '../invoice/components/service-period-codes-list/service-period-codes-list.component';

@Component({
  selector: 'pa-invoice-code-values',
  templateUrl: './invoice-code-values.component.html',
  styleUrl: './invoice-code-values.component.scss',
  standalone: true,
  imports: [
    InvoiceRecipientCodesListComponent,
    InvoicePeriodCodesListComponent,
    BuildingElementCodesListComponent,
    ServicePeriodCodesListComponent,
    MatButtonModule
  ]
})
export class InvoiceCodeValuesComponent {
  @ViewChild('list') cmp: { editItem: () => void };
  typeCodeTable: KindInvoiceCodeType;
  typesTable = KindInvoiceCodeType;

  private $destroy = new Subject<boolean>();

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.pipe(takeUntil(this.$destroy)).subscribe((data) => {
      this.typeCodeTable = data.typeTable;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  addItem(): void {
    this.cmp.editItem();
  }
}
