import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { first } from 'rxjs';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';
import { getParentNode } from 'app/areas/tree/utils/fn';
import { AvaProjectContentEditOperation, IElementDto } from 'app/generated-client/generated-client';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

interface IPlace {
  value: string;
  label: string;
}

@Component({
  selector: 'pa-copy-element-modal-window',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatFormFieldModule, MatSelectModule, FormsModule, MatButtonModule, FlexLayoutDirective],
  templateUrl: './copy-element-modal-window.component.html',
  styleUrls: ['./copy-element-modal-window.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyElementModalWindowComponent implements OnInit {
  places: IPlace[] = [];
  selectedPlace: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      currnetElement: IElementDto;
      elementForCopy: IElementDto;
    },
    @Optional() private matDialogRef: MatDialogRef<CopyElementModalWindowComponent>,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService
  ) {}

  ngOnInit(): void {
    if (this.data && this.data.currnetElement && this.data.currnetElement.elementType === 'ServiceSpecificationGroupDto') {
      this.places = [
        { value: 'before', label: 'Vor' },
        { value: 'after', label: 'Nach' },
        { value: 'intoGroup', label: 'In Gruppe' }
      ];
    } else if (this.data && this.data.currnetElement && this.data.currnetElement.elementType) {
      this.places = [
        { value: 'before', label: 'Vor' },
        { value: 'after', label: 'Nach' }
      ];
    } else {
      this.places = [{ value: 'intoGroup', label: 'In Gruppe' }];
      this.selectedPlace = 'intoGroup';
    }
  }

  confirmSelectedLocation(): void {
    this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(first()).subscribe((s) => {
      const parentOfElement = getParentNode(this.data.currnetElement.id, s?.project.serviceSpecifications[0]);
      const insertOperation: any = {
        operation: AvaProjectContentEditOperation.Insert,
        insertOperation: {
          newElement: this.data.elementForCopy,
          parentId:
            parentOfElement && parentOfElement.id === s?.project.serviceSpecifications[0].id
              ? null
              : this.data.currnetElement.id === s?.project.serviceSpecifications[0].id
              ? null
              : parentOfElement.id,
          previousId: null,
          nextId: null,
          autoGenerateItemNumber: true
        }
      };
      switch (this.selectedPlace) {
        case 'before':
          insertOperation.insertOperation.nextId = this.data.currnetElement.id;
          break;
        case 'after':
          insertOperation.insertOperation.previousId = this.data.currnetElement.id;
          break;
        case 'intoGroup':
          insertOperation.insertOperation.parentId =
            this.data.currnetElement.id === s?.project.serviceSpecifications[0].id ? null : this.data.currnetElement.id;
          break;
      }
      this.matDialogRef.close(insertOperation);
    });
  }
}
