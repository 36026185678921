import { Pipe, PipeTransform } from '@angular/core';

import { OriginDto } from '../../../generated-client/generated-client';

@Pipe({
  name: 'originDto',
  standalone: true
})
export class OriginDtoPipe implements PipeTransform {
  transform(value: OriginDto): string {
    switch (value) {
      case OriginDto.Gaeb2000:
        return 'GAEB 2000';

      case OriginDto.Gaeb90:
        return 'GAEB 90';

      case OriginDto.GaebXml:
        return 'GAEB XML';

      case OriginDto.Oenorm:
        return 'ÖNorm';

      case OriginDto.Reb:
        return 'REB';

      case OriginDto.Self:
        return 'Systemformat';

      case OriginDto.Sia:
        return 'SIA 451 (Schweiz)';
    }
    return null;
  }
}
