<div>
  <mat-form-field class="full-width">
    <mat-label>Suche</mat-label>
    <input matInput [ngModel]="filter" (ngModelChange)="servicePeriodCodesService.onFilter($event)" />
  </mat-form-field>
</div>
<table mat-table [dataSource]="servicePeriodCodesService" matSort (matSortChange)="servicePeriodCodesService.onSort($event)">
  <ng-container matColumnDef="name">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let row">{{ row.name }}</td>
  </ng-container>
  <ng-container matColumnDef="description">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Beschreibung</th>
    <td mat-cell *matCellDef="let row">{{ row.description }}</td>
  </ng-container>
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef class="actions"></th>
    <td mat-cell *matCellDef="let row" class="actions">
      <button mat-icon-button color="primary" type="button" (click)="editItem(row)">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button color="warn" type="button" (click)="deleteItem(row)">
        <mat-icon color="warn">close</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="showedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: showedColumns"></tr>
</table>
<pa-upper-paginator
  [pageSizeOptions]="[5, 10, 25, 100]"
  [upperPaginated]="servicePeriodCodesService.paginationResult | async"
  [upperService]="servicePeriodCodesService"
  (page)="servicePeriodCodesService.onPage($event)"
>
</pa-upper-paginator>
