import { Injectable, NgZone } from '@angular/core';

import { AuthenticationMessenger, JwtTokenService } from '@dangl/angular-dangl-identity-client';

import { ReplaySubject, Subject, combineLatest } from 'rxjs';
import { filter, first, map, take } from 'rxjs/operators';

import { ModePageService } from 'app/areas/tree/services/mode-page.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedQuantityTakeOffMessengerService } from 'app/shared/services/messengers/selected-quantity-take-off-messenger.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

import { getAppConfig } from '../../../app-config-accessor';
import {
  AvaProjectDiff,
  AvaProjectGet,
  EnterPositionCalculationResult,
  EnterQuantityTakeOffResult,
  IElementDto,
  LastCalculationVisitResult,
  LastQuantityTakeOffVisitResult,
  ProjectDto,
  ProjectGet,
  ProjectUsers,
  QuantityTakeOffGet,
  QuantityTakeOffType,
  UserKickResult
} from '../../../generated-client/generated-client';

import { AvaProjectDiffApplier } from './ava-project-diff-applier';

import { ProjectQuantityEstimationService } from '../messengers/project-quantity-estimation.service';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';

@Injectable({
  providedIn: 'root'
})
export class AvaHubConnector {
  private connection: HubConnection;
  private messageQueue: { methodName: string; args?: any[] }[] = [];
  private messageQueueWithResults: { methodName: string; args?: any[]; identifier: number }[] = [];
  private lastIdentifierForQueue = 0;
  private messageQueueWithResultsSource = new Subject<{ identifier: number; result: any }>();

  private currentProjectUsersSource = new ReplaySubject<ProjectUsers>(1);
  currentProjectUsers = this.currentProjectUsersSource.asObservable();

  private userKickedSource = new ReplaySubject<UserKickResult>(1);
  userKicked = this.userKickedSource.asObservable();

  private avaProjectDiffSource = new Subject<AvaProjectDiff>();
  avaProjectDiff = this.avaProjectDiffSource.asObservable();

  constructor(
    private authenticationMessenger: AuthenticationMessenger,
    private jwtTokenService: JwtTokenService,
    private avaProjectDiffApplier: AvaProjectDiffApplier,
    private ngZone: NgZone,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private modePageService: ModePageService,
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private selectedQuantityTakeOffMessengerService: SelectedQuantityTakeOffMessengerService,
    private projectQuantityEstimationService: ProjectQuantityEstimationService
  ) {
    this.buildConnection();
    this.setUpMessageListeners();
    this.listenToUserAuthentication();

    this.connection.onreconnected(() => {
      this.modePageService.modePage.pipe(first()).subscribe((modePage) => {
        if (modePage === 'calculation') {
          this.ensureCalculationModeReconnected();
        } else if (modePage.includes('pages.')) {
          this.ensureInvoicePagesModeReconnected(modePage);
        } else if (modePage.includes('positions')) {
          this.ensureInvoicePositionModeReconnected(modePage);
        } else {
          this.selectedProjectMessengerService.selectedProject.pipe(first()).subscribe((selectedProject) => {
            if (selectedProject) {
              this.notifyOfEnteringProject(selectedProject.id);
            }
          });
        }

        this.sendQueuedMessages();
      });
    });
  }

  notifyOfEnteringProject(projectId: string): void {
    this.sendMessageToServer('EnterProjectAsync', projectId);
  }

  notifyOfExitingProject(projectId: string): void {
    this.sendMessageToServer('ExitProjectAsync', projectId);
  }

  getLastVisitedCalculationAsync(avaProjectId: string): Promise<LastCalculationVisitResult> {
    return this.sendMessageToServerAndGetResponse<LastCalculationVisitResult>('GetLastVisitedCalculationAsync', avaProjectId);
  }

  getLastVisitedQuantityTakeOffAsync(
    avaProjectId: string,
    quantityTakeOffType: QuantityTakeOffType
  ): Promise<LastQuantityTakeOffVisitResult> {
    return this.sendMessageToServerAndGetResponse<LastQuantityTakeOffVisitResult>(
      'GetLastVisitedQuantityTakeOffAsync',
      avaProjectId,
      quantityTakeOffType
    );
  }

  tryEnterPositionCalculation(projectId: string, avaProjectId: string, positionId: string): Promise<EnterPositionCalculationResult> {
    return this.sendMessageToServerAndGetResponse<EnterPositionCalculationResult>(
      'EnterPositionCalculationAsync',
      projectId,
      avaProjectId,
      positionId
    );
  }

  notifyOfExitingCalculation(projectId: string, avaProjectId: string, positionId: string): void {
    this.sendMessageToServer('ExitPositionCalculationAsync', projectId, avaProjectId, positionId);
  }

  tryEnterQuantityTakeOffPage(
    projectId: string,
    avaProjectId: string,
    quantityTakeOffId: string,
    pageId: string
  ): Promise<EnterQuantityTakeOffResult> {
    return this.sendMessageToServerAndGetResponse<EnterQuantityTakeOffResult>(
      'EnterQuantityTakeOffPageAsync',
      projectId,
      avaProjectId,
      quantityTakeOffId,
      pageId
    );
  }

  tryEnterQuantityTakeOffPosition(
    projectId: string,
    avaProjectId: string,
    quantityTakeOffId: string,
    positionId: string
  ): Promise<EnterQuantityTakeOffResult> {
    return this.sendMessageToServerAndGetResponse<EnterQuantityTakeOffResult>(
      'EnterQuantityTakeOffPositionAsync',
      projectId,
      avaProjectId,
      quantityTakeOffId,
      positionId
    );
  }

  tryEnterQuantityTakeOffPositionQtoWithoutPositionAsync(
    projectId: string,
    avaProjectId: string,
    quantityTakeOffId: string
  ): Promise<EnterQuantityTakeOffResult> {
    return this.sendMessageToServerAndGetResponse<EnterQuantityTakeOffResult>(
      'EnterQuantityTakeOffPositionQtoWithoutPositionAsync',
      projectId,
      avaProjectId,
      quantityTakeOffId
    );
  }

  notifyOfExitingQuantityTakeOffPosition(projectId: string, avaProjectId: string, quantityTakeOffId: string, positionId: string): void {
    this.sendMessageToServer('ExitQuantityTakeOffPositionAsync', projectId, avaProjectId, quantityTakeOffId, positionId);
  }

  notifyOfExitingQuantityTakeOffPositionQtoWithoutPosition(projectId: string, avaProjectId: string, quantityTakeOffId: string): void {
    this.sendMessageToServer('ExitQuantityTakeOffPositionQtoWithoutPositionAsync', projectId, avaProjectId, quantityTakeOffId);
  }

  notifyOfExitingQuantityTakeOffPage(projectId: string, avaProjectId: string, quantityTakeOffId: string, pageId: string): void {
    this.sendMessageToServer('ExitQuantityTakeOffPageAsync', projectId, avaProjectId, quantityTakeOffId, pageId);
  }

  getCurrentProjectUsers(projectId: string): Promise<ProjectUsers> {
    return this.sendMessageToServerAndGetResponse<ProjectUsers>('GetProjectUsers', projectId);
  }

  tryKickProjectUser(projectId: string, userId: string): Promise<UserKickResult> {
    return this.sendMessageToServerAndGetResponse<UserKickResult>('KickUserFromBlockingSectionAsync', projectId, userId);
  }

  private sendQueuedMessages(): void {
    while (this.messageQueue.length > 0) {
      const message = this.messageQueue.splice(0, 1)[0];
      this.sendMessageToServer(message.methodName, ...message.args);
    }
    while (this.messageQueueWithResults.length > 0) {
      const message = this.messageQueueWithResults.splice(0, 1)[0];
      this.sendMessageToServerAndGetResponse<any>(message.methodName, ...message.args).then((r) => {
        this.messageQueueWithResultsSource.next({
          identifier: message.identifier,
          result: r
        });
      });
    }
  }

  private async sendMessageToServer(methodName: string, ...args: any[]): Promise<void> {
    if (this.connection.state === HubConnectionState.Connected) {
      await this.connection.send(methodName, ...args);
    } else {
      this.messageQueue.push({ methodName, args });
    }
  }

  private async sendMessageToServerAndGetResponse<T>(methodName: string, ...args: any[]): Promise<T> {
    if (this.connection.state === HubConnectionState.Connected) {
      const response = await this.connection.invoke<T>(methodName, ...args);
      return response;
    } else {
      const messageIdentifier = ++this.lastIdentifierForQueue;
      this.messageQueueWithResults.push({
        methodName: methodName,
        args: args,
        identifier: messageIdentifier
      });

      return this.messageQueueWithResultsSource
        .pipe(
          filter((r) => r.identifier === messageIdentifier),
          take(1),
          map((r) => r.result)
        )
        .toPromise();
    }
  }

  private setUpMessageListeners(): void {
    // This informs about what users are working in the current project
    this.connection.on('ProjectUsers', (projectUsers: ProjectUsers) =>
      this.ngZone.run(() => this.currentProjectUsersSource.next(projectUsers))
    );

    // This is informing about an user being kicked from a blocking project section
    this.connection.on('UserKicked', (kickResult: UserKickResult) => {
      this.userKickedSource.next(kickResult);
    });

    this.connection.on('AvaProjectUpdate', (projectId: string, avaProjectId: string, avaProjectDiff: AvaProjectDiff) => {
      this.avaProjectDiffSource.next(avaProjectDiff);
      this.ngZone.run(() => this.avaProjectDiffApplier.tryApplyAvaProjectDiff(projectId, avaProjectId, avaProjectDiff));
    });

    this.connection.on('AssumedQuantitiesUpdate', (projectId: string, avaProjectId: string) => {
      this.projectQuantityEstimationService.refreshAssumedQuantities();
    });
  }

  private buildConnection(): void {
    this.connection = new HubConnectionBuilder()
      .withAutomaticReconnect()
      .withUrl(getAppConfig().backendUrl + '/hubs/ava', {
        accessTokenFactory: () =>
          this.jwtTokenService
            .getToken()
            .pipe(map((at) => at.accessToken))
            .toPromise()
      })
      .build();
  }

  private listenToUserAuthentication(): void {
    this.authenticationMessenger.isAuthenticated.subscribe((userIsAuthenticated) => {
      if (
        userIsAuthenticated &&
        this.connection.state !== HubConnectionState.Connected &&
        this.connection.state !== HubConnectionState.Connecting &&
        this.connection.state !== HubConnectionState.Reconnecting
      ) {
        this.startConnection();
      } else if (!userIsAuthenticated && this.connection.state === HubConnectionState.Connected) {
        if (this.connection.state === HubConnectionState.Connected) {
          this.connection.stop();
        }
      }
    });
  }

  private startConnection(): void {
    if (this.connection.state !== HubConnectionState.Connected) {
      this.ngZone.runOutsideAngular(() => {
        this.connection.start().then(() => {
          this.sendQueuedMessages();
        });
      });
    }
  }

  private ensureCalculationModeReconnected(): void {
    combineLatest([
      this.selectedProjectMessengerService.selectedProject,
      this.selectedSpecificationMessengerService.selectedServiceSpecification,
      this.selectedSpecificationElementMessengerService.selectedElement
    ])
      .pipe(first())
      .subscribe(
        ([project, s, e]: [
          ProjectGet,
          { avaProjectId: string; project: ProjectDto; avaProject: AvaProjectGet },
          { id: string; element: IElementDto }
        ]) => {
          if (project && s) {
            this.notifyOfEnteringProject(project.id);
            this.getCurrentProjectUsers(project.id);
            if (e) {
              this.tryEnterPositionCalculation(project.id, s.avaProjectId, e.id);
            }
          }
        }
      );
  }

  private ensureInvoicePositionModeReconnected(modePage: string): void {
    combineLatest([
      this.selectedProjectMessengerService.selectedProject,
      this.selectedSpecificationMessengerService.selectedServiceSpecification,
      this.selectedQuantityTakeOffMessengerService.selectedQuantityTakeOff,
      this.selectedSpecificationElementMessengerService.selectedElement
    ])
      .pipe(first())
      .subscribe(
        ([project, s, qto, e]: [
          ProjectGet,
          { avaProjectId: string; project: ProjectDto; avaProject: AvaProjectGet },
          QuantityTakeOffGet,
          { id: string; element: IElementDto }
        ]) => {
          if (project && s) {
            this.notifyOfEnteringProject(project.id);
            this.getCurrentProjectUsers(project.id);
            if (e) {
              this.tryEnterQuantityTakeOffPosition(project.id, s.avaProjectId, qto.id, e?.id);
            }
          }
        }
      );
  }
  private ensureInvoicePagesModeReconnected(modePage: string): void {
    combineLatest([
      this.selectedProjectMessengerService.selectedProject,
      this.selectedSpecificationMessengerService.selectedServiceSpecification,
      this.selectedQuantityTakeOffMessengerService.selectedQuantityTakeOff
    ])
      .pipe(first())
      .subscribe(
        ([project, s, qto]: [ProjectGet, { avaProjectId: string; project: ProjectDto; avaProject: AvaProjectGet }, QuantityTakeOffGet]) => {
          if (project && s) {
            this.notifyOfEnteringProject(project.id);
            this.getCurrentProjectUsers(project.id);
            const pageId = modePage.split('.').pop();
            if (pageId) {
              this.tryEnterQuantityTakeOffPage(project.id, s.avaProjectId, qto.id, pageId);
            }
          }
        }
      );
  }
}
