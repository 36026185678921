import { Injectable, OnDestroy } from '@angular/core';
import { EMPTY, Observable, ReplaySubject, Subject, combineLatest, first, switchMap, of } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';

import {
  AvaProjectContentEditClient,
  AvaProjectContentEditPut,
  AvaProjectContentEditResultGet,
  IElementDto
} from './../../../generated-client/generated-client';
import { SelectedSpecificationElementMessengerService } from './../../../shared/services/messengers/selected-specification-element-messenger.service';
import { CopyElementModalWindowComponent } from './../../copy-element-view/copy-element-modal-window/copy-element-modal-window.component';
import { ServiceSpecificationsHasChangeService } from 'app/shared/services/messengers/service-specifications-has-change.service';

@Injectable({
  providedIn: 'root'
})
export class TreeCopyElementService implements OnDestroy {
  private destroy$ = new Subject<boolean>();

  private treeElementForCopySource = new ReplaySubject<IElementDto>(1);
  treeElementForCopy = this.treeElementForCopySource.asObservable();

  selectedElement: IElementDto;

  constructor(
    private avaProjectContentEditClient: AvaProjectContentEditClient,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private modalService: ModalService,
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private serviceSpecificationsHasChangeService: ServiceSpecificationsHasChangeService
  ) {
    this.selectedSpecificationElementMessengerService.selectedElement
      .pipe(takeUntil(this.destroy$))
      .subscribe(e => (this.selectedElement = e ? e.element : null));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  setTreeElementForCopy(element: IElementDto): void {
    this.treeElementForCopySource.next(element);
  }

  insertTreeElementCopy(node: IElementDto, elementForCopy: IElementDto): Observable<AvaProjectContentEditResultGet> {
    const currnetElement = this.selectedElement ?? node;
    return this.modalService
      .openModal(CopyElementModalWindowComponent, {
        dialogType: ConfirmationType.General,
        data: {
          currnetElement: currnetElement,
          elementForCopy: elementForCopy
        },
        disableClose: true
      })
      .afterClosed()
      .pipe(
        filter(r => !!r),
        switchMap(value => {
          return this.serviceSpecificationsHasChangeService.checkServiceSpecificationHasBeenEdited().pipe(
            filter(r => !!r),
            switchMap(() => of(value))
          );
        }),
        switchMap((insertOperation: AvaProjectContentEditPut) => {
          return combineLatest([
            this.selectedProjectMessengerService.selectedProject,
            this.selectedSpecificationMessengerService.selectedServiceSpecification
          ]).pipe(
            first(),
            switchMap(([p, s]) => this.avaProjectContentEditClient.editAvaProjectContent(p.id, s.avaProjectId, insertOperation))
          );
        })
      );
  }
}
