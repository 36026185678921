import { Pipe, PipeTransform } from '@angular/core';

import { QuantityTakeOffCalculationType } from '../../../generated-client/generated-client';

@Pipe({
  name: 'quantityTakeOffCalculationType',
  standalone: true
})
export class QuantityTakeOffCalculationTypePipe implements PipeTransform {
  transform(value: QuantityTakeOffCalculationType): string {
    switch (value) {
      case QuantityTakeOffCalculationType.ByPage:
        return 'Arbeitsblattbasiert';

      case QuantityTakeOffCalculationType.ByPosition:
        return 'Positionsbasiert';
    }

    return null;
  }
}
