import { NgIf } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose
} from '@angular/material/dialog';

import { Subject, takeUntil } from 'rxjs';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';
import { ContextListMenuSettingType, ContextMenuSettingType } from 'app/shared/models';

import { ContextMenuSettingService } from '../../services/context-menu-setting.service';

@Component({
  selector: 'pa-context-menu-setting',
  templateUrl: './context-menu-setting.component.html',
  styleUrls: ['./context-menu-setting.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutDirective,
    MatDialogTitle,
    NgIf,
    MatDialogContent,
    MatCheckbox,
    FormsModule,
    MatDialogActions,
    MatButton,
    MatDialogClose
  ]
})
export class ContextMenuSettingComponent implements OnInit {
  isCalculation: boolean;
  isChanged: boolean;
  currentContextListMenu: ContextListMenuSettingType;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ContextMenuSettingType,
    @Optional() private matDialogRef: MatDialogRef<ContextMenuSettingComponent>,
    private contextMenuSettingService: ContextMenuSettingService
  ) {}

  ngOnInit(): void {
    let service;
    switch (this.data) {
      case ContextMenuSettingType.CalculationTable:
        this.isCalculation = true;
        service = this.contextMenuSettingService.listCalculationMenu;
        break;
    }
    if (service) {
      service.pipe(takeUntil(this.$destroy)).subscribe((list: ContextListMenuSettingType) => (this.currentContextListMenu = { ...list }));
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  closeModal(): void {
    this.matDialogRef.close();
  }

  save(): void {
    if (this.isCalculation) {
      this.contextMenuSettingService.setListCalculationMenu(this.currentContextListMenu);
    }
    this.closeModal();
  }
}
