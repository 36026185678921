<div mat-dialog-content>
  <h2 *ngIf="!isEdit">Neue Elementauswahl</h2>
  <h2 *ngIf="isEdit">Elementauswahl Bearbeiten</h2>
  <div class="tree">
    <pa-main-tree [isSelectingMode]="true" [selectedElements]="selectedElements"></pa-main-tree>
  </div>
  <form [formGroup]="formSelection" (ngSubmit)="createSelection()" class="form-wrap">
    <div fxLayout="column">
      <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input type="text" matInput formControlName="name" />
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="12px" class="buttons">
      <button mat-raised-button color="warn" type="button" mat-dialog-close>Abbrechen</button>
      <button mat-raised-button color="primary" type="submit" [disabled]="formSelection.invalid">Speichern</button>
    </div>
  </form>
</div>
