import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

type LongTextEditType = { caption?: string; body: string; tail?: string };

@Component({
  selector: 'pa-long-text-edit-modal',
  templateUrl: './long-text-edit-modal.component.html',
  styleUrls: ['./long-text-edit-modal.component.scss'],
  standalone: true,
  imports: [NgIf, MatFormField, MatLabel, MatInput, CdkTextareaAutosize, FormsModule, MatDialogActions, MatButton, MatDialogClose]
})
export class LongTextEditModalComponent implements OnInit {
  data: LongTextEditType;
  isChanged: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) private propEdit: LongTextEditType) {}

  ngOnInit(): void {
    this.data = { ...this.propEdit, body: '' };
  }
}
