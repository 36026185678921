<h1 mat-dialog-title>Preis Manuell Setzen</h1>
<div mat-dialog-content>
  <h3>{{ data.position?.itemNumber?.stringRepresentation }}</h3>
  <span>{{ data.companyName }}</span>

  <form (ngSubmit)="setPrice()" [formGroup]="priceForm">
    <mat-form-field>
      <mat-label>Preis</mat-label>
      <input matInput required formControlName="price" type="number" />
    </mat-form-field>

    <div fxLayout="row" fxLayoutGap="6px">
      <button mat-raised-button color="primary">Speichern</button>
      <button mat-raised-button color="warn" type="button" (click)="cancelPriceSetting()">Löschen</button>
    </div>
  </form>
</div>
