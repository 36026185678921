import { Injectable, NgZone } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { TreeViewMessageType } from 'app/shared/models';
import { TreeViewCommandType } from 'app/shared/models/tree-view-command.model';

import { ElectronService } from './electron.service';

@Injectable({
  providedIn: 'root'
})
export class TreeViewMessengerService {
  private treeViewVisibleSource = new ReplaySubject<boolean>(1);
  treeViewVisible = this.treeViewVisibleSource.asObservable();

  private dataToTreeViewSource = new ReplaySubject<TreeViewMessageType>(1);
  dataToTreeView = this.dataToTreeViewSource.asObservable();

  private dataFromTreeViewSource = new ReplaySubject<TreeViewMessageType>(1);
  dataFromTreeView = this.dataFromTreeViewSource.asObservable();

  constructor(private electronService: ElectronService, ngZone: NgZone) {
    if (electronService.isElectron) {
      electronService.ipcRenderer.on('TreeViewWindowVisible', () => ngZone.run(() => this.treeViewVisibleSource.next(true)));

      electronService.ipcRenderer.on('TreeViewWindowClosed', () =>
        ngZone.run(() => {
          this.sendDataFromTreeView({
            data: null,
            command: TreeViewCommandType.Closed
          });
          this.treeViewVisibleSource.next(false);
        })
      );

      electronService.ipcRenderer.on('DataToTreeView', (_, data: TreeViewMessageType) =>
        ngZone.run(() => this.dataToTreeViewSource.next(data))
      );

      electronService.ipcRenderer.on('DataFromTreeView', (_, data: TreeViewMessageType) => {
        ngZone.run(() => this.dataFromTreeViewSource.next(data));
      });
    }
  }

  showTreeViewWindow(): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('ShowTreeViewWindow');
    }
  }

  setOnTopTreeView(alwaysOnTop: boolean): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('TreeViewWindowOnTop', alwaysOnTop);
    }
  }

  sendDataToTreeView(data: TreeViewMessageType): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('SendDataToTreeView', data);
    }
  }

  sendDataFromTreeView(data: TreeViewMessageType): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('SendDataFromTreeView', data);
    }
  }

  closeTreeViewWindow(): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('CloseTreeViewWindow');
    }
  }
}
