import { Component, Optional } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MatDialogContent, MatDialogClose } from '@angular/material/dialog';

import { BuildingElementCodesListComponent } from '../building-element-codes-list/building-element-codes-list.component';

@Component({
  selector: 'pa-building-element-codes-modal',
  templateUrl: './building-element-codes-modal.component.html',
  styleUrls: ['./building-element-codes-modal.component.scss'],
  standalone: true,
  imports: [MatDialogContent, BuildingElementCodesListComponent, MatButton, MatDialogClose]
})
export class BuildingElementCodesModalComponent {
  constructor(@Optional() private matDialogRef: MatDialogRef<BuildingElementCodesModalComponent>) {}

  selecting(row): void {
    this.matDialogRef.close(row);
  }
}
