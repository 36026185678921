import { NgIf, NgFor, PercentPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatButtonToggleGroup, MatButtonToggle } from '@angular/material/button-toggle';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSelect } from '@angular/material/select';
import { MatStepper, MatStep, MatStepLabel, MatStepperNext, MatStepperPrevious } from '@angular/material/stepper';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { Subject, of } from 'rxjs';
import { delay, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';

import { CompaniesService } from '@shared/services/lightquery/companies.service';

import {
  AvaFileInspectionResultGet,
  AvaFilesClient,
  CompanyGet,
  ContactGet,
  ContactType,
  MasterAdditionGroupGet,
  MasterAdditionGroupsClient,
  ProjectGet,
  ProjectPost,
  ProjectQuantityTakeOffType,
  ProjectsClient,
  TaxRateGet
} from 'app/generated-client/generated-client';
import { SelectProjectQtoTypeModalComponent } from 'app/shared/components/select-project-qto-type-modal/select-project-qto-type-modal.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { ContactsService } from 'app/shared/services/lightquery/contacts.service';
import { ProjectsService } from 'app/shared/services/lightquery/projects.service';
import { TaxRatesService } from 'app/shared/services/lightquery/tax-rates.service';
import { ModalService } from 'app/shared/services/modal.service';

import { OriginDtoPipe } from '../../../../shared/pipes/ui-data-display/origin-dto.pipe';
import { ProjectQtoTypePipe } from '../../../../shared/pipes/ui-data-display/project-qto-type.pipe';
import { ElectronService } from '../../../../shared/services/electron/electron.service';
import { ContactsComponent } from '../../../contacts/components/contacts/contacts.component';
import { FlexLayoutDirective } from '../../../flex-layout/flex-layout.directive';
import { CompanyListComponent } from '../../../management/components/company-list/company-list.component';

import { SelectProjectAvaProjectComponent } from '../select-project-ava-project/select-project-ava-project.component';
import { PaginationResult } from 'ng-lightquery';

@Component({
  selector: 'pa-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss'],
  standalone: true,
  imports: [
    MatStepper,
    MatStep,
    MatStepLabel,
    NgIf,
    MatButton,
    MatCheckbox,
    FormsModule,
    FlexLayoutDirective,
    MatProgressSpinner,
    MatStepperNext,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatSelect,
    NgFor,
    MatOption,
    MatSuffix,
    MatStepperPrevious,
    MatIcon,
    CompanyListComponent,
    MatButtonToggleGroup,
    MatButtonToggle,
    ContactsComponent,
    PercentPipe,
    OriginDtoPipe,
    ProjectQtoTypePipe
  ]
})
export class AddProjectComponent implements OnInit, OnDestroy {
  avaFileInspectionResult: AvaFileInspectionResultGet;
  contactsPaginated: PaginationResult<ContactGet>;
  fileCouldNotBeRead = false;
  masterAdditionGroupForm: FormGroup;
  masterAdditionGroups: MasterAdditionGroupGet[];
  newContactFormGroup: FormGroup;
  newOrExistingContactToggle = 'existingContact';
  performingAvaInspection = false;
  projectDataFormGroup: FormGroup;
  requestEnRoute = false;
  selectedCompany: CompanyGet | null;
  selectedContact: ContactGet;
  selectedTendererContact: ContactGet;
  selectedFile: File;
  servSpecNameFormGroup: FormGroup;
  costCenterFormGroup: FormGroup;
  includePricesFromAvaFile = true;
  importPricesAsFixedPrices = true;
  standardGroupId: string;
  isCopyAdditionGroup: boolean;
  copyAdditionObj: any;
  projectForQtoType: ProjectQuantityTakeOffType = null;
  private filterSource = new Subject<string>();
  private $destroy: Subject<boolean> = new Subject<boolean>();
  allTaxRates: TaxRateGet[];
  enableSupportForSkippedTiersInItemNumberSchema = true;
  companiesList: CompanyGet[];
  //TODO if we don't need these steps in the future, we can delete them
  hideStep = true;

  constructor(
    private avaFilesClient: AvaFilesClient,
    private contactsService: ContactsService,
    private masterAdditionGroupsClient: MasterAdditionGroupsClient,
    private notificationsService: AvaNotificationsService,
    private projectsClient: ProjectsClient,
    private projectsService: ProjectsService,
    private router: Router,
    private _formBuilder: FormBuilder,
    private modalService: ModalService,
    private taxRatesService: TaxRatesService,
    public sanitizer: DomSanitizer,
    private companiesService: CompaniesService,
    private electronService: ElectronService
  ) {}

  ngOnInit(): void {
    this.setParameters();

    this.contactsService.paginationResult
      .pipe(takeUntil(this.$destroy))
      .subscribe((r: PaginationResult<ContactGet>) => (this.contactsPaginated = r));

    this.masterAdditionGroupsClient
      .getAllMasterAdditionGroups()
      .pipe(map((g) => g.filter((group) => group?.masterPriceComponentAdditions.length)))
      .subscribe((g: MasterAdditionGroupGet[]) => {
        this.masterAdditionGroups = g;
        this.standardGroupId = g.find((gg: MasterAdditionGroupGet) => gg.isDefault)?.id;
        if (this.standardGroupId) {
          this.masterAdditionGroupForm.patchValue({
            masterAdditionGroupId: this.standardGroupId
          });
        }
      });

    this.setFormBuilderParams();

    this.taxRatesService.getAll().subscribe((allRates) => {
      this.allTaxRates = allRates;
      const germanRate = allRates.find((r) => r.countryCode.toLowerCase() === 'de');
      if (germanRate) {
        this.projectDataFormGroup.patchValue({
          selectedTaxRate: germanRate,
          projectTaxRate: Math.round(germanRate.rate * 10000) / 100
        });
      } else if (allRates.length > 0) {
        this.projectDataFormGroup.patchValue({
          selectedTaxRate: allRates[0],
          projectTaxRate: Math.round(allRates[0].rate * 10000) / 100
        });
      }
    });

    this.companiesService.getAll().subscribe((companiesList) => (this.companiesList = companiesList));
  }

  selectTaxRate(taxRate: TaxRateGet): void {
    this.projectDataFormGroup.patchValue({
      projectTaxRate: Math.round(taxRate.rate * 10000) / 100
    });
  }

  tryTransformProjectYear(): void {
    const projectYear = this.projectDataFormGroup.get('projectYear').value;
    if (projectYear && !isNaN(+projectYear)) {
      const year = +projectYear;
      if (year < 100) {
        this.projectDataFormGroup.patchValue({
          projectYear: year + 2000
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.contactsService.setQueryParameter('contactType', null);
    this.contactsService.setQueryParameter('filter', null);
    this.contactsService.sort = null;
    this.contactsService.pageSize = 20;
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private setParameters(): void {
    this.contactsService.setQueryParameter('contactType', ContactType.Customer);
    this.contactsService.sort = {
      isDescending: false,
      propertyName: 'contactNumber'
    };
    this.contactsService.pageSize = 5;
    this.filterSource
      .pipe(
        switchMap((x: string) => of<string>(x).pipe(delay(250))),
        distinctUntilChanged(),
        takeUntil(this.$destroy)
      )
      .subscribe((filterValue: string) => {
        this.contactsService.setQueryParameter('filter', filterValue);
      });
  }

  private setFormBuilderParams(): void {
    this.projectDataFormGroup = this._formBuilder.group({
      name: ['', (c) => Validators.required(c)],
      description: [''],
      manualProjectNumber: [''],
      selectedTaxRate: [null],
      projectTaxRate: [0, () => Validators.min(0)]
    });

    this.newContactFormGroup = this._formBuilder.group({
      name: ['', (c) => Validators.required(c)]
    });
    this.servSpecNameFormGroup = this._formBuilder.group({
      name: ['', (c) => Validators.required(c)]
    });
    this.masterAdditionGroupForm = this._formBuilder.group({
      masterAdditionGroupId: '',
      additionsSourceAvaProjectId: ''
    });
    this.costCenterFormGroup = this._formBuilder.group({
      costCenter: ['']
    });
  }

  onFileSelected(files: FileList): void {
    if (files && files.length > 0) {
      this.selectedFile = files[0];

      this.performingAvaInspection = true;
      this.avaFilesClient
        .inspectAvaFile({
          fileName: this.selectedFile.name,
          data: this.selectedFile
        })
        .subscribe(
          (ava: AvaFileInspectionResultGet) => {
            this.fileCouldNotBeRead = false;
            this.performingAvaInspection = false;
            this.avaFileInspectionResult = ava;
            this.patchFormGroupValues(ava);
          },
          () => {
            this.performingAvaInspection = false;
            this.fileCouldNotBeRead = true;
            this.notificationsService.error('Die Datei konnte nicht gelesen werden.');
          }
        );
    }
  }

  private patchFormGroupValues(ava: AvaFileInspectionResultGet): void {
    if (ava.projectInformation) {
      this.projectDataFormGroup.patchValue({
        name: ava.projectInformation.name,
        description: ava.projectInformation.description
      });
    }

    if (ava.serviceSpecificationName) {
      this.servSpecNameFormGroup.patchValue({
        name: ava.serviceSpecificationName
      });
    } else {
      this.servSpecNameFormGroup.patchValue({
        name: ava.projectInformation?.name ?? 'Standard'
      });
    }

    if (ava.projectInformation?.buyer?.name) {
      this.newContactFormGroup.patchValue({
        name: ava.projectInformation.buyer.name
      });
    }
  }

  onContactSelected(contact: ContactGet): void {
    this.selectedContact = contact;
  }

  onTendererContactSelected(contact: ContactGet): void {
    this.selectedTendererContact = contact;
  }

  createProject(): void {
    this.requestEnRoute = true;
    this.createProjectInternal();
  }

  private async createProjectInternal(): Promise<void> {
    const masterAdditionGroupId = this.masterAdditionGroupForm.value.masterAdditionGroupId || this.standardGroupId;
    const sourceAvaProjectIdForAdditions = this.masterAdditionGroupForm.value.additionsSourceAvaProjectId;
    if (!this.selectedCompany) {
      this.selectedCompany = this.companiesList[0];
    }

    const projectModel: ProjectPost = {
      name: this.projectDataFormGroup.value.name,
      description: this.projectDataFormGroup.value.description,
      manualProjectNumber: this.projectDataFormGroup.value.manualProjectNumber,
      serviceSpecificationName: this.servSpecNameFormGroup.value.name,
      existingAvaProjectIdForAdditions: sourceAvaProjectIdForAdditions,
      masterAdditionGroupId: sourceAvaProjectIdForAdditions ? null : masterAdditionGroupId,
      companyId: this.selectedCompany?.id,
      skipImportingPrices: !this.includePricesFromAvaFile,
      importPricesAsFixedPrices: this.importPricesAsFixedPrices,
      projectTaxRate: Math.round(this.projectDataFormGroup.value.projectTaxRate * 100) * 0.0001,
      allowedQuantityTakeOffTypes: this.projectForQtoType,
      enableSupportForSkippedTiersInItemNumberSchema: this.enableSupportForSkippedTiersInItemNumberSchema,
      costCenter: this.costCenterFormGroup.value.costCenter
    };

    if (this.newOrExistingContactToggle === 'existingContact' && this.selectedContact != null) {
      projectModel.existingContactId = this.selectedContact.id;
    } else if (this.newOrExistingContactToggle === 'newContact' && this.newContactFormGroup.value.name) {
      projectModel.newContact = {
        name: this.newContactFormGroup.value.name
      };
    }

    if (this.selectedTendererContact != null) {
      projectModel.existingTendererContactId = this.selectedTendererContact.id;
    }

    if (this.selectedFile) {
      projectModel.serviceSpecificationFile = {
        base64Content: await this.getBase64ForSelectedFile(),
        fileName: this.selectedFile.name,
        mimeType: 'application/octet-stream'
      };
    }

    this.projectsClient.createProject(projectModel).subscribe(
      (projectGet: ProjectGet) => {
        this.router.navigate(['/projects', projectGet.id, 'service-specifications']);
        this.notificationsService.success('Projekt erstellt!');
        this.projectsClient.setUserHasAccessedProject(projectGet.id).subscribe(() => this.projectsService.forceRefresh());
        this.requestEnRoute = false;
      },
      () => {
        this.notificationsService.error('Projekt konnte nicht erstellt werden.');
        this.requestEnRoute = false;
      }
    );
  }

  cancelProjectCreation(): void {
    this.router.navigate(['/projects']);
  }

  private getBase64ForSelectedFile(): Promise<string> {
    return new Promise<string>((resolve) => {
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(this.selectedFile);
      reader.onload = function () {
        let encoded: string = reader.result.toString().replace(/^data:(.*,)?/, '');
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
    });
  }

  setSelectedCompany(company: CompanyGet): void {
    this.selectedCompany = company;
  }

  copyAdditionGroup(value?: boolean): void {
    this.isCopyAdditionGroup = value;
    const masterAddition = this.masterAdditionGroupForm.get('masterAdditionGroupId');
    const copyAddition = this.masterAdditionGroupForm.get('additionsSourceAvaProjectId');
    if (this.isCopyAdditionGroup) {
      this.modalService
        .openModal(SelectProjectAvaProjectComponent, { dialogType: ConfirmationType.General })
        .afterClosed()
        .subscribe((obj) => {
          this.copyAdditionObj = obj;
          if (obj) {
            copyAddition.setValue(this.copyAdditionObj.avaProject.id);
            masterAddition.disable();
            masterAddition.setValue('');
          } else {
            this.isCopyAdditionGroup = false;
          }
        });
    } else {
      masterAddition.setValue(this.standardGroupId);
      masterAddition.enable();
      copyAddition.setValue('');
      this.copyAdditionObj = null;
    }
  }

  tryChangeQtoType(): void {
    console.log(123);
    this.modalService
      .openModal(SelectProjectQtoTypeModalComponent, { dialogType: ConfirmationType.General, data: this.projectForQtoType })
      .afterClosed()
      .subscribe((newType) => {
        if (newType) {
          this.projectForQtoType = <ProjectQuantityTakeOffType>newType;
        }
      });
  }
}
