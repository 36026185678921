import { NgIf, AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UploadFileSettings } from 'app/shared/components/upload-file/upload-file.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';

import { CompaniesClient, CompanyGet, CompanyLogosClient } from '../../../../generated-client/generated-client';
import { UploadFileComponent } from '../../../../shared/components/upload-file/upload-file.component';
import { ImgSrcPipe } from '../../../../shared/pipes/img-src.pipe';
import { AvaNotificationsService } from '../../../../shared/services/ava-notifications.service';
import { ModalService } from '../../../../shared/services/modal.service';
import { FlexLayoutDirective } from '../../../flex-layout/flex-layout.directive';

import { ModalToDeleteCompanyComponent } from '../modal-to-delete-company/modal-to-delete-company.component';

@Component({
  selector: 'pa-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FlexLayoutDirective,
    MatProgressSpinner,
    FormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatButton,
    UploadFileComponent,
    AsyncPipe,
    ImgSrcPipe
  ]
})
export class CompanyInfoComponent implements OnInit {
  uploadFileSettings: UploadFileSettings = {
    height: '200px',
    maxWidth: '400px',
    label: 'Neues Unternehmenslogo'
  };
  companyId: string;
  currentCompany: CompanyGet;
  isChanged: boolean;
  hasLoaded: boolean;
  isLoading: boolean;
  imageFileId: string | null = null;
  private $destroy = new Subject<boolean>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private companiesClient: CompaniesClient,
    private companyLogosClient: CompanyLogosClient,
    private modalService: ModalService,
    private notificationsService: AvaNotificationsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.pipe(takeUntil(this.$destroy)).subscribe(({ id }: Params) => (this.companyId = id));

    this.companiesClient
      .getCompanyById(this.companyId)
      .pipe(takeUntil(this.$destroy))
      .subscribe((company: CompanyGet) => {
        this.currentCompany = company;
        this.hasLoaded = true;
        this.getUrlLogo();
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private getUrlLogo(): void {
    if (this.currentCompany?.companyLogoFileId) {
      this.imageFileId = this.currentCompany.companyLogoFileId;
    }
  }

  public changeDetail(): void {
    this.isLoading = true;
    this.companiesClient
      .updateCompanyDetails(this.currentCompany.id, this.currentCompany)
      .pipe(takeUntil(this.$destroy))
      .subscribe(
        () => {
          this.isChanged = false;
          this.isLoading = false;
          this.notificationsService.success('Daten gespeichert.');
        },
        () => {
          this.isLoading = false;
          this.notificationsService.error('Fehler beim Speichern.');
        }
      );
  }

  public changeLogo(addedFiles: File[]): void {
    if (addedFiles?.length) {
      this.isLoading = true;
      const newLogo = addedFiles[0];
      const newFileLogo = { data: newLogo, fileName: newLogo.name };
      this.companyLogosClient
        .setCompanyLogo(this.currentCompany.id, newFileLogo)
        .pipe(takeUntil(this.$destroy))
        .subscribe((company: CompanyGet) => {
          this.currentCompany = company;
          this.isLoading = false;
          this.getUrlLogo();
        });
    }
  }

  deleteCompany(companyId: string): void {
    this.modalService
      .openModal(ModalToDeleteCompanyComponent, { dialogType: ConfirmationType.General })
      .afterClosed()
      .subscribe((isDelete: boolean) => {
        if (isDelete) {
          this.companiesClient.deleteCompany(companyId).subscribe(
            () => {
              this.notificationsService.success('Das Unternehmen wurde gelöscht');
              this.router.navigate(['management', 'companies']);
            },
            () => this.notificationsService.error('Diese Firma wird in einem Projekt verwendet,' + ' sie kann nicht gelöscht werden')
          );
        }
      });
  }
}
