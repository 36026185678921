<div class="compact">
  <div fxLayout="row" fxLayoutGap="12px" class="row" [class.disabled-row]="!element">
    <div fxLayout="row" fxLayoutAlign="start center">
      <label class="topic">OZ</label>
      <input class="val" type="text" readonly [value]="element?.itemNumber?.stringRepresentation" />
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <label class="topic">LV-M</label>
      <input class="val right" type="text" readonly [value]="element?.quantity | number: '1.3-3'" />
      <input class="topic" type="text" readonly [value]="element?.unitTag || ''" />
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <label class="topic">EP</label>
      <input class="val right" type="text" readonly [value]="element?.unitPrice | number: '1.2-2'" />
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <label class="topic">GP</label>
      <input class="val right" type="text" readonly [value]="element?.totalPrice | number: '1.2-2'" />
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <label class="topic">KT</label>
      <input class="val-long" type="text" readonly [value]="element?.shortText" [matTooltip]="element | positionText" />
    </div>
    <div fxLayout="row" *ngIf="showPositionAndPriceType" fxLayoutAlign="start center">
      <label class="topic">P-Art</label>
      <input class="val" type="text" readonly [value]="element?.positionType | positionType" />
    </div>
    <div fxLayout="row" *ngIf="showPositionAndPriceType" fxLayoutAlign="start center">
      <label class="topic">PP</label>
      <input class="val" type="text" readonly [value]="element?.priceType | priceType" />
    </div>
  </div>
</div>
