import { Component } from '@angular/core';
import { MatDialogContent } from '@angular/material/dialog';
import { MatTabGroup, MatTab, MatTabContent } from '@angular/material/tabs';

import { CalculationAvaExportComponent } from '../calculation-ava-export/calculation-ava-export.component';
import { CalculationPdfExportComponent } from '../calculation-pdf-export/calculation-pdf-export.component';
import { CalculationProductsExportComponent } from '../calculation-products-export/calculation-products-export.component';
import { ServiceSpecificationExportsListComponent } from '../service-specification-exports-list/service-specification-exports-list.component';

@Component({
  selector: 'pa-calculation-export-modal',
  templateUrl: './calculation-export-modal.component.html',
  styleUrls: ['./calculation-export-modal.component.scss'],
  standalone: true,
  imports: [
    MatDialogContent,
    MatTabGroup,
    MatTab,
    MatTabContent,
    CalculationAvaExportComponent,
    CalculationPdfExportComponent,
    ServiceSpecificationExportsListComponent,
    CalculationProductsExportComponent
  ]
})
export class CalculationExportModalComponent {}
