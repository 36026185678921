<div *ngIf="(flatElementsDto && flatElementsDto.data.length) || !filterInitializing; else noList" class="wrap-table element-container">
  <div class="table">
    <cdk-virtual-scroll-viewport headerHeight="22" [tvsItemSize]="22" class="virtual-scroll-viewport">
      <table mat-table [dataSource]="flatElementsDto">
        <ng-container matColumnDef="icon">
          <th mat-header-cell *matHeaderCellDef class="mat-table-sticky"></th>
          <td mat-cell *matCellDef="let row" [style.width.px]="24">
            <button mat-icon-button class="node-toggle-button">
              <mat-icon class="mat-icon-rtl-mirror" *ngIf="row.elementTypeDiscriminator === 'NoteTextDto'">subject</mat-icon>
              <mat-icon class="mat-icon-rtl-mirror" *ngIf="row.elementTypeDiscriminator === 'ExecutionDescriptionDto'">event</mat-icon>
              <mat-icon class="mat-icon-rtl-mirror" *ngIf="row.elementTypeDiscriminator === 'ServiceSpecificationGroupDto'"
                >folder_open</mat-icon
              >
              <ng-container *ngIf="row.elementTypeDiscriminator === 'PositionDto'">
                <mat-icon *ngIf="row | nodeWithoutCalculation: (positionIdsWithoutCalculations$ | async); else tableBlock">add</mat-icon>
              </ng-container>

              <ng-template #tableBlock>
                <mat-icon color="warn">block</mat-icon>
              </ng-template>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="itemNumber">
          <th mat-header-cell *matHeaderCellDef class="mat-table-sticky">OZ</th>
          <td mat-cell *matCellDef="let row" [style.width.ch]="row?.itemNumber?.stringRepresentation.length + 2">
            <ng-container *ngIf="row.elementTypeDiscriminator === 'ServiceSpecificationGroupDto'">
              <ng-container *ngIf="row.id !== (selectedElementId$ | async)">
                <div>
                  {{ $any(row).itemNumber.stringRepresentation }}
                  {{ $any(row).positionType | showPositionAbbreviation: $any(row).priceType }}
                </div>
              </ng-container>
              <input
                *ngIf="row.id === (selectedElementId$ | async)"
                matInput
                type="text"
                [(ngModel)]="row.itemNumber.stringRepresentation"
                (change)="saveChangedElement(row)"
              />
            </ng-container>
            <ng-container *ngIf="row.elementTypeDiscriminator === 'PositionDto'">
              <ng-container *ngIf="row.id !== (selectedElementId$ | async)">
                <div>
                  {{ $any(row).itemNumber.stringRepresentation }}
                  {{ $any(row).positionType | showPositionAbbreviation: $any(row).priceType }}
                </div>
              </ng-container>
              <input
                *ngIf="row.id === (selectedElementId$ | async)"
                matInput
                type="text"
                [(ngModel)]="row.itemNumber.stringRepresentation"
                (change)="saveChangedElement(row)"
              />
            </ng-container>
            <ng-container *ngIf="row.id !== (selectedElementId$ | async)"></ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="text">
          <th mat-header-cell *matHeaderCellDef class="mat-table-sticky">Text</th>
          <td mat-cell *matCellDef="let row" class="table-text">
            <div *ngIf="row.id !== (selectedElementId$ | async)">
              {{ row | nodeText }}
            </div>
            <ng-container *ngIf="row.id === (selectedElementId$ | async)">
              <input
                *ngIf="row.elementTypeDiscriminator !== 'ExecutionDescriptionDto'"
                [placeholder]="row.shortText ? '' : 'Kurztext'"
                matInput
                type="text"
                [(ngModel)]="row.shortText"
                (change)="saveChangedElement(row)"
              />
              <input
                *ngIf="row.elementTypeDiscriminator === 'ExecutionDescriptionDto'"
                matInput
                type="text"
                [(ngModel)]="row.label"
                (change)="saveChangedElement(row)"
              />
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="unitPrice">
          <th mat-header-cell *matHeaderCellDef class="right mat-table-sticky">EP</th>
          <td mat-cell *matCellDef="let row" class="right min-width">
            <ng-container
              *ngIf="row.elementTypeDiscriminator === 'ServiceSpecificationGroupDto' || row.elementTypeDiscriminator === 'PositionDto'"
            >
              <div *ngIf="row.id !== (selectedElementId$ | async)">{{ row.unitPrice | number: '1.2-2' }}</div>
              <input
                *ngIf="row.id === (selectedElementId$ | async)"
                matInput
                type="number"
                matInputDecimalPlaces
                [(ngModel)]="row.unitPrice"
                (change)="setFixedPrice(row)"
              />
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef class="right mat-table-sticky">Menge</th>
          <td mat-cell *matCellDef="let row" class="right min-width">
            <ng-container
              *ngIf="row.elementTypeDiscriminator === 'ServiceSpecificationGroupDto' || row.elementTypeDiscriminator === 'PositionDto'"
            >
              <div *ngIf="row.id !== (selectedElementId$ | async)">{{ row.quantity | number: '1.2-2' }}</div>
              <input
                *ngIf="row.id === (selectedElementId$ | async)"
                matInput
                type="number"
                matInputDecimalPlaces
                [(ngModel)]="row.quantity"
                (change)="saveChangedElement(row)"
              />
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="unitTag">
          <th mat-header-cell *matHeaderCellDef class="mat-table-sticky">Einheit</th>
          <td mat-cell *matCellDef="let row" class="min-width">
            <ng-container
              *ngIf="row.elementTypeDiscriminator === 'ServiceSpecificationGroupDto' || row.elementTypeDiscriminator === 'PositionDto'"
            >
              <div *ngIf="row.id !== (selectedElementId$ | async)">{{ row.unitTag }}</div>
              <input
                *ngIf="row.id === (selectedElementId$ | async)"
                matInput
                type="text"
                [(ngModel)]="row.unitTag"
                (change)="saveChangedElement(row)"
              />
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="deductionFactor">
          <th mat-header-cell *matHeaderCellDef class="right mat-table-sticky">Nachlass</th>
          <td mat-cell *matCellDef="let row" class="right min-width">
            <ng-container
              *ngIf="row.elementTypeDiscriminator === 'ServiceSpecificationGroupDto' || row.elementTypeDiscriminator === 'PositionDto'"
            >
              <div *ngIf="row.id !== (selectedElementId$ | async)">{{ row.deductionFactor | percent: '1.2-2' }}</div>
              <input
                *ngIf="row.id === (selectedElementId$ | async)"
                matInput
                type="number"
                matInputDecimalPlaces
                [(ngModel)]="row.deductionFactor"
                (change)="saveChangedElement(row)"
              />
            </ng-container>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="['icon', 'itemNumber', 'text', 'unitPrice', 'quantity', 'unitTag', 'deductionFactor']; sticky: true"
          class="mat-header-row"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['icon', 'itemNumber', 'text', 'unitPrice', 'quantity', 'unitTag', 'deductionFactor']"
          (dblclick)="editElementByDbl(row)"
          (click)="selectElementBySingl(row)"
          (contextmenu)="modePage === 'lv-editor' || modePage === 'calculation' ? showContextMenu($event, row) : null"
          [ngClass]="{
            'selected-node': row.id === (selectedElementId$ | async),
            'choose-node': row.id === choseSelectedElementId,
            'text-group': row.elementTypeDiscriminator === 'ServiceSpecificationGroupDto'
          }"
        ></tr>
      </table>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
<pa-tree-menu [contextMenuPosition]="contextMenuPosition"></pa-tree-menu>
<ng-template #noList>
  <p class="no-list">Keine Elemente gefunden.</p>
</ng-template>
