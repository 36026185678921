import { NgIf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild,
  inject
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LvEditorService } from '@serv-spec/services/lv-editor.service';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';
import { NoteTextDto } from 'app/generated-client/generated-client';
import { ElementViewMessengerService } from 'app/shared/services/electron/element-view-messenger.service';

import { SafeHtmlPipe } from '../../../../shared/pipes/safe-html.pipe';

import { ItemNumberFieldComponent } from '../item-number-field/item-number-field.component';
import { PositionTypeComponent } from '../position-type/position-type.component';

@Component({
  selector: 'pa-note-text',
  templateUrl: './note-text.component.html',
  styleUrls: ['./note-text.component.scss'],
  standalone: true,
  imports: [ItemNumberFieldComponent, PositionTypeComponent, NgIf, MatCheckbox, FormsModule, SafeHtmlPipe, FlexLayoutDirective]
})
export class NoteTextComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @ViewChild('shortTextInput', { static: false }) inputElement: ElementRef;
  @Input() element: NoteTextDto;
  @Input() shortText: string;
  elementViewOpen = false;
  $destroy: Subject<boolean> = new Subject<boolean>();
  lvEditorService = inject(LvEditorService);
  renderer = inject(Renderer2);

  constructor(private elementViewMessengerService: ElementViewMessengerService) {}

  ngOnInit(): void {
    this.elementViewMessengerService.elementViewVisible
      .pipe(takeUntil(this.$destroy))
      .subscribe((isOpen) => (this.elementViewOpen = isOpen));
  }

  ngAfterViewInit() {
    this.adjustWidth();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.element && !changes.element.firstChange) {
      this.adjustWidth();
    }
  }

  adjustWidth() {
    setTimeout(() => {
      const inputElement = this.inputElement.nativeElement;
      this.renderer.setStyle(inputElement, 'width', 'auto');
      this.renderer.setStyle(inputElement, 'width', inputElement.scrollWidth + 'px');
    }, 100);
  }

  shortEditor(): void {
    this.lvEditorService.saveChangedElement(this.element);
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }
}
