<ng-container *ngIf="!userInfo">
  <a routerLink="/login" id="login-nav-link">Login</a>
</ng-container>
<ng-container *ngIf="userInfo">
  <button mat-button [matMenuTriggerFor]="menu" color="primary">
    <div fxLayout="row" fxLayoutGap="8px">
      <img class="profile-image" *ngIf="userInfo?.identiconId" [diIcon]="userInfo.identiconId" [rectangleSize]="30" />
      <span id="username-nav">
        {{ userInfo.username }}
      </span>
    </div>
  </button>
</ng-container>
<mat-menu #menu>
  <button mat-menu-item (click)="changeUserSettings()">
    <mat-icon>settings</mat-icon>
    <span>Einstellungen</span>
  </button>
  <button mat-menu-item (click)="checkForUpdates()">
    <mat-icon>update</mat-icon>
    <span>Prüfen auf Updates</span>
  </button>
  <button mat-menu-item (click)="openDocumentation()">
    <mat-icon>help</mat-icon>
    <span>Dokumentation</span>
  </button>
  <button mat-menu-item (click)="openHelpWindow()">
    <mat-icon>help_outline</mat-icon>
    <span>Hilfe</span>
  </button>
  <button mat-menu-item (click)="showErrorReportForm()">
    <mat-icon>error</mat-icon>
    <span>Fehler Melden</span>
  </button>
  <button mat-menu-item (click)="logout()">
    <mat-icon>power_settings_new</mat-icon>
    <span>Ausloggen</span>
  </button>
</mat-menu>
