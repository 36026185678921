<h1>Hinweistext</h1>

<div fxLayout="row" fxLayoutGap="12px" class="row compact">
  <div fxLayout="row" class="position">
    <div class="topic">OZ</div>
    <pa-item-number-field [element]="element"></pa-item-number-field>
  </div>
  <div fxLayout="row" class="position">
    <pa-position-type [element]="element"></pa-position-type>
  </div>
</div>

<ng-container *ngIf="!elementViewOpen && element">
  <div fxLayoutGap="12px">
    <mat-checkbox disabled [checked]="element.isOpeningText">Vorbemerkung</mat-checkbox>
    <mat-checkbox disabled [checked]="element.isClosingText">Schlussbemerkung</mat-checkbox>
  </div>
  <div class="short-text">
    <input #shortTextInput type="text" [(ngModel)]="element.shortText" (blur)="shortEditor()" (keydown.enter)="shortTextInput.blur()" />
  </div>
  <div [innerHTML]="element.htmlLongText || '' | safeHtml"></div>
</ng-container>
