import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';

import { DanglIdentityModule } from '@dangl/angular-dangl-identity-client';

import { AngularSplitModule } from 'angular-split';
import { NgDanglIconsModule } from 'ng-dangl-icons';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [],
  imports: [
    DanglIdentityModule,
    NgDanglIconsModule,
    AngularSplitModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    TableVirtualScrollModule,
    ScrollingModule
  ],
  exports: [DanglIdentityModule, NgDanglIconsModule, AngularSplitModule, ToastrModule, TableVirtualScrollModule, ScrollingModule]
})
export class ThirdPartyModule {}
