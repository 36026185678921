import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'columnNames',
  standalone: true
})
export class ColumnNamesPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'index':
        return 'Index';

      case 'rowType':
        return 'Zeilentyp';

      case 'subPositionIdentifier':
        return 'Unterposition';

      case 'formula':
        return 'Ansatz';

      case 'result':
        return 'Ergebnis';

      case 'unit':
        return 'Einheit';

      case 'referenceName':
        return 'Referenz';

      case 'totalCost':
        return 'Summe';

      case 'image':
        return 'Bild';

      case 'itemNumber':
        return 'OZ';

      case 'shortText':
        return 'Kurztext';

      case 'rowNumber':
        return 'Index';

      case 'isInternal':
        return 'Intern';

      case 'text':
        return 'Text';

      case 'formulaFactor':
        return 'Rechenzeichen Faktor';

      case 'factor':
        return 'Faktor';

      case 'divisor':
        return 'Divisor';

      case 'labourHours':
        return 'Lohnstunden';

      case 'labourTotalSum':
        return 'Lohnbetrag';

      case 'sum':
        return 'Summe';

      case 'sumAfterAdditions':
        return 'Summe mit Zuschlag';

      case 'totalSum':
        return 'Aktuelle Abrechnung';

      case 'allTotalSum':
        return 'Bisher abgerechnet';

      case 'serviceSpecificationTotalSum':
        return 'LV-Summe';

      case 'selectedRowsSumma':
        return 'Summe ausgewählte Zeilen';

      case 'pageQTOTotal':
        return 'Summe Blatt';

      case 'positionTotal':
        return 'Summe Position';

      case 'positionQuantity':
        return 'Gesamtmenge Position';

      case 'selectedRowsQuantity':
        return 'Menge ausgewählte Zeilen';

      case 'totalInvoicedPerPosition':
        return 'Gesamtsumme Position alle Abrechnungen';

      case 'totalQuantityPerPosition':
        return 'Gesamtmenge Position alle Abrechnungen';
      case 'positionType':
        return 'Typ';
      case 'quantity':
        return 'Menge';
      case 'unitTag':
        return 'Einheit';
      case 'unitPrice':
        return 'EP';
      case 'totalPrice':
        return 'GP';
      case 'fixedPrice':
        return 'FP';
    }

    if (value != null && value.startsWith('pc_')) {
      // This is for price components in the calculation view. They always
      // start with 'pc_' and then use their unique name
      return value.substr(3);
    }

    return value;
  }
}
