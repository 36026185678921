import { NgIf, AsyncPipe, DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild, Inject, Optional } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCheckboxChange, MatCheckbox } from '@angular/material/checkbox';
import { MatRipple } from '@angular/material/core';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject, combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { PageQtoMenuAction } from '@shared/models';
import { InvoiceMenuActions } from '@shared/models/invoice-menu-actions';
import { ContextMenuSettingsService } from '@shared/services/context-menu-settings.service';
import { StandalonePagesService } from '@shared/services/lightquery/standalone-pages.service';

import { getAppConfig } from 'app/app-config-accessor';
import {
  PageQuantityTakeOffGet,
  PageQuantityTakeOffOverviewGet,
  PageQuantityTakeOffsClient,
  ProjectStatus,
  QuantityTakeOffGet
} from 'app/generated-client/generated-client';
import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { PageQuantityTakeOffsService } from 'app/shared/services/lightquery/page-quantity-take-offs.service';
import { PagesAllAvaProjectService } from 'app/shared/services/lightquery/pages-all-ava-project.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedQuantityTakeOffMessengerService } from 'app/shared/services/messengers/selected-quantity-take-off-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';
import { dateUTC } from 'app/shared/utilities/dateUTC';

import { UpperPaginatorComponent } from '../../../../../../../../shared/components/upper-paginator/upper-paginator.component';
import { ProjectCurrencyPipe } from '../../../../../../../../shared/pipes/ui-data-display/project-currency.pipe';
import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';

import { CopyInvoicePageComponent } from '../copy-invoice-page/copy-invoice-page.component';
import { InvoiceExportPreviewComponent } from '../invoice-export-preview/invoice-export-preview.component';
import { InvoiceTableMenuComponent } from '../invoice-table-menu/invoice-table-menu.component';
import { NewInvoicePageComponent } from '../new-invoice-page/new-invoice-page.component';

@Component({
  selector: 'pa-invoice-pages',
  templateUrl: './invoice-pages.component.html',
  styleUrls: ['./invoice-pages.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatButton,
    FlexLayoutDirective,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatTooltip,
    MatDatepicker,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    MatRipple,
    MatCheckbox,
    MatIconButton,
    MatIcon,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    UpperPaginatorComponent,
    InvoiceTableMenuComponent,
    AsyncPipe,
    DatePipe,
    ProjectCurrencyPipe
  ]
})
export class InvoicePagesComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) private sort: MatSort;
  @ViewChild(InvoiceTableMenuComponent) contextMenuComponent: InvoiceTableMenuComponent;
  displayedColumns = ['pageNumber', 'isChecked', 'name', 'subContractor', 'buildingElementCode', 'modifiedAtUtc', 'totalSum', 'actions'];
  invoice: QuantityTakeOffGet;
  structureView: string;
  canAddNewPages = true;
  isReadOnly: boolean;
  private projectId: string;
  private avaProjectId: string;
  performanceQtoDate = new FormGroup({
    performancePeriodStartUtc: new FormControl(),
    performancePeriodEndUtc: new FormControl()
  });
  private nextPageNumber: string;
  private $destroy: Subject<boolean> = new Subject<boolean>();
  iSModalMode: boolean;
  selectedPages: PageQuantityTakeOffOverviewGet[] = [];
  tryGoBack: boolean;
  contextMenuPosition = { x: '0px', y: '0px' };
  disabledObject: { [key: string]: boolean } = {};

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: {
      iSModalMode: boolean;
      pagesSelection: PageQuantityTakeOffOverviewGet[] | null;
    },
    private modalService: ModalService,
    public pageQuantityTakeOffsService: PageQuantityTakeOffsService,
    private pageQuantityTakeOffsClient: PageQuantityTakeOffsClient,
    private selectedQuantityTakeOffMessengerService: SelectedQuantityTakeOffMessengerService,
    private route: ActivatedRoute,
    private router: Router,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private avaNotificationsService: AvaNotificationsService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    @Optional() private dialogRef: MatDialogRef<InvoicePagesComponent>,
    public sanitizer: DomSanitizer,
    private pagesAllAvaProjectService: PagesAllAvaProjectService,
    private standalonePagesService: StandalonePagesService,
    private contextMenuSettingsService: ContextMenuSettingsService
  ) {}

  ngOnInit(): void {
    this.pageQuantityTakeOffsService.forceRefresh();
    this.iSModalMode = this.data?.iSModalMode;

    this.selectedPages = this.data?.pagesSelection ? this.data?.pagesSelection : [];
    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((p) => {
      this.isReadOnly = p?.status === ProjectStatus.Archived || p?.status === ProjectStatus.Locked;
      this.projectId = p?.id;
    });

    this.pageQuantityTakeOffsService.initService({
      sorting: { propertyName: 'pageNumber', isDescending: true },
      sortTableObj: this.sort,
      paging: { page: 1, pageSize: this.iSModalMode ? 5 : 20 }
    });
    this.pageQuantityTakeOffsService.setQueryParameter('buildingElementCodeId', null);
    this.structureView = this.router.url.includes('invoices') || this.iSModalMode ? 'invoices' : 'estimations';
    if (this.structureView === 'estimations') {
      this.displayedColumns = this.displayedColumns.filter((dc) => dc !== 'isChecked');
    } else if (this.iSModalMode) {
      this.displayedColumns = ['selectPage', 'pageNumber', 'name', 'buildingElementCode', 'modifiedAtUtc', 'totalSum'];
    }

    combineLatest([
      this.selectedQuantityTakeOffMessengerService.selectedQuantityTakeOff.pipe(filter((qto) => !!qto)),
      this.selectedSpecificationMessengerService.selectedServiceSpecification
    ])
      .pipe(takeUntil(this.$destroy))
      .subscribe(([qto, p]: [QuantityTakeOffGet, { avaProjectId: string }]) => {
        this.invoice = qto;
        if (this.invoice?.markedAsBilledAtUtc) {
          this.canAddNewPages = false;
        } else {
          this.canAddNewPages = true;
        }
        this.avaProjectId = p?.avaProjectId;
        if (this.structureView === 'invoices' && !this.performanceQtoDate.value.performancePeriodStartUtc) {
          this.performanceQtoDate = new FormGroup({
            performancePeriodStartUtc: new FormControl({ value: dateUTC(qto.performancePeriodStartUtc, true), disabled: true }),
            performancePeriodEndUtc: new FormControl({ value: dateUTC(qto.performancePeriodEndUtc, true), disabled: true })
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  getSelectedPageValue(id: string): boolean {
    return !!this.selectedPages?.find((v) => v.id === id);
  }

  changeSelectionPage(e: MatCheckboxChange, page: PageQuantityTakeOffOverviewGet): void {
    if (e.checked) {
      this.selectPage(page);
    } else {
      this.removePage(page);
    }
  }

  selectPage(page: PageQuantityTakeOffOverviewGet): void {
    this.selectedPages.push(page);
  }

  removePage(page: PageQuantityTakeOffOverviewGet): void {
    this.selectedPages = this.selectedPages.filter((p) => p.id !== page.id);
  }

  saveSelectedPages(): void {
    this.dialogRef.close(this.selectedPages);
  }

  dismiss(): void {
    this.dialogRef.close(null);
  }

  showAddNewModal(): void {
    this.modalService
      .openModal(NewInvoicePageComponent, {
        dialogType: ConfirmationType.General,
        data: { structureView: this.structureView, quantityTakeOffId: this.invoice.id }
      })
      .afterClosed()
      .subscribe((pqto: PageQuantityTakeOffGet) => {
        if (pqto) {
          if (pqto.quantityTakeOffId != null) {
            this.pageQuantityTakeOffsService.forceRefresh();
            this.router.navigate([pqto.id], {
              relativeTo: this.route
            });
          } else {
            this.router.navigate(['../..', 'standalone-pages', pqto.id], {
              relativeTo: this.route
            });
          }
        }
      });
  }

  exportData(): void {
    this.modalService.openModal(InvoiceExportPreviewComponent, {
      dialogType: ConfirmationType.General,
      data: {
        fileName: 'Aufmaßblätter',
        reportType: this.structureView,
        argumentsReport: [this.invoice.id]
      }
    });
  }

  selectRow(id: string): void {
    if (!this.iSModalMode) {
      this.selectedQuantityTakeOffMessengerService.setSelectedQuantityTakeOffPage(id);
      this.router.navigate([this.router.url, id]);
    }
  }

  showSheet(): void {
    this.router.navigate(['sheets'], {
      relativeTo: this.route
    });
  }

  showQuantity(): void {
    this.router.navigate(['quantity'], {
      relativeTo: this.route
    });
  }

  deletePage(row: PageQuantityTakeOffOverviewGet): void {
    this.modalService
      .openModal(ModalConfirmComponent, {
        dialogType: ConfirmationType.Delete,
        data: ['Löschen', 'Aufmaßblatt', `Blatt: ${row.name}`, 'red']
      })
      .afterClosed()
      .subscribe((isConfirm: boolean) => {
        if (isConfirm) {
          this.pageQuantityTakeOffsClient.deletePageQuantityTakeOff(this.projectId, this.avaProjectId, row.id).subscribe(
            () => {
              this.pageQuantityTakeOffsService.forceRefresh();
              this.pagesAllAvaProjectService.forceRefresh();
              this.avaNotificationsService.success('Aufmaßblatt gelöscht.');
            },
            () => this.avaNotificationsService.error('Das Aufmaßblatt konnte nicht gelöscht werden.')
          );
        }
      });
  }

  editPage(page: PageQuantityTakeOffGet): void {
    this.modalService
      .openModal(NewInvoicePageComponent, {
        dialogType: ConfirmationType.General,
        data: { structureView: this.structureView, page: page },
        restoreFocus: false
      })
      .afterClosed()
      .subscribe((pqto: PageQuantityTakeOffGet) => {
        if (pqto) {
          this.pageQuantityTakeOffsService.forceRefresh();
        }
      });
  }

  back(): void {
    this.tryGoBack = true;
    setTimeout(() => {
      if (this.tryGoBack) {
        this.router.navigate(['../..'], {
          relativeTo: this.route
        });
      }
    }, 200);
  }

  backCalculation(): void {
    if (getAppConfig().isQtoOnlyMode) {
      return;
    }
    this.tryGoBack = false;
    this.router.navigate(['../../..', 'calculation'], {
      relativeTo: this.route
    });
  }

  saveQTOData(): void {
    const data = {
      performancePeriodStartUtc: dateUTC(this.performanceQtoDate.value.performancePeriodStartUtc),
      performancePeriodEndUtc: dateUTC(this.performanceQtoDate.value.performancePeriodEndUtc)
    };
    this.selectedQuantityTakeOffMessengerService.changeQuantityTakeOffDate(
      this.projectId,
      this.invoice.avaProjectId,
      this.invoice.id,
      data
    );
  }

  showContextMenu(event: MouseEvent, item: PageQuantityTakeOffOverviewGet): void {
    this.disabledObject = {
      delete: item.isChecked,
      move: item.isChecked,
      edit: item.isChecked || !this.canAddNewPages
    };
    this.contextMenuSettingsService.setDefaultSettings(event, item, this.contextMenuPosition, this.contextMenuComponent.menu);
  }

  handlerMenuAction(e: PageQtoMenuAction): void {
    switch (e.action) {
      case InvoiceMenuActions.EditInvoiceName:
        this.editPage(e.item);
        break;
      case InvoiceMenuActions.RemoveInvoice:
        this.deletePage(e.item);
        break;
      case InvoiceMenuActions.CopyInvoice:
        this.copyPage(e.item);
        break;
      case InvoiceMenuActions.MoveInvoice:
        this.copyPage(e.item, true);
        break;
      default:
        break;
    }
  }

  copyPage(page: PageQuantityTakeOffOverviewGet, isRemovePage?: boolean): void {
    this.modalService
      .openModal(CopyInvoicePageComponent, {
        dialogType: ConfirmationType.General,
        data: { structureView: this.structureView, page, quantityTakeOffId: page.quantityTakeOffId, isRemovePage },
        restoreFocus: false
      })
      .afterClosed()
      .subscribe((newPage) => {
        if (newPage) {
          this.pageQuantityTakeOffsService.forceRefresh();
          this.pagesAllAvaProjectService.forceRefresh();

          if (newPage.quantityTakeOffId != null) {
            this.router.navigate(['../..', newPage.quantityTakeOffId, 'pages', newPage.id], {
              relativeTo: this.route
            });
          } else {
            this.router.navigate(['../..', 'standalone-pages', newPage.id], {
              relativeTo: this.route
            });
          }
        }
      });
  }
}
