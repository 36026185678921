import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IElementDto } from 'app/generated-client/generated-client';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';

import { ElementComponent } from '../../../../../../../elements/components/element/element.component';
import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';
import { ServiceSpecificationTreeComponent } from '../../../service-specification-tree/service-specification-tree.component';

@Component({
  selector: 'pa-calculation-mode',
  templateUrl: './calculation-mode.component.html',
  styleUrls: ['./calculation-mode.component.scss'],
  standalone: true,
  imports: [ServiceSpecificationTreeComponent, FlexLayoutDirective, ElementComponent]
})
export class CalculationModeComponent implements OnInit, OnDestroy {
  element: IElementDto;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService) {}

  ngOnInit(): void {
    this.selectedSpecificationElementMessengerService.selectedElement
      .pipe(takeUntil(this.$destroy))
      .subscribe((e: { id: string; element: IElementDto }) => {
        this.element = e?.element;
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }
}
