import { CommonModule, PercentPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';

import { ContactPaymentTermGet, ProjectPaymentTermGet } from 'app/generated-client/generated-client';

import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { ModalService } from 'app/shared/services/modal.service';
import { TermsNewComponent } from '../terms-new/terms-new.component';

@Component({
  selector: 'pa-terms-table',
  templateUrl: './terms-table.component.html',
  styleUrls: ['./terms-table.component.scss'],
  standalone: true,
  imports: [CommonModule, MatTableModule, MatIconModule, MatButtonModule]
})
export class TermsTableComponent implements OnChanges {
  @ViewChild(MatSort) sort: MatSort;
  @Input() termsItems: (ProjectPaymentTermGet | ContactPaymentTermGet)[];
  @Output() addTerm = new EventEmitter<number[]>();
  @Output() deleteTerm = new EventEmitter<ProjectPaymentTermGet | ContactPaymentTermGet>();
  columns: string[] = ['index', 'deadline', 'name', 'rate', 'del'];
  dataSource: MatTableDataSource<ProjectPaymentTermGet | ContactPaymentTermGet>;

  constructor(private modalService: ModalService, private pipePercent: PercentPipe) {}

  ngOnChanges(): void {
    this.dataSource = new MatTableDataSource(this.termsItems);
    this.dataSource.sort = this.sort;
  }

  delTerm(row: ProjectPaymentTermGet | ContactPaymentTermGet): void {
    this.modalService
      .openModal(ModalConfirmComponent, {
        dialogType: ConfirmationType.Delete,
        data: ['Löschen', 'Zahlungsbedingungen', `${row.deadline} Tage / ${this.pipePercent.transform(row.rate, '1.2-2')} Skonto`]
      })
      .afterClosed()
      .subscribe((isConfirm: boolean) => {
        if (isConfirm) {
          this.deleteTerm.emit(row);
        }
      });
  }

  newTerm(): void {
    this.modalService
      .openModal(TermsNewComponent, { dialogType: ConfirmationType.General })
      .afterClosed()
      .subscribe({
        next: data => {
          if (data) {
            this.addTerm.emit(data);
          }
        }
      });
  }
}
