import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtTokenService } from '@dangl/angular-dangl-identity-client';

@Injectable({
  providedIn: 'root'
})
export class LocalAuthenticationService {
  constructor(
    private http: HttpClient,
    private jwtTokenService: JwtTokenService
  ) {}

  getAndSaveLocalAuthenticationToken(): void {
    this.http.get<{ accessToken: string }>('/api/local-authentication/token').subscribe((jwt) => {
      this.jwtTokenService.storeCustomToken({
        accessToken: jwt.accessToken,
        expiresAt: Date.now() + 1000000
      });
    });
  }
}
