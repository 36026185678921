import { AsyncPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { ServicePeriodCodesService } from 'app/shared/services/lightquery/service-period-codes.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';

import { ProjectGet, ServicePeriodCodeGet, ServicePeriodCodesClient } from '../../../../../../../../generated-client/generated-client';
import { UpperPaginatorComponent } from '../../../../../../../../shared/components/upper-paginator/upper-paginator.component';

import { ServicePeriodCodesNewModalComponent } from '../service-period-codes-new-modal/service-period-codes-new-modal.component';

@Component({
  selector: 'pa-service-period-codes-list',
  templateUrl: './service-period-codes-list.component.html',
  styleUrls: ['./service-period-codes-list.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    MatIconButton,
    MatIcon,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    UpperPaginatorComponent,
    AsyncPipe
  ]
})
export class ServicePeriodCodesListComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) private sort: MatSort;
  @Input() isEdit: boolean;
  private projectId: string;
  showedColumns = ['name', 'description'];
  filter: string;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    public servicePeriodCodesService: ServicePeriodCodesService,
    private servicePeriodCodesClient: ServicePeriodCodesClient,
    private avaNotificationsService: AvaNotificationsService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.servicePeriodCodesService.initService({
      paging: { page: 1, pageSize: 50 },
      sorting: { propertyName: 'name', isDescending: true },
      sortTableObj: this.sort
    });

    this.servicePeriodCodesService.forceRefresh();

    if (this.isEdit) {
      this.showedColumns.push('action');
    }
    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((project: ProjectGet) => {
      this.projectId = project.id;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
    this.servicePeriodCodesService.setQueryParameter('filter', null);
  }

  deleteItem(row: ServicePeriodCodeGet): void {
    this.modalService
      .openModal(ModalConfirmComponent, { dialogType: ConfirmationType.Delete, data: ['Löschen', 'Leistungszeitraum', row.name] })
      .afterClosed()
      .subscribe((e) => {
        if (e) {
          this.servicePeriodCodesClient.deleteServicePeriodCode(this.projectId, row.id).subscribe(
            () => {
              this.avaNotificationsService.success('Leistungszeitraum gelöscht');
              this.servicePeriodCodesService.forceRefresh();
            },
            () => {
              this.avaNotificationsService.error('Fehler beim Löschen des Leistungszeitraums');
            }
          );
        }
      });
  }

  editItem(row: ServicePeriodCodeGet = null): void {
    this.modalService
      .openModal(ServicePeriodCodesNewModalComponent, {
        dialogType: ConfirmationType.General,
        data: row
      })
      .afterClosed()
      .subscribe((e) => {
        if (e) {
          if (!row) {
            this.servicePeriodCodesClient
              .createServicePeriodCode(this.projectId, {
                projectId: this.projectId,
                name: e.name,
                description: e.description
              })
              .subscribe(
                () => {
                  this.avaNotificationsService.success('Leistungszeitraum gespeichert');
                  this.servicePeriodCodesService.forceRefresh();
                },
                () => {
                  this.avaNotificationsService.error('Fehler beim Speichern des Leistungszeitraums');
                }
              );
          } else {
            this.servicePeriodCodesClient
              .updateServicePeriodCodeDetails(this.projectId, row.id, {
                id: row.id,
                name: e.name,
                description: e.description,
                projectId: this.projectId
              })
              .subscribe(
                () => {
                  this.avaNotificationsService.success('Leistungszeitraum gespeichert');
                  this.servicePeriodCodesService.forceRefresh();
                },
                () => this.avaNotificationsService.error('Fehler beim Speichern des Leistungszeitraums')
              );
          }
        }
      });
  }
}
