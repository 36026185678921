<h1>Ausführungsbeschreibung <span class="mode">Bearbeitungsmodus</span></h1>
<ng-container *ngIf="elementChanged">
  <input class="changing broad" type="text" [(ngModel)]="elementChanged.label" (ngModelChange)="isChanged = true" />
  <p>
    Nr.
    <input class="changing" type="text" [(ngModel)]="elementChanged.identifier" (ngModelChange)="isChanged = true" />
  </p>

  <ng-container *ngIf="elementChanged.blocks?.length">
    <ng-container *ngFor="let block of elementChanged.blocks; let index = index">
      <input class="changing broad" type="text" [(ngModel)]="block.shortText" (ngModelChange)="isChanged = true" />
      <dangl-tiny-mce
        [ngModel]="block.htmlLongText"
        (ngModelChange)="changedTinyMce($event, block, 'htmlLongText')"
        tinyMceLanguageCode="de"
      >
      </dangl-tiny-mce>

      <button mat-raised-button color="warn" (click)="deleteBlock(index)">Block löschen</button>
      <hr />
    </ng-container>
  </ng-container>
  <button mat-raised-button color="primary" (click)="addBlock()">Block hinzufügen</button>
</ng-container>

<div fxLayout="row" fxLayoutGap="12px" class="buttons">
  <button mat-raised-button color="primary" [disabled]="!isChanged" (click)="exit(true)">Speichern</button>
  <button mat-raised-button color="warn" (click)="exit()">Abbrechen</button>
</div>
