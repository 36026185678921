<div mat-dialog-content class="wrapper" *ngIf="finishedLoading; else loader">
  <ng-container *ngIf="showingPdf">
    <h1>Bitte Druckvorschaufenster beachten.</h1>
  </ng-container>
  <ng-container *ngIf="!showingPdf">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
      <h1>Schwerpunkt LV Positionen</h1>
      <div fxLayoutAlign="end center" fxLayoutGap="10px">
        <button mat-raised-button color="primary" (click)="openSelectionElementWindow()">Elementauswahl</button>
        <div fxLayout="column">
          <mat-checkbox [(ngModel)]="generateLongTexts">Langtext beim Export in PDF anzeigen</mat-checkbox>
          <mat-checkbox [(ngModel)]="includeHeaderOnlyOnFirstPage">Kopfzeile nur auf der ersten Seite drucken</mat-checkbox>
        </div>
        <button mat-raised-button color="primary" (click)="generatePdfAndShowPrintView()">PDF Export</button>
      </div>
    </div>
    <div class="table-container mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortColumn($event)" multiTemplateDataRows>
        <ng-container matColumnDef="positionType">
          <th mat-header-cell *matHeaderCellDef>Typ</th>
          <td mat-cell *matCellDef="let element">{{ element.position | shortPositionAndPriceType }}</td>
        </ng-container>

        <ng-container matColumnDef="itemNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>OZ</th>
          <td mat-cell *matCellDef="let element">{{ element.position.itemNumber.stringRepresentation }}</td>
        </ng-container>

        <ng-container matColumnDef="shortText">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Text</th>
          <td mat-cell *matCellDef="let element">{{ element.position | positionText }}</td>
        </ng-container>

        <ng-container matColumnDef="unitPrice">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-align-right">EP</th>
          <td mat-cell *matCellDef="let element" class="right">{{ element.position.unitPrice | projectCurrency }}</td>
        </ng-container>

        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-align-right">Menge</th>
          <td mat-cell *matCellDef="let element" class="right">{{ element.position.quantity | number: '1.3-3' }}</td>
        </ng-container>

        <ng-container matColumnDef="unitTag">
          <th mat-header-cell *matHeaderCellDef>Einheit</th>
          <td mat-cell *matCellDef="let element">{{ element.position.unitTag }}</td>
        </ng-container>

        <ng-container matColumnDef="longText">
          <th mat-header-cell *matHeaderCellDef>Langtext</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="expandedElement !== element.position; else visible">
              <mat-icon class="visibility-icon" (click)="expandedElement = expandedElement === element.position ? null : element.position"
                >visibility_off</mat-icon
              >
            </ng-container>
            <ng-template #visible>
              <mat-icon class="visibility-icon" (click)="expandedElement = expandedElement === element.position ? null : element.position"
                >visibility</mat-icon
              >
            </ng-template>
          </td>
        </ng-container>

        <ng-container matColumnDef="totalPrice">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-align-right">GP</th>
          <td mat-cell *matCellDef="let element" class="right">{{ element.position.totalPrice | projectCurrency }}</td>
        </ng-container>

        <ng-container matColumnDef="percentage">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-align-right">%</th>
          <td mat-cell *matCellDef="let element" class="right">{{ element.percentageOfTotalPrice | percent: '1.2-2' }}</td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length" class="expanded-detail">
            <div class="element-detail" [@detailExpand]="element.position == expandedElement ? 'expanded' : 'collapsed'">
              <div
                *ngIf="expandedElement"
                class="element-description-attribution"
                [innerHTML]="element.position.htmlLongText | safeHtml"
              ></div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: columnsToDisplay"
          class="element-row"
          [class.expanded-row]="expandedElement === element.position"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
      </table>
    </div>
  </ng-container>
</div>
<ng-template #loader>
  <mat-spinner></mat-spinner>
</ng-template>
