import { NgIf, NgFor, DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  MatTableDataSource,
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';

import { Subject, first, takeUntil } from 'rxjs';

import { FlatElementsService } from 'app/areas/tree/services/flat-elements.service';
import {
  AvaProjectAdditionGet,
  AvaProjectAssumedPricesClient,
  AvaProjectElementAssumedPriceGet,
  IElementDto,
  ServiceSpecificationGroupDto
} from 'app/generated-client/generated-client';

import { ElementTypePipe } from '../../../../../../shared/pipes/ui-data-display/element-type.pipe';
import { ProjectCurrencyPipe } from '../../../../../../shared/pipes/ui-data-display/project-currency.pipe';
import { SelectedSpecificationAdditionsMessengerService } from '../../../../../../shared/services/messengers/selected-specification-additions-messenger.service';
import { SelectedSpecificationMessengerService } from '../../../../../../shared/services/messengers/selected-specification-messenger.service';
import { AllowNumericWithDecimalDirective } from '../../directives/allow-numeric-with-decimal.directive';
import { MatInputDecimalPlacesDirective } from '../../directives/mat-input-decimal-places.directive';

@Component({
  selector: 'pa-calculation-estimation-table',
  templateUrl: './calculation-estimation-table.component.html',
  styleUrls: ['./calculation-estimation-table.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatInput,
    MatInputDecimalPlacesDirective,
    AllowNumericWithDecimalDirective,
    FormsModule,
    MatIconButton,
    MatIcon,
    NgFor,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatProgressSpinner,
    DecimalPipe,
    ProjectCurrencyPipe,
    ElementTypePipe
  ]
})
export class CalculationEstimationTableComponent implements OnInit {
  dataSource = new MatTableDataSource<{ element: IElementDto; assumedPrice: AvaProjectElementAssumedPriceGet }>();
  finishedLoading = false;
  private flatElements: IElementDto[] = [];
  columnsToDisplay = [
    'positionType',
    'itemNumber',
    'shortText',
    'unitPrice',
    'quantity',
    'unitTag',
    'totalPrice',
    'priceComponents',
    'calculationEstimationTotal'
  ];
  private $destroy: Subject<boolean> = new Subject<boolean>();
  private avaProjectId: string;
  private projectId: string;
  calculatedTotalsByGroupId: { [key: string]: number } = {};
  calculatedServSpecTotal: number | null = null;

  constructor(
    private flatElementsService: FlatElementsService,
    private avaProjectAssumedPricesClient: AvaProjectAssumedPricesClient,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private selectedSpecificationAdditionsMessengerService: SelectedSpecificationAdditionsMessengerService
  ) {}

  ngOnInit(): void {
    this.flatElementsService.flatElementsDto.pipe(takeUntil(this.$destroy)).subscribe((elementsDto) => {
      this.flatElements = [];
      elementsDto.forEach((item) => {
        if (item.elementType === 'ServiceSpecificationGroupDto' || item.elementType === 'PositionDto') {
          this.flatElements.push(item);
        }
      });

      this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(first()).subscribe((serviceSpecification) => {
        this.avaProjectId = serviceSpecification.avaProjectId;
        this.projectId = serviceSpecification.parentProjectId;
        this.loadAssumedPrices();
      });
    });
  }

  private loadAssumedPrices(): void {
    this.avaProjectAssumedPricesClient
      .getAllAssumedPricesForAvaProjectElements(this.projectId, this.avaProjectId)
      .subscribe((assumedPrices) => {
        this.selectedSpecificationAdditionsMessengerService.selectedAdditions.pipe(first()).subscribe((additions) => {
          if (this.dataSource.data?.length > 0) {
            // In this case, we just want to update the assumed prices
            this.dataSource.data.forEach((item) => {
              const element = item.element;
              const assumedPrice = this.getAssumedPriceObjectForElement(element, assumedPrices, additions);
              item.assumedPrice = assumedPrice;
            });
          } else {
            this.dataSource.data = this.flatElements.map((element) => {
              const assumedPrice = this.getAssumedPriceObjectForElement(element, assumedPrices, additions);

              return {
                element: element,
                assumedPrice: assumedPrice
              };
            });

            this.finishedLoading = true;
          }

          this.avaProjectAssumedPricesClient
            .getAvaProjectContentWithAssumedPrices(this.projectId, this.avaProjectId)
            .subscribe((avaProject) => {
              this.calculatedTotalsByGroupId = {};
              const servSpec = avaProject.serviceSpecifications[0];
              this.calculatedServSpecTotal = servSpec.totalPrice;
              FlatElementsService.getFlatElements(servSpec).forEach((element) => {
                if (element.elementType === 'ServiceSpecificationGroupDto') {
                  this.calculatedTotalsByGroupId[element.id] = (element as ServiceSpecificationGroupDto).totalPrice;
                }
              });
            });
        });
      });
  }

  private getAssumedPriceObjectForElement(
    element: IElementDto,
    assumedPrices: AvaProjectElementAssumedPriceGet[],
    additions: AvaProjectAdditionGet
  ): AvaProjectElementAssumedPriceGet {
    let assumedPrice = assumedPrices.find((item) => item.elementId === element.id);
    if (!assumedPrice) {
      assumedPrice = {
        elementId: element.id,
        avaProjectId: this.avaProjectId
      };
    }

    const existingPriceComponents = assumedPrice.priceComponents || [];
    assumedPrice.priceComponents = additions.priceComponents.map((pc) => {
      const existingPriceComponent = existingPriceComponents.find((item) => item.label === pc.priceComponent);

      return (
        existingPriceComponent || {
          label: pc.priceComponent,
          value: null
        }
      );
    });

    return assumedPrice;
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  fixNumberValue(element: any, name: string, item: { element: IElementDto; assumedPrice: AvaProjectElementAssumedPriceGet }) {
    element[name] = element[name].replace(',', '.');
    element[name] = Number(element[name]) || 0;
    this.savedAssumedPrice(item);
  }

  somePc(element: AvaProjectElementAssumedPriceGet): boolean {
    return element.priceComponents?.some((item) => !!item.label && item.value != null) || false;
  }

  savedAssumedPrice(item: { element: IElementDto; assumedPrice: AvaProjectElementAssumedPriceGet }): void {
    const model = item.assumedPrice;
    model.priceComponents = model.priceComponents?.filter((pc) => pc.value != null);

    this.avaProjectAssumedPricesClient
      .setAssumedPriceForAvaProjectElement(this.projectId, this.avaProjectId, item.element.id, model)
      .subscribe(() => {
        this.loadAssumedPrices();
      });
  }
}
