import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CurrentTabComponentService } from '@master/services/current-tab-component.service';

import { MasterDataProductComponent } from '../master-data-product/master-data-product.component';

@Component({
  selector: 'pa-master-data-product-parent',
  templateUrl: './master-data-product-parent.component.html',
  styleUrls: ['./master-data-product-parent.component.scss'],
  standalone: true,
  imports: [MasterDataProductComponent]
})
export class MasterDataProductParentComponent implements OnInit {
  private $destroy: Subject<boolean> = new Subject<boolean>();
  mode: string;
  productId: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private currentTabComponentService: CurrentTabComponentService
  ) {}

  ngOnInit(): void {
    this.productId = this.activatedRoute.snapshot.params['articleId'];
    this.currentTabComponentService.currentTab.pipe(takeUntil(this.$destroy)).subscribe((tab) => (this.mode = tab));
  }

  dismiss(): void {
    this.router.navigate(['master-data', this.mode]);
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }
}
