export enum ContextMenuSettingType {
  CalculationTable = 'CalculationTable',
  QtoPositions = 'QtoPositions',
  QtoPage = 'QtoPage'
}

export interface ContextListMenuSettingType {
  removeRow?: boolean;
  cutRow?: boolean;
  copyCalculation?: boolean;
  addRowBefore?: boolean;
  addRowAfter?: boolean;
  addMaterials?: boolean;
  addEquipment?: boolean;
  addCasing?: boolean;
  saveRows?: boolean;
  addSavedRow?: boolean;
  saveOneRow?: boolean;
}
