<div class="wrapper">
  <h2>Unternehmensdaten</h2>

  <div class="spinner" *ngIf="!hasLoaded || isLoading">
    <div fxFlex fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <ng-container *ngIf="currentCompany && hasLoaded">
    <div fxLayout="row" [style.opacity]="isLoading ? '0.3' : ''">
      <form (ngSubmit)="changeDetail()">
        <img src="{{ imageFileId | imgSrc | async }}" alt="Logo" class="logo" *ngIf="imageFileId" />
        <div class="details">
          <div fxLayout="row" fxLayoutGap="12px">
            <mat-form-field appearance="fill">
              <mat-label>Name</mat-label>
              <input matInput required name="name" [(ngModel)]="currentCompany.name" (ngModelChange)="isChanged = true" />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Straße</mat-label>
              <input matInput name="street" [(ngModel)]="currentCompany.street" (ngModelChange)="isChanged = true" />
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="12px">
            <mat-form-field appearance="fill">
              <mat-label>Postleitzahl</mat-label>
              <input matInput name="zipCode" [(ngModel)]="currentCompany.zipCode" (ngModelChange)="isChanged = true" />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Ort</mat-label>
              <input matInput name="city" [(ngModel)]="currentCompany.city" (ngModelChange)="isChanged = true" />
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="12px">
            <mat-form-field appearance="fill">
              <mat-label>Land</mat-label>
              <input matInput name="country" [(ngModel)]="currentCompany.country" (ngModelChange)="isChanged = true" />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Ländercode</mat-label>
              <input matInput name="countryCode" [(ngModel)]="currentCompany.countryCode" (ngModelChange)="isChanged = true" />
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="12px">
            <mat-form-field appearance="fill">
              <mat-label>Telefon</mat-label>
              <input matInput name="phone" [(ngModel)]="currentCompany.phone" (ngModelChange)="isChanged = true" />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Email</mat-label>
              <input matInput name="email" [(ngModel)]="currentCompany.email" (ngModelChange)="isChanged = true" />
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="12px">
            <mat-form-field appearance="fill">
              <mat-label>Amtsgericht</mat-label>
              <input matInput name="registerCourt" [(ngModel)]="currentCompany.registerCourt" (ngModelChange)="isChanged = true" />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Registernummer</mat-label>
              <input
                matInput
                name="registerCourtNumber"
                [(ngModel)]="currentCompany.registerCourtNumber"
                (ngModelChange)="isChanged = true"
              />
            </mat-form-field>
          </div>
          <mat-form-field appearance="fill">
            <mat-label>USt. Id</mat-label>
            <input matInput name="vatId" [(ngModel)]="currentCompany.vatId" (ngModelChange)="isChanged = true" />
          </mat-form-field>
        </div>
        <div class="buttons">
          <button mat-button mat-raised-button type="submit" color="primary" [disabled]="!isChanged || isLoading || !currentCompany?.name">
            Änderungen Speichern
          </button>
          <button
            mat-button
            mat-raised-button
            type="button"
            color="warn"
            [disabled]="!currentCompany.canBeDeleted"
            (click)="deleteCompany(companyId)"
          >
            Unternehmen Löschen
          </button>
        </div>
      </form>

      <div fxLayout="column" class="field-logo">
        <pa-upload-file [uploadFileSettings]="uploadFileSettings" (uploadFile)="changeLogo($event)"></pa-upload-file>
      </div>
    </div>
  </ng-container>
</div>
