import { Component, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';

import { Subject, takeUntil } from 'rxjs';

import { ProjectGet, ProjectQuantityTakeOffType, ProjectsClient } from 'app/generated-client/generated-client';
import { SelectProjectQtoTypeModalComponent } from 'app/shared/components/select-project-qto-type-modal/select-project-qto-type-modal.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';

import { ProjectQtoTypePipe } from '../../../../../../shared/pipes/ui-data-display/project-qto-type.pipe';
import { AvaNotificationsService } from '../../../../../../shared/services/ava-notifications.service';

@Component({
  selector: 'pa-project-qto-type',
  templateUrl: './project-qto-type.component.html',
  styleUrls: ['./project-qto-type.component.scss'],
  standalone: true,
  imports: [MatButton, ProjectQtoTypePipe]
})
export class ProjectQtoTypeComponent implements OnInit {
  project: ProjectGet;
  private $destroy = new Subject<boolean>();

  constructor(
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private modalService: ModalService,
    private projectsClient: ProjectsClient,
    private avaNotificationsService: AvaNotificationsService
  ) {}

  ngOnInit(): void {
    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((project: ProjectGet) => {
      this.project = project;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  tryChangeQtoType(): void {
    this.modalService
      .openModal(SelectProjectQtoTypeModalComponent, {
        dialogType: ConfirmationType.General,
        data: this.project.allowedQuantityTakeOffTypes
      })
      .afterClosed()
      .subscribe((newType) => {
        if (newType) {
          this.saveQtoType(newType);
        }
      });
  }

  saveQtoType(newType: ProjectQuantityTakeOffType): void {
    this.projectsClient.setAllowedQuantityTakeOffTypesForProject(this.project.id, newType).subscribe({
      next: () => {
        this.project.allowedQuantityTakeOffTypes = newType;
      },
      error: () => this.avaNotificationsService.error('Fehler beim Ändern der erlaubten Mengenberechnungstypen.')
    });
  }
}
