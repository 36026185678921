import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckboxChange, MatCheckbox } from '@angular/material/checkbox';
import { MatRipple } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTableDataSource,
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';

import { PageQuantityTakeOffOverviewGet, QuantityTakeOffGet, QuantityTakeOffType } from 'app/generated-client/generated-client';
import { UpperPaginatorComponent } from 'app/shared/components/upper-paginator/upper-paginator.component';
import { PagesAllAvaProjectService } from 'app/shared/services/lightquery/pages-all-ava-project.service';

import { UpperPaginatorComponent as UpperPaginatorComponent_1 } from '../../../../../../../../shared/components/upper-paginator/upper-paginator.component';
import { ProjectCurrencyPipe } from '../../../../../../../../shared/pipes/ui-data-display/project-currency.pipe';
import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-invoices-pages-multi-select',
  templateUrl: './invoices-pages-multi-select.component.html',
  styleUrls: ['./invoices-pages-multi-select.component.scss'],
  standalone: true,
  imports: [
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatRipple,
    MatCheckbox,
    FormsModule,
    MatSortHeader,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    UpperPaginatorComponent_1,
    FlexLayoutDirective,
    MatButton,
    DatePipe,
    ProjectCurrencyPipe
  ]
})
export class InvoicesPagesMultiSelectComponent implements OnInit {
  @ViewChild(UpperPaginatorComponent) paginatorComp: UpperPaginatorComponent;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<PageQuantityTakeOffOverviewGet>;
  selectedPages: PageQuantityTakeOffOverviewGet[] = [];
  columnsToDisplay: string[] = [
    'selectPage',
    'quantityTakeOff.name',
    'quantityTakeOff.number',
    'isChecked',
    'title',
    'subContractor',
    'buildingElementCode',
    'pageNumber',
    'totalSum',
    'billed'
  ];
  paginationOptions = { totalCount: 0, pageSize: 0, page: 0 };
  isChanged = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      selectedQto: QuantityTakeOffGet[];
      quantityTakeOffType: QuantityTakeOffType;
      selectedPage: PageQuantityTakeOffOverviewGet[];
    },
    @Optional() public dialogRef: MatDialogRef<InvoicesPagesMultiSelectComponent>,
    public pagesAllAvaProjectService: PagesAllAvaProjectService
  ) {}

  ngOnInit(): void {
    this.pagesAllAvaProjectService.getAll({ quantityTakeOffType: this.data.quantityTakeOffType }).subscribe((list) => {
      const listID = this.data.selectedQto?.map((qto) => qto.id);
      const newList = list?.filter((page) => listID?.includes(page.quantityTakeOffId));
      this.dataSource = new MatTableDataSource(newList);
      this.paginationOptions = { totalCount: newList?.length, pageSize: 5, page: 1 };
      if (this.paginatorComp) {
        this.dataSource.paginator = this.paginatorComp.upperPaginator;
        this.dataSource.sort = this.sort;
      }
      if (this.data.selectedPage?.length) {
        this.selectedPages = this.data.selectedPage;
      } else {
        this.selectedPages = newList;
      }
    });
  }

  changeSelectionPage(e: MatCheckboxChange, page: PageQuantityTakeOffOverviewGet): void {
    if (e.checked) {
      this.selectedPages.push(page);
    } else {
      this.selectedPages = this.selectedPages.filter((p) => p.id !== page.id);
    }
    this.isChanged = true;
  }

  getSelectedPageValue(id: string): boolean {
    return !!this.selectedPages?.find((v) => v.id === id);
  }

  saveSelectedQto(): void {
    this.dialogRef.close(this.selectedPages);
  }

  dismiss(): void {
    this.dialogRef.close();
  }

  public onPage(pageEvent: PageEvent): void {
    this.paginationOptions.page = pageEvent.pageIndex + 1;
    this.paginationOptions.pageSize = pageEvent.pageSize;
  }
}
