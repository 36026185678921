import { Injectable, OnDestroy } from '@angular/core';

import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestsService implements OnDestroy {
  private requestsSource = new ReplaySubject<{ url: string; isError: boolean; statusCode: number }>();
  requests = this.requestsSource.asObservable();

  private runningRequestsCounter = 0;
  private requestsRunningSource = new ReplaySubject<number>(1);
  requestsRunning = this.requestsRunningSource.asObservable();

  ngOnDestroy(): void {
    this.requestsSource.complete();
    this.requestsRunningSource.complete();
  }

  incrementRunningRequests(): void {
    this.runningRequestsCounter++;
    this.requestsRunningSource.next(this.runningRequestsCounter);
  }

  setRequest(url: string, isError: boolean, statusCode: number): void {
    this.runningRequestsCounter--;
    this.requestsRunningSource.next(this.runningRequestsCounter);
    this.requestsSource.next({ url, isError, statusCode });
  }
}
