import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, NgForm, ValidationErrors, FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatFormField, MatLabel, MatError, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  MasterDataProductGet,
  MasterDataProductPut,
  MasterDataProductsClient,
  PriceComponentType,
  ProjectSheetQuantityType,
  ReportGetOfProductsSheetReport,
  ReportsClient
} from 'app/generated-client/generated-client';
import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { PrintViewMessengerService } from 'app/shared/services/electron/print-view-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';

import { FlexLayoutDirective } from '../../../flex-layout/flex-layout.directive';
import { MatInputDecimalPlacesDirective } from '../../../project-id/components/service-specifications/directives/mat-input-decimal-places.directive';
import { CurrentTabComponentService } from '../../services/current-tab-component.service';

@Component({
  selector: 'pa-master-data-product',
  templateUrl: './master-data-product.component.html',
  styleUrls: ['./master-data-product.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutDirective,
    NgIf,
    MatCheckbox,
    FormsModule,
    MatButton,
    MatProgressSpinner,
    MatFormField,
    MatLabel,
    MatInput,
    MatError,
    MatInputDecimalPlacesDirective,
    MatIcon,
    MatSuffix
  ]
})
export class MasterDataProductComponent implements OnInit, OnDestroy {
  @ViewChild('savingForm') savingForm: NgForm;
  @Output() dismissEvent = new EventEmitter();
  @Input() productId: string;
  mode: string;
  isEdit = false;
  isChanged = false;
  formValues: MasterDataProductGet & MasterDataProductPut = {
    id: '',
    name: '',
    description: '',
    unit: '',
    price: null,
    priceComponentType: PriceComponentType.Materials
  };
  private $destroy: Subject<boolean> = new Subject<boolean>();
  isLoadingPdf = false;
  includeHeaderOnlyOnFirstPage = false;

  constructor(
    private modalService: ModalService,
    private masterDataProductsClient: MasterDataProductsClient,
    private reportsClient: ReportsClient,
    private printViewMessengerService: PrintViewMessengerService,
    private currentTabComponentService: CurrentTabComponentService,
    private avaNotificationsService: AvaNotificationsService
  ) {}

  ngOnInit(): void {
    this.currentTabComponentService.currentTab.pipe(takeUntil(this.$destroy)).subscribe((tab) => {
      this.mode = tab;
      if (this.mode === 'articles') {
        this.formValues.priceComponentType = PriceComponentType.Materials;
      } else {
        this.formValues.priceComponentType = PriceComponentType.Equipment;
      }
    });

    if (this.productId) {
      this.masterDataProductsClient
        .getMasterDataProductById(this.productId)
        .pipe(takeUntil(this.$destroy))
        .subscribe((e: MasterDataProductGet) => {
          this.formValues = e;
          this.savingForm.form.controls['price'].addValidators(this.validPrice);
        });
    }

    this.printViewMessengerService.printViewClosed.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.isLoadingPdf = false;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  public dismiss(): void {
    if (this.isEdit) {
      this.isEdit = false;
    } else {
      this.dismissEvent.emit();
    }
  }

  public saveItemsForm(): void {
    if (typeof this.formValues.price === 'string') {
      this.formValues.price = +(<string>this.formValues.price).replace(',', '.');
    }
    const target = this.mode === 'articles' ? 'Stammartikel' : 'Stammgerät';

    this.masterDataProductsClient
      .editMasterDataProduct(this.formValues.id, this.formValues)
      .pipe(takeUntil(this.$destroy))
      .subscribe(
        () => this.avaNotificationsService.success(target + ' gespeichert.'),
        () => this.avaNotificationsService.error('Fehler beim Speichern.')
      );
    this.isEdit = false;
  }

  delete(): void {
    const target = this.mode === 'articles' ? 'Stammartikel' : 'Stammgerät';
    this.modalService
      .openModal(ModalConfirmComponent, { dialogType: ConfirmationType.Delete, data: ['Löschen', target, `Name: ${this.formValues.name}`] })
      .afterClosed()
      .subscribe((isConfirm: boolean) => {
        if (isConfirm) {
          this.masterDataProductsClient.deleteMasterDataProduct(this.productId).subscribe(
            () => {
              this.dismiss();
              this.avaNotificationsService.success(target + ' gelöscht.');
            },
            () => this.avaNotificationsService.error('Fehler beim Löschen, ' + target + ' ist möglicherweise in Benutzung.')
          );
        }
      });
  }

  generateReport(): void {
    this.isLoadingPdf = true;
    this.reportsClient
      .getProductsSheetReport(
        null,
        null,
        [this.productId],
        null,
        null,
        null,
        false,
        true,
        this.includeHeaderOnlyOnFirstPage,
        ProjectSheetQuantityType.Calculated
      )
      .subscribe({
        next: (report: ReportGetOfProductsSheetReport) => {
          this.printViewMessengerService.showPdfPreview(
            report.htmlReport,
            this.formValues.name,
            report.isLandscape,
            report.pdfReportBase64,
            this.includeHeaderOnlyOnFirstPage
          );
          this.printViewMessengerService.waitForPrintViewReady().subscribe(() => {
            this.isLoadingPdf = false;
          });
        },
        error: () => {
          this.isLoadingPdf = false;
          this.avaNotificationsService.error('Fehler beim Erstellen der PDF-Datei');
        }
      });
  }

  validPrice(control: AbstractControl): ValidationErrors {
    // eslint-disable-next-line
    const reg = /[^0-9\.\,]/gi;
    if (reg.test(control.value)) {
      return { invalidPrice: true };
    } else {
      return null;
    }
  }
}
