import { NgFor, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckboxChange, MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';

import { forkJoin, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { AvaProjectsService } from '@shared/services/lightquery/ava-projects.service';

import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { ProjectsService } from 'app/shared/services/lightquery/projects.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';

import {
  ApiErrorOfProjectCopyErrorType,
  AvaProjectAdditionsClient,
  ProjectCopyErrorType,
  ProjectsClient,
  ProjectStatus
} from '../../../../../../generated-client/generated-client';
import { ProjectStatusPipe } from '../../../../../../shared/pipes/ui-data-display/project-status.pipe';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-copy-project',
  templateUrl: './copy-project.component.html',
  styleUrls: ['./copy-project.component.scss'],
  standalone: true,
  imports: [
    MatDialogContent,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatSelect,
    NgFor,
    MatOption,
    MatCheckbox,
    NgIf,
    FlexLayoutDirective,
    MatProgressSpinner,
    MatDialogActions,
    MatButton,
    ProjectStatusPipe
  ]
})
export class CopyProjectComponent implements OnInit, OnDestroy {
  private $destroy: Subject<boolean> = new Subject<boolean>();
  listStatus = Object.keys(ProjectStatus).map((ps) => <ProjectStatus>ps);
  newStatus: ProjectStatus | null = null;
  private projectId: string;
  projectCopySuffix: string;
  manualProjectNumber: string;
  setProjectToArchived = false;
  fixPriceOption = false;
  isStartCopying = false;
  isExcludePriceComponent = false;
  uniquePriceComponents = new Set<string>();
  selectedPriceComponents = new Set<string>();
  constructor(
    private matDialogRef: MatDialogRef<CopyProjectComponent>,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private avaNotificationsService: AvaNotificationsService,
    private router: Router,
    private projectsClient: ProjectsClient,
    private projectsService: ProjectsService,
    private avaProjectAdditionsClient: AvaProjectAdditionsClient,
    private avaProjectsService: AvaProjectsService
  ) {}

  ngOnInit(): void {
    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((p) => {
      this.projectId = p?.id;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  cancel(): void {
    this.matDialogRef.close();
  }

  copyProject(): void {
    if (this.projectId) {
      this.isStartCopying = true;

      this.projectsClient
        .copyProject(this.projectId, {
          projectId: this.projectId,
          newStatus: this.newStatus,
          projectNumberSuffix: this.projectCopySuffix,
          setPreviousProjectToArchived: this.setProjectToArchived,
          setPricesToLockedInNewProject: this.fixPriceOption,
          manualProjectNumber: this.manualProjectNumber,
          calculationRowPriceComponentsToExclude: this.selectedPriceComponents.size ? [...this.selectedPriceComponents] : null
        })
        .subscribe({
          next: (p) => {
            this.isStartCopying = false;
            this.matDialogRef.close();
            this.projectsClient.setUserHasAccessedProject(p.id).subscribe(() => this.projectsService.forceRefresh());
            this.router.navigate(['/projects', p.id, 'service-specifications']);
            this.selectedProjectMessengerService.setSelectedProject(p);
            this.avaNotificationsService.success('Projekt kopiert');
          },
          error: (error) => {
            if (this.isDetailedError(error) && error.error === ProjectCopyErrorType.SuffixAlreadyExists) {
              this.avaNotificationsService.error('Ein Projekt mit diesem Suffix existiert bereits.');
            } else {
              this.avaNotificationsService.error('Unbekannter Fehler beim Kopieren des Projekts.');
            }
            this.isStartCopying = false;
          }
        });
    }
  }

  private isDetailedError(error: any): error is ApiErrorOfProjectCopyErrorType {
    return 'error' in error;
  }

  getUniquePriceComponents(e: MatCheckboxChange): void {
    this.uniquePriceComponents.clear();
    this.selectedPriceComponents.clear();
    if (e.checked) {
      this.selectedProjectMessengerService.selectedProject
        .pipe(
          filter((p) => !!p),
          switchMap((p) => {
            return this.avaProjectsService.connect().pipe(
              map((s) => s.map((servSpec) => servSpec.id)),
              switchMap((ids) => {
                const requests = ids.map((id) => this.avaProjectAdditionsClient.getAvaProjectAdditions(p.id, id));
                return forkJoin(requests);
              })
            );
          }),
          take(1)
        )
        .subscribe((avaProjectAdditions) => {
          avaProjectAdditions.forEach((addition) =>
            addition.priceComponents.forEach((priceComponent) => this.uniquePriceComponents.add(priceComponent.priceComponent))
          );
        });
    }
  }

  excludePriceComponentFromCopyProject(e: MatCheckboxChange, priceComponent: string): void {
    if (e.checked) {
      this.selectedPriceComponents.add(priceComponent);
    } else {
      this.selectedPriceComponents.delete(priceComponent);
    }
  }
}
