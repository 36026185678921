import { CdkDragDrop, CdkDragMove } from '@angular/cdk/drag-drop';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { getElementDtoById, getParentNode } from 'app/areas/tree/utils/fn';
import {
  IElementDto,
  ServiceSpecificationDto,
  ServiceSpecificationGroupDto,
  TreeViewDisplayType
} from 'app/generated-client/generated-client';
import { GroupViewService } from 'app/shared/services/group-view.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

@Injectable({
  providedIn: 'root'
})
export class TreeNodeDragingService implements OnDestroy {
  private $destroy: Subject<boolean> = new Subject<boolean>();

  private moveElementSource = new Subject<{ elementId: string; targetContainerId: string; targetPreviousId: string | null }>();
  moveElement = this.moveElementSource.asObservable();

  private previousElementId: string | null = null;
  private serviceSpecifications: ServiceSpecificationDto;
  private isDropBefore = false;
  private projectGroupView: TreeViewDisplayType;
  constructor(
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private groupViewService: GroupViewService
  ) {
    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        takeUntil(this.$destroy),
        filter(servSpec => !!servSpec)
      )
      .subscribe(s => {
        this.serviceSpecifications = s?.project.serviceSpecifications[0];
      });

    this.groupViewService.projectGroupView.pipe(takeUntil(this.$destroy)).subscribe(projectGroupView => {
      this.projectGroupView = projectGroupView;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  dragMoved(event: CdkDragMove<any>): void {
    this.isDropBefore = false;
    document.getElementsByClassName('show-after')[0]?.classList.remove('show-after');
    document.getElementsByClassName('show-before')[0]?.classList.remove('show-before');
    const elementUnderCursor = document.elementFromPoint(event.pointerPosition.x, event.pointerPosition.y)?.closest('[data-id]');
    if (!elementUnderCursor) {
      return;
    }
    const elementIdUnderCursor = elementUnderCursor.getAttribute('data-id');
    let parentElementForElementUderCursor;
    if (elementIdUnderCursor !== 'without-id') {
      parentElementForElementUderCursor = getParentNode(elementIdUnderCursor, this.serviceSpecifications) as ServiceSpecificationGroupDto;
    } else {
      parentElementForElementUderCursor = getElementDtoById(
        elementUnderCursor.getAttribute('data-parent'),
        this.serviceSpecifications.elements
      );
    }
    elementUnderCursor.classList.add('show-after');
    let elements = parentElementForElementUderCursor.elements;

    if (this.projectGroupView === TreeViewDisplayType.PositionList) {
      elements = parentElementForElementUderCursor.elements.filter(e => e.elementTypeDiscriminator === 'PositionDto');
    }

    const firstChildrenElement = elements.length
      ? document.querySelector(`[data-id="${elements[0].id}"]`)
      : document.querySelector(`[data-parent="${parentElementForElementUderCursor.id}"]`);
    const rect = firstChildrenElement.getBoundingClientRect();

    if (event.pointerPosition.y - rect.top < 12) {
      document.getElementsByClassName('show-after')[0]?.classList.remove('show-after');
      firstChildrenElement.classList.add('show-before');
      this.isDropBefore = true;
    }

    if (this.previousElementId !== parentElementForElementUderCursor.id) {
      this.clearElementStyles();
      elements.forEach((e: any, index) => {
        if (index === 0) {
          document.querySelector(`[data-id="${e.id}"]`).classList.add('show-parent-top');
        }

        if (index === elements.length - 1) {
          document.querySelector(`[data-id="${e.id}"]`).classList.add('show-parent-bottom');
        }
        document.querySelector(`[data-id="${e.id}"]`).classList.add('show-parent-side');
        if (
          e.elementTypeDiscriminator === 'ServiceSpecificationGroupDto' &&
          e.elements.length &&
          this.projectGroupView !== TreeViewDisplayType.Tree
        ) {
          this.addStyleForChild(e);
        }
      });
      this.previousElementId = parentElementForElementUderCursor.id;
    }
  }

  private addStyleForChild(serviceSpecificationGroup: ServiceSpecificationGroupDto): void {
    serviceSpecificationGroup.elements.forEach(e => {
      document.querySelector(`[data-id="${e.id}"]`)?.classList.add('show-parent-side');
      if ((e as ServiceSpecificationGroupDto).elements && (e as ServiceSpecificationGroupDto).elements.length) {
        this.addStyleForChild(e);
      }
    });
  }

  private clearElementStyles(): void {
    document.getElementsByClassName('show-after')[0]?.classList.remove('show-after');
    document.getElementsByClassName('show-before')[0]?.classList.remove('show-before');
    document.getElementsByClassName('show-parent-top')[0]?.classList.remove('show-parent-top');
    document.getElementsByClassName('show-parent-bottom')[0]?.classList.remove('show-parent-bottom');
    const arrayOfHtmlElements = Array.from(document.getElementsByClassName('show-parent-side'));
    arrayOfHtmlElements.forEach(e => e.classList.remove('show-parent-side'));
  }

  drop(event: CdkDragDrop<IElementDto[]>): void {
    if (!event.isPointerOverContainer) {
      this.clearElementStyles();
      return;
    }

    const elementUnderCursor = document.elementFromPoint(event.dropPoint.x, event.dropPoint.y).closest('li');
    const elementIdUnderCursor = elementUnderCursor.getAttribute('data-id');
    let parentElementIdForElementUderCursor;
    if (elementIdUnderCursor !== 'without-id') {
      parentElementIdForElementUderCursor = getParentNode(elementIdUnderCursor, this.serviceSpecifications).id;
    } else {
      parentElementIdForElementUderCursor = getElementDtoById(
        elementUnderCursor.getAttribute('data-parent'),
        this.serviceSpecifications.elements
      ).id;
    }
    this.moveElementSource.next({
      elementId: event.item.data.id,
      targetContainerId: parentElementIdForElementUderCursor,
      targetPreviousId: !this.isDropBefore && elementIdUnderCursor !== 'without-id' ? elementIdUnderCursor : null
    });
    this.clearElementStyles();
    this.previousElementId = null;
  }
}
