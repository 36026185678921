import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UserSettings } from 'app/generated-client/generated-client';
import { UserSettingsService } from './user-settings.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DateFormatService {
  useTwoDigitDateFormat = false;
  private $destroy = new Subject<boolean>();

  constructor(private userSettingsService: UserSettingsService) {
    this.userSettingsService.currentUserSettings.pipe(takeUntil(this.$destroy)).subscribe((setting: UserSettings) => {
      this.useTwoDigitDateFormat = setting?.useTwoDigitDateFormat;
    });
  }

  get display() {
    return {
      dateInput: this.useTwoDigitDateFormat ? 'DD.MM.YY' : 'DD.MM.YYYY'
    };
  }

  get parse() {
    return {
      dateInput: this.useTwoDigitDateFormat ? 'DD.MM.YY' : 'DD.MM.YYYY'
    };
  }
}
