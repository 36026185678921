import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModePageService {
  private modePageSource = new ReplaySubject<string>(1);
  modePage = this.modePageSource.asObservable();

  constructor() {}

  setModePage(modePage: string): void {
    this.modePageSource.next(modePage);
  }
}
