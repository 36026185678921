<h2 mat-dialog-title *ngIf="!!data?.isRemovePage">Blatt Verschieben</h2>
<h2 mat-dialog-title *ngIf="!data?.isRemovePage">Blatt Kopieren</h2>
<form mat-dialog-content [formGroup]="newPageForm" class="main" (ngSubmit)="createPage()">
  <div>
    <mat-form-field class="wider">
      <mat-label>Titel</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>
  </div>

  <mat-checkbox [checked]="isPageNumberManually" (change)="changeSelection($event)">Blattnummer selbst festlegen</mat-checkbox>

  <div *ngIf="isPageNumberManually">
    <mat-form-field class="wider">
      <mat-label>Blattnummer</mat-label>
      <input matInput formControlName="customPageNumber" />
    </mat-form-field>
  </div>

  <div>
    <mat-form-field appearance="fill" class="wider">
      <mat-label>Zielabrechnung</mat-label>
      <mat-select name="status" [value]="quantityTakeOffId" (selectionChange)="changedQto($event)">
        <mat-option [value]="null">- Keine -</mat-option>
        <mat-option *ngFor="let item of listParentQTO" [value]="item.id">{{ item.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div fxLayoutGap="12px">
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!newPageForm.valid || isLoading || !pageData || (isQtoOnlyMode && !quantityTakeOffId)"
    >
      <ng-container *ngIf="!!data?.isRemovePage"> Verschieben </ng-container>
      <ng-container *ngIf="!data?.isRemovePage"> Kopieren </ng-container>
    </button>
    <button mat-raised-button color="warn" type="button" (click)="cancel()" [disabled]="isLoading">Abbrechen</button>
  </div>
  <div class="spinner" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>
</form>
