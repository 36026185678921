import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { LocalProjectsService } from '../../services/local-projects.service';

@Component({
  selector: 'pa-local-projects-overview',
  templateUrl: './local-projects-overview.component.html',
  styleUrls: ['./local-projects-overview.component.scss'],
  standalone: true,
  imports: [RouterOutlet]
})
export class LocalProjectsOverviewComponent implements OnInit {
  constructor(private localProjectsService: LocalProjectsService) {}

  ngOnInit(): void {
    this.localProjectsService.activeProjectFilePath = null;
  }
}
