import { Component, NgZone, OnInit } from '@angular/core';

import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SheetsViewMessengerService } from 'app/shared/services/electron/sheets-view-messenger.service';
import { LocalStorageViewService } from 'app/shared/services/local-storage-view.service';
import { SelectedQuantityTakeOffMessengerService } from 'app/shared/services/messengers/selected-quantity-take-off-messenger.service';

import { GeneralEquipmentComponent } from '../../../../shared/components/general-equipment/general-equipment.component';
import { InvoiceSheetsComponent } from '../../../project-id/components/service-specifications/components/invoice/components/invoice-sheets/invoice-sheets.component';

@Component({
  selector: 'pa-sheets-view',
  templateUrl: './sheets-view.component.html',
  styleUrls: ['./sheets-view.component.scss'],
  standalone: true,
  imports: [GeneralEquipmentComponent, InvoiceSheetsComponent]
})
export class SheetsViewComponent implements OnInit {
  modeEquipment = 'sheets';
  quantityTakeOffId: string;
  avaProjectId: string;
  private $destroy: Subject<boolean> = new Subject();

  constructor(
    private localStorageViewService: LocalStorageViewService,
    private sheetsViewMessengerService: SheetsViewMessengerService,
    private selectedQuantityTakeOffMessengerService: SelectedQuantityTakeOffMessengerService,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.settingView();
    this.sheetsViewMessengerService.dataToSheetsView
      .pipe(takeUntil(this.$destroy))
      .subscribe((e: { command: string; data: { avaProjectId: string; quantityTakeOffId: string } }) => {
        switch (e.command) {
          case 'IDs':
            this.avaProjectId = e?.data?.avaProjectId;
            this.quantityTakeOffId = this.avaProjectId ? e?.data?.quantityTakeOffId : '';
            break;
          case 'changes':
            this.selectedQuantityTakeOffMessengerService.setSomeChangesQuantityTakeOff();
            break;
        }
      });
    this.ngZone.runOutsideAngular(() => {
      fromEvent(document, 'dragstart')
        .pipe(takeUntil(this.$destroy))
        .subscribe((event: DragEvent) => {
          event.preventDefault();
        });
    });
  }

  private settingView(): void {
    const { alwaysOnTop } = this.localStorageViewService.getSettingView(this.modeEquipment);
    this.sheetsViewMessengerService.setOnTopSheetsView(alwaysOnTop);
  }
}
