<div mat-dialog-content class="wrapper">
  <h2>Zahlungsbedingung hinzufügen</h2>
  <form>
    <div fxLayout="column" fxLayoutGap="12px">
      <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput name="name" [(ngModel)]="data.name" />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Frist in Tagen</mat-label>
        <input matInput type="number" step="1" name="deadline" [(ngModel)]="data.deadline" />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Skonto in %</mat-label>
        <input matInput type="number" step="1" name="rate" [(ngModel)]="data.rate" />
      </mat-form-field>
    </div>

    <div class="button-actions">
      <button mat-raised-button color="primary" type="submit" [mat-dialog-close]="getData()" [disabled]="!isValid()">Speichern</button>
      <button mat-button mat-raised-button color="warn" type="button" mat-dialog-close>Abbrechen</button>
    </div>
  </form>
</div>
