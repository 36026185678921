<div class="wrapper">
  <div *ngIf="!showingPdf && !requestEnRoute" class="container">
    <div fxLayoutGap="10px">
      <mat-checkbox [(ngModel)]="includePricesInPdfExport">Preise im Druck ausgeben</mat-checkbox>
      <br />
      <mat-checkbox [(ngModel)]="includePositionsWithoutTotal">Eventualpositionen mit ausgeben</mat-checkbox>
      <br />
      <mat-checkbox [(ngModel)]="includeHeaderOnlyOnFirstPage">Kopfzeile nur auf der ersten Seite drucken</mat-checkbox>
      <br />
    </div>
    <button color="primary" mat-raised-button (click)="generatePdfAndShowPrintView()">PDF Erzeugen</button>
  </div>
  <h1 *ngIf="showingPdf && !requestEnRoute && !waitingForPdf">Bitte Druckvorschaufenster beachten.</h1>
  <div class="wrapper-loader" *ngIf="requestEnRoute || waitingForPdf">
    <mat-spinner></mat-spinner>
  </div>
</div>
