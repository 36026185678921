import { Injectable, NgZone } from '@angular/core';

import { Subject } from 'rxjs';

import { AvaNotificationsService } from './../ava-notifications.service';
import { ElectronService } from './electron.service';

import { TelemetryService } from '../telemetry.service';

export interface ErrorMessage {
  name: string;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class HandlerElectronErrorsService {
  private errorMessageSource = new Subject<ErrorMessage>();
  errorMessage$ = this.errorMessageSource.asObservable();
  constructor(
    private electronService: ElectronService,
    private ngZone: NgZone,
    private avaNotificationsService: AvaNotificationsService,
    private telemetryService: TelemetryService
  ) {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.on('ElectronErrorMessage', (_, err: ErrorMessage) =>
        this.ngZone.run(() => {
          this.errorMessageSource.next(err);
          this.avaNotificationsService.error(err.message, err.name);
          this.telemetryService.logErrorMessage(err.message, err.name);
        })
      );
    }
  }
}
