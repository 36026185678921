<div style="padding: 24px">
  <h2 *ngIf="contact && contact['id']">Kontakt Bearbeiten</h2>
  <h2 *ngIf="contact && !contact['id']">Kontakt Erstellen</h2>
  <form [formGroup]="contactForm" (ngSubmit)="saveContact()" *ngIf="contact">
    <mat-card-content class="contact-form">
      <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput type="text" required name="name" formControlName="name" />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Typ</mat-label>
        <mat-select name="contactType" formControlName="contactType">
          <mat-option *ngFor="let type of contactTypes" [value]="type">
            {{ type | contactType }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Namenszusatz</mat-label>
        <input matInput type="text" name="nameAddition" formControlName="nameAddition" />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>USt Id</mat-label>
        <input matInput type="text" name="vatId" formControlName="vatId" />
      </mat-form-field>
      <div class="button-actions">
        <button mat-raised-button color="primary" type="submit" [disabled]="contactForm.invalid">Speichern</button>
        <button mat-raised-button color="warn" type="button" (click)="dismiss()">Abbrechen</button>
      </div>
    </mat-card-content>
  </form>
</div>
