import { DataSource } from '@angular/cdk/table';
import { DatePipe, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject, combineLatest } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { StandalonePagesService } from '@shared/services/lightquery/standalone-pages.service';

import {
  BuildingElementCodeGet,
  PageQuantityTakeOffGet,
  PageQuantityTakeOffsClient,
  ProjectGet,
  ProjectQuantityTakeOffType,
  ProjectStatus,
  ProjectsClient,
  QuantityTakeOffCalculationType,
  QuantityTakeOffGet,
  QuantityTakeOffType,
  QuantityTakeOffsClient,
  UserSettings
} from 'app/generated-client/generated-client';
import { SelectProjectQtoTypeModalComponent } from 'app/shared/components/select-project-qto-type-modal/select-project-qto-type-modal.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { QuantityTakeOffsService } from 'app/shared/services/lightquery/quantity-take-offs.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';
import { UserSettingsService } from 'app/shared/services/user-settings.service';

import { getAppConfig } from '../../../../../../../../app-config-accessor';
import { AvaNotificationsService } from '../../../../../../../../shared/services/ava-notifications.service';

import { CanalExportModalComponent } from '../canal-export-modal/canal-export-modal.component';
import { InvoicesTableComponent } from '../invoices-table/invoices-table.component';
import { NewInvoicePageComponent } from '../new-invoice-page/new-invoice-page.component';
import { NewInvoiceComponent } from '../new-invoice/new-invoice.component';
import { PaginationResult } from 'ng-lightquery';

@Component({
  selector: 'pa-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  standalone: true,
  imports: [NgIf, MatButton, InvoicesTableComponent]
})
export class InvoicesComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) private sort: MatSort;
  dataSource: DataSource<QuantityTakeOffGet>;
  quantityTakeOffsPaginated: PaginationResult<QuantityTakeOffGet>;
  structureView: string;
  projectId: string;
  project: ProjectGet;
  columnsToDisplay: string[];
  isHavePages = false;
  isOnlyPagesMode = false;
  typeQTO: QuantityTakeOffType;
  selectedBuildingElementCode: BuildingElementCodeGet;
  isReadOnly: boolean;
  userSettings: UserSettings;
  latestQTO: QuantityTakeOffGet;
  private $destroy: Subject<boolean> = new Subject<boolean>();
  enableCanalConvertExport = false;

  constructor(
    private modalService: ModalService,
    public quantityTakeOffsService: QuantityTakeOffsService,
    private route: ActivatedRoute,
    private router: Router,
    private pipeDate: DatePipe,
    private quantityTakeOffsClient: QuantityTakeOffsClient,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private userSettingsService: UserSettingsService,
    private projectsClient: ProjectsClient,
    private avaNotificationsService: AvaNotificationsService,
    private standalonePagesService: StandalonePagesService,
    private pageQuantityTakeOffsClient: PageQuantityTakeOffsClient
  ) {
    if (getAppConfig().enableCanalConvertExport) {
      this.enableCanalConvertExport = true;
    }
  }

  ngOnInit(): void {
    this.structureView = this.router.url.includes('invoices') ? 'invoices' : 'estimations';

    if (this.structureView === 'invoices') {
      this.typeQTO = QuantityTakeOffType.Invoice;
    } else {
      this.typeQTO = QuantityTakeOffType.QuantityEstimation;
    }

    combineLatest([
      this.selectedProjectMessengerService.selectedProject,
      this.selectedSpecificationMessengerService.selectedServiceSpecification
    ])
      .pipe(takeUntil(this.$destroy))
      .subscribe(([project, avaProject]: [ProjectGet, { avaProjectId: string }]) => {
        this.project = project;
        this.projectId = project?.id;
        this.isReadOnly = project?.status === ProjectStatus.Archived || project?.status === ProjectStatus.Locked;
        const avaProjectId = avaProject?.avaProjectId;
        if (this.projectId && avaProjectId) {
          if (!this.project.allowedQuantityTakeOffTypes) {
            this.tryChangeQtoType();
          }

          if (this.typeQTO === QuantityTakeOffType.Invoice) {
            this.pageQuantityTakeOffsClient
              .getAllPageQuantityTakeOffs(this.projectId, avaProjectId, null, false, false, null, '', '', 1, undefined)
              .subscribe((e) => {
                this.isHavePages = !!e.data.length;
              });
          }

          this.route.queryParams.pipe(first()).subscribe((params) => {
            const shouldIgnoreNoPositionQtoCheck = !!params.ignoreAllPagesCheck;
            if (!shouldIgnoreNoPositionQtoCheck) {
              this.checkNoPositionQTO();
            }
          });
        }
      });

    this.userSettingsService.currentFullSettings.pipe(takeUntil(this.$destroy)).subscribe((e: UserSettings) => {
      this.userSettings = e;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  showAddNewModal(): void {
    this.modalService
      .openModal(NewInvoiceComponent, { dialogType: ConfirmationType.General })
      .afterClosed()
      .subscribe((qto: QuantityTakeOffGet) => {
        if (qto) {
          this.quantityTakeOffsService.forceRefresh();
          this.checkNoPositionQTO();
          this.router.navigate([qto.id, qto.calculationType === QuantityTakeOffCalculationType.ByPage ? 'pages' : 'positions'], {
            relativeTo: this.route
          });
        }
      });
  }

  showAddNewPageModal(): void {
    this.modalService
      .openModal(NewInvoicePageComponent, {
        dialogType: ConfirmationType.General,
        data: { structureView: this.structureView }
      })
      .afterClosed()
      .subscribe((pqto: PageQuantityTakeOffGet) => {
        if (pqto) {
          if (pqto.quantityTakeOffId) {
            this.router.navigate([pqto.quantityTakeOffId, 'pages', pqto.id], { relativeTo: this.route });
          } else {
            this.router.navigate(['standalone-pages', pqto.id], { relativeTo: this.route });
          }
        }
      });
  }

  showSheet(): void {
    this.router.navigate(['sheets'], {
      relativeTo: this.route
    });
  }

  checkNoPositionQTO(): void {
    this.quantityTakeOffsService.getAll({ quantityTakeOffType: this.typeQTO }).subscribe((e: QuantityTakeOffGet[]) => {
      if (e?.length) {
        const onlyPagesQTO = e.filter((item) => item.calculationType === QuantityTakeOffCalculationType.ByPage);
        if (onlyPagesQTO.length) {
          onlyPagesQTO.sort((a1, a2) => (a1.createdAtUtc > a2.createdAtUtc ? -1 : 1));
          this.latestQTO = onlyPagesQTO[0];
        }
      }
    });
  }

  tryChangeQtoType(): void {
    this.modalService
      .openModal(SelectProjectQtoTypeModalComponent, {
        dialogType: ConfirmationType.General,
        data: this.project.allowedQuantityTakeOffTypes
      })
      .afterClosed()
      .subscribe((newType) => {
        if (newType) {
          this.saveQtoType(newType);
        }
      });
  }

  saveQtoType(newType: ProjectQuantityTakeOffType): void {
    this.projectsClient.setAllowedQuantityTakeOffTypesForProject(this.project.id, newType).subscribe({
      next: () => {
        this.project.allowedQuantityTakeOffTypes = newType;
      },
      error: () => this.avaNotificationsService.error('Fehler beim Ändern der erlaubten Mengenberechnungstypen.')
    });
  }

  initiateCanalExport(): void {
    this.modalService.openModal(CanalExportModalComponent, {
      dialogType: ConfirmationType.General
    });
  }
}
