import { Injectable } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Injectable({
  providedIn: 'root'
})
export class ContextMenuSettingsService {
  public setDefaultSettings(
    event: MouseEvent,
    // menuData is property of contextMenu with type MatMenuTrigger, it is data to be passed along to any lazily-rendered content.
    // It have type 'any'. After that value is returned to method in context menu as argument 'row'.
    // https://material.angular.io/components/menu/api
    item: any,
    contextMenuPosition: any = { x: '0px', y: '0px' },
    contextMenu: MatMenuTrigger
  ): void {
    event.preventDefault();
    contextMenuPosition.x = `${event.clientX}px`;
    contextMenuPosition.y = `${event.clientY}px`;
    contextMenu.menuData = { item };
    contextMenu.menu.focusFirstItem('mouse');
    contextMenu.openMenu();
  }
}
