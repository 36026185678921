import { Injectable, NgZone } from '@angular/core';

import { ReplaySubject, Subject } from 'rxjs';

import {
  AvaProjectGet,
  CalculationEntry,
  MasterDataProductGet,
  PositionCalculationGet,
  PositionDto,
  ProjectGet
} from 'app/generated-client/generated-client';

import { CopyCalculationMethodsType } from '../../models';

import { ElectronService } from './electron.service';

@Injectable({
  providedIn: 'root'
})
export class CopyCalculationViewMessengerService {
  private copyCalculationViewVisibleSource = new ReplaySubject<boolean>(1);
  copyCalculationViewVisible = this.copyCalculationViewVisibleSource.asObservable();

  private listDataSource = new ReplaySubject<{ method?: CopyCalculationMethodsType }>(1);
  listData = this.listDataSource.asObservable();

  private selectedProjectSource = new ReplaySubject<any>(1);
  selectedProject = this.selectedProjectSource.asObservable();

  private selectedAvaProjectIdSource = new ReplaySubject<string>(1);
  selectedAvaProjectId = this.selectedAvaProjectIdSource.asObservable();

  private selectedElementGroupIdSource = new ReplaySubject<string>(1);
  selectedElementGroupId = this.selectedElementGroupIdSource.asObservable();

  private selectedCalculationRowsSource = new Subject<CalculationEntry[]>();
  selectedCalculationRows = this.selectedCalculationRowsSource.asObservable();

  private selectedPositionListForCopySource = new ReplaySubject<{
    sourceProjectId: string;
    sourceAvaProjectId: string;
    sourcePositionIds: string[];
  }>(1);
  selectedPositionListForCopy = this.selectedPositionListForCopySource.asObservable();

  private saveCalculationRowsSource = new Subject<CalculationEntry[]>();
  saveCalculationRows = this.saveCalculationRowsSource.asObservable();

  private selectedPositionSource = new Subject<{ element: PositionDto; positionCalculation: PositionCalculationGet }>();
  selectedPosition = this.selectedPositionSource.asObservable();

  private selectedDataForCopySource = new Subject<{
    group?: any;
    position?: PositionDto;
    positionCalculation?: PositionCalculationGet;
    avaProject?: AvaProjectGet;
    project: ProjectGet;
  }>();
  selectedDataForCopy = this.selectedDataForCopySource.asObservable();

  private selectedArticleOrEquipmentSource = new Subject<MasterDataProductGet>();
  selectedArticleOrEquipment = this.selectedArticleOrEquipmentSource.asObservable();

  private selectedCalculationRowSource = new Subject<CalculationEntry>();
  selectedCalculationRow = this.selectedCalculationRowSource.asObservable();

  private reloadCurrentPositionCalculationSource = new Subject<void>();
  reloadCurrentPositionCalculation = this.reloadCurrentPositionCalculationSource.asObservable();

  constructor(
    private electronService: ElectronService,
    ngZone: NgZone
  ) {
    if (electronService.isElectron) {
      electronService.ipcRenderer.on('CopyCalculationViewWindowVisible', () =>
        ngZone.run(() => this.copyCalculationViewVisibleSource.next(true))
      );

      electronService.ipcRenderer.on('CopyCalculationViewWindowClosed', () =>
        ngZone.run(() => this.copyCalculationViewVisibleSource.next(false))
      );

      electronService.ipcRenderer.on('CopyCalculationRows', (_, rows: CalculationEntry[]) => {
        ngZone.run(() => this.selectedCalculationRowsSource.next(rows));
      });

      electronService.ipcRenderer.on('SaveCalculationRows', (_, rows: CalculationEntry[]) => {
        ngZone.run(() => this.saveCalculationRowsSource.next(rows));
      });

      electronService.ipcRenderer.on('CopyPosition', (_, data: { element: PositionDto; positionCalculation: PositionCalculationGet }) => {
        ngZone.run(() => this.selectedPositionSource.next(data));
      });

      electronService.ipcRenderer.on(
        'CopyListPosition',
        (_, data: { sourceProjectId: string; sourceAvaProjectId: string; sourcePositionIds: string[] }) => {
          ngZone.run(() => this.selectedPositionListForCopySource.next(data));
        }
      );

      electronService.ipcRenderer.on(
        'SendDataForCopyToMain',
        (
          _,
          data: {
            group?: any;
            position?: PositionDto;
            positionCalculation?: PositionCalculationGet;
            avaProject?: AvaProjectGet;
            project: ProjectGet;
          }
        ) => {
          ngZone.run(() => this.selectedDataForCopySource.next(data));
        }
      );

      electronService.ipcRenderer.on('CopyCalculationRow', (_, row: CalculationEntry) => {
        ngZone.run(() => this.selectedCalculationRowSource.next(row));
      });

      electronService.ipcRenderer.on('SelectedProjectToViewWindow', (_, data: any) => {
        ngZone.run(() => this.selectedProjectSource.next(data));
      });

      electronService.ipcRenderer.on('SelectedAvaProjectToViewWindow', (_, data: string) => {
        ngZone.run(() => this.selectedAvaProjectIdSource.next(data));
      });

      electronService.ipcRenderer.on('CopyCalculationArticleOrEquipment', (_, selection: MasterDataProductGet) => {
        ngZone.run(() => this.selectedArticleOrEquipmentSource.next(selection));
      });

      electronService.ipcRenderer.on('ReloadCurrentPositionCalculation', () => {
        ngZone.run(() => this.reloadCurrentPositionCalculationSource.next());
      });

      electronService.ipcRenderer.on('SetListData', (event, data: { method?: CopyCalculationMethodsType }) =>
        ngZone.run(() => {
          this.listDataSource.next(data);
        })
      );
    }
  }

  changeSelectedPosition(data): void {
    this.selectedPositionSource.next(data);
  }
  changeSelectedDataForCopy(data): void {
    this.selectedDataForCopySource.next(data);
  }

  showCopyCalculationViewWindow(): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('ShowCopyCalculationViewWindow');
    }
  }

  closeCopyCalculationViewWindow(): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('CloseCopyCalculationViewWindow');
    }
  }

  setOnTopCopyCalculation(alwaysOnTop: boolean): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('CopyCalculationViewWindowOnTop', alwaysOnTop);
    }
  }

  setListCopyCalculation(data: { method?: CopyCalculationMethodsType }): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('SetListCopyCalculationData', {
        data
      });
    }
  }

  sendSelectedCalculationRowsToCopyToMainWindow(rows: CalculationEntry[]): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('SelectCalculationRows', rows);
      return;
    }
    this.selectedCalculationRowsSource.next(rows);
  }

  sendSaveCalculationRowsFromCopyToMainWindow(rows: CalculationEntry[]): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('SaveCalculationRows', rows);
      return;
    }
    this.saveCalculationRowsSource.next(rows);
  }

  sendFullPositionToCopyToMainWindow(data: { element: PositionDto; positionCalculation: PositionCalculationGet }): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('SelectPosition', data);
      return;
    }
    this.selectedPositionSource.next(data);
  }

  sendListPositionToCopyToMainWindow(data: any): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('SendListPositionForCopy', data);
      return;
    }
    this.selectedPositionListForCopySource.next(data);
  }

  sendDataForCopyToMainWindow(data: {
    group?: any;
    position?: PositionDto;
    positionCalculation?: PositionCalculationGet;
    avaProject?: AvaProjectGet;
    project: ProjectGet;
  }): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('SendDataForCopy', data);
      return;
    }
    this.selectedDataForCopySource.next(data);
  }

  sendSelectedCalculationRowToCopyToMainWindow(row: CalculationEntry): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('SelectCalculationRow', row);
      return;
    }
    this.selectedCalculationRowSource.next(row);
  }

  sendSelectedArticleOrEquipmentToCopyToMainWindow(selection: MasterDataProductGet): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('SelectCalculationArticleOrEquipment', selection);
      return;
    }
    this.selectedArticleOrEquipmentSource.next(selection);
  }

  sendSelectedProjectToViewWindow(selectedProject: ProjectGet): void {
    this.selectedProjectSource.next(selectedProject);
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('SendSelectedProjectToViewWindow', selectedProject);
    }
  }

  sendSelectedAvaProjectToViewWindow(selectedAvaProjectId: string): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('SendSelectedAvaProjectToViewWindow', selectedAvaProjectId);
      return;
    }
    this.selectedAvaProjectIdSource.next(selectedAvaProjectId);
  }

  sendSelectedElementGroupToViewWindow(elementGroupId: string): void {
    this.selectedElementGroupIdSource.next(elementGroupId);
  }

  sendReloadCurrentPositionCalculation(): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('SendReloadCurrentPositionCalculation');
      return;
    }
    this.raiseReloadCurrentPositionCalculationDirectly();
  }

  raiseReloadCurrentPositionCalculationDirectly(): void {
    this.reloadCurrentPositionCalculationSource.next();
  }
}
