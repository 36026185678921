import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ContactDetailComponent } from '@contacts/components/contact-detail/contact-detail.component';

import { CatalogComponent } from 'app/areas//catalogs/components/catalog/catalog.component';

import { IsAuthenticatedGuard } from '../../shared/guards/is-authenticated.guard';

import { ArticlesComponent } from './components/articles/articles.component';
import { CasingComponent } from './components/casing/casing.component';
import { EquipmentsComponent } from './components/equipments/equipments.component';
import { MasterAdditionGroupDetailComponent } from './components/master-addition-group-detail/master-addition-group-detail.component';
import { MasterAdditionGroupsTableComponent } from './components/master-addition-groups-table/master-addition-groups-table.component';
import { MasterAdditionNewGroupComponent } from './components/master-addition-new-group/master-addition-new-group.component';
import { MasterDataProductParentComponent } from './components/master-data-product-parent/master-data-product-parent.component';
import { MasterDataTaxRatesComponent } from './components/master-data-tax-rates/master-data-tax-rates.component';
import { MasterDataComponent } from './components/master-data/master-data.component';
import { MasterReportsComponent } from './components/master-reports/master-reports.component';
import { MasterTextTemplatesComponent } from './components/master-text-templates/master-text-templates.component';
import { MiscellaneousComponent } from './components/miscellaneous/miscellaneous.component';
import { SubContractorServicesComponent } from './components/sub-contractor-services/sub-contractor-services.component';

const routes: Routes = [
  {
    path: 'master-data',
    component: MasterDataComponent,
    canActivate: [IsAuthenticatedGuard],
    children: [
      {
        path: 'master-addition-groups',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: MasterAdditionGroupsTableComponent
          },
          {
            path: 'new-group',
            component: MasterAdditionNewGroupComponent
          },
          {
            path: 'detail/:additionId',
            component: MasterAdditionGroupDetailComponent
          }
        ]
      },
      {
        path: 'articles',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: ArticlesComponent
          },
          {
            component: MasterDataProductParentComponent,
            path: 'item/:articleId'
          }
        ]
      },
      {
        path: 'equipments',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: EquipmentsComponent
          },
          {
            component: MasterDataProductParentComponent,
            path: 'item/:articleId'
          }
        ]
      },
      {
        path: 'casing',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: CasingComponent
          },
          {
            component: MasterDataProductParentComponent,
            path: 'item/:articleId'
          }
        ]
      },
      {
        path: 'sub-contractor-services',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: SubContractorServicesComponent
          },
          {
            component: MasterDataProductParentComponent,
            path: 'item/:articleId'
          }
        ]
      },
      {
        path: 'miscellaneous',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: MiscellaneousComponent
          },
          {
            component: MasterDataProductParentComponent,
            path: 'item/:articleId'
          }
        ]
      },
      {
        path: 'reports',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: MasterReportsComponent
          }
        ]
      },
      {
        path: 'templates',
        component: MasterTextTemplatesComponent
      },
      {
        path: 'tax-rates',
        component: MasterDataTaxRatesComponent
      },
      {
        path: 'contacts',
        loadComponent: () => import('@contacts/components/contacts/contacts.component').then((c) => c.ContactsComponent)
      },
      {
        path: 'contacts/:contactsId',
        loadComponent: () => import('@contacts/components/contact/contact.component').then((c) => c.ContactComponent),
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: ContactDetailComponent
          }
        ]
      },
      {
        path: 'catalogs',
        loadComponent: () => import('app/areas//catalogs/components/catalogs/catalogs.component').then((c) => c.CatalogsComponent)
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'master-addition-groups'
      }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MasterDataRoutingModule {}
