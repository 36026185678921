import { NgClass, PercentPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'pa-progressbar-percentage',
  templateUrl: './progressbar-percentage.component.html',
  styleUrls: ['./progressbar-percentage.component.scss'],
  standalone: true,
  imports: [MatTooltip, NgClass, PercentPipe]
})
export class ProgressbarPercentageComponent {
  @Input() value: number;

  getColorFromPercentage(percentage: number): string {
    if (percentage < 0.9) return 'yellow';
    if (percentage >= 0.9 && percentage <= 1.1) return 'green';
    return 'red';
  }

  getStringFromPercentageMax(percentage: number): string {
    return percentage > 2 ? '100%' : `${(percentage * 100) / 2}%`;
  }
}
