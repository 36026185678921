import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatRipple } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { combineLatestWith, filter, takeUntil } from 'rxjs/operators';

import { SelectedProjectMessengerService } from '@shared/services/messengers/selected-project-messenger.service';

import {
  FileResponse,
  PriceInquiriesClient,
  PriceInquiryGet,
  PriceInquiryPost,
  PriceInquiryRequestsClient,
  ProjectDto,
  ProjectGet
} from 'app/generated-client/generated-client';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { PriceInquiryService } from 'app/shared/services/lightquery/price-inquiry.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';

import { UpperPaginatorComponent } from '../../../../../../shared/components/upper-paginator/upper-paginator.component';
import { AvaNotificationsService } from '../../../../../../shared/services/ava-notifications.service';
import { FileSaverService } from '../../../../../../shared/services/file-saver.service';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

import { PriceInquiriesNewModalComponent } from '../price-inquiries-new-modal/price-inquiries-new-modal.component';

@Component({
  selector: 'pa-price-inquiries',
  templateUrl: './price-inquiries.component.html',
  styleUrls: ['./price-inquiries.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutDirective,
    MatButton,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    MatRipple,
    MatIconButton,
    MatIcon,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    UpperPaginatorComponent,
    AsyncPipe,
    DatePipe
  ]
})
export class PriceInquiriesComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  avaProjectId: string;
  projectId: string;
  displayedColumns = ['count', 'name', 'createdAtUtc', 'useAssumedQuantities', 'action'];
  private $destroy = new Subject<boolean>();
  project: ProjectGet | null = null;
  avaProject: ProjectDto | null = null;
  isDownloading = false;

  constructor(
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    public priceInquiryService: PriceInquiryService,
    private priceInquiriesClient: PriceInquiriesClient,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private priceInquiryRequestsClient: PriceInquiryRequestsClient,
    private fileSaverService: FileSaverService,
    private avaNotificationsService: AvaNotificationsService
  ) {}

  ngOnInit(): void {
    this.getData();
    this.selectedProjectMessengerService.selectedProject
      .pipe(
        combineLatestWith(this.selectedSpecificationMessengerService.selectedServiceSpecification),
        filter(([project, avaProject]) => !!project && !!avaProject),
        takeUntil(this.$destroy)
      )
      .subscribe(([project, avaProject]) => {
        this.projectId = avaProject?.parentProjectId;
        this.avaProjectId = avaProject?.avaProjectId;
        this.project = project;
        this.avaProject = avaProject?.avaProject;
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  getData(): void {
    this.priceInquiryService.initService({
      sortTableObj: this.sort
    });
  }

  back(): void {
    this.router.navigate(['..', 'calculation'], { relativeTo: this.route });
  }

  newPriceInquiries(): void {
    this.modalService
      .openModal(PriceInquiriesNewModalComponent, { dialogType: ConfirmationType.General })
      .afterClosed()
      .subscribe((e: PriceInquiryPost) => {
        if (e) {
          this.addPriceInquiries(e);
        }
      });
  }

  addPriceInquiries(e: PriceInquiryPost): void {
    this.priceInquiriesClient
      .createPriceInquiry(this.projectId, this.avaProjectId, e)
      .subscribe(() => this.priceInquiryService.forceRefresh());
  }

  showPriceInquiries(line: PriceInquiryGet): void {
    this.router.navigate(['item', { item: line.id }], { relativeTo: this.route });
  }

  showSelectionList(): void {
    if (this.projectId && this.avaProjectId) {
      this.router.navigateByUrl(`/projects/${this.projectId}/service-specifications/${this.avaProjectId}/selection`);
    }
  }

  downloadPackageForPriceInquiry(p: PriceInquiryGet): void {
    this.isDownloading = true;
    this.priceInquiryRequestsClient.createOfferRequestPackageArchive(this.projectId, this.avaProjectId, p.id).subscribe({
      next: (r: FileResponse) => {
        this.isDownloading = false;
        this.fileSaverService.saveAs(r);
      },
      error: () => {
        this.isDownloading = false;
        this.avaNotificationsService.error('Fehler beim Erstellen der Datei');
      }
    });
  }

  downloadAllPackages(): void {
    this.isDownloading = true;
    this.priceInquiriesClient.createOfferRequestPackageArchive(this.projectId, this.avaProjectId).subscribe({
      next: (r: FileResponse) => {
        this.isDownloading = false;
        this.fileSaverService.saveAs(r);
      },
      error: () => {
        this.isDownloading = false;
        this.avaNotificationsService.error('Fehler beim Erstellen der Datei');
      }
    });
  }
}
