import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSelect } from '@angular/material/select';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CompanyGet, ProjectCompanyPut, ProjectGet, ProjectsClient } from 'app/generated-client/generated-client';
import { CompaniesService } from 'app/shared/services/lightquery/companies.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';

import { ImgSrcPipe } from '../../../../../../shared/pipes/img-src.pipe';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-project-company',
  templateUrl: './project-company.component.html',
  styleUrls: ['./project-company.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FlexLayoutDirective,
    MatProgressSpinner,
    MatFormField,
    MatLabel,
    MatSelect,
    FormsModule,
    NgFor,
    MatOption,
    MatButton,
    AsyncPipe,
    ImgSrcPipe
  ]
})
export class ProjectCompanyComponent implements OnInit {
  isChanged: boolean;
  isLoading: boolean;
  currentProject: ProjectGet;
  listCompany: CompanyGet[] = [];
  logoFileId: string | null = null;
  private $destroy = new Subject<boolean>();

  constructor(
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private companiesService: CompaniesService,
    private projectsClient: ProjectsClient
  ) {}

  ngOnInit(): void {
    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((p) => {
      this.currentProject = p;
      this.getUrlLogo(p.companyId);
    });

    this.companiesService.getAll().subscribe((c) => {
      this.listCompany = c;
      if (this.currentProject) {
        this.getUrlLogo(this.currentProject.companyId);
      }
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private getUrlLogo(companyId: string): void {
    if (!this.listCompany || this.listCompany.length === 0) {
      return;
    }

    const logoId = this.listCompany.find((company: CompanyGet) => company.id === companyId)?.companyLogoFileId;
    if (!logoId) {
      return;
    }

    this.logoFileId = logoId;
  }

  public saveCompany(): void {
    const model: ProjectCompanyPut = {
      projectId: this.currentProject.id,
      companyId: this.currentProject.companyId
    };
    this.isLoading = true;
    this.projectsClient
      .setCompanyForProject(this.currentProject.id, model)
      .pipe(takeUntil(this.$destroy))
      .subscribe((project: ProjectGet) => {
        this.selectedProjectMessengerService.setSelectedProject(project);
        this.isChanged = false;
        this.isLoading = false;
      });
  }

  public onChangeCompany(companyId: string): void {
    this.currentProject.companyId = companyId;
    this.getUrlLogo(companyId);
    this.isChanged = true;
  }
}
