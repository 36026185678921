import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';

import { Subject } from 'rxjs';

import { AvaFileExportGet, FileResponse, FilesClient } from 'app/generated-client/generated-client';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { FileSaverService } from 'app/shared/services/file-saver.service';
import { AvaProjectAvaFileExportsService } from 'app/shared/services/lightquery/ava-project-ava-file-exports.service';

import { UpperPaginatorComponent } from '../../../../../../shared/components/upper-paginator/upper-paginator.component';
import { AvaFileExportPhasePipe } from '../../../../../../shared/pipes/ui-data-display/ava-file-export-phase.pipe';
import { AvaFileTargetTypePipe } from '../../../../../../shared/pipes/ui-data-display/ava-file-target-type.pipe';

@Component({
  selector: 'pa-service-specification-exports-list',
  templateUrl: './service-specification-exports-list.component.html',
  styleUrls: ['./service-specification-exports-list.component.scss'],
  standalone: true,
  imports: [
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    MatButton,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    UpperPaginatorComponent,
    AsyncPipe,
    DatePipe,
    AvaFileTargetTypePipe,
    AvaFileExportPhasePipe
  ]
})
export class ServiceSpecificationExportsListComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) private sort: MatSort;

  requestEnRoute = false;
  showedColumn = ['fileName', 'createdAtUtc', 'createdByUsername', 'exportPhase', 'targetType', 'save'];
  private $destroy: Subject<void> = new Subject<void>();

  constructor(
    public avaProjectAvaFileExportsService: AvaProjectAvaFileExportsService,
    private filesClient: FilesClient,
    private notificationsService: AvaNotificationsService,
    private fileSaverService: FileSaverService
  ) {}

  ngOnInit(): void {
    this.avaProjectAvaFileExportsService.initService({
      sorting: { propertyName: 'createdAtUtc', isDescending: true },
      sortTableObj: this.sort
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  saveFile(fileExport: AvaFileExportGet): void {
    this.requestEnRoute = true;
    this.filesClient.downloadFile(fileExport.fileId).subscribe(
      (fileResponse: FileResponse) => {
        this.requestEnRoute = false;
        this.fileSaverService.saveAs(fileResponse);
      },
      () => {
        this.requestEnRoute = false;
        this.notificationsService.error('Datei kann nicht abgerufen werden');
      }
    );
  }
}
