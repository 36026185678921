<h2>Arbeiter</h2>
<div class="wrapper">
  <div class="spinner" *ngIf="isLoading">
    <div fxFlex fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
    </div>
  </div>

  <div *ngIf="hasNoMiddleWageConfigured">
    <p>Keine separate Mittellohnberechnung im Projekt vorhanden.</p>
  </div>

  <table mat-table [dataSource]="dataSource" *ngIf="!isLoading && !(scenarioMode && hasNoMiddleWageConfigured)" class="mat-elevation-z8">
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef>Nr.</th>
      <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>Anzahl</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="!element.isEdit; else edit">
          {{ element.amount | number: '1.2-2' }}
        </ng-container>
        <ng-template #edit>
          <mat-form-field class="custom-form-field">
            <input type="number" matInput [ngModel]="element.amount" (ngModelChange)="changeAmount($event)" />
          </mat-form-field>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Berufsgruppe</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="!element.isEdit; else edit">
          {{ element.description }}
        </ng-container>
        <ng-template #edit>
          <mat-form-field class="custom-form-field occupational-group-field">
            <input type="text" matInput [ngModel]="element.description" (ngModelChange)="changeDescription($event)" />
          </mat-form-field>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="medianWage">
      <th mat-header-cell *matHeaderCellDef>Stundenlohn</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="!element.isEdit; else edit">
          {{ element.medianWage | projectCurrency }}
        </ng-container>
        <ng-template #edit>
          <mat-form-field class="custom-form-field">
            <input type="number" matInput [ngModel]="element.medianWage" (ngModelChange)="changeMedianWage($event)" />
          </mat-form-field>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="sum">
      <th mat-header-cell *matHeaderCellDef>Gesamt</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.amount && element.medianWage">
          {{ element.amount * element.medianWage | projectCurrency }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef>Bearbeiten</th>
      <td mat-cell *matCellDef="let element; let i = index">
        <ng-container *ngIf="!element.isEdit; else edit">
          <button mat-icon-button color="primary" (click)="editRow(element)">
            <mat-icon>edit</mat-icon>
          </button>
        </ng-container>
        <ng-template #edit>
          <div fxLayout="row">
            <button
              mat-icon-button
              color="primary"
              (click)="saveEditing(element, i)"
              [disabled]="!copyAmount || !copyDescription || !copyMedianWage"
            >
              <mat-icon [ngClass]="{ disable: !copyAmount || !copyDescription || !copyMedianWage }">done</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="cancelEditing(element, i)" class="close-btn">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef>Löschen</th>
      <td mat-cell *matCellDef="let element; let i = index">
        <button mat-icon-button (click)="deleteRow(i)" color="warn">
          <mat-icon color="warn">close</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="addRow">
      <td mat-cell mat-footer-cell *matFooterCellDef>
        <button mat-icon-button (click)="addRow()" class="add-btn" [disabled]="!isPossibleAddRow">
          <mat-icon color="primary" [ngClass]="{ disable: !isPossibleAddRow }">add_box</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr mat-footer-row *matFooterRowDef="['addRow']"></tr>
  </table>

  <div class="calculation-wrapper" fxLayoutGap="10px" fxLayout="column" *ngIf="!isLoading && !(scenarioMode && hasNoMiddleWageConfigured)">
    <div fxLayout="row" fxLayoutAlign="space-between">
      <span class="bold">Gesamtbetrag pro Stunde</span>
      <span class="bold ta-right">{{ wageCalculationDetails?.amountPerHour | projectCurrency }}</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <span class="bold">Gesamtanzahl</span>
      <span class="bold ta-right">{{ wageCalculationDetails?.numberOfWorkers | number: '1.2-2' }}</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <span class="bold">Aufsichtsanteil</span>
      <input
        class="ta-right"
        type="number"
        [(ngModel)]="wageCalculation.resourcesSupervisionPart"
        (ngModelChange)="recalculate()"
        matInputDecimalPlaces
        [formatOnlyOnFocusOut]="true"
        [isShowZero]="true"
      />
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <span class="bold">Anzahl Arbeiter</span>
      <span class="bold">{{ wageCalculationDetails?.productiveWorkers | number: '1.2-2' }}</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayoutAlign="start center">
        <span class="bold">Mittellohn Betrag pro Stunde</span>
        <mat-icon
          matTooltip="Mittellohnbetrag manuell gesetzt, nicht berechnet"
          color="primary"
          *ngIf="+wageCalculation.medianHourlyWageOverride"
          class="info-icon"
          >help_outline</mat-icon
        >
      </div>
      <div fxLayoutGap="10px">
        <input
          class="ta-right"
          type="number"
          [(ngModel)]="wageCalculation.medianHourlyWageOverride"
          (ngModelChange)="recalculate()"
          matInputDecimalPlaces
          [formatOnlyOnFocusOut]="true"
        />
        <span class="bold">{{ wageCalculation?.medianHourlyWage | projectCurrency }}</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="space-between">
          <span class="bold">Lohnzusatzkosten</span>
          <div>
            <input
              type="number"
              [(ngModel)]="supplementalWageCostsPercentage"
              (ngModelChange)="recalculate()"
              matInputDecimalPlaces
              [formatOnlyOnFocusOut]="true"
              [isShowZero]="true"
            />

            <span>%</span>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <span class="bold">Lohnnebenkosten</span>
          <div>
            <input
              type="number"
              [(ngModel)]="incidentalWageCostsPercentage"
              (ngModelChange)="recalculate()"
              matInputDecimalPlaces
              [formatOnlyOnFocusOut]="true"
              [isShowZero]="true"
            />
            <span>%</span>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <span class="pr30 bold">Kleingeräte & Werkzeuge</span>
          <div>
            <input
              type="number"
              [(ngModel)]="toolsAndMinorDevicesPercentage"
              (ngModelChange)="recalculate()"
              matInputDecimalPlaces
              [formatOnlyOnFocusOut]="true"
              [isShowZero]="true"
            />
            <span>%</span>
          </div>
        </div>
        <div class="pt30 bold">Kalkulationslohn</div>
        <div class="bold">
          BKG
          <span *ngIf="laborSiteOperationCostsAddition">({{ laborSiteOperationCostsAddition | percent: '1.2-2' }})</span>
        </div>
        <div class="bold">
          AGK
          <span *ngIf="laborCompanyOperationCostsAddition">({{ laborCompanyOperationCostsAddition | percent: '1.2-2' }})</span>
        </div>
        <div class="bold">
          WuG
          <span *ngIf="riskAndProfitAddition">({{ riskAndProfitAddition | percent: '1.2-2' }})</span>
        </div>
        <div class="bold">Verrechnungslohn</div>
      </div>
      <div fxLayout="column" fxLayoutAlign="end" fxLayoutGap="10px">
        <div class="bold ta-right">
          {{ wageCalculationDetails?.additionalWageCosts | projectCurrency }}
        </div>
        <div class="bold ta-right">
          {{ wageCalculationDetails?.nonWageCosts | projectCurrency }}
        </div>
        <div class="bold ta-right">
          {{ wageCalculationDetails?.toolsCosts | projectCurrency }}
        </div>
        <div class="pt30">
          <input
            matTooltip="Durch direktes Eingaben kann der Kaklkulationslohn überschrieben werden."
            *ngIf="wageCalculation"
            #calculationWageOverride
            type="number"
            [(ngModel)]="wageCalculation.calculationWage"
            (blur)="setWageCalculationOverride()"
            matInputDecimalPlaces
            [formatOnlyOnFocusOut]="true"
            (ngModelChange)="shouldTryToCalculateWageOverride = true"
            (keyup.enter)="calculationWageOverride.blur()"
          />
          <span class="bold ta-right">{{ projectCurrency }}</span>
        </div>
        <div class="bold ta-right">{{ laborSiteOperationCostsAdditionPart | projectCurrency }}</div>
        <div class="bold ta-right">{{ laborCompanyOperationCostsAdditionPart | projectCurrency }}</div>
        <div class="bold ta-right">{{ riskAndProfitAdditionPart | projectCurrency }}</div>
        <div class="bold ta-right">
          {{ transferWage$ | async | projectCurrency }}
        </div>
      </div>
    </div>
  </div>
  <button mat-button mat-raised-button color="primary" class="save-btn" (click)="saveWages()" *ngIf="!scenarioMode" [disabled]="isLoading">
    Speichern
  </button>
</div>
