import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';

import { BimFileGet } from '../../../../generated-client/generated-client';
import { ViewerResizeDirective } from '../../directives/viewer-resize.directive';
import { BimViewService } from '../../services/bim-view.service';

@Component({
  selector: 'pa-model-bim-view',
  templateUrl: './model-bim-view.component.html',
  styleUrls: ['./model-bim-view.component.scss'],
  standalone: true,
  imports: [ViewerResizeDirective, FlexLayoutDirective]
})
export class ModelBimViewComponent implements AfterViewInit, OnDestroy {
  @ViewChild('canvas_element') wrapperCanvas: ElementRef;
  @Input() bimFile: BimFileGet;
  private $destroy: Subject<void> = new Subject<void>();

  constructor(private viewerService: BimViewService) {}

  ngOnDestroy(): void {
    this.viewerService.resetViewer();

    this.$destroy.next();
    this.$destroy.complete();
  }

  ngAfterViewInit(): void {
    if (this.wrapperCanvas) {
      this.viewerService.init(this.wrapperCanvas.nativeElement, this.bimFile);
    }
  }
}
