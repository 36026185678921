<div class="spinner" *ngIf="!hasLoaded || isLoading">
  <div fxFlex fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
  </div>
</div>
<div class="wrapper" *ngIf="hasLoaded && !isLoading">
  <form (ngSubmit)="changeDetail()">
    <mat-form-field appearance="fill">
      <mat-label>Vergabenummer</mat-label>
      <input matInput name="award" [(ngModel)]="currentProject.awardIdentifier" (ngModelChange)="isChanged = true" />
    </mat-form-field>

    <div class="wrapper-btn">
      <button mat-raised-button type="submit" color="primary" [disabled]="!isChanged">Speichern</button>
    </div>
  </form>
</div>
