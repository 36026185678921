import { NgIf, NgFor } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProjectGet, ProjectLocksClient } from 'app/generated-client/generated-client';
import { ProjectsService } from 'app/shared/services/lightquery/projects.service';
import { LockProjectService } from 'app/shared/services/lock-project.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';

import { AvaNotificationsService } from '../../../../../../shared/services/ava-notifications.service';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-lock-project',
  templateUrl: './lock-project.component.html',
  styleUrls: ['./lock-project.component.scss'],
  standalone: true,
  imports: [NgIf, FlexLayoutDirective, MatButton, NgFor]
})
export class LockProjectComponent implements OnInit, OnDestroy {
  project: ProjectGet;
  additionUsers: boolean;
  username: string;
  $destroy: Subject<boolean> = new Subject<boolean>();
  projectIsLockedForCurrentUser = false;

  constructor(
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private projectsService: ProjectsService,
    private lockProjectService: LockProjectService,
    private projectLocksClient: ProjectLocksClient,
    private avaNotificationsService: AvaNotificationsService
  ) {}

  ngOnInit(): void {
    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((p: ProjectGet) => {
      this.project = p;
      this.checkIfProjectIsLockedForCurrentUser();
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  accessCurrentUser(): boolean {
    return this.lockProjectService.currentUserHasAccess(this.project);
  }

  lockProject(): void {
    const currentUserId = this.lockProjectService.getCurrentUserId();
    this.projectLocksClient
      .lockProjectForUser(this.project.id, {
        projectId: this.project.id,
        userId: currentUserId
      })
      .subscribe({
        next: (projectResult) => {
          this.update(projectResult);
          this.checkIfProjectIsLockedForCurrentUser();
          this.avaNotificationsService.success('Das Projekt ist jetzt gesperrt.');
        },
        error: () => this.avaNotificationsService.error('Das Projekt konnte nicht gesperrt werden.')
      });
  }

  unlockProject(): void {
    const currentUserId = this.lockProjectService.getCurrentUserId();
    this.projectLocksClient.deleteProjectLockForUser(this.project.id, currentUserId).subscribe({
      next: (projectResult) => {
        this.update(projectResult);
        this.checkIfProjectIsLockedForCurrentUser();
        this.avaNotificationsService.success('Die Projektsperre wurde aufgehoben');
      },
      error: () => this.avaNotificationsService.error('Das Projekt konnte nicht entsperrt werden.')
    });
  }

  update(updatedProject: ProjectGet): void {
    this.project = updatedProject;
    this.checkIfProjectIsLockedForCurrentUser();
    this.projectsService.forceRefresh();
  }

  private checkIfProjectIsLockedForCurrentUser(): void {
    const currentUserId = this.lockProjectService.getCurrentUserId();
    this.projectIsLockedForCurrentUser =
      this.project?.projectUserAccessRestrictions?.length > 0 &&
      this.project.projectUserAccessRestrictions.some((p) => p.userId === currentUserId);
  }
}
