<div fxFlexFill>
  <h2>Typen</h2>
  <mat-list>
    <mat-list-item (click)="selectElement()" [class.selected]="!selectedElementTypes.length">Alle</mat-list-item>
    <hr />
    <mat-list-item
      *ngFor="let element of entityIdsByIfcType$ | async | keyvalue"
      (click)="selectElement(element.key, $event)"
      [class.selected]="selectedElementTypes.includes(element.key)"
    >
      {{ element.key | ifcElementType }}
    </mat-list-item>
  </mat-list>
</div>
