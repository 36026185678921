<div *ngIf="data.caption">
  <mat-form-field class="area">
    <mat-label>Vor</mat-label>
    <textarea matInput cdkTextareaAutosize [value]="data.caption" disabled></textarea>
  </mat-form-field>
</div>

<div>
  <mat-form-field class="area">
    <mat-label>Ergänzung</mat-label>
    <textarea matInput class="area-body" [(ngModel)]="data.body" (ngModelChange)="isChanged = true"></textarea>
  </mat-form-field>
</div>

<div *ngIf="data.tail">
  <mat-form-field class="area">
    <mat-label>Nach</mat-label>
    <textarea matInput cdkTextareaAutosize [value]="data.tail" disabled></textarea>
  </mat-form-field>
</div>
<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close color="warn">Abbrechen</button>
  <button mat-raised-button [mat-dialog-close]="data" color="primary" class="btn-action" [disabled]="!isChanged">Speichern</button>
</mat-dialog-actions>
