import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

import { CalculationEntry, PositionCalculationGet } from 'app/generated-client/generated-client';

@Injectable({
  providedIn: 'root'
})
export class CurrentPositionCalculationGetService {
  private currentPositionCalcSource = new ReplaySubject<PositionCalculationGet>(1);
  currentPositionCalc = this.currentPositionCalcSource.asObservable();

  private isCalculationDataLoadingSource = new Subject<boolean>();
  isCalculationDataLoading = this.isCalculationDataLoadingSource.asObservable();

  setCurrentPositionCalc(currentPositionCalc: PositionCalculationGet): void {
    this.currentPositionCalcSource.next(currentPositionCalc);
  }

  setIsCalculationDataLoading(isLoading: boolean): void {
    this.isCalculationDataLoadingSource.next(isLoading);
  }
}
