<ng-container *ngIf="!showingPdf && !requestEnRoute && !waitingForPdf">
  <pa-selecting-element-buttons (elementSelectionChanged)="elementSelection = $event"></pa-selecting-element-buttons>
  <div fxLayoutGap="15px">
    <mat-checkbox [(ngModel)]="includeSignatureField">Signaturfeld drucken</mat-checkbox>
    <mat-checkbox [(ngModel)]="includeHeaderOnlyOnFirstPage">Kopfzeile nur auf der ersten Seite drucken</mat-checkbox>
    <mat-checkbox [(ngModel)]="includePrices">Preise ausgeben</mat-checkbox>
  </div>

  <div>
    <fieldset class="texts" fxLayoutGap="15px">
      <legend>Textbausteine</legend>
      <mat-checkbox [checked]="!!startText" (click)="selectStartingText($event)">Vortext</mat-checkbox>
      <mat-checkbox [checked]="!!endText" (click)="selectEndingText($event)">Schlusstext</mat-checkbox>
    </fieldset>
  </div>
  <div fxLayoutAlign="start center" fxLayoutGap="10px">
    <button color="primary" mat-raised-button (click)="generatePdfAndShowPrintView()">PDF Erzeugen</button>
    <button color="primary" mat-raised-button (click)="generatePdfAndShowCompactPrintView()">Kompaktes PDF Erzeugen</button>
  </div>
</ng-container>

<h1 *ngIf="showingPdf && !requestEnRoute && !waitingForPdf">Bitte Druckvorschaufenster beachten.</h1>
<div class="wrapper" *ngIf="requestEnRoute || waitingForPdf">
  <mat-spinner></mat-spinner>
</div>
