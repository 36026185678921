import { NgIf } from '@angular/common';
import { Component, Inject, Optional } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { UserDisplayInfoGet } from '../../../generated-client/generated-client';

@Component({
  selector: 'pa-user-kick-notification',
  templateUrl: './user-kick-notification.component.html',
  styleUrls: ['./user-kick-notification.component.scss'],
  standalone: true,
  imports: [NgIf, MatButton]
})
export class UserKickNotificationComponent {
  constructor(
    @Optional() private matdialogRef: MatDialogRef<UserKickNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { userInfo: UserDisplayInfoGet }
  ) {}

  close(): void {
    this.matdialogRef.close();
  }
}
