import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ProjectIdComponent } from './components/project-id/project-id.component';
import { ProjectIdRoutingModule } from './project-id-routing.module';

@NgModule({
  imports: [CommonModule, ProjectIdRoutingModule, ProjectIdComponent]
})
export class ProjectIdModule {}
