<div class="wrapper">
  <div class="wrapper-table">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="selectPage">
        <th mat-header-cell *matHeaderCellDef>Ausgewählt</th>
        <td mat-cell *matCellDef="let row" matRipple>
          <mat-checkbox [checked]="getSelectedPageValue(row.id)" (change)="changeSelectionPage($event, row)"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="quantityTakeOff.name">
        <th mat-header-cell *matHeaderCellDef>Abrechnung</th>
        <td mat-cell *matCellDef="let row">{{ row.quantityTakeOff.name }}</td>
      </ng-container>
      <ng-container matColumnDef="quantityTakeOff.number">
        <th mat-header-cell *matHeaderCellDef>Nummer</th>
        <td mat-cell *matCellDef="let row">{{ row.quantityTakeOff.number }}</td>
      </ng-container>
      <ng-container matColumnDef="isChecked">
        <th mat-header-cell *matHeaderCellDef>Geprüft</th>
        <td mat-cell *matCellDef="let row" matRipple>
          <mat-checkbox [(ngModel)]="row.isChecked" disabled></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="title">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Titel</th>
        <td mat-cell *matCellDef="let row">{{ row.name }}</td>
      </ng-container>
      <ng-container matColumnDef="subContractor">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Subunternehmer</th>
        <td mat-cell *matCellDef="let row">
          {{ row.subContractorName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="buildingElementCode">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>BT Code</th>
        <td mat-cell *matCellDef="let row">{{ row.buildingElementCode }}</td>
      </ng-container>
      <ng-container matColumnDef="pageNumber">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Blatt</th>
        <td mat-cell *matCellDef="let row">{{ row.pageNumber }}</td>
      </ng-container>
      <ng-container matColumnDef="totalSum">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="right">Summe</th>
        <td mat-cell *matCellDef="let row" class="right">{{ row.totalSum | projectCurrency }}</td>
      </ng-container>
      <ng-container matColumnDef="billed">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Abgerechnet</th>
        <td mat-cell *matCellDef="let row">
          <div class="bill">
            {{ row.quantityTakeOffMarkedAsBilledAtUtc | date: 'dd.MM.yyyy' }}
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
    </table>
    <pa-upper-paginator
      [pageSizeOptions]="[5, 10, 25, 100]"
      [upperPaginated]="paginationOptions"
      [upperService]="paginationOptions"
      (page)="onPage($event)"
    >
    </pa-upper-paginator>
  </div>
  <div fxLayoutGap="12px" class="btn-wrap">
    <button mat-button mat-raised-button color="primary" (click)="saveSelectedQto()" [disabled]="!isChanged">Speichern</button>
    <button mat-button mat-raised-button color="warn" type="button" (click)="dismiss()">Abbrechen</button>
  </div>
</div>
