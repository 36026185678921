import { NgIf, AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatMenuTrigger, MatMenu, MatMenuContent, MatMenuItem } from '@angular/material/menu';

import { Subject, combineLatest, takeUntil } from 'rxjs';

import { SelectedSpecificationMessengerService } from '@shared/services/messengers/selected-specification-messenger.service';

import { TreeNodeMarkService } from 'app/areas/tree/services/tree-node-mark.service';
import { CopyMatchPositionModalComponent } from 'app/shared/components/copy-match-position-modal/copy-match-position-modal.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { TreeViewCommandType } from 'app/shared/models/tree-view-command.model';
import { WindowType } from 'app/shared/models/window-type.models';
import { CopyCalculationViewMessengerService } from 'app/shared/services/electron/copy-calculation-view-messenger.service';
import { TreeViewMessengerService } from 'app/shared/services/electron/tree-view-messenger.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';
import { ShowedViewsService } from 'app/shared/services/showed-views.service';

import { ElementTypeClassPipe } from '../../../../shared/pipes/ui-data-display/element-type-class.pipe';
import { AvaNotificationsService } from '../../../../shared/services/ava-notifications.service';
import { ClickerService } from '../../services/clicker.service';
import { TreeMessagesSeparateService } from '../../services/tree-messages-separate.service';
import { getParentNode } from '../../utils/fn';

import {
  AvaProjectContentEditClient,
  AvaProjectContentEditOperation,
  AvaProjectGet,
  IElementDto,
  PositionCalculationGet,
  PositionDto,
  ProjectDto,
  ProjectGet,
  ServiceSpecificationDto,
  ServiceSpecificationGroupDto
} from './../../../../generated-client/generated-client';
import { CopyElementViewMessengerService } from './../../../../shared/services/electron/copy-element-view-messenger.service';
import { CopyCalculationForGroupService } from './../../services/copy-calculation-for-group.service';
import { ModePageService } from './../../services/mode-page.service';
import { TreeCopyElementService } from './../../services/tree-copy-element.service';
import { TreeNodeService } from './../../services/tree-node.service';

@Component({
  selector: 'pa-tree-menu',
  templateUrl: './tree-menu.component.html',
  styleUrls: ['./tree-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatMenu, MatMenuContent, NgIf, MatMenuItem, MatButton, MatMenuTrigger, AsyncPipe, ElementTypeClassPipe]
})
export class TreeMenuComponent implements OnDestroy, OnInit {
  @ViewChild(MatMenuTrigger) menu: MatMenuTrigger;

  @Input() contextMenuPosition: { x: string; y: string } = { x: '0px', y: '0px' };
  @Input() isInnerWindow = false;
  @Input() isCopyCalculation = false;
  @Input() isCopyElementView = false;
  valueForCopy: {
    group?: any;
    position?: PositionDto;
    positionCalculation?: PositionCalculationGet;
    avaProject?: AvaProjectGet;
    project: ProjectGet;
  };
  project: ProjectGet;
  avaProjectId: string;
  selectedElement: IElementDto;
  getChangePosition = false;

  private $destroy: Subject<boolean> = new Subject<boolean>();

  selectedGroupForCopy: ServiceSpecificationGroupDto;
  copyElementViewVisible$ = this.copyElementViewMessengerService.copyElementViewVisible;
  modePage: string;
  windowTypes = WindowType;
  showedList: string[];
  isVisibleCopyCalc = false;
  positionListForCopy: { sourceProjectId: string; sourceAvaProjectId: string; sourcePositionIds: string[] } = null;
  serviceSpecification: ServiceSpecificationDto;

  markedElements$ = this.treeNodeMarkService.treeNodeMarkElements;
  constructor(
    public modePageService: ModePageService,
    private treeNodeService: TreeNodeService,
    private copyCalculationForGroupService: CopyCalculationForGroupService,
    private treeMessagesSeparateService: TreeMessagesSeparateService,
    private copyCalculationViewMessengerService: CopyCalculationViewMessengerService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private modalService: ModalService,
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private clickerService: ClickerService,
    public treeCopyElementService: TreeCopyElementService,
    private showedViewsService: ShowedViewsService,
    private treeNodeMarkService: TreeNodeMarkService,
    private copyElementViewMessengerService: CopyElementViewMessengerService,
    private treeViewMessengerService: TreeViewMessengerService,
    private avaProjectContentEditClient: AvaProjectContentEditClient,
    private avaNotificationsService: AvaNotificationsService
  ) {
    this.copyCalculationForGroupService.startCopyingGroup.pipe(takeUntil(this.$destroy)).subscribe((startCopyingGroup) => {
      if (startCopyingGroup) {
        this.selectedGroupForCopy = startCopyingGroup.groupNode;
      } else {
        this.selectedGroupForCopy = null;
      }
    });
  }

  ngOnInit(): void {
    combineLatest([this.showedViewsService.showedViews, this.showedViewsService.showedSeparatedViews])
      .pipe(takeUntil(this.$destroy))
      .subscribe((e: [string[], { name: string; rect: DOMRect }[]]) => {
        const [list, separatedList] = e;
        this.showedList = [...list, ...separatedList.map((item) => item.name)];
      });

    if (!this.isInnerWindow && !this.isCopyCalculation) {
      this.copyCalculationViewMessengerService.selectedDataForCopy.pipe(takeUntil(this.$destroy)).subscribe((data) => {
        this.valueForCopy = data;
      });

      this.copyCalculationViewMessengerService.selectedPositionListForCopy.pipe(takeUntil(this.$destroy)).subscribe((data) => {
        this.positionListForCopy = data;
      });

      this.copyCalculationViewMessengerService.copyCalculationViewVisible.pipe(takeUntil(this.$destroy)).subscribe((isVisible) => {
        if (this.isVisibleCopyCalc && !isVisible) {
          this.copyCalculationViewMessengerService.sendListPositionToCopyToMainWindow(null);
        }
        this.isVisibleCopyCalc = isVisible;
      });

      combineLatest([
        this.selectedProjectMessengerService.selectedProject,
        this.selectedSpecificationMessengerService.selectedServiceSpecification
      ])
        .pipe(takeUntil(this.$destroy))
        .subscribe(([project, s]: [ProjectGet, { avaProjectId: string; project: ProjectDto }]) => {
          this.project = project;
          this.avaProjectId = s?.avaProjectId;
          this.serviceSpecification = s?.project.serviceSpecifications[0];
        });

      this.selectedSpecificationElementMessengerService.selectedElement.pipe(takeUntil(this.$destroy)).subscribe((selectedElement) => {
        this.selectedElement = selectedElement ? Object.assign({}, selectedElement?.element) : null;
        if (this.getChangePosition) {
          this.copyPositionToNodeNext();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  addElement(element: IElementDto, place: string): void {
    this.treeNodeService.addNode(element, place).subscribe();
  }

  deleteElementContext(element: IElementDto): void {
    this.treeNodeService.deleteNode(element);
  }

  startCopyGroup(groupNode: ServiceSpecificationGroupDto, isModal?: boolean): void {
    this.copyCalculationForGroupService.setStartCopyingGroup({ groupNode, isModal });
  }

  delMarkCopyGroup(): void {
    this.copyCalculationForGroupService.delMarkCopyGroup();
  }

  startSourceCopy(node: PositionDto | ServiceSpecificationGroupDto): void {
    this.treeMessagesSeparateService.setNodeForCopy(node);
  }

  copyPositionToNode(node: PositionDto): void {
    this.getChangePosition = true;
    if (node?.id !== this.selectedElement?.id) {
      this.clickerService.clickedBySingle(node);
    } else {
      this.copyPositionToNodeNext();
    }
  }

  copyPositionToNodeNext(): void {
    this.getChangePosition = false;
    setTimeout(() => {
      this.copyCalculationViewMessengerService.changeSelectedPosition({
        element: this.valueForCopy.position,
        positionCalculation: this.valueForCopy.positionCalculation
      });
    }, 500);
  }

  copyGroupToNode(groupNode: ServiceSpecificationGroupDto, sourceProject?: ProjectGet): void {
    this.copyCalculationForGroupService.setStartCopyingGroup({ groupNode });
    this.modalService
      .openModal(CopyMatchPositionModalComponent, {
        dialogType: ConfirmationType.General,
        data: {
          sourceProject: sourceProject ?? this.project,
          sourceAvaProject: this.valueForCopy.avaProject,
          sourceGroup: this.valueForCopy.group,
          isInMainView: true
        }
      })
      .afterClosed()
      .subscribe((hasUpdatedCalculation) => {
        if (hasUpdatedCalculation) {
          // This one is sending the message if we're in main view
          this.copyCalculationViewMessengerService.raiseReloadCurrentPositionCalculationDirectly();
        }
        this.copyCalculationForGroupService.setStartCopyingGroup(null);
      });
  }

  clearValueForCopy(): void {
    this.copyCalculationViewMessengerService.changeSelectedDataForCopy(null);
  }

  startCopyElement(element: IElementDto): void {
    if (this.isInnerWindow) {
      this.copyElementViewMessengerService.sendDataFromCopyElement(element);
      return;
    }
    this.treeCopyElementService.setTreeElementForCopy(element);
  }

  insertElementCopy(node: IElementDto, treeElementForCopy: IElementDto): void {
    this.treeCopyElementService.insertTreeElementCopy(node, treeElementForCopy).subscribe();
  }

  openCopyElementView(): void {
    if (this.isInnerWindow) {
      this.treeViewMessengerService.sendDataFromTreeView({ command: TreeViewCommandType.OpenCopyElementView, data: null });
      return;
    }
    this.copyElementViewMessengerService.checkSettingAndOpenCopyElementView();
    this.menu.closeMenu();
  }

  addElementAsMarked(elementId: string): void {
    this.treeNodeMarkService.addToMarkElements(elementId);
  }

  removeElementAsMarked(elementId: string): void {
    this.treeNodeMarkService.removeFromMarkElements(elementId);
  }

  copyPositionList(node): void {
    const targetContainer = node.elementType === 'ServiceSpecificationGroupDto' ? node : getParentNode(node.id, this.serviceSpecification);
    // In this method, we want to insert the copied positions into the target container
    this.avaProjectContentEditClient
      .editAvaProjectContent(this.project.id, this.avaProjectId, {
        operation: AvaProjectContentEditOperation.MultiPositionInsert,
        multiPositionInsertOperation: {
          sourceAvaProjectId: this.positionListForCopy.sourceAvaProjectId,
          sourcePositionIds: this.positionListForCopy.sourcePositionIds,
          targetParentId: targetContainer.id
        }
      })
      .subscribe({
        next: () => {
          this.avaNotificationsService.success('Positionen erfolgreich importiert');
        },
        error: () => {
          this.avaNotificationsService.error('Fehler beim importieren der Positionen');
        }
      });
  }

  copyCalculationList(node): void {
    const targetContainer = node.elementType === 'ServiceSpecificationGroupDto' ? node : getParentNode(node.id, this.serviceSpecification);

    this.modalService
      .openModal(CopyMatchPositionModalComponent, {
        dialogType: ConfirmationType.General,
        data: {
          sourceProjectId: this.positionListForCopy.sourceProjectId,
          sourceAvaProject: this.positionListForCopy.sourceAvaProjectId,
          isInMainView: true,
          sourcePositionIds: this.positionListForCopy.sourcePositionIds,
          targetContainerId: targetContainer.id
        }
      })
      .afterClosed()
      .subscribe((hasUpdatedCalculation) => {
        if (hasUpdatedCalculation) {
          // This one is sending the message if we're in main view
          this.copyCalculationViewMessengerService.raiseReloadCurrentPositionCalculationDirectly();
        }
      });
  }
}
