<ng-container *ngIf="modePageService.modePage | async as modePage">
  <as-split
    (dragEnd)="onDragEnd($event.sizes)"
    direction="vertical"
    fxFlexFill
    #asSplitBottomRef
    *ngIf="!withoutSplitArea; else treeSelecting"
  >
    <as-split-area [size]="80" [minSize]="20">
      <ng-container *ngTemplateOutlet="treeSelecting"></ng-container>
    </as-split-area>
    <as-split-area [size]="20" *ngIf="modePage !== 'calculation' && modePage !== 'lv-editor' && showTotalsInTreeQTO">
      <ng-container *ngIf="!isSelectingMode">
        <pa-total-sums [isInnerWindow]="isInnerWindow" [selectedElement]="selectedElement"> </pa-total-sums>
      </ng-container>
    </as-split-area>
  </as-split>

  <ng-template #treeSelecting>
    <div fxLayout="row" fxLayoutAlign="start center" class="main-tree-action">
      <mat-form-field class="full-width">
        <mat-label>Suche</mat-label>
        <input matInput [ngModel]="filter" (ngModelChange)="onFilter($event)" />
      </mat-form-field>
      <mat-checkbox
        color="primary"
        *ngIf="allowFilterPositionOnlyAddition"
        matTooltip="Nur Positionen mit Testergänzung anzeigen"
        [(ngModel)]="isPositionOnlyAddition"
        (ngModelChange)="changeOnlyAddition()"
        >TE</mat-checkbox
      >
      <button
        mat-icon-button
        color="primary"
        [disabled]="projectGroupView !== ProjectGroupView.Tree"
        (click)="expandedAllElements(true)"
        matTooltip="Alle aufklappen"
      >
        <mat-icon class="layer-background">layers</mat-icon>
        <mat-icon class="layer-icon layer-icon-plus">add</mat-icon>
      </button>
      <button
        mat-icon-button
        color="primary"
        [disabled]="projectGroupView !== ProjectGroupView.Tree"
        (click)="expandedAllElements(false)"
        matTooltip="Alle zuklappen"
      >
        <mat-icon class="layer-background">layers</mat-icon>
        <mat-icon class="layer-icon layer-icon-minus">remove</mat-icon>
      </button>
      <ng-container *ngIf="isSelectingMode">
        <button mat-raised-button (click)="changeAll(true)" matTooltip="Alle hinzufügen" class="cover-selection">
          <mat-icon>add</mat-icon>
          <span>Alle</span>
        </button>
        <button mat-raised-button (click)="changeAll(false)" matTooltip="Alle entfernen" class="cover-selection">
          <mat-icon>remove</mat-icon>
          <span>Alle</span>
        </button>
      </ng-container>
    </div>
    <div class="main-wrapper-tree" *ngIf="isShowTree; else loading" #currentTree>
      <ng-container *ngIf="groupViewService.projectGroupView | async as projectGroupView">
        <pa-tree-structure-view
          *ngIf="projectGroupView === ProjectGroupView.Tree && !filter && serviceSpecification?.elements?.length && !isPositionOnlyAddition"
          [elements]="serviceSpecification.elements"
          [parentNode]="serviceSpecification"
          [hierarchyLevel]="0"
          [isSelectingMode]="isSelectingMode"
          [subPositionFromParentComponent]="subPositions"
          [choseElementId]="choseSelectedElementId"
          [treeState]="treeState"
          [selectedGroupForCopy]="selectedGroupForCopy"
          [isInnerWindow]="isInnerWindow"
          [isCopyCalculation]="isCopyCalculation"
          [isCopyElementView]="isCopyElementView"
        ></pa-tree-structure-view>
        <ng-container *ngIf="projectGroupView === ProjectGroupView.Tree && !serviceSpecification.elements.length && !filter">
          <p
            class="no-list"
            (contextmenu)="modePage === 'lv-editor' || modePage === 'calculation' ? showContextMenu($event, serviceSpecification) : null"
          >
            Keine Elemente gefunden.
          </p>
          <pa-tree-menu [contextMenuPosition]="contextMenuPosition"></pa-tree-menu>
        </ng-container>

        <pa-tree-list-view
          *ngIf="
            projectGroupView === ProjectGroupView.List ||
            projectGroupView === ProjectGroupView.PositionList ||
            ((filter || isPositionOnlyAddition) && projectGroupView === ProjectGroupView.Tree)
          "
          [choseElementId]="choseSelectedElementId"
          [filter]="filterSource$"
          [isSelectingMode]="isSelectingMode"
        ></pa-tree-list-view>

        <pa-tree-table-view
          *ngIf="projectGroupView === ProjectGroupView.Table"
          [choseElementId]="choseSelectedElementId || null"
          [filter]="filterSource$"
          [isInnerWindow]="isInnerWindow"
        ></pa-tree-table-view>
      </ng-container>
    </div>
    <ng-template #loading>
      <div fxLayoutAlign="center center" class="loader">
        <mat-spinner></mat-spinner>
      </div>
    </ng-template>
  </ng-template>
</ng-container>
