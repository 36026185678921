import { MasterDataProductGet, PriceComponentType } from 'app/generated-client/generated-client';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationMainService } from './main/pagination-main.service';

@Injectable({
  providedIn: 'root'
})
export class MasterDataProductsService extends PaginationMainService<MasterDataProductGet> {
  constructor(protected http: HttpClient) {
    super(http);
    this.beginBaseUrl('/api/master-data/products');
  }

  setPriceComponentType(priceComponentType: PriceComponentType): void {
    this.setQueryParameter('priceComponentType', priceComponentType);
  }
}
