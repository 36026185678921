<h2 mat-dialog-title class="modal__header">Einstellungen</h2>
<div mat-dialog-content>
  <p>Hier werden Benutzereinstellungen verwaltet.</p>
  <div class="modal__content" fxLayout="column" fxLayoutGap="12px" *ngIf="currentUserSettings">
    <mat-checkbox
      color="primary"
      [(ngModel)]="currentUserSettings.showMultiComponentsViewInMainWindow"
      (ngModelChange)="changeSettings()"
      *ngIf="isElectron"
    >
      Erweiterungsfenster im Hauptfenster der Anwendung anzeigen
    </mat-checkbox>
    <fieldset>
      <legend>Abrechnung</legend>
      <div fxLayout="column">
        <mat-checkbox
          color="primary"
          [(ngModel)]="currentUserSettings.showShortTextColumnsInQuantityTakeOff"
          (ngModelChange)="changeSettings()"
        >
          Spalte für Kurztexte immer anzeigen
        </mat-checkbox>
        <mat-checkbox
          color="primary"
          [(ngModel)]="currentUserSettings.showReferenceNameInQuantityTakeOff"
          (ngModelChange)="changeSettings()"
        >
          Spalte für Referenzen in Abrechnung immer anzeigen
        </mat-checkbox>
        <mat-checkbox color="primary" [(ngModel)]="currentUserSettings.showUnitTagsInQuantityTakeOff" (ngModelChange)="changeSettings()">
          Einheitenspalte in Abrechnungen anzeigen
        </mat-checkbox>
        <mat-checkbox color="primary" [(ngModel)]="currentUserSettings.showImagesInQuantityTakeOff" (ngModelChange)="changeSettings()">
          Bilder in Abrechnungen direkt anzeigen
        </mat-checkbox>
        <mat-checkbox
          color="primary"
          [(ngModel)]="currentUserSettings.showImageColumnsInQuantityTakeOff"
          (ngModelChange)="changeSettings()"
        >
          Spalte für Bilder immer anzeigen
        </mat-checkbox>
        <mat-checkbox
          color="primary"
          [(ngModel)]="currentUserSettings.showPositionTypesInQuantityTakeOff"
          (ngModelChange)="changeSettings()"
        >
          Positions- und Preistyp immer anzeigen
        </mat-checkbox>
        <mat-checkbox
          color="primary"
          [(ngModel)]="currentUserSettings.showPositionTotalInvoicedInSearchWindowInQuantityTakeOff"
          (ngModelChange)="changeSettings()"
        >
          Positionsabrechnungsmenge im Suchfenster bei Abrechnungen anzeigen
        </mat-checkbox>
        <mat-checkbox
          color="primary"
          [(ngModel)]="currentUserSettings.showsTotalsInQuantityTakeOffBelowTree"
          (ngModelChange)="changeSettings()"
        >
          Summen in Abrechnung unter LV-Baum anzeigen
        </mat-checkbox>
        <mat-checkbox
          color="primary"
          [(ngModel)]="currentUserSettings.showsTotalsInQuantityTakeOffBelowTable"
          (ngModelChange)="changeSettings()"
          matTooltip="Nur, wenn der LV-Baum nicht angezeigt wird"
        >
          Summen in Abrechnung unter Tabelle anzeigen
        </mat-checkbox>
        <mat-checkbox color="primary" [(ngModel)]="currentUserSettings.showPositionQtoInSingleTable" (ngModelChange)="changeSettings()">
          Positionsabrechnung als Gesamttabelle anzeigen
        </mat-checkbox>
        <mat-checkbox color="primary" [(ngModel)]="currentUserSettings.showTooltipsInQtoTable" (ngModelChange)="changeSettings()">
          Tooltips in Zellen anzeigen
        </mat-checkbox>
        <mat-checkbox
          color="primary"
          [(ngModel)]="currentUserSettings.showButtonsToManageFontStylesInContextMenu"
          (ngModelChange)="changeSettings()"
        >
          Schriftart- und Schriftgrößen-Buttons im Kontextmenü anzeigen
        </mat-checkbox>
        <mat-checkbox
          color="primary"
          [(ngModel)]="currentUserSettings.markInvoicesAutomaticallyAsBilledAfterExport"
          (ngModelChange)="changeSettings()"
        >
          Rechnung nach Druck automatisch als abgerechnet markieren
        </mat-checkbox>
        <mat-checkbox color="primary" [(ngModel)]="currentUserSettings.allowOverwriteMode" (ngModelChange)="changeSettings()">
          Via `EINFG` zwischen Überschreibmodus umschalten
        </mat-checkbox>
        <mat-checkbox color="primary" [(ngModel)]="currentUserSettings.stayOnInvalidQtoFormula" (ngModelChange)="changeSettings()">
          Bei ungültiger Formel in Zelle bleiben
        </mat-checkbox>
      </div>
    </fieldset>
    <mat-checkbox
      color="primary"
      [(ngModel)]="currentUserSettings.showCompactPositionsViewByDefaultInCalculation"
      (ngModelChange)="changeSettings()"
    >
      Kompakte Positionsdetails in Kalkulation
    </mat-checkbox>
    <div class="select-wrap">
      <mat-label for="numberSelect">Schriftgröße in Kalkulationstabelle </mat-label>
      <mat-select id="numberSelect" [(ngModel)]="currentUserSettings.calculationTableFontSize">
        <mat-option [value]="11">Klein</mat-option>
        <mat-option [value]="14">Mittel</mat-option>
        <mat-option [value]="17">Groß</mat-option>
      </mat-select>
    </div>
    <mat-checkbox color="primary" [(ngModel)]="currentUserSettings.disableConfirmModalWindow" (ngModelChange)="changeSettings()">
      Bestätigung beim Löschen deaktivieren
    </mat-checkbox>
    <mat-checkbox
      color="primary"
      [(ngModel)]="currentUserSettings.promptForConfirmationWhenDeletingRows"
      (ngModelChange)="changeSettings()"
    >
      Bestätigung beim Löschen von Zeilen erzwingen
    </mat-checkbox>
    <mat-checkbox color="primary" [(ngModel)]="currentUserSettings.automaticallyOpenLastVisitedElement" (ngModelChange)="changeSettings()">
      Letzte geöffnete Position bei Kalkulation und Abrechnung merken
    </mat-checkbox>
    <mat-checkbox
      color="primary"
      [(ngModel)]="currentUserSettings.automaticallyOpenLastVisitedQuantityEstimationElement"
      (ngModelChange)="changeSettings()"
    >
      Letzte geöffnete Position bei Mengenermittlung merken
    </mat-checkbox>
    <mat-checkbox color="primary" [(ngModel)]="currentUserSettings.useTwoDigitDateFormat" (ngModelChange)="changeSettings()">
      Zweistellige Jahreszahl für Datum verwenden
    </mat-checkbox>
    <mat-checkbox
      color="primary"
      [(ngModel)]="currentUserSettings.onlySelectPositionsWithHotkeyNavigation"
      (ngModelChange)="changeSettings()"
    >
      Nur Positionen bei Hotkeynavigation (F11 & F12) auswählen
    </mat-checkbox>
    <mat-checkbox
      *ngIf="isShowSettingForPdfWindow"
      color="primary"
      [(ngModel)]="currentUserSettings.showFullViewOverlayWhenOpeningPdfPreview"
      (ngModelChange)="changeSettings()"
    >
      PDF Vorschau soll Hauptfenster immer überlagern
    </mat-checkbox>
    <mat-checkbox color="primary" [(ngModel)]="currentUserSettings.insertCopiedCalculationToRedMarker" (ngModelChange)="changeSettings()">
      Kopierte Zeilen in Kalkulation am roten Marker einfügen
    </mat-checkbox>
    <mat-checkbox
      color="primary"
      [(ngModel)]="currentUserSettings.copyWholeSubPositionWhenCopyingSubPositionRow"
      (ngModelChange)="changeSettings()"
    >
      Ganze Unterposition beim Kopieren von Kalkulationszeile kopieren
    </mat-checkbox>
    <mat-checkbox
      color="primary"
      [(ngModel)]="currentUserSettings.showTreeFilterForPositionsWithTextAdditions"
      (ngModelChange)="changeSettings()"
    >
      Filter für Positionen mit Textergänzungen anzeigen
    </mat-checkbox>
  </div>
  <button mat-raised-button color="primary" (click)="openSubPositionColorSettings()">Farbschema Unterpositionen</button>
</div>
<mat-dialog-actions>
  <button mat-button mat-raised-button color="primary" type="button" mat-dialog-close>Schließen</button>
</mat-dialog-actions>
