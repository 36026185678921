<div mat-dialog-content>
  <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
  <div class="wrapper" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between">
    <pa-invoice-pdf-table></pa-invoice-pdf-table>
    <ng-container *ngIf="selectedAttachmentService.selectedAttachment | async; else upload">
      <pa-invoice-pdf-preview (saveAttachment)="savedAttachment($event)" [isSelectedMode]="isSelectedMode"></pa-invoice-pdf-preview>
    </ng-container>
    <ng-template #upload>
      <pa-upload-invoice-pdf-file></pa-upload-invoice-pdf-file>
    </ng-template>
  </div>
</div>
