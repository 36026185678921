import { NgIf } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogClose } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

import { Subject } from 'rxjs';

import { BuildingElementCodeGet } from '../../../../../../../../generated-client/generated-client';
import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-building-element-codes-new-modal',
  templateUrl: './building-element-codes-new-modal.component.html',
  styleUrls: ['./building-element-codes-new-modal.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    MatDialogContent,
    ReactiveFormsModule,
    FlexLayoutDirective,
    MatFormField,
    MatLabel,
    MatInput,
    MatButton,
    MatDialogClose
  ]
})
export class BuildingElementCodesNewModalComponent implements OnInit, OnDestroy {
  newItemForm: FormGroup;
  $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      content: BuildingElementCodeGet;
      heading?: string;
    }
  ) {}

  ngOnInit(): void {
    this.newItemForm = new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', Validators.required),
      description: new FormControl('')
    });
    if (this.data?.content) {
      this.newItemForm.patchValue({ ...this.data?.content });
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }
}
