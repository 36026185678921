import { NgIf, AsyncPipe } from '@angular/common';
import { Component, Inject, Optional } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogClose } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';

import { SelectedAttachmentService } from 'app/shared/services/messengers/selected-attachment.service';

import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';

import { InvoicePdfPreviewComponent } from '../invoice-pdf-preview/invoice-pdf-preview.component';
import { InvoicePdfTableComponent } from '../invoice-pdf-table/invoice-pdf-table.component';
import { UploadInvoicePdfFileComponent } from '../upload-invoice-pdf-file/upload-invoice-pdf-file.component';

@Component({
  selector: 'pa-invoice-pdf-file',
  templateUrl: './invoice-pdf-file.component.html',
  styleUrls: ['./invoice-pdf-file.component.scss'],
  standalone: true,
  imports: [
    MatDialogContent,
    MatIconButton,
    MatDialogClose,
    MatIcon,
    FlexLayoutDirective,
    InvoicePdfTableComponent,
    NgIf,
    InvoicePdfPreviewComponent,
    UploadInvoicePdfFileComponent,
    AsyncPipe
  ]
})
export class InvoicePdfFileComponent {
  isSelectedMode = true;
  constructor(
    public selectedAttachmentService: SelectedAttachmentService,
    @Optional() private dialogRef: MatDialogRef<InvoicePdfFileComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    if (this.data) {
      this.isSelectedMode = this.data.isSelectingMode;
    }
  }

  savedAttachment(savedAttachment: {
    quantityTakeOffAttachmentId?: string | null;
    quantityTakeOffAttachmentImageFileId?: string | null;
  }): void {
    this.dialogRef.close(savedAttachment);
  }
}
