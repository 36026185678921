import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';

import { NgxDropzoneModule } from 'ngx-dropzone';

export interface UploadFileSettings {
  label?: string;
  height?: string;
  maxWidth?: string;
  isMultipleSelection?: boolean;
  accept?: string;
}

@Component({
  selector: 'pa-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
  standalone: true,
  imports: [NgxDropzoneModule, MatButtonModule, FlexLayoutDirective]
})
export class UploadFileComponent {
  @Input() uploadFileSettings: UploadFileSettings;
  @Output() uploadFile = new EventEmitter<File[]>();

  constructor() {}

  onSelectFromInput(addedFiles: FileList): void {
    const files: File[] = [];
    for (let i = 0; i < addedFiles.length; i++) {
      files.push(addedFiles.item(i));
    }

    this.onSelect(files);
  }

  onSelect(addedFiles: File[]): void {
    this.uploadFile.emit(addedFiles);
  }
}
