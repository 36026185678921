<h2 mat-dialog-title>Neue Preisanfrage</h2>
<div mat-dialog-content [formGroup]="createPriceInquiryRequestForm">
  <div fxLayout="row" fxLayoutGap="10px">
    <div fxLayout="column">
      <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" required [readonly]="!!selectedContac" />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Kommentar</mat-label>
        <input matInput formControlName="comments" />
      </mat-form-field>
    </div>
    <div fxLayout="column">
      <button mat-raised-button color="primary" (click)="chooseContact()">Gespeicherten Kontakt auswählen</button>
      <mat-checkbox formControlName="isSelectedContact" (change)="changeSelectedContact($event)"> Kontakt verknüpft</mat-checkbox>
    </div>
  </div>

  <button mat-raised-button color="primary" (click)="create()" [disabled]="!createPriceInquiryRequestForm.valid">Erstellen</button>
</div>
