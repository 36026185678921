import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';

import { ConfirmationType } from '../../../../shared/models/dialog-config.model';
import { TaxRatesService } from '../../../../shared/services/lightquery/tax-rates.service';
import { ModalService } from '../../../../shared/services/modal.service';

import { MasterDataTaxRatesAddModalComponent } from '../master-data-tax-rates-add-modal/master-data-tax-rates-add-modal.component';
import { MasterDataTaxRatesTableComponent } from '../master-data-tax-rates-table/master-data-tax-rates-table.component';

@Component({
  selector: 'pa-master-data-tax-rates',
  templateUrl: './master-data-tax-rates.component.html',
  styleUrls: ['./master-data-tax-rates.component.scss'],
  standalone: true,
  imports: [MatButton, MasterDataTaxRatesTableComponent]
})
export class MasterDataTaxRatesComponent {
  constructor(
    private taxRatesService: TaxRatesService,
    private modalService: ModalService
  ) {}

  createTaxRate(): void {
    this.modalService
      .openModal(MasterDataTaxRatesAddModalComponent, { dialogType: ConfirmationType.General })
      .afterClosed()
      .subscribe((e) => {
        if (e) {
          this.taxRatesService.forceRefresh();
        }
      });
  }
}
