import { DataSource } from '@angular/cdk/table';
import { NgIf, NgFor } from '@angular/common';
import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatMenuTrigger, MatMenu, MatMenuContent, MatMenuItem } from '@angular/material/menu';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject, combineLatest } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { AvaNotificationsService } from '@shared/services/ava-notifications.service';
import { PrintViewMessengerService } from '@shared/services/electron/print-view-messenger.service';

import {
  PageQuantityTakeOffGet,
  PageQuantityTakeOffsClient,
  ProjectGet,
  ProjectStatus,
  QuantityTakeOffCalculationType,
  QuantityTakeOffGet,
  QuantityTakeOffType,
  QuantityTakeOffsRebExportClient,
  RebExportType,
  ReportsClient,
  UserSettings
} from 'app/generated-client/generated-client';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { ContextMenuSettingsService } from 'app/shared/services/context-menu-settings.service';
import { PageQuantityTakeOffsService } from 'app/shared/services/lightquery/page-quantity-take-offs.service';
import { PagesAllAvaProjectService } from 'app/shared/services/lightquery/pages-all-ava-project.service';
import { QuantityTakeOffsService } from 'app/shared/services/lightquery/quantity-take-offs.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';
import { UserSettingsService } from 'app/shared/services/user-settings.service';

import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';

import { InvoicesPagesComponent } from '../invoices-pages/invoices-pages.component';
import { NewInvoicePageComponent } from '../new-invoice-page/new-invoice-page.component';
import { NewInvoiceComponent } from '../new-invoice/new-invoice.component';
import { PaginationResult } from 'ng-lightquery';

@Component({
  selector: 'pa-invoices-all-pages',
  templateUrl: './invoices-all-pages.component.html',
  styleUrls: ['./invoices-all-pages.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FlexLayoutDirective,
    MatButton,
    InvoicesPagesComponent,
    MatMenu,
    MatMenuContent,
    NgFor,
    MatMenuItem,
    MatMenuTrigger,
    MatProgressSpinner
  ]
})
export class InvoicesAllPagesComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) private sort: MatSort;
  @ViewChildren(MatMenuTrigger) private contextMenu: QueryList<MatMenuTrigger>;
  dataSource: DataSource<QuantityTakeOffGet>;
  quantityTakeOffsPaginated: PaginationResult<QuantityTakeOffGet>;
  structureView: string;
  projectId: string;
  avaProjectId: string;
  isLoading = false;
  columnsToDisplay: string[];
  isHavePages = false;
  typeQTO: QuantityTakeOffType;
  isReadOnly: boolean;
  userSettings: UserSettings;
  latestQTO: QuantityTakeOffGet;
  onlyPagesQTO: QuantityTakeOffGet[];
  contextMenuPosition = { x: '0px', y: '0px' };
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private modalService: ModalService,
    public quantityTakeOffsService: QuantityTakeOffsService,
    private route: ActivatedRoute,
    private router: Router,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private userSettingsService: UserSettingsService,
    private pagesAllAvaProjectService: PagesAllAvaProjectService,
    private pageQuantityTakeOffsService: PageQuantityTakeOffsService,
    private contextMenuSettingsService: ContextMenuSettingsService,
    private pageQuantityTakeOffsClient: PageQuantityTakeOffsClient,
    private quantityTakeOffsRebExportClient: QuantityTakeOffsRebExportClient,
    private avaNotificationsService: AvaNotificationsService,
    private reportsClient: ReportsClient,
    private printViewMessengerService: PrintViewMessengerService
  ) {}

  ngOnInit(): void {
    this.structureView = this.router.url.includes('invoices') ? 'invoices' : 'estimations';

    if (this.structureView === 'invoices') {
      this.typeQTO = QuantityTakeOffType.Invoice;
    } else {
      this.typeQTO = QuantityTakeOffType.QuantityEstimation;
    }

    combineLatest([
      this.selectedProjectMessengerService.selectedProject,
      this.selectedSpecificationMessengerService.selectedServiceSpecification
    ])
      .pipe(takeUntil(this.$destroy))
      .subscribe(([project, avaProject]: [ProjectGet, { avaProjectId: string }]) => {
        this.projectId = project?.id;
        this.isReadOnly = project?.status === ProjectStatus.Archived || project?.status === ProjectStatus.Locked;
        this.avaProjectId = avaProject?.avaProjectId;
        if (this.projectId && this.avaProjectId) {
          if (this.typeQTO === QuantityTakeOffType.Invoice) {
            this.pageQuantityTakeOffsClient
              .getAllPageQuantityTakeOffs(this.projectId, this.avaProjectId, null, false, false, null, '', '', 1, undefined)
              .subscribe((e) => {
                this.isHavePages = !!e.data.length;
              });
          }

          this.checkNoPositionQTO();
        }
      });

    this.userSettingsService.currentFullSettings.pipe(takeUntil(this.$destroy)).subscribe((e: UserSettings) => {
      this.userSettings = e;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  showAddNewModal(): void {
    this.modalService
      .openModal(NewInvoiceComponent, { dialogType: ConfirmationType.General })
      .afterClosed()
      .subscribe((qto: QuantityTakeOffGet) => {
        if (qto) {
          this.quantityTakeOffsService.forceRefresh();
          this.checkNoPositionQTO();
        }
      });
  }

  showSheet(): void {
    this.router.navigate(['sheets'], {
      relativeTo: this.route
    });
  }

  changeOnlyPagesMode() {
    this.router.navigate(['..'], {
      relativeTo: this.route,
      queryParams: {
        ignoreAllPagesCheck: true
      }
    });
  }

  checkNoPositionQTO(): void {
    this.quantityTakeOffsService.getAll({ quantityTakeOffType: this.typeQTO }).subscribe((e: QuantityTakeOffGet[]) => {
      if (e?.length) {
        this.onlyPagesQTO = e.filter((item) => item.calculationType === QuantityTakeOffCalculationType.ByPage && !item.markedAsBilledAtUtc);
        if (this.onlyPagesQTO.length) {
          this.onlyPagesQTO.sort((a1, a2) => (a1.createdAtUtc > a2.createdAtUtc ? -1 : 1));
          this.latestQTO = this.onlyPagesQTO[0];
        }
      }
    });
  }

  createPagesForLastQTO(): void {
    this.modalService
      .openModal(NewInvoicePageComponent, {
        dialogType: ConfirmationType.General,
        data: { structureView: this.structureView, quantityTakeOffId: null }
      })
      .afterClosed()
      .subscribe((pageQto: PageQuantityTakeOffGet) => {
        if (pageQto) {
          this.pagesAllAvaProjectService.forceRefresh();
          this.pageQuantityTakeOffsService.forceRefresh();
          setTimeout(() => {
            if (pageQto.quantityTakeOffId) {
              this.router.navigate(['..', pageQto.quantityTakeOffId, 'pages', pageQto.id], {
                relativeTo: this.route
              });
            } else {
              this.router.navigate(['..', 'standalone-pages', pageQto.id], {
                relativeTo: this.route
              });
            }
          }, 1);
        }
      });
  }

  onContextMenu(event): void {
    if (this.onlyPagesQTO?.length > 1) {
      this.contextMenuSettingsService.setDefaultSettings(event, null, this.contextMenuPosition, this.contextMenu.first);
    }
  }

  changeLastQto(page: QuantityTakeOffGet): void {
    this.latestQTO = page;
    this.createPagesForLastQTO();
  }

  printPages(): void {
    this.isLoading = true;
    this.quantityTakeOffsRebExportClient
      .exportQuantityTakeOffPagesToRebFile(this.projectId, this.avaProjectId, RebExportType.D11, null, {})
      .pipe(switchMap((r) => this.reportsClient.getPageQtoOverviewReport(this.projectId, this.avaProjectId, r.rebExportReport)))
      .subscribe({
        next: (report) => {
          this.printViewMessengerService.showPdfPreview(
            report.htmlReport,
            'Aufmaßblattübersicht',
            report.isLandscape,
            report.pdfReportBase64,
            false
          );
          this.printViewMessengerService.waitForPrintViewReady().subscribe(() => {
            this.isLoading = false;
          });
        },
        error: () => {
          this.isLoading = false;
          this.avaNotificationsService.error('Fehler beim Laden der Aufmaßblattübersicht');
        }
      });
  }
}
