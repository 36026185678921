import { NgIf, NgFor } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogClose } from '@angular/material/dialog';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { FlatElementsService } from 'app/areas/tree/services/flat-elements.service';
import {
  AvaProjectRecalculationClient,
  DeductionFactorsClient,
  PositionDto,
  ProjectDto,
  ServiceSpecificationGroupDto
} from 'app/generated-client/generated-client';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';

import { ElementTypeClassPipe } from '../../../../../../shared/pipes/ui-data-display/element-type-class.pipe';
import { ProjectCurrencyPipe } from '../../../../../../shared/pipes/ui-data-display/project-currency.pipe';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-service-specification-deduction-handling-modal',
  templateUrl: './service-specification-deduction-handling-modal.component.html',
  styleUrls: ['./service-specification-deduction-handling-modal.component.scss'],
  standalone: true,
  imports: [
    MatDialogContent,
    FlexLayoutDirective,
    MatCheckbox,
    FormsModule,
    NgIf,
    ReactiveFormsModule,
    NgFor,
    MatFormField,
    MatLabel,
    MatInput,
    MatSuffix,
    MatButton,
    MatDialogClose,
    ProjectCurrencyPipe,
    ElementTypeClassPipe
  ]
})
export class ServiceSpecificationDeductionHandlingModalComponent implements OnInit, OnDestroy {
  itemForm: FormGroup;
  isChanged: boolean;

  private projectCopy: ProjectDto | null = null;
  elements: (PositionDto | ServiceSpecificationGroupDto)[] = [];
  deductedPrices: number[] = [];
  showGroups = true;
  showPositions = false;
  isSaving = false;

  private recalculateSource = new Subject<void>();
  private $destroy = new Subject<void>();

  constructor(
    @Optional() private dialogRef: MatDialogRef<ServiceSpecificationDeductionHandlingModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      avaProject: ProjectDto;
      projectId: string;
    },
    private fb: FormBuilder,
    private avaProjectRecalculationClient: AvaProjectRecalculationClient,
    private deductionFactorsClient: DeductionFactorsClient,
    private avaNotificationsService: AvaNotificationsService
  ) {}

  ngOnInit(): void {
    if (!this.data?.avaProject) {
      return;
    }
    this.projectCopy = JSON.parse(JSON.stringify(this.data.avaProject));
    this.elements = FlatElementsService.getFlatElements(this.projectCopy.serviceSpecifications[0]);

    this.itemForm = this.fb.group({
      items: this.fb.array([
        this.fb.group({
          deductionFactor: this.transformPercent(this.projectCopy.serviceSpecifications[0].deductionFactor),
          absoluteDeduction: this.projectCopy.serviceSpecifications[0].absoluteDeduction,
          elemenentType: null,
          text: this.projectCopy.serviceSpecifications[0].name ?? 'LV'
        }),
        ...this.elements.map((element) =>
          this.fb.group({
            deductionFactor: this.transformPercent(element.deductionFactor),
            absoluteDeduction: (element as any).absoluteDeduction,
            elementType: element.elementType,
            text: element.itemNumber?.stringRepresentation + ' - ' + element.shortText
          })
        )
      ])
    });

    this.setPricesFromProject(this.projectCopy);

    let ignoreEntryChanges = true;

    let itemsArray = (this.itemForm.controls['items'] as FormArray).controls[0];
    if (this.projectCopy.serviceSpecifications[0].absoluteDeduction) {
      itemsArray.get('deductionFactor').disable();
      itemsArray.patchValue({
        deductionFactor: null
      });
    }

    for (let i = 0; i < this.elements.length; i++) {
      itemsArray = (this.itemForm.controls['items'] as FormArray).controls[i + 1];
      if (this.elements[i].elementType === 'PositionDto') {
        itemsArray.get('absoluteDeduction').disable();
      }

      if (this.elements[i]['absoluteDeduction']) {
        itemsArray.get('deductionFactor').disable();
        itemsArray.patchValue({
          deductionFactor: null
        });
      }
    }

    ignoreEntryChanges = false;

    this.itemForm.valueChanges.pipe(takeUntil(this.$destroy)).subscribe(() => {
      if (ignoreEntryChanges) {
        return;
      }

      ignoreEntryChanges = true;
      this.isChanged = true;

      for (let i = 0; i < this.itemForm.value.items.length; i++) {
        const element = this.itemForm.value.items[i];
        const itemsArray = (this.itemForm.controls['items'] as FormArray).controls[i];
        if (element.absoluteDeduction) {
          itemsArray.patchValue({
            deductionFactor: null
          });
          itemsArray.get('deductionFactor').disable();
        } else {
          itemsArray.get('deductionFactor').enable();
        }
      }

      ignoreEntryChanges = false;
      this.recalculateSource.next();
    });

    this.recalculateSource.pipe(takeUntil(this.$destroy), debounceTime(500)).subscribe(() => {
      this.applyFormDataToElementCopy();
      this.avaProjectRecalculationClient.recalculateAvaProject(this.projectCopy).subscribe((r) => {
        this.setPricesFromProject(r);
      });
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  private setPricesFromProject(project: ProjectDto): void {
    this.deductedPrices = [];
    this.deductedPrices.push(project.serviceSpecifications[0].deductedPrice);

    const flatElements = FlatElementsService.getFlatElements(project.serviceSpecifications[0]);

    for (let i = 0; i < flatElements.length; i++) {
      this.deductedPrices.push((<any>flatElements[i]).deductedPrice);
    }
  }

  getArray(): FormArray {
    return this.itemForm.get('items') as FormArray;
  }

  save(): void {
    if (this.isSaving) {
      return;
    }

    this.isSaving = true;
    this.applyFormDataToElementCopy();

    this.deductionFactorsClient.setDeductionFactorsForProject(this.data.projectId, this.data.avaProject.id, this.projectCopy).subscribe(
      (project: ProjectDto) => {
        this.avaNotificationsService.success('Nachlässe gespeichert');
        this.dialogRef.close(project);
        this.isSaving = false;
      },
      () => this.avaNotificationsService.error('Fehler beim Speichern der Nachlässe')
    );
  }

  private applyFormDataToElementCopy(): void {
    this.projectCopy.serviceSpecifications[0].deductionFactor = this.transformDigit(this.itemForm.value.items[0].deductionFactor, true);
    this.projectCopy.serviceSpecifications[0].absoluteDeduction = this.transformDigit(
      this.itemForm.value.items[0].absoluteDeduction,
      false
    );
    for (let i = 0; i < this.elements.length; i++) {
      this.elements[i].deductionFactor = this.transformDigit(this.itemForm.value.items[i + 1].deductionFactor, true);
      if (this.elements[i].elementType === 'ServiceSpecificationGroupDto') {
        (this.elements[i] as ServiceSpecificationGroupDto).absoluteDeduction = this.transformDigit(
          this.itemForm.value.items[i + 1].absoluteDeduction,
          false
        );
      }
    }
  }

  private transformPercent(data: number): number {
    const rounded: number = Math.round((data + Number.EPSILON) * 10000) / 100;
    return (<any>rounded.toFixed(2).replace('.', ',')) as number;
  }

  public transformDigit(data: number, toPercentage: boolean): number {
    if (typeof data === 'string') {
      data = +(<string>data).replace(',', '.');
    }
    return toPercentage ? data / 100 : data;
  }
}
