<div fxLayoutAlign="center center" fxFlexFill>
  <mat-card>
    <mat-card-header>
      <h1 mat-card-title>Login</h1>
    </mat-card-header>
    <mat-card-content>
      <form #loginForm="ngForm" (ngSubmit)="login()">
        <p>
          <mat-form-field>
            <mat-label>Benutzername oder Email</mat-label>
            <span matPrefix>
              <mat-icon class="icon">person</mat-icon>
            </span>
            <input
              matInput
              required
              name="identifier"
              #loginInput
              [(ngModel)]="credentials.identifier"
              (change)="resetErrorDisplay()"
              #identifier="ngModel"
            />
            <mat-error *ngIf="identifier.hasError('required')"> Bitte eingeben </mat-error>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field>
            <mat-label>Passwort</mat-label>
            <span matPrefix>
              <mat-icon class="icon">lock</mat-icon>
            </span>
            <input
              type="password"
              matInput
              required
              name="password"
              [(ngModel)]="credentials.password"
              (change)="resetErrorDisplay()"
              #password="ngModel"
            />
            <mat-error *ngIf="password.hasError('required')"> Bitte eingeben </mat-error>
          </mat-form-field>
        </p>
        <p>
          <mat-checkbox name="save" [(ngModel)]="savePassword">Passwort speichern</mat-checkbox>
        </p>
        <p class="login-failed-message" *ngIf="showLoginError" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>warning</mat-icon>
          <strong>Login fehlgeschlagen.</strong>
        </p>
        <button class="login-button" id="login-button" mat-raised-button [disabled]="!loginForm.valid || requestEnRoute">Login</button>
      </form>
    </mat-card-content>
  </mat-card>
</div>
