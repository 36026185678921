import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject, Subject, filter, takeUntil } from 'rxjs';

import { AvaProjectElementMarkingsClient, MarkedElementGet, MarkedElementsGet } from 'app/generated-client/generated-client';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

@Injectable({
  providedIn: 'root'
})
export class TreeNodeMarkService implements OnDestroy {
  private treeNodeMarkElementsSource = new ReplaySubject<{ [elementId: string]: MarkedElementGet }>(1);
  treeNodeMarkElements = this.treeNodeMarkElementsSource.asObservable();
  private $destroy: Subject<boolean> = new Subject<boolean>();
  private projectId: string;
  private avaProjectId: string;
  constructor(
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private avaProjectElementMarkingsClient: AvaProjectElementMarkingsClient
  ) {
    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        takeUntil(this.$destroy),
        filter(s => !!s)
      )
      .subscribe(s => {
        this.projectId = s?.parentProjectId;
        this.avaProjectId = s?.avaProjectId;
      });
  }
  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  getMarkElementForCurrentServSpec(): void {
    this.avaProjectElementMarkingsClient
      .getAllMarkedElementsForAvaProject(this.projectId, this.avaProjectId)
      .subscribe((e: MarkedElementsGet) => {
        if (e && e.elements) {
          this.setTreeNodeMarkElements(e.elements);
        } else {
          this.setTreeNodeMarkElements({});
        }
      });
  }

  private setTreeNodeMarkElements(e: { [elementId: string]: any }): void {
    this.treeNodeMarkElementsSource.next(e);
  }

  addToMarkElements(elementId: string): void {
    this.avaProjectElementMarkingsClient
      .markElementInAvaProject(this.projectId, this.avaProjectId, elementId)
      .subscribe((e: MarkedElementsGet) => {
        this.setTreeNodeMarkElements(e.elements);
      });
  }

  removeFromMarkElements(elementId: string): void {
    this.avaProjectElementMarkingsClient
      .unmarkElementInAvaProject(this.projectId, this.avaProjectId, elementId)
      .subscribe((e: MarkedElementsGet) => {
        this.setTreeNodeMarkElements(e.elements);
      });
  }
}
