import { PositionQuantityTakeOffGet, QuantityTakeOffGet, QuantityTakeOffsClient } from 'app/generated-client/generated-client';
import { ReplaySubject, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SelectedQuantityTakeOffMessengerService {
  private selectedQuantityTakeOffSource = new ReplaySubject<QuantityTakeOffGet>(1);
  selectedQuantityTakeOff = this.selectedQuantityTakeOffSource.asObservable();

  private selectedQuantityTakeOffPositionSource = new ReplaySubject<PositionQuantityTakeOffGet>(1);
  selectedQuantityTakeOffPosition = this.selectedQuantityTakeOffPositionSource.asObservable();

  private selectedQuantityTakeOffPageSource = new ReplaySubject<string>(1);
  selectedQuantityTakeOffPage = this.selectedQuantityTakeOffPageSource.asObservable();

  private changedQuantityTakeOffPageSource = new Subject<boolean>();
  changedQuantityTakeOffPage = this.changedQuantityTakeOffPageSource.asObservable();

  private someChangesQuantityTakeOffSource = new Subject<boolean>();
  someChangesQuantityTakeOff = this.someChangesQuantityTakeOffSource.asObservable();

  private errorRowIndexQuantityTakeOffSource = new ReplaySubject<{ isSameUrl?: boolean; errorRowIndex: number } | null>(1);
  errorRowIndexQuantityTakeOff = this.errorRowIndexQuantityTakeOffSource.asObservable();

  manualPageChangeRequested = new Subject<boolean>();

  constructor(private quantityTakeOffsClient: QuantityTakeOffsClient) {
    this.setErrorRowIndex(null);
  }

  setErrorRowIndex(value: { isSameUrl?: boolean; errorRowIndex: number } | null): void {
    this.errorRowIndexQuantityTakeOffSource.next(value);
  }

  setSelectedQuantityTakeOff(quantityTakeOff: QuantityTakeOffGet): void {
    this.selectedQuantityTakeOffSource.next(quantityTakeOff);
  }

  setSelectedQuantityTakeOffPage(pageId: string): void {
    this.selectedQuantityTakeOffPageSource.next(pageId);
  }

  setChangedQuantityTakeOffPage(pageId: string): void {
    this.selectedQuantityTakeOffPageSource.next(pageId);
    this.changedQuantityTakeOffPageSource.next(true);
  }

  setSelectedQuantityTakeOffPosition(positionQuantityTakeOff: PositionQuantityTakeOffGet): void {
    this.selectedQuantityTakeOffPositionSource.next(positionQuantityTakeOff);
  }

  setSomeChangesQuantityTakeOff(): void {
    this.someChangesQuantityTakeOffSource.next(true);
  }

  changeQuantityTakeOffDate(
    projectId: string,
    avaProjectId: string,
    quantityTakeOffId: string,
    data: { performancePeriodStartUtc: Date; performancePeriodEndUtc: Date }
  ): void {
    this.quantityTakeOffsClient
      .setPerformancePeriodDatesForQuantityTakeOff(projectId, avaProjectId, quantityTakeOffId, {
        performancePeriodStartUtc: data.performancePeriodStartUtc,
        performancePeriodEndUtc: data.performancePeriodEndUtc
      })
      .subscribe((e: QuantityTakeOffGet) => {
        this.selectedQuantityTakeOffSource.next(e);
      });
  }
}
