import { AsyncPipe } from '@angular/common';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { map, Observable, combineLatest, Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';

import { SelectedSortMethodService } from '@project/components/project-files/services/selected-sort-method.service';

import { ProjectFileFolderGet, ProjectFilesClient } from 'app/generated-client/generated-client';
import { FileElement } from 'app/shared/models';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';

import { ProjectFileService } from './../../services/project-file.service';

import { FileManagerComponent } from '../file-manager/file-manager.component';

@Component({
  selector: 'pa-project-files-explorer',
  templateUrl: './project-files-explorer.component.html',
  styleUrls: ['./project-files-explorer.component.scss'],
  standalone: true,
  imports: [FileManagerComponent, AsyncPipe]
})
export class ProjectFilesExplorerComponent implements OnInit, OnDestroy {
  @Input() extensions: string[] = null;
  private $destroy: Subject<boolean> = new Subject<boolean>();
  private projectAndFileGot = new Subject<boolean>();
  public fileElements: Observable<FileElement[]>;
  selectedSortMethod = 'alphabetically';
  constructor(
    public projectFileService: ProjectFileService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private projectFilesClient: ProjectFilesClient,
    private selectedSortMethodService: SelectedSortMethodService
  ) {}

  currentRoot: FileElement;
  currentPath: string;
  canNavigateUp = false;

  ngOnInit() {
    this.getProjectFolderAndFiles();
    this.selectedSortMethodService.selectedSortMethod.pipe(takeUntil(this.$destroy)).subscribe((selectedSortMethod) => {
      this.selectedSortMethod = selectedSortMethod;
      this.updateFileElementQuery();
    });
    this.projectAndFileGot
      .pipe(
        switchMap(() => this.projectFileService.selectedProjFolder),
        filter((f) => !!f),
        take(1)
      )
      .subscribe((f) => {
        this.currentRoot = this.projectFileService.get(f.id);
        this.navigateToFolder(this.currentRoot, true);
      });
    this.projectFileService.currentPath.pipe(take(1)).subscribe((currentPath) => {
      this.currentPath = currentPath;
    });
  }

  ngOnDestroy(): void {
    this.projectFileService.setSelectedProjFile(null);
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  getProjectFolderAndFiles(): void {
    this.selectedProjectMessengerService.selectedProject
      .pipe(
        map((p) => p.id),
        switchMap((id) => {
          return combineLatest({
            folders: this.projectFilesClient.getAllFoldersForProject(id),
            files: this.projectFilesClient
              .getAllFilesForProject(id, undefined, undefined, undefined, undefined, undefined)
              .pipe(map((v) => v.data))
          });
        }),
        take(1)
      )
      .subscribe((r) => {
        const folders = r.folders;
        const files = r.files;
        if (folders) {
          folders.forEach((f) => {
            this.projectFileService.add({
              id: f.id,
              name: f.name,
              isFolder: true,
              parent: f.parentFolderId ? f.parentFolderId : 'root',
              canDelete: f.canDelete,
              projectId: f.projectId
            });
          });
        }
        if (files) {
          files.forEach((f) => {
            if (!this.extensions) {
              this.projectFileService.add({
                id: f.id,
                name: f.file.fileName,
                isFolder: false,
                parent: f.folderId,
                fileId: f.file.id,
                file: f.file,
                canDelete: f.canDelete
              });
            } else if (this.extensions && this.extensions.some((extension) => f.file.fileName.includes(extension))) {
              this.projectFileService.add({
                id: f.id,
                name: f.file.fileName,
                isFolder: false,
                parent: f.folderId,
                fileId: f.file.id,
                file: f.file,
                canDelete: f.canDelete
              });
            }
          });
        }
        this.updateFileElementQuery();
        this.projectAndFileGot.next(true);
        this.projectAndFileGot.complete();
      });
  }

  addFolder(folder: ProjectFileFolderGet): void {
    this.projectFileService.add({
      id: folder.id,
      isFolder: true,
      name: folder.name,
      canDelete: folder.canDelete,
      parent: folder.parentFolderId ? folder.parentFolderId : 'root'
    });
    this.updateFileElementQuery();
  }

  removeElement(element: FileElement) {
    this.projectFileService.delete(element.id);
    this.updateFileElementQuery();
  }

  navigateToFolder(element: FileElement, isProgramatically = false) {
    this.currentRoot = element;
    this.projectFileService.setSelectedProjFolder({
      id: this.currentRoot.id,
      projectId: this.currentRoot.projectId,
      name: this.currentRoot.name,
      parentFolderId: this.currentRoot.parent,
      canDelete: this.currentRoot.canDelete
    });
    this.updateFileElementQuery();
    if (!isProgramatically) {
      this.currentPath = this.pushToPath(this.currentPath, element.name);
      this.projectFileService.setCurrentPath(this.currentPath);
    }
    this.canNavigateUp = true;
  }

  navigateUp(): void {
    if (this.currentRoot && this.currentRoot.parent === 'root') {
      this.currentRoot = null;
      this.projectFileService.setSelectedProjFolder(null);
      this.canNavigateUp = false;
      this.updateFileElementQuery();
    } else {
      this.currentRoot = this.projectFileService.get(this.currentRoot.parent);
      this.projectFileService.setSelectedProjFolder({
        id: this.currentRoot.id,
        projectId: this.currentRoot.projectId,
        name: this.currentRoot.name,
        parentFolderId: this.currentRoot.parent,
        canDelete: this.currentRoot.canDelete
      });
      this.updateFileElementQuery();
    }
    this.currentPath = this.popFromPath(this.currentPath);
    this.projectFileService.setCurrentPath(this.currentPath);
  }

  renameElement(element: ProjectFileFolderGet): void {
    this.projectFileService.update(element.id, { name: element.name });
    this.updateFileElementQuery();
  }

  updateFileElementQuery(): void {
    this.fileElements = this.projectFileService.queryInFolder(this.currentRoot ? this.currentRoot.id : 'root').pipe(
      map((fileElements) => {
        fileElements.sort(this.sortBy());
        return fileElements;
      }),
      map((fileElements) => {
        fileElements.sort((a, b) => {
          return a.isFolder === b.isFolder ? 0 : a.isFolder ? -1 : 1;
        });
        return fileElements;
      })
    );
  }

  pushToPath(path: string, folderName: string): string {
    let p = path ? path : '';
    p += `${folderName}/`;
    return p;
  }

  popFromPath(path: string): string {
    let p = path ? path : '';
    const split = p.split('/');
    split.splice(split.length - 2, 1);
    p = split.join('/');
    return p;
  }

  sortBy() {
    if (this.selectedSortMethod === 'alphabetically') {
      return function (a: FileElement, b: FileElement) {
        return a.name < b.name ? -1 : 1;
      };
    } else if (this.selectedSortMethod === 'date decreasing') {
      return function (a: FileElement, b: FileElement) {
        if (!a.isFolder && !b.isFolder) {
          return a.file.createdAtUtc < b.file.createdAtUtc ? -1 : 1;
        }
        return a.name < b.name ? -1 : 1;
      };
    } else if (this.selectedSortMethod === 'date increasing') {
      return function (a: FileElement, b: FileElement) {
        if (!a.isFolder && !b.isFolder) {
          return a.file.createdAtUtc > b.file.createdAtUtc ? -1 : 1;
        }
        return a.name < b.name ? -1 : 1;
      };
    }
  }
}
