import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'elementTypeClass',
  standalone: true
})
export class ElementTypeClassPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'NoteTextDto':
        return 'Hinweis';

      case 'ExecutionDescriptionDto':
        return 'Ausführungsbeschreibung';

      case 'ServiceSpecificationGroupDto':
        return 'Gruppe';

      case 'PositionDto':
        return 'Position';
    }

    return null;
  }
}
