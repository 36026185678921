<div mat-dialog-content>
  <ng-container *ngIf="!data.page; else edit">
    <h1>Neues Aufmaßblatt Erstellen</h1>
  </ng-container>
  <ng-template #edit>
    <h1>Aufmaßblatt Bearbeiten</h1>
  </ng-template>
  <form [formGroup]="newPageForm">
    <div>
      <mat-form-field class="wider page-number">
        <mat-label>Aufmaßblattnummer</mat-label>
        <input matInput formControlName="customPageNumber" />
        <mat-hint>Optional, wird ansonsten automatisch vergeben</mat-hint>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="wider">
        <mat-label>Titel</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>
    </div>

    <div *ngIf="listParentQTO?.length > 1">
      <mat-form-field appearance="fill" class="wider">
        <mat-label>Abrechnung</mat-label>
        <mat-select name="status" [value]="data?.quantityTakeOffId" (selectionChange)="changedQto($event)">
          <mat-option [value]="null">- Ohne -</mat-option>
          <mat-option *ngFor="let item of listParentQTO" [value]="item.id">{{ item.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <button
        mat-raised-button
        type="button"
        class="sub-contractor-button"
        color="primary"
        *ngIf="!isSubContractor"
        (click)="selectContact(true)"
      >
        Subunternehmer Auswählen
      </button>
      <button
        mat-raised-button
        type="button"
        class="sub-contractor-button"
        color="warn"
        *ngIf="isSubContractor"
        (click)="selectContact(false)"
      >
        Subunternehmer Entfernen
      </button>
      <div>
        <mat-form-field *ngIf="selectedContact" class="wider">
          <mat-label>Kontakt Name</mat-label>
          <input matInput disabled [value]="selectedContact.name" />
        </mat-form-field>
      </div>
    </div>
    <div>
      <mat-form-field class="wider">
        <mat-label>Bauteilcode</mat-label>
        <input
          matInput
          formControlName="newBuildingElementCode"
          (click)="listBuildingElementCode()"
          readonly
          *ngIf="newPageForm.value.existingBuildingElementCodeId"
        />
        <input
          matInput
          formControlName="newBuildingElementCode"
          (click)="listBuildingElementCode()"
          *ngIf="!newPageForm.value.existingBuildingElementCodeId"
        />
      </mat-form-field>
    </div>
    <div class="buttons">
      <ng-container *ngIf="!data.page; else editWindow">
        <button mat-raised-button color="primary" [disabled]="!newPageForm.valid" (click)="createPage()">Erstellen</button>
      </ng-container>
      <ng-template #editWindow>
        <button mat-raised-button color="primary" [disabled]="!newPageForm.valid" (click)="editPage()">Speichern</button>
      </ng-template>
      <button mat-raised-button color="warn" type="button" (click)="cancel()">Abbrechen</button>
    </div>
  </form>
</div>
