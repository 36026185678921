import { PositionDto } from 'app/generated-client/generated-client';

export function filterElementsByIds(elements: PositionDto[], ids: string[]): PositionDto[] {
  if (!elements || !elements.length) {
    return [];
  }
  if (!ids || !ids.length) {
    return [];
  }
  return elements.filter(el => ids.find(id => el.id === id));
}
