<div class="pages" [ngClass]="{ small: isSmaller }">
  <mat-paginator
    clanss="pages-paginatior"
    [length]="upperPaginated?.totalCount"
    [pageSize]="upperPaginated?.pageSize"
    [pageIndex]="upperService?.page - 1"
    [pageSizeOptions]="pageSizeOptions"
    (page)="onPage($event)"
    [showFirstLastButtons]="true"
    changePaginationButtons
  >
  </mat-paginator>
  <input
    matInput
    class="pages-num"
    matTooltip="Seite"
    matTooltipPosition="above"
    type="number"
    step="1"
    (change)="inputPage($event, upperPaginated, upperService)"
    placeholder="Seite"
    [disabled]="upperPaginated?.totalCount / upperPaginated?.pageSize <= 1 || !upperService"
  />
</div>
