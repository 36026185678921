import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';

@Component({
  selector: 'pa-modal-to-delete-company',
  templateUrl: './modal-to-delete-company.component.html',
  styleUrls: ['./modal-to-delete-company.component.scss'],
  standalone: true,
  imports: [MatDialogContent, MatDialogActions, MatButton, MatDialogClose]
})
export class ModalToDeleteCompanyComponent {}
