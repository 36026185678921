import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProjectStatus } from 'app/generated-client/generated-client';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';

import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';
import { ServiceSpecificationTreeComponent } from '../../../service-specification-tree/service-specification-tree.component';

import { InvoicePageComponent } from '../invoice-page/invoice-page.component';

@Component({
  selector: 'pa-tree-invoice-page',
  templateUrl: './tree-invoice-page.component.html',
  styleUrls: ['./tree-invoice-page.component.scss'],
  standalone: true,
  imports: [forwardRef(() => ServiceSpecificationTreeComponent), FlexLayoutDirective, InvoicePageComponent]
})
export class TreeInvoicePageComponent implements OnInit, OnDestroy {
  isReadOnly: boolean;
  isStandalonePage = false;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    combineLatest([this.selectedProjectMessengerService.selectedProject, this.route.data])
      .pipe(takeUntil(this.$destroy))
      .subscribe(([p, data]) => {
        this.isReadOnly = p?.status === ProjectStatus.Archived || p?.status === ProjectStatus.Locked;
        this.isStandalonePage = data?.isStandalonePage;
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }
}
