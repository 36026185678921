import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PriceInquiryGet } from 'app/generated-client/generated-client';
import { SelectedSpecificationMessengerService } from '../messengers/selected-specification-messenger.service';
import { PaginationMainService } from './main/pagination-main.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PriceInquiryService extends PaginationMainService<PriceInquiryGet> {
  private projectId: string;
  private avaProjectId: string;

  constructor(protected http: HttpClient, private selectedSpecificationMessengerService: SelectedSpecificationMessengerService) {
    super(http);
    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(takeUntil(this.$destroy))
      .subscribe((p: { avaProjectId: string; parentProjectId: string }) => {
        this.projectId = p?.parentProjectId;
        this.avaProjectId = p?.avaProjectId;
        this.setBaseUrl();
      });
  }

  private setBaseUrl(): void {
    if (this.projectId && this.avaProjectId) {
      this.beginBaseUrl(`/api/projects/${this.projectId}/ava-projects/${this.avaProjectId}/price-inquiries`);
    } else {
      this.baseUrl = null;
    }
  }
}
