<mat-menu #rowContextMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    @if (!isPages) {
    <button mat-menu-item [disabled]="item.markedAsBilledAtUtc" (click)="doInvoiceMenuAction(menuActions.EditInvoiceName, item)">
      <mat-icon color="primary">create</mat-icon>
      <span>Umbenennen</span>
    </button>

    <button mat-menu-item (click)="doInvoiceMenuAction(menuActions.CopyInvoice, item)">
      <mat-icon color="primary">file_copy</mat-icon>
      <span>Kopieren</span>
    </button>

    <button mat-menu-item [disabled]="item.markedAsBilledAtUtc" (click)="doInvoiceMenuAction(menuActions.RemoveInvoice, item)">
      <mat-icon color="warn">close</mat-icon>
      <span>{{ structureView === 'invoices' ? 'Abrechnung' : 'Mengenermittlung' }} löschen</span>
    </button>

    <ng-container *ngIf="structureView === 'estimations'">
      <ng-container
        *ngIf="
          item.calculationType === 'ByPosition' &&
          !item.useAsAssumedQuantities &&
          !item.isProjectQuantityCalculation &&
          !hasAnyQtoCalculationReferences
        "
      >
        <button mat-menu-item (click)="doInvoiceMenuAction(menuActions.AddProjectQuantityReference, item)">
          <mat-icon color="primary">add</mat-icon>
          <span>Mit Projektmengen verknüpfen</span>
        </button>
      </ng-container>

      <ng-container
        *ngIf="
          item.calculationType === 'ByPosition' &&
          !item.useAsAssumedQuantities &&
          !item.isProjectQuantityCalculation &&
          !hasAnyQtoAssumedQuantities
        "
      >
        <button mat-menu-item (click)="doInvoiceMenuAction(menuActions.AddProjectQuantityReferenceAsAssumed, item)">
          <mat-icon color="primary">add</mat-icon>
          <span>Mit Projektmengen verknüpfen (VA)</span>
        </button>
      </ng-container>

      <ng-container *ngIf="item.calculationType === 'ByPosition' && item.isProjectQuantityCalculation">
        <button mat-menu-item (click)="doInvoiceMenuAction(menuActions.DeleteProjectQuantityReference, item)">
          <mat-icon color="warn">delete</mat-icon>
          <span>Nicht mehr mit Projektmengen verknüpfen</span>
        </button>
      </ng-container>

      <ng-container *ngIf="item.calculationType === 'ByPosition' && item.useAsAssumedQuantities">
        <button mat-menu-item (click)="doInvoiceMenuAction(menuActions.DeleteProjectQuantityReferenceAsAssumed, item)">
          <mat-icon color="warn">delete</mat-icon>
          <span>Nicht mehr mit VA-Mengen verknüpfen</span>
        </button>
      </ng-container>

      <ng-container>
        <button mat-menu-item (click)="doInvoiceMenuAction(menuActions.ApplyQuantities, item)">
          <mat-icon color="primary">swap_vertical_circle</mat-icon>
          <span>Projektmengen übernehmen</span>
        </button>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="structureView === 'invoices'">
      <button mat-menu-item (click)="doInvoiceMenuAction(menuActions.PrintInvoice, item)">
        <mat-icon color="primary">print</mat-icon>
        <span>Drucken</span>
      </button>
    </ng-container>

    <ng-container *ngIf="structureView === 'invoices'">
      <button mat-menu-item (click)="doInvoiceMenuAction(menuActions.BulkExportQuantities, item)">
        <mat-icon color="primary">print</mat-icon>
        <span>Mengenübersicht</span>
      </button>
    </ng-container>
    } @else {
    <button mat-menu-item (click)="doInvoiceMenuAction(menuActions.Open, item)">
      <span>Öffnen</span>
    </button>

    <button mat-menu-item (click)="doInvoiceMenuAction(menuActions.EditInvoiceName, item)" [disabled]="disabledObject['edit']">
      <mat-icon [color]="disabledObject['edit'] ? '' : 'primary'">create</mat-icon>
      <span>Umbenennen</span>
    </button>

    <button mat-menu-item (click)="doInvoiceMenuAction(menuActions.CopyInvoice, item)">
      <mat-icon color="primary">file_copy</mat-icon>
      <span>Kopieren</span>
    </button>

    <!-- Hide button, since that can now be done via the table -->
    <!-- <button mat-menu-item (click)="doInvoiceMenuAction(menuActions.MoveInvoice, item)" [disabled]="disabledObject['move']">
      <mat-icon [color]="disabledObject['move'] ? '' : 'primary'">open_in_new</mat-icon>
      <span>Verschieben</span>
    </button> -->

    <button
      mat-menu-item
      (click)="doInvoiceMenuAction(menuActions.RemoveInvoice, item)"
      [disabled]="item.markedAsBilledAtUtc || disabledObject['delete']"
    >
      <mat-icon [color]="item.markedAsBilledAtUtc || disabledObject['delete'] ? '' : 'warn'">delete</mat-icon>
      <span>Löschen</span>
    </button>
    }
  </ng-template>
</mat-menu>

<div
  style="position: fixed; visibility: hidden"
  [matMenuTriggerFor]="rowContextMenu"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
></div>
