import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable({
  providedIn: 'root'
})
export class CustomPaginatorIntl extends MatPaginatorIntl {
  constructor() {
    super();
    this.firstPageLabel = 'Erste Seite';
    this.lastPageLabel = 'Letzte Seite';
    this.nextPageLabel = 'Nächste Seite';
    this.previousPageLabel = 'Vorherige Seite';
    this.itemsPerPageLabel = 'Einträge pro Seite';
  }

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 von ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    const maxPages = Math.ceil(length / pageSize);
    return `Einträge ${startIndex + 1} - ${endIndex} von ${length}, Seite ${page + 1} von ${maxPages}`;
  };
}
