import { AvaProjectSubPositionsGet, SubPositionGet } from '../../../generated-client/generated-client';
import { ReplaySubject, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SubPositionsMessengerService {
  private subPositionsSource = new ReplaySubject<{ avaProjectId: string; subPositions: AvaProjectSubPositionsGet }>(1);
  subPositions = this.subPositionsSource.asObservable();
  private positionSpecificSubPositionsSource = new Subject<{ positionId: string; subPositions: SubPositionGet[] }>();
  positionSpecificSubPositions = this.positionSpecificSubPositionsSource.asObservable();

  constructor() {}

  private lastSubPositions: { avaProjectId: string; subPositions: AvaProjectSubPositionsGet };

  setSubPositions(subPositionData: { avaProjectId: string; subPositions: AvaProjectSubPositionsGet }): void {
    this.lastSubPositions = subPositionData;
    this.subPositionsSource.next(subPositionData);
  }

  setSubPositionsForSpecificPosition(positionId: string, avaProjectId: string, subPositions: SubPositionGet[]): void {
    if (avaProjectId == this.lastSubPositions?.avaProjectId) {
      this.lastSubPositions.subPositions.subPositionsByAvaPositionId[positionId] = subPositions;
      this.subPositionsSource.next(this.lastSubPositions);
      this.positionSpecificSubPositionsSource.next({
        positionId,
        subPositions
      });
    }
  }
}
