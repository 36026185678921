import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CopyCalculationTopPartService {
  canLeave: boolean;
  private changesSource = new Subject<boolean>();
  changes = this.changesSource.asObservable();

  private hiddenTopSource = new ReplaySubject<boolean>(1);
  hiddenTop = this.hiddenTopSource.asObservable();

  private infoFromTopPartSource = new ReplaySubject<any>(1);
  infoFromTopPart = this.infoFromTopPartSource.asObservable();

  private infoToTopPartSource = new ReplaySubject<any>(1);
  infoToTopPart = this.infoToTopPartSource.asObservable();

  constructor() {}

  setChanges(): void {
    this.changesSource.next(true);
  }

  setToTopPart(command?: string, data?: any): void {
    this.infoToTopPartSource.next({ command, data });
  }

  setFromTopPart(command?: string, data?: any): void {
    this.infoFromTopPartSource.next({ command, data });
  }

  tryHide(): void {
    this.canLeave = true;
    setTimeout(() => {
      if (this.canLeave) {
        this.hiddenTopSource.next(true);
      }
    }, 300);
  }

  stopHiding(): void {
    this.canLeave = false;
  }

  setShow(): void {
    this.hiddenTopSource.next(false);
    this.canLeave = false;
  }
}
