import { DOCUMENT, CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, NgZone, OnDestroy, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';

import { fromEvent, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { UserSettingsService } from '@shared/services/user-settings.service';

import { CopyCalculationViewComponent } from 'app/areas/copy-calculation-view/components/copy-calculation-view/copy-calculation-view.component';
import { OtherProjectTabComponent } from 'app/areas/copy-calculation-view/components/other-project-tab/other-project-tab.component';
import { CopyCalculationTopPartService } from 'app/areas/copy-calculation-view/services/copy-calculation-top-part.service';
import { UserSettings } from 'app/generated-client/generated-client';
import { WindowType } from 'app/shared/models/window-type.models';
import { CopyCalculationViewMessengerService } from 'app/shared/services/electron/copy-calculation-view-messenger.service';
import { ShowedViewsService } from 'app/shared/services/showed-views.service';

import { EmbeddedViewNamesPipe } from '../../../../shared/pipes/ui-data-display/embedded-view-names.pipe';
import { CopyElementViewComponent } from '../../../copy-element-view/copy-element-view/copy-element-view.component';
import { FlexLayoutDirective } from '../../../flex-layout/flex-layout.directive';
import { LongTextWindowComponent } from '../../../long-text-view/components/long-text-window/long-text-window.component';
import { InvoiceSheetsComponent } from '../../../project-id/components/service-specifications/components/invoice/components/invoice-sheets/invoice-sheets.component';

@Component({
  selector: 'pa-one-view-window',
  templateUrl: './one-view-window.component.html',
  styleUrls: ['./one-view-window.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexLayoutDirective,
    MatButton,
    MatIcon,
    LongTextWindowComponent,
    OtherProjectTabComponent,
    CopyCalculationViewComponent,
    InvoiceSheetsComponent,
    CopyElementViewComponent,
    EmbeddedViewNamesPipe
  ]
})
export class OneViewWindowComponent implements OnInit, OnDestroy {
  @Input() nameWindow: WindowType;
  @Output() selectWindow = new EventEmitter<boolean>();
  @Output() removeWindow = new EventEmitter<string>();
  @Output() separateWindow = new EventEmitter<{ name: string; rect: DOMRect }>();
  @ViewChild('wrapElement') wrapElement: ElementRef;
  public get windowType(): typeof WindowType {
    return WindowType;
  }
  name: WindowType;
  isModal: boolean;
  draggingCorner: boolean;
  x: number;
  y: number;
  px: number;
  py: number;
  width: number;
  height: number;
  currentFunctionName: string;
  elementGroupId: string;

  hiddenWindows: boolean;
  savedWindow: any = null;
  isFullSize = false;
  isProjectSearchShowAbove = false;
  isMainSeparated = false;
  canRemoveOne = true;
  timeClear: NodeJS.Timeout;
  private $destroy: Subject<boolean> = new Subject();

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() private matDialogRef: MatDialogRef<OneViewWindowComponent>,
    private showedViewsService: ShowedViewsService,
    private copyCalculationTopPartService: CopyCalculationTopPartService,
    private copyCalculationViewMessengerService: CopyCalculationViewMessengerService,
    private ngZone: NgZone,
    @Inject(DOCUMENT) private document: Document,
    private userSettingsService: UserSettingsService
  ) {}

  ngOnInit(): void {
    this.name = this.nameWindow || this.data?.name;
    this.isModal = !this.nameWindow;
    if (this.isModal) {
      this.width = this.data?.rect?.width;
      this.height = this.data?.rect?.height;
      this.x = this.data?.rect?.left;
      this.y = this.data?.rect?.top;
    }

    this.showedViewsService.hiddenWindows.pipe(takeUntil(this.$destroy)).subscribe((hiddenWindows: boolean) => {
      this.hiddenWindows = hiddenWindows;
      if (this.hiddenWindows) {
        this.hide();
      } else {
        this.show();
      }
    });

    this.copyCalculationViewMessengerService.selectedElementGroupId.pipe(takeUntil(this.$destroy)).subscribe((elementGroupId) => {
      this.elementGroupId = elementGroupId;
    });

    this.ngZone.runOutsideAngular(() => {
      fromEvent(this.document, 'mousemove')
        .pipe(takeUntil(this.$destroy))
        .subscribe((event: MouseEvent) => {
          this.onCornerMove(event);
        });
    });

    this.userSettingsService.currentUserSettings.pipe(takeUntil(this.$destroy)).subscribe((setting: UserSettings) => {
      if (this.isModal && !setting?.showMultiComponentsViewInMainWindow) {
        this.matDialogRef.close();
      }
    });

    this.showedViewsService.showedMainSeparated.pipe(takeUntil(this.$destroy)).subscribe((isMainSeparated) => {
      setTimeout(() => (this.isMainSeparated = isMainSeparated), 0);
    });

    this.showedViewsService.backAllWindow.pipe(takeUntil(this.$destroy)).subscribe(() => {
      if (this.isModal) {
        setTimeout(() => {
          this.matDialogRef.close(2);
        }, 100);
      }
    });

    this.showedViewsService.showedSeparatedViews.pipe(takeUntil(this.$destroy)).subscribe((separate) => {
      if (this.isModal && !separate.find((item) => item.name == this.name)) {
        this.matDialogRef?.close(2);
      }
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  remove(event: MouseEvent): void {
    event.stopPropagation();
    if (this.canRemoveOne) {
      this.canRemoveOne = false;
      this.timeClear = setTimeout(() => {
        this.matDialogRef.close(1);
        this.canRemoveOne = true;
      }, 200);
    }
  }

  removeAll(): void {
    clearTimeout(this.timeClear);
    this.showedViewsService.setBackAllWindow();
    setTimeout(() => (this.canRemoveOne = true), 200);
  }

  separate(): void {
    if (this.nameWindow) {
      this.separateWindow.emit({ name: this.nameWindow, rect: (this.wrapElement.nativeElement as HTMLDivElement).getBoundingClientRect() });
    }
  }

  close(): void {
    if (this.nameWindow) {
      this.removeWindow.emit(this.nameWindow);
    } else {
      this.matDialogRef.close(2);
    }
  }

  onClick(event: MouseEvent): void {
    if (event.button === 2) {
      event.preventDefault();
      this.selectWindow.emit(true);
    } else {
      this.separate();
    }
  }

  overlayUp(): void {
    const container = (this.wrapElement.nativeElement as HTMLDivElement).closest('.cdk-overlay-container') as HTMLDivElement;
    const count = container.querySelectorAll('.pane-window');

    if (count.length > 1) {
      const list = container.querySelectorAll('.cdk-global-overlay-wrapper');
      list.forEach((elem) => {
        if ((elem as HTMLDivElement).style.zIndex === '1000') {
          (elem as HTMLDivElement).style.zIndex = '999';
        } else {
          (elem as HTMLDivElement).style.zIndex = '998';
        }
      });
      const el = (this.wrapElement.nativeElement as HTMLDivElement).closest('.cdk-global-overlay-wrapper') as HTMLDivElement;
      el.style.zIndex = '1000';
      if (this.name === WindowType.CopyCalc) {
        const topPart = container.querySelector('.top-part-window') as HTMLDivElement;
        if (topPart) {
          const topOverlay = topPart.closest('.cdk-global-overlay-wrapper') as HTMLDivElement;
          topOverlay.style.zIndex = '1000';
        }
        setTimeout(() => {
          this.copyCalculationTopPartService.setChanges();
        }, 1);
      }
    }
  }

  startResize(event: MouseEvent, fnName: string): void {
    if (this.isFullSize) {
      return;
    }
    this.currentFunctionName = fnName;
    this.draggingCorner = true;
    this.px = event.clientX;
    this.py = event.clientY;
    const rect = (this.wrapElement.nativeElement as HTMLDivElement).closest('.mat-mdc-dialog-container').getBoundingClientRect();
    this.x = rect.left;
    this.y = rect.top;

    fromEvent(this.document, 'mouseup')
      .pipe(takeUntil(this.$destroy), take(1))
      .subscribe(() => {
        this.stopResize();
      });
  }

  fnList = {
    bottomRight: (offsetX: number, offsetY: number) => {
      this.width += offsetX;
      this.height += offsetY;
    },
    right: (offsetX: number) => {
      this.width += offsetX;
    },
    left: (offsetX: number) => {
      this.px += offsetX;
      this.x += offsetX;
      this.width -= offsetX;
      this.matDialogRef.updatePosition({ top: `${this.y}px`, left: `${this.x}px` });
    },
    move: (offsetX: number, offsetY: number) => {
      this.px += offsetX;
      this.x += offsetX;
      this.py += offsetY;
      this.y += offsetY;
      this.notAllowOutside();
      this.matDialogRef.updatePosition({ top: `${this.y}px`, left: `${this.x}px` });
    },
    top: (offsetX: number, offsetY: number) => {
      this.py += offsetY;
      this.y += offsetY;
      this.height -= offsetY;
      this.notAllowOutside();
      this.matDialogRef.updatePosition({ top: `${this.y}px`, left: `${this.x}px` });
    },
    bottom: (offsetX: number, offsetY: number) => {
      this.height += offsetY;
    }
  };

  notAllowOutside(): void {
    this.y = this.y < 0 ? 0 : this.y;
  }

  onCornerMove(event: MouseEvent) {
    if (!this.draggingCorner) {
      return;
    }

    this.ngZone.run(() => {
      const offsetX = event.clientX - this.px;
      const offsetY = event.clientY - this.py;
      this.fnList[this.currentFunctionName](offsetX, offsetY);
      this.px = event.clientX;
      this.py = event.clientY;
    });
  }

  stopResize() {
    this.draggingCorner = false;
    if (this.data?.name && this.wrapElement) {
      this.showedViewsService.addSeparatedViews({
        name: this.name,
        rect: (this.wrapElement.nativeElement as HTMLDivElement).getBoundingClientRect()
      });
    }
  }

  tryLeave(): void {
    this.showedViewsService.tryHide();
  }

  stopLeave(): void {
    this.showedViewsService.setShow();
  }

  hide(): void {}

  show(): void {}

  fullSize(): void {
    this.savedWindow = {
      y: this.y,
      x: this.x,
      width: this.width,
      height: this.height
    };
    const area = this.document.querySelector('.main-window').getBoundingClientRect();

    this.x = area.x;
    this.y = area.y;
    this.width = area.width - 10;
    this.height = area.height - 10;
    this.matDialogRef.updatePosition({ top: `${this.y}px`, left: `${this.x}px` });
    this.isFullSize = true;
  }

  middleSize(): void {
    this.x = this.savedWindow.x;
    this.y = this.savedWindow.y;
    this.width = this.savedWindow.width;
    this.height = this.savedWindow.height;
    this.matDialogRef.updatePosition({ top: `${this.y}px`, left: `${this.x}px` });
    this.isFullSize = false;
  }
}
