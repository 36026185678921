import { DOCUMENT, NgIf } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AfterViewInit, Component, ErrorHandler, Inject, LOCALE_ID, NgZone, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { MatMiniFabButton } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { Title } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';

import { AuthenticationMessenger } from '@dangl/angular-dangl-identity-client';

import { Subject, fromEvent } from 'rxjs';
import { filter, first, take, takeUntil } from 'rxjs/operators';

import { ChangeViewportHeightService } from '@serv-spec/services/change-viewport-height.service';

import { HelpWindowModalComponent } from '@shared/components/help-window-modal/help-window-modal.component';
import { MainFrameComponent } from '@shared/components/main-frame/main-frame.component';
import { MainRect } from '@shared/models';
import { ElectronService } from '@shared/services/electron/electron.service';
import { ErrorHandlerService } from '@shared/services/error-handler.service';

import { LocalAuthenticationService } from 'app/areas/local-projects/services/local-authentication.service';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { WindowType } from 'app/shared/models/window-type.models';
import { HandlerElectronErrorsService } from 'app/shared/services/electron/handler-electron-errors.service';
import { HelpViewMessengerService } from 'app/shared/services/electron/help-view-messenger.service';
import { WindowStateService } from 'app/shared/services/electron/window-state.service';
import { PdfOverlayService } from 'app/shared/services/pdf-overlay.service';
import { setAsSplitSize } from 'app/shared/utilities/area-size';

import { DetailTableModalService } from './areas/elements/services/detail-table-modal.service';
import { LocalProjectIdInterceptor } from './areas/local-projects/interceptors/local-project-id.interceptor';
import { MultiViewWindowComponent } from './areas/views/components/multi-view-window/multi-view-window.component';
import { OneViewWindowComponent } from './areas/views/components/one-view-window/one-view-window.component';
import { UserSettings } from './generated-client/generated-client';
import { GeneralHeaderComponent } from './shared/components/general-header/general-header.component';
import { SelectViewsComponent } from './shared/components/select-views/select-views.component';
import { ModalService } from './shared/services/modal.service';
import { ShowedViewsService } from './shared/services/showed-views.service';
import { UserSettingsService } from './shared/services/user-settings.service';
import { getStorage, setStorage } from './shared/utilities/storage';
import { version } from './version';

import { AppConfig } from '../environments/environment';
import { IOutputAreaSizes, SplitComponent, AngularSplitModule } from 'angular-split';

@Component({
  selector: 'pa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    AngularSplitModule,
    NgIf,
    GeneralHeaderComponent,
    RouterOutlet,
    MultiViewWindowComponent,
    MatMiniFabButton,
    MatTooltip,
    MatIcon,
    MatDialogModule
  ],
  providers: [
    PdfOverlayService,
    HandlerElectronErrorsService,
    {
      provide: 'TINYMCE_BASE_URL',
      useValue: AppConfig.production ? 'assets/tinymce-assets/' : '/assets/tinymce-assets/'
    },
    {
      provide: 'APP_VERSION',
      useValue: version.version
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE'
    },
    AppConfig.isQtoOnlyMode
      ? {
          provide: HTTP_INTERCEPTORS,
          multi: true,
          useClass: LocalProjectIdInterceptor
        }
      : []
  ]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('asSplitHorizontalRef') asSplitHorizontalRef: SplitComponent;
  multiView: boolean;
  hidden: boolean;
  listWindow: WindowType[] = [WindowType.Product, WindowType.CopyCalc, WindowType.CopyElement, WindowType.LongText, WindowType.CompareQTO];
  showedListWindow: string[] = [];
  separatedList: { name: string; rect: DOMRect }[] = [];
  isSeparatedMain: boolean = getStorage<boolean>('MAIN_FRAME_SEPARATED', false);
  isAuthenticated = false;
  private matDialogRef: MatDialogRef<MainFrameComponent>;
  private $destroy = new Subject<boolean>();
  isElectron = inject(ElectronService).isElectron;

  constructor(
    private userSettingsService: UserSettingsService,
    private modalService: ModalService,
    private showedViewsService: ShowedViewsService,
    private windowStateService: WindowStateService,
    private authenticationMessenger: AuthenticationMessenger,
    private ngZone: NgZone,
    private changeViewportHeightService: ChangeViewportHeightService,
    private detailTableModalService: DetailTableModalService,
    @Inject(DOCUMENT) private document: Document,
    //It is necessary to create a service
    private pdfOverlayService: PdfOverlayService,
    private handlerElectronErrorsService: HandlerElectronErrorsService,
    private helpViewMessengerService: HelpViewMessengerService,
    private localAuthenticationService: LocalAuthenticationService,
    private title: Title
  ) {}

  ngOnInit(): void {
    if (AppConfig.isQtoOnlyMode) {
      this.title.setTitle(AppConfig.appPrefix + 'AVA');
      // In QTO only mode, we're just using a static, local user account so we
      // set a dummy value for the UI to display user data and let the auth logic work
      // as if an actual user was logged in
      this.localAuthenticationService.getAndSaveLocalAuthenticationToken();
    }

    if (this.isSeparatedMain) {
      setTimeout(() => this.showSeparateFrame(), 500);
    }
    this.authenticationMessenger.isAuthenticated
      .pipe(
        filter((isAuthenticated) => isAuthenticated),
        first(),
        takeUntil(this.$destroy)
      )
      .subscribe(() => {
        // We want to reload the last window configuration once on startup
        this.windowStateService.applyLastSavedWindowState();
        this.isAuthenticated = true;
      });

    this.showedViewsService.showedViews.pipe(takeUntil(this.$destroy)).subscribe((list: string[]) => {
      this.changedLength(list);
    });
    this.userSettingsService.currentUserSettings.pipe(takeUntil(this.$destroy)).subscribe((setting: UserSettings) => {
      if (!this.multiView && setting?.showMultiComponentsViewInMainWindow) {
        setTimeout(() => {
          setAsSplitSize('AS_SPLIT_MAIN_HORIZONTAL_SIZE', this.asSplitHorizontalRef);
          this.restoreSeparatedViews();
        }, 1);
      } else if (!setting?.showMultiComponentsViewInMainWindow) {
        this.matDialogRef?.close();
        setTimeout(() => {
          this.isSeparatedMain = false;
        }, 1);
      }
      this.multiView = setting.showMultiComponentsViewInMainWindow;
      this.changeShowing();
    });

    this.ngZone.runOutsideAngular(() => {
      fromEvent(document, 'dragstart')
        .pipe(takeUntil(this.$destroy))
        .subscribe((event: DragEvent) => {
          event.preventDefault();
        });

      if (!this.isElectron) {
        let isModalOpen: boolean;
        fromEvent(document, 'keydown')
          .pipe(takeUntil(this.$destroy))
          .subscribe((event: KeyboardEvent) => {
            if (event.key === 'F1' && !isModalOpen) {
              event.preventDefault();
              isModalOpen = !!this.modalService
                .openModal(HelpWindowModalComponent, { dialogType: ConfirmationType.General })
                .afterClosed()
                .subscribe(() => {
                  isModalOpen = false;
                });
            }
          });
      }
    });

    this.showedViewsService.showedMainSeparated.pipe(takeUntil(this.$destroy)).subscribe((isMainSeparated) => {
      if (isMainSeparated) {
        const list = document.querySelectorAll('.multi-area .one-view');
        const listSeparated = this.showedListWindow.map((name, index) => ({ name, rect: list[index].getBoundingClientRect() }));
        listSeparated.forEach((itemData) => {
          setTimeout(() => this.separateWindow(itemData), 0);
        });
      }
    });

    this.showedViewsService.showedSeparatedViews.pipe(takeUntil(this.$destroy)).subscribe((separate) => {
      this.separatedList = separate;
    });

    this.showedViewsService.backAllWindow.pipe(takeUntil(this.$destroy)).subscribe(() => {
      const addList = this.separatedList.map((item) => item.name);
      setTimeout(() => {
        this.showedListWindow.push(...addList);
        this.showedViewsService.setShowedViews([...this.showedListWindow]);
      }, 200);
    });
  }

  ngAfterViewInit(): void {
    setAsSplitSize('AS_SPLIT_MAIN_HORIZONTAL_SIZE', this.asSplitHorizontalRef);
  }

  ngOnDestroy(): void {
    this.matDialogRef?.close();
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  onDragEnd(sizes: IOutputAreaSizes): void {
    setStorage<number[]>('AS_SPLIT_MAIN_HORIZONTAL_SIZE', sizes as number[]);
    this.changeViewportHeightService.updatedViewportHeight(true);
    this.detailTableModalService.startChangePosition();
  }

  changedLength(list: string[]): void {
    setTimeout(() => {
      if (this.hidden && list.length) {
        setTimeout(() => {
          setAsSplitSize('AS_SPLIT_MAIN_HORIZONTAL_SIZE', this.asSplitHorizontalRef);
        }, 100);
      }
      this.showedListWindow = [...list];
      this.hidden = !this.showedListWindow?.length;
      this.changeShowing();
    }, 1);
  }

  selectViews(): void {
    const separateList = this.separatedList.map((item) => item.name);

    this.modalService
      .openModal(SelectViewsComponent, {
        dialogType: ConfirmationType.General,
        data: { default: this.listWindow, showed: [...this.showedListWindow, ...separateList] }
      })
      .afterClosed()
      .subscribe((newList) => {
        if (newList) {
          const newShowedList = newList.filter((item: string) => !separateList.includes(item));
          const deletedSeparatedList = separateList.filter((item) => !newList.includes(item));
          deletedSeparatedList?.forEach((name) => {
            this.showedViewsService.delSeparatedViews(name);
          });
          if (this.isSeparatedMain) {
            newShowedList.forEach((name: string, index: number) => {
              setTimeout(
                () =>
                  this.separateWindow({
                    name,
                    rect: { width: 700, height: 400, top: 200 + index * 30, left: 200 + index * 30 } as DOMRect
                  }),
                0
              );
            });
          } else {
            this.showedListWindow = newShowedList;
            this.showedViewsService.setShowedViews([...newShowedList]);
          }
        }
      });
  }

  showSeparatedWindows(): void {
    this.showedViewsService.setShow();
  }

  restoreSeparatedViews(): void {
    this.showedViewsService.showedSeparatedViews.pipe(take(1)).subscribe((listViews) => {
      listViews.forEach((item) => {
        this.separateWindow(item, true);
      });
    });
  }

  separateWindow(data: { name: string; rect: DOMRect }, isRestore?: boolean): void {
    this.modalService
      .openModal(OneViewWindowComponent, {
        dialogType: ConfirmationType.General,
        data,
        position: { top: `${data.rect.top}px`, left: `${data.rect.left}px` },
        hasBackdrop: false,
        panelClass: 'pane-window'
      })
      .afterClosed()
      .subscribe((isRemove) => {
        if (isRemove) {
          if (isRemove === 1) {
            this.showedListWindow.push(data.name as WindowType);
            this.showedViewsService.setShowedViews([...this.showedListWindow]);
          }
          this.showedViewsService.delSeparatedViews(data.name);
        }
      });
    if (!isRestore) {
      this.showedListWindow = this.showedListWindow.filter((item) => item !== data.name);
      this.showedViewsService.setShowedViews([...this.showedListWindow]);
      this.showedViewsService.addSeparatedViews(data);
    }
  }

  tryLeave(): void {
    this.showedViewsService.tryHide();
  }

  changeShowing(): void {
    this.showedViewsService.changeShowedBottomView(!!(this.multiView && !this.hidden));
  }

  toggleSeparate(): void {
    setStorage<boolean>('MAIN_FRAME_SEPARATED', !this.isSeparatedMain);
    if (!this.isSeparatedMain) {
      this.isSeparatedMain = true;
      this.showSeparateFrame();
    } else {
      this.matDialogRef?.close();
    }
  }

  showSeparateFrame(): void {
    const area = this.document.querySelector('.main-window').getBoundingClientRect();
    const rect = getStorage<MainRect>('MAIN_FRAME_RECT', {
      top: 50,
      left: 50,
      width: area.width - 150,
      height: area.height - 300
    } as MainRect);
    const checkedCenter = rect.width / 2 + rect.left;
    if (!this.isAuthenticated && (checkedCenter < 100 || area.width - checkedCenter < 100)) {
      rect.width = area.width - 30;
      rect.left = 0;
      rect.top = 0;
    }
    const data = { rect };
    this.matDialogRef = this.modalService.openModal(MainFrameComponent, {
      dialogType: ConfirmationType.General,
      data,
      position: { top: `${data.rect.top}px`, left: `${data.rect.left}px` },
      hasBackdrop: false,
      panelClass: 'pane-window',
      closeOnNavigation: false
    });
    this.matDialogRef.afterClosed().subscribe(() => {
      this.isSeparatedMain = false;
      this.matDialogRef = null;
    });
  }
}
