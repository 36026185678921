import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SelectedProjectMessengerService } from '../messengers/selected-project-messenger.service';
import { filter, takeUntil } from 'rxjs/operators';
import { ProjectFileGet } from 'app/generated-client/generated-client';
import { PaginationMainService } from './main/pagination-main.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectFilesFilterService extends PaginationMainService<ProjectFileGet> {
  constructor(protected http: HttpClient, private selectedProjectMessengerService: SelectedProjectMessengerService) {
    super(http);

    this.selectedProjectMessengerService.selectedProject
      .pipe(
        filter(c => !!c),
        takeUntil(this.$destroy)
      )
      .subscribe(project => {
        this.beginBaseUrl(`/api/projects/${project.id}/project-files`);
      });
  }
}
