import { NgFor } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';

import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { CompanyGet, TextTemplateGet, TextTemplatesClient } from 'app/generated-client/generated-client';
import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { UpperPaginatorComponent } from 'app/shared/components/upper-paginator/upper-paginator.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { CompaniesService } from 'app/shared/services/lightquery/companies.service';
import { TextTemplatesService } from 'app/shared/services/lightquery/text-templates.service';
import { ModalService } from 'app/shared/services/modal.service';

import { UpperPaginatorComponent as UpperPaginatorComponent_1 } from '../../../../shared/components/upper-paginator/upper-paginator.component';
import { TextTemplateTypePipe } from '../../../../shared/pipes/ui-data-display/text-template-type.pipe';
import { FlexLayoutDirective } from '../../../flex-layout/flex-layout.directive';

import { MasterTextTemplatesAddModalComponent } from '../master-text-templates-add-modal/master-text-templates-add-modal.component';
import { PaginationResult } from 'ng-lightquery';

@Component({
  selector: 'pa-master-text-templates-table',
  templateUrl: './master-text-templates-table.component.html',
  styleUrls: ['./master-text-templates-table.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutDirective,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatSelect,
    MatOption,
    NgFor,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    UpperPaginatorComponent_1,
    TextTemplateTypePipe
  ]
})
export class MasterTextTemplatesTableComponent implements OnInit {
  @ViewChild(UpperPaginatorComponent) paginatorComp: UpperPaginatorComponent;
  @ViewChild(MatSort, { static: true }) private sort: MatSort;
  companies: { [id: string]: CompanyGet } = {};
  listCompany: CompanyGet[] = [];
  filterByCompanyId = '';
  filterText = '';
  displayedColumns: string[] = ['name', 'companyName', 'templateType', 'default'];
  textTemplatesPaginated: PaginationResult<TextTemplateGet>;
  private filterSource = new Subject<boolean>();
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private companiesService: CompaniesService,
    private modalService: ModalService,
    public textTemplatesService: TextTemplatesService,
    private textTemplatesClient: TextTemplatesClient,
    private avaNotificationsService: AvaNotificationsService
  ) {}

  ngOnInit(): void {
    this.companiesService.getAll().subscribe((c) => {
      this.listCompany = c;
      c.forEach((item) => (this.companies[item.id] = item));
    });

    this.textTemplatesService.initService({
      sortTableObj: this.sort,
      paging: {
        page: 1,
        pageSize: 10
      },
      sorting: {
        isDescending: true,
        propertyName: 'name'
      }
    });
    this.sort.sort({
      id: 'name',
      start: 'asc',
      disableClear: true
    });

    this.textTemplatesService.paginationResult.pipe(takeUntil(this.$destroy)).subscribe((pr) => (this.textTemplatesPaginated = pr));

    this.filterSource.pipe(takeUntil(this.$destroy), debounceTime(250)).subscribe(() => this.setFilter());
  }

  ngOnDestroy(): void {
    this.setFilter(true);
    this.$destroy.next(true);
    this.$destroy.complete();
    this.filterSource.complete();
  }

  selectItem(row: TextTemplateGet): void {
    this.modalService
      .openModal(MasterTextTemplatesAddModalComponent, { dialogType: ConfirmationType.General, data: row })
      .afterClosed()
      .subscribe((e: TextTemplateGet) => {
        if (e) {
          if (e?.id) {
            this.textTemplatesClient.updateTextTemplate(row.id, e).subscribe({
              next: () => this.refresh(),
              error: () => this.avaNotificationsService.error('Fehler beim Speichern der Textvorlage.')
            });
          } else {
            this.modalService
              .openModal(ModalConfirmComponent, { dialogType: ConfirmationType.Delete, data: ['Löschen', 'Textvorlage', row.name] })
              .afterClosed()
              .subscribe((isConfirm) => {
                if (isConfirm) {
                  this.textTemplatesClient.deleteTextTemplate(row.id).subscribe({
                    next: () => this.refresh(),
                    error: () => this.avaNotificationsService.error('Fehler beim Löschen der Textvorlage.')
                  });
                }
              });
          }
        }
      });
  }

  refresh(): void {
    this.textTemplatesService.forceRefresh();
  }

  add(row: TextTemplateGet): void {
    this.textTemplatesClient.createTextTemplate(row).subscribe({
      next: () => this.refresh(),
      error: () => this.avaNotificationsService.error('Fehler beim Erstellen der Textvorlage.')
    });
  }

  changeFilter(): void {
    this.filterSource.next(true);
  }

  setFilter(isSetNull?: boolean): void {
    this.textTemplatesService.setQueryParameter('filter', isSetNull ? null : this.filterText);
    this.textTemplatesService.setQueryParameter('companyId', isSetNull ? null : this.filterByCompanyId);
  }
}
