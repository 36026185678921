<div class="spinner" *ngIf="!hasLoaded || isLoading">
  <div fxFlex fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
  </div>
</div>
<div class="wrapper" *ngIf="hasLoaded && !isLoading">
  <form (ngSubmit)="changeCostCenter()">
    <mat-form-field appearance="fill">
      <mat-label>Kostenstelle</mat-label>
      <input matInput name="costCenter" [(ngModel)]="currentProject.costCenter" (ngModelChange)="isChanged = true" />
    </mat-form-field>

    <div class="wrapper-btn">
      <button mat-raised-button type="submit" color="primary" [disabled]="!isChanged">Speichern</button>
      <button mat-raised-button type="button" color="warn" (click)="deleteCostCenter()">Löschen</button>
    </div>
  </form>
</div>
