import { AvaFileExportGet } from 'app/generated-client/generated-client';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SelectedProjectMessengerService } from '../messengers/selected-project-messenger.service';
import { SelectedSpecificationMessengerService } from '../messengers/selected-specification-messenger.service';
import { PaginationMainService } from './main/pagination-main.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AvaProjectAvaFileExportsService extends PaginationMainService<AvaFileExportGet> {
  private projectId: string;
  private avaProjectId: string;

  constructor(
    protected http: HttpClient,
    selectedProjectMessengerService: SelectedProjectMessengerService,
    selectedSpecificationMessengerService: SelectedSpecificationMessengerService
  ) {
    super(http);

    selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe(p => {
      this.projectId = p?.id;
      this.buildUrlWithParameters();
    });

    selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(takeUntil(this.$destroy))
      .subscribe((s: { avaProjectId: string; parentProjectId: string }) => {
        this.projectId = s?.parentProjectId;
        this.avaProjectId = s?.avaProjectId;
        this.buildUrlWithParameters();
      });
  }

  private buildUrlWithParameters() {
    if (this.projectId && this.avaProjectId) {
      this.beginBaseUrl(`/api/projects/${this.projectId}/ava-projects/${this.avaProjectId}/ava-file-exports`);
    } else {
      this.baseUrl = null;
    }
  }

  setAvaProjectId(avaProjectId: string): void {
    this.avaProjectId = avaProjectId;
    this.buildUrlWithParameters();
  }
}
