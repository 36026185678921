<ng-container *ngIf="!readOnly || totalCount !== 0; else emptyList">
  <table mat-table [dataSource]="addressesService" matSort (matSortChange)="addressesService.onSort($event)">
    <ng-container matColumnDef="zipCode">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>PLZ</th>
      <td mat-cell *matCellDef="let row">{{ row.zipCode }}</td>
    </ng-container>
    <ng-container matColumnDef="city">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Stadt</th>
      <td mat-cell *matCellDef="let row">{{ row.city }}</td>
    </ng-container>
    <ng-container matColumnDef="street">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Straße</th>
      <td mat-cell *matCellDef="let row">{{ row.street }}</td>
    </ng-container>
    <ng-container matColumnDef="countryCode">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Ländercode</th>
      <td mat-cell *matCellDef="let row">{{ row.countryCode }}</td>
    </ng-container>
    <ng-container matColumnDef="country">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Land</th>
      <td mat-cell *matCellDef="let row">{{ row.country }}</td>
    </ng-container>
    <ng-container matColumnDef="isPrimaryAddress">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Hauptadresse</th>
      <td mat-cell *matCellDef="let row">{{ row.isPrimaryAddress ? '✔' : '' }}</td>
    </ng-container>
    <ng-container matColumnDef="isDefaultInvoiceAddress">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Rechnunsgadresse</th>
      <td mat-cell *matCellDef="let row">{{ row.isDefaultInvoiceAddress ? '✔' : '' }}</td>
    </ng-container>
    <ng-container matColumnDef="delete">
      <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <button class="btn-delete" (click)="confirmDeletion($event, row)">
          <mat-icon color="warn">close</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayColumn"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumn" (click)="address(row)"></tr>
  </table>
  <pa-upper-paginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    [upperPaginated]="addressesService.paginationResult | async"
    [upperService]="addressesService"
    (page)="addressesService.onPage($event)"
  >
  </pa-upper-paginator>
  <div>
    <a mat-raised-button (click)="address()" *ngIf="!readOnly">Adresse Hinzufügen</a>
  </div>
</ng-container>
<ng-template #emptyList>
  <p>Keine Adressen zum Kontakt gespeichert</p>
</ng-template>
