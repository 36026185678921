<div mat-dialog-content class="wrapper">
  <h3>Projekt Kopieren</h3>

  <p>Das Projekt kann kopiert werden, dabei bleiben alle Daten und Einstellungen erhalten.</p>
  <p>
    <strong>Achtung:</strong>
    Alle Dateien werden dabei auf <i>Schreibgeschützt</i> gesetzt, das bedeutet, dass bestehenden Dateien in diesem Projekt nicht mehr
    gelöscht werden können.
  </p>

  <mat-form-field appearance="fill" class="suffix">
    <mat-label>Suffix</mat-label>
    <input matInput type="text" name="projectCopySuffix" [(ngModel)]="projectCopySuffix" />
  </mat-form-field>

  <p>Optional kann eine beliebige Projektnummer vergeben werden.</p>

  <mat-form-field appearance="fill" class="suffix">
    <mat-label>Projektnummer</mat-label>
    <input matInput type="text" name="manualProjectNumber" [(ngModel)]="manualProjectNumber" />
  </mat-form-field>
  <div>
    <mat-form-field appearance="fill">
      <mat-label>Neuer Status</mat-label>
      <mat-select name="status" [(value)]="newStatus">
        <mat-option *ngFor="let status of listStatus" [value]="status">{{ status | projectStatus }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-checkbox [(ngModel)]="setProjectToArchived">Dieses Projekt nach dem Kopieren archivieren?</mat-checkbox>
    <p>Wenn dieses Projekt archiviert wird, können keine Änderungen mehr vorgenommen werden.</p>
  </div>
  <div>
    <mat-checkbox [(ngModel)]="fixPriceOption">Kalkulationen im neuen Projekt festschreiben?</mat-checkbox>
  </div>
  <div>
    <mat-checkbox [(ngModel)]="isExcludePriceComponent" (change)="getUniquePriceComponents($event)"
      >Preiskomponenten ausschließen</mat-checkbox
    >
    <div class="price-components-wrap" *ngIf="isExcludePriceComponent && uniquePriceComponents.size">
      <fieldset>
        <div fxLayout="row" fxLayoutGap="12px">
          <mat-checkbox
            color="primary"
            *ngFor="let priceComponent of uniquePriceComponents"
            (change)="excludePriceComponentFromCopyProject($event, priceComponent)"
          >
            {{ priceComponent }}
          </mat-checkbox>
        </div>
      </fieldset>
    </div>
  </div>

  <div class="spinner" *ngIf="isStartCopying">
    <mat-spinner></mat-spinner>
  </div>
</div>
<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="copyProject()" [disabled]="isStartCopying">Kopieren</button>
  <button mat-raised-button color="warn" (click)="cancel()">Abbrechen</button>
</mat-dialog-actions>
