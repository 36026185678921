<div [style.maxHeight]="'calc(100%  - 60px )'" class="project">
  <div fxLayout="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start" class="project-wrapper">
    <mat-card class="project-card" *ngFor="let project of projectsPaginated?.data" fxFlex="0 0 256px">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>{{ project.name }}</mat-card-title>
        <mat-card-subtitle>
          <div>{{ project.companyName }}</div>
          {{ project.lastAccessedAtUtc | date: 'dd.MM.yyyy HH:mm' }}
        </mat-card-subtitle>
      </mat-card-header>
      <img mat-card-image [diIcon]="project.identiconId" [rectangleSize]="256" />
      <mat-card-content> </mat-card-content>
      <mat-card-actions>
        <button mat-button (click)="selectProject(project)">Auswählen</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
<pa-upper-paginator
  *ngIf="projectsPaginated"
  [pageSizeOptions]="pageSizeOptions"
  [upperPaginated]="projectsPaginated"
  [upperService]="projectsService"
  (page)="projectsService.onPage($event)"
>
</pa-upper-paginator>
