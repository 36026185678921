import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProjectStatus, UserSettings } from 'app/generated-client/generated-client';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { UserSettingsService } from 'app/shared/services/user-settings.service';

import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';
import { ServiceSpecificationTreeComponent } from '../../../service-specification-tree/service-specification-tree.component';

import { InvoicePositionsAllComponent } from '../invoice-positions-all/invoice-positions-all.component';
import { InvoicePositionsComponent } from '../invoice-positions/invoice-positions.component';

@Component({
  selector: 'pa-tree-invoice-positions',
  templateUrl: './tree-invoice-positions.component.html',
  styleUrls: ['./tree-invoice-positions.component.scss'],
  standalone: true,
  imports: [ServiceSpecificationTreeComponent, FlexLayoutDirective, NgIf, InvoicePositionsComponent, InvoicePositionsAllComponent]
})
export class TreeInvoicePositionsComponent implements OnInit, OnDestroy {
  isReadOnly: boolean;
  currentUserSettings: UserSettings;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private userSettingsService: UserSettingsService
  ) {}

  ngOnInit(): void {
    this.selectedProjectMessengerService.selectedProject
      .pipe(takeUntil(this.$destroy))
      .subscribe((p) => (this.isReadOnly = p?.status === ProjectStatus.Archived || p?.status === ProjectStatus.Locked));

    this.userSettingsService.currentUserSettings.pipe(takeUntil(this.$destroy)).subscribe((setting: UserSettings) => {
      this.currentUserSettings = setting;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }
}
