import { NgClass, NgIf, CurrencyPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { MatMenuTrigger, MatMenu, MatMenuContent, MatMenuItem } from '@angular/material/menu';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';

import { Subject, fromEvent, take, takeUntil } from 'rxjs';

import { ProjectGet, ProjectsClient, UserSettingsClient } from 'app/generated-client/generated-client';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { ContextMenuSettingsService } from 'app/shared/services/context-menu-settings.service';
import { ProjectsService } from 'app/shared/services/lightquery/projects.service';
import { LockProjectService } from 'app/shared/services/lock-project.service';

import { LockIconComponent } from '../../../../shared/components/lock-icon/lock-icon.component';
import { TableWrapperComponent } from '../../../../shared/components/table-wrapper/table-wrapper.component';
import { UpperPaginatorComponent } from '../../../../shared/components/upper-paginator/upper-paginator.component';
import { ProjectStatusPipe } from '../../../../shared/pipes/ui-data-display/project-status.pipe';

import { NgDanglIconsModule } from 'ng-dangl-icons';
import { PaginationResult } from 'ng-lightquery';

@Component({
  selector: 'pa-projects-table',
  templateUrl: './projects-table.component.html',
  styleUrls: ['./projects-table.component.scss'],
  standalone: true,
  imports: [
    TableWrapperComponent,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatRipple,
    NgDanglIconsModule,
    MatSortHeader,
    LockIconComponent,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    NgClass,
    NgIf,
    UpperPaginatorComponent,
    MatMenu,
    MatMenuContent,
    MatMenuItem,
    MatMenuTrigger,
    CurrencyPipe,
    DatePipe,
    ProjectStatusPipe
  ]
})
export class ProjectsTableComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) private sort: MatSort;
  @ViewChild(MatMenuTrigger) private contextMenu: MatMenuTrigger;
  @Input() projectsPaginated: PaginationResult<ProjectGet>;
  @Input() pageSizeOptions: number[];
  @Input() isContextMenu: boolean;
  @Output() selectRow = new EventEmitter<ProjectGet>();
  @Output() getContextMenu = new EventEmitter<{ command: string; data: any }>();
  @Output() isMenuOpened = new EventEmitter<boolean>();

  selectedProject: ProjectGet;
  totalPrices: { [projectId: string]: number } = {};
  private $destroy = new Subject<void>();

  displayedColumns = [
    'identicon',
    'locking',
    'constructedProjectNumber',
    'name',
    'contactName',
    'tendererContactName',
    'status',
    'companyName',
    'costCenter',
    'totalPrice',
    'lastAccessedAtUtc'
  ];
  contextMenuPosition = { x: '0px', y: '0px' };

  constructor(
    public projectsService: ProjectsService,
    private contextMenuSettingsService: ContextMenuSettingsService,
    private notificationsService: AvaNotificationsService,
    private lockProjectService: LockProjectService,
    private projectsClient: ProjectsClient,
    private userSettingsClient: UserSettingsClient
  ) {}

  ngOnInit(): void {
    this.projectsService.initService({
      paging: { page: 1, pageSize: this.isContextMenu ? 5 : 20 },
      sorting: {
        propertyName: 'lastAccessedAtUtc',
        isDescending: true
      },
      sortTableObj: this.sort
    });

    this.projectsService.paginationResult.pipe(takeUntil(this.$destroy)).subscribe((r) => {
      const projectIds = r.data.map((p) => p.id).filter((id) => this.totalPrices[id] == null);
      if (projectIds.length > 0) {
        this.projectsClient
          .getTotalPricesForProjects({
            projectIds: projectIds
          })
          .subscribe((totalPrices) => {
            this.totalPrices = {
              ...this.totalPrices,
              ...totalPrices.totalPriceByProjectId
            };
          });
      }
    });

    if (this.isContextMenu) {
      this.userSettingsClient.getLastOpenedProjectAsCopyCalculationSource().subscribe((p) => {
        this.selectedProject = p;
      });
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  selectProject(project: ProjectGet): void {
    if (this.lockProjectService.currentUserHasAccess(project)) {
      this.selectRow.emit(project);
      this.selectedProject = project;
    } else {
      this.notificationsService.error('Dieses Projekt ist gesperrt');
    }
  }

  showContextMenu(event: MouseEvent, element: ProjectGet): void {
    if (this.isContextMenu) {
      fromEvent(document, 'click')
        .pipe(take(1))
        .subscribe(() => {
          if (this.contextMenu.menuOpen) {
            this.isMenuOpened.emit(this.contextMenu.menuOpen);
          }
        });
      this.isMenuOpened.emit(this.contextMenu.menuOpen);
      this.contextMenuSettingsService.setDefaultSettings(event, element, this.contextMenuPosition, this.contextMenu);
    }
  }

  importPositions(row): void {
    this.getContextMenu.emit({ command: 'allCalculations', data: row });
  }
}
