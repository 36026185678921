<div class="wrapper">
  <div class="wrapper-table" [style.opacity]="isLoading ? '0.5' : '1'">
    <div class="totals" *ngIf="structureView === 'invoices'"><span>Insgesamt abgerechnet:</span> {{ allTotalSum | projectCurrency }}</div>
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="quantityTakeOffsService.onSort($event)">
      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let row" [routerLink]="[row.id, row.calculationType === 'ByPage' ? 'pages' : 'positions']">
          {{ row.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="number">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Nummer</th>
        <td mat-cell *matCellDef="let row" [routerLink]="[row.id, row.calculationType === 'ByPage' ? 'pages' : 'positions']">
          {{ row.number }}
        </td>
      </ng-container>
      <ng-container matColumnDef="calculationType">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Typ</th>
        <td mat-cell *matCellDef="let row" [routerLink]="[row.id, row.calculationType === 'ByPage' ? 'pages' : 'positions']">
          {{ row.calculationType | quantityTakeOffCalculationType }}
        </td>
      </ng-container>
      <ng-container matColumnDef="totalSum">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="right">Summe</th>
        <td mat-cell *matCellDef="let row" [routerLink]="[row.id, row.calculationType === 'ByPage' ? 'pages' : 'positions']" class="right">
          {{ row.calculation?.totalSum | projectCurrency }}
        </td>
      </ng-container>
      <ng-container matColumnDef="modifiedAtUtc">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Letzte Änderung</th>
        <td mat-cell *matCellDef="let row" [routerLink]="[row.id, row.calculationType === 'ByPage' ? 'pages' : 'positions']">
          {{ row.modifiedAtUtc | date: 'dd.MM.yyyy HH:mm' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="isProjectQuantityCalculation">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Projektmengen</th>
        <td mat-cell *matCellDef="let row">
          <span *ngIf="row.calculationType === 'ByPosition' && row.isProjectQuantityCalculation"> ✔ Verknüpft </span>
          <span
            *ngIf="
              row.calculationType === 'ByPosition' &&
              !row.useAsAssumedQuantities &&
              !row.isProjectQuantityCalculation &&
              !hasAnyQtoCalculationReferences
            "
          >
            Nicht verknüpft
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="useAsAssumedQuantities">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>VA-Mengen</th>
        <td mat-cell *matCellDef="let row">
          <span *ngIf="row.calculationType === 'ByPosition' && row.useAsAssumedQuantities"> ✔ Verknüpft </span>
          <span
            *ngIf="
              row.calculationType === 'ByPosition' &&
              !row.useAsAssumedQuantities &&
              !row.isProjectQuantityCalculation &&
              !hasAnyQtoAssumedQuantities
            "
          >
            Nicht verknüpft
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="billed">
        <th
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          matTooltip="Datum der Rechnungsstellung. Als abgerechnet markierte können nicht mehr bearbeitet werden."
        >
          Abgerechnet
        </th>
        <td
          mat-cell
          *matCellDef="let row"
          matTooltip="Datum der Rechnungsstellung. Als abgerechnet markierte können nicht mehr bearbeitet werden."
        >
          <div matRipple class="bill" (click)="selectBill($event, row)">
            {{ row.markedAsBilledAtUtc | date: 'dd.MM.yyyy' }}
            <span class="material-icons icon icon-calendar" *ngIf="!row.markedAsBilledAtUtc">edit_calendar</span>
            <mat-icon class="icon" color="warn" *ngIf="row.markedAsBilledAtUtc">close</mat-icon>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" class="center">
          <button mat-icon-button color="primary" (click)="showContextMenu($event, row)">
            <mat-icon>more_vert</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
    </table>
    <pa-upper-paginator
      [pageSizeOptions]="[5, 10, 25, 100]"
      [upperPaginated]="quantityTakeOffsService.paginationResult | async"
      [upperService]="quantityTakeOffsService"
      (page)="quantityTakeOffsService.onPage($event)"
    >
    </pa-upper-paginator>
  </div>
  <div fxFlexFill class="spinner" *ngIf="isLoading" fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
  </div>
</div>
<pa-invoice-table-menu
  [contextMenuPosition]="contextMenuPosition"
  [structureView]="structureView"
  [hasAnyQtoCalculationReferences]="hasAnyQtoCalculationReferences"
  [hasAnyQtoAssumedQuantities]="hasAnyQtoAssumedQuantities"
  (invoiceMenuActions)="handlerMenuAction($event)"
></pa-invoice-table-menu>
