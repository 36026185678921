import { NgIf } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  GroupCalculationTotalsClient,
  GroupCalculationTotalsGet,
  ServiceSpecificationGroupDto
} from 'app/generated-client/generated-client';
import { ElementViewMessengerService } from 'app/shared/services/electron/element-view-messenger.service';

import { SelectedSpecificationMessengerService } from '../../../../shared/services/messengers/selected-specification-messenger.service';

import { GroupTotalsTableComponent } from '../group-totals-table/group-totals-table.component';

@Component({
  selector: 'pa-service-specification-group',
  templateUrl: './service-specification-group.component.html',
  styleUrls: ['./service-specification-group.component.scss'],
  standalone: true,
  imports: [NgIf, MatCheckbox, FormsModule, GroupTotalsTableComponent]
})
export class ServiceSpecificationGroupComponent implements OnInit, OnChanges, OnDestroy {
  @Input() element: ServiceSpecificationGroupDto;
  @Input() projectId: string;
  @Input() serviceSpecificationId: string;
  isLoaded: boolean;
  currentTotals: GroupCalculationTotalsGet;
  elementViewOpen = false;
  private _includePositionsWithoutTotalPrice = false;
  set includePositionsWithoutTotalPrice(value: boolean) {
    this._includePositionsWithoutTotalPrice = value;
    this.tryGetGroupTotals();
  }
  get includePositionsWithoutTotalPrice(): boolean {
    return this._includePositionsWithoutTotalPrice;
  }
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private elementViewMessengerService: ElementViewMessengerService,
    private groupCalculationTotalsClient: GroupCalculationTotalsClient,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService
  ) {}

  ngOnInit(): void {
    this.tryGetGroupTotals();
    this.elementViewMessengerService.elementViewVisible.pipe(takeUntil(this.$destroy)).subscribe((isOpen) => {
      this.elementViewOpen = isOpen;
    });

    this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(takeUntil(this.$destroy)).subscribe((s) => {
      this.projectId = s?.parentProjectId;
      this.serviceSpecificationId = s?.avaProjectId;
      this.tryGetGroupTotals();
    });
  }

  ngOnChanges(): void {
    this.tryGetGroupTotals();
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private tryGetGroupTotals(): void {
    this.isLoaded = false;
    if (this.projectId && this.serviceSpecificationId) {
      this.groupCalculationTotalsClient
        .getGroupTotals(this.projectId, this.serviceSpecificationId, this.element.id, this.includePositionsWithoutTotalPrice)
        .pipe(takeUntil(this.$destroy))
        .subscribe((totals: GroupCalculationTotalsGet) => {
          this.currentTotals = totals;
          this.isLoaded = true;
        });
    } else {
      this.currentTotals = null;
      this.isLoaded = true;
    }
  }
}
