import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, RouterOutlet } from '@angular/router';

import { take } from 'rxjs/operators';

import { AvaHubConnector } from '../../../../shared/services/signalr/ava-hub-connector';
import { CurrentProjectIdService } from '../../../current-projectId.service';

@Component({
  selector: 'pa-project-id',
  templateUrl: './project-id.component.html',
  styleUrls: ['./project-id.component.scss'],
  standalone: true,
  imports: [RouterOutlet]
})
export class ProjectIdComponent implements OnInit, OnDestroy {
  private projectId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private currentProjectService: CurrentProjectIdService,
    private avaHubConnector: AvaHubConnector
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.pipe(take(1)).subscribe((p: Params) => {
      this.projectId = p['projectId'];
      this.currentProjectService.selectProject(this.projectId);
      this.avaHubConnector.notifyOfEnteringProject(this.projectId);
    });
  }

  ngOnDestroy(): void {
    this.avaHubConnector.notifyOfExitingProject(this.projectId);
  }
}
