import { NgIf, NgFor } from '@angular/common';
import { Component, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatSelect } from '@angular/material/select';

import { filter, first } from 'rxjs/operators';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';
import {
  AvaProjectAdditionsClient,
  AvaProjectPriceComponentAdditionGet,
  PriceComponentType,
  PriceInquiryRequestBidderPriceImportPut
} from 'app/generated-client/generated-client';

import { PriceComponentTypePipe } from '../../pipes/ui-data-display/price-component-type.pipe';
import { AvaNotificationsService } from '../../services/ava-notifications.service';
import { SelectedSpecificationMessengerService } from '../../services/messengers/selected-specification-messenger.service';

@Component({
  selector: 'pa-selecting-price-component-modal',
  templateUrl: './selecting-price-component-modal.component.html',
  styleUrls: ['./selecting-price-component-modal.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    NgIf,
    MatProgressSpinner,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutDirective,
    MatFormField,
    MatSelect,
    NgFor,
    MatOption,
    MatRadioGroup,
    MatRadioButton,
    MatLabel,
    MatInput,
    MatSuffix,
    MatCheckbox,
    MatButton,
    PriceComponentTypePipe
  ]
})
export class SelectingPriceComponentModalComponent implements OnInit {
  priceComponents: AvaProjectPriceComponentAdditionGet[] | null = null;
  isLoading = true;
  priceComponentForm: FormGroup | null = null;

  constructor(
    @Optional() public dialogRef: MatDialogRef<SelectingPriceComponentModalComponent>,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private avaProjectAdditionsClient: AvaProjectAdditionsClient,
    private avaNotificationsService: AvaNotificationsService
  ) {}

  ngOnInit(): void {
    this.priceComponentForm = new FormGroup({
      priceComponent: new FormControl(),
      appendType: new FormControl('NewSubPosition'),
      discountRate: new FormControl(),
      setExistingRowsInCalculationsToInternal: new FormControl(false)
    });
    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        filter((p) => !!p),
        first()
      )
      .subscribe(
        (p) => {
          this.avaProjectAdditionsClient.getAvaProjectAdditions(p.parentProjectId, p.avaProjectId).subscribe((additions) => {
            this.priceComponents = additions.priceComponents;
            if (this.priceComponents?.length > 0) {
              let initialPriceComponent = this.priceComponents.find((pc) => pc.priceComponentType === PriceComponentType.Subcontractor);
              if (!initialPriceComponent) {
                initialPriceComponent = this.priceComponents[0];
              }
              this.priceComponentForm.controls['priceComponent'].setValue(initialPriceComponent.priceComponent);
            }
            this.isLoading = false;
          });
        },
        () => {
          this.avaNotificationsService.error('Fehler beim Laden der Preiskomponenten für das Projekt.');
          this.isLoading = false;
        }
      );
  }

  selecting(): void {
    const payload: PriceInquiryRequestBidderPriceImportPut = {
      ...this.priceComponentForm.value,
      discountRate: this.priceComponentForm.value.discountRate / 100
    };
    this.dialogRef.close(payload);
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
