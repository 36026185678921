<div class="buttons">
  <button mat-raised-button color="primary" type="button" (click)="addItem()">Neu</button>
</div>
<div class="list">
  @switch (typeCodeTable) { @case (typesTable.InvoiceRecipientCode) {
  <pa-invoice-recipient-codes-list [isEdit]="true" #list></pa-invoice-recipient-codes-list>
  } @case (typesTable.InvoicePeriodCode) {
  <pa-invoice-period-codes-list [isEdit]="true" #list></pa-invoice-period-codes-list>
  } @case (typesTable.ProjectBEC) {
  <pa-building-element-codes-list [isEdit]="true" #list></pa-building-element-codes-list>
  } @case (typesTable.ServicePeriod) {
  <pa-service-period-codes-list [isEdit]="true" #list></pa-service-period-codes-list>
  } }
</div>
