import { NgClass } from '@angular/common';
import { Component, Input, Output, EventEmitter, ViewChild, HostListener } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'pa-upper-paginator',
  templateUrl: './upper-paginator.component.html',
  styleUrls: ['./upper-paginator.component.scss'],
  standalone: true,
  imports: [MatPaginatorModule, MatTooltipModule, MatInputModule, NgClass]
})
export class UpperPaginatorComponent {
  @Input() pageSizeOptions: number[];
  @Input() upperPaginated: any;
  @Input() upperService: any;
  @Input() isSmaller: boolean;
  @Output() page = new EventEmitter<PageEvent>();
  @ViewChild(MatPaginator) upperPaginator: MatPaginator;
  @HostListener('document:keyup', ['$event']) handleKeyboardUpEvent(event: KeyboardEvent): void {
    switch (event.key) {
      case 'PageUp':
        this.upperPaginator.previousPage();
        break;
      case 'PageDown':
        this.upperPaginator.nextPage();
        break;
      default:
    }
  }

  constructor() {}

  onPage(pageEvent: PageEvent): void {
    this.page.emit(pageEvent);
  }

  inputPage(event, upPaginated, upService): void {
    const numPage = Number(event.target.value);
    const max = Math.ceil(upPaginated.totalCount / upPaginated.pageSize);
    if (numPage > 0 && numPage <= max) {
      const newPage: number = Number(event.target.value);
      if (upService.page !== newPage) {
        this.upperPaginator.firstPage();
        if (newPage > 1) {
          Array(newPage - 1)
            .fill(1)
            .forEach(() => this.upperPaginator.nextPage());
        }
      }
    }
    event.target.value = null;
  }
}
