<div class="wrapper">
  <div [ngStyle]="{ color: color }">{{ action }} bestätigen: {{ name }}?</div>
  <div>{{ detail }}</div>
</div>
<mat-dialog-actions>
  <button
    mat-raised-button
    [mat-dialog-close]="true"
    color="primary"
    class="btn-action"
    #confirmButton
    (focus)="isBtnConfirmFocused = true"
    (blur)="isBtnConfirmFocused = false"
  >
    {{ action }}
  </button>
  <button
    mat-raised-button
    mat-dialog-close
    color="warn"
    #closemButton
    (focus)="isBtnCloseFocused = true"
    (blur)="isBtnCloseFocused = false"
  >
    Abbrechen
  </button>
</mat-dialog-actions>
