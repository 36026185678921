<div class="wrapper-btn" fxLayoutAlign="start" fxLayoutGap="10px">
  <button mat-raised-button color="primary" (click)="saveAssignedQuantities()" [disabled]="!selectedElement || !quantityAssignments.length">
    <span>LV Menge zuordnen</span>
  </button>
  <button
    mat-raised-button
    color="primary"
    (click)="unassignQuantities()"
    [disabled]="
      !selectedElement ||
      !assignedQuantities ||
      !assignedQuantities.quantityAssignmentsByPositionId[selectedElement.id] ||
      !quantityAssignments ||
      !quantityAssignments.length
    "
  >
    <span>Zuordnung aufheben</span>
  </button>

  <button mat-raised-button color="primary" (click)="openCreateQtoDialog()" [disabled]="!hasAssignedquantitiesInBackend">
    BIM Mengenermittlung
  </button>
</div>
