<div class="wrapper">
  <h2>Mengenrundung</h2>

  <div class="invoice-rounding-group">
    <div *ngFor="let roundingOption of roundingOptions" class="invoice-rounding-option">
      <mat-checkbox [(ngModel)]="roundingOption.alwaysRoundUp">Immer aufrunden</mat-checkbox>

      <mat-form-field>
        <mat-label>Weniger als</mat-label>
        <input number matInput name="lessThan" [(ngModel)]="roundingOption.lessThan" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Runden zum Nächsten</mat-label>
        <input number matInput name="roundToNearest" [(ngModel)]="roundingOption.roundToNearest" />
      </mat-form-field>
      <button mat-mini-fab color="warn" (click)="removeRoundingOption(roundingOption)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>

  <button mat-fab color="primary" (click)="addRoundingOption()">
    <mat-icon>add</mat-icon>
  </button>

  <div class="rounding-wrap">
    <mat-form-field>
      <mat-label>Zuschlag (wird auf Menge aufgeschlagen)</mat-label>
      <input type="number" matInput [(ngModel)]="roundingMargin" />
      <span matSuffix>%</span>
    </mat-form-field>
  </div>
  <div class="button-actions" fxLayout="row" fxLayoutGap="8px" *ngIf="isModal">
    <button mat-button mat-raised-button color="primary" type="button" (click)="saveRounding(true)">Übernehmen</button>
    <button mat-button mat-raised-button color="warn" type="button" (click)="saveRounding(false)">Ohne Rundung</button>
  </div>
</div>
