import { Component, Input } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { ModalService } from 'app/shared/services/modal.service';

import { MasterDataProductGet, MasterDataProductsClient, PriceComponentType } from '../../../../generated-client/generated-client';

import { MasterDataProductsTableComponent } from '../master-data-products-table/master-data-products-table.component';
import { ModalMasterItemsNewComponent } from '../modal-master-items-articles-new/modal-master-items-new.component';

@Component({
  selector: 'pa-master-data-products',
  templateUrl: './master-data-products.component.html',
  styleUrls: ['./master-data-products.component.scss'],
  standalone: true,
  imports: [MatButton, MasterDataProductsTableComponent]
})
export class MasterDataProductsComponent {
  private $destroy: Subject<boolean> = new Subject<boolean>();
  @Input() priceComponentType: PriceComponentType;
  @Input() createNewText: string;
  @Input() routeCategory: string;

  constructor(
    private modalService: ModalService,
    private router: Router,
    private masterDataProductsClient: MasterDataProductsClient
  ) {}

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  public selectElement(item: MasterDataProductGet): void {
    this.router.navigate(['master-data', this.routeCategory, 'item', item.id]);
  }

  public createItem(): void {
    this.modalService
      .openModal(ModalMasterItemsNewComponent, { dialogType: ConfirmationType.General })
      .afterClosed()
      .subscribe((model: { name: string; description?: string; unit: string; price: number }) => {
        if (model?.name) {
          const { name, description, unit, price } = model;
          this.masterDataProductsClient
            .createMasterDataProduct({
              name: name,
              description: description,
              price: price,
              priceComponentType: this.priceComponentType,
              unit: unit
            })
            .pipe(takeUntil(this.$destroy))
            .subscribe(({ id }: MasterDataProductGet) => {
              this.router.navigate(['master-data', this.routeCategory, 'item', id]);
            });
        }
      });
  }
}
