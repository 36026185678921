<ng-content></ng-content>

<div *ngIf="!listData || listData.length === 0">
  <span class="no-errors">Keine Fehler</span>
</div>

<mat-selection-list [multiple]="false" *ngIf="listData && listData.length > 0">
  <mat-list-option color="primary" *ngFor="let position of listData" (click)="selectPosition(position)">
    <span
      *ngIf="
        position.elementTypeDiscriminator === 'ServiceSpecificationGroupDto' || position.elementTypeDiscriminator === 'PositionDto';
        else withOutItemNumber
      "
    >
      {{ $any(position)?.itemNumber?.stringRepresentation }} - {{ position | positionText }}
    </span>
    <ng-template #withOutItemNumber>
      {{ position | positionText }}
    </ng-template>
  </mat-list-option>
</mat-selection-list>
