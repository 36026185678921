<div fxLayoutAlign="space-between center">
  <h2>Preisanfragen</h2>
  <div>
    <button *ngIf="!selectedRequest" mat-raised-button color="primary" (click)="createPackage()" [disabled]="isDownloading">
      Anfragedateien Speichern
    </button>
    <button *ngIf="!selectedRequest" mat-raised-button color="primary" (click)="createPriceInquiryRequests()">Neue Preisanfrage</button>
    <button *ngIf="!selectedRequest" mat-raised-button color="primary" (click)="createPriceInquiryRequestsViaDragAndDrop()">
      Neue Preisanfrage per Drag & Drop
    </button>
  </div>
</div>

<div *ngIf="dataSource && !selectedRequest" class="table-wrap">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="index">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let row; let idx = index" matRipple>
        {{ idx + 1 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let row" matRipple>
        {{ row.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="createdAtUtc">
      <th mat-header-cell *matHeaderCellDef>Erstellt</th>
      <td mat-cell *matCellDef="let row" matRipple>
        {{ row.createdAtUtc | date: 'dd.MM.yyyy HH:mm' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="submittedAtUtc">
      <th mat-header-cell *matHeaderCellDef>Abgegeben</th>
      <td mat-cell *matCellDef="let row" matRipple>
        {{ row.submittedAtUtc | date: 'dd.MM.yyyy HH:mm' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let row" matRipple>
        {{ row.status | priceInquiryStatus }}
      </td>
    </ng-container>

    <ng-container matColumnDef="comments">
      <th mat-header-cell *matHeaderCellDef>Kommentare</th>
      <td mat-cell *matCellDef="let row" matRipple>
        {{ row.comments }}
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button (click)="deletePriceInquiry($event, row)" matTooltip="Löschen">
          <mat-icon color="warn">close</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="selectedRequest = row"
      [ngClass]="{ accepted: row.status === priceInquiryStatus.Accepted }"
    ></tr>
  </table>
</div>
<div *ngIf="selectedRequest">
  <h3>
    Anfrage an:
    <span class="name">
      {{ selectedRequest.name }}
      ({{ selectedRequest.createdAtUtc | date: 'dd.MM.yyyy HH:mm' }})
    </span>
    <span class="status">
      {{ selectedRequest.status | priceInquiryStatus }}
    </span>
  </h3>
  <div fxLayout="row" fxLayoutGap="12px" class="buttons">
    <button mat-raised-button color="primary" type="button" (click)="selectedRequest = null; fun = 0">Alle Anfragen</button>
    <button mat-raised-button [color]="fun === 1 ? '' : 'primary'" type="button" (click)="fun = 1">Einspielen aus Datei</button>
    <button mat-raised-button [color]="fun === 2 ? '' : 'primary'" type="button" (click)="fun = 2">Tabellenansicht</button>
    <button mat-raised-button color="primary" type="button" (click)="sendPriceInquiry()">Exportieren</button>
  </div>
  <div class="data">
    <ng-container *ngIf="fun === 1">
      <pa-price-inquiries-input-file
        [priceInquiryId]="selectedPriceInquiry?.id"
        [requestId]="selectedRequest.id"
        (importFile)="fun = 2"
      ></pa-price-inquiries-input-file>
    </ng-container>
    <ng-container *ngIf="fun === 2">
      <pa-price-inquiries-input-manually
        [priceInquiryId]="selectedPriceInquiry?.id"
        [requestId]="selectedRequest.id"
      ></pa-price-inquiries-input-manually>
    </ng-container>
  </div>
</div>
