<div mat-dialog-content>
  <h2>Preisanfrage Erstellen</h2>
  <div class="tree" *ngIf="currentFun === 2">
    <pa-main-tree [isSelectingMode]="true"></pa-main-tree>
  </div>

  <form [formGroup]="formInquiry" (ngSubmit)="createInquiry()" class="form-wrap">
    <div fxLayout="column" *ngIf="formInquiry">
      <mat-form-field appearance="fill">
        <mat-label>Elemente auswählen</mat-label>
        <mat-select (selectionChange)="chooseFunction($event)" required>
          <mat-option *ngFor="let fun of listFun; let ind = index" [value]="ind + 1">
            {{ fun }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input type="text" matInput formControlName="name" required />
      </mat-form-field>

      <div>
        <mat-checkbox color="primary" formControlName="useAssumedQuantities"> Anfrage mit VA-Mengen erstellen </mat-checkbox>
      </div>

      <div fxLayout="row" fxLayoutGap="12px" class="buttons">
        <button mat-raised-button color="warn" type="button" mat-dialog-close>Abbrechen</button>
        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="formInquiry.invalid || !(isAllElements || formInquiry.value.elementSelectionId || formInquiry.value.elementSelection)"
        >
          Erstellen
        </button>
      </div>
    </div>
  </form>
</div>
