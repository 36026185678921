import { NgIf, NgFor } from '@angular/common';
import { Component, OnInit, Self, OnDestroy, Optional } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MatDialogContent, MatDialogClose } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';

import { delay, Subject, take, takeUntil } from 'rxjs';

import { TreeNodeSelectingService } from 'app/areas/tree/services/tree-node-selecting.service';
import { TreeNodeStateService } from 'app/areas/tree/services/tree-node-state.service';
import { ElementSelectionGet, SelectedElement, TreeViewDisplayType } from 'app/generated-client/generated-client';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { GroupViewService } from 'app/shared/services/group-view.service';
import { ProjectQuantityEstimationService } from 'app/shared/services/messengers/project-quantity-estimation.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';

import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';
import { MainTreeComponent } from '../../../../../tree/components/main-tree/main-tree.component';

import { SelectionElementListModalComponent } from '../selection-element-list-modal/selection-element-list-modal.component';

@Component({
  selector: 'pa-price-inquiries-new-modal',
  templateUrl: './price-inquiries-new-modal.component.html',
  styleUrls: ['./price-inquiries-new-modal.component.scss'],
  providers: [GroupViewService],
  standalone: true,
  imports: [
    MatDialogContent,
    NgIf,
    MainTreeComponent,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutDirective,
    MatFormField,
    MatLabel,
    MatSelect,
    NgFor,
    MatOption,
    MatInput,
    MatCheckbox,
    MatButton,
    MatDialogClose
  ]
})
export class PriceInquiriesNewModalComponent implements OnInit, OnDestroy {
  private $destroy: Subject<boolean> = new Subject<boolean>();
  formInquiry: FormGroup = this.fb.group({
    elementSelectionId: null,
    elementSelection: null,
    name: '',
    useAssumedQuantities: false
  });
  isAllElements: boolean;
  currentFun: number;
  listFun: string[] = ['Gesamt-LV', 'Auswahl', 'Bestehende Auswahl'];
  readonly treeViewDisplayType = TreeViewDisplayType.Tree;

  constructor(
    @Optional() private matDialogRef: MatDialogRef<PriceInquiriesNewModalComponent>,
    private modalService: ModalService,
    private fb: FormBuilder,
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private treeNodeStateService: TreeNodeStateService,
    @Self() private groupViewService: GroupViewService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private treeNodeSelectingService: TreeNodeSelectingService,
    private projectQuantityEstimationService: ProjectQuantityEstimationService
  ) {}

  ngOnInit(): void {
    //TODO Consider another solution, so that the tree when opening a modal window is always displayed in a tree structure
    //Since there is a delay added here, because we are also getting the value for ProjectSettings
    this.selectedProjectMessengerService.selectedProject.pipe(take(1), delay(100)).subscribe(() => {
      this.groupViewService.setGroupView(TreeViewDisplayType.Tree);
    });
    this.selectedSpecificationElementMessengerService.clearSelectedElement();
    this.treeNodeStateService.resetTreeState();
    this.treeNodeSelectingService.selectingChanged.pipe(takeUntil(this.$destroy)).subscribe((selectingChanged) => {
      this.getSelecting(selectingChanged);
    });

    this.projectQuantityEstimationService.currentProjectHasAssumedQuantities.pipe(takeUntil(this.$destroy)).subscribe((hasEstimation) => {
      if (!hasEstimation) {
        this.formInquiry.get('useAssumedQuantities').disable();
      }
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  createInquiry(): void {
    this.formInquiry.get('useAssumedQuantities').enable();
    this.matDialogRef.close(this.formInquiry.value);
  }

  setAllElements(): void {
    this.isAllElements = true;
  }

  chooseFromList(): void {
    this.modalService
      .openModal(SelectionElementListModalComponent, { dialogType: ConfirmationType.General, autoFocus: false })
      .afterClosed()
      .subscribe((e: ElementSelectionGet) => {
        if (e) {
          this.formInquiry.get('elementSelectionId').setValue(e.id);
          if (!this.formInquiry.value.name) {
            this.formInquiry.get('name').setValue(e.name);
          }
        }
      });
  }

  chooseFunction(event): void {
    this.currentFun = event.value;
    this.isAllElements = false;
    this.formInquiry.get('elementSelectionId').setValue(null);
    this.formInquiry.get('elementSelection').setValue(null);
    switch (this.currentFun) {
      case 1:
        this.setAllElements();
        break;
      case 3:
        this.chooseFromList();
        break;
    }
  }

  getSelecting(value: SelectedElement[]): void {
    if (value.length) {
      this.formInquiry.get('elementSelection').setValue({ selectedElements: value });
    } else {
      this.formInquiry.get('elementSelection').setValue(null);
    }
  }
}
