import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FocusRowService {
  focusedRowNumber: number;
  private focusedIndexRowSource = new ReplaySubject<number>(1);
  focusedIndexRow = this.focusedIndexRowSource.asObservable();

  constructor() {}

  setFocusedRowByIndex(index: number): void {
    this.focusedIndexRowSource.next(index);
    this.focusedRowNumber = index;
  }
}
