import { NgIf, NgFor } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionList, MatListOption } from '@angular/material/list';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { PositionDto } from 'app/generated-client/generated-client';

import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-position-list-without-quantity',
  templateUrl: './position-list-without-quantity.component.html',
  styleUrls: ['./position-list-without-quantity.component.scss'],
  standalone: true,
  imports: [NgIf, FlexLayoutDirective, MatProgressSpinner, MatSelectionList, NgFor, MatListOption, MatButton]
})
export class PositionListWithoutQuantityComponent implements OnInit {
  isLoading = false;
  positionListWithoutQuantity: PositionDto[];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PositionDto[],
    @Optional() private dialogRef: MatDialogRef<PositionListWithoutQuantityComponent>
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.positionListWithoutQuantity = this.data;
  }

  close(ignore: boolean): void {
    this.dialogRef.close(ignore);
  }
}
