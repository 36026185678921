<h2 mat-dialog-title>Element Einfügen</h2>
<div mat-dialog-content>
  <p>Das kopierte Element wird relativ zur Auswahl eingefügt.</p>
  <mat-form-field appearance="fill">
    <mat-label>Platzierung</mat-label>
    <mat-select [(ngModel)]="selectedPlace">
      <mat-option *ngFor="let place of places" [value]="place.value">
        {{ place.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div fxLayoutGap="12px">
    <button mat-flat-button color="primary" (click)="confirmSelectedLocation()" cdkFocusInitial>Ok</button>
    <button mat-flat-button color="warn" mat-dialog-close>Abbrechen</button>
  </div>
</div>
