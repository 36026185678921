<div class="wrapper">
  <div class="wrapper-table">
    <table mat-table [dataSource]="quantityTakeOffsService" matSort (matSortChange)="quantityTakeOffsService.onSort($event)">
      <ng-container matColumnDef="selectQto">
        <th mat-header-cell *matHeaderCellDef>Ausgewählt</th>
        <td mat-cell *matCellDef="let row" matRipple>
          <mat-checkbox [checked]="getSelectedValue(row.id)" (change)="changeSelection($event, row)"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let row">
          {{ row.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="calculationType">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Typ</th>
        <td mat-cell *matCellDef="let row">
          {{ row.calculationType | quantityTakeOffCalculationType }}
        </td>
      </ng-container>
      <ng-container matColumnDef="modifiedAtUtc">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Letzte Änderung</th>
        <td mat-cell *matCellDef="let row">
          {{ row.modifiedAtUtc | date: 'dd.MM.yyyy HH:mm' }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
    </table>
    <pa-upper-paginator
      [pageSizeOptions]="[5, 10, 25, 100]"
      [upperPaginated]="quantityTakeOffsService.paginationResult | async"
      [upperService]="quantityTakeOffsService"
      (page)="quantityTakeOffsService.onPage($event)"
    >
    </pa-upper-paginator>
  </div>

  <div fxLayoutGap="12px" class="btn-wrap">
    <button mat-button mat-raised-button color="primary" (click)="saveSelection()" [disabled]="!isChanged">Speichern</button>
    <button mat-button mat-raised-button color="warn" type="button" (click)="dismiss()">Abbrechen</button>
  </div>
</div>
