<div mat-dialog-content class="wrapper-tree">
  <div class="btn-wrap">
    <button mat-raised-button color="primary" (click)="saveSelection()">
      <mat-icon>save</mat-icon>
      <span>Speichern</span>
    </button>
    <button mat-raised-button color="warn" (click)="cancelSelection()">
      <span>Abbrechen</span>
    </button>
  </div>
  <div class="tree">
    <pa-main-tree [isSelectingMode]="true" [selectedElements]="selectedElements"></pa-main-tree>
  </div>
</div>
