<div class="spinner" *ngIf="isLoading">
  <div fxFlex fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
  </div>
</div>
<div class="wrapper" *ngIf="!isLoading">
  <mat-form-field appearance="fill" class="project-select">
    <mat-label>Firma</mat-label>
    <mat-select name="company" [ngModel]="currentProject?.companyId" (ngModelChange)="onChangeCompany($event)">
      <mat-option *ngFor="let company of listCompany" [value]="company.id">{{ company.name }}</mat-option>
    </mat-select>
  </mat-form-field>
  <div>
    <img src="{{ logoFileId | imgSrc | async }}" alt="Logo" class="logo" *ngIf="logoFileId" />
  </div>
  <div class="wrapper-btn">
    <button mat-raised-button color="primary" (click)="saveCompany()" [disabled]="!isChanged">Speichern</button>
  </div>
</div>
