import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { CalculationCheckGet } from 'app/generated-client/generated-client';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CheckErrorsAndShowNotificationService {
  constructor(private avaNotificationsService: AvaNotificationsService) {}

  checkForErrorsAndShowErrorNotifications(errors: CalculationCheckGet): boolean {
    let hasFoundError = false;
    if (errors?.elementIdsWithMissingTextAdditions?.length > 0) {
      hasFoundError = true;
      this.avaNotificationsService.error('Kalkulation: Positionen mit nicht ausgefüllten Textergänzungen');
    }
    if (errors?.positionIdsWithMissingCalculation?.length > 0) {
      hasFoundError = true;
      this.avaNotificationsService.error('Kalkulation: Positionen ohne Kalkulation');
    }
    if (errors?.positionIdsWithMissingUnitTag?.length > 0) {
      hasFoundError = true;
      this.avaNotificationsService.error('Kalkulation: Positionen ohne definierte Einheit');
    }
    if (errors?.positionIdsWithNegativePrice?.length > 0) {
      hasFoundError = true;
      this.avaNotificationsService.error('Kalkulation: Positionen mit negativem Preis');
    }
    if (errors?.positionIdsWithNegativeQuantity?.length > 0) {
      hasFoundError = true;
      this.avaNotificationsService.error('Kalkulation: Positionen mit negativer Menge');
    }
    if (errors?.positionIdsWithZeroPrice?.length > 0) {
      hasFoundError = true;
      this.avaNotificationsService.error('Kalkulation: Positionen mit Nullpreis');
    }
    if (errors?.positionIdsWithZeroQuantity?.length > 0) {
      hasFoundError = true;
      this.avaNotificationsService.error('Kalkulation: Positionen mit Nullmenge');
    }
    if (errors?.positionIdsWithErrorsInCalculation?.length > 0) {
      hasFoundError = true;
      this.avaNotificationsService.error('Kalkulation: Ansätze mit Fehlern');
    }

    return hasFoundError;
  }
}
