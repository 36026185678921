<div *ngIf="!subPositionElements" class="top-part">
  <h2>Bieterpreise</h2>
  <div fxLayoutGap="12px">
    <button mat-raised-button color="primary" (click)="toggleAll(true)"><mat-icon>add</mat-icon> Alle</button>
    <button mat-raised-button color="primary" (click)="toggleAll()"><mat-icon>remove</mat-icon> Alle</button>
  </div>
</div>
<div
  [ngClass]="{
    'table-wrap': !subPositionElements,
    'table-wrap-subelements': subPositionElements
  }"
  *ngIf="!subPositionElements || headerWidth?.length"
>
  <table mat-table [dataSource]="subPositionElements ? subPositionElements : dataSource" [class.edit]="isEditable" multiTemplateDataRows>
    <ng-container matColumnDef="index">
      <th mat-header-cell *matHeaderCellDef [style.width.px]="subPositionElements && headerWidth?.length ? headerWidth[0] : null">#</th>
      <td mat-cell *matCellDef="let row; let idx = dataIndex">
        {{ idx + 1 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="itemNumber">
      <th mat-header-cell *matHeaderCellDef [style.width.px]="subPositionElements && headerWidth?.length ? headerWidth[1] : null">OZ</th>
      <td mat-cell *matCellDef="let row">
        {{ subPositionElements ? row?.itemNumber?.stringRepresentation : positionsList[row.avaPositionId].itemNumber.stringRepresentation }}
      </td>
    </ng-container>

    <ng-container matColumnDef="shortText">
      <th mat-header-cell *matHeaderCellDef [style.width.px]="subPositionElements && headerWidth?.length ? headerWidth[2] : null">
        Kurztext
      </th>
      <td mat-cell *matCellDef="let row">
        {{ subPositionElements ? row.shortText : positionsList[row.avaPositionId].shortText }}
      </td>
    </ng-container>

    <ng-container matColumnDef="unitPrice">
      <th mat-header-cell *matHeaderCellDef [style.width.px]="subPositionElements && headerWidth?.length ? headerWidth[3] : null">
        {{ !subPositionElements ? 'EP' : 'Menge' }}
      </th>
      <td mat-cell *matCellDef="let row; let idx = index">
        <ng-container *ngIf="subPositionElements">
          <span> {{ row?.quantity | number: '1.3-3' }}</span>
          <span> {{ row?.unit }}</span>
        </ng-container>
        <ng-container *ngIf="!subPositionElements">
          <input
            [(ngModel)]="row.unitPrice"
            [disabled]="!isEditable"
            [attr.disabled]="!isEditable ? 'disabled' : null"
            type="number"
            matInputDecimalPlaces
            (change)="saveChanges(row.avaPositionId, row.unitPrice)"
          />
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="comment" [style.width.px]="subPositionElements && headerWidth?.length ? headerWidth[4] : null">
      <th mat-header-cell *matHeaderCellDef>{{ !subPositionElements ? 'Kommentar' : 'EP' }}</th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="!subPositionElements">
          {{ row.comment }}
        </ng-container>
        <ng-container *ngIf="subPositionElements">
          {{ row?.unitPrice | number: '1.2-2' }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="wasSetManually" [style.width.px]="subPositionElements && headerWidth?.length ? headerWidth[5] : null">
      <th mat-header-cell *matHeaderCellDef>{{ !subPositionElements ? 'Vom Auftraggeber gesetzt' : 'Artikelnummer' }}</th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="subPositionElements">
          {{ row.supplierArticleNumber }}
        </ng-container>
        <ng-container *ngIf="!subPositionElements">
          <mat-icon *ngIf="row.wasSetManually" color="primary">keyboard</mat-icon>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef [style.width.px]="subPositionElements && headerWidth?.length ? headerWidth[6] : null"></th>
      <td mat-cell *matCellDef="let element; let idx = dataIndex">
        <ng-container *ngIf="element.priceInquiryRequestPriceCommerceArticles?.length">
          <button mat-icon-button (click)="changeSubpositionsVisibility(idx)">
            <mat-icon *ngIf="!expandedRows.has(idx)">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="expandedRows.has(idx)">keyboard_arrow_up</mat-icon>
          </button>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element; let idx = dataIndex" [attr.colspan]="displayedColumns.length" class="expanded-detail">
        <div class="element-detail" [@detailExpand]="expandedRows.has(idx) ? 'expanded' : 'collapsed'">
          <span class="element-detail_header">Einzelartikel aus Händlerangebot</span>
          <pa-price-inquiries-input-manually
            *ngIf="expandedRows.has(idx)"
            [subPositionElements]="element.priceInquiryRequestPriceCommerceArticles"
            [headerWidth]="columnWidths"
          ></pa-price-inquiries-input-manually>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: displayedColumns"
      class="element-row"
      [class.expanded-row]="expandedElement === element"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
  </table>
</div>
<div class="buttons" fxLayoutGap="12px" *ngIf="!subPositionElements">
  <button mat-raised-button color="primary" type="button" (click)="isEditable = !isEditable" *ngIf="!isEditable">Bearbeiten</button>
  <button mat-raised-button type="button" (click)="isEditable = !isEditable" *ngIf="isEditable">Fertig</button>
</div>
