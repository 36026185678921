import { NgIf } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { FlatElementsService } from 'app/areas/tree/services/flat-elements.service';
import { TreeNodeStateService } from 'app/areas/tree/services/tree-node-state.service';
import {
  ElementSelection,
  ElementSelectionGet,
  ElementSelectionsClient,
  PriceInquiriesClient,
  PriceInquiryGet,
  ProjectDto,
  SelectedElement,
  ServiceSpecificationDto,
  ServiceSpecificationGroupDto,
  TreeViewDisplayType
} from 'app/generated-client/generated-client';
import { KeyboardPositionSelectionService } from 'app/shared/services/keyboard-position-selection.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { setAsSplitSize } from 'app/shared/utilities/area-size';
import { setStorage } from 'app/shared/utilities/storage';

import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';
import { MainTreeComponent } from '../../../../../tree/components/main-tree/main-tree.component';

import { PriceInquiriesRequestTableComponent } from '../price-inquiries-request-table/price-inquiries-request-table.component';
import { ServiceSpecificationTableViewComponent } from '../service-specification-table-view/service-specification-table-view.component';
import { SubcontractorsPriceLevelComponent } from '../subcontractors-price-level/subcontractors-price-level.component';
import { IOutputAreaSizes, AngularSplitModule } from 'angular-split';

@Component({
  selector: 'pa-price-inquiries-detail',
  templateUrl: './price-inquiries-detail.component.html',
  styleUrls: ['./price-inquiries-detail.component.scss'],
  providers: [KeyboardPositionSelectionService, FlatElementsService],
  standalone: true,
  imports: [
    FlexLayoutDirective,
    MatButton,
    AngularSplitModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatDatepicker,
    MainTreeComponent,
    MatTabGroup,
    MatTab,
    ServiceSpecificationTableViewComponent,
    PriceInquiriesRequestTableComponent,
    SubcontractorsPriceLevelComponent
  ]
})
export class PriceInquiriesDetailComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('asSplitRightRef') asSplitRightRef: ElementRef;
  @ViewChild('requestTable') requestTable: PriceInquiriesRequestTableComponent;
  projectId: string;
  avaProjectId: string;
  priceInquiryId: string;
  selectedPriceInquiry: PriceInquiryGet;
  formInquiry: FormGroup;
  avaProject: ServiceSpecificationDto;
  showedAvaProject: ServiceSpecificationDto;
  flatIdArray = new Set<string>();
  elementSelectionId: string | null;
  readonly treeViewDisplayType = TreeViewDisplayType.Tree;
  private $destroy = new Subject<boolean>();
  showAssumedQuantities = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private priceInquiriesClient: PriceInquiriesClient,
    private elementSelectionsClient: ElementSelectionsClient,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private fb: FormBuilder,
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private treeNodeStateService: TreeNodeStateService
  ) {}

  ngOnInit(): void {
    this.selectedSpecificationElementMessengerService.clearSelectedElement();
    this.treeNodeStateService.resetTreeState();
    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        takeUntil(this.$destroy),
        filter((r) => !!r)
      )
      .subscribe((e: { avaProjectId: string; parentProjectId: string; project: ProjectDto }) => {
        this.projectId = e?.parentProjectId;
        this.avaProjectId = e?.avaProjectId;
        this.avaProject = e?.project.serviceSpecifications[0];

        this.priceInquiryId = this.route.snapshot.paramMap.get('item');
        this.priceInquiriesClient
          .getPriceInquiryById(this.projectId, this.avaProjectId, this.priceInquiryId)
          .subscribe((e: PriceInquiryGet) => {
            this.selectedPriceInquiry = e;
            this.updateValues(e);
          });
      });
  }

  ngAfterViewInit(): void {
    setAsSplitSize('AS_SPLIT_TREE_RIGHT_SIZE', this.asSplitRightRef);
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  onDragEnd(sizes: IOutputAreaSizes): void {
    setStorage<number[]>('AS_SPLIT_TREE_RIGHT_SIZE', sizes as number[]);
  }

  back(): void {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  private updateValues(e: PriceInquiryGet): void {
    this.elementSelectionId = e?.elementSelectionId;
    const { name, createdAtUtc, elementSelectionId } = e;
    this.formInquiry = this.fb.group({ name, createdAtUtc, elementSelectionId });
    this.showAssumedQuantities = e.useAssumedQuantities;
    if (elementSelectionId) {
      this.elementSelectionsClient
        .getElementSelectionDetailById(this.projectId, this.avaProjectId, elementSelectionId)
        .subscribe((e: ElementSelectionGet) => {
          this.formShowedTree(e.data);
        });
    } else {
      this.showedAvaProject = JSON.parse(JSON.stringify(this.avaProject));
    }
  }

  private formShowedTree(data: ElementSelection): void {
    this.flatIdArray.clear();
    this.getId(data.selectedElements);
    this.showedAvaProject = JSON.parse(JSON.stringify(this.avaProject));
    this.copyElements(this.showedAvaProject, JSON.parse(JSON.stringify(this.avaProject)));
  }

  private copyElements(
    obj1: ServiceSpecificationDto | ServiceSpecificationGroupDto,
    obj2: ServiceSpecificationDto | ServiceSpecificationGroupDto
  ): void {
    if (obj2.elements?.length) {
      obj1.elements = obj2.elements.filter((item) => this.flatIdArray.has(item.id));

      if (obj1.elements?.length) {
        obj1.elements.forEach((item) => this.copyElements(item, item));
      }
    }
  }

  private getId(list: SelectedElement[]): void {
    if (list) {
      list.forEach((item: SelectedElement) => {
        this.flatIdArray.add(item.elementId);
        if (item.children) {
          this.getId(item.children);
        }
      });
    }
  }

  acceptedPriceInquiry(): void {
    this.requestTable.refresh();
  }
}
