<div class="wrapper" *ngIf="userInfo">
  <mat-icon *ngIf="!notifications.length" matTooltip="Keine Benachrichtigungen"> notifications_none </mat-icon>
  <mat-icon
    *ngIf="notifications.length"
    [matBadge]="notifications.length"
    matBadgeColor="warn"
    matBadgeSize="small"
    [matTooltip]="'Du hast ' + notifications.length + (notifications.length === 1 ? ' Benachrichtigung' : ' Benachrichtigungen')"
    [matMenuTriggerFor]="notesMenu"
  >
    notifications
  </mat-icon>
</div>

<mat-menu #notesMenu="matMenu">
  <div class="note-title">
    Benachrichtigungen
    <mat-icon class="note-title-button" matTooltip="Alle ansehen" color="primary" (click)="readNote()"> visibility </mat-icon>
  </div>
  <div mat-menu-item *ngFor="let note of notifications" class="note" [class]="note.mode" (click)="readNote(note.id)">
    <div class="note-text">
      <pa-note-icon [mode]="note.mode"></pa-note-icon>
      <span>{{ note.message }}</span>
    </div>
  </div>
</mat-menu>
