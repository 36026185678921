import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';

import { TextAdditionPut, TextAdditionsClient } from 'app/generated-client/generated-client';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { ModalService } from 'app/shared/services/modal.service';

import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';

import { LongTextEditModalComponent } from '../long-text-edit-modal/long-text-edit-modal.component';
import { v4 as uuidv4 } from 'uuid';

type LongTextEditType = { caption?: string; body: string; tail?: string };

@Component({
  selector: 'pa-long-text',
  templateUrl: './long-text.component.html',
  styleUrls: ['./long-text.component.scss'],
  standalone: true,
  imports: [SafeHtmlPipe]
})
export class LongTextComponent implements OnChanges {
  @Input() longText: string;
  @Input() addition: string[];
  @Output() changedLongText = new EventEmitter<string>();
  @ViewChild('divLongText') divLongText: ElementRef;
  projectId: string;
  serviceSpecificationId: string;
  elementId: string;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private modalService: ModalService,
    private textAdditionsClient: TextAdditionsClient
  ) {}

  ngOnChanges(): void {
    if (this.addition) {
      [this.projectId, this.serviceSpecificationId, this.elementId] = this.addition;
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  handleClick(event: MouseEvent) {
    const container = (event.target as HTMLElement).closest('.text-addition-container');
    if (container) {
      const caption = container.querySelector('.text-addition-caption')?.textContent;
      const tail = container.querySelector('.text-addition-tail')?.textContent;
      const bodyElement = container.querySelector('.text-addition-body');
      this.modalService
        .openModal(LongTextEditModalComponent, {
          dialogType: ConfirmationType.General,
          data: { caption, body: bodyElement?.textContent, tail }
        })
        .afterClosed()
        .subscribe((data: LongTextEditType) => {
          if (data) {
            const { body } = data;
            const textAdditionId = container.id || uuidv4();
            bodyElement.innerHTML = body;
            this.changeLongText(textAdditionId, body);
            this.changedLongText.emit(this.divLongText.nativeElement.innerHTML);
          }
        });
    }
  }

  changeLongText(textAdditionId: string, body: string): void {
    if (!this.projectId || !this.serviceSpecificationId || !this.elementId) {
      return;
    }
    const textAddition: TextAdditionPut = {
      textAdditionId,
      avaProjectId: this.serviceSpecificationId,
      elementId: this.elementId,
      body
    };

    this.textAdditionsClient
      .updateTextAddition(this.projectId, this.serviceSpecificationId, this.elementId, textAdditionId, textAddition)
      .subscribe();
  }
}
