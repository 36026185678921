import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';

@Component({
  selector: 'pa-file-modal-to-remove',
  templateUrl: './file-modal-to-remove.component.html',
  styleUrls: ['./file-modal-to-remove.component.scss'],
  standalone: true,
  imports: [MatDialogContent, MatDialogActions, MatButton, MatDialogClose]
})
export class FileModalToRemoveComponent {}
