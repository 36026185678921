import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: `
  [fxLayout],
  [fxLayoutAlign],
  [fxLayoutGap],
  [fxFlex],
  [fxFlexFill]
  `,
  standalone: true
})
export class FlexLayoutDirective implements OnInit {
  private element: HTMLElement;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.element = this.el.nativeElement;
    this.renderer.setStyle(this.element, 'box-sizing', 'border-box');
    if (this.element.hasAttribute('fxFlexFill')) {
      this.renderer.setStyle(this.element, 'height', '100%');
      this.renderer.setStyle(this.element, 'min-height', '100%');
      this.renderer.setStyle(this.element, 'min-width', '100%');
      this.renderer.setStyle(this.element, 'width', '100%');
    }
    if (this.element.hasAttribute('fxFlex')) {
      const [grow, shrink, basis] = this.element.getAttribute('fxFlex').split(' ');
      if (grow && !isNaN(+grow)) {
        if (+grow < 10) {
          this.renderer.setStyle(this.element, 'flex-grow', grow);
        } else {
          this.renderer.setStyle(this.element, 'flex-grow', '1');
          this.renderer.setStyle(this.element, 'flex-shrink', '1');
          this.renderer.setStyle(this.element, 'flex-basis', '100%');
          this.renderer.setStyle(this.element, 'max-width', `${grow}%`);
        }
      }
      if (shrink) {
        this.renderer.setStyle(this.element, 'flex-shrink', shrink);
      }
      if (basis && (['px', 'vw', 'vh'].includes(basis.slice(-2)) || basis.slice(-1) === '%')) {
        this.renderer.setStyle(this.element, 'flex-basis', basis);
        this.renderer.setStyle(this.element, 'max-width', basis);
        this.renderer.setStyle(this.element, 'min-width', basis);
      }
    }

    if (this.element.hasAttribute('fxLayoutAlign')) {
      this.renderer.setStyle(this.element, 'display', 'flex');
      const [first, second] = this.element.getAttribute('fxLayoutAlign').split(' ');
      switch (first) {
        case 'center':
          this.renderer.setStyle(this.element, 'justify-content', 'center');
          break;
        case 'start':
          this.renderer.setStyle(this.element, 'justify-content', 'flex-start');
          break;
        case 'end':
          this.renderer.setStyle(this.element, 'justify-content', 'flex-end');
          break;
        case 'space-around':
          this.renderer.setStyle(this.element, 'justify-content', 'space-around');
          break;
        case 'space-between':
          this.renderer.setStyle(this.element, 'justify-content', 'space-between');
          break;
        case 'space-evenly':
          this.renderer.setStyle(this.element, 'justify-content', 'space-evenly');
          break;
        default:
          break;
      }
      switch (second) {
        case 'center':
          this.renderer.setStyle(this.element, 'align-items', 'center');
          break;
        case 'start':
          this.renderer.setStyle(this.element, 'align-items', 'flex-start');
          break;
        case 'end':
          this.renderer.setStyle(this.element, 'align-items', 'flex-end');
          break;
        case 'stretch':
          this.renderer.setStyle(this.element, 'align-items', 'stretch');
          break;
        case 'baseline':
          this.renderer.setStyle(this.element, 'align-items', 'baseline');
          break;
        case 'space-between':
          this.renderer.setStyle(this.element, 'align-content', 'space-between');
          break;
        case 'space-around':
          this.renderer.setStyle(this.element, 'align-content', 'space-around');
          break;
        default:
          break;
      }
    }

    if (this.element.hasAttribute('fxLayout')) {
      this.renderer.setStyle(this.element, 'display', 'flex');
      const [direction, wrap] = this.element.getAttribute('fxLayout').split(' ');
      switch (direction) {
        case 'row':
          this.renderer.setStyle(this.element, 'flex-direction', 'row');
          break;
        case 'column':
          this.renderer.setStyle(this.element, 'flex-direction', 'column');
          break;
        case 'row-reverse':
          this.renderer.setStyle(this.element, 'flex-direction', 'row-reverse');
          break;
        case 'column-reverse':
          this.renderer.setStyle(this.element, 'flex-direction', 'column-reverse');
          break;
        default:
          break;
      }
      if (wrap === 'wrap') {
        this.renderer.setStyle(this.element, 'flex-wrap', 'wrap');
      }
    }

    if (this.element.hasAttribute('fxLayoutGap')) {
      this.renderer.setStyle(this.element, 'display', 'flex');
      const gap = this.element.getAttribute('fxLayoutGap');
      this.renderer.setStyle(this.element, 'gap', gap);
    }
  }
}
