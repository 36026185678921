export enum UiPositionType {
  Unknown = 'Unknown',
  /** 'Normalposition' in German */
  Regular = 'Regular',
  /** 'Alternativposition' in German */
  Alternative = 'Alternative',
  /** 'Bedarfsposition' in German */
  Optional = 'Optional',
  /** 'Eventualposition' in German */
  Eventual = 'Eventual',
  /** 'Regiearbeit' in German */
  HourlyPaidWork = 'HourlyPaidWork',
  /** 'Umlageposition' in German */
  Complementing = 'Complementing',
  /** 'Textposition' in German */
  NoteText = 'NoteText'
}
