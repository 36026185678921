import { NgIf, NgFor, DecimalPipe, PercentPipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AvaProjectAdditionGet, AvaProjectAdditionPut, AvaProjectAdditionsClient } from 'app/generated-client/generated-client';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { SelectedSpecificationAdditionsMessengerService } from 'app/shared/services/messengers/selected-specification-additions-messenger.service';

import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

interface RiskAndProfitDistributionEntry {
  name: string;
  profitPart: number | null;
  operationalRiskPart: number | null;
  performanceRelatedRiskPart: number | null;
}

@Component({
  selector: 'pa-risk-and-profit-modal',
  templateUrl: './risk-and-profit-modal.component.html',
  styleUrls: ['./risk-and-profit-modal.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatProgressSpinner,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    FlexLayoutDirective,
    MatButton,
    MatDialogClose,
    DecimalPipe,
    PercentPipe
  ]
})
export class RiskAndProfitModalComponent implements OnInit, OnDestroy {
  mainForm: FormGroup;
  projectId: string;
  private avaProjectId: string;
  riskAndProfitAddition: number;
  priceComponents = [];
  loading: boolean;
  private $destroy = new Subject<void>();
  private originalAdditions: AvaProjectAdditionGet;

  constructor(
    private selectedSpecificationAdditionsMessengerService: SelectedSpecificationAdditionsMessengerService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: string,
    @Optional() private matDialogRef: MatDialogRef<RiskAndProfitModalComponent>,
    private avaProjectAdditionsClient: AvaProjectAdditionsClient,
    private avaNotificationsService: AvaNotificationsService
  ) {}

  ngOnInit(): void {
    this.projectId = this.data;

    this.selectedSpecificationAdditionsMessengerService.selectedAdditions.pipe(takeUntil(this.$destroy)).subscribe((additions) => {
      this.avaProjectId = additions.avaProjectId;
      this.originalAdditions = additions;
      this.riskAndProfitAddition = additions.riskAndProfitAddition;
      this.priceComponents = additions.priceComponents;

      const riskAndProfitDistribution: RiskAndProfitDistributionEntry[] = [
        {
          name: 'Lohn',
          profitPart: 100 * (additions.laborProfitPart ?? 0),
          operationalRiskPart: 100 * (additions.laborOperationalRiskPart ?? 0),
          performanceRelatedRiskPart: 100 * (additions.laborPerformanceRelatedRiskPart ?? 0)
        }
      ].concat(
        additions.priceComponents.map((pc) => {
          return {
            name: pc.priceComponent,
            profitPart: 100 * (pc.profitPart ?? 0),
            operationalRiskPart: 100 * (pc.operationalRiskPart ?? 0),
            performanceRelatedRiskPart: 100 * (pc.performanceRelatedRiskPart ?? 0)
          };
        })
      );

      this.mainForm = this.fb.group({
        riskAndProfitDistribution: this.fb.array(riskAndProfitDistribution.map((item) => this.fb.group(item)))
      });
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  saveData(): void {
    if (this.mainForm.dirty) {
      this.loading = true;
      const newAdditions = JSON.parse(JSON.stringify(this.originalAdditions)) as AvaProjectAdditionPut;
      const riskAndProfitDistribution: RiskAndProfitDistributionEntry[] = this.mainForm.value.riskAndProfitDistribution;
      riskAndProfitDistribution.forEach((distribution, index) => {
        if (index === 0) {
          newAdditions.laborProfitPart = distribution.profitPart / 100;
          newAdditions.laborOperationalRiskPart = distribution.operationalRiskPart / 100;
          newAdditions.laborPerformanceRelatedRiskPart = distribution.performanceRelatedRiskPart / 100;
        } else {
          const priceComp = newAdditions.priceComponents.find((pc) => pc.priceComponent === distribution.name);
          if (priceComp) {
            priceComp.profitPart = distribution.profitPart / 100;
            priceComp.operationalRiskPart = distribution.operationalRiskPart / 100;
            priceComp.performanceRelatedRiskPart = distribution.performanceRelatedRiskPart / 100;
          }
        }
      });

      this.avaProjectAdditionsClient.editAvaProjectAdditions(this.projectId, this.avaProjectId, newAdditions).subscribe(
        (updatedAdditions) => {
          this.loading = false;
          this.selectedSpecificationAdditionsMessengerService.setSelectedAdditions(updatedAdditions);
          this.matDialogRef.close(!this.isInvalid());
        },
        () => {
          this.loading = false;
          this.avaNotificationsService.error('Fehler beim Speichern der W&G Aufteilung');
        }
      );
    } else {
      this.matDialogRef.close(!this.isInvalid());
    }
  }

  get getArr(): FormArray {
    return this.mainForm.get('riskAndProfitDistribution') as FormArray;
  }

  setAllToProfit(): void {
    const controls = this.getArr.controls;

    for (let i = 0; i < controls.length; i++) {
      controls[i].patchValue({
        profitPart: this.riskAndProfitAddition * 100,
        operationalRiskPart: 0,
        performanceRelatedRiskPart: 0
      });
      controls[i].markAsDirty();
    }
  }

  isInvalid(): boolean {
    return this.mainForm.value.riskAndProfitDistribution.some((item) => !this.checkIfPriceComponentIsValid(item));
  }

  checkIfPriceComponentIsValid(item: RiskAndProfitDistributionEntry): boolean {
    const itemPercentage = item.profitPart + item.operationalRiskPart + item.performanceRelatedRiskPart;
    const additionsPercentage = this.riskAndProfitAddition * 100;
    return Math.abs(itemPercentage - additionsPercentage) < 0.001;
  }
}
