import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, switchMap } from 'rxjs';

import { DialogConfig } from 'app/shared/models/dialog-config.model';
import { ModalService } from 'app/shared/services/modal.service';

@Injectable({
  providedIn: 'root'
})
export class CheckErrorsAndOpenModalWindowService {
  constructor(private modalService: ModalService) {}

  checkErrorsAndOpenModalWindow<TModal>(
    checkRequest: Observable<unknown>,
    component: ComponentType<TModal>,
    config: DialogConfig
  ): Observable<unknown> {
    return checkRequest.pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      switchMap((r: { [key: string]: unknown; hasErrors: boolean }) => {
        if (r && r.hasErrors) {
          config.data = { ...config.data, ...r };
          return this.modalService.openModal(component, config).afterClosed();
        } else {
          return of(null);
        }
      })
    );
  }
}
