<div class="wrapper-drop">
  <ngx-dropzone
    (change)="onSelect($event.addedFiles)"
    class="wrapper-drop__field"
    [style.height]="uploadFileSettings?.height"
    [style.max-width]="uploadFileSettings?.maxWidth"
    [accept]="uploadFileSettings?.accept || '*'"
  >
    <ngx-dropzone-label>{{ uploadFileSettings?.label }}</ngx-dropzone-label>
  </ngx-dropzone>
  <div fxLayout="column" fxLayoutAlign="center center">
    <p>- oder -</p>
    <input
      hidden
      type="file"
      [multiple]="uploadFileSettings?.isMultipleSelection"
      [accept]="uploadFileSettings?.accept"
      (change)="onSelectFromInput(fileInput.files)"
      #fileInput
    />
    <button mat-button mat-raised-button color="primary" (click)="fileInput.click()">Datei Auswählen</button>
  </div>
</div>
