import { Pipe, PipeTransform } from '@angular/core';

import { BimOperationStatus } from '../../../generated-client/generated-client';

@Pipe({
  name: 'bimFileStatus',
  standalone: true
})
export class BimFileStatusPipe implements PipeTransform {
  transform(value: BimOperationStatus): string {
    switch (value) {
      case BimOperationStatus.Pending:
        return 'In Bearbeitung...';
      case BimOperationStatus.Succeeded:
        return 'Modell verfügbar';
      case BimOperationStatus.Failed:
        return 'Konvertierungsfehler';
    }
    return null;
  }
}
