import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { AllowNumericWithDecimalDirective } from '../../../areas/project-id/components/service-specifications/directives/allow-numeric-with-decimal.directive';

@Component({
  selector: 'pa-percentage-input-field',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, AllowNumericWithDecimalDirective],
  templateUrl: './percentage-input-field.component.html',
  styleUrls: ['./percentage-input-field.component.scss']
})
export class PercentageInputFieldComponent {
  @Output() valueUpdated = new EventEmitter();
  @Input() label: string;
  @Input() fieldName: string;
  @Input() isEditing: boolean;
  @Input() set value(newValue: any) {
    this._value = newValue;
  }
  get formattedValue(): string {
    return this._value ? (this._value * 100).toFixed(2) : '0.00';
  }
  set formattedValue(value: string) {
    value = value.replace(',', '.');
    this._value = parseFloat(value) / 100;
    this._value = +this._value.toFixed(4);
  }
  private _value: any;

  updateValue(newValue: string): void {
    this.formattedValue = newValue;
    this.valueUpdated.emit(isNaN(this._value) ? 0 : this._value);
  }
}
