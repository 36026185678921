<div *ngIf="!isLoading" class="spinner">
  <div fxFlex fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
  </div>
</div>
<mat-form-field appearance="fill" *ngIf="isLoading">
  <mat-label>MwSt. Satz</mat-label>
  <input name="taxRates" type="number" matInput matInputDecimalPlaces [(ngModel)]="taxRate" (change)="isChanged = true" />
  <span matSuffix>%</span>
</mat-form-field>
<div>
  <button mat-raised-button color="primary" (click)="setTaxRates()" [disabled]="!isChanged">Speichern</button>
</div>
