import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

import { Subject, takeUntil } from 'rxjs';

import { GeneralEquipmentComponent } from '@shared/components/general-equipment/general-equipment.component';

import { CopyCalculationTopPartComponent } from 'app/areas/copy-calculation-view/components/copy-calculation-top-part/copy-calculation-top-part.component';
import { CopyCalculationTopPartService } from 'app/areas/copy-calculation-view/services/copy-calculation-top-part.service';
import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';
import { MainTreeComponent } from 'app/areas/tree/components/main-tree/main-tree.component';
import { FlatElementsService } from 'app/areas/tree/services/flat-elements.service';
import { ModePageService } from 'app/areas/tree/services/mode-page.service';
import { TreeNodeStateService } from 'app/areas/tree/services/tree-node-state.service';
import {
  AvaProjectGet,
  AvaProjectsClient,
  ProjectDto,
  ServiceSpecificationDto,
  TreeViewDisplayType
} from 'app/generated-client/generated-client';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { CopyElementViewMessengerService } from 'app/shared/services/electron/copy-element-view-messenger.service';
import { GroupViewService } from 'app/shared/services/group-view.service';
import { LocalStorageViewService } from 'app/shared/services/local-storage-view.service';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';
import { getStorage, setStorage } from 'app/shared/utilities/storage';

import { AngularSplitModule, IOutputAreaSizes } from 'angular-split';

@Component({
  selector: 'pa-copy-element-view',
  standalone: true,
  templateUrl: './copy-element-view.component.html',
  styleUrls: ['./copy-element-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, AngularSplitModule, FlexLayoutDirective, GeneralEquipmentComponent, MainTreeComponent],
  providers: [ModePageService, SelectedSpecificationElementMessengerService, TreeNodeStateService, FlatElementsService]
})
export class CopyElementViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('asSplitRightRef') asSplitRightRef: ElementRef;
  @ViewChild('scrollEl') scrollElement: ElementRef;

  @Input() noSeparate: boolean;
  modeEquipment = 'copyElement';
  private $destroy = new Subject<boolean>();
  topPartComp: MatDialogRef<CopyCalculationTopPartComponent>;
  isShowTopPart = false;
  serviceSpecification: ServiceSpecificationDto;

  constructor(
    private copyCalculationTopPartService: CopyCalculationTopPartService,
    private modalService: ModalService,
    private avaProjectsClient: AvaProjectsClient,
    private cdr: ChangeDetectorRef,
    private modePageService: ModePageService,
    private groupViewService: GroupViewService,
    private treeNodeStateService: TreeNodeStateService,
    public sanitizer: DomSanitizer,
    private localStorageViewService: LocalStorageViewService,
    private copyElementViewMessengerService: CopyElementViewMessengerService
  ) {}

  ngOnInit(): void {
    this.initTree();
    this.topPartComp = this.modalService.openModal(CopyCalculationTopPartComponent, {
      dialogType: ConfirmationType.General,
      data: {},
      position: { bottom: `0px`, left: `0px` },
      hasBackdrop: false,
      panelClass: 'top-part-window'
    });
    if (this.noSeparate) {
      this.copyCalculationTopPartService.hiddenTop.pipe(takeUntil(this.$destroy)).subscribe((e) => {
        if (e) {
          this.hideTop();
        } else {
          if (!this.isShowTopPart) {
            this.showTop();
          }
        }
      });
    } else {
      this.settingView();
      this.topPartComp.afterOpened().subscribe(() => {
        this.copyCalculationTopPartService.setShow();
        this.copyCalculationTopPartService.setToTopPart('show');
      });
    }

    this.copyCalculationTopPartService.infoFromTopPart.pipe(takeUntil(this.$destroy)).subscribe((e: { command: string; data?: any }) => {
      switch (e.command) {
        case 'beginProject':
          this.selectAvaProject(e.data);
          break;
        case 'beginAvaProject':
          this.selectAvaProject(e.data);
          break;
        case 'selectAvaProject':
          this.selectAvaProject(e.data);
          break;
      }
    });
  }

  ngAfterViewInit(): void {
    this.setAsSplitSize('AS_SPLIT_TREE_COPY_ELEMENT_RIGHT_SIZE', this.asSplitRightRef);
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
    this.topPartComp.close();
  }

  private initTree(): void {
    this.modePageService.setModePage('other-mode');
    this.groupViewService.setGroupView(TreeViewDisplayType.Tree);
    this.treeNodeStateService.setTreeState({});
  }

  enterMouse(): void {
    this.copyCalculationTopPartService.setShow();
  }

  tryLeave(): void {
    this.copyCalculationTopPartService.tryHide();
  }

  onDragEnd(sizes: IOutputAreaSizes): void {
    setStorage<number[]>('AS_SPLIT_TREE_COPY_ELEMENT_RIGHT_SIZE', sizes as number[]);
  }

  private setAsSplitSize(name: string, asSplit: any): void {
    const storage = getStorage<number[]>(name, [] as number[]);
    if (storage.length) {
      asSplit.setVisibleAreaSizes(storage);
    }
  }

  showTop(): void {
    this.isShowTopPart = true;
    this.copyCalculationTopPartService.setToTopPart('show');
    this.changePosition();
  }

  hideTop(): void {
    this.isShowTopPart = false;
    this.copyCalculationTopPartService.setToTopPart('hide');
  }

  changePosition(): void {
    setTimeout(() => {
      const scroll: DOMRect = this.scrollElement.nativeElement.getBoundingClientRect();
      const rect: DOMRect = this.scrollElement.nativeElement.closest('.one-view').getBoundingClientRect();
      if (scroll && rect) {
        this.copyCalculationTopPartService.setToTopPart('position', {
          width: scroll.width,
          left: scroll.left,
          bottom: window.innerHeight - rect.top
        });
      }
    }, 1);
  }

  selectAvaProject(avaProject: AvaProjectGet): void {
    this.serviceSpecification = null;
    this.cdr.markForCheck();
    if (avaProject) {
      this.avaProjectsClient.getAvaProjectContentById(avaProject.projectId, avaProject.id).subscribe((projectDto: ProjectDto) => {
        this.serviceSpecification = projectDto.serviceSpecifications[0];
        this.cdr.markForCheck();
      });
    }
  }

  private settingView(): void {
    const { alwaysOnTop } = this.localStorageViewService.getSettingView(this.modeEquipment);
    this.copyElementViewMessengerService.setOnTopCopyElementView(alwaysOnTop);
  }
}
