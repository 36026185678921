<div mat-dialog-content class="wrapper">
  <h3>Positionsverwendung</h3>
  <h4>{{ data?.name }}</h4>
  <cdk-virtual-scroll-viewport itemSize="20" class="viewport">
    <div *cdkVirtualFor="let item of positionUsageList">
      <div class="item" fxLayoutAlign="space-between" (click)="selectPosition(item)">
        <div class="item-wrap-t" fxLayoutGap="5px">
          <span class="item-number">
            {{ item.itemNumber.stringRepresentation }}
          </span>
          <span class="item-text">{{ item | positionText }}</span>
        </div>
        <div fxLayoutGap="5px">
          <span class="item-quantity"> {{ item.quantity | number: '1.3-3' }}</span>
          <span> {{ item.unitTag }}</span>
        </div>
      </div>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
