import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatAnchor } from '@angular/material/button';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTableDataSource,
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MasterDataAdditionGroupsService } from '@master/services/master-data-addition-groups.service';
import { MasterDataReloadGroupsService } from '@master/services/master-data-reload-groups.service';

import { MasterAdditionGroupGet } from 'app/generated-client/generated-client';
import { UpperPaginatorComponent } from 'app/shared/components/upper-paginator/upper-paginator.component';

import { UpperPaginatorComponent as UpperPaginatorComponent_1 } from '../../../../shared/components/upper-paginator/upper-paginator.component';

@Component({
  selector: 'pa-master-addition-groups-table',
  templateUrl: './master-addition-groups-table.component.html',
  styleUrls: ['./master-addition-groups-table.component.scss'],
  standalone: true,
  imports: [
    MatAnchor,
    RouterLink,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    UpperPaginatorComponent_1
  ]
})
export class MasterAdditionGroupsTableComponent implements OnInit, AfterViewInit {
  @ViewChild(UpperPaginatorComponent) paginatorComp: UpperPaginatorComponent;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<MasterAdditionGroupGet>;
  displayedColumns = ['name', 'description'];
  paginationOptions = { totalCount: 0, pageSize: 0, page: 0 };
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private masterDataReloadGroupsService: MasterDataReloadGroupsService,
    private masterDataAdditionGroupsService: MasterDataAdditionGroupsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.masterDataReloadGroupsService.reload();
    this.masterDataAdditionGroupsService.additionGroup
      .pipe(takeUntil(this.$destroy))
      .subscribe((additionGroup: MasterAdditionGroupGet[]) => {
        this.paginationOptions = { totalCount: additionGroup.length, pageSize: 5, page: 1 };
        this.dataSource = new MatTableDataSource(additionGroup);
        if (this.paginatorComp) {
          this.dataSource.paginator = this.paginatorComp.upperPaginator;
          this.dataSource.sort = this.sort;
        }
      });
  }

  ngAfterViewInit() {
    if (this.dataSource && this.paginatorComp) {
      this.dataSource.paginator = this.paginatorComp.upperPaginator;
      this.dataSource.sort = this.sort;
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  selectElement(row: MasterAdditionGroupGet): void {
    this.router.navigate(['detail', row.id], { relativeTo: this.route });
  }

  public onPage(pageEvent: PageEvent): void {
    this.paginationOptions.page = pageEvent.pageIndex + 1;
    this.paginationOptions.pageSize = pageEvent.pageSize;
  }
}
