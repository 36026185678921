import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { UploadFileComponent, UploadFileSettings } from '@shared/components/upload-file/upload-file.component';
import { ConfirmationType } from '@shared/models/dialog-config.model';
import { ModalService } from '@shared/services/modal.service';

import { ContactsComponent } from '@contacts/components/contacts/contacts.component';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';
import { ContactGet } from 'app/generated-client/generated-client';

@Component({
  selector: 'pa-new-catalog',
  standalone: true,
  imports: [
    NgIf,
    FlexLayoutDirective,
    UploadFileComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatDialogModule
  ],
  templateUrl: './new-catalog.component.html',
  styleUrls: ['./new-catalog.component.scss']
})
export class NewCatalogComponent {
  uploadFileSettings: UploadFileSettings = {
    height: '200px',
    maxWidth: '400px',
    label: 'Katalogdatei, DATANORM oder UGL',
    isMultipleSelection: false
  };
  private modalService = inject(ModalService);
  private matDialogRef = inject(MatDialogRef);
  catalogName: string;
  selectedContac: ContactGet;
  selectedFile: File;
  constructor() {}

  onSelect(files: File[]): void {
    this.selectedFile = files[0];
  }

  chooseContact(): void {
    this.modalService
      .openModal(ContactsComponent, {
        dialogType: ConfirmationType.General,
        restoreFocus: false,
        autoFocus: false,
        data: { isCreatePriceInuiry: true }
      })
      .afterClosed()
      .subscribe((contact: ContactGet) => {
        this.selectedContac = contact;
      });
  }

  createCatalog(): void {
    this.matDialogRef.close({
      catalogName: this.catalogName,
      catalogData: this.selectedFile,
      contactId: this.selectedContac?.id || ''
    });
  }
}
