import { EventManager } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

/**
 * Taken from:
 * https://indepth.dev/posts/1434/running-event-listeners-outside-of-the-ngzone
 */
@Injectable({
  providedIn: 'root'
})
export class ZonelessEventPluginService {
  manager: EventManager;

  supports(eventName: string): boolean {
    return eventName.endsWith('.zoneless');
  }

  addEventListener(element: HTMLElement, eventName: string, originalHandler: EventListener): Function {
    const [nativeEventName] = eventName.split('.');

    this.manager.getZone().runOutsideAngular(() => {
      element.addEventListener(nativeEventName, originalHandler);
    });

    return () => {
      element.removeEventListener(nativeEventName, originalHandler);
    };
  }
}
