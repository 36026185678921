import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';

import { CopyElementViewMessengerService } from 'app/shared/services/electron/copy-element-view-messenger.service';
import { LongTextViewMessengerService } from 'app/shared/services/electron/long-text-view-messenger.service';
import { SheetsViewMessengerService } from 'app/shared/services/electron/sheets-view-messenger.service';
import { TreeViewMessengerService } from 'app/shared/services/electron/tree-view-messenger.service';

import { WindowSettingType } from '../../models';
import { CopyCalculationViewMessengerService } from '../../services/electron/copy-calculation-view-messenger.service';
import { ElementViewMessengerService } from '../../services/electron/element-view-messenger.service';
import { LocalStorageViewService } from '../../services/local-storage-view.service';

@Component({
  selector: 'pa-modal-setting-window',
  templateUrl: './modal-setting-window.component.html',
  styleUrls: ['./modal-setting-window.component.scss'],
  standalone: true,
  imports: [MatDialogContent, MatCheckbox, FormsModule]
})
export class ModalSettingWindowComponent implements OnInit {
  setting: WindowSettingType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public mode: string,
    private copyCalculationViewMessengerService: CopyCalculationViewMessengerService,
    private elementViewMessengerService: ElementViewMessengerService,
    private treeViewMessengerService: TreeViewMessengerService,
    private localStorageViewService: LocalStorageViewService,
    private sheetsViewMessengerService: SheetsViewMessengerService,
    private longTextViewMessengerService: LongTextViewMessengerService,
    private copyElementViewMessengerService: CopyElementViewMessengerService
  ) {}

  ngOnInit(): void {
    this.setting = this.localStorageViewService.getSettingView(this.mode);
  }

  toggleAlwaysOnTop(alwaysOnTop: boolean): void {
    this.setting.alwaysOnTop = alwaysOnTop;
    this.localStorageViewService.setSettingView(this.mode);
    switch (this.mode) {
      case 'element':
        this.elementViewMessengerService.setOnTopElement(alwaysOnTop);
        break;
      case 'copyCalculation':
        this.copyCalculationViewMessengerService.setOnTopCopyCalculation(alwaysOnTop);
        break;
      case 'tree':
        this.treeViewMessengerService.setOnTopTreeView(alwaysOnTop);
        break;
      case 'longText':
        this.longTextViewMessengerService.setOnTopLongTextView(alwaysOnTop);
        break;
      case 'sheets':
        this.sheetsViewMessengerService.setOnTopSheetsView(alwaysOnTop);
        break;
      case 'copyElement':
        this.copyElementViewMessengerService.setOnTopCopyElementView(alwaysOnTop);
        break;
      default:
    }
  }
}
