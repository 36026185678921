<div class="wrapper-selection">
  <h2>Mengenübernahme</h2>
  <pa-filter-selection
    [listOfFilteringElements]="propertySetName"
    [formControl]="propertySetNameControl"
    (ngModelChange)="onSelectPropertySetName()"
    label="Eigenschaftsgruppe"
  ></pa-filter-selection>
  <pa-filter-selection
    [listOfFilteringElements]="propertyName"
    [formControl]="propertyNameControl"
    (ngModelChange)="onSelectpropertyName()"
    label="Eigenschaft"
  ></pa-filter-selection>
  <mat-form-field appearance="fill">
    <mat-label>Faktor</mat-label>
    <input type="number" matInput [formControl]="factorControl" />
  </mat-form-field>
</div>
