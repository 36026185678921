<div mat-dialog-content>
  <h2>Sub LVs</h2>
  <p>Ein Sub LV ist ein Teil des LVs, bei dem nur bestimmte Elemente ausgewählt wurden.</p>
  <ng-container *ngIf="!isOpenInModalWindow">
    <div fxLayout="row" fxLayoutGap="12px" class="buttons">
      <button mat-raised-button color="primary" (click)="back()">Zurück</button>
      <button mat-raised-button color="primary" (click)="newSelection()">Neu</button>
    </div>
  </ng-container>
  <pa-selection-element-list-table
    #listTable
    (selected)="chooseItem($event)"
    [isDelete]="true"
    [isOpenInModalWindow]="isOpenInModalWindow"
  ></pa-selection-element-list-table>
</div>
