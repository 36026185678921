import { NgIf } from '@angular/common';
import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Subject, combineLatest, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChangeViewportHeightService } from '@serv-spec/services/change-viewport-height.service';

import { FlatElementsService } from 'app/areas/tree/services/flat-elements.service';
import { ModePageService } from 'app/areas/tree/services/mode-page.service';
import {
  AvaProjectGet,
  AvaProjectsClient,
  IElementDto,
  ProjectDto,
  ProjectGet,
  ProjectsClient
} from 'app/generated-client/generated-client';
import { ElementViewMessengerService } from 'app/shared/services/electron/element-view-messenger.service';
import { ElementHasWithoutTotalGroupService } from 'app/shared/services/element-has-without-total-group.service';
import { LocalStorageViewService } from 'app/shared/services/local-storage-view.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SpecificationSourceService } from 'app/shared/services/specification-source.service';

import { getAppConfig } from '../../../../app-config-accessor';
import { GeneralEquipmentComponent } from '../../../../shared/components/general-equipment/general-equipment.component';
import { SelectedSpecificationMessengerService } from '../../../../shared/services/messengers/selected-specification-messenger.service';
import { AvaHubConnector } from '../../../../shared/services/signalr/ava-hub-connector';
import { AvaProjectDiffApplier } from '../../../../shared/services/signalr/ava-project-diff-applier';
import { ElementComponent } from '../../../elements/components/element/element.component';

@Component({
  selector: 'pa-element-view',
  templateUrl: './element-view.component.html',
  styleUrls: ['./element-view.component.scss'],
  standalone: true,
  imports: [GeneralEquipmentComponent, NgIf, ElementComponent]
})
export class ElementViewComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('contentView') contentView: ElementRef;
  mode = 'element';
  addition: string[];
  element: IElementDto;
  private $destroy: Subject<boolean> = new Subject();

  // The two properties below are to ensure we're not downloading the same ava project
  // multiple times
  private lastProjectId: string;
  private lastServiceSpecificationId;

  constructor(
    private elementViewMessengerService: ElementViewMessengerService,
    private title: Title,
    private localStorageViewService: LocalStorageViewService,
    private sourceService: SpecificationSourceService,
    private elementHasWithoutTotalGroupService: ElementHasWithoutTotalGroupService,
    private avaHubConnector: AvaHubConnector,
    private avaProjectDiffApplier: AvaProjectDiffApplier,
    private projectsClient: ProjectsClient,
    private avaProjectsClient: AvaProjectsClient,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private flatElementsService: FlatElementsService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private modePageService: ModePageService,
    private ngZone: NgZone,
    private changeViewportHeightService: ChangeViewportHeightService
  ) {}

  ngOnInit(): void {
    this.avaHubConnector.avaProjectDiff.pipe(takeUntil(this.$destroy)).subscribe((avaProjectDiff) => {
      if (this.element?.id && avaProjectDiff?.elementDiffs && avaProjectDiff?.elementDiffs[this.element.id]) {
        avaProjectDiff.elementDiffs[this.element.id].forEach((operation) => {
          AvaProjectDiffApplier.applySingleElementDiffOperation(this.element, operation);
        });
      }
    });

    this.ngZone.runOutsideAngular(() => {
      fromEvent(document, 'dragstart')
        .pipe(takeUntil(this.$destroy))
        .subscribe((event: DragEvent) => {
          event.preventDefault();
        });
    });

    let currentProjectId: string = null;
    const title = getAppConfig().appPrefix + 'AVA - Element Detail';
    this.title.setTitle(title);
    this.elementViewMessengerService.selectedElement
      .pipe(takeUntil(this.$destroy))
      .subscribe((e: { element?: IElementDto; options?: string[] }) => {
        this.element = e?.element;
        this.addition = e?.options;
        if (this.addition?.length > 0) {
          const newProjectId = this.addition[0];
          const newServSpecId = this.addition[1];
          this.getAvaProject(newProjectId, newServSpecId);
          if (newProjectId != currentProjectId) {
            currentProjectId = newProjectId;
          }
        }
      });
    this.elementViewMessengerService.ensureLatestSelectedElementIsLoaded();
    this.settingView();
    this.elementViewMessengerService.data.pipe(takeUntil(this.$destroy)).subscribe(({ command, data }) => {
      const [modePage] = data;
      switch (command) {
        case 'changeModePage':
          this.modePageService.setModePage(modePage);
          break;
        case 'elementList':
          this.sourceService.setAllSpecif(data);
          break;
        case 'hasWithoutTotalGroup':
          this.elementHasWithoutTotalGroupService.setValue(data[0]);
          break;
        default:
      }
    });
  }

  ngAfterViewInit(): void {
    this.tryChangeHeightCalc();
  }

  tryChangeHeightCalc(): void {
    if (this.contentView) {
      this.changeViewportHeightService.setViewportHeight(this.contentView.nativeElement.offsetHeight);
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
  }

  settingView(): void {
    const { alwaysOnTop } = this.localStorageViewService.getSettingView(this.mode);
    this.elementViewMessengerService.setOnTopElement(alwaysOnTop);
  }

  private getAvaProject(projectId: string, serviceSpecificationId: string): void {
    if (this.lastProjectId == projectId && this.lastServiceSpecificationId == serviceSpecificationId) {
      return;
    }

    this.lastServiceSpecificationId = serviceSpecificationId;
    this.lastProjectId = projectId;

    if (projectId && serviceSpecificationId) {
      combineLatest([
        this.projectsClient.getProjectById(projectId),
        this.avaProjectsClient.getAvaProjectById(projectId, serviceSpecificationId),
        this.avaProjectsClient.getAvaProjectContentById(projectId, serviceSpecificationId)
      ])
        .pipe(takeUntil(this.$destroy))
        .subscribe(([project, avaProject, servSpec]: [ProjectGet, AvaProjectGet, ProjectDto]) => {
          this.flatElementsService.setElementsDto(servSpec.serviceSpecifications[0]);
          this.selectedProjectMessengerService.setSelectedProject(project);
          this.selectedSpecificationMessengerService.setSelectedServiceSpecification(
            serviceSpecificationId,
            servSpec,
            projectId,
            avaProject
          );
        });
    }
  }
}
