<pa-general-equipment [mode]="modeEquipment">
  <div class="wrapper">
    <div class="btn-ground">
      <div class="btn-wrap" fxLayout="row wrap" fxLayoutAlign="space-between end">
        <mat-button-toggle-group value="tree" [(ngModel)]="groupView" (ngModelChange)="changeGroupView($event)">
          <mat-button-toggle value="Tree">Hierarchie</mat-button-toggle>
          <mat-button-toggle value="List">Liste</mat-button-toggle>
          <mat-button-toggle value="PositionList">Positionen</mat-button-toggle>
          <mat-button-toggle value="Table">Tabelle</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <pa-main-tree [isInnerWindow]="true" [withoutSplitArea]="false"></pa-main-tree>
  </div>
</pa-general-equipment>
