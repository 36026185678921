<h2>Preisanfragen</h2>

<div fxLayout="row" fxLayoutGap="12px" class="buttons">
  <button mat-raised-button color="primary" (click)="back()">Zurück</button>
  <button mat-raised-button color="primary" (click)="newPriceInquiries()">Neue Preisanfrage</button>
  <button mat-raised-button color="primary" (click)="showSelectionList()">Sub LVs</button>
  <button mat-button mat-raised-button color="primary" (click)="downloadAllPackages()" [disabled]="isDownloading">
    Alle als Zip Speichern
  </button>
</div>
<table mat-table [dataSource]="priceInquiryService" matSort (matSortChange)="priceInquiryService.onSort($event)">
  <ng-container matColumnDef="count">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Elemente</th>
    <td mat-cell matRipple *matCellDef="let row">{{ row.elementSelectionId ? 'Auswahl' : 'Gesamt-LV' }}</td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
    <td mat-cell matRipple *matCellDef="let row">
      {{ row.name }}
    </td>
  </ng-container>
  <ng-container matColumnDef="useAssumedQuantities">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Mengen</th>
    <td mat-cell matRipple *matCellDef="let row">
      {{ row.useAssumedQuantities ? 'VA-Mengen' : 'Projektmengen' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="createdAtUtc">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Erstellt am</th>
    <td mat-cell matRipple *matCellDef="let row">
      {{ row.createdAtUtc | date: 'dd.MM.yyyy HH:mm' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef class="actions"></th>
    <td mat-cell *matCellDef="let row" class="actions" (click)="$event.stopPropagation()">
      <button mat-icon-button color="primary" type="button" [disabled]="isDownloading" (click)="downloadPackageForPriceInquiry(row)">
        <mat-icon>download</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="showPriceInquiries(row)"></tr>
</table>
<pa-upper-paginator
  [pageSizeOptions]="[5, 10, 25, 100]"
  [upperPaginated]="priceInquiryService.paginationResult | async"
  [upperService]="priceInquiryService"
  (page)="priceInquiryService.onPage($event)"
>
</pa-upper-paginator>
