<div style="padding: 24px">
  <pa-contacts [isInProjectCreation]="true" (selectContact)="selectContact($event)" [contactType]="contactType"></pa-contacts>

  <p *ngIf="showCloseCancelButtons">
    Ausgewählt:
    <span *ngIf="selectedContact">{{ selectedContact?.name }}</span>
    <span *ngIf="!selectedContact"><i>Keine Auswahl</i></span>
  </p>

  <button *ngIf="showCloseCancelButtons" color="primary" mat-raised-button (click)="close()">Speichern</button>
  <button *ngIf="showCloseCancelButtons" color="warn" mat-raised-button (click)="cancel()">Abbrechen</button>
</div>
