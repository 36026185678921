import { Pipe, PipeTransform } from '@angular/core';

import { AdditionTypeDto } from 'app/generated-client/generated-client';

@Pipe({
  name: 'additionType',
  standalone: true
})
export class AdditionTypePipe implements PipeTransform {
  transform(value: AdditionTypeDto): string {
    switch (value) {
      case AdditionTypeDto.None:
        return 'Keine Ergänzung';
      case AdditionTypeDto.ByBuyer:
        return 'Käufertext';
      case AdditionTypeDto.ByBidder:
        return 'Bietertext';
    }
    return null;
  }
}
