import { NgIf, NgFor } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';

import { filter, first } from 'rxjs/operators';

import { getAppConfig } from 'app/app-config-accessor';
import {
  QuantityTakeOffCalculationType,
  QuantityTakeOffGet,
  QuantityTakeOffType,
  QuantityTakeOffsClient,
  ProjectDto,
  ProjectGet,
  ProjectQuantityTakeOffType
} from 'app/generated-client/generated-client';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { dateUTC } from 'app/shared/utilities/dateUTC';

import { QuantityTakeOffCalculationTypePipe } from '../../../../../../../../shared/pipes/ui-data-display/quantity-take-off-calculation-type.pipe';

@Component({
  selector: 'pa-new-invoice',
  templateUrl: './new-invoice.component.html',
  styleUrls: ['./new-invoice.component.scss'],
  standalone: true,
  imports: [
    MatDialogContent,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatSelect,
    NgFor,
    MatOption,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatDatepicker,
    MatCheckbox,
    MatButton,
    QuantityTakeOffCalculationTypePipe
  ]
})
export class NewInvoiceComponent implements OnInit {
  calculationTypes: QuantityTakeOffCalculationType[];
  newInvoiceForm: FormGroup;
  private avaProjectId: string;
  private projectId: string;
  structureView: string;
  requestInFlight = false;
  projectForQtoType: ProjectQuantityTakeOffType = null;
  isQtoOnlyMode = getAppConfig().isQtoOnlyMode;

  constructor(
    @Optional() private matDialogRef: MatDialogRef<NewInvoiceComponent>,
    private notificationsService: AvaNotificationsService,
    private quantityTakeOffsClient: QuantityTakeOffsClient,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private router: Router,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    @Inject(MAT_DIALOG_DATA) public data: QuantityTakeOffGet
  ) {}

  ngOnInit(): void {
    this.structureView = this.router.url.includes('invoices') ? 'invoices' : 'estimations';

    this.calculationTypes = Object.keys(QuantityTakeOffCalculationType).map((v: string) => <QuantityTakeOffCalculationType>v);

    this.newInvoiceForm = new FormGroup({
      avaProjectId: new FormControl(''),
      name: new FormControl('', (c: AbstractControl) => Validators.required(c)),
      number: new FormControl(null),
      calculationType: new FormControl(''),
      quantityTakeOffType: new FormControl(''),
      useCurrentProjectQuantities: new FormControl(false),
      performancePeriodStartUtc: new FormControl(),
      performancePeriodEndUtc: new FormControl()
    });

    this.newInvoiceForm.patchValue({ calculationType: this.data ? this.data.calculationType : QuantityTakeOffCalculationType.ByPage });
    if (this.data) {
      this.newInvoiceForm.get('calculationType').disable();
      this.newInvoiceForm.patchValue({ name: this.data.name });
    }
    if (this.isQtoOnlyMode) {
      this.newInvoiceForm.patchValue({ calculationType: QuantityTakeOffCalculationType.ByPage });
    }

    if (this.structureView === 'invoices') {
      this.newInvoiceForm.patchValue({ quantityTakeOffType: QuantityTakeOffType.Invoice });
    } else {
      this.newInvoiceForm.patchValue({ quantityTakeOffType: QuantityTakeOffType.QuantityEstimation });
    }

    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        filter((r) => !!r),
        first()
      )
      .subscribe((s: { avaProjectId: string; project: ProjectDto }) => {
        this.newInvoiceForm.patchValue({ avaProjectId: s.avaProjectId });
        this.avaProjectId = s.avaProjectId;
      });
    this.selectedProjectMessengerService.selectedProject.pipe(first()).subscribe((p: ProjectGet) => {
      this.projectId = p.id;
      this.projectForQtoType = p.allowedQuantityTakeOffTypes;
      this.setupQTOCalculationType();
    });
  }

  cancel(): void {
    this.matDialogRef.close(false);
  }

  createInvoice(): void {
    this.newInvoiceForm.get('calculationType').enable();
    this.requestInFlight = true;
    const data = this.newInvoiceForm.value;
    data.performancePeriodStartUtc = dateUTC(data.performancePeriodStartUtc);
    data.performancePeriodEndUtc = dateUTC(data.performancePeriodEndUtc);
    const request = this.data
      ? this.quantityTakeOffsClient.copyQuantityTakeOff(this.projectId, this.avaProjectId, this.data.id, {
          projectId: this.projectId,
          avaProjectId: this.avaProjectId,
          quantityTakeOffId: this.data.id,
          newName: data.name,
          newNumber: data.number,
          performancePeriodStartUtc: data.performancePeriodStartUtc,
          performancePeriodEndUtc: data.performancePeriodEndUtc
        })
      : this.quantityTakeOffsClient.createQuantityTakeOff(this.projectId, this.avaProjectId, data);

    request.subscribe(
      (qto: QuantityTakeOffGet) => {
        this.requestInFlight = false;
        this.matDialogRef.close(qto);
        if (this.structureView === 'invoices') {
          this.notificationsService.success('Die Abrechnung wurde erstellt.');
        } else {
          this.notificationsService.success('Die Mengenermittlung wurde erstellt.');
        }
      },
      () => {
        this.requestInFlight = false;
        if (this.structureView === 'invoices') {
          this.notificationsService.error('Die Abrechnung konnte nicht erstellt werden.');
        } else {
          this.notificationsService.error('Die Mengenermittlung konnte nicht erstellt werden.');
        }
        if (this.projectForQtoType !== ProjectQuantityTakeOffType.All) {
          this.newInvoiceForm.get('calculationType').disable();
        }
      }
    );
  }

  setupQTOCalculationType(): void {
    if (this.projectForQtoType !== ProjectQuantityTakeOffType.All) {
      if (this.projectForQtoType === ProjectQuantityTakeOffType.Positions) {
        this.newInvoiceForm.patchValue({ calculationType: QuantityTakeOffCalculationType.ByPosition });
      }
      this.newInvoiceForm.get('calculationType').disable();
    }
  }
}
