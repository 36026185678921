import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TopMenuButtonsService {
  private clickedButtonSource = new Subject<string>();
  clickedButton = this.clickedButtonSource.asObservable();

  constructor() {}

  setClickedButton(name: string): void {
    this.clickedButtonSource.next(name);
  }
}
