import { NgIf, NgFor } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatBadge } from '@angular/material/badge';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';

import { AuthenticationMessenger } from '@dangl/angular-dangl-identity-client';
import { UserInfo } from '@dangl/angular-dangl-identity-client/models/user-info';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { ModalService } from 'app/shared/services/modal.service';

import { NoteIconComponent } from '../note-icon/note-icon.component';
import { NotificationWindowComponent } from '../notification-window/notification-window.component';

@Component({
  selector: 'pa-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  standalone: true,
  imports: [NgIf, MatIcon, MatTooltip, MatBadge, MatMenuTrigger, MatMenu, NgFor, MatMenuItem, NoteIconComponent]
})
export class NotificationListComponent implements OnInit, OnDestroy {
  private $destroy: Subject<boolean> = new Subject<boolean>();
  userInfo: UserInfo;
  notifications: any[] = [];

  constructor(
    private avaNotificationsService: AvaNotificationsService,
    private modalService: ModalService,
    private authenticationMessenger: AuthenticationMessenger
  ) {}

  ngOnInit(): void {
    this.avaNotificationsService.notifications.pipe(takeUntil(this.$destroy)).subscribe((e) => (this.notifications = e));
    this.authenticationMessenger.userInfo.pipe(takeUntil(this.$destroy)).subscribe((ui) => (this.userInfo = ui));
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  readNote(id: string = ''): void {
    this.modalService.openModal(NotificationWindowComponent, {
      dialogType: ConfirmationType.General,
      data: { notifications: this.notifications, id }
    });
  }
}
