import { NgIf, NgFor } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';

import { take } from 'rxjs/operators';

import { LvEditorService } from '@serv-spec/services/lv-editor.service';

import { ComissionStatusDto, ItemNumberDto, PriceTypeDto, ServiceSpecificationGroupDto } from 'app/generated-client/generated-client';
import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { ModalService } from 'app/shared/services/modal.service';

import { ComissionStatusPipe } from '../../../../../../../../shared/pipes/ui-data-display/comission-status.pipe';
import { PriceTypePipe } from '../../../../../../../../shared/pipes/ui-data-display/price-type.pipe';
import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';
import { ItemNumberEditingComponent } from '../../../../../../../service-specification-editing/components/item-number-editing/item-number-editing.component';

@Component({
  selector: 'pa-lv-editor-service-specification-group',
  templateUrl: './lv-editor-service-specification-group.component.html',
  styleUrls: ['./lv-editor-service-specification-group.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    ItemNumberEditingComponent,
    FormsModule,
    FlexLayoutDirective,
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    NgFor,
    MatInput,
    MatSuffix,
    MatButton,
    PriceTypePipe,
    ComissionStatusPipe
  ]
})
export class LvEditorServiceSpecificationGroupComponent implements OnInit, OnDestroy, OnChanges {
  @Input() element: ServiceSpecificationGroupDto;
  @Input() isEditableAll: boolean;
  elementChanged: ServiceSpecificationGroupDto = null;
  isChanged: boolean;
  isElementChanged: boolean;
  comissionStatusList = Object.keys(ComissionStatusDto) as ComissionStatusDto[];
  priceTypes = Object.keys(PriceTypeDto) as PriceTypeDto[];

  constructor(
    private lvEditorService: LvEditorService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.getElement();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isChanged) {
      this.openConfirmWindow();
    } else {
      this.getElement();
      if (changes.element.previousValue && changes.element.currentValue && changes.element.previousValue !== changes.element.currentValue) {
        setTimeout(() => {
          if (!this.isEditableAll) {
            this.lvEditorService.stopChangeElement();
          }
        }, 1);
      }
    }
  }

  ngOnDestroy(): void {
    this.lvEditorService.isElementChanged.pipe(take(1)).subscribe((isElementChanged) => (this.isElementChanged = isElementChanged));
    if (this.isChanged && this.isElementChanged) {
      this.openConfirmWindow();
    }
    this.lvEditorService.setChangeElement(false);
    setTimeout(() => {
      this.lvEditorService.stopChangeElement();
    }, 1);
  }

  getElement(): void {
    if (this.element) {
      this.elementChanged = JSON.parse(JSON.stringify(this.element));
    }
  }

  changeElement(): void {
    this.isChanged = true;
    this.lvEditorService.setChangeElement(this.isChanged);
  }

  onItemNumberChanged(itemNumber: ItemNumberDto): void {
    this.changeElement();
    this.elementChanged.itemNumber = itemNumber;
  }

  exit(save: boolean = false): void {
    if (save) {
      this.lvEditorService.saveChangedElement(this.elementChanged);
    }
    this.lvEditorService.stopChangeElement();
    this.isChanged = false;
    this.lvEditorService.setChangeElement(this.isChanged);
  }

  openConfirmWindow(): void {
    this.modalService
      .openModal(ModalConfirmComponent, {
        dialogType: ConfirmationType.General,
        data: ['Verlassen', 'Gruppe', 'Änderungen wurden nicht gespeichert. Speichern?'],
        autoFocus: false
      })
      .afterClosed()
      .subscribe((r) => {
        this.exit(!!r);
      });
  }
}
