import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { IElementDto } from 'app/generated-client/generated-client';

@Injectable({
  providedIn: 'root'
})
export class TreeNodeStateService {
  private treeNodeStateSource = new BehaviorSubject<{ [elementId: string]: boolean }>({});
  treeNodeState = this.treeNodeStateSource.asObservable();

  private updateTreeNodeStateSource = new Subject<boolean>();
  updateTreeNodeState = this.updateTreeNodeStateSource.asObservable();
  constructor() {}

  set(node: IElementDto): void {
    this.treeNodeStateSource.value[node.id] = true;

    this.treeNodeStateSource.next(this.treeNodeStateSource.value);
  }

  remove(id: string): void {
    delete this.treeNodeStateSource.value[id];
    this.treeNodeStateSource.next(this.treeNodeStateSource.value);
  }

  resetTreeState(): void {
    this.treeNodeStateSource.next({});
  }

  setTreeState(treeNodeState: { [elementId: string]: boolean }): void {
    this.treeNodeStateSource.next(treeNodeState);
  }

  updateTree(): void {
    this.updateTreeNodeStateSource.next(true);
  }
}
