import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  ContactGet,
  ContactPaymentTermGet,
  ContactPaymentTermsClient,
  PaginationResultOfContactPaymentTermGet
} from 'app/generated-client/generated-client';

import { AvaNotificationsService } from '../../../../shared/services/ava-notifications.service';
import { SelectedContactMessengerService } from '../../services/selected-contact-messenger.service';
import { TermsTableComponent } from '@contacts/components/terms-table/terms-table.component';

@Component({
  selector: 'pa-contact-terms',
  templateUrl: './contact-terms.component.html',
  styleUrls: ['./contact-terms.component.scss'],
  standalone: true,
  imports: [TermsTableComponent]
})
export class ContactTermsComponent implements OnInit, OnDestroy {
  termsItems: ContactPaymentTermGet[];
  private contactId: string;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private selectedContactMessengerService: SelectedContactMessengerService,
    private contactPaymentTermsClient: ContactPaymentTermsClient,
    private avaNotificationsService: AvaNotificationsService
  ) {}

  ngOnInit(): void {
    this.selectedContactMessengerService.selectedContact.pipe(takeUntil(this.$destroy)).subscribe((c: ContactGet) => {
      this.contactId = c?.id;
      if (this.contactId) {
        this.getTerms(this.contactId);
      }
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private getTerms(contactId: string): void {
    this.contactPaymentTermsClient
      .getAllContactPaymentTermsForContact(contactId, undefined, undefined, undefined, undefined)
      .pipe(takeUntil(this.$destroy))
      .subscribe((e: PaginationResultOfContactPaymentTermGet) => {
        this.termsItems = e.data;
      });
  }

  deleteTerm(row: ContactPaymentTermGet): void {
    this.contactPaymentTermsClient.deleteContactPaymentTerm(row.contactId, row.id).subscribe(() => this.getTerms(this.contactId));
  }

  addTerm(data): void {
    const post = { ...data, contactId: this.contactId };
    this.contactPaymentTermsClient.createContactPaymentTerm(this.contactId, post).subscribe({
      next: () => this.getTerms(this.contactId),
      error: () => this.avaNotificationsService.error('Fehler beim Speichern der Zahlungsbedingungen')
    });
  }
}
