import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ElementSelectionOverviewGet } from 'app/generated-client/generated-client';
import { takeUntil } from 'rxjs/operators';
import { SelectedSpecificationMessengerService } from '../messengers/selected-specification-messenger.service';
import { PaginationMainService } from './main/pagination-main.service';

@Injectable({
  providedIn: 'root'
})
export class SelectionElementService extends PaginationMainService<ElementSelectionOverviewGet> {
  private projectId: string;
  private avaProjectId: string;

  constructor(protected http: HttpClient, private selectedSpecificationMessengerService: SelectedSpecificationMessengerService) {
    super(http);
    this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(takeUntil(this.$destroy)).subscribe(p => {
      this.projectId = p?.parentProjectId;
      this.avaProjectId = p?.avaProjectId;
      this.setBaseUrl();
    });
  }

  private setBaseUrl(): void {
    if (this.projectId && this.avaProjectId) {
      this.beginBaseUrl(`/api/projects/${this.projectId}/ava-projects/${this.avaProjectId}/element-selections`);
    } else {
      this.baseUrl = null;
    }
  }
}
