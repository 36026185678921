import { NgIf, NgFor } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSelect } from '@angular/material/select';
import { MatTabGroup, MatTab } from '@angular/material/tabs';

import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import {
  AvaFileTargetType,
  AvaProjectGet,
  FileResponse,
  PriceInquiryRequestOfferExportPost,
  PriceInquiryRequestOfferFileType,
  PriceInquiryRequestsClient,
  ReportsClient,
  TextTemplatesClient,
  TextTemplateType
} from 'app/generated-client/generated-client';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { PrintViewMessengerService } from 'app/shared/services/electron/print-view-messenger.service';
import { FileSaverService } from 'app/shared/services/file-saver.service';

import { AvaFileTargetTypePipe } from '../../../../../../shared/pipes/ui-data-display/ava-file-target-type.pipe';
import { ModalService } from '../../../../../../shared/services/modal.service';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

import { ReportTextSelectionComponent } from '../report-text-selection/report-text-selection.component';

@Component({
  selector: 'pa-send-price-inquiry',
  templateUrl: './send-price-inquiry.component.html',
  styleUrls: ['./send-price-inquiry.component.scss'],
  standalone: true,
  imports: [
    MatDialogContent,
    MatTabGroup,
    MatTab,
    NgIf,
    FlexLayoutDirective,
    MatProgressSpinner,
    MatFormField,
    MatLabel,
    MatSelect,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    MatOption,
    MatButton,
    MatCheckbox,
    AvaFileTargetTypePipe
  ]
})
export class SendPriceInquiryComponent implements OnInit {
  targetTypes: AvaFileTargetType[];
  targetType = new FormControl();
  avaProject: AvaProjectGet;
  showingPdf = false;
  waitingForPdf = false;
  requestEnRoute = false;
  includeSignatureField = true;
  includeLongTexts = false;
  includeHeaderOnlyOnFirstPage = false;

  offerStartText: string;
  offerEndText: string;
  newName: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      projectId: string;
      avaProjectId: string;
      priceInquiryId: string;
      requestId?: string;
      priceInquiryName: string;
    },
    private avaNotificationsService: AvaNotificationsService,
    private reportsClient: ReportsClient,
    private printViewMessengerService: PrintViewMessengerService,
    private priceInquiryRequestsClient: PriceInquiryRequestsClient,
    private fileSaverService: FileSaverService,
    private modalService: ModalService,
    private textTemplatesClient: TextTemplatesClient
  ) {}

  ngOnInit(): void {
    this.targetTypes = <AvaFileTargetType[]>Object.keys(PriceInquiryRequestOfferFileType);
    this.targetType.patchValue(PriceInquiryRequestOfferFileType.Excel);

    if (this.data?.projectId && this.data?.avaProjectId) {
      this.textTemplatesClient
        .getLastUsedHtmlTextForAvaProject(this.data.projectId, this.data.avaProjectId, TextTemplateType.SubContractorOfferRequestStart)
        .subscribe({
          next: (text) => (this.offerStartText = text.htmlText)
        });
      this.textTemplatesClient
        .getLastUsedHtmlTextForAvaProject(this.data.projectId, this.data.avaProjectId, TextTemplateType.SubContractorOfferRequestEnd)
        .subscribe({
          next: (text) => (this.offerEndText = text.htmlText)
        });
    }

    this.newName = `${this.data.priceInquiryName}`;
  }

  selectStartingText(): void {
    this.selectText(this.offerStartText, TextTemplateType.SubContractorOfferRequestStart).subscribe((t) => (this.offerStartText = t));
  }

  selectEndingText(): void {
    this.selectText(this.offerEndText, TextTemplateType.SubContractorOfferRequestEnd).subscribe((t) => (this.offerEndText = t));
  }

  private selectText(currentText: string, textTemplateType: TextTemplateType): Observable<string> {
    return this.modalService
      .openModal(ReportTextSelectionComponent, {
        dialogType: ConfirmationType.General,
        data: {
          text: currentText,
          templateType: textTemplateType
        }
      })
      .afterClosed()
      .pipe(map((text) => text ?? currentText));
  }

  generatePdfAndShowPrintView(): void {
    this.waitingForPdf = true;
    this.requestEnRoute = true;

    this.reportsClient
      .getServiceSpecificationReportForSubContractorOfferRequest(
        this.data.requestId,
        true,
        this.includeSignatureField,
        this.includeHeaderOnlyOnFirstPage,
        this.includeLongTexts,
        {
          endHtmlText: this.offerEndText,
          startHtmlText: this.offerStartText
        }
      )
      .subscribe({
        next: (report) => {
          this.printViewMessengerService.showPdfPreview(
            report.htmlReport,
            this.newName,
            report.isLandscape,
            report.pdfReportBase64,
            this.includeHeaderOnlyOnFirstPage
          );
          this.printViewMessengerService.waitForPrintViewReady().subscribe(() => {
            this.showingPdf = true;
            this.waitingForPdf = false;
            this.requestEnRoute = false;
          });
          this.printViewMessengerService.printViewClosed.pipe(first()).subscribe(() => {
            this.showingPdf = false;
            this.waitingForPdf = false;
            this.requestEnRoute = false;
          });
        },
        error: () => {
          this.waitingForPdf = false;
          this.requestEnRoute = false;
          this.showingPdf = false;
          this.avaNotificationsService.error('Fehler beim Erstellen der PDF-Datei');
        }
      });
  }
  createOfferRequest(): void {
    this.requestEnRoute = true;
    const fileType: PriceInquiryRequestOfferExportPost = {
      fileType: this.targetType.value
    };
    this.priceInquiryRequestsClient
      .createOfferRequestFile(this.data.projectId, this.data.avaProjectId, this.data.priceInquiryId, this.data.requestId, fileType)
      .subscribe({
        next: (r: FileResponse) => {
          this.requestEnRoute = false;
          const { fileName } = r;
          const newFileName = `${this.newName}${fileName.slice(fileName.lastIndexOf('.'))}`;
          r.fileName = newFileName;
          r.headers['content-disposition'] = r.headers['content-disposition'].replaceAll(fileName, newFileName);
          this.fileSaverService.saveAs(r);
        },
        error: () => {
          this.requestEnRoute = false;
          this.avaNotificationsService.error('Fehler beim Erstellen der Datei');
        }
      });
  }
}
