import { Pipe, PipeTransform } from '@angular/core';

import { ServiceTypeDto } from 'app/generated-client/generated-client';

@Pipe({
  name: 'serviceType',
  standalone: true
})
export class ServiceTypePipe implements PipeTransform {
  transform(value: ServiceTypeDto): string {
    switch (value) {
      case ServiceTypeDto.Regular:
        return 'Normal';
      case ServiceTypeDto.HourlyPaidWork:
        return 'Stundenlohnarbeiten';
    }
    return null;
  }
}
