<h1>Hinweistext <span class="mode">Bearbeitungsmodus</span></h1>
<ng-container *ngIf="elementChanged">
  <div fxLayoutGap="12px">
    <mat-checkbox [(ngModel)]="elementChanged.isOpeningText" (change)="changeElement()">Vorbemerkung</mat-checkbox>
    <mat-checkbox [(ngModel)]="elementChanged.isClosingText" (change)="changeElement()">Schlussbemerkung</mat-checkbox>
  </div>

  <div>
    <input
      class="changing broad"
      type="text"
      placeholder="Kurztext"
      [(ngModel)]="elementChanged.shortText"
      (ngModelChange)="changeElement()"
    />
  </div>
  <div *ngIf="isEditableAll">
    <input class="changing" type="text" placeholder="Kennung" [(ngModel)]="elementChanged.identifier" (ngModelChange)="changeElement()" />
  </div>
  <dangl-tiny-mce
    [ngModel]="elementChanged.htmlLongText"
    (ngModelChange)="changedTinyMce($event, elementChanged, 'htmlLongText')"
    tinyMceLanguageCode="de"
  >
  </dangl-tiny-mce>
</ng-container>

<div fxLayout="row" fxLayoutGap="12px" class="buttons">
  <button mat-raised-button color="primary" [disabled]="!isChanged" (click)="exit(true)">Speichern</button>
  <button mat-raised-button color="warn" (click)="exit()">Abbrechen</button>
</div>
