import { Component } from '@angular/core';

import { PriceComponentType } from '../../../../generated-client/generated-client';

import { MasterDataProductsComponent } from '../master-data-products/master-data-products.component';

@Component({
  selector: 'pa-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss'],
  standalone: true,
  imports: [MasterDataProductsComponent]
})
export class ArticlesComponent {
  priceComponentType = PriceComponentType.Materials;
}
