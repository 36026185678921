<div
  fxLayout="row"
  fxLayoutGap="20px"
  [style.height]="sanitizer.bypassSecurityTrustStyle('calc(100% - ' + bottom.offsetHeight + 'px)')"
  class="content"
>
  <pa-master-addition-group
    (recalculateTransferWage)="recalculateTransferWage()"
    [isEditing]="isEditing"
    [isLoading]="isLoading"
    [selectedAdditionGroup]="selectedGroup"
  >
  </pa-master-addition-group>

  <pa-calculation-middle-wages
    *ngIf="selectedGroup?.id"
    [masterAdditionId]="selectedGroup?.id"
    [laborSiteOperationCostsAddition]="selectedGroup?.laborSiteOperationCostsAddition"
    [laborCompanyOperationCostsAddition]="selectedGroup?.laborCompanyOperationCostsAddition"
    [riskAndProfitAddition]="selectedGroup?.riskAndProfitAddition"
    (calculationChange)="onCalculationChange($event)"
  ></pa-calculation-middle-wages>
</div>
<div #bottom>
  <div class="btn-box" fxLayout="row wrap" fxLayoutGap="12px">
    <button mat-raised-button [disabled]="isEditing" type="button" [color]="isEditing ? 'primary' : ''" (click)="isEditing = !isEditing">
      Bearbeiten
    </button>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="!isEditing">Speichern</button>
    <button mat-button mat-raised-button color="warn" class="btn" (click)="dismiss()">Abbrechen</button>
    <button mat-raised-button color="warn" class="nav-btn" (click)="showConfirmDeletionModal()" [disabled]="selectedGroup?.isDefault">
      Gruppe Löschen
    </button>
  </div>
  <div *ngIf="selectedGroup && !selectedGroup?.masterPriceComponentAdditions.length" class="warning-text">
    <strong>Achtung:</strong> In dieser Zuschlagsgruppe sind keine Preiskomponenten definiert. Die Zuschlagsgruppe kann erst in einem
    Projekt verwendet werden, wenn mindestens eine Preiskomponente definiert wurde.
  </div>
</div>
