<div mat-dialog-content class="wrapper">
  <h1>Kalkulationsexport</h1>
  <mat-tab-group id="export-tabs" [preserveContent]="true" [disablePagination]="true">
    <mat-tab label="AVA Datei">
      <ng-template matTabContent>
        <pa-calculation-ava-export></pa-calculation-ava-export>
      </ng-template>
    </mat-tab>
    <mat-tab label="Drucken & PDF">
      <ng-template matTabContent>
        <pa-calculation-pdf-export></pa-calculation-pdf-export>
      </ng-template>
    </mat-tab>
    <mat-tab label="Vorherige Exporte">
      <ng-template matTabContent>
        <pa-service-specification-exports-list></pa-service-specification-exports-list>
      </ng-template>
    </mat-tab>
    <mat-tab label="Projektartikel">
      <ng-template matTabContent>
        <pa-calculation-products-export></pa-calculation-products-export>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
