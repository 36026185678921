import { Component, Inject, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { UserDisplayInfoGet } from '../../../generated-client/generated-client';
import { AvaNotificationsService } from '../../services/ava-notifications.service';
import { AvaHubConnector } from '../../services/signalr/ava-hub-connector';

@Component({
  selector: 'pa-kick-project-user',
  templateUrl: './kick-project-user.component.html',
  styleUrls: ['./kick-project-user.component.scss'],
  standalone: true,
  imports: [MatCheckbox, FormsModule, MatButton]
})
export class KickProjectUserComponent {
  constructor(
    @Optional() private matDialogRef: MatDialogRef<KickProjectUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { projectId: string; id: string; userInfo: UserDisplayInfoGet },
    private avaNotificationsService: AvaNotificationsService,
    private avaHubConnector: AvaHubConnector
  ) {}

  confirmKick = false;

  tryKickUser(): void {
    this.avaHubConnector.tryKickProjectUser(this.data.projectId, this.data.id).then((r) => {
      if (r.isSuccess) {
        this.avaNotificationsService.success('Der Benutzer wurde entfernt.');
        this.matDialogRef.close(true);
      } else {
        this.avaNotificationsService.error('Der Benutzer konnte nicht entfernt werden.');
        this.matDialogRef.close(false);
      }
    });
  }

  cancel(): void {
    this.matDialogRef.close(false);
  }
}
