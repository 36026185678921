import { Injectable } from '@angular/core';

import { JwtTokenService } from '@dangl/angular-dangl-identity-client';

import { AppConfig } from '../../../../environments/environment';

import { ElectronService } from './electron.service';

/**
 * This service sets up a communication channel between the renderer and the main thread
 * to send Bearer tokens on demand. This is used, for example, when downloading files.
 * They're running as regular file downloads, and the main thread is set up to append
 * bearer tokens for requests hitting the files api endpoint.
 */
@Injectable({
  providedIn: 'root'
})
export class MainThreadHttpInterceptorHelperService {
  constructor(private electronService: ElectronService, private jwtTokenService: JwtTokenService) {}

  setupMainThreadBearerTokenExchange(): void {
    if (!this.electronService.isElectron) {
      // This is only required in Electron.
      return;
    }

    // This listens to requests on the 'BearerTokenRequestChannel' and responds with
    // a valid Bearer token, or an error response, to the main thread

    this.electronService.ipcRenderer.on('BearerTokenRequestChannel', (_, bearerTokenRequestId: string) => {
      this.jwtTokenService.getToken().subscribe(
        (t) => {
          this.electronService.ipcRenderer.send(bearerTokenRequestId, { token: `Bearer ${t.accessToken}` });
        },
        (error) => this.electronService.ipcRenderer.send(bearerTokenRequestId, { error: error })
      );
    });

    // The backend url is sent so the main thread can set up the trusted url on which it
    // may include bearer tokens
    this.electronService.ipcRenderer?.send('TrustedBackendUrlChannel', AppConfig.backendUrl);
  }
}
