import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';

@Component({
  selector: 'pa-modal-delete-contact',
  templateUrl: './modal-delete-contact.component.html',
  styleUrls: ['./modal-delete-contact.component.scss'],
  standalone: true,
  imports: [MatDialogContent, MatDialogActions, MatButton, MatDialogClose]
})
export class ModalDeleteContactComponent {}
