<div class="wrapper">
  <h2>Wagnis & Gewinn Aufteilung</h2>
  <div class="spinner" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
  <form [formGroup]="mainForm" (ngSubmit)="saveData()" (keydown.enter)="saveData()" *ngIf="mainForm">
    <table>
      <tr>
        <td></td>
        <td>Lohn</td>
        <td *ngFor="let pc of priceComponents">{{ pc.priceComponent }}</td>
      </tr>
      <tr>
        <td>Wagnis und Gewinn</td>
        <td>{{ riskAndProfitAddition | percent: '1.2-2' }}</td>
        <td *ngFor="let pc of priceComponents">{{ riskAndProfitAddition | percent: '1.2-2' }}</td>
      </tr>
      <tr>
        <td>Gewinn</td>
        <ng-container formArrayName="riskAndProfitDistribution">
          <td *ngFor="let pc of getArr.controls; index as i">
            <div [formGroupName]="i">
              <input type="number" formControlName="profitPart" />
            </div>
          </td>
        </ng-container>
      </tr>
      <tr>
        <td>betriebsbezogenes Wagnis</td>
        <ng-container formArrayName="riskAndProfitDistribution">
          <td *ngFor="let pc of getArr.controls; index as i">
            <div [formGroupName]="i">
              <input type="number" formControlName="operationalRiskPart" />
            </div>
          </td>
        </ng-container>
      </tr>
      <tr>
        <td>leistungsbezogenes Wagnis</td>
        <ng-container formArrayName="riskAndProfitDistribution">
          <td *ngFor="let pc of getArr.controls; index as i">
            <div [formGroupName]="i">
              <input type="number" formControlName="performanceRelatedRiskPart" />
            </div>
          </td>
        </ng-container>
      </tr>
      <tr>
        <td>Summe</td>
        <ng-container formArrayName="riskAndProfitDistribution">
          <td *ngFor="let pc of getArr.controls; index as i">
            <span [class.warn]="!checkIfPriceComponentIsValid(pc.value)">
              {{ pc.value.profitPart + pc.value.operationalRiskPart + pc.value.performanceRelatedRiskPart | number: '1.2-2' }} %
            </span>
          </td>
        </ng-container>
      </tr>
    </table>
    <div fxLayout="row" fxLayoutGap="12px" class="buttons">
      <button mat-raised-button type="button" (click)="setAllToProfit()">Alles auf Gewinn aufteilen</button>
      <button mat-raised-button color="primary" type="submit" [disabled]="isInvalid()">Speichern</button>
      <button mat-raised-button color="warn" type="button" mat-dialog-close>Abbrechen</button>
    </div>
  </form>
</div>
