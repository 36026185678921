<h1>Ordnungszahlen Mehrfach Verwendet</h1>
<p>Im LV sind Positionen mit Duplikaten in den Ordnungszahlen vorhanden. Klicke eine Position an, um diese zu öffnen.</p>

<div class="wrapper">
  <div class="spinner" *ngIf="!isLoading; else content">
    <div fxFlex fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <ng-template #content>
    <ng-container>
      <button mat-raised-button color="primary" (click)="close()">Ignorieren</button>
      <mat-selection-list [multiple]="false">
        <mat-list-option color="primary" *ngFor="let position of duplicateItemNumber" (click)="selectedPosition(position)">
          {{ position.itemNumber.stringRepresentation }} - {{ position.shortText }}
        </mat-list-option>
      </mat-selection-list>
      <button mat-raised-button color="primary" (click)="close()">Ignorieren</button>
    </ng-container>
  </ng-template>
</div>
