import { AfterViewInit, Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { BimViewerCanvasService } from '../services/bim-viewer-canvas.service';

@Directive({
  selector: '[viewerResize]',
  standalone: true
})
export class ViewerResizeDirective implements AfterViewInit {
  private $destroy: Subject<boolean> = new Subject<boolean>();
  // See this Stackoverflow question for more info:
  // https://stackoverflow.com/questions/10214873/make-canvas-as-wide-and-as-high-as-parent

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private bimViewerCanvasService: BimViewerCanvasService
  ) {
    this.bimViewerCanvasService?.changedSize.pipe(takeUntil(this.$destroy)).subscribe(() => this.resize());
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  public resize() {
    // First, the viewer element is set to a height of zero, otherwise its
    // own height would prevent the parent from resizing smaller and this
    // would lead to an ever-increasing height of the viewer
    // Same for width
    this.setViewerSize(true);
    setTimeout(() => {
      this.setViewerSize();
    }, 1);
  }

  @HostListener('window:resize', ['$event']) onWindowResize() {
    this.resize();
  }

  ngAfterViewInit(): void {
    this.setViewerSize();
  }

  private setViewerSize(isNull?: boolean) {
    this.renderer.setAttribute(this.el.nativeElement, 'width', isNull ? '0' : this.el.nativeElement.offsetWidth);
    this.renderer.setAttribute(this.el.nativeElement, 'height', isNull ? '0' : this.el.nativeElement.offsetHeight);
  }
}
