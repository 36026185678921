import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageQuantityTakeOffOverviewGet } from 'app/generated-client/generated-client';
import { PaginationMainService } from './main/pagination-main.service';
import { SelectedProjectMessengerService } from '../messengers/selected-project-messenger.service';
import { SelectedSpecificationMessengerService } from '../messengers/selected-specification-messenger.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PagesAllAvaProjectService extends PaginationMainService<PageQuantityTakeOffOverviewGet> {
  private projectId: string;
  private avaProjectId: string;

  constructor(
    protected http: HttpClient,
    selectedProjectMessengerService: SelectedProjectMessengerService,
    selectedSpecificationMessengerService: SelectedSpecificationMessengerService
  ) {
    super(http);
    selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((p) => {
      this.projectId = p?.id;
      this.setBaseUrl();
    });
    selectedSpecificationMessengerService.selectedServiceSpecification.pipe(takeUntil(this.$destroy)).subscribe((p) => {
      this.avaProjectId = p?.avaProjectId;
      this.setBaseUrl();
    });
  }

  private setBaseUrl(): void {
    if (this.projectId && this.avaProjectId) {
      this.beginBaseUrl(`/api/projects/${this.projectId}/ava-projects/${this.avaProjectId}/page-quantity-take-offs`);
    } else {
      this.baseUrl = null;
    }
  }
}
