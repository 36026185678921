import { Pipe, PipeTransform } from '@angular/core';

import { AvaProjectType } from '../../../generated-client/generated-client';

@Pipe({
  name: 'avaProjectType',
  standalone: true
})
export class AvaProjectTypePipe implements PipeTransform {
  transform(value: AvaProjectType): string {
    switch (value) {
      case AvaProjectType.Normal:
        return 'Normal';
      case AvaProjectType.Addendum:
        return 'Nachtrag';
    }

    return null;
  }
}
