import { Directive, HostListener, Injector, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[paInputMask]',
  standalone: true
})
// This is taken from:
// https://stackoverflow.com/a/54462816/4190785
export class InputMaskDirective {
  @Input()
  set paInputMask(value: string) {
    this.regExpr = new RegExp(value);
  }

  private _oldvalue: string = '';
  private regExpr: RegExp;
  private control: NgControl;
  constructor(injector: Injector) {
    //this make sure that not error if not applied to a NgControl
    try {
      this.control = injector.get(NgControl);
    } catch (e) {
      /* We're ignoring the error here */
    }
  }
  @HostListener('input', ['$event'])
  change($event: any) {
    const item = $event.target;
    const value = item.value;

    let pos = item.selectionStart; //get the position of the cursor
    const matchvalue = value;
    const noMatch: boolean = value && !this.regExpr.test(matchvalue);
    if (noMatch) {
      item.selectionStart = item.selectionEnd = pos - 1;
      if (item.value.length < this._oldvalue.length && pos == 0) pos = 2;
      if (this.control) this.control.control.setValue(this._oldvalue, { emit: false });

      item.value = this._oldvalue;
      item.selectionStart = item.selectionEnd = pos - 1; //recover the position
    } else this._oldvalue = value;
  }
}
