import { Injectable, OnDestroy, inject } from '@angular/core';
import { UserSettingsService } from '@shared/services/user-settings.service';
import { ReplaySubject, Subject, filter, fromEvent, of, switchMap, takeUntil } from 'rxjs';

// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export class OverwriteModeMessengerService implements OnDestroy {
  private isOverwriteModeSource = new ReplaySubject<boolean>(1);
  isOverwriteMode$ = this.isOverwriteModeSource.asObservable();
  private isOverwriteMode = false;
  userSettingsService = inject(UserSettingsService);

  private $destroy = new Subject<boolean>();
  constructor() {
    this.userSettingsService.currentUserSettings
      .pipe(
        switchMap((userSettings) => {
          if (!userSettings.allowOverwriteMode) {
            return of(null);
          }
          return fromEvent(document, 'keydown').pipe(
            filter((event: KeyboardEvent) => event.key === 'Insert' && !event.altKey),
            takeUntil(this.$destroy)
          );
        })
      )
      .subscribe((e) => {
        if (e) {
          this.isOverwriteMode = !this.isOverwriteMode;
        } else {
          this.isOverwriteMode = false;
        }
        this.setOverwriteMode(this.isOverwriteMode);
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private setOverwriteMode(isOverwrite: boolean): void {
    this.isOverwriteModeSource.next(isOverwrite);
  }
}
