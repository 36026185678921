import { Injectable } from '@angular/core';

import { PriceRoundingModeDto } from '../../../../../generated-client/generated-client';

@Injectable({
  providedIn: 'root'
})
export class RoundingService {
  static withRounding(value: number, precision: number, roundingMode: PriceRoundingModeDto): number {
    if (precision < 0 || precision > 18) {
      throw new Error('Precision must be between 0 and 18');
    }

    const precisionPower: number = this.powerOfTen(precision);
    switch (roundingMode) {
      case PriceRoundingModeDto.Normal:
        return Math.round(value * precisionPower) / precisionPower;

      case PriceRoundingModeDto.Floor:
        return Math.floor(value * precisionPower) / precisionPower;

      case PriceRoundingModeDto.Ceiling:
        return Math.ceil(value * precisionPower) / precisionPower;

      default:
        throw new Error('Invalid enum parameter given for roundingMode');
    }
  }

  private static powerOfTen(precision: number): number {
    let power = 1;
    while (precision > 0) {
      power *= 10;
      precision--;
    }
    return power;
  }
}
