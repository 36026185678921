import { NgIf } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { Subject } from 'rxjs';

import { MasterDataProductGet, MasterDataProductsClient, PriceComponentType } from 'app/generated-client/generated-client';

import { PriceComponentTypePipe } from '../../../../../../shared/pipes/ui-data-display/price-component-type.pipe';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';
import { MasterDataProductsTableComponent } from '../../../../../master-data/components/master-data-products-table/master-data-products-table.component';

@Component({
  selector: 'pa-master-data-price-component-selection',
  templateUrl: './master-data-price-component-selection.component.html',
  styleUrls: ['./master-data-price-component-selection.component.scss'],
  standalone: true,
  imports: [NgIf, FlexLayoutDirective, MatProgressSpinner, MasterDataProductsTableComponent, PriceComponentTypePipe]
})
export class MasterDataPriceComponentSelectionComponent implements OnInit, OnDestroy {
  private $destroy: Subject<boolean> = new Subject<boolean>();
  didNotFindExactMatch = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      priceComponentType: PriceComponentType;
      filter: string;
    },
    @Optional() private matDialogRef: MatDialogRef<MasterDataPriceComponentSelectionComponent>,
    private masterDataProductsClient: MasterDataProductsClient
  ) {}

  ngOnInit(): void {
    this.masterDataProductsClient.getAllMasterDataProducts(this.data.filter, this.data.priceComponentType, null, '', '', 2, 1).subscribe(
      (response) => {
        if (response && response.data && response.data.length === 1) {
          this.selectProduct(response.data[0]);
        } else {
          this.didNotFindExactMatch = true;
        }
      },
      () => (this.didNotFindExactMatch = true)
    );
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  selectProduct(product: MasterDataProductGet): void {
    this.matDialogRef.close(product);
  }
}
