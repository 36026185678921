import { NgIf, NgFor } from '@angular/common';
import { Component, OnInit, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';

import { AvaProjectGet, CompanyGet, ProjectGet } from 'app/generated-client/generated-client';
import { CompaniesService } from 'app/shared/services/lightquery/companies.service';

import { FlexLayoutDirective } from '../../../flex-layout/flex-layout.directive';
import { ServiceSpecificationsTableFlexibleComponent } from '../../../project-id/components/service-specifications/components/service-specifications-table-flexible/service-specifications-table-flexible.component';

import { ProjectsControlComponent } from '../projects-control/projects-control.component';

@Component({
  selector: 'pa-select-project-ava-project',
  templateUrl: './select-project-ava-project.component.html',
  styleUrls: ['./select-project-ava-project.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FlexLayoutDirective,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatSelect,
    MatOption,
    NgFor,
    ProjectsControlComponent,
    MatButton,
    ServiceSpecificationsTableFlexibleComponent
  ]
})
export class SelectProjectAvaProjectComponent implements OnInit {
  selectedProject: ProjectGet;
  filterProject: string = '';
  filterByCompanyId: string = '';
  listCompany: CompanyGet[] = [];
  selectedAvaProject: AvaProjectGet;

  constructor(
    @Optional() private matDialogRef: MatDialogRef<SelectProjectAvaProjectComponent>,
    private companiesService: CompaniesService
  ) {}

  ngOnInit(): void {
    this.companiesService.getAll().subscribe((c) => (this.listCompany = c));
  }

  beginProject() {
    this.selectedProject = null;
    this.beginAvaProject();
  }

  beginAvaProject() {
    this.selectedAvaProject = null;
  }

  selectProject(project: ProjectGet): void {
    this.beginProject();
    setTimeout(() => {
      this.selectedProject = project;
      this.filterProject = '';
      this.filterByCompanyId = '';
    }, 200);
  }

  selectAvaProject(avaProject: AvaProjectGet) {
    this.matDialogRef.close({ project: this.selectedProject, avaProject });
  }
}
