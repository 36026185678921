import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ContextListMenuSettingType } from '../models';
import { getStorage, setStorage } from '../utilities/storage';

const allTrueData = {
  removeRow: true,
  cutRow: true,
  copyCalculation: true,
  addRowBefore: true,
  addRowAfter: true,
  addMaterials: true,
  addEquipment: true,
  addCasing: true,
  saveRows: true,
  addSavedRow: true,
  saveOneRow: true
};

@Injectable({
  providedIn: 'root'
})
export class ContextMenuSettingService {
  private listCalculationMenuSource = new ReplaySubject<ContextListMenuSettingType>(1);
  listCalculationMenu = this.listCalculationMenuSource.asObservable();

  constructor() {
    const savedData = getStorage<ContextListMenuSettingType>('contextMenuCalculation', allTrueData);
    this.setListCalculationMenu(savedData);
  }

  public setListCalculationMenu(data: ContextListMenuSettingType): void {
    this.listCalculationMenuSource.next(data);
    setStorage<ContextListMenuSettingType>('contextMenuCalculation', data);
  }
}
