import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy, Inject, Optional } from '@angular/core';
import { FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { AddressesClient, AddressGet, AddressPost, ContactGet } from 'app/generated-client/generated-client';
import { AddressesService } from 'app/shared/services/lightquery/addresses.service';

import { AvaNotificationsService } from '../../../../shared/services/ava-notifications.service';
import { SelectedAddressMessengerService } from '../../services/selected-address-messenger.service';
import { SelectedContactMessengerService } from '../../services/selected-contact-messenger.service';

@Component({
  selector: 'pa-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    ReactiveFormsModule
  ]
})
export class AddressFormComponent implements OnInit, OnDestroy {
  private $destroy: Subject<boolean> = new Subject<boolean>();
  address: AddressGet | AddressPost;
  private contactId: string;
  addressForm: FormGroup;
  requestEnRoute = false;

  countryCodes: {
    countryCode: string;
    country: string;
  }[] = [
    {
      countryCode: '',
      country: null
    },
    {
      countryCode: 'DE',
      country: 'Deutschland'
    },
    {
      countryCode: 'AT',
      country: 'Österreich'
    },
    {
      countryCode: 'CH',
      country: 'Schweiz'
    }
  ];

  constructor(
    private addressesClient: AddressesClient,
    private addressesService: AddressesService,
    private notificationsService: AvaNotificationsService,
    private router: Router,
    private selectedAddressMessengerService: SelectedAddressMessengerService,
    private selectedContactMessengerService: SelectedContactMessengerService,
    @Inject(MAT_DIALOG_DATA) private data: string,
    @Optional() private matDialogRef: MatDialogRef<AddressFormComponent>,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.addressForm = this.fb.group({
      street: [''],
      zipCode: [''],
      city: [''],
      countryCode: ['DE'],
      country: ['Deutschland'],
      isPrimaryAddress: [''],
      isDefaultInvoiceAddress: ['']
    });
    this.getAddressData();
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private getAddressData(): void {
    this.selectedContactMessengerService.selectedContact.pipe(takeUntil(this.$destroy)).subscribe((c: ContactGet) => {
      this.contactId = c?.id;
      if (this.data) {
        const addressId = this.data;
        this.selectAddress(addressId);
        this.selectedAddressMessengerService.selectedAddress.pipe(first()).subscribe((address: AddressGet) => {
          this.address = address;
          this.addressForm.patchValue({ ...address });
        });
      } else {
        this.address = {
          city: null,
          contactId: this.contactId,
          country: 'Deutschland',
          countryCode: 'DE',
          isDefaultInvoiceAddress: false,
          isPrimaryAddress: false,
          street: null,
          id: null,
          zipCode: null
        };
      }
    });
  }

  updateCountryCode(countryCode: string): void {
    const country = this.countryCodes.find((cc: { countryCode: string; country: string }) => cc.countryCode == countryCode);
    if (country) {
      this.address.country = country.country;
      this.addressForm.patchValue({
        country: country.country
      });
    }
  }

  private selectAddress(addressId: string): void {
    const address = this.addressesService.getAddress(addressId);
    if (address) {
      this.selectedAddressMessengerService.setSelectedAddress(address);
      return;
    }
    if (!address) {
      this.addressesClient
        .getAddressForContactById(this.contactId, addressId)
        .subscribe((a: AddressGet) => this.selectedAddressMessengerService.setSelectedAddress(a));
    }
  }

  saveAddress(): void {
    this.requestEnRoute = true;
    if (this.address['id']) {
      this.addressesClient
        .editAddress(this.contactId, this.address['id'], {
          id: this.address['id'],
          contactId: this.contactId,
          ...this.addressForm.value
        })
        .subscribe({
          next: () => {
            this.addressesService.forceRefresh();
            this.notificationsService.success('Adressse aktualisiert');
            this.matDialogRef.close();
          },
          error: () => {
            this.notificationsService.error('Fehler beim Speichern der Adresse');
            this.matDialogRef.close();
          }
        });
    } else {
      this.addressesClient
        .createAddress(this.contactId, {
          contactId: this.contactId,
          ...this.addressForm.value
        })
        .subscribe({
          next: () => {
            this.addressesService.forceRefresh();
            this.notificationsService.success('Adresse erstellt');
            this.matDialogRef.close();
          },
          error: () => {
            this.notificationsService.error('Fehler beim Speichern der Adresse');
            this.matDialogRef.close();
          }
        });
    }
  }

  dismiss(): void {
    this.matDialogRef.close();
  }
}
