<div>
  <ng-container *ngIf="project?.projectUserAccessRestrictions">
    <div fxLayoutAlign="start center" fxLayoutGap="20px">
      <button mat-raised-button color="primary" (click)="unlockProject()" *ngIf="projectIsLockedForCurrentUser">Entsperren</button>
    </div>
    <div class="list">
      <div *ngFor="let user of project.projectUserAccessRestrictions; let index = index" class="list-item">
        #{{ index + 1 }} : <span class="user">{{ user.userName }}</span>
      </div>
    </div>
  </ng-container>
  <button mat-raised-button color="primary" (click)="lockProject()" *ngIf="!projectIsLockedForCurrentUser">Sperren</button>
</div>
