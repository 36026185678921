<div class="wrap">
  <table
    class="export-table"
    mat-table
    [dataSource]="avaProjectAvaFileExportsService"
    matSort
    (matSortChange)="avaProjectAvaFileExportsService.onSort($event)"
  >
    <ng-container matColumnDef="fileName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let row">{{ row.fileName }}</td>
    </ng-container>
    <ng-container matColumnDef="createdAtUtc">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Erstellt</th>
      <td mat-cell *matCellDef="let row">{{ row.createdAtUtc | date: 'dd.MM.yyyy HH:mm' }}</td>
    </ng-container>
    <ng-container matColumnDef="createdByUsername">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Erstellt von</th>
      <td mat-cell *matCellDef="let row">{{ row.createdByUsername }}</td>
    </ng-container>
    <ng-container matColumnDef="exportPhase">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Phase</th>
      <td mat-cell *matCellDef="let row">{{ row.exportPhase | avaFileExportPhase }}</td>
    </ng-container>
    <ng-container matColumnDef="targetType">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Typ</th>
      <td mat-cell *matCellDef="let row">{{ row.targetType | avaFileTargetType }}</td>
    </ng-container>
    <ng-container matColumnDef="save">
      <th mat-header-cell *matHeaderCellDef>Speichern</th>
      <td mat-cell *matCellDef="let row">
        <button mat-raised-button color="primary" [disabled]="requestEnRoute" (click)="saveFile(row)">Speichern</button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="showedColumn; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: showedColumn"></tr>
  </table>
</div>
<pa-upper-paginator
  [pageSizeOptions]="[5, 10, 25, 100]"
  [upperPaginated]="avaProjectAvaFileExportsService.paginationResult | async"
  [upperService]="avaProjectAvaFileExportsService"
  (page)="avaProjectAvaFileExportsService.onPage($event)"
>
</pa-upper-paginator>
