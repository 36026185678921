import { NgIf, NgFor, DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelectionList, MatListOption } from '@angular/material/list';

import { Subject, of } from 'rxjs';
import { delay, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';

import { ProjectFileService } from '@project/components/project-files/services/project-file.service';

import { ProjectFileGet } from 'app/generated-client/generated-client';
import { ProjectFilesFilterService } from 'app/shared/services/lightquery/project-files-filter.service';

import { UpperPaginatorComponent } from '../../../../../../shared/components/upper-paginator/upper-paginator.component';

import { ProjectFileListPreviewComponent } from '../project-file-list-preview/project-file-list-preview.component';
import { ProjectFilesExplorerComponent } from '../project-files-explorer/project-files-explorer.component';
import { PaginationResult } from 'ng-lightquery';

@Component({
  selector: 'pa-project-files-list',
  templateUrl: './project-files-list.component.html',
  styleUrls: ['./project-files-list.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    NgIf,
    MatSelectionList,
    NgFor,
    MatListOption,
    ProjectFileListPreviewComponent,
    UpperPaginatorComponent,
    ProjectFilesExplorerComponent,
    DatePipe
  ]
})
export class ProjectFilesListComponent implements OnInit, OnDestroy {
  @Input() extensions: string[] = null;
  filter: string;
  isFilter: boolean;
  paginationResult: PaginationResult<ProjectFileGet>;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    public projectFilesFilterService: ProjectFilesFilterService,
    private projectFileService: ProjectFileService
  ) {}

  ngOnInit(): void {
    this.setFilter();
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private setFilter(): void {
    this.projectFilesFilterService.initService({
      filtering: this.filter,
      sorting: { propertyName: 'lastAccessedAtUtc', isDescending: true }
    });

    this.projectFilesFilterService.$filter
      .pipe(
        switchMap((x: string) => of<string>(x).pipe(delay(250))),
        distinctUntilChanged(),
        takeUntil(this.$destroy)
      )
      .subscribe((filterValue: string) => {
        if (!filterValue) {
          this.isFilter = null;
        }
      });
    this.projectFilesFilterService.paginationResult.pipe(takeUntil(this.$destroy)).subscribe((files: PaginationResult<ProjectFileGet>) => {
      if (files) {
        this.paginationResult = files;
        this.paginationResult.data = this.paginationResult.data.sort(ProjectFilesListComponent.sortFilterValues.bind(this));
        this.projectFileService.setSelectedProjFile(null);
      }
    });
  }

  private static sortFilterValues(a: [string, ProjectFileGet], b: [string, ProjectFileGet]): number {
    return a['file'].fileName.localeCompare(b['file'].fileName);
  }

  onFilter(filter: string): void {
    this.isFilter = true;
    this.projectFilesFilterService.onFilter(filter);
  }

  selectedFile(file: ProjectFileGet): void {
    this.projectFileService.setSelectedProjFile(file);
  }
}
