import { NgIf } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

import { TaxRateGet, TaxRatesClient } from '../../../../generated-client/generated-client';
import { ModalConfirmComponent } from '../../../../shared/components/modal-confirm/modal-confirm.component';
import { ConfirmationType } from '../../../../shared/models/dialog-config.model';
import { AvaNotificationsService } from '../../../../shared/services/ava-notifications.service';
import { ModalService } from '../../../../shared/services/modal.service';
import { FlexLayoutDirective } from '../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-master-data-tax-rates-add-modal',
  templateUrl: './master-data-tax-rates-add-modal.component.html',
  styleUrls: ['./master-data-tax-rates-add-modal.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    FormsModule,
    MatDialogContent,
    ReactiveFormsModule,
    FlexLayoutDirective,
    MatFormField,
    MatLabel,
    MatInput,
    MatButton,
    NgIf
  ]
})
export class MasterDataTaxRatesAddModalComponent implements OnInit {
  showDelete: boolean;
  changedHtmlTemplate: boolean;
  strState: string;

  templatesForm: FormGroup;
  defaultData: TaxRateGet = {
    id: '',
    country: '',
    countryCode: '',
    rate: 0
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TaxRateGet,
    @Optional() private matDialogRef: MatDialogRef<MasterDataTaxRatesAddModalComponent>,
    private fb: FormBuilder,
    private taxRatesClient: TaxRatesClient,
    private avaNotificationsService: AvaNotificationsService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.showDelete = !!this.data?.id;
    if (this.showDelete) {
      this.templatesForm = this.fb.group({ ...this.data });
    } else {
      this.templatesForm = this.fb.group({ ...this.defaultData });
    }

    if (this.data) {
      this.templatesForm.patchValue({
        rate: Math.round(this.data.rate * 10000) / 100
      });
    }

    this.strState = JSON.stringify(this.templatesForm.value);
  }

  saveTaxRate(): void {
    const obj: TaxRateGet = structuredClone(this.templatesForm.value);
    obj.rate = obj.rate / 100;

    if (obj.id) {
      this.taxRatesClient.editTaxRate(obj.id, obj).subscribe({
        next: () => {
          this.matDialogRef.close(true);
          this.avaNotificationsService.success('Der Mehrwertsteuersatz wurde gespeichert.');
        },
        error: () => {
          this.avaNotificationsService.error('Der Mehrwertsteuersatz konnte nicht gespeichert werden.');
        }
      });
    } else {
      this.taxRatesClient.createTaxRate(obj).subscribe({
        next: () => {
          this.matDialogRef.close(true);
          this.avaNotificationsService.success('Der Mehrwertsteuersatz wurde gespeichert.');
        },
        error: () => {
          this.avaNotificationsService.error('Der Mehrwertsteuersatz konnte nicht gespeichert werden.');
        }
      });
    }
  }

  cancel(): void {
    this.matDialogRef.close(false);
  }

  isChanges(): boolean {
    return JSON.stringify(this.templatesForm.value) !== this.strState;
  }

  deleteTaxRate(): void {
    this.modalService
      .openModal(ModalConfirmComponent, { dialogType: ConfirmationType.Delete, data: ['Löschen', 'Mehrwertsteuersatz', this.data.country] })
      .afterClosed()
      .subscribe((isConfirm) => {
        if (isConfirm) {
          this.taxRatesClient.deleteTaxRate(this.data.id).subscribe({
            next: () => this.matDialogRef.close(true),
            error: () => this.avaNotificationsService.error('Fehler beim Löschen des Mehrwertsteuersatzes.')
          });
        }
      });
  }
}
