import { NgIf, AsyncPipe, DecimalPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  inject
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatRipple } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger, MatMenu, MatMenuContent, MatMenuItem } from '@angular/material/menu';
import { MatSort, MatSortable, Sort, MatSortHeader } from '@angular/material/sort';
import {
  MatTableDataSource,
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';

import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { ShowingColumnsModalComponent } from '@shared/components/showing-columns-modal/showing-columns-modal.component';
import { ConfirmationType } from '@shared/models/dialog-config.model';
import { ContextMenuSettingsService } from '@shared/services/context-menu-settings.service';
import { ModalService } from '@shared/services/modal.service';
import { ShowingColumnsService } from '@shared/services/showing-columns.service';

import { CalculationFixedPriceService } from '@project/services/calculation-fixed-price.service';

import { PositionDto } from 'app/generated-client/generated-client';

import { RowSelectionDirective } from '../../../../../../shared/directives/row-selection.directive';
import { PositionTextPipe } from '../../../../../../shared/pipes/ui-data-display/position-text.pipe';
import { ShowPositionAbbreviationPipe } from '../../../../../tree/pipes/show-position-abbreviation.pipe';

import { ResizableDirective } from '../invoice/directives/resizable.directive';

@Component({
  selector: 'pa-positions-table',
  templateUrl: './positions-table.component.html',
  styleUrls: ['./positions-table.component.scss'],
  standalone: true,
  imports: [
    MatTable,
    MatSort,
    RowSelectionDirective,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    ResizableDirective,
    MatCellDef,
    MatCell,
    MatRipple,
    MatSortHeader,
    NgIf,
    MatIcon,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatMenu,
    MatMenuContent,
    MatMenuItem,
    MatButton,
    MatMenuTrigger,
    AsyncPipe,
    DecimalPipe,
    PositionTextPipe,
    ShowPositionAbbreviationPipe
  ]
})
export class PositionsTableComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(MatSort, { static: true }) private sort: MatSort;
  @ViewChildren(MatMenuTrigger) contextMenu: QueryList<MatMenuTrigger>;

  @Input() limitHeight = 0;
  @Input() filter: string;
  @Input() activeId: string;
  @Input() isLongText: boolean;
  @Input() isSearchAll: boolean;
  @Input() dataSource: PositionDto[];
  @Input() allPositions: PositionDto[];
  @Input() multiSelectPosition: string[] = [];
  @Output() selectRow = new EventEmitter<PositionDto>();
  @Output() selectRowMulti = new EventEmitter<PositionDto>();
  @Output() copyRow = new EventEmitter<PositionDto>();

  canOneClick: boolean;
  isSelectionActive = false;
  firstSelectedIndex: number;

  contextMenuPosition = { x: '0px', y: '0px' };

  filteredDataSource: MatTableDataSource<PositionDto> = new MatTableDataSource([]);

  displayedColumns = [];
  defaultDisplayedColumns = [
    'index',
    'positionType',
    'itemNumber',
    'shortText',
    'quantity',
    'unitTag',
    'unitPrice',
    'totalPrice',
    'fixedPrice'
  ];

  private filterSource = new Subject<boolean>();
  private $filter = this.filterSource.asObservable();
  private $destroy = new Subject<boolean>();

  contextMenuSettingsService = inject(ContextMenuSettingsService);
  modalService = inject(ModalService);
  showingColumnsService = inject(ShowingColumnsService);
  listOfItemsWithFixedPrice$ = inject(CalculationFixedPriceService).listOfItemsWithFixedPrice;

  constructor() {}

  ngOnInit(): void {
    this.$filter.pipe(debounceTime(500), takeUntil(this.$destroy)).subscribe(() => this.filterData());
    this.filtering();
    this.sort.sort({ id: 'itemNumber', start: 'asc' } as MatSortable);
    this.filteredDataSource.sort = this.sort;
    setTimeout(() => {
      this.onSort({ active: this.sort.active, direction: this.sort.direction });
    }, 600);
    this.showingColumnsService.settingsLoaded.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.displayedColumns = this.showingColumnsService.getFilteredColumns('CopyCalculation', this.defaultDisplayedColumns);
    });
  }

  ngOnChanges(): void {
    this.filtering();
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  filtering(): void {
    this.filterSource.next(true);
  }

  filterData(): void {
    if (!this.filter) {
      this.filteredDataSource.data = this.dataSource;
    } else {
      this.filteredDataSource.data = (this.isSearchAll ? this.allPositions : this.dataSource).filter((item: PositionDto) => {
        let generalFilter = (item?.itemNumber.stringRepresentation + item.shortText).toLowerCase().indexOf(this.filter.toLowerCase()) > -1;
        if (this.isLongText) {
          generalFilter = generalFilter || item?.htmlLongText?.toLowerCase().indexOf(this.filter.toLowerCase()) > -1;
        }
        return generalFilter;
      });
    }
    if (this.filteredDataSource?.data?.length === 1) {
      setTimeout(() => {
        this.selectPosition({} as MouseEvent, this.filteredDataSource.data[0]);
      }, 200);
    }
  }

  selectPosition(event: MouseEvent, row: PositionDto): void {
    if (event.shiftKey) {
      return;
    }
    this.canOneClick = true;
    setTimeout(() => {
      if (this.canOneClick) {
        if (event.ctrlKey || this.multiSelectPosition?.length) {
          this.selectRowMulti.emit(row);
        } else {
          this.selectRow.emit(row);
        }
      }
    }, 200);
  }

  copyPosition(row: PositionDto): void {
    this.canOneClick = false;
    this.copyRow.emit(row);
  }

  onSort(sort: Sort): void {
    if (sort.direction && sort.active === 'itemNumber') {
      const isAsc = sort.direction === 'asc';
      this.filteredDataSource.data = this.filteredDataSource.data.sort((a, b) => {
        return (a?.itemNumber?.stringRepresentation < b?.itemNumber?.stringRepresentation ? -1 : 1) * (isAsc ? 1 : -1);
      });
    }
  }

  showHeaderContextMenu(event: MouseEvent): void {
    this.contextMenuSettingsService.setDefaultSettings(event, null, this.contextMenuPosition, this.contextMenu.last);
  }

  editListColumns(): void {
    this.modalService
      .openModal(ShowingColumnsModalComponent, {
        dialogType: ConfirmationType.General,
        data: {
          component: 'CopyCalculation',
          columns: this.defaultDisplayedColumns
        },
        disableClose: true,
        autoFocus: false
      })
      .afterClosed()
      .subscribe(() => {});
  }

  onRowSelected(event: { firstSelectedIndex: number | null; lastSelectedIndex: number | null }): void {
    let selectedRows: PositionDto[] = [];
    if (event.lastSelectedIndex === null) {
      selectedRows = [this.filteredDataSource.data[event.firstSelectedIndex]];
    } else {
      selectedRows = [...this.filteredDataSource.data.slice(event.firstSelectedIndex, event.lastSelectedIndex + 1)];
    }
    selectedRows.forEach((row) => {
      this.selectRowMulti.emit(row);
    });
  }

  handleSelectionProcess(e: { firstSelectedIndex: number | null; isSelectionActive: boolean }) {
    this.firstSelectedIndex = e.firstSelectedIndex;
    this.isSelectionActive = e.isSelectionActive;
  }
}
