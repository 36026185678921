import { Injectable, OnDestroy } from '@angular/core';

import { Observable, Subject, combineLatest, filter, first, map, of, takeUntil } from 'rxjs';

import { ServiceSpecificationsHasChangeService } from 'app/shared/services/messengers/service-specifications-has-change.service';
import { PositionHasCalculationService } from 'app/shared/services/position-has-calculation.service';

import {
  AvaProjectContentEditClient,
  AvaProjectContentEditCreateType,
  AvaProjectContentEditOperation,
  IElementDto,
  ItemNumberSchemaDto,
  PositionDto,
  ProjectDto,
  ServiceSpecificationGroupDto
} from '../../generated-client/generated-client';

import { SelectedSpecificationElementMessengerService } from './messengers/selected-specification-element-messenger.service';
import { SelectedSpecificationMessengerService } from './messengers/selected-specification-messenger.service';
import { AvaProjectDiffApplier } from './signalr/ava-project-diff-applier';

@Injectable({
  providedIn: 'root'
})
/**
 * This service is used to manually select item numbers via strings, e.g.
 * when the user enters them in an input field
 */
export class ItemNumberSelectionService implements OnDestroy {
  // TODO RENAME THIS CLASS
  private projectId: string;
  private avaProjectId: string;
  private itemNumberSchema: ItemNumberSchemaDto;
  private $destroyed = new Subject<void>();

  constructor(
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private avaProjectContentEditClient: AvaProjectContentEditClient,
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private avaProjectDiffApplier: AvaProjectDiffApplier,
    private positionHasCalculationService: PositionHasCalculationService,
    private serviceSpecificationsHasChangeService: ServiceSpecificationsHasChangeService
  ) {
    selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        takeUntil(this.$destroyed),
        filter((s) => !!s)
      )
      .subscribe((s) => {
        // TODO ALSO REACT WHEN CONTAINERS ARE REMOVED OR ADDED, VIA SIGNALR AVA DIFF UPDATE MECHANISM
        this.setUpContainers(s.project);
        this.projectId = s.parentProjectId;
        this.avaProjectId = s.avaProjectId;
        this.itemNumberSchema = s.project.projectInformation?.itemNumberSchema;
      });
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  private setUpContainers(avaProject: ProjectDto): void {
    this.containers = [];

    this.handleContainers({
      self: avaProject.serviceSpecifications[0],
      itemNumber: {
        stringRepresentation: ''
      }
    });
  }

  private handleContainers(container: {
    itemNumber: { stringRepresentation: string };
    self: { elements?: IElementDto[]; id?: string };
  }): void {
    this.containers.push({ itemNumber: container.itemNumber?.stringRepresentation, container: container.self });

    if (container.self.elements) {
      container.self.elements.forEach((e) => {
        if (e.elementTypeDiscriminator === 'ServiceSpecificationGroupDto') {
          const group = e as ServiceSpecificationGroupDto;
          this.handleContainers({
            itemNumber: {
              stringRepresentation: group.itemNumber?.stringRepresentation || ''
            },
            self: group
          });
        }
      });
    }
  }

  private containers: { itemNumber: string; container: { elements?: IElementDto[]; id?: string } }[] = [];

  /** It returns 'true' if the rename was performed, 'false' if it was cancelled */
  goToElementByItemNumber(newItemNumber: string): Observable<boolean> {
    if (this.itemNumberSchema && newItemNumber) {
      const splitItemNumber = newItemNumber.split('.');

      let constructedItemNumber = '';
      let hasFoundError = false;
      for (let i = 0; i < splitItemNumber.length; i++) {
        if (this.itemNumberSchema.tiers?.length >= i && splitItemNumber[i].length > 0) {
          const length = this.itemNumberSchema.tiers[i].length;
          constructedItemNumber += splitItemNumber[i].padStart(length, '0') + '.';
        } else {
          hasFoundError = false;
        }
      }

      if (!hasFoundError) {
        newItemNumber = constructedItemNumber;
      }
    }

    const existingElement = this.containers
      .flatMap((c) => c.container.elements)
      .find((e) => (e as any).itemNumber?.stringRepresentation?.replace(/\.$/, '') === newItemNumber?.replace(/\.$/, ''));
    if (existingElement) {
      this.selectedSpecificationElementMessengerService.trySelectElementById(existingElement?.id);
      return of(false);
    }

    const affectedElementId = new Subject<string>();
    const newlyCreatedElement = new Subject<IElementDto>();
    const returnObservable = new Subject<boolean>();

    combineLatest([affectedElementId, newlyCreatedElement])
      .pipe(takeUntil(this.$destroyed))
      .subscribe(([elementId, element]: [string, IElementDto]) => {
        if (!elementId || !element) {
          return;
        }

        this.getElementByIdFromCurrentServiceSpecification(elementId).subscribe((newElement) => {
          this.positionHasCalculationService.updateList();
          this.selectedSpecificationElementMessengerService.trySelectElementById(newElement?.id);

          this.selectedSpecificationElementMessengerService.selectedElement
            .pipe(
              filter((e) => !!e && e.id !== elementId),
              first()
            )
            .subscribe(() => {
              this.getElementByIdFromCurrentServiceSpecification(elementId).subscribe((elementAfterChange) => {
                let shouldDeleteAgain = false;
                if (elementAfterChange && elementAfterChange.elementType === 'PositionDto') {
                  const position = elementAfterChange as PositionDto;
                  shouldDeleteAgain = !position.shortText && !position.quantity && !position.unitTag;
                } else if (elementAfterChange && elementAfterChange.elementType === 'ServiceSpecificationGroupDto') {
                  const group = elementAfterChange as ServiceSpecificationGroupDto;
                  shouldDeleteAgain = !group.shortText && (!group.elements || group.elements.length === 0);
                } else {
                  // TODO EITHER SHOW AN ERROR OR DO SOMETHING ELSE
                }

                if (shouldDeleteAgain) {
                  this.avaProjectContentEditClient
                    .editAvaProjectContent(this.projectId, this.avaProjectId, {
                      operation: AvaProjectContentEditOperation.Delete,
                      deleteOperation: {
                        elementId: elementAfterChange.id
                      }
                    })
                    .subscribe(() => {
                      /* Empty subscription so the request is sent */
                    });
                }
              });
            });
        });

        affectedElementId.complete();
        newlyCreatedElement.complete();
      });

    this.avaProjectDiffApplier.avaProjectDiffApplied
      .pipe(
        map((appliedAvaProjectDiff) =>
          appliedAvaProjectDiff.avaProjectDiff.newElements.find(
            (e) => (e.element as any).itemNumber?.stringRepresentation?.replace(/\.$/, '') === newItemNumber?.replace(/\.$/, '')
          )
        ),
        filter((e) => !!e),
        first()
      )
      .subscribe((newElement) => {
        newlyCreatedElement.next(newElement.element);
      });

    this.serviceSpecificationsHasChangeService.checkServiceSpecificationHasBeenEdited().subscribe((shouldEdit) => {
      returnObservable.next(shouldEdit);
      if (shouldEdit) {
        this.avaProjectContentEditClient
          .editAvaProjectContent(this.projectId, this.avaProjectId, {
            operation: AvaProjectContentEditOperation.Create,
            createOperation: {
              elementType: AvaProjectContentEditCreateType.Position,
              itemNumber: newItemNumber
            }
          })
          .subscribe((r) => {
            // TODO DO SOMETHING FUNKY
            // Here, we should wait until we got both the update and also a new AVA Diff, so the element exists and is also
            // in the tree. Then we select it. If the user navigates away from the element without having made any changes,
            // the element will be deleted.
            // Maybe we should hook into the edit content client to see if the user does make any changes, or just
            // check after switching if a short text, a price, a quantity or an unit tag was edited in the element, otherwise we'll
            // delete it
            // TODO SHOULD ALSO CHECK IF ELEMENT HAS ALREADY CHILDREN, LIKE ADDING A NEW GROUP AND THEN A POSITION BELOW IT
            // TODO THIS IS NOW DONE WITH THE COMBINELATEST SUBSCRIPTION ABOVE, BUT ITS NOT REALLY COMPLETE
            affectedElementId.next(r.affectedElementId);
          });
      }
    });

    return returnObservable;
  }

  private getElementByIdFromCurrentServiceSpecification(id: string): Observable<IElementDto> {
    return this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(
      filter((s) => !!s),
      first(),
      map((avaProject) => {
        const flatElements = this.getFlatElements(avaProject.project.serviceSpecifications[0]);
        const newElement = flatElements.find((e) => e.id === id);
        return newElement;
      })
    );
  }

  private getFlatElements(container: { elements?: IElementDto[] }): IElementDto[] {
    const flatElements: IElementDto[] = [];

    if (container.elements) {
      container.elements.forEach((e) => {
        if (e.elementTypeDiscriminator === 'ServiceSpecificationGroupDto') {
          const group = e as ServiceSpecificationGroupDto;
          flatElements.push(group);
          flatElements.push(...this.getFlatElements(group));
        } else {
          flatElements.push(e);
        }
      });
    }

    return flatElements;
  }
}
