<h2 mat-dialog-title>{{ data.sourceGroup ? 'Gruppen' : 'Projekt' }}kalkulation Übernehmen</h2>
<div mat-dialog-content>
  <div>
    <mat-form-field *ngIf="avaProjects">
      <mat-label>LV Auswählen</mat-label>
      <mat-select [(ngModel)]="selectedAvaProject" [disabled]="requestEnRoute || notChooseAvaProject">
        <mat-option *ngFor="let avaProject of avaProjects" [value]="avaProject">
          {{ avaProject.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <button mat-raised-button (click)="check()" [disabled]="requestEnRoute || !selectedAvaProject">Prüfen</button>
  </div>
  <div class="wrapper">
    <div class="wrapper-list" *ngIf="mappedPositions?.length > 0">
      <h3>
        <strong> Zugeordnete Positionen </strong>
      </h3>
      <div *ngFor="let mapping of mappedPositions; let index = index">
        <div fxLayoutGap="12px">
          <span class="index">{{ index + 1 }}</span>
          <span class="number">{{ mapping.targetPosition.itemNumber?.stringRepresentation }}</span>
          <span>{{ mapping.targetPosition | positionText }}</span>
        </div>
        <div>
          <mat-form-field appearance="fill" class="position-select">
            <mat-label>Quellposition</mat-label>
            <mat-select name="sourcePos" [(ngModel)]="mapping.sourcePosition">
              <mat-option [value]="null">Ignorieren</mat-option>
              <mat-option *ngFor="let sourcePosition of sourcePositions" [value]="sourcePosition"
                >{{ sourcePosition.itemNumber.stringRepresentation }}
                -
                {{ sourcePosition | positionText }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="wrapper-list" *ngIf="unmappedTargetPositions?.length > 0">
      <h3>
        <strong> Nicht zugeordnete Positionen </strong>
      </h3>
      <p>Ignorierte Positionen werden nicht übernommen.</p>
      <div *ngFor="let targetPosition of unmappedTargetPositions; let index = index">
        <div fxLayoutGap="12px">
          <span class="index">{{ index + 1 }}</span>
          <span class="mark" [class.change]="targetPosition.ignore">{{ targetPosition.ignore ? 'Ignorieren' : 'Auswahl' }}</span>
          <span class="number">{{ targetPosition.targetPosition.itemNumber?.stringRepresentation }}</span>
          <span>{{ targetPosition.targetPosition | positionText }}</span>
        </div>
        <div>
          <mat-form-field appearance="fill" class="position-select">
            <mat-label>Quellposition</mat-label>
            <mat-select
              name="sourcePos"
              [(ngModel)]="targetPosition.sourcePosition"
              (ngModelChange)="handlePositionAssignmentChangeEvent(targetPosition.targetPosition.id, $event)"
            >
              <mat-option [value]="null">Ignorieren</mat-option>
              <mat-option *ngFor="let sourcePosition of sourcePositions" [value]="sourcePosition"
                >{{ sourcePosition.itemNumber.stringRepresentation }}
                -
                {{ sourcePosition | positionText }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <p color="warn"><strong>Achtung</strong>, bei der Übernahme werden alle Kalkulationen im Zielprojekt überschrieben.</p>
</div>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close color="warn">Abbrechen</button>
  <button mat-raised-button color="primary" type="button" (click)="save()" [disabled]="!canImportCalculation || requestEnRoute">
    Kalkulationen Übernehmen
  </button>
</mat-dialog-actions>
