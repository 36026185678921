import { ComponentType } from '@angular/cdk/portal';
import { AsyncPipe, DatePipe, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';

import { take } from 'rxjs/operators';

import { SpecificProjectProductsTableComponent } from '@serv-spec/components/project-products/components/specific-project-products-table/specific-project-products-table.component';

import { TableWrapperComponent } from '@shared/components/table-wrapper/table-wrapper.component';
import { UpperPaginatorComponent } from '@shared/components/upper-paginator/upper-paginator.component';
import { ConfirmationType } from '@shared/models/dialog-config.model';
import { ModalService } from '@shared/services/modal.service';

import { CatalogComponent } from 'app/areas/catalogs/components/catalog/catalog.component';
import { NewCatalogComponent } from 'app/areas/catalogs/components/new-catalog/new-catalog.component';
import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';
import { CatalogsService } from 'app/shared/services/lightquery/catalogs.service';

import { MasterDataProductCataloguesClient, MasterProductCatalogueGet } from '../../../../generated-client/generated-client';
import { AvaNotificationsService } from '../../../../shared/services/ava-notifications.service';

@Component({
  selector: 'pa-catalogs',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    FormsModule,
    UpperPaginatorComponent,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatButtonModule,
    AsyncPipe,
    DatePipe,
    NgSwitch,
    NgSwitchCase,
    MatTooltipModule,
    TableWrapperComponent,
    FlexLayoutDirective
  ],
  templateUrl: './catalogs.component.html',
  styleUrls: ['./catalogs.component.scss']
})
export class CatalogsComponent implements OnInit, OnDestroy {
  @Input() noSeparate: boolean;
  dialogRef: MatDialogRef<CatalogComponent>;
  showArchivedCatalogs = false;
  filterText = '';
  catalogsService = inject(CatalogsService);
  private modalService = inject(ModalService);
  private avaNotificationsService = inject(AvaNotificationsService);
  private elementRef = inject(ElementRef);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  private masterDataProductCataloguesClient = inject(MasterDataProductCataloguesClient);

  displayedColumns = ['name', 'createdAtUtc', 'isArchived', 'contactName'];
  isSpecificProjectProductsTableComponent: boolean;
  constructor() {}

  ngOnInit(): void {
    this.activatedRoute.data.pipe(take(1)).subscribe((routeData) => {
      if (Object.keys(routeData).length > 0) {
        this.isSpecificProjectProductsTableComponent = routeData?.isSpecificProjectProductsTableComponent;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
      this.modalService.setDialogRef(null);
    }
  }

  changeFilter(): void {
    this.catalogsService.onFilter(this.filterText);
  }

  changeShowingArchivedCatalogs(): void {
    this.catalogsService.setQueryParameter('includeArchived', this.showArchivedCatalogs.toString());
  }

  selectElement(catalog: MasterProductCatalogueGet): void {
    const componentRect = this.elementRef.nativeElement.getBoundingClientRect();
    const openedComponent: ComponentType<any> = this.isSpecificProjectProductsTableComponent
      ? SpecificProjectProductsTableComponent
      : CatalogComponent;
    this.dialogRef = this.modalService.openModal(openedComponent, {
      dialogType: ConfirmationType.General,
      width: `${componentRect.width}px`,
      maxWidth: '100%',
      position: {
        top: `${componentRect.top}px`,
        left: `${componentRect.left}px`
      },
      autoFocus: false,
      data: { noSeparate: this.noSeparate, maxHeight: componentRect.height, catalogue: catalog },
      hasBackdrop: this.noSeparate ? false : true
    });
    this.modalService.setDialogRef(this.dialogRef);
    this.dialogRef.afterClosed().subscribe(() => {
      this.modalService.setDialogRef(null);
    });
  }

  createCatalog(): void {
    this.modalService
      .openModal(NewCatalogComponent, { dialogType: ConfirmationType.General, autoFocus: false, width: '400px', disableClose: true })
      .afterClosed()
      .subscribe((newCatalog: any) => {
        if (newCatalog) {
          this.masterDataProductCataloguesClient
            .createNewMasterProductCatalogue(newCatalog.catalogName, newCatalog.contactId, {
              data: newCatalog.catalogData,
              fileName: newCatalog.catalogData.name
            })
            .subscribe({
              next: (r) => {
                this.catalogsService.forceRefresh();
                this.selectElement(r);
                this.avaNotificationsService.success('Katalog importiert.');
              },
              error: () => {
                this.avaNotificationsService.error('Fehler beim Importieren des Katalogs.');
              }
            });
        }
      });
  }
}
