import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgIf, DecimalPipe, PercentPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialogContent } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSort, Sort, MatSortHeader } from '@angular/material/sort';
import {
  MatTableDataSource,
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  AvaProjectReportsClient,
  ElementSelection,
  MainPositionGet,
  PositionDto,
  ProjectDto,
  ReportGetOfMainPositionsReport,
  ReportsClient
} from 'app/generated-client/generated-client';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { PrintViewMessengerService } from 'app/shared/services/electron/print-view-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';

import { SafeHtmlPipe } from '../../../../../../shared/pipes/safe-html.pipe';
import { PositionTextPipe } from '../../../../../../shared/pipes/ui-data-display/position-text.pipe';
import { ProjectCurrencyPipe } from '../../../../../../shared/pipes/ui-data-display/project-currency.pipe';
import { ShortPositionAndPriceTypePipe } from '../../../../../../shared/pipes/ui-data-display/short-position-and-price-type.pipe';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

import { SelectingElementModalComponent } from '../selecting-element-modal/selecting-element-modal.component';

@Component({
  selector: 'pa-main-positions-table',
  templateUrl: './main-positions-table.component.html',
  styleUrls: ['./main-positions-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
  standalone: true,
  imports: [
    NgIf,
    MatDialogContent,
    FlexLayoutDirective,
    MatButton,
    MatCheckbox,
    FormsModule,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatSortHeader,
    MatIcon,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatProgressSpinner,
    DecimalPipe,
    PercentPipe,
    SafeHtmlPipe,
    ProjectCurrencyPipe,
    ShortPositionAndPriceTypePipe,
    PositionTextPipe
  ]
})
export class MainPositionsTableComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;

  private $destroy: Subject<boolean> = new Subject<boolean>();
  dataSource = new MatTableDataSource<MainPositionGet>();
  columnsToDisplay = [
    'positionType',
    'itemNumber',
    'shortText',
    'quantity',
    'unitTag',
    'unitPrice',
    'totalPrice',
    'longText',
    'percentage'
  ];
  expandedElement: PositionDto | null;
  serviceSpecification: { avaProjectId: string; project: ProjectDto; parentProjectId: string };
  finishedLoading = false;
  generateLongTexts = false;
  showingPdf = false;
  includeHeaderOnlyOnFirstPage = false;

  constructor(
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private avaProjectReportsClient: AvaProjectReportsClient,
    private reportsClient: ReportsClient,
    private notificationsService: AvaNotificationsService,
    private printViewMessengerService: PrintViewMessengerService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(takeUntil(this.$destroy)).subscribe((s) => {
      if (s?.avaProjectId && s?.parentProjectId) {
        this.serviceSpecification = s;
        this.avaProjectReportsClient.getMainPositionsListOrdered(s.parentProjectId, s.avaProjectId).subscribe({
          next: (positionsList) => {
            let deltaFullPercent = 1;
            positionsList.positions.forEach((item) => {
              item.percentageOfTotalPrice = Math.floor(item.percentageOfTotalPrice * 10000) / 10000;
              deltaFullPercent -= item.percentageOfTotalPrice;
            });
            deltaFullPercent = deltaFullPercent > 0 ? deltaFullPercent : 0;
            this.dataSource.data = positionsList.positions.map((item, index) =>
              index ? item : { ...item, percentageOfTotalPrice: item.percentageOfTotalPrice + deltaFullPercent }
            );
            this.finishedLoading = true;
          },
          error: () => {
            this.finishedLoading = true;
          }
        });
      }
    });

    this.printViewMessengerService.printViewClosed.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.showingPdf = false;
      this.finishedLoading = true;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  generatePdfAndShowPrintView(elementSelection?: ElementSelection): void {
    this.finishedLoading = false;
    this.reportsClient
      .getMainPositionsTable(this.serviceSpecification.avaProjectId, true, this.generateLongTexts, this.includeHeaderOnlyOnFirstPage, {
        elementSelection: elementSelection
      })
      .subscribe({
        next: (report: ReportGetOfMainPositionsReport | null) => {
          this.printViewMessengerService.showPdfPreview(
            report.htmlReport,
            report.reportData.projectName,
            report.isLandscape,
            report.pdfReportBase64,
            this.includeHeaderOnlyOnFirstPage
          );
          this.printViewMessengerService.waitForPrintViewReady().subscribe(() => {
            this.finishedLoading = true;
            this.showingPdf = true;
          });
        },
        error: () => {
          this.finishedLoading = true;
          this.notificationsService.error('Fehler beim Erstellen der Druckvorschau.');
        }
      });
  }

  sortColumn(sort: Sort): void {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a: MainPositionGet, b: MainPositionGet) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'percentage':
          return this.compare(a.percentageOfTotalPrice, b.percentageOfTotalPrice, isAsc);
        case 'totalPrice':
          return this.compare(a.position.totalPrice, b.position.totalPrice, isAsc);
        case 'quantity':
          return this.compare(a.position.quantity, b.position.quantity, isAsc);
        case 'unitPrice':
          return this.compare(a.position.unitPrice, b.position.unitPrice, isAsc);
        case 'shortText':
          return this.compare(a.position.shortText, b.position.shortText, isAsc);
        case 'itemNumber':
          return this.compare(a.position.itemNumber.stringRepresentation, b.position.itemNumber.stringRepresentation, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  openSelectionElementWindow(): void {
    this.modalService
      .openModal(SelectingElementModalComponent, { dialogType: ConfirmationType.General, autoFocus: false, restoreFocus: false })
      .afterClosed()
      .subscribe((e: ElementSelection) => {
        if (e) {
          this.generatePdfAndShowPrintView(e);
        }
      });
  }
}
