import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BimViewerCanvasService {
  private changedSizeSource = new Subject<boolean>();
  changedSize = this.changedSizeSource.asObservable();

  constructor() {}

  setChangeSizeCanvas(): void {
    this.changedSizeSource.next(true);
  }
}
