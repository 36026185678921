import { Pipe, PipeTransform } from '@angular/core';

import { PriceInquiryStatus } from '../../../generated-client/generated-client';

@Pipe({
  name: 'priceInquiryStatus',
  standalone: true
})
export class PriceInquiryStatusPipe implements PipeTransform {
  transform(value: PriceInquiryStatus): string {
    switch (value) {
      case PriceInquiryStatus.Accepted:
        return 'Angenommen';

      case PriceInquiryStatus.Created:
        return 'Erstellt';

      case PriceInquiryStatus.Received:
        return 'Erhalten';

      case PriceInquiryStatus.RejectedByBidder:
        return 'Abgelehnt (Vom Bieter)';

      case PriceInquiryStatus.RejectedByBuyer:
        return 'Abgelehnt (Vom Käufer)';

      case PriceInquiryStatus.Sent:
        return 'Verschickt';

      case PriceInquiryStatus.AcceptedBefore:
        return 'Angenommen, wieder entfernt';
    }

    return null;
  }
}
