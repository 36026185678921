import { EMPTY, ReplaySubject, Subject, switchMap } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { ProjectSettings, TreeViewDisplayType } from 'app/generated-client/generated-client';
import { filter, first, takeUntil } from 'rxjs/operators';

import { SelectedProjectMessengerService } from './messengers/selected-project-messenger.service';
import { UserSettingsService } from './user-settings.service';

@Injectable({
  providedIn: 'root'
})
export class GroupViewService implements OnDestroy {
  private projectGroupViewSource = new ReplaySubject<TreeViewDisplayType>(1);
  projectGroupView = this.projectGroupViewSource.asObservable();

  private $destroy = new Subject<boolean>();

  isCheckSelectedProject$ = new Subject<boolean>();

  constructor(private userSettingsService: UserSettingsService, private selectedProjectMessengerService: SelectedProjectMessengerService) {
    this.isCheckSelectedProject$
      .pipe(
        switchMap(v => {
          if (!v) {
            return this.selectedProjectMessengerService.selectedProject;
          }
          return EMPTY;
        })
      )
      .pipe(
        filter(p => !!p),
        takeUntil(this.$destroy)
      )
      .subscribe(() => {
        this.changedProject();
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  setGroupView(groupView: TreeViewDisplayType, notSave = false): void {
    this.projectGroupViewSource.next(groupView);
    if (!notSave) {
      this.userSettingsService
        .getProjectSettings()
        .pipe(
          filter(e => e != null),
          first()
        )
        .subscribe((e: ProjectSettings) => {
          e.treeViewDisplayType = groupView;
          this.userSettingsService.setProjectSettings(e);
        });
    }
  }

  private changedProject(): void {
    this.userSettingsService
      .getProjectSettings()
      .pipe(first())
      .subscribe(e => {
        this.setGroupView(e?.treeViewDisplayType ?? TreeViewDisplayType.Tree, true);
      });
  }
}
