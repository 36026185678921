import { Component, OnInit, Inject, OnDestroy, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { MatTabGroup, MatTab, MatTabContent } from '@angular/material/tabs';

import { take } from 'rxjs/operators';

import { FlatElementsService } from 'app/areas/tree/services/flat-elements.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

import { AvaProjectGet } from '../../../../../../generated-client/generated-client';
import { AvaProjectAvaFileExportsService } from '../../../../../../shared/services/lightquery/ava-project-ava-file-exports.service';

import { AvaProjectsClient } from './../../../../../../generated-client/generated-client';

import { CreateServiceSpecificationExportComponent } from '../create-service-specification-export/create-service-specification-export.component';
import { CreateServiceSpecificationPdfComponent } from '../create-service-specification-pdf/create-service-specification-pdf.component';
import { ServiceSpecificationExportsListComponent } from '../service-specification-exports-list/service-specification-exports-list.component';

@Component({
  selector: 'pa-service-specification-export-modal',
  templateUrl: './service-specification-export-modal.component.html',
  styleUrls: ['./service-specification-export-modal.component.scss'],
  standalone: true,
  imports: [
    MatDialogContent,
    MatTabGroup,
    MatTab,
    MatTabContent,
    CreateServiceSpecificationExportComponent,
    CreateServiceSpecificationPdfComponent,
    ServiceSpecificationExportsListComponent
  ]
})
export class ServiceSpecificationExportModalComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public avaProject: AvaProjectGet,
    private avaProjectAvaFileExportsService: AvaProjectAvaFileExportsService,
    @Optional() private matDialogRef: MatDialogRef<ServiceSpecificationExportModalComponent>,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private avaProjectsClient: AvaProjectsClient,
    private flatElementsService: FlatElementsService
  ) {}

  ngOnInit(): void {
    if (this.avaProject && this.avaProject.projectId) {
      this.avaProjectAvaFileExportsService.setAvaProjectId(this.avaProject?.id);
      // Since the export dialog is most likely running before an actual AvaProject has been selected,
      // we need to make sure that the correct AvaProject is selected so that the element selection tree
      // can be correctly displayed.
      this.ensureAvaProjectContentIsCorrectlySetUp();
    }
  }

  closeModal(exportSuccessful: boolean): void {
    if (exportSuccessful) {
      this.matDialogRef.close();
    }
  }

  ngOnDestroy(): void {
    this.selectedSpecificationMessengerService.clearSelectedServiceSpecification();
  }

  private ensureAvaProjectContentIsCorrectlySetUp(): void {
    this.avaProjectsClient
      .getAvaProjectContentById(this.avaProject.projectId, this.avaProject.id)
      .pipe(take(1))
      .subscribe((avaProject) => {
        this.selectedSpecificationMessengerService.setSelectedServiceSpecification(
          this.avaProject.id,
          avaProject,
          this.avaProject.projectId,
          this.avaProject
        );
        this.flatElementsService.setElementsDto(avaProject.serviceSpecifications[0]);
      });
  }
}
