import { Injectable, OnDestroy, Pipe, PipeTransform } from '@angular/core';

import { Observable, Subject, delay, map, merge, of, takeUntil } from 'rxjs';

import { IElementDto } from '../../generated-client/generated-client';

import { UiPositionType } from '../models/ui-position-type';
import { PositionTypeConverterService } from '../services/converters/position-type-converter.service';
import { AvaProjectDiffApplier } from '../services/signalr/ava-project-diff-applier';

@Pipe({
  name: 'singlePositionType',
  standalone: true
})
@Injectable({
  providedIn: 'root'
})
export class SinglePositionTypePipe implements PipeTransform, OnDestroy {
  private $destroy = new Subject<void>();

  constructor(
    private positionTypeConverterService: PositionTypeConverterService,
    private avaProjectDiffApplier: AvaProjectDiffApplier
  ) {}

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  transform(value: IElementDto): Observable<UiPositionType> {
    const initialPositionType = this.positionTypeConverterService.convertIElementToPositionType(value);

    return merge(
      of(initialPositionType),
      this.avaProjectDiffApplier.avaProjectDiffApplied.pipe(
        takeUntil(this.$destroy),
        delay(10),
        map(() => this.positionTypeConverterService.convertIElementToPositionType(value))
      )
    );
  }
}
