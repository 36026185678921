<div>
  <mat-form-field class="full-width">
    <mat-label>Suche</mat-label>
    <input matInput [ngModel]="filter" (ngModelChange)="pdfAttachmentsService.onFilter($event)" />
  </mat-form-field>
</div>
<div class="wrapper-table">
  <table mat-table [dataSource]="pdfAttachmentsService" matSort (matSortChange)="pdfAttachmentsService.onSort($event)">
    <ng-container matColumnDef="sourceFileName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let row">
        {{ row.sourceFileName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="createdAtUtc">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Erstellt</th>
      <td mat-cell *matCellDef="let row">{{ row.createdAtUtc | date: 'dd.MM.yyyy HH:mm' }}</td>
    </ng-container>
    <ng-container matColumnDef="page">
      <th mat-header-cell *matHeaderCellDef>Seiten</th>
      <td mat-cell *matCellDef="let row">{{ row?.quantityTakeOffAttachmentImageFiles?.length }}</td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="actions"></th>
      <td mat-cell *matCellDef="let row" class="actions">
        <button mat-icon-button color="primary" type="button" (click)="downloadAttachment($event, row)">
          <mat-icon mat-icon-button>download</mat-icon>
        </button>
        <button mat-icon-button color="warn" type="button" (click)="deleteAttachment($event, row)">
          <mat-icon mat-icon-button>close</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="showedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: showedColumns"
      (click)="selectItem(row)"
      [ngClass]="{ 'selected-row': row.id === selectedAttchmentId }"
    ></tr>
  </table>
</div>
<pa-upper-paginator
  [pageSizeOptions]="[5, 10, 25, 100]"
  [upperPaginated]="pdfAttachmentsService.paginationResult | async"
  [upperService]="pdfAttachmentsService"
  (page)="pdfAttachmentsService.onPage($event)"
>
</pa-upper-paginator>
