import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { CopyCalculationViewMessengerService } from '@shared/services/electron/copy-calculation-view-messenger.service';
import { CatalogsService } from '@shared/services/lightquery/catalogs.service';
import { MasterDataProductsService } from '@shared/services/lightquery/master-data-products.service';

import { MasterDataProductsTableComponent } from '@master/components/master-data-products-table/master-data-products-table.component';

import {
  MasterDataProductCataloguesClient,
  MasterDataProductGet,
  MasterProductCatalogueGet,
  PriceComponentType
} from 'app/generated-client/generated-client';

@Component({
  selector: 'pa-catalog',
  standalone: true,
  imports: [NgIf, MatButtonModule, MasterDataProductsTableComponent, MatCheckboxModule, FormsModule],
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit, OnDestroy {
  private masterDataProductsService = inject(MasterDataProductsService);
  private route = inject(ActivatedRoute);
  private catalogsService = inject(CatalogsService);
  private matDialogRef = inject(MatDialogRef);
  public dialogData: any = inject(MAT_DIALOG_DATA);
  public sanitizer = inject(DomSanitizer);
  private copyCalculationViewMessengerService = inject(CopyCalculationViewMessengerService);
  private masterDataProductCataloguesClient = inject(MasterDataProductCataloguesClient);

  currentCatalog: MasterProductCatalogueGet;
  priceComponentType = PriceComponentType.Materials;
  noSeparate: boolean;
  selectedProduct: MasterDataProductGet;
  constructor() {}

  ngOnInit(): void {
    this.noSeparate = this.dialogData?.noSeparate;

    const catalogue = this.dialogData?.catalogue;
    this.masterDataProductsService.setQueryParameter('catalogueId', catalogue?.id || null);
    this.currentCatalog = catalogue;
  }

  ngOnDestroy(): void {
    this.masterDataProductsService.setQueryParameter('catalogueId', null);
  }

  back(): void {
    this.matDialogRef.close();
  }

  dblSelectMasterDataProduct(): void {
    if (this.selectedProduct) {
      this.copyCalculationViewMessengerService.sendSelectedArticleOrEquipmentToCopyToMainWindow(this.selectedProduct);
    }
  }

  selectMasterDataProduct(selectedProduct: MasterDataProductGet): void {
    this.selectedProduct = selectedProduct;
  }

  changeArchivedCatalog(): void {
    this.masterDataProductCataloguesClient
      .setArchivedStatusForMasterCatalogue(this.currentCatalog.id, {
        catalogueId: this.currentCatalog.id,
        isArchived: this.currentCatalog.isArchived
      })
      .subscribe((r) => {
        this.catalogsService.forceRefresh();
        this.currentCatalog.isArchived = !!r.isArchived;
      });
  }
}
