import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { Subject, filter, takeUntil } from 'rxjs';

import { SelectedSpecificationElementMessengerService } from '@shared/services/messengers/selected-specification-element-messenger.service';

import { QuantityAttribute } from 'app/areas/bim-view/models';
import { CountingQuantityAssignmentsService } from 'app/areas/bim-view/services/counting-quantity-assignments.service';
import { QuantityAttributeService } from 'app/areas/bim-view/services/quantity-attribute.service';
import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';
import {
  BimFileGet,
  BimFileQuantityAssignmentModel,
  BimFileQuantityAssignmentModelPut,
  BimFilesQuantitiesClient,
  IElementDto,
  PositionDto
} from 'app/generated-client/generated-client';

import { ConfirmationType } from '../../../../../../shared/models/dialog-config.model';
import { SelectedSpecificationMessengerService } from '../../../../../../shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from '../../../../../../shared/services/modal.service';
import { BimModelAssignedQuantitiesMessengerService } from '../../../../services/bim-model-assigned-quantities-messenger.service';

import { CreateBimQtoComponent } from '../create-bim-qto/create-bim-qto.component';

@Component({
  selector: 'pa-managed-assign-element-quantity',
  templateUrl: './managed-assign-element-quantity.component.html',
  styleUrls: ['./managed-assign-element-quantity.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, FlexLayoutDirective]
})
export class ManagedAssignElementQuantityComponent implements OnInit, OnDestroy {
  selectedElement: PositionDto | null = null;
  private projectId: string | null = null;
  private avaProjectId: string | null = null;
  private $destroy: Subject<boolean> = new Subject<boolean>();
  quantityAssignments: QuantityAttribute[] = [];
  countingQuantityAssignmentsService = inject(CountingQuantityAssignmentsService);
  bimFilesQuantitiesClient = inject(BimFilesQuantitiesClient);
  quantityAttributeService = inject(QuantityAttributeService);
  selectedSpecificationMessengerService = inject(SelectedSpecificationMessengerService);
  selectedSpecificationElementMessengerService = inject(SelectedSpecificationElementMessengerService);
  bimModelAssignedQuantitiesMessengerService = inject(BimModelAssignedQuantitiesMessengerService);
  modalService = inject(ModalService);
  assignedQuantities: BimFileQuantityAssignmentModel | null = null;
  hasAssignedquantitiesInBackend = false;
  @Input() bimFile: BimFileGet;

  constructor() {}

  ngOnInit(): void {
    this.selectedSpecificationElementMessengerService.selectedElement
      .pipe(takeUntil(this.$destroy))
      .subscribe((selectedElement: { id: string; element: IElementDto }) => {
        if (selectedElement?.element?.elementTypeDiscriminator === 'PositionDto') {
          this.selectedElement = selectedElement.element;
        } else {
          this.selectedElement = null;
        }
      });

    this.bimModelAssignedQuantitiesMessengerService.bimFileQuantityAssignment
      .pipe(takeUntil(this.$destroy))
      .subscribe((assignedQuantities) => {
        this.assignedQuantities = assignedQuantities;
        if (this.assignedQuantities == null) {
          this.hasAssignedquantitiesInBackend = false;
        } else {
          // We're checking if there are properties with non-zero length present
          this.hasAssignedquantitiesInBackend = Object.values(this.assignedQuantities.quantityAssignmentsByPositionId).some(
            (v) => v.length
          );
        }
      });

    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        takeUntil(this.$destroy),
        filter((selectedServiceSpecification) => selectedServiceSpecification != null)
      )
      .subscribe((selectedServiceSpecification) => {
        this.avaProjectId = selectedServiceSpecification.avaProjectId;
        this.projectId = selectedServiceSpecification.parentProjectId;

        this.bimFilesQuantitiesClient.getAssignedBimQuantities(this.projectId, this.avaProjectId, this.bimFile.id).subscribe((r) => {
          this.bimModelAssignedQuantitiesMessengerService.setBimFileQuantityAssignment(r);
        });
      });

    this.quantityAttributeService.quantityAttributeList.pipe(takeUntil(this.$destroy)).subscribe((list) => {
      this.quantityAssignments = [...list];
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  saveAssignedQuantities(): void {
    if (this.selectedElement == null) {
      return;
    }

    const backendAssignments: BimFileQuantityAssignmentModelPut = {
      quantityAssignmentsByPositionId: {}
    };

    backendAssignments.quantityAssignmentsByPositionId[this.selectedElement.id] = this.quantityAssignments.map((qa) => {
      return {
        buildingElementEntityId: qa.entityId,
        elementQuantity: qa.quantity
      };
    });

    this.bimFilesQuantitiesClient
      .assignBimQuantitiesToServiceSpecification(this.projectId, this.avaProjectId, this.bimFile.id, backendAssignments)
      .subscribe((r) => {
        this.bimModelAssignedQuantitiesMessengerService.setBimFileQuantityAssignment(r);
      });
  }

  unassignQuantities(): void {
    if (this.selectedElement == null) {
      return;
    }

    const assignedQuantities = this.assignedQuantities.quantityAssignmentsByPositionId[this.selectedElement.id];
    if (!assignedQuantities) {
      return;
    }

    const assignedEntityIds = this.quantityAssignments.map((qa) => qa.entityId);

    const assignmentsToRemove = assignedQuantities.filter((assignedQuantity) =>
      assignedEntityIds.includes(assignedQuantity.buildingElementEntityId)
    );

    this.bimFilesQuantitiesClient
      .unassignBimQuantitiesToServiceSpecification(this.projectId, this.avaProjectId, this.bimFile.id, {
        avaProjectElementId: this.selectedElement.id,
        assignmentIds: assignmentsToRemove.map((a) => a.assignmentId)
      })
      .subscribe((r) => {
        this.bimModelAssignedQuantitiesMessengerService.setBimFileQuantityAssignment(r);
      });
  }

  openCreateQtoDialog(): void {
    this.modalService.openModal(CreateBimQtoComponent, {
      dialogType: ConfirmationType.General,
      data: {
        projectId: this.projectId,
        avaProjectId: this.avaProjectId,
        bimFileId: this.bimFile.id
      }
    });
  }
}
