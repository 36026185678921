<div class="wrapper">
  <div *ngIf="!showingPdf && !requestEnRoute" class="container">
    <div fxLayoutGap="12px" fxLayoutAlign="start start">
      <mat-form-field appearance="fill" class="award-number">
        <mat-label>Vergabenummer</mat-label>
        <input matInput type="text" [(ngModel)]="awardIdentifier" />
        <mat-hint><strong>Überschreiben der gespeicherten Vergabenummer</strong> </mat-hint>
      </mat-form-field>
      <mat-form-field appearance="fill" class="award-number">
        <mat-label>Titel</mat-label>
        <input matInput type="text" [(ngModel)]="customOfferExportHeaderText" />
        <mat-hint><strong>Drucktitel für Angebot</strong> </mat-hint>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Adresse</mat-label>
        <textarea matInput [(ngModel)]="address" rows="4"></textarea>
      </mat-form-field>
    </div>

    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Druckoptionen </mat-panel-title>
        </mat-expansion-panel-header>
        <fieldset>
          <legend>Alle</legend>
          <mat-checkbox [(ngModel)]="includeHeaderOnlyOnFirstPage">Kopfzeile nur auf der ersten Seite drucken</mat-checkbox>
        </fieldset>
        <fieldset>
          <legend>Kalkulation</legend>
          <div fxLayoutGap="12px">
            <mat-checkbox [(ngModel)]="showRowMarkedI">Als intern markierte Zeilen ausgeben</mat-checkbox>
            <mat-checkbox [(ngModel)]="includeProjectInfo">Projektinfo</mat-checkbox>
          </div>
          <div fxLayoutGap="12px">
            <mat-checkbox [(ngModel)]="includeAdditions">Zuschläge</mat-checkbox>
            <mat-checkbox [(ngModel)]="includeSumList">Summenliste</mat-checkbox>
            <mat-checkbox [disabled]="!projectHasQuantityCalculation" [(ngModel)]="includeQuantityEstimation"
              >Mengenermittlung drucken</mat-checkbox
            >
          </div>
        </fieldset>
        <fieldset>
          <legend>Angebot</legend>
          <div fxLayoutGap="12px">
            <mat-checkbox [(ngModel)]="includeWarningsForCalculationErrors">Warnung bei Kalkulationsfehlern drucken</mat-checkbox>
            <mat-checkbox [(ngModel)]="excludePositions">Ohne Positionen</mat-checkbox>
            <mat-checkbox [(ngModel)]="includeOnlyOverview">Nur Übersicht</mat-checkbox>
          </div>
          <div fxLayoutGap="12px">
            <mat-checkbox [(ngModel)]="exportLongTextCompletely">Langtext komplett ausgeben</mat-checkbox>
            <mat-checkbox [(ngModel)]="includeSignatureField">Signaturfeld drucken</mat-checkbox>
          </div>
          <mat-form-field>
            <mat-label>Maximale Exporttiefe</mat-label>
            <mat-select [(ngModel)]="maxElementExportDepth">
              <mat-option [value]="-1">Alle</mat-option>
              <mat-option [value]="0">Los (1)</mat-option>
              <mat-option [value]="1">Gewerk (2)</mat-option>
              <mat-option [value]="2">Titel (3)</mat-option>
              <mat-option [value]="3">Untertitel (4)</mat-option>
              <mat-option [value]="4">Subtitel (5)</mat-option>
            </mat-select>
          </mat-form-field>
        </fieldset>
        <fieldset>
          <legend>Schlußblatt</legend>
          <mat-checkbox [(ngModel)]="includePositionsWithoutTotal">Eventualpositionen im Schlußblatt ausgeben</mat-checkbox>
        </fieldset>
      </mat-expansion-panel>
    </mat-accordion>

    <div [style.marginBottom]="elementSelection ? '22px' : '50px'">
      <pa-selecting-element-buttons (elementSelectionChanged)="elementSelection = $event"></pa-selecting-element-buttons>
    </div>

    <fieldset class="texts" fxLayoutGap="15px">
      <legend>Textbausteine</legend>
      <mat-checkbox [checked]="!!offerStartText" (click)="selectStartingText($event)">Vortext</mat-checkbox>
      <mat-checkbox [checked]="!!offerEndText" (click)="selectEndingText($event)">Schlusstext</mat-checkbox>
    </fieldset>

    <div fxLayoutGap="10px">
      <button color="primary" mat-raised-button (click)="generatePdfAndShowPrintView()">Kalkulation</button>
      <button color="primary" mat-raised-button (click)="generateOfferPdfAndShowPrintView()">Angebot</button>
      <button color="primary" mat-raised-button (click)="generateEfb221()">EFB Blatt 221</button>
      <button color="primary" mat-raised-button (click)="generateEfb223()">EFB Blatt 223</button>
      <button color="primary" mat-raised-button (click)="generateFinishingForm()">Schlussblatt</button>
      <button color="primary" mat-raised-button (click)="generatePdfAndShowCompactPrintView()">LV Ausdruck (Kompakt)</button>
    </div>
  </div>
  <h1 *ngIf="showingPdf && !requestEnRoute && !waitingForPdf">Bitte Druckvorschaufenster beachten.</h1>
  <div class="wrapper-loader" *ngIf="requestEnRoute || waitingForPdf">
    <mat-spinner></mat-spinner>
  </div>
</div>
