import { BimFileGet, BimFilesClient, IfcStructure } from '../../../generated-client/generated-client';
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationCube, State, ViewType, Viewer } from '@xbim/viewer';
import { ReplaySubject, Subject, filter, take, takeUntil } from 'rxjs';

import { BimLoaderOverlayService } from './bim-loader-overlay.service';
import { BimStateService } from './bim-state.service';
import { BimStateType } from '../models';
import { SelectedSpecificationMessengerService } from '../../../shared/services/messengers/selected-specification-messenger.service';

@Injectable({
  providedIn: 'root'
})
export class BimViewService implements OnDestroy {
  private viewer: Viewer;
  private overlay: BimLoaderOverlayService;
  private _structure = new ReplaySubject<IfcStructure>(1);
  structure = this._structure.asObservable();
  panelCurrentlyExpanded: string[] = [];
  loadModelId: number | null;
  pickedElementId: number;
  state: BimStateType = null;
  canvas: HTMLCanvasElement;
  bimFile: BimFileGet;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private stateService: BimStateService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private bimFilesClient: BimFilesClient
  ) {
    stateService.onReset.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.unloadModelFromGPU();
    });

    stateService.state.pipe(takeUntil(this.$destroy)).subscribe((state) => {
      this.state = state;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  init(canvasElement: HTMLCanvasElement, bimFile: BimFileGet): void {
    if (this.loadModelId) {
      if (this.bimFile?.id === this.state.bimFile?.id) {
        canvasElement.parentElement.replaceChild(this.canvas, canvasElement);
        return;
      } else {
        this.unloadModelFromGPU();
      }
    }
    this.viewer = new Viewer(canvasElement);
    this.viewer.background = [245, 245, 245, 255];
    this.overlay = new BimLoaderOverlayService();
    this.overlay.init(this.viewer);
    this.viewer.addPlugin(this.overlay);
    this.viewer.addPlugin(this.getCubePlugin());
    this.overlay.show();

    this.viewer.on('loaded', (loaded) => {
      this.overlay.hide();
      if (this.viewer.isModelLoaded(loaded.model)) {
        this.stateService.setSelectedElement(null);
        this.viewer.show(ViewType.DEFAULT, undefined, loaded.model, true);
        this.loadModelId = loaded.model;
        this.canvas = canvasElement;
        this.bimFile = bimFile;
      }
    });
    let lastPicked: number | null = null;
    this.viewer.on('pick', (element) => {
      this.pickedElementId = element.id;
      this.stateService.setSelectedElement(element.id);
      if (lastPicked != null) {
        const elementState = this.viewer.getState(lastPicked) === State.HIDDEN ? State.HIDDEN : State.UNSTYLED;
        this.viewer?.setState(elementState, [lastPicked]);
      }
      lastPicked = element.id;
      this.viewer?.setState(State.HIGHLIGHTED, [element.id]);
    });

    this.loadBimFile(bimFile);

    this.viewer.start(this.loadModelId);
  }

  getCubePlugin(): NavigationCube {
    const cube = new NavigationCube();
    cube.highlighting = 1;
    cube.maxSize = 70;
    cube.passiveAlpha = 0.8;
    return cube;
  }

  unloadModelFromGPU(): void {
    if (this.loadModelId) {
      this.viewer.unload(this.loadModelId);
      this.stateService.nullState();
      this.loadModelId = null;
    }
  }

  loadFileInViewer(file: Blob): void {
    this.viewer?.loadAsync(file);
    this.stateService.setWexbimLoaded();
  }

  setStructure(structure: IfcStructure): void {
    this._structure.next(structure);
    this.stateService.setIsStructure(!!this.structure);
  }

  getListAllElements(): number[] | null {
    return this.loadModelId ? this.viewer?.getModelState(this.loadModelId).map((v) => v[0]) : null;
  }

  resetViewer(): void {
    if (this.loadModelId) {
      this.viewer?.resetState(this.getListAllElements());
    }
  }

  highLight(id?: number): void {
    this.viewer.clearHighlighting();
    if (id) {
      this.viewer.setState(State.HIGHLIGHTED, [id]);
    }
  }

  hideAllElementsExcept(elementIds: number[] | null): void {
    this.stateService.setShowedElements(elementIds);
    if (this.loadModelId) {
      this.resetViewer();
      this.viewer?.setState(State.HIDDEN, this.getListAllElements()?.filter((e) => !!elementIds && !elementIds?.includes(e)));
      if (this.checkIfSelectedElementIsHidden(this.pickedElementId, elementIds)) {
        this.viewer.setState(State.HIGHLIGHTED, [this.pickedElementId]);
        this.stateService.setSelectedElement(this.pickedElementId);
      } else {
        this.stateService.setSelectedElement(null);
      }
    }
  }

  private checkIfSelectedElementIsHidden(selectedId: number | null, elementIds: number[] | null): boolean {
    return elementIds?.includes(selectedId);
  }

  private loadBimFile(bimFile: BimFileGet): void {
    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        filter((s) => !!s),
        take(1)
      )
      .subscribe((serviceSpecification) => {
        const projectId = serviceSpecification.parentProjectId;
        const avaProjectId = serviceSpecification.avaProjectId;
        this.bimFilesClient.getGeometryResult(projectId, avaProjectId, bimFile.id).subscribe((r) => {
          this.viewer.load(r.data);
          this.stateService.setWexbimLoaded();
        });

        this.bimFilesClient.getStructureResult(projectId, avaProjectId, bimFile.id).subscribe((ifcStructure) => {
          this.setStructure(ifcStructure);
        });
      });
  }
}
