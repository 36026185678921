import { Pipe, PipeTransform } from '@angular/core';

import { PositionTypeDto, PriceTypeDto } from 'app/generated-client/generated-client';

@Pipe({
  name: 'showPositionAbbreviation',
  standalone: true
})
export class ShowPositionAbbreviationPipe implements PipeTransform {
  // A pipe in Angular is a pure function and is designed to work with simple data types.
  // if a pipe were allowed to accept an object and modify it,
  // it would violate the principle of immutability. So here we need to send two parameters instead an  positon object.
  transform(positionType: string, priceType: string, isShowNormalPosition = false): string {
    if (positionType === PositionTypeDto.Alternative && priceType === PriceTypeDto.WithoutTotal) {
      return '(A)';
    } else if (positionType === PositionTypeDto.Optional && priceType === PriceTypeDto.WithoutTotal) {
      return '(E)';
    } else if (positionType === PositionTypeDto.Optional && priceType === PriceTypeDto.WithTotal) {
      return '(B)';
    } else {
      return isShowNormalPosition ? '(N)' : '';
    }
  }
}
