import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';

import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { ProjectGet, ProjectsClient } from 'app/generated-client/generated-client';
import { ProjectsService } from 'app/shared/services/lightquery/projects.service';

import { ProjectsCardsComponent } from '../projects-cards/projects-cards.component';
import { ProjectsTableComponent } from '../projects-table/projects-table.component';
import { PaginationResult } from 'ng-lightquery';

@Component({
  selector: 'pa-projects-control',
  templateUrl: './projects-control.component.html',
  styleUrls: ['./projects-control.component.scss'],
  standalone: true,
  imports: [NgIf, ProjectsTableComponent, ProjectsCardsComponent]
})
export class ProjectsControlComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isCards: boolean;
  @Input() filter: string;
  @Input() filterContactName: string;
  @Input() filterProjectNumber: string;
  @Input() filterCompany: string;
  @Input() filterStatus: string;
  @Input() isInnerWindow: boolean;
  @Input() showArchivedProjects: boolean;
  @Output() selectRow = new EventEmitter<ProjectGet>();
  @Output() getContextMenu = new EventEmitter<{ command: string; data: any }>();
  @Output() isMenuOpened = new EventEmitter<boolean>();
  projectsPaginated: PaginationResult<ProjectGet>;
  pageSizeOptions: number[];
  private filterSource: Subject<string> = new Subject<string>();
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    public projectsService: ProjectsService,
    private projectsClient: ProjectsClient
  ) {}

  ngOnInit(): void {
    this.pageSizeOptions = this.isInnerWindow ? [5, 10] : [5, 10, 20, 25, 100];
    this.init();

    this.projectsService.paginationResult.pipe(takeUntil(this.$destroy)).subscribe((r: PaginationResult<ProjectGet>) => {
      this.projectsPaginated = r;
    });

    this.filterSource
      .pipe(takeUntil(this.$destroy), debounceTime(250))
      .subscribe((filterValue: string) => this.projectsService.setQueryParameter('filter', filterValue));
  }

  ngOnChanges(): void {
    this.setFilterSettings();
  }

  ngOnDestroy(): void {
    this.setFilterSettings(true);
    this.$destroy.next(true);
    this.$destroy.complete();
  }
  selectProject(project: ProjectGet): void {
    this.selectRow.emit(project);
    if (!this.isInnerWindow) {
      this.projectsClient.setUserHasAccessedProject(project.id).subscribe(() => this.projectsService.forceRefresh());
    }
  }

  private init(): void {
    this.setFilterSettings();
  }

  onFilter(isSetNull: boolean): void {
    if (isSetNull) {
      this.projectsService.setQueryParameter('filter', null);
    } else {
      setTimeout(() => {
        this.filterSource.next(this.filter);
      }, 0);
    }
  }

  public onFilterCompany(isSetNull: boolean): void {
    this.projectsService.setQueryParameter('companyId', isSetNull ? null : this.filterCompany);
  }

  public onFilterStatus(isSetNull: boolean): void {
    this.projectsService.setQueryParameter('status', isSetNull ? null : this.filterStatus);
  }

  public onFilterProjectContactName(isSetNull: boolean): void {
    this.projectsService.setQueryParameter('contactNameFilter', isSetNull ? null : this.filterContactName);
  }

  public onFilterProjectNumber(isSetNull: boolean): void {
    this.projectsService.setQueryParameter('projectNumberFilter', isSetNull ? null : this.filterProjectNumber);
  }

  public onShowArchivedProjects(isSetNull: boolean): void {
    this.projectsService.setQueryParameter('includeArchived', isSetNull ? null : (!!this.showArchivedProjects).toString());
  }

  private setFilterSettings(isSetNull = false): void {
    this.onFilter(isSetNull);
    this.onFilterCompany(isSetNull);
    this.onFilterStatus(isSetNull);
    this.onShowArchivedProjects(isSetNull);
    this.onFilterProjectContactName(isSetNull);
    this.onFilterProjectNumber(isSetNull);
  }

  runContextMenu(event): void {
    this.getContextMenu.emit(event);
  }

  getMenuStatus(e: boolean): void {
    this.isMenuOpened.emit(e);
  }
}
