import { AvaProjectGet } from 'app/generated-client/generated-client';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationMainService } from './main/pagination-main.service';

@Injectable({
  providedIn: 'root'
})
export class AvaProjectsFlexibleService extends PaginationMainService<AvaProjectGet> {
  constructor(protected http: HttpClient) {
    super(http);
  }

  start(projectId: string): void {
    this.beginBaseUrl(`/api/projects/${projectId}/ava-projects`);
  }
}
