import { NgIf } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { Router, RouterLink } from '@angular/router';

import { AuthenticationMessenger, AuthenticationService } from '@dangl/angular-dangl-identity-client';
import { UserInfo } from '@dangl/angular-dangl-identity-client/models/user-info';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';

import { getAppConfig } from '../../../app-config-accessor';
import { ElectronService } from '../../../shared/services/electron/electron.service';
import { version } from '../../../version';
import { AutoUpdateService } from '../../services/auto-update.service';
import { AvaNotificationsService } from '../../services/ava-notifications.service';
import { ModalService } from '../../services/modal.service';

import { ErrorReportComponent } from '../error-report/error-report.component';
import { HelpWindowModalComponent } from '../help-window-modal/help-window-modal.component';
import { ModalUserSettingsComponent } from '../modal-user-settings/modal-user-settings.component';
import { NgDanglIconsModule } from 'ng-dangl-icons';

@Component({
  selector: 'pa-login-partial',
  templateUrl: './login-partial.component.html',
  styleUrls: ['./login-partial.component.scss'],
  standalone: true,
  imports: [NgIf, FlexLayoutDirective, RouterLink, MatButton, MatMenuTrigger, NgDanglIconsModule, MatMenu, MatMenuItem, MatIcon]
})
export class LoginPartialComponent implements OnDestroy {
  constructor(
    private authenticationMessenger: AuthenticationMessenger,
    private authenticationService: AuthenticationService,
    private router: Router,
    private autoUpdateService: AutoUpdateService,
    private notificationService: AvaNotificationsService,
    private electronService: ElectronService,
    private matDialog: MatDialog,
    private modalService: ModalService
  ) {
    this.authenticationMessenger.userInfo.pipe(takeUntil(this.$destroy)).subscribe((ui) => (this.userInfo = ui));
  }

  userInfo: UserInfo;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  logout(): void {
    this.authenticationService.logout();
    this.router.navigateByUrl('/login');
  }

  checkForUpdates(): void {
    this.autoUpdateService.checkForVersionAndPromptUser(true).subscribe((updateAvailable) => {
      if (!updateAvailable) {
        this.notificationService.info('Du hast die aktuellste Version.', 'Keine Updates');
      }
    });
  }

  openDocumentation() {
    const docuUrl = `https://docs.dangl-it.com/Projects/${getAppConfig().danglDocuProjectName}/${version.version}`;
    if (this.electronService.isElectron) {
      // This will only work in Electron environments
      this.electronService.shell.openExternal(docuUrl);
    } else {
      // Outside of electron, this doesnt really make sense, so we're just redirecting:
      window.open(docuUrl);
    }
  }

  showErrorReportForm(): void {
    if (this.electronService.isElectron) {
      // The timeout here is required to allow the closing animation of the
      // menu to finish, so it's not included in the screenshot.
      setTimeout(() => {
        this.electronService
          .takeScreenshotAsBase64()
          ?.pipe(takeUntil(this.$destroy))
          .subscribe((screenshot) => {
            this.matDialog.open(ErrorReportComponent, {
              data: screenshot
            });
          });
      }, 200);
    } else {
      this.matDialog.open(ErrorReportComponent);
    }
  }

  changeUserSettings(): void {
    this.modalService.openModal(ModalUserSettingsComponent, { dialogType: ConfirmationType.General });
  }

  openHelpWindow(): void {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send('helpWindowOpen');
    } else {
      this.modalService.openModal(HelpWindowModalComponent, { dialogType: ConfirmationType.General });
    }
  }
}
