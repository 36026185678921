import { NgIf, AsyncPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';
import { RouterLink } from '@angular/router';

import { Subject } from 'rxjs';

import { CompanyGet } from 'app/generated-client/generated-client';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { CompaniesService } from 'app/shared/services/lightquery/companies.service';
import { ModalService } from 'app/shared/services/modal.service';

import { UpperPaginatorComponent } from '../../../../shared/components/upper-paginator/upper-paginator.component';

import { CompanyAddComponent } from '../company-add/company-add.component';

@Component({
  selector: 'pa-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    NgIf,
    MatButton,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    RouterLink,
    UpperPaginatorComponent,
    AsyncPipe
  ]
})
export class CompanyListComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) private sort: MatSort;
  @Input() isInProjectCreation: boolean;
  @Output() selectedCompany = new EventEmitter<CompanyGet>();
  displayedColumns = ['name', 'street', 'zipCode', 'city', 'country'];
  filter: string = '';
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    public companiesService: CompaniesService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.companiesService.initService({
      filtering: this.filter,
      paging: { page: 1, pageSize: 10 },
      sortTableObj: this.sort
    });
    this.companiesService.forceRefresh();
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  selectCompany(company: CompanyGet): void {
    this.selectedCompany.emit(company);
  }

  addCompany(): void {
    this.modalService
      .openModal(CompanyAddComponent, { dialogType: ConfirmationType.General })
      .afterClosed()
      .subscribe((isAdded: boolean) => {
        if (isAdded) {
          this.companiesService.forceRefresh();
        }
      });
  }
}
