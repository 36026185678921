import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { HandlerElectronErrorsService } from 'app/shared/services/electron/handler-electron-errors.service';
import { FileSaverService } from 'app/shared/services/file-saver.service';

import {
  AvaProjectGet,
  ElementSelection,
  ElementSelectionsClient,
  ReportGetOfServiceSpecificationReport,
  ReportsClient,
  TextTemplateType,
  TextTemplatesClient
} from '../../../../../../generated-client/generated-client';
import { AvaNotificationsService } from '../../../../../../shared/services/ava-notifications.service';
import { PrintViewMessengerService } from '../../../../../../shared/services/electron/print-view-messenger.service';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

import { ModalService } from './../../../../../../shared/services/modal.service';

import { ReportTextSelectionComponent } from '../report-text-selection/report-text-selection.component';
import { SelectingElementButtonsComponent } from '../selecting-element-buttons/selecting-element-buttons.component';

@Component({
  selector: 'pa-create-service-specification-pdf',
  templateUrl: './create-service-specification-pdf.component.html',
  styleUrls: ['./create-service-specification-pdf.component.scss'],
  standalone: true,
  imports: [NgIf, SelectingElementButtonsComponent, FlexLayoutDirective, MatCheckbox, FormsModule, MatButton, MatProgressSpinner]
})
export class CreateServiceSpecificationPdfComponent implements OnInit, OnDestroy {
  @Input() serviceSpecification: AvaProjectGet;
  @Output() exportFinished = new EventEmitter<boolean>();

  private $destroy: Subject<void> = new Subject<void>();

  requestEnRoute = false;
  showingPdf = false;
  waitingForPdf = false;
  elementSelection: ElementSelection = null;
  includeSignatureField = true;
  includeHeaderOnlyOnFirstPage = false;
  includePrices = true;
  startText: string;
  endText: string;
  private lastStartText: string;
  private lastEndText: string;

  constructor(
    private ngZone: NgZone,
    private notificationsService: AvaNotificationsService,
    private printViewMessengerService: PrintViewMessengerService,
    private reportsClient: ReportsClient,
    private modalService: ModalService,
    private textTemplatesClient: TextTemplatesClient,
    private elementSelectionsClient: ElementSelectionsClient,
    private fileSaverService: FileSaverService,
    private handlerElectronErrorsService: HandlerElectronErrorsService
  ) {}

  ngOnInit(): void {
    this.printViewMessengerService.ensurePrintViewClosedIsSubscribed();
    this.printViewMessengerService.printViewClosed.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.ngZone.run(() => {
        this.showingPdf = false;
        this.requestEnRoute = false;
        this.waitingForPdf = false;
      });
    });

    if (this.serviceSpecification?.id && this.serviceSpecification?.projectId) {
      this.textTemplatesClient
        .getLastUsedHtmlTextForAvaProject(
          this.serviceSpecification.projectId,
          this.serviceSpecification.id,
          TextTemplateType.ServiceSpecificationStart
        )
        .subscribe({
          next: (text) => (this.startText = text.htmlText)
        });
      this.textTemplatesClient
        .getLastUsedHtmlTextForAvaProject(
          this.serviceSpecification.projectId,
          this.serviceSpecification.id,
          TextTemplateType.ServiceSpecificationEnd
        )
        .subscribe({
          next: (text) => (this.endText = text.htmlText)
        });
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  generatePdfAndShowPrintView(): void {
    this.requestEnRoute = true;
    this.reportsClient
      .getServiceSpecificationReport(
        this.serviceSpecification.id,
        true,
        true,
        false,
        false,
        this.includeSignatureField,
        this.includeHeaderOnlyOnFirstPage,
        this.includePrices,
        {
          elementSelection: this.elementSelection,
          elementSelectionId: null,
          startHtmlText: this.startText,
          endHtmlText: this.endText
        }
      )
      .subscribe({
        next: (report: ReportGetOfServiceSpecificationReport) => {
          this.printViewMessengerService.showPdfPreview(
            report.htmlReport,
            this.serviceSpecification.name,
            report.isLandscape,
            report.pdfReportBase64,
            this.includeHeaderOnlyOnFirstPage
          );
          this.waitingForPdf = true;
          this.printViewMessengerService.waitForPrintViewReady().subscribe(() => {
            this.waitingForPdf = false;
          });

          this.showingPdf = true;
          this.requestEnRoute = false;
        },
        error: () => {
          this.requestEnRoute = false;
          this.notificationsService.error('Fehler beim Erstellen der Druckvorschau.');
        }
      });
  }

  generatePdfAndShowCompactPrintView(): void {
    this.requestEnRoute = true;
    this.reportsClient
      .getServiceSpecificationCompactReport(
        this.serviceSpecification.id,
        true,
        false,
        false,
        false,
        this.includeSignatureField,
        this.includeHeaderOnlyOnFirstPage,
        this.includePrices,
        {
          elementSelection: this.elementSelection,
          elementSelectionId: null,
          startHtmlText: this.startText,
          endHtmlText: this.endText
        }
      )
      .subscribe({
        next: (report: ReportGetOfServiceSpecificationReport) => {
          this.printViewMessengerService.showPdfPreview(
            report.htmlReport,
            'Kompaktes Leistungsverzeichnis',
            report.isLandscape,
            report.pdfReportBase64,
            this.includeHeaderOnlyOnFirstPage
          );
          this.waitingForPdf = true;
          this.printViewMessengerService.waitForPrintViewReady().subscribe(() => {
            this.waitingForPdf = false;
          });

          this.showingPdf = true;
          this.requestEnRoute = false;
        },
        error: () => {
          this.requestEnRoute = false;
          this.notificationsService.error('Fehler beim Erstellen der Druckvorschau.');
        }
      });
  }

  selectStartingText(event: MouseEvent): void {
    event.preventDefault();
    if (!this.startText) {
      this.startText = this.lastStartText;
      this.selectText(this.startText, TextTemplateType.ServiceSpecificationStart).subscribe((t) => (this.startText = t));
    } else {
      this.lastStartText = this.startText;
      this.startText = null;
    }
  }

  selectEndingText(event: MouseEvent): void {
    event.preventDefault();
    if (!this.endText) {
      this.endText = this.lastEndText;
      this.selectText(this.endText, TextTemplateType.ServiceSpecificationEnd).subscribe((t) => (this.endText = t));
    } else {
      this.lastEndText = this.endText;
      this.endText = null;
    }
  }

  private selectText(currentText: string, textTemplateType: TextTemplateType): Observable<string> {
    return this.modalService
      .openModal(ReportTextSelectionComponent, {
        dialogType: ConfirmationType.General,
        data: {
          text: currentText,
          templateType: textTemplateType
        }
      })
      .afterClosed()
      .pipe(map((text) => text ?? currentText));
  }
}
