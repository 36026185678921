import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProjectCurrentItemsService {
  private projCurrItemsSource = new BehaviorSubject<any[]>([]);
  projCurrItems = this.projCurrItemsSource.asObservable();

  setProjCurrItems(data: any, type: string): void {
    if (type === 'uploadedFile') {
      const prevCurrItems = this.projCurrItemsSource.getValue();
      prevCurrItems.push(data);
      this.projCurrItemsSource.next(prevCurrItems);
    } else if (type === 'deletedFile') {
      const prevCurrItems = this.projCurrItemsSource.getValue().filter(items => items.id !== data);
      this.projCurrItemsSource.next(prevCurrItems);
    } else {
      this.projCurrItemsSource.next(data);
    }
  }
}
