import { AsyncPipe } from '@angular/common';
import { Component, ViewChild, type OnInit, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';

import { Subject, takeUntil } from 'rxjs';

import { ServicePeriodCodesNewModalComponent } from '@serv-spec/components/invoice/components/service-period-codes-new-modal/service-period-codes-new-modal.component';

import { ModalConfirmComponent } from '@shared/components/modal-confirm/modal-confirm.component';
import { ConfirmationType } from '@shared/models/dialog-config.model';
import { AvaNotificationsService } from '@shared/services/ava-notifications.service';
import { SelectedProjectMessengerService } from '@shared/services/messengers/selected-project-messenger.service';
import { ModalService } from '@shared/services/modal.service';

import { InvoicePeriodCodeGet, InvoicePeriodCodesClient, ProjectGet } from 'app/generated-client/generated-client';
import { InvoicePeriodCodesService } from 'app/shared/services/lightquery/invoice-period-codes.service';

import { UpperPaginatorComponent } from '../../../../../../shared/components/upper-paginator/upper-paginator.component';

@Component({
  selector: 'pa-invoice-period-codes-list',
  templateUrl: './invoice-period-codes-list.component.html',
  styleUrls: ['./invoice-period-codes-list.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    MatIconButton,
    MatIcon,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    UpperPaginatorComponent,
    AsyncPipe
  ]
})
export class InvoicePeriodCodesListComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) private sort: MatSort;
  @Input() isEdit: boolean;
  private projectId: string;
  showedColumns = ['name', 'description'];
  filter: string;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    public invoicePeriodCodesService: InvoicePeriodCodesService,
    private invoicePeriodCodesClient: InvoicePeriodCodesClient,
    private avaNotificationsService: AvaNotificationsService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.invoicePeriodCodesService.initService({
      paging: { page: 1, pageSize: 50 },
      sorting: { propertyName: 'name', isDescending: true },
      sortTableObj: this.sort
    });

    this.invoicePeriodCodesService.forceRefresh();

    if (this.isEdit) {
      this.showedColumns.push('action');
    }
    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((project: ProjectGet) => {
      this.projectId = project.id;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
    this.invoicePeriodCodesService.setQueryParameter('filter', null);
  }

  deleteItem(row: InvoicePeriodCodeGet): void {
    this.modalService
      .openModal(ModalConfirmComponent, { dialogType: ConfirmationType.Delete, data: ['Löschen', 'Abrechnungszeitraum', row.name] })
      .afterClosed()
      .subscribe((e) => {
        if (e) {
          this.invoicePeriodCodesClient.deleteInvoicePeriodCode(this.projectId, row.id).subscribe(
            () => {
              this.avaNotificationsService.success('Abrechnungszeitraum gelöscht');
              this.invoicePeriodCodesService.forceRefresh();
            },
            () => {
              this.avaNotificationsService.error('Fehler beim Löschen des Abrechnungszeitraums');
            }
          );
        }
      });
  }

  editItem(row: InvoicePeriodCodeGet = null): void {
    this.modalService
      .openModal(ServicePeriodCodesNewModalComponent, {
        dialogType: ConfirmationType.General,
        data: row
      })
      .afterClosed()
      .subscribe((e) => {
        if (e) {
          if (!row) {
            this.invoicePeriodCodesClient
              .createInvoicePeriodCode(this.projectId, {
                projectId: this.projectId,
                name: e.name,
                description: e.description
              })
              .subscribe(
                () => {
                  this.avaNotificationsService.success('Abrechnungszeitraum gespeichert');
                  this.invoicePeriodCodesService.forceRefresh();
                },
                () => {
                  this.avaNotificationsService.error('Fehler beim Speichern des Abrechnungszeitraums');
                }
              );
          } else {
            this.invoicePeriodCodesClient
              .updateInvoicePeriodCodeDetails(this.projectId, row.id, {
                id: row.id,
                name: e.name,
                description: e.description,
                projectId: this.projectId
              })
              .subscribe(
                () => {
                  this.avaNotificationsService.success('Abrechnungszeitraum gespeichert');
                  this.invoicePeriodCodesService.forceRefresh();
                },
                () => this.avaNotificationsService.error('Fehler beim Speichern des Abrechnungszeitraums')
              );
          }
        }
      });
  }
}
