import { Component } from '@angular/core';

import { PriceComponentType } from 'app/generated-client/generated-client';

import { MasterDataProductsComponent } from '../master-data-products/master-data-products.component';

@Component({
  selector: 'pa-equipments',
  templateUrl: './sub-contractor-services.component.html',
  styleUrls: ['./sub-contractor-services.component.scss'],
  standalone: true,
  imports: [MasterDataProductsComponent]
})
export class SubContractorServicesComponent {
  priceComponentType = PriceComponentType.Subcontractor;
}
