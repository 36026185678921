import { NgIf } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';

import { TinyMceComponent } from '@dangl/angular-material-shared/tiny-mce';

import { take } from 'rxjs/operators';

import { LvEditorService } from '@serv-spec/services/lv-editor.service';

import { NoteTextDto } from 'app/generated-client/generated-client';
import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { ModalService } from 'app/shared/services/modal.service';

import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-lv-editor-note-text',
  templateUrl: './lv-editor-note-text.component.html',
  styleUrls: ['./lv-editor-note-text.component.scss'],
  standalone: true,
  imports: [NgIf, FlexLayoutDirective, MatCheckbox, FormsModule, TinyMceComponent, MatButton]
})
export class LvEditorNoteTextComponent implements OnInit, OnChanges, OnDestroy {
  @Input() element: NoteTextDto;
  @Input() isEditableAll: boolean;
  elementChanged: NoteTextDto = null;
  isChanged: boolean;
  isElementChanged: boolean;

  constructor(
    private lvEditorService: LvEditorService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.getElement();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isChanged) {
      this.openConfirmWindow();
    } else {
      this.getElement();
      if (changes.element.previousValue && changes.element.currentValue && changes.element.previousValue !== changes.element.currentValue) {
        setTimeout(() => {
          if (!this.isEditableAll) {
            this.lvEditorService.stopChangeElement();
          }
        }, 1);
      }
    }
  }

  ngOnDestroy(): void {
    this.lvEditorService.isElementChanged.pipe(take(1)).subscribe((isElementChanged) => (this.isElementChanged = isElementChanged));
    if (this.isChanged && this.isElementChanged) {
      this.openConfirmWindow();
    }
    setTimeout(() => {
      this.lvEditorService.stopChangeElement();
    }, 1);
    this.lvEditorService.setChangeElement(false);
  }

  getElement(): void {
    if (this.element) {
      this.elementChanged = { ...this.element };
    }
  }

  exit(save: boolean = false): void {
    if (save) {
      this.lvEditorService.saveChangedElement(this.elementChanged);
    }
    this.lvEditorService.stopChangeElement();
    this.isChanged = false;
    this.lvEditorService.setChangeElement(this.isChanged);
  }

  changedTinyMce(event, obj, prop) {
    if (event !== obj[prop]) {
      this.changeElement();
      obj[prop] = event;
    }
  }

  changeElement(): void {
    this.isChanged = true;
    this.lvEditorService.setChangeElement(this.isChanged);
  }

  openConfirmWindow(): void {
    this.modalService
      .openModal(ModalConfirmComponent, {
        dialogType: ConfirmationType.General,
        data: ['Verlassen', 'Hinweistext', 'Änderungen wurden nicht gespeichert. Speichern?'],
        autoFocus: false
      })
      .afterClosed()
      .subscribe((r) => {
        this.exit(!!r);
      });
  }
}
