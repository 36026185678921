<div class="wrapper" *ngIf="finishedLoading; else loader">
  <h3>Errechneter LV-Gesamtpreis: {{ calculatedServSpecTotal | projectCurrency }}</h3>

  <div class="table-container mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="positionType">
        <th mat-header-cell *matHeaderCellDef>Typ</th>
        <td mat-cell *matCellDef="let item">{{ item.element | elementType }}</td>
      </ng-container>

      <ng-container matColumnDef="itemNumber">
        <th mat-header-cell *matHeaderCellDef>OZ</th>
        <td mat-cell *matCellDef="let item">
          <span *ngIf="item.element.elementType === 'PositionDto' || item.element.elementType === 'ServiceSpecificationGroupDto'">{{
            item.element.itemNumber.stringRepresentation
          }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="shortText">
        <th mat-header-cell *matHeaderCellDef>Text</th>
        <td mat-cell *matCellDef="let item">{{ item.element.shortText }}</td>
      </ng-container>

      <ng-container matColumnDef="unitPrice">
        <th mat-header-cell *matHeaderCellDef class="right">EP</th>
        <td mat-cell *matCellDef="let item" class="right">
          <div class="price" *ngIf="item.element.elementType === 'PositionDto'">
            <input
              matInput
              type="text"
              matInputDecimalPlaces
              allowNumericWithDecimal
              [(ngModel)]="item.assumedPrice.unitPrice"
              [disabled]="item.assumedPrice.totalPrice || somePc(item.assumedPrice)"
              (change)="fixNumberValue(item.assumedPrice, 'unitPrice', item)"
            />
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef class="right">Menge</th>
        <td mat-cell *matCellDef="let item" class="right">
          <span *ngIf="item.element.elementType === 'PositionDto'">
            {{ $any(item.element).quantity | number: '1.3-3' }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="unitTag">
        <th mat-header-cell *matHeaderCellDef>Einheit</th>
        <td mat-cell *matCellDef="let item">
          <span *ngIf="item.element.elementType === 'PositionDto'">
            {{ $any(item.element).unitTag }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="totalPrice">
        <th mat-header-cell *matHeaderCellDef class="right">GP</th>
        <td mat-cell *matCellDef="let item" class="right">
          <div class="price" *ngIf="item.element.elementType === 'PositionDto'">
            <input
              matInput
              type="text"
              matInputDecimalPlaces
              allowNumericWithDecimal
              [(ngModel)]="item.assumedPrice.totalPrice"
              [disabled]="item.assumedPrice.unitPrice || somePc(item.assumedPrice)"
              (change)="fixNumberValue(item.assumedPrice, 'totalPrice', item)"
            />
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="priceComponents">
        <th mat-header-cell *matHeaderCellDef class="right">Preiskomponenten</th>
        <td mat-cell *matCellDef="let item" class="right">
          <ng-container *ngIf="item.element.elementType === 'PositionDto'">
            <input type="checkbox" #btnOpen hidden [checked]="false" />
            <div class="btn">
              <button
                mat-icon-button
                color="primary"
                [disabled]="!!item.assumedPrice.unitPrice || !!item.assumedPrice.totalPrice"
                (click)="btnOpen.checked = !btnOpen.checked"
              >
                <mat-icon *ngIf="!btnOpen.checked || !!item.assumedPrice.unitPrice || !!item.assumedPrice.totalPrice">visibility</mat-icon>
                <mat-icon *ngIf="btnOpen.checked && !item.assumedPrice.unitPrice && !item.assumedPrice.totalPrice">visibility_off</mat-icon>
              </button>
            </div>
            <div class="pc" [class.visible]="btnOpen.checked && !item.assumedPrice.unitPrice && !item.assumedPrice.totalPrice">
              <div *ngFor="let ps of item.assumedPrice.priceComponents" class="pc_item">
                <div style="margin-right: 5px; width: 100px; margin-bottom: 5px">{{ ps.label }}:</div>
                <div class="price">
                  <input
                    matInput
                    type="text"
                    matInputDecimalPlaces
                    allowNumericWithDecimal
                    [(ngModel)]="ps.value"
                    (change)="fixNumberValue(ps, 'value', item)"
                  />
                </div>
              </div>
            </div>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="calculationEstimationTotal">
        <th mat-header-cell *matHeaderCellDef class="right">Berechneter GP</th>
        <td mat-cell *matCellDef="let item" class="right">
          <div *ngIf="item.element.elementType === 'ServiceSpecificationGroupDto'">
            {{ calculatedTotalsByGroupId[item.element.id] | projectCurrency }}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplay" class="element-row"></tr>
    </table>
  </div>
</div>
<ng-template #loader>
  <div class="spinner">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
