import { Injectable, NgZone } from '@angular/core';

import { ElectronService } from './electron.service';

@Injectable({
  providedIn: 'root'
})
export class HelpViewMessengerService {
  constructor(
    private electronService: ElectronService,
    ngZone: NgZone
  ) {
    if (electronService.isElectron) {
      electronService.ipcRenderer.on('helpWindowClose', () => {
        ngZone.run(() => {
          if (this.electronService.isElectron) {
            this.electronService.ipcRenderer.send('helpWindowClose', {});
          }
        });
      });
    }
  }
}
