import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { GuidGenerator } from '@dangl/angular-material-shared/guid-generator';

import { Observable, Subject, catchError, filter, map, take } from 'rxjs';

import { Base64FileParameter, ProjectGet } from '../../../generated-client/generated-client';
import { AvaNotificationsService } from '../../../shared/services/ava-notifications.service';
import { ElectronService } from '../../../shared/services/electron/electron.service';

@Injectable({
  providedIn: 'root'
})
export class LocalProjectsService {
  constructor(
    private http: HttpClient,
    private avaNotificationsService: AvaNotificationsService,
    private electronService: ElectronService,
    private ngZone: NgZone,
    private router: Router
  ) {
    this.lastOpenedLocalProjectsSource.next(this.getLastUsedProjects());

    if (electronService.isElectron) {
      electronService.ipcRenderer.on(
        'LocalProjectSelected',
        (
          _,
          __,
          secondChannelData: {
            isAvailable: boolean;
            correlationId: string;
            projectId?: string;
            projectName?: string;
            filePath?: string;
          }[]
        ) => {
          if (secondChannelData?.length !== 1) {
            return;
          }

          const saveData = secondChannelData[0];
          ngZone.run(() => this.projectOpenSource.next(saveData));
        }
      );
    }
  }

  private lastOpenedLocalProjectsSource = new Subject<{ id: string; name: string; filePath: string; lastOpenedAtUtc: Date }[]>();
  public lastOpenedLocalProjects = this.lastOpenedLocalProjectsSource.asObservable();
  private projectOpenSource = new Subject<{
    isAvailable: boolean;
    correlationId: string;
    projectId?: string;
    projectName?: string;
    filePath?: string;
  }>();

  public activeProjectFilePath: string;

  public createLocalProject(
    databaseFileLocation: string,
    projectCreationModel: {
      name: string;
      buyer: string;
      description: string;
      serviceSpecificationFile: Base64FileParameter;
    }
  ): Observable<ProjectGet> {
    const subject = new Subject<ProjectGet>();
    this.http
      .post('/api/local-projects/database', {
        filePath: databaseFileLocation,
        name: projectCreationModel.name,
        buyer: projectCreationModel.buyer,
        description: projectCreationModel.description,
        serviceSpecificationFile: projectCreationModel.serviceSpecificationFile
      })
      .subscribe({
        next: (project: ProjectGet) => {
          this.setProjectLocationInBackend(project.id, databaseFileLocation).subscribe({
            next: () => {
              this.saveLocalProjectPath(project.id, project.name, databaseFileLocation);
              subject.next(project);
              subject.complete();
            },
            error: (error) => {
              subject.error(error);
              subject.complete();
              this.avaNotificationsService.error('Die Projektdatei konnte nicht gefunden werden');
            }
          });
        },
        error: (error) => {
          subject.error(error);
          subject.complete();
        }
      });

    return subject;
  }

  setProjectLocationInBackend(projectId: string, projectLocation: string): Observable<void> {
    return this.http
      .post('/api/local-projects/project-location', {
        projectId: projectId,
        projectLocation: projectLocation
      })
      .pipe(
        catchError((error) => {
          this.removeLocalProjectIfSaved(projectId);
          throw error;
        }),
        map(() => {})
      );
  }

  saveLocalProjectPath(projectId: string, name: string, filePath: string): void {
    const projectsArray = this.getLastUsedProjects();
    const existingProject = projectsArray.find((p) => p.id === projectId);

    // If the project exists, we'll remove it and put it at the top, otherwise we just insert at index 0
    if (existingProject) {
      projectsArray.splice(projectsArray.indexOf(existingProject), 1);
    }

    projectsArray.unshift({
      id: projectId,
      name,
      filePath,
      lastOpenedAtUtc: new Date()
    });

    // We're only saving the last 10 projects
    if (projectsArray.length > 10) {
      projectsArray.splice(10, projectsArray.length - 10);
    }

    // Then we're saving it again
    localStorage.setItem('localProjects', JSON.stringify(projectsArray));
  }

  removeLocalProjectIfSaved(projectId: string): void {
    const projectsArray = this.getLastUsedProjects();
    const existingProject = projectsArray.find((p) => p.id === projectId);

    // If the project exists, we'll remove it
    if (existingProject) {
      projectsArray.splice(projectsArray.indexOf(existingProject), 1);
    }

    localStorage.setItem('localProjects', JSON.stringify(projectsArray));
  }

  getLastUsedProjects(): { id: string; name: string; filePath: string; lastOpenedAtUtc: Date }[] {
    let currentValues = localStorage.getItem('localProjects');
    if (!currentValues) {
      currentValues = '[]';
    }

    return JSON.parse(currentValues) as { id: string; name: string; filePath: string; lastOpenedAtUtc: Date }[];
  }

  openLocalProjectFromFile(): void {
    const correlationId = GuidGenerator.generatePseudoRandomGuid();
    this.electronService.ipcRenderer.send('LoadProjectLocallyFromFile', {
      correlationId
    });

    this.projectOpenSource
      .pipe(
        filter((p) => p.correlationId === correlationId),
        take(1)
      )
      .subscribe((p) => {
        if (p.isAvailable) {
          this.openProject(p.filePath, p.projectId, p.projectName);
        }
      });
  }

  openProject(filePath: string, projectId: string, projectName: string): Observable<boolean> {
    const subject = new Subject<boolean>();
    this.activeProjectFilePath = filePath;
    this.saveLocalProjectPath(projectId, projectName, filePath);
    this.setProjectLocationInBackend(projectId, filePath).subscribe({
      next: () => {
        this.router.navigate(['/projects', projectId, 'service-specifications']);
        subject.next(true);
        subject.complete();
      },
      error: () => {
        this.avaNotificationsService.error('Die Projektdatei konnte nicht gefunden werden');
        subject.next(false);
        subject.complete();
      }
    });

    return subject;
  }
}
