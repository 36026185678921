<div mat-dialog-content>
  <h2>Ansichten auswählen</h2>
  <div *ngFor="let one of list">
    <mat-checkbox [(ngModel)]="one.checked">
      {{ one.name | embeddedViewNames }}
      <ng-container *ngIf="separatedList.includes(one.name)">(abgedockt)</ng-container>
    </mat-checkbox>
  </div>
</div>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close color="warn">Abbrechen</button>
  <button mat-raised-button color="primary" class="btn-action" (click)="save()">Speichern</button>
</mat-dialog-actions>
