import { CdkTrapFocus } from '@angular/cdk/a11y';
import { NgIf } from '@angular/common';
import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger, MatMenu, MatMenuContent, MatMenuItem } from '@angular/material/menu';

import { Subject, filter, first, switchMap, take, takeUntil } from 'rxjs';

import { DuplicateItemNumberListComponent } from '@serv-spec/components/duplicate-item-number-list/duplicate-item-number-list.component';

import {
  AvaProjectContentEditClient,
  AvaProjectContentEditOperation,
  IElementDto,
  ItemNumberSchemaDto,
  ItemNumberSchemaTierDto,
  ItemNumberSchemasClient,
  NoteTextDto,
  PositionDto,
  ProjectDto,
  ServiceSpecificationGroupDto
} from 'app/generated-client/generated-client';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { SelectedSpecificationElementMessengerService } from 'app/shared/services/messengers/selected-specification-element-messenger.service';

import { ItemNumberSelectionService } from '../../../../shared/services/item-number-selection.service';
import { SelectedSpecificationMessengerService } from '../../../../shared/services/messengers/selected-specification-messenger.service';
import { ServiceSpecificationsHasChangeService } from '../../../../shared/services/messengers/service-specifications-has-change.service';
import { ModalService } from '../../../../shared/services/modal.service';
import { FlatElementsService } from '../../../tree/services/flat-elements.service';

@Component({
  selector: 'pa-item-number-field',
  templateUrl: './item-number-field.component.html',
  styleUrls: ['./item-number-field.component.scss'],
  standalone: true,
  imports: [NgIf, CdkTrapFocus, FormsModule, MatMenuTrigger, MatMenu, MatMenuContent, MatMenuItem, MatIcon]
})
export class ItemNumberFieldComponent implements OnInit, OnDestroy {
  private _element: IElementDto;
  @Input() set element(value: IElementDto) {
    this._element = value;

    if (value == null) {
      this.itemNumberString = null;
      this.originalItemNumberString = null;
      return;
    }

    if (value.elementType === 'PositionDto' || value.elementType === 'ServiceSpecificationGroupDto') {
      this.itemNumberString = (value as PositionDto).itemNumber?.stringRepresentation || '';
    } else if (value.elementType === 'NoteTextDto') {
      this.itemNumberString = (value as NoteTextDto).identifier;
    }

    this.originalItemNumberString = this.itemNumberString;
  }
  get element(): IElementDto {
    return this._element;
  }
  isChangeItemNumber: boolean;
  itemNumberString: string;
  originalItemNumberString: string;
  projectId: string;
  avaProjectId: string;
  avaProject: ProjectDto;
  schema: ItemNumberSchemaDto;

  constructor(
    private ngZone: NgZone,
    private itemNumberSelectionService: ItemNumberSelectionService,
    private flatElementsService: FlatElementsService,
    private modalService: ModalService,
    private selectedSpecificationElementMessengerService: SelectedSpecificationElementMessengerService,
    private avaProjectContentEditClient: AvaProjectContentEditClient,
    private serviceSpecificationsHasChangeService: ServiceSpecificationsHasChangeService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private itemNumberSchemasClient: ItemNumberSchemasClient
  ) {}

  private $destroy = new Subject<void>();

  ngOnInit(): void {
    this.selectedSpecificationMessengerService.selectedServiceSpecification
      .pipe(
        takeUntil(this.$destroy),
        filter((s) => !!s)
      )
      .subscribe((s) => {
        this.projectId = s.parentProjectId;
        this.avaProjectId = s.avaProjectId;
        this.avaProject = s.project;
        this.getSchema();
      });
  }

  private getSchema(): void {
    this.itemNumberSchemasClient
      .getItemNumberSchema(this.projectId, this.avaProjectId)
      .subscribe((schema: ItemNumberSchemaDto) => (this.schema = schema));
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  changeItemNumber(): void {
    this.isChangeItemNumber = false;
    if (this.itemNumberString === this.originalItemNumberString) {
      // No change present
      return;
    }

    if (this.element.elementType == 'NoteTextDto') {
      this.changeItemNumberForNoteText(this.element as NoteTextDto);
      return;
    }

    const numberedElement = this.element as PositionDto | ServiceSpecificationGroupDto;

    numberedElement.itemNumber.stringRepresentation = this.itemNumberString;
    this.serviceSpecificationsHasChangeService
      .checkServiceSpecificationHasBeenEdited()
      .pipe(
        switchMap((r) => {
          if (r) {
            return this.avaProjectContentEditClient.editAvaProjectContent(this.projectId, this.avaProjectId, {
              operation: AvaProjectContentEditOperation.Edit,
              updateOperation: {
                element: this.element
              }
            });
          }
        })
      )
      .subscribe((res) => {
        if (res.project.serviceSpecifications[0].containsDuplicateItemNumbers) {
          this.flatElementsService.flatElementsDto
            .pipe(
              filter((v) => !!v),
              take(1)
            )
            .subscribe((elementsDto) => {
              const elements: (PositionDto | ServiceSpecificationGroupDto)[] = elementsDto.filter(
                (e) => e.elementType === 'PositionDto' || e.elementType === 'ServiceSpecificationGroupDto'
              );
              let itemsWithDuplicateNumber = [];
              let duplicateNumbers = [];
              duplicateNumbers = elements
                .map((e) => e.itemNumber.stringRepresentation)
                .map((e, i, final) => final.findIndex((el, index) => el === e && index !== i && i))
                .filter((obj) => elements[obj])
                .map((e) => elements[e].itemNumber.stringRepresentation);
              itemsWithDuplicateNumber = elements.filter((obj) =>
                duplicateNumbers.find((itemNumber) => itemNumber === obj.itemNumber.stringRepresentation)
              );
              if (itemsWithDuplicateNumber.length) {
                this.modalService
                  .openModal(DuplicateItemNumberListComponent, {
                    dialogType: ConfirmationType.General,
                    autoFocus: false,
                    restoreFocus: false,
                    disableClose: true,
                    data: itemsWithDuplicateNumber
                  })
                  .afterClosed()
                  .subscribe((selectedPosition) => {
                    if (selectedPosition) {
                      this.selectedSpecificationElementMessengerService.trySelectElementById(selectedPosition?.id);
                    }
                  });
              }
            });
        }
      });
  }

  private changeItemNumberForNoteText(noteText: NoteTextDto): void {
    noteText.identifier = this.itemNumberString;
    this.serviceSpecificationsHasChangeService
      .checkServiceSpecificationHasBeenEdited()
      .pipe(
        switchMap((r) => {
          if (r) {
            return this.avaProjectContentEditClient.editAvaProjectContent(this.projectId, this.avaProjectId, {
              operation: AvaProjectContentEditOperation.Edit,
              updateOperation: {
                element: this.element
              }
            });
          }
        })
      )
      .subscribe((res) => {
        if (res.project.serviceSpecifications[0].containsDuplicateItemNumbers) {
          this.flatElementsService.flatElementsDto
            .pipe(
              filter((v) => !!v),
              take(1)
            )
            .subscribe((elementsDto) => {
              const elements: (PositionDto | ServiceSpecificationGroupDto)[] = elementsDto.filter(
                (e) => e.elementType === 'PositionDto' || e.elementType === 'ServiceSpecificationGroupDto'
              );
              let itemsWithDuplicateNumber = [];
              let duplicateNumbers = [];
              duplicateNumbers = elements
                .map((e) => e.itemNumber.stringRepresentation)
                .map((e, i, final) => final.findIndex((el, index) => el === e && index !== i && i))
                .filter((obj) => elements[obj])
                .map((e) => elements[e].itemNumber.stringRepresentation);
              itemsWithDuplicateNumber = elements.filter((obj) =>
                duplicateNumbers.find((itemNumber) => itemNumber === obj.itemNumber.stringRepresentation)
              );
              if (itemsWithDuplicateNumber.length) {
                this.modalService
                  .openModal(DuplicateItemNumberListComponent, {
                    dialogType: ConfirmationType.General,
                    autoFocus: false,
                    restoreFocus: false,
                    disableClose: true,
                    data: itemsWithDuplicateNumber
                  })
                  .afterClosed()
                  .subscribe((selectedPosition) => {
                    if (selectedPosition) {
                      this.selectedSpecificationElementMessengerService.trySelectElementById(selectedPosition?.id);
                    }
                  });
              }
            });
        }
      });
  }

  createNewOrSelectExistingElement(): void {
    if (this.itemNumberString === this.originalItemNumberString) {
      // No change present
      return;
    }

    setTimeout(() => {
      this.ngZone.run(() => {
        this.itemNumberSelectionService
          .goToElementByItemNumber(this.itemNumberString)
          .pipe(first())
          .subscribe((hasChanged) => {
            if (!hasChanged) {
              this.itemNumberString = this.originalItemNumberString;
            }
          });
      });
    }, 0);
  }

  public inputItemNumber(event: KeyboardEvent): void {
    if (event.key !== 'F7') {
      event.stopPropagation();
    }
    if ((event.key.length === 1 && !event.ctrlKey) || (event.key === 'v' && event.ctrlKey)) {
      setTimeout(() => {
        const el = event.target as HTMLInputElement;
        el.value = this.changeItemNumberValue(el.value);
      }, 1);
    }
  }

  changeItemNumberValue(value: string): string {
    const { separator } = this.schema;
    const cleanedValue = value.split(separator).join('');
    let currentPos = 0;
    let newValue = '';
    this.schema.tiers.forEach((item: ItemNumberSchemaTierDto, index: number) => {
      const { length } = item;
      let add = cleanedValue.slice(currentPos, currentPos + length);
      if (add.length === length && index < this.schema.tiers.length - 1) {
        add += separator;
      }
      currentPos += length;
      newValue += add;
    });
    return newValue;
  }

  openMenu(event: MouseEvent, viewChild: MatMenuTrigger): void {
    event.preventDefault();
    viewChild.openMenu();
  }
}
