import { NgIf, AsyncPipe } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { combineLatestWith, filter, switchMap, take } from 'rxjs/operators';

import {
  PageQuantityTakeOffRowModel,
  QuantityTakeOffAttachmentGet,
  QuantityTakeOffAttachmentsClient
} from 'app/generated-client/generated-client';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedQuantityTakeOffMessengerService } from 'app/shared/services/messengers/selected-quantity-take-off-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

import { ImgSrcPipe } from '../../../../../../../../shared/pipes/img-src.pipe';
import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-invoice-image-modal',
  templateUrl: './invoice-image-modal.component.html',
  styleUrls: ['./invoice-image-modal.component.scss'],
  standalone: true,
  imports: [NgIf, FlexLayoutDirective, MatButton, AsyncPipe, ImgSrcPipe]
})
export class InvoiceImageModalComponent implements OnInit {
  attachmentImageFileId: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { element: PageQuantityTakeOffRowModel; isReadOnly: boolean },
    @Optional() private dialogRef: MatDialogRef<InvoiceImageModalComponent>,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private selectedQuantityTakeOffMessengerService: SelectedQuantityTakeOffMessengerService,
    private quantityTakeOffAttachmentsClient: QuantityTakeOffAttachmentsClient
  ) {}

  ngOnInit(): void {
    if (this.data?.element?.quantityTakeOffAttachmentId) {
      this.selectedProjectMessengerService.selectedProject
        .pipe(
          combineLatestWith(this.selectedSpecificationMessengerService.selectedServiceSpecification),
          filter(([project, avaProject]) => !!project && !!avaProject),
          take(1),
          switchMap(([project, avaProject]) => {
            return this.quantityTakeOffAttachmentsClient.getQuantityTakeOffAttachmentByIdForQuantityTakeOff(
              project.id,
              avaProject.avaProjectId,
              this.data?.element?.quantityTakeOffAttachmentId
            );
          })
        )
        .subscribe((attachment: QuantityTakeOffAttachmentGet) => {
          if (attachment && attachment.quantityTakeOffAttachmentImageFiles.length) {
            this.attachmentImageFileId = attachment.quantityTakeOffAttachmentImageFiles.find(
              (q) => q.id === this.data.element.quantityTakeOffAttachmentImageFileId
            )?.imageFileId;
          }
        });
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  deleteImage(): void {
    this.dialogRef.close(true);
  }
}
