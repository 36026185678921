import { Component, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';

import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-estimation-quantity-export-setting',
  templateUrl: './estimation-quantity-export-setting.component.html',
  styleUrls: ['./estimation-quantity-export-setting.component.scss'],
  standalone: true,
  imports: [FlexLayoutDirective, MatCheckbox, FormsModule, MatButton]
})
export class EstimationQuantityExportSettingComponent {
  includeHeaderOnlyOnFirstPage = false;
  constructor(@Optional() private matDialogRef: MatDialogRef<EstimationQuantityExportSettingComponent>) {}

  createExport(): void {
    this.matDialogRef.close({ includeHeaderOnlyOnFirstPage: this.includeHeaderOnlyOnFirstPage });
  }
}
