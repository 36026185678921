import { NgIf, NgClass, DecimalPipe, PercentPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow
} from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject, combineLatest } from 'rxjs';
import { first, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { EstimationQuantityExportSettingComponent } from '@serv-spec/components/invoice/components/estimation-quantity-export-setting/estimation-quantity-export-setting.component';
import { PositionListWithoutQuantityComponent } from '@serv-spec/components/invoice/components/position-list-without-quantity/position-list-without-quantity.component';

import {
  AvaProjectQuantityEstimationTransferPut,
  IElementDto,
  PositionDto,
  ProjectDto,
  ProjectGet,
  ProjectStatus,
  QuantityEstimationTransferRoundingPut,
  QuantityTakeOffGet,
  QuantityTakeOffsClient,
  ReportGetOfQuantityEstimationReport,
  ReportsClient,
  ServiceSpecificationGroupDto
} from 'app/generated-client/generated-client';
import { ModalConfirmComponent } from 'app/shared/components/modal-confirm/modal-confirm.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { PrintViewMessengerService } from 'app/shared/services/electron/print-view-messenger.service';
import { FileSaverService } from 'app/shared/services/file-saver.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedQuantityTakeOffMessengerService } from 'app/shared/services/messengers/selected-quantity-take-off-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';

import { InvoiceRoundingComponent } from '../../../../../../../../shared/components/invoice-rounding/invoice-rounding.component';
import { PositionTextPipe } from '../../../../../../../../shared/pipes/ui-data-display/position-text.pipe';
import { ProjectCurrencyPipe } from '../../../../../../../../shared/pipes/ui-data-display/project-currency.pipe';
import { ProjectStatusPipe } from '../../../../../../../../shared/pipes/ui-data-display/project-status.pipe';
import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';
import { FlatElementsService } from '../../../../../../../tree/services/flat-elements.service';

@Component({
  selector: 'pa-estimation-quantity',
  templateUrl: './estimation-quantity.component.html',
  styleUrls: ['./estimation-quantity.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FlexLayoutDirective,
    MatProgressSpinner,
    NgClass,
    MatButton,
    MatIcon,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    DecimalPipe,
    PercentPipe,
    ProjectStatusPipe,
    ProjectCurrencyPipe,
    PositionTextPipe
  ]
})
export class EstimationQuantityComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  saved: boolean;
  showingPdf = false;
  includeHeaderOnlyOnFirstPage = false;
  projectId: string;
  projectStatus: ProjectStatus;
  avaProjectId: string;
  avaProject: ProjectDto;
  originalAvaProject: ProjectDto;
  quantityTakeOffId: string;
  QtoName: string;
  positionsList: PositionDto[] = [];
  filteredList: PositionDto[];
  originalPrices: {
    [positionId: string]: {
      unitPrice: number;
      totalPrice: number;
      deltaPercent: number;
    };
  } = {};
  filter = '';
  totalPrice: number;
  originalTotalPrice: number;
  totalPriceDeltaPercent: number;
  totalPriceDeltaAbsolute: number;
  columns = [
    'itemNumber',
    'shortText',
    'quantity',
    'unitTag',
    'unitPrice',
    'totalPrice',
    'delta',
    'originalUnitPrice',
    'originalTotalPrice'
  ];
  noChangedStatus: boolean;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private quantityTakeOffsClient: QuantityTakeOffsClient,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private selectedQuantityTakeOffMessengerService: SelectedQuantityTakeOffMessengerService,
    private modalService: ModalService,
    private avaNotificationsService: AvaNotificationsService,
    private flatElementsService: FlatElementsService,
    private fileSaverService: FileSaverService,
    private reportsClient: ReportsClient,
    private printViewMessengerService: PrintViewMessengerService
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.selectedProjectMessengerService.selectedProject,
      this.selectedSpecificationMessengerService.selectedServiceSpecification,
      this.selectedQuantityTakeOffMessengerService.selectedQuantityTakeOff
    ])
      .pipe(takeUntil(this.$destroy))
      .subscribe(([project, avaProject, qto]: [ProjectGet, { avaProjectId: string; project: ProjectDto }, QuantityTakeOffGet]) => {
        this.projectId = project.id;
        this.projectStatus = project.status;

        switch (project.status) {
          case 'Offer':
          case 'Awarded':
            this.noChangedStatus = true;
            break;
        }

        this.avaProjectId = avaProject.avaProjectId;
        this.originalAvaProject = avaProject.project;
        this.originalPrices = {};
        this.createList(this.originalAvaProject.serviceSpecifications[0].elements, true);
        this.quantityTakeOffId = qto.id;
        this.QtoName = qto.name;
        this.showChanges();
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  back(): void {
    this.router.navigate(['..'], {
      relativeTo: this.route
    });
  }

  showChanges(): void {
    this.quantityTakeOffsClient
      .calculateAvaProjectWithQuantityEstimationQuantities(this.projectId, this.avaProjectId, this.quantityTakeOffId)
      .subscribe((projectDto: ProjectDto) => {
        this.avaProject = projectDto;
        this.totalPrice = projectDto.serviceSpecifications[0].totalPrice;
        this.originalTotalPrice = this.originalAvaProject.serviceSpecifications[0].totalPrice;
        this.totalPriceDeltaPercent = (this.originalTotalPrice ? this.totalPrice / this.originalTotalPrice : 0) - 1;
        this.totalPriceDeltaAbsolute = this.totalPrice - this.originalTotalPrice;
        this.createList(projectDto.serviceSpecifications[0].elements);
        this.filterList();
        this.isLoading = false;
      });
  }

  trySetQuantity(): void {
    this.flatElementsService.flatElementsDto.pipe(take(1)).subscribe((flatElements) => {
      this.quantityTakeOffsClient
        .checkIfAllPositionsHaveNonZeroQuantity(this.projectId, this.avaProjectId, this.quantityTakeOffId)
        .pipe(
          map((checkResult) => checkResult.positionIdsWithZeroQuantity.map((positionId) => flatElements.find((fe) => fe.id === positionId)))
        )
        .pipe(
          take(1),
          switchMap((positonList: PositionDto[]) =>
            !positonList.length
              ? this.modalService
                  .openModal(ModalConfirmComponent, {
                    dialogType: ConfirmationType.General,
                    data: ['Update', 'Projektmenge', `Projektmenge ${this.QtoName}`]
                  })
                  .afterClosed()
              : this.modalService
                  .openModal(PositionListWithoutQuantityComponent, {
                    dialogType: ConfirmationType.General,
                    data: positonList,
                    restoreFocus: false,
                    disableClose: true
                  })
                  .afterClosed()
          )
        )
        .subscribe((isConfirm: boolean) => {
          if (isConfirm) {
            if (this.noChangedStatus) {
              this.trySetQuantityRed();
            } else {
              this.setQuantity();
            }
          }
        });
    });
  }

  trySetQuantityRed(): void {
    this.modalService
      .openModal(ModalConfirmComponent, {
        dialogType: ConfirmationType.General,
        data: ['Update', 'Projektmenge', `Projektmenge ${this.QtoName}`, 'red']
      })
      .afterClosed()
      .subscribe((isConfirm2: boolean) => {
        if (isConfirm2) {
          this.setQuantity();
        }
      });
  }

  setQuantity(): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    const transfer: AvaProjectQuantityEstimationTransferPut = {
      avaProjectId: this.avaProjectId,
      quantityTakeOffId: this.quantityTakeOffId
    };

    const projectId = this.projectId;

    this.modalService
      .openModal(InvoiceRoundingComponent, { dialogType: ConfirmationType.General, data: { isModal: true } })
      .afterClosed()
      .subscribe((options: { roundingOptions?: QuantityEstimationTransferRoundingPut[] | null; roundingMargin?: number }) => {
        transfer.roundingOptions = options.roundingOptions;
        if (options.roundingMargin) {
          transfer.marginPercentage = options.roundingMargin * 0.01;
        }
        this.quantityTakeOffsClient.setAvaProjectQuantitiesFromQuantityEstimation(projectId, this.avaProjectId, transfer).subscribe({
          next: () => {
            this.avaNotificationsService.success('Mengen im Projekt aktualisiert');
            this.isLoading = false;
          },
          error: () => {
            this.avaNotificationsService.error('Fehler beim Speichern der Mengen im Projekt');
            this.isLoading = false;
          }
        });
      });
  }

  private createList(elements: IElementDto[], isOriginal = false): void {
    elements.forEach((element: IElementDto) => {
      switch (element.elementType) {
        case 'ServiceSpecificationGroupDto':
          this.createList((<ServiceSpecificationGroupDto>element).elements, isOriginal);
          break;
        case 'PositionDto':
          if (!isOriginal) {
            this.positionsList.push(element as PositionDto);
            const newTotal = (element as PositionDto).totalPrice;
            const previousTotal = this.originalPrices[element.id]?.totalPrice || 0;

            if (previousTotal !== 0) {
              const delta = newTotal / previousTotal - 1;
              this.originalPrices[element.id].deltaPercent = delta;
            }
          } else {
            this.originalPrices[element.id] = {
              unitPrice: (element as PositionDto).unitPrice,
              totalPrice: (element as PositionDto).totalPrice,
              deltaPercent: 0
            };
          }
          break;
      }
    });
  }

  filterList(): void {
    const filterWord = this.filter.toLowerCase();
    this.filteredList = this.positionsList.filter((item: PositionDto) => {
      return item.itemNumber.stringRepresentation.toLowerCase().includes(filterWord) || item.shortText.toLowerCase().includes(filterWord);
    });
  }

  openEstimationInPdfView(): void {
    this.isLoading = true;
    this.modalService
      .openModal(EstimationQuantityExportSettingComponent, {
        dialogType: ConfirmationType.General,
        autoFocus: false,
        restoreFocus: false,
        disableClose: true
      })
      .afterClosed()
      .pipe(
        tap((settings) => (this.includeHeaderOnlyOnFirstPage = settings.includeHeaderOnlyOnFirstPage)),
        switchMap(() => {
          return this.reportsClient.getQuantityEstimationReport(
            this.projectId,
            this.avaProjectId,
            this.quantityTakeOffId,
            true,
            this.includeHeaderOnlyOnFirstPage
          );
        })
      )
      .subscribe({
        next: (report: ReportGetOfQuantityEstimationReport) => {
          this.printViewMessengerService.showPdfPreview(
            report.htmlReport,
            report.reportData.quantityTakeOffName,
            report.isLandscape,
            report.pdfReportBase64,
            this.includeHeaderOnlyOnFirstPage
          );
          this.printViewMessengerService
            .waitForPrintViewReady()
            .pipe(takeUntil(this.$destroy))
            .subscribe(() => {
              this.isLoading = false;
              this.showingPdf = true;
            });
          this.printViewMessengerService.printViewClosed.pipe(first()).subscribe(() => {
            this.showingPdf = false;
            this.isLoading = false;
          });
        },
        error: () => {
          this.isLoading = false;
          this.avaNotificationsService.error('Fehler beim Erstellen der Druckvorschau');
        }
      });
  }
}
