import { Pipe, PipeTransform } from '@angular/core';

import { PositionTypeDto } from '../../../generated-client/generated-client';

@Pipe({
  name: 'positionType',
  standalone: true
})
export class PositionTypePipe implements PipeTransform {
  transform(value: PositionTypeDto): string {
    switch (value) {
      case PositionTypeDto.Alternative:
        return 'Alternativposition';

      case PositionTypeDto.Optional:
        return 'Bedarfsposition';

      case PositionTypeDto.Regular:
        return 'Normalposition';
    }

    return null;
  }
}
