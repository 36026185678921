import { NgIf, NgFor } from '@angular/common';
import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  ItemNumberSchemaDto,
  ItemNumberSchemaTierDto,
  ItemNumberSchemaTierTypeDto,
  ItemNumberSchemasClient,
  ItemNumberTypeDto,
  ProjectGet
} from 'app/generated-client/generated-client';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';

import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-setting-schema',
  templateUrl: './setting-schema.component.html',
  styleUrls: ['./setting-schema.component.scss'],
  standalone: true,
  imports: [
    MatDialogContent,
    NgIf,
    FlexLayoutDirective,
    MatProgressSpinner,
    FormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatDivider,
    NgFor,
    MatIconButton,
    MatIcon,
    MatButton
  ]
})
export class SettingSchemaComponent implements OnInit, OnDestroy {
  isCreateMode = false;
  itemNumberSchema: ItemNumberSchemaDto;
  savedItemNumberSchema: ItemNumberSchemaDto;
  hasLoaded = false;
  isLoading = false;
  isChanged = false;
  isEdit = false;
  listItemNumberTypeDto = Object.keys(ItemNumberTypeDto) as ItemNumberTypeDto[];
  listItemNumberSchemaTierTypeDto: string[] = Object.keys(ItemNumberSchemaTierTypeDto);
  example: string;
  private projectId: string;
  private avaProjectId: string;
  private $destroy = new Subject<boolean>();

  private defaultTier: ItemNumberSchemaTierDto = {
    length: 2,
    type: ItemNumberTypeDto.Numeric,
    tierType: ItemNumberSchemaTierTypeDto.Group,
    isLot: false,
    increment: 1
  };
  private defaultListTier: ItemNumberSchemaTierDto[] = [
    { ...this.defaultTier },
    { ...this.defaultTier },
    { ...this.defaultTier, length: 4, tierType: ItemNumberSchemaTierTypeDto.Position }
  ];
  private defaultSchema: ItemNumberSchemaDto = {
    totalLength: this.defaultListTier.reduce((sum, item) => sum + item.length, 0),
    tiers: this.defaultListTier,
    separator: '.',
    filler: '0',
    skipNonExistingLevelsInPositionItemNumbers: false,
    skippedTiersFiller: '_',
    schemaIsCorrectlyDefined: false
  };

  constructor(
    private itemNumberSchemasClient: ItemNumberSchemasClient,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    @Optional() private matDialogRef: MatDialogRef<SettingSchemaComponent>,
    private avaNotificationsService: AvaNotificationsService
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.selectedProjectMessengerService.selectedProject,
      this.selectedSpecificationMessengerService.selectedServiceSpecification
    ])
      .pipe(takeUntil(this.$destroy))
      .subscribe(([project, specification]: [ProjectGet, { avaProjectId: string }]) => {
        this.projectId = project?.id;
        this.avaProjectId = specification?.avaProjectId;
        if (this.avaProjectId) {
          this.tryGetSchema();
        } else {
          this.isCreateMode = true;
          this.getDefaultSchema();
        }
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  private tryGetSchema(): void {
    this.itemNumberSchemasClient.getItemNumberSchema(this.projectId, this.avaProjectId).subscribe((schema: ItemNumberSchemaDto) => {
      this.getVariables(schema);
      this.hasLoaded = true;
    });
  }

  private getVariables(schema: ItemNumberSchemaDto): void {
    this.itemNumberSchema = JSON.parse(JSON.stringify(schema));
    this.savedItemNumberSchema = JSON.parse(JSON.stringify(schema));
    this.example = SettingSchemaComponent.getExample(this.itemNumberSchema);
  }

  public updateSchema(): void {
    this.isLoading = true;
    if (!this.isCreateMode) {
      this.itemNumberSchemasClient.updateItemNumberSchemaForAvaProject(this.projectId, this.avaProjectId, this.itemNumberSchema).subscribe(
        (itemNumberSchema: ItemNumberSchemaDto) => {
          this.isLoading = false;
          this.isChanged = false;
          this.isEdit = false;
          this.getVariables(itemNumberSchema);
          this.avaNotificationsService.success('OZ Maske gespeichert.');
        },
        () => {
          this.isLoading = false;
          this.avaNotificationsService.error('Fehler beim Speichern der OZ Maske.');
        }
      );
    } else {
      this.isLoading = false;
      this.isChanged = false;
      this.isEdit = false;
    }
  }

  public cancelChange(): void {
    if (this.isCreateMode && !this.isEdit) {
      this.matDialogRef.close();
    } else {
      const tiers = this.savedItemNumberSchema.tiers.map((item) => ({ ...item }));
      this.itemNumberSchema = { ...this.savedItemNumberSchema, tiers };
      this.isEdit = false;
      this.isChanged = false;
    }
  }

  public static getExample(schema: ItemNumberSchemaDto): string {
    const example = schema.tiers
      .map((item, index) => {
        const length = item.length;
        const word = (schema.filler ? schema.filler.repeat(length) : '') + (index + 1);
        return word.slice(-length, word.length) + (schema.separator || '');
      })
      .join('');
    return example;
  }

  newTier(): void {
    this.itemNumberSchema.tiers.push({});
    this.isChanged = true;
    setTimeout(() => {
      const el = document.querySelector('.list.last input') as HTMLInputElement;
      el?.focus();
    }, 1);
  }

  removeTier(index: number): void {
    this.itemNumberSchema.tiers = this.itemNumberSchema.tiers.filter((_, ind) => ind !== index);
    this.isChanged = true;
  }

  getDefaultSchema(): void {
    this.getVariables(this.defaultSchema);
    this.hasLoaded = true;
  }

  createSchema(): void {
    this.matDialogRef.close(this.itemNumberSchema);
  }
}
