import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProjectGet, ProjectsClient } from 'app/generated-client/generated-client';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';

import { AvaNotificationsService } from '../../../../../../shared/services/ava-notifications.service';
import { ProjectsService } from '../../../../../../shared/services/lightquery/projects.service';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss'],
  standalone: true,
  imports: [NgIf, FlexLayoutDirective, MatProgressSpinner, MatFormField, MatLabel, MatInput, FormsModule, MatButton]
})
export class EditProjectComponent implements OnInit, OnDestroy {
  private $destroy = new Subject<boolean>();
  private originalProject: ProjectGet;
  currentProject: ProjectGet;
  isLoading = false;
  constructor(
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private projectsClient: ProjectsClient,
    private projectsService: ProjectsService,
    private avaNotificationsService: AvaNotificationsService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((project: ProjectGet) => {
      if (project) {
        this.originalProject = project;
        this.currentProject = JSON.parse(JSON.stringify(project));
        this.isLoading = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  editProject(): void {
    this.isLoading = true;

    this.projectsClient
      .setProjectName(this.currentProject.id, {
        name: this.currentProject.name,
        description: this.currentProject.description
      })
      .subscribe(
        (project: ProjectGet) => {
          this.isLoading = false;
          this.avaNotificationsService.success('Name und Beschreibung gespeichert.');
          this.projectsService.forceRefresh();
          Object.assign(this.originalProject, project);
        },
        () => {
          this.isLoading = false;
          this.avaNotificationsService.error('Fehler beim Speichern der Projektdaten');
        }
      );
  }
}
