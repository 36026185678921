<div mat-dialog-content>
  <h1>Reihenfolge Ändern</h1>
  <ng-container *ngIf="!isLoading; else spiner">
    <form (ngSubmit)="saveElement()" disabled>
      <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
        <div class="example-box" *ngFor="let element of elements" cdkDrag>
          <ng-container *ngIf="element.elementTypeDiscriminator === 'NoteTextDto'">
            <mat-icon>subject</mat-icon>
            <ng-container *ngIf="element.shortText">
              {{ element.shortText }}
            </ng-container>
            <ng-container *ngIf="!element.shortText"> Hinweis </ng-container>
          </ng-container>

          <ng-container *ngIf="element.elementTypeDiscriminator === 'ExecutionDescriptionDto'">
            <mat-icon>event</mat-icon>
            {{ element.identifier }} - {{ element.label }}
          </ng-container>

          <ng-container *ngIf="element.elementTypeDiscriminator === 'PositionDto'">
            <mat-icon>add</mat-icon>
            {{ element.itemNumber.stringRepresentation }} - {{ element.shortText }}
          </ng-container>

          <ng-container *ngIf="element.elementTypeDiscriminator === 'ServiceSpecificationGroupDto'">
            <mat-icon>folder_open</mat-icon>
            {{ element.itemNumber.stringRepresentation }} - {{ element.shortText }}
          </ng-container>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="12px" class="buttons">
        <button mat-raised-button color="primary" [disabled]="isLoading">Speichern</button>
        <button mat-raised-button type="button" mat-dialog-close>Abbrechen</button>
      </div>
    </form>
  </ng-container>
  <ng-template #spiner>
    <div *ngIf="isLoading">
      <div fxFlex fxLayoutAlign="center center">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </ng-template>
</div>
