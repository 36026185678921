import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationMainService } from './main/pagination-main.service';
import { TextTemplateGet } from '../../../generated-client/generated-client';

@Injectable({
  providedIn: 'root'
})
export class TextTemplatesService extends PaginationMainService<TextTemplateGet> {
  constructor(protected http: HttpClient) {
    super(http);
    this.beginBaseUrl('/api/text-templates');
  }
}
