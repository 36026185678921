<div fxLayoutGap="12px" class="filter">
  <mat-form-field fxFlex="1 0">
    <mat-label>Suche</mat-label>
    <input matInput [(ngModel)]="filterText" (ngModelChange)="changeFilter()" />
  </mat-form-field>
</div>

<div class="mat-elevation-z8 wrapper">
  <table mat-table [dataSource]="taxRatesService" matSort (matSortChange)="taxRatesService.onSort($event)">
    <ng-container matColumnDef="country">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Land</th>
      <td mat-cell *matCellDef="let element">{{ element.country }}</td>
    </ng-container>

    <ng-container matColumnDef="countryCode">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Ländercode</th>
      <td mat-cell *matCellDef="let element">{{ element.countryCode }}</td>
    </ng-container>

    <ng-container matColumnDef="rate">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Mehrwertsteuersatz</th>
      <td mat-cell *matCellDef="let element">{{ element.rate | percent: '1.2-2' }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" class="master-table-tr" (click)="selectItem(row)"></tr>
  </table>
  <pa-upper-paginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    [upperPaginated]="taxRatesPaginated"
    [upperService]="taxRatesService"
    (page)="taxRatesService.onPage($event)"
  >
  </pa-upper-paginator>
</div>
