import { NgIf, NgFor } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTabNav, MatTabLink } from '@angular/material/tabs';
import { NavigationEnd, Router, RouterLinkActive, RouterLink, RouterOutlet } from '@angular/router';

import { Subject } from 'rxjs';
import { filter, takeUntil, takeWhile } from 'rxjs/operators';

import { AdditionSubscriptionService } from 'app/shared/services/addition-subscription.service';

import { IncludesPipe } from '../../../../shared/pipes/includes.pipe';

import { CurrentTabComponentService } from './../../services/current-tab-component.service';

@Component({
  selector: 'pa-master-data',
  templateUrl: './master-data.component.html',
  styleUrls: ['./master-data.component.scss'],
  standalone: true,
  imports: [NgIf, MatTabNav, NgFor, MatTabLink, RouterLinkActive, RouterLink, RouterOutlet, IncludesPipe]
})
export class MasterDataComponent implements OnInit, OnDestroy {
  private $destroy: Subject<boolean> = new Subject<boolean>();
  tabs = [
    { path: 'master-addition-groups', viewValue: 'Zuschlagsgruppen' },
    { path: 'articles', viewValue: 'Artikel' },
    { path: 'equipments', viewValue: 'Geräte' },
    { path: 'casing', viewValue: 'Schalung' },
    { path: 'sub-contractor-services', viewValue: 'Subunternehmerleistungen' },
    { path: 'miscellaneous', viewValue: 'Verschiedene Produkte' },
    { path: 'reports', viewValue: 'Berichte' },
    { path: 'templates', viewValue: 'Textvorlagen' },
    { path: 'tax-rates', viewValue: 'Mehrwertsteuersätze' },
    { path: 'contacts', viewValue: 'Kontakte' },
    { path: 'catalogs', viewValue: 'Produktkataloge' }
  ];
  currentRoute: string;

  constructor(
    private additionSubscriptionService: AdditionSubscriptionService,
    public router: Router,
    private currentTabComponentService: CurrentTabComponentService
  ) {
    this.router.events
      .pipe(
        filter((event: NavigationEnd) => event instanceof NavigationEnd),
        takeWhile((event: NavigationEnd) => event.id === 1),
        takeUntil(this.$destroy)
      )
      .subscribe((event: NavigationEnd) => {
        const index = this.tabs.findIndex((v) => event.url.includes(v.path) === true);
        this.currentRoute = this.tabs[index].path;
      });
  }

  ngOnInit(): void {
    if (this.currentRoute) {
      this.currentTabComponentService.setCurrentTab(this.currentRoute);
    } else {
      this.currentTabComponentService.setCurrentTab('master-addition-groups');
    }
    this.additionSubscriptionService.setSelectedAdditionSub('master-data');
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
    this.currentTabComponentService.setCurrentTab(null);
  }

  selectedTab(tab: string): void {
    this.currentTabComponentService.setCurrentTab(tab);
  }
}
