import { NgIf, NgFor } from '@angular/common';
import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProjectFileService } from '@project/components/project-files/services/project-file.service';

import { ProjectFileFolderGet, ProjectFileGet, ProjectGet } from '../../../../../../generated-client/generated-client';
import { SelectedProjectMessengerService } from '../../../../../../shared/services/messengers/selected-project-messenger.service';
import { SelectedSortMethodService } from '../../services/selected-sort-method.service';

@Component({
  selector: 'pa-project-files-select',
  templateUrl: './project-files-select.component.html',
  styleUrls: ['./project-files-select.component.scss'],
  standalone: true,
  imports: [NgIf, MatFormField, MatLabel, MatSelect, FormsModule, NgFor, MatOption]
})
export class ProjectFilesSelectComponent implements OnInit, OnDestroy {
  selectedProject: string;
  isShowSortingFilter = false;
  selectedFolder: ProjectFileFolderGet[] = [];
  sortBy = [
    {
      value: 'date increasing',
      displayValue: 'Datum aufsteigen'
    },
    {
      value: 'date decreasing',
      displayValue: 'Datum absteigend'
    },
    {
      value: 'alphabetically',
      displayValue: 'Alphabetisch'
    }
  ];

  sorting = 'alphabetically';
  sortingItems: ProjectFileGet[];
  $destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private selectedSortMethodService: SelectedSortMethodService,
    public projectFileService: ProjectFileService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.selectedProjectMessengerService.selectedProject.pipe(takeUntil(this.$destroy)).subscribe((project: ProjectGet) => {
      if (project) {
        this.selectedProject = project.name;
      }
    });
    this.projectFileService
      .getAllOpenedFiles()
      .pipe(takeUntil(this.$destroy))
      .subscribe((projectFile) => {
        if (projectFile.length) {
          this.isShowSortingFilter = projectFile.filter((f) => !f.isFolder).length > 1;
          this.cd.detectChanges();
        } else {
          this.isShowSortingFilter = false;
          this.cd.detectChanges();
        }
        if (!this.isShowSortingFilter && this.sorting !== 'alphabetically') {
          this.sorting = 'alphabetically';
          this.selectedSortMethodService.setSelectedSortMethod(this.sorting);
        }
      });

    this.setSelectedGroup();
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  setSelectedGroup(): void {
    this.selectedSortMethodService.setSelectedSortMethod(this.sorting);
  }
}
