<div mat-dialog-content>
  <form (ngSubmit)="createElement()" [formGroup]="newElementForm">
    <p>Container: {{ parent?.itemNumber?.stringRepresentation ?? 'LV' }}</p>
    <fieldset *ngIf="selectedElement">
      <legend>Relative Position zum ausgewählten Element</legend>
      <div [class.root]="newElementForm.value.isRoot" class="name">
        Element: <span class="name-text">{{ this.selectedElement | positionText }}</span>
      </div>
      <mat-radio-group class="radio-group" formControlName="relation">
        <mat-radio-button class="radio-button" *ngFor="let mode of modes" [value]="mode">
          {{ mode }}
        </mat-radio-button>
      </mat-radio-group>
    </fieldset>
    <div>
      <mat-form-field appearance="fill" class="type-select">
        <mat-label>Elementtyp</mat-label>
        <mat-select name="status" formControlName="elementType">
          <mat-option *ngFor="let item of listTypes" [value]="item">{{ item | elementTypeClass }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="new-element-short-text">
        <mat-label>Kurztext</mat-label>
        <input matInput required formControlName="shortText" />
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="12px" class="buttons">
      <button mat-raised-button color="primary" [disabled]="newElementForm.invalid">Speichern</button>
      <button mat-raised-button type="button" mat-dialog-close>Abbrechen</button>
    </div>
  </form>
</div>
