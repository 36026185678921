import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { PersonGet } from '../../../generated-client/generated-client';

@Injectable({
  providedIn: 'root'
})
export class SelectedPersonMessengerService {
  private selectedPersonSource = new ReplaySubject<PersonGet>(1);
  selectedPerson = this.selectedPersonSource.asObservable();

  setSelectedPerson(selectedPerson: PersonGet): void {
    this.selectedPersonSource.next(selectedPerson);
  }
}
