import { NgIf, DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent } from '@angular/material/dialog';

import { ErrorReportGet, ErrorReportsClient } from 'app/generated-client/generated-client';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { ErrorReportsService } from 'app/shared/services/lightquery/error-reports.service';

import { getAppConfig } from '../../../../app-config-accessor';

@Component({
  selector: 'pa-error-report-detail',
  templateUrl: './error-report-detail.component.html',
  styleUrls: ['./error-report-detail.component.scss'],
  standalone: true,
  imports: [MatDialogContent, NgIf, MatButton, MatChipListbox, MatChipOption, DatePipe]
})
export class ErrorReportDetailComponent {
  imageFileUrl: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public errorReport: ErrorReportGet,
    private errorReportsClient: ErrorReportsClient,
    private errorReportsService: ErrorReportsService,
    private matDialogRef: MatDialogRef<ErrorReportDetailComponent>,
    private notificationsService: AvaNotificationsService
  ) {
    if (errorReport.imageFileId) {
      const backendUrl = getAppConfig().backendUrl;
      this.imageFileUrl = `${backendUrl}/api/files/${errorReport.imageFileId}`;
    }
  }

  resolve(): void {
    this.errorReportsClient.markErrorReportAsResolved(this.errorReport.id).subscribe(
      () => {
        this.notificationsService.success('Als erledigt markiert');
        this.errorReportsService.forceRefresh();
        this.dismiss();
      },
      (error) => {
        this.notificationsService.error('Fehler, Bericht kann nicht auf erledigt gesetzt werden');
        console.error(error);
      }
    );
  }

  dismiss(): void {
    this.matDialogRef.close();
  }
}
