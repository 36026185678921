import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ifcElementType',
  standalone: true
})
export class IfcElementTypePipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'IfcBeam':
        return 'Balken';

      case 'IfcBuildingElementProxy':
        return 'Bauelement';

      case 'IfcColumn':
        return 'Stütze';

      case 'IfcCovering':
        return 'Belag';

      case 'IfcCurtainWall':
        return 'Vorhangfassade';

      case 'IfcDoor':
        return 'Tür';

      case 'IfcFooting':
        return 'Fundament';

      case 'IfcFurniture':
        return 'Möbel';

      case 'IfcFurnishingElement':
        return 'Raumausstattung';

      case 'IfcMember':
        return 'Stab';

      case 'IfcOpeningElement':
        return 'Öffnung';

      case 'IfcPile':
        return 'Fundament';

      case 'IfcPlate':
        return 'Platte';

      case 'IfcRailing':
        return 'Geländer';

      case 'IfcRamp':
        return 'Rampe';

      case 'IfcRoof':
        return 'Dach';

      case 'IfcShadingDevice':
        return 'Verschattung';

      case 'IfcSlab':
        return 'Decke';

      case 'IfcSpace':
        return 'Raum';

      case 'IfcStair':
        return 'Treppe';

      case 'IfcStairFlight':
        return 'Treppenlauf';

      case 'IfcWall':
        return 'Wand';

      case 'IfcWallStandardCase':
        return 'Wand (Standard)';

      case 'IfcWindow':
        return 'Fenster';

      case 'IfcProject':
        return 'Projekt';

      case 'IfcBuildingStorey':
        return 'Geschoss';
    }

    return value;
  }
}
