import { CommonModule } from '@angular/common';
import { Component, inject, Output, EventEmitter, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { SelectingElementModalComponent } from '@serv-spec/components/selecting-element-modal/selecting-element-modal.component';
import { SelectionElementListComponent } from '@serv-spec/components/selection-element-list/selection-element-list.component';
import { ConfirmationType } from '@shared/models/dialog-config.model';
import { ModalService } from '@shared/services/modal.service';
import { ElementSelection } from 'app/generated-client/generated-client';
import { filter } from 'rxjs';

@Component({
  selector: 'pa-selecting-element-buttons',
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  templateUrl: './selecting-element-buttons.component.html',
  styleUrls: ['./selecting-element-buttons.component.scss']
})
export class SelectingElementButtonsComponent {
  @Input() isShowElementSelectionTableButton = true;
  @Output() elementSelectionChanged: EventEmitter<ElementSelection> = new EventEmitter<ElementSelection>();
  elementSelection: ElementSelection = null;
  modalService = inject(ModalService);

  selectElements(): void {
    this.modalService
      .openModal(SelectingElementModalComponent, {
        dialogType: ConfirmationType.General,
        autoFocus: false,
        restoreFocus: false,
        data: this.elementSelection
      })
      .afterClosed()
      .subscribe((e: ElementSelection) => {
        if (e) {
          this.elementSelection = e;
        } else {
          this.elementSelection = null;
        }
        this.elementSelectionChanged.emit(this.elementSelection);
      });
  }

  openElementSelectionTable(): void {
    this.modalService
      .openModal(SelectionElementListComponent, {
        dialogType: ConfirmationType.General,
        autoFocus: false,
        restoreFocus: false,
        data: {
          isOpenInModalWindow: true
        }
      })
      .afterClosed()
      .pipe(filter((r) => !!r))
      .subscribe((e: ElementSelection) => {
        this.elementSelection = e;
        this.elementSelectionChanged.emit(this.elementSelection);
      });
  }
}
