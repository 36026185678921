import { NgIf, NgFor } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogClose } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatSelect } from '@angular/material/select';

import { of, Subject, switchMap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  AvaProjectContentEditClient,
  AvaProjectContentEditCreateType,
  AvaProjectContentEditOperation,
  AvaProjectContentEditPut,
  AvaProjectContentEditResultGet,
  IElementDto,
  ServiceSpecificationGroupDto
} from 'app/generated-client/generated-client';
import { ServiceSpecificationsHasChangeService } from 'app/shared/services/messengers/service-specifications-has-change.service';
import { PositionHasCalculationService } from 'app/shared/services/position-has-calculation.service';
import { SpinnerOverlayService } from 'app/shared/services/spinner-overlay.service';

import { ElementTypeClassPipe } from '../../../../../../../../shared/pipes/ui-data-display/element-type-class.pipe';
import { PositionTextPipe } from '../../../../../../../../shared/pipes/ui-data-display/position-text.pipe';
import { AvaNotificationsService } from '../../../../../../../../shared/services/ava-notifications.service';
import { SelectedSpecificationMessengerService } from '../../../../../../../../shared/services/messengers/selected-specification-messenger.service';
import { FlexLayoutDirective } from '../../../../../../../flex-layout/flex-layout.directive';

@Component({
  selector: 'pa-lv-editor-new-element-modal',
  templateUrl: './lv-editor-new-element-modal.component.html',
  styleUrls: ['./lv-editor-new-element-modal.component.scss'],
  standalone: true,
  imports: [
    MatDialogContent,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    MatRadioGroup,
    NgFor,
    MatRadioButton,
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    MatInput,
    FlexLayoutDirective,
    MatButton,
    MatDialogClose,
    ElementTypeClassPipe,
    PositionTextPipe
  ]
})
export class LvEditorNewElementModalComponent implements OnInit, OnDestroy {
  newElementForm: FormGroup;
  selectedElement: IElementDto;
  parent: ServiceSpecificationGroupDto | null;
  isOnlyRoot: boolean;
  modes: string[] = ['Davor', 'Danach', 'Darunter'];
  listTypes: string[] = ['NoteTextDto', 'PositionDto', 'ServiceSpecificationGroupDto'];
  shortText: string;
  private $destroy: Subject<boolean> = new Subject();
  private projectId: string | null = null;
  private avaProjectId: string | null = null;
  private selectedNewElementType: AvaProjectContentEditCreateType = AvaProjectContentEditCreateType.Position;

  constructor(
    @Optional() private matDialogRef: MatDialogRef<LvEditorNewElementModalComponent>,
    private avaProjectContentEditClient: AvaProjectContentEditClient,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private avaNotificationsService: AvaNotificationsService,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      selectedElement?: IElementDto;
      parentElement?: ServiceSpecificationGroupDto;
      preselection?: string;
    },
    private serviceSpecificationsHasChangeService: ServiceSpecificationsHasChangeService,
    private positionHasCalculationService: PositionHasCalculationService,
    private spinnerOverlayService: SpinnerOverlayService
  ) {
    this.newElementForm = new FormGroup({
      relation: new FormControl(this.modes[0]),
      elementType: new FormControl('PositionDto'),
      shortText: new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {
    this.selectedElement = this.data.selectedElement;

    if (this.data.preselection) {
      this.newElementForm.value.relation = this.data.preselection;
    }

    this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(takeUntil(this.$destroy)).subscribe((p) => {
      if (p) {
        this.projectId = p.parentProjectId;
        this.avaProjectId = p.avaProjectId;
        this.parent = this.data.parentElement?.id === p.project.serviceSpecifications[0].id ? null : this.data.parentElement;
      }
    });

    if (this.selectedElement && this.selectedElement.elementType !== 'ServiceSpecificationGroupDto') {
      this.modes = this.modes.filter((i) => i !== 'Darunter');
    }

    if (!this.selectedElement) {
      this.isOnlyRoot = true;
      this.newElementForm.get('relation').disable();
    }

    this.newElementForm
      .get('elementType')
      .valueChanges.pipe(takeUntil(this.$destroy))
      .subscribe((elementType: string) => {
        switch (elementType) {
          case 'PositionDto':
            this.selectedNewElementType = AvaProjectContentEditCreateType.Position;
            break;

          case 'NoteTextDto':
            this.selectedNewElementType = AvaProjectContentEditCreateType.NoteText;
            break;

          case 'ServiceSpecificationGroupDto':
            this.selectedNewElementType = AvaProjectContentEditCreateType.ServiceSpecificationGroup;
            break;
        }
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  checkValue(event: MouseEvent): void {
    if (this.isOnlyRoot) {
      event.preventDefault();
    }
  }

  createElement(): void {
    this.spinnerOverlayService.show();
    const createOperation: AvaProjectContentEditPut = {
      operation: AvaProjectContentEditOperation.Create,
      createOperation: {
        elementType: this.selectedNewElementType,
        parentId: this.parent?.id,
        shortText: this.newElementForm.value.shortText
      }
    };

    const selectedRelation = this.newElementForm.value.relation;
    if (selectedRelation != null) {
      switch (selectedRelation) {
        case 'Davor':
          createOperation.createOperation.nextId = this.selectedElement.id;
          break;

        case 'Danach':
          createOperation.createOperation.previousId = this.selectedElement.id;
          break;

        case 'Darunter':
          createOperation.createOperation.parentId = this.selectedElement.id;
          break;
        default:
          throw new Error('Invalid relation encountered when trying to create element: ' + selectedRelation);
      }
    } else {
      createOperation.createOperation.parentId = this.parent?.id;
    }

    this.serviceSpecificationsHasChangeService
      .checkServiceSpecificationHasBeenEdited()
      .pipe(
        switchMap((r) => {
          if (r) {
            return this.avaProjectContentEditClient.editAvaProjectContent(this.projectId, this.avaProjectId, createOperation);
          }
          return of(null);
        })
      )
      .subscribe({
        next: (editResult: AvaProjectContentEditResultGet) => {
          if (editResult) {
            this.spinnerOverlayService.hide();
            this.avaNotificationsService.success('Element erstellt.');
            this.matDialogRef.close(editResult);
            this.positionHasCalculationService.updateList();
          } else {
            this.spinnerOverlayService.hide();
          }
        },
        error: () => {
          this.spinnerOverlayService.hide();
          this.avaNotificationsService.error('Fehler beim Erstellen des Elements.');
        }
      });
  }
}
