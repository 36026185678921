import { Injectable } from '@angular/core';

import { CalculationEntry, PositionCalculation, PriceRoundingModeDto } from '../../../../../generated-client/generated-client';

import { RoundingService } from './rounding.service';

import { CalculationResult, Calculator } from 'antlr-calculator';

@Injectable({
  providedIn: 'root'
})
export class CalculationService {
  static recalculateCalculationRows(calculation: PositionCalculation | null): {
    isSuccess: boolean;
    value?: PositionCalculation;
    errorMessage?: string;
  } {
    if (calculation == null) {
      throw new Error('calculation can not be null');
    }

    if (calculation.calculationEntries == null) {
      throw new Error('Calculation entries can not be null');
    }

    if (calculation.calculationEntries.length == 0) {
      throw new Error('There are no calculation entries present in the calculation');
    }

    if (calculation.additions == null) {
      throw new Error('Additions can not be null');
    }

    const sortedEntries: CalculationEntry[] = calculation.calculationEntries.sort(
      (e1: CalculationEntry, e2: CalculationEntry) => e1.rowIndex - e2.rowIndex
    );

    let hasFoundErrorInFormulas = false;

    for (let i = 0; i < sortedEntries.length; i++) {
      const entry: CalculationEntry = sortedEntries[i];

      CalculationService.ensureEntryHasNoUndefinedValues(entry);

      if (entry.priceComponents != null) {
        const additionsMatchPriceComponents =
          entry.priceComponents.filter((pc) => !!calculation.additions.priceComponents?.find((pca) => pca.priceComponent === pc.label))
            .length === entry.priceComponents.length;

        if (!additionsMatchPriceComponents) {
          const errorMessage = 'Not all price components in the calculation have a matching addition factor.';
          return {
            isSuccess: false,
            errorMessage: errorMessage
          };
        }
      }

      if (!this.isNullOrWhitespace(entry.formulaFactor)) {
        const calculationResult: CalculationResult = Calculator.calculate(entry.formulaFactor);
        if (!calculationResult.isValid) {
          const errorMessage =
            `Invalid formula for factor given:\r\n${entry.formulaFactor}\r\n` +
            `Calculation result:\r\n${JSON.stringify(calculationResult, null, 2)}`;
          hasFoundErrorInFormulas = true;
          entry.hasFormulaError = true;
          entry.formulaError = errorMessage;
          entry.factor = null;
        } else {
          entry.factor = this.withRounding(calculationResult.result, 5);
          entry.hasFormulaError = false;
          entry.formulaError = null;
        }
      } else {
        entry.hasFormulaError = false;
        entry.formulaError = null;
      }

      if (entry.factor && typeof entry.factor === 'string') {
        entry.factor = +(<string>entry.factor).replace(',', '.');
      }
      if (entry.priceComponents && entry.priceComponents.length > 0) {
        entry.priceComponents.forEach((pc) => {
          if (pc.sum && typeof pc.sum == 'string') {
            pc.sum = +(<string>pc.sum).replace(',', '.');
          }
        });
      }
    }

    calculation.entriesWithErrors = hasFoundErrorInFormulas;

    return {
      isSuccess: true,
      value: calculation
    };
  }

  /**
   * There's actually a history behind this method:
   * When deleting rows in the Calculation table, we've discovered that
   * some values still were shown in the table.
   * This had to do with the virtual scroll implementation, and the bug was reported
   * in issue #1292 (https://github.com/Dangl-IT/Dangl.PfeifferAVA/issues/1292)
   *
   * It turned out that it was due to an old row having a value, e.g. in 'formulaFactor',
   * but then it got replaced with a row with a similar 'rowIndex' (basically the row that was
   * moved to its position), but by default it had 'formulaFactor' set to 'undefined'. So the
   * value has not been updated.
   *
   * This method now sets those values to null, to ensure that they're correctly picked up.
   * I think there's otherwise some internal stuff going on with 'Object.keys# that prevents it.
   *
   * @param entry the CalculationEntry to clean
   */
  private static ensureEntryHasNoUndefinedValues(entry: CalculationEntry) {
    if (entry.formulaFactor === undefined) {
      entry.formulaFactor = null;
    }
    if (entry.factor === undefined) {
      entry.factor = null;
    }
    if (entry.divisor === undefined) {
      entry.divisor = null;
    }
    if (entry.labourHours === undefined) {
      entry.labourHours = null;
    }
    if (entry.priceComponents) {
      entry.priceComponents.forEach((pc) => {
        if (pc.sum === undefined) {
          pc.sum = null;
        }
      });
    }
  }

  private static withRounding(value: number, decimalPlaces?: number): number {
    if (decimalPlaces == null) {
      decimalPlaces = 2;
    }
    return RoundingService.withRounding(value, decimalPlaces, PriceRoundingModeDto.Normal);
  }

  private static isNullOrWhitespace(value: string): boolean {
    if (!value || value.length === 0) {
      return true;
    }

    return /^\s*$/.test(value);
  }
}
