import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatTabNav, MatTabLink } from '@angular/material/tabs';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

import { getAppConfig } from 'app/app-config-accessor';

@Component({
  selector: 'pa-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss'],
  standalone: true,
  imports: [MatTabNav, MatTabLink, RouterLink, RouterLinkActive, NgIf, RouterOutlet]
})
export class ManagementComponent {
  isQtoOnlyMode = getAppConfig().isQtoOnlyMode;
}
