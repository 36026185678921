<div class="wrapper">
  <mat-icon color="warn" class="close-icon" (click)="dialogRef.close(null)">close</mat-icon>
  <h2>Formelfehler in Abrechnung</h2>
  <ng-container *ngIf="errorsData">
    <div *ngIf="errorsData?.positionErrors?.length">
      <mat-selection-list [multiple]="false">
        <mat-list-option color="primary" *ngFor="let position of positions" (click)="handlerClick(position)">
          <div>
            <span> {{ $any(position)?.itemNumber?.stringRepresentation }} - {{ position | positionText }} - Zeile:</span>
            <ng-container *ngFor="let err of errorsData.positionErrors">
              <span *ngIf="err.avaPositionId === position.id"> {{ err.errorRowIndex }}</span>
            </ng-container>
          </div>
        </mat-list-option>
      </mat-selection-list>
    </div>
    <div *ngIf="errorsData?.pageErrors?.length">
      <mat-selection-list [multiple]="false">
        <mat-list-option
          color="primary"
          *ngFor="let page of pageQtos; let i = index"
          (click)="handlerClickPage(page, errorsData.pageErrors[i].errorRowIndex)"
        >
          <div>
            <span> {{ page.pageNumber }} - {{ page.name }} </span>
            <span> - Zeile {{ errorsData.pageErrors[i].errorRowIndex }}</span>
          </div>
        </mat-list-option>
      </mat-selection-list>
    </div>
  </ng-container>
</div>
