import { Component, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

import { CompaniesClient } from 'app/generated-client/generated-client';

@Component({
  selector: 'pa-company-add',
  templateUrl: './company-add.component.html',
  styleUrls: ['./company-add.component.scss'],
  standalone: true,
  imports: [FormsModule, MatDialogContent, ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatButton]
})
export class CompanyAddComponent implements OnInit {
  formCompany: FormGroup;

  constructor(
    private companiesClient: CompaniesClient,
    @Optional() private dialogRef: MatDialogRef<CompanyAddComponent>
  ) {}

  ngOnInit(): void {
    this.formCompany = new FormGroup({
      name: new FormControl(''),
      street: new FormControl(''),
      zipCode: new FormControl(''),
      city: new FormControl(''),
      country: new FormControl(''),
      countryCode: new FormControl(''),
      phone: new FormControl(''),
      email: new FormControl(''),
      vatId: new FormControl('')
    });
  }

  createCompany(): void {
    const newCompany = this.formCompany.value;
    this.companiesClient.createCompany(newCompany).subscribe(() => {
      this.dialogRef.close(true);
    });
  }
}
