import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { CompanyAddComponent } from './components/company-add/company-add.component';
import { CompanyInfoComponent } from './components/company-info/company-info.component';
import { CompanyListComponent } from './components/company-list/company-list.component';
import { ErrorReportDetailComponent } from './components/error-report-detail/error-report-detail.component';
import { ErrorReportsComponent } from './components/error-reports/error-reports.component';
import { FilesComponent } from './components/files/files.component';
import { ManagementComponent } from './components/management/management.component';
import { ModalToDeleteCompanyComponent } from './components/modal-to-delete-company/modal-to-delete-company.component';
import { ProjectRestoreComponent } from './components/project-restore/project-restore.component';
import { ManagementRoutingModule } from './management-routing.module';

@NgModule({
  exports: [CompanyListComponent],
  imports: [
    CommonModule,
    ManagementRoutingModule,
    SharedModule,
    ErrorReportDetailComponent,
    ErrorReportsComponent,
    FilesComponent,
    ManagementComponent,
    CompanyInfoComponent,
    CompanyListComponent,
    ModalToDeleteCompanyComponent,
    CompanyAddComponent,
    ProjectRestoreComponent
  ]
})
export class ManagementModule {}
