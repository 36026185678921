<div class="table-wrapper" #scrolledElement [ngClass]="{ group: isGroup }">
  <table cdk-table [dataSource]="dataSource" [ngStyle]="{ opacity: isLoading && !isGroup ? 0 : 1 }">
    <ng-container cdkColumnDef="text">
      <th cdk-header-cell *cdkHeaderCellDef #cellTotalWidth class="left"></th>
      <td cdk-cell *cdkCellDef="let element" class="text" [class.text-group]="isGroup">
        <span>{{ element.text }}</span>
      </td>
    </ng-container>

    <ng-container cdkColumnDef="labourHours">
      <th cdk-header-cell *cdkHeaderCellDef #cellTotalWidth class="right">
        <ng-container *ngIf="isGroup">Lohnstunden</ng-container>
      </th>
      <td cdk-cell *cdkCellDef="let element" class="number" [class.number-group]="isGroup">
        <span>{{ element.labourHours | number: '1.3-3' }}</span>
      </td>
    </ng-container>

    <ng-container cdkColumnDef="labourHoursSum">
      <th cdk-header-cell *cdkHeaderCellDef #cellTotalWidth class="right">
        <ng-container *ngIf="isGroup">Lohnsumme</ng-container>
      </th>
      <td cdk-cell *cdkCellDef="let element" class="number" [class.number-group]="isGroup">
        <span>{{ element.labourHoursSum | number: '1.2-2' }}</span>
      </td>
    </ng-container>

    <ng-container *ngFor="let priceComp of additions; let i = index" cdkColumnDef="pc_{{ priceComp }}">
      <th cdk-header-cell *cdkHeaderCellDef #cellTotalWidth class="right">
        <ng-container *ngIf="isGroup">{{ priceComp }}</ng-container>
      </th>

      <td cdk-cell *cdkCellDef="let element" class="number" [class.number-group]="isGroup">
        {{ element.priceComponents[priceComp] | number: '1.2-2' }}
      </td>
    </ng-container>

    <ng-container cdkColumnDef="sum">
      <th cdk-header-cell *cdkHeaderCellDef #cellTotalWidth class="right">
        <ng-container *ngIf="isGroup">Summe</ng-container>
      </th>
      <td cdk-cell *cdkCellDef="let element" class="number" [class.number-group]="isGroup">
        <span>{{ element.sum | number: '1.2-2' }}</span>
      </td>
    </ng-container>

    <ng-container cdkColumnDef="tail">
      <th cdk-header-cell *cdkHeaderCellDef #cellTotalWidth></th>
      <td cdk-cell *cdkCellDef></td>
    </ng-container>

    <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
    <tr cdk-row *cdkRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
