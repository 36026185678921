import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';

import { ContactGet, ContactType } from 'app/generated-client/generated-client';
import { UpperPaginatorComponent } from 'app/shared/components/upper-paginator/upper-paginator.component';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { ContactTypePipe } from 'app/shared/pipes/ui-data-display/contact-type.pipe';
import { ContactsService } from 'app/shared/services/lightquery/contacts.service';
import { ModalService } from 'app/shared/services/modal.service';

import { ContactsFormComponent } from '../contacts-form/contacts-form.component';
import { ImportContactFileComponent } from '../import-contact-file/import-contact-file.component';

@Component({
  selector: 'pa-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSelectModule,
    ContactTypePipe,
    MatButtonModule,
    UpperPaginatorComponent,
    MatSortModule,
    MatDialogModule
  ]
})
export class ContactsComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() isInProjectCreation: boolean;
  @Output() selectContact = new EventEmitter<ContactGet>();
  contactTypes = Object.keys(ContactType) as ContactType[];
  filter = '';
  @Input() contactType: ContactType;
  private _selectedContactType: ContactType | string = '';
  private $destroy: Subject<boolean> = new Subject<boolean>();

  get selectedContactType(): ContactType | string {
    return this._selectedContactType;
  }
  set selectedContactType(value: ContactType | string) {
    this._selectedContactType = value;
    this.contactsService.setQueryParameter('contactType', value);
  }

  constructor(
    public contactsService: ContactsService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() private matDialogRef: MatDialogRef<ContactsComponent>,
    private modalService: ModalService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.contactsService.initService({
      filtering: this.filter,
      paging: { page: 1, pageSize: 10 },
      sorting: { propertyName: 'contactNumber', isDescending: false },
      sortTableObj: this.sort
    });
    if (this.contactType) {
      this.selectedContactType = this.contactType;
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
    this.contactsService.setQueryParameter('contactType', null);
  }

  selectedContact(contact: ContactGet): void {
    if (this.isInProjectCreation) {
      this.selectContact.emit(contact);
    } else {
      if (!this.data.isCreatePriceInuiry) {
        this.router.navigate([`${this.router.url}/${contact.id}`]);
      } else {
        this.matDialogRef.close(contact);
      }
    }
  }

  newContact(): void {
    this.modalService.openModal(ContactsFormComponent, { dialogType: ConfirmationType.General });
  }

  openImportContactModal(): void {
    this.modalService.openModal(ImportContactFileComponent, { dialogType: ConfirmationType.General });
  }
}
