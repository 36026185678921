import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TableWrapperComponent } from '@shared/components/table-wrapper/table-wrapper.component';

import { ContactsComponent } from '@contacts/components/contacts/contacts.component';

import { SharedModule } from '../../shared/shared.module';

import { AddProjectComponent } from './components/add-project/add-project.component';
import { ProjectsCardsComponent } from './components/projects-cards/projects-cards.component';
import { ProjectsControlComponent } from './components/projects-control/projects-control.component';
import { ProjectsOverviewComponent } from './components/projects-overview/projects-overview.component';
import { ProjectsTableComponent } from './components/projects-table/projects-table.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { SelectProjectAvaProjectComponent } from './components/select-project-ava-project/select-project-ava-project.component';
import { ProjectsRoutingModule } from './projects-routing.module';

import { ManagementModule } from '../management/management.module';

@NgModule({
  imports: [
    CommonModule,
    ProjectsRoutingModule,
    SharedModule,
    ManagementModule,
    ContactsComponent,
    ContactsComponent,
    TableWrapperComponent,
    AddProjectComponent,
    ProjectsCardsComponent,
    ProjectsComponent,
    ProjectsOverviewComponent,
    ProjectsTableComponent,
    ProjectsControlComponent,
    SelectProjectAvaProjectComponent
  ],
  exports: [ProjectsTableComponent, ProjectsControlComponent]
})
export class ProjectsModule {}
