import { CdkDrag, CdkDragPlaceholder } from '@angular/cdk/drag-drop';
import { NgStyle, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SubPositionGet } from 'app/generated-client/generated-client';

@Component({
  selector: 'pa-position-node',
  templateUrl: './position-node.component.html',
  styleUrls: ['./position-node.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CdkDrag, NgStyle, NgIf, CdkDragPlaceholder]
})
export class PositionNodeComponent {
  @Input() element: SubPositionGet;
  constructor() {}
}
