import { Injectable } from '@angular/core';
import { CompanyGet } from 'app/generated-client/generated-client';
import { HttpClient } from '@angular/common/http';
import { PaginationMainService } from './main/pagination-main.service';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService extends PaginationMainService<CompanyGet> {
  constructor(protected http: HttpClient) {
    super(http);
    this.beginBaseUrl('/api/companies');
  }
}
