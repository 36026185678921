import { NgIf } from '@angular/common';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatFormField, MatLabel, MatHint } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSelect } from '@angular/material/select';

import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

import {
  AddressesClient,
  CalculationsChecksClient,
  ContactGet,
  ContactsClient,
  EfbPageType,
  ElementSelection,
  PaginationResultOfAddressGet,
  ProjectDto,
  ProjectGet,
  ReportGetOfServiceSpecificationReport,
  ReportsClient,
  TextTemplateType,
  TextTemplatesClient
} from 'app/generated-client/generated-client';
import { ConfirmationType } from 'app/shared/models/dialog-config.model';
import { AvaNotificationsService } from 'app/shared/services/ava-notifications.service';
import { PrintViewMessengerService } from 'app/shared/services/electron/print-view-messenger.service';
import { SelectedProjectMessengerService } from 'app/shared/services/messengers/selected-project-messenger.service';
import { SelectedSpecificationMessengerService } from 'app/shared/services/messengers/selected-specification-messenger.service';
import { ModalService } from 'app/shared/services/modal.service';

import { ProjectQuantityEstimationService } from '../../../../../../shared/services/messengers/project-quantity-estimation.service';
import { FlexLayoutDirective } from '../../../../../flex-layout/flex-layout.directive';

import { CheckErrorsAndShowNotificationService } from './../../services/check-errors-and-show-notification.service';

import { CalculationCheckModalWindowComponent } from '../calculation-check-modal-window/calculation-check-modal-window.component';
import { ReportTextSelectionComponent } from '../report-text-selection/report-text-selection.component';
import { RiskAndProfitModalComponent } from '../risk-and-profit-modal/risk-and-profit-modal.component';
import { SelectingElementButtonsComponent } from '../selecting-element-buttons/selecting-element-buttons.component';

@Component({
  selector: 'pa-calculation-pdf-export',
  templateUrl: './calculation-pdf-export.component.html',
  styleUrls: ['./calculation-pdf-export.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FlexLayoutDirective,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatHint,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatCheckbox,
    MatSelect,
    MatOption,
    SelectingElementButtonsComponent,
    MatButton,
    MatProgressSpinner
  ]
})
export class CalculationPdfExportComponent implements OnInit, OnDestroy {
  private $destroy: Subject<boolean> = new Subject<boolean>();
  awardIdentifier: string;
  showingPdf = false;
  waitingForPdf = false;
  requestEnRoute = false;
  serviceSpecificationId: string;
  address: string;
  private projectId: string;
  showRowMarkedI = false;
  exportLongTextCompletely = false;
  includeHeaderOnlyOnFirstPage = false;
  excludePositions = false;
  includePositionsWithoutTotal = false;
  elementSelection: ElementSelection = null;
  includeSignatureField = true;
  customOfferExportHeaderText = 'Angebot';
  includeProjectInfo = true;
  includeAdditions = true;
  includeSumList = true;
  includeQuantityEstimation = false;
  projectHasQuantityCalculation = false;
  includeWarningsForCalculationErrors = true;
  includeOnlyOverview = false;
  offerStartText: string;
  offerEndText: string;
  private lastStartText: string;
  private lastEndText: string;

  maxElementExportDepth: number = -1;

  constructor(
    private printViewMessengerService: PrintViewMessengerService,
    private reportsClient: ReportsClient,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private avaNotificationsService: AvaNotificationsService,
    private ngZone: NgZone,
    private modalService: ModalService,
    private selectedProjectMessengerService: SelectedProjectMessengerService,
    private contactsClient: ContactsClient,
    private addressesClient: AddressesClient,
    private calculationChecksClient: CalculationsChecksClient,
    private textTemplatesClient: TextTemplatesClient,
    private checkErrorsAndShowNotificationService: CheckErrorsAndShowNotificationService,
    private projectQuantityEstimationService: ProjectQuantityEstimationService
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.selectedProjectMessengerService.selectedProject,
      this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(filter((s) => !!s))
    ])
      .pipe(takeUntil(this.$destroy))
      .subscribe(([p, s]: [ProjectGet, { project: ProjectDto; avaProjectId: string }]) => {
        this.serviceSpecificationId = s.avaProjectId;
        this.projectId = p.id;
        this.getProjectInfo(p, s.project);

        this.textTemplatesClient
          .getLastUsedHtmlTextForAvaProject(this.projectId, this.serviceSpecificationId, TextTemplateType.OfferStart)
          .subscribe({
            next: (text) => (this.offerStartText = text.htmlText)
          });
        this.textTemplatesClient
          .getLastUsedHtmlTextForAvaProject(this.projectId, this.serviceSpecificationId, TextTemplateType.OfferEnd)
          .subscribe({
            next: (text) => (this.offerEndText = text.htmlText)
          });
      });

    this.printViewMessengerService.printViewClosed.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.ngZone.run(() => {
        this.showingPdf = false;
        this.waitingForPdf = false;
        this.requestEnRoute = false;
      });
    });

    this.projectQuantityEstimationService.currentProjectHasQuantityCalculation
      .pipe(takeUntil(this.$destroy))
      .subscribe((hasQuantityCalculation) => {
        this.projectHasQuantityCalculation = hasQuantityCalculation;
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  generatePdfAndShowPrintView(): void {
    this.waitingForPdf = true;
    this.requestEnRoute = true;

    combineLatest([
      this.calculationChecksClient.getCalculationCheckResult(this.projectId, this.serviceSpecificationId),
      this.reportsClient.getServiceSpecificationCalculation(this.serviceSpecificationId, true, this.includeHeaderOnlyOnFirstPage, {
        elementSelection: this.elementSelection,
        includeInternalRows: this.showRowMarkedI,
        includeProjectInfo: this.includeProjectInfo,
        includeAdditions: this.includeAdditions,
        includeSumList: this.includeSumList,
        includeQuantityCalculation: this.includeQuantityEstimation
      })
    ]).subscribe({
      next: ([checkResult, report]) => {
        const showPdf = () => {
          this.printViewMessengerService.showPdfPreview(
            report.htmlReport,
            'Kalkulation',
            report.isLandscape,
            report.pdfReportBase64,
            this.includeHeaderOnlyOnFirstPage
          );
          this.printViewMessengerService.waitForPrintViewReady().subscribe(() => {
            this.showingPdf = true;
            this.waitingForPdf = false;
            this.requestEnRoute = false;
          });
        };

        const hasErrorsInCalculation = this.checkErrorsAndShowNotificationService.checkForErrorsAndShowErrorNotifications(checkResult);

        if (hasErrorsInCalculation) {
          this.modalService
            .openModal(CalculationCheckModalWindowComponent, { dialogType: ConfirmationType.General, disableClose: true })
            .afterClosed()
            .subscribe(() => showPdf());
        } else {
          showPdf();
        }
      },
      error: () => {
        this.waitingForPdf = false;
        this.requestEnRoute = false;
        this.showingPdf = false;
        this.avaNotificationsService.error('Fehler beim Erstellen der PDF-Datei');
      }
    });
  }

  generateEfb221(): void {
    this.modalService
      .openModal(RiskAndProfitModalComponent, { dialogType: ConfirmationType.General, data: this.projectId })
      .afterClosed()
      .subscribe((e) => {
        if (e) {
          this.generateEfbReportAndShowPrintView(EfbPageType.Page221);
        }
      });
  }

  generateEfb223(): void {
    this.modalService
      .openModal(RiskAndProfitModalComponent, { dialogType: ConfirmationType.General, data: this.projectId })
      .afterClosed()
      .subscribe((e) => {
        if (e) {
          this.generateEfbReportAndShowPrintView(EfbPageType.Page223);
        }
      });
  }

  private generateEfbReportAndShowPrintView(pageType: EfbPageType): void {
    this.waitingForPdf = true;
    this.requestEnRoute = true;
    this.reportsClient
      .getEfbDocumentsForServiceSpecification(this.serviceSpecificationId, true, this.includeHeaderOnlyOnFirstPage, {
        awardIdentifier: this.awardIdentifier,
        pageType: pageType,
        elementSelection: this.elementSelection
      })
      .subscribe({
        next: (report) => {
          this.printViewMessengerService.showPdfPreview(
            report.htmlReport,
            'EFB_Blätter',
            report.isLandscape,
            report.pdfReportBase64,
            this.includeHeaderOnlyOnFirstPage
          );
          this.printViewMessengerService.waitForPrintViewReady().subscribe(() => {
            this.showingPdf = true;
            this.waitingForPdf = false;
            this.requestEnRoute = false;
          });
        },
        error: () => {
          this.showingPdf = false;
          this.waitingForPdf = false;
          this.requestEnRoute = false;
          this.avaNotificationsService.error('Fehler beim Erstellen der PDF-Datei');
        }
      });
  }

  generateFinishingForm(): void {
    this.waitingForPdf = true;
    this.requestEnRoute = true;
    this.reportsClient
      .getCalculationFinishingFormForServiceSpecification(
        this.serviceSpecificationId,
        true,
        this.includeHeaderOnlyOnFirstPage,
        this.includePositionsWithoutTotal,
        {
          elementSelection: this.elementSelection
        }
      )
      .subscribe({
        next: (report) => {
          this.printViewMessengerService.showPdfPreview(
            report.htmlReport,
            'Schlussblatt',
            report.isLandscape,
            report.pdfReportBase64,
            this.includeHeaderOnlyOnFirstPage
          );
          this.printViewMessengerService.waitForPrintViewReady().subscribe(() => {
            this.showingPdf = true;
            this.waitingForPdf = false;
            this.requestEnRoute = false;
          });
        },
        error: () => {
          this.showingPdf = false;
          this.waitingForPdf = false;
          this.requestEnRoute = false;
          this.avaNotificationsService.error('Fehler beim Erstellen der PDF-Datei');
        }
      });
  }

  generateOfferPdfAndShowPrintView(): void {
    this.requestEnRoute = true;
    this.reportsClient
      .getServiceSpecificationOfferReport(
        this.serviceSpecificationId,
        true,
        this.customOfferExportHeaderText,
        this.includeSignatureField,
        this.includeHeaderOnlyOnFirstPage,
        this.excludePositions,
        this.includeOnlyOverview,
        this.includeWarningsForCalculationErrors,
        {
          elementSelection: this.elementSelection,
          includeLongTexts: this.exportLongTextCompletely,
          buyerAddress: this.address,
          endHtmlText: this.offerEndText,
          startHtmlText: this.offerStartText,
          maxElementExportDepth: this.maxElementExportDepth < 0 ? null : this.maxElementExportDepth
        }
      )
      .subscribe(
        (report: ReportGetOfServiceSpecificationReport) => {
          this.printViewMessengerService.showPdfPreview(
            report.htmlReport,
            'Angebot',
            report.isLandscape,
            report.pdfReportBase64,
            this.includeHeaderOnlyOnFirstPage
          );
          this.waitingForPdf = true;
          this.printViewMessengerService.waitForPrintViewReady().subscribe(() => {
            this.waitingForPdf = false;
          });

          this.showingPdf = true;
          this.requestEnRoute = false;
        },
        () => {
          this.requestEnRoute = false;
          this.avaNotificationsService.error('Fehler beim Erstellen der Druckvorschau.');
        }
      );
  }

  generatePdfAndShowCompactPrintView(): void {
    this.requestEnRoute = true;
    this.reportsClient
      .getServiceSpecificationCompactReport(
        this.serviceSpecificationId,
        true,
        false,
        false,
        false,
        false,
        this.includeHeaderOnlyOnFirstPage,
        true,
        {
          elementSelection: this.elementSelection,
          endHtmlText: this.offerEndText,
          startHtmlText: this.offerStartText
        }
      )
      .subscribe({
        next: (report: ReportGetOfServiceSpecificationReport) => {
          this.printViewMessengerService.showPdfPreview(
            report.htmlReport,
            'Kompaktes Leistungsverzeichnis',
            report.isLandscape,
            report.pdfReportBase64,
            this.includeHeaderOnlyOnFirstPage
          );
          this.waitingForPdf = true;
          this.printViewMessengerService.waitForPrintViewReady().subscribe(() => {
            this.waitingForPdf = false;
          });

          this.showingPdf = true;
          this.requestEnRoute = false;
        },
        error: () => {
          this.requestEnRoute = false;
          this.avaNotificationsService.error('Fehler beim Erstellen der Druckvorschau.');
        }
      });
  }

  selectStartingText(event: MouseEvent): void {
    event.preventDefault();
    if (!this.offerStartText) {
      this.offerStartText = this.lastStartText;
      this.selectText(this.offerStartText, TextTemplateType.OfferStart).subscribe((t) => (this.offerStartText = t));
    } else {
      this.lastStartText = this.offerStartText;
      this.offerStartText = null;
    }
  }

  selectEndingText(event: MouseEvent): void {
    event.preventDefault();
    if (!this.offerEndText) {
      this.offerEndText = this.lastEndText;
      this.selectText(this.offerEndText, TextTemplateType.OfferEnd).subscribe((t) => (this.offerEndText = t));
    } else {
      this.lastEndText = this.offerEndText;
      this.offerEndText = null;
    }
  }

  private selectText(currentText: string, textTemplateType: TextTemplateType): Observable<string> {
    return this.modalService
      .openModal(ReportTextSelectionComponent, {
        dialogType: ConfirmationType.General,
        data: {
          text: currentText,
          templateType: textTemplateType
        }
      })
      .afterClosed()
      .pipe(map((text) => text ?? currentText));
  }

  getProjectInfo(project: ProjectGet, avaProject: ProjectDto): void {
    if (project.contactId) {
      let contact: ContactGet;
      this.contactsClient
        .getContactById(project.contactId)
        .pipe(
          takeUntil(this.$destroy),
          switchMap((c: ContactGet) => {
            contact = c;
            return this.addressesClient.getAllAddressesForContact(c.id, undefined, undefined, 500, 1);
          })
        )
        .subscribe((e: PaginationResultOfAddressGet) => {
          const address = e.data.find((item) => item.isDefaultInvoiceAddress);
          if (address) {
            this.address = `${contact.name ?? ''}\r\n${address.street ?? ''}\r\n${address.zipCode ?? ''} ${address.city ?? ''}\r\n${
              address.country ?? ''
            }`.trim();
          } else if (e.data.length > 0) {
            const firstAddress = e.data[0];
            this.address = `${contact.name ?? ''}\r\n${firstAddress.street ?? ''}\r\n${firstAddress.zipCode ?? ''} ${
              firstAddress.city ?? ''
            }\r\n${firstAddress.country ?? ''}`.trim();
          } else {
            const projectBuyer = avaProject?.projectInformation?.buyer;
            if (projectBuyer != null) {
              this.address = `${projectBuyer.name ?? ''}\r\n${projectBuyer.street ?? ''}\r\n${projectBuyer.zipCode ?? ''} ${
                projectBuyer.city ?? ''
              }\r\n${projectBuyer.country ?? ''}`.trim();
            } else {
              this.address = contact.name;
            }
          }
        });
    } else {
      const projectBuyer = avaProject?.projectInformation?.buyer;
      if (projectBuyer != null) {
        this.address = `${projectBuyer.name ?? ''}\r\n${projectBuyer.street ?? ''}\r\n${projectBuyer.zipCode ?? ''} ${
          projectBuyer.city ?? ''
        }\r\n${projectBuyer.country ?? ''}`.trim();
      }
    }
  }
}
