<h1 mat-dialog-title>Mengenübersicht Drucken</h1>
<div mat-dialog-content>
  <div class="wrapper">
    <div *ngIf="!showingPdf">
      <div class="margin-bottom-10">
        <div fxLayoutGap="10px" class="btn-selection-wrap">
          <button color="primary" mat-raised-button (click)="selectQTOs()" [disabled]="hasOnlyOne">Abrechnung</button>
          <button color="warn" mat-raised-button (click)="qtoSelection = null" [disabled]="!qtoSelection">Nur Einzelne</button>
        </div>
        <mat-hint *ngIf="qtoSelection?.length">Nur ausgewählte Abrechnungen werden gedruckt</mat-hint>
      </div>

      <div class="margin-bottom-10" *ngIf="'ByPage' === calculationType">
        <div fxLayoutGap="10px" class="btn-selection-wrap">
          <button color="primary" mat-raised-button (click)="selectPages()">Blätter</button>
          <button color="warn" mat-raised-button (click)="pagesSelection = null" [disabled]="!pagesSelection">Alle Blätter</button>
        </div>
        <mat-hint *ngIf="pagesSelection?.length">Nur ausgewählte Blätter werden gedruckt</mat-hint>
      </div>
    </div>
    <h1 *ngIf="showingPdf">Bitte Druckvorschaufenster beachten.</h1>
    <div class="wrapper-loader" *ngIf="isLoading" fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <div class="dialog-actions" fxLayoutGap="10px">
    <button color="primary" [disabled]="showingPdf || isLoading" mat-raised-button (click)="generatePdfAndShowPreview()">
      PDF Erzeugen
    </button>
    <button mat-raised-button (click)="closeDialog()">Abbrechen</button>
  </div>
</div>
