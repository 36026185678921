<div class="wrapper">
  <form [formGroup]="exportFormGroup">
    <ng-container *ngIf="!requestEnRoute">
      <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field appearance="fill">
          <mat-label>Name</mat-label>
          <input matInput formControlName="exportName" required />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Typ</mat-label>
          <mat-select formControlName="targetType">
            <mat-option *ngFor="let targetType of targetTypes" [value]="targetType">
              {{ targetType | avaFileTargetType }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Phase</mat-label>
          <mat-select formControlName="exportPhase">
            <mat-option *ngFor="let phase of exportPhases" [value]="phase">
              {{ phase | avaFileExportPhase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <pa-selecting-element-buttons (elementSelectionChanged)="elementSelection = $event"></pa-selecting-element-buttons>
      <div>
        <mat-checkbox class="example-margin" formControlName="persistFile">Datei im Projekt speichern</mat-checkbox>
      </div>
      <div>
        <mat-checkbox class="example-margin" formControlName="alwaysIncludeDescriptionsInGaeb">Texte in GAEB immer ausgeben</mat-checkbox>
      </div>

      <button color="primary" mat-raised-button [disabled]="!exportFormGroup.valid" (click)="createExport()">Erstellen</button>
    </ng-container>
  </form>
  <div class="wrapper-loader" *ngIf="requestEnRoute">
    <mat-spinner></mat-spinner>
  </div>
</div>
