<div>
  <div class="btn-selection-wrap">
    <button type="button" color="primary" mat-raised-button (click)="selectElements()">Elementauswahl</button>
    <button type="button" color="warn" mat-raised-button (click)="elementSelection = null" [disabled]="!elementSelection">
      Teilauswahl aufheben
    </button>
    <button type="button" color="primary" mat-raised-button (click)="openElementSelectionTable()" *ngIf="isShowElementSelectionTableButton">
      Elementauswahl laden
    </button>
  </div>
  <span *ngIf="elementSelection">Teil-LV ausgewählt</span>
</div>
