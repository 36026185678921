import { Pipe, PipeTransform } from '@angular/core';

import { ProjectStatus } from '../../../generated-client/generated-client';

@Pipe({
  name: 'projectStatus',
  standalone: true
})
export class ProjectStatusPipe implements PipeTransform {
  transform(value: ProjectStatus): string {
    switch (value) {
      case ProjectStatus.Offer:
        return 'Angebot';
      case ProjectStatus.Awarded:
        return 'Auftrag';
      case ProjectStatus.Archived:
        return 'Archiviert';
      case ProjectStatus.Locked:
        return 'Gesperrt';
    }

    return null;
  }
}
