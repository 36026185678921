import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentTabComponentService {
  private _currentTab = new ReplaySubject<string | null>(1);
  currentTab = this._currentTab.asObservable();

  setCurrentTab(currentTab: string | null): void {
    this._currentTab.next(currentTab);
  }
}
