import { NgIf } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMiniFabButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { ActivatedRoute, Params } from '@angular/router';

import { combineLatest, filter, take } from 'rxjs';

import { FlexLayoutDirective } from 'app/areas/flex-layout/flex-layout.directive';

import { BimFileGet, BimFilesClient } from '../../../../generated-client/generated-client';
import { SelectedSpecificationMessengerService } from '../../../../shared/services/messengers/selected-specification-messenger.service';
import { BimModelFileLightqueryService } from '../../services/bim-model-file-lightquery.service';
import { BimStateService } from '../../services/bim-state.service';

import { LeftSideBimViewComponent } from '../left-side-bim-view/left-side-bim-view.component';
import { ModelBimViewComponent } from '../model-bim-view/model-bim-view.component';
import { RightSideBimViewComponent } from '../right-side-bim-view/right-side-bim-view.component';
import { SplitComponent, AngularSplitModule } from 'angular-split';

@Component({
  selector: 'pa-main-frame-bim-view',
  templateUrl: './main-frame-bim-view.component.html',
  styleUrls: ['./main-frame-bim-view.component.scss'],
  standalone: true,
  imports: [
    AngularSplitModule,
    NgIf,
    LeftSideBimViewComponent,
    ModelBimViewComponent,
    MatMiniFabButton,
    MatIcon,
    RightSideBimViewComponent,
    FlexLayoutDirective
  ]
})
export class MainFrameBimViewComponent implements OnInit {
  @ViewChild('asBimSplitRef') asBimSplitRef: SplitComponent;
  isHideLeftSide = false;
  isHideRightSide = false;
  bimFile: BimFileGet;
  leftSideAreaSize: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private selectedSpecificationMessengerService: SelectedSpecificationMessengerService,
    private stateService: BimStateService,
    private bimFilesClient: BimFilesClient,
    private bimModelFileLightqueryService: BimModelFileLightqueryService
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.selectedSpecificationMessengerService.selectedServiceSpecification.pipe(filter((s) => !!s)),
      this.activatedRoute.params
    ])
      .pipe(take(1))
      .subscribe(([selectedServSpec, params]: [Params, Params]) => {
        const projectId = selectedServSpec.parentProjectId;
        const avaProjectId = selectedServSpec.avaProjectId;
        const bimFileId = params.bimFileId;
        this.bimFile = this.bimModelFileLightqueryService.getModelById(bimFileId);
        if (this.bimFile) {
          this.stateService.setBimFile(this.bimFile);
          return;
        }

        this.bimFilesClient.getBimFileById(projectId, avaProjectId, bimFileId).subscribe((f) => {
          this.bimFile = f;
          this.stateService.setBimFile(f);
        });
      });
  }

  toggleLeftSide(): void {
    this.isHideLeftSide = !this.isHideLeftSide;
    this.resizeSplit();
  }
  toggleRightSide(): void {
    this.isHideRightSide = !this.isHideRightSide;
    this.resizeSplit();
  }

  resizeSplit(): void {
    setTimeout(() => {
      if (!this.isHideLeftSide && this.isHideRightSide) {
        this.asBimSplitRef.setVisibleAreaSizes([20, 80]);
      } else if (this.isHideLeftSide && !this.isHideRightSide) {
        this.asBimSplitRef.setVisibleAreaSizes([80, 20]);
      } else if (!this.isHideLeftSide && !this.isHideRightSide) {
        this.asBimSplitRef.setVisibleAreaSizes([20, 60, 20]);
        this.dragEnd({ sizes: [20, 60, 20] });
      }
    }, 0);
  }

  dragEnd({ sizes }): void {
    this.leftSideAreaSize = sizes[0];
  }
}
